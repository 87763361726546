import {
  MISMOCode,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum LicenseAuthorityLevelType {
  Other = 'Other',
  Private = 'Private',
  PublicFederal = 'PublicFederal',
  PublicLocal = 'PublicLocal',
  PublicState = 'PublicState',
}

enum AppraiserLicenseType {
  CertifiedGeneral = 'CertifiedGeneral',
  CertifiedResidential = 'CertifiedResidential',
  LicensedResidentialAppraiser = 'LicensedResidentialAppraiser',
  Other = 'Other',
  RegisteredTraineeApprentice = 'RegisteredTraineeApprentice',
}

type AppraiserLicense = MISMOEntity &
  Partial<{
    appraiserLicenseType: AppraiserLicenseType;
    appraiserLicenseTypeOtherDescription: MISMOString;
  }>;

enum PropertyLicenseType {
  Other = 'Other',
  Rental = 'Rental',
  RightToUse = 'RightToUse',
}

type PropertyLicense = MISMOEntity &
  Partial<{
    propertyLicenseType: PropertyLicenseType;
    propertyLicenseTypeOtherDescription: MISMOString;
  }>;

export type License = MISMOEntity &
  Partial<{
    licenseAuthorityLevelType: LicenseAuthorityLevelType;
    licenseAuthorityLevelTypeOtherDescription: MISMOString;
    licenseExemptIndicator: MISMOIndicator;
    licenseExpirationDate: MISMODate;
    licenseIdentifier: MISMOIdentifier;
    licenseIssueDate: MISMODate;
    licenseIssuingAuthorityName: MISMOString;
    licenseIssuingAuthorityStateCode: MISMOCode;
    licenseIssuingAuthorityStateName: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }> &
  (AppraiserLicense | PropertyLicense);
