import { useHistory, useParams } from 'react-router-dom';

import { useGetPoolWithTradeRequestQuery } from '../hooks/queries';
import { CreateTradeRequestPopup } from './CreateTradeRequestPopup';

type CreateTradeRequestPopupRouteParams = {
  poolId: string;
};

export const TradeRequestPoolPage = () => {
  const history = useHistory();
  const { poolId } = useParams<CreateTradeRequestPopupRouteParams>();

  const { data: pool, isLoading: isPoolLoading } = useGetPoolWithTradeRequestQuery(poolId);

  return (
    <CreateTradeRequestPopup
      isPoolLoading={isPoolLoading}
      onCancel={() => history.goBack()}
      onSubmit={() => history.push('/trade-requests')}
      pool={pool}
    />
  );
};
