import { DateTime, Duration } from 'luxon';

export const isSameDay = (d1: Date, d2: Date) => {
  return DateTime.fromJSDate(d1).hasSame(DateTime.fromJSDate(d2), 'day');
};

export const isDateToday = (date: Date) => {
  const today = DateTime.now();
  return today.hasSame(DateTime.fromJSDate(date), 'day');
};

export const isDateYesterday = (date: Date) => {
  const yesterday = DateTime.now().minus(Duration.fromObject({ day: 1 }));
  const dateInQuestion = DateTime.fromJSDate(date);
  return yesterday.hasSame(dateInQuestion, 'day');
};
