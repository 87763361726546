import { LoanChatMessage } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { SendLoanChatMessageMutation } from '.';

export const useSendLoanChatMessageMutation = (loanNumber: string) =>
  useMutation((data: SendLoanChatMessageMutation) => {
    const url = makeApiUrl(`loans/${loanNumber}/messages`);

    return postRequestWithAuth<LoanChatMessage>(url, {
      body: JSON.stringify(data),
    });
  });
