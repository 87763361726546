import {
  MISMOCode,
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

type CensusInformation = MISMOEntity &
  Partial<{
    censusBlockGroupIdentifier: MISMOIdentifier;
    censusBlockIdentifier: MISMOIdentifier;
    censusTractBaseIdentifier: MISMOIdentifier;
    censusTractIdentifier: MISMOIdentifier;
    censusTractSuffixIdentifier: MISMOIdentifier;
  }>;

enum FIPSCountySubdivisionType {
  CensusCountyDivision = 'CensusCountyDivision',
  MinorCivilDivision = 'MinorCivilDivision',
}

type FIPSInformation = MISMOEntity &
  Partial<{
    FIPSCountryCode: MISMOCode;
    FIPSCountyCode: MISMOCode;
    FIPSCountySubdivisionCode: MISMOCode;
    FIPSCountySubdivisionName: MISMOString;
    FIPSCountySubdivisionType: FIPSCountySubdivisionType;
    FIPSPlaceCode: MISMOCode;
    FIPSPlaceName: MISMOString;
    FIPSStateAlphaCode: MISMOCode;
    FIPSStateNumericCode: MISMOCode;
  }>;

enum RecordingJurisdictionType {
  County = 'County',
  Other = 'Other',
}

type GeneralIdentifier = MISMOEntity &
  Partial<{
    congressionalDistrictIdentifier: MISMOIdentifier;
    coreBasedStatisticalAreaCode: MISMOCode;
    coreBasedStatisticalAreaDivisionCode: MISMOCode;
    judicialDistrictName: MISMOString;
    judicialDivisionName: MISMOString;
    mapReferenceIdentifier: MISMOIdentifier;
    mapReferenceSecondIdentifier: MISMOIdentifier;
    metropolitanDivisionIdentifier: MISMOIdentifier;
    MSAIdentifier: MISMOIdentifier;
    municipalityName: MISMOString;
    recordingJurisdictionName: MISMOString;
    recordingJurisdictionType: RecordingJurisdictionType;
    recordingJurisdictionTypeOtherDescription: MISMOString;
    schoolDistrictName: MISMOString;
    unincorporatedAreaName: MISMOString;
  }>;

type GeospatialInformation = MISMOEntity &
  Partial<{
    geospatialPropertyIdentifier: MISMOIdentifier;
    latitudeIdentifier: MISMOIdentifier;
    longitudeIdentifier: MISMOIdentifier;
    lotBoundaryIdentifier: MISMOIdentifier;
    naturalLotLocationIdentifier: MISMOIdentifier;
    naturalUnitSpaceEntryIdentifier: MISMOIdentifier;
  }>;

type UniquePropertyIdentification = MISMOEntity &
  Partial<{
    propertyIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type LocationIdentifier = MISMOEntity &
  Partial<{
    censusInformation: CensusInformation;
    FIPSInformation: FIPSInformation;
    generalIdentifier: GeneralIdentifier;
    geospatialInformation: GeospatialInformation;
    uniquePropertyIdentifications: UniquePropertyIdentification[];
  }>;
