import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { baseButtonStyles } from './Button.styles';

type styleProps = ThemeProps<DefaultTheme> & ButtonProps;

export const activeStyles = ({ $variant, theme }: styleProps) => css`
  color: ${$variant === 'dark' ? theme.colors.accent01 : theme.colors.fillButtonLight};
  background: ${$variant === 'dark' ? theme.colors.white30 : theme.colors.backgroundButtonLight};
`;

export const hoverStyles = ({ $variant, theme }: styleProps) => css`
  color: ${$variant === 'dark' ? theme.colors.accent01 : theme.colors.white};
  background: ${theme.colors.white15};
`;

export const smallStyles = css`
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const mediumStyles = ({ $variant }: styleProps) => css`
  width: 24px;
  height: ${$variant === 'light' ? 22 : 24}px;
`;

export const largeStyles = css`
  width: 32px;
  height: 32px;
`;

type ButtonProps = {
  $size?: 'small' | 'medium' | 'large';
  $variant?: 'light' | 'dark';
};

export const Button = styled.button<ButtonProps>(
  ({ $size = 'large', theme }) => css`
    ${baseButtonStyles}

    appearance: none;
    flex-shrink: 0;

    color: ${theme.colors.textPrimary01};
    padding: 0;

    ${$size === 'small' && smallStyles}
    ${$size === 'medium' && mediumStyles}
    ${$size === 'large' && largeStyles}

    &:hover,
    &:focus-visible {
      color: ${theme.colors.fillButtonIconHover};
      background: ${theme.colors.backgroundButtonHover};
    }

    &:active {
      ${activeStyles}
    }

    &:disabled {
      opacity: 0.2;
    }
  `
);
