import {
  GetHoldingTankDocumentsResponse,
  SubmissionDocumentAttributes,
} from '@plus-platform/shared';

import {
  getBlobRequestWithAuth,
  getRequestWithAuth,
  makeApiUrl,
  postFormDataRequestWithAuth,
} from '../../utils/apiUtils';

export const uploadDocuments = (files: File[]) => {
  const url = makeApiUrl('submissions/upload');
  const data = new FormData();

  files.forEach((file) => data.append('documents', file));

  return postFormDataRequestWithAuth<{ submissionId: string }>(url, { body: data });
};

type DocumentDetails = SubmissionDocumentAttributes;

export const getSubmissionDocumentDetails = async (documentId: string) => {
  const url = makeApiUrl(`submissions/document/${documentId}`);
  return getRequestWithAuth<DocumentDetails>(url);
};

export const getSubmissionDocumentImage = async (
  submissionId: string,
  documentName: string,
  page?: number
) => {
  const queryParams = page ? `?page=${page}` : '';
  const url = makeApiUrl(
    `submissions/submission/${submissionId}/image/${documentName}${queryParams}`
  );
  return getBlobRequestWithAuth(url);
};

export const getHoldingTankDocuments = async (
  filterIds: number[] = [],
  offset = 0,
  limit: number | undefined = undefined,
  sortBy: string | undefined = undefined
) => {
  // TODO: add sort direction support, for now asc only
  const sortSymbol = '';
  const sortQuery = sortBy ? `${sortSymbol}${sortBy}` : '';

  const query = new URLSearchParams({
    offset: `${offset}`,
    limit: limit ? `${limit}` : '',
    sort: sortQuery,
  });

  filterIds.forEach((filterId) => query.append('filterIds', `${filterId}`));

  const url = makeApiUrl(`submissions/holding-tank?${query}`);
  return getRequestWithAuth<GetHoldingTankDocumentsResponse>(url);
};
