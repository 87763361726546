import { isDateToday, isDateYesterday } from '../../utils/dateUtils';
import { formatDate } from '../../utils/formatUtils';
import * as Styles from './DateDivider.styles';

type DateDividerProps = {
  date: Date;
  variant?: 'solid' | 'outline';
};

export const DateDivider = ({ date, variant }: DateDividerProps) => {
  return (
    <Styles.Divider role="separator">
      <Styles.Line />
      <Styles.DateBlock $variant={variant}>
        {isDateYesterday(date)
          ? 'Yesterday'
          : isDateToday(date)
          ? 'Today'
          : formatDate(date, {
              dateStyle: 'medium',
            })}
      </Styles.DateBlock>
      <Styles.Line />
    </Styles.Divider>
  );
};
