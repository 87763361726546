import { Depreciation } from '../depreciation';
import { NewImprovement } from '../newImprovement';
import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOPercent,
  MISMOString,
} from '../types';

export enum CostAnalysisType {
  Other = 'Other',
  Replacement = 'Replacement',
  Reproduction = 'Reproduction',
}

export type CostApproach = MISMOEntity &
  Partial<{
    costAnalysisCommentDescription: MISMOString;
    costAnalysisType: CostAnalysisType;
    costAnalysisTypeOtherDescription: MISMOString;
    costServiceQualityRatingDescription: MISMOString;
    estimatedRemainingEconomicLifeYearsCount: MISMOCount;
    manufacturedHomeInstallationCostAmount: MISMOAmount;
    newImprovementCostLocalizedAmount: MISMOAmount;
    newImprovementCostMultiplierPercent: MISMOPercent;
    newImprovementDepreciatedCostAmount: MISMOAmount;
    newImprovementTotalCostAmount: MISMOAmount;
    siteEstimatedValueAmount: MISMOAmount;
    siteEstimatedValueCommentDescription: MISMOString;
    siteOtherImprovementsAsIsAmount: MISMOAmount;
    valueIndicatedByCostApproachAmount: MISMOAmount;
    depreciation: Depreciation;
    newImprovements: NewImprovement[];
  }>;
