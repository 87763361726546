import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type Props = {
  message?: string;
  children?: ReactNode;
};

const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const ErrorMessage = styled.pre`
  color: ${({ theme }) => theme.colors.redMain};
  margin: 24px;
`;

const ErrorContent = styled.div``;

export const Error = ({ children, message }: Props) => (
  <StyledError>
    <ErrorContent>
      {message && <ErrorMessage>{message}</ErrorMessage>}
      {children}
    </ErrorContent>
  </StyledError>
);
