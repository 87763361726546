import { DelinquencySummary, PortfolioSummary } from '@plus-platform/shared';
import React, { useState } from 'react';
import { useTheme } from 'styled-components/macro';

import { ButtonIcon } from '../components/ButtonIcon';
import { Card } from '../components/Card';
import { DelinquencyPanes } from '../components/DelinquencyPanes/DelinquencyPanes';
import { Divider } from '../components/Divider';
import { ChevronDownIcon, ChevronUpIcon } from '../components/icons';
import { Key, Pair, Value } from '../components/KeyValue';
import { Highlight, SummaryColumns, SummaryWrapper } from '../components/Summary';
import { formatMonetary, formatNumber, formatPercentage } from '../utils/formatUtils';
import * as Styles from './LoanSummary.styles';

type LoanShortSummaryProps = {
  hasPoolInformation?: boolean;
  poolsCount?: number;
  loansCount?: number;
  upbAmount?: number;
  style?: React.CSSProperties;
  remittanceAmount?: number;
  delinquencySummary?: DelinquencySummary;
  weightedAverageLTV?: number;
  weightedAverageCoupon?: number;
};

export const LoanShortSummary = ({
  delinquencySummary,
  hasPoolInformation = false,
  loansCount,
  poolsCount,
  remittanceAmount,
  upbAmount,
  weightedAverageCoupon,
  weightedAverageLTV,
}: LoanShortSummaryProps) => {
  const theme = useTheme();
  return (
    <SummaryColumns>
      {hasPoolInformation && (
        <>
          <Pair $align="left" $direction="vertical">
            <Key>Pool count</Key>
            <Value>{poolsCount}</Value>
          </Pair>
          <Divider $orientation="vertical" $flexItem />
        </>
      )}
      <Pair $align="left" $direction="vertical">
        <Key>Loan count</Key>
        <Value>{formatNumber(loansCount)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>UPB</Key>
        <Value>{formatMonetary(upbAmount)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>Remittance</Key>
        <Value>{formatMonetary(remittanceAmount)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>WAC</Key>
        <Value>{formatPercentage(weightedAverageCoupon)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>Wtd Avg LTV</Key>
        <Value>{formatPercentage(weightedAverageLTV)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>Loans in Default</Key>
        <Value>{formatNumber(delinquencySummary?.total?.loanCount)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>30 Days Default</Key>
        <Value>
          <Highlight color={theme.colors.delinquency30Days}>
            {formatNumber(delinquencySummary?.thirtyDays?.loanCount)}
          </Highlight>
        </Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>60 Days Default</Key>
        <Value>
          <Highlight color={theme.colors.delinquency60Days}>
            {formatNumber(delinquencySummary?.sixtyDays?.loanCount)}
          </Highlight>
        </Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>90+ Days Default</Key>
        <Value>
          <Highlight color={theme.colors.delinquency90Days}>
            {formatNumber(delinquencySummary?.ninetyPlusDays?.loanCount)}
          </Highlight>
        </Value>
      </Pair>
    </SummaryColumns>
  );
};

export const PoolsSummary = ({
  delinquencySummary,
  hasPoolInformation = true,
  loansCount,
  poolsCount,
  remittanceAmount,
  upbAmount,
  weightedAverageCoupon,
  weightedAverageLTV,
}: LoanShortSummaryProps) => {
  return (
    <SummaryWrapper>
      <LoanShortSummary
        delinquencySummary={delinquencySummary}
        poolsCount={poolsCount}
        loansCount={loansCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
        hasPoolInformation={hasPoolInformation}
      />
    </SummaryWrapper>
  );
};

type LoanPortfolioSummaryProps = {
  summary?: PortfolioSummary;
};

export const LoanPortfolioSummary = ({ summary }: LoanPortfolioSummaryProps) => {
  const [showDetailSummary, setShowDetailSummary] = useState(false);

  if (!summary) {
    // TODO: Update <Skeleton> and re-introduce - dependancy on Khayam
    return null;
  }

  return (
    <Card>
      <SummaryWrapper>
        {showDetailSummary ? (
          <Styles.DetailedWrapper>
            <DelinquencyPanes summary={summary} />
          </Styles.DetailedWrapper>
        ) : (
          <LoanShortSummary
            delinquencySummary={summary.loansInDefault}
            loansCount={summary.loansCount}
            remittanceAmount={summary.loansRemittanceAmount}
            upbAmount={summary.loansUPBAmount}
            weightedAverageCoupon={summary.loansWeightedAverageCoupon}
            weightedAverageLTV={summary.loansWeightedAverageLTV}
          />
        )}
        <ButtonIcon
          onClick={() => {
            setShowDetailSummary(!showDetailSummary);
          }}
          variant="dark"
        >
          {showDetailSummary ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </ButtonIcon>
      </SummaryWrapper>
    </Card>
  );
};
