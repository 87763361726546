import React from 'react';

import { Loader } from '../../components/Loader';
import { useGetSubmissionDocumentDetailsQuery } from '../../hooks/queries';
import * as Styles from './DocumentViewer.styles';
import { DocumentViewerPage, PageView } from './PageView';
import { SelectedPageView } from './SelectedPageView';

type OcrulusDocumentViewerProps = {
  onPageClick: (pageId: DocumentViewerPage['id']) => void;
  selectedPageId?: DocumentViewerPage['id'];
  documentId: string;
  documentName?: string;
};

const OcrulusDocumentViewer = ({
  documentId,
  documentName,
  onPageClick,
  selectedPageId,
}: OcrulusDocumentViewerProps) => {
  const [loadedPageIds, setLoadedPageIds] = React.useState<DocumentViewerPage['id'][]>([]);
  const [containerMaxHeight, setMaxContainerHeight] = React.useState<number | undefined>(undefined);
  const [hasLoadedAllPages, setHasLoadedAllPages] = React.useState<boolean>(false);

  const { data, isLoading } = useGetSubmissionDocumentDetailsQuery(`${documentId}`);

  const pageCount = data?.pageCount || 0;

  React.useEffect(() => {
    if (pageCount > 0 && pageCount === loadedPageIds.length) {
      setHasLoadedAllPages(true);
    }
  }, [pageCount, loadedPageIds]);

  React.useEffect(() => {
    if (selectedPageId === undefined && loadedPageIds.includes(0)) {
      onPageClick(0);
    }
  }, [loadedPageIds, onPageClick]);

  const handleImageLoad = (imageHeight: number) => {
    setMaxContainerHeight(imageHeight);
  };

  const handlePageLoaded = (pageId: DocumentViewerPage['id']) => {
    if (!loadedPageIds.includes(pageId)) {
      setLoadedPageIds([...loadedPageIds, pageId]);
    }
  };

  return (
    <React.Fragment>
      {(!hasLoadedAllPages || isLoading) && (
        <Styles.LoadingContainer>
          <Loader />
          {pageCount > 0 && (
            <div>
              Loading <span>{loadedPageIds.length}</span> of <span>{pageCount}</span>
            </div>
          )}
        </Styles.LoadingContainer>
      )}

      {data && (
        <Styles.DocumentPageSubSection $isVisible>
          <Styles.DocumentContent>
            <Styles.DocumentPages>
              <Styles.ScrollableContent>
                <Styles.PageViews $maxHeight={containerMaxHeight}>
                  {[...Array(pageCount).keys()].map((pageIndex) => (
                    <PageView
                      key={pageIndex}
                      loadedPageIds={loadedPageIds}
                      onPageClick={onPageClick}
                      onPageLoaded={handlePageLoaded}
                      submissionId={data.submissionId}
                      documentName={documentName || data.documentName}
                      selectedPageId={selectedPageId}
                      id={pageIndex}
                    />
                  ))}
                </Styles.PageViews>
              </Styles.ScrollableContent>
            </Styles.DocumentPages>
            {selectedPageId !== undefined && (
              <SelectedPageView
                submissionId={data.submissionId}
                documentName={documentName || data.documentName}
                onImageLoad={handleImageLoad}
                id={selectedPageId}
              />
            )}
          </Styles.DocumentContent>
        </Styles.DocumentPageSubSection>
      )}
    </React.Fragment>
  );
};

export const DocumentViewer = ({
  documentId,
  documentName,
  onPageClick,
  selectedPageId,
}: OcrulusDocumentViewerProps) => {
  return (
    <OcrulusDocumentViewer
      documentId={documentId}
      onPageClick={onPageClick}
      selectedPageId={selectedPageId}
      documentName={documentName}
    />
  );
};
