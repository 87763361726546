import {
  GetSummaryByAnalyticGroupResponse,
  GetSummaryByHomeAnalyticGroupResponse,
  PortfolioAnalyticItem,
  PortfolioLoanRateType,
  US_STATES,
} from '@plus-platform/shared';
import { useTheme } from 'styled-components/macro';

import { Divider } from '../../components/Divider';
import { Heading } from '../../components/Heading';
import { Key, Pair, Value } from '../../components/KeyValue';
import { LoanMap } from '../../components/LoanMap';
import { PageSection } from '../../components/Page';
import { Skeleton } from '../../components/Skeleton';
import { formatDefaultValue, formatNumber, formatPercentage } from '../../utils/formatUtils';
import { formatLoanRateType } from '../../utils/loanUtils';
import { AnalyticDetailWidget } from './AnalyticDetailWidget';
import * as Styles from './AnalyticGroupDetailWidget.styles';
import { AnalyticsChart } from './AnalyticsCharts';
import { AnalyticWidgetLink } from './AnalyticWidget.styles';
import {
  flattenValueForMetric,
  formatDataForAnalyticWidget,
  formatValueForMetric,
  getWorstByGroupedValue,
  portfolioAnalyticMetricLabels,
} from './utils';

type AnalyticGroupDetailWidgetProps = {
  analytic?: PortfolioAnalyticItem;
  loansSummaryData?: GetSummaryByAnalyticGroupResponse | GetSummaryByHomeAnalyticGroupResponse;
  isLoading: boolean;
  groupValue: string;
};

export const AnalyticGroupDetailWidget = (props: AnalyticGroupDetailWidgetProps) => {
  const { analytic, groupValue, isLoading, loansSummaryData } = props;
  const theme = useTheme();

  const analyticItems = analytic?.data || [];

  const metricByLabel = analytic ? portfolioAnalyticMetricLabels[analytic.metricBy] : '';
  const groupTitle = metricByLabel ? `In the ${groupValue} ${metricByLabel} band` : '';

  const delinquencyRate = loansSummaryData
    ? loansSummaryData.totalDelinquentLoansCount / loansSummaryData.totalLoansCount
    : undefined;

  const loanTypeValues = Object.entries(loansSummaryData?.valuesByTypesOfLoan || {});
  const stateValues = Object.entries(loansSummaryData?.valuesByState || {});

  const worstLoanType = getWorstByGroupedValue(
    loanTypeValues.map(([loanType, { value }]) => ({
      groupedByValue: loanType,
      value,
    })),
    analytic?.metric
  );
  const worstLoanTypeLabel = worstLoanType
    ? formatLoanRateType(worstLoanType as PortfolioLoanRateType)
    : undefined;
  const worstStateCode = getWorstByGroupedValue(
    stateValues.map(([state, { value }]) => ({
      groupedByValue: state,
      value,
    })),
    analytic?.metric
  );
  const worstStateName = US_STATES.find((state) => state.code === worstStateCode)?.name;

  const countByState = stateValues.reduce((acc, [stateCode, data]) => {
    const flattenedValue = flattenValueForMetric(data, analytic?.metric);
    return {
      ...acc,
      [stateCode]: {
        value: flattenedValue,
        formattedValue: formatValueForMetric(flattenedValue, analytic?.metric),
      },
    };
  }, {});

  return (
    <Styles.PageContentWrapper>
      <Styles.Sidebar>
        {analyticItems.length === 0 && <p>No analytics available</p>}

        {analyticItems.length > 0 && analytic && (
          <>
            {analyticItems.map((item, index) => {
              const id = `${item.groupedByValue}-${index}`;
              const data = formatDataForAnalyticWidget(analytic.metric, [
                {
                  value: item.value,
                  groupedByValue: item.groupedByValue,
                  loansCount: item.loansCount,
                },
              ]);

              return (
                <AnalyticWidgetLink
                  key={id}
                  $size="medium"
                  $isActive={item.groupedByValue === groupValue}
                  to={item.groupedByValue}
                >
                  <Styles.Widget>
                    <AnalyticsChart
                      id={id}
                      data={data}
                      visualisationType={analytic.visualisationType}
                      metric={analytic.metric}
                      metricBy={analytic.metricBy}
                      style={{ justifyContent: 'space-evenly' }}
                    />
                  </Styles.Widget>
                </AnalyticWidgetLink>
              );
            })}
          </>
        )}
      </Styles.Sidebar>

      <Styles.MainContent>
        {isLoading && <Skeleton height="600px" />}

        {!isLoading && (
          <>
            <PageSection>
              <Heading $size="medium">{groupTitle}</Heading>

              <Styles.SectionWrapper>
                <Pair $align="left" $direction="vertical">
                  <Key>Number of loans</Key>
                  <Value>{formatNumber(loansSummaryData?.totalLoansCount)}</Value>
                </Pair>

                <Divider $orientation="vertical" $flexItem />

                <Pair $align="left" $direction="vertical">
                  <Key>Number of delinquent loans</Key>
                  <Value>{formatNumber(loansSummaryData?.totalDelinquentLoansCount)}</Value>
                </Pair>

                <Divider $orientation="vertical" $flexItem />

                <Pair $align="left" $direction="vertical">
                  <Key>Rate of delinquency</Key>
                  <Value>{formatPercentage(delinquencyRate)}</Value>
                </Pair>

                <Divider $orientation="vertical" $flexItem />

                <Pair $align="left" $direction="vertical">
                  <Key>Worst by loan type</Key>
                  <Value>{formatDefaultValue(worstLoanTypeLabel)}</Value>
                </Pair>

                <Divider $orientation="vertical" $flexItem />

                <Pair $align="left" $direction="vertical">
                  <Key>Worst by state</Key>
                  <Value>{formatDefaultValue(worstStateName)}</Value>
                </Pair>
              </Styles.SectionWrapper>
            </PageSection>
            <PageSection>
              <Heading $size="medium">By types of loan</Heading>

              <Styles.SectionWrapper>
                {analytic && (
                  <AnalyticDetailWidget
                    analytic={{
                      ...analytic,
                      data: loanTypeValues.map(([loanType, { loansCount, value }]) => ({
                        groupedByValue: formatLoanRateType(loanType as PortfolioLoanRateType),
                        value,
                        loansCount,
                      })),
                    }}
                  />
                )}
              </Styles.SectionWrapper>
            </PageSection>
            <PageSection>
              <Heading $size="medium">By location</Heading>

              <Styles.SectionWrapper>
                <LoanMap
                  loans={countByState}
                  getMapDefaultColor={(threshold) =>
                    threshold > 0 ? theme.colors.white20 : theme.colors.white50
                  }
                />
              </Styles.SectionWrapper>
            </PageSection>
          </>
        )}
      </Styles.MainContent>
    </Styles.PageContentWrapper>
  );
};
