import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import * as api from '../api/documents';
import { Button } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { AddIcon, AlertIcon, CheckmarkIcon } from '../components/icons';
import { Loader } from '../components/Loader';
import { PageContent } from '../components/Page';
import { Popup, PopupHeader } from '../components/Popup';
import { useInterval } from '../hooks/useInterval';
import { getDocumentFileTypeProperties } from '../ingestion/utils';
import { DocumentStepModal } from './DocumentStepModal';
import { SubmitButtonsWrapper } from './DocumentStepModal.styles';
import * as Styles from './LoanFromDocuments.styles';
import { SubmissionStep } from './submissions/submissionUtils';

// For mock processing message
const PROCESSING_MESSAGE_TIME = 9000;
const PROCESSING_STEPS = 3;

type UploadProcessingModalProps = {
  submissionId: string | null;
  hasSubmissionError: boolean;
};

const UploadProcessingModal = ({
  hasSubmissionError,
  submissionId,
}: UploadProcessingModalProps) => {
  const [step, setStep] = useState<number>(1);
  const theme = useTheme();
  const history = useHistory();

  useInterval(
    async () => {
      if (step < PROCESSING_STEPS) {
        setStep((step) => step + 1);
      }
    },
    PROCESSING_MESSAGE_TIME / PROCESSING_STEPS,
    false
  );

  const delayRedirect = (to: string) =>
    setTimeout(() => history.push(to), PROCESSING_MESSAGE_TIME + 1200);

  useEffect(() => {
    if (submissionId) {
      delayRedirect(`/documents/submissions/${submissionId}`);
    }
  }, [submissionId]);

  return (
    <Popup width="350px" height="166px">
      <PopupHeader title="PlusBot" />
      {hasSubmissionError ? (
        <Styles.ProcessingWrapper>
          <Styles.ProcessingStep>
            <AlertIcon />
            <Styles.ProcessingMessage>An error occurred, please try again</Styles.ProcessingMessage>
          </Styles.ProcessingStep>
        </Styles.ProcessingWrapper>
      ) : (
        <>
          {step === 1 && (
            <Styles.ProcessingWrapper>
              <Styles.ProcessingStep $isActive>
                <Loader size={16} color={theme.colors.accent01} />
                <Styles.ProcessingMessage>Opening communications</Styles.ProcessingMessage>
              </Styles.ProcessingStep>
              <Styles.ProcessingStep>
                <Styles.ProcessingMessage>Creating a unique Submission ID</Styles.ProcessingMessage>
              </Styles.ProcessingStep>
            </Styles.ProcessingWrapper>
          )}
          {step === 2 && (
            <Styles.ProcessingWrapper>
              <Styles.ProcessingStep $isComplete>
                <CheckmarkIcon />
                <Styles.ProcessingMessage $isComplete>
                  Communications complete<span>OCR online</span>
                </Styles.ProcessingMessage>
              </Styles.ProcessingStep>
              <Styles.ProcessingStep $isActive>
                <Loader size={16} color={theme.colors.accent01} />
                <Styles.ProcessingMessage>Creating a unique Submission ID</Styles.ProcessingMessage>
              </Styles.ProcessingStep>
            </Styles.ProcessingWrapper>
          )}
          {step === 3 && (
            <Styles.ProcessingWrapper>
              <Styles.ProcessingStep $isComplete>
                <CheckmarkIcon />
                <Styles.ProcessingMessage $isComplete>
                  Communications complete<span>OCR online</span>
                </Styles.ProcessingMessage>
              </Styles.ProcessingStep>
              <Styles.ProcessingStep $isComplete>
                <CheckmarkIcon />
                <Styles.ProcessingMessage $isComplete>
                  Submission ID created <span>Redirecting to your submission...</span>
                </Styles.ProcessingMessage>
              </Styles.ProcessingStep>
            </Styles.ProcessingWrapper>
          )}
        </>
      )}
    </Popup>
  );
};

type UploadedDocumentAttributes = {
  name: string;
  type?: string;
};

export const UploadedDocumentsList = ({
  documents,
  numProcessed,
}: {
  numProcessed?: number;
  documents: UploadedDocumentAttributes[];
}) => {
  return (
    <Styles.DocumentsListWrapper>
      {documents.map(({ type }, index) => {
        const { icon: Icon, shortName } = getDocumentFileTypeProperties(type);

        return (
          <Styles.DocumentContainer
            key={index}
            $isMuted={numProcessed ? index + 1 > numProcessed : false}
          >
            <Styles.FileTypeImage>
              <Icon />
            </Styles.FileTypeImage>
            {shortName}
          </Styles.DocumentContainer>
        );
      })}
    </Styles.DocumentsListWrapper>
  );
};

export const LoanFromDocuments = () => {
  const [submissionId, setSubmissionId] = React.useState<string | null>(null);
  const [hasSubmissionError, setHasSubmissionError] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = React.useState<FileList | null>(null);
  const history = useHistory();
  const theme = useTheme();

  const handleUploadMultipleDocuments = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedFiles(event.target.files);
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!uploadedFiles) {
      return;
    }

    setIsUploading(true);

    try {
      const uploadDocumentsData = await api.uploadDocuments([...uploadedFiles]);
      if (uploadDocumentsData) {
        setSubmissionId(uploadDocumentsData.submissionId);
      } else {
        setHasSubmissionError(true);
      }

      // TODO: do something if upload fails
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
    }
  };

  const canSubmit = Boolean(uploadedFiles && uploadedFiles?.length > 0);

  return (
    <PageContent>
      <Styles.FormContainer encType="multipart/form-data" method="post" onSubmit={handleSubmit}>
        <DocumentStepModal step={SubmissionStep.UPLOAD} isInline={false}>
          <Styles.ContentWrapper>
            <Styles.RequiredMessage>
              Required documents to create new loan
              <Styles.RequiredMessageText>
                Only one (1) document required.
              </Styles.RequiredMessageText>
              <Styles.FileTypeShortName style={{ height: 52 }}>Loan Note</Styles.FileTypeShortName>
            </Styles.RequiredMessage>
            {isUploading && (
              <UploadProcessingModal
                submissionId={submissionId}
                hasSubmissionError={hasSubmissionError}
              />
            )}

            <Styles.MultipleUploadContainer>
              <Styles.UploadDescription>
                <div>Upload</div>
                <Styles.UploadDescriptionText>
                  Browse to find and add files for submission
                </Styles.UploadDescriptionText>
              </Styles.UploadDescription>
              <Styles.ButtonsWrapper>
                <Styles.HiddenInput
                  accept="application/pdf"
                  id="document-upload"
                  multiple
                  onChange={handleUploadMultipleDocuments}
                  type="file"
                />
                {uploadedFiles && uploadedFiles.length && (
                  <UploadedDocumentsList documents={[...uploadedFiles]} />
                )}
                <Styles.MultipleFileUploadButton
                  as="label"
                  htmlFor="document-upload"
                  $canSubmit={canSubmit}
                >
                  <AddIcon
                    color={canSubmit ? theme.colors.accent01 : theme.colors.primary02}
                    height="20px"
                    width="20px"
                  />
                  Add
                </Styles.MultipleFileUploadButton>
              </Styles.ButtonsWrapper>
            </Styles.MultipleUploadContainer>
            <SubmitButtonsWrapper>
              <Button
                $color="tertiary"
                $variant="outlined"
                disabled={isUploading}
                onClick={() => history.push('/portfolio')}
              >
                Cancel
              </Button>
              <ButtonLoader disabled={!canSubmit} isLoading={isUploading} type="submit">
                Upload
              </ButtonLoader>
            </SubmitButtonsWrapper>
          </Styles.ContentWrapper>
        </DocumentStepModal>
      </Styles.FormContainer>
    </PageContent>
  );
};
