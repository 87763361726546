import React from 'react';

export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="13"
      viewBox="0 0 15 13"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 5.5L6.5 10L13 1" stroke="currentColor" strokeWidth="3" />
    </svg>
  );
};
