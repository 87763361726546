import { useQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const usePortfolioDelinquentLoanTypesByNotRateDetailsQuery = (
  ...args: Parameters<typeof api.getPortfolioDelinquentLoanTypesByNoteRateDetails>
) =>
  useQuery([QueryKeys.PORTFOLIO_DELINQUENT_LOAN_TYPES_BY_NOTE_RATE_DETAILS, ...args], () =>
    api.getPortfolioDelinquentLoanTypesByNoteRateDetails(...args)
  );
