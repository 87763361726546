import debounce from 'lodash/debounce';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';

import { ButtonIcon } from './ButtonIcon';
import { ChevronLeftIcon, ChevronRightIcon } from './icons';
import * as Styles from './ImageGallery.styles';

export type ImageGalleryProps = HTMLAttributes<HTMLDivElement>;

function firstChildOutOfViewRight(current: HTMLDivElement): Element | undefined {
  const children = current?.children;

  let targetChild: Element | undefined;

  for (const child of Array.from(children).reverse()) {
    const childRect = child as HTMLElement;
    const parentRight = current.scrollLeft + current.getBoundingClientRect().width;

    if (childRect.offsetLeft > parentRight) {
      targetChild = child;
    } else {
      break;
    }
  }
  return targetChild;
}

function firstChildOutOfViewLeft(current: HTMLDivElement): Element | undefined {
  const children = current?.children;

  let targetChild: Element | undefined;

  for (const child of Array.from(children)) {
    const childRect = child as HTMLElement;

    if (childRect.offsetLeft < current.scrollLeft) {
      targetChild = child;
    } else {
      break;
    }
  }
  return targetChild;
}

function isLeftButtonDisabled(sRef: HTMLDivElement): boolean {
  return firstChildOutOfViewLeft(sRef) === undefined;
}

function isRightButtonDisabled(sRef: HTMLDivElement): boolean {
  return firstChildOutOfViewRight(sRef) === undefined;
}

const debounceOptions = {};

export const ImageGallery = ({ children, ...rest }: ImageGalleryProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const [leftDisabled, setLeftDisabled] = useState(false);
  const [rightDisabled, setRightDisabled] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const handleScroll = useRef(
    debounce(
      (ref) => {
        setLeftDisabled(isLeftButtonDisabled(ref.current!));
        setRightDisabled(isRightButtonDisabled(ref.current!));
      },
      150,
      debounceOptions
    )
  ).current;

  useEffect(() => {
    const observer = new ResizeObserver(([current]) => {
      setShowButtons((current?.target.scrollWidth || 0) > (current?.target.clientWidth || 0));
    });
    scrollAreaRef.current && observer.observe(scrollAreaRef.current);
    return () => {
      observer.disconnect();
    };
  }, [wrapperRef, scrollAreaRef, setShowButtons]);

  function scrollRight() {
    const outOfViewChild = firstChildOutOfViewRight(scrollAreaRef.current!);
    outOfViewChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
  }

  function scrollLeft() {
    const outOfViewChild = firstChildOutOfViewLeft(scrollAreaRef.current!);
    outOfViewChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  return (
    <Styles.Wrapper {...rest} ref={wrapperRef}>
      {showButtons && (
        <ButtonIcon onClick={scrollLeft} disabled={leftDisabled}>
          <ChevronLeftIcon />
        </ButtonIcon>
      )}
      <Styles.ScrollArea ref={scrollAreaRef} onScroll={() => handleScroll(scrollAreaRef)}>
        {children}
      </Styles.ScrollArea>
      {showButtons && (
        <ButtonIcon onClick={scrollRight} disabled={rightDisabled}>
          <ChevronRightIcon />
        </ButtonIcon>
      )}
    </Styles.Wrapper>
  );
};
