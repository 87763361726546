import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { DocumentsTableOverview } from './DocumentsTableOverview';

export const DocumentsTablePage = () => {
  return (
    <Page>
      <PageHeader>
        <Heading>Portfolio Library</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/documents" title="Documents" />
          <BreadcrumbItem title="Portfolio Library" />
        </Breadcrumb>
      </PageHeader>

      <PageContent $hasFlex>
        <DocumentsTableOverview />
      </PageContent>
    </Page>
  );
};
