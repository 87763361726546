import { GetNotificationsCountForUserResponse, Message, MessageType } from '@plus-platform/shared';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useSocketContext } from '../../contexts/SocketContext';
import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { createURLSearchParams } from '../../utils/queryUtils';
import { QueryKeys } from './types';

const getUserNotificationsCount = (query?: URLSearchParams) => {
  const url = makeApiUrl(`user/notifications/count`, query);
  return getRequestWithAuth<GetNotificationsCountForUserResponse>(url);
};

type GetNotificationsCountForUserQuery = {
  types?: string[];
  counterpartyId?: number;
  tradeRequestId?: number;
};

export const useNotificationsCountQuery = (props: GetNotificationsCountForUserQuery = {}) => {
  const { subscribe, unsubscribe } = useSocketContext();
  const queryClient = useQueryClient();
  const params = createURLSearchParams(props);
  const serializedParams = params.toString();
  const { data, isLoading } = useQuery([QueryKeys.USER_NOTIFICATIONS_COUNT, serializedParams], () =>
    getUserNotificationsCount(params)
  );

  useEffect(() => {
    const handleNotificationCountMessage = (message: Message) => {
      if (message.type !== MessageType.NotificationsCountMessage) {
        return;
      }

      const { params: messageProps } = message.payload;
      // the websocket message isn't for the current query

      if (!isEqual(props, messageProps)) {
        return;
      }

      queryClient.invalidateQueries(QueryKeys.USER_NOTIFICATIONS_COUNT);
    };

    subscribe(handleNotificationCountMessage);
  }, [subscribe, unsubscribe, queryClient, props]);

  return { data, isLoading };
};
