import { ButtonIcon } from './ButtonIcon';
import * as Styles from './DocumentCard.styles';
import { CloseLineIcon } from './icons';
import { PDFIconBadge } from './PDFIconBadge';

type DocumentCardProps = {
  documentName: string;
  documentType?: string;
  onRemove?: () => void;
};

export enum CardDocumentType {
  PDF = 'PDF document',
  Word = 'Word document',
  Excel = 'Excel document',
  Generic = 'Generic document',
}

export const DocumentCard = ({
  documentName,
  documentType = CardDocumentType.PDF,
  onRemove,
}: DocumentCardProps) => (
  <Styles.Wrapper>
    {/* TODO: Add icons for document types as stand-alone component to also be used in DocumentPreview.tsx*/}
    <PDFIconBadge />
    <Styles.TextWrapper>
      <Styles.NameText>{documentName}</Styles.NameText>
      <Styles.DocTypeText>{documentType}</Styles.DocTypeText>
    </Styles.TextWrapper>
    <Styles.CloseIconWrapper>
      {onRemove && (
        <ButtonIcon size="small" onClick={onRemove}>
          <CloseLineIcon />
        </ButtonIcon>
      )}
    </Styles.CloseIconWrapper>
  </Styles.Wrapper>
);
