import { rgba } from 'polished';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { baseButtonStyles } from '../../components/Button.styles';

type ToggleStyleProps = {
  $isActive: boolean;
  $hasSrc: boolean;
};

type styleProps = ThemeProps<DefaultTheme>;

const activeStyles = ({ theme }: styleProps) => css`
  box-shadow: 0 0 0 1px ${theme.colors.accent01};
`;

export const Toggle = styled.button<ToggleStyleProps>(
  ({ $hasSrc, $isActive, theme }) => css`
    ${baseButtonStyles}

    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    background: ${$hasSrc ? 'transparent' : theme.colors.white};

    &:hover,
    &:focus-within {
      box-shadow: 0 0 0 1px ${rgba(theme.colors.accent01, 0.15)};

      ${$isActive && activeStyles}
    }

    &:active {
      box-shadow: 0 0 0 1px ${rgba(theme.colors.accent01, 0.3)};

      ${$isActive && activeStyles}
    }

    ${$isActive && activeStyles}
  `
);
