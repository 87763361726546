import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../../types';

enum AppraisalReviewCategoryAccuracyType {
  Accurate = 'Accurate',
  NotAccurate = 'NotAccurate',
  NotApplicable = 'NotApplicable',
  NotDeveloped = 'NotDeveloped',
}

enum AppraisalReviewCategoryType {
  ComparableRentals = 'ComparableRentals',
  ComparablesSimilar = 'ComparablesSimilar',
  Contract = 'Contract',
  CostAndIncome = 'CostAndIncome',
  DataAnalysis = 'DataAnalysis',
  Improvements = 'Improvements',
  MarketRent = 'MarketRent',
  Neighborhood = 'Neighborhood',
  Other = 'Other',
  RentSchedule = 'RentSchedule',
  Site = 'Site',
  Subject = 'Subject',
  TransferHistory = 'TransferHistory',
  ValueIndicators = 'ValueIndicators',
}

type ValuationReviewCategory = MISMOEntity &
  Partial<{
    appraisalReviewCategoryAccuracyType: AppraisalReviewCategoryAccuracyType;
    appraisalReviewCategoryCommentDescription: MISMOString;
    appraisalReviewCategoryType: AppraisalReviewCategoryType;
    appraisalReviewCategoryTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type ValuationReview = MISMOEntity &
  Partial<{
    appraisalIdentifier: MISMOIdentifier;
    appraisalReviewDisagreementDescription: MISMOString;
    appraisalReviewExtraordinaryAssumptionsDescription: MISMOString;
    appraisalReviewImprovementsCompletedIndicator: MISMOIndicator;
    appraisalReviewMarketValueChangeCommentDescription: MISMOString;
    appraisalReviewOriginalAppraisalEffectiveDate: MISMODate;
    appraisalReviewOriginalAppraisedValueAccurateIndicator: MISMOIndicator;
    appraisalReviewOriginalAppraisedValueAmount: MISMOAmount;
    appraisalReviewValueConclusionCommentDescription: MISMOString;
    propertyMarketValueDecreasedIndicator: MISMOIndicator;
    valuationReviewCategories: ValuationReviewCategory[];
  }>;
