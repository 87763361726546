import React from 'react';

export const FeedbackIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9997 13.9775C12.1632 13.9775 12.3004 13.9222 12.4113 13.8116C12.5221 13.7009 12.5775 13.5638 12.5775 13.4003C12.5775 13.2368 12.5222 13.0996 12.4116 12.9888C12.3009 12.8779 12.1638 12.8225 12.0003 12.8225C11.8368 12.8225 11.6996 12.8778 11.5888 12.9884C11.4779 13.0991 11.4225 13.2362 11.4225 13.3997C11.4225 13.5632 11.4778 13.7004 11.5884 13.8113C11.6991 13.9221 11.8362 13.9775 11.9997 13.9775ZM11.475 11.4925H12.525V7.1H11.475V11.4925ZM5 19V6.05C5 5.77 5.105 5.525 5.315 5.315C5.525 5.105 5.77 5 6.05 5H17.95C18.23 5 18.475 5.105 18.685 5.315C18.895 5.525 19 5.77 19 6.05V15.15C19 15.43 18.895 15.675 18.685 15.885C18.475 16.095 18.23 16.2 17.95 16.2H7.8L5 19ZM7.345 15.15H17.95V6.05H6.05V16.55L7.345 15.15Z"
        fill="currentColor"
      />
    </svg>
  );
};
