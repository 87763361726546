import { PartialOrNull } from '../utils';
import { ADCOAnalysisStatus, ADCOExecutionStatus } from './loans';

export enum ADCOAssetType {
  Servicing = 'Servicing',
  AutoLoans = 'AutoLoans',
  StructuredSecurities = 'StructuredSecurities',
  SingleFamilyLoans = 'SingleFamilyLoans',
  MultiFamilyLoans = 'MultiFamilyLoans',
  Guarantees = 'Guarantees',
  AgencyPools = 'AgencyPools',
}

export enum ADCOPositionType {
  AutoLoanPosition = 'AutoLoanPosition',
  MortgageLoanPosition = 'MortgageLoanPosition',
  MortgagePoolPosition = 'MortgagePoolPosition',
  ServicingLoanPosition = 'ServicingLoanPosition',
  ServicingPoolPosition = 'ServicingPoolPosition',
  MultiFamilyLoanPosition = 'MultiFamilyLoanPosition',
}

export type ADCORateFixed = 0 | 1;

export enum ADCOSwaptionTerm {
  '3M' = '3M',
  '6M' = '6M',
  '1Y' = '1Y',
  '2Y' = '2Y',
  '3Y' = '3Y',
  '5Y' = '5Y',
  '7Y' = '7Y',
  '10Y' = '10Y',
}

export enum ADCOYieldCurveTerm {
  '1M' = '1M',
  '3M' = '3M',
  '6M' = '6M',
  '1Y' = '1Y',
  '2Y' = '2Y',
  '3Y' = '3Y',
  '4Y' = '4Y',
  '5Y' = '5Y',
  '7Y' = '7Y',
  '10Y' = '10Y',
  '15Y' = '15Y',
  '20Y' = '20Y',
  '25Y' = '25Y',
  '30Y' = '30Y',
}

export enum ADCORunType {
  LoanDynamics = 'LoanDynamics',
  RiskValDynamics = 'RiskValDynamics',
}

export enum ADCOSupportedIndices {
  FN30 = 'FN30',
  FN15 = 'FN15',
  FN07 = 'FN07',
  FG30 = 'FG30',
  FG15 = 'FG15',
  FG07 = 'FG07',
  FG05 = 'FG05',
  GN30 = 'GN30',
  GN15 = 'GN15',
  LIB6MC = 'LIB6MC',
  LIB1YC = 'LIB1YC',
  COFINA = 'COFINA',
  COFI = 'COFI',
  PRIME = 'PRIME',
  SOFR = 'SOFR',
  SOFR30A = 'SOFR30A',
  SOFR90A = 'SOFR90A',
  SOFR180A = 'SOFR180A',
}

export enum ADCOPositionFormat {
  Loan = 'Loan',
  Pool = 'Pool',
}

export type ADCOFieldConfigMetadata = PartialOrNull<{
  enableInterestRateRisk: boolean;
  enableModelRisk: boolean;
  enableExternalHpaAndUnemployment: boolean;
}>;

export enum ADCOSettingsType {
  Ldm = 'Ldm',
  Lk = 'Lk',
}

export type ADCOAccessTokenResponse = {
  jwtToken: string;
  refreshToken: string;
};

export type ADCOAnalysisSettings = PartialOrNull<{
  settingsType: ADCOSettingsType;
  modelDataDate: string;
  fieldConfigMetaData?: ADCOFieldConfigMetadata;
}>;

export type ADCOAnalysis = PartialOrNull<{
  id: number;
  name: string;
  runType: string;
  assetType: string;
  positionFormat: string;
  createDate: string;
  modifiedDate: string;
  lastRunDate: string;
  lastCompletionDate: string;
  executionStatus: ADCOExecutionStatus;
  status?: ADCOAnalysisStatus;
  settings?: ADCOAnalysisSettings;
}>;

export type ADCOAnalysisExecution = PartialOrNull<{
  executionStatus: ADCOExecutionStatus;
  errorMessage: string;
  itemsProcessed: number;
  totalItems: number;
  currentStep: number;
  totalSteps: number;
  stepDesc: string;
  hasErrorRecords: boolean;
}>;

export enum ADCOResultCollectionType {
  ValueAndRisk = 'ValueAndRisk',
  ScenarioGrid = 'ScenarioGrid',
  ScenarioGridReprice = 'ScenarioGridReprice',
  StressTest = 'StressTest',
  StressTestReprice = 'StressTestReprice',
  Errors = 'Errors',
  LdmResults = 'LdmResults',
  LdmAggregateResults = 'LdmAggregateResults',
  CashFlow = 'CashFlow',
}

export type ADCOAnalysisResults = PartialOrNull<{
  cashFlowType: string;
  cprCc: string;
  marketValue: string;
  currentBalance: string;
  price: string;
  wal: string;
  walCc: string;
  walFc: string;
  yieldCc: string;
  yieldFc: string;
  vega: string;
  ltvSens: string;
  oas: string;
  oasDur: string;
  cumLoss?: number;
  expShortfall?: number;
}>;

export type ADCOPortfolioData = {
  age: number;
  originalTerm: number;
  positionType: ADCOPositionType;
  rateFixed: ADCORateFixed;
  remainingTerm: number;
};

export type ADCOCouponYieldData = PartialOrNull<{
  index: ADCOSupportedIndices;
  rate: number;
}>;

export type ADCOYieldCurveData = PartialOrNull<{
  libor: number;
  sofr: number;
  term: ADCOYieldCurveTerm;
  treasury: number;
}>;

export type ADCOSwaptionVolatilitiesData = PartialOrNull<{
  term: ADCOSwaptionTerm;
  vol_10Y: number;
  vol_1Y: number;
  vol_2Y: number;
}>;
