import React from 'react';

export const SearchFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7556 20L13.1556 14.4C12.7111 14.7556 12.2 15.037 11.6222 15.2444C11.0444 15.4519 10.4296 15.5556 9.77778 15.5556C8.16296 15.5556 6.7963 14.9963 5.67778 13.8778C4.55926 12.7593 4 11.3926 4 9.77778C4 8.16296 4.55926 6.7963 5.67778 5.67778C6.7963 4.55926 8.16296 4 9.77778 4C11.3926 4 12.7593 4.55926 13.8778 5.67778C14.9963 6.7963 15.5556 8.16296 15.5556 9.77778C15.5556 10.4296 15.4519 11.0444 15.2444 11.6222C15.037 12.2 14.7556 12.7111 14.4 13.1556L20 18.7556L18.7556 20ZM9.77778 13.7778C10.8889 13.7778 11.8333 13.3889 12.6111 12.6111C13.3889 11.8333 13.7778 10.8889 13.7778 9.77778C13.7778 8.66667 13.3889 7.72222 12.6111 6.94444C11.8333 6.16667 10.8889 5.77778 9.77778 5.77778C8.66667 5.77778 7.72222 6.16667 6.94444 6.94444C6.16667 7.72222 5.77778 8.66667 5.77778 9.77778C5.77778 10.8889 6.16667 11.8333 6.94444 12.6111C7.72222 13.3889 8.66667 13.7778 9.77778 13.7778Z"
        fill="currentColor"
      />
    </svg>
  );
};
