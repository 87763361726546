import styled from 'styled-components/macro';

export const PropertyPhoto = styled.img`
  vertical-align: middle;
  width: 100%;
`;

export const Description = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const DescriptionName = styled.dt`
  width: 50%;
  padding: 2px 15px 2px 0;
  text-align: right;
  color: ${({ theme }) => theme.colors.greyMain};
`;

export const DescriptionValue = styled.dt`
  padding: 2px 0;
  width: 50%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.greyDark};
`;
