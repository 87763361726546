import {
  TradeQuoteType,
  TradeRequestMessageCreationMetadataQuote,
  TradeRequestWithSummary,
} from '@plus-platform/shared';
import React from 'react';

import { Button } from '../../../../components/Button';
import { ButtonLoader } from '../../../../components/ButtonLoader';
import { Divider } from '../../../../components/Divider';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { DropzoneProvider } from '../../../../contexts/DropzoneContext';
import {
  useAcceptTradeRequestQuoteCounterMutation,
  useCreateTradeRequestQuoteCounterMutation,
  useRejectTradeRequestQuoteCounterMutation,
} from '../../../../hooks/queries';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { QuoteForm } from '../QuoteForm';

enum View {
  Initial,
  Accept,
  Reject,
  Counter,
}

type InboundQuoteAcceptRejectCounterProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: TradeRequestWithSummary;
  onCounterAccept: () => void;
  onCounterAcceptConfirm: () => void;
  onCounterAcceptCancel: () => void;
  onCounterBack: () => void;
  onCounterReject: () => void;
  onCounterRejectCancel: () => void;
  onCounterRejectConfirm: () => void;
  onCounterBackCancel: () => void;
  onCounterCounter: () => void;
};

export const InboundQuoteAcceptRejectCounter = (props: InboundQuoteAcceptRejectCounterProps) => {
  const {
    onCounterAccept,
    onCounterAcceptCancel,
    onCounterAcceptConfirm,
    onCounterBack,
    onCounterBackCancel,
    onCounterCounter,
    onCounterReject,
    onCounterRejectCancel,
    onCounterRejectConfirm,
    quote,
    tradeRequest,
  } = props;
  const { fromCounterparty } = quote;

  const [view, setView] = React.useState(View.Initial);

  const {
    isLoading: isAcceptingCounter,
    isSuccess: hasAcceptedCounter,
    mutateAsync: acceptCounter,
  } = useAcceptTradeRequestQuoteCounterMutation();

  const {
    isLoading: isRejectingCounter,
    isSuccess: hasRejectedCounter,
    mutateAsync: rejectCounter,
  } = useRejectTradeRequestQuoteCounterMutation();

  const {
    isLoading: isCountering,
    isSuccess: hasCountered,
    mutateAsync: counterCounter,
  } = useCreateTradeRequestQuoteCounterMutation();

  const canActOnCounter =
    !isAcceptingCounter &&
    !hasAcceptedCounter &&
    !isRejectingCounter &&
    !hasRejectedCounter &&
    !isCountering &&
    !hasCountered;

  const author =
    quote.type === TradeQuoteType.SELLER_TO_BUYER
      ? {
          user: tradeRequest.user,
          organization: tradeRequest.user.organization,
        }
      : {
          user: fromCounterparty.user,
          organization: fromCounterparty.user.organization,
        };

  return (
    <React.Fragment>
      {view === View.Initial && (
        <PlusMessage>
          <MessageText>
            <TradeMessage
              id="Trade.Inbound.Quote.Counter.Received"
              values={{
                userName: formatNameWithInitial(author.user.lastName, author.user.firstName),
                linkedQuoteId: quote.linkedQuoteId!,
              }}
            />{' '}
            What do you want to do now?
          </MessageText>
          <MessageActions>
            <ButtonLoader
              $size="large"
              disabled={!canActOnCounter}
              onClick={() => {
                onCounterAccept?.();
                setView(View.Accept);
              }}
            >
              Accept counter
            </ButtonLoader>
            <Button
              $size="large"
              $variant="outlined"
              disabled={!canActOnCounter}
              onClick={() => {
                onCounterBack?.();
                setView(View.Counter);
              }}
            >
              Counter back
            </Button>
            <Divider $flexItem $orientation="vertical" />
            <Button
              $color="danger"
              $size="large"
              $variant="outlined"
              disabled={!canActOnCounter}
              onClick={() => {
                onCounterReject?.();
                setView(View.Reject);
              }}
            >
              Reject without counter
            </Button>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.Accept && (
        <PlusMessage>
          <MessageText>
            <TradeMessage
              id="Trade.Inbound.Quote.Counter.Received"
              values={{
                userName: formatNameWithInitial(author.user.lastName, author.user.firstName),
                linkedQuoteId: quote.linkedQuoteId!,
              }}
            />{' '}
            What do you want to do now?
          </MessageText>
          <MessageText>
            You've chosen to accept the counter from{' '}
            <strong>{formatNameWithInitial(author.user.lastName, author.user.firstName)}</strong>{' '}
            Are you sure to want to proceed?
          </MessageText>
          <MessageActions>
            <ButtonLoader
              $size="large"
              disabled={!canActOnCounter}
              isLoading={isAcceptingCounter}
              onClick={async () => {
                await acceptCounter({
                  tradeRequestId: tradeRequest.id,
                  tradeQuoteCounterId: quote.id,
                });
                onCounterAcceptConfirm?.();
              }}
            >
              Proceed
            </ButtonLoader>
            <Button
              $color="tertiary"
              $size="large"
              $variant="outlined"
              disabled={!canActOnCounter}
              onClick={() => {
                setView(View.Initial);
                onCounterAcceptCancel?.();
              }}
            >
              Cancel
            </Button>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.Reject && (
        <PlusMessage>
          <MessageText>
            <TradeMessage
              id="Trade.Inbound.Quote.Counter.Received"
              values={{
                userName: formatNameWithInitial(author.user.lastName, author.user.firstName),
                linkedQuoteId: quote.linkedQuoteId!,
              }}
            />{' '}
            What do you want to do now?
          </MessageText>
          <MessageText>
            You've chosen to reject without countering the counter from{' '}
            {formatNameWithInitial(author.user.lastName, author.user.firstName)} Are you sure to
            want to proceed?
          </MessageText>
          <MessageActions>
            <ButtonLoader
              $size="large"
              isLoading={isRejectingCounter}
              onClick={async () => {
                await rejectCounter({
                  tradeRequestId: tradeRequest.id,
                  tradeQuoteCounterId: quote.id,
                });
                onCounterRejectConfirm?.();
              }}
            >
              Proceed
            </ButtonLoader>
            <Button
              $color="tertiary"
              $size="large"
              $variant="outlined"
              disabled={!canActOnCounter}
              onClick={() => {
                setView(View.Initial);
                onCounterRejectCancel?.();
              }}
            >
              Cancel
            </Button>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.Counter && (
        <DropzoneProvider accept="application/pdf" multiple={false} noClick>
          {({ getRootProps }) => (
            <div {...getRootProps()}>
              <PlusMessage>
                <MessageText>
                  <TradeMessage
                    id="Trade.Inbound.Quote.Counter.Received"
                    values={{
                      userName: formatNameWithInitial(author.user.lastName, author.user.firstName),
                      linkedQuoteId: quote.linkedQuoteId!,
                    }}
                  />{' '}
                  What do you want to do now?
                </MessageText>
                <MessageText>OK, you've chosen to counter</MessageText>
                <QuoteForm
                  isCounter
                  isSubmitting={isCountering}
                  onCancel={() => {
                    setView(View.Initial);
                    onCounterBackCancel?.();
                  }}
                  onSubmit={async (values) => {
                    await counterCounter({
                      stipulation: values.stipulation,
                      reason: values.reason,
                      value: values.pricePercentage / 100,
                      tradeRequestId: tradeRequest.id,
                      quoteId: quote.id,
                    });
                    onCounterCounter?.();
                  }}
                />
              </PlusMessage>
            </div>
          )}
        </DropzoneProvider>
      )}
    </React.Fragment>
  );
};
