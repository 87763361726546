import styled from 'styled-components/macro';

export const AdditionalSection = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const PhotoWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.white05};
`;

export const TransportWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
`;
