import styled, { css } from 'styled-components/macro';

const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText.bodyRegular03}
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  margin: 2px 0;
`;

export const ContentLabel = styled(Text)(
  ({ theme }) => css`
    text-align: right;
    width: 100%;
    color: ${theme.colors.textPrimary01};
    margin: 0;
    margin-right: 8px;
  `
);

export const Value = styled(Text)`
  font-weight: 500;
`;

const ActiveContentMixin = css`
  background: ${(props) => props.theme.colors.white15};
  border-radius: 4px;

  ${ContentLabel}, ${Value} {
    color: ${(props) => props.theme.colors.accent01};
  }
`;

export const Content = styled.div<{ $isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 0 8px;
  align-items: center;

  ${ContentLabel} {
    color: ${(props) => props.theme.colors.white70};
  }

  ${(props) => props.$isActive && ActiveContentMixin}

  &:hover {
    ${ActiveContentMixin}
  }
`;
