import {
  OutboundTradeCounterpartyActionWithSummary,
  TradeCounterpartyActionHistoryFilter,
} from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import { InfiniteData, useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

const NUMBER_OF_ITEMS_PER_PAGE = 20;
// TODO: Implement trade wide polling
// const POLLING_DELAY = 10000;

export type TradeRequestOutboundActionsHistoryResponse = {
  actions: OutboundTradeCounterpartyActionWithSummary[];
  totalCount: number;
};

export const useTradeRequestOutboundActionsHistoryQuery = (
  tradeRequestId: string,
  filter: TradeCounterpartyActionHistoryFilter,
  onSuccess?: (data: InfiniteData<TradeRequestOutboundActionsHistoryResponse | undefined>) => void
) => {
  return useInfiniteQuery(
    [QueryKeys.TRADE_REQUEST_OUTBOUND_ACTION_HISTORY, tradeRequestId, filter],
    ({ pageParam = 0 }) => {
      const query = new URLSearchParams({
        limit: String(NUMBER_OF_ITEMS_PER_PAGE),
        offset: String(pageParam),
      });
      const url = makeApiUrl(
        `trade-requests/${tradeRequestId}/outbound/history/${filter}?${query}`
      );

      return getRequestWithAuth<TradeRequestOutboundActionsHistoryResponse>(url);
    },
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      getNextPageParam(lastPage, pages) {
        return lastPage && lastPage.actions.length === NUMBER_OF_ITEMS_PER_PAGE
          ? sumBy(pages, 'actions.length')
          : undefined;
      },
      // TODO: Implement trade wide polling
      // refetchInterval: POLLING_DELAY,
    }
  );
};
