import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMODay,
  MISMOEntity,
  MISMOIndicator,
  MISMOMonth,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum CurtailmentApplicationSequenceType {
  CurtailmentFirst = 'CurtailmentFirst',
  CurtailmentLast = 'CurtailmentLast',
}

enum PaymentBillingMethodType {
  BillOnReceipt = 'BillOnReceipt',
  Coupons = 'Coupons',
  NoBilling = 'NoBilling',
  Statement = 'Statement',
  StatementAndCoupon = 'StatementAndCoupon',
}

enum PaymentBillingStatementFrequencyType {
  Annual = 'Annual',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Semiannual = 'Semiannual',
}

export enum PaymentFrequencyType {
  Annual = 'Annual',
  AtMaturity = 'AtMaturity',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly',
  Other = 'Other',
  Quarterly = 'Quarterly',
  Semiannual = 'Semiannual',
  Semimonthly = 'Semimonthly',
  Weekly = 'Weekly',
}

enum PaymentStateType {
  Current = 'Current',
  Other = 'Other',
  Pending = 'Pending',
  Previous = 'Previous',
}

enum AdditionalChargeType {
  LatePayment = 'LatePayment',
  Other = 'Other',
  PrepaymentPenalty = 'PrepaymentPenalty',
  RealizedLossDueToForeclosedREOPropertyLiquidation = 'RealizedLossDueToForeclosedREOPropertyLiquidation',
  RealizedLossDueToLoanModification = 'RealizedLossDueToLoanModification',
  SkipPayment = 'SkipPayment',
}

type AdditionalCharge = MISMOEntity &
  Partial<{
    additionalChargeAmount: MISMOAmount;
    additionalChargeDate: MISMODate;
    additionalChargeType: AdditionalChargeType;
    additionalChargeTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

type PaymentComponentBreakout = MISMOEntity &
  Partial<{
    buydownSubsidyPaymentAmount: MISMOAmount;
    buydownSubsidyPaymentEffectiveDate: MISMODate;
    escrowShortagePaymentAmount: MISMOAmount;
    homeownersAssociationDuesEffectiveDate: MISMODate;
    homeownersAssociationDuesPaymentAmount: MISMOAmount;
    HUD235SubsidyPaymentAmount: MISMOAmount;
    HUD235SubsidyPaymentEffectiveDate: MISMODate;
    insurancePaymentAmount: MISMOAmount;
    interestPaymentAmount: MISMOAmount;
    paymentStateType: PaymentStateType;
    paymentStateTypeOtherDescription: MISMOString;
    principalAndInterestPaymentAmount: MISMOAmount;
    principalAndInterestPaymentEffectiveDate: MISMODate;
    principalPaymentAmount: MISMOAmount;
    SCRASubsidyPaymentAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
    taxAndInsurancePaymentAmount: MISMOAmount;
    taxAndInsurancePaymentEffectiveDate: MISMODate;
    taxesPaymentAmount: MISMOAmount;
    totalEscrowPaymentAmount: MISMOAmount;
    totalOptionalProductsPaymentAmount: MISMOAmount;
    totalOptionalProductsPaymentEffectiveDate: MISMODate;
    totalPaymentAmount: MISMOAmount;
    additionalCharges: AdditionalCharge[];
  }>;

export type PaymentRule = MISMOEntity &
  Partial<{
    curtailmentApplicationSequenceType: CurtailmentApplicationSequenceType;
    finalPaymentAmount: MISMOAmount;
    firstPrincipalReductionDate: MISMODate;
    fullyIndexedInitialPrincipalAndInterestPaymentAmount: MISMOAmount;
    initialInterestOnlyPaymentAmount: MISMOAmount;
    initialPaymentRatePercent: MISMOPercent;
    initialPITIPaymentAmount: MISMOAmount;
    initialPrincipalAndInterestAndMIMonthlyPaymentAmount: MISMOAmount;
    initialPrincipalAndInterestPaymentAmount: MISMOAmount;
    initialTaxAndInsurancePaymentAmount: MISMOAmount;
    loanPaymentAccumulationAmount: MISMOAmount;
    partialPaymentAllowedIndicator: MISMOIndicator;
    partialPaymentPenaltyIndicator: MISMOIndicator;
    paymentBillingMethodType: PaymentBillingMethodType;
    paymentBillingStatementFrequencyType: PaymentBillingStatementFrequencyType;
    paymentBillingStatementLeadDaysCount: MISMOCount;
    paymentFrequencyType: PaymentFrequencyType;
    paymentFrequencyTypeOtherDescription: MISMOString;
    paymentOptionIndicator: MISMOIndicator;
    paymentRemittanceDay: MISMODay;
    scheduledAnnualPaymentCount: MISMOCount;
    scheduledFirstPaymentDate: MISMODate;
    scheduledTotalPaymentCount: MISMOCount;
    seasonalPaymentPeriodEndMonth: MISMOMonth;
    seasonalPaymentPeriodStartMonth: MISMOMonth;
    thirdPartyInvestorFeePassThroughPercent: MISMOPercent;
    thirdPartyInvestorInterestPassThroughPercent: MISMOPercent;
    thirdPartyInvestorPrincipalPassThroughPercent: MISMOPercent;
    totalOptionalPaymentCount: MISMOCount;
  }>;

export type PaymentSummary = MISMOEntity &
  Partial<{
    aggregateLoanCurtailmentAmount: MISMOAmount;
    defaultedFirstPaymentIndicator: MISMOIndicator;
    interestBearingUPBAmount: MISMOAmount;
    interestPaidThroughDate: MISMODate;
    lastPaidInstallmentDueDate: MISMODate;
    lastPaymentReceivedDate: MISMODate;
    lifeOfLoanTotalInterestPaidAmount: MISMOAmount;
    lifeOfLoanTotalMiscellaneousChargesAmount: MISMOAmount;
    nextPaymentDueDate: MISMODate;
    nonInterestBearingExpenseAmount: MISMOAmount;
    nonInterestBearingInterestAmount: MISMOAmount;
    nonInterestBearingUPBAmount: MISMOAmount;
    paymentHistoryPatternText: MISMOString;
    postPetitionNextPaymentDueDate: MISMODate;
    scheduledUPBAmount: MISMOAmount;
    servicingTerminationUPBAmount: MISMOAmount;
    totalPITIMonthsCount: MISMOCount;
    totalPITIReceivedAmount: MISMOAmount;
    UPBAmount: MISMOAmount;
  }>;

export type Payment = MISMOEntity &
  Partial<{
    // arrearage: Arrearage;
    // loanYearlyPaymentSummaries: LoanYearlyPaymentSummary[];
    // partialPayments: PartialPayment[];
    paymentComponentBreakouts: PaymentComponentBreakout[];
    paymentRule: PaymentRule;
    // paymentScheduleItems: PaymentScheduleItem[];
    paymentSummary: PaymentSummary;
    // periodicLateCounts: PeriodicLateCount[];
    // skipPayment: SkipPayment;
  }>;
