import React from 'react';

export const TradeLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 20L4 15.5556L8 11.1111L9.14 12.3556L7.06 14.6667H19.2V16.4444H7.06L9.14 18.7556L8 20ZM16 12.8889L14.86 11.6444L16.94 9.33333H4.8V7.55556H16.94L14.86 5.24444L16 4L20 8.44444L16 12.8889Z"
        fill="currentColor"
      />
    </svg>
  );
};
