import { Loan } from '@plus-platform/shared';
import React, { useMemo } from 'react';

import { Card } from '../../../components/Card';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Key, Pair, TitleKey, Value } from '../../../components/KeyValue';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import { formatPoolSaleDate } from '../../../trades/tradeRequestsUtils';
import { EMPTY_VALUE, formatDefaultValue } from '../../../utils/formatUtils';
import * as Styles from '../LoanDetail.styles';

type LoanDetailsAquisitionHistoryProps = {
  loan: Loan;
};

const LoanDetailAcquisitionHistory = React.forwardRef<
  HTMLDivElement,
  LoanDetailsAquisitionHistoryProps
>(({ loan }, ref) => {
  const formattedPoolSaleDate = useMemo(
    () =>
      formatPoolSaleDate(
        // TODO: Determine trade status based on counterparties
        // loan.pool?.tradeRequest?.status,
        undefined,
        loan.pool?.tradeRequest?.updatedAt
      ),
    [loan]
  );

  return (
    <Card
      $hasPadding
      id="acquisition-history"
      ref={ref}
      data-testid="LoanDetail_AcquisitionHistory"
    >
      <Heading $size="medium">Acquisition history</Heading>
      <WidgetSectionWrapper>
        <WidgetSection>
          <Styles.AdditionalSection>
            <Heading $size="small">Current servicer</Heading>
            <TitleKey>Company details</TitleKey>
            <Pair $size="large">
              <Key>Pool Number</Key>
              <Value>{formatDefaultValue(loan.pool?.id)}</Value>
            </Pair>

            <Pair $size="large">
              <Key>Pool Type</Key>
              {/* TODO: Add type on Pool structure once defined and return it from backend */}
              <Value>{EMPTY_VALUE}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Deal Identifier</Key>
              <Value>{formatDefaultValue(loan.pool?.tradeRequest?.id)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Reporting</Key>
              <Value>{formatDefaultValue(loan?.servicer?.servicer?.reporting)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Sale Date</Key>
              <Value>{formattedPoolSaleDate}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Status</Key>
              <Value>
                {loan.pool ? formatDefaultValue(loan?.servicer?.servicer?.status) : EMPTY_VALUE}
              </Value>
            </Pair>
            <Pair $size="large">
              <Key>Loans</Key>
              <Value>{formatDefaultValue(loan.pool?.summary?.loansCount)}</Value>
            </Pair>
          </Styles.AdditionalSection>
        </WidgetSection>

        <Divider $flexItem $orientation="vertical" />

        <WidgetSection>
          <Styles.AdditionalSection>
            <Heading $size="small">Previous servicer</Heading>
            <TitleKey>Company details</TitleKey>
            <Pair $size="large">
              <Key>Company Name</Key>
              <Value>{formatDefaultValue(loan.loanDetails.companyName)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Company Identifier</Key>
              <Value>{formatDefaultValue(loan.loanDetails.companyIdentifier)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Company Address</Key>
              <Value>{formatDefaultValue(loan.loanDetails.companyAddress)}</Value>
            </Pair>
          </Styles.AdditionalSection>
        </WidgetSection>

        <WidgetSection></WidgetSection>
      </WidgetSectionWrapper>
    </Card>
  );
});

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailAcquisitionHistory;
