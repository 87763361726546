import { Coordinates, ForeclosureCreation, LoanDelinquencyStatus, MISMO } from '.';
import { Identifier } from './global';

type PropertyLot = {
  size?: number;
  sizeArea?: number;
  description?: string;
};

type PropertyOccupant = {
  name: string;
  phoneNumber: string;
  type: string;
};

type PropertyListingAgent = {
  name: string;
  phoneNumber: string;
};

type PropertyListing = {
  id: string;
  price?: number;
  originalListPrice?: number;
  officeName: string;
  agent: PropertyListingAgent;
  listingDate?: Date;
};

type PropertyClosing = {
  closingDate?: Date;
  price?: number;
};

export type PropertyMedia = {
  id: string;
  url: string;
  thumbnailUrl: string;
  description: string;
};

type PropertySpecifications = {
  bedroomsCount?: number;
  bathroomsCount?: number;
  finishedArea?: {
    aboveGrade: string;
    belowGrade: string;
  };
  lot: PropertyLot;
};

export type PropertyScores = {
  walk?: {
    score?: number;
    description: string;
  };
  transit?: {
    score?: number;
    description: string;
  };
  bike?: {
    score?: number;
    description: string;
  };
};

export enum NearbyPropertyType {
  RENTAL = 'RENTAL',
  FOR_SALE = 'FOR_SALE',
}

export type NearbyPropertyDetails = {
  propertyType: NearbyPropertyType;
  id: string;
  title: string;
  geolocation?: Coordinates;
  address: string;
  type: string;
  subType: string;
  yearBuilt?: number;
  onMarketDate?: Date;
  daysOnMarket?: number;
  specifications: PropertySpecifications;
  occupant?: PropertyOccupant;
  listing: PropertyListing;
  closing: PropertyClosing;
  media: PropertyMedia[];
};

export enum PropertyComparableSaleType {
  SUBJECT_PROPERTY = 'SUBJECT_PROPERTY',
  OTHER = 'OTHER',
}

export type PropertyComparableSaleCreation = {
  propertyId: Property['id'];
  address?: string;
  salePrice: number;
  type: PropertyComparableSaleType;
};

export type PropertyComparableSale = PropertyComparableSaleCreation & {
  id: Identifier;
};

export type PropertyCreation = PropertyBasement &
  PropertyRoof &
  PropertyNeighborhood &
  ForeclosureCreation & {
    purchasePrice?: number;
    currentBpo?: number;
    currentBpoDate?: Date;
    downPaymentAmount?: number;
    fullAddress: string;
    addressLine1: string;
    city: string;
    state: string;
    zipCode: string;
    county?: string;
    zoningDescription?: string;
    propertyType: string;
    propertyOccupancyType?: MISMO.PropertyCurrentOccupancyType;
    propertyEstateType?: MISMO.PropertyEstateType;
    numberOfUnits?: number;
    attachmentType?: MISMO.AttachmentType;
    description?: string;
    yearBuilt?: number;
    geolocationLatitude?: number;
    geolocationLongitude?: number;
    numberOfBedrooms?: number;
    numberOfBathrooms?: number;
    numberOfStories?: number;
    totalLivingArea?: number;
    foundationWallType?: string;
    exteriorWallType?: string;
    interiorFloorsDescription?: string;
    interiorWallsDescription?: string;
    trimFinishDescription?: string;
    bathFloorDescription?: string;
    isBasementOutsideEntry?: boolean;
    hasInfestation?: boolean;
    hasDamp?: boolean;
    hasSettlement?: boolean;
    hasSumpPump?: boolean;
    floorType?: string;
    wallType?: string;
    hasGarage?: boolean;
    hasDriveway?: boolean;
    drivewaySurface?: string;
    spaceForCars?: number;
    hasGuttersAndDownspouts?: boolean;
    foundationType?: string;
    atticFeatures?: string[];
    heatingTypes?: string[];
    coolingTypes?: string[];
    amenities?: string[];
    comparableSales?: Omit<PropertyComparableSaleCreation, 'propertyId'>[];
    createdAt?: Date;
    updatedAt?: Date;
  };

export type PropertyBasement = {
  basementArea?: number;
  basementFinishedSpace?: number;
};

export type PropertyRoof = {
  roofSurfaceType?: string;
  roofAge?: number;
};

export type PropertyNeighborhood = {
  neighborhoodLocation?: MISMO.NeighborhoodPropertyLocationType;
  neighborhoodBuiltUp?: MISMO.NeighborhoodBuiltUpRangeType;
  neighborhoodGrowth?: MISMO.NeighborhoodGrowthPaceType;
  neighborhoodTrendType?: MISMO.NeighborhoodPropertyValueTrendType;
  neighborhoodDemandSupplyType?: MISMO.NeighborhoodDemandSupplyType;
  neighborhoodMarketingMonthsDurationType?: MISMO.NeighborhoodTypicalMarketingMonthsDurationType;
};

export type Property = PropertyCreation & {
  id: Identifier;
};

export type AggregatedProperty = Property & {
  comparableSales: PropertyComparableSale[];
};

export type NearbyProperties = {
  properties: NearbyPropertyDetails[];
};

export type PropertyDetails = Property & {
  delinquencyStatus: LoanDelinquencyStatus;
};

export type PropertyValuation = {
  date: string;
  value: number;
  propertyType?: Property['propertyType'];
  bedroomCount?: number;
};

export type PropertyValuationHistory = {
  purchasePrice: PropertyValuation[];
  bpo: PropertyValuation[];
  avm: PropertyValuation[];
  appraisal: PropertyValuation[];
  comparableSales: PropertyValuation[];
  mls: PropertyValuation[];
};
