import { LoanAmortizationData, ScheduleLine } from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';

import { formatMonetary } from '../../utils/formatUtils';
import * as Styles from './AmortizationChartLegend.styles';

export const AmortizationChartLegendSummary = () => {
  return (
    <Styles.CaptionWrapper>
      <Styles.ChartCaption>
        <Styles.CaptionTitle>
          <Styles.ChartBadge color="statusGreen" />
          Principal
        </Styles.CaptionTitle>
      </Styles.ChartCaption>
      <Styles.ChartCaption>
        <Styles.CaptionTitle>
          <Styles.ChartBadge color="statusBlue" />
          Interest
        </Styles.CaptionTitle>
      </Styles.ChartCaption>
    </Styles.CaptionWrapper>
  );
};

type AmortizationChartLegendProps = {
  selectedScheduleLine: ScheduleLine;
} & Pick<LoanAmortizationData, 'amortizationSchedule'>;

export const AmortizationChartLegendDetailed = ({
  amortizationSchedule,
  selectedScheduleLine,
}: AmortizationChartLegendProps) => {
  const totalUnpaidInterest = useMemo(() => {
    if (!amortizationSchedule || !selectedScheduleLine) {
      return undefined;
    }

    const selectedScheduleIndex = amortizationSchedule.findIndex(
      (schedule) => schedule.paymentNumber === selectedScheduleLine.paymentNumber
    );

    return sumBy(
      amortizationSchedule.slice(selectedScheduleIndex),
      (schedule) => schedule.interestPayment
    );
  }, [amortizationSchedule, selectedScheduleLine]);

  return (
    <Styles.CaptionWrapper $flexDirection="column" $justify="start" $align="start">
      <Styles.ChartCaption>
        <Styles.CaptionTitle>
          <Styles.ChartBadge color="statusGreen" />
          Principal
        </Styles.CaptionTitle>
        <Styles.CaptionText>
          {`${formatMonetary(selectedScheduleLine?.unpaidPrincipal)} of UPB to still collect`}
        </Styles.CaptionText>
      </Styles.ChartCaption>
      <Styles.ChartCaption>
        <Styles.CaptionTitle>
          <Styles.ChartBadge color="statusBlue" />
          Interest
        </Styles.CaptionTitle>
        <Styles.CaptionText>
          {`${formatMonetary(totalUnpaidInterest)} of Interest to still collect`}
        </Styles.CaptionText>
      </Styles.ChartCaption>
    </Styles.CaptionWrapper>
  );
};
