import { GetPortfolioLoansItem } from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import { Switch, useRouteMatch } from 'react-router-dom';

import * as api from '../api/portfolio';
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import {
  AddToPoolIcon,
  ChevronRightIcon,
  ColumnsLineIcon,
  ExportLineIcon,
  FilterLineIcon,
  ImportLineIcon,
  MapFillIcon,
  MapLineIcon,
  SettingsLineIcon,
  TableFillIcon,
  TableLineIcon,
  ViewIcon,
} from '../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection, SubMenu } from '../components/Menu';
import { getMaxVisibleItems, TableWrapper } from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableOptions } from '../components/TableOptions';
import { MapTableStats, TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { LinkToggle } from '../components/Toggles/LinkToggle';
import { ToggleGroup } from '../components/Toggles/ToggleGroup';
import { PrivateRoute } from '../global/PrivateRoute';
import {
  usePortfolioFiltersQuery,
  usePortfolioLoansQuery,
  useSavePortfolioFiltersMutation,
} from '../hooks/queries';
import { getAppliedFilters } from '../utils/portfolioFilterUtils';
import { PortfolioMap } from './PortfolioMap/PortfolioMap';
import { PortfolioTable } from './PortfolioTable';

const PORTFOLIO_ITEMS_PER_PAGE = 20;
const PORTFOLIO_SORT_BY = 'state';

type PortfolioProps = {
  isPortfolioLoading: boolean;
};

enum Views {
  TABLE = '/portfolio',
  MAP = '/portfolio/map',
}

export const Portfolio = ({ isPortfolioLoading }: PortfolioProps) => {
  const isMapDisplayed = Boolean(useRouteMatch(Views.MAP));

  const { data: filters = [], isLoading: isFiltersLoading } = usePortfolioFiltersQuery();
  const appliedFilters = getAppliedFilters(filters);

  const {
    data: portfolioLoans,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = usePortfolioLoansQuery(
    appliedFilters.map((filter) => filter.id),
    PORTFOLIO_ITEMS_PER_PAGE,
    PORTFOLIO_SORT_BY
  );
  const { mutateAsync: updateFilters } = useSavePortfolioFiltersMutation();

  const pages = compact(get(portfolioLoans, 'pages', []));
  const loans: GetPortfolioLoansItem[] = flatMap(pages, 'data');

  const totalLoansCount = get(pages, '0.totalLoansCount', 0);
  const totalFilteredLoansCount = get(pages, '0.totalFilteredLoansCount', 0);

  const handleExportAllLoans = () => {
    api.exportLoanPortfolio(
      (portfolioLoans?.pages || []).flatMap((page) => (page ? page.loanNumbers : []))
    );
  };

  return (
    <TableFilters
      filters={filters}
      isLoading={isFiltersLoading}
      onFiltersUpdate={updateFilters}
      path={isMapDisplayed ? Views.MAP : Views.TABLE}
    >
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({ closeLeftPanel, containerHeight, isLeftPanelOpen, openLeftPanel }) => (
            <>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={updateFilters}
                />
              )}
              <Card $hasFlex>
                <TableToolbar>
                  {isMapDisplayed ? (
                    <MapTableStats totalCount={totalLoansCount} />
                  ) : (
                    <TableStats totalCount={totalFilteredLoansCount} visibleCount={loans.length} />
                  )}
                  <Divider $orientation="vertical" $flexItem />
                  <TableFilterBar
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={updateFilters}
                  />
                  <TableOptions>
                    <ToggleGroup size="small">
                      <LinkToggle to={Views.TABLE} exact data-testid="PortfolioTable_ListButton">
                        {!isMapDisplayed ? <TableFillIcon /> : <TableLineIcon />}
                      </LinkToggle>
                      <LinkToggle to={Views.MAP} exact data-testid="PortfolioTable_MapButton">
                        {isMapDisplayed ? <MapFillIcon /> : <MapLineIcon />}
                      </LinkToggle>
                    </ToggleGroup>
                    <Menu title="Grid tools">
                      <MenuSection>
                        <MenuItem to="/import">
                          <ImportLineIcon />
                          <MenuItemText>Import loans</MenuItemText>
                        </MenuItem>
                        <MenuItem onClick={handleExportAllLoans}>
                          <ExportLineIcon />
                          <MenuItemText>Export all</MenuItemText>
                        </MenuItem>
                        <MenuItem disabled>
                          <ExportLineIcon />
                          <MenuItemText>Export selection</MenuItemText>
                        </MenuItem>
                        <MenuItem disabled>
                          <AddToPoolIcon />
                          <MenuItemText>Add selection to pool</MenuItemText>
                        </MenuItem>
                      </MenuSection>
                      <MenuSection>
                        <MenuItem isSubmenu>
                          <ViewIcon />
                          <MenuItemText>View</MenuItemText>
                          <ChevronRightIcon />
                          <SubMenu $direction="left">
                            <MenuSection>
                              <MenuItem disabled={!isMapDisplayed} to={Views.TABLE}>
                                <TableLineIcon />
                                <MenuItemText>As table</MenuItemText>
                              </MenuItem>
                              <MenuItem disabled={isMapDisplayed} to={Views.MAP}>
                                <MapLineIcon />
                                <MenuItemText>As map</MenuItemText>
                              </MenuItem>
                            </MenuSection>
                          </SubMenu>
                        </MenuItem>
                      </MenuSection>
                      <MenuSection>
                        <MenuItem onClick={openLeftPanel}>
                          <FilterLineIcon />
                          <MenuItemText>Filters</MenuItemText>
                        </MenuItem>
                      </MenuSection>
                      <MenuSection>
                        <MenuItem disabled>
                          <SettingsLineIcon />
                          <MenuItemText>Settings</MenuItemText>
                        </MenuItem>
                        <MenuItem disabled>
                          <ColumnsLineIcon />
                          <MenuItemText>Columns</MenuItemText>
                        </MenuItem>
                      </MenuSection>
                    </Menu>
                  </TableOptions>
                </TableToolbar>
                <Switch>
                  <PrivateRoute
                    path="/portfolio/map"
                    component={() => (
                      <ActivityIndicator contain isActive={isPortfolioLoading || isLoading}>
                        <PortfolioMap height={containerHeight} filters={appliedFilters} />
                      </ActivityIndicator>
                    )}
                  />
                  <ActivityIndicator contain isActive={isPortfolioLoading || isLoading}>
                    <TableWrapper>
                      <PortfolioTable
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        loans={loans}
                        maxVisibleItems={getMaxVisibleItems({
                          containerHeight,
                          hasHeaderGroups: true,
                        })}
                        shouldRedirectToLoan
                        totalLoansCount={totalFilteredLoansCount}
                      />
                    </TableWrapper>
                  </ActivityIndicator>
                </Switch>
              </Card>
            </>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};
