import { Identifier } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

const importLOSLoans = (loanIds: Identifier[]) => {
  const url = makeApiUrl('loans/los/import');

  return postRequestWithAuth<Identifier[]>(url, {
    body: JSON.stringify({ loanIds }),
  });
};

export const useImportLOSLoansMutation = () => useMutation(importLOSLoans);
