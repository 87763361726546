import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChartsWrapper = styled.div`
  width: 75%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartSection = styled.div`
  padding: 12px;
  position: relative;
  border-radius: 4px;

  background: color-mix(
    in srgb,
    ${(props) => props.theme.colors.deprecatedPrimary01},
    ${(props) => props.theme.colors.white} 5%
  );
  &:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }
  &:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
  &:nth-child(5) {
    grid-area: 2 / 3 / 3 / 4;
  }
`;
