import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

// TODO: Replace $variant with $elevation
export type CardVariant = 'default' | 'light';

type CardProps = {
  $hasFlex?: boolean;
  $hasScroll?: boolean;
  $hasPadding?: boolean;
  $variant?: CardVariant;
};

type styleProps = ThemeProps<DefaultTheme> & CardProps;

const defaultStyles = ({ theme }: styleProps) => css`
  background: ${theme.colors.primary02};
`;

const lightStyles = ({ theme }: styleProps) => css`
  background: ${theme.colors.primary03};
`;

export const Card = styled.div<CardProps>(
  ({ $hasFlex, $hasPadding, $hasScroll, $variant = 'default' }) => css`
    ${$variant === 'default' && defaultStyles}
    ${$variant === 'light' && lightStyles}
    

    border-radius: 4px;

    ${$hasFlex &&
    css`
      min-height: 0;
      flex: 1;
    `}

    ${$hasScroll &&
    css`
      overflow-y: auto;
    `}

    ${$hasPadding &&
    css`
      padding: 12px;
    `}
  `
);
