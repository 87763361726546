import { AttributeType, DocumentCategoryType, Loan, MISMO } from '@plus-platform/shared';
import React, { useMemo } from 'react';

import { BodyTextLink } from '../../../components/BodyTextLink';
import { Card } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import { Key, Pair, TitleKey, Value } from '../../../components/KeyValue';
import { ReadPermissions } from '../../../components/Permissions';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import {
  formatBoolean,
  formatDate,
  formatDefaultValue,
  formatPercentage,
} from '../../../utils/formatUtils';
import {
  formatAmortizationType,
  formatLienPriorityType,
  formatLoanPurposeType,
  formatLoanType,
  formatPropertyUsageType,
  formatRefinancePurposeType,
} from '../../../utils/loanUtils';
import * as Styles from '../LoanDetail.styles';

type LoanDetailOriginationProps = {
  loan: Loan;
  setDocumentCategory: (documentCategory: DocumentCategoryType) => void;
  refs: Record<string, React.MutableRefObject<HTMLDivElement | null>>;
};

const LoanDetailOrigination = React.forwardRef<HTMLDivElement, LoanDetailOriginationProps>(
  ({ loan, refs, setDocumentCategory }, ref) => {
    const interestRateAdjustment = useMemo(() => loan?.loanInterestRateAdjustments?.[0], [loan]);

    return (
      <Card $hasPadding id="origination" ref={ref} data-testid="LoanDetail_Origination">
        <Heading $size="medium">Origination</Heading>
        <WidgetSectionWrapper>
          <WidgetSection>
            <Pair $size="large" $align="right">
              <Key>Originating Entity</Key>
              <Value>{formatDefaultValue(loan.loanDetails.originatingEntry)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Loan Type</Key>
              <Value>{formatLoanType(loan.loanTerms?.loanType)}</Value>
            </Pair>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_NOTE_RATE_PERCENT]}
            >
              <Pair $size="large" $align="right">
                <Key>Interest Rate</Key>
                <Value>{formatPercentage(loan.loanTerms?.noteRate)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_AMORTIZATION_TYPE]}
            >
              <Pair $size="large" $align="right">
                <Key>Amortization Type</Key>
                <Value>{formatAmortizationType(loan.loanTerms?.amortizationType)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_IS_BALLOON]}>
              <Pair $size="large" $align="right">
                <Key>Is Balloon</Key>
                <Value>{formatBoolean(loan.loanTerms?.isBalloon)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_LIEN_PRIORITY_TYPE]}
            >
              <Pair $size="large" $align="right">
                <Key>Lien Priority Type</Key>
                <Value>{formatLienPriorityType(loan.loanTerms?.lienPriorityType)}</Value>
              </Pair>
            </ReadPermissions>
            {interestRateAdjustment &&
              loan.loanTerms?.amortizationType === MISMO.LoanAmortizationType.AdjustableRate && (
                <React.Fragment>
                  <ReadPermissions
                    requiredAttributes={[
                      AttributeType.ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_MARGIN_RATE,
                    ]}
                  >
                    <Pair $size="large" $align="right">
                      <Key>ARM Margin Rate</Key>
                      <Value>{formatPercentage(interestRateAdjustment?.marginRate)}</Value>
                    </Pair>
                  </ReadPermissions>
                  <ReadPermissions
                    requiredAttributes={[
                      AttributeType.ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_EFFECTIVE_DATE,
                    ]}
                  >
                    <Pair $size="large" $align="right">
                      <Key>ARM Effective Date</Key>
                      <Value>
                        {formatDate(
                          interestRateAdjustment?.effectiveDate
                            ? new Date(interestRateAdjustment.effectiveDate)
                            : undefined
                        )}
                      </Value>
                    </Pair>
                  </ReadPermissions>
                  <ReadPermissions
                    requiredAttributes={[
                      AttributeType.ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_LIFETIME_CEILING_RATE,
                    ]}
                  >
                    <Pair $size="large" $align="right">
                      <Key>ARM Lifetime Ceiling Rate</Key>
                      <Value>{formatPercentage(interestRateAdjustment?.lifetimeCeilingRate)}</Value>
                    </Pair>
                  </ReadPermissions>
                  <ReadPermissions
                    requiredAttributes={[
                      AttributeType.ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_LIFETIME_FLOOR_RATE,
                    ]}
                  >
                    <Pair $size="large" $align="right">
                      <Key>ARM Lifetime Floor Rate</Key>
                      <Value>{formatPercentage(interestRateAdjustment?.lifetimeFloorRate)}</Value>
                    </Pair>
                  </ReadPermissions>
                  <ReadPermissions
                    requiredAttributes={[
                      AttributeType.ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_PERIOD_CEILING_RATE,
                    ]}
                  >
                    <Pair $size="large" $align="right">
                      <Key>ARM Period Rate Change Ceiling Rate</Key>
                      <Value>
                        {formatPercentage(interestRateAdjustment?.periodRateChangeCeilingRate)}
                      </Value>
                    </Pair>
                  </ReadPermissions>
                  <ReadPermissions
                    requiredAttributes={[
                      AttributeType.ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_PERIOD_FLOOR_RATE,
                    ]}
                  >
                    <Pair $size="large" $align="right">
                      <Key>ARM Period Rate Change Floor Rate</Key>
                      <Value>
                        {formatPercentage(interestRateAdjustment?.periodRateChangeFloorRate)}
                      </Value>
                    </Pair>
                  </ReadPermissions>

                  {/* TODO: set permissions for this data: */}
                  <Pair $size="large" $align="right">
                    <Key>Index Names</Key>
                    <Value>
                      {formatDefaultValue(
                        (loan?.loanIndexes || []).map((loanIndex) => loanIndex.indexName).join(', ')
                      )}
                    </Value>
                  </Pair>
                </React.Fragment>
              )}

            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_TERM_MONTHS]}>
              <Pair $size="large" $align="right">
                <Key>Loan Term</Key>
                <Value>{formatDefaultValue(loan.loanTerms?.termMonths)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_REASON_FOR_LOAN]}>
              <Pair $size="large" $align="right">
                <Key>Reason for Loan</Key>
                <Value>{formatPropertyUsageType(loan.loanDetails.reasonForLoan)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_PURPOSE_OF_LOAN]}>
              <Pair $size="large" $align="right">
                <Key>Purpose of Loan</Key>
                <Value>{formatLoanPurposeType(loan.loanDetails.purposeOfLoan)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_REFINANCE_PURPOSE_TYPE]}
            >
              <Pair $size="large" $align="right">
                <Key>Refinance Purpose Type</Key>
                <Value>{formatRefinancePurposeType(loan.loanDetails?.refinancePurposeType)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_ADDITIONAL_LIENS]}>
              <Pair $size="large" $align="right">
                <Key>Additional Liens</Key>
                <Value>{formatDefaultValue(loan.loanDetails.additionalLiens)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_AGENCY_CASE_NUMBER]}
            >
              <Pair $size="large" $align="right">
                <Key>Agency Case Number</Key>
                <Value>{formatDefaultValue(loan.loanDetails.agencyCaseNumber)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_LENDER_NAME]}>
              <Pair $size="large" $align="right">
                <Key>Lenders Name</Key>
                <Value>{formatDefaultValue(loan.loanDetails?.lendersName)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_LENDER_ADDRESS]}>
              <Pair $size="large" $align="right">
                <Key>Lenders Address</Key>
                <Value>{formatDefaultValue(loan.loanDetails?.lendersAddress)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_LENDER_CASE_NUMBER]}
            >
              <Pair $size="large" $align="right">
                <Key>Lenders Case Number</Key>
                <Value>{formatDefaultValue(loan.loanDetails.lendersCaseNumber)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_QUALIFIED_MORTGAGE]}
            >
              <Pair $size="large" $align="right">
                <Key>Qualified Mortgage</Key>
                <Value>{formatDefaultValue(loan.loanDetails.qualifiedMortgage)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_QUALIFYING_RATE]}>
              <Pair $size="large" $align="right">
                <Key>Qualifying Rate</Key>
                <Value>{formatPercentage(loan.loanDetails?.qualifyingRatePercent)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_ORIGINAL_LTV]}>
              <Pair $size="large" $align="right">
                <Key>Original LTV</Key>
                <Value>{formatPercentage(loan.loanDetails?.originalLtv)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_COMBINED_LTV]}>
              <Pair $size="large" $align="right">
                <Key>Combined LTV</Key>
                <Value>{formatPercentage(loan.loanDetails?.combinedLtv)}</Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions
              requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_RESERVES_MONTHLY_PAYMENT_COUNT]}
            >
              <Pair $size="large" $align="right">
                <Key>PITIA Reserves Months</Key>
                <Value>
                  {formatDefaultValue(loan.loanDetails?.borrowerReservesMonthlyPaymentCount)}
                </Value>
              </Pair>
            </ReadPermissions>
            <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_ABILITY_TO_REPAY]}>
              <Pair $size="large" $align="right">
                <Key>Ability To Repay</Key>
                <Value>{formatDefaultValue(loan.loanDetails.abilityToRepay)}</Value>
              </Pair>
            </ReadPermissions>
          </WidgetSection>

          <WidgetSection>
            <Styles.AdditionalSection>
              <TitleKey>Company Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Company Name</Key>
                <Value>{formatDefaultValue(loan.loanDetails.companyName)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Identifier</Key>
                <Value>{formatDefaultValue(loan.loanDetails.companyIdentifier)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Address</Key>
                <Value>{formatDefaultValue(loan.loanDetails.companyAddress)}</Value>
              </Pair>
            </Styles.AdditionalSection>
            <Styles.AdditionalSection>
              <TitleKey>Originator Personnel Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Name</Key>
                <Value>{formatDefaultValue(loan.loanDetails.originatorPersonnelName)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Employee Identifier</Key>
                <Value>{formatDefaultValue(loan.loanDetails.originatorPersonnelId)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Phone</Key>
                <Value>{formatDefaultValue(loan.loanDetails.originatorPersonnelPhoneNumber)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Origination documents</Key>
                <Value>
                  <BodyTextLink
                    onClick={() => {
                      if (refs['acquisition-history']?.current) {
                        refs['acquisition-history'].current.scrollIntoView();
                      }
                      setDocumentCategory(DocumentCategoryType.ORIGINATION_DOCUMENT);
                    }}
                    to="#acquisition-history"
                  >
                    View
                  </BodyTextLink>
                </Value>
              </Pair>
            </Styles.AdditionalSection>
          </WidgetSection>
        </WidgetSectionWrapper>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailOrigination;
