import {
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum HMDA_HOEPALoanStatusReasonType {
  HOEPALoanBecauseOfAPR = 'HOEPALoanBecauseOfAPR',
  HOEPALoanBecauseOfBothAPRAndPointsAndFees = 'HOEPALoanBecauseOfBothAPRAndPointsAndFees',
  HOEPALoanBecauseOfPointsAndFees = 'HOEPALoanBecauseOfPointsAndFees',
  Other = 'Other',
}

enum HMDAApplicationSubmissionType {
  NotApplicable = 'NotApplicable',
  NotSubmittedDirectly = 'NotSubmittedDirectly',
  SubmittedDirectly = 'SubmittedDirectly',
}

enum HMDACoveredLoanInitiallyPayableToReportingInstitutionStatusType {
  InitiallyPayable = 'InitiallyPayable',
  NotApplicable = 'NotApplicable',
  NotInitiallyPayable = 'NotInitiallyPayable',
}

enum HMDADispositionType {
  ApplicationApprovedButNotAccepted = 'ApplicationApprovedButNotAccepted',
  ApplicationDenied = 'ApplicationDenied',
  ApplicationWithdrawn = 'ApplicationWithdrawn',
  FileClosedForIncompleteness = 'FileClosedForIncompleteness',
  LoanOriginated = 'LoanOriginated',
  LoanPurchasedByYourInstitution = 'LoanPurchasedByYourInstitution',
  PreapprovalRequestApprovedButNotAccepted = 'PreapprovalRequestApprovedButNotAccepted',
  PreapprovalRequestDenied = 'PreapprovalRequestDenied',
}

enum HMDAManufacturedHomeLegalClassificationType {
  NotApplicable = 'NotApplicable',
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum HMDAPostalAddressUnknownType {
  CityName = 'CityName',
  EntireAddress = 'EntireAddress',
  None = 'None',
  PostalCode = 'PostalCode',
  StreetAddress = 'StreetAddress',
  StreetAddressAndCityName = 'StreetAddressAndCityName',
  StreetAddressAndPostalCode = 'StreetAddressAndPostalCode',
}

enum HMDAPreapprovalType {
  NotApplicable = 'NotApplicable',
  PreapprovalWasNotRequested = 'PreapprovalWasNotRequested',
  PreapprovalWasRequested = 'PreapprovalWasRequested',
}

enum HMDAPurchaserType {
  AffiliateInstitution = 'AffiliateInstitution',
  CommercialOrSavingsBank = 'CommercialOrSavingsBank',
  CreditUnionMortgageOrFinanceCompany = 'CreditUnionMortgageOrFinanceCompany',
  FannieMae = 'FannieMae',
  FarmerMac = 'FarmerMac',
  FreddieMac = 'FreddieMac',
  GinnieMae = 'GinnieMae',
  LifeInsuranceCompany = 'LifeInsuranceCompany',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
  PrivateSecuritization = 'PrivateSecuritization',
}

enum HMDAPurposeOfLoanType {
  HomeImprovement = 'HomeImprovement',
  HomePurchase = 'HomePurchase',
  Other = 'Other',
  Refinancing = 'Refinancing',
}

enum HMDAReasonForDenialType {
  CreditApplicationIncomplete = 'CreditApplicationIncomplete',
  InsufficientCash = 'InsufficientCash',
  InsufficientCollateralValue = 'InsufficientCollateralValue',
  InsufficientCreditHistory = 'InsufficientCreditHistory',
  InsufficientEmploymentHistory = 'InsufficientEmploymentHistory',
  InsufficientIncome = 'InsufficientIncome',
  MortgageInsuranceDenied = 'MortgageInsuranceDenied',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
  UnverifiableInformation = 'UnverifiableInformation',
}

type HMDALoanDenial = MISMOEntity &
  Partial<{
    HMDAReasonForDenialType: HMDAReasonForDenialType;
    HMDAReasonForDenialTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type HMDALoan = MISMOEntity &
  Partial<{
    HMDA_HOEPALoanStatusIndicator: MISMOIndicator;
    HMDA_HOEPALoanStatusReasonType: HMDA_HOEPALoanStatusReasonType;
    HMDA_HOEPALoanStatusReasonTypeOtherDescription: MISMOString;
    HMDAApplicationSubmissionType: HMDAApplicationSubmissionType;
    HMDABusinessPurposeIndicator: MISMOIndicator;
    HMDACoveredLoanInitiallyPayableToReportingInstitutionStatusType: HMDACoveredLoanInitiallyPayableToReportingInstitutionStatusType;
    HMDADispositionDate: MISMODate;
    HMDADispositionType: HMDADispositionType;
    HMDAManufacturedHomeLegalClassificationType: HMDAManufacturedHomeLegalClassificationType;
    HMDAMultipleCreditScoresUsedIndicator: MISMOIndicator;
    HMDAOtherNonAmortizingFeaturesIndicator: MISMOIndicator;
    HMDAPostalAddressUnknownType: HMDAPostalAddressUnknownType;
    HMDAPreapprovalType: HMDAPreapprovalType;
    HMDAPurchaserType: HMDAPurchaserType;
    HMDAPurchaserTypeOtherDescription: MISMOString;
    HMDAPurposeOfLoanType: HMDAPurposeOfLoanType;
    HMDAPurposeOfLoanTypeOtherDescription: MISMOString;
    HMDARateSpreadPercent: MISMOPercent;
    HMDAReportingCRAExemptionIndicator: MISMOIndicator;
    HMDAReportingSmallPopulationIndicator: MISMOIndicator;
    HMDALoanDenials: HMDALoanDenial[];
  }>;
