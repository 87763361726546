import { Stipulation, StipulationType } from '@plus-platform/shared';
import React from 'react';
import styled, { css, StyledComponentPropsWithRef } from 'styled-components/macro';

import { PDFIcon } from '../components/icons/PdfIcon';
import {
  Tag as StyledTag,
  TagHelperText as StyledTagHelperText,
  TagLabel as StyledTagLabel,
  Tags as StyledTags,
} from '../components/Tag';
import { fontFamily } from '../styles/typography';
import { useStipulationResources } from './useStipulationResources';

const Tag = styled(StyledTag)`
  ${({ width }) => css`
    display: flex;
    width: ${width ?? 220}px;
    font-size: 13px;
    column-gap: 10px;
    padding: 12px;
    border-radius: 6px;
  `}
`;

const TagLabel = styled(StyledTagLabel)`
  font-family: ${fontFamily.text};
  flex: 1;
`;

const TagHelperText = styled(StyledTagHelperText)`
  font-family: ${fontFamily.text};
`;

const Icon = styled.span`
  color: ${({ theme }) => theme.colors.tradePDFPurple};
  width: 32px;
  height: 32px;
`;

type StipulationTagProps = {
  name: string;
  width?: number;
} & React.ComponentProps<typeof Tag> &
  StyledComponentPropsWithRef<typeof Tag>;

export const StipulationTag = ({ name, ...props }: StipulationTagProps) => {
  return (
    <Tag
      icon={
        <Icon>
          <PDFIcon />
        </Icon>
      }
      variant="violet"
      {...props}
    >
      <TagLabel>{name}</TagLabel>
      <TagHelperText>PDF</TagHelperText>
    </Tag>
  );
};

const PreviewTag = styled(StipulationTag)`
  cursor: pointer;
`;

type StipulationPreviewTagProps = {
  stipulationType: StipulationType;
  stipulationId: Stipulation['id'];
  stipulationFileOriginalName: string;
} & Omit<StipulationTagProps, 'name'>;

export const StipulationPreviewTag = ({
  stipulationFileOriginalName,
  stipulationId,
  stipulationType,
  ...props
}: StipulationPreviewTagProps) => {
  const { openDocument } = useStipulationResources(stipulationType, stipulationId);

  return (
    <PreviewTag
      {...props}
      forwardAs="div"
      name={stipulationFileOriginalName}
      onClick={openDocument}
      width={300}
    />
  );
};

export const StipulationPreviewTags = styled(StyledTags)`
  display: flex;
  flex-direction: column;
  column-gap: 10px;
`;
