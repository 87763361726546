import { US_STATES } from '@plus-platform/shared';
import chunk from 'lodash/chunk';
import { Col, Container, Row } from 'styled-bootstrap-grid';

import { formatDefaultValue } from '../utils/formatUtils';
import * as Styles from './StatesTable.styles';

const RELEVANT_USA_STATES = US_STATES.filter(
  (state) =>
    ![
      'American Samoa',
      'Federated States Of Micronesia',
      'Northern Mariana Islands',
      'Puerto Rico',
      'Guam',
      'Marshall Islands',
      'Palau',
      'Virgin Islands',
    ].includes(state.name)
);

type StatesTableProps = {
  id: string;
  data: {
    [key: string]: {
      value: number;
      formattedValue: string;
    };
  };
  variant?: 'small' | 'medium';
  styledColumnCount?: number;
  chunkColumnCount?: number;
  elementColumnCount?: number;
  selectedState?: string;
  onStateClick?: (stateCode: string) => void;
};

export const StatesTable = ({
  chunkColumnCount = 20,
  data,
  elementColumnCount = 4,
  id,
  onStateClick,
  selectedState,
  styledColumnCount = 4,
  variant = 'medium',
}: StatesTableProps) => {
  const columns = chunk(RELEVANT_USA_STATES, chunkColumnCount);

  return (
    <Col col={styledColumnCount} noGutter>
      <Container fluid style={{ padding: 0 }}>
        <Row style={{ margin: 0 }}>
          {columns.map((states, index) => (
            <Col col={elementColumnCount} key={`state-${index}`}>
              {states.map((state) => (
                <Styles.Content
                  key={`${id}state-${state.code}`}
                  $isActive={state.code === selectedState}
                  onClick={() => onStateClick?.(state.code)}
                >
                  <Styles.ContentLabel data-testid="Breakdown_State_Label">
                    {variant === 'small' ? state.code : state.name}
                  </Styles.ContentLabel>
                  <Styles.Value data-testid="Breakdown_State_Value">
                    {formatDefaultValue(data[state.code]?.formattedValue)}
                  </Styles.Value>
                </Styles.Content>
              ))}
            </Col>
          ))}
        </Row>
      </Container>
    </Col>
  );
};
