import {
  PortfolioHomeAnalyticGroupType,
  PortfolioHomeAnalyticPresetType,
  PortfolioHomeAnalyticPreviewItem,
} from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioHomeAnalyticsPreview = (
  group: PortfolioHomeAnalyticGroupType,
  preset: PortfolioHomeAnalyticPresetType
) => {
  const url = makeApiUrl(`/portfolio/home/analytics/${group}/${preset}`);

  return getRequestWithAuth<PortfolioHomeAnalyticPreviewItem[]>(url);
};
