import { Switch } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { PrivateRoute } from '../global/PrivateRoute';
import { TradeRequestDataLoanPage } from './TradeRequestDataLoan/TradeRequestDataLoanPage';
import { TradeRequestPage } from './TradeRequestPage';
import { TradeRequestsOutbound } from './TradeRequestsOutbound';

export const TradeRequestsOutboundPage = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/trade-requests/:category/:tradeId/loans/:loanId"
        render={() => <TradeRequestDataLoanPage />}
      />
      <PrivateRoute path="/trade-requests/:category/:tradeId" render={() => <TradeRequestPage />} />
      <Page>
        <PageHeader>
          <Heading>Outbound</Heading>
          <Breadcrumb>
            <BreadcrumbItem linkTo="/" title="Home" />
            <BreadcrumbItem linkTo="/trade-requests" title="Trade activity" />
            <BreadcrumbItem title="Outbound" />
          </Breadcrumb>
        </PageHeader>
        <PageContent>
          <TradeRequestsOutbound />
        </PageContent>
      </Page>
    </Switch>
  );
};
