import {
  DelinquencyType,
  PortfolioBreakdown,
  PortfolioBreakdownDelinquencyType,
  PortfolioBreakdownType,
} from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { VictoryScatter } from 'victory-scatter';

import { ToggleSwitch } from '../../../components/ToggleSwitch';
import { formatPercentage } from '../../../utils/formatUtils';
import { orderedLoanRateTypeLabels, orderedLoanRateTypes } from '../../../utils/loanUtils';
import { DELINQUENT_CHART_COLOR_MAPPING } from '../helpers';
import {
  LARGEST_BUBBLE_SIZE,
  mapDefaultedLoansCountByNoteRateForChart,
  SMALLEST_BUBBLE_SIZE,
} from './helpers';
import { Emphasis, Toggle, Toggles } from './LoanTypesByDefault.styles';
import { LoanTypesByMetricsChart } from './LoanTypesByMetricsChart';

type LoanTypesByDefaultProps = {
  defaultedLoansCountByNoteRate?: PortfolioBreakdown['delinquentLoansCountByNoteRate'];
};

export const LoanTypesByDefault = (props: LoanTypesByDefaultProps) => {
  const { colors } = useTheme();
  const { defaultedLoansCountByNoteRate } = props;

  const [selectedDelinquencyType, setSelectedDelinquencyType] =
    React.useState<PortfolioBreakdownDelinquencyType>(PortfolioBreakdownType.ALL);

  const data = mapDefaultedLoansCountByNoteRateForChart(defaultedLoansCountByNoteRate);

  const flatData = Object.values(data).flat();

  const totalLoansCount = sumBy(data.all, 'loansCount');
  const thirtyDayLoansCount = sumBy(data.thirtyDays, 'loansCount');
  const sixtyDayLoansCount = sumBy(data.sixtyDays, 'loansCount');
  const ninetyDayLoansCount = sumBy(data.ninetyDays, 'loansCount');

  const smallestNoteRate = Math.min(...flatData.map((dataItem) => dataItem.noteRate));
  const largestNoteRate = Math.max(...flatData.map((dataItem) => dataItem.noteRate));
  const largestLoanCount = Math.max(...flatData.map((dataItem) => dataItem.loansCount));

  const chartData = data[selectedDelinquencyType]?.map((dataItem) => {
    const { loansCount, noteRate, rateType } = dataItem;
    const loanCountRatio = largestLoanCount ? loansCount / largestLoanCount : 0;

    return {
      x: noteRate * 100,
      y: orderedLoanRateTypes.indexOf(rateType) * 2 + 1,
      size: Math.max(LARGEST_BUBBLE_SIZE * loanCountRatio, SMALLEST_BUBBLE_SIZE),
      fill: DELINQUENT_CHART_COLOR_MAPPING[selectedDelinquencyType],
    };
  });

  const isDelinquencyToggleSelected = (type: PortfolioBreakdownDelinquencyType) => {
    return selectedDelinquencyType === type;
  };

  const toggleSelectedDelinquencyType = (type: PortfolioBreakdownDelinquencyType) => {
    setSelectedDelinquencyType(type);
  };

  return (
    <React.Fragment>
      {/* TODO: For now, we show only 3 delinquency groups, for consistency with the rest of the application.
      When other parts of the UI are updated to include all 4 delinquency groups
      make sure to update this section as well and add a toggle for 90 plus days group. */}
      <Toggles>
        <Toggle>
          All
          <ToggleSwitch
            $size="small"
            $toggledColor={colors.statusTurquoise}
            checked={isDelinquencyToggleSelected(PortfolioBreakdownType.ALL)}
            onChange={() => toggleSelectedDelinquencyType(PortfolioBreakdownType.ALL)}
          />
        </Toggle>
        <Toggle>
          30-day
          <Emphasis>
            {thirtyDayLoansCount} / {formatPercentage(thirtyDayLoansCount / totalLoansCount, 0)}
          </Emphasis>
          <ToggleSwitch
            $size="small"
            $toggledColor={colors.statusYellow}
            checked={isDelinquencyToggleSelected(DelinquencyType.THIRTY_DAYS)}
            onChange={() => toggleSelectedDelinquencyType(DelinquencyType.THIRTY_DAYS)}
          />
        </Toggle>
        <Toggle>
          60-day
          <Emphasis>
            {sixtyDayLoansCount} / {formatPercentage(sixtyDayLoansCount / totalLoansCount, 0)}
          </Emphasis>
          <ToggleSwitch
            $size="small"
            $toggledColor={colors.statusOrange}
            checked={isDelinquencyToggleSelected(DelinquencyType.SIXTY_DAYS)}
            onChange={() => toggleSelectedDelinquencyType(DelinquencyType.SIXTY_DAYS)}
          />
        </Toggle>
        <Toggle>
          90-day
          <Emphasis>
            {ninetyDayLoansCount} / {formatPercentage(ninetyDayLoansCount / totalLoansCount, 0)}
          </Emphasis>
          <ToggleSwitch
            $size="small"
            $toggledColor={colors.statusRed}
            checked={isDelinquencyToggleSelected(DelinquencyType.NINETY_DAYS)}
            onChange={() => toggleSelectedDelinquencyType(DelinquencyType.NINETY_DAYS)}
          />
        </Toggle>
      </Toggles>

      <LoanTypesByMetricsChart
        labels={orderedLoanRateTypeLabels}
        height={176}
        domain={{ x: [smallestNoteRate * 100, largestNoteRate * 100] }}
        padding={{ top: 21 }}
      >
        <VictoryScatter data={chartData} style={{ data: { fill: ({ datum }) => datum.fill } }} />
      </LoanTypesByMetricsChart>
    </React.Fragment>
  );
};
