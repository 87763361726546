import {
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

export enum OffSiteImprovementOwnershipType {
  Private = 'Private',
  Public = 'Public',
  Unknown = 'Unknown',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum OffSiteImprovementType {
  Alley = 'Alley',
  CurbGutter = 'CurbGutter',
  Gated = 'Gated',
  Other = 'Other',
  Sidewalk = 'Sidewalk',
  Street = 'Street',
  StreetAccess = 'StreetAccess',
  StreetLighting = 'StreetLighting',
  StreetMaintenance = 'StreetMaintenance',
  StreetSurface = 'StreetSurface',
}

export type OffSiteImprovement = MISMOEntity &
  Partial<{
    conditionRatingType: ConditionRatingType;
    offSiteImprovementDescription: MISMOString;
    offSiteImprovementOwnershipType: OffSiteImprovementOwnershipType;
    offSiteImprovementsUtilityNotTypicalDescription: MISMOString;
    offSiteImprovementsUtilityTypicalIndicator: MISMOIndicator;
    offSiteImprovementType: OffSiteImprovementType;
    offSiteImprovementTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
