import { TradeRequest, TradeRequestQuote } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postFormDataRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type CreateTradeRequestQuoteCounterMutation = {
  tradeRequestId: TradeRequest['id'];
  quoteId: TradeRequestQuote['id'];
  value: number;
  reason?: string;
  stipulation?: File;
};

const getCounterCreationUrl = (data: CreateTradeRequestQuoteCounterMutation) => {
  const { quoteId, tradeRequestId } = data;

  return makeApiUrl(`trade-requests/${tradeRequestId}/quotes/${quoteId}/counters`);
};

const createTradeRequestQuoteCounter = (data: CreateTradeRequestQuoteCounterMutation) => {
  const { reason, stipulation, value } = data;
  const url = getCounterCreationUrl(data);
  const body = new FormData();

  body.append('value', String(value));

  if (reason) {
    body.append('reason', reason);
  }

  if (stipulation) {
    body.append('stipulation', stipulation);
  }

  return postFormDataRequestWithAuth(url, { body });
};

export const useCreateTradeRequestQuoteCounterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createTradeRequestQuoteCounter, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_INBOUND_QUOTES);
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_OUTBOUND_QUOTES);
    },
  });
};
