import React from 'react';
import {
  StyledComponentInnerOtherProps,
  StyledComponentPropsWithRef,
} from 'styled-components/macro';

import * as Styles from './ToggleSwitch.styles';

type ToggleProps = StyledComponentPropsWithRef<typeof Styles.Input> &
  StyledComponentInnerOtherProps<typeof Styles.Input>;

export const ToggleSwitch = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Styles.Label className={className}>
        <Styles.Input {...props} ref={ref} type="checkbox" />
        {children}
      </Styles.Label>
    );
  }
);
