import { GetNotesForLoanResponse } from '@plus-platform/shared';
import { useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const DEFAULT_NUMBER_OF_NOTES_PER_PAGE = 20;

const getNotes = async (loanNumber: string, limit: number, offset: number) => {
  const query = new URLSearchParams({
    limit: String(limit),
    offset: String(offset),
  });
  const url = makeApiUrl(`loans/${loanNumber}/notes`, query);
  return getRequestWithAuth<GetNotesForLoanResponse>(url);
};

export const useGetLoanNotesQuery = (loanNumber: string) => {
  return useInfiniteQuery(
    [QueryKeys.LOAN_NOTES, loanNumber],
    async ({ pageParam = 0 }) => {
      return getNotes(loanNumber, DEFAULT_NUMBER_OF_NOTES_PER_PAGE, pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage === undefined || lastPage?.items.length < DEFAULT_NUMBER_OF_NOTES_PER_PAGE) {
          return undefined;
        }

        return lastPage?.pagination.offset + lastPage?.pagination.limit;
      },
      keepPreviousData: true,
    }
  );
};
