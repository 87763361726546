import {
  useDeletePortfolioAnalyticsMutation,
  useUpdatePortfolioAnalyticsMutation,
} from '../../hooks/queries';
import { AnalyticWidget, AnalyticWidgetProps } from './AnalyticWidget';

type AnalyticTileProps = Omit<AnalyticWidgetProps, 'linkUrl'>;

export const AnalyticTile = (props: AnalyticTileProps) => {
  const { id } = props;

  const { mutateAsync: deletePortfolioAnalytics } = useDeletePortfolioAnalyticsMutation();
  const { mutateAsync: updatePortfolioAnalytics } = useUpdatePortfolioAnalyticsMutation();

  return (
    <AnalyticWidget
      {...props}
      linkUrl={`/analytics/${id}`}
      onDelete={() => deletePortfolioAnalytics({ id })}
      onUpdate={(visualisationType) => updatePortfolioAnalytics({ id, visualisationType })}
    />
  );
};
