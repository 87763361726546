import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOPercent,
  MISMOString,
} from './types';

enum ConstructionLoanEstimatedInterestCalculationMethodType {
  FullLoanCommitment = 'FullLoanCommitment',
  HalfLoanCommitment = 'HalfLoanCommitment',
}

export enum ConstructionLoanType {
  ConstructionOnly = 'ConstructionOnly',
  ConstructionToPermanent = 'ConstructionToPermanent',
}

enum ConstructionPhaseInterestPaymentFrequencyType {
  Biweekly = 'Biweekly',
  Monthly = 'Monthly',
  Other = 'Other',
}

enum ConstructionPhaseInterestPaymentMethodType {
  AddToPermanentLoanAmount = 'AddToPermanentLoanAmount',
  BilledToBorrower = 'BilledToBorrower',
  Other = 'Other',
}

enum ConstructionPhaseInterestPaymentType {
  InterestPaidAtEndOfConstruction = 'InterestPaidAtEndOfConstruction',
  InterestPaidPeriodically = 'InterestPaidPeriodically',
  Other = 'Other',
}

export enum ConstructionToPermanentClosingFeatureType {
  AutomaticConversion = 'AutomaticConversion',
  ConvertibleARM = 'ConvertibleARM',
  ModificationAgreement = 'ModificationAgreement',
  NewNote = 'NewNote',
  Other = 'Other',
}

export enum ConstructionToPermanentClosingType {
  OneClosing = 'OneClosing',
  Other = 'Other',
  TwoClosing = 'TwoClosing',
}

export type Construction = MISMOEntity &
  Partial<{
    constructionImprovementCostsAmount: MISMOAmount;
    constructionImprovementsEstimatedValueAmount: MISMOAmount;
    constructionLoanEstimatedInterestCalculationMethodType: ConstructionLoanEstimatedInterestCalculationMethodType;
    constructionLoanInterestReserveAmount: MISMOAmount;
    constructionLoanTotalTermMonthsCount: MISMOCount;
    constructionLoanType: ConstructionLoanType;
    constructionPeriodInterestRatePercent: MISMOPercent;
    constructionPeriodNumberOfMonthsCount: MISMOCount;
    constructionPhaseInterestPaymentFrequencyType: ConstructionPhaseInterestPaymentFrequencyType;
    constructionPhaseInterestPaymentFrequencyTypeOtherDescription: MISMOString;
    constructionPhaseInterestPaymentMethodType: ConstructionPhaseInterestPaymentMethodType;
    constructionPhaseInterestPaymentMethodTypeOtherDescription: MISMOString;
    constructionPhaseInterestPaymentType: ConstructionPhaseInterestPaymentType;
    constructionPhaseInterestPaymentTypeOtherDescription: MISMOString;
    constructionToPermanentClosingFeatureType: ConstructionToPermanentClosingFeatureType;
    constructionToPermanentClosingFeatureTypeOtherDescription: MISMOString;
    constructionToPermanentClosingType: ConstructionToPermanentClosingType;
    constructionToPermanentClosingTypeOtherDescription: MISMOString;
    constructionToPermanentFirstPaymentDueDate: MISMODate;
    constructionToPermanentRecertificationDate: MISMODate;
    constructionToPermanentRecertificationValueAmount: MISMOAmount;
    landAppraisedValueAmount: MISMOAmount;
    landEstimatedValueAmount: MISMOAmount;
    landOriginalCostAmount: MISMOAmount;
  }>;
