import React from 'react';

import * as Styles from './ButtonToggle.styles';

type ButtonIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean;
  value: string;
  onChange?: (event: React.MouseEvent<HTMLElement>, v: string[]) => void;
  size?: 'small' | 'medium' | 'large';
  lightBackground?: boolean;
};

export const ButtonToggle = ({
  children,
  isActive = false,
  lightBackground,
  onClick,
  size,
  ...rest
}: ButtonIconProps) => {
  return (
    <Styles.Button
      type="button"
      onClick={onClick}
      $isActive={isActive}
      $size={size}
      $lightBackground={lightBackground}
      {...rest}
    >
      {children}
    </Styles.Button>
  );
};

ButtonToggle.displayName = 'ButtonToggle';
