import { Filter, GetPortfolioGeodataPropertiesItem } from '@plus-platform/shared';
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api';
import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Loader } from '../../components/Loader';
import { MapWrapper } from '../../components/Map';
import { useMapContext } from '../../contexts/MapContext';
import { useGeodataPropertiesQuery } from '../../hooks/queries';
import { AllLayers } from '../../loans/layers';
import { LayerControlsWithoutExtraData } from '../../loans/layers/LayerControls';
import { LoanNearbyPropertiesProvider } from '../../loans/LoanData';
import { MapLayerEnum } from '../../loans/types';
import {
  convertCoordinatesFromGeolocation,
  DEFAULT_MAP_ZOOM_LEVEL,
  DEFAULT_MAP_ZOOM_MIN_LEVEL,
  DEFAULT_US_MAP_LAT_LNG_RESTRICTIONS,
} from '../../utils/mapUtils';
import { LoanSummary } from './LoanSummary';
import { CloseIcon, Sidebar, SidebarContent, Wrapper } from './PortfolioMap.styles';
import { PropertyMarker } from './PropertyMarker';

const NearbyPropertiesForProperty = React.lazy(() => import('./NearbyPropertiesForProperty'));

type MapContainerProps = {
  mapLayers: MapLayerEnum[];
  properties: GetPortfolioGeodataPropertiesItem[];
  onPropertyMarkerClick: (loanNumber: string) => void;
};

const MapContainer = ({ mapLayers, onPropertyMarkerClick, properties }: MapContainerProps) => {
  const [googleMap, setGoogleMap] = React.useState<google.maps.Map | undefined>(undefined);
  const [mapHeight, setMapHeight] = React.useState<number | undefined>(undefined);
  const [mapBounds, setMapBounds] = React.useState<google.maps.LatLngBounds | undefined>(undefined);

  React.useEffect(() => {
    const bounds = getBoundsFromProperties(properties);
    setMapBounds(bounds);

    if (googleMap) {
      googleMap.fitBounds(bounds);
    }
  }, [googleMap, properties]);

  const handleBoundsChanged = () => {
    if (!googleMap) {
      return;
    }

    const bounds = googleMap.getBounds();
    setMapBounds(bounds);
    // resize event doesn't seem to be triggered by the google map, so storing
    // the height when bounds change
    setMapHeight(googleMap.getDiv().clientHeight);
  };

  return (
    <GoogleMap
      mapContainerClassName="google-maps"
      onBoundsChanged={handleBoundsChanged}
      onLoad={(map) => setGoogleMap(map)}
      options={{
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        minZoom: DEFAULT_MAP_ZOOM_MIN_LEVEL,
        restriction: {
          latLngBounds: DEFAULT_US_MAP_LAT_LNG_RESTRICTIONS,
        },
      }}
      zoom={DEFAULT_MAP_ZOOM_LEVEL}
    >
      {mapBounds && (
        <AllLayers
          displayedMapLayers={mapLayers}
          googleMap={googleMap}
          mapBounds={mapBounds}
          mapHeight={mapHeight}
          targetCoordinates={undefined}
        />
      )}

      <MarkerClusterer>
        {(clusterer) => (
          <>
            {properties.map((property) => (
              <PropertyMarker
                key={property.id}
                onClick={() => onPropertyMarkerClick(property.loanNumber)}
                property={property}
                clusterer={clusterer}
              />
            ))}
          </>
        )}
      </MarkerClusterer>
    </GoogleMap>
  );
};

const getBoundsFromProperties = (properties: GetPortfolioGeodataPropertiesItem[]) => {
  return properties.reduce((acc, currentProperty) => {
    if (currentProperty?.geolocationLatitude && currentProperty?.geolocationLongitude) {
      acc.extend(
        convertCoordinatesFromGeolocation({
          latitude: Number(currentProperty.geolocationLatitude),
          longitude: Number(currentProperty.geolocationLongitude),
        })
      );
    }

    return acc;
  }, new google.maps.LatLngBounds());
};

type PortfolioMapProps = {
  filters: Filter[];
  height?: number;
};

export const PortfolioMap = ({ filters, height }: PortfolioMapProps) => {
  const { isLoaded } = useMapContext();
  const [selectedLoanNumber, setSelectedLoanNumber] = React.useState<string | undefined>();

  const [mapLayers, setMapLayers] = React.useState<MapLayerEnum[]>([]);

  const { data: propertiesData = [], isLoading } = useGeodataPropertiesQuery(map(filters, 'id'));
  const properties: GetPortfolioGeodataPropertiesItem[] = get(propertiesData, 'data', []);

  const selectedProperty = properties.find(
    (property) => property.loanNumber === selectedLoanNumber
  );

  return (
    <Wrapper $height={height}>
      <MapWrapper data-testid="PortfolioMap_Wrapper">
        <ActivityIndicator contain isActive={isLoading || isLoaded === false} showChildren>
          {isLoaded && (
            <MapContainer
              mapLayers={mapLayers}
              onPropertyMarkerClick={setSelectedLoanNumber}
              properties={properties}
            />
          )}
        </ActivityIndicator>
        <LayerControlsWithoutExtraData mapLayers={mapLayers} onToggleMapLayer={setMapLayers} />
        {selectedLoanNumber && (
          <Sidebar data-testid="PortfolioMap_Sidebar">
            <CloseIcon onClick={() => setSelectedLoanNumber(undefined)} />
            <SidebarContent>
              <LoanSummary loanNumber={selectedLoanNumber} />

              {selectedProperty && (
                <React.Suspense fallback={<Loader />}>
                  <LoanNearbyPropertiesProvider
                    queryProps={{
                      loanNumber: selectedLoanNumber,
                    }}
                  >
                    <NearbyPropertiesForProperty />
                  </LoanNearbyPropertiesProvider>
                </React.Suspense>
              )}
            </SidebarContent>
          </Sidebar>
        )}
      </MapWrapper>
    </Wrapper>
  );
};
