import { CalculationType, PortfolioAnalyticItem, VisualisationType } from '@plus-platform/shared';
import React from 'react';

import { ButtonLoader } from '../../components/ButtonLoader';
import { BarChartIcon, BubbleChartLineIcon, MapLineIcon } from '../../components/icons';
import { Select } from '../../components/Select';
import { ButtonToggle } from '../../components/Toggles/ButtonToggle';
import { ToggleGroup } from '../../components/Toggles/ToggleGroup';
import { useCreatePortfolioAnalyticsMutation } from '../../hooks/queries';
import { ErrorLegend, Footer, Legend, Text, Title, Wrapper } from './Analytics.styles';
import {
  ChoiceForm,
  SelectWrapper,
  VisualisationToggleWrapper,
} from './AnalyticsChoiceWidget.styles';
import { Emphasis } from './AnalyticWidget.styles';
import {
  ALLOWED_METRIC_BY_OPTIONS,
  ALLOWED_METRIC_OPTIONS,
  getAllowedCalculationTypes,
  getAllowedVisualizationTypes,
  getCalculationTypeLabel,
  getVisualizationTypeLabel,
  mapMetricsToOptions,
} from './utils';

const analyticsSelectStyles = {
  menu: {
    minWidth: '300px',
  },
  placeholder: {
    alignItems: 'center',
  },
};

const VISUALISATION_ICONS_MAPPING: Record<VisualisationType, JSX.Element> = {
  [VisualisationType.BAR_CHART]: <BarChartIcon />,
  [VisualisationType.BUBBLE_CHART]: <BubbleChartLineIcon />,
  [VisualisationType.CHLOROPLETH]: <MapLineIcon />,
};

type AnalyticsChoiceWidgetProps = {
  analytics: PortfolioAnalyticItem[];
};

export const AnalyticsChoiceWidget = ({ analytics }: AnalyticsChoiceWidgetProps) => {
  const [metric, setMetric] = React.useState('');
  const [metricBy, setMetricBy] = React.useState('');
  const [visualisationType, setVisualisationType] = React.useState<VisualisationType | undefined>(
    undefined
  );
  const [metricCalculationType, setMetricCalculationType] = React.useState<
    CalculationType | undefined
  >(undefined);

  const { isLoading: isNewPortfolioAnalyticCreating, mutateAsync: createPortfolioAnalytics } =
    useCreatePortfolioAnalyticsMutation();

  const metricOptions = mapMetricsToOptions(ALLOWED_METRIC_OPTIONS);
  const metricByOptions = mapMetricsToOptions(ALLOWED_METRIC_BY_OPTIONS);

  const selectedMetricLabel = metricOptions.find((option) => option.value === metric)?.label;
  const selectedMetricByLabel = metricByOptions.find((option) => option.value === metricBy)?.label;

  const visualizationTypeLabel = getVisualizationTypeLabel(visualisationType);
  const calculationTypeLabel = getCalculationTypeLabel(metricCalculationType);

  const allowedVisualizationTypes = getAllowedVisualizationTypes({ metric, metricBy });
  const allowedMetricCalculationTypes = getAllowedCalculationTypes(metric);

  const isWidgetDuplicate = analytics.some(
    (analytic) =>
      analytic.metric === metric &&
      analytic.metricBy === metricBy &&
      analytic.visualisationType === visualisationType
  );

  return (
    <Wrapper>
      <ChoiceForm
        onSubmit={async (event) => {
          event.preventDefault();

          try {
            if (metric && metricBy && visualisationType) {
              await createPortfolioAnalytics({
                metric,
                metricBy,
                visualisationType,
                metricCalculationType,
              });
              setMetric('');
              setMetricBy('');
              setVisualisationType(undefined);
              setMetricCalculationType(undefined);
            }
          } catch (error) {
            // TODO: display error message once error states are defined
          }
        }}
      >
        <Title>
          <Emphasis>Show me the...</Emphasis>
        </Title>

        <SelectWrapper>
          <Select
            label="Type of Metric"
            options={metricOptions}
            value={metric}
            onChange={(value) => {
              setMetric(String(value));
              setVisualisationType(undefined);
            }}
            styles={analyticsSelectStyles}
          />
        </SelectWrapper>

        {metric && allowedMetricCalculationTypes.length > 0 && (
          <ToggleGroup
            size="small"
            variant="spaced"
            value={String(metricCalculationType)}
            onChange={(_, value) => setMetricCalculationType(value as CalculationType)}
          >
            {allowedMetricCalculationTypes.map((calculationType) => (
              <ButtonToggle key={calculationType} value={calculationType}>
                {getCalculationTypeLabel(calculationType)}
              </ButtonToggle>
            ))}
          </ToggleGroup>
        )}

        <Text>by</Text>

        <SelectWrapper>
          <Select
            label="Type of Quantity"
            options={metricByOptions}
            value={metricBy}
            onChange={(value) => {
              setMetricBy(String(value));
              setVisualisationType(undefined);
            }}
            styles={analyticsSelectStyles}
          />
        </SelectWrapper>

        {metric && metricBy && allowedVisualizationTypes.length > 0 && (
          <React.Fragment>
            <Text>using</Text>

            <VisualisationToggleWrapper>
              <ToggleGroup
                size="large"
                variant="spaced"
                style={{ width: '100%' }}
                value={String(visualisationType)}
                onChange={(_, value) => setVisualisationType(value as VisualisationType)}
              >
                {allowedVisualizationTypes.map((visualisationType) => {
                  const IconComponent = VISUALISATION_ICONS_MAPPING[visualisationType];
                  return (
                    <ButtonToggle
                      key={visualisationType}
                      style={{ width: '100%' }}
                      size="large"
                      value={visualisationType}
                    >
                      {IconComponent}
                    </ButtonToggle>
                  );
                })}
              </ToggleGroup>
            </VisualisationToggleWrapper>
          </React.Fragment>
        )}

        {selectedMetricLabel && selectedMetricByLabel && (
          <Legend>
            E.g show me the {calculationTypeLabel && <Emphasis>{calculationTypeLabel}</Emphasis>}{' '}
            <Emphasis>{selectedMetricLabel}</Emphasis> by{' '}
            <Emphasis>{selectedMetricByLabel}</Emphasis>{' '}
            {visualizationTypeLabel && (
              <React.Fragment>
                using a <Emphasis>{visualizationTypeLabel}</Emphasis>
              </React.Fragment>
            )}
          </Legend>
        )}

        {isWidgetDuplicate && (
          <ErrorLegend>A widget with the same attributes already exists.</ErrorLegend>
        )}

        <Footer>
          <ButtonLoader
            disabled={!metric || !metricBy || !visualisationType || isWidgetDuplicate}
            isLoading={isNewPortfolioAnalyticCreating}
            type="submit"
            $size="medium"
          >
            Generate
          </ButtonLoader>
        </Footer>
      </ChoiceForm>
    </Wrapper>
  );
};
