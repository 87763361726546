import { TradeRequestDetails, UserIdentifier } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { createTradeRequest } from '../../trades/tradeRequestsUtils';
import { QueryKeys } from './types';

export type TradeRequestCreationMutation = {
  poolId: string;
  details?: TradeRequestDetails;
  stipulations: File[];
  counterparties: UserIdentifier[];
};

export const useCreateTradeRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: TradeRequestCreationMutation) => createTradeRequest(data), {
    onSuccess: (tradeRequest, { poolId }) => {
      // TODO: Consider returning trade request with summary so we can
      // manually update query cache instead of invalidating

      queryClient.invalidateQueries([QueryKeys.TRADE_REQUESTS_SUMMARY]);

      queryClient.invalidateQueries([QueryKeys.TRADE_REQUEST_BY_POOL_ID, poolId]);

      queryClient.invalidateQueries([QueryKeys.PORTFOLIO_SUMMARY]);
    },
  });
};
