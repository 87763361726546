import {
  BreakdownType,
  PortfolioHomeAnalyticGroupType,
  PortfolioSettings,
  RecommendedAnalyticType,
} from '@plus-platform/shared';
import xor from 'lodash/xor';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../components/Button';
import { PopupContent, PopupFooter } from '../../components/Popup';
import { Stack } from '../../components/Stack';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { AnalyticSlide, AnalyticSlider } from './AnalyticSlider';
import Default30DayPreview from './assets/30-day-default-preview.png';
import Default60DayPreview from './assets/60-day-default-preview.png';
import Default90DayPreview from './assets/90-day-default-preview.png';
import BrilliantPreview from './assets/brilliant-preview.png';
import LoanCountPreview from './assets/loan-count-preview.png';
import LoanTypesByNoteRatePreview from './assets/loan-types-by-note-rate-preview.png';
import LoanTypesPreview from './assets/loan-types-preview.png';
import LoansInDefaultPreview from './assets/loans-in-default-preview.png';
import {
  Analytic,
  Analytics,
  NoPreview,
  Preview,
  Section,
  SectionTitle,
  SelectableAnalytic,
  ToggleLabel,
  ToggleWrapper,
} from './EditDataPopup.styles';
import { Subtitle } from './styles';

const breakdown = [
  {
    id: BreakdownType.LOAN_COUNT,
    title: 'Loan count',
    previewImage: LoanCountPreview,
  },
  {
    id: BreakdownType.LOANS_IN_DEFAULT,
    title: 'Loans in default',
    previewImage: LoansInDefaultPreview,
  },
  {
    id: BreakdownType.LOAN_TYPES,
    title: 'Loan types',
    previewImage: LoanTypesPreview,
  },
  {
    id: BreakdownType.LOAN_TYPES_BY_NOTE_RATE,
    title: 'Loan types by note rate',
    previewImage: LoanTypesByNoteRatePreview,
  },
  {
    id: BreakdownType.DEFAULT_30_DAY,
    title: '30-day default',
    previewImage: Default30DayPreview,
  },
  {
    id: BreakdownType.DEFAULT_60_DAY,
    title: '60-day default',
    previewImage: Default60DayPreview,
  },
  {
    id: BreakdownType.DEFAULT_90_DAY,
    title: '90-day default',
    previewImage: Default90DayPreview,
  },
  {
    id: BreakdownType.VALUATION_METRICS,
    title: 'Valuation metrics',
  },
  {
    id: BreakdownType.RISK_METRICS,
    title: 'Risk metrics',
  },
  {
    id: BreakdownType.CREDIT_METRICS,
    title: 'Credit metrics',
  },
  {
    id: BreakdownType.INTEREST_RATE_SENSITIVITY,
    title: 'Interest rate sensitivity',
  },
];

const recommendedAnalytics = [
  {
    id: RecommendedAnalyticType.BRILLIANT,
    title: 'Brilliant',
    previewImage: BrilliantPreview,
    previewUrl: 'recommended/brilliant',
  },
];

type EditDataPopupProps = {
  settings: PortfolioSettings;
  onCancel: () => void;
  onUpdate: (settings: PortfolioSettings) => void;
};

export const EditDataPopup = (props: EditDataPopupProps) => {
  const { onCancel, settings } = props;
  const [selectedBreakdownTypes, setSelectedBreakdownTypes] = React.useState<BreakdownType[]>(
    settings?.breakdown ?? []
  );
  const [selectedRecommendedAnalytics, setSelectedRecommendedAnalytics] = React.useState<
    RecommendedAnalyticType[]
  >(settings?.analytics?.[PortfolioHomeAnalyticGroupType.RECOMMENDED] ?? []);
  const [selectedAnalytic, setSelectedAnalytic] = React.useState<
    (typeof recommendedAnalytics)[number] | undefined
  >();

  const history = useHistory();

  const isBreakdownTypeSelected = (type: BreakdownType) => {
    return selectedBreakdownTypes.includes(type);
  };

  const toggleBreakdownType = (type: BreakdownType) => {
    setSelectedBreakdownTypes((selectedBreakdownTypes) => xor(selectedBreakdownTypes, [type]));
  };

  const isRecommendedAnalyticSelected = (id: RecommendedAnalyticType) => {
    return selectedRecommendedAnalytics.includes(id);
  };

  const toggleRecommendedAnalytic = (id: RecommendedAnalyticType) => {
    setSelectedRecommendedAnalytics((selectedRecommendedAnalytics) =>
      xor(selectedRecommendedAnalytics, [id])
    );
  };

  const onUpdate = () => {
    props.onUpdate({
      breakdown: selectedBreakdownTypes,
      analytics: {
        [PortfolioHomeAnalyticGroupType.RECOMMENDED]: selectedRecommendedAnalytics,
      },
    });
  };

  const goToSelectedAnalyticPreview = () => {
    if (selectedAnalytic?.previewUrl) {
      history.push(`/home/data/${selectedAnalytic.previewUrl}`);
    }
  };

  return (
    <React.Fragment>
      <PopupContent>
        <Section>
          <SectionTitle>At a glance</SectionTitle>
          <Subtitle>Description text</Subtitle>

          <AnalyticSlider>
            {breakdown.map((breakdownType) => (
              <AnalyticSlide key={breakdownType.id}>
                <Analytic as="div">
                  {breakdownType.previewImage && (
                    <Preview src={breakdownType.previewImage} alt={breakdownType.title} />
                  )}

                  {!breakdownType.previewImage && <NoPreview />}

                  <ToggleWrapper>
                    <ToggleLabel id={breakdownType.id}>{breakdownType.title}</ToggleLabel>
                    <ToggleSwitch
                      $size="small"
                      onChange={() => toggleBreakdownType(breakdownType.id)}
                      checked={isBreakdownTypeSelected(breakdownType.id)}
                      aria-labelledby={breakdownType.id}
                    />
                  </ToggleWrapper>
                </Analytic>
              </AnalyticSlide>
            ))}
          </AnalyticSlider>
        </Section>

        <Section>
          <SectionTitle>Recommended</SectionTitle>
          <Subtitle>
            These recommendations are Plus-curated choices that we thing you’ll find useful.
          </Subtitle>

          <Analytics>
            {recommendedAnalytics.map((recommendedAnalytic) => (
              <SelectableAnalytic
                key={recommendedAnalytic.id}
                onClick={() => setSelectedAnalytic(recommendedAnalytic)}
                $isSelected={recommendedAnalytic === selectedAnalytic}
              >
                <Preview src={recommendedAnalytic.previewImage} alt={recommendedAnalytic.title} />
                <ToggleWrapper>
                  <ToggleLabel>{recommendedAnalytic.title}</ToggleLabel>
                  <ToggleSwitch
                    $size="small"
                    onClick={(event) => event.stopPropagation()}
                    onChange={() => toggleRecommendedAnalytic(recommendedAnalytic.id)}
                    checked={isRecommendedAnalyticSelected(recommendedAnalytic.id)}
                  />
                </ToggleWrapper>
              </SelectableAnalytic>
            ))}
          </Analytics>
        </Section>
      </PopupContent>

      <PopupFooter>
        <Stack>
          <Button $size="large" $color="secondary" $variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            $size="large"
            $color="secondary"
            $variant="outlined"
            disabled={!selectedAnalytic?.previewUrl}
            onClick={goToSelectedAnalyticPreview}
          >
            Preview
          </Button>
          <Button $size="large" onClick={onUpdate}>
            Edit
          </Button>
        </Stack>
      </PopupFooter>
    </React.Fragment>
  );
};
