import { ALL_LOANS_ATTRIBUTES, AttributeType, PermissionType } from '@plus-platform/shared';
import { Switch, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Button } from '../components/Button';
import { Heading } from '../components/Heading';
import { AddIcon } from '../components/icons';
import {
  Page,
  PageContent,
  PageHeader,
  PageSectionTitle,
  PageSectionTitleWrapper,
} from '../components/Page';
import { Stack } from '../components/Stack';
import { PrivateRoute } from '../global/PrivateRoute';
import { usePoolQuery } from '../hooks/queries';
import { LoanDetailedSummary } from '../loans/DeprecatedLoanSummary';
import { formatDefaultValue } from '../utils/formatUtils';
import { AddLoansToPoolPage } from './AddLoansToPoolPage';
import { DeletePoolPopup } from './DeletePoolPopup';
import { PoolMenu } from './PoolMenu';
import { PoolRemoveLoansTable } from './PoolRemoveLoansTable';

const ADD_TO_POOL_PATH = 'add-loans';
const DELETE_POOL_PATH = 'delete';

export const PoolPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.greenTree};
  border-radius: 6px;
`;

export const PoolWrapper = styled.div`
  padding: 16px;

  & + & {
    padding-top: 0;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

type PoolDetailPageRouteParams = {
  poolId: string;
};

export const PoolDetailPage = () => {
  const { poolId } = useParams<PoolDetailPageRouteParams>();
  const history = useHistory();
  const { data: poolData, isLoading } = usePoolQuery(Number(poolId));

  const poolName = poolData?.name;
  const summary = poolData?.summary;
  const loanIds = poolData?.loanIds ?? [];

  return (
    <Switch>
      <PrivateRoute
        path={`/pool/:poolId/${ADD_TO_POOL_PATH}`}
        render={() => <AddLoansToPoolPage poolName={poolName} summary={poolData?.summary} />}
        requiredPermissions={{
          [PermissionType.PERMISSION_EDIT]: [AttributeType.ATTRIBUTE_POOL],
          [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
        }}
      />
      <PrivateRoute
        path={`/pool/:poolId/${DELETE_POOL_PATH}`}
        render={() => (
          <DeletePoolPopup
            onCancel={() => history.goBack()}
            onSubmit={() => history.push('/pools')}
          />
        )}
        requiredPermissions={{
          [PermissionType.PERMISSION_DELETE]: [AttributeType.ATTRIBUTE_POOL],
          [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
        }}
      />
      <Page>
        <PageHeader>
          <Heading>{poolName && formatDefaultValue(poolName)}</Heading>
          <Breadcrumb>
            <BreadcrumbItem linkTo="/" title="Home" />
            <BreadcrumbItem linkTo="/pools" title="Pools" />
            {poolName && <BreadcrumbItem title={poolName} />}
          </Breadcrumb>
        </PageHeader>
        <PageContent>
          <PoolPanel style={{ marginBottom: 8 }}>
            <PoolWrapper>
              <PageSectionTitleWrapper>
                <PageSectionTitle>{formatDefaultValue(poolName)}</PageSectionTitle>
                <PoolMenu loansCount={summary?.loansCount} poolId={poolId} />
              </PageSectionTitleWrapper>
              <LoanDetailedSummary summary={summary} variant="pool" />
              {!isLoading && loanIds.length === 0 && (
                <Content>
                  <Stack $justifyContent="center">
                    <Button
                      $color="primary"
                      $size="medium"
                      $variant="outlined"
                      onClick={() => {
                        history.push(`/pool/${poolId}/${ADD_TO_POOL_PATH}`);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add loans from portfolio
                    </Button>
                    <Button
                      // TODO: Add duplication functionality
                      $color="primary"
                      $size="medium"
                      $variant="outlined"
                      startIcon={<AddIcon />}
                    >
                      Duplicate existing pool
                    </Button>
                  </Stack>
                </Content>
              )}
            </PoolWrapper>
          </PoolPanel>
          {!isLoading && loanIds.length > 0 && <PoolRemoveLoansTable poolId={poolId} />}
        </PageContent>
      </Page>
    </Switch>
  );
};
