import React from 'react';

export const ViewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0021 15.5455C12.8613 15.5455 13.5909 15.2447 14.1909 14.6433C14.7909 14.0419 15.0909 13.3116 15.0909 12.4524C15.0909 11.5932 14.7902 10.8636 14.1888 10.2636C13.5873 9.66364 12.857 9.36364 11.9979 9.36364C11.1387 9.36364 10.4091 9.66435 9.80909 10.2658C9.20909 10.8672 8.90909 11.5975 8.90909 12.4567C8.90909 13.3159 9.20981 14.0455 9.81124 14.6455C10.4127 15.2455 11.143 15.5455 12.0021 15.5455ZM11.9957 14.4909C11.4289 14.4909 10.9485 14.2925 10.5545 13.8957C10.1606 13.4989 9.96364 13.0171 9.96364 12.4503C9.96364 11.8834 10.162 11.403 10.5588 11.0091C10.9556 10.6152 11.4374 10.4182 12.0043 10.4182C12.5711 10.4182 13.0515 10.6166 13.4455 11.0134C13.8394 11.4102 14.0364 11.892 14.0364 12.4588C14.0364 13.0257 13.838 13.5061 13.4412 13.9C13.0444 14.2939 12.5626 14.4909 11.9957 14.4909ZM12 17.9091C10.2303 17.9091 8.6303 17.4061 7.2 16.4C5.7697 15.3939 4.70303 14.0788 4 12.4545C4.70303 10.8303 5.7697 9.51515 7.2 8.50909C8.6303 7.50303 10.2303 7 12 7C13.7697 7 15.3697 7.50303 16.8 8.50909C18.2303 9.51515 19.297 10.8303 20 12.4545C19.297 14.0788 18.2303 15.3939 16.8 16.4C15.3697 17.4061 13.7697 17.9091 12 17.9091ZM11.9969 16.8182C13.4656 16.8182 14.8152 16.4212 16.0455 15.6273C17.2758 14.8333 18.2121 13.7758 18.8545 12.4545C18.2121 11.1333 17.2768 10.0758 16.0485 9.28182C14.8203 8.48788 13.4718 8.09091 12.0031 8.09091C10.5344 8.09091 9.18485 8.48788 7.95455 9.28182C6.72424 10.0758 5.78182 11.1333 5.12727 12.4545C5.78182 13.7758 6.72322 14.8333 7.95147 15.6273C9.17974 16.4212 10.5282 16.8182 11.9969 16.8182Z"
        fill="currentColor"
      />
    </svg>
  );
};
