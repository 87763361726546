import {
  Pool,
  PoolCreation,
  PoolDetail,
  PoolsSummary,
  PoolWithTradeRequest,
} from '@plus-platform/shared';

import {
  deleteRequestWithAuth,
  getRequestWithAuth,
  makeApiUrl,
  postDownloadRequestWithAuth,
  postRequestWithAuth,
} from '../utils/apiUtils';

export const getPoolsWithSummary = () =>
  getRequestWithAuth<PoolsSummary>(makeApiUrl('pools/summary'));

export const getPoolDetail = (poolId: string) =>
  getRequestWithAuth<PoolDetail>(makeApiUrl(`pool/${poolId}`));

export const getPoolWithTradeRequest = (poolId: string) =>
  getRequestWithAuth<PoolWithTradeRequest>(makeApiUrl(`pool/${poolId}/trade-request`));

export const createPool = (data: PoolCreation) =>
  postRequestWithAuth<Pool>(makeApiUrl('pools'), {
    body: JSON.stringify(data),
  });

export const deletePool = (poolId: string) =>
  deleteRequestWithAuth<string>(makeApiUrl(`pool/${poolId}`));

export const exportPoolLoans = async (poolId: string) => {
  const url = makeApiUrl(`pool/${poolId}/loans/export`);
  return postDownloadRequestWithAuth(url);
};
