import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMODay,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMONumeric,
  MISMOPercent,
  MISMOString,
} from './types';

enum ExceptionInterestAdjustmentDayMethodType {
  ActualLastPaidInstallmentDueDate = 'ActualLastPaidInstallmentDueDate',
  FirstOfMonth = 'FirstOfMonth',
  ScheduledLastPaidInstallmentDueDate = 'ScheduledLastPaidInstallmentDueDate',
}

enum ExecutionType {
  Cash = 'Cash',
  Guarantor = 'Guarantor',
  Multilender = 'Multilender',
  Other = 'Other',
}

enum FundingInterestAdjustmentDayMethodType {
  ActualLastPaidInstallmentDueDate = 'ActualLastPaidInstallmentDueDate',
  FirstOfMonth = 'FirstOfMonth',
  ScheduledLastPaidInstallmentDueDate = 'ScheduledLastPaidInstallmentDueDate',
}

enum InvestorOwnershipType {
  Administered = 'Administered',
  Participation = 'Participation',
  Pending = 'Pending',
  Whole = 'Whole',
}

export enum InvestorRemittanceType {
  ActualInterestActualPrincipal = 'ActualInterestActualPrincipal',
  Other = 'Other',
  ScheduledInterestActualPrincipal = 'ScheduledInterestActualPrincipal',
  ScheduledInterestScheduledPrincipal = 'ScheduledInterestScheduledPrincipal',
}

enum InvestorReportingMethodType {
  ExceptionReporting = 'ExceptionReporting',
  FullReporting = 'FullReporting',
}

enum LoanBuyupBuydownType {
  Buydown = 'Buydown',
  Buyup = 'Buyup',
  BuyupBuydownDoesNotApply = 'BuyupBuydownDoesNotApply',
  Other = 'Other',
}

enum LoanDefaultedReportingFrequencyType {
  Annually = 'Annually',
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Semiannually = 'Semiannually',
  Semimonthly = 'Semimonthly',
  Weekly = 'Weekly',
}

export enum LoanDefaultLossPartyType {
  Investor = 'Investor',
  Lender = 'Lender',
  LoanSeller = 'LoanSeller',
  MortgageInsuranceCompany = 'MortgageInsuranceCompany',
  Servicer = 'Servicer',
  Shared = 'Shared',
}

enum PassThroughCalculationMethodType {
  BottomUp = 'BottomUp',
  TopDown = 'TopDown',
}

export enum RelatedLoanInvestorType {
  FNM = 'FNM',
  FRE = 'FRE',
  Other = 'Other',
  Seller = 'Seller',
  Unknown = 'Unknown',
}

export enum REOMarketingPartyType {
  Investor = 'Investor',
  Lender = 'Lender',
  MortgageInsuranceCompany = 'MortgageInsuranceCompany',
  Other = 'Other',
  Seller = 'Seller',
  Servicer = 'Servicer',
  Unknown = 'Unknown',
}

export type InvestorLoanInformation = MISMOEntity &
  Partial<{
    baseGuarantyFeePercent: MISMOPercent;
    concurrentServicingTransferIndicator: MISMOIndicator;
    exceptionInterestAdjustmentDayMethodType: ExceptionInterestAdjustmentDayMethodType;
    excessServicingFeeRatePercent: MISMOPercent;
    executionType: ExecutionType;
    executionTypeOtherDescription: MISMOString;
    FREPrimaryMortgageMarketSurveyPublishedDate: MISMODate;
    FREPrimaryMortgageMarketSurveyPublishedRatePercent: MISMOPercent;
    FREPrimaryMortgageMarketSurveyRoundedRatePercent: MISMOPercent;
    fundingInterestAdjustmentDayMethodType: FundingInterestAdjustmentDayMethodType;
    guarantyFeeAddOnIndicator: MISMOIndicator;
    guarantyFeeAfterAlternatePaymentMethodPercent: MISMOPercent;
    guarantyFeePercent: MISMOPercent;
    interestVarianceToleranceMaximumAmount: MISMOAmount;
    investorCollateralProgramIdentifier: MISMOIdentifier;
    investorCommitmentTakeoutPricePercent: MISMOPercent;
    investorCreditPolicyEffectiveDate: MISMODate;
    investorDealIdentifier: MISMOIdentifier;
    investorGroupingIdentifier: MISMOIdentifier;
    investorGuarantyFeeAfterBuyupBuydownPercent: MISMOPercent;
    investorHeldCollateralPercent: MISMOPercent;
    investorInstitutionIdentifier: MISMOIdentifier;
    investorLoanCommitmentExpirationDate: MISMODate;
    investorLoanEarlyPayoffPenaltyWaiverIndicator: MISMOIndicator;
    investorLoanEffectiveDate: MISMODate;
    investorLoanPartialPrepaymentPenaltyWaiverIndicator: MISMOIndicator;
    investorMasterCommitmentIdentifier: MISMOIdentifier;
    investorOwnershipPercent: MISMOPercent;
    investorOwnershipType: InvestorOwnershipType;
    investorProductPlanIdentifier: MISMOIdentifier;
    investorRemittanceDay: MISMODay;
    investorRemittanceDaysCount: MISMOCount;
    investorRemittanceIdentifier: MISMOIdentifier;
    investorRemittanceType: InvestorRemittanceType;
    investorRemittanceTypeOtherDescription: MISMOString;
    investorReportingMethodType: InvestorReportingMethodType;
    investorRequiredMarginPercent: MISMOPercent;
    investorServicingFeeAmount: MISMOAmount;
    investorServicingFeeRatePercent: MISMOPercent;
    lastReportedToInvestorPrincipalBalanceAmount: MISMOAmount;
    lateDeliveryGraceDaysCount: MISMOCount;
    lenderTargetFundingDate: MISMODate;
    loanAcquisitionScheduledUPBAmount: MISMOAmount;
    loanBuyupBuydownBasisPointNumber: MISMONumeric;
    loanBuyupBuydownType: LoanBuyupBuydownType;
    loanBuyupBuydownTypeOtherDescription: MISMOString;
    loanDefaultedReportingFrequencyType: LoanDefaultedReportingFrequencyType;
    loanDefaultLossPartyType: LoanDefaultLossPartyType;
    loanDelinquencyAdvanceDaysCount: MISMOCount;
    loanFundingAdvancedAmount: MISMOAmount;
    loanPriorOwnerName: MISMOString;
    loanPurchaseDiscountAmount: MISMOAmount;
    loanPurchasePremiumAmount: MISMOAmount;
    loanServicingFeeBasisPointNumber: MISMONumeric;
    loanServicingIndicator: MISMOIndicator;
    loanServicingRightsPurchaseOnlyIndicator: MISMOIndicator;
    loanYieldSpreadPremiumAmount: MISMOAmount;
    loanYieldSpreadPremiumRatePercent: MISMOPercent;
    MICoverageServicingFeeRatePercent: MISMOPercent;
    nonStandardServicingFeeRetainedByServicerAmount: MISMOAmount;
    passThroughCalculationMethodType: PassThroughCalculationMethodType;
    passThroughCeilingRatePercent: MISMOPercent;
    passThroughRatePercent: MISMOPercent;
    principalVarianceToleranceMaximumAmount: MISMOAmount;
    relatedInvestorLoanIdentifier: MISMOIdentifier;
    relatedLoanInvestorType: RelatedLoanInvestorType;
    relatedLoanInvestorTypeOtherDescription: MISMOString;
    removeFromTransferIndicator: MISMOIndicator;
    REOMarketingPartyType: REOMarketingPartyType;
    REOMarketingPartyTypeOtherDescription: MISMOString;
    servicingFeeMinimumRatePercent: MISMOPercent;
    thirdPartyLoanAcquisitionPriceAmount: MISMOAmount;
    UPBVarianceToleranceMaximumAmount: MISMOAmount;
    warehouseAdvanceAmount: MISMOAmount;
    warehouseCommitmentSubcategoryIdentifier: MISMOIdentifier;
    wholeLoanInterestDueAmount: MISMOAmount;
    wholeLoanPrincipalAmount: MISMOAmount;
    wholeLoanSaleDate: MISMODate;
  }>;
