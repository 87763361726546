import { GetPortfolioLoansDocumentSummaryResponse } from '@plus-platform/shared';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import * as api from '../../api/portfolio';
import { QueryKeys } from './types';

const DEFAULT_ITEMS_PER_PAGE = 10;

export const usePortfolioLoansDocumentSummaryQuery = (
  filterIds: number[],
  itemCount = DEFAULT_ITEMS_PER_PAGE,
  sortBy = 'id'
): UseInfiniteQueryResult<GetPortfolioLoansDocumentSummaryResponse> =>
  useInfiniteQuery(
    [QueryKeys.PORTFOLIO_LOANS_DOCUMENT_SUMMARY, itemCount, filterIds, sortBy],
    async ({ pageParam = 0 }) => {
      return api.getPortfolioLoansDocumentSummary(filterIds, pageParam, itemCount, sortBy);
    },
    {
      getNextPageParam: (lastPage) => (lastPage !== undefined ? lastPage.pageNumber : 0),
    }
  );
