import { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { NudeButton } from '../components/Button';
import { ResetDataModal } from './ResetDataModal/ResetDataModal';

const ResetButton = styled(NudeButton)`
  ${({ theme }) => css`
    color: ${theme.colors.redSecondary};
  `}
`;

export const DataReset = () => {
  const [isRestModalOpen, setIsResetModalOpen] = useState<boolean>(false);

  return (
    <>
      <ResetButton onClick={() => setIsResetModalOpen(true)}>Reset Data</ResetButton>
      {isRestModalOpen && (
        <ResetDataModal isOpen={isRestModalOpen} onClose={() => setIsResetModalOpen(false)} />
      )}
    </>
  );
};
