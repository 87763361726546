import React from 'react';
import { Redirect } from 'react-router-dom';

import loginLogo from '../assets/login-logo.svg';
import { ButtonLoader } from '../components/ButtonLoader';
import { useLoginMutation } from '../hooks/queries';
import {
  ActionsWrapper,
  ButtonWrapper,
  ErrorText,
  Input,
  Inputs,
  LeftHalf,
  LoginForm,
  RightHalf,
  Subtitle,
  Title,
  Wrapper,
} from './Login.styles';

export const Login = () => {
  const { error, isError, isLoading, isSuccess, mutate } = useLoginMutation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const isFormValid = Boolean(email.trim());

  if (isSuccess) {
    return <Redirect to="/home" />;
  }

  return (
    <Wrapper>
      <LeftHalf>
        <img alt="Plus logo" src={loginLogo} />
      </LeftHalf>
      <RightHalf>
        <LoginForm
          onSubmit={(event) => {
            event.preventDefault();

            if (isFormValid) {
              mutate({ email, password });
            }
          }}
        >
          <Title>Sign into Plus Platform</Title>
          <Subtitle>View and manage all of the loans in your portfolio</Subtitle>

          {isError && (
            <ErrorText>{error instanceof Error ? error.message : 'Something went wrong'}</ErrorText>
          )}
          <Inputs>
            <Input
              $isInvalid={isError}
              autoComplete="email"
              id="login-email"
              name="login-email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
              placeholder="Email"
              required
              title="Email"
              type="email"
              value={email}
              data-testid="Login_Email"
            />
            <Input
              $isInvalid={isError}
              id="login-password"
              name="login-password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
              placeholder="Password"
              required
              title="Password"
              type="password"
              value={password}
              data-testid="Login_Password"
            />
          </Inputs>
          <ActionsWrapper>
            <div>Forgot email/password?</div>
            <div>Create new Plus account</div>
          </ActionsWrapper>

          <ButtonWrapper>
            <ButtonLoader
              $isFullWidth
              $size="large"
              isLoading={isLoading}
              title="Login"
              type="submit"
              data-testid="Login_Button"
            >
              Login
            </ButtonLoader>
          </ButtonWrapper>
        </LoginForm>
      </RightHalf>
    </Wrapper>
  );
};
