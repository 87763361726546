import { transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { CreateUserInvitationResponse } from '../../hooks/queries/useCreateUserInvitationMutation';
import { ModalButton } from '../AdminModal/AdminModal';

const RoleBlock = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.greenMain};
    border: 0.5px solid ${theme.colors.greenMain};
    font-family: ${theme.typography.fontFamily.text};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${theme.colors.white};
    margin: 16px 0;
    height: 80px;
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border: 0.5px solid ${theme.colors.greenMain};
    padding: 15px 24px;
    margin-bottom: 16px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: ${theme.colors.greenMain};
    margin-top: 0;
    text-transform: uppercase;
  `}
`;

const BodyText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${theme.colors.background01};
    padding: 0 64px;
  `}
`;

const InviteEmail = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.background01};

    span {
      font-family: ${theme.typography.fontFamily.text};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: right;
      color: ${transparentize(0.5, theme.colors.background01)};
    }
  `}
`;

type InviteUserSuccessProps = {
  onClose: () => void;
  invite?: CreateUserInvitationResponse;
};

export const InviteUserSuccess = ({ invite, onClose }: InviteUserSuccessProps) => {
  return (
    <React.Fragment>
      <Container>
        <Title>Congratulations</Title>

        <BodyText>
          Your new Plus user has been sent an invitation to join the Plus Platform. They'll be able
          to start using their account as soon as they complete the registration process by
          inputting their details and setting a password. Typically, this only takes a few minutes.
        </BodyText>

        {invite && (
          <InviteEmail>
            <span>Username: </span>
            {` `} {invite?.email}
          </InviteEmail>
        )}
      </Container>
      {invite && (
        <RoleBlock>Your new Plus user has been created with {invite?.role} permissions.</RoleBlock>
      )}
      <ButtonWrapper>
        <ModalButton onClick={onClose}>Done</ModalButton>
      </ButtonWrapper>
    </React.Fragment>
  );
};
