import { PortfolioAnalyticItem } from '@plus-platform/shared';
import { Route, Switch, useParams } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { ButtonLink } from '../../components/ButtonLink';
import { Heading } from '../../components/Heading.styles';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { Skeleton } from '../../components/Skeleton';
import { useGetPortfolioAnalyticsById } from '../../hooks/queries';
import { AnalyticDetailWidget } from './AnalyticDetailWidget';
import { AnalyticGroupDetailPage } from './AnalyticGroupDetailPage';
import { AnalyticsMenu, AnalyticsMenuItem, AnalyticsWrapper } from './Analytics.styles';
import { getLabelFromAnalyticResult } from './utils';

type AnalyticDetailProps = {
  analytic?: PortfolioAnalyticItem;
  isLoading: boolean;
};

const AnalyticDetail = ({ analytic, isLoading }: AnalyticDetailProps) => {
  if (!analytic) {
    return null;
  }

  const title = analytic ? getLabelFromAnalyticResult(analytic) : '';

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>{title}</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem linkTo="/analytics" title="Analytics" />
              <BreadcrumbItem title={title} />
            </Breadcrumb>
          </div>
        </PageHeaderContent>
      </PageHeader>
      <PageContent>
        <AnalyticsMenu>
          <ButtonLink to="">
            <AnalyticsMenuItem>All loans</AnalyticsMenuItem>
          </ButtonLink>
          <ButtonLink to="#">
            <AnalyticsMenuItem>Types of loans</AnalyticsMenuItem>
          </ButtonLink>
          <ButtonLink to="#">
            <AnalyticsMenuItem>Changes</AnalyticsMenuItem>
          </ButtonLink>
          <ButtonLink to="#">
            <AnalyticsMenuItem>Location</AnalyticsMenuItem>
          </ButtonLink>
        </AnalyticsMenu>

        <AnalyticsWrapper>
          {isLoading && <Skeleton height={307} />}

          {!isLoading && analytic && <AnalyticDetailWidget hasLink analytic={analytic} />}

          {!isLoading && !analytic && <p>No data available</p>}
        </AnalyticsWrapper>
      </PageContent>
    </Page>
  );
};

type AnalyticParams = {
  analyticId: string;
};

export const AnalyticPage = () => {
  const { analyticId } = useParams<AnalyticParams>();

  const { data: analyticData, isLoading } = useGetPortfolioAnalyticsById(analyticId);

  return (
    <Switch>
      <Route
        component={() => <AnalyticDetail analytic={analyticData} isLoading={isLoading} />}
        path="/analytics/:analyticId"
        exact
      />
      <Route
        component={() => (
          <AnalyticGroupDetailPage analytic={analyticData} isAnalyticLoading={isLoading} />
        )}
        path="/analytics/:analyticId/group/:groupValue"
      />
    </Switch>
  );
};
