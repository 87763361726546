import React from 'react';
import styled, { css } from 'styled-components/macro';

import { NavLink } from '../../../components/Link';

export const ContentWrapper = styled.div`
  position: relative;
  padding-left: 168px;
  flex: 1;
`;

export const MainContent = styled.div`
  flex: 1;
`;

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.white05};
  padding-right: 8px;
  overflow-y: auto;
  width: 160px;
`;

export const RateTypeLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding: 12px;
  aspect-ratio: 1;
  min-width: 132px;

  &.active,
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary02};
  }
`;

export const RateTypeTitle = styled.h5`
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography.bodyText.bodyMedium02Tight}
`;

export const RateTypeSubtitle = styled.h6`
  color: ${({ theme }) => theme.colors.white70};

  ${({ theme }) => theme.typography.bodyText.bodyMedium04}
`;

export const BubbleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 72px;
`;

type BubbleProps = {
  $size: Exclude<React.CSSProperties['width'], undefined>;
};

export const Bubble = styled.div<BubbleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  aspect-ratio: 1;
  width: ${(props) => props.$size}%;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography.headings.headingMedium02}

  ${(props) =>
    props.$size > 70 &&
    css`
      ${({ theme }) => theme.typography.headings.headingMedium01}
    `}


  ${(props) =>
    props.$size <= 50 &&
    css`
      ${({ theme }) => theme.typography.headings.headingMedium03}
    `}
`;
