import { AggregatedTradeCounterparty, TradeQuoteWithSummary } from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { QuoteSummaryType } from '../types';

type OutboundQuoteCounterbackRejectedProps = {
  date: TradeQuoteWithSummary['createdAt'];
  fromCounterparty: AggregatedTradeCounterparty;
};

export const OutboundQuoteCounterbackRejected = ({
  date,
  fromCounterparty,
}: OutboundQuoteCounterbackRejectedProps) => {
  return (
    <PlusMessage date={date}>
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.Quote.Counterback.Rejected"
          values={{
            counterType: QuoteSummaryType.COUNTERBACK.toLowerCase(),
            userName: formatNameWithInitial(
              fromCounterparty.user.lastName,
              fromCounterparty.user.firstName
            ),
            organizationName: fromCounterparty.user.organization.tradingName,
          }}
        />
      </MessageText>
    </PlusMessage>
  );
};
