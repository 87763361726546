import { Loan } from '@plus-platform/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { Card } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import { AlertIcon, BubbleChartFillIcon, LineGraphIcon } from '../../../components/icons';
import { Key, Pair, Value, ValueWithAlert } from '../../../components/KeyValue';
import { FullWidthLoaderWrapper, Loader } from '../../../components/Loader';
import { ButtonToggle } from '../../../components/Toggles/ButtonToggle';
import { LinkToggle } from '../../../components/Toggles/LinkToggle';
import { ToggleGroup } from '../../../components/Toggles/ToggleGroup';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import {
  formatCompactMonetary,
  formatDate,
  formatMonetary,
  formatPercentage,
} from '../../../utils/formatUtils';
import { LoanPropertyValuationsData, useLoanAmortization } from '../../LoanData';
import { LoanChart } from '../../LoanPropertyValuationHistory/LoanChart';
import {
  ChartHeader,
  ChartSection,
  ChartsWrapper,
} from '../../LoanPropertyValuationHistory/LoanChart.styles';
import { PropertyValuationHistoryChart } from '../../LoanPropertyValuationHistory/PropertyValuationHistoryChart';
import { generateDateTicks } from '../../LoanPropertyValuationHistory/utils';

type LoanDetailsFinancialsProps = {
  loan: Loan;
};

const LoanDetailFinancials = React.forwardRef<HTMLDivElement, LoanDetailsFinancialsProps>(
  ({ loan }, ref) => {
    const theme = useTheme();

    const location = useLocation();

    const { data: loanAmortizationData, isLoading } = useLoanAmortization();

    const amortizationSchedule = loanAmortizationData?.amortizationSchedule || [];

    const nextLines = amortizationSchedule.slice(0, 5);

    return (
      <Card $hasPadding ref={ref} id="financials" data-testid="LoanDetail_Financials">
        <Heading $size="medium">Financials</Heading>
        <WidgetSectionWrapper>
          <WidgetSection>
            <Pair $size="large" $align="right">
              <Key>Purchase Price</Key>
              <Value>{formatMonetary(loan.loanProperty.property.purchasePrice)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Current Value</Key>
              <Value>{formatMonetary(loan.loanProperty.property.currentBpo)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Current Value Date</Key>
              <Value>
                {formatDate(
                  loan.loanProperty.property.currentBpoDate
                    ? new Date(loan.loanProperty.property.currentBpoDate)
                    : undefined
                )}
              </Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Down Payment</Key>
              <Value>{formatMonetary(loan.loanProperty.property.downPaymentAmount)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Original UPB</Key>
              <Value>{formatMonetary(loan.financials.originalUPB)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Current UPB</Key>
              <Value>{formatMonetary(loan.financials.currentUPB)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Current LTV</Key>
              <Value>{formatPercentage(loan.financials.currentLTV)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Total Monthly Payment</Key>
              <Value>{formatMonetary(loan.financials.totalMonthlyPayment)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Investor Remittance</Key>
              <Value>{formatMonetary(loan.financials.investorRemittance.remittanceAmount)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Servicing Fee Rate</Key>
              <Value>
                {formatPercentage(loan.financials.investorRemittance.yearlyServicingFeeRate)}
              </Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Escrow Monthly Payment</Key>
              <ValueWithAlert>
                {formatMonetary(loan.loanEscrow.escrowMonthlyPaymentAmount)}
                {!loan.loanEscrow.isEscrowMonthlyPaymentCompliant && (
                  <AlertIcon color={theme.colors.redSecondary} height="12px" width="12px" />
                )}
              </ValueWithAlert>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Principal</Key>
              <Value>{formatMonetary(loan.financials.principal)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Interest</Key>
              <Value>{formatMonetary(loan.financials.interest)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Taxes</Key>
              <Value>{formatMonetary(loan.financials.taxes)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Insurance</Key>
              <Value>{formatMonetary(loan.financials.insurance)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>HOA Fee</Key>
              <Value>{formatMonetary(loan.financials.hoaFee)}</Value>
            </Pair>
            <Pair $size="large" $align="right">
              <Key>Mortgage Insurance</Key>
              <Value>{formatMonetary(loan.financials.mortgageInsurance)}</Value>
            </Pair>
          </WidgetSection>

          <ChartsWrapper>
            <ChartSection>
              <ChartHeader>
                <Heading $size="medium">Valuation history</Heading>
                <ToggleGroup size="small">
                  <LinkToggle to={`${location.pathname}#financials?view=bubble`} exact>
                    <BubbleChartFillIcon />
                  </LinkToggle>
                  <ButtonToggle value="line">
                    <LineGraphIcon />
                  </ButtonToggle>
                </ToggleGroup>
              </ChartHeader>

              <React.Suspense
                fallback={
                  <FullWidthLoaderWrapper>
                    <Loader />
                  </FullWidthLoaderWrapper>
                }
              >
                <LoanPropertyValuationsData>
                  <PropertyValuationHistoryChart />
                </LoanPropertyValuationsData>
              </React.Suspense>
            </ChartSection>

            <ChartSection>
              <LoanChart
                title="LTV"
                chartProps={{
                  domain: {
                    y: [0, 1],
                  },
                }}
                xAxisProps={{
                  tickValues: generateDateTicks([new Date(loan.loanTerms.startingDate)]),
                }}
                yAxisProps={{
                  tickFormat: (y) => formatPercentage(y),
                }}
                data={[
                  {
                    x: new Date(loan.loanTerms.startingDate),
                    y: loan.financials.currentLTV,
                  },
                ]}
              />
            </ChartSection>
            <ChartSection>
              <LoanChart
                title="Escrow payments"
                chartProps={{
                  domain: {
                    y: [0, loan.financials.totalMonthlyPayment * 2],
                  },
                }}
                xAxisProps={{
                  tickValues: generateDateTicks([new Date(loan.loanTerms.startingDate)]),
                }}
                yAxisProps={{
                  tickFormat: (y) => formatMonetary(y),
                }}
                data={[]}
              />
            </ChartSection>
            <ChartSection>
              <LoanChart
                title="UPB"
                chartProps={{
                  domain: {
                    y: [0, loan.financials.currentUPB * 2],
                  },
                }}
                xAxisProps={{
                  tickValues: generateDateTicks([new Date(loan.loanTerms.startingDate)]),
                }}
                yAxisProps={{
                  tickFormat: (y) => `$${formatCompactMonetary(y)}`,
                }}
                data={[
                  {
                    x: new Date(loan.loanTerms.startingDate),
                    y: loan.financials.currentUPB,
                  },
                ]}
              />
            </ChartSection>
            <ChartSection>
              <LoanChart
                title="Amortization"
                isLoading={isLoading}
                chartProps={{
                  domain: {
                    y: [0, Math.max(...nextLines.map((line) => line.scheduledPaymentAmount)) * 2],
                  },
                }}
                xAxisProps={{
                  tickValues: nextLines.map((line) => new Date(line.paymentDueDate)),
                }}
                yAxisProps={{
                  tickFormat: (y) => formatMonetary(y),
                }}
                data={nextLines.map((line) => ({
                  x: new Date(line.paymentDueDate),
                  y: line.scheduledPaymentAmount,
                }))}
              />
            </ChartSection>
          </ChartsWrapper>
        </WidgetSectionWrapper>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailFinancials;
