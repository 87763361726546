import React from 'react';

export const ConduitLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08 8V16H4.62V8H4.08ZM3.9 7C3.40294 7 3 7.3731 3 7.83333V16.1667C3 16.6269 3.40294 17 3.9 17H4.8C5.29706 17 5.7 16.6269 5.7 16.1667H5.70005H6.60005C7.09711 16.1667 7.50005 15.7936 7.50005 15.3333H16.5H16.5C16.5 15.7936 16.9029 16.1667 17.4 16.1667H18.3C18.3 16.6269 18.7029 17 19.2 17H20.1C20.5971 17 21 16.6269 21 16.1667V7.83333C21 7.3731 20.5971 7 20.1 7H19.2C18.7029 7 18.3 7.3731 18.3 7.83333L17.4 7.83333C16.9029 7.83333 16.5 8.20643 16.5 8.66667L16.5 8.66667H7.50005C7.50005 8.20643 7.09711 7.83333 6.60005 7.83333H5.70005H5.7C5.7 7.3731 5.29706 7 4.8 7H3.9ZM19.38 8V16H19.92V8H19.38ZM5.88005 15.1667V8.83333H6.42005V15.1667H5.88005ZM17.58 8.83333V15.1667H18.12V8.83333H17.58ZM7.67998 14.3333V9.66667H16.32V14.3333H7.67998ZM8.40002 10.75C8.40002 10.5199 8.6015 10.3333 8.85002 10.3333H12.45C12.6986 10.3333 12.9 10.5199 12.9 10.75C12.9 10.9801 12.6986 11.1667 12.45 11.1667H8.85002C8.6015 11.1667 8.40002 10.9801 8.40002 10.75ZM14.25 10.3333C14.0015 10.3333 13.8 10.5199 13.8 10.75C13.8 10.9801 14.0015 11.1667 14.25 11.1667H15.15C15.3986 11.1667 15.6 10.9801 15.6 10.75C15.6 10.5199 15.3986 10.3333 15.15 10.3333H14.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
