import { ZipCodeData } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type ZipCodeDataResponse = ZipCodeData;

export const getZipCodeData = async (zipCode: string) => {
  if (!zipCode) {
    return null;
  }
  return getRequestWithAuth<ZipCodeDataResponse>(makeApiUrl(`zip-code-data/${zipCode}`));
};

export const useZipCodeDataQuery = (zipCode: string) =>
  useQuery([QueryKeys.ZIP_CODE_DATA, zipCode], () => {
    return getZipCodeData(zipCode);
  });
