import {
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { AdjustmentRuleType } from './types';

enum PerChangeInterestRateRoundingTimingType {
  RoundRateAfterCheckingMinimumIndexRateMovement = 'RoundRateAfterCheckingMinimumIndexRateMovement',
  RoundRateBeforeCheckingMinimumIndexRateMovement = 'RoundRateBeforeCheckingMinimumIndexRateMovement',
}

export type InterestRatePerChangeAdjustmentRule = MISMOEntity &
  Partial<{
    adjustmentRuleType: AdjustmentRuleType;
    noInterestRateCapsFeatureDescription: MISMOString;
    paymentsBetweenRateChangeNoticesCount: MISMOCount;
    paymentsBetweenRateChangesCount: MISMOCount;
    paymentsToFirstInterestRateValueCount: MISMOCount;
    perChangeCeilingRatePercent: MISMOPercent;
    perChangeFactorOfIndexPercent: MISMOPercent;
    perChangeFloorRatePercent: MISMOPercent;
    perChangeInterestRateRoundingTimingType: PerChangeInterestRateRoundingTimingType;
    perChangeMaximumDecreaseRatePercent: MISMOPercent;
    perChangeMaximumIncreaseRatePercent: MISMOPercent;
    perChangeMinimumDecreaseRatePercent: MISMOPercent;
    perChangeMinimumIncreaseRatePercent: MISMOPercent;
    perChangeRateAdjustmentCalculationMethodDescription: MISMOString;
    perChangeRateAdjustmentEffectiveDate: MISMODate;
    perChangeRateAdjustmentEffectiveMonthsCount: MISMOCount;
    perChangeRateAdjustmentFrequencyMonthsCount: MISMOCount;
    perChangeRateAdjustmentPaymentsCount: MISMOCount;
    sequenceNumber: MISMOSequenceNumber;
  }>;
