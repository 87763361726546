import { OutboundTradeRequestWithSummary } from '@plus-platform/shared';
import capitalize from 'lodash/capitalize';
import { Switch, useParams } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { PrivateRoute } from '../global/PrivateRoute';
import { Dashboard as TradeRequestDashboard } from './TradeRequestDashboard';
import { TradeRequestDetailPage } from './TradeRequestDetailPage';
import { TradeRequestTransactionLogPage } from './TradeRequestTransactionLogPage';
import { useTradeRequestSummary } from './useTradeRequestSummary';

type TradeRequestPageParams = {
  category: string;
  tradeId: string;
};

export const TradeRequestPage = () => {
  const { category, tradeId } = useParams<TradeRequestPageParams>();
  const { data: tradeRequest, isLoading } = useTradeRequestSummary<OutboundTradeRequestWithSummary>(
    tradeId,
    category
  );

  return (
    <Switch>
      <PrivateRoute
        path="/trade-requests/:category/:tradeId/transaction-log"
        render={() => (
          <TradeRequestTransactionLogPage isLoading={isLoading} tradeRequest={tradeRequest} />
        )}
      />
      <PrivateRoute
        path={[
          '/trade-requests/:category/:tradeId/:counterpartyId/:tabType',
          '/trade-requests/:category/:tradeId/:counterpartyId',
        ]}
        render={() => <TradeRequestDetailPage />}
      />
      <Page>
        <PageHeader>
          <Heading>Trade dashboard</Heading>
          <Breadcrumb>
            <BreadcrumbItem linkTo="/" title="Home" />
            <BreadcrumbItem linkTo="/trade-requests" title="Trade activity" />
            <BreadcrumbItem linkTo={`/trade-requests/${category}`} title={capitalize(category)} />
            <BreadcrumbItem title="Trade dashboard" />
          </Breadcrumb>
        </PageHeader>
        <PageContent>
          <TradeRequestDashboard isLoading={isLoading} tradeRequest={tradeRequest} />
        </PageContent>
      </Page>
    </Switch>
  );
};
