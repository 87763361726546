import { useMutation } from 'react-query';

import { makeApiUrl, postFormDataRequestWithAuth } from '../../utils/apiUtils';
import { AddLoanDocumentMutation } from '.';

const addLoanDocument = (data: AddLoanDocumentMutation) => {
  const { file, loanNumber, type } = data;

  const url = makeApiUrl(`loans/${loanNumber}/documents`);
  const body = new FormData();

  body.append('document', file);
  body.append('type', type);

  return postFormDataRequestWithAuth(url, { body });
};

export const useAddLoanDocumentMutation = () => {
  return useMutation(addLoanDocument);
};
