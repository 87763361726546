import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Row, Table as TableComponent, Tbody } from '../../components/Table';
import { WidthWrapper } from '../../global/WidthWrapper';

export const Wrapper = styled(WidthWrapper)`
  padding: 24px 16px;
`;

export const CountLabel = styled.div(
  ({ theme }) => css`
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    font-family: ${theme.typography.fontFamily.text};
    color: ${transparentize(0.5, theme.colors.white)};

    strong {
      color: ${theme.colors.white};
      margin: 0 4px;
    }
  `
);

export const Table = styled(TableComponent)`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 0;
`;

export const TableRow = styled(Row)`
  grid-template-columns: 60px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
`;

export const TableBody = styled(Tbody)`
  overflow-y: auto;
`;
