import React from 'react';

export const StipulationsLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.11111 16.4444H13.5556V14.6667H8.11111V16.4444ZM8.11111 12.8889H15.8889V11.1111H8.11111V12.8889ZM8.11111 9.33333H15.8889V7.55556H8.11111V9.33333ZM6.55556 20C6.12778 20 5.76157 19.8259 5.45694 19.4778C5.15231 19.1296 5 18.7111 5 18.2222V5.77778C5 5.28889 5.15231 4.87037 5.45694 4.52222C5.76157 4.17407 6.12778 4 6.55556 4H17.4444C17.8722 4 18.2384 4.17407 18.5431 4.52222C18.8477 4.87037 19 5.28889 19 5.77778V18.2222C19 18.7111 18.8477 19.1296 18.5431 19.4778C18.2384 19.8259 17.8722 20 17.4444 20H6.55556ZM6.55556 18.2222H17.4444V5.77778H6.55556V18.2222Z"
        fill="currentColor"
      />
    </svg>
  );
};
