import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { SECTION_RADIUS } from '../styles/radiuses';

export const PAGE_MAX_WIDTH = 1408;
export const PAGE_MIN_WIDTH = 1180;
export const PAGE_MIN_MARGIN = 16;

export const Page = styled.div<{ $withoutHeader?: boolean }>(
  ({ $withoutHeader, theme }) => css`
    display: grid;
    grid-template-columns:
      [page-start] minmax(${PAGE_MIN_MARGIN}px, auto) [main-start] minmax(
        ${PAGE_MIN_WIDTH}px,
        ${PAGE_MAX_WIDTH}px
      )
      [main-end] minmax(${PAGE_MIN_MARGIN}px, auto) [page-end];
    grid-auto-rows: ${$withoutHeader ? `auto` : `max-content 1fr`};
    row-gap: 8px;
    height: calc(100% - 58px);
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: ${theme.colors.backgroundPage};
  `
);

export const PageHeader = styled.header<{
  $isSticky?: boolean;
}>`
  ${({ $isSticky }) => css`
    grid-column: main-start / main-end;
    align-items: center;

    ${$isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 3;
    `}
  `}
`;

export const PageHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageTitle = styled.h2`
  ${({ theme }) => css`
    display: inline-flex;
    gap: 8px;
    color: ${theme.colors.white90};
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    line-height: 30px;
  `};
`;

export const PageSectionTitle = styled.h2`
  ${({ theme }) => css`
    margin: 0;
    font-family: ${theme.typography.fontFamily.display};
    font-size: 18px;
    font-weight: 700;
    color: ${transparentize(0.1, theme.colors.white)};
    padding: 0 0 16px;
  `}
`;

export const PageSectionSubtitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.display};
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: ${transparentize(0.1, theme.colors.white)};
  `}
`;

export const PageSectionContent = styled.div`
  margin-top: 12px;
  padding-top: 12px;
`;

export const PageSectionTitleWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  ${PageSectionTitle} {
    padding-bottom: 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

type PageSectionProps = {
  $isFullHeight?: boolean;
  $hasFlex?: boolean;
};

export const PageSection = styled.div<PageSectionProps>`
  ${({ $hasFlex, $isFullHeight, theme }) => css`
    background: ${theme.colors.backgroundPageSection};
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 8px;

    ${$isFullHeight &&
    css`
      margin-bottom: 0;
    `}

    ${$hasFlex &&
    css`
      display: flex;
      flex-direction: column;
    `}

    ${$isFullHeight &&
    $hasFlex &&
    css`
      flex: 1;
      min-height: 0;
    `}
  `}
`;

export const PageSubSection = styled.div`
  background: ${({ theme }) => theme.colors.purpleDark};
  border-radius: ${SECTION_RADIUS};
  padding: 16px;

  & + & {
    margin-top: 10px;
  }
`;

type PageContentProps = {
  $hasFlex?: boolean;
};

export const PageContent = styled.div<PageContentProps>`
  ${({ $hasFlex }) => css`
    grid-column: main-start / main-end;

    ${{ $hasFlex } &&
    css`
      display: flex;
      flex-direction: column;
      /* Required for overflow when using flex for height of parent container */
      min-height: 0;
    `}
  `}
`;
