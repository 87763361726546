export const WeatherIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.84375 14.875C8.34375 15.375 9.03125 15.6562 9.75 15.6562C10.4062 15.6562 11.0625 15.4062 11.5625 14.9375C12 15.3438 12.5938 15.5312 13.2188 15.5312C13.8438 15.5312 14.4688 15.2812 14.9062 14.8438C15.0938 14.875 15.375 14.9375 15.5312 14.9375C16.8438 14.9375 17.9062 13.875 17.9062 12.5625C17.9062 11.4688 17.1875 10.5625 16.1562 10.25C16.1562 10.125 16.1875 10 16.1875 9.84375C16.1875 7.5625 14.3438 5.71875 12.0625 5.71875C10.5938 5.71875 9.25 6.5 8.5 7.75C8.1875 7.59375 7.84375 7.5 7.46875 7.46875C7.46875 7.4375 7.4375 7.40625 7.4375 7.375C6.96875 6.53125 6.09375 6.03125 5.125 6.03125C3.6875 6.03125 2.5 7.1875 2.5 8.59375C2.5 9.40625 2.90625 10.1875 3.5625 10.6562C3.125 11.0937 2.84375 11.6875 2.84375 12.3438C2.84375 13.625 3.84375 14.6562 5.125 14.7188C6.0625 15.4688 6.96875 15.5 7.84375 14.875ZM4.46875 10.1562C4.46875 10.1562 4.4375 10.125 4.46875 10.1562C4.4375 10.125 4.375 10.125 4.34375 10.0938C3.78125 9.8125 3.4375 9.25 3.4375 8.625C3.4375 7.71875 4.21875 6.96875 5.15625 6.96875C5.6875 6.96875 6.21875 7.21875 6.53125 7.65625C5.625 7.96875 5 8.84375 5 9.84375C5 9.90625 5 9.9375 5 10C4.8125 10.0313 4.625 10.0938 4.46875 10.1562Z"
        fill="currentColor"
      />
      <path
        d="M18.7812 2.46875C18.7812 2.4375 18.7812 2.375 18.7812 2.34375C18.7812 1.0625 17.7188 0 16.4375 0C15.625 0 14.9062 0.40625 14.4688 1.03125C14.3125 0.96875 14.125 0.9375 13.9375 0.9375C13.1562 0.9375 12.5312 1.5625 12.5312 2.3125C11.9062 2.4375 11.4062 3.03125 11.4062 3.6875C11.4062 4.4375 11.9688 5.0625 12.7188 5.09375C13.1875 5.46875 13.7188 5.5 14.1875 5.1875C14.4688 5.4375 14.8438 5.5625 15.2188 5.5625C15.5625 5.5625 15.9062 5.4375 16.1875 5.21875C16.4375 5.40625 16.75 5.5 17.0625 5.5C17.4062 5.5 17.75 5.375 18 5.15625C18.0938 5.1875 18.2188 5.1875 18.2813 5.1875C19.0625 5.1875 19.7188 4.5625 19.7188 3.78125C19.7188 3.1875 19.3438 2.65625 18.7812 2.46875Z"
        fill="currentColor"
      />
      <path
        d="M4.84375 5.59375C5.09375 5.59375 5.28125 5.40625 5.28125 5.15625V4.3125C5.28125 4.0625 5.09375 3.875 4.84375 3.875C4.59375 3.875 4.40625 4.0625 4.40625 4.3125V5.15625C4.40625 5.40625 4.59375 5.59375 4.84375 5.59375Z"
        fill="currentColor"
      />
      <path
        d="M2.25027 6.40625C2.34402 6.5 2.43777 6.53125 2.56277 6.53125C2.68777 6.53125 2.81277 6.46875 2.87527 6.40625C3.06277 6.21875 3.03152 5.9375 2.87527 5.78125L2.25027 5.1875C2.15652 5.09375 2.06277 5.0625 1.93777 5.0625C1.81277 5.0625 1.68777 5.125 1.62527 5.1875C1.43777 5.375 1.46902 5.65625 1.62527 5.8125L2.25027 6.40625Z"
        fill="currentColor"
      />
      <path
        d="M2.0625 8.3125C2.0625 8.0625 1.875 7.875 1.625 7.875H0.71875C0.46875 7.875 0.28125 8.0625 0.28125 8.3125C0.28125 8.5625 0.46875 8.75 0.71875 8.75H1.59375C1.84375 8.78125 2.0625 8.5625 2.0625 8.3125Z"
        fill="currentColor"
      />
      <path
        d="M2.875 10.25C2.78125 10.1562 2.6875 10.125 2.5625 10.125C2.4375 10.125 2.34375 10.1562 2.25 10.25L1.625 10.8438C1.4375 11.0313 1.4375 11.3125 1.625 11.4688C1.71875 11.5625 1.8125 11.5938 1.9375 11.5938C2.0625 11.5938 2.15625 11.5625 2.25 11.4688L2.875 10.875C3.0625 10.7187 3.0625 10.4062 2.875 10.25Z"
        fill="currentColor"
      />
      <path
        d="M7.125 6.53125C7.25 6.53125 7.34375 6.5 7.4375 6.40625L8.0625 5.8125C8.25 5.625 8.25 5.34375 8.0625 5.1875C7.96875 5.09375 7.875 5.0625 7.75 5.0625C7.625 5.0625 7.53125 5.09375 7.4375 5.1875L6.8125 5.78125C6.625 5.96875 6.625 6.25 6.8125 6.40625C6.90625 6.46875 7 6.53125 7.125 6.53125Z"
        fill="currentColor"
      />
      <path
        d="M14.9062 16.4375C14.625 16.3125 14.2812 16.125 13.625 16.125C12.9687 16.125 12.625 16.3125 12.3437 16.4375C12.0937 16.5625 11.9062 16.6563 11.4687 16.6563C11.0312 16.6563 10.8438 16.5625 10.5938 16.4375C10.3125 16.3125 9.96875 16.125 9.3125 16.125C8.65625 16.125 8.3125 16.3125 8.03125 16.4375C7.78125 16.5625 7.59375 16.6563 7.15625 16.6563C6.71875 16.6563 6.53125 16.5625 6.28125 16.4375C6 16.3125 5.65625 16.125 5 16.125C4.75 16.125 4.5625 16.3125 4.5625 16.5625C4.5625 16.8125 4.75 17 5 17C5.4375 17 5.625 17.0938 5.875 17.2188C6.15625 17.3438 6.5 17.5312 7.15625 17.5312C7.8125 17.5312 8.15625 17.3438 8.4375 17.2188C8.6875 17.0938 8.875 17 9.3125 17C9.75 17 9.9375 17.0938 10.1875 17.2188C10.4687 17.3438 10.8125 17.5312 11.4687 17.5312C12.125 17.5312 12.4687 17.3438 12.75 17.2188C13 17.0938 13.1875 17 13.625 17C14.0625 17 14.25 17.0938 14.5 17.2188C14.7812 17.3438 15.125 17.5312 15.7812 17.5312C16.0312 17.5312 16.2188 17.3438 16.2188 17.0938C16.2188 16.8438 16.0312 16.6563 15.7812 16.6563C15.375 16.6563 15.1562 16.5625 14.9062 16.4375Z"
        fill="currentColor"
      />
      <path
        d="M18 18.125C17.6875 18.125 17.5625 18.0625 17.4062 17.9688C17.1875 17.875 16.9062 17.75 16.4375 17.75C15.9375 17.75 15.6562 17.875 15.4688 17.9688C15.2812 18.0625 15.1562 18.125 14.875 18.125C14.5625 18.125 14.4375 18.0625 14.2812 17.9688C14.0625 17.875 13.7812 17.75 13.3125 17.75C12.8125 17.75 12.5625 17.875 12.3437 17.9688C12.1562 18.0625 12.0312 18.125 11.75 18.125C11.4375 18.125 11.3125 18.0625 11.1562 17.9688C10.9375 17.875 10.6562 17.75 10.1875 17.75C9.9375 17.75 9.75 17.9375 9.75 18.1875C9.75 18.4375 9.9375 18.625 10.1875 18.625C10.5 18.625 10.625 18.6875 10.7812 18.7812C11 18.875 11.2812 19 11.75 19C12.25 19 12.5 18.875 12.7188 18.7812C12.9062 18.6875 13.0312 18.625 13.3125 18.625C13.625 18.625 13.75 18.6875 13.9062 18.7812C14.125 18.875 14.4062 19 14.875 19C15.375 19 15.6562 18.875 15.8438 18.7812C16.0312 18.6875 16.1562 18.625 16.4375 18.625C16.75 18.625 16.875 18.6875 17.0312 18.7812C17.25 18.875 17.5312 19 18 19C18.25 19 18.4375 18.8125 18.4375 18.5625C18.4375 18.3438 18.25 18.125 18 18.125Z"
        fill="currentColor"
      />
      <path
        d="M9.78125 19.0937C9.625 19.0937 9.5625 19.0625 9.4375 19C9.28125 18.9375 9.09375 18.8438 8.71875 18.8438C8.34375 18.8438 8.15625 18.9375 8 19C7.875 19.0625 7.8125 19.0937 7.65625 19.0937C7.5 19.0937 7.4375 19.0625 7.3125 19C7.15625 18.9375 6.96875 18.8438 6.59375 18.8438C6.21875 18.8438 6.03125 18.9375 5.875 19C5.75 19.0625 5.6875 19.0937 5.53125 19.0937C5.375 19.0937 5.3125 19.0625 5.1875 19C5.03125 18.9375 4.84375 18.8438 4.46875 18.8438C4.21875 18.8438 4.03125 19.0313 4.03125 19.2813C4.03125 19.5312 4.21875 19.7187 4.46875 19.7187C4.625 19.7187 4.6875 19.75 4.8125 19.8125C4.96875 19.875 5.15625 19.9688 5.53125 19.9688C5.90625 19.9688 6.09375 19.875 6.25 19.8125C6.375 19.75 6.4375 19.7187 6.59375 19.7187C6.75 19.7187 6.8125 19.75 6.9375 19.8125C7.09375 19.875 7.28125 19.9688 7.65625 19.9688C8.03125 19.9688 8.21875 19.875 8.375 19.8125C8.5 19.75 8.5625 19.7187 8.71875 19.7187C8.875 19.7187 8.9375 19.75 9.0625 19.8125C9.21875 19.875 9.40625 19.9688 9.78125 19.9688C10.0312 19.9688 10.2188 19.7812 10.2188 19.5312C10.2188 19.3125 10.0312 19.0937 9.78125 19.0937Z"
        fill="currentColor"
      />
    </svg>
  );
};
