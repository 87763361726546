import styled from 'styled-components/macro';

import type { ButtonStyleProps } from './Button.styles';
import * as Styles from './Button.styles';

export const DefaultButton = styled.button`
  width: 100%;
  border: 0;
  outline: 0;
  appearance: none;
  height: 34px;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
  font-family: ${(props) => props.theme.typography.fontFamily.display};
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
`;

export const NudeButton = styled.button`
  width: 100%;
  margin: 0;
  padding: 0;
  appearance: none;
  border: 0;
  outline: 0;
  text-align: inherit;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
`;

export type ButtonProps = Omit<ButtonStyleProps, '$hasStartIcon | $hasEndIcon'> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
  };

export const Button = ({
  $color = 'primary',
  $isFullWidth = false,
  $size = 'medium',
  $variant = 'contained',
  children,
  endIcon,
  startIcon,
  ...rest
}: ButtonProps) => {
  return (
    <Styles.Button
      $color={$color}
      $variant={$variant}
      $size={$size}
      $isFullWidth={$isFullWidth}
      $hasStartIcon={Boolean(startIcon)}
      $hasEndIcon={Boolean(endIcon)}
      {...rest}
    >
      {startIcon}
      {children}
      {endIcon}
    </Styles.Button>
  );
};
