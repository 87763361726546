export type OrganizationIdentifier = number;

export enum OrganizationType {
  ORGANIZATION_SERVICER = 'ORGANIZATION_SERVICER',
  ORGANIZATION_INVESTOR = 'ORGANIZATION_INVESTOR',
  ORGANIZATION_ORIGINATOR = 'ORGANIZATION_ORIGINATOR',
  ORGANIZATION_CUSTODIAN = 'ORGANIZATION_CUSTODIAN',
  ORGANIZATION_TEMP = 'ORGANIZATION_TEMP',
  ORGANIZATION_SYSTEM = 'ORGANIZATION_SYSTEM',
}

export type OrganizationCreation = {
  tradingName: string;
  legalEntityName: string;
  address?: string;
  type: OrganizationType;
  uid?: string;
  servicerId?: string;
};

export type Organization = OrganizationCreation & {
  id: OrganizationIdentifier;
};

export enum OrganizationAttributeType {
  ATTRIBUTE_ORGANIZATION = 'ATTRIBUTE_ORGANIZATION',
  ATTRIBUTE_ORGANIZATION_USER = 'ATTRIBUTE_ORGANIZATION_USER',
  ATTRIBUTE_ORGANIZATION_INVITE = 'ATTRIBUTE_ORGANIZATION_INVITE',
}

export type OrganizationMember = {
  fullName: string;
  email: string;
  status: string;
  role: string;
  createdAt: Date;
  phoneNumber: string;
  inviteId?: string;
};
