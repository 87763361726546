import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from './types';

export enum HighCostJurisdictionType {
  City = 'City',
  County = 'County',
  Other = 'Other',
  State = 'State',
}

export type HighCostMortgage = MISMOEntity &
  Partial<{
    averagePrimeOfferRatePercent: MISMOPercent;
    highCostJurisdictionName: MISMOString;
    highCostJurisdictionType: HighCostJurisdictionType;
    highCostJurisdictionTypeOtherDescription: MISMOString;
    HOEPA_APRInterestRatePercent: MISMOPercent;
    HOEPARateThresholdPercent: MISMOPercent;
    HOEPATotalPointsAndFeesThresholdAmount: MISMOAmount;
    HOEPATotalPointsAndFeesThresholdPercent: MISMOPercent;
    preDiscountedInterestRatePercent: MISMOPercent;
    regulationZExcludedBonaFideDiscountPointsIndicator: MISMOIndicator;
    regulationZExcludedBonaFideDiscountPointsPercent: MISMOPercent;
    regulationZHighCostLoanDisclosureDeliveryDate: MISMODate;
    regulationZTotalAffiliateFeesAmount: MISMOAmount;
    regulationZTotalLoanAmount: MISMOAmount;
    regulationZTotalPointsAndFeesAmount: MISMOAmount;
    regulationZTotalPointsAndFeesPercent: MISMOPercent;
  }>;
