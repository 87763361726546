import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ForeclosureCaseDismissedReasonType {
  CourtOrdered = 'CourtOrdered',
  LossMitigationCompleted = 'LossMitigationCompleted',
  LossMitigationInReview = 'LossMitigationInReview',
  Other = 'Other',
  Reinstatement = 'Reinstatement',
  Unknown = 'Unknown',
}

enum ForeclosureMethodType {
  Judicial = 'Judicial',
  NonJudicial = 'NonJudicial',
  Other = 'Other',
}

export type Foreclosure = MISMOEntity &
  Partial<{
    activeForeclosureIndicator: MISMOIndicator;
    bidActualAmount: MISMOAmount;
    bidMinimumAmount: MISMOAmount;
    bidOpenAmount: MISMOAmount;
    daysToForeclosureSaleCount: MISMOCount;
    deficiencyRightsPreservedIndicator: MISMOIndicator;
    foreclosureCaseDismissedReasonType: ForeclosureCaseDismissedReasonType;
    foreclosureCaseDismissedReasonTypeOtherDescription: MISMOString;
    foreclosureCaseIdentifier: MISMOIdentifier;
    foreclosureDelayIndicator: MISMOIndicator;
    foreclosureFeeAmount: MISMOAmount;
    foreclosureMethodType: ForeclosureMethodType;
    foreclosureMethodTypeOtherDescription: MISMOString;
    foreclosurePerformanceMaximumDaysCount: MISMOCount;
    foreclosureProcedureEndedDate: MISMODate;
    foreclosureSaleProceedsAmount: MISMOAmount;
    foreclosureSaleProjectedDate: MISMODate;
    foreclosureSaleTotalSubordinateLienholderPayoutAmount: MISMOAmount;
    IRSLienNotificationIndicator: MISMOIndicator;
    redemptionExercisedIndicator: MISMOIndicator;
    redemptionPeriodExpirationDate: MISMODate;
    redemptionPeriodTermMonthsCount: MISMOCount;
    sequenceNumber: MISMOSequenceNumber;
    // foreclosureActions: ForeclosureAction[];
    // foreclosureDelays: ForeclosureDelay[];
    // foreclosureExpenses: ForeclosureExpense[];
    // foreclosureExpenseSummary: ForeclosureExpenseSummary;
    // foreclosureStatuses: ForeclosureStatus[];
  }>;
