import React from 'react';
import styled, { css } from 'styled-components/macro';

// @todo: this could be moved to a more generic location
import { ErrorText } from '../Login.styles';

type CreateAccountErrorMessageProps = {
  errorMessage: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.display};
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: ${theme.colors.statusBlack};
  `}
`;

// @todo: This could probably be made into something more generic
// for use in other places, but not we'll namespace it under create account
export const CreateAccountErrorMessage = ({ errorMessage }: CreateAccountErrorMessageProps) => {
  return (
    <Container>
      <Title>Something went wrong</Title>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Container>
  );
};
