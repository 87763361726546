import { Identifier } from '.';

export enum CustodianAttributeType {
  ATTRIBUTE_CUSTODIAN = 'ATTRIBUTE_CUSTODIAN',
}

export type CustodianIdentifier = Identifier;

export type CustodianCreation = {
  name: string;
  fullAddress: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Custodian = CustodianCreation & {
  id: CustodianIdentifier;
};
