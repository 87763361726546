import {
  MISMOCount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from './types';

enum AdditionalProjectConsiderationsType {
  CondoHotel = 'CondoHotel',
  Other = 'Other',
  Timeshare = 'Timeshare',
}

export enum CondominiumProjectStatusType {
  Established = 'Established',
  New = 'New',
}

export enum ProjectAttachmentType {
  Attached = 'Attached',
  Detached = 'Detached',
}

export enum ProjectDesignType {
  GardenProject = 'GardenProject',
  HighriseProject = 'HighriseProject',
  MidriseProject = 'MidriseProject',
  Other = 'Other',
  TownhouseRowhouse = 'TownhouseRowhouse',
}

enum ProjectEligibilityDeterminationType {
  FullReview = 'FullReview',
  LimitedReview = 'LimitedReview',
  ProjectEligibilityReviewService = 'ProjectEligibilityReviewService',
}

export enum ProjectLegalStructureType {
  CommonInterestApartment = 'CommonInterestApartment',
  Condominium = 'Condominium',
  Cooperative = 'Cooperative',
  Unknown = 'Unknown',
}

enum ProjectPrimaryUsageType {
  Investment = 'Investment',
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
  Tenant = 'Tenant',
}

export enum ProjectClassificationIdentifier {
  CondominiumProjectManagerReview = 'CondominiumProjectManagerReview',
  ExemptFromReview = 'ExemptFromReview',
  ReciprocalReview = 'ReciprocalReview',
  FHAApproved = 'FHAApproved',
  FullReview = 'FullReview',
  Other = 'Other',
  ProjectEligibilityReviewService = 'ProjectEligibilityReviewService',
  StreamlinedReview = 'StreamlinedReview',
  EstablishedProject = 'EstablishedProject',
  NewProject = 'NewProject',
  FannieMaeE = 'FannieMaeE',
  FannieMaeF = 'FannieMaeF',
  FannieMaeP = 'FannieMaeP',
  FannieMaeQ = 'FannieMaeQ',
  FannieMaeR = 'FannieMaeR',
  FannieMaeS = 'FannieMaeS',
  FannieMaeT = 'FannieMaeT',
  FannieMaeU = 'FannieMaeU',
  FannieMaeV = 'FannieMaeV',
  FannieMae1 = 'FannieMae1',
  FannieMae2 = 'FannieMae2',
}

export type Project = MISMOEntity &
  Partial<{
    additionalProjectConsiderationsType: AdditionalProjectConsiderationsType;
    additionalProjectConsiderationsTypeOtherDescription: MISMOString;
    condominiumProjectStatusType: CondominiumProjectStatusType;
    condominiumPUDDeclarationsDescription: MISMOString;
    FNMCondominiumProjectManagerProjectIdentifier: MISMOIdentifier;
    projectAmenityImprovementsCompletedIndicator: MISMOIndicator;
    projectAttachmentType: ProjectAttachmentType;
    projectClassificationIdentifier: ProjectClassificationIdentifier;
    projectCommercialSpaceDescription: MISMOString;
    projectCommercialSpaceIndicator: MISMOIndicator;
    projectCommercialSpacePercent: MISMOPercent;
    projectCommonElementLeasedIndicator: MISMOIndicator;
    projectCommonElementLeaseTermsDescription: MISMOString;
    projectContainsMultipleDwellingUnitsDataSourceDescription: MISMOString;
    projectContainsMultipleDwellingUnitsIndicator: MISMOIndicator;
    projectDesignType: ProjectDesignType;
    projectDesignTypeOtherDescription: MISMOString;
    projectDwellingUnitCount: MISMOCount;
    projectDwellingUnitsSoldCount: MISMOCount;
    projectEligibilityDeterminationType: ProjectEligibilityDeterminationType;
    projectLegalStructureType: ProjectLegalStructureType;
    projectName: MISMOString;
    projectPrimaryUsageType: ProjectPrimaryUsageType;
    PUDIndicator: MISMOIndicator;
    twoToFourUnitCondominiumProjectIndicator: MISMOIndicator;
    establishedProjectIndicator: MISMOIndicator;
    // conversion: Conversion;
    // dataSources: DataSource[];
    // phases: Phase[];
    // projectAnalysis: ProjectAnalysis;
    // projectBlanketFinancings: ProjectBlanketFinancing[];
    // projectCarStorages: ProjectCarStorage[];
    // projectCommonElements: ProjectCommonElement[];
    // projectDeveloper: ProjectDeveloper;
    // projectFinancialInformation: ProjectFinancialInformation;
    // projectHousingUnitInventories: ProjectHousingUnitInventory[];
    // projectManagement: ProjectManagement;
    // projectOwnership: ProjectOwnership;
    // projectRatings: ProjectRating[];
  }>;
