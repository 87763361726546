import { PortfolioAnalytics, VisualisationType } from '@plus-platform/shared';

import { makeApiUrl, putRequestWithAuth } from '../../utils/apiUtils';

export type UpdatePortfolioAnalyticsProps = {
  id: string;
  visualisationType?: VisualisationType;
};

export const updatePortfolioAnalytics = async ({
  id,
  visualisationType,
}: UpdatePortfolioAnalyticsProps) => {
  const url = makeApiUrl(`portfolio/analytics/${id}`);
  return putRequestWithAuth<PortfolioAnalytics['id']>(url, {
    body: JSON.stringify({ visualisationType }),
  });
};
