import { formatTime } from '../../utils/formatUtils';
import * as Styles from './Message.styles';

type MessageProps = {
  userName: string;
  organizationName?: string;
  icon: React.ReactNode;
  date?: Date;
  children: React.ReactNode;
};

export const Message = ({
  children,
  date,
  icon,
  organizationName,
  userName,
  ...rest
}: MessageProps) => {
  return (
    <Styles.Message {...rest}>
      <Styles.Header>
        {icon}
        <Styles.UserName>{userName}</Styles.UserName>
        {organizationName && <Styles.Meta>{organizationName}</Styles.Meta>}
        {date && (
          <Styles.Meta>
            {formatTime(new Date(date), {
              hour: 'numeric',
              minute: 'numeric',
              hour12: false,
            })}
          </Styles.Meta>
        )}
      </Styles.Header>
      {children}
    </Styles.Message>
  );
};

export const MessageText = Styles.MessageText;
export const MessageActions = Styles.MessageActions;
