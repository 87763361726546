import { CloseLineIcon } from '../components/icons';
import * as Styled from './Popover.styles';

type PopoverProps = {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
  variant?: 'portfolio' | 'pool';
  width?: number;
  isInset?: boolean;
};

export const Popover = (props: PopoverProps) => {
  const { children, isInset, onClose, title, variant, width } = props;

  return (
    <Styled.Wrapper $isInset={isInset} $variant={variant} $width={width}>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        <Styled.CloseButton onClick={() => onClose()} type="button">
          <CloseLineIcon height={16} width={16} />
        </Styled.CloseButton>
      </Styled.Header>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export const PopoverScrollable = Styled.Scrollable;
