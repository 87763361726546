import { ellipsis } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Heading } from '../../../components/Heading.styles';
import { TitleKey } from '../../../components/KeyValue';
import { HeaderCell } from '../../../components/Table';

export const TableChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TableRow = styled.div`
  display: grid;
  column-gap: 24px;
  grid-template-columns: 2fr 1fr 1fr 1.5fr;
`;

export const BHeaderCell = styled(HeaderCell)(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyMedium02};
    color: ${theme.colors.white};
    height: unset;

    &:first-of-type {
      text-align: right;
    }
  `
);

export const Cell = styled.div<{ align?: 'left' | 'center' | 'right' }>(
  ({ align, theme }) => css`
    display: flex;
    width: auto;
    text-align: ${align || 'left'};
    color: ${theme.colors.white};
    ${ellipsis()}
    ${theme.typography.bodyText.bodyRegular02}

    &:first-child {
      justify-content: flex-end;
      text-align: ${align || 'right'};
      color: ${theme.colors.white70};
    }
  `
);

export const BorrowerHeading = styled(Heading)`
  padding: 0;
  line-height: 14px;
`;

export const BorrowersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

export const BorrowerHeadingGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BorrowerCount = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.white70};
    ${theme.typography.headings.headingMedium03};
  `
);

export const CreditScoresChartWrapper = styled.div``;

export const ChartHeading = styled(TitleKey)`
  margin-left: 57px;
  width: auto;
  display: inline-block;
`;
