import { SubmissionErrorAttributes } from '@plus-platform/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodyTextLink } from '../components/BodyTextLink';
import { Page, PageContent } from '../components/Page';
import { DocumentStepModal } from '../documents/DocumentStepModal';
import { FileTypeShortName } from '../documents/LoanFromDocuments.styles';
import { SubmissionStep } from '../documents/submissions/submissionUtils';
import { SubmissionParams } from '../documents/types';
import { useGetSubmissionDocumentsQuery } from '../hooks/queries/useGetSubmissionDocumentsQuery';
import { SubmissionBreakdown } from './SubmissionBreakdown';
import * as Styles from './SubmissionPage.styles';
import {
  getDocumentType,
  getErrorCount,
  getFilesProcessedOrErrored,
  getIsSubmissionComplete,
  GroupedOriginName,
  groupValidationByOcrolusUuid,
  groupValidationErrors,
} from './utils';

type DocumentValidationTableProps = {
  errorName: string;
  submissionErrors?: SubmissionErrorAttributes[];
};

const DocumentValidationTable = (props: DocumentValidationTableProps) => {
  const { errorName, submissionErrors } = props;
  return (
    <Styles.DocumentErrorWrapper>
      <Styles.DocumentErrorHeader>
        {errorName} issues ({getErrorCount(submissionErrors)})
      </Styles.DocumentErrorHeader>
      {(submissionErrors ?? []).map((error) => (
        <Styles.DocumentErrorContent key={error.id}>{error.message}</Styles.DocumentErrorContent>
      ))}
    </Styles.DocumentErrorWrapper>
  );
};

export const SubmissionPage = () => {
  const [isCreatingLoan, setIsCreatingLoan] = useState(false);
  const params = useParams<SubmissionParams>();

  const { data: submissionData } = useGetSubmissionDocumentsQuery(params?.submissionId);
  if (!submissionData) {
    return null;
  }

  const groupedErrors = groupValidationErrors(submissionData.validationErrors || []);
  const groupedByDocument = groupValidationByOcrolusUuid(groupedErrors);

  const completePercent =
    getFilesProcessedOrErrored(submissionData) / submissionData.filesReceived ?? 0;
  const isComplete = getIsSubmissionComplete(submissionData);

  const hasValidatedDocuments =
    isComplete &&
    submissionData &&
    submissionData?.documents &&
    submissionData?.documents?.length > 0;

  const activeStep = isComplete
    ? isCreatingLoan
      ? SubmissionStep.CREATE
      : SubmissionStep.VALIDATE
    : SubmissionStep.INGEST;

  return (
    <Page>
      <PageContent>
        <DocumentStepModal
          step={activeStep}
          completePercent={completePercent}
          submissionNumber={submissionData.submissionNumber}
        >
          <SubmissionBreakdown
            isCreatingLoan={isCreatingLoan}
            onSetIsCreatingLoan={() => setIsCreatingLoan(true)}
            isIngestionComplete={isComplete}
            submission={submissionData}
            groupedErrors={groupedErrors}
          />
        </DocumentStepModal>
        <Styles.ValidationWrapper $hasBackground={hasValidatedDocuments}>
          {hasValidatedDocuments && (
            <Styles.ValidationHeader>
              Issues identified for submission {submissionData?.submissionNumber}
            </Styles.ValidationHeader>
          )}
          <Styles.ValidationContent>
            {!hasValidatedDocuments ? (
              <Styles.EmptyWrapper>
                The ingestion process may take some time
                <Styles.IngestionMessage>
                  Feel free to leave this page and we'll notify you once the process is complete
                </Styles.IngestionMessage>
                <BodyTextLink to="/portfolio/create-single-loan">
                  Upload more documents
                </BodyTextLink>{' '}
                | <BodyTextLink to="/documents/submissions">Go to submissions list</BodyTextLink>
              </Styles.EmptyWrapper>
            ) : (
              <Styles.ValidationGrid>
                <div />
                <Styles.ErrorHeading>
                  Via OCR ({groupedErrors[GroupedOriginName.OCR].length})
                </Styles.ErrorHeading>
                <Styles.ErrorHeading>
                  Via MISMO 3.4/ULDD/UCD ({groupedErrors[GroupedOriginName.SMD].length})
                </Styles.ErrorHeading>
                <Styles.ErrorHeading>
                  Via Plus ({groupedErrors[GroupedOriginName.CUSTOM].length})
                </Styles.ErrorHeading>
                {(submissionData.documents || []).map((document) => (
                  <>
                    <FileTypeShortName>{getDocumentType(document.documentType)}</FileTypeShortName>
                    <DocumentValidationTable
                      errorName="OCR"
                      submissionErrors={
                        groupedByDocument[GroupedOriginName.OCR][document.ocrolusUuid]
                      }
                    />
                    <DocumentValidationTable
                      errorName="MISMO 3.4/ULDD/UCD"
                      submissionErrors={
                        groupedByDocument[GroupedOriginName.SMD][document.ocrolusUuid]
                      }
                    />
                    <DocumentValidationTable
                      errorName="Plus"
                      submissionErrors={
                        groupedByDocument[GroupedOriginName.CUSTOM][document.ocrolusUuid]
                      }
                    />
                  </>
                ))}
              </Styles.ValidationGrid>
            )}
          </Styles.ValidationContent>
        </Styles.ValidationWrapper>
      </PageContent>
    </Page>
  );
};
