import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { IndexRule } from './indexRule';

enum ConversionOptionPeriodFeeBalanceCalculationType {
  CurrentBalanceAtConversion = 'CurrentBalanceAtConversion',
  OriginalBalance = 'OriginalBalance',
}

enum ConversionOptionPeriodRoundingType {
  Down = 'Down',
  Nearest = 'Nearest',
  NoRounding = 'NoRounding',
  Up = 'Up',
}

enum ConversionOptionPeriodType {
  OnDemand = 'OnDemand',
  OnDemandAtInterestRateChangeDates = 'OnDemandAtInterestRateChangeDates',
  OnDemandMonthly = 'OnDemandMonthly',
  Scheduled = 'Scheduled',
}

enum ConversionScheduleType {
  Anytime = 'Anytime',
  OnFirstAndSecondRateAdjustment = 'OnFirstAndSecondRateAdjustment',
  OnFirstThroughFifthRateAdjustment = 'OnFirstThroughFifthRateAdjustment',
  OnFirstThroughThirdRateAdjustment = 'OnFirstThroughThirdRateAdjustment',
  OnSecondThroughTenthRateAdjustment = 'OnSecondThroughTenthRateAdjustment',
  OnThirdThroughFifthRateAdjustment = 'OnThirdThroughFifthRateAdjustment',
  Other = 'Other',
}

enum ConversionType {
  Other = 'Other',
  ToBiweeklyPaymentFrequency = 'ToBiweeklyPaymentFrequency',
  ToFixedRate = 'ToFixedRate',
  ToFullyAmortizingTerm = 'ToFullyAmortizingTerm',
  ToLevelPayment = 'ToLevelPayment',
  ToLowerInterestRate = 'ToLowerInterestRate',
  ToMonthlyPaymentFrequency = 'ToMonthlyPaymentFrequency',
}

type ConversionAdjustmentLifetimeAdjustmentRule = MISMOEntity &
  Partial<{
    conversionDateMaximumExtensionMonthsCount: MISMOCount;
    conversionMaximumAllowedCount: MISMOCount;
    conversionOptionDurationMonthsCount: MISMOCount;
    conversionOptionMarginRatePercent: MISMOPercent;
    conversionOptionMaximumRatePercent: MISMOPercent;
    conversionOptionMinimumRatePercent: MISMOPercent;
    conversionOptionNoteTermGreaterThanFifteenYearsAdditionalPercent: MISMOPercent;
    conversionOptionNoteTermLessThanFifteenYearsAdditionalPercent: MISMOPercent;
    conversionScheduleType: ConversionScheduleType;
    conversionScheduleTypeOtherDescription: MISMOString;
    conversionType: ConversionType;
    conversionTypeOtherDescription: MISMOString;
  }>;

type ConversionOptionPeriodAdjustmentRule = MISMOEntity &
  Partial<{
    conversionOptionPeriodAdjustmentEffectiveDate: MISMODate;
    conversionOptionPeriodDemandNotificationFrequencyCount: MISMOCount;
    conversionOptionPeriodExpirationDate: MISMODate;
    conversionOptionPeriodFeeAmount: MISMOAmount;
    conversionOptionPeriodFeeBalanceCalculationType: ConversionOptionPeriodFeeBalanceCalculationType;
    conversionOptionPeriodFeePercent: MISMOPercent;
    conversionOptionPeriodReplyDaysCount: MISMOCount;
    conversionOptionPeriodRoundingPercent: MISMOPercent;
    conversionOptionPeriodRoundingType: ConversionOptionPeriodRoundingType;
    conversionOptionPeriodType: ConversionOptionPeriodType;
    conversionRateCalculationMethodDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type ConversionAdjustment = MISMOEntity &
  Partial<{
    conversionAdjustmentLifetimeAdjustmentRule: ConversionAdjustmentLifetimeAdjustmentRule[];
    conversionOptionPeriodAdjustmentRules: ConversionOptionPeriodAdjustmentRule[];
    indexRules: IndexRule[];
  }>;
