import {
  InboundTradeRequestWithSummary,
  QuoteMessageType,
  TradeCounterpartyActionType,
  TradeQuoteType,
  TradeRequestMessageType,
} from '@plus-platform/shared';
import React from 'react';
import styled from 'styled-components/macro';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { MessageActions, MessageText, PlusMessage } from '../../../components/Messages';
import { DateDivider } from '../../../components/Messages/DateDivider';
import { shouldDisplayDateDivider } from '../../../components/Messages/messengerUtils';
import { DropzoneProvider } from '../../../contexts/DropzoneContext';
import { useTradeRequestInboundQuotesQuery } from '../../../hooks/queries';
import { useCreateTradeRequestQuoteMutation } from '../../../hooks/queries/useCreateTradeRequestQuoteMutation';
import { getSellerCounterparty } from '../../tradeRequestsUtils';
import * as Styles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import { useTabMessages } from '../TradeRequestDirectMessaging/useMessages';
import { InboundCompleted } from './Messages/InboundCompleted';
import { InboundQuoteAcceptRejectCounter } from './Messages/InboundQuoteAcceptRejectCounter';
import { InboundQuoteCounterAccepted } from './Messages/InboundQuoteCounterAccepted';
import { InboundQuoteCountered } from './Messages/InboundQuoteCountered';
import { InboundQuoteCounterRejected } from './Messages/InboundQuoteCounterRejected';
import { InboundQuotePending } from './Messages/InboundQuotePending';
import { InboundQuoteRejected } from './Messages/InboundQuoteRejected';
import { QuoteForm } from './QuoteForm';
import { isLastMessage } from './quoteUtils';

const StipulationUploadWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  place-content: center;
  flex-direction: column;
`;

type InboundTradeRequestQuotesProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundTradeRequestQuotes = (props: InboundTradeRequestQuotesProps) => {
  const { tradeRequest } = props;
  const { counterparties } = tradeRequest;
  const [isQuoteFormDisplayed, setIsQuoteFormDisplayed] = React.useState(false);

  const { data: quotes = [] } = useTradeRequestInboundQuotesQuery(tradeRequest.id);

  const { isLoading, messages } = useTabMessages({
    tradeRequestId: tradeRequest.id,
    messageType: TradeRequestMessageType.QUOTE,
  });

  const { isLoading: isCreatingQuote, mutate: createQuote } = useCreateTradeRequestQuoteMutation();

  const sellerCounterparty = getSellerCounterparty(counterparties);
  if (!sellerCounterparty) {
    return null;
  }

  const actionTypes = sellerCounterparty.actions.map((action) => action.type);
  const canQuote = actionTypes.includes(TradeCounterpartyActionType.ACCEPT_DATA_REQUEST);

  const filteredQuotes = quotes.filter((quote) => !quote.linkedQuoteId);
  const totalQuoteCount = filteredQuotes.length;

  return (
    <React.Fragment>
      <DropzoneProvider accept="application/pdf" disabled={!canQuote} multiple={false} noClick>
        {({ getRootProps }) => (
          <StipulationUploadWrapper {...getRootProps()}>
            <ActivityIndicator contain isActive={isLoading}>
              <Styles.Header>
                <Heading $size="medium">Bids</Heading>
                <Styles.IntroText>
                  This is the very beginning of Bids on trading pool{' '}
                  <strong>{tradeRequest.pool.name}</strong> ticket ID{' '}
                  <strong>{tradeRequest.id}</strong>.
                </Styles.IntroText>
              </Styles.Header>
              {/* TODO: Add pagination to quotes request and pass loadMore fn */}
              <MessengerList messages={messages}>
                {({ measure, message }) => {
                  const currentQuoteIndex = message?.metadata?.quote
                    ? quotes.findIndex(({ id }) => message?.metadata?.quote?.id === id)
                    : -1;
                  const previousMessage = messages[messages.length - 1];

                  const previousMessageCreationDate = previousMessage?.metadata?.quote?.createdAt
                    ? new Date(previousMessage.metadata.quote.createdAt)
                    : undefined;
                  const messageCreationDate = message?.metadata?.quote?.createdAt
                    ? new Date(message.metadata.quote.createdAt)
                    : undefined;
                  const hasDateDivider = messageCreationDate
                    ? shouldDisplayDateDivider(messageCreationDate, previousMessageCreationDate)
                    : false;

                  return (
                    <React.Fragment>
                      {hasDateDivider && messageCreationDate && (
                        <DateDivider date={messageCreationDate} />
                      )}

                      {message?.metadata?.quoteMessageType ===
                        QuoteMessageType.QUOTE_CREATED_PENDING &&
                        message?.metadata?.quote && (
                          <InboundQuotePending
                            currentQuoteIndex={currentQuoteIndex}
                            quote={message.metadata.quote}
                            totalQuoteCount={totalQuoteCount}
                            tradeRequest={tradeRequest}
                          />
                        )}

                      {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_COUNTERED &&
                        message?.metadata?.quote && (
                          <InboundQuoteCountered
                            currentQuoteIndex={currentQuoteIndex}
                            quote={message.metadata.quote}
                            totalQuoteCount={totalQuoteCount}
                            tradeRequest={tradeRequest}
                          />
                        )}

                      {message?.metadata?.quoteMessageType ===
                        QuoteMessageType.QUOTE_COUNTER_ACCEPTED &&
                        message?.metadata?.quote && (
                          <InboundQuoteCounterAccepted
                            currentQuoteIndex={currentQuoteIndex}
                            quote={message.metadata.quote}
                            totalQuoteCount={totalQuoteCount}
                            tradeRequest={tradeRequest}
                          />
                        )}

                      {message?.metadata?.quoteMessageType ===
                        QuoteMessageType.QUOTE_COUNTER_REJECTED &&
                        message?.metadata?.quote && (
                          <InboundQuoteCounterRejected
                            currentQuoteIndex={currentQuoteIndex}
                            quote={message.metadata.quote}
                            totalQuoteCount={totalQuoteCount}
                            tradeRequest={tradeRequest}
                          />
                        )}

                      {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_REJECTED &&
                        message?.metadata?.quote && (
                          <InboundQuoteRejected
                            currentQuoteIndex={currentQuoteIndex}
                            quote={message.metadata.quote}
                            totalQuoteCount={totalQuoteCount}
                            tradeRequest={tradeRequest}
                          />
                        )}

                      {message?.metadata?.quoteMessageType ===
                        QuoteMessageType.QUOTE_CTA_NEW_QUOTE &&
                        messages.length === 1 && (
                          <React.Fragment>
                            {isQuoteFormDisplayed ? (
                              <PlusMessage>
                                <MessageText>
                                  You're now able to Quote on the pool{' '}
                                  <strong>{tradeRequest.pool.name}</strong> ticket ID{' '}
                                  <strong>{tradeRequest.id}</strong>. What would you like to do now?
                                </MessageText>
                                <MessageText>You're quoting...</MessageText>
                                <QuoteForm
                                  isSubmitting={isCreatingQuote}
                                  onCancel={() => {
                                    setIsQuoteFormDisplayed(false);
                                    measure();
                                  }}
                                  onSubmit={(values) => {
                                    createQuote({
                                      tradeRequestId: tradeRequest.id,
                                      value: values.pricePercentage / 100,
                                      stipulation: values?.stipulation,
                                    });
                                  }}
                                />
                              </PlusMessage>
                            ) : (
                              <PlusMessage>
                                <MessageText>
                                  You're now able to Quote on the pool{' '}
                                  <strong>{tradeRequest.pool.name}</strong> ticket ID{' '}
                                  <strong>{tradeRequest.id}</strong>. What would you like to do now?
                                </MessageText>
                                <MessageActions>
                                  <Button
                                    $size="large"
                                    disabled={isCreatingQuote}
                                    onClick={() => {
                                      setIsQuoteFormDisplayed(true);
                                      measure();
                                    }}
                                  >
                                    Begin quote
                                  </Button>
                                </MessageActions>
                              </PlusMessage>
                            )}
                          </React.Fragment>
                        )}

                      {message?.metadata?.quoteMessageType ===
                        QuoteMessageType.QUOTE_COUNTERED_CTA &&
                        message?.metadata?.quote &&
                        isLastMessage(messages, message.metadata) &&
                        message.metadata.quote.type === TradeQuoteType.SELLER_TO_BUYER && (
                          <InboundQuoteAcceptRejectCounter
                            onCounterAccept={measure}
                            onCounterAcceptCancel={measure}
                            onCounterAcceptConfirm={measure}
                            onCounterBack={measure}
                            onCounterBackCancel={measure}
                            onCounterCounter={measure}
                            onCounterReject={measure}
                            onCounterRejectCancel={measure}
                            onCounterRejectConfirm={measure}
                            quote={message.metadata.quote}
                            tradeRequest={tradeRequest}
                          />
                        )}

                      {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_COMPLETED && (
                        <InboundCompleted tradeRequest={tradeRequest} />
                      )}
                    </React.Fragment>
                  );
                }}
              </MessengerList>
            </ActivityIndicator>
          </StipulationUploadWrapper>
        )}
      </DropzoneProvider>
    </React.Fragment>
  );
};
