import styled, { css, DefaultTheme } from 'styled-components/macro';

import { Button } from './Button.styles';

export const Filters = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const FilterButton = styled(Button)(
  ({ theme }) => css`
    box-shadow: inset 0 0 0 1px ${theme.colors.white05};
  `
);

export const AppliedFilters = styled.ul`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
  list-style: none;
  margin: 0;
`;

export const mapColorLabelToPrimaryAndSecondary = (colorLabel: string, theme: DefaultTheme) => {
  const BUTTON_COLOR_LABEL_TO_HEXES_MAP: Record<string, [string, string]> = {
    standard: [theme.colors.white05, theme.colors.white],
    default30Days: [theme.colors.delinquency30Days, theme.colors.blue],
    default60Days: [theme.colors.delinquency60Days, theme.colors.blue],
    default90Days: [theme.colors.delinquency90Days, theme.colors.blue],
  };

  return BUTTON_COLOR_LABEL_TO_HEXES_MAP[colorLabel];
};

export type AppliedFilterStyleProps = {
  $color?: 'standard' | 'default30Days' | 'default60Days' | 'default90Days';
};

export const AppliedFilter = styled.li<AppliedFilterStyleProps>(({ $color, theme }) => {
  const [primaryColor, secondaryColor] = mapColorLabelToPrimaryAndSecondary(
    $color ?? 'standard',
    theme
  );

  return css`
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
    height: 24px;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    background-color: ${primaryColor};
    color: ${secondaryColor};
    cursor: default;
  `;
});

export const AppliedFilterLabel = styled.span(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04}
  `
);

export const FilterRemoveButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;

  svg {
    display: block;
  }
`;
