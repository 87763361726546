import React from 'react';

import { ActionText, MenuItemAction } from './FlagsAlertsWarningsPage.styles';

type MenuItemButtonProps = {
  title: string;
  icon?: React.ReactElement;
  actionComponent?: (isOpened: boolean) => JSX.Element | null;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const MenuItemButton = ({
  actionComponent,
  icon,
  onClick,
  style,
  title,
}: MenuItemButtonProps) => {
  const [isOpened, setIsOpened] = React.useState(false);

  return (
    <MenuItemAction
      onClick={() => {
        setIsOpened(!isOpened);
        onClick?.();
      }}
      style={style}
    >
      <ActionText>
        {icon}
        <span>{title}</span>
      </ActionText>
      {actionComponent?.(isOpened)}
    </MenuItemAction>
  );
};

export const SpecificMenuItemButton = ({ style, ...rest }: MenuItemButtonProps) => {
  return (
    <MenuItemButton
      {...rest}
      style={{
        borderRadius: 0,
        ...style,
      }}
    />
  );
};
