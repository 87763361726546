import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, putRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type MarkAllUserNotificationsAsReadMutation = {
  types?: string[];
  tradeRequestId?: number;
  counterpartyId?: number;
};

const markAllUserNotificationsAsReadRequest = (data?: MarkAllUserNotificationsAsReadMutation) => {
  const url = makeApiUrl(`user/notifications/read`);
  return putRequestWithAuth(url, {
    ...(data && {
      body: JSON.stringify(data),
    }),
  });
};

export const useMarkAllNotificationsAsReadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(markAllUserNotificationsAsReadRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.USER_NOTIFICATIONS_COUNT);
      queryClient.invalidateQueries(QueryKeys.USER_NOTIFICATIONS);
    },
  });
};
