import { MISMOEntity, MISMOString } from '../types';

export enum LoanOriginatorType {
  Broker = 'Broker',
  Correspondent = 'Correspondent',
  Lender = 'Lender',
  Other = 'Other',
}

export type LoanOriginator = MISMOEntity &
  Partial<{
    loanOriginatorType: LoanOriginatorType;
    loanOriginatorTypeOtherDescription: MISMOString;
  }>;
