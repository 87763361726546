import {
  GetPortfolioLoanTypesByNoteRateLoansResponse,
  PortfolioBreakdownRateType,
} from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioLoanTypesByNoteRateLoans = async (
  rateType: PortfolioBreakdownRateType,
  noteRate: number,
  offset = 0
) => {
  const params = new URLSearchParams({ offset: String(offset) });
  const url = makeApiUrl(`/portfolio/types/${rateType}/rates/${noteRate}/loans`, params);

  return getRequestWithAuth<GetPortfolioLoanTypesByNoteRateLoansResponse>(url);
};
