import React from 'react';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { SubmissionsList } from './SubmissionsList';
import { SubmissionsSummary } from './SubmissionsSummary';

export const SubmissionsPage = () => {
  return (
    <React.Fragment>
      <Page>
        <PageHeader>
          <Heading>Submissions</Heading>
          <Breadcrumb>
            <BreadcrumbItem linkTo="/" title="Home" />
            <BreadcrumbItem linkTo="/documents" title="Ingestion" />
            <BreadcrumbItem title="Submissions" />
          </Breadcrumb>

          <SubmissionsSummary />
        </PageHeader>

        <PageContent>
          <SubmissionsList path="/documents/submissions" />
        </PageContent>
      </Page>
    </React.Fragment>
  );
};
