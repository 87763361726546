import {
  InboundTradeRequestWithSummary,
  TradeQuoteType,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { QuoteSummaryType } from '../types';

type InboundQuoteCounterAcceptedProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: InboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const InboundQuoteCounterAccepted = ({
  quote,
  tradeRequest,
}: InboundQuoteCounterAcceptedProps) => {
  return quote.type === TradeQuoteType.SELLER_TO_BUYER ? (
    <PlusMessage date={quote.createdAt}>
      <MessageText>
        <TradeMessage
          id="Trade.Inbound.Quote.Counter.Accepted"
          values={{
            counterType: QuoteSummaryType.COUNTER.toLowerCase(),
            userName: formatNameWithInitial(
              tradeRequest.user.lastName,
              tradeRequest.user.firstName
            ),
            organizationName: tradeRequest.user.organization.tradingName,
          }}
        />
      </MessageText>
    </PlusMessage>
  ) : null; // TODO: Confirm that we do not need to cover this situation
};
