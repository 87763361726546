import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  AddToPoolIcon,
  ChevronRightIcon,
  ConduitLineIcon,
  ContactIcon,
  DocumentScanIcon,
  FeedbackIcon,
  HelpIcon,
  NotificationsPausedIcon,
  OpenFolderIcon,
  PoolLineIcon,
  StrategyIcon,
  TicketIcon,
  ViewIcon,
} from '../../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection, SubMenu } from '../../components/Menu';
import { useLayoutContext } from '../../contexts/LayoutContext';

type MainMenuProps = {
  'data-testid'?: string;
};

export const MainMenu = ({ 'data-testid': dataTestId }: MainMenuProps) => {
  const { ticketDrawer } = useLayoutContext();
  const location = useLocation();

  return (
    <Menu title="Menu" toggleProps={{ size: 'large' }} data-testid={dataTestId}>
      <MenuSection>
        <MenuItem to="/pools/create">
          <AddToPoolIcon />
          <MenuItemText>New pool</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <TicketIcon />
          <MenuItemText>New ticket</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <StrategyIcon />
          <MenuItemText>New strategy</MenuItemText>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <MenuItem to="/portfolio/create-single-loan">
          <DocumentScanIcon />
          <MenuItemText>Create loans from documents</MenuItemText>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <MenuItem disabled={location.pathname !== '/home'} onClick={ticketDrawer.onPanelOpen}>
          <OpenFolderIcon />
          <MenuItemText>Open ticket drawer</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <NotificationsPausedIcon />
          <MenuItemText>Pause notifications</MenuItemText>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <MenuItem isSubmenu>
          <ViewIcon />
          <MenuItemText>View</MenuItemText>
          <ChevronRightIcon />
          <SubMenu $direction="left">
            <MenuSection>
              <MenuItem to="/conduit">
                <ConduitLineIcon />
                <MenuItemText>Conduit</MenuItemText>
              </MenuItem>
              <MenuItem to="/pools">
                <PoolLineIcon />
                <MenuItemText>Pools</MenuItemText>
              </MenuItem>
            </MenuSection>
          </SubMenu>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <MenuItem disabled>
          <HelpIcon />
          <MenuItemText>Help</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <FeedbackIcon />
          <MenuItemText>Feedback</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <ContactIcon />
          <MenuItemText>Contact Plus</MenuItemText>
        </MenuItem>
      </MenuSection>
    </Menu>
  );
};
