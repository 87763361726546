import React from 'react';

export const IngestionFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 16.8H15V15.2H9V16.8ZM9 13.6H15V12H9V13.6ZM7.5 20C7.0875 20 6.73438 19.8433 6.44063 19.53C6.14687 19.2167 6 18.84 6 18.4V5.6C6 5.16 6.14687 4.78333 6.44063 4.47C6.73438 4.15667 7.0875 4 7.5 4H13.5L18 8.8V18.4C18 18.84 17.8531 19.2167 17.5594 19.53C17.2656 19.8433 16.9125 20 16.5 20H7.5ZM12.75 9.6H16.5L12.75 5.6V9.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
