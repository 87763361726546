import styled from 'styled-components/macro';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`;

export const TitleWrapper = styled.div`
  padding: 12px;
`;

export const TableWrapper = styled.div`
  padding-bottom: 16px;
`;

export const TableBodyWrapper = styled.div`
  margin: 0 16px;
`;
