import { AQIEntity } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type AirQualityResponse = AQIEntity;

export const getAirQualityCurrent = async (params: URLSearchParams) => {
  return getRequestWithAuth<AirQualityResponse>(makeApiUrl(`air-quality/current?${params}`));
};

export const useAirQualityCurrentQuery = (latitude: number, longitude: number) => {
  const params = new URLSearchParams({
    latitude: String(latitude),
    longitude: String(longitude),
  });

  return useQuery([QueryKeys.AIR_QUALITY_CURRENT, params.toString()], () =>
    getAirQualityCurrent(params)
  );
};
