import React from 'react';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Divider } from '../../components/Divider';
import { SettingsLineIcon } from '../../components/icons';
import { Table, TableWrapper, Tbody, Thead } from '../../components/Table';
import { TableFilterBar } from '../../components/TableFilterBar';
import { TableStats } from '../../components/TableStats';
import { TableToolbar } from '../../components/TableToolbar';
import { useGetOrganizationMembersQuery } from '../../hooks/queries/useGetOrganizationMembersQuery';
import { useResendUserInvitationMutation } from '../../hooks/queries/useResendUserInvitationMutation';
import { useRevokeUserInvitationMutation } from '../../hooks/queries/useRevokeUserInvitationMutation';
import { OrganizationMembersActionBar } from './OrganizationMembersActionBar';
import { ActionsWrapper } from './OrganizationMembersTable.styles';
import { OrganizationMembersTableHeaderRow } from './OrganizationMembersTableHeaderRow';
import { OrganizationMembersTableRow } from './OrganizationMembersTableRow';

export const OrganizationMembersTable = () => {
  const { data: members, isError, isLoading } = useGetOrganizationMembersQuery();
  const { mutateAsync: revokeUserInvitation } = useRevokeUserInvitationMutation();
  const { mutateAsync: resendUserInvitation } = useResendUserInvitationMutation();

  const totalCount = members?.length || 0;

  const handleRevokeInvite = async (inviteId: string) => {
    try {
      await revokeUserInvitation(inviteId);
      // @todo: use a toast instead of alert
      alert('The invite was revoked');
    } catch (err) {
      // @todo: use a toast instead of alert
      // properly handle the error
      alert('There was an error revoking the invite');
    }
  };
  const handleResendInvite = async (inviteId: string) => {
    try {
      await resendUserInvitation(inviteId);
      // @todo: use a toast instead of alert
      alert('The invite was resent');
    } catch (err) {
      // @todo: use a toast instead of alert
      // properly handle the error
      alert('There was an error resending the invite');
    }
  };

  // NOTES:
  // The filters in the table are not implemented yet
  // until we have more detail on how they should work and what they should do
  //
  // The total count is the same as the visible count because we are not
  // paginating the table or filtering the results yet
  return (
    <React.Fragment>
      <TableToolbar>
        <TableStats totalCount={totalCount} visibleCount={totalCount} label="users" />
        <Divider $flexItem $orientation="vertical" $variant="middle" />
        <TableFilterBar
          filters={[]}
          onFilterSettingsClick={() => {
            console.log('todo');
          }}
          onFiltersUpdate={() => {
            console.log('todo');
          }}
          isDisabled
        />
        <ActionsWrapper>
          <OrganizationMembersActionBar />
          <SettingsLineIcon />
        </ActionsWrapper>
      </TableToolbar>
      <TableWrapper>
        <ActivityIndicator contain isActive={isLoading}>
          {isError ? (
            <div>
              There was an error loading your organization's members. Please try again later.
            </div>
          ) : (
            <Table>
              <Thead>
                <OrganizationMembersTableHeaderRow />
              </Thead>
              <Tbody>
                {members?.map((member) => (
                  <OrganizationMembersTableRow
                    key={member.email}
                    member={member}
                    onRevokeInvite={handleRevokeInvite}
                    onResendInvite={handleResendInvite}
                  />
                ))}
              </Tbody>
            </Table>
          )}
        </ActivityIndicator>
      </TableWrapper>
    </React.Fragment>
  );
};
