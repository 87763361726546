import { Tooltip } from 'react-tooltip';
import { useTheme } from 'styled-components/macro';

import {
  DataAttribute,
  PropertyDescription,
  PropertyTitle,
} from '../../properties/NearbyPropertDetail.styles';
import { formatDefaultValue, formatMonetary } from '../../utils/formatUtils';

export const PROPERTY_VALUATION_TOOLTIP_ID = 'property-valuation-tooltip';

export type PropertyValuationPopupData = {
  price: number;
  bedroomCount?: number;
  address: string;
};

type PropertyValuationPopupProps = {
  propertyValuation?: PropertyValuationPopupData;
};

export const PropertyValuationPopup = ({ propertyValuation }: PropertyValuationPopupProps) => {
  const { address, bedroomCount, price } = propertyValuation || {};

  const theme = useTheme();

  return (
    <Tooltip
      style={{
        width: '300',
        padding: '16px',
        backgroundColor: theme.colors.white,
        zIndex: '1',
      }}
      id={PROPERTY_VALUATION_TOOLTIP_ID}
      variant="light"
      isOpen={Boolean(propertyValuation)}
      openOnClick
    >
      <PropertyTitle>Price: {formatMonetary(price)}</PropertyTitle>
      <PropertyDescription>
        {formatDefaultValue(bedroomCount, 'No')} bedroom{(bedroomCount || 0) > 1 && 's'}
        {address && <DataAttribute>{address}</DataAttribute>}
      </PropertyDescription>
    </Tooltip>
  );
};
