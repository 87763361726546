import { PortfolioAnalyticMetric } from '@plus-platform/shared';

import { StatesTable } from '../../components/StatesTable';
import { TableWrapper } from './Analytics.styles';
import { AnalyticsValue, convertValuesToHashmap } from './utils';

type AnalyticsTableProps = {
  id: string;
  data: AnalyticsValue[];
  metric: PortfolioAnalyticMetric;
  metricBy: PortfolioAnalyticMetric;
};

export const AnalyticsTable = ({ data, id }: AnalyticsTableProps) => {
  const valuesByState = convertValuesToHashmap(data);

  return (
    <TableWrapper>
      <StatesTable
        variant="small"
        styledColumnCount={12}
        chunkColumnCount={13}
        elementColumnCount={3}
        id={id}
        data={valuesByState}
      />
    </TableWrapper>
  );
};
