import { createContext, ReactNode, useContext, useState } from 'react';

type ProviderProps = {
  children: ReactNode;
};

type Layout = {
  ticketDrawer: {
    isPanelOpen: boolean;
    onPanelOpen: () => void;
    onPanelClose: () => void;
    onPanelToggle: () => void;
  };
};

const LayoutContext = createContext<Layout>({
  ticketDrawer: {
    isPanelOpen: false,
    onPanelToggle: () => undefined,
    onPanelOpen: () => undefined,
    onPanelClose: () => undefined,
  },
});

export const LayoutProvider = ({ children }: ProviderProps) => {
  const [isTicketDrawerOpen, setIsTicketDrawerOpen] = useState(false);

  const handleTicketDrawerToggle = () => setIsTicketDrawerOpen((isOpen) => !isOpen);
  const handleTicketDrawerOpen = () => setIsTicketDrawerOpen(true);
  const handleTicketDrawerClose = () => setIsTicketDrawerOpen(false);

  return (
    <LayoutContext.Provider
      value={{
        ticketDrawer: {
          isPanelOpen: isTicketDrawerOpen,
          onPanelToggle: handleTicketDrawerToggle,
          onPanelOpen: handleTicketDrawerOpen,
          onPanelClose: handleTicketDrawerClose,
        },
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
