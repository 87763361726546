import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Cell, TABLE_MARGIN } from '../../components/Table';

export const ORGANIZATION_MEMBERS_TABLE_COLUMN_COUNT = 6;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const ContextMenuTrigger = styled.button`
  border: 0;
  outline: 0;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => transparentize(0.4, theme.colors.white)};
  width: 20px;
  height: 20px;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 3px;

  svg {
    width: 100%;

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ContextMenuWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.selectOptions};
    border-radius: 4px;
    z-index: 5;
    color: ${({ theme }) => theme.colors.statusBlack};
    position: absolute;
    right: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
  `}
`;

export const ContextMenuItem = styled.div`
  ${({ theme }) => css`
    border: 0;
    outline: 0;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    color: ${theme.colors.white};
    padding: 4px 12px;
    display: flex;
    font-family: ${(props) => props.theme.typography.fontFamily.text};

    &:hover {
      background-color: ${theme.colors.greenLight};
      color: ${theme.colors.statusBlack};
      font-weight: 500;
    }
  `}
`;

export const MemberRow = styled.div<{
  columnCount: number;
  $isInvite: boolean;
}>`
  ${({ $isInvite, columnCount, theme }) => css`
    padding: 0 ${TABLE_MARGIN}px;
    display: grid;
    column-gap: 8px;
    grid-template-columns: repeat(${columnCount}, 1fr);
    border-top: 1px solid ${transparentize(0.9, theme.colors.white)};

    ${Cell} {
      color: ${$isInvite ? transparentize(0.5, theme.colors.white) : 'inherit'};
    }
  `}
`;

export const MemberCell = styled(Cell)`
  overflow: initial;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
