import { white } from './darkTheme/colors';

const sansSerif =
  "font-family: 'Yantramanav', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'Arial', sans-serif";
const fontSize = 11;
const padding = 8;
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  padding,
  fill: white,
  fillOpacity: 0.8,
  stroke: white,
  strokeWidth: 0.2,
};

export const plusChartTheme = {
  area: {
    style: {
      data: { stroke: white },
    },
  },
  axis: {
    style: {
      axis: {
        stroke: white,
        fill: white,
      },
      axisLabel: {
        ...baseLabelStyles,
        padding: 25,
      },
      grid: {
        stroke: 'transparent',
      },
      tick: {
        stroke: white,
        fill: white,
      },
      tickLabels: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
  },
  dependentAxis: {
    style: {
      axis: {
        stroke: white,
        fill: white,
      },
      axisLabel: {
        ...baseLabelStyles,
        padding: 25,
      },
      grid: {
        stroke: white,
        strokeOpacity: 0.6,
        pointerEvents: 'painted',
      },
      tick: {
        stroke: white,
        fill: white,
      },
      tickLabels: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
  },
  brush: {
    style: {
      stroke: white,
      fillOpacity: 0.1,
    },
  },
};
