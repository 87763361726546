import clamp from 'lodash/clamp';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { bgSpin } from '../../styles/keyframes';

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
`;

type StepProps = {
  $isActive?: boolean;
  $isComplete?: boolean;
  $shouldAnimate?: boolean;
};

export const HeaderWrapper = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.textPrimary01};
  ${(props) => props.theme.typography.headings.headingRegular01};
  background: ${(props) => props.theme.colors.primary02};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary03};
`;

export const Circle = styled.div`
  min-width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.typography.headings.headingMedium03Tight};
`;

export const StepWrapper = styled.div<{ $shouldAnimate?: boolean }>`
  position: relative;
  border-radius: 4px;
  width: 162px;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-right: -100%;
    right: -8px;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.white10};
  }

  ${(props) =>
    props.$shouldAnimate &&
    css`
      --border-size: 4px;
      --border-angle: 0turn;
      @property --border-angle {
        syntax: '<angle>';
        inherits: true;
        initial-value: 0turn;
      }

      background-image: conic-gradient(
          from var(--border-angle),
          ${(props) => props.theme.colors.primary02},
          ${(props) => props.theme.colors.primary02} 50%,
          ${(props) => props.theme.colors.primary02}
        ),
        conic-gradient(
          from var(--border-angle),
          transparent 20%,
          ${(props) => props.theme.colors.primary02},
          ${(props) => props.theme.colors.statusOrange}
        );
      background-size: calc(100% - var(--border-size)) calc(100% - var(--border-size)), cover;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: var(--border-size);
      animation: ${bgSpin} 4s linear infinite;
    `}
`;

export const StepProgress = styled.div<{ $shouldAnimate?: boolean; $completePercent?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${(props) =>
    props.$shouldAnimate &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: width 0.2s ease-in-out;
        width: ${props.$completePercent
          ? `${clamp(props.$completePercent * 100, 0, 100)}%`
          : '50%'};
        border-radius: 4px 0 0 4px;
        background: ${(props) => transparentize(0.5, props.theme.colors.statusOrange)};
      }
    `}
`;

export const Step = styled.div<StepProps>(
  ({ $isActive, $isComplete, $shouldAnimate }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 40px;
    position: relative;
    text-align: center;
    border-radius: 4px;
    flex-direction: row;
    width: 100%;
    ${(props) => props.theme.typography.headings.headingRegular02};
    border: 1px solid ${(props) => props.theme.colors.white10};
    color: ${(props) => props.theme.colors.textPrimary03};
    background: ${(props) => props.theme.colors.primary02};

    ${Circle} {
      background: ${(props) => props.theme.colors.primary03};
    }

    svg {
      color: ${(props) => props.theme.colors.statusGreen};
      margin-right: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      margin-left: -9px;
      top: 0;
      bottom: 0;
      width: 8px;
      background: ${(props) => props.theme.colors.primary02};
    }

    ${$isActive &&
    css`
      ${(props) => props.theme.typography.headings.headingMedium02};
      color: ${(props) => props.theme.colors.textPrimary01};
      ${Circle} {
        background: ${(props) => props.theme.colors.primary10};
        color: ${(props) => props.theme.colors.textSecondary01};
      }
    `}

    ${$isComplete &&
    css`
      ${Circle} {
        background: ${(props) => props.theme.colors.textPrimary01};
      }
    `}

    ${$shouldAnimate &&
    css`
      position: relative;
      background: none;

      ${Circle} {
        background: ${(props) => props.theme.colors.statusOrange};
        color: ${(props) => props.theme.colors.white};
      }
    `}
  `
);

export const StepDetails = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  white-space: nowrap;
`;
