import { Filter } from '@plus-platform/shared';
import React from 'react';

import { getAppliedFilters, getFilterLabel } from '../utils/portfolioFilterUtils';
import { Divider } from './Divider';
import { CloseLineIcon, FilterLineIcon } from './icons';
import * as Styles from './TableFilterBar.styles';

type PortfolioFilterProps = {
  filters: Filter[];
  onFiltersUpdate: (filters: Filter[]) => unknown | Promise<unknown>;
  onFilterSettingsClick: () => void;
  isDisabled?: boolean;
};

export const TableFilterBar = ({
  filters,
  isDisabled,
  onFilterSettingsClick,
  onFiltersUpdate,
}: PortfolioFilterProps) => {
  const [isUpdatingFilters, setIsUpdatingFilters] = React.useState(false);

  const appliedFilters = getAppliedFilters(filters);
  const hasAppliedFilters = appliedFilters.length > 0;

  const removeAppliedFilter = async (appliedFilter: Filter) => {
    try {
      const updatedFilters = filters.map((filter) => {
        return {
          ...filter,
          isApplied: appliedFilter.id === filter.id ? false : filter.isApplied,
        };
      });

      setIsUpdatingFilters(true);
      await onFiltersUpdate(updatedFilters);
    } finally {
      setIsUpdatingFilters(false);
    }
  };

  return (
    <Styles.Filters>
      <Styles.FilterButton
        $variant="outlined"
        $color="tertiary"
        $size="xSmall"
        disabled={isDisabled}
        onClick={onFilterSettingsClick}
        data-testid="TableFilterBar_Button"
        $hasStartIcon
      >
        <FilterLineIcon width={16} height={16} />
        {appliedFilters.length > 0
          ? `${appliedFilters.length} filter${appliedFilters.length > 1 ? 's' : ''} applied`
          : 'Filters'}
      </Styles.FilterButton>
      {hasAppliedFilters && (
        <>
          <Divider $orientation="vertical" $flexItem />
          <Styles.AppliedFilters>
            {appliedFilters.map((filter) => (
              <Styles.AppliedFilter key={filter.id} data-testid="TableFilterBar_AppliedFilter">
                <Styles.AppliedFilterLabel title={getFilterLabel(filter)}>
                  {getFilterLabel(filter)}
                </Styles.AppliedFilterLabel>
                <Styles.FilterRemoveButton
                  disabled={isUpdatingFilters}
                  onClick={() => removeAppliedFilter(filter)}
                >
                  <CloseLineIcon width={16} height={16} />
                </Styles.FilterRemoveButton>
              </Styles.AppliedFilter>
            ))}
          </Styles.AppliedFilters>
        </>
      )}
    </Styles.Filters>
  );
};
