// ripped this from shorturl.at/aoFK7
// allows us to serialize an array of params
// into URLSearchParams, this isn't supported by default
export const createURLSearchParams = <T extends Record<any, any>>(params: T): URLSearchParams => {
  return Object.entries(params).reduce((searchParams, [key, value]) => {
    if (Array.isArray(value)) {
      for (const innerValue of value) {
        searchParams.append(key, innerValue);
      }
      return searchParams;
    }
    searchParams.append(key, value);
    return searchParams;
  }, new URLSearchParams());
};
