import { useJsApiLoader } from '@react-google-maps/api';
import { createContext, ReactNode, useContext } from 'react';

import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../config';

type ProviderProps = {
  children: ReactNode;
};

type Map = {
  isLoaded: boolean;
};

const MapContext = createContext<Map>({
  isLoaded: false,
});

export const MapProvider = ({ children }: ProviderProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (!REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw Error('No Google Maps API key present');
  }

  return (
    <MapContext.Provider
      value={{
        isLoaded,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
