import { AttributeType, PermissionType } from '@plus-platform/shared';
import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../global/PrivateRoute';
import { TradeRequestPoolPage } from './TradeRequestPoolPage';
import { TradeRequests } from './TradeRequests';
import { TradeRequestsCompletedPage } from './TradeRequestsCompletedPage';
import { TradeRequestsInboundPage } from './TradeRequestsInboundPage';
import { TradeRequestsOutboundPage } from './TradeRequestsOutboundPage';

export const TradeRequestsPage = () => {
  return (
    <React.Fragment>
      <PrivateRoute
        exact
        path="/trade-requests/pool/:poolId"
        render={() => <TradeRequestPoolPage />}
        requiredPermissions={{
          [PermissionType.PERMISSION_CREATE]: [AttributeType.ATTRIBUTE_TRADE_REQUEST],
          [PermissionType.PERMISSION_READ]: [
            AttributeType.ATTRIBUTE_ORGANIZATION,
            AttributeType.ATTRIBUTE_POOL,
            AttributeType.ATTRIBUTE_TRADE_REQUEST,
          ],
        }}
      />
      <Switch>
        <PrivateRoute
          exact
          path="/trade-requests/completed"
          render={() => <TradeRequestsCompletedPage />}
          requiredPermissions={{
            [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_TRADE_REQUEST],
          }}
        />
        <PrivateRoute
          path="/trade-requests/inbound"
          render={() => <TradeRequestsInboundPage />}
          requiredPermissions={{
            [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_TRADE_REQUEST],
          }}
        />
        <PrivateRoute
          path="/trade-requests/outbound"
          render={() => <TradeRequestsOutboundPage />}
          requiredPermissions={{
            [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_TRADE_REQUEST],
          }}
        />
        <TradeRequests />
      </Switch>
    </React.Fragment>
  );
};
