import styled, { css } from 'styled-components';

import { Heading } from '../components/Heading.styles';
import { InternalLink } from '../components/Link';
import { bodyText } from '../styles/typography';
import { Subtitle, Title } from './FlagsAlertsWarningsPage.styles';

export const AnalyticsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AnalyticsTitle = styled(Title)`
  padding: 0;
`;

export const AnalyticsSubtitle = styled(Subtitle)`
  padding: 0;
`;

export const HeaderLink = styled(InternalLink)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 1px solid ${theme.colors.borderAdminBackLinkIcon};
    background-color: ${theme.colors.backgroundAdminBackLinkIcon};
  `
);

export const Description = styled.p(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    ${bodyText.bodyRegular03Tight};
    color: ${theme.colors.text01};
  `
);

export const Subheading = styled(Heading).attrs({ as: 'h3', $size: 'small' })`
  margin-bottom: 12px;
`;

export const Subsection = styled.div(
  ({ theme }) => css`
    position: relative;
    border-radius: 4px;
    margin-top: 24px;
    padding: 12px;
    background-color: ${theme.colors.backgroundAdminSubsection};
  `
);

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LoaderOpacityMask = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.backgroundAdminSubsection};
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);
