import {
  DelinquencyType,
  PortfolioBreakdownDelinquencyType,
  PortfolioBreakdownType,
} from '@plus-platform/shared';
import styled, { css } from 'styled-components/macro';

import { PageSection } from '../../../components/Page';
import { Bubble, RateTypeLink as RateTypeLinkComponent } from './LoanTypesByDetailPage.styles';

export const OverviewPageSection = styled(PageSection)`
  display: flex;
  column-gap: 160px;
`;

export const SummaryItems = styled.div`
  display: flex;
`;

type RateTypeLinkProps = {
  $delinquencyType: PortfolioBreakdownDelinquencyType;
};

export const RateTypeLink = styled(RateTypeLinkComponent)<RateTypeLinkProps>`
  ${Bubble} {
    ${(props) =>
      props.$delinquencyType === PortfolioBreakdownType.ALL &&
      css`
        background-color: ${({ theme }) => theme.colors.statusTurquoise};
      `}

    ${(props) =>
      props.$delinquencyType === DelinquencyType.THIRTY_DAYS &&
      css`
        background-color: ${({ theme }) => theme.colors.statusYellow};
        color: ${({ theme }) => theme.colors.statusBlack};
      `}

    ${(props) =>
      props.$delinquencyType === DelinquencyType.SIXTY_DAYS &&
      css`
        background-color: ${({ theme }) => theme.colors.statusOrange};
      `}

    ${(props) =>
      props.$delinquencyType === DelinquencyType.NINETY_DAYS &&
      css`
        background-color: ${({ theme }) => theme.colors.statusRed};
      `}
  }
`;
