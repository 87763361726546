import { DefaultTheme } from 'styled-components';

import * as darkColors from './darkTheme/colors';
import * as legacyColors from './deprecatedColors';
import { fade, fadeFromLeft, fadeFromRight } from './keyframes';
import * as lightColors from './lightTheme/colors';
import { SECTION_RADIUS } from './radiuses';
import * as shadows from './shadows';
import { bodyText, fontFamily, fontSize, fontWeight, headings, lineHeight } from './typography';

const themeProps = {
  heights: { header: 64 },
  radius: { section: SECTION_RADIUS },
  typography: {
    fontFamily,
    fontWeight,
    fontSize,
    lineHeight,
    headings,
    bodyText,
  },
  keyFrames: {
    fade,
    fadeFromLeft,
    fadeFromRight,
  },
  shadows,
};

export const darkTheme: DefaultTheme = {
  ...themeProps,
  colors: {
    ...darkColors,
    // TODO: Deprecate once colors are no longer in use
    ...legacyColors,
  },
};

export const lightTheme: DefaultTheme = {
  ...themeProps,
  colors: lightColors,
};

export const gridTheme = {
  col: {
    padding: 5, // default 15
  },
};

export const isDarkTheme = (theme: DefaultTheme) => theme.colors.primary10 === '#FFFFFF';
