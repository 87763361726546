import React from 'react';

export const DeltaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="11"
      viewBox="0 0 12 11"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 0L12 11H0L6 0Z" fill="currentColor" />
    </svg>
  );
};
