import { formatNumber } from '../../utils/formatUtils';
import { Analytic, AnalyticContainer, AnalyticHeader, AnalyticTitle } from './Analytic';
import { Bar, Bars, LoanCount } from './DelinquencyByDaysMetrics.styles';
import { DelinquencyFinancials, DelinquencyFinancialsProps } from './DelinquencyFinancials';
import { DelinquencyByDaysVariant } from './types';

type DelinquencyByDaysMetricsProps = DelinquencyFinancialsProps & {
  loanCount?: number;
  title: string;
  variant: DelinquencyByDaysVariant;
  delinquency30DayCount?: number;
  delinquency60DayCount?: number;
  delinquency90DayCount?: number;
};

const getBarWidth = (count: number, totalCount: number) => {
  const width = (count / totalCount) * 100;

  return `${width}%`;
};

export const DelinquencyByDaysMetrics = (props: DelinquencyByDaysMetricsProps) => {
  const {
    delinquency30DayCount = 0,
    delinquency60DayCount = 0,
    delinquency90DayCount = 0,
    loanCount,
    title,
    variant,
    ...financials
  } = props;

  const totalCount = delinquency30DayCount + delinquency60DayCount + delinquency90DayCount;

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>{title}</AnalyticTitle>
          <LoanCount $variant={variant}>{formatNumber(loanCount)}</LoanCount>
        </AnalyticHeader>

        <Bars>
          <Bar
            $variant="30-day"
            $isHighlighted={variant === '30-day'}
            $width={getBarWidth(delinquency30DayCount, totalCount)}
          />
          <Bar
            $variant="60-day"
            $isHighlighted={variant === '60-day'}
            $width={getBarWidth(delinquency60DayCount, totalCount)}
          />
          <Bar
            $variant="90-day"
            $isHighlighted={variant === '90-day'}
            $width={getBarWidth(delinquency90DayCount, totalCount)}
          />
        </Bars>

        <DelinquencyFinancials {...financials} />
      </AnalyticContainer>
    </Analytic>
  );
};
