import startOfMonth from 'date-fns/startOfMonth';

const YEAR_IN_SECONDS = 1000 * 60 * 60 * 24 * 365;

const findEarliestDate = (earliest: Date, target: Date) => {
  return target < earliest ? target : earliest;
};

const findLatestDate = (latest: Date, target: Date) => {
  return target > latest ? target : latest;
};

// Returns an array of evenly spaced dates with an interval between them,
// spanning from the earliest date to the latest date in the given array of data points.
export const generateDateTicks = (allData: Date[], interval: number = YEAR_IN_SECONDS) => {
  // return an empty array if there is no data
  if (allData.length === 0) {
    return [];
  }

  const earliestDate = allData.reduce(findEarliestDate, allData[0]);
  const latestDate = allData.reduce(findLatestDate, allData[0]);

  const length = Math.ceil((latestDate.getTime() - earliestDate.getTime()) / interval);

  if (length === 0) {
    // calculate two intervals before and after the earliest and latest dates
    // to ensure that the chart has a minimum of 5 intervals, this is used when
    // the bpo and purchasePrice data is the same.
    const oneIntervalBefore = new Date(earliestDate.getTime() - interval);
    const oneIntervalAfter = new Date(latestDate.getTime() + interval);
    const twoIntervalsAfter = new Date(latestDate.getTime() + interval * 2);
    const threeIntervalsAfter = new Date(latestDate.getTime() + interval * 3);
    const fourIntervalsAfter = new Date(latestDate.getTime() + interval * 4);

    return [
      oneIntervalBefore,
      earliestDate,
      latestDate,
      oneIntervalAfter,
      twoIntervalsAfter,
      threeIntervalsAfter,
      fourIntervalsAfter,
    ].map(startOfMonth);
  }

  return Array.from({ length }, (_, index) => {
    const date = new Date(earliestDate.getTime());
    date.setMonth(date.getMonth() + index * 3);
    return date;
  }).map(startOfMonth);
};
