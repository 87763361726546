import React from 'react';

export const StrategyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.699 19.4854L14.8835 18.6699L16.1068 17.4272L14.8835 16.2039L15.699 15.3689L16.9417 16.6117L18.165 15.3689L19 16.2039L17.7573 17.4272L19 18.6699L18.165 19.4854L16.9417 18.2621L15.699 19.4854ZM7.13252 18.3204C7.40663 18.3204 7.63754 18.2277 7.82524 18.0422C8.01295 17.8568 8.1068 17.627 8.1068 17.3529C8.1068 17.0788 8.01408 16.8479 7.82864 16.6602C7.64322 16.4725 7.41344 16.3786 7.13932 16.3786C6.86521 16.3786 6.6343 16.4714 6.4466 16.6568C6.2589 16.8422 6.16505 17.072 6.16505 17.3461C6.16505 17.6202 6.25777 17.8511 6.4432 18.0388C6.62863 18.2265 6.8584 18.3204 7.13252 18.3204ZM7.14334 19.4854C6.54293 19.4854 6.0356 19.2776 5.62136 18.8618C5.20712 18.446 5 17.9412 5 17.3472C5 16.7533 5.20766 16.2492 5.62297 15.835C6.0383 15.4207 6.54261 15.2136 7.13592 15.2136C7.62783 15.2136 8.06149 15.3592 8.43689 15.6505C8.8123 15.9417 9.06472 16.3204 9.19417 16.7864C9.81553 16.7605 10.3398 16.521 10.767 16.068C11.1942 15.6149 11.4078 15.0777 11.4078 14.4563V10.9417C11.4078 9.92104 11.7675 9.05097 12.4869 8.33155C13.2063 7.61214 14.0764 7.25243 15.0971 7.25243H16.7282L15.3107 5.83495L16.1456 5L18.9806 7.83495L16.1456 10.6699L15.3107 9.85437L16.7282 8.41748H15.0971C14.3959 8.41748 13.7999 8.66289 13.3091 9.15373C12.8182 9.64455 12.5728 10.2406 12.5728 10.9417V14.4563C12.5728 15.4013 12.246 16.2136 11.5922 16.8932C10.9385 17.5728 10.1392 17.9256 9.19417 17.9515C9.05178 18.4045 8.79146 18.7735 8.4132 19.0583C8.03494 19.343 7.61165 19.4854 7.14334 19.4854ZM5.81553 9.62136L5 8.80583L6.2233 7.56311L5 6.33981L5.81553 5.50485L7.05825 6.74757L8.28155 5.50485L9.1165 6.33981L7.87379 7.56311L9.1165 8.80583L8.28155 9.62136L7.05825 8.39806L5.81553 9.62136Z"
        fill="currentColor"
      />
    </svg>
  );
};
