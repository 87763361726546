import { MISMOAmount, MISMOEntity, MISMOString } from './types';

export enum FeeSummaryTotalFeesPaidToType {
  Broker = 'Broker',
  Investor = 'Investor',
  Lender = 'Lender',
  Other = 'Other',
}

export type FeeSummaryTotalFeesPaidTo = MISMOEntity &
  Partial<{
    feeSummaryTotalFeesPaidToAmount: MISMOAmount;
    feeSummaryTotalFeesPaidToType: FeeSummaryTotalFeesPaidToType;
    feeSummaryTotalFeesPaidToTypeOtherDescription: MISMOString;
  }>;
