import {
  AggregatedTradeRequestMessage,
  TradeRequest,
  TradeRequestMessageType,
} from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import { useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

const NUMBER_OF_ITEMS_PER_PAGE = 20;

type TradeRequestCounterpartyMessagesQuery = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId: string;
  messageType: TradeRequestMessageType;
};

export const useTradeRequestCounterpartyMessagesQuery = (
  query: TradeRequestCounterpartyMessagesQuery
) =>
  useInfiniteQuery(
    [QueryKeys.TRADE_REQUEST_COUNTERPARTY_MESSAGES, query],
    ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        limit: String(NUMBER_OF_ITEMS_PER_PAGE),
        offset: String(pageParam),
      });
      const { messageType, tradeCounterpartyId, tradeRequestId } = query;
      const url = makeApiUrl(
        `trade-requests/${tradeRequestId}/counterparties/${tradeCounterpartyId}/messages/${messageType}?${params}`
      );

      return getRequestWithAuth<AggregatedTradeRequestMessage[]>(url);
    },
    {
      getNextPageParam(lastPage = [], pages) {
        return lastPage.length < NUMBER_OF_ITEMS_PER_PAGE ? undefined : sumBy(pages, 'length');
      },
    }
  );
