import { NearbyProperties, PropertyScores, PropertyValuationHistory } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../utils/apiUtils';

type PropertyValuationHistoryResponse = PropertyValuationHistory;

export const getPropertyScores = (loanNumber: string) =>
  getRequestWithAuth<PropertyScores>(makeApiUrl(`loans/${loanNumber}/property-score`));

export const getNearbyProperties = (loanNumber: string) =>
  getRequestWithAuth<NearbyProperties>(makeApiUrl(`nearbyProperties/${loanNumber}`));

export const getPropertyValuationHistory = (loanNumber: string) =>
  getRequestWithAuth<PropertyValuationHistoryResponse>(
    makeApiUrl(`loans/${loanNumber}/property-valuation`)
  );
