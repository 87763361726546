import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

export enum AutomatedUnderwritingSystemType {
  Assetwise = 'Assetwise',
  Capstone = 'Capstone',
  Clues = 'Clues',
  DecisionEngine = 'DecisionEngine',
  DesktopUnderwriter = 'DesktopUnderwriter',
  ECS = 'ECS',
  FHAScorecard = 'FHAScorecard',
  FirstMortgageCreditScore = 'FirstMortgageCreditScore',
  GuaranteedUnderwritingSystem = 'GuaranteedUnderwritingSystem',
  LoanProductAdvisor = 'LoanProductAdvisor',
  LoanProspector = 'LoanProspector',
  Other = 'Other',
  Strategyware = 'Strategyware',
  Zippy = 'Zippy',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum AutomatedUnderwritingSystemTypeOtherDescription {
  GuaranteedUnderwritingSystem = 'GuaranteedUnderwritingSystem',
}

enum DesktopUnderwriterRecommendationType {
  ApproveEligible = 'ApproveEligible',
  ApproveIneligible = 'ApproveIneligible',
  Error = 'Error',
  ExpandedApproval1Eligible = 'ExpandedApproval1Eligible',
  ExpandedApproval1Ineligible = 'ExpandedApproval1Ineligible',
  ExpandedApproval2Eligible = 'ExpandedApproval2Eligible',
  ExpandedApproval2Ineligible = 'ExpandedApproval2Ineligible',
  ExpandedApproval3Eligible = 'ExpandedApproval3Eligible',
  ExpandedApproval3Ineligible = 'ExpandedApproval3Ineligible',
  OutOfScope = 'OutOfScope',
  ReferEligible = 'ReferEligible',
  ReferIneligible = 'ReferIneligible',
  ReferWithCaution4 = 'ReferWithCaution4',
  Unknown = 'Unknown',
}

enum FREPurchaseEligibilityType {
  FreddieMacEligible = 'FreddieMacEligible',
  FreddieMacIneligible = 'FreddieMacIneligible',
}

export type AutomatedUnderwriting = MISMOEntity &
  Partial<{
    automatedUnderwritingCaseIdentifier: MISMOIdentifier;
    automatedUnderwritingDecisionDatetime: MISMOIdentifier;
    automatedUnderwritingEvaluationStatusDescription: MISMOString;
    automatedUnderwritingMethodVersionIdentifier: MISMOIdentifier;
    automatedUnderwritingProcessDescription: MISMOString;
    automatedUnderwritingRecommendationDescription: MISMOString;
    automatedUnderwritingSystemDocumentWaiverIndicator: MISMOIndicator;
    automatedUnderwritingSystemResultValue: MISMOValue;
    automatedUnderwritingSystemType: AutomatedUnderwritingSystemType;
    automatedUnderwritingSystemTypeOtherDescription: MISMOString;
    desktopUnderwriterRecommendationType: DesktopUnderwriterRecommendationType;
    FHAPreReviewResultsValue: MISMOString;
    FREPurchaseEligibilityType: FREPurchaseEligibilityType;
    loanProspectorCreditRiskClassificationDescription: MISMOString;
    loanProspectorDocumentationClassificationDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

enum LoanUnderwritingSubmitterType {
  Broker = 'Broker',
  Correspondent = 'Correspondent',
  LenderOtherThanSeller = 'LenderOtherThanSeller',
  Other = 'Other',
  Seller = 'Seller',
}

export type Underwriting = MISMOEntity &
  Partial<{
    contractualAgreementIndicator: MISMOIndicator;
    contractUnderwritingIndicator: MISMOIndicator;
    firstLevelUnderwritingIndicator: MISMOIndicator;
    loanManualUnderwritingIndicator: MISMOIndicator;
    loanManualUnderwritingOrganizationName: MISMOString;
    loanUnderwritingInvestorGuidelinesIndicator: MISMOIndicator;
    loanUnderwritingSubmitterType: LoanUnderwritingSubmitterType;
    loanUnderwritingSubmitterTypeOtherDescription: MISMOString;
    underwritingCommentsDescription: MISMOString;
    automatedUnderwritings: AutomatedUnderwriting[];
  }>;
