import { Identifier } from '@plus-platform/shared';
import noop from 'lodash/noop';
import xor from 'lodash/xor';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { ButtonLoader } from '../../components/ButtonLoader';
import { PageHeader, PageSectionTitle } from '../../components/Page';
import { Radio } from '../../components/Radio';
import { Cell, HeaderCell, Thead } from '../../components/Table';
import { useImportLOSLoansMutation } from '../../hooks/queries/useImportLOSLoansMutation';
import { useLOSLoansQuery } from '../../hooks/queries/useLOSLoansQuery';
import * as Styles from './SelectLoansPage.styles';

export const SelectLoansPage = () => {
  const [selectedLoanIds, setSelectedLoanIds] = React.useState<Identifier[]>([]);
  const history = useHistory();
  const { data: loans, isLoading } = useLOSLoansQuery();
  const { isLoading: isImporting, mutateAsync: importLoans } = useImportLOSLoansMutation();

  const hasSelectedLoans = selectedLoanIds.length > 0;

  const toggleSelectedLoan = (id: Identifier) => {
    setSelectedLoanIds((ids) => xor(ids, [id]));
  };

  const isLoanSelected = (id: Identifier) => {
    return selectedLoanIds.includes(id);
  };

  const importSelectedLoans = async () => {
    await importLoans(selectedLoanIds);
    history.push('/import/assign-custodian');
  };

  return (
    <ActivityIndicator contain isActive={isLoading}>
      <Styles.Wrapper>
        <PageHeader>
          <PageSectionTitle>Choose loans to import</PageSectionTitle>
          <Styles.CountLabel>
            Showing <strong>{loans?.length}</strong> of <strong>{loans?.length}</strong> loans
          </Styles.CountLabel>
          <ButtonLoader
            style={{ marginTop: '10px', marginBottom: '20px' }}
            disabled={!hasSelectedLoans}
            isLoading={isImporting}
            onClick={importSelectedLoans}
          >
            Import selected loans
          </ButtonLoader>
        </PageHeader>

        <Styles.Table>
          <Thead>
            <Styles.TableRow $columnCount={0}>
              <HeaderCell />
              <HeaderCell>Loan ID</HeaderCell>
            </Styles.TableRow>
          </Thead>
          <Styles.TableBody>
            {loans?.map((loan) => (
              <Styles.TableRow
                $columnCount={0}
                key={loan.id}
                onClick={() => toggleSelectedLoan(loan.id)}
              >
                <Cell>
                  <Radio $size="small" checked={isLoanSelected(loan.id)} onChange={noop} />
                </Cell>
                <Cell>{loan.id}</Cell>
              </Styles.TableRow>
            ))}
          </Styles.TableBody>
        </Styles.Table>
      </Styles.Wrapper>
    </ActivityIndicator>
  );
};
