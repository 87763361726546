import React from 'react';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import * as Styles from './DocumentStepModal.styles';
import { SubmissionHeader } from './submissions/SubmissionHeader';
import { SubmissionStep } from './submissions/submissionUtils';

type Props = {
  step: SubmissionStep;
  children: React.ReactNode;
  isInline?: boolean;
  submissionNumber?: string;
  completePercent?: number;
};

const ModalContent = ({ children, completePercent, isInline, step, submissionNumber }: Props) => {
  return (
    <>
      <PageHeader>
        <Heading>Ingestion</Heading>
      </PageHeader>
      <PageContent>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/documents" title="Ingestion" />
          <BreadcrumbItem title={`Submission ${submissionNumber}`} />
        </Breadcrumb>
        <SubmissionHeader
          activeStep={step}
          submissionNumber={submissionNumber}
          completePercent={completePercent}
        />
        <Styles.Container $isInline={isInline}>{children}</Styles.Container>
      </PageContent>
    </>
  );
};

export const DocumentStepModal = ({
  children,
  completePercent,
  isInline = true,
  step,
  submissionNumber,
}: Props) => {
  if (!isInline) {
    return (
      <Styles.ModalBackground>
        <Styles.ModalWrapper>
          <Page style={{ padding: 0 }}>
            <ModalContent
              children={children}
              submissionNumber={submissionNumber}
              completePercent={completePercent}
              step={step}
            />
          </Page>
        </Styles.ModalWrapper>
      </Styles.ModalBackground>
    );
  }

  return (
    <ModalContent
      children={children}
      completePercent={completePercent}
      submissionNumber={submissionNumber}
      step={step}
    />
  );
};
