import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { ConversionAdjustment } from './conversionAdjustment';
import { InterestRateAdjustment } from './interestRateAdjustment';
import { PrincipalAndInterestPaymentAdjustment } from './principalAndInterestPaymentAdjustment';
import { AdjustmentRuleType } from './types';

enum AdjustmentChangeOccurrenceType {
  ARM = 'ARM',
  Conversion = 'Conversion',
  GEM = 'GEM',
  GPM = 'GPM',
  LoanToValue = 'LoanToValue',
  NonScheduled = 'NonScheduled',
  Other = 'Other',
  Step = 'Step',
  TimelyPaymentReward = 'TimelyPaymentReward',
}

export enum ConvertibleStatusType {
  Active = 'Active',
  Exercised = 'Exercised',
  Expired = 'Expired',
}

type RateOrPaymentChangeOccurrence = MISMOEntity &
  Partial<{
    adjustmentChangeEffectiveDueDate: MISMODate;
    adjustmentChangeIndexRatePercent: MISMOPercent;
    adjustmentChangeInterestRatePercent: MISMOPercent;
    adjustmentChangeOccurrenceExtendedTermMonthsCount: MISMOCount;
    adjustmentChangeOccurrencePassThroughRatePercent: MISMOPercent;
    adjustmentChangeOccurrenceType: AdjustmentChangeOccurrenceType;
    adjustmentChangeOccurrenceTypeOtherDescription: MISMOString;
    adjustmentChangePrincipalAndInterestPaymentAmount: MISMOAmount;
    adjustmentChangeProjectedPrincipalBalanceAmount: MISMOAmount;
    adjustmentChangeServiceFeeAmount: MISMOAmount;
    adjustmentChangeServiceFeeRatePercent: MISMOPercent;
    adjustmentRuleType: AdjustmentRuleType;
    balloonResetDate: MISMODate;
    carryoverRatePercent: MISMOPercent;
    convertibleStatusType: ConvertibleStatusType;
    lastRateChangeNotificationDate: MISMODate;
    latestConversionEffectiveDate: MISMODate;
    nextConversionOptionEffectiveDate: MISMODate;
    nextConversionOptionNoticeDate: MISMODate;
    nextDemandConversionOptionNoticeDate: MISMODate;
    nextIgnorePrincipalAndInterestPaymentAdjustmentCapsDate: MISMODate;
    nextIgnoreRateAdjustmentCapsDate: MISMODate;
    nextPrincipalAndInterestPaymentChangeEffectiveDate: MISMODate;
    nextRateAdjustmentEffectiveDate: MISMODate;
    nextRateAdjustmentEffectiveNoticeDate: MISMODate;
    rateAdjustmentPercent: MISMODate;
    sequenceNumber: MISMOSequenceNumber;
    servicerARMPlanIdentifier: MISMOIdentifier;
  }>;

export type Adjustment = MISMOEntity &
  Partial<{
    conversionAdjustment: ConversionAdjustment;
    interestRateAdjustment: InterestRateAdjustment;
    principalAndInterestPaymentAdjustment: PrincipalAndInterestPaymentAdjustment;
    rateOrPaymentChangeOccurrences: RateOrPaymentChangeOccurrence[];
  }>;
