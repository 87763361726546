import {
  canBuyerCounterpartyExchangeMessages,
  CounterpartyType,
  getNotificationTypesForTradeTab,
  hasSellerCounterpartyExpressedInterest,
  OutboundTradeRequestWithSummary,
  TradeRequestTabType,
} from '@plus-platform/shared';
import noop from 'lodash/noop';

import { AvatarWithFallback } from '../../../components/AvatarWithFallback';
import { useMarkAllNotificationsAsReadMutation } from '../../../hooks/queries/useMarkAllNotificationsAsReadMutation';
import { SidebarHeading, SidebarItem } from '../../TradeRequestPageLayout';
import { formatNameWithInitial, getSellerCounterparty } from '../../tradeRequestsUtils';
import * as Styles from '../Messenger.styles';
import { TradeRequestTabNotifications } from '../TradeRequestTabNotifications';
import { useMessagingNavigation } from './useMessagingNavigation';

type OutboundMessagingContactTabsProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundMessagingContactTabs = (props: OutboundMessagingContactTabsProps) => {
  const { goToMessagingWithCounterparty, isCounterpartyMessagingActive } = useMessagingNavigation();
  const { mutateAsync: markAllNotificationsAsRead } = useMarkAllNotificationsAsReadMutation();
  const { counterparties } = props.tradeRequest;

  const sellerCounterparty = getSellerCounterparty(counterparties);
  const hasSellerAcceptedExpressionOfInterest = hasSellerCounterpartyExpressedInterest(
    (sellerCounterparty?.actions || []).map((action) => action.type)
  );

  const counterpartiesAllowedToExchangeMessages = sellerCounterparty
    ? counterparties.filter((counterparty) => {
        return (
          hasSellerAcceptedExpressionOfInterest &&
          counterparty.type === CounterpartyType.BUYER &&
          canBuyerCounterpartyExchangeMessages(counterparty.actions.map((action) => action.type))
        );
      })
    : [];

  if (!sellerCounterparty || !counterpartiesAllowedToExchangeMessages.length) {
    return null;
  }

  return (
    <SidebarItem>
      <SidebarHeading>Direct messages</SidebarHeading>
      <Styles.Tabs>
        {counterpartiesAllowedToExchangeMessages.map(({ id, user }) => (
          <Styles.Tab key={id}>
            <Styles.TabButton
              $isActive={isCounterpartyMessagingActive(String(id))}
              onClick={() => {
                markAllNotificationsAsRead({
                  counterpartyId: sellerCounterparty.id,
                  types: getNotificationTypesForTradeTab(TradeRequestTabType.MESSAGES),
                  tradeRequestId: props.tradeRequest.id,
                }).catch(noop);
                goToMessagingWithCounterparty(String(id));
              }}
            >
              <AvatarWithFallback
                firstName={user.firstName}
                lastName={user.lastName}
                src={user.avatarUrl}
                size={24}
              />
              <Styles.TabButtonText>
                {formatNameWithInitial(user.lastName, user.firstName)}
              </Styles.TabButtonText>
              <TradeRequestTabNotifications
                counterpartyId={sellerCounterparty.id}
                notificationTypes={getNotificationTypesForTradeTab(TradeRequestTabType.MESSAGES)}
                tradeRequestId={props.tradeRequest.id}
              />
            </Styles.TabButton>
          </Styles.Tab>
        ))}
      </Styles.Tabs>
    </SidebarItem>
  );
};
