import { GetPoolLoansItem, Loan } from '@plus-platform/shared';
import { RefObject, useRef } from 'react';

import { getPropertyPhotoForLoan } from '../assets/photos';
import { ActivityIndicator } from '../components/ActivityIndicator';
import { ButtonIcon } from '../components/ButtonIcon';
import { Card } from '../components/Card';
import {
  AssetLedgerIcon,
  CensusDataIcon,
  CloseLineIcon,
  CreditCardIcon,
  CustodianIcon,
  FinancialsIcon,
  FolderIcon,
  HistoryIcon,
  InsuranceIcon,
  OriginationIcon,
  PersonIcon,
  PropertyIcon,
  ServicerIcon,
  StipulationsLineIcon,
} from '../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection } from '../components/Menu';
import { useLoanSummaryQuery } from '../hooks/queries';
import { formatPoolSaleDate } from '../trades/tradeRequestsUtils';
import { LoanProvider, useLoanContext } from './context';
import * as Styles from './LoanDetailPanel.styles';
import {
  LoanAcquisitionWidget,
  LoanAmortizationWidget,
  LoanAssetLedgerWidget,
  LoanBorrowersWidget,
  LoanCustodialWidget,
  LoanDemographicsWidget,
  LoanDocumentsWidget,
  LoanFinancialsWidget,
  LoanInsuranceWidget,
  LoanNotesWidget,
  LoanOriginationWidget,
  LoanPropertyWidget,
  LoanServicerBasicWidget,
} from './LoanSummaryWidgets';

type LoanDetailPanelProps = {
  loan?: GetPoolLoansItem;
  onClose: () => void;
};

export const LoanDetailPanel = ({ loan, onClose }: LoanDetailPanelProps) => {
  const { data: loanSummary, isLoading } = useLoanSummaryQuery(loan?.loanDetails?.loanNumber);

  return (
    <Card $hasFlex $hasScroll>
      <Styles.Wrapper>
        <ActivityIndicator contain isActive={isLoading}>
          {loanSummary && <LoanDetails loanSummary={loanSummary} onClose={onClose} />}
        </ActivityIndicator>
      </Styles.Wrapper>
    </Card>
  );
};

type LoanDetailsProps = {
  loanSummary: Loan;
  onClose: () => void;
};

const LoanDetails = ({ loanSummary, onClose }: LoanDetailsProps) => {
  const financialsRef = useRef<HTMLDivElement>(null);
  const propertyRef = useRef<HTMLDivElement>(null);
  const documentsRef = useRef<HTMLDivElement>(null);
  const originationRef = useRef<HTMLDivElement>(null);
  const borrowersRef = useRef<HTMLDivElement>(null);
  const assetLedgerRef = useRef<HTMLDivElement>(null);
  const custodialRef = useRef<HTMLDivElement>(null);
  const servicerRef = useRef<HTMLDivElement>(null);
  const acquisitionRef = useRef<HTMLDivElement>(null);
  const amortizationRef = useRef<HTMLDivElement>(null);
  const insuranceRef = useRef<HTMLDivElement>(null);
  const notesRef = useRef<HTMLDivElement>(null);
  const demographicsRef = useRef<HTMLDivElement>(null);

  const { isAnonymised } = useLoanContext();

  const handleScrollIntoView = (ref: RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const variant = 'light';
  const formattedPoolSaleDate = formatPoolSaleDate(
    // TODO: Determine trade status based on counterparties
    // loanSummary?.pool?.tradeRequest?.status,
    undefined,
    loanSummary?.pool?.tradeRequest?.updatedAt
  );

  const propertyPhotoPath = getPropertyPhotoForLoan(loanSummary.id);
  const { loanNumber } = loanSummary.loanDetails;

  return (
    <>
      <Styles.Header>
        <Menu
          title={`Loan ${loanNumber}`}
          direction="right"
          paddingSide={13}
          toggleProps={{ size: 'medium' }}
        >
          <MenuSection>
            <MenuItem onClick={() => handleScrollIntoView(financialsRef)}>
              <FinancialsIcon />
              <MenuItemText>Financials</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(propertyRef)}>
              <PropertyIcon />
              <MenuItemText>Property</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(originationRef)}>
              <OriginationIcon />
              <MenuItemText>Origination</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(borrowersRef)}>
              <PersonIcon />
              <MenuItemText>Borrower</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(custodialRef)}>
              <CustodianIcon />
              <MenuItemText>Custodial</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(servicerRef)}>
              <ServicerIcon />
              <MenuItemText>Servicer</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(insuranceRef)} disabled={isAnonymised}>
              <InsuranceIcon />
              <MenuItemText>Insurance</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(acquisitionRef)} disabled={isAnonymised}>
              <HistoryIcon />
              <MenuItemText>Aquisition history</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(documentsRef)}>
              <FolderIcon />
              <MenuItemText>Document library</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(assetLedgerRef)}>
              <AssetLedgerIcon />
              <MenuItemText>Asset ledger</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(amortizationRef)}>
              <CreditCardIcon />
              <MenuItemText>Amortization & payment status</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(demographicsRef)}>
              <CensusDataIcon />
              <MenuItemText>Census data</MenuItemText>
            </MenuItem>
            <MenuItem onClick={() => handleScrollIntoView(notesRef)} disabled={isAnonymised}>
              <StipulationsLineIcon />
              <MenuItemText>Notes</MenuItemText>
            </MenuItem>
          </MenuSection>
        </Menu>
        <span>Loan {loanNumber}</span>
        <ButtonIcon onClick={onClose} size="medium" variant="dark">
          <CloseLineIcon />
        </ButtonIcon>
      </Styles.Header>
      <Styles.Image alt="Property" src={propertyPhotoPath} />
      <Styles.Sections>
        <LoanProvider value={{ loanNumber }}>
          <>
            <LoanFinancialsWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={financialsRef}
            />
            <LoanPropertyWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={propertyRef}
            />
            <LoanOriginationWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={originationRef}
            />
            <LoanBorrowersWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={borrowersRef}
            />
            <LoanCustodialWidget loanNumber={loanNumber} variant={variant} ref={custodialRef} />
            <LoanServicerBasicWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={servicerRef}
            />
            {!isAnonymised && (
              <LoanInsuranceWidget
                loanNumber={loanNumber}
                loanSummary={loanSummary}
                variant={variant}
                ref={insuranceRef}
              />
            )}
            {!isAnonymised && (
              <LoanAcquisitionWidget
                formattedPoolSaleDate={formattedPoolSaleDate}
                loanNumber={loanNumber}
                loanSummary={loanSummary}
                variant={variant}
                ref={acquisitionRef}
              />
            )}
            <LoanDocumentsWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={documentsRef}
            />
            <LoanAssetLedgerWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={assetLedgerRef}
            />
            <LoanAmortizationWidget
              loanNumber={loanNumber}
              variant={variant}
              ref={amortizationRef}
            />
            <LoanDemographicsWidget
              loanNumber={loanNumber}
              loanSummary={loanSummary}
              variant={variant}
              ref={demographicsRef}
            />
            {!isAnonymised && (
              <LoanNotesWidget loanNumber={loanNumber} variant={variant} ref={notesRef} />
            )}
          </>
        </LoanProvider>
      </Styles.Sections>
    </>
  );
};
