import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
  MISMOURI,
} from '../types';

export enum LoanIdentifierType {
  AgencyCase = 'AgencyCase',
  InvestorCommitment = 'InvestorCommitment',
  InvestorContract = 'InvestorContract',
  InvestorLoan = 'InvestorLoan',
  InvestorWorkoutCase = 'InvestorWorkoutCase',
  LenderCase = 'LenderCase',
  LenderLoan = 'LenderLoan',
  LoanPriceQuote = 'LoanPriceQuote',
  MERS_MIN = 'MERS_MIN',
  MIRateQuote = 'MIRateQuote',
  NewServicerLoan = 'NewServicerLoan',
  Other = 'Other',
  PoolIssuerLoan = 'PoolIssuerLoan',
  PriceResponse = 'PriceResponse',
  SellerLoan = 'SellerLoan',
  ServicerLoan = 'ServicerLoan',
  ServicerWorkoutCase = 'ServicerWorkoutCase',
  SubservicerLoan = 'SubservicerLoan',
  UniversalLoan = 'UniversalLoan',
  WholesaleLenderLoan = 'WholesaleLenderLoan',
}

export type LoanIdentifier = MISMOEntity &
  Partial<{
    loanIdentifier: MISMOIdentifier;
    'loanIdentifier@IdentifierOwnerURI': MISMOURI;
    loanIdentifierType: LoanIdentifierType;
    loanIdentifierTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
