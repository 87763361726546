import React from 'react';

export const HashtagLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.64444 19L7.53333 15.5219H4L4.33333 14.2094H7.86667L9 9.79063H5.46667L5.8 8.47812H9.33333L10.2 5H11.5111L10.6444 8.47812H15.1556L16.0222 5H17.3333L16.4667 8.47812H20L19.6667 9.79063H16.1333L15 14.2094H18.5333L18.2 15.5219H14.6667L13.7778 19H12.4667L13.3556 15.5219H8.84444L7.95556 19H6.64444ZM9.17778 14.2094H13.6889L14.8222 9.79063H10.3111L9.17778 14.2094Z"
        fill="currentColor"
      />
    </svg>
  );
};
