import { GetNotificationsForUserResponse, NotificationDto } from '@plus-platform/shared';
import { InfiniteData } from 'react-query';

export const getNotificationItemsFromInfinitePagedList = (
  infinitePagedNotifications: InfiniteData<GetNotificationsForUserResponse | undefined> | undefined
): {
  unreadNotifications: GetNotificationsForUserResponse['items'];
  readNotifications: GetNotificationsForUserResponse['items'];
} => {
  const notificationItems = (infinitePagedNotifications?.pages
    .filter((page) => Boolean(page?.items))
    .flatMap((page) => page?.items) ?? []) as NotificationDto[];

  return {
    unreadNotifications: notificationItems.filter((notification) => !notification.read),
    readNotifications: notificationItems.filter((notification) => notification.read),
  };
};
