import { ScheduleLine } from '@plus-platform/shared';
import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Card } from '../../../components/Card';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Loader } from '../../../components/Loader';
import { WidgetSectionWrapper } from '../../../components/Widget';
import { AmortizationChart } from '../../LoanAmortization/AmortizationChart';
import { AmortizationChartLegendDetailed } from '../../LoanAmortization/AmortizationChartLegend';
import { AmortizationInformation } from '../../LoanAmortization/AmortizationInformation';
import { useLoanAmortization } from '../../LoanData';
import * as Styles from './LoanDetailAmortization.styles';

const LoanDetailAmortizationContent = () => {
  const [selectedScheduleLine, setSelectedScheduleLine] = useState<ScheduleLine | undefined>();

  const { data, isLoading } = useLoanAmortization();

  useEffect(() => {
    if (data && !isLoading) {
      setSelectedScheduleLine(
        data?.amortizationSchedule.find(
          (schedule) => schedule.paymentNumber === data.nextPaymentNumber
        )
      );
    }
  }, [data, isLoading]);

  return (
    <ActivityIndicator isActive={isLoading}>
      {data && (
        <>
          <Styles.AmortizationWidgetSection>
            <AmortizationInformation
              asOfDate={data.asOfDate}
              nextPaymentNumber={data.nextPaymentNumber}
              numbersOfPaymentsBehind={data.numbersOfPaymentsBehind}
              refinanceProjection={data.refinanceProjection}
              termMonths={data.termMonths}
            />
          </Styles.AmortizationWidgetSection>
          <Divider $flexItem $orientation="vertical" />
        </>
      )}
      {data?.amortizationSchedule && selectedScheduleLine && (
        <Styles.ChartWrapper>
          <Styles.Chart>
            <AmortizationChart
              amortizationSchedule={data.amortizationSchedule}
              selectedScheduleLine={selectedScheduleLine}
              setSelectedScheduleLine={setSelectedScheduleLine}
            />
          </Styles.Chart>
          <Styles.ChartDescription>
            <Styles.AmortizationPadding>
              <AmortizationChartLegendDetailed
                amortizationSchedule={data.amortizationSchedule}
                selectedScheduleLine={selectedScheduleLine}
              />
            </Styles.AmortizationPadding>
          </Styles.ChartDescription>
        </Styles.ChartWrapper>
      )}
    </ActivityIndicator>
  );
};

const LoanDetailAmortization = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <Card $hasPadding id="amortization" ref={ref} data-testid="LoanDetail_Amortization">
      <Heading $size="medium">Amortization &amp; Payment Status</Heading>
      <WidgetSectionWrapper>
        <React.Suspense fallback={<Loader />}>
          <LoanDetailAmortizationContent />
        </React.Suspense>
      </WidgetSectionWrapper>
    </Card>
  );
});

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailAmortization;
