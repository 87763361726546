import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
  TradeCounterpartyActionHistoryFilter,
  TradeCounterpartyActionType,
} from '@plus-platform/shared';
import React from 'react';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Heading } from '../../../components/Heading';
import { DateDivider } from '../../../components/Messages/DateDivider';
import {
  isMessageDate,
  shouldDisplayDateDivider,
} from '../../../components/Messages/messengerUtils';
import {
  DateMessage,
  MessageType,
  OutboundActionMessage,
  OutboundCTAMessage,
  OutboundMessage,
} from '../../../components/Messages/types';
import { useTradeRequestOutboundActionsHistory } from '../../useTradeRequestOutboundActionsHistory';
import * as Styles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import { OutboundDataRequestAcceptDecline } from './Messages/OutboundDataRequestAcceptDeny';
import { OutboundDataRequestAccepted } from './Messages/OutboundDataRequestAccepted';
import { OutboundDataRequestDenied } from './Messages/OutboundDataRequestDenied';
import { OutboundDataRequestRequested } from './Messages/OutboundDataRequestRequested';

type OutboundTradeRequestDataRequestsProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundTradeRequestDataRequests = (props: OutboundTradeRequestDataRequestsProps) => {
  const { tradeRequest } = props;

  const { actions, fetchMoreActions, isLoading } = useTradeRequestOutboundActionsHistory(
    String(tradeRequest.id),
    TradeCounterpartyActionHistoryFilter.DATA_REQUEST
  );

  const messageList = React.useMemo(() => {
    const isPendingRequest = (
      actions: OutboundTradeCounterpartyActionWithSummary[],
      index: number
    ) => {
      const isSettled = actions
        .slice(index)
        .find(
          (action) =>
            [
              TradeCounterpartyActionType.ACCEPT_DATA_REQUEST,
              TradeCounterpartyActionType.DENY_DATA_REQUEST,
            ].includes(action.type) &&
            action.fromCounterparty?.id === actions[index]?.fromCounterparty?.id
        );

      return !isSettled;
    };

    return actions.flatMap((action, index) => {
      const previousAction = actions[index - 1];

      let messages: OutboundMessage[] = [];

      if (
        action.createdAt &&
        shouldDisplayDateDivider(action.createdAt, previousAction?.createdAt)
      ) {
        const message: DateMessage = {
          type: MessageType.DATE_DIVIDER,
          data: { date: new Date(action.createdAt) },
        };
        messages = [...messages, message];
      }

      if (
        [
          TradeCounterpartyActionType.REQUEST_DATA_REQUEST,
          TradeCounterpartyActionType.ACCEPT_DATA_REQUEST,
          TradeCounterpartyActionType.DENY_DATA_REQUEST,
        ].includes(action.type)
      ) {
        const message: OutboundActionMessage = {
          type: MessageType.ACTION,
          data: { action, tradeRequest },
        };
        messages = [...messages, message];
      }

      if (
        action.type === TradeCounterpartyActionType.REQUEST_DATA_REQUEST &&
        isPendingRequest(actions, index)
      ) {
        const message: OutboundCTAMessage = {
          type: MessageType.CTA,
          data: { action, tradeRequest },
        };
        messages = [...messages, message];
      }

      return messages;
    });
  }, [actions, tradeRequest]);

  return (
    <ActivityIndicator contain isActive={isLoading}>
      <Styles.Header>
        <Heading $size="medium">Data requests</Heading>
        <Styles.IntroText>
          This is the very beginning of Data requests on trading pool{' '}
          <strong>{tradeRequest.pool.name}</strong> ticket ID <strong>{tradeRequest.id}</strong>.
        </Styles.IntroText>
      </Styles.Header>
      <MessengerList<OutboundMessage> loadMore={fetchMoreActions} messages={messageList}>
        {({ index, measure, message }) => (
          <React.Fragment>
            {isMessageDate(message) && (
              <DateDivider
                key={`${(message as DateMessage).data.date}`}
                variant="outline"
                {...(message as DateMessage).data}
              />
            )}
            {message.type === MessageType.ACTION && (
              <React.Fragment>
                {message.data.action.type === TradeCounterpartyActionType.REQUEST_DATA_REQUEST && (
                  <OutboundDataRequestRequested {...message.data} />
                )}
                {message.data.action.type === TradeCounterpartyActionType.ACCEPT_DATA_REQUEST && (
                  <OutboundDataRequestAccepted {...message.data} />
                )}
                {message.data.action.type === TradeCounterpartyActionType.DENY_DATA_REQUEST && (
                  <OutboundDataRequestDenied {...message.data} />
                )}
              </React.Fragment>
            )}
            {message.type === MessageType.CTA && index === messageList.length - 1 && (
              <React.Fragment>
                {message.data.action.type === TradeCounterpartyActionType.REQUEST_DATA_REQUEST && (
                  <OutboundDataRequestAcceptDecline
                    onTransferData={measure}
                    onTransferDataCancellation={measure}
                    onTransferDataConfirmation={measure}
                    onTransferDataDecline={measure}
                    onTransferDataDeclineWithExplanation={measure}
                    onTransferDataDeclineWithExplanationConfirm={measure}
                    onTransferDataDeclineWithoutExplanation={measure}
                    {...message.data}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </MessengerList>
    </ActivityIndicator>
  );
};
