import { LienPriorityType } from '../lien';
import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOPercent,
  MISMOString,
} from '../types';

export enum LoanPurposeType {
  MortgageModification = 'MortgageModification',
  Other = 'Other',
  Purchase = 'Purchase',
  Refinance = 'Refinance',
  Unknown = 'Unknown',
}

export enum MortgageType {
  Conventional = 'Conventional',
  FHA = 'FHA',
  LocalAgency = 'LocalAgency',
  Other = 'Other',
  PublicAndIndianHousing = 'PublicAndIndianHousing',
  StateAgency = 'StateAgency',
  // NOTE: This is named USDARuralHousing by ULDD and USDARuralDevelopment by MISMO
  USDARuralDevelopment = 'USDARuralDevelopment',
  VA = 'VA',
}

enum SupplementalMortgageType {}

enum UPBChangeFrequencyType {
  Annually = 'Annually',
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Semiannually = 'Semiannually',
  Semimonthly = 'Semimonthly',
  Weekly = 'Weekly',
}

export type TermsOfLoan = MISMOEntity &
  Partial<{
    assumedLoanAmount: MISMOAmount;
    baseLoanAmount: MISMOAmount;
    disclosedFullyIndexedRatePercent: MISMOPercent;
    disclosedIndexRatePercent: MISMOPercent;
    disclosedMarginRatePercent: MISMOPercent;
    lienPriorityType: LienPriorityType;
    lienPriorityTypeOtherDescription: MISMOString;
    loanPurposeType: LoanPurposeType;
    loanPurposeTypeOtherDescription: MISMOString;
    mortgageType: MortgageType;
    mortgageTypeOtherDescription: MISMOString;
    noteAmount: MISMOAmount;
    noteCityName: MISMOString;
    noteDate: MISMODate;
    noteRatePercent: MISMOPercent;
    noteStateName: MISMOString;
    originalInterestRateDiscountPercent: MISMOPercent;
    sharedAppreciationCapAmount: MISMOAmount;
    sharedAppreciationRatePercent: MISMOPercent;
    supplementalMortgageType: SupplementalMortgageType;
    supplementalMortgageTypeOtherDescription: MISMOString;
    UPBChangeFrequencyType: UPBChangeFrequencyType;
    weightedAverageInterestRatePercent: MISMOPercent;
  }>;
