import { LoanDetails } from './loans';
import { OrganizationIdentifier } from './organizations';
import { UserIdentifier } from './users';

export type SubmissionCreation = {
  id: number;
  fileNames: string[];
  flowId: string;
  restrictions: string[];
};

export enum SubmissionType {
  SUBMISSION_UPLOAD = 'SUBMISSION_UPLOAD',
  SUBMISSION_ENCOMPASS = 'SUBMISSION_ENCOMPASS',
}

export interface SubmissionAttributes {
  id: string;
  organizationId: OrganizationIdentifier;
  userId: UserIdentifier;
  submissionNumber: string;
  type: SubmissionType;
  filesReceived: number;
  filesProcessed?: number;
  filesErrored?: number;
  bookId?: string;
  loans?: LoanDetails[];
  documents?: SubmissionDocumentAttributes[];
  validationErrors?: SubmissionErrorAttributes[];
  createdAt: Date;
  updatedAt: Date;
}

export interface SubmissionDocumentAttributes {
  id: string;
  submissionId: string;
  ocrolusUuid: string;
  ocrolusMixedId?: number;
  documentType?: string;
  documentName: string;
  pageCount: number;
  status: string;
  rejectionReason?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum SubmissionErrorOrigin {
  OCR = 'OCR',
  MISMO = 'MISMO',
  ULDD = 'ULDD',
  MERGER = 'MERGER',
  CUSTOM = 'CUSTOM',
  SUBMISSION = 'SUBMISSION',
}

export interface SubmissionErrorAttributes {
  id: string;
  submissionId: string;
  severity: string;
  message: string;
  origin: SubmissionErrorOrigin;
  ocrolusUuid?: string;
  fieldName?: string;
  friendlyFieldName?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface SubmissionSummary {
  submissionCount: number;
  ingestedCount: number;
  validatedCount: number;
}

export type HoldingTankDocumentResult = {
  submissionId: string;
  submissionCreatedAt: string;
  message: string;
  severity: string;
  origin: SubmissionErrorOrigin;
  fieldName: string;
  friendlyFieldName: string;
  details: {
    ocrulusUuid: string;
    type: string;
    name: string;
    rejectionReason: string;
  };
};

export type HoldingTankDocument = {
  submissionId: string;
  submissionNumber: string;
  submissionDate: string;
  fileType: string;
  documents: HoldingTankDocumentResult[];
};

export type HoldingTankDocumentError = {
  id: string;
  submissionErrorId: string;
  isDeleted: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type GetSubmissionsResponse = {
  pageNumber: number;
  pageCount: number;
  totalSubmissionsCount: number;
  data: SubmissionAttributes[];
};
