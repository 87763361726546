import {
  AttributeType,
  InboundTradeRequestWithSummary,
  PermissionType,
  TradeCounterpartyActionType,
  TradeRequestTabType,
} from '@plus-platform/shared';
import { Switch, useHistory } from 'react-router';
import { useTheme } from 'styled-components/macro';

import { Card } from '../../components/Card';
import { HeadingLink } from '../../components/HeadingLink';
import {
  BidLineIcon,
  ChatLineIcon,
  DataRequestLineIcon,
  StipulationsLineIcon,
} from '../../components/icons';
import { KeyValueList } from '../../components/KeyValue';
import { TradeStatus } from '../../components/TradeStatus';
import { PrivateRoute } from '../../global/PrivateRoute';
import { TradeRequestData } from '../TradeRequestData';
import { Messenger } from '../TradeRequestMessenger/Messenger';
import { getSellerCounterparty, getStepStatuses } from '../tradeRequestsUtils';
import { TradeRequestCategory, TradeRequestStep } from '../types';
import {
  TradeTicketLoansCount,
  TradeTicketLoansCoupon,
  TradeTicketLoansJudicialSplit,
  TradeTicketLoansRemittance,
  TradeTicketLoansUPB,
  TradeTicketLoansWeightedAverageLTV,
  TradeTicketSeller,
  TradeTicketSendDate,
  TradeTicketSender,
} from './TradeTicket';
import * as Styles from './TradeTicket.styles';

const getActionProps = (step: TradeRequestStep, tradeRequest: InboundTradeRequestWithSummary) => {
  const statuses = getStepStatuses(tradeRequest);

  return {
    isActive: statuses.isActive[step],
    isPending: statuses.isPending[step],
    isComplete: statuses.isComplete[step],
  };
};

type TradeTicketActivityProps = {
  size: 'small' | 'medium' | 'large';
  variant?: 'default' | 'light';
  tradeRequest: InboundTradeRequestWithSummary;
};

const TradeTicketActivity = ({ size, tradeRequest, variant }: TradeTicketActivityProps) => {
  const theme = useTheme();

  const dataRequest = getActionProps(TradeRequestStep.DATA_REQUEST, tradeRequest);

  const messages = [];
  const bids = [];
  const stipulations = [];

  const showLabel = size === 'large';

  return (
    <Styles.Statuses $count={4} $size={size}>
      <TradeStatus
        color={theme.colors.statusTurquoise}
        count={stipulations.length}
        icon={<StipulationsLineIcon />}
        isInline={false}
        label={showLabel ? 'Stipulations' : undefined}
        variant={variant}
      />
      <TradeStatus
        count={messages.length}
        icon={<ChatLineIcon />}
        isInline={false}
        label={showLabel ? 'Messages' : undefined}
        variant={variant}
      />
      <TradeStatus
        color={theme.colors.statusOrange}
        isComplete={dataRequest.isComplete}
        icon={<DataRequestLineIcon />}
        isInline={false}
        label={showLabel ? 'Request data' : undefined}
        variant={variant}
      />
      <TradeStatus
        count={bids.length}
        icon={<BidLineIcon />}
        isInline={false}
        label={showLabel ? 'Bids' : undefined}
        variant={variant}
      />
    </Styles.Statuses>
  );
};

// TODO: Implement ticket actions for inbound tickets
const TicketActions = () => {
  return <Styles.Actions />;
};

type InboundTicketCardProps = {
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  tradeRequest: InboundTradeRequestWithSummary;
  url: string;
  variant?: 'default' | 'light';
};

const InboundTicketCard = ({
  children,
  size,
  tradeRequest,
  url,
  variant,
}: InboundTicketCardProps) => {
  return (
    <Card $variant={variant}>
      <Styles.Header $variant={variant}>
        <HeadingLink $size="medium" $variant={variant} to={url}>
          Inbound RFQ - {tradeRequest.pool.name}
        </HeadingLink>
        <TradeTicketActivity size={size} tradeRequest={tradeRequest} variant={variant} />
      </Styles.Header>
      {children}
      <TicketActions />
    </Card>
  );
};

type InboundTradeRequestProps = {
  tradeRequest: InboundTradeRequestWithSummary;
  url: string;
  variant?: 'default' | 'light';
};

export const InboundTradeRequestSmall = (props: InboundTradeRequestProps) => {
  const { tradeRequest } = props;
  return (
    <InboundTicketCard size="small" {...props}>
      <Styles.TradeInfo $size="small">
        <KeyValueList $size="small" $templateColumns="60px auto" $type="list">
          <TradeTicketSendDate tradeRequest={tradeRequest} />
          <TradeTicketSender tradeRequest={tradeRequest} />
          <TradeTicketSeller tradeRequest={tradeRequest} />
        </KeyValueList>
        <KeyValueList $size="small" $templateColumns="82px auto" $type="list">
          <TradeTicketLoansCount tradeRequest={tradeRequest} />
          <TradeTicketLoansUPB tradeRequest={tradeRequest} />
          <TradeTicketLoansCoupon tradeRequest={tradeRequest} />
        </KeyValueList>
      </Styles.TradeInfo>
    </InboundTicketCard>
  );
};

export const InboundTradeRequestMedium = (props: InboundTradeRequestProps) => {
  const { tradeRequest } = props;
  return (
    <InboundTicketCard size="medium" {...props}>
      <Styles.TradeInfo $size="medium">
        <KeyValueList $size="large" $templateColumns="80px auto" $type="list">
          <TradeTicketSendDate tradeRequest={tradeRequest} />
          <TradeTicketSender tradeRequest={tradeRequest} />
          <TradeTicketSeller tradeRequest={tradeRequest} />
        </KeyValueList>
        <KeyValueList $size="large" $templateColumns="82px auto" $type="list">
          <TradeTicketLoansCount tradeRequest={tradeRequest} />
          <TradeTicketLoansUPB tradeRequest={tradeRequest} />
          <TradeTicketLoansRemittance tradeRequest={tradeRequest} />
        </KeyValueList>
        <KeyValueList $size="large" $templateColumns="140px auto" $type="list">
          <TradeTicketLoansCoupon tradeRequest={tradeRequest} />
          <TradeTicketLoansWeightedAverageLTV tradeRequest={tradeRequest} />
          <TradeTicketLoansJudicialSplit tradeRequest={tradeRequest} />
        </KeyValueList>
      </Styles.TradeInfo>
    </InboundTicketCard>
  );
};

export const InboundTradeRequestLarge = (props: InboundTradeRequestProps) => {
  const { tradeRequest } = props;
  return (
    <InboundTicketCard size="large" {...props}>
      <Styles.TradeInfo $size="large">
        <TradeTicketSendDate
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketSender
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketSeller
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansCount
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansUPB
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansRemittance
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansCoupon
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansWeightedAverageLTV
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansJudicialSplit
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
      </Styles.TradeInfo>
    </InboundTicketCard>
  );
};

type InboundTradeRequestSidebarProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundTradeRequestSidebar = ({ tradeRequest }: InboundTradeRequestSidebarProps) => {
  return (
    <Styles.TradeInfoVertical>
      <TradeTicketSendDate tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketSender tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketSeller tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansCount tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansUPB tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansRemittance tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansCoupon tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansWeightedAverageLTV
        tradeRequest={tradeRequest}
        $align="left"
        $direction="vertical"
      />
      <TradeTicketLoansJudicialSplit
        tradeRequest={tradeRequest}
        $align="left"
        $direction="vertical"
      />
    </Styles.TradeInfoVertical>
  );
};

const TRADE_STEP_TO_TAB_MAP = {
  [TradeRequestStep.EXPRESSION_OF_INTEREST]: TradeRequestTabType.EXPRESSIONS_OF_INTEREST,
  [TradeRequestStep.DATA_REQUEST]: TradeRequestTabType.DATA_REQUESTS,
  [TradeRequestStep.QUOTE]: TradeRequestTabType.QUOTES,
};

type InboundTradeRequestDetailProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundTradeRequestDetail = ({ tradeRequest }: InboundTradeRequestDetailProps) => {
  const history = useHistory();

  const sellerCounterparty = getSellerCounterparty(tradeRequest.counterparties);

  if (!sellerCounterparty) {
    return null;
  }

  const actionTypes = sellerCounterparty.actions.map((action) => action.type);
  const canViewTradeData = actionTypes.includes(TradeCounterpartyActionType.ACCEPT_DATA_REQUEST);

  const disabledTabs = Object.entries(TRADE_STEP_TO_TAB_MAP).reduce<TradeRequestTabType[]>(
    (acc, [step, tab]) => {
      const { isActive, isComplete, isPending } = getActionProps(
        step as TradeRequestStep,
        tradeRequest
      );

      return !isPending && !isActive && !isComplete ? [...acc, tab] : acc;
    },
    []
  );

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/trade-requests/inbound/:tradeRequestId/:counterpartyId/data-view"
        render={() => {
          if (canViewTradeData) {
            return <TradeRequestData tradeRequest={tradeRequest} />;
          }

          history.goBack();
        }}
        requiredPermissions={{
          [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_TRADE_REQUEST],
        }}
      />
      <Messenger
        category={TradeRequestCategory.INBOUND}
        disabledTabs={disabledTabs}
        tradeRequest={tradeRequest}
      />
    </Switch>
  );
};
