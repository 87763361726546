import { formatDecimal } from '../../utils/formatUtils';
import { Analytic, AnalyticContainer, AnalyticHeader, AnalyticTitle } from './Analytic';
import { BreakdownTileContent } from './BreakdownTile';

type RiskMetricsProps = {
  wal?: number | null;
  walCC?: number | null;
  walFC?: number | null;
  cumulativeLoss?: number | null;
  expectedShortfall?: number | null;
};

export const RiskMetrics = (props: RiskMetricsProps) => {
  const { cumulativeLoss, expectedShortfall, wal, walCC, walFC } = props;

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Risk metrics</AnalyticTitle>
        </AnalyticHeader>

        <BreakdownTileContent label="WAL" value={formatDecimal(wal)} />
        <BreakdownTileContent label="WAL (CC)" value={formatDecimal(walCC)} />
        <BreakdownTileContent label="WAL (FC)" value={formatDecimal(walFC)} />
        <BreakdownTileContent label="Cum. loss" value={formatDecimal(cumulativeLoss)} />
        <BreakdownTileContent label="Exp. shortfall" value={formatDecimal(expectedShortfall)} />
      </AnalyticContainer>
    </Analytic>
  );
};
