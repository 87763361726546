import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { PageSubSection } from '../../components/Page';

export const LoadingContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
    font-size: 18px;
    border-radius: 6px;
    background-color: ${theme.colors.purpleMedium};

    div {
      margin-right: 16px;
      color: ${transparentize(0.4, theme.colors.white)};
    }

    span {
      display: inline-block;
      padding: 0 3px;
    }
  `}
`;

export const DocumentContent = styled.div`
  display: flex;
  height: 100%;
`;

export const DocumentPages = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 120px;
  min-width: 120px;
`;

export const DocumentPageSubSection = styled(PageSubSection)<{
  $isVisible: boolean;
}>`
  ${({ $isVisible, theme }) => css`
    background-color: ${theme.colors.purpleMedium};

    ${!$isVisible &&
    css`
      display: none;
    `}
  `}
`;

export const ScrollableContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const PageViews = styled.div<{ $maxHeight?: number }>`
  ${({ $maxHeight, theme }) => css`
    max-height: ${$maxHeight}px;
    overflow-y: auto;
    margin-right: 12px;
    border-right: 1px solid ${transparentize(0.7, theme.colors.white)};
    padding: 0 12px 12px 0;
  `}
`;
