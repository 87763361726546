import { useQuery, useQueryClient } from 'react-query';

import { getTradeRequestsSummary } from '../../trades/tradeRequestsUtils';
import { QueryKeys } from './types';

export const useGetTradeRequestsSummaryQuery = () => {
  const queryClient = useQueryClient();

  return useQuery([QueryKeys.TRADE_REQUESTS_SUMMARY], getTradeRequestsSummary, {
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData([QueryKeys.TRADE_REQUESTS_INBOUND_SUMMARY], data.inbound);

        queryClient.setQueryData([QueryKeys.TRADE_REQUESTS_OUTBOUND_SUMMARY], data.outbound);

        [...data.inbound, ...data.outbound].forEach((tradeRequest) => {
          queryClient.setQueryData(
            [QueryKeys.TRADE_REQUEST_SUMMARY, String(tradeRequest.id)],
            tradeRequest
          );
        });
      }

      return data;
    },
  });
};
