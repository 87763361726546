import {
  InboundTradeCounterpartyActionWithSummary,
  StipulationType,
  TradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, UserMessage } from '../../../../components/Messages';
import { UserProfile } from '../../../../users/types';
import { TradeMessage } from '../../../TradeMessage';
import { StipulationPreview } from '../../StipulationPreview';

type InboundExpressionOfInterestRequestedProps = {
  action: InboundTradeCounterpartyActionWithSummary;
  tradeRequest: TradeRequestWithSummary;
  userProfile: UserProfile;
  onStipulationToggle?: () => void;
};

export const InboundExpressionOfInterestRequested = ({
  action,
  onStipulationToggle,
  tradeRequest,
  userProfile,
}: InboundExpressionOfInterestRequestedProps) => {
  const { avatarUrl, firstName, lastName, organization } = userProfile;

  return (
    <UserMessage
      date={action.createdAt}
      organizationName={organization.tradingName}
      firstName={firstName}
      lastName={lastName}
      userAvatar={avatarUrl}
    >
      <MessageText>
        <TradeMessage
          id="Trade.Inbound.ExpressionOfInterest.Requested"
          values={{ poolName: tradeRequest.pool.name }}
        />
      </MessageText>
      {action?.stipulation?.stipulation && (
        <StipulationPreview
          stipulationFileOriginalName={action.stipulation.stipulation.fileOriginalName}
          stipulationId={action.stipulation.stipulation.id}
          stipulationType={StipulationType.STIPULATION_ACTION}
          onStipulationToggle={onStipulationToggle}
        />
      )}
    </UserMessage>
  );
};
