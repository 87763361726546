import * as Styles from './ProgressBar.styles';

// TODO: Update to instead take a prop of type {color: string, value: number}[]
type TopProgressBarProps = {
  color1?: string;
  color2?: string;
  color3?: string;
};

export const ProgressBar = ({ color1, color2, color3 }: TopProgressBarProps) => (
  <Styles.ProgressBar>
    <Styles.Bar $color={color1} />
    <Styles.Bar $color={color2} />
    <Styles.Bar $color={color3} />
  </Styles.ProgressBar>
);
