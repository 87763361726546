import { UserInvitationTokenStatus } from '@plus-platform/shared';
import { Location } from 'history';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';

import { validateInviteToken } from '../utils/authUtils';

export const INVALID_LINK_ERROR =
  'Your invitation link is invalid. Please contact your administrator for a new signup link.';
export const EXPIRED_LINK_ERROR =
  'Your invitation link has expired. Please contact your administrator for a new signup link.';
export const VALIDATION_PROBLEM_ERROR =
  'There was a problem validating your invitation link. Please try again later.';

export class TokenValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TokenValidationError';
  }
}

export const handleTokenValidation = async (token: string) => {
  const response = await validateInviteToken(token).catch(() => {
    throw new TokenValidationError(VALIDATION_PROBLEM_ERROR);
  });

  if (!response?.status) {
    throw new TokenValidationError(VALIDATION_PROBLEM_ERROR);
  }

  switch (response.status) {
    case UserInvitationTokenStatus.VALID:
      return { token };
    case UserInvitationTokenStatus.INVALID:
      throw new TokenValidationError(INVALID_LINK_ERROR);
    case UserInvitationTokenStatus.EXPIRED:
      throw new TokenValidationError(EXPIRED_LINK_ERROR);
    default:
      throw new TokenValidationError(VALIDATION_PROBLEM_ERROR);
  }
};

export const useInviteTokenValidation = (location: Location) => {
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<TokenValidationError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkTokenIsValid = async () => {
      const searchParams = new URLSearchParams(location.search);
      const tokenFromQuery = searchParams.get('token');
      const expiresFromQuery = searchParams.get('expires');

      if (isNil(tokenFromQuery) || isNil(expiresFromQuery)) {
        setError(new TokenValidationError(INVALID_LINK_ERROR));
        setIsLoading(false);
        return;
      }

      const expiresInSeconds = Number(expiresFromQuery);
      const nowInSeconds = Math.floor(Date.now() / 1000);

      if (expiresInSeconds < nowInSeconds) {
        setError(new TokenValidationError(EXPIRED_LINK_ERROR));
        setIsLoading(false);
        return;
      }

      try {
        const { token } = await handleTokenValidation(tokenFromQuery!);
        setToken(token);
      } catch (error) {
        if (error instanceof TokenValidationError) {
          setError(error);
        } else {
          setError(new TokenValidationError(VALIDATION_PROBLEM_ERROR));
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (location.search) {
      checkTokenIsValid();
    }
  }, [location.search]);

  return {
    token,
    error,
    isLoading,
    isError: Boolean(error),
  };
};
