import { ellipsis } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Badge } from '../components/Badge';
import { DefaultButton } from '../components/Button';
import { Heading } from '../components/Heading.styles';
import { WidgetContent } from '../components/Widget';

export const ListHeading = styled(Heading)`
  padding: 0 0 12px 0;
`;

export const LDWidgetContent = styled(WidgetContent)`
  padding: 12px 0 0 0;
`;

export const DocumentList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DocumentListItem = styled.li`
  padding: 0;
  margin: 0;
`;

export const CountBadge = styled.div<{ $color: string }>`
  ${({ $color }) => css`
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: ${$color};
    border-radius: 1rem;
    margin: 0 4px -1px 0;
  `}
`;

export const DocumentContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const DocumentHeading = styled(Heading)`
  ${ellipsis()};
  padding: 0;
  line-height: 1rem;
`;

export const DocumentText = styled.div(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyBold03Tight}
    color: ${theme.colors.white70};
    ${ellipsis()};
  `
);

export const DocumentButton = styled(DefaultButton)`
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
`;

export const DocumentDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${Badge},
  svg {
    flex-shrink: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.deprecatedPrimary02};
`;
