import { MISMO } from '../types';

const ADCO_OCCUPANCY_TYPES_MAPPING: Partial<
  Record<MISMO.PropertyUsageType, string>
> = {
  [MISMO.PropertyUsageType.Investment]: 'I',
  [MISMO.PropertyUsageType.PrimaryResidence]: 'O',
  [MISMO.PropertyUsageType.SecondHome]: 'S',
};

export const getADCOOccupancyType = (
  loanUsageType: MISMO.PropertyUsageType
) => {
  return ADCO_OCCUPANCY_TYPES_MAPPING[loanUsageType];
};
