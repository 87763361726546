import { PortfolioHomeAnalyticItem } from '@plus-platform/shared';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { ButtonLink } from '../../components/ButtonLink';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { Skeleton } from '../../components/Skeleton';
import { AnalyticDetailWidget } from './AnalyticDetailWidget';
import { AnalyticsMenu, AnalyticsMenuItem, AnalyticsWrapper } from './Analytics.styles';
import { getLabelForHomeAnalytic, mapHomeAnalyticItemToAnalyticItem } from './utils';

type HomeAnalyticDetailPageProps = {
  analytic?: PortfolioHomeAnalyticItem;
  isLoading: boolean;
};

export const HomeAnalyticDetailPage = (props: HomeAnalyticDetailPageProps) => {
  const { analytic, isLoading } = props;
  const analyticItem = mapHomeAnalyticItemToAnalyticItem(analytic);

  if (!analyticItem || !analytic) {
    return null;
  }

  const title = getLabelForHomeAnalytic(analytic);

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>{title}</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem title={title} />
            </Breadcrumb>
          </div>
        </PageHeaderContent>
      </PageHeader>
      <PageContent>
        <AnalyticsMenu>
          <ButtonLink to="">
            <AnalyticsMenuItem>All loans</AnalyticsMenuItem>
          </ButtonLink>
          <ButtonLink to="#">
            <AnalyticsMenuItem>Types of loans</AnalyticsMenuItem>
          </ButtonLink>
          <ButtonLink to="#">
            <AnalyticsMenuItem>Changes</AnalyticsMenuItem>
          </ButtonLink>
          <ButtonLink to="#">
            <AnalyticsMenuItem>Location</AnalyticsMenuItem>
          </ButtonLink>
        </AnalyticsMenu>

        <AnalyticsWrapper>
          {isLoading && <Skeleton height={307} />}
          {!isLoading && <AnalyticDetailWidget hasLink analytic={analyticItem} />}
        </AnalyticsWrapper>
      </PageContent>
    </Page>
  );
};
