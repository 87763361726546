import { TradeRequestWithSummary } from '@plus-platform/shared';
import compact from 'lodash/compact';

import {
  TradeRequestTransactionLogsResponse,
  useExportTradeRequestTransactionLogsMutation,
} from '../hooks/queries';
import { UserProfile } from '../users/types';
import { TradeTransactionLog } from './types';
import { getAggregatedLog } from './useTradeRequestTransactionLogs';

type TradeRequestTransactionLogsExport = {
  logs: TradeTransactionLog[];
  totalCount: number;
};

export const useTradeRequestTransactionLogsExport = () => {
  const mutation = useExportTradeRequestTransactionLogsMutation();

  const transformLogs = (
    data: TradeRequestTransactionLogsResponse | undefined,
    tradeRequest?: TradeRequestWithSummary,
    userProfile?: UserProfile
  ) => {
    if (!data) {
      return;
    }

    return {
      logs: compact(data.logs.map((log) => getAggregatedLog(log, tradeRequest, userProfile))),
      totalCount: data.totalCount,
    } as TradeRequestTransactionLogsExport;
  };

  return {
    exportLogs: (
      logIds: TradeRequestWithSummary['id'][],
      tradeRequest?: TradeRequestWithSummary,
      userProfile?: UserProfile
    ) => {
      if (!tradeRequest || !userProfile) {
        return;
      }

      return new Promise<TradeRequestTransactionLogsExport | undefined>((resolve, reject) =>
        mutation
          .mutateAsync({ logIds })
          .then((data) => {
            const transformed = transformLogs(data, tradeRequest, userProfile);
            resolve(transformed);
          })
          .catch((error) => {
            reject(error);
          })
      );
    },
  };
};
