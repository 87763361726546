import styled, { css } from 'styled-components/macro';

export const Tabs = styled.div`
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.white05};
  border-radius: 4px;
  padding: 2px;
`;

const activeTabStyles = css`
  &,
  &:hover {
    color: ${({ theme }) => theme.colors.primary01};
    background-color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.typography.headings.headingMedium04}
  }
`;

type TabProps = {
  $isActive?: boolean;
};

export const Tab = styled.button<TabProps>`
  display: inline-block;
  text-align: center;
  appearance: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: transparent;
  min-width: 72px;
  padding: 2px;
  border-radius: 2px;
  border: none;

  ${({ theme }) => theme.typography.headings.headingRegular04}

  &:hover {
    background-color: ${({ theme }) => theme.colors.white10};
  }

  &.active {
    ${activeTabStyles}
  }

  ${(props) => props.$isActive && activeTabStyles}
`;
