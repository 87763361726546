import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import * as Styles from './InputText.styles';
import { InputWrapperProps } from './InputText.styles';

export type InputTextProps = React.InputHTMLAttributes<HTMLInputElement> &
  InputWrapperProps & {
    label?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear?: () => void;
    actionButton?: React.ReactComponentElement<any>;
  };

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      $isInvalid,
      $width,
      actionButton,
      label,
      onChange,
      onClear,
      placeholder = '',
      type = 'text',
      value,
      ...rest
    },
    forwardedRef
  ) => {
    const [hasText, setHasText] = useState(value !== undefined ? Boolean(value) : false);
    const innerRef = useRef<HTMLInputElement | null>(null);

    const showSmallLabel = Boolean(placeholder) || hasText;

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasText(Boolean(event.target.value));
        onChange?.(event);
      },
      [onChange]
    );

    const handleClear = useCallback(() => {
      innerRef?.current && (innerRef.current.value = '');
      setHasText(false);
      onClear?.();
    }, [onClear]);

    useEffect(() => setHasText(Boolean(innerRef?.current?.value) || Boolean(value)), []);

    return (
      <Styles.InputWrapper
        $width={$width}
        $isInvalid={$isInvalid}
        $variant={actionButton ? 'withAction' : 'default'}
      >
        <Styles.Label $isSmall={showSmallLabel} $variant={actionButton ? 'withAction' : 'default'}>
          {label}
        </Styles.Label>
        <Styles.Input
          ref={(ref) => {
            if (ref) {
              innerRef.current = ref;
            }
            if (typeof forwardedRef === 'function') {
              forwardedRef(ref);
            } else if (forwardedRef !== null) {
              forwardedRef.current = ref;
            }
          }}
          type={type}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          {...rest}
        />
        {hasText && !actionButton && <Styles.CloseButton onClick={handleClear} />}
        {actionButton && (
          <Styles.ActionButtonWrapper>
            {React.cloneElement(actionButton, { disabled: !hasText || $isInvalid, $size: 'small' })}
          </Styles.ActionButtonWrapper>
        )}
      </Styles.InputWrapper>
    );
  }
);
