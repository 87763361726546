import range from 'lodash/range';
import React from 'react';
import { VictoryAxis } from 'victory-axis';
import { VictoryChart, VictoryChartProps } from 'victory-chart';
import { BlockProps } from 'victory-core';

import { defaultLoanTypesChartTheme } from '../helpers';

type LoanTypesByMetricsChartProps = Pick<VictoryChartProps, 'height' | 'domain'> & {
  labels: string[];
  children?: React.ReactNode;
  padding?: BlockProps;
};

export const LoanTypesByMetricsChart = (props: LoanTypesByMetricsChartProps) => {
  const { children, labels, padding = {}, ...chartProps } = props;

  const tickFormat = labels.flatMap((label) => ['', label]);

  return (
    <VictoryChart
      theme={defaultLoanTypesChartTheme}
      padding={{ left: 70, top: 12, right: 12, bottom: 20, ...padding }}
      domainPadding={{ x: 15 }}
      {...chartProps}
    >
      {children}
      <VictoryAxis />
      <VictoryAxis
        dependentAxis
        tickFormat={tickFormat}
        tickValues={range(tickFormat.length + 1)}
        invertAxis
      />
    </VictoryChart>
  );
};
