import { BorrowerDetails, BorrowerVerifications, CreditScores } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../utils/apiUtils';

const buildborrowerDetailsParams = (borrowerDetails: BorrowerDetails) => {
  const query = new URLSearchParams({
    id: String(borrowerDetails.id),
    name: borrowerDetails.name,
    ssn: borrowerDetails.ssn,
  });
  if (borrowerDetails.dateOfBirth) {
    query.append('dateOfBirth', borrowerDetails.dateOfBirth);
  }
  if (borrowerDetails.address) {
    query.append('address', borrowerDetails.address);
  }
  if (borrowerDetails.phoneNumber) {
    query.append('phoneNumber', borrowerDetails.phoneNumber);
  }

  return query;
};

export const getBorrowerCreditScores = (borrowerDetails: BorrowerDetails) => {
  const query = buildborrowerDetailsParams(borrowerDetails);

  return getRequestWithAuth<CreditScores[]>(makeApiUrl(`borrowerCreditScores?${query}`));
};

export const getBorrowerVerifications = (borrowerDetails: BorrowerDetails) => {
  const query = buildborrowerDetailsParams(borrowerDetails);

  return getRequestWithAuth<BorrowerVerifications>(makeApiUrl(`borrowerVerifications?${query}`));
};
