import {
  DocumentCategoryType,
  DocumentSubCategoryType,
  DocumentType,
  EncompassDocumentType,
  EscrowDisbursementReportDocumentType,
  Form1003DocumentType,
  FormLoanEstimateDocumentType,
  FormW8BENDocumentType,
  FormW9DocumentType,
  OptionalDocumentType,
  PoolSaleReportDocumentType,
  PropertyPreservationReportDocumentType,
  RequiredDocumentType,
} from '../types';

export const DOCUMENT_CATEGORY_MAPPING: Record<
  DocumentCategoryType,
  DocumentType[]
> = {
  [DocumentCategoryType.ORIGINATION_DOCUMENT]: [
    // TODO: Add Forms 181, and note here
    Form1003DocumentType.FANNIE_MAE_FORM_1003_LEGACY,
    RequiredDocumentType.Form1003,
    RequiredDocumentType.Form1004,
    RequiredDocumentType.Form1005,
    RequiredDocumentType.Form1099,
    OptionalDocumentType.Form1008,
    RequiredDocumentType.W2,
    FormW8BENDocumentType.IRS_FORM_W8BEN,
    FormW9DocumentType.IRS_FORM_W9,
    FormLoanEstimateDocumentType.CFPB_FORM_LOAN_ESTIMATE,
    EncompassDocumentType.ENCOMPASS_DOCUMENT_TYPE,
  ],
  [DocumentCategoryType.SERVICER_DOCUMENT]: [
    PropertyPreservationReportDocumentType.STANDARD_PROPERTY_PRESERVATION_REPORT,
    EscrowDisbursementReportDocumentType.STANDARD_ESCROW_DISBURSEMENT_REPORT,
  ],
  [DocumentCategoryType.TRANSACTION_DOCUMENT]: [
    PoolSaleReportDocumentType.STANDARD_POOL_SALE_REPORT,
  ],
};

export const DOCUMENT_SUBCATEGORY_MAPPING: {
  [DocumentCategoryType.ORIGINATION_DOCUMENT]: Record<
    DocumentSubCategoryType,
    DocumentType[]
  >;
} = {
  [DocumentCategoryType.ORIGINATION_DOCUMENT]: {
    // TODO: Add Forms 181 and note here
    [DocumentSubCategoryType.CREDIT_DOCUMENT]: [
      Form1003DocumentType.FANNIE_MAE_FORM_1003_LEGACY,
      RequiredDocumentType.Form1003,
      RequiredDocumentType.Form1004,
      RequiredDocumentType.Form1005,
      RequiredDocumentType.Form1099,
      RequiredDocumentType.W2,
      OptionalDocumentType.Form1008,
    ],
    [DocumentSubCategoryType.CLOSING_DOCUMENT]: [
      FormW8BENDocumentType.IRS_FORM_W8BEN,
      FormW9DocumentType.IRS_FORM_W9,
    ],
    [DocumentSubCategoryType.DISCLOSURE_DOCUMENT]: [
      FormLoanEstimateDocumentType.CFPB_FORM_LOAN_ESTIMATE,
    ],
  },
};
