import { PortfolioSummary } from '@plus-platform/shared';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { DelinquencyPanes } from '../components/DelinquencyPanes/DelinquencyPanes';
import { Skeleton } from '../components/Skeleton';
import * as Styles from './TradeRequestLoansSummary.styles';

type TradeRequestLoansSummaryProps = {
  style?: React.CSSProperties;
  summary?: PortfolioSummary;
};

export const TradeRequestLoansSummary = ({ style, summary }: TradeRequestLoansSummaryProps) => {
  const theme = useTheme();

  if (!summary) {
    return <Skeleton background={theme.colors.primary03} height={184} />;
  }
  return (
    <Styles.DetailedWrapper style={style}>
      <DelinquencyPanes summary={summary} variant="pool" />
    </Styles.DetailedWrapper>
  );
};
