import { LoanAmortizationType } from './amortization';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMODay,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
  MISMOURI,
} from './types';

enum GovernmentBondFinancingProgramType {
  BuilderBond = 'BuilderBond',
  ConsolidatedBond = 'ConsolidatedBond',
  FinalBond = 'FinalBond',
  Other = 'Other',
}

export enum PoolAccrualRateStructureType {
  StatedStructure = 'StatedStructure',
  WeightedAverageStructure = 'WeightedAverageStructure',
}

enum PoolClassType {
  FNM = 'FNM',
  FRE = 'FRE',
  GNMAI = 'GNMAI',
  GNMAII = 'GNMAII',
  Other = 'Other',
  Private = 'Private',
}

enum PoolingMethodType {
  ConcurrentDate = 'ConcurrentDate',
  InternalReserve = 'InternalReserve',
  Other = 'Other',
}

export enum PoolInterestRateRoundingType {
  Down = 'Down',
  Nearest = 'Nearest',
  NoRounding = 'NoRounding',
  Up = 'Up',
}

export enum PoolMortgageType {
  Conventional = 'Conventional',
  FHA = 'FHA',
  USDARuralHousing = 'USDARuralHousing',
  VA = 'VA',
}

enum PoolStructureType {
  InvestorDefinedMultipleLender = 'InvestorDefinedMultipleLender',
  LenderInitiatedMultipleLender = 'LenderInitiatedMultipleLender',
  MultipleIssuer = 'MultipleIssuer',
  Other = 'Other',
  SingleIssuer = 'SingleIssuer',
  SingleLender = 'SingleLender',
}

export type Pool = MISMOEntity &
  Partial<{
    amortizationType: LoanAmortizationType;
    amortizationTypeOtherDescription: MISMOString;
    CUSIPIdentifier: MISMOIdentifier;
    governmentBondFinanceIndicator: MISMOIndicator;
    governmentBondFinancingProgramType: GovernmentBondFinancingProgramType;
    governmentBondFinancingProgramTypeOtherDescription: MISMOString;
    poolAccrualRateStructureType: PoolAccrualRateStructureType;
    poolAssumabilityIndicator: MISMOIndicator;
    poolBalloonIndicator: MISMOIndicator;
    poolClassType: PoolClassType;
    poolClassTypeOtherDescription: MISMOString;
    poolConcurrentTransferIndicator: MISMOIndicator;
    poolCurrentLoanCount: MISMOCount;
    poolCurrentPrincipalBalanceAmount: MISMOAmount;
    poolFixedServicingFeePercent: MISMOPercent;
    poolGuarantyFeeRatePercent: MISMOPercent;
    poolIdentifier: MISMOIdentifier;
    poolingMethodType: PoolingMethodType;
    poolingMethodTypeOtherDescription: MISMOString;
    poolInterestAdjustmentEffectiveDate: MISMODate;
    poolInterestAdjustmentIndexLeadDaysCount: MISMOCount;
    poolInterestAndPaymentAdjustmentIndexLeadDaysCount: MISMOCount;
    poolInterestOnlyIndicator: MISMOIndicator;
    poolInterestRateRoundingPercent: MISMOPercent;
    poolInterestRateRoundingType: PoolInterestRateRoundingType;
    poolInterestRateTruncatedDigitsCount: MISMOCount;
    poolInvestorProductPlanIdentifier: MISMOIdentifier;
    poolIssueDate: MISMODate;
    poolIssuerIdentifier: MISMOIdentifier;
    poolMarginRatePercent: MISMOPercent;
    poolMaturityDate: MISMODate;
    poolMaximumAccrualRatePercent: MISMOPercent;
    poolMinimumAccrualRatePercent: MISMOPercent;
    poolMortgageRatePercent: MISMOPercent;
    poolMortgageType: PoolMortgageType;
    poolMortgageTypeOtherDescription: MISMOString;
    poolOriginalLoanCount: MISMOCount;
    poolOriginalPrincipalBalanceAmount: MISMOAmount;
    poolOwnershipPercent: MISMOPercent;
    poolPrefixIdentifier: MISMOIdentifier;
    poolPriorPeriodPrincipalBalanceAmount: MISMOAmount;
    poolScheduledPrincipalAndInterestPaymentAmount: MISMOAmount;
    poolScheduledPrincipalBalanceAmount: MISMOAmount;
    poolScheduledRemittancePaymentDay: MISMODay;
    poolSecurityInterestRatePercent: MISMOPercent;
    poolSecurityIssueDateInterestRatePercent: MISMOPercent;
    poolServiceFeeRatePercent: MISMOPercent;
    poolStructureType: PoolStructureType;
    poolStructureTypeOtherDescription: MISMOString;
    poolSuffixIdentifier: MISMOIdentifier;
    poolUnscheduledPrincipalAmount: MISMOAmount;
    poolUnscheduledPrincipalPaymentDay: MISMODay;
    securityTradeBookEntryDate: MISMODate;
    securityTradeCustomerAccountIdentifier: MISMOIdentifier;
    'securityTradeCustomerAccountIdentifier@IdentifierOwnerURI': MISMOURI;
    // poolCertificate: PoolCertificate;
  }>;
