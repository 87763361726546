import React from 'react';

import { NotificationsFillIcon, NotificationsLineIcon } from '../../components/icons';
import { Menu, MenuSection } from '../../components/Menu';
import {
  useMarkAllNotificationsAsReadMutation,
  useMarkAllNotificationsAsSeenMutation,
  useNotificationsCountQuery,
} from '../../hooks/queries';
import * as Styles from './NotificationsPanel.styles';
import { NotificationsPanelList } from './NotificationsPanelList';

export const NotificationsPanel = () => {
  const { data: notificationsCount } = useNotificationsCountQuery();
  const { mutateAsync: markAllNotificationsAsSeen } = useMarkAllNotificationsAsSeenMutation();
  const { mutateAsync: markAllNotificationsAsRead } = useMarkAllNotificationsAsReadMutation();

  const hasUnreadNotifications = notificationsCount && notificationsCount?.unreadCount > 0;
  const hasUnseenNotifications = notificationsCount && notificationsCount?.unseenCount > 0;

  const handleMarkAllAsRead = async () => {
    if (hasUnreadNotifications) {
      await markAllNotificationsAsRead({});
    }
  };

  const handleMenuOpen = async () => {
    if (hasUnseenNotifications) {
      await markAllNotificationsAsSeen();
    }
  };

  return (
    <Menu
      title="Notifications"
      toggleProps={{
        icon: (
          <Styles.Wrapper>
            {hasUnseenNotifications && (
              <Styles.Badge>{notificationsCount?.unseenCount}</Styles.Badge>
            )}
            <NotificationsLineIcon />
          </Styles.Wrapper>
        ),
        activeIcon: <NotificationsFillIcon />,
        size: 'large',
      }}
      onMenuOpen={handleMenuOpen}
      width="266px"
    >
      <MenuSection>
        <NotificationsPanelList
          onMarkAllAsRead={handleMarkAllAsRead}
          unseenNotificationsCount={notificationsCount?.unseenCount}
        />
      </MenuSection>
    </Menu>
  );
};
