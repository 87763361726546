import { IntegratedDisclosure } from './integratedDisclosure';
import {
  MISMOAmount,
  MISMODate,
  MISMODatetime,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOString,
  MISMOValue,
} from './types';

export enum PayoffActionType {
  'LenderOrderBothPayoffSubordinate' = 'LenderOrderBothPayoffSubordinate',
  'LenderOrderPayoff' = 'LenderOrderPayoff',
  'LenderOrderSubordinate' = 'LenderOrderSubordinate',
  'Other' = 'Other',
  'TitleCompanyOrderBothPayoffSubordinate' = 'TitleCompanyOrderBothPayoffSubordinate',
  'TitleCompanyOrderPayoff' = 'TitleCompanyOrderPayoff',
  'TitleCompanyOrderSubordinate' = 'TitleCompanyOrderSubordinate',
}

export enum PayoffApplicationSequenceType {
  JustPayoff = 'JustPayoff',
  NormalPaymentThenPayoff = 'NormalPaymentThenPayoff',
}

export enum PayoffRequestedByType {
  Lender = 'Lender',
  Other = 'Other',
  ServiceProvider = 'ServiceProvider',
}

export type Payoff = MISMOEntity &
  Partial<{
    integratedDisclosureSectionType: IntegratedDisclosure;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    liabilityAccountRemainsOpenIndicator: MISMOIndicator;
    payoffAccountNumberIdentifier: MISMOIdentifier;
    payoffActionType: PayoffActionType;
    payoffActionTypeOtherDescription: MISMOString;
    payoffAmount: MISMOAmount;
    payoffApplicationSequenceType: PayoffApplicationSequenceType;
    payoffOrderedDatetime: MISMODatetime;
    payoffPartialIndicator: MISMOIndicator;
    payoffPerDiemAmount: MISMOAmount;
    payoffPrepaymentPenaltyAmount: MISMOAmount;
    payoffRequestedByType: PayoffRequestedByType;
    payoffRequestedByTypeOtherDescription: MISMOString;
    payoffRequestedDate: MISMODate;
    payoffSpecifiedHUD1LineNumberValue: MISMOValue;
    payoffThroughDate: MISMODate;
  }>;
