import styled from 'styled-components/macro';

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 12px;
`;

export const Content = styled.div`
  flex: 1;
  padding: 16px 12px;
  overflow-y: auto;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
`;
