import { Annotation, Geography, Marker } from 'react-simple-maps';
import styled, { css } from 'styled-components/macro';

const ChloroplethText = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily.text};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  margin: 4px 0;
`;

const ChloroplethLabel = styled(ChloroplethText)(
  ({ theme }) => css`
    text-align: right;
    width: 100%;
    color: ${theme.colors.textPrimary01};
    margin: 0;
  `
);

const ChloroplethValue = styled(ChloroplethText)`
  font-weight: 500;
`;

const ActiveMapContentMixin = css`
  background: ${(props) => props.theme.colors.accent01};
  color: ${(props) => props.theme.colors.statusBlack};
  ${ChloroplethLabel}, ${ChloroplethValue} {
    color: ${(props) => props.theme.colors.statusBlack};
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
`;

export const MapAnnotation = styled(Annotation)``;

export const Map = styled(Geography)`
  cursor: pointer;
`;

export const MapMarker = styled(Marker)``;

export const MapContent = styled.div<{ $isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 0 8px;
  align-items: center;

  ${ChloroplethLabel} {
    color: ${(props) => props.theme.colors.white70};
  }

  ${(props) => props.$isActive && ActiveMapContentMixin}

  &:hover {
    ${ActiveMapContentMixin}
  }
`;

export const MapCircle = styled.circle`
  ${({ theme }) => css`
    fill: ${theme.colors.white80};
  `}
`;

export const MapText = styled.text`
  ${({ theme }) => css`
    fill: ${theme.colors.blue};
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 500;
  `}
`;
