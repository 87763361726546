import {
  TradeRequestMessageCreationMetadataQuote,
  TradeRequestQuote,
  TradeRequestWithSummary,
} from '@plus-platform/shared';

import { Button } from '../../../../components/Button';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type OutboundQuoteCounterAcceptedProps = {
  tradeRequest: TradeRequestWithSummary;
  quote: TradeRequestMessageCreationMetadataQuote;
  onProceed: (counterId: TradeRequestQuote['id']) => void;
};

export const OutboundQuoteCounterAccepted = ({
  onProceed,
  quote: { fromCounterparty, id },
}: OutboundQuoteCounterAcceptedProps) => {
  return (
    <PlusMessage>
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.Quote.Counter.Accepted"
          values={{
            userName: formatNameWithInitial(
              fromCounterparty.user.lastName,
              fromCounterparty.user.firstName
            ),
            organizationName: fromCounterparty.user.organization.tradingName,
          }}
        />{' '}
        Would you like to proceed?
      </MessageText>
      <MessageActions>
        <Button
          $size="large"
          onClick={() => {
            onProceed?.(id);
          }}
        >
          Proceed
        </Button>
      </MessageActions>
    </PlusMessage>
  );
};
