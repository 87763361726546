import React from 'react';

export const ThumbsUpLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1905 19.2381H7.80952V9.33333L13.1429 4L14.0952 4.95238C14.1841 5.04127 14.2571 5.1619 14.3143 5.31429C14.3714 5.46667 14.4 5.6127 14.4 5.75238V6.01905L13.5619 9.33333H18.4762C18.8825 9.33333 19.2381 9.48571 19.5429 9.79048C19.8476 10.0952 20 10.4508 20 10.8571V12.381C20 12.4698 19.9873 12.5651 19.9619 12.6667C19.9365 12.7683 19.9111 12.8635 19.8857 12.9524L17.6 18.3238C17.4857 18.5778 17.2952 18.7937 17.0286 18.9714C16.7619 19.1492 16.4825 19.2381 16.1905 19.2381ZM9.33333 17.7143H16.1905L18.4762 12.381V10.8571H11.619L12.6476 6.66667L9.33333 9.98095V17.7143ZM7.80952 9.33333V10.8571H5.52381V17.7143H7.80952V19.2381H4V9.33333H7.80952Z"
        fill="currentColor"
      />
    </svg>
  );
};
