import styled, { css } from 'styled-components/macro';

import { Stack } from '../Stack';

const MESSAGE_SIDE_MARGIN = 32;

export const Message = styled.div`
  padding: 12px;
  overflow-anchor: none;
`;

export const Header = styled.h3`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const UserName = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyMedium02}
    color: ${theme.colors.textPrimary01};
  `
);

export const Meta = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02}
    color: ${theme.colors.textPrimary03};
  `
);

export const MessageText = styled.p(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02Tight}
    color: ${theme.colors.textPrimary01};
    margin: 0 0 0 ${MESSAGE_SIDE_MARGIN}px;

    br {
      display: block;
      margin-bottom: 8px;
    }

    strong {
      ${theme.typography.bodyText.bodyMedium02Tight}
    }

    /* TODO: Validate if needed */
    & + & {
      margin-top: 8px;
    }
  `
);

export const MessageAction = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
`;

export const MessageActions = styled(Stack)`
  margin-top: 20px;
  margin-left: ${MESSAGE_SIDE_MARGIN}px;
`;
