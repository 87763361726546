import { Borrower, CreditScores } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanBorrowerCreditScoresQuery = {
  tradeRequestId: string;
  loanId: string;
  borrowerId: Borrower['id'];
};

const getTradeRequestDataLoanBorrowerCreditScores = (
  query: TradeRequestDataLoanBorrowerCreditScoresQuery
) => {
  const { borrowerId, loanId, tradeRequestId } = query;
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/data/loans/${loanId}/borrowers/${borrowerId}/credit-scores`
  );

  return getRequestWithAuth<CreditScores[]>(url);
};

export const useTradeRequestDataLoanBorrowerCreditScoresQuery = (
  query: TradeRequestDataLoanBorrowerCreditScoresQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_BORROWER_CREDIT_SCORES, query], () =>
    getTradeRequestDataLoanBorrowerCreditScores(query)
  );
