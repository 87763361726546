import {
  AggregatedDocument,
  DOCUMENT_CATEGORY_MAPPING,
  DOCUMENT_SUBCATEGORY_MAPPING,
  DocumentCategoryType,
  DocumentSubCategoryType,
  DocumentType,
} from '@plus-platform/shared';

const DOCUMENT_CATEGORY_MAPPING_BY_DOCUMENT_TYPE: Partial<
  Record<DocumentType, DocumentCategoryType>
> = Object.entries(DOCUMENT_CATEGORY_MAPPING).reduce(
  (accCategory, [currentCategory, documentTypes]) => {
    return documentTypes.reduce(
      (accDocumentType, currentType) => ({
        ...accDocumentType,
        [currentType]: currentCategory,
      }),
      accCategory
    );
  },
  {}
);

const DOCUMENT_SUBCATEGORY_MAPPING_BY_DOCUMENT_TYPE: Partial<
  Record<DocumentType, DocumentSubCategoryType>
> = Object.entries(DOCUMENT_SUBCATEGORY_MAPPING).reduce((accCategory, [_, subCategoryMap]) => {
  return Object.entries(subCategoryMap).reduce(
    (accSubCategory, [currentSubCategory, documentTypes]) => {
      return documentTypes.reduce(
        (accDocumentType, currentType) => ({
          ...accDocumentType,
          [currentType]: currentSubCategory,
        }),
        accSubCategory
      );
    },
    accCategory
  );
}, {});

export const getDocumentCategoryByType = (documentType: DocumentType) => {
  return DOCUMENT_CATEGORY_MAPPING_BY_DOCUMENT_TYPE[documentType];
};

export const getDocumentSubCategoryByType = (documentType: DocumentType) => {
  return DOCUMENT_SUBCATEGORY_MAPPING_BY_DOCUMENT_TYPE[documentType];
};

export const getDocumentsByCategory = (
  documents: AggregatedDocument[],
  documentCategory: DocumentCategoryType
) =>
  documents.filter(
    (document) => getDocumentCategoryByType(document.documentType) === documentCategory
  );

const getDocumentsBySubCategory = (
  documents: AggregatedDocument[],
  documentSubCategory: DocumentSubCategoryType
) =>
  documents.filter(
    (document) => getDocumentSubCategoryByType(document.documentType) === documentSubCategory
  );

export const getClosingDocuments = (documents: AggregatedDocument[]) =>
  getDocumentsBySubCategory(documents, DocumentSubCategoryType.CLOSING_DOCUMENT);

export const getCreditDocuments = (documents: AggregatedDocument[]) =>
  getDocumentsBySubCategory(documents, DocumentSubCategoryType.CREDIT_DOCUMENT);

export const getDisclosureDocuments = (documents: AggregatedDocument[]) =>
  getDocumentsBySubCategory(documents, DocumentSubCategoryType.DISCLOSURE_DOCUMENT);
