import React from 'react';

export const OutboundIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.29231 18L6 16.7077L14.8615 7.84615H6.92308V6H18V17.0769H16.1538V9.13846L7.29231 18Z"
        fill="currentColor"
      />
    </svg>
  );
};
