import {
  AggregatedLoanBorrower,
  BorrowerVerification,
  BorrowerVerifications,
  Loan,
  LoanAdditionalPayments,
  LoanBorrower,
} from '@plus-platform/shared';
import React, { useMemo } from 'react';

import { Card } from '../../../components/Card';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Key, Pair, TitleKey, Value } from '../../../components/KeyValue';
import { Loader } from '../../../components/Loader';
import { Table, Tbody, Thead } from '../../../components/Table';
import { ButtonToggle } from '../../../components/Toggles/ButtonToggle';
import { ToggleGroup } from '../../../components/Toggles/ToggleGroup';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import {
  EMPTY_VALUE,
  formatBoolean,
  formatDefaultValue,
  formatMonetary,
  formatPercentage,
} from '../../../utils/formatUtils';
import { useLoanContext } from '../../context';
import {
  BorrowerCreditScoresData,
  BorrowerVerificationsData,
  useBorrowerVerifications,
} from '../../LoanData';
import { LoanDetailAdditionalSection } from '../LoanDetail.styles';
import * as Styles from './LoanDetailBorrowers.styles';

const CreditScoresChart = React.lazy(() => import('../../CreditScoresChart'));

enum BorrowerDataType {
  ORIGINATION = 'ORIGINATION',
  EQUIFAX = 'EQUIFAX',
  EXPERIAN = 'EXPERIAN',
}

type PartialLoanBorrower = Partial<LoanBorrower> & {
  additionalPayments: Partial<LoanAdditionalPayments>;
};

const getBorrowerVerifiedDataByProvider = (provider?: BorrowerVerification) => {
  const identity = provider?.identity;
  const employment = provider?.employment;

  const totalMonthlyIncome = employment?.totalAnnualIncomeAmountInCents
    ? employment.totalAnnualIncomeAmountInCents / 12
    : undefined;

  return {
    name: identity?.name,
    ssn: identity?.ssn,
    phoneNumber: identity?.phoneNumber,
    dateOfBirth: identity?.dateOfBirth,
    presentAddressFullAddress: identity?.fullAddress,
    // TODO: check self-employed status via verification provider
    isSelfEmployed: undefined,
    employerName: employment?.employerName,
    positionTitle: employment?.position,
    monthlyIncomeTotal: totalMonthlyIncome,
    additionalPayments: {},
  };
};

const getBorrowerVerifiedDataByType = (
  borrowerDataType: BorrowerDataType,
  loanBorrower: AggregatedLoanBorrower,
  additionalPayments?: LoanAdditionalPayments,
  verifications?: BorrowerVerifications
): PartialLoanBorrower => {
  if (borrowerDataType === BorrowerDataType.ORIGINATION) {
    return {
      ...loanBorrower.borrower,
      additionalPayments: {
        ...additionalPayments,
      },
    };
  }

  if (borrowerDataType === BorrowerDataType.EQUIFAX) {
    return getBorrowerVerifiedDataByProvider(verifications?.equifax);
  }

  return getBorrowerVerifiedDataByProvider(verifications?.experian);
};

const convertMonthlyIncomeToAnnual = (income?: number) => {
  if (!income) {
    return undefined;
  }

  return income * 12;
};

type LoanBorrowerWidgetProps = {
  loanBorrower: AggregatedLoanBorrower;
  borrowerIndex: number;
  borrowerCount: number;
  additionalPayments?: LoanAdditionalPayments;
};

const LoanBorrowerWidget = ({
  additionalPayments,
  borrowerCount,
  borrowerIndex,
  loanBorrower,
}: LoanBorrowerWidgetProps) => {
  const [borrowerDataType, setBorrowerDataType] = React.useState(BorrowerDataType.ORIGINATION);

  const { isAnonymised } = useLoanContext();
  const { data: verifications } = useBorrowerVerifications();

  const verifiedBorrowerData = getBorrowerVerifiedDataByType(
    borrowerDataType,
    loanBorrower,
    additionalPayments,
    verifications
  );

  return (
    <Card $variant="light" $hasPadding>
      <Styles.ControlsWrapper>
        <Styles.BorrowerHeadingGroup>
          <Styles.BorrowerCount>
            Borrower {borrowerIndex} of {borrowerCount}
          </Styles.BorrowerCount>
          <Styles.BorrowerHeading $size="medium">
            {formatDefaultValue(verifiedBorrowerData?.name)}
          </Styles.BorrowerHeading>
        </Styles.BorrowerHeadingGroup>
        <ToggleGroup
          size="small"
          value={borrowerDataType}
          onChange={(e, v) => setBorrowerDataType(v as BorrowerDataType)}
        >
          <ButtonToggle value={BorrowerDataType.ORIGINATION as string}>
            Origination Data
          </ButtonToggle>
          <ButtonToggle value={BorrowerDataType.EQUIFAX as string}>
            Verified by Equifax
          </ButtonToggle>
          <ButtonToggle value={BorrowerDataType.EXPERIAN as string}>
            Verified by Experian
          </ButtonToggle>
        </ToggleGroup>
      </Styles.ControlsWrapper>

      <WidgetSectionWrapper>
        <WidgetSection>
          <LoanDetailAdditionalSection>
            <TitleKey>Personal details</TitleKey>
            <Pair $size="large">
              <Key>Name</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.name)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Representative Credit Score</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.representativeCreditScore)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>SSN</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.ssn)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>DOB</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.dateOfBirth)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Marital Status</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.maritalStatus)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Phone Number</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.phoneNumber)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Email</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.email)}</Value>
            </Pair>
            <Pair $size="large" $isMultiLine>
              <Key>Former Address</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.formerAddressFullAddress)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Present Address</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.presentAddressFullAddress)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Years Of School</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.yearsOfSchool)}</Value>
            </Pair>
          </LoanDetailAdditionalSection>

          <LoanDetailAdditionalSection>
            <TitleKey>Employment Status</TitleKey>
            <Pair $size="large">
              <Key>Is Self-employed</Key>
              <Value>{formatBoolean(verifiedBorrowerData?.isSelfEmployed)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Name of Employer</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.employerName)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Employer Address</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData?.employerAddressFullAddress)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Years on the job</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData.yearsOnTheJob)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Years in Field of Business</Key>
              <Value>
                {formatDefaultValue(verifiedBorrowerData.yearsEmployedInThisProfession)}
              </Value>
            </Pair>
            <Pair $size="large">
              <Key>Current Title</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData.positionTitle)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>Business Phone</Key>
              <Value>{formatDefaultValue(verifiedBorrowerData.employmentPhone)}</Value>
            </Pair>
            <Pair $size="large">
              <Key>More Than One Employer</Key>
              <Value>{formatBoolean(verifiedBorrowerData.hasMoreThanOneEmployer)}</Value>
            </Pair>
          </LoanDetailAdditionalSection>
        </WidgetSection>

        <Divider $flexItem $orientation="vertical" />

        <WidgetSection>
          {!isAnonymised && (
            <React.Fragment>
              <LoanDetailAdditionalSection>
                <TitleKey>Reported annual income</TitleKey>
                <Pair $size="large">
                  <Key>Base Salary</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(verifiedBorrowerData?.monthlyIncomeBaseSalary)
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Overtime</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(verifiedBorrowerData?.monthlyIncomeOvertime)
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Bonuses</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(verifiedBorrowerData?.monthlyIncomeBonuses)
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Commissions</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(verifiedBorrowerData?.monthlyIncomeCommissions)
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Dividends / Interest</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(
                        verifiedBorrowerData?.monthlyIncomeDividendsAndInterest
                      )
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Net Rental Income</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(
                        verifiedBorrowerData?.monthlyIncomeNetRentalIncome
                      )
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Total</Key>
                  <Value>
                    {formatMonetary(
                      convertMonthlyIncomeToAnnual(verifiedBorrowerData?.monthlyIncomeTotal)
                    )}
                  </Value>
                </Pair>
              </LoanDetailAdditionalSection>
              <LoanDetailAdditionalSection>
                <TitleKey>Housing-related obligations</TitleKey>
                <Pair $size="large">
                  <Key>Monthly Mortgage Payment</Key>
                  <Value>
                    {formatMonetary(
                      verifiedBorrowerData?.additionalPayments?.firstMortgageMonthlyAmount
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Monthly Property Taxes</Key>
                  <Value>
                    {formatMonetary(verifiedBorrowerData?.additionalPayments?.taxMonthlyAmount)}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Monthly Insurance Premiums</Key>
                  <Value>
                    {formatMonetary(
                      verifiedBorrowerData?.additionalPayments?.mortgageInsuranceMonthlyAmount
                    )}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Monthly HOA Fee</Key>
                  <Value>
                    {formatMonetary(verifiedBorrowerData?.additionalPayments?.hoaMonthlyAmount)}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Total Monthly Housing Cost</Key>
                  <Value>
                    {formatMonetary(
                      verifiedBorrowerData?.additionalPayments?.totalHousingCostMonthlyAmount
                    )}
                  </Value>
                </Pair>
              </LoanDetailAdditionalSection>
              <LoanDetailAdditionalSection>
                <TitleKey>Debt-to-income ratios</TitleKey>
                <Pair $size="large">
                  <Key>Front End DTI</Key>
                  <Value>
                    {verifiedBorrowerData?.dti
                      ? formatPercentage(verifiedBorrowerData?.dti)
                      : EMPTY_VALUE}
                  </Value>
                </Pair>
                <Pair $size="large">
                  <Key>Back End DTI</Key>
                  <Value>
                    {verifiedBorrowerData?.backendDti
                      ? formatPercentage(verifiedBorrowerData?.backendDti)
                      : EMPTY_VALUE}
                  </Value>
                </Pair>
              </LoanDetailAdditionalSection>
            </React.Fragment>
          )}
        </WidgetSection>

        <Divider $flexItem $orientation="vertical" />

        <WidgetSection $isLarge>
          <Styles.TableChartWrapper>
            {!isAnonymised && (
              <LoanDetailAdditionalSection>
                <Table style={{ padding: 0 }}>
                  <Thead>
                    <Styles.TableRow>
                      <Styles.BHeaderCell>2022 income</Styles.BHeaderCell>
                      <Styles.BHeaderCell>W-2</Styles.BHeaderCell>
                      <Styles.BHeaderCell>1099</Styles.BHeaderCell>
                      <Styles.BHeaderCell>Effective Tax Rate</Styles.BHeaderCell>
                    </Styles.TableRow>
                  </Thead>
                  <Tbody>
                    <Styles.TableRow>
                      <Styles.Cell>Total Reported Annual Income</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2TotalReportedAnnualIncome)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099TotalReportedAnnualIncome)}
                      </Styles.Cell>
                      <Styles.Cell></Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Federal</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2FederalTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099FederalTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatPercentage(verifiedBorrowerData?.federalEffectiveTaxRate)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Social Security</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2SocialSecurityTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099SocialSecurityTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatPercentage(verifiedBorrowerData?.socialSecurityEffectiveTaxRate)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Medicare</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2MedicareTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099MedicareTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatPercentage(verifiedBorrowerData?.medicareEffectiveTaxRate)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>State</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2StateTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099StateTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatPercentage(verifiedBorrowerData?.stateEffectiveTaxRate)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Local</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2LocalTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099LocalTaxAmount)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatPercentage(verifiedBorrowerData?.localEffectiveTaxRate)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Tax Paid</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.formW2TotalTaxPaid)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.form1099TotalTaxPaid)}
                      </Styles.Cell>
                      <Styles.Cell>
                        {formatPercentage(verifiedBorrowerData?.taxPaidEffectiveTaxRate)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Annual Income After Tax</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.totalAnnualIncomeAfterTax)}
                      </Styles.Cell>
                    </Styles.TableRow>
                    <Styles.TableRow>
                      <Styles.Cell>Monthly Income After Tax</Styles.Cell>
                      <Styles.Cell>
                        {formatMonetary(verifiedBorrowerData?.totalMonthlyIncomeAfterTax)}
                      </Styles.Cell>
                    </Styles.TableRow>
                  </Tbody>
                </Table>
              </LoanDetailAdditionalSection>
            )}
            <LoanDetailAdditionalSection>
              <Styles.ChartHeading>Detailed FICO Scores</Styles.ChartHeading>

              <Styles.CreditScoresChartWrapper>
                <React.Suspense fallback={<Loader />}>
                  <BorrowerCreditScoresData borrower={loanBorrower.borrower}>
                    <CreditScoresChart />
                  </BorrowerCreditScoresData>
                </React.Suspense>
              </Styles.CreditScoresChartWrapper>
            </LoanDetailAdditionalSection>
          </Styles.TableChartWrapper>
        </WidgetSection>
      </WidgetSectionWrapper>
    </Card>
  );
};

type BorrowerWidgetProps = {
  loan: Loan;
};

export const LoanDetailBorrowers = React.forwardRef<HTMLDivElement, BorrowerWidgetProps>(
  ({ loan }, ref) => {
    const [value, setValue] = React.useState('borrowers');

    const additionalPayments = useMemo(
      () => ({
        hoaMonthlyAmount: loan.loanDetails.hoaMonthlyAmount,
        hazardInsuranceMonthlyAmount: loan.loanDetails.hazardInsuranceMonthlyAmount,
        taxMonthlyAmount: loan.loanDetails.taxMonthlyAmount,
        mortgageInsuranceMonthlyAmount: loan.loanDetails.mortgageInsuranceMonthlyAmount,
        firstMortgageMonthlyAmount: loan.loanDetails.firstMortgageMonthlyAmount,
        otherFinancingMonthlyAmount: loan.loanDetails.otherFinancingMonthlyAmount,
        otherExpensesMonthlyAmount: loan.loanDetails.otherExpensesMonthlyAmount,
        totalHousingCostMonthlyAmount: loan.loanDetails.totalHousingCostMonthlyAmount,
      }),
      [loan]
    );

    function handleTabChange(
      event: React.MouseEvent<HTMLElement>,
      value: string | string[] | null
    ) {
      setValue(value as string);
    }

    return (
      <Card $hasPadding id="borrower" ref={ref} data-testid="LoanDetail_Borrowers">
        <Heading $size="medium">Borrower</Heading>
        <Styles.BorrowersWrapper>
          <ToggleGroup size="large" value={value} variant="spaced" onChange={handleTabChange}>
            <ButtonToggle value="borrowers">Borrowers</ButtonToggle>
            <ButtonToggle value="obligors">Obligors & Non-Obligors</ButtonToggle>
          </ToggleGroup>

          {loan.loanBorrowers.map((loanBorrower, index) => {
            return (
              <BorrowerVerificationsData
                borrower={loanBorrower.borrower}
                key={loanBorrower.borrower.id}
              >
                <LoanBorrowerWidget
                  additionalPayments={additionalPayments}
                  borrowerIndex={index + 1}
                  borrowerCount={loan.loanBorrowers.length}
                  loanBorrower={loanBorrower}
                />
              </BorrowerVerificationsData>
            );
          })}
        </Styles.BorrowersWrapper>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailBorrowers;
