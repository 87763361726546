import { Message, MessageType } from '@plus-platform/shared';
import React from 'react';
import { useQueryClient } from 'react-query';

import { useSocketContext } from '../contexts/SocketContext';
import { QueryKeys, useGetTradeRequestsSummaryQuery } from '../hooks/queries';

export const useTradeRequestsSummary = () => {
  const query = useGetTradeRequestsSummaryQuery();
  const { subscribe, unsubscribe } = useSocketContext();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const handleMessage = (message: Message) => {
      if (message.type !== MessageType.TradeRequestActionMessage) {
        return;
      }

      queryClient.invalidateQueries([QueryKeys.TRADE_REQUESTS_SUMMARY]);
    };

    subscribe(handleMessage);

    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, queryClient]);

  return query;
};
