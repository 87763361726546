import { Alias } from './alias';
import { Contact } from './contact';
import {
  MISMOCount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOString,
  MISMOURI,
  MISMOURL,
} from './types';

export enum LegalEntityType {
  Corporation = 'Corporation',
  CorporationSole = 'CorporationSole',
  Estate = 'Estate',
  GovernmentEntity = 'GovernmentEntity',
  JointVenture = 'JointVenture',
  LimitedLiabilityCompany = 'LimitedLiabilityCompany',
  LimitedPartnership = 'LimitedPartnership',
  NonProfitCorporation = 'NonProfitCorporation',
  Partnership = 'Partnership',
  Other = 'Other',
  SoleProprietorship = 'SoleProprietorship',
  Trust = 'Trust',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum LegalEntityTypeOtherDescription {
  LandTrustAndBeneficiaryIsIndividual = 'LandTrustAndBeneficiaryIsIndividual',
  LivingTrust = 'LivingTrust',
}

export type LegalEntity = MISMOEntity &
  Partial<{
    entityHomePageURL: MISMOURL;
    fullName: MISMOString;
    globalLegalEntityIdentifier: MISMOIdentifier;
    legalEntityLicensingTypeDescription: MISMOString;
    legalEntityOrganizedUnderTheLawsOfJurisdictionName: MISMOString;
    legalEntitySuccessorClauseTextDescription: MISMOString;
    legalEntityType: LegalEntityType;
    legalEntityTypeOtherDescription: MISMOString;
    MERSOrganizationIdentifier: MISMOIdentifier;
    registeredDomainNameURI: MISMOURI;
    requiredSignatoryCount: MISMOCount;
    aliases: Alias[];
    contacts: Contact[];
  }>;
