import { Coordinates } from '@plus-platform/shared';
import {
  BicyclingLayer,
  GroundOverlay,
  KmlLayer,
  TrafficLayer,
  TransitLayer,
} from '@react-google-maps/api';
import React, { useState } from 'react';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { getFEMADataURL, getWeatherDataURL, NHC_ACTIVE_KML_URL } from '../../utils/mapUtils';
import { MapLayerEnum } from '../types';
import { AirQualityLayer } from './AirQualityLayer';
import { EarthquakesLayer } from './EarthquakesLayer';
import { EvChargingStationsLayer } from './EvChargingStationsLayer';
import { useAQILayer } from './useAQILayer';

type AllLayersProps = {
  googleMap?: google.maps.Map;
  mapBounds: google.maps.LatLngBounds;
  mapHeight?: number;
  displayedMapLayers: MapLayerEnum[];
  targetCoordinates?: Coordinates;
};

export const AllLayers = ({
  displayedMapLayers,
  googleMap,
  mapBounds,
  mapHeight,
  targetCoordinates,
}: AllLayersProps) => {
  const isMapLayerDisplayed = React.useCallback(
    (layer: MapLayerEnum) => {
      return displayedMapLayers.includes(layer);
    },
    [displayedMapLayers]
  );
  const [mapLayerLoading, setMapLayerLoading] = useState<boolean>(false);

  const FEMALayerUrl = isMapLayerDisplayed(MapLayerEnum.NFHL)
    ? getFEMADataURL(googleMap, mapBounds)
    : '';
  const weatherLayerUrl = isMapLayerDisplayed(MapLayerEnum.WEATHER)
    ? getWeatherDataURL(googleMap, mapBounds)
    : '';

  useAQILayer(isMapLayerDisplayed(MapLayerEnum.AIR_QUALITY), googleMap);

  return (
    <ActivityIndicator contain isActive={mapLayerLoading} showChildren>
      {isMapLayerDisplayed(MapLayerEnum.TRANSIT) && <TransitLayer />}
      {isMapLayerDisplayed(MapLayerEnum.BICYCLING) && <BicyclingLayer />}
      {isMapLayerDisplayed(MapLayerEnum.TRAFFIC) && <TrafficLayer />}
      {isMapLayerDisplayed(MapLayerEnum.STORMS) && (
        <KmlLayer
          // a hacky way to re-create the layer when the map height changes, this
          // forces the KML layer to re-position overlays that are positioned relatively
          // to the map container
          key={mapHeight}
          options={{ preserveViewport: true }}
          url={NHC_ACTIVE_KML_URL}
        />
      )}

      {isMapLayerDisplayed(MapLayerEnum.EV_CHARGING_STATIONS) && (
        <EvChargingStationsLayer bounds={mapBounds} />
      )}

      {isMapLayerDisplayed(MapLayerEnum.AIR_QUALITY) &&
        targetCoordinates?.latitude &&
        targetCoordinates?.longitude && (
          <AirQualityLayer
            latitude={targetCoordinates.latitude}
            longitude={targetCoordinates.longitude}
          />
        )}

      {FEMALayerUrl && (
        <GroundOverlay
          /**
           * For some reason, the GroundOverlay component wants a LatLngBoundsLiteral and not LatLngBounds
           */
          bounds={mapBounds.toJSON()}
          // NOTE: this is mandatory due to the way the GroundOverlay component was built.
          // we can wonder who designs a library component that cannot handle prop changes properly, but that's another story...
          key={FEMALayerUrl}
          url={FEMALayerUrl}
        />
      )}

      {weatherLayerUrl && (
        <GroundOverlay
          /**
           * For some reason, the GroundOverlay component wants a LatLngBoundsLiteral and not LatLngBounds
           */
          bounds={mapBounds.toJSON()}
          key={weatherLayerUrl}
          url={weatherLayerUrl}
        />
      )}

      {isMapLayerDisplayed(MapLayerEnum.EARTHQUAKES) && (
        <EarthquakesLayer mapBounds={mapBounds} onLoadingStateChanged={setMapLayerLoading} />
      )}
    </ActivityIndicator>
  );
};
