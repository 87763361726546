import styled, { css } from 'styled-components';

const largeStyles = css`
  width: 24px;
  height: 24px;
  border-width: 1px;

  &:checked {
    border-width: 2px;

    &::after {
      width: 14px;
      height: 14px;
    }
  }
`;

const smallStyles = css`
  width: 16px;
  height: 16px;
  border-width: 1px;

  &:checked {
    border-width: 2px;

    &::after {
      width: 8px;
      height: 8px;
    }
  }
`;

export type RadioStyleProps = {
  $size: 'large' | 'small';
};

export const Radio = styled.input.attrs({ type: 'radio' })<RadioStyleProps>(
  ({ $size = 'large', theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    outline: 0;
    cursor: pointer;
    border-radius: 12px;
    border-style: solid;
    border-color: ${theme.colors.primary07};

    &:checked {
      border-color: ${theme.colors.accent01};

      &::after {
        content: '';
        background-color: ${theme.colors.accent01};
        border-radius: 50%;
      }
    }

    &:disabled {
      opacity: 0.2;
    }

    ${$size === 'large' && largeStyles}
    ${$size === 'small' && smallStyles}
  `
);
