import { LoanEvent } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

/**
 * Query hook for fetching loan event log
 */
export const useLoanEventLogQuery = (loanNumber: string) =>
  useQuery([QueryKeys.LOAN_EVENT_LOG, loanNumber], async () => {
    const url = makeApiUrl(`loan/${loanNumber}/event-log`);
    return await getRequestWithAuth<LoanEvent[]>(url);
  });
