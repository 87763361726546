import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum WallMaterialType {
  Aluminum = 'Aluminum',
  Block = 'Block',
  Brick = 'Brick',
  CementBoard = 'CementBoard',
  Concrete = 'Concrete',
  Flagstone = 'Flagstone',
  Frame = 'Frame',
  Glass = 'Glass',
  Other = 'Other',
  Steel = 'Steel',
  Stone = 'Stone',
  Stucco = 'Stucco',
  Synthetic = 'Synthetic',
  Veneer = 'Veneer',
  Vinyl = 'Vinyl',
  Wood = 'Wood',
}

export type ExteriorWall = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
    wallMaterialType: WallMaterialType;
    wallMaterialTypeOtherDescription: MISMOString;
  }>;
