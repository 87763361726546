import styled, { css } from 'styled-components/macro';

import { NudeButton } from '../components/Button';
import { Heading } from '../components/Heading.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled(Heading)`
  margin: 20px 0;
`;

export const Subtitle = styled(Heading).attrs({ as: 'h2', $size: 'medium' })``;

export const Header = styled.header(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.fillAdminMenuItemText};
  `
);

const menuProps = css`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
  padding: 0;
  list-style: none;
`;

export const GeneralMenu = styled.ul`
  ${menuProps};
  position: relative;
  gap: 8px;
`;

export const SpecificMenu = styled.ul`
  ${menuProps};
  position: relative;
`;

export const ActionText = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${theme.colors.fillAdminMenuItemText};
  `
);

export const MenuItem = styled.li``;

export const SpecificMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    position: relative;

    & + & {
      &:after {
        position: absolute;
        content: '';
        left: 12px;
        right: 12px;
        top: -1px;
        height: 1px;
        background-color: ${theme.colors.borderAdminMenuItem};
      }
    }
  `
);

export const MenuItemAction = styled(NudeButton)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: ${theme.colors.backgroundAdminMenuItem};
    border-radius: 4px;

    &:hover {
      background-color: ${theme.colors.backgroundAdminMenuItemHover};
    }
  `
);

export const ActionColour = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Status = styled.div<{ $color: string }>(
  ({ $color, theme }) => css`
    width: 80px;
    padding: 4px 16px;
    border-radius: 4px;
    background-color: ${$color};
    color: ${theme.colors.white};
    text-align: center;
  `
);
