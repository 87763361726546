import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

type WrapperProps = {
  $hasSrc: boolean;
  $size: number;
  $variant?: 'solid' | 'outline';
};

type variantStylesProps = ThemeProps<DefaultTheme> & WrapperProps;

const solidVariantStyles = ({ $hasSrc, theme }: variantStylesProps) => css`
  background: ${$hasSrc ? 'transparent' : theme.colors.primary10};
  color: ${theme.colors.primary01};
`;

const outlineVariantStyles = ({ $hasSrc, theme }: variantStylesProps) => css`
  background: ${theme.colors.primary01};
  box-shadow: inset 0 0 0 1px ${$hasSrc ? theme.colors.primary01 : theme.colors.primary06};
  color: ${theme.colors.textPrimary01};
`;

export const Wrapper = styled.div<WrapperProps>(
  ({ $size, $variant = 'solid' }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    width: ${$size}px;
    height: ${$size}px;
    border-radius: 50%;
    padding: 0;
    overflow: hidden;

    ${$variant === 'solid' && solidVariantStyles}
    ${$variant === 'outline' && outlineVariantStyles}
  `
);

type AvatarProps = {
  $size: number;
};

export const Avatar = styled.img<AvatarProps>(
  ({ $size }) => css`
    width: ${$size}px;
    height: ${$size}px;
  `
);

type InitialsProps = {
  $size: number;
};

export const Initials = styled.span<InitialsProps>(({ $size, theme }) => {
  if ($size < 32) {
    return theme.typography.bodyText.bodyMedium03Tight;
  }

  return theme.typography.bodyText.bodyRegular02;
});
