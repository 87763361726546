import { Menu } from '@headlessui/react';
import { OrganizationMember } from '@plus-platform/shared';

import { BurgerIcon } from '../../components/icons/BurgerIcon';
import { Cell } from '../../components/Table';
import { formatDate, formatDefaultValue } from '../../utils/formatUtils';
import {
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuWrapper,
  MemberCell,
  MemberRow,
  ORGANIZATION_MEMBERS_TABLE_COLUMN_COUNT,
} from './OrganizationMembersTable.styles';

const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

type OrganizationMembersTableRowProps = {
  member: OrganizationMember;
  onRevokeInvite: (inviteId: string) => void;
  onResendInvite: (inviteId: string) => void;
};

export const OrganizationMembersTableRow = ({
  member,
  onResendInvite,
  onRevokeInvite,
}: OrganizationMembersTableRowProps) => {
  const handleResendInvite = () => {
    if (member.inviteId) {
      // @todo: use a modal instead of window.confirm
      const isConfirmed = window.confirm('Are you sure you want to resend this invite?');
      if (isConfirmed) {
        onResendInvite(member.inviteId);
      }
    }
  };

  const handleRevokeInvite = () => {
    if (member.inviteId) {
      // @todo: use a modal instead of window.confirm
      const isConfirmed = window.confirm('Are you sure you want to revoke this invite?');
      if (isConfirmed) {
        onRevokeInvite(member.inviteId);
      }
    }
  };

  const isInvite = Boolean(member.inviteId);

  // The createdAt date is not applicable for invites as they are not created yet
  // and it reflects the date they joined in this context.
  const createdAt = isInvite ? null : member.createdAt;

  return (
    <MemberRow columnCount={ORGANIZATION_MEMBERS_TABLE_COLUMN_COUNT} $isInvite={isInvite}>
      <MemberCell>
        {formatDefaultValue(member.fullName)}
        {isInvite && (
          <Menu>
            <Menu.Button as={ContextMenuTrigger}>
              <BurgerIcon />
            </Menu.Button>
            <Menu.Items as={ContextMenuWrapper}>
              <Menu.Item as={ContextMenuItem} onClick={handleResendInvite}>
                Resend Invite Email
              </Menu.Item>
              <Menu.Item as={ContextMenuItem} onClick={handleRevokeInvite}>
                Revoke Invite
              </Menu.Item>
            </Menu.Items>
          </Menu>
        )}
      </MemberCell>
      <Cell>{formatDefaultValue(member.email)}</Cell>
      <Cell>{formatDefaultValue(member.status)}</Cell>
      <Cell>{formatDefaultValue(member.role)}</Cell>
      <Cell>{formatDate(createdAt ? new Date(createdAt) : null, DATE_FORMAT_OPTIONS)}</Cell>
      <Cell>{formatDefaultValue(member.phoneNumber)}</Cell>
    </MemberRow>
  );
};
