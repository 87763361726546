import React from 'react';

export const ChevronUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 14.7941L12 8L5 14.7941L6.2425 16L12 10.4119L17.7575 16L19 14.7941Z"
        fill="currentColor"
      />
    </svg>
  );
};
