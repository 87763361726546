import {
  PASSWORD_INPUT_FIELD_MAX_LENGTH,
  PASSWORD_INPUT_FIELD_MIN_LENGTH,
} from '@plus-platform/shared';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Input } from '../components/Input';
import { Page, PageContent, PageHeader } from '../components/Page';
import { useChangePasswordMutation } from '../hooks/queries/useChangePasswordMutation';
import {
  ButtonLoader,
  PasswordFields,
  PasswordForm,
  Section,
  SubTitle,
} from './ProfilePage.styles';

type PasswordFormValues = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const ProfilePage = () => {
  const { mutateAsync: changePassword } = useChangePasswordMutation();
  const { formState, handleSubmit, register, reset, watch } = useForm<PasswordFormValues>({
    mode: 'onChange',
  });

  const newPassword = watch('newPassword');
  const { isSubmitSuccessful, isSubmitting, isValid } = formState;

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  const updatePassword = async (values: PasswordFormValues) => {
    const { currentPassword, newPassword } = values;

    await changePassword({ currentPassword, newPassword });
  };

  return (
    <Page>
      <PageHeader>
        <Heading>Profile</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem title="Profile" />
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        <Section>
          <SubTitle>Password</SubTitle>
          <PasswordForm onSubmit={handleSubmit(updatePassword)}>
            <PasswordFields>
              <Input
                placeholder="Current password"
                type="password"
                {...register('currentPassword', { required: true })}
              />
              <Input
                placeholder="New password"
                type="password"
                {...register('newPassword', {
                  required: true,
                  minLength: PASSWORD_INPUT_FIELD_MIN_LENGTH,
                  maxLength: PASSWORD_INPUT_FIELD_MAX_LENGTH,
                  validate: {
                    atLeastOneLowercase: (value) => /[a-z]/.test(value),
                    atLeastOneUppercase: (value) => /[A-Z]/.test(value),
                    atLeastOneDigit: (value) => /\d/.test(value),
                    atLeastOneSpecialCharacter: (value) =>
                      /[-#!$@%^&*()_+|~=`{}[\]:";'<>?,./ ]/.test(value),
                  },
                })}
              />
              <Input
                placeholder="Repeat new password"
                type="password"
                {...register('repeatNewPassword', {
                  required: true,
                  deps: ['newPassword'],
                  validate: (value) => value === newPassword,
                })}
              />
            </PasswordFields>
            <ButtonLoader disabled={!isValid} isLoading={isSubmitting}>
              Update password
            </ButtonLoader>
          </PasswordForm>
        </Section>
      </PageContent>
    </Page>
  );
};
