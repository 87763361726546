import { ALL_LOANS_ATTRIBUTES, PermissionType } from '@plus-platform/shared';
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { InternalLink } from '../components/Link';
import { PrivateRoute } from '../global/PrivateRoute';
import { WidthWrapper } from '../global/WidthWrapper';
import { useLoanSummaryQuery } from '../hooks/queries';
import { LoanProvider } from './context';
import { LoanDetailPage } from './LoanDetail/LoanDetailPage';
import { LoanSummaryPage } from './LoanSummaryPage';
import { LoanPageRouteParams } from './types';

const Wrapper = styled(WidthWrapper)`
  padding: 24px 16px;
`;

const Title = styled.h3`
  margin: 0 0 0 10px;
  padding: 16px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  font-weight: 600;
  font-family: ${(props) => props.theme.typography.fontFamily.display};
`;

export const LoanPage = () => {
  const { loanNumber } = useParams<LoanPageRouteParams>();
  const { data: loanSummary, isError, isLoading, isSuccess } = useLoanSummaryQuery(loanNumber);

  if (isLoading) {
    return <ActivityIndicator contain isActive />;
  }

  if (!loanNumber || isError || (isSuccess && !loanSummary)) {
    // TODO need UI designs for this screen
    return (
      <Wrapper>
        <WidthWrapper>
          <Title>
            Unknown Loan. Please <InternalLink to="/home">click here</InternalLink> to go back to
            the homepage
          </Title>
        </WidthWrapper>
      </Wrapper>
    );
  }

  return (
    <LoanProvider value={{ loanNumber }}>
      <Switch>
        <PrivateRoute
          component={LoanDetailPage}
          path="/loan/:loanNumber/detail"
          requiredPermissions={{
            [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
          }}
        />
        <PrivateRoute
          path="/loan/:loanNumber"
          render={() => (loanSummary ? <LoanSummaryPage loanSummary={loanSummary} /> : null)}
          requiredPermissions={{
            [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
          }}
        />
      </Switch>
    </LoanProvider>
  );
};
