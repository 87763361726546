import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    border: 1px solid ${theme.colors.greyExtraLight};
    border-radius: 5px;
    overflow: hidden;
  `}
`;

const Button = styled.label<{ $isActive: boolean }>`
  ${({ $isActive, theme }) => css`
    position: relative;
    padding: 8px 16px;
    cursor: pointer;
    background-color: ${theme.colors.white};
    color: ${theme.colors.background01};
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;

    &:hover {
      background-color: ${theme.colors.greyLight};
      color: ${theme.colors.white};
    }

    ${$isActive &&
    css`
      background-color: ${theme.colors.background01};
      color: ${theme.colors.white};
      font-weight: bold;

      &:hover {
        background-color: ${theme.colors.background01};
      }
    `}

    &:not(:last-child) {
      border-right: 1px solid ${theme.colors.greyExtraLight};
    }

    input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }
  `}
`;

type SegmentedControlOptionValue = {
  value: string;
  label: string;
};

type SegmentedControlProps = {
  id: string;
  options: SegmentedControlOptionValue[];
  value: string;
  onChange: (value: string) => void;
};

export const SegmentedControl = ({ id, onChange, options, value }: SegmentedControlProps) => {
  return (
    <Container role="radiogroup" aria-labelledby={id}>
      {options.map((option) => (
        <Button
          key={option.value}
          htmlFor={`${id}-${option.value}`}
          $isActive={option.value === value}
        >
          <input
            type="radio"
            id={`${id}-${option.value}`}
            name={id}
            value={option.value}
            checked={option.value === value}
            onChange={() => onChange(option.value)}
          />
          {option.label}
        </Button>
      ))}
    </Container>
  );
};
