import React from 'react';

export const UpdateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="currentColor" height="12" width="2" x="5" />
      <rect fill="currentColor" height="12" transform="rotate(90 12 5)" width="2" x="12" y="5" />
    </svg>
  );
};
