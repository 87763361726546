import { LoanEvent } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanEventLogQuery = {
  tradeRequestId: string;
  loanId: string;
};

const getTradeRequestDataLoanEventLog = (query: TradeRequestDataLoanEventLogQuery) => {
  const { loanId, tradeRequestId } = query;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/loans/${loanId}/event-log`);

  return getRequestWithAuth<LoanEvent[]>(url);
};

export const useTradeRequestDataLoanEventLogQuery = (query: TradeRequestDataLoanEventLogQuery) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_EVENT_LOG, query], () =>
    getTradeRequestDataLoanEventLog(query)
  );
