import React from 'react';

export const AddCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 0C6.91775 0 5.37103 0.469192 4.05544 1.34824C2.73985 2.22729 1.71447 3.47672 1.10897 4.93853C0.503466 6.40034 0.34504 8.00887 0.653721 9.56072C0.962403 11.1126 1.72433 12.538 2.84315 13.6569C3.96197 14.7757 5.38743 15.5376 6.93928 15.8463C8.49113 16.155 10.0997 15.9965 11.5615 15.391C13.0233 14.7855 14.2727 13.7602 15.1518 12.4446C16.0308 11.129 16.5 9.58225 16.5 8C16.5 6.94942 16.2931 5.90914 15.891 4.93853C15.489 3.96793 14.8997 3.08601 14.1569 2.34315C13.414 1.60028 12.5321 1.011 11.5615 0.608964C10.5909 0.206926 9.55058 0 8.5 0ZM11.7 8.8H9.3V11.2C9.3 11.4122 9.21572 11.6157 9.06569 11.7657C8.91566 11.9157 8.71217 12 8.5 12C8.28783 12 8.08435 11.9157 7.93432 11.7657C7.78429 11.6157 7.7 11.4122 7.7 11.2V8.8H5.3C5.08783 8.8 4.88435 8.71571 4.73432 8.56568C4.58429 8.41565 4.5 8.21217 4.5 8C4.5 7.78782 4.58429 7.58434 4.73432 7.43431C4.88435 7.28428 5.08783 7.2 5.3 7.2H7.7V4.8C7.7 4.58783 7.78429 4.38434 7.93432 4.23431C8.08435 4.08428 8.28783 4 8.5 4C8.71217 4 8.91566 4.08428 9.06569 4.23431C9.21572 4.38434 9.3 4.58783 9.3 4.8V7.2H11.7C11.9122 7.2 12.1157 7.28428 12.2657 7.43431C12.4157 7.58434 12.5 7.78782 12.5 8C12.5 8.21217 12.4157 8.41565 12.2657 8.56568C12.1157 8.71571 11.9122 8.8 11.7 8.8Z"
        fill="currentColor"
      />
    </svg>
  );
};
