import { SubmissionAttributes } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const getSubmissionDocumentsQuery = async (submissionId: string) => {
  const url = makeApiUrl(`submissions/submission/${submissionId}/documents`);
  return await getRequestWithAuth<Required<SubmissionAttributes>>(url);
};

export const useGetSubmissionDocumentsQuery = (submissionId: string) =>
  useQuery([QueryKeys.SUBMISSIONS_DOCUMENTS], async () => {
    return await getSubmissionDocumentsQuery(submissionId);
  });
