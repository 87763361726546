export const ZIP_CODE_DEMOGRAPHIC_KEY_TO_LABEL: Record<string, string> = {
  whiteAlone: 'White',
  blackOrAfricanAmerican: 'Black or African American',
  hispanicOrLatino: 'Hispanic or Latino',
  asian: 'Asian',
  americanIndianAndAlaskaNative: 'American Indian and Alaska Native',
  nativeHawaiianAndOtherPacificIslander: 'Native Hawaiian and Other Pacific Islander',
};

export const getLoanNotesMessageFromNotesCount = (totalCount: number): string => {
  switch (totalCount) {
    case 0:
      return 'No notes available.';
    case 1:
      return 'There is 1 note on this loan.';
    default:
      return `There are ${totalCount} notes on this loan.`;
  }
};
