import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMONumericString,
  MISMOSequenceNumber,
} from './types';

export enum TaxpayerIdentifierType {
  EmployerIdentificationNumber = 'EmployerIdentificationNumber',
  IndividualTaxpayerIdentificationNumber = 'IndividualTaxpayerIdentificationNumber',
  PreparerTaxpayerIdentificationNumber = 'PreparerTaxpayerIdentificationNumber',
  SocialSecurityNumber = 'SocialSecurityNumber',
  TaxpayerIdentificationNumberForPendingUSAdoptions = 'TaxpayerIdentificationNumberForPendingUSAdoptions',
}

export type TaxpayerIdentifier = MISMOEntity &
  Partial<{
    canadianSocialInsuranceNumberIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
    SSNCertificationIndicator: MISMOIndicator;
    taxpayerIdentifierType: TaxpayerIdentifierType;
    taxpayerIdentifierValue: MISMONumericString;
  }>;
