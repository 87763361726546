import { PortfolioSummary } from '@plus-platform/shared';
import { ellipsis, transparentize } from 'polished';
import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import { Key, Row, Value } from '../components/DeprecatedKeyValue';
import {
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../utils/formatUtils';

export const ProgressBar = styled.div`
  display: flex;
  margin-bottom: 1px;
  column-gap: 1px;
`;

export const Bar = styled.div<{
  $color?: string;
  $percentage?: number;
  $variant: 'loan' | 'pool';
  $isLarge?: boolean;
}>(
  ({ $color, $isLarge, $percentage, $variant, theme }) => css`
    /** FIX ME: Width should be based on the percentage of loans in default that fall into this timebox  */
    width: ${$percentage ?? 33}%;
    height: 4px;

    ${$color
      ? css`
          background-color: ${$color};
        `
      : css`
          background-color: ${$variant === 'pool'
            ? theme.colors.poolSummaryBarGreen
            : theme.colors.purpleViolet};
        `}

    ${$isLarge &&
    css`
      width: 66%;
    `}
  `
);

export const LargeBar = styled(Bar)`
  /** FIX ME: Width should be based on the percentage of loans in default that fall into this timebox  */
  width: 66%;
`;

export const SummaryHeader = styled.div<{ $isClickable?: boolean }>`
  text-align: center;
  color: ${(props) => transparentize(0.1, props.theme.colors.white)};
  font-family: ${(props) => props.theme.typography.fontFamily.display};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.02em;
  padding: 8px 0;
  margin-bottom: 12px;

  ${(props) => props.onClick && `cursor: pointer;`}
`;

const SummaryCount = styled.span<{ $highlightColor?: string }>(
  ({ $highlightColor }) => css`
    font-weight: 700;
    color: ${$highlightColor ?? 'inherit'};
  `
);

export const SummarySection = styled.div<{
  $backgroundColor?: string;
  $variant: 'loan' | 'pool';
}>(
  ({ $variant, theme }) => css`
    background-color: ${theme.colors.purpleDark};
    border-radius: 4px;
    padding: 12px 12px 16px;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    ${Key}, ${Value} {
      width: 50%;
      ${ellipsis()};
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    ${SummaryHeader} {
      background-color: ${theme.colors.modalPurple};
    }

    ${$variant === 'pool' &&
    css`
      background-color: ${theme.colors.lightGreen01};

      ${SummaryHeader} {
        background-color: ${theme.colors.lightGreen};
      }
    `}
  `
);

type TopProgressBarProps = {
  $variant: 'loan' | 'pool';
  color1?: string;
  color2?: string;
  color3?: string;
};

export const TopProgressBar = ({ $variant, color1, color2, color3 }: TopProgressBarProps) => (
  <ProgressBar>
    <Bar $color={color1} $variant={$variant} />
    <Bar $color={color2} $variant={$variant} />
    <LargeBar $color={color3} $variant={$variant} />
  </ProgressBar>
);

type SectionHeaderProps = {
  children: React.ReactNode;
  highlight?: string;
  highlightColor?: string;
  onClick?: () => void;
};

export const SectionHeader = ({
  children,
  highlight,
  highlightColor,
  onClick,
}: SectionHeaderProps) => (
  <SummaryHeader onClick={onClick} $isClickable={Boolean(onClick)}>
    {children}{' '}
    {highlight && <SummaryCount $highlightColor={highlightColor}>{highlight}</SummaryCount>}
  </SummaryHeader>
);

type SummaryKeyFiguresProps = {
  $variant: 'loan' | 'pool';
  loansCount: number;
  remittanceAmount: number;
  upbAmount: number;
  weightedAverageLTV: number;
  weightedAverageCoupon: number;
};

export const SummaryKeyFigures = ({
  $variant,
  loansCount,
  remittanceAmount,
  upbAmount,
  weightedAverageCoupon,
  weightedAverageLTV,
}: SummaryKeyFiguresProps) => {
  return (
    <React.Fragment>
      <ProgressBar>
        <Bar $percentage={100} $variant={$variant} />
      </ProgressBar>
      <SectionHeader>Loan count {formatNumber(loansCount)}</SectionHeader>
      <Row>
        <Key>UPB</Key>
        <Value>{formatMonetary(upbAmount)}</Value>
      </Row>
      <Row>
        <Key>Remittance</Key>
        <Value>{formatMonetary(remittanceAmount)}</Value>
      </Row>
      <Row>
        <Key>WAC</Key>
        <Value>{formatPercentage(weightedAverageCoupon)}</Value>
      </Row>
      <Row>
        <Key>Wtd Avg LTV</Key>
        <Value>{formatPercentage(weightedAverageLTV)}</Value>
      </Row>
    </React.Fragment>
  );
};

type DelinquencyFinancialsProps = {
  upbAmount?: number;
  remittanceAmount?: number;
  weightedAverageLTV?: number;
  weightedAverageCoupon?: number;
  judicialCount?: number;
  nonJudicialCount?: number;
};

export const DelinquencyFinancials = ({
  judicialCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyFinancialsProps) => {
  return (
    <React.Fragment>
      <Row>
        <Key>UPB</Key>
        <Value>{formatMonetary(upbAmount)}</Value>
      </Row>
      <Row>
        <Key>P+I Arrearage</Key>
        <Value>{formatMonetary(remittanceAmount)}</Value>
      </Row>
      <Row>
        <Key>WAC</Key>
        <Value>{formatPercentage(weightedAverageCoupon)}</Value>
      </Row>
      <Row>
        <Key>Wtd Avg LTV</Key>
        <Value>{formatPercentage(weightedAverageLTV)}</Value>
      </Row>
      <Row>
        <Key>Judicial / Non-Judicial</Key>
        <Value>
          {formatDefaultValue(judicialCount)} / {formatDefaultValue(nonJudicialCount)}
        </Value>
      </Row>
    </React.Fragment>
  );
};

type DelinquencyPaneProps = DelinquencyFinancialsProps & {
  loanCount?: number;
  variant: 'loan' | 'pool';
};

const Delinquency90DaysPane = ({
  judicialCount,
  loanCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant,
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyPaneProps) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <TopProgressBar $variant={variant} color3={theme.colors.redSecondary} />
      <SectionHeader
        highlight={formatNumber(loanCount).toString()}
        highlightColor={theme.colors.redSecondary}
      >
        90+ Days Default
      </SectionHeader>

      <DelinquencyFinancials
        judicialCount={judicialCount}
        nonJudicialCount={nonJudicialCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
      />
    </React.Fragment>
  );
};

const Delinquency60DaysPane = ({
  judicialCount,
  loanCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant,
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyPaneProps) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <TopProgressBar $variant={variant} color2={theme.colors.statusOrange} />
      <SectionHeader
        highlight={formatNumber(loanCount).toString()}
        highlightColor={theme.colors.statusOrange}
      >
        60 Days Default
      </SectionHeader>

      <DelinquencyFinancials
        judicialCount={judicialCount}
        nonJudicialCount={nonJudicialCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
      />
    </React.Fragment>
  );
};

const Delinquency30DaysPane = ({
  judicialCount,
  loanCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant,
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyPaneProps) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <TopProgressBar $variant={variant} color1={theme.colors.statusYellow} />
      <SectionHeader
        highlight={formatNumber(loanCount).toString()}
        highlightColor={theme.colors.statusYellow}
      >
        30 Days Default
      </SectionHeader>

      <DelinquencyFinancials
        judicialCount={judicialCount}
        nonJudicialCount={nonJudicialCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
      />
    </React.Fragment>
  );
};

type DelinquencyWidgetProps = {
  summary: PortfolioSummary;
  hideLoansInDefault?: boolean;
  variant?: 'loan' | 'pool';
};

export const DelinquencyWidget = ({
  hideLoansInDefault,
  summary,
  variant,
}: DelinquencyWidgetProps) => {
  const theme = useTheme();
  const $variant = variant ?? 'loan';

  return (
    <React.Fragment>
      <SummarySection $variant={$variant}>
        <SummaryKeyFigures
          $variant={$variant}
          loansCount={summary.loansCount}
          remittanceAmount={summary.loansRemittanceAmount}
          upbAmount={summary.loansUPBAmount}
          weightedAverageCoupon={summary.loansWeightedAverageCoupon}
          weightedAverageLTV={summary.loansWeightedAverageLTV}
        />
      </SummarySection>
      {!hideLoansInDefault && (
        <SummarySection $variant={$variant}>
          <TopProgressBar $variant={$variant} />
          <SectionHeader
            highlight={formatNumber(summary.loansInDefault?.total?.loanCount).toString()}
            highlightColor={theme.colors.white}
          >
            Loans in Default
          </SectionHeader>

          <DelinquencyFinancials
            judicialCount={summary.loansInDefault?.total?.judicialCount}
            nonJudicialCount={summary.loansInDefault?.total?.nonJudicialCount}
            remittanceAmount={summary.loansInDefault?.total?.outstandingRemittance}
            upbAmount={summary.loansInDefault?.total?.upb}
            weightedAverageCoupon={summary.loansInDefault?.total?.weightedAverageCoupon}
            weightedAverageLTV={summary.loansInDefault?.total?.weightedAverageLTV}
          />
        </SummarySection>
      )}

      <SummarySection $variant={$variant}>
        <Delinquency30DaysPane
          judicialCount={summary.loansInDefault?.thirtyDays?.judicialCount}
          loanCount={summary.loansInDefault?.thirtyDays?.loanCount}
          nonJudicialCount={summary.loansInDefault?.thirtyDays?.nonJudicialCount}
          remittanceAmount={summary.loansInDefault?.thirtyDays?.outstandingRemittance}
          upbAmount={summary.loansInDefault?.thirtyDays?.upb}
          variant={$variant}
          weightedAverageCoupon={summary.loansInDefault?.thirtyDays?.weightedAverageCoupon}
          weightedAverageLTV={summary.loansInDefault?.thirtyDays?.weightedAverageLTV}
        />
      </SummarySection>

      <SummarySection $variant={$variant}>
        <Delinquency60DaysPane
          judicialCount={summary.loansInDefault?.sixtyDays?.judicialCount}
          loanCount={summary.loansInDefault?.sixtyDays?.loanCount}
          nonJudicialCount={summary.loansInDefault?.sixtyDays?.nonJudicialCount}
          remittanceAmount={summary.loansInDefault?.sixtyDays?.outstandingRemittance}
          upbAmount={summary.loansInDefault?.sixtyDays?.upb}
          variant={$variant}
          weightedAverageCoupon={summary.loansInDefault?.sixtyDays?.weightedAverageCoupon}
          weightedAverageLTV={summary.loansInDefault?.sixtyDays?.weightedAverageLTV}
        />
      </SummarySection>

      <SummarySection $variant={$variant}>
        <Delinquency90DaysPane
          judicialCount={summary.loansInDefault?.ninetyPlusDays?.judicialCount}
          loanCount={summary.loansInDefault?.ninetyPlusDays?.loanCount}
          nonJudicialCount={summary.loansInDefault?.ninetyPlusDays?.nonJudicialCount}
          remittanceAmount={summary.loansInDefault?.ninetyPlusDays?.outstandingRemittance}
          upbAmount={summary.loansInDefault?.ninetyPlusDays?.upb}
          variant={$variant}
          weightedAverageCoupon={summary.loansInDefault?.ninetyPlusDays?.weightedAverageCoupon}
          weightedAverageLTV={summary.loansInDefault?.ninetyPlusDays?.weightedAverageLTV}
        />
      </SummarySection>
    </React.Fragment>
  );
};
