import {
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum HousingCounselingAgencyListSourceType {
  CFPB = 'CFPB',
  HUD = 'HUD',
  Other = 'Other',
}

export enum CounselingFormatType {
  FaceToFace = 'FaceToFace',
  Internet = 'Internet',
  Other = 'Other',
  Telephone = 'Telephone',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum CounselingFormatTypeOtherDescription {
  BorrowerDidNotParticipate = 'BorrowerDidNotParticipate',
}

export enum CounselingProviderType {
  AmericanHomeownerEducationAndCounselingInstituteApproved = 'AmericanHomeownerEducationAndCounselingInstituteApproved',
  HUDApprovedCounselingAgency = 'HUDApprovedCounselingAgency',
  LenderTrained = 'LenderTrained',
  LocalHousingCounselingAgency = 'LocalHousingCounselingAgency',
  MultiStateOrganization = 'MultiStateOrganization',
  NationalIntermediary = 'NationalIntermediary',
  Other = 'Other',
  RegionalIntermediary = 'RegionalIntermediary',
  StateHousingFinanceAgency = 'StateHousingFinanceAgency',
  SubGrantee = 'SubGrantee',
  Unknown = 'Unknown',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum CounselingProviderTypeOtherDescription {
  BorrowerDidNotParticipate = 'BorrowerDidNotParticipate',
  MortgageInsuranceCompany = 'MortgageInsuranceCompany',
  NonProfitOrganization = 'NonProfitOrganization',
}

enum CounselingSettingType {
  Classroom = 'Classroom',
  Homestudy = 'Homestudy',
  Other = 'Other',
}

export enum CounselingType {
  Counseling = 'Counseling',
  Education = 'Education',
}

export type CounselingEvent = MISMOEntity &
  Partial<{
    counselingCompletedDate: MISMODate;
    counselingConfirmationIndicator: MISMOIndicator;
    counselingDeliveredDate: MISMODate;
    counselingFormatType: CounselingFormatType;
    counselingFormatTypeOtherDescription: MISMOString;
    counselingInitiatedDate: MISMODate;
    counselingProviderType: CounselingProviderType;
    counselingProviderTypeOtherDescription: MISMOString;
    counselingSettingType: CounselingSettingType;
    counselingSettingTypeOtherDescription: MISMOString;
    counselingType: CounselingType;
    housingCounselingCertificationIssuedDate: MISMODate;
    sequenceNumber: MISMOSequenceNumber;
    // counselingTopics: CounselingTopic[];
  }>;

export type Counseling = MISMOEntity &
  Partial<{
    housingCounselingAgenciesListProvidedDate: MISMODate;
    housingCounselingAgencyListSourceType: HousingCounselingAgencyListSourceType;
    housingCounselingAgencyListSourceTypeOtherDescription: MISMOString;
    HUDCounselingInitiatedIndicator: MISMOIndicator;
    counselingEvents: CounselingEvent[];
  }>;
