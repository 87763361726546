import React from 'react';

export const CustodianIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.52381 20C7.10476 20 6.74603 19.8508 6.44762 19.5524C6.14921 19.254 6 18.8952 6 18.4762V10.8571C6 10.4381 6.14921 10.0794 6.44762 9.78095C6.74603 9.48254 7.10476 9.33333 7.52381 9.33333H8.28571V7.80952C8.28571 6.75556 8.65714 5.85714 9.4 5.11429C10.1429 4.37143 11.0413 4 12.0952 4C13.1492 4 14.0476 4.37143 14.7905 5.11429C15.5333 5.85714 15.9048 6.75556 15.9048 7.80952V9.33333H16.6667C17.0857 9.33333 17.4444 9.48254 17.7429 9.78095C18.0413 10.0794 18.1905 10.4381 18.1905 10.8571V18.4762C18.1905 18.8952 18.0413 19.254 17.7429 19.5524C17.4444 19.8508 17.0857 20 16.6667 20H7.52381ZM7.52381 18.4762H16.6667V10.8571H7.52381V18.4762ZM12.0952 16.1905C12.5143 16.1905 12.873 16.0413 13.1714 15.7429C13.4698 15.4444 13.619 15.0857 13.619 14.6667C13.619 14.2476 13.4698 13.8889 13.1714 13.5905C12.873 13.2921 12.5143 13.1429 12.0952 13.1429C11.6762 13.1429 11.3175 13.2921 11.019 13.5905C10.7206 13.8889 10.5714 14.2476 10.5714 14.6667C10.5714 15.0857 10.7206 15.4444 11.019 15.7429C11.3175 16.0413 11.6762 16.1905 12.0952 16.1905ZM9.80952 9.33333H14.381V7.80952C14.381 7.1746 14.1587 6.63492 13.7143 6.19048C13.2698 5.74603 12.7302 5.52381 12.0952 5.52381C11.4603 5.52381 10.9206 5.74603 10.4762 6.19048C10.0317 6.63492 9.80952 7.1746 9.80952 7.80952V9.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};
