import { TradeRequestWithSummary } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getTradeRequestSummary } from '../../trades/tradeRequestsUtils';
import { QueryKeys } from './types';

export const useGetTradeRequestSummaryQuery = <T = TradeRequestWithSummary>(
  tradeRequestId: string,
  category: string,
  onSuccess?: (data?: T) => void
) =>
  useQuery(
    [QueryKeys.TRADE_REQUEST_SUMMARY, tradeRequestId, category],
    () => getTradeRequestSummary<T>(tradeRequestId, category),
    { onSuccess: (data) => onSuccess?.(data) }
  );
