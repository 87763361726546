import {
  MISMOAmount,
  MISMOEntity,
  MISMONumeric,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum NewImprovementType {
  Additional = 'Additional',
  Dwelling = 'Dwelling',
  EnergyEfficientItems = 'EnergyEfficientItems',
  Garage = 'Garage',
  LevelFive = 'LevelFive',
  LevelFour = 'LevelFour',
  LevelOne = 'LevelOne',
  LevelThree = 'LevelThree',
  LevelTwo = 'LevelTwo',
  Other = 'Other',
  PorchesPatios = 'PorchesPatios',
  SectionFour = 'SectionFour',
  SectionOne = 'SectionOne',
  SectionThree = 'SectionThree',
  SectionTwo = 'SectionTwo',
}

export type NewImprovement = MISMOEntity &
  Partial<{
    newImprovementCostAmount: MISMOAmount;
    newImprovementCostDescription: MISMOString;
    newImprovementType: NewImprovementType;
    newImprovementTypeOtherDescription: MISMOString;
    pricePerSquareFootAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
    squareFeetNumber: MISMONumeric;
  }>;
