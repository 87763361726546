import { Filter } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, putRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type FilterParam = Omit<Filter, 'id'>;

const APIFilterURL = 'user/filters/portfolio';

const savePortfolioFilters = (filters: FilterParam[]) => {
  const url = makeApiUrl(APIFilterURL);

  return putRequestWithAuth<Filter[]>(url, {
    body: JSON.stringify(filters),
  });
};

export const useSavePortfolioFiltersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(savePortfolioFilters, {
    onSuccess: (filters = []) => {
      queryClient.setQueryData([QueryKeys.PORTFOLIO_FILTERS], filters);
    },
  });
};

const saveSinglePortfolioFilter = (filter: Omit<FilterParam, 'isApplied'>) => {
  const url = makeApiUrl(`${APIFilterURL}/single`);

  return putRequestWithAuth<Filter>(url, {
    body: JSON.stringify({ ...filter, isApplied: true }),
  });
};

export const useSinglePortfolioFilterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(saveSinglePortfolioFilter, {
    onSuccess: (filter) => {
      queryClient.setQueryData([QueryKeys.PORTFOLIO_FILTER], filter);
    },
  });
};
