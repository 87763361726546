import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import {
  EventPropTypeInterface,
  Point,
  PointProps,
  StringOrNumberOrCallback,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryScatterTTargetType,
} from 'victory';

import { FullWidthLoaderWrapper, Loader } from '../../components/Loader';
import { plusChartTheme } from '../../styles/chart';
import { formatMonetary } from '../../utils/formatUtils';
import { useLoanPropertyValuations } from '../LoanData';
import {
  PROPERTY_VALUATION_TOOLTIP_ID,
  PropertyValuationPopup,
  PropertyValuationPopupData,
} from './PropertyValuationPopup';
import { Circle, Legend, LegendItem, LegendText, Wrapper } from './styles';
import {
  PropertyValuationDataPoint,
  PropertyValuationHistoryChartProps,
  PropertyValuationHistoryResponse,
  PropertyValuationType,
} from './types';
import { generateDateTicks } from './utils';

const getYValuationBounds = (allYData: number[]) => {
  const minValuation = Math.min(...allYData) * 0.9;
  const maxValuation = Math.max(...allYData) * 1.1;
  return { minValuation, maxValuation };
};

const formatDateTick = (date: Date | number) => {
  // We want to hide the axis if there isn't any data
  // The default value is a number, so we check for it
  // to prevent a crash
  if (typeof date === 'number') {
    return '';
  }

  return date
    .toLocaleDateString('en-GB', {
      year: '2-digit',
      month: 'short',
    })
    .replace(/,/g, '-');
};

const transformValuationDataToChartFormat = (data: PropertyValuationDataPoint, index?: number) => ({
  ...data,
  x: new Date(data.date),
  y: data.value,
  index,
});

const hasPurchasePrice = (response: PropertyValuationHistoryResponse) =>
  get(response, 'purchasePrice.length', 0) > 0;

const CustomPoint = (props: PointProps) => {
  return (
    <React.Fragment>
      <Point {...props} data-tooltip-id={PROPERTY_VALUATION_TOOLTIP_ID} />
    </React.Fragment>
  );
};

export const PropertyValuationHistoryChart = ({
  height = 200,
  width = 200,
}: PropertyValuationHistoryChartProps) => {
  const [selectedPropertyValuation, setSelectedPropertyValuation] = React.useState<
    PropertyValuationPopupData | undefined
  >(undefined);
  const { data: propertyValuations, isLoading } = useLoanPropertyValuations();
  const theme = useTheme();
  const bpoBlue = theme.colors.purple;
  const appraisalCoral = theme.colors.redMain;
  const purchasePriceYellow = theme.colors.tradeActivityYellow;
  const avmGrey = theme.colors.greenLight;
  const comparableSalesPurple = theme.colors.purpleLight;
  const mlsGreen = theme.colors.lightGreen03;

  const axisStyles = {
    grid: {
      stroke: theme.colors.white05,
    },
    axis: { stroke: theme.colors.white05 },
  };

  if (isLoading) {
    return (
      <FullWidthLoaderWrapper>
        <Loader />
      </FullWidthLoaderWrapper>
    );
  }

  if (!propertyValuations) {
    return null;
  }

  const allPropertyValuations = Object.values(propertyValuations).flat();
  const allPropertyYValues = allPropertyValuations.map((data) => data.value);

  const { maxValuation, minValuation } = getYValuationBounds(allPropertyYValues);

  const scatterEvents: EventPropTypeInterface<
    VictoryScatterTTargetType,
    StringOrNumberOrCallback
  >[] = [
    {
      target: 'data',
      eventHandlers: {
        onClick: () => {
          return [
            {
              target: 'data',
              mutation: (props) => {
                const { address, bedroomCount, value } = props.datum;
                setSelectedPropertyValuation({
                  price: value,
                  bedroomCount,
                  address,
                });
              },
            },
          ];
        },
      },
    },
  ];

  return (
    <Wrapper>
      <Legend>
        <LegendItem>
          <Circle color={purchasePriceYellow} />
          <LegendText>Purchase Price</LegendText>
        </LegendItem>
        <LegendItem>
          <Circle color={appraisalCoral} />
          <LegendText>Appraisal</LegendText>
        </LegendItem>
        <LegendItem>
          <Circle color={bpoBlue} />
          <LegendText>BPO</LegendText>
        </LegendItem>
        <LegendItem>
          <Circle color={avmGrey} />
          <LegendText>AVM</LegendText>
        </LegendItem>
        <LegendItem>
          <Circle color={comparableSalesPurple} />
          <LegendText>Comparable</LegendText>
        </LegendItem>
        <LegendItem>
          <Circle color={mlsGreen} />
          <LegendText>MLS</LegendText>
        </LegendItem>
      </Legend>

      <PropertyValuationPopup propertyValuation={selectedPropertyValuation} />

      <VictoryChart
        padding={{
          top: 8,
          bottom: 16,
          left: 38,
          right: 16,
        }}
        height={height}
        width={width}
        theme={plusChartTheme}
        domain={{
          y: [minValuation, maxValuation],
        }}
      >
        <VictoryAxis
          crossAxis
          orientation="bottom"
          tickFormat={formatDateTick}
          tickValues={generateDateTicks(
            allPropertyValuations.map(transformValuationDataToChartFormat).map((d) => d.x)
          )}
          style={{
            ...axisStyles,
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(x) => formatMonetary(x)}
          style={{
            ...axisStyles,
          }}
        />
        <VictoryScatter
          style={{ data: { fill: bpoBlue } }}
          size={3}
          data={propertyValuations.bpo.map((data, index) => ({
            ...transformValuationDataToChartFormat(data, index),
            type: PropertyValuationType.BPO,
          }))}
        />
        <VictoryScatter
          style={{ data: { fill: avmGrey } }}
          data={propertyValuations.avm.map((data, index) => ({
            ...transformValuationDataToChartFormat(data, index),
            type: PropertyValuationType.AVM,
          }))}
        />
        {hasPurchasePrice(propertyValuations) && (
          <VictoryScatter
            style={{ data: { fill: purchasePriceYellow } }}
            data={allPropertyValuations.map((data) => ({
              ...data,
              x: new Date(data.date),
              y: get(propertyValuations, 'purchasePrice[0].value'),
            }))}
            dataComponent={<CustomPoint />}
            events={scatterEvents}
          />
        )}
        {hasPurchasePrice(propertyValuations) && (
          <VictoryLine
            style={{
              data: { stroke: purchasePriceYellow },
              parent: { border: '1px solid #ccc' },
            }}
            data={allPropertyValuations.map((data) => ({
              ...data,
              x: new Date(data.date),
              y: get(propertyValuations, 'purchasePrice[0].value'),
            }))}
          />
        )}
        <VictoryScatter
          style={{ data: { fill: appraisalCoral } }}
          data={propertyValuations.appraisal.map((data, index) => ({
            ...transformValuationDataToChartFormat(data, index),
            type: PropertyValuationType.APPRAISAL,
          }))}
          dataComponent={<CustomPoint />}
          events={scatterEvents}
        />
        <VictoryScatter
          style={{ data: { fill: comparableSalesPurple } }}
          data={propertyValuations.comparableSales.map((data, index) => ({
            ...transformValuationDataToChartFormat(data, index),
            type: PropertyValuationType.COMPARABLE_SALES,
          }))}
          dataComponent={<CustomPoint />}
          events={scatterEvents}
        />

        <VictoryScatter
          style={{ data: { fill: mlsGreen } }}
          data={propertyValuations.mls.map((data, index) => ({
            ...transformValuationDataToChartFormat(data, index),
            type: PropertyValuationType.MLS,
          }))}
          dataComponent={<CustomPoint />}
          events={scatterEvents}
        />

        <VictoryLine
          style={{
            data: { stroke: avmGrey },
            parent: { border: '1px solid #ccc' },
          }}
          data={propertyValuations.avm.map(transformValuationDataToChartFormat)}
        />
        <VictoryLine
          style={{
            data: { stroke: bpoBlue },
            parent: { border: '1px solid #ccc' },
          }}
          data={propertyValuations.bpo.map(transformValuationDataToChartFormat)}
        />
      </VictoryChart>
    </Wrapper>
  );
};
