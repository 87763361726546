import { Counseling } from '../counseling';
import { CreditScore } from '../creditScore';
import { Dependent } from '../dependent';
import { Employer } from '../employer';
import { HousingExpense, HousingExpenseSummary } from '../housingExpense';
import { CurrentIncome } from '../income';
import { Residence } from '../residence';
import {
  MISMOAmount,
  MISMOCode,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOString,
} from '../types';
import { Declaration } from './declaration';
import { GovernmentMonitoring } from './governmentMonitoring';

export enum BorrowerCharacteristicType {
  LivingTrust = 'LivingTrust',
  Other = 'Other',
  SeasonedWorker = 'SeasonedWorker',
  SellerEmployee = 'SellerEmployee',
  TrailingBorrower = 'TrailingBorrower',
}

export enum BorrowerClassificationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

enum BorrowerRelationshipTitleType {
  AHusbandAndWife = 'AHusbandAndWife',
  AMarriedCouple = 'AMarriedCouple',
  AMarriedMan = 'AMarriedMan',
  AMarriedPerson = 'AMarriedPerson',
  AMarriedWoman = 'AMarriedWoman',
  AnUnmarriedMan = 'AnUnmarriedMan',
  AnUnmarriedPerson = 'AnUnmarriedPerson',
  AnUnmarriedWoman = 'AnUnmarriedWoman',
  ASameSexMarriedCouple = 'ASameSexMarriedCouple',
  AsDomesticPartners = 'AsDomesticPartners',
  ASingleMan = 'ASingleMan',
  ASinglePerson = 'ASinglePerson',
  ASingleWoman = 'ASingleWoman',
  AWidow = 'AWidow',
  AWidower = 'AWidower',
  AWifeAndHusband = 'AWifeAndHusband',
  HerHusband = 'HerHusband',
  HisWife = 'HisWife',
  JoinedInACivilUnion = 'JoinedInACivilUnion',
  JoinedInACommonLawMarriage = 'JoinedInACommonLawMarriage',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
}

enum DomesticRelationshipType {
  CivilUnion = 'CivilUnion',
  DomesticPartnership = 'DomesticPartnership',
  Other = 'Other',
  RegisteredReciprocalBeneficiaryRelationship = 'RegisteredReciprocalBeneficiaryRelationship',
}

enum EmploymentStateType {
  Employed = 'Employed',
  Other = 'Other',
  Retired = 'Retired',
  Unemployed = 'Unemployed',
}

enum JointAssetLiabilityReportingType {
  Jointly = 'Jointly',
  NotJointly = 'NotJointly',
}

export enum MaritalStatusType {
  Divorced = 'Divorced',
  Married = 'Married',
  NotProvided = 'NotProvided',
  Other = 'Other',
  Separated = 'Separated',
  Unknown = 'Unknown',
  Unmarried = 'Unmarried',
}

export type Borrower = MISMOEntity &
  Partial<{
    automobilesOwnedCount: MISMOCount;
    borrowerAgeAtApplicationYearsCount: MISMOCount;
    borrowerApplicationSignedDate: MISMODate;
    borrowerBankruptcyIndicator: MISMOIndicator;
    borrowerBirthDate: MISMODate;
    borrowerCharacteristicType: BorrowerCharacteristicType;
    borrowerCharacteristicTypeOtherDescription: MISMOString;
    borrowerClassificationType: BorrowerClassificationType;
    borrowerDeathDate: MISMODate;
    borrowerIsAnIndividualPersonIndicator: MISMOIndicator;
    borrowerMailToAddressSameAsPropertyIndicator: MISMOIndicator;
    borrowerQualifyingIncomeAmount: MISMOAmount;
    borrowerRelationshipTitleType: BorrowerRelationshipTitleType;
    borrowerRelationshipTitleTypeOtherDescription: MISMOString;
    borrowerSameAsBuilderIndicator: MISMOIndicator;
    borrowerTotalMortgagedPropertiesCount: MISMOCount;
    communityPropertyStateResidentIndicator: MISMOIndicator;
    creditFileBorrowerAgeYearsCount: MISMOCount;
    creditReportAuthorizationIndicator: MISMOIndicator;
    creditReportIdentifier: MISMOIdentifier;
    dependentCount: MISMOCount;
    domesticRelationshipIndicator: MISMOIndicator;
    domesticRelationshipStateCode: MISMOCode;
    domesticRelationshipType: DomesticRelationshipType;
    domesticRelationshipTypeOtherDescription: MISMOString;
    employmentStateType: EmploymentStateType;
    employmentStateTypeOtherDescription: MISMOString;
    employmentVerificationLevelCode: MISMOCode;
    housingCounselingRequiredIndicator: MISMOIndicator;
    incomeVerificationLevelCode: MISMOCode;
    intentToProceedWithLoanTransactionIndicatedDate: MISMODate;
    jointAssetLiabilityReportingType: JointAssetLiabilityReportingType;
    maritalStatusType: MaritalStatusType;
    maritalStatusTypeOtherDescription: MISMOString;
    militaryLendingActCoveredBorrowerIndicator: MISMOIndicator;
    schoolingYearsCount: MISMOCount;
    selfDeclaredMilitaryServiceIndicator: MISMOIndicator;
    signedAuthorizationToRequestTaxRecordsIndicator: MISMOIndicator;
    spousalVABenefitsEligibilityIndicator: MISMOIndicator;
    taxRecordsObtainedIndicator: MISMOIndicator;
    // adverseAction: AdverseAction;
    // bankruptcies: Bankruptcy[];
    counseling: Counseling;
    creditScores: CreditScore[];
    currentIncome: CurrentIncome;
    declaration: Declaration;
    dependents: Dependent[];
    employers: Employer[];
    // governmentBorrower: GovernmentBorrower;
    governmentMonitoring: GovernmentMonitoring;
    // hardshipDeclaration: HardshipDeclaration;
    housingExpenses: HousingExpense[];
    housingExpenseSummary: HousingExpenseSummary;
    // militaryServices: MilitaryService[];
    // nearestLivingRelative: NearestLivingRelative;
    residences: Residence[];
    // solicitationPreference: SolicitationPreference;
    // summaries: Summary[];
  }>;
