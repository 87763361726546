import styled, { css } from 'styled-components/macro';

export const MapWrapper = styled.div`
  height: 100%;
  min-height: 0;
  flex: 1;
  display: flex;

  ${({ theme }) => css`
    /* Workaround for google maps cluster icon styling bug */
    .ev-charging-station-cluster {
      background-color: #0b0d22;
      outline: 3px solid ${theme.colors.darkBlue};
      border-radius: 37px;
      color: ${theme.colors.white};

      > img {
        display: none;
      }
    }
  `}
`;
