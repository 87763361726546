import React from 'react';

export const DataRequestLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.77778 20C6.28889 20 5.87037 19.8259 5.52222 19.4778C5.17407 19.1296 5 18.7111 5 18.2222V5.77778C5 5.28889 5.17407 4.87037 5.52222 4.52222C5.87037 4.17407 6.28889 4 6.77778 4H17.2222C17.7111 4 18.1296 4.17407 18.4778 4.52222C18.8259 4.87037 19 5.28889 19 5.77778V18.2222C19 18.7111 18.8259 19.1296 18.4778 19.4778C18.1296 19.8259 17.7111 20 17.2222 20H6.77778ZM6.77778 8.73333H17.2222V5.77778H6.77778V8.73333ZM6.77778 13.4889H17.2222V10.5111H6.77778V13.4889ZM6.77778 18.2222H17.2222V15.2667H6.77778V18.2222ZM7.66667 8.13333V6.35556H9.44444V8.13333H7.66667ZM7.66667 12.8889V11.1111H9.44444V12.8889H7.66667ZM7.66667 17.6444V15.8667H9.44444V17.6444H7.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
