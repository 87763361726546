import { EvChargingStation } from '@plus-platform/shared';
import { Marker, MarkerClusterer } from '@react-google-maps/api';
import { Clusterer, ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import evChargingStationIcon from '../../assets/ev-charging-station.svg';
import { useEvChargingStationsNearbyQuery } from '../../hooks/queries';
import { useDebounce } from '../../hooks/useDebounce';
import { getHaversineDistance } from '../../utils/mapUtils';

type EvChargingStationMarkerProps = {
  station: EvChargingStation;
  clusterer: Clusterer;
};

const EvChargingStationMarker = ({ clusterer, station }: EvChargingStationMarkerProps) => (
  <Marker
    clusterer={clusterer}
    icon={evChargingStationIcon}
    position={{
      lng: station.longitude,
      lat: station.latitude,
    }}
  />
);

type EvChargingStationsProps = {
  bounds: google.maps.LatLngBounds;
};

const formatBounds = (bounds: google.maps.LatLngBounds) => ({
  latitude: bounds.getCenter().lat(),
  longitude: bounds.getCenter().lng(),
  distance: Math.round(
    getHaversineDistance(
      {
        latitude: bounds.getNorthEast().lat(),
        longitude: bounds.getNorthEast().lng(),
      },
      {
        latitude: bounds.getSouthWest().lat(),
        longitude: bounds.getSouthWest().lng(),
      }
    )
  ),
});

type EvChargingStationsClustererProps = {
  markers: EvChargingStation[];
};

const EvChargingStationsClusterer = React.memo(
  ({ markers }: EvChargingStationsClustererProps) => {
    const theme = useTheme();
    return (
      <MarkerClusterer
        styles={[
          {
            className: 'ev-charging-station-cluster',
            url: '',
            height: 37,
            width: 37,
            textColor: theme.colors.white,
            textSize: 12,
          } as ClusterIconStyle,
        ]}
      >
        {(clusterer) => (
          <React.Fragment>
            {markers.map((evChargingStation) => (
              <EvChargingStationMarker
                clusterer={clusterer}
                key={evChargingStation.id}
                station={evChargingStation}
              />
            ))}
          </React.Fragment>
        )}
      </MarkerClusterer>
    );
  },
  (prev, next) => isEqual(prev.markers, next.markers)
);

export const EvChargingStationsLayer = ({ bounds }: EvChargingStationsProps) => {
  const debouncedBounds = useDebounce<google.maps.LatLngBounds>(bounds);

  const [markers, setMarkers] = React.useState<EvChargingStation[]>([]);
  const formattedBounds = React.useMemo(() => formatBounds(debouncedBounds), [debouncedBounds]);

  const { data, isLoading } = useEvChargingStationsNearbyQuery(
    formattedBounds.latitude,
    formattedBounds.longitude,
    formattedBounds.distance
  );

  React.useEffect(() => {
    if (data && !isLoading && !isEqual(data, markers)) {
      setMarkers(data);
    }
  }, [data, isLoading, markers]);

  if (!markers.length) {
    return null;
  }

  return <EvChargingStationsClusterer markers={markers} />;
};
