import {
  PortfolioHomeAnalyticGroupType,
  PortfolioSettings,
  RecommendedAnalyticType,
} from '@plus-platform/shared';
import uniq from 'lodash/uniq';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { Popup, PopupHeader } from '../../components/Popup';
import { PrivateRoute } from '../../global/PrivateRoute';
import { BrilliantDataPreview } from './BrilliantPreview';
import { EditDataPopup } from './EditDataPopup';

type DataPopupProps = {
  settings: PortfolioSettings;
  onCancel: () => void;
  onUpdate: (settings: PortfolioSettings) => void;
};

export const DataPopup = (props: DataPopupProps) => {
  const { onUpdate, settings } = props;
  const match = useRouteMatch();
  const history = useHistory();

  const goToEditData = () => {
    history.push(`${match.url}/edit`);
  };

  const onAddBrilliantPreview = () => {
    onUpdate({
      ...settings,
      analytics: {
        [PortfolioHomeAnalyticGroupType.RECOMMENDED]: uniq([
          ...(settings.analytics[PortfolioHomeAnalyticGroupType.RECOMMENDED] ?? []),
          RecommendedAnalyticType.BRILLIANT,
        ]),
      },
    });
  };

  return (
    <Popup top={12} bottom={12} left={12} right={12}>
      <PopupHeader title="Edit data" />

      <Switch>
        <PrivateRoute render={() => <EditDataPopup {...props} />} path={`${match.path}/edit`} />
        <PrivateRoute
          render={() => (
            <BrilliantDataPreview onCancel={goToEditData} onAdd={onAddBrilliantPreview} />
          )}
          // TODO: Have a look at creating a more generic solution to preview capsules when
          // any of the others capsule previews actually have a defined UX/UI.
          path={`${match.path}/recommended/brilliant`}
        />

        <Redirect to="/home" />
      </Switch>
    </Popup>
  );
};
