import {
  MISMOCode,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMONumeric,
  MISMOString,
} from './types';

enum NFIPCommunityParticipationStatusType {
  Emergency = 'Emergency',
  NonParticipating = 'NonParticipating',
  Other = 'Other',
  Probation = 'Probation',
  Regular = 'Regular',
  Suspended = 'Suspended',
  Unknown = 'Unknown',
}

enum NFIPFloodDataRevisionType {
  LOMA = 'LOMA',
  LOMR = 'LOMR',
}

type FloodRiskFactor = MISMOEntity &
  Partial<{
    baseFloodElevationFeetNumber: MISMONumeric;
    floodDepthFeetNumber: MISMONumeric;
    propertyElevationFeetNumber: MISMONumeric;
    specialFloodHazardAreaDistanceFeetNumber: MISMONumeric;
  }>;

export type FloodDetermination = MISMOEntity &
  Partial<{
    floodCertificationIdentifier: MISMOIdentifier;
    floodDeterminationLifeOfLoanIndicator: MISMOIndicator;
    floodDiscrepancyGrandfatheredIndicator: MISMOIndicator;
    floodDiscrepancyIndicator: MISMOIndicator;
    floodDiscrepancyResolvedIndicator: MISMOIndicator;
    floodPartialIndicator: MISMOIndicator;
    floodProductCertifyDate: MISMODate;
    NFIPCommunityFIRMDate: MISMODate;
    NFIPCommunityIdentifier: MISMOIdentifier;
    NFIPCommunityName: MISMOString;
    NFIPCommunityParticipationStartDate: MISMODate;
    NFIPCommunityParticipationStatusType: NFIPCommunityParticipationStatusType;
    NFIPCommunityParticipationStatusTypeOtherDescription: MISMOString;
    NFIPCountyName: MISMOString;
    NFIPFloodDataRevisionType: NFIPFloodDataRevisionType;
    NFIPFloodZoneIdentifier: MISMOIdentifier;
    NFIPLetterOfMapDate: MISMODate;
    NFIPMapIdentifier: MISMOIdentifier;
    NFIPMapIndicator: MISMOIndicator;
    NFIPMapPanelDate: MISMODate;
    NFIPMapPanelIdentifier: MISMOIdentifier;
    NFIPMapPanelSuffixIdentifier: MISMOIdentifier;
    NFIPStateCode: MISMOCode;
    protectedAreaDesignationDate: MISMODate;
    protectedAreaIndicator: MISMOIndicator;
    specialFloodHazardAreaIndicator: MISMOIndicator;
    floodRiskFactor: FloodRiskFactor;
  }>;
