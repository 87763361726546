import { GetPortfolioLoansDocumentSummaryResponse } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioLoansDocumentSummary = async (
  filterIds: number[],
  offset = 0,
  limit: number | undefined = undefined,
  sortBy: string | undefined = undefined
) => {
  const sortSymbol = '';
  const sortQuery = sortBy ? `${sortSymbol}${sortBy}` : '';

  const query = new URLSearchParams({
    offset: `${offset}`,
    limit: limit ? `${limit}` : '',
    sort: sortQuery,
  });

  filterIds.forEach((id) => query.append('filterIds', `${id}`));

  const url = makeApiUrl(`portfolio/loans/document-summary?${query}`);
  return getRequestWithAuth<GetPortfolioLoansDocumentSummaryResponse>(url);
};
