import { PortfolioSummary } from '@plus-platform/shared';
import React from 'react';

import {
  AddToPoolIcon,
  AnalyticsLineIcon,
  DeleteIcon,
  DuplicateIcon,
  ExportLineIcon,
  HistoryIcon,
  PortfolioLineIcon,
  ShareIcon,
  TradeLineIcon,
} from '../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection } from '../components/Menu';
import { exportPoolLoans } from './poolsUtils';

type PoolMenuProps = {
  poolId?: string;
  loansCount?: PortfolioSummary['loansCount'];
};

export const PoolMenu = ({ loansCount, poolId }: PoolMenuProps) => {
  return (
    <Menu title="Pool tools" data-testid="PoolActionsMenu">
      <MenuSection>
        <MenuItem disabled={!poolId} to={`/pool/${poolId}/add-loans`}>
          <AddToPoolIcon />
          <MenuItemText>Add loans to pool</MenuItemText>
        </MenuItem>
        <MenuItem
          disabled={!poolId || !loansCount || loansCount === 0}
          to={`/trade-requests/pool/${poolId}`}
        >
          <TradeLineIcon />
          <MenuItemText>Trade</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <ShareIcon />
          <MenuItemText>Share</MenuItemText>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <MenuItem disabled>
          <HistoryIcon />
          <MenuItemText>History</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <AnalyticsLineIcon />
          <MenuItemText>Visualisations</MenuItemText>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        {poolId && (
          <MenuItem onClick={() => exportPoolLoans(poolId)}>
            <ExportLineIcon />
            <MenuItemText>Export all</MenuItemText>
          </MenuItem>
        )}
        <MenuItem disabled={!poolId} to={`/pool/${poolId}/delete`}>
          <DeleteIcon />
          <MenuItemText>Delete</MenuItemText>
        </MenuItem>
        <MenuItem disabled>
          <DuplicateIcon />
          <MenuItemText>Duplicate</MenuItemText>
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <MenuItem disabled>
          <PortfolioLineIcon />
          <MenuItemText>View portfolio</MenuItemText>
        </MenuItem>
      </MenuSection>
    </Menu>
  );
};
