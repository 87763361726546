import React from 'react';
import { StyledComponentInnerOtherProps } from 'styled-components/macro';

import { QuoteCard, QuoteStack as Stack } from './Quote.styles';

type QuoteStackProps = {
  children: React.ReactNode;
};

export const QuoteStack = (props: QuoteStackProps) => {
  const { children } = props;
  const flattenedChildren = Array.isArray(children) ? children.flat() : [children];

  const [activeIndex, setActiveIndex] = React.useState(flattenedChildren.length - 1);

  return (
    <Stack $activeIndex={activeIndex}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onClick: () => setActiveIndex(index),
            $index: index,
            $isActive: activeIndex === index,
            $isStack: true,
            $count: flattenedChildren.length,
            style: {
              zIndex:
                index === activeIndex
                  ? flattenedChildren.length
                  : index < activeIndex
                  ? index
                  : flattenedChildren.length - index,
              transform: `translateX(${25 * (flattenedChildren.length - 1 - index)}px)`,
            },
          } as StyledComponentInnerOtherProps<typeof QuoteCard>);
        }
      })}
    </Stack>
  );
};
