import React from 'react';

export const OpenFolderIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.11628 18C4.83101 18 4.57364 17.8844 4.34419 17.6531C4.11473 17.4219 4 17.1625 4 16.875V7.125C4 6.8375 4.11473 6.57813 4.34419 6.34688C4.57364 6.11563 4.83101 6 5.11628 6H10.3442L11.4605 7.125H17.7674C18.0527 7.125 18.3101 7.24063 18.5395 7.47188C18.769 7.70313 18.8837 7.9625 18.8837 8.25H10.9767L9.86047 7.125H5.11628V16.875L7.01395 9.375H20L18.0093 17.1375C17.9349 17.4375 17.7984 17.6563 17.6 17.7938C17.4016 17.9313 17.1473 18 16.8372 18H5.11628ZM6.28837 16.875H16.9302L18.493 10.5H7.85116L6.28837 16.875Z"
        fill="currentColor"
      />
    </svg>
  );
};
