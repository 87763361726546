import {
  PortfolioHomeAnalyticGroupType,
  PortfolioHomeAnalyticPresetType,
} from '@plus-platform/shared';
import { useQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const usePortfolioHomeAnalyticsPreviewQuery = (
  group: PortfolioHomeAnalyticGroupType,
  preset: PortfolioHomeAnalyticPresetType
) =>
  useQuery([QueryKeys.PORTFOLIO_HOME_ANALYTICS_PREVIEW, group, preset], () =>
    api.getPortfolioHomeAnalyticsPreview(group, preset)
  );
