import { InboundTradeRequestWithSummary } from '@plus-platform/shared';

import { useTradeRequestsInboundSummaryQuery } from '../hooks/queries';
import { TradeRequestsView } from './TradeRequestsView';
import { InboundTradeRequestLarge } from './TradeRequestTickets';

export const TradeRequestsInbound = () => {
  const { data, isLoading } = useTradeRequestsInboundSummaryQuery();
  const tradeRequests = data || [];

  return (
    <TradeRequestsView<InboundTradeRequestWithSummary>
      isLoading={isLoading}
      tradeRequests={tradeRequests}
      noItemsMessage="No inbound trade activity"
      renderItem={(tradeRequest) => (
        <InboundTradeRequestLarge
          key={tradeRequest.id}
          tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
          url={`/trade-requests/inbound/${tradeRequest.id}`}
        />
      )}
    />
  );
};
