import {
  InboundTradeRequestWithSummary,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';

import { UserMessage } from '../../../../components/Messages';
import { QuoteRejectedSummary } from '../QuoteRejectedSummary';
import { QuoteStack } from '../QuoteStack';
import { QuoteSummary } from '../QuoteSummary';
import { QuoteSummaryType } from '../types';

type InboundQuoteRejectedProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: InboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const InboundQuoteRejected = (props: InboundQuoteRejectedProps) => {
  const { currentQuoteIndex, quote, totalQuoteCount, tradeRequest } = props;

  return (
    <UserMessage
      date={quote.updatedAt}
      organizationName={tradeRequest.user.organization.tradingName}
      lastName={tradeRequest.user.lastName}
      firstName={tradeRequest.user.firstName}
    >
      <QuoteStack>
        <QuoteSummary
          currentQuoteIndex={currentQuoteIndex}
          quote={quote}
          totalQuoteCount={totalQuoteCount}
          tradeRequest={tradeRequest}
        />
        <QuoteRejectedSummary
          reference={`${quote.id}`}
          sender={tradeRequest.user}
          senderOrganization={tradeRequest.user.organization}
          sentAt={quote.updatedAt}
          type={QuoteSummaryType.QUOTE}
          value={quote.value}
        />
      </QuoteStack>
    </UserMessage>
  );
};
