import styled, { css } from 'styled-components/macro';

export const Filters = styled.ul`
  margin: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 360px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 32px;
`;

export const FilterRow = styled.li<{
  $isDisplayed: boolean;
}>(
  ({ $isDisplayed }) => css`
    display: ${$isDisplayed ? 'grid' : 'none'};
    grid-template-columns: min-content repeat(4, minmax(250px, 1fr)) auto;
    column-gap: 16px;
    max-width: 100%;
    align-items: center;
  `
);

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
