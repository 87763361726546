import {
  MISMOCode,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum DocumentType {
  '203KBorrowerAcknowledgment' = '203KBorrowerAcknowledgment',
  '203KBorrowerIdentityOfInterestCertification' = '203KBorrowerIdentityOfInterestCertification',
  '203KConsultantIdentityOfInterestStatement' = '203KConsultantIdentityOfInterestStatement',
  '203KConsultantReport' = '203KConsultantReport',
  '203KCostEstimates' = '203KCostEstimates',
  '203KDrawRequest' = '203KDrawRequest',
  '203KHomeownerAcknowledgment' = '203KHomeownerAcknowledgment',
  '203KInitialDrawRequest' = '203KInitialDrawRequest',
  '203KMaximumMortgageWorksheet' = '203KMaximumMortgageWorksheet',
  '203KRehabilitationAgreement' = '203KRehabilitationAgreement',
  AbstractNoticeAgreement = 'AbstractNoticeAgreement',
  AbstractOfJudgment = 'AbstractOfJudgment',
  ACHDebitAuthorization = 'ACHDebitAuthorization',
  AcknowledgmentOfNoticeOfRightToCancel = 'AcknowledgmentOfNoticeOfRightToCancel',
  AffidavitOfDeath = 'AffidavitOfDeath',
  AffidavitOfLostNote = 'AffidavitOfLostNote',
  AffiliatedBusinessArrangementDisclosure = 'AffiliatedBusinessArrangementDisclosure',
  AirportNoisePollutionAgreement = 'AirportNoisePollutionAgreement',
  AlternativeRequestForAgreementOfShortSale = 'AlternativeRequestForAgreementOfShortSale',
  AmendatoryClause = 'AmendatoryClause',
  AmortizationSchedule = 'AmortizationSchedule',
  ApplicationDisclosure = 'ApplicationDisclosure',
  'ApplicationDisclosure:AdditionalCreditAndDebt' = 'ApplicationDisclosure:AdditionalCreditAndDebt',
  AppraisalRecertification = 'AppraisalRecertification',
  AppraisalReport = 'AppraisalReport',
  'AppraisalReport:Desktop' = 'AppraisalReport:Desktop',
  'AppraisalReport:ExteriorOnly' = 'AppraisalReport:ExteriorOnly',
  'AppraisalReport:InteriorExterior' = 'AppraisalReport:InteriorExterior',
  AppraisalReview = 'AppraisalReview',
  'AppraisalReview:ExteriorOnlyInspectionWithValueOpinion' = 'AppraisalReview:ExteriorOnlyInspectionWithValueOpinion',
  'AppraisalReview:ExteriorOnlyWithoutValueOpinion' = 'AppraisalReview:ExteriorOnlyWithoutValueOpinion',
  'AppraisalReview:InteriorExteriorInspectionWithValueOpinion' = 'AppraisalReview:InteriorExteriorInspectionWithValueOpinion',
  'AppraisalReview:InteriorExteriorWithoutValueOpinion' = 'AppraisalReview:InteriorExteriorWithoutValueOpinion',
  'AppraisalReview:NoInspectionWithValueOpinion' = 'AppraisalReview:NoInspectionWithValueOpinion',
  'AppraisalReview:WithoutValueOpinion' = 'AppraisalReview:WithoutValueOpinion',
  AppraisalReviewRequest = 'AppraisalReviewRequest',
  ApprovalLetter = 'ApprovalLetter',
  ArticlesOfIncorporation = 'ArticlesOfIncorporation',
  Assignment = 'Assignment',
  'Assignment:AssignmentOfDeedOfTrust' = 'Assignment:AssignmentOfDeedOfTrust',
  'Assignment:AssignmentOfMortgage' = 'Assignment:AssignmentOfMortgage',
  'Assignment:AssignmentOfRents' = 'Assignment:AssignmentOfRents',
  'Assignment:AssignmentOfTrade' = 'Assignment:AssignmentOfTrade',
  'Assignment:BlanketAssignment' = 'Assignment:BlanketAssignment',
  'Assignment:CooperativeAssignmentOfProprietaryLease' = 'Assignment:CooperativeAssignmentOfProprietaryLease',
  AssumptionAgreement = 'AssumptionAgreement',
  AssuranceOfCompletion = 'AssuranceOfCompletion',
  AttestationOfDamageToProperty = 'AttestationOfDamageToProperty',
  AttorneyInFactAffidavit = 'AttorneyInFactAffidavit',
  AttorneysOpinionLetter = 'AttorneysOpinionLetter',
  AutomatedUnderwritingFeedback = 'AutomatedUnderwritingFeedback',
  AutomatedValuationReport = 'AutomatedValuationReport',
  'AutomatedValuationReport:WithInspection' = 'AutomatedValuationReport:WithInspection',
  AVMFeedback = 'AVMFeedback',
  BaileeLetter = 'BaileeLetter',
  BalanceTransferAuthorizationNotice = 'BalanceTransferAuthorizationNotice',
  BalloonRefinanceDisclosure = 'BalloonRefinanceDisclosure',
  BankDepositSlip = 'BankDepositSlip',
  BankruptcyDischargeNotice = 'BankruptcyDischargeNotice',
  BankStatement = 'BankStatement',
  'BankStatement:401K' = 'BankStatement:401K',
  'BankStatement:CheckingAccount' = 'BankStatement:CheckingAccount',
  'BankStatement:MutualFundAccount' = 'BankStatement:MutualFundAccount',
  'BankStatement:SavingAccount' = 'BankStatement:SavingAccount',
  'BankStatement:StockAccount' = 'BankStatement:StockAccount',
  BenefitPlanDistributionStatement = 'BenefitPlanDistributionStatement',
  'BenefitPlanDistributionStatement:401K' = 'BenefitPlanDistributionStatement:401K',
  'BenefitPlanDistributionStatement:Annuity' = 'BenefitPlanDistributionStatement:Annuity',
  'BenefitPlanDistributionStatement:Pension' = 'BenefitPlanDistributionStatement:Pension',
  'BenefitPlanDistributionStatement:Trust' = 'BenefitPlanDistributionStatement:Trust',
  Bid = 'Bid',
  BirthCertificate = 'BirthCertificate',
  BondCertificate = 'BondCertificate',
  BorrowerAcknowledgmentOfPropertyCondition = 'BorrowerAcknowledgmentOfPropertyCondition',
  BorrowerCorrespondence = 'BorrowerCorrespondence',
  'BorrowerCorrespondence:GapInEmployment' = 'BorrowerCorrespondence:GapInEmployment',
  'BorrowerCorrespondence:LetterOfExplanation' = 'BorrowerCorrespondence:LetterOfExplanation',
  'BorrowerCorrespondence:LetterOfIntent' = 'BorrowerCorrespondence:LetterOfIntent',
  'BorrowerCorrespondence:QualifiedWrittenRequest' = 'BorrowerCorrespondence:QualifiedWrittenRequest',
  'BorrowerCorrespondence:TrailingSpouse' = 'BorrowerCorrespondence:TrailingSpouse',
  BorrowerLienAffidavit = 'BorrowerLienAffidavit',
  BorrowersCertification = 'BorrowersCertification',
  BorrowersContractWithRespectToHotelAndTransientUseOfProperty = 'BorrowersContractWithRespectToHotelAndTransientUseOfProperty',
  BreachNotice = 'BreachNotice',
  BrokerDisclosureStatement = 'BrokerDisclosureStatement',
  BrokerPriceOpinion = 'BrokerPriceOpinion',
  'BrokerPriceOpinion:Desktop' = 'BrokerPriceOpinion:Desktop',
  'BrokerPriceOpinion:ExteriorInspection' = 'BrokerPriceOpinion:ExteriorInspection',
  BuildersCertification = 'BuildersCertification',
  'BuildersCertification:BuilderCertificationOfPlansAndSpecifications' = 'BuildersCertification:BuilderCertificationOfPlansAndSpecifications',
  'BuildersCertification:BuildersCertificate' = 'BuildersCertification:BuildersCertificate',
  'BuildersCertification:PropertyInspection' = 'BuildersCertification:PropertyInspection',
  'BuildersCertification:TermiteTreatment' = 'BuildersCertification:TermiteTreatment',
  BuildingPermit = 'BuildingPermit',
  BusinessLicense = 'BusinessLicense',
  BuydownAgreement = 'BuydownAgreement',
  BuyingYourHomeSettlementCostsAndHelpfulInformation = 'BuyingYourHomeSettlementCostsAndHelpfulInformation',
  CAIVRSAuthorization = 'CAIVRSAuthorization',
  CancellationOfListing = 'CancellationOfListing',
  Check = 'Check',
  Checklist = 'Checklist',
  ChildSupportVerification = 'ChildSupportVerification',
  CloseLineOfCreditRequest = 'CloseLineOfCreditRequest',
  ClosingDisclosure = 'ClosingDisclosure',
  'ClosingDisclosure:AlternateForm' = 'ClosingDisclosure:AlternateForm',
  'ClosingDisclosure:BorrowerOnly' = 'ClosingDisclosure:BorrowerOnly',
  'ClosingDisclosure:ModelForm' = 'ClosingDisclosure:ModelForm',
  'ClosingDisclosure:SellerOnly' = 'ClosingDisclosure:SellerOnly',
  ClosingInstructions = 'ClosingInstructions',
  CollectionRegister = 'CollectionRegister',
  ComparativeIncomeAnalysis = 'ComparativeIncomeAnalysis',
  ComplianceAgreement = 'ComplianceAgreement',
  ComplianceInspectionReport = 'ComplianceInspectionReport',
  ConditionalCommitment = 'ConditionalCommitment',
  CondominiumOccupancyCertificate = 'CondominiumOccupancyCertificate',
  ConservatorAndGuardianshipAgreement = 'ConservatorAndGuardianshipAgreement',
  ConstructionCostBreakdown = 'ConstructionCostBreakdown',
  ConsumerHandbookOnARM = 'ConsumerHandbookOnARM',
  ConversionOptionNotice = 'ConversionOptionNotice',
  ConveyanceDeed = 'ConveyanceDeed',
  'ConveyanceDeed:BargainAndSaleDeed' = 'ConveyanceDeed:BargainAndSaleDeed',
  'ConveyanceDeed:QuitClaimDeed' = 'ConveyanceDeed:QuitClaimDeed',
  'ConveyanceDeed:WarrantyDeed' = 'ConveyanceDeed:WarrantyDeed',
  ConveyanceTaxForm = 'ConveyanceTaxForm',
  CooperativeBylaws = 'CooperativeBylaws',
  CooperativeOperatingBudget = 'CooperativeOperatingBudget',
  CooperativeProprietaryLease = 'CooperativeProprietaryLease',
  CooperativeRecognitionAgreement = 'CooperativeRecognitionAgreement',
  CooperativeStockCertificate = 'CooperativeStockCertificate',
  CooperativeStockPower = 'CooperativeStockPower',
  CosignerNotice = 'CosignerNotice',
  CouncilOfAmericanBuildingOfficialsCertification = 'CouncilOfAmericanBuildingOfficialsCertification',
  CounselingCertification = 'CounselingCertification',
  'CounselingCertification:Homeownership' = 'CounselingCertification:Homeownership',
  'CounselingCertification:HomeRetention' = 'CounselingCertification:HomeRetention',
  CounselingChecklistForMilitaryHomebuyers = 'CounselingChecklistForMilitaryHomebuyers',
  CreditCardAuthorization = 'CreditCardAuthorization',
  CreditInsuranceAgreement = 'CreditInsuranceAgreement',
  CreditReport = 'CreditReport',
  CreditReportingAdjustment = 'CreditReportingAdjustment',
  CustomerIdentificationVerification = 'CustomerIdentificationVerification',
  DeathCertificate = 'DeathCertificate',
  DebtorsStatementOfRights = 'DebtorsStatementOfRights',
  DivorceDecree = 'DivorceDecree',
  DocumentDeliveryCertification = 'DocumentDeliveryCertification',
  'DocumentDeliveryCertification:AppraisalReport' = 'DocumentDeliveryCertification:AppraisalReport',
  DocumentSequence = 'DocumentSequence',
  DriversLicense = 'DriversLicense',
  EarlyStartLetter = 'EarlyStartLetter',
  ElectronicFundsTransfer = 'ElectronicFundsTransfer',
  ElevationCertificate = 'ElevationCertificate',
  EmployeeIdentification = 'EmployeeIdentification',
  EnergyEfficientMortgageWorksheet = 'EnergyEfficientMortgageWorksheet',
  EqualCreditOpportunityActDisclosure = 'EqualCreditOpportunityActDisclosure',
  EscrowAgreement = 'EscrowAgreement',
  EscrowAnalysisAnnualDisclosureStatement = 'EscrowAnalysisAnnualDisclosureStatement',
  EscrowForCompletionAgreement = 'EscrowForCompletionAgreement',
  EscrowForCompletionLetter = 'EscrowForCompletionLetter',
  EscrowWaiver = 'EscrowWaiver',
  EstimateOfClosingCostsPaidToThirdParty = 'EstimateOfClosingCostsPaidToThirdParty',
  EstoppelAgreement = 'EstoppelAgreement',
  EvaluationReport = 'EvaluationReport',
  'EvaluationReport:Desktop' = 'EvaluationReport:Desktop',
  'EvaluationReport:ExteriorInspection' = 'EvaluationReport:ExteriorInspection',
  FACTACreditScoreDisclosure = 'FACTACreditScoreDisclosure',
  FairLendingNotice = 'FairLendingNotice',
  FederalApplicationInsuranceDisclosure = 'FederalApplicationInsuranceDisclosure',
  FederalSaleOfInsuranceDisclosure = 'FederalSaleOfInsuranceDisclosure',
  FHA = 'FHA',
  _MIPremiumNettingAuthorization = 'MIPremiumNettingAuthorization',
  FHAFiveDayWaiver = 'FHAFiveDayWaiver',
  FHALimitedDenialOfParticipationGeneralServicesAdministrationChecklist = 'FHALimitedDenialOfParticipationGeneralServicesAdministrationChecklist',
  FHAMortgageCreditAnalysisWorksheet = 'FHAMortgageCreditAnalysisWorksheet',
  FHAReferralChecklist = 'FHAReferralChecklist',
  FHARefinanceMaximumMortgageWorksheet = 'FHARefinanceMaximumMortgageWorksheet',
  FHARefinanceOfBorrowersInNegativeEquityPositionsBorrowerCertification = 'FHARefinanceOfBorrowersInNegativeEquityPositionsBorrowerCertification',
  FinancialStatement = 'FinancialStatement',
  'FinancialStatement:BalanceSheet' = 'FinancialStatement:BalanceSheet',
  'FinancialStatement:CashFlow' = 'FinancialStatement:CashFlow',
  'FinancialStatement:FinancialPosition' = 'FinancialStatement:FinancialPosition',
  'FinancialStatement:Income' = 'FinancialStatement:Income',
  FloodHazardNotice = 'FloodHazardNotice',
  FloodInsuranceAgreement = 'FloodInsuranceAgreement',
  ForbearancePlanAgreement = 'ForbearancePlanAgreement',
  ForcePlacedInsuranceNotice = 'ForcePlacedInsuranceNotice',
  'ForcePlacedInsuranceNotice:Cancellation' = 'ForcePlacedInsuranceNotice:Cancellation',
  'ForcePlacedInsuranceNotice:Initial' = 'ForcePlacedInsuranceNotice:Initial',
  'ForcePlacedInsuranceNotice:InsufficientDocumentation' = 'ForcePlacedInsuranceNotice:InsufficientDocumentation',
  'ForcePlacedInsuranceNotice:Placement' = 'ForcePlacedInsuranceNotice:Placement',
  'ForcePlacedInsuranceNotice:Renewal' = 'ForcePlacedInsuranceNotice:Renewal',
  'ForcePlacedInsuranceNotice:SecondAndFinal' = 'ForcePlacedInsuranceNotice:SecondAndFinal',
  ForYourProtectionHomeInspection = 'ForYourProtectionHomeInspection',
  FREOwnedStreamlineRefinanceChecklist = 'FREOwnedStreamlineRefinanceChecklist',
  FundingTransmittal = 'FundingTransmittal',
  GeneralLoanAcknowledgment = 'GeneralLoanAcknowledgment',
  GFE = 'GFE',
  GiftLetter = 'GiftLetter',
  GroupSavingsAgreement = 'GroupSavingsAgreement',
  HECMAntiChurningDisclosure = 'HECMAntiChurningDisclosure',
  HECMCalculationEvidence = 'HECMCalculationEvidence',
  HECMChoiceOfInsuranceOptionsNotice = 'HECMChoiceOfInsuranceOptionsNotice',
  HECMClosingCostsAndLiensSchedule = 'HECMClosingCostsAndLiensSchedule',
  HECMCounselingWaiverQualification = 'HECMCounselingWaiverQualification',
  HECMExtension = 'HECMExtension',
  HECMFaceToFaceCertification = 'HECMFaceToFaceCertification',
  HECMFinancialAssessmentWorksheet = 'HECMFinancialAssessmentWorksheet',
  HECMLoanSubmissionSchedule = 'HECMLoanSubmissionSchedule',
  HECMNoticeToBorrower = 'HECMNoticeToBorrower',
  HECMPaymentPlan = 'HECMPaymentPlan',
  HERSHomeEnergyReport = 'HERSHomeEnergyReport',
  HighCostWorksheet = 'HighCostWorksheet',
  HoldHarmlessAgreement = 'HoldHarmlessAgreement',
  HomeBuyerEducationCertification = 'HomeBuyerEducationCertification',
  HomeEquityLineFreezeLetter = 'HomeEquityLineFreezeLetter',
  HomeownersAssociationCertification = 'HomeownersAssociationCertification',
  HousingCounselingAgenciesList = 'HousingCounselingAgenciesList',
  IdentityAffidavit = 'IdentityAffidavit',
  'IdentityAffidavit:IdentityTheft' = 'IdentityAffidavit:IdentityTheft',
  'IdentityAffidavit:Name' = 'IdentityAffidavit:Name',
  'IdentityAffidavit:Signature' = 'IdentityAffidavit:Signature',
  IdentityTheftDisclosure = 'IdentityTheftDisclosure',
  IncompleteApplicationNotice = 'IncompleteApplicationNotice',
  IndividualDevelopmentAccountStatement = 'IndividualDevelopmentAccountStatement',
  InitialAdjustableRateMortgageDisclosure = 'InitialAdjustableRateMortgageDisclosure',
  InitialEscrowAccountDisclosure = 'InitialEscrowAccountDisclosure',
  IntegratedDisclosure = 'IntegratedDisclosure',
  InterestRateAndDiscountDisclosureStatement = 'InterestRateAndDiscountDisclosureStatement',
  Invoice = 'Invoice',
  'Invoice:UtilityBill' = 'Invoice:UtilityBill',
  IRS1040 = 'IRS1040',
  IRS1041 = 'IRS1041',
  IRS1065 = 'IRS1065',
  IRS1098 = 'IRS1098',
  IRS1099 = 'IRS1099',
  IRS1099A = 'IRS1099A',
  IRS1099B = 'IRS1099B',
  IRS1099C = 'IRS1099C',
  IRS1099CAP = 'IRS1099CAP',
  IRS1099DIV = 'IRS1099DIV',
  IRS1099G = 'IRS1099G',
  IRS1099H = 'IRS1099H',
  IRS1099INT = 'IRS1099INT',
  IRS1099LTC = 'IRS1099LTC',
  IRS1099MISC = 'IRS1099MISC',
  IRS1099OID = 'IRS1099OID',
  IRS1099PATR = 'IRS1099PATR',
  IRS1099Q = 'IRS1099Q',
  IRS1099R = 'IRS1099R',
  IRS1099S = 'IRS1099S',
  IRS1099SA = 'IRS1099SA',
  IRS1120 = 'IRS1120',
  IRS1120S = 'IRS1120S',
  IRSTaxTranscript = 'IRSTaxTranscript',
  IRSW2 = 'IRSW2',
  IRSW8 = 'IRSW8',
  IRSW9 = 'IRSW9',
  ItemizationOfAmountFinanced = 'ItemizationOfAmountFinanced',
  Judgment = 'Judgment',
  LandLeaseholdAgreement = 'LandLeaseholdAgreement',
  LastWillAndTestament = 'LastWillAndTestament',
  LeadHazardInformation = 'LeadHazardInformation',
  LegalDescription = 'LegalDescription',
  LenderCorrespondence = 'LenderCorrespondence',
  LenderLateEndorsementRequestCertificationLetter = 'LenderLateEndorsementRequestCertificationLetter',
  LifeInsurancePolicy = 'LifeInsurancePolicy',
  LineItemBudget = 'LineItemBudget',
  LoanApplication = 'LoanApplication',
  'LoanApplication:FNM1009' = 'LoanApplication:FNM1009',
  'LoanApplication:URLA' = 'LoanApplication:URLA',
  LoanClosingNotice = 'LoanClosingNotice',
  LoanEstimate = 'LoanEstimate',
  LoanModificationAffidavit = 'LoanModificationAffidavit',
  LoanModificationAgreement = 'LoanModificationAgreement',
  LoanModificationDenialNotice = 'LoanModificationDenialNotice',
  LoanModificationRequest = 'LoanModificationRequest',
  LoanModificationTrialPaymentPeriodAgreement = 'LoanModificationTrialPaymentPeriodAgreement',
  LoanPayoffRequest = 'LoanPayoffRequest',
  LoanStatement = 'LoanStatement',
  LoanSubmissionSummary = 'LoanSubmissionSummary',
  ManufacturedHousingCertificateOfTitle = 'ManufacturedHousingCertificateOfTitle',
  MaritalStatusAffidavit = 'MaritalStatusAffidavit',
  MarriageCertificate = 'MarriageCertificate',
  MIApplication = 'MIApplication',
  MICertificate = 'MICertificate',
  MIConditionalCommitment = 'MIConditionalCommitment',
  MIDeclination = 'MIDeclination',
  MIDisclosure = 'MIDisclosure',
  MilitaryDischargePapers = 'MilitaryDischargePapers',
  MilitaryIdentification = 'MilitaryIdentification',
  MIModification = 'MIModification',
  MIRateQuote = 'MIRateQuote',
  MISuspension = 'MISuspension',
  MonthlySummaryOfAssistancePayments = 'MonthlySummaryOfAssistancePayments',
  MortgageesCertificationAndApplicationForAssistanceOrInterestReductionPayments = 'MortgageesCertificationAndApplicationForAssistanceOrInterestReductionPayments',
  NameAffidavit = 'NameAffidavit',
  NationalIdentification = 'NationalIdentification',
  'NationalIdentification:CedulaDeIdentidad' = 'NationalIdentification:CedulaDeIdentidad',
  'NationalIdentification:SocialSecurityCard' = 'NationalIdentification:SocialSecurityCard',
  NearestLivingRelativeInformation = 'NearestLivingRelativeInformation',
  'NearestLivingRelativeInformation:AlternativeContact' = 'NearestLivingRelativeInformation:AlternativeContact',
  'NearestLivingRelativeInformation:VAQuestionnaire' = 'NearestLivingRelativeInformation:VAQuestionnaire',
  NonBorrowingSpouseCertificationLetter = 'NonBorrowingSpouseCertificationLetter',
  'NonBorrowingSpouseCertificationLetter:Eligible' = 'NonBorrowingSpouseCertificationLetter:Eligible',
  'NonBorrowingSpouseCertificationLetter:Ineligible' = 'NonBorrowingSpouseCertificationLetter:Ineligible',
  NonDiplomatVerification = 'NonDiplomatVerification',
  NonRefundabilityNotice = 'NonRefundabilityNotice',
  Note = 'Note',
  'Note:Consolidated' = 'Note:Consolidated',
  'Note:HECMLoanAgreement' = 'Note:HECMLoanAgreement',
  'Note:NewMoney' = 'Note:NewMoney',
  NoteAddendum = 'NoteAddendum',
  'NoteAddendum:AffordableMeritRate' = 'NoteAddendum:AffordableMeritRate',
  'NoteAddendum:Arbitration' = 'NoteAddendum:Arbitration',
  'NoteAddendum:ARM' = 'NoteAddendum:ARM',
  'NoteAddendum:Balloon' = 'NoteAddendum:Balloon',
  'NoteAddendum:Construction' = 'NoteAddendum:Construction',
  'NoteAddendum:FixedRateOption' = 'NoteAddendum:FixedRateOption',
  'NoteAddendum:GEM' = 'NoteAddendum:GEM',
  'NoteAddendum:GPM' = 'NoteAddendum:GPM',
  'NoteAddendum:InterestOnly' = 'NoteAddendum:InterestOnly',
  'NoteAddendum:InterVivosRevocableTrust' = 'NoteAddendum:InterVivosRevocableTrust',
  'NoteAddendum:OccupancyDeclaration' = 'NoteAddendum:OccupancyDeclaration',
  'NoteAddendum:Prepayment' = 'NoteAddendum:Prepayment',
  'NoteAddendum:RateImprovement' = 'NoteAddendum:RateImprovement',
  NoteAllonge = 'NoteAllonge',
  NoteAndSecurityInstrumentModification = 'NoteAndSecurityInstrumentModification',
  NoteModification = 'NoteModification',
  NoteRider = 'NoteRider',
  'NoteRider:ARM' = 'NoteRider:ARM',
  'NoteRider:Balloon' = 'NoteRider:Balloon',
  'NoteRider:Buydown' = 'NoteRider:Buydown',
  'NoteRider:HECMRepair' = 'NoteRider:HECMRepair',
  'NoteRider:Occupancy' = 'NoteRider:Occupancy',
  'NoteRider:Prepayment' = 'NoteRider:Prepayment',
  'NoteRider:StepRate' = 'NoteRider:StepRate',
  NoticeOfActionTaken = 'NoticeOfActionTaken',
  NoticeOfCompletion = 'NoticeOfCompletion',
  NoticeOfDefault = 'NoticeOfDefault',
  NoticeOfRightToCancel = 'NoticeOfRightToCancel',
  'NoticeOfRightToCancel:AddSecurityInterest' = 'NoticeOfRightToCancel:AddSecurityInterest',
  'NoticeOfRightToCancel:CreditIncrease' = 'NoticeOfRightToCancel:CreditIncrease',
  'NoticeOfRightToCancel:IncreaseSecurity' = 'NoticeOfRightToCancel:IncreaseSecurity',
  'NoticeOfRightToCancel:OpenAccount' = 'NoticeOfRightToCancel:OpenAccount',
  NoticeOfRightToCopyOfAppraisalReport = 'NoticeOfRightToCopyOfAppraisalReport',
  NoticeToHomebuyer = 'NoticeToHomebuyer',
  NoticeToLender = 'NoticeToLender',
  OccupancyAgreement = 'OccupancyAgreement',
  OccupancyCertification = 'OccupancyCertification',
  Other = 'Other',
  PartialReleaseOfSecurityInstrument = 'PartialReleaseOfSecurityInstrument',
  PartnershipAgreement = 'PartnershipAgreement',
  Passport = 'Passport',
  PaymentHistory = 'PaymentHistory',
  PaymentLetter = 'PaymentLetter',
  PayoffStatement = 'PayoffStatement',
  PayStub = 'PayStub',
  PermanentResidentIdentification = 'PermanentResidentIdentification',
  PersonalPropertyAppraisalReport = 'PersonalPropertyAppraisalReport',
  PowerOfAttorney = 'PowerOfAttorney',
  'PowerOfAttorney:Durable' = 'PowerOfAttorney:Durable',
  'PowerOfAttorney:Limited' = 'PowerOfAttorney:Limited',
  'PowerOfAttorney:ManufacturedHousing' = 'PowerOfAttorney:ManufacturedHousing',
  PrepaymentChargeOptionNotice = 'PrepaymentChargeOptionNotice',
  PrequalificationLetter = 'PrequalificationLetter',
  PrimaryMIAnnualDisclosure = 'PrimaryMIAnnualDisclosure',
  PrivacyDisclosure = 'PrivacyDisclosure',
  PrivateIdentification = 'PrivateIdentification',
  ProductComparisonDisclosure = 'ProductComparisonDisclosure',
  ProofOfHUDWaiverCAIVRSClearance = 'ProofOfHUDWaiverCAIVRSClearance',
  ProofOfMonetaryInvestment = 'ProofOfMonetaryInvestment',
  ProofOfResidenceInDeclaredMajorDisasterArea = 'ProofOfResidenceInDeclaredMajorDisasterArea',
  ProofOfTaxDeferralOrExemption = 'ProofOfTaxDeferralOrExemption',
  PropertyInspectionReport = 'PropertyInspectionReport',
  'PropertyInspectionReport:Carpet' = 'PropertyInspectionReport:Carpet',
  'PropertyInspectionReport:Environmental' = 'PropertyInspectionReport:Environmental',
  'PropertyInspectionReport:Footings' = 'PropertyInspectionReport:Footings',
  'PropertyInspectionReport:Framing' = 'PropertyInspectionReport:Framing',
  'PropertyInspectionReport:Heating' = 'PropertyInspectionReport:Heating',
  'PropertyInspectionReport:Insulation' = 'PropertyInspectionReport:Insulation',
  'PropertyInspectionReport:Pest' = 'PropertyInspectionReport:Pest',
  'PropertyInspectionReport:Plumbing' = 'PropertyInspectionReport:Plumbing',
  'PropertyInspectionReport:Roof' = 'PropertyInspectionReport:Roof',
  'PropertyInspectionReport:Septic' = 'PropertyInspectionReport:Septic',
  'PropertyInspectionReport:Soil' = 'PropertyInspectionReport:Soil',
  'PropertyInspectionReport:SoilTreatment' = 'PropertyInspectionReport:SoilTreatment',
  'PropertyInspectionReport:StructuralEngineering' = 'PropertyInspectionReport:StructuralEngineering',
  'PropertyInspectionReport:SubterraneanTermite' = 'PropertyInspectionReport:SubterraneanTermite',
  'PropertyInspectionReport:Termite' = 'PropertyInspectionReport:Termite',
  'PropertyInspectionReport:WaterHealth' = 'PropertyInspectionReport:WaterHealth',
  'PropertyInspectionReport:Well' = 'PropertyInspectionReport:Well',
  PropertyInsuranceBinder = 'PropertyInsuranceBinder',
  'PropertyInsuranceBinder:Earthquake' = 'PropertyInsuranceBinder:Earthquake',
  'PropertyInsuranceBinder:FireAndExtendedCoverage' = 'PropertyInsuranceBinder:FireAndExtendedCoverage',
  'PropertyInsuranceBinder:Flood' = 'PropertyInsuranceBinder:Flood',
  'PropertyInsuranceBinder:Hazard' = 'PropertyInsuranceBinder:Hazard',
  'PropertyInsuranceBinder:Homeowners' = 'PropertyInsuranceBinder:Homeowners',
  'PropertyInsuranceBinder:Hurricane' = 'PropertyInsuranceBinder:Hurricane',
  'PropertyInsuranceBinder:InsectInfestation' = 'PropertyInsuranceBinder:InsectInfestation',
  'PropertyInsuranceBinder:Leasehold' = 'PropertyInsuranceBinder:Leasehold',
  'PropertyInsuranceBinder:MineSubsidence' = 'PropertyInsuranceBinder:MineSubsidence',
  'PropertyInsuranceBinder:PersonalProperty' = 'PropertyInsuranceBinder:PersonalProperty',
  'PropertyInsuranceBinder:Storm' = 'PropertyInsuranceBinder:Storm',
  'PropertyInsuranceBinder:Tornado' = 'PropertyInsuranceBinder:Tornado',
  'PropertyInsuranceBinder:Volcano' = 'PropertyInsuranceBinder:Volcano',
  'PropertyInsuranceBinder:Wind' = 'PropertyInsuranceBinder:Wind',
  PropertyInsuranceDeclarations = 'PropertyInsuranceDeclarations',
  'PropertyInsuranceDeclarations:Earthquake' = 'PropertyInsuranceDeclarations:Earthquake',
  'PropertyInsuranceDeclarations:FireAndExtendedCoverage' = 'PropertyInsuranceDeclarations:FireAndExtendedCoverage',
  'PropertyInsuranceDeclarations:Flood' = 'PropertyInsuranceDeclarations:Flood',
  'PropertyInsuranceDeclarations:Hazard' = 'PropertyInsuranceDeclarations:Hazard',
  'PropertyInsuranceDeclarations:Homeowners' = 'PropertyInsuranceDeclarations:Homeowners',
  'PropertyInsuranceDeclarations:Hurricane' = 'PropertyInsuranceDeclarations:Hurricane',
  'PropertyInsuranceDeclarations:InsectInfestation' = 'PropertyInsuranceDeclarations:InsectInfestation',
  'PropertyInsuranceDeclarations:Leasehold' = 'PropertyInsuranceDeclarations:Leasehold',
  'PropertyInsuranceDeclarations:MineSubsidence' = 'PropertyInsuranceDeclarations:MineSubsidence',
  'PropertyInsuranceDeclarations:PersonalProperty' = 'PropertyInsuranceDeclarations:PersonalProperty',
  'PropertyInsuranceDeclarations:Storm' = 'PropertyInsuranceDeclarations:Storm',
  'PropertyInsuranceDeclarations:Tornado' = 'PropertyInsuranceDeclarations:Tornado',
  'PropertyInsuranceDeclarations:Volcano' = 'PropertyInsuranceDeclarations:Volcano',
  'PropertyInsuranceDeclarations:Wind' = 'PropertyInsuranceDeclarations:Wind',
  PropertyInsurancePolicy = 'PropertyInsurancePolicy',
  'PropertyInsurancePolicy:Earthquake' = 'PropertyInsurancePolicy:Earthquake',
  'PropertyInsurancePolicy:FireAndExtendedCoverage' = 'PropertyInsurancePolicy:FireAndExtendedCoverage',
  'PropertyInsurancePolicy:Flood' = 'PropertyInsurancePolicy:Flood',
  'PropertyInsurancePolicy:Hazard' = 'PropertyInsurancePolicy:Hazard',
  'PropertyInsurancePolicy:Homeowners' = 'PropertyInsurancePolicy:Homeowners',
  'PropertyInsurancePolicy:Hurricane' = 'PropertyInsurancePolicy:Hurricane',
  'PropertyInsurancePolicy:InsectInfestation' = 'PropertyInsurancePolicy:InsectInfestation',
  'PropertyInsurancePolicy:Leasehold' = 'PropertyInsurancePolicy:Leasehold',
  'PropertyInsurancePolicy:MineSubsidence' = 'PropertyInsurancePolicy:MineSubsidence',
  'PropertyInsurancePolicy:PersonalProperty' = 'PropertyInsurancePolicy:PersonalProperty',
  'PropertyInsurancePolicy:Storm' = 'PropertyInsurancePolicy:Storm',
  'PropertyInsurancePolicy:Tornado' = 'PropertyInsurancePolicy:Tornado',
  'PropertyInsurancePolicy:Volcano' = 'PropertyInsurancePolicy:Volcano',
  'PropertyInsurancePolicy:Wind' = 'PropertyInsurancePolicy:Wind',
  PropertyInsuranceRequirementDisclosure = 'PropertyInsuranceRequirementDisclosure',
  PurchaseAgreement = 'PurchaseAgreement',
  RateChangeNotice = 'RateChangeNotice',
  RateLockAgreement = 'RateLockAgreement',
  'RateLockAgreement:BusinessToBusiness' = 'RateLockAgreement:BusinessToBusiness',
  'RateLockAgreement:BusinessToConsumer' = 'RateLockAgreement:BusinessToConsumer',
  ReaffirmationAgreement = 'ReaffirmationAgreement',
  Receipt = 'Receipt',
  RecertificationOfFamilyIncomeAndComposition = 'RecertificationOfFamilyIncomeAndComposition',
  RecertificationOfFamilyIncomeStatisticalReport = 'RecertificationOfFamilyIncomeStatisticalReport',
  Reconveyance = 'Reconveyance',
  RefinanceCostEstimateWorksheet = 'RefinanceCostEstimateWorksheet',
  'RefinanceCostEstimateWorksheet:HECM' = 'RefinanceCostEstimateWorksheet:HECM',
  RelocationBenefitsPackage = 'RelocationBenefitsPackage',
  RelocationBuyoutAgreement = 'RelocationBuyoutAgreement',
  RemittanceDeliveryReceipt = 'RemittanceDeliveryReceipt',
  RentalAgreement = 'RentalAgreement',
  RentalIncomeAnalysisStatement = 'RentalIncomeAnalysisStatement',
  RepaymentPlanAgreement = 'RepaymentPlanAgreement',
  RequestForAgreementOfShortSale = 'RequestForAgreementOfShortSale',
  RequestForCopyOfTaxReturn = 'RequestForCopyOfTaxReturn',
  'RequestForCopyOfTaxReturn:IRS4506' = 'RequestForCopyOfTaxReturn:IRS4506',
  'RequestForCopyOfTaxReturn:IRS4506T' = 'RequestForCopyOfTaxReturn:IRS4506T',
  'RequestForCopyOfTaxReturn:IRS4506TEZ' = 'RequestForCopyOfTaxReturn:IRS4506TEZ',
  RequestForNoticeOfDefault = 'RequestForNoticeOfDefault',
  ResidualIncomeAnalysisWorksheet = 'ResidualIncomeAnalysisWorksheet',
  ResolutionOfConsumerComplaint = 'ResolutionOfConsumerComplaint',
  RetirementAccountStatement = 'RetirementAccountStatement',
  RiskBasedPricingNotice = 'RiskBasedPricingNotice',
  'RiskBasedPricingNotice:AccountReviewNotice' = 'RiskBasedPricingNotice:AccountReviewNotice',
  'RiskBasedPricingNotice:CreditScoreDisclosureException' = 'RiskBasedPricingNotice:CreditScoreDisclosureException',
  'RiskBasedPricingNotice:CreditScoreDisclosureNoResidentialSecured' = 'RiskBasedPricingNotice:CreditScoreDisclosureNoResidentialSecured',
  'RiskBasedPricingNotice:CreditScoreDisclosureNoScore' = 'RiskBasedPricingNotice:CreditScoreDisclosureNoScore',
  'RiskBasedPricingNotice:GeneralNotice' = 'RiskBasedPricingNotice:GeneralNotice',
  RoadMaintenanceAgreement = 'RoadMaintenanceAgreement',
  SatisfactionOfJudgment = 'SatisfactionOfJudgment',
  SatisfactionOfSecurityInstrument = 'SatisfactionOfSecurityInstrument',
  'SatisfactionOfSecurityInstrument:LienRelease' = 'SatisfactionOfSecurityInstrument:LienRelease',
  'SatisfactionOfSecurityInstrument:SatisfactionOfDeedOfTrust' = 'SatisfactionOfSecurityInstrument:SatisfactionOfDeedOfTrust',
  'SatisfactionOfSecurityInstrument:SatisfactionOfMortgage' = 'SatisfactionOfSecurityInstrument:SatisfactionOfMortgage',
  SCRANoticeDisclosure = 'SCRANoticeDisclosure',
  Section32DisclosureNotice = 'Section32DisclosureNotice',
  SecurityInstrument = 'SecurityInstrument',
  'SecurityInstrument:DeedOfTrust' = 'SecurityInstrument:DeedOfTrust',
  'SecurityInstrument:Mortgage' = 'SecurityInstrument:Mortgage',
  SecurityInstrumentAddendum = 'SecurityInstrumentAddendum',
  'SecurityInstrumentAddendum:FixedRateOption' = 'SecurityInstrumentAddendum:FixedRateOption',
  SecurityInstrumentModification = 'SecurityInstrumentModification',
  'SecurityInstrumentModification:ConsolidationAgreement' = 'SecurityInstrumentModification:ConsolidationAgreement',
  'SecurityInstrumentModification:ConsolidationExtensionModificationAgreement' = 'SecurityInstrumentModification:ConsolidationExtensionModificationAgreement',
  'SecurityInstrumentModification:ModificationAgreement' = 'SecurityInstrumentModification:ModificationAgreement',
  SecurityInstrumentRider = 'SecurityInstrumentRider',
  'SecurityInstrumentRider:AffordableMeritRate' = 'SecurityInstrumentRider:AffordableMeritRate',
  'SecurityInstrumentRider:ARM' = 'SecurityInstrumentRider:ARM',
  'SecurityInstrumentRider:Balloon' = 'SecurityInstrumentRider:Balloon',
  'SecurityInstrumentRider:Beneficiary' = 'SecurityInstrumentRider:Beneficiary',
  'SecurityInstrumentRider:Biweekly' = 'SecurityInstrumentRider:Biweekly',
  'SecurityInstrumentRider:Condominium' = 'SecurityInstrumentRider:Condominium',
  'SecurityInstrumentRider:Construction' = 'SecurityInstrumentRider:Construction',
  'SecurityInstrumentRider:GEM' = 'SecurityInstrumentRider:GEM',
  'SecurityInstrumentRider:GPM' = 'SecurityInstrumentRider:GPM',
  'SecurityInstrumentRider:HomesteadExemption' = 'SecurityInstrumentRider:HomesteadExemption',
  'SecurityInstrumentRider:InterestOnly' = 'SecurityInstrumentRider:InterestOnly',
  'SecurityInstrumentRider:InterVivosRevocableTrust' = 'SecurityInstrumentRider:InterVivosRevocableTrust',
  'SecurityInstrumentRider:Investor' = 'SecurityInstrumentRider:Investor',
  'SecurityInstrumentRider:LandTrust' = 'SecurityInstrumentRider:LandTrust',
  'SecurityInstrumentRider:Leasehold' = 'SecurityInstrumentRider:Leasehold',
  'SecurityInstrumentRider:ManufacturedHousing' = 'SecurityInstrumentRider:ManufacturedHousing',
  'SecurityInstrumentRider:MERSRegistry' = 'SecurityInstrumentRider:MERSRegistry',
  'SecurityInstrumentRider:NonOwner' = 'SecurityInstrumentRider:NonOwner',
  'SecurityInstrumentRider:OneToFourFamily' = 'SecurityInstrumentRider:OneToFourFamily',
  'SecurityInstrumentRider:OwnerOccupancy' = 'SecurityInstrumentRider:OwnerOccupancy',
  'SecurityInstrumentRider:Prepayment' = 'SecurityInstrumentRider:Prepayment',
  'SecurityInstrumentRider:PUD' = 'SecurityInstrumentRider:PUD',
  'SecurityInstrumentRider:RateImprovement' = 'SecurityInstrumentRider:RateImprovement',
  'SecurityInstrumentRider:Rehabilitation' = 'SecurityInstrumentRider:Rehabilitation',
  'SecurityInstrumentRider:RenewalAndExtension' = 'SecurityInstrumentRider:RenewalAndExtension',
  'SecurityInstrumentRider:SecondHome' = 'SecurityInstrumentRider:SecondHome',
  'SecurityInstrumentRider:SecondLien' = 'SecurityInstrumentRider:SecondLien',
  'SecurityInstrumentRider:TaxExemptFinancing' = 'SecurityInstrumentRider:TaxExemptFinancing',
  'SecurityInstrumentRider:VA' = 'SecurityInstrumentRider:VA',
  'SecurityInstrumentRider:VeteransLandBoard' = 'SecurityInstrumentRider:VeteransLandBoard',
  'SecurityInstrumentRider:WaiverOfBorrowersRights' = 'SecurityInstrumentRider:WaiverOfBorrowersRights',
  'SecurityInstrumentRider:WaiverOfDowerRights' = 'SecurityInstrumentRider:WaiverOfDowerRights',
  SellerLeadHazardDisclosureAndAcknowledgment = 'SellerLeadHazardDisclosureAndAcknowledgment',
  ServicingDisclosureStatement = 'ServicingDisclosureStatement',
  ServicingTransferStatement = 'ServicingTransferStatement',
  SettlementStatement = 'SettlementStatement',
  'SettlementStatement:HUD1' = 'SettlementStatement:HUD1',
  'SettlementStatement:HUD1A' = 'SettlementStatement:HUD1A',
  ShortSaleAgreement = 'ShortSaleAgreement',
  ShortSaleProcessChecklist = 'ShortSaleProcessChecklist',
  SocialSecurityAwardLetter = 'SocialSecurityAwardLetter',
  SolicitationLetter = 'SolicitationLetter',
  StandardFloodHazardDetermination = 'StandardFloodHazardDetermination',
  StateIdentification = 'StateIdentification',
  StatementOfBorrowerBenefit = 'StatementOfBorrowerBenefit',
  StockCertificate = 'StockCertificate',
  SubordinationAgreement = 'SubordinationAgreement',
  SubsidyAgreement = 'SubsidyAgreement',
  SubstitutionOfTrustee = 'SubstitutionOfTrustee',
  Survey = 'Survey',
  SurveyAffidavit = 'SurveyAffidavit',
  TaxAssessmentValueIndication = 'TaxAssessmentValueIndication',
  TaxAuthorization = 'TaxAuthorization',
  TaxCertificate = 'TaxCertificate',
  TaxLien = 'TaxLien',
  'TaxLien:Federal' = 'TaxLien:Federal',
  'TaxLien:Local' = 'TaxLien:Local',
  'TaxLien:State' = 'TaxLien:State',
  TaxLienRelease = 'TaxLienRelease',
  'TaxLienRelease:Federal' = 'TaxLienRelease:Federal',
  'TaxLienRelease:Local' = 'TaxLienRelease:Local',
  'TaxLienRelease:State' = 'TaxLienRelease:State',
  TaxpayerIdentification = 'TaxpayerIdentification',
  'TaxpayerIdentification:NumeroDeIdentificacionTributaria' = 'TaxpayerIdentification:NumeroDeIdentificacionTributaria',
  TaxReturn = 'TaxReturn',
  TenYearWarranty = 'TenYearWarranty',
  ThirdPartyEmploymentStatement = 'ThirdPartyEmploymentStatement',
  TILDisclosure = 'TILDisclosure',
  TitleAbstract = 'TitleAbstract',
  TitleCommitment = 'TitleCommitment',
  TitleInsuranceEndorsement = 'TitleInsuranceEndorsement',
  TitleInsurancePolicy = 'TitleInsurancePolicy',
  TrialLoanModificationAgreement = 'TrialLoanModificationAgreement',
  TrialPeriodPlanNotice = 'TrialPeriodPlanNotice',
  TrustAgreement = 'TrustAgreement',
  UCC1Statement = 'UCC1Statement',
  UCC3Statement = 'UCC3Statement',
  UnderwritingConditions = 'UnderwritingConditions',
  'UnderwritingConditions:MIApplication' = 'UnderwritingConditions:MIApplication',
  UnderwritingTransmittal = 'UnderwritingTransmittal',
  UnusedEscrowFundsDistributionAuthorization = 'UnusedEscrowFundsDistributionAuthorization',
  UtilityBill = 'UtilityBill',
  VAAcknowledgmentOfNoInspection = 'VAAcknowledgmentOfNoInspection',
  VACertificateOfEligibility = 'VACertificateOfEligibility',
  VACertificateOfReasonableValue = 'VACertificateOfReasonableValue',
  VACollectionPolicyNotice = 'VACollectionPolicyNotice',
  VAForeclosureBidLetter = 'VAForeclosureBidLetter',
  VAFundingFeeNotice = 'VAFundingFeeNotice',
  VAInterestRateReductionRefinancingLoanWorksheet = 'VAInterestRateReductionRefinancingLoanWorksheet',
  VALoanAnalysis = 'VALoanAnalysis',
  VALoanSummarySheet = 'VALoanSummarySheet',
  ValuationUpdate = 'ValuationUpdate',
  VARateReductionRefinanceInformationAndAgreement = 'VARateReductionRefinanceInformationAndAgreement',
  VAReportAndCertificationOfLoanDisbursement = 'VAReportAndCertificationOfLoanDisbursement',
  VARequestForCertificationOfEligibilityForHomeLoanBenefit = 'VARequestForCertificationOfEligibilityForHomeLoanBenefit',
  VAVerificationOfBenefitRelatedIndebtedness = 'VAVerificationOfBenefitRelatedIndebtedness',
  VerificationOfAddress = 'VerificationOfAddress',
  VerificationOfCredit = 'VerificationOfCredit',
  VerificationOfDebt = 'VerificationOfDebt',
  VerificationOfDependentCare = 'VerificationOfDependentCare',
  VerificationOfDeposit = 'VerificationOfDeposit',
  VerificationOfEmployment = 'VerificationOfEmployment',
  VerificationOfMERSRegistry = 'VerificationOfMERSRegistry',
  VerificationOfMortgageOrRent = 'VerificationOfMortgageOrRent',
  VerificationOfSecurities = 'VerificationOfSecurities',
  VerificationOfSSN = 'VerificationOfSSN',
  Visa = 'Visa',
  VolunteerEscrowPrepaymentDesignation = 'VolunteerEscrowPrepaymentDesignation',
  VoterRegistration = 'VoterRegistration',
  WarrantyOfCompletionOfConstruction = 'WarrantyOfCompletionOfConstruction',
  WireInstructions = 'WireInstructions',
  WireTransferAuthorization = 'WireTransferAuthorization',
  WireTransferConfirmation = 'WireTransferConfirmation',
  Worksheet = 'Worksheet',
}

export type DocumentClass = MISMOEntity &
  Partial<{
    documentType: DocumentType;
    documentTypeOtherDescription: MISMOString;
    recordingJurisdictionDocumentCode: MISMOCode;
    sequenceNumber: MISMOSequenceNumber;
  }>;
