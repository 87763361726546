import { TradeRequest, TradeRequestQuote } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type RejectTradeRequestQuoteCounterMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeQuoteCounterId: TradeRequestQuote['id'];
};

const rejectTradeRequestQuoteCounter = (data: RejectTradeRequestQuoteCounterMutation) => {
  const { tradeQuoteCounterId, tradeRequestId } = data;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/counters/${tradeQuoteCounterId}/reject`);

  return postRequestWithAuth(url);
};

export const useRejectTradeRequestQuoteCounterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(rejectTradeRequestQuoteCounter, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_INBOUND_QUOTES);
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_OUTBOUND_QUOTES);
    },
  });
};
