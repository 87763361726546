import React from 'react';

import { makeApiUrl } from '../../utils/apiUtils';
import { getNormalizedCoordinates } from '../../utils/mapUtils';

const AQI_LAYER = 'AQI';

export const useAQILayer = (isLayerDisplayed: boolean, googleMap?: google.maps.Map) => {
  React.useEffect(() => {
    const hasAQILayer = googleMap?.overlayMapTypes.getAt(0)?.name === AQI_LAYER;

    const addAQILayer = () => {
      const imageLayer = new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const normalizedCoord = getNormalizedCoordinates(coord, zoom);
          return normalizedCoord
            ? makeApiUrl(`air-quality/tiles/${zoom}/${normalizedCoord.x}/${normalizedCoord.y}`)
            : '';
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 8,
        name: AQI_LAYER,
        opacity: 0.3,
      });

      googleMap?.overlayMapTypes.insertAt(0, imageLayer);
    };

    const removeAQILayer = () => {
      googleMap?.overlayMapTypes.removeAt(0);
    };

    if (isLayerDisplayed && !hasAQILayer) {
      addAQILayer();
    }

    if (!isLayerDisplayed && hasAQILayer) {
      removeAQILayer();
    }
  }, [isLayerDisplayed, googleMap]);
};
