import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  width: 350px;
  min-height: 200px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    ${theme.typography.headings.headingMedium03}
    position: sticky;
    top: 0;
    z-index: 1;
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto min-content;
    column-gap: 8px;
    width: 100%;
    padding: 12px;
    color: ${theme.colors.white};
    background: ${theme.colors.deprecatedPrimary01};
  `}
`;

export const Image = styled.img`
  width: calc(100% - 24px);
  height: auto;
  border-radius: 4px;
  margin: 0 12px 8px;
`;

export const Sections = styled.div`
  margin: 0 12px;
  padding-bottom: 12px;
`;
