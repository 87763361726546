import React from 'react';

export const PDFIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 33 32"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1679_21248)">
        <path
          d="M5.80822 0H27.0685C30.2466 0 32.8767 2.63014 32.8767 5.80822V26.1918C32.8767 29.4247 30.2466 32 27.0685 32H5.80822C2.63014 32.0548 0 29.4247 0 26.2466V5.80822C0 2.63014 2.63014 0 5.80822 0Z"
          fill="currentColor"
        />
        <path
          d="M26.4107 18.5755C25.7532 17.8631 24.4929 17.5344 22.6847 17.5344C21.6984 17.5344 20.6573 17.6439 19.671 17.8083C19.0134 17.2056 18.4655 16.4933 17.9175 15.7809C17.534 15.233 17.1504 14.685 16.8217 14.0823C17.3696 12.5481 17.6984 10.9042 17.808 9.26039C17.808 7.78094 17.2052 6.19189 15.5614 6.19189C15.0134 6.19189 14.4655 6.52066 14.1915 7.01381C13.4792 8.21929 13.7532 10.685 14.9039 13.2604C14.5203 14.4111 14.0819 15.5618 13.5888 16.822C13.1504 17.9179 12.6573 18.959 12.0545 19.9453C10.3559 20.6029 6.79426 22.2467 6.46549 24.0549C6.3559 24.6029 6.52029 25.1508 6.95864 25.4796C7.34221 25.8083 7.89015 26.0275 8.4381 25.9727C10.5751 25.9727 12.7121 23.0138 14.1915 20.4385C15.0134 20.1645 15.8354 19.8905 16.7121 19.6713C17.6436 19.4522 18.5203 19.233 19.3422 19.0686C21.6436 21.0412 23.671 21.37 24.6573 21.37C26.0271 21.37 26.5751 20.7672 26.7395 20.2741C26.9586 19.6713 26.8491 19.0138 26.4107 18.5755ZM24.9312 19.6166C24.8765 20.0001 24.4381 20.2741 23.8902 20.2741C23.7258 20.2741 23.5614 20.2741 23.397 20.2193C22.3011 19.9453 21.26 19.3974 20.3833 18.685C21.0956 18.5755 21.8628 18.5207 22.5751 18.5207C23.0682 18.5207 23.5614 18.5755 24.0545 18.6303C24.5477 18.7398 25.0956 18.959 24.9312 19.6166ZM15.123 7.50696C15.2326 7.34258 15.397 7.23299 15.6162 7.1782C16.1641 7.1782 16.2737 7.78094 16.2737 8.32888C16.2189 9.58915 15.9997 10.7946 15.6162 12.0001C14.6847 9.64395 14.7943 8.05491 15.123 7.50696ZM18.1915 18.0275C17.5888 18.1371 16.986 18.3015 16.3833 18.4659C15.9449 18.5755 15.5066 18.7398 15.0134 18.8494C15.2326 18.3563 15.4518 17.8631 15.671 17.4248C15.9449 16.822 16.1641 16.2193 16.3833 15.6166C16.6025 15.9453 16.7669 16.2193 16.986 16.4933C17.3696 17.0412 17.808 17.5344 18.1915 18.0275ZM11.5614 21.2056C10.1915 23.3974 8.82166 24.822 8.05453 24.822C7.94495 24.822 7.78056 24.7672 7.72577 24.7124C7.56138 24.6029 7.50659 24.3837 7.56138 24.2193C7.67097 23.3974 9.26001 22.2467 11.5614 21.2056Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1679_21248">
          <rect fill="white" height="32" width="32.8767" />
        </clipPath>
      </defs>
    </svg>
  );
};
