import { MISMOEntity, MISMOSequenceNumber, MISMOString } from '../types';

enum InfluenceImpactType {
  Adverse = 'Adverse',
  Beneficial = 'Beneficial',
  Neutral = 'Neutral',
}

enum SiteViewType {
  CityStreet = 'CityStreet',
  GolfCourse = 'GolfCourse',
  Industrial = 'Industrial',
  LimitedSight = 'LimitedSight',
  MountainView = 'MountainView',
  Other = 'Other',
  Park = 'Park',
  Pastoral = 'Pastoral',
  PowerLines = 'PowerLines',
  Residential = 'Residential',
  Skyline = 'Skyline',
  Traffic = 'Traffic',
  WaterView = 'WaterView',
  Woods = 'Woods',
}

export type SiteView = MISMOEntity &
  Partial<{
    influenceImpactType: InfluenceImpactType;
    sequenceNumber: MISMOSequenceNumber;
    siteViewDescription: MISMOString;
    siteViewType: SiteViewType;
    siteViewTypeOtherDescription: MISMOString;
  }>;
