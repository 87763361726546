import { AttributeType, Identifier } from './global';
import { Organization, OrganizationIdentifier } from './organizations';
import { PermissionType } from './permissions';
import { Role } from './roles';

export type UserIdentifier = number;

export type UserCreation = {
  email: string;
  roleId: string;
  firstName: string;
  lastName: string;
  password: string;
  avatarUrl?: string;
};

export type User = Pick<UserCreation, 'firstName' | 'lastName' | 'email' | 'avatarUrl'> & {
  id: UserIdentifier;
  organizationId: OrganizationIdentifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedUser = User & {
  organization: Organization;
};

export type UserInvitation = {
  id: UserIdentifier;
  email: string;
  roleId: Role['id'];
  organizationId: OrganizationIdentifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type UserSettings = {
  id: Identifier;
  userId: User['id'];
  settings: JSON;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AnalyticsUserSettings = {
  id: Identifier;
  analyticId: Identifier;
  userId: User['id'];
  settings: JSON;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedUserInvitation = UserInvitation & {
  role: Role;
};

export enum UsersAttributeType {
  ATTRIBUTE_USERS_EMAIL = 'ATTRIBUTE_USERS_EMAIL',
  ATTRIBUTE_USERS_NAME = 'ATTRIBUTE_USERS_NAME',
}

export type UserPermissionHierarchy = Record<PermissionType | string, AttributeType[]>;

export enum UserInvitationTokenStatus {
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
}
