import { keyframes } from 'styled-components';

export const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const fadeFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const skeletonLoading = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
`;

export const bgSpin = keyframes`
  to {
    --border-angle: 1turn;
  }
`;
