import {
  NEW_TRADE_MAXIMUM_NUMBER_OF_STIPULATIONS,
  PoolWithTradeRequest,
  UserIdentifier,
} from '@plus-platform/shared';
import without from 'lodash/without';
import React from 'react';
import { DropzoneInputProps, useDropzone } from 'react-dropzone';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Button } from '../components/Button';
import { ButtonIcon } from '../components/ButtonIcon';
import { ButtonLoader } from '../components/ButtonLoader';
import { DocumentCard } from '../components/DocumentCard';
import { Heading } from '../components/Heading';
import { AddPeopleIcon, CloseLineIcon, StipulationsLineIcon } from '../components/icons';
import { FullWidthLoaderWrapper, Loader } from '../components/Loader';
import { Popup } from '../components/Popup';
import { RecipientCard } from '../components/RecipientCard';
import { UploadOverlay } from '../components/UploadOverlay';
import { useCreateTradeRequestMutation, usePoolQuery } from '../hooks/queries';
import { useTradingContactsQuery } from '../hooks/queries/useTradingContactsQuery';
import { UserProfile } from '../users/types';
import * as Styles from './CreateTradeRequestPopup.styles';
import { DistributionListDialog } from './DistributionListDialog';
import { TradeRequestLoansSummary } from './TradeRequestLoansSummary';

type DistributionSectionProps = {
  isLoadingTradingContacts: boolean;
  selectedContacts: UserProfile[];
  removeSelectedContact: (contact: UserProfile) => void;
  setIsDistributionListDialogDisplayed: (isDisplayed: boolean) => void;
};

const DistributionSection = ({
  isLoadingTradingContacts,
  removeSelectedContact,
  selectedContacts,
  setIsDistributionListDialogDisplayed,
}: DistributionSectionProps) => (
  <>
    <Styles.HeadingWrapper>
      <Heading $size="medium">Recipients</Heading>
    </Styles.HeadingWrapper>
    <Styles.Section>
      {isLoadingTradingContacts && (
        <FullWidthLoaderWrapper>
          <Loader />
        </FullWidthLoaderWrapper>
      )}

      {!isLoadingTradingContacts && (
        <>
          {selectedContacts.length > 0 ? (
            <Styles.TagList>
              {selectedContacts.map((contact) => (
                <RecipientCard
                  key={contact.id}
                  firstName={contact.firstName}
                  lastName={contact.lastName}
                  imgSrc={contact.avatarUrl}
                  organization={contact.organization?.tradingName}
                  callback={() => removeSelectedContact(contact)}
                />
              ))}
              <Button
                $size="large"
                $variant="outlined"
                onClick={() => setIsDistributionListDialogDisplayed(true)}
              >
                Add more
              </Button>
            </Styles.TagList>
          ) : (
            <Button
              $size="large"
              onClick={() => setIsDistributionListDialogDisplayed(true)}
              startIcon={<AddPeopleIcon />}
            >
              Add recipients
            </Button>
          )}
        </>
      )}
    </Styles.Section>
  </>
);

type StipulationsSectionProps = {
  canAcceptMoreStipulations: boolean;
  stipulations: File[];
  onRemoveStipulation: (stipulation: File) => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
};

const StipulationsSection = ({
  canAcceptMoreStipulations,
  getInputProps,
  onRemoveStipulation,
  open,
  stipulations,
}: StipulationsSectionProps) => (
  <>
    <Styles.HeadingWrapper>
      <Heading $size="medium">Stipulations</Heading>
      <Styles.BodyText $size="small" $lineHeight="tight">
        Browse or drag and drop your files
      </Styles.BodyText>
    </Styles.HeadingWrapper>
    <Styles.Section>
      {stipulations.length > 0 ? (
        <Styles.TagList>
          {stipulations.map((stipulation, index) => (
            <DocumentCard
              key={index}
              documentName={stipulation.name}
              onRemove={() => onRemoveStipulation(stipulation)}
            />
          ))}
          <Button
            $size="large"
            $variant="outlined"
            disabled={!canAcceptMoreStipulations}
            onClick={open}
          >
            Add more
          </Button>
        </Styles.TagList>
      ) : (
        <Button
          $size="large"
          disabled={!canAcceptMoreStipulations}
          onClick={open}
          startIcon={<StipulationsLineIcon />}
        >
          Add stipulations
        </Button>
      )}
      <input {...getInputProps()} />
    </Styles.Section>
  </>
);

type CreateTradeRequestPopupProps = {
  isPoolLoading: boolean;
  pool?: PoolWithTradeRequest;
  onCancel: () => void;
  onSubmit: () => void;
};

export const CreateTradeRequestPopup = ({
  isPoolLoading,
  onCancel,
  onSubmit,
  pool,
}: CreateTradeRequestPopupProps) => {
  const [selectedContactIds, setSelectedContactIds] = React.useState<UserIdentifier[]>([]);
  const [isDistributionListDialogDisplayed, setIsDistributionListDialogDisplayed] =
    React.useState(false);
  const [stipulations, setStipulations] = React.useState<File[]>([]);

  const { isLoading: isNewTradeRequestCreating, mutateAsync: createTradeRequest } =
    useCreateTradeRequestMutation();

  const { data: tradingContacts = [], isLoading: isLoadingTradingContacts } =
    useTradingContactsQuery();

  const { data: poolData, isLoading: isLoadingPoolData } = usePoolQuery(pool?.id);

  const isLoading =
    isPoolLoading || isNewTradeRequestCreating || isLoadingTradingContacts || isLoadingPoolData;

  const isPoolAlreadyTraded = Boolean(pool?.tradeRequest);
  const doesPoolHaveLoans = Boolean(pool?.loanIds?.length);
  const hasSelectedContacts = Boolean(selectedContactIds.length);
  const canAcceptMoreStipulations = stipulations.length < NEW_TRADE_MAXIMUM_NUMBER_OF_STIPULATIONS;

  const selectedContacts = tradingContacts.filter((contact) => {
    return selectedContactIds.includes(contact.id);
  });

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    noClick: true,
    accept: 'application/pdf',
    disabled: isLoading,
    onDropAccepted: (acceptedStipulations) => {
      setStipulations((stipulations) =>
        [...stipulations, ...acceptedStipulations].slice(
          0,
          NEW_TRADE_MAXIMUM_NUMBER_OF_STIPULATIONS
        )
      );
    },
  });

  async function handleSubmit() {
    if (hasSelectedContacts && pool) {
      try {
        await createTradeRequest({
          poolId: String(pool.id),
          counterparties: selectedContactIds,
          stipulations,
        });

        await onSubmit();
      } catch (error) {
        // TODO: display error message once error states are defined
      }
    }
  }

  function closeDistributionListDialog() {
    setIsDistributionListDialogDisplayed(false);
  }

  function removeSelectedContact(contact: UserProfile) {
    setSelectedContactIds((ids) => without(ids, contact.id));
  }

  function removeStipulation(stipulation: File) {
    setStipulations((stipulations) => without(stipulations, stipulation));
  }

  return (
    <>
      <Popup width="1120px" minHeight="518px">
        <Styles.PopupTitleWrapper>
          <Heading $size="large">Trade</Heading>
          <ButtonIcon onClick={onCancel} size="medium">
            <CloseLineIcon />
          </ButtonIcon>
        </Styles.PopupTitleWrapper>

        <Styles.ContentWrapper>
          <Styles.Content>
            <div {...getRootProps()}>
              <ActivityIndicator contain isActive={isLoading}>
                {(isPoolAlreadyTraded || !doesPoolHaveLoans) && (
                  <Styles.ErrorWrapper>
                    <Styles.ErrorMessage>
                      {isPoolAlreadyTraded &&
                        'The selected pool is already assigned to a Trade Request. '}
                      {!doesPoolHaveLoans &&
                        'The selected pool does not have any loans assigned to it.'}
                    </Styles.ErrorMessage>
                  </Styles.ErrorWrapper>
                )}

                {!isPoolAlreadyTraded && doesPoolHaveLoans && (
                  <>
                    <Styles.Section>
                      <TradeRequestLoansSummary summary={poolData?.summary} />
                    </Styles.Section>

                    <DistributionSection
                      isLoadingTradingContacts={isLoadingTradingContacts}
                      selectedContacts={selectedContacts}
                      removeSelectedContact={removeSelectedContact}
                      setIsDistributionListDialogDisplayed={setIsDistributionListDialogDisplayed}
                    />
                    <StipulationsSection
                      canAcceptMoreStipulations={canAcceptMoreStipulations}
                      stipulations={stipulations}
                      onRemoveStipulation={removeStipulation}
                      getInputProps={getInputProps}
                      open={open}
                    />
                  </>
                )}
              </ActivityIndicator>
              <UploadOverlay isVisible={isDragActive} />
            </div>
          </Styles.Content>
          <Styles.ButtonWrapper>
            <Button
              $color="secondary"
              $variant="outlined"
              disabled={isLoading}
              onClick={onCancel}
              $size="large"
            >
              Cancel
            </Button>
            <ButtonLoader
              disabled={isPoolAlreadyTraded || !hasSelectedContacts}
              isLoading={isLoading}
              onClick={handleSubmit}
              $size="large"
            >
              Send
            </ButtonLoader>
          </Styles.ButtonWrapper>
        </Styles.ContentWrapper>
      </Popup>
      {isDistributionListDialogDisplayed && (
        <DistributionListDialog
          onCancel={closeDistributionListDialog}
          onContactsSelect={(contacts) => {
            setSelectedContactIds(contacts);
            closeDistributionListDialog();
          }}
          selectedContacts={selectedContactIds}
        />
      )}
    </>
  );
};
