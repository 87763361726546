import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: () => void,
  delay: number | null,
  shouldFireImmediately?: boolean
) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      return savedCallback.current();
    };

    if (delay !== null) {
      if (shouldFireImmediately) {
        tick();
      }

      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, shouldFireImmediately]);
};
