import { ALL_LOANS_ATTRIBUTES, Loan, PermissionType } from '@plus-platform/shared';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../components/Page';
import { PrivateRoute } from '../global/PrivateRoute';
import { LoanBreadcrumb } from './LoanBreadcrumb';
import { SUMMARY_MAP_PATH } from './LoanBreadcrumbSummary';
import { LoanSummaryMap } from './LoanSummaryMap';
import { LoanSummaryMetaInfo } from './LoanSummaryMetaInfo';
import { LoanSummaryWidgets } from './LoanSummaryWidgets';
import { ServicerChat } from './ServicerChat';
import { ExtraDataEnum } from './types';

type LoanSummaryPagePropTypes = {
  loanSummary: Loan;
};

export const LoanSummaryPage = ({ loanSummary }: LoanSummaryPagePropTypes) => {
  const [extraDataToDisplay, setExtraDataToDisplay] = React.useState<ExtraDataEnum | undefined>(
    undefined
  );
  const [isServicerChatOpened, setIsServicerChatOpened] = React.useState(false);

  const loanNumber = loanSummary.loanDetails.loanNumber.toUpperCase();
  const loanTitle = `Loan ID ${loanNumber || ''}`;

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>{loanTitle}</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem linkTo="/portfolio" title="Portfolio" />
              <BreadcrumbItem title={loanTitle} />
            </Breadcrumb>
          </div>
          <LoanSummaryMetaInfo loan={loanSummary} />
        </PageHeaderContent>
        <LoanBreadcrumb loan={loanSummary} />
      </PageHeader>
      <PageContent>
        {isServicerChatOpened && (
          <ServicerChat
            loanId={loanSummary.id}
            loanNumber={loanNumber}
            onClose={() => setIsServicerChatOpened(false)}
          />
        )}
        <Switch>
          <Redirect exact from="/loan/:loanNumber" to="/loan/:loanNumber/summary" />
          <PrivateRoute
            exact
            path="/loan/:loanNumber/summary"
            render={() => (
              <LoanSummaryWidgets
                isServicerChatOpened={isServicerChatOpened}
                loanSummary={loanSummary}
                onChatButtonClick={() => setIsServicerChatOpened(true)}
              />
            )}
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
            }}
          />
          <PrivateRoute
            exact
            path={`/loan/:loanNumber/${SUMMARY_MAP_PATH}`}
            render={() => (
              <LoanSummaryMap
                extraDataToDisplay={extraDataToDisplay}
                loanSummary={loanSummary}
                onDetailsButtonClick={(data) => {
                  setExtraDataToDisplay(data);
                }}
                onMLSButtonClick={(data) => {
                  setExtraDataToDisplay(data);
                }}
                onSidebarClose={() => setExtraDataToDisplay(undefined)}
              />
            )}
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
            }}
          />
        </Switch>
      </PageContent>
    </Page>
  );
};
