import { formatNumber } from '../../utils/formatUtils';
import {
  Analytic,
  AnalyticContainer,
  AnalyticCount,
  AnalyticHeader,
  AnalyticTitle,
} from './Analytic';
import { DelinquencyFinancials, DelinquencyFinancialsProps } from './DelinquencyFinancials';

type DelinquencyTotalMetricsProps = DelinquencyFinancialsProps & {
  loanCount?: number;
};

export const DelinquencyTotalMetrics = (props: DelinquencyTotalMetricsProps) => {
  const { loanCount, ...financials } = props;

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Loans in default</AnalyticTitle>
          <AnalyticCount>{formatNumber(loanCount)}</AnalyticCount>
        </AnalyticHeader>

        <DelinquencyFinancials {...financials} />
      </AnalyticContainer>
    </Analytic>
  );
};
