import { Divider } from './Divider';
import * as Styles from './TableToolbar.styles';

type TableToolbarProps = {
  children: React.ReactNode;
};

export const TableToolbar = ({ children }: TableToolbarProps) => {
  return (
    <Styles.Toolbar>
      <Styles.Content>{children}</Styles.Content>
      <Divider />
    </Styles.Toolbar>
  );
};
