import {
  InboundTradeCounterpartyActionWithSummary,
  InboundTradeRequestWithSummary,
} from '@plus-platform/shared';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type InboundDataRequestAcceptedProps = {
  action: InboundTradeCounterpartyActionWithSummary;
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundDataRequestAccepted = ({
  action,
  tradeRequest,
}: InboundDataRequestAcceptedProps) => {
  const history = useHistory();
  return (
    <PlusMessage date={action.createdAt}>
      <MessageText>
        <TradeMessage
          id="Trade.Inbound.DataRequest.Accepted"
          values={{
            userName: formatNameWithInitial(
              tradeRequest.user.lastName,
              tradeRequest.user.firstName
            ),
          }}
        />
      </MessageText>
      <MessageActions>
        <Button $size="large" onClick={() => history.push('data-view')}>
          View data
        </Button>
      </MessageActions>
    </PlusMessage>
  );
};
