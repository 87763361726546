import {
  DocumentCategoryType,
  GetPortfolioLoansDocumentSummaryItemDocument,
} from '@plus-platform/shared';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Card } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import { CloseLineIcon } from '../../../components/icons';
import { Loader } from '../../../components/Loader';
import { PageSectionSubtitle, PageSectionTitleWrapper } from '../../../components/Page';
import { SearchBar } from '../../../components/SearchBar';
import { ButtonToggle } from '../../../components/Toggles/ButtonToggle';
import { ToggleGroup } from '../../../components/Toggles/ToggleGroup';
import { ActionButton, ActionButtons } from '../../../components/ViewActions';
import {
  getClosingDocuments,
  getCreditDocuments,
  getDisclosureDocuments,
  getDocumentsByCategory,
} from '../../../documents/documentUtils';
import { DocumentViewer } from '../../../documents/submissions/DocumentViewer';
import { useLoanContext } from '../../context';
import { LoanDocumentsData, useLoanDocuments } from '../../LoanData';
import { LoanDocumentListsDocument, LoanDocumentsLists } from '../../LoanDocumentsLists';
import * as Styles from './LoanDetailDocuments.styles';

type DocumentsLedgerProps = {
  documentCategory: DocumentCategoryType;
  onSelectedDocumentChange?: (selectedDocumentId: string | undefined) => void;
};

const DocumentsLedger = ({ documentCategory, onSelectedDocumentChange }: DocumentsLedgerProps) => {
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<string | undefined>(undefined);
  const [selectedPageId, setSelectedPageId] = React.useState<number | undefined>(undefined);

  const { isAnonymised } = useLoanContext();
  const { data: loanDocuments, isLoading } = useLoanDocuments();

  useEffect(() => {
    onSelectedDocumentChange?.(selectedDocumentId);
  }, [selectedDocumentId]);

  const filteredDocuments = getDocumentsByCategory(
    (loanDocuments || []).flatMap((loanDocument) => loanDocument.document),
    documentCategory
  );

  // Temporarily casting to unknown to avoid type errors
  // GetPortfolioLoansDocumentSummaryItemDocument shares the same shape as the old
  // AgreementDocument type, but we'll want to define our own type for this component
  const closingDocuments = getClosingDocuments(
    filteredDocuments
  ) as unknown as GetPortfolioLoansDocumentSummaryItemDocument[];
  const creditDocuments = getCreditDocuments(
    filteredDocuments
  ) as unknown as GetPortfolioLoansDocumentSummaryItemDocument[];
  const disclosureDocuments = getDisclosureDocuments(
    filteredDocuments
  ) as unknown as GetPortfolioLoansDocumentSummaryItemDocument[];

  const selectedDocument = (filteredDocuments || []).find(
    ({ id, processorId }) =>
      String(processorId) === selectedDocumentId || String(id) === selectedDocumentId
  );

  const handleDocumentClick = (document: LoanDocumentListsDocument) => {
    if (isAnonymised) {
      return;
    }

    const documentId =
      document?.submissionId || document?.processorId ? document.processorId : document.id;
    if (documentId !== undefined) {
      setSelectedDocumentId(String(documentId));
    }
  };

  return (
    <Styles.Content>
      <ActivityIndicator isActive={isLoading}>
        {selectedDocumentId && (
          <Styles.DocumentModal>
            <PageSectionTitleWrapper>
              <PageSectionSubtitle>
                Viewing document: {selectedDocument?.documentTitle}
              </PageSectionSubtitle>
              <ActionButtons>
                <ActionButton>
                  <CloseLineIcon
                    onClick={() => {
                      setSelectedPageId(undefined);
                      setSelectedDocumentId(undefined);
                    }}
                  />
                </ActionButton>
              </ActionButtons>
            </PageSectionTitleWrapper>

            <DocumentViewer
              documentId={selectedDocumentId}
              documentName={selectedDocument?.documentPath}
              onPageClick={(pageId) => setSelectedPageId(pageId)}
              selectedPageId={selectedPageId}
            />
          </Styles.DocumentModal>
        )}
        {/* TODO: we should get the document status once implemented to check whether it contains warnings or missing data during ingestion phase */}
        {!selectedDocumentId && (
          <LoanDocumentsLists
            closingDocuments={closingDocuments}
            creditDocuments={creditDocuments}
            disclosureDocuments={disclosureDocuments}
            documentCategory={documentCategory}
            onDocumentClick={handleDocumentClick}
          />
        )}
      </ActivityIndicator>
    </Styles.Content>
  );
};

type LoanDetailDocumentsProps = {
  documentSearchQuery: string;
  setDocumentSearchQuery: (query: string) => void;
  documentCategory: DocumentCategoryType;
  setDocumentCategory: (v: DocumentCategoryType) => void;
  isSearchBarDisplayed: boolean;
  setIsSearchBarDisplayed: (v: boolean) => void;
};

const LoanDetailDocuments = React.forwardRef<HTMLDivElement, LoanDetailDocumentsProps>(
  (
    {
      documentCategory,
      documentSearchQuery,
      isSearchBarDisplayed,
      setDocumentCategory,
      setDocumentSearchQuery,
      setIsSearchBarDisplayed,
    },
    ref
  ) => {
    const handleDocumentSearchChange = React.useMemo(
      () => debounce((event) => setDocumentSearchQuery(event.target.value), 500),
      [setDocumentSearchQuery]
    );

    return (
      <Card $hasPadding id="document-library" ref={ref} data-testid="LoanDetail_DocumentLibrary">
        <Heading $size="medium">Document library</Heading>
        <Styles.ControlsWrapper>
          <ToggleGroup
            size="large"
            variant="spaced"
            value={documentCategory as string}
            onChange={(e, v) => setDocumentCategory(v as DocumentCategoryType)}
          >
            <ButtonToggle value={DocumentCategoryType.ORIGINATION_DOCUMENT as string}>
              Origination Documents
            </ButtonToggle>
            <ButtonToggle value={DocumentCategoryType.SERVICER_DOCUMENT as string}>
              Servicer Documents
            </ButtonToggle>
            <ButtonToggle value={DocumentCategoryType.TRANSACTION_DOCUMENT as string}>
              Transaction Documents
            </ButtonToggle>
          </ToggleGroup>

          <Styles.SearchWrapper hidden={!isSearchBarDisplayed}>
            <SearchBar
              $lightBackground
              onChange={handleDocumentSearchChange}
              placeholder="Search"
            />
          </Styles.SearchWrapper>
        </Styles.ControlsWrapper>
        <React.Suspense fallback={<Loader />}>
          <LoanDocumentsData searchQuery={documentSearchQuery}>
            <DocumentsLedger
              documentCategory={documentCategory}
              onSelectedDocumentChange={(selectedDocumentId) =>
                setIsSearchBarDisplayed(!selectedDocumentId)
              }
            />
          </LoanDocumentsData>
        </React.Suspense>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailDocuments;
