import styled, { css } from 'styled-components/macro';

export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0 16px;
`;

type DiskProps = {
  $hasSrc: boolean;
};

export const Disk = styled.div<DiskProps>(
  ({ $hasSrc, theme }) => css`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${$hasSrc ? 'transparent' : theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
`;

export const Initials = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02}
    color: ${theme.colors.background01};
  `
);

export const Username = styled.h2(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium03}
    color: ${theme.colors.textPrimary01}
  `
);

export const Organization = styled.h2(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}
    color: ${theme.colors.textPrimary02};
  `
);

export const Email = styled.p(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}
    color: ${theme.colors.textPrimary03};
    margin: 0;
  `
);
