import { Loan } from '@plus-platform/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const useLoanWidgetsRefs = (loan?: Loan) => {
  const location = useLocation();

  const mapRef = React.useRef<HTMLDivElement>(null);
  const financialsRef = React.useRef<HTMLDivElement>(null);
  const propertyRef = React.useRef<HTMLDivElement>(null);
  const originationRef = React.useRef<HTMLDivElement>(null);
  const borrowerRef = React.useRef<HTMLDivElement>(null);
  const servicerRef = React.useRef<HTMLDivElement>(null);
  const insuranceRef = React.useRef<HTMLDivElement>(null);
  const acquisitionLibraryRef = React.useRef<HTMLDivElement>(null);
  const documentLibraryRef = React.useRef<HTMLDivElement>(null);
  const assetLedgerRef = React.useRef<HTMLDivElement>(null);
  const eventLogRef = React.useRef<HTMLDivElement>(null);
  const amortizationRef = React.useRef<HTMLDivElement>(null);
  const notesRef = React.useRef<HTMLDivElement>(null);
  const custodialRef = React.useRef<HTMLDivElement>(null);

  const refs: Record<string, React.MutableRefObject<HTMLDivElement | null>> = React.useMemo(
    () => ({
      map: mapRef,
      financials: financialsRef,
      property: propertyRef,
      origination: originationRef,
      borrower: borrowerRef,
      servicer: servicerRef,
      insurance: insuranceRef,
      'acquisition-history': acquisitionLibraryRef,
      'document-library': documentLibraryRef,
      'asset-ledger': assetLedgerRef,
      'event-log': eventLogRef,
      amortization: amortizationRef,
      notes: notesRef,
      custodial: custodialRef,
    }),
    []
  );

  React.useEffect(() => {
    if (!loan) {
      return;
    }

    const target = (location?.hash || '').replace('#', '');
    const currentRef = refs[target];

    currentRef?.current?.scrollIntoView();
  }, [loan, refs, location]);

  return refs;
};
