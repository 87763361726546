import React from 'react';

import { Badge } from '../../components/Badge';
import { useNotificationsCountQuery } from '../../hooks/queries';

type TradeRequestTabNotificationsProps = {
  notificationTypes?: string[];
  tradeRequestId?: number;
  counterpartyId?: number;
  color?: string;
};

export const TradeRequestTabNotifications = (props: TradeRequestTabNotificationsProps) => {
  const { data: notificationCounts, isLoading } = useNotificationsCountQuery({
    types: props.notificationTypes,
    tradeRequestId: props.tradeRequestId,
    ...(props.counterpartyId && { counterpartyId: props.counterpartyId }),
  });

  if (isLoading || notificationCounts?.unreadCount === 0) {
    return null;
  }

  return (
    <Badge $color={props.color} $size={16}>
      {notificationCounts?.unreadCount}
    </Badge>
  );
};
