import styled from 'styled-components/macro';

export const Toolbar = styled.header``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 12px 12px 12px 16px;
`;
