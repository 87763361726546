import styled from 'styled-components';

type ChangeValueProps = {
  $isPositive: boolean;
};

export const ChangeValue = styled.span<ChangeValueProps>`
  color: ${({ $isPositive, theme }) =>
    $isPositive ? theme.colors.statusGreen : theme.colors.statusRed};
`;
