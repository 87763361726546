import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';
import { Verification } from './verification';

enum IncomeReportedType {
  PostTax = 'PostTax',
  PreTax = 'PreTax',
}

export enum IncomeType {
  AccessoryUnitIincome = 'AccessoryUnitIincome',
  Alimony = 'Alimony',
  AutomobileAllowance = 'AutomobileAllowance',
  Base = 'Base',
  BoarderIncome = 'BoarderIncome',
  Bonus = 'Bonus',
  BorrowerEstimatedTotalMonthlyIncome = 'BorrowerEstimatedTotalMonthlyIncome',
  CapitalGains = 'CapitalGains',
  ChildSupport = 'ChildSupport',
  Commissions = 'Commissions',
  ContractBasis = 'ContractBasis',
  DefinedContributionPlan = 'DefinedContributionPlan',
  Disability = 'Disability',
  DividendsInterest = 'DividendsInterest',
  EmploymentRelatedAccount = 'EmploymentRelatedAccount',
  FosterCare = 'FosterCare',
  HousingAllowance = 'HousingAllowance',
  HousingChoiceVoucherProgram = 'HousingChoiceVoucherProgram',
  MilitaryBasePay = 'MilitaryBasePay',
  MilitaryClothesAllowance = 'MilitaryClothesAllowance',
  MilitaryCombatPay = 'MilitaryCombatPay',
  MilitaryFlightPay = 'MilitaryFlightPay',
  MilitaryHazardPay = 'MilitaryHazardPay',
  MilitaryOverseasPay = 'MilitaryOverseasPay',
  MilitaryPropPay = 'MilitaryPropPay',
  MilitaryQuartersAllowance = 'MilitaryQuartersAllowance',
  MilitaryRationsAllowance = 'MilitaryRationsAllowance',
  MilitaryVariableHousingAllowance = 'MilitaryVariableHousingAllowance',
  MiscellaneousIncome = 'MiscellaneousIncome',
  MortgageCreditCertificate = 'MortgageCreditCertificate',
  MortgageDifferential = 'MortgageDifferential',
  NetRentalIncome = 'NetRentalIncome',
  NonBorrowerContribution = 'NonBorrowerContribution',
  NonBorrowerHouseholdIncome = 'NonBorrowerHouseholdIncome',
  NotesReceivableInstallment = 'NotesReceivableInstallment',
  Other = 'Other',
  Overtime = 'Overtime',
  Pension = 'Pension',
  ProposedGrossRentForSubjectProperty = 'ProposedGrossRentForSubjectProperty',
  PublicAssistance = 'PublicAssistance',
  RealEstateOwnedGrossRentalIncome = 'RealEstateOwnedGrossRentalIncome',
  Royalties = 'Royalties',
  SelfEmploymentIncome = 'SelfEmploymentIncome',
  SelfEmploymentLoss = 'SelfEmploymentLoss',
  SeparateMaintenance = 'SeparateMaintenance',
  SocialSecurity = 'SocialSecurity',
  SubjectPropertyNetCashFlow = 'SubjectPropertyNetCashFlow',
  TemporaryLeave = 'TemporaryLeave',
  TipIncome = 'TipIncome',
  TrailingCoBorrowerIncome = 'TrailingCoBorrowerIncome',
  Trust = 'Trust',
  Unemployment = 'Unemployment',
  VABenefitsNonEducational = 'VABenefitsNonEducational',
  WorkersCompensation = 'WorkersCompensation',
}

export type CurrentIncomeItem = MISMOEntity &
  Partial<{
    currentIncomeMonthlyTotalAmount: MISMOAmount;
    employmentIncomeIndicator: MISMOIndicator;
    foreignIncomeIndicator: MISMOIndicator;
    incomeFederalTaxExemptIndicator: MISMOIndicator;
    incomeType: IncomeType;
    incomeTypeOtherDescription: MISMOString;
    seasonalIncomeIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
    // incomeDocumentations: IncomeDocumentation[];
    verification: Verification;
  }>;

type CurrentIncomeItemSummary = MISMOEntity &
  Partial<{
    monthlyMilitaryEntitlementsAmount: MISMOAmount;
    monthlyRetirementAmount: MISMOAmount;
  }>;

export type CurrentIncome = MISMOEntity &
  Partial<{
    incomeReportedType: IncomeReportedType;
    URLABorrowerTotalMonthlyIncomeAmount: MISMOAmount;
    URLABorrowerTotalOtherIncomeAmount: MISMOAmount;
    currentIncomeItems: CurrentIncomeItem[];
    currentIncomeItemSummary: CurrentIncomeItemSummary;
  }>;
