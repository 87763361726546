import { Borrower, BorrowerVerifications } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanBorrowerVerificationsQuery = {
  tradeRequestId: string;
  loanId: string;
  borrowerId: Borrower['id'];
};

const getTradeRequestDataLoanBorrowerVerifications = (
  query: TradeRequestDataLoanBorrowerVerificationsQuery
) => {
  const { borrowerId, loanId, tradeRequestId } = query;
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/data/loans/${loanId}/borrowers/${borrowerId}/verifications`
  );

  return getRequestWithAuth<BorrowerVerifications>(url);
};

export const useTradeRequestDataLoanBorrowerVerificationsQuery = (
  query: TradeRequestDataLoanBorrowerVerificationsQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_BORROWER_VERIFICATIONS, query], () =>
    getTradeRequestDataLoanBorrowerVerifications(query)
  );
