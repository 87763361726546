import { FundsSourceType, FundsType } from './funds';
import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum DownPaymentOptionType {
  FivePercentOption = 'FivePercentOption',
  FNM97Option = 'FNM97Option',
  Other = 'Other',
  ThreeTwoOption = 'ThreeTwoOption',
}

export type DownPayment = MISMOEntity &
  Partial<{
    downPaymentAmount: MISMOAmount;
    downPaymentOptionType: DownPaymentOptionType;
    downPaymentOptionTypeOtherDescription: MISMOString;
    fundsSourceType: FundsSourceType;
    fundsSourceTypeOtherDescription: MISMOString;
    fundsType: FundsType;
    fundsTypeOtherDescription: MISMOString;
    propertySellerFundingIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
  }>;
