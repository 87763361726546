import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from '../types';

enum AVMIndexSourceType {
  CaseShiller = 'CaseShiller',
  FHFA = 'FHFA',
  NationalAssociationOfHomeBuilders = 'NationalAssociationOfHomeBuilders',
  NationalAssociationOfRealtors = 'NationalAssociationOfRealtors',
  Other = 'Other',
}

enum AVMIndexType {
  CoreLogic = 'CoreLogic',
  FederalHousingFinanceAdministration = 'FederalHousingFinanceAdministration',
  LenderProcessingServices = 'LenderProcessingServices',
  NationalAssociationRealtorsPendingHomeSale = 'NationalAssociationRealtorsPendingHomeSale',
  Other = 'Other',
  StandardsPoorCaseShiller = 'StandardsPoorCaseShiller',
}

enum AVMMethodType {
  Assisted = 'Assisted',
  HedonicModel = 'HedonicModel',
  Hybrid = 'Hybrid',
  Interactive = 'Interactive',
  Other = 'Other',
  RepeatSalesModel = 'RepeatSalesModel',
  Unknown = 'Unknown',
}

export enum AVMModelNameType {
  AutomatedPropertyService = 'AutomatedPropertyService',
  AVMax = 'AVMax',
  Casa = 'Casa',
  CAValue = 'CAValue',
  CollateralMarketValue = 'CollateralMarketValue',
  CollateralValuationModel = 'CollateralValuationModel',
  // NOTE: this exists in ULDD but not in MISMO
  FidelityHansen = 'FidelityHansen',
  // NOTE: this exists in MISMO but not in ULDD
  GEOCompVal = 'GEOCompVal',
  HomePriceAnalyzer = 'HomePriceAnalyzer',
  // NOTE: this exists in ULDD but not in MISMO
  HomePriceIndex = 'HomePriceIndex',
  HomeValueExplorer = 'HomeValueExplorer',
  // NOTE: this exists in MISMO but not in ULDD
  IntellirealAVM = 'IntellirealAVM',
  // NOTE: this exists in MISMO but not in ULDD
  IVal = 'IVal',
  // NOTE: this exists in ULDD but not in MISMO
  Indicator = 'Indicator',
  // NOTE: this exists in ULDD but not in MISMO
  NetValue = 'NetValue',
  Other = 'Other',
  Pass = 'Pass',
  // NOTE: this exists in MISMO but not in ULDD
  PowerBase6 = 'PowerBase6',
  // NOTE: this exists in ULDD but not in MISMO
  PropertySurveyAnalysisReport = 'PropertySurveyAnalysisReport',
  // NOTE: this exists in MISMO but not in ULDD
  RealAssessment = 'RealAssessment',
  // NOTE: this exists in MISMO but not in ULDD
  RealtorValuationModel = 'RealtorValuationModel',
  // NOTE: this exists in MISMO but not in ULDD
  Relar = 'Relar',
  // NOTE: this exists in MISMO but not in ULDD
  SiteXValue = 'SiteXValue',
  ValueFinder = 'ValueFinder',
  ValuePoint = 'ValuePoint',
  ValuePoint4 = 'ValuePoint4',
  ValuePointPlus = 'ValuePointPlus',
  // NOTE: this exists in MISMO but not in ULDD
  ValueSmart = 'ValueSmart',
  ValueSure = 'ValueSure',
  // NOTE: this exists in ULDD but not in MISMO
  ValueWizard = 'ValueWizard',
  // NOTE: this exists in ULDD but not in MISMO
  ValueWizardPlus = 'ValueWizardPlus',
  VeroIndexPlus = 'VeroIndexPlus',
  VeroValue = 'VeroValue',
  // NOTE: this exists in MISMO but not in ULDD
  VeroValueAdvantage = 'VeroValueAdvantage',
  // NOTE: this exists in MISMO but not in ULDD
  VeroValuePreferred = 'VeroValuePreferred',
}

enum AVMOutcomeType {
  IndicatedValue = 'IndicatedValue',
  NetValue = 'NetValue',
  Other = 'Other',
}

enum PropertyValuationStateType {
  Original = 'Original',
  Subsequent = 'Subsequent',
}

enum ValuationRequestCascadingReturnType {
  All = 'All',
  Other = 'Other',
  Single = 'Single',
}

export type AVM = MISMOEntity &
  Partial<{
    AVMCascadePreferenceIdentifier: MISMOIdentifier;
    AVMConfidenceLevelIdentifier: MISMOIdentifier;
    AVMConfidenceScoreIndicator: MISMOIndicator;
    AVMConfidenceScoreValue: MISMOValue;
    AVMDate: MISMODate;
    AVMHighValueRangeAmount: MISMOAmount;
    AVMIndexSourceType: AVMIndexSourceType;
    AVMIndexSourceTypeOtherDescription: MISMOString;
    AVMIndexType: AVMIndexType;
    AVMIndexTypeOtherDescription: MISMOString;
    AVMLowValueRangeAmount: MISMOAmount;
    AVMMethodType: AVMMethodType;
    AVMMethodTypeOtherDescription: MISMOString;
    AVMModelEffectiveDate: MISMODate;
    AVMModelNameType: AVMModelNameType;
    AVMModelNameTypeOtherDescription: MISMOString;
    AVMOutcomeType: AVMOutcomeType;
    AVMOutcomeTypeOtherDescription: MISMOString;
    AVMServiceProviderName: MISMOString;
    AVMValueAmount: MISMOAmount;
    forecastStandardDeviationScoreValue: MISMOValue;
    forecastStandardDeviationScoringModelName: MISMOString;
    propertyValuationStateType: PropertyValuationStateType;
    sequenceNumber: MISMOSequenceNumber;
    valuationRequestCascadeRuleIdentifier: MISMOIdentifier;
    valuationRequestCascadingReturnType: ValuationRequestCascadingReturnType;
    valuationRequestCascadingReturnTypeOtherDescription: MISMOString;
  }>;
