import styled, { css } from 'styled-components/macro';

import { Text } from './Analytics.styles';

const BUBBLE_TO_FONT_SIZE_RATIO = 3;

export const ChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 0;
  align-items: flex-end;
  justify-content: space-between;
`;

export const BubbleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-basis: calc(50% - 8px);
`;

export const Bubble = styled.div<{ size: number }>`
  ${({ size, theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size}px;
    background-color: ${theme.colors.statusTurquoise};
    color: ${theme.colors.white};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${Math.min(
      Math.max(size / BUBBLE_TO_FONT_SIZE_RATIO, theme.typography.fontSize.body04),
      theme.typography.fontSize.heading01
    )}px;
  `}
`;

export const ValueLabel = styled(Text)`
  ${({ theme }) => css`
    padding-top: 8px;
    color: ${theme.colors.white};
    ${theme.typography.bodyText.bodyMedium02Tight}
  `}
`;

export const LoansLabel = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize.body04}px;
    color: ${theme.colors.white70};
  `}
`;
