import styled, { css } from 'styled-components/macro';

import { Heading } from '../../components/Heading.styles';

export const Layout = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: grid;
    grid-template-columns: 221px 1fr;
    width: 100%;
    border-top: 1px solid ${theme.colors.primary03};
  `
);

export const Sidebar = styled.div(
  ({ theme }) => css`
    padding: 12px 0;
    border-right: 1px solid ${theme.colors.primary03};
  `
);

export const SidebarContent = styled.div`
  display: grid;
  height: fit-content;
  row-gap: 24px;
`;

export const SidebarItem = styled.div``;

export const SidebarHeading = styled(Heading).attrs((props) => ({
  ...props,
  as: 'h3',
  $size: 'small',
}))(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary03};
    margin-bottom: 8px;
  `
);

export const Content = styled.div`
  padding-left: 16px;
`;
