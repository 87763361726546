import React, { HTMLAttributes } from 'react';
import type { NavLinkProps } from 'react-router-dom';

import * as Styles from './Breadcrumb.styles';
import { Heading } from './Heading';
import { ChevronRightIcon } from './icons';

type BreadcrumbItemProps = Omit<NavLinkProps, 'to'> & {
  title: string;
  linkTo?: string;
};

export const BreadcrumbItem = ({ linkTo, title, ...rest }: BreadcrumbItemProps) =>
  linkTo ? (
    <>
      <Styles.BreadcrumbItem to={linkTo} {...rest}>
        {title}
      </Styles.BreadcrumbItem>
      <ChevronRightIcon height={16} width={16} />
    </>
  ) : (
    <Heading $size="small">{title}</Heading>
  );

type BreadcrumbProps = HTMLAttributes<HTMLDivElement>;

export const Breadcrumb = ({ children, ...rest }: BreadcrumbProps) => {
  return (
    <Styles.BreadcrumbWrapper {...rest}>
      <Styles.BreadcrumbItemsWrapper>{children}</Styles.BreadcrumbItemsWrapper>
    </Styles.BreadcrumbWrapper>
  );
};
