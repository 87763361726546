import {
  IntegratedDisclosureSectionType,
  IntegratedDisclosureSubsectionType,
} from './integratedDisclosure';
import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

enum ProrationItemType {
  BoroughPropertyTax = 'BoroughPropertyTax',
  CityPropertyTax = 'CityPropertyTax',
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  CountyPropertyTax = 'CountyPropertyTax',
  DistrictPropertyTax = 'DistrictPropertyTax',
  EarthquakeInsurancePremium = 'EarthquakeInsurancePremium',
  FloodInsurancePremium = 'FloodInsurancePremium',
  GroundRent = 'GroundRent',
  HailInsurancePremium = 'HailInsurancePremium',
  HazardInsurancePremium = 'HazardInsurancePremium',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeownersInsurancePremium = 'HomeownersInsurancePremium',
  InterestOnLoanAssumption = 'InterestOnLoanAssumption',
  MortgageInsurancePremium = 'MortgageInsurancePremium',
  Other = 'Other',
  OtherAssessment = 'OtherAssessment',
  PastDuePropertyTax = 'PastDuePropertyTax',
  RentFromSubjectProperty = 'RentFromSubjectProperty',
  SellersReserveAccountAssumption = 'SellersReserveAccountAssumption',
  SpecialAssessment = 'SpecialAssessment',
  StatePropertyTax = 'StatePropertyTax',
  TownPropertyTax = 'TownPropertyTax',
  Utilities = 'Utilities',
  VolcanoInsurancePremium = 'VolcanoInsurancePremium',
  WindAndStormInsurancePremium = 'WindAndStormInsurancePremium',
}

export type ProrationItem = MISMOEntity &
  Partial<{
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    integratedDisclosureSubsectionType: IntegratedDisclosureSubsectionType;
    prorationItemAmount: MISMOAmount;
    prorationItemPaidFromDate: MISMODate;
    prorationItemPaidThroughDate: MISMODate;
    prorationItemType: ProrationItemType;
    'prorationItemType@DisplayLabelText': MISMOString;
    prorationItemTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
