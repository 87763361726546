import {
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

export enum ApplicationTakenMethodType {
  Email = 'Email',
  FaceToFace = 'FaceToFace',
  Fax = 'Fax',
  Internet = 'Internet',
  Mail = 'Mail',
  Telephone = 'Telephone',
}

enum HMDADemographicCollectionVersionType {
  '2018Version' = '2018Version',
  Other = 'Other',
  Pre2018Version = 'Pre2018Version',
}

export enum HMDAGenderType {
  ApplicantSelectedBothMaleAndFemale = 'ApplicantSelectedBothMaleAndFemale',
  Female = 'Female',
  InformationNotProvidedUnknown = 'InformationNotProvidedUnknown',
  Male = 'Male',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
}

export enum HMDAEthnicityType {
  HispanicOrLatino = 'HispanicOrLatino',
  InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication = 'InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication',
  NotApplicable = 'NotApplicable',
  NotHispanicOrLatino = 'NotHispanicOrLatino',
  Other = 'Other',
}

export type HMDAEthnicity = MISMOEntity &
  Partial<{
    HMDAEthnicityType: HMDAEthnicityType;
    HMDAEthnicityTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export enum HMDAEthnicityOriginType {
  Cuban = 'Cuban',
  Mexican = 'Mexican',
  Other = 'Other',
  PuertoRican = 'PuertoRican',
}

type HMDAEthnicityOrigin = MISMOEntity &
  Partial<{
    HMDAEthnicityOriginType: HMDAEthnicityOriginType;
    HMDAEthnicityOriginTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export enum HMDARaceType {
  AmericanIndianOrAlaskaNative = 'AmericanIndianOrAlaskaNative',
  Asian = 'Asian',
  BlackOrAfricanAmerican = 'BlackOrAfricanAmerican',
  InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication = 'InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication',
  NativeHawaiianOrOtherPacificIslander = 'NativeHawaiianOrOtherPacificIslander',
  NotApplicable = 'NotApplicable',
  White = 'White',
}

export enum HMDARaceDesignationType {
  AsianIndian = 'AsianIndian',
  Chinese = 'Chinese',
  Filipino = 'Filipino',
  GuamanianOrChamorro = 'GuamanianOrChamorro',
  Japanese = 'Japanese',
  Korean = 'Korean',
  NativeHawaiian = 'NativeHawaiian',
  Other = 'Other',
  OtherAsian = 'OtherAsian',
  OtherPacificIslander = 'OtherPacificIslander',
  Samoan = 'Samoan',
  Vietnamese = 'Vietnamese',
}

type HMDARaceDesignation = MISMOEntity &
  Partial<{
    HMDARaceDesignationOtherAsianDescription: MISMOString;
    HMDARaceDesignationOtherPacificIslanderDescription: MISMOString;
    HMDARaceDesignationType: HMDARaceDesignationType;
    HMDARaceDesignationTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type HMDARace = MISMOEntity &
  Partial<{
    HMDARaceType: HMDARaceType;
    HMDARaceTypeAdditionalDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    HMDARaceDesignations: HMDARaceDesignation[];
  }>;

export type GovernmentMonitoring = MISMOEntity &
  Partial<{
    applicationTakenMethodType: ApplicationTakenMethodType;
    HMDADemographicCollectionDate: MISMODate;
    HMDADemographicCollectionVersionType: HMDADemographicCollectionVersionType;
    HMDADemographicCollectionVersionTypeOtherDescription: MISMOString;
    HMDAEthnicityCollectedBasedOnVisualObservationOrSurnameIndicator: MISMOIndicator;
    HMDAEthnicityRefusalIndicator: MISMOIndicator;
    HMDAGenderCollectedBasedOnVisualObservationOrNameIndicator: MISMOIndicator;
    HMDAGenderRefusalIndicator: MISMOIndicator;
    HMDAGenderType: HMDAGenderType;
    HMDAGenderTypeOtherDescription: MISMOString;
    HMDARaceCollectedBasedOnVisualObservationOrSurnameIndicator: MISMOIndicator;
    HMDARaceRefusalIndicator: MISMOIndicator;
    HMDARefusalIndicator: MISMOIndicator;
    raceNationalOriginRefusalIndicator: MISMOIndicator;
    HMDAEthnicities: HMDAEthnicity[];
    HMDAEthnicityOrigins: HMDAEthnicityOrigin[];
    HMDARaces: HMDARace[];
  }>;
