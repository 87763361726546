import { Coordinates, EarthquakeData, MapBounds } from '@plus-platform/shared';
import proj4 from 'proj4';

import gsRating1Icon from '../assets/gs-rating-1.svg';
import gsRating2Icon from '../assets/gs-rating-2.svg';
import gsRating3Icon from '../assets/gs-rating-3.svg';
import gsRating4Icon from '../assets/gs-rating-4.svg';
import gsRating5Icon from '../assets/gs-rating-5.svg';
import gsRating6Icon from '../assets/gs-rating-6.svg';
import gsRating7Icon from '../assets/gs-rating-7.svg';
import gsRating8Icon from '../assets/gs-rating-8.svg';
import gsRating9Icon from '../assets/gs-rating-9.svg';
import gsRating10Icon from '../assets/gs-rating-10.svg';
import gsRatingUnratedIcon from '../assets/gs-rating-unrated.svg';
import { statusOrange, statusRed, statusYellow, white } from '../styles/darkTheme/colors';
import { getRequestWithAuth, makeApiUrl } from './apiUtils';

const MAP_SCALE_FACTOR = 4;
export const DEFAULT_MAP_ZOOM_MIN_LEVEL = 4;
export const DEFAULT_MAP_ZOOM_LEVEL = 15;
export const DEFAULT_US_MAP_CENTER = {
  lat: 39.7837304,
  lng: -100.4458825,
};
export const DEFAULT_US_MAP_LAT_LNG_RESTRICTIONS = {
  north: 80,
  east: -20,
  south: 0,
  west: -170,
};

export const DEFAULT_INFO_WINDOW_WIDTH = 260;

export const NHC_ACTIVE_KML_URL = 'https://www.nhc.noaa.gov/gis/kml/nhc_active.kml';

const FEMA_DATA_MAP_SERVER_URL =
  'https://hazards.fema.gov/gis/nfhl/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export';
const WEATHER_DATA_MAP_SERVER_URL =
  'https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/natl_fcst_wx_chart/MapServer/export';

enum FEMA_LAYERS {
  NFHL_AVAILABILITY,
  LOMRS,
  LOMAS,
  FIRM_PANELS,
  BASE_INDEX,
  PLSS,
  TOPOLOGICAL_LOW_CONFIDENCE_AREAS,
  RIVER_MILE_MARKERS,
  DATUM_CONVERSION_POINTS,
  COASTAL_GAGES,
  GAGES,
  NODES,
  HIGH_WATER_MARKS,
  STATION_START_POINTS,
  CROSS_SECTIONS,
  COASTAL_TRANSECTS,
  BASE_FLOOD_ELEVATIONS,
  PROFILE_BASELINES,
  TRANSECT_BASELINES,
  LIMIT_OF_MODERATE_WAVE_ACTION,
  WATER_LINES,
  COASTAL_BARRIER_RESOURCES_SYSTEM_AREA,
  POLITICAL_JURISDICTIONS,
  LEVEES,
  GENERAL_STRUCTURES,
  PRIMARY_FRONTAL_DUNES,
  HYDROLOGIC_REACHES,
  FLOOD_HAZARD_BOUNDARIES,
  FLOOD_HAZARD_ZONES,
  SUBMITTAL_INFORMATION,
  ALLUVIAL_FANS,
  SUBBASINS,
  WATER_AREAS,
}

const FEMA_LAYERS_TO_DISPLAY = [
  FEMA_LAYERS.LOMAS,
  FEMA_LAYERS.BASE_INDEX,
  FEMA_LAYERS.PLSS,
  FEMA_LAYERS.RIVER_MILE_MARKERS,
  FEMA_LAYERS.DATUM_CONVERSION_POINTS,
  FEMA_LAYERS.COASTAL_GAGES,
  FEMA_LAYERS.HIGH_WATER_MARKS,
  FEMA_LAYERS.STATION_START_POINTS,
  FEMA_LAYERS.CROSS_SECTIONS,
  FEMA_LAYERS.COASTAL_TRANSECTS,
  FEMA_LAYERS.TRANSECT_BASELINES,
  FEMA_LAYERS.LIMIT_OF_MODERATE_WAVE_ACTION,
  FEMA_LAYERS.WATER_LINES,
  FEMA_LAYERS.COASTAL_BARRIER_RESOURCES_SYSTEM_AREA,
];

enum WEATHER_LAYERS {
  DAY_1,
  DAY_1_HIGH_AND_LOWS,
  DAY_1_FRONTS,
  DAY_1_RAIN_THUNDERSTORMS,
  DAY_1_RAIN,
  DAY_1_MIXED_PRECIPITATION,
  DAY_1_SNOW,
  DAY_1_SEVERE_THUNDERSTORMS_POSSIBLE,
  DAY_1_HEAVY_RAIN_FLASH_FLOODING_POSSIBLE,
  DAY_1_CRITICAL_FIRE_WEATHER_POSSIBLE,
  DAY_1_FREEZING_RAIN_POSSIBLE,
  DAY_1_HEAVY_SNOW_POSSIBLE,
  DAY_2,
  DAY_2_HIGH_AND_LOWS,
  DAY_2_FRONTS,
  DAY_2_RAIN_THUNDERSTORMS,
  DAY_2_RAIN,
  DAY_2_MIXED_PRECIPITATION,
  DAY_2_SNOW,
  DAY_2_SEVERE_THUNDERSTORMS_POSSIBLE,
  DAY_2_HEAVY_RAIN_FLASH_FLOODING_POSSIBLE,
  DAY_2_CRITICAL_FIRE_WEATHER_POSSIBLE,
  DAY_2_FREEZING_RAIN_POSSIBLE,
  DAY_2_HEAVY_SNOW_POSSIBLE,
  DAY_3,
  DAY_3_HIGH_AND_LOWS,
  DAY_3_FRONTS,
  DAY_3_RAIN_THUNDERSTORMS,
  DAY_3_RAIN,
  DAY_3_MIXED_PRECIPITATION,
  DAY_3_SNOW,
  DAY_3_SEVERE_THUNDERSTORMS_POSSIBLE,
  DAY_3_HEAVY_RAIN_FLASH_FLOODING_POSSIBLE,
  DAY_3_CRITICAL_FIRE_WEATHER_POSSIBLE,
  DAY_3_FREEZING_RAIN_POSSIBLE,
  DAY_3_HEAVY_SNOW_POSSIBLE,
}

const WEATHER_LAYERS_TO_DISPLAY = [WEATHER_LAYERS.DAY_1];

export const convertCoordinatesFromGeolocation = (geolocation: Coordinates) => {
  return {
    lat: geolocation.latitude,
    lng: geolocation.longitude,
  };
};

const convertFromEPSG4326ToEPSG3857 = (longitude: number, latitude: number) => {
  const coordinates = proj4('EPSG:4326', 'EPSG:3857', [longitude, latitude]);
  return {
    longitude: coordinates[0],
    latitude: coordinates[1],
  };
};

const getBoundingBoxParamsFromBounds = (bounds: google.maps.LatLngBounds) => {
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  const convertedSouthWest = convertFromEPSG4326ToEPSG3857(southWest.lng(), southWest.lat());
  const convertedNorthEast = convertFromEPSG4326ToEPSG3857(northEast.lng(), northEast.lat());

  return `${convertedSouthWest.longitude},${convertedSouthWest.latitude},${convertedNorthEast.longitude},${convertedNorthEast.latitude}`;
};

const getMapServerURL = (
  baseURL: string,
  layers: (number | string)[],
  googleMap?: google.maps.Map,
  bounds?: google.maps.LatLngBounds
) => {
  if (!googleMap || !bounds) {
    return undefined;
  }

  const divBoundingRect = googleMap.getDiv().getBoundingClientRect();
  const width = divBoundingRect.width;
  const height = divBoundingRect.height;

  const query = new URLSearchParams({
    dpi: '96',
    transparent: 'true',
    format: 'png32',
    f: 'image',
    layers: layers.join(','),
    bbox: getBoundingBoxParamsFromBounds(bounds),
    bboxSR: '102100',
    imageSR: '102100',
    size: `${Math.round(width)},${Math.round(height)}`,
  });

  const url = `${baseURL}?${query}`;

  return url;
};

export const getFEMADataURL = (googleMap?: google.maps.Map, bounds?: google.maps.LatLngBounds) => {
  return getMapServerURL(FEMA_DATA_MAP_SERVER_URL, FEMA_LAYERS_TO_DISPLAY, googleMap, bounds);
};

export const getWeatherDataURL = (
  googleMap?: google.maps.Map,
  bounds?: google.maps.LatLngBounds
) => {
  return getMapServerURL(WEATHER_DATA_MAP_SERVER_URL, WEATHER_LAYERS_TO_DISPLAY, googleMap, bounds);
};

export const normalizeEarthquakeMagnitude = (magnitude: number) => {
  return magnitude * MAP_SCALE_FACTOR;
};

export const getEarthquakeColorByMagnitude = (magnitude: number) => {
  if (magnitude <= 2.5) {
    return white;
  }

  if (magnitude <= 4.5) {
    return statusYellow;
  }

  if (magnitude < 6.5) {
    return statusOrange;
  }

  return statusRed;
};

const convertCoordinateToDMS = (coordinate: number) => {
  const degrees = Math.floor(Math.abs(coordinate));
  const minutes = (Math.abs(coordinate) - degrees) * 60;
  const minutesTruncated = Math.floor(minutes);
  const seconds = Math.floor((minutes - minutesTruncated) * 60);

  return degrees + "'" + minutesTruncated + '"' + seconds;
};

export const formatLatLongToDegrees = (latitude: number, longitude: number) => {
  const lat = convertCoordinateToDMS(latitude);
  const latCardinal = latitude >= 0 ? 'N' : 'S';

  const long = convertCoordinateToDMS(longitude);
  const longCardinal = longitude >= 0 ? 'E' : 'W';

  const formattedLatitude = `${lat} °${latCardinal}`;
  const formattedLongitude = `${long} °${longCardinal}`;

  return `${formattedLatitude} ${formattedLongitude}`;
};

export const getEarthquakes = (bounds: MapBounds) => {
  const params = new URLSearchParams({
    minLatitude: String(Math.min(bounds.minLatitude, bounds.maxLatitude)),
    minLongitude: String(Math.min(bounds.minLongitude, bounds.maxLongitude)),
    maxLatitude: String(Math.max(bounds.minLatitude, bounds.maxLatitude)),
    maxLongitude: String(Math.max(bounds.minLongitude, bounds.maxLongitude)),
  });

  return getRequestWithAuth<EarthquakeData>(makeApiUrl(`earthquakes?${params}`));
};

const GreatSchoolsRatingIconMappings: Record<string, string> = {
  '1': gsRating1Icon,
  '2': gsRating2Icon,
  '3': gsRating3Icon,
  '4': gsRating4Icon,
  '5': gsRating5Icon,
  '6': gsRating6Icon,
  '7': gsRating7Icon,
  '8': gsRating8Icon,
  '9': gsRating9Icon,
  '10': gsRating10Icon,
};

export const getGreatSchoolsRatingIcon = (rating: string | null) =>
  rating === null ? gsRatingUnratedIcon : GreatSchoolsRatingIconMappings[rating];

enum GreatSchoolsRatingLabel {
  BELOW_AVERAGE = 'Below Average',
  AVERAGE = 'Average',
  ABOVE_AVERAGE = 'Above Average',
  UNRATED = 'Currently Unrated',
}

const GreatSchoolsRatingLabelMappings: Record<string, GreatSchoolsRatingLabel> = {
  '1': GreatSchoolsRatingLabel.BELOW_AVERAGE,
  '2': GreatSchoolsRatingLabel.BELOW_AVERAGE,
  '3': GreatSchoolsRatingLabel.BELOW_AVERAGE,
  '4': GreatSchoolsRatingLabel.BELOW_AVERAGE,
  '5': GreatSchoolsRatingLabel.AVERAGE,
  '6': GreatSchoolsRatingLabel.AVERAGE,
  '7': GreatSchoolsRatingLabel.ABOVE_AVERAGE,
  '8': GreatSchoolsRatingLabel.ABOVE_AVERAGE,
  '9': GreatSchoolsRatingLabel.ABOVE_AVERAGE,
  '10': GreatSchoolsRatingLabel.ABOVE_AVERAGE,
};

export const getGreatSchoolsRatingLabel = (rating: string | null) =>
  rating === null ? GreatSchoolsRatingLabel.UNRATED : GreatSchoolsRatingLabelMappings[rating];

export const getHaversineDistance = (
  { latitude: point1Lat, longitude: point1Lng }: Coordinates,
  { latitude: point2Lat, longitude: point2Lng }: Coordinates
) => {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = point1Lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = point2Lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (point2Lng - point1Lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    );
  return d;
};

// https://developers.google.com/maps/documentation/javascript/examples/maptype-image
// Normalizes the coords that tiles repeat across the x axis (horizontally)
// like the standard Google map tiles.
export const getNormalizedCoordinates = (coord: google.maps.Point, zoom: number) => {
  const y = coord.y;
  let x = coord.x;

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom;

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null;
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return { x, y };
};
