export const {
  HOST = 'plusplatform.test',
  HTTPS: ENV_HTTPS = 'true',
  REACT_APP_ALLOW_DATA_RESET: ENV_REACT_APP_ALLOW_DATA_RESET = 'false',
  REACT_APP_API_URL = 'https://plusplatform.test:8080',
  REACT_APP_BUILD_VERSION = 'Plus',
  REACT_APP_GOOGLE_MAPS_API_KEY = '',
  REACT_APP_WS_RECONNECT: ENV_REACT_APP_WS_RECONNECT = 'true',
  REACT_APP_WS_RECONNECT_DELAY: ENV_REACT_APP_WS_RECONNECT_DELAY = '1000',
  REACT_APP_WS_URL = 'wss://plusplatform.test:8081',
} = process.env;

const toBoolean = (value: string) => ['1', 'true'].includes(value);

export const HTTPS: boolean = toBoolean(ENV_HTTPS);
export const REACT_APP_WS_RECONNECT: boolean = toBoolean(ENV_REACT_APP_WS_RECONNECT);
export const REACT_APP_WS_RECONNECT_DELAY = Number(ENV_REACT_APP_WS_RECONNECT_DELAY);
export const REACT_APP_ALLOW_DATA_RESET: boolean = toBoolean(ENV_REACT_APP_ALLOW_DATA_RESET);
