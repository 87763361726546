/**
 * Primaries
 */

export const primary01 = '#FFFFFF';
export const primary02 = '#F2F2F2';
export const primary03 = '#E7E7EC';
export const primary04 = '#D9D9DE';
export const primary05 = '#C7C7CB';
export const primary06 = '#BBBBC0';
export const primary07 = '#474B5E';
export const primary08 = '#31354B';
export const primary09 = '#262A41';
export const primary10 = '#111322';

/**
 * Colors
 */

export const statusYellow = '#E4B200';
export const statusOrange = '#F27622';
export const statusRed = '#DE4137';
export const statusTurquoise = '#009DA7';
export const statusGreen = '#007854';
export const statusBlue = '#1D7BE9';
export const statusWhite = '#FFFFFF';
export const statusBlack = '#111322';
export const statusLightGrey = '#E7E7EC';
export const statusDarkGrey = '#C7C7CB';

/**
 * Accents
 */

export const accent01 = statusGreen;

/**
 * Backgrounds
 */

export const background01 = primary01;

/**
 * Text Primary
 */

export const textPrimary01 = primary10;
export const textPrimary02 = primary08;
export const textPrimary03 = primary06;

/**
 * Text Secondary
 */

export const textSecondary01 = primary01;
export const textSecondary02 = primary03;
export const textSecondary03 = primary06;

/**
 * DEPRECATED Primary (Blues)
 */

export const deprecatedPrimary01 = '#1B1F37';
export const deprecatedPrimary02 = '#242A4E';
export const deprecatedPrimary03 = '#2D335B';

/**
 * Whites
 */

export const white = 'rgba(255, 255, 255, 1)';
export const white90 = 'rgba(255, 255, 255, 0.9)';
export const white80 = 'rgba(255, 255, 255, 0.8)';
export const white70 = 'rgba(255, 255, 255, 0.7)';
export const white60 = 'rgba(255, 255, 255, 0.6)';
export const white50 = 'rgba(255, 255, 255, 0.5)';
export const white40 = 'rgba(255, 255, 255, 0.4)';
export const white30 = 'rgba(255, 255, 255, 0.3)';
export const white20 = 'rgba(255, 255, 255, 0.2)';
export const white15 = 'rgba(255, 255, 255, 0.15)';
export const white10 = 'rgba(255, 255, 255, 0.1)';
export const white05 = 'rgba(255, 255, 255, 0.05)';

/**
 * DEPRECATED Blues
 */

export const blue = 'rgb(17, 19, 34)';
export const blue90 = 'rgba(17, 19, 34, 0.9)';
export const blue80 = 'rgba(17, 19, 34, 0.8)';
export const blue70 = 'rgba(17, 19, 34, 0.7)';
export const blue60 = 'rgba(17, 19, 34, 0.6)';
export const blue50 = 'rgba(17, 19, 34, 0.5)';
export const blue40 = 'rgba(17, 19, 34, 0.4)';
export const blue30 = 'rgba(17, 19, 34, 0.3)';
export const blue20 = 'rgba(17, 19, 34, 0.2)';
export const blue15 = 'rgba(17, 19, 34, 0.15)';
export const blue10 = 'rgba(17, 19, 34, 0.1)';
export const blue05 = 'rgba(17, 19, 34, 0.05)';

/**
 * DEPRECATED
 */

export const lightGreen = '#00E09D';

/**
 * Flags, alerts, warnings
 */

export const flag = statusBlue;
export const alert = statusOrange;
export const warning = statusRed;

/**
 * Defaults
 */

export const delinquency30Days = statusYellow;
export const delinquency60Days = statusOrange;
export const delinquency90Days = statusRed;

/**
 * Toggle Switch
 */

export const switchOn = accent01;
export const switchOff = primary04;

/**
 * Text
 */

export const text01 = 'rgba(17, 19, 34, 0.59)';
export const textNavigation = textPrimary02;
export const textNavigationActive = textPrimary01;
export const textNavigationHover = blue;
export const textGraduatedRangeLabel = blue;

/**
 * Anchors
 */

export const anchorColor = textPrimary01;
export const anchorColorHover = accent01;
export const anchorColorActive = accent01;
export const anchorBGColor = 'transparent';
export const anchorBGColorHover = primary03;
export const anchorBGColorActive = primary04;

/**
 * Selects
 */

export const selectOptionText = textPrimary01;
export const selectOptionTextSelected = accent01;
export const selectOptionTextSelectedInvalid = statusOrange;
export const selectOptionBackground = 'transparent';
export const selectOptionBackgroundSelected = primary04;

/**
 * Inputs
 */

export const inputBackground = primary02;
export const inputBackgroundHover = primary03;
export const inputBackgroundActive = primary03;
export const inputBorderHover = accent01;
export const inputBorderActive = accent01;
export const inputBorderInvalidHover = statusOrange;
export const inputBorderInvalidActive = statusOrange;
export const inputCaret = accent01;
export const inputCaretInvalid = statusOrange;
export const inputLabelText = textPrimary02;
export const inputText = textPrimary01;

/**
 * Backgrounds
 */

export const backgroundHeader = white;
export const backgroundPage = 'rgb(248, 250, 253)';
export const backgroundPageSection = white;
export const backgroundNavigationActive = 'rgba(236, 238, 242, 1)';
export const backgroundNavigationHover = 'rgba(236, 238, 242, 1)';

export const backgroundButtonHover = 'rgba(236, 238, 242, 1)';
export const backgroundButtonLight = white;

export const backgroundButtonIconToggleActive = 'rgba(236, 238, 242, 1)';
export const backgroundButtonIconToggleHover = 'rgba(236, 238, 242, 1)';

export const backgroundHeaderSearchBar = 'rgba(236, 238, 242, 1)';

export const backgroundAdminSidePanel = white;

export const backgroundAdminMenuItem = 'rgb(243, 243, 244)';
export const backgroundAdminMenuItemHover = 'rgb(209, 210, 213)';

export const backgroundAdminSubsection = 'rgb(243, 243, 244)';

export const backgroundAdminBackLinkIcon = 'transparent';

/**
 * Borders
 */
export const borderColorHeader = 'rgba(236, 238, 242, 1)';
export const borderHeaderSearchBar = 'transparent';

export const borderAdminSidePanel = 'rgba(236, 238, 242, 1)';
export const borderAdminMenuItem = 'rgb(232, 232, 234)';

export const borderAdminBackLinkIcon = 'rgba(236, 238, 242, 1)';

/**
 * Fills
 */
export const fillButtonIconHover = blue;
export const fillSearchIcon = blue;

export const fillButtonLight = blue;

export const fillButtonIconToggleActive = blue;
export const fillButtonIconToggleHover = blue;

export const fillAdminSidePanelLabel = 'rgb(115, 116, 125)';
export const fillAdminSidePanelIcon = 'rgb(115, 116, 125)';

export const fillHeading = textPrimary01;
export const fillAdminMenuItemText = blue80;

export const fillAdminBackLinkIcon = blue;

export const fillGraduatedRangeTick = 'rgb(198, 198, 202)';
export const fillGraduatedRangeSelectedTick = 'rgb(0, 120, 84)';

export const fillSettingsSpinnerIcon = blue;
