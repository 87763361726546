import styled, { css } from 'styled-components/macro';

type HeaderProps = {
  $variant?: 'default' | 'light';
};

export const Header = styled.div<HeaderProps>(
  ({ $variant, theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 12px;
    padding: 12px;
    border-bottom: 1px solid
      ${$variant === 'light' ? theme.colors.primary04 : theme.colors.primary03};
  `
);

export const Dull = styled.span(
  ({ theme }) => css`
    ${theme.typography.headings.headingRegular03}
    color: ${theme.colors.textPrimary02};
    white-space: pre;
  `
);

type StatusesProps = {
  $size: 'small' | 'medium' | 'large';
  $count: number;
};

export const Statuses = styled.div<StatusesProps>(
  ({ $count, $size }) => css`
    display: grid;
    column-gap: 8px;

    ${$size === 'small' &&
    css`
      grid-template-columns: repeat(${$count}, 1fr);
      width: 100%;
    `}

    ${($size === 'medium' || $size === 'large') &&
    css`
      grid-template-columns: repeat(${$count}, max-content);
    `}
  `
);

type TradeInfoProps = {
  $size: 'small' | 'medium' | 'large';
};

export const TradeInfo = styled.div<TradeInfoProps>(
  ({ $size }) => css`
    display: grid;
    width: 100%;
    padding: 12px 8px 0;

    ${$size === 'small' &&
    css`
      grid-template-columns: repeat(2, max-content);
    `}

    ${$size === 'medium' &&
    css`
      grid-template-columns: minmax(min-content, auto) minmax(max-content, auto) min-content;
    `}

    ${$size === 'large' &&
    css`
      grid-template-columns: repeat(9, max-content);
      column-gap: 24px;
      width: auto;
      max-width: 100%;
    `}
  `
);

export const TradeInfoVertical = styled.div`
  display: grid;
  row-gap: 8px;
`;

type RecipientsWrapperProps = {
  $size: 'small' | 'medium' | 'large';
};

export const RecipientsWrapper = styled.div<RecipientsWrapperProps>(
  ({ $size }) => css`
    display: flex;
    padding: 12px;

    ${($size === 'medium' || $size === 'large') &&
    css`
      padding-top: 24px;
    `}
  `
);

export const RecipientsLabel = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02}
    color: ${theme.colors.textPrimary02};
    padding: 0 4px;
    margin-right: 12px;
  `
);

export const Recipients = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
  list-style: none;
`;

export const Recipient = styled.li`
  list-style: none;
`;

export const Actions = styled.div`
  padding-bottom: 12px;
`;

export const ActivityList = styled.div`
  display: grid;
  row-gap: 6px;
  margin-right: 16px;
`;

export const Step = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${theme.colors.primary10};
  `
);

export const StepLabel = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}
    color: ${theme.colors.textPrimary01};
    margin-right: auto;
  `
);

export const StepCount = styled.span(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04};
    color: ${theme.colors.statusBlack};
  `
);
