import React from 'react';

export const AddSingleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8 16.92H13V14.34H15.6V13.14H13V10.54H11.8V13.14H9.2V14.34H11.8V16.92ZM7.2 20C6.88 20 6.6 19.88 6.36 19.64C6.12 19.4 6 19.12 6 18.8V5.2C6 4.88 6.12 4.6 6.36 4.36C6.6 4.12 6.88 4 7.2 4H14.42L18.8 8.38V18.8C18.8 19.12 18.68 19.4 18.44 19.64C18.2 19.88 17.92 20 17.6 20H7.2ZM13.82 8.92V5.2H7.2V18.8H17.6V8.92H13.82Z"
        fill="currentColor"
      />
    </svg>
  );
};
