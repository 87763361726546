import { Identifier } from './global';
import { Loan } from './loans';

export enum ChatParticipantType {
  User = 'User',
  Servicer = 'Servicer',
}

export type ChatParticipant = {
  type: ChatParticipantType;
  id: Identifier;
};

export type LoanChatMessageCreation = {
  loanId: Loan['id'];
  message: string;
  fromId: ChatParticipant['id'];
  fromType: ChatParticipant['type'];
  toId: ChatParticipant['id'];
  toType: ChatParticipant['type'];
  createdAt?: Date;
  updatedAt?: Date;
};

export type LoanChatMessage = LoanChatMessageCreation & {
  id: Identifier;
};
