import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { PageSectionTitle } from '../../../components/Page';
import { Messenger as MessengerComponent } from '../MessengerList.styles';

export const Messenger = styled(MessengerComponent)`
  justify-content: start;
`;

export const TradeConfirmationTitle = styled(PageSectionTitle)`
  padding: 20px 20px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tradeDividerPurple};
  font-size: 14px;
  flex: initial;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-height: 0;
  padding: 15px 20px;
`;

export const Content = styled.div``;

export const Title = styled.h3(
  ({ theme }) => css`
    font-weight: bold;
    font-size: 14px;
    font-family: ${theme.typography.fontFamily.display};
    margin-bottom: 10px;
    color: ${transparentize(0.2, theme.colors.white)};
  `
);

export const Description = styled.p`
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => transparentize(0.1, theme.colors.white)};
`;

export const PoolWrapper = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 25px;
  min-height: 226px;
`;

export const Stipulations = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 300px) auto;
  align-items: start;
  justify-content: start;
  column-gap: 16px;
  padding-top: 20px;
`;

export const NoStipulations = styled.div`
  font-size: 14px;
`;
