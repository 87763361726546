import { Filter } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const getPortfolioFilters = () => {
  const url = makeApiUrl(`user/filters/portfolio`);

  return getRequestWithAuth<Filter[]>(url);
};

export const usePortfolioFiltersQuery = () =>
  useQuery([QueryKeys.PORTFOLIO_FILTERS], getPortfolioFilters);
