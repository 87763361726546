import { mix } from 'polished';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import {
  BUTTON_SIDE_PADDING_BY_SIZE_MAP,
  mapColorLabelToPrimaryAndSecondary,
} from './Button.styles.utils';

export type ButtonStyleProps = {
  $variant?: 'contained' | 'outlined' | 'text';
  $size?: 'xSmall' | 'small' | 'medium' | 'large';
  $color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'neutral';
  $isFullWidth?: boolean;
  $hasStartIcon?: boolean;
  $hasEndIcon?: boolean;
};

type styleProps = ThemeProps<DefaultTheme> & ButtonStyleProps;

export const baseButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  width: auto;
  outline: 0;
  background: none;
  border: 0;
  border-radius: 4px;
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    pointer-events: none;
    transition: none;
  }
`;

const containedButtonStyles = ({ $color, theme }: styleProps) => {
  const [primaryColor, secondaryColor] = mapColorLabelToPrimaryAndSecondary(
    $color ?? 'primary',
    theme
  );

  return css`
    background-color: ${primaryColor};
    color: ${secondaryColor};

    &:hover,
    &:focus-visible {
      background: ${mix(0.15, theme.colors.background01, primaryColor)};
    }

    &:active {
      background: ${mix(0.3, theme.colors.background01, primaryColor)};
    }

    &:disabled {
      opacity: 0.2;
    }
  `;
};

const outlinedButtonStyles = ({ $color, theme }: styleProps) => {
  const [primaryColor] = mapColorLabelToPrimaryAndSecondary($color ?? 'primary', theme);

  return css`
    box-shadow: inset 0 0 0 1px ${primaryColor};
    color: ${primaryColor};

    &:hover,
    &:focus-visible {
      background-color: ${theme.colors.white15};
    }

    &:active {
      background-color: ${theme.colors.white30};
    }

    &:disabled {
      opacity: 0.2;
    }
  `;
};

const textButtonStyles = ({ $color, theme }: styleProps) => {
  const [primaryColor] = mapColorLabelToPrimaryAndSecondary($color ?? 'primary', theme);

  return css`
    color: ${primaryColor};

    &:hover,
    &:focus-visible {
      color: ${theme.colors.white};
    }

    &:disabled {
      color: ${theme.colors.buttonDisabledTextGrey};
    }
  `;
};

const xSmallButtonStyles = ({ $hasEndIcon, $hasStartIcon, theme }: styleProps) => css`
  ${theme.typography.headings.headingMedium04};
  height: 24px;
  padding-left: ${$hasStartIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['xSmall'] : 8}px;
  padding-right: ${$hasEndIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['xSmall'] : 8}px;
`;

const smallButtonStyles = ({ $hasEndIcon, $hasStartIcon, theme }: styleProps) => css`
  ${theme.typography.headings.headingMedium03};
  height: 24px;
  padding-left: ${$hasStartIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['small'] : 8}px;
  padding-right: ${$hasEndIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['small'] : 8}px;
`;

const mediumButtonStyles = ({ $hasEndIcon, $hasStartIcon, theme }: styleProps) => css`
  ${theme.typography.headings.headingMedium03};
  height: 32px;
  padding-left: ${$hasStartIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['medium'] : 12}px;
  padding-right: ${$hasEndIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['medium'] : 12}px;
`;

const largeButtonStyles = ({ $hasEndIcon, $hasStartIcon, theme }: styleProps) => css`
  ${theme.typography.headings.headingMedium03};
  height: 40px;
  padding-left: ${$hasStartIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['large'] : 16}px;
  padding-right: ${$hasEndIcon ? BUTTON_SIDE_PADDING_BY_SIZE_MAP['large'] : 16}px;
`;

export const Button = styled.button.attrs<ButtonStyleProps>((props) => ({
  ...props,
  type: props.type ?? 'button',
}))<ButtonStyleProps>(
  ({ $isFullWidth, $size, $variant }) => css`
    ${baseButtonStyles}

    ${$isFullWidth &&
    css`
      width: 100%;
    `}

    /**
      Button sizes - xSmall | small | medium | large
      */

    ${$size === 'xSmall' && xSmallButtonStyles}

    ${$size === 'small' && smallButtonStyles}

    ${$size === 'medium' && mediumButtonStyles}

    ${$size === 'large' && largeButtonStyles}

    /**
      Button variants - contained | outlined | text
      */

    ${$variant === 'contained' && containedButtonStyles}

    ${$variant === 'outlined' && outlinedButtonStyles}

    ${$variant === 'text' && textButtonStyles}
  `
);
