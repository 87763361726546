import { Loan } from '@plus-platform/shared';
import React from 'react';

import { Divider } from '../components/Divider';
import { Key, Pair, Value } from '../components/KeyValue';
import { useZipCodeDataQuery } from '../hooks/queries';
import { formatMonetary, formatPercentage } from '../utils/formatUtils';
import * as Styles from './LoanSummaryMetaInfo.styles';

type LoanSummaryTitleStatsProps = {
  loan?: Loan;
};

export const LoanSummaryMetaInfo = ({ loan }: LoanSummaryTitleStatsProps) => {
  const zipCode = loan?.loanProperty.property.zipCode;
  const { data: zipCodeData } = useZipCodeDataQuery(zipCode || '');

  return (
    <Styles.Wrapper>
      <Pair $align="left" $direction="vertical">
        <Key>For Zip code</Key>
        <Value>{zipCode}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>Median household income</Key>
        <Value>{formatMonetary(zipCodeData?.medianIncome)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left" $direction="vertical">
        <Key>% below poverty line</Key>
        <Value>{formatPercentage(zipCodeData?.povertyLevel)}</Value>
      </Pair>
    </Styles.Wrapper>
  );
};
