import { Message } from '@plus-platform/shared';
import noop from 'lodash/noop';
import union from 'lodash/union';
import without from 'lodash/without';
import React from 'react';

import { REACT_APP_WS_RECONNECT_DELAY } from '../config';
import { getSocketApiUrl } from '../utils/apiUtils';
import { shouldReconnectSocket } from '../utils/socketUtils';
import { useUserContext } from './UserContext';

export const enum SocketStatusType {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

// FIXME: Update with real message types - examples only
export const enum SocketMessageTypes {
  CLIENT_MESSAGE = 'CLIENT_MESSAGE',
  SERVICER_MESSAGE = 'SERVICER_MESSAGE',
}

interface SocketMessageType {
  type: SocketMessageTypes;
  payload: string;
}

type MessageListener = (message: Message) => void;

interface SocketContextState {
  sendMessage: (message: SocketMessageType) => void;
  status: SocketStatusType;
  subscribe: (listener: MessageListener) => void;
  unsubscribe: (listener: MessageListener) => void;
}

interface SocketContextProps {
  children?: React.ReactNode;
}

const initialState = {
  status: SocketStatusType.CONNECTING,
  sendMessage: noop,
  subscribe: noop,
  unsubscribe: noop,
};

export const SocketContext = React.createContext<SocketContextState>(initialState);

export const SocketProvider = ({ children }: SocketContextProps) => {
  const { userProfile } = useUserContext();

  const socket = React.useRef<WebSocket | undefined>();
  const [status, setStatus] = React.useState<SocketStatusType>(SocketStatusType.CONNECTING);
  const listeners = React.useRef<MessageListener[]>([]);

  const handleOpen = React.useCallback(() => setStatus(SocketStatusType.OPEN), []);

  const handleClose = React.useCallback(() => setStatus(SocketStatusType.CLOSED), []);

  const handleMessage = React.useCallback((event: MessageEvent<string>) => {
    try {
      const message = JSON.parse(event.data) as Message;

      listeners.current.forEach((listener) => listener(message));
    } catch {
      // eslint-disable-next-line no-console
      console.error('Failed to parse incoming WebSocket event', event);
    }
  }, []);

  const handleError = React.useCallback(
    /* eslint-disable-next-line no-console */
    (err: unknown) => console.error(err),
    []
  );

  const sendMessage = React.useCallback((message: SocketMessageType) => {
    socket.current?.send(JSON.stringify(message));
  }, []);

  const subscribe = React.useCallback((listener: MessageListener) => {
    listeners.current = union(listeners.current, [listener]);
  }, []);

  const unsubscribe = React.useCallback((listener: MessageListener) => {
    listeners.current = without(listeners.current, listener);
  }, []);

  const createSocket = React.useCallback(() => {
    socket.current = new WebSocket(getSocketApiUrl());
    socket.current?.addEventListener('open', handleOpen);
    socket.current?.addEventListener('close', handleClose);
    socket.current?.addEventListener('message', handleMessage);
    socket.current?.addEventListener('error', handleError);
  }, [handleOpen, handleClose, handleError, handleMessage]);

  // Init
  React.useEffect(() => {
    const destroySocket = () => {
      socket.current?.close();
      socket.current?.removeEventListener('open', handleOpen);
      socket.current?.removeEventListener('close', handleClose);
      socket.current?.removeEventListener('message', handleMessage);
      socket.current?.removeEventListener('error', handleError);
    };

    if (userProfile) {
      createSocket();
    }
    return () => destroySocket();
  }, [createSocket, handleOpen, handleClose, handleError, handleMessage, userProfile]);

  // Reconnect
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (shouldReconnectSocket(status)) {
        createSocket();
      }
    }, REACT_APP_WS_RECONNECT_DELAY);
    return () => clearTimeout(timeout);
  }, [status, createSocket]);

  return (
    <SocketContext.Provider
      value={{
        sendMessage,
        status,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => React.useContext(SocketContext);
