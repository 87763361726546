import { Filter, GetPortfolioLoansDocumentSummaryItem, Identifier } from '@plus-platform/shared';
import React from 'react';
import { FetchNextPageOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { VirtualItem } from 'react-virtual/types';
import { useTheme } from 'styled-components/macro';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Badge, BadgeWrapper } from '../components/Badge';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import {
  Cell,
  ErrorRow,
  GroupedHeaderCell,
  HeaderCell,
  HeaderRow,
  LoadingRow,
  Table,
  TABLE_MARGIN,
  TableWrapper,
  Tbody,
  Thead,
} from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { VirtualizedTable } from '../components/VirtualizedTable';
import { useDoubleClick } from '../hooks/useDoubleClick';
import { EMPTY_VALUE, formatDefaultValue } from '../utils/formatUtils';
import { DocumentsGroupedHeaderRow, RowWrapper, StatusGroup } from './DocumentsTable.styles';
import * as Styles from './DocumentsTable.styles';
import { DocumentsTablePopover } from './DocumentsTablePopover';

const TABLE_COLUMN_COUNT = 12;

type GroupedHeaderProps = {
  columnCount: number;
};

const TableGroupedHeader = ({ columnCount }: GroupedHeaderProps) => (
  <DocumentsGroupedHeaderRow $columnCount={columnCount}>
    <GroupedHeaderCell />
    <GroupedHeaderCell>Origination Documents</GroupedHeaderCell>
    <GroupedHeaderCell>Servicer Documents</GroupedHeaderCell>
    <GroupedHeaderCell>Investor Documents</GroupedHeaderCell>
    <GroupedHeaderCell>Transaction Documents</GroupedHeaderCell>
  </DocumentsGroupedHeaderRow>
);

const TableHeaderCells = () => (
  <React.Fragment>
    <HeaderCell>Loan ID</HeaderCell>
    <HeaderCell>Originator</HeaderCell>
    <HeaderCell>Loan Type</HeaderCell>
    <HeaderCell>Closing Docs</HeaderCell>
    <HeaderCell>Credit Docs</HeaderCell>
    <HeaderCell>Disclosure Docs</HeaderCell>
    <HeaderCell>Borrower comms</HeaderCell>
    <HeaderCell>Delinquency</HeaderCell>
    <HeaderCell>Instructions</HeaderCell>
    <HeaderCell>{EMPTY_VALUE}</HeaderCell>
    <HeaderCell>Inbound</HeaderCell>
    <HeaderCell>Outbound</HeaderCell>
  </React.Fragment>
);

const TableHeader = () => (
  <Thead>
    <TableGroupedHeader columnCount={TABLE_COLUMN_COUNT} />
    <HeaderRow $columnCount={TABLE_COLUMN_COUNT}>
      <TableHeaderCells />
    </HeaderRow>
  </Thead>
);

type RowCellsProps = {
  loanWithDocumentSummary: GetPortfolioLoansDocumentSummaryItem;
};

const RowCells = ({ loanWithDocumentSummary }: RowCellsProps) => {
  const theme = useTheme();
  const { closingDocuments, creditDocuments, disclosureDocuments, loanId, loanType, originator } =
    loanWithDocumentSummary;

  return (
    <React.Fragment>
      <Cell>{formatDefaultValue(loanId)}</Cell>
      <Cell>{formatDefaultValue(originator)}</Cell>
      <Cell>{formatDefaultValue(loanType)}</Cell>
      <Cell>
        <StatusGroup>
          {/* TODO: Update with real data */}
          <BadgeWrapper>
            <Badge $color={theme.colors.greenLight} $size={8} />
            {closingDocuments.length}
          </BadgeWrapper>
          <BadgeWrapper>
            <Badge $color={theme.colors.redSecondary} $size={8} />0
          </BadgeWrapper>
        </StatusGroup>
      </Cell>
      <Cell>
        <StatusGroup>
          {/* TODO: Update with real data */}
          <BadgeWrapper>
            <Badge $color={theme.colors.greenLight} $size={8} />
            {creditDocuments.length}
          </BadgeWrapper>
          <BadgeWrapper>
            <Badge $color={theme.colors.redSecondary} $size={8} />0
          </BadgeWrapper>
        </StatusGroup>
      </Cell>
      <Cell>
        <StatusGroup>
          {/* TODO: Update with real data */}
          <BadgeWrapper>
            <Badge $color={theme.colors.greenLight} $size={8} />
            {disclosureDocuments.length}
          </BadgeWrapper>
          <BadgeWrapper>
            <Badge $color={theme.colors.redSecondary} $size={8} />0
          </BadgeWrapper>
        </StatusGroup>
      </Cell>
      {/* TODO: Update with real data */}
      <Cell>0</Cell>
      <Cell>0</Cell>
      <Cell>0</Cell>
      <Cell>0</Cell>
      <Cell>0</Cell>
      <Cell>0</Cell>
    </React.Fragment>
  );
};

type RenderRowProps = {
  isLoaderRow: boolean;
  totalLoansCount: number;
  virtualRow: VirtualItem;
  loanWithDocumentSummary?: GetPortfolioLoansDocumentSummaryItem;
  onRowClick?: (loanId: Identifier) => void;
  onRowDoubleClick?: (loanNumber: string) => void;
};

const renderRow = ({
  isLoaderRow,
  loanWithDocumentSummary,
  onRowClick,
  onRowDoubleClick,
  totalLoansCount,
  virtualRow,
}: RenderRowProps) => {
  return (
    <RowWrapper
      height={virtualRow.size}
      offsetY={virtualRow.start}
      data-testid="DocumentsTable_Row"
    >
      {isLoaderRow && (
        <LoadingRow>
          {virtualRow.index < totalLoansCount ? 'Loading more...' : 'Nothing more to load'}
        </LoadingRow>
      )}

      {!isLoaderRow && !loanWithDocumentSummary && <ErrorRow>Error loading row</ErrorRow>}

      {!isLoaderRow && loanWithDocumentSummary && (
        <Styles.DocumentsRow
          style={{ padding: `0 ${TABLE_MARGIN}px` }}
          $columnCount={TABLE_COLUMN_COUNT}
          onClick={() => onRowClick?.(loanWithDocumentSummary.loanId)}
          onDoubleClick={() => onRowDoubleClick?.(loanWithDocumentSummary.loanNumber)}
        >
          <RowCells loanWithDocumentSummary={loanWithDocumentSummary} />
        </Styles.DocumentsRow>
      )}
    </RowWrapper>
  );
};

type TableProps = {
  loansWithDocumentSummary: GetPortfolioLoansDocumentSummaryItem[];
  totalLoansCount: number;
  totalFilteredLoansCount: number;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  onFiltersUpdate: (filters: Filter[]) => unknown | Promise<unknown>;
  filters: Filter[];
  isLoading: boolean;
  maxVisibleItems?: number;
};

export const DocumentsTable = ({
  fetchNextPage,
  filters,
  isFetchingNextPage,
  isLoading,
  loansWithDocumentSummary,
  maxVisibleItems,
  onFiltersUpdate,
  totalFilteredLoansCount,
  totalLoansCount,
}: TableProps) => {
  const [selectedLoan, setSelectedLoan] = React.useState<
    GetPortfolioLoansDocumentSummaryItem | undefined
  >();
  const parentRef = React.useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  const { handleClick: handleRowClick, handleDoubleClick: handleRowDoubleClick } = useDoubleClick({
    onClick: (loanId: Identifier) => {
      setSelectedLoan(loansWithDocumentSummary.find((loan) => loan.loanId === loanId));
    },
    onDoubleClick: (loanNumber: GetPortfolioLoansDocumentSummaryItem['loanNumber']) => {
      history.push(`/documents/loan/${loanNumber}`);
    },
  });

  return (
    <TableFilters filters={filters} onFiltersUpdate={onFiltersUpdate} path="/documents">
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({ closeLeftPanel, isLeftPanelOpen, openLeftPanel }) => (
            <React.Fragment>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={onFiltersUpdate}
                />
              )}
              <Card>
                <TableToolbar>
                  <TableStats
                    totalCount={totalLoansCount}
                    visibleCount={loansWithDocumentSummary.length}
                  />
                  <Divider $flexItem $orientation="vertical" $variant="middle" />
                  <TableFilterBar
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={onFiltersUpdate}
                  />
                </TableToolbar>
                <TableWrapper>
                  {selectedLoan && (
                    <DocumentsTablePopover
                      loanWithDocumentSummary={selectedLoan}
                      onClose={() => setSelectedLoan(undefined)}
                    />
                  )}
                  <ActivityIndicator contain isActive={isLoading}>
                    <Table>
                      <TableHeader />
                      <Tbody>
                        <VirtualizedTable<GetPortfolioLoansDocumentSummaryItem>
                          fetchNextPage={fetchNextPage}
                          isFetchingNextPage={isFetchingNextPage}
                          items={loansWithDocumentSummary}
                          maxVisibleItems={maxVisibleItems}
                          parentRef={parentRef}
                          renderRow={(isLoaderRow, virtualRow, loanWithDocumentSummary) => {
                            if (!loanWithDocumentSummary) {
                              return null;
                            }

                            return renderRow({
                              isLoaderRow,
                              totalLoansCount: totalFilteredLoansCount,
                              virtualRow,
                              loanWithDocumentSummary,
                              onRowClick: handleRowClick,
                              onRowDoubleClick: handleRowDoubleClick,
                            });
                          }}
                          totalItemsCount={totalFilteredLoansCount}
                        />
                      </Tbody>
                    </Table>
                  </ActivityIndicator>
                </TableWrapper>
              </Card>
            </React.Fragment>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};
