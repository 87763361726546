import { useQuery } from 'react-query';

import * as api from '../../api/documents';
import { QueryKeys } from './types';

export const useGetSubmissionDocumentImageQuery = (
  submissionId: string,
  documentName: string,
  pageNumber?: number
) =>
  useQuery(
    [QueryKeys.SUBMISSION_DOCUMENT_IMAGE, submissionId, documentName, pageNumber],
    async () => {
      return await api.getSubmissionDocumentImage(submissionId, documentName, pageNumber);
    }
  );
