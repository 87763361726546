import { DefaultTheme } from 'styled-components';

export const mapColorLabelToPrimaryAndSecondary = (colorLabel: string, theme: DefaultTheme) => {
  const BUTTON_COLOR_LABEL_TO_HEXES_MAP: Record<string, [string, string]> = {
    primary: [theme.colors.accent01, theme.colors.textSecondary01],
    secondary: [theme.colors.accent01, theme.colors.accent01],
    tertiary: [theme.colors.primary10, theme.colors.textPrimary01],
    danger: [theme.colors.statusRed, theme.colors.statusWhite],
    neutral: [theme.colors.statusWhite, theme.colors.statusBlack],
  };

  return BUTTON_COLOR_LABEL_TO_HEXES_MAP[colorLabel];
};

export const BUTTON_SIDE_PADDING_BY_SIZE_MAP = {
  xSmall: 2,
  small: 2,
  medium: 4,
  large: 8,
};
