import React from 'react';

import * as Styles from './ToggleGroup.styles';

type ToggleGroupProps<T = string> = {
  children: React.ReactNode;
  onChange?: (event: React.MouseEvent<HTMLElement>, value: T | T[] | null) => void;
  value?: T | T[] | null;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'spaced';
  style?: React.CSSProperties;
  shouldEnforceValue?: boolean;
};

function getComponentDisplayName(element: React.ReactElement<any>) {
  const node = element as React.ReactElement<React.ComponentType<any>>;
  const type = (node as unknown as React.ReactElement<React.FunctionComponent>).type;

  return typeof type === 'function'
    ? (type as React.FunctionComponent).displayName ||
        (type as React.FunctionComponent).name ||
        'Unknown'
    : type;
}

const buttonComponents = ['ButtonIconToggle', 'ButtonToggle', 'View'];

export const ToggleGroup = <T,>({
  children,
  onChange,
  shouldEnforceValue = false,
  size = 'large',
  style,
  value,
  variant = 'default',
}: ToggleGroupProps<T>) => {
  const handleChange = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, buttonValue: T) => {
      if (!onChange || buttonValue === undefined) {
        return;
      }

      if (Array.isArray(value)) {
        const set = new Set<T>(value);

        if (set.has(buttonValue)) {
          if (shouldEnforceValue && set.size === 1) {
            return;
          }
          set.delete(buttonValue);
        } else {
          set.add(buttonValue);
        }

        const updatedValue = Array.from(set);
        return onChange(event, updatedValue);
      }

      const isEqualValue = value === buttonValue;

      if (shouldEnforceValue && isEqualValue) {
        return onChange(event, buttonValue);
      }

      const updatedValue = isEqualValue ? null : buttonValue;
      return onChange(event, updatedValue);
    },
    [value, onChange, shouldEnforceValue]
  );

  return (
    <Styles.ToggleGroup $spaced={variant === 'spaced'} style={style}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        const componentName = getComponentDisplayName(child);
        const extraProps: Record<string, any> = {};

        if (child.type === 'button' || buttonComponents.includes(componentName)) {
          extraProps.onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            child.props.onClick?.(event);

            if (!event.defaultPrevented) {
              handleChange(event, child.props.value);
            }
          };

          if (Array.isArray(value)) {
            extraProps.isActive = value.includes(child.props.value);
          } else {
            extraProps.isActive = value === child.props.value;
          }
        }

        extraProps.size = size;
        extraProps.lightBackground = variant === 'spaced';

        return React.cloneElement(child, extraProps);
      })}
    </Styles.ToggleGroup>
  );
};
