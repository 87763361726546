import { PermissionType, UserPermissionHierarchy } from '@plus-platform/shared';
import { Fragment, ReactNode, useMemo } from 'react';

import { usePermissions } from './Permissions.hooks';

type Props = {
  children: ReactNode;
  fallback?: ReactNode;
  permissions: UserPermissionHierarchy;
};

export const Permissions = ({ children, fallback, permissions }: Props) => {
  const { hasPermissions } = usePermissions();

  return <Fragment>{hasPermissions(permissions) ? children : fallback}</Fragment>;
};

export const ReadPermissions = ({
  children,
  requiredAttributes,
}: Omit<Props, 'permissions'> & {
  requiredAttributes: UserPermissionHierarchy[keyof UserPermissionHierarchy];
}) => {
  const permissions = useMemo(
    () => ({ [PermissionType.PERMISSION_READ]: requiredAttributes }),
    [requiredAttributes]
  );

  return <Permissions permissions={permissions}>{children}</Permissions>;
};

export const CreatePermissions = ({
  children,
  requiredAttributes,
}: Omit<Props, 'permissions'> & {
  requiredAttributes: UserPermissionHierarchy[keyof UserPermissionHierarchy];
}) => {
  const permissions = useMemo(
    () => ({ [PermissionType.PERMISSION_CREATE]: requiredAttributes }),
    [requiredAttributes]
  );

  return <Permissions permissions={permissions}>{children}</Permissions>;
};
