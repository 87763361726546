import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../../types';

export enum PropertyValuationConditionalConclusionType {
  AsIs = 'AsIs',
  Other = 'Other',
  SubjectToCompletionPerPlans = 'SubjectToCompletionPerPlans',
  SubjectToRepairsAndConditions = 'SubjectToRepairsAndConditions',
  SubjectToRepairsProvingUnnecessary = 'SubjectToRepairsProvingUnnecessary',
}

type ValuationReconciliationSummary = MISMOEntity &
  Partial<{
    appraisalReportEffectiveDate: MISMODate;
    opinionOfValueAmount: MISMOAmount;
    propertyValuationConditionalConclusionType: PropertyValuationConditionalConclusionType;
    propertyValuationConditionalConclusionTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type ValuationReconciliation = MISMOEntity &
  Partial<{
    valuationReconciliationConditionsCommentDescription: MISMOString;
    valuationReconciliationSummaryCommentDescription: MISMOString;
    // repair: Repair;
    valuationReconciliationSummaries: ValuationReconciliationSummary[];
  }>;
