import capitalize from 'lodash/capitalize';
import React from 'react';
import { Redirect, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { PrivateRoute } from '../../global/PrivateRoute';
import { WidthWrapper } from '../../global/WidthWrapper';
import { useTradeRequestDataLoanQuery } from '../../hooks/queries';
import { AnonymisedLoanProvider } from '../../loans/context';
import { LoanBreadcrumb } from '../../loans/LoanBreadcrumb';
import { SUMMARY_MAP_PATH } from '../../loans/LoanBreadcrumbSummary';
import { LoanDetail, LoanDetailMap } from '../../loans/LoanDetail/LoanDetail';
import { LoanSummaryMap } from '../../loans/LoanSummaryMap';
import { LoanSummaryMetaInfo } from '../../loans/LoanSummaryMetaInfo';
import { LoanSummaryWidgets } from '../../loans/LoanSummaryWidgets';
import { MapPage } from './MapPage';

type PageParams = {
  tradeId: string;
  loanId: string;
  category: string;
};

export const TradeRequestDataLoanPage = () => {
  const { path } = useRouteMatch();
  const { category, loanId, tradeId } = useParams<PageParams>();
  const location = useLocation();
  const isDetail = location.pathname.includes('/detail');

  const { data: loan, isLoading } = useTradeRequestDataLoanQuery({
    tradeRequestId: tradeId,
    loanId,
  });

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>{`Trade ${tradeId}`}</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem linkTo="/trade-requests" title="Trade activity" />
              <BreadcrumbItem linkTo={`/trade-requests/${category}`} title={capitalize(category)} />
              <BreadcrumbItem
                linkTo={`/trade-requests/${category}/${tradeId}`}
                title={`Trade ${tradeId}`}
              />

              {isDetail && (
                <React.Fragment>
                  <BreadcrumbItem
                    linkTo={`/trade-requests/${category}/${tradeId}/loans/${loanId}`}
                    title={`Loan ${loanId}`}
                  />
                  <BreadcrumbItem title="Full record" />
                </React.Fragment>
              )}
              {!isDetail && <BreadcrumbItem title={`Loan ${loanId}`} />}
            </Breadcrumb>
          </div>
          <LoanSummaryMetaInfo loan={loan} />
        </PageHeaderContent>
        <LoanBreadcrumb loan={loan} />
      </PageHeader>

      <PageContent>
        <WidthWrapper>
          <ActivityIndicator contain isActive={isLoading}>
            {loan && (
              <AnonymisedLoanProvider
                value={{
                  loanNumber: loan.loanDetails.loanNumber,
                  loanId: String(loan.id),
                  tradeRequestId: tradeId,
                }}
              >
                <Switch>
                  <Redirect exact from={`${path}/detail`} to={`${path}/detail/grid`} />
                  <PrivateRoute
                    path={`${path}/detail/grid`}
                    render={() => <LoanDetail isLoanLoading={false} loan={loan} />}
                  />
                  <PrivateRoute
                    path={`${path}/detail/${SUMMARY_MAP_PATH}`}
                    render={() => (
                      <MapPage>
                        {({ extraDataToDisplay, setExtraDataToDisplay }) => (
                          <LoanDetailMap
                            extraDataToDisplay={extraDataToDisplay}
                            isLoanLoading={false}
                            loan={loan}
                            onDetailsButtonClick={setExtraDataToDisplay}
                            onMLSButtonClick={setExtraDataToDisplay}
                            onSidebarClose={() => setExtraDataToDisplay(undefined)}
                          />
                        )}
                      </MapPage>
                    )}
                  />

                  <Redirect exact from={path} to={`${path}/summary`} />
                  <PrivateRoute
                    path={`${path}/summary`}
                    render={() => <LoanSummaryWidgets loanSummary={loan} />}
                  />
                  <PrivateRoute
                    path={`${path}/${SUMMARY_MAP_PATH}`}
                    render={() => (
                      <MapPage>
                        {({ extraDataToDisplay, setExtraDataToDisplay }) => (
                          <LoanSummaryMap
                            extraDataToDisplay={extraDataToDisplay}
                            loanSummary={loan}
                            onDetailsButtonClick={setExtraDataToDisplay}
                            onMLSButtonClick={setExtraDataToDisplay}
                            onSidebarClose={() => setExtraDataToDisplay(undefined)}
                          />
                        )}
                      </MapPage>
                    )}
                  />
                </Switch>
              </AnonymisedLoanProvider>
            )}
          </ActivityIndicator>
        </WidthWrapper>
      </PageContent>
    </Page>
  );
};
