import React from 'react';
import { DefaultTheme } from 'styled-components';
import styled, { css, StyledComponentPropsWithRef } from 'styled-components/macro';

type ChainVariantProps = {
  $variant?: 'default' | 'custodial';
};

type LinkStyleProps = ChainVariantProps & {
  $hasError?: boolean;
};

type ChainProps = {
  children?: React.ReactElement | React.ReactElement[];
  variant?: 'default' | 'custodial';
} & StyledComponentPropsWithRef<'div'>;

const chainLinkErrorStyles = (theme: DefaultTheme) => css`
  &::before {
    border-bottom: 1px dashed ${theme.colors.statusRed};
  }
`;

const chainLinkCustodialStyles = (theme: DefaultTheme) => css`
  &::before {
    right: calc(50% + 12px);
    width: calc(100% - 24px);
    border-color: ${theme.colors.white};
  }

  &::after {
    border-color: ${theme.colors.white};
    background-color: ${theme.colors.white};
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
  }
`;

const Link = styled.div<LinkStyleProps>(
  ({ $hasError, $variant, theme }) => css`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      height: 1px;
      right: calc(50% + 8px);
      width: calc(100% - 16px);
      border-bottom: 1px solid ${theme.colors.statusTurquoise};
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      margin-left: -8px;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      background-color: color-mix(
        in srgb,
        ${theme.colors.deprecatedPrimary01},
        ${theme.colors.statusTurquoise} 70%
      );
      border: 1px solid ${theme.colors.statusTurquoise};
      border-radius: 50%;
    }

    ${$variant === 'custodial' && chainLinkCustodialStyles(theme)}
    ${$hasError && chainLinkErrorStyles(theme)}
  `
);

export const ChainLinkTitle = styled.div<ChainVariantProps>(
  ({ $variant, theme }) => css`
    margin-top: 16px;
    color: ${theme.colors.white};
    ${$variant === 'custodial'
      ? theme.typography.headings.headingMedium04
      : theme.typography.bodyText.bodyMedium03Tight}
  `
);

export const ChainLinkText = styled.div`
  color: ${({ theme }) => theme.colors.white70};
  ${({ theme }) => theme.typography.bodyText.bodyRegular03Tight}
`;
export const ChainLinkSubtext = styled.div`
  color: ${({ theme }) => theme.colors.white70};
  ${({ theme }) => theme.typography.bodyText.bodyRegular04}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 32px 0 0;

  ${Link}:first-child {
    &::before {
      display: none;
    }
  }
`;

export const ChainLink = ({ children, hasError, variant }: ChainProps & { hasError?: boolean }) => (
  <Link $variant={variant} $hasError={hasError}>
    {React.Children.map(
      children,
      (child) => child && React.cloneElement(child as React.ReactElement, { $variant: variant })
    )}
  </Link>
);

export const Chain = ({ children, variant = 'default' }: ChainProps) => {
  return (
    <Wrapper>
      {React.Children.map(
        children,
        (child) => child && React.cloneElement(child as React.ReactElement, { variant })
      )}
    </Wrapper>
  );
};
