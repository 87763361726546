import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { DefaultButton } from '../components/Button';

type WrapperProps = {
  $variant?: 'portfolio' | 'pool';
  $width?: number;
  $isInset?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ $isInset, $variant, $width, theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: ${$width ?? 366}px;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.tradeChatButtonPurple};
    padding: 20px 0 0;
    border-radius: 6px 6px 0 0;
    box-shadow: ${theme.shadows.loanPopoverPanel};

    ${$variant === 'pool' &&
    css`
      background: ${theme.colors.lightGreen};
      box-shadow: ${theme.shadows.poolPopoverPanel};
    `}

    ${$isInset &&
    css`
      top: -16px;
      height: calc(100% + 16px);
    `}
  `}
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 16px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.display};
    font-size: 15px;
    font-weight: 700;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 0;
  flex: 1;
  min-height: 0;
`;

export const Scrollable = styled.div`
  overflow: auto;
  min-height: 0;
  flex: 1;
`;

export const CloseButton = styled(DefaultButton)`
  ${({ theme }) => css`
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    color: ${transparentize(0.8, theme.colors.white)};
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${theme.colors.white};
    }
  `}
`;
