import compact from 'lodash/compact';
import React from 'react';
import { InfiniteData } from 'react-query';

import { CompletedTradeRequestsResponse, useCompletedTradeRequestsQuery } from '../hooks/queries';

const getTradeRequestsFromData = (
  data?: InfiniteData<CompletedTradeRequestsResponse | undefined>
) => {
  return compact(data?.pages).flatMap((page) => page.tradeRequests) ?? [];
};

const getTotalCountFromData = (data?: InfiniteData<CompletedTradeRequestsResponse | undefined>) => {
  return data?.pages[data?.pages.length - 1]?.totalCount ?? 0;
};

export const useCompletedTradeRequests = () => {
  const { data, ...query } = useCompletedTradeRequestsQuery();

  const tradeRequestsData = React.useMemo(
    () => ({
      tradeRequests: getTradeRequestsFromData(data),
      totalCount: getTotalCountFromData(data),
    }),
    [data]
  );

  return { ...tradeRequestsData, ...query };
};
