import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import { Key, Pair, Value } from '../components/KeyValue';
import { SummaryColumns, SummaryWrapper } from '../components/Summary';
import { useGetSubmissionsSummaryQuery } from '../hooks/queries/useGetSubmissionsSummaryQuery';
import { formatNumber } from '../utils/formatUtils';

export const SubmissionsSummary = () => {
  const { data: submissionsSummaryData } = useGetSubmissionsSummaryQuery();

  return (
    <Card>
      <SummaryWrapper>
        <SummaryColumns>
          <Pair $align="left" $direction="vertical">
            <Key>Submissions</Key>
            <Value>{formatNumber(submissionsSummaryData?.submissionCount)}</Value>
          </Pair>
          <Divider $orientation="vertical" $flexItem />
          <Pair $align="left" $direction="vertical">
            <Key>Documents Ingested</Key>
            <Value>{formatNumber(submissionsSummaryData?.ingestedCount)}</Value>
          </Pair>
          <Divider $orientation="vertical" $flexItem />
          <Pair $align="left" $direction="vertical">
            <Key>Documents validated</Key>
            <Value>{formatNumber(submissionsSummaryData?.validatedCount)}</Value>
          </Pair>
        </SummaryColumns>
      </SummaryWrapper>
    </Card>
  );
};
