import {
  DelinquencyType,
  GetPortfolioDelinquentLoanTypesByNoteRateDetailsResponse,
  PortfolioLoanRateType,
} from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioDelinquentLoanTypesByNoteRateDetails = async (
  delinquencyType: DelinquencyType,
  rateType: PortfolioLoanRateType,
  noteRate: number
) => {
  const url = makeApiUrl(
    `/portfolio/delinquent/${delinquencyType}/types/${rateType}/rates/${noteRate}`
  );

  return getRequestWithAuth<GetPortfolioDelinquentLoanTypesByNoteRateDetailsResponse>(url);
};
