import { TradeRequestLog } from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import { InfiniteData, useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

const NUMBER_OF_ITEMS_PER_PAGE = 20;

export type TradeRequestTransactionLogsResponse = {
  logs: TradeRequestLog[];
  totalCount: number;
  allIds: TradeRequestLog['id'][];
};

export const useTradeRequestTransactionLogsQuery = (
  tradeRequestId?: string,
  isEnabled?: boolean,
  onSuccess?: (data: InfiniteData<TradeRequestTransactionLogsResponse | undefined>) => void
) => {
  return useInfiniteQuery(
    [QueryKeys.TRADE_REQUEST_TRANSACTION_LOG, tradeRequestId],
    ({ pageParam = 0 }) => {
      const query = new URLSearchParams({
        limit: String(NUMBER_OF_ITEMS_PER_PAGE),
        offset: String(pageParam),
      });
      const url = makeApiUrl(`trade-requests/${tradeRequestId}/logs?${query}`);
      return getRequestWithAuth<TradeRequestTransactionLogsResponse>(url);
    },
    {
      enabled: Boolean(isEnabled),
      onSuccess: (data) => onSuccess?.(data),
      getNextPageParam(lastPage, pages) {
        return lastPage?.logs.length === NUMBER_OF_ITEMS_PER_PAGE
          ? sumBy(pages, 'logs.length')
          : undefined;
      },
    }
  );
};
