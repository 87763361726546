import { formatNameWithInitial } from '../trades/tradeRequestsUtils';
import { AvatarWithFallback } from './AvatarWithFallback';
import { ButtonIcon } from './ButtonIcon';
import { CloseLineIcon } from './icons';
import * as Styles from './RecipientCard.styles';

type RecipientCardProps = {
  firstName: string;
  lastName: string;
  organization?: string;
  callback?: () => void;
  imgSrc?: string;
  size?: 'small' | 'large';
  variant?: 'default' | 'light';
};

export const RecipientCard = ({
  callback,
  firstName,
  imgSrc,
  lastName,
  organization,
  size = 'large',
  variant = 'default',
}: RecipientCardProps) => (
  <Styles.Wrapper $variant={variant}>
    {size === 'large' && (
      <AvatarWithFallback firstName={firstName} lastName={lastName} src={imgSrc} />
    )}
    <Styles.TextWrapper>
      <Styles.NameText>{formatNameWithInitial(lastName, firstName)}</Styles.NameText>
      {size === 'large' && organization && (
        <Styles.InstitutionText>{organization}</Styles.InstitutionText>
      )}
    </Styles.TextWrapper>
    {size === 'large' && (
      <Styles.IconWrapper>
        {callback && (
          <ButtonIcon size="small" onClick={callback}>
            <CloseLineIcon />
          </ButtonIcon>
        )}
      </Styles.IconWrapper>
    )}
  </Styles.Wrapper>
);
