import styled from 'styled-components/macro';

export const BarValue = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

type BarProps = {
  $width?: number;
  $offset?: number;
};

export const Bar = styled.div<BarProps>`
  width: 72px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.white05};
  border-radius: 2px;
  overflow: hidden;

  &::before {
    display: block;
    position: relative;
    content: '';
    height: 100%;
    width: ${(props) => props.$width ?? 100}%;
    left: ${(props) => props.$offset ?? 0}%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
