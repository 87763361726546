import styled, { css } from 'styled-components/macro';

export const Recipients = styled.div`
  width: 100%;
  display: flex;
`;

type InitialsProps = {
  $zIndex: number;
};

export const Initials = styled.span<InitialsProps>(
  ({ $zIndex }) => css`
    position: relative;
    display: inline-block;
    margin-right: -4px;
    z-index: ${$zIndex};
  `
);
