import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOPercent,
  MISMOString,
} from './types';

export enum LateChargeType {
  FlatDollarAmount = 'FlatDollarAmount',
  NoLateCharges = 'NoLateCharges',
  Other = 'Other',
  PercentageOfDelinquentInterest = 'PercentageOfDelinquentInterest',
  PercentageOfNetPayment = 'PercentageOfNetPayment',
  PercentageOfPrincipalBalance = 'PercentageOfPrincipalBalance',
  PercentageOfTotalPayment = 'PercentageOfTotalPayment',
  PercentOfPrincipalAndInterest = 'PercentOfPrincipalAndInterest',
}

export type LateChargeRule = MISMOEntity &
  Partial<{
    lateChargeAdditionalDescription: MISMOString;
    lateChargeAmount: MISMOAmount;
    lateChargeGracePeriodDaysCount: MISMOCount;
    lateChargeMaximumAmount: MISMOAmount;
    lateChargeMinimumAmount: MISMOAmount;
    lateChargeRatePercent: MISMOPercent;
    lateChargeType: LateChargeType;
    lateChargeTypeOtherDescription: MISMOString;
  }>;
