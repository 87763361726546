import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum ExpenseType {
  Alimony = 'Alimony',
  CarMaintenance = 'CarMaintenance',
  CharitableContributions = 'CharitableContributions',
  ChildCare = 'ChildCare',
  ChildSupport = 'ChildSupport',
  Clothing = 'Clothing',
  DryCleaning = 'DryCleaning',
  Entertainment = 'Entertainment',
  GroceryToiletry = 'GroceryToiletry',
  HealthInsurance = 'HealthInsurance',
  JobRelatedExpenses = 'JobRelatedExpenses',
  Medical = 'Medical',
  MiscellaneousLivingExpenses = 'MiscellaneousLivingExpenses',
  NetRentalExpense = 'NetRentalExpense',
  Other = 'Other',
  PayrollInsuranceDeduction = 'PayrollInsuranceDeduction',
  PayrollMiscellaneousDeductions = 'PayrollMiscellaneousDeductions',
  PayrollProfitSharingDeduction = 'PayrollProfitSharingDeduction',
  PayrollRetirementDeduction = 'PayrollRetirementDeduction',
  PayrollTaxDeduction = 'PayrollTaxDeduction',
  SeparateMaintenanceExpense = 'SeparateMaintenanceExpense',
  UnionDues = 'UnionDues',
}

export type Expense = MISMOEntity &
  Partial<{
    alimonyOwedToName: MISMOString;
    expenseDescription: MISMOString;
    expenseMonthlyPaymentAmount: MISMOAmount;
    expenseRemainingTermMonthsCount: MISMOCount;
    expenseType: ExpenseType;
    expenseTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
