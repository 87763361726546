import styled, { css } from 'styled-components';

import {
  GroupedHeaderRow as GroupedHeaderRowComponent,
  HeaderCell as HeaderCellComponent,
  HeaderRow as HeaderRowComponent,
} from '../components/Table';
import { ProgressWrapper } from '../documents/submissions/ProgressBar.styles';

export const StatusComplete = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.greenLight};
  `}
`;

export const RowWrapper = styled.div<{ height: number; offsetY: number }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 16px;
  right: 0;
  height: ${(props) => props.height}px;
  transform: translateY(${(props) => props.offsetY}px);
`;

export const HeaderRow = styled(HeaderRowComponent)`
  pointer-events: auto;
`;

export const GroupedHeaderRow = styled(GroupedHeaderRowComponent)`
  grid-template-columns: repeat(20, 1fr);
`;

export const HeaderCell = styled(HeaderCellComponent)<{ onClick?: () => void }>`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  align-items: center;
  display: flex;
`;

export const ProgressBarFillerMixin = css<{ $isComplete?: boolean }>`
  background-color: ${(props) =>
    props.$isComplete ? props.theme.colors.statusGreen : props.theme.colors.statusOrange};
  border-radius: 0;
`;

export const ProgressBar = styled(ProgressWrapper)<{ $isComplete?: boolean }>`
  progress {
    border-radius: 0;
    max-width: 80%;

    background-color: ${(props) => props.theme.colors.primary05};
    &::-webkit-progress-bar {
      ${ProgressBarFillerMixin};
      background-color: ${(props) => props.theme.colors.primary05};
    }

    &::-webkit-progress-value {
      ${ProgressBarFillerMixin};
    }
    &::-moz-progress-bar {
      ${ProgressBarFillerMixin};
    }
  }
`;
