import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const useDeletePortfolioHomeAnalyticMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(api.deletePortfolioHomeAnalytic, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries([QueryKeys.PORTFOLIO_HOME_ANALYTICS]);
      queryClient.invalidateQueries([QueryKeys.PORTFOLIO_HOME_ANALYTIC, id]);
    },
  });
};
