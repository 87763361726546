import { Identifier } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../api/pools';
import { QueryKeys } from './types';

/**
 * Mutation for updating a loan pool
 */
export const usePoolUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ loanIds, poolId }: { poolId: Identifier; loanIds: Identifier[] }) => {
      return api.updatePool(poolId, { loanIds });
    },
    {
      onSuccess: (_, { poolId }) => {
        queryClient.invalidateQueries([QueryKeys.POOL, poolId]);
        queryClient.invalidateQueries([QueryKeys.POOL_LOANS, poolId]);

        // TODO: Remove once we remove the Pool table column
        queryClient.invalidateQueries([QueryKeys.PORTFOLIO_LOANS]);
      },
    }
  );
};
