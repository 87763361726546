import { ArchitecturalDesign } from './architecturalDesign';
import { ExteriorFeature } from './exteriorFeature';
import { ExteriorWall } from './exteriorWall';
import { Foundation } from './foundation';
import { Insulation } from './insulation';
import { Roof } from './roof';
import {
  MISMOCount,
  MISMOEntity,
  MISMOIndicator,
  MISMONumeric,
  MISMOString,
} from './types';
import { Window } from './window';

enum RentControlStatusType {
  No = 'No',
  Yes = 'Yes',
}

enum StructureStateType {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
  NotStarted = 'NotStarted',
}

export type Structure = MISMOEntity &
  Partial<{
    accessoryUnitCount: MISMOCount;
    additionsDescription: MISMOString;
    ageYearsCount: MISMOCount;
    atticExistsIndicator: MISMOIndicator;
    buildingCount: MISMOCount;
    characteristicsAffectMarketabilityDescription: MISMOString;
    elevatorCount: MISMOCount;
    grossBuildingAreaSquareFeetNumber: MISMONumeric;
    grossLivingAreaSquareFeetDataSourceDescription: MISMOString;
    livingUnitCount: MISMOCount;
    rentControlDescription: MISMOString;
    rentControlStatusType: RentControlStatusType;
    storiesCount: MISMOCount;
    structureConditionDescription: MISMOString;
    structureConstructionStatusDescription: MISMOString;
    structureNeverOccupiedIndicator: MISMOIndicator;
    structureSingleFamilyResidenceIndicator: MISMOIndicator;
    structureStateDescription: MISMOString;
    structureStateType: StructureStateType;
    architecturalDesign: ArchitecturalDesign;
    // energyEfficiencyConsiderations: EnergyEfficiencyConsideration[];
    exteriorFeatures: ExteriorFeature[];
    exteriorWalls: ExteriorWall[];
    foundations: Foundation[];
    insulation: Insulation;
    roof: Roof;
    // structureAnalyses: StructureAnalysis[];
    windows: Window[];
  }>;
