import {
  CounterpartyType,
  CounterpartyWithSummary,
  getLatestCounterpartyStatusFromActions,
  InboundTradeRequestWithSummary,
  OutboundTradeRequestWithSummary,
  Pool,
  TradeCounterpartyActionType,
  TradeCounterpartyActionWithSummary,
  TradeCounterpartyStatus,
  TradeRequest,
  TradeRequestsSummary,
  TradeRequestWithSummary,
} from '@plus-platform/shared';

import { TradeRequestCreationMutation } from '../hooks/queries';
import {
  getRequestWithAuth,
  makeApiUrl,
  postDownloadRequestWithAuth,
  postFormDataRequestWithAuth,
} from '../utils/apiUtils';
import { EMPTY_VALUE, formatDate, formatDefaultValue, formatTime } from '../utils/formatUtils';
import { TradeRequestStep } from './types';

export const formatPoolSaleDate = (status?: string, saleDate?: Date) => {
  // TODO: Determine trade status based on counterparties
  return status === 'TRADE_COMPLETED' && saleDate
    ? String(formatDefaultValue(formatDate(saleDate)))
    : EMPTY_VALUE;
};

export const formatNameWithInitial = (lastName: string, firstName?: string) => {
  return firstName ? `${lastName}, ${firstName.slice(0, 1)}` : lastName;
};

export const formatTradeCreatedAtDate = (createdAt: Date) => {
  return formatDate(createdAt, {
    dateStyle: 'medium',
  });
};

export const formatTradeCreatedAtTime = (createdAt: Date) => {
  return formatTime(createdAt, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

export const getTradeRequestsSummary = () =>
  getRequestWithAuth<TradeRequestsSummary>(makeApiUrl('trade-requests/summary'));

export const getTradeRequestSummary = <T = TradeRequestWithSummary>(
  tradeRequestId: string,
  category: string
) => getRequestWithAuth<T>(makeApiUrl(`trade-requests/${tradeRequestId}/summary/${category}`));

export const getInboundTradeRequestsSummary = () =>
  getRequestWithAuth<InboundTradeRequestWithSummary[]>(
    makeApiUrl('trade-requests/inbound/summary')
  );

export const getOutboundTradeRequestsSummary = () =>
  getRequestWithAuth<OutboundTradeRequestWithSummary[]>(
    makeApiUrl('trade-requests/outbound/summary')
  );

export const getTradeRequestsByPoolId = (poolId: Pool['id']) =>
  getRequestWithAuth<TradeRequest[]>(makeApiUrl(`trade-requests/${poolId}`));

export const createTradeRequest = (data: TradeRequestCreationMutation) => {
  const body = new FormData();

  body.append('poolId', data.poolId);

  data.counterparties.forEach((id) => body.append('counterparties', String(id)));

  data.stipulations.forEach((stipulation) => body.append('details[stipulations]', stipulation));

  return postFormDataRequestWithAuth<TradeRequest>(makeApiUrl('trade-requests'), { body });
};

export const exportTradeRequestData = async (tradeRequestId: TradeRequest['id']) => {
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/export`);

  return postDownloadRequestWithAuth(url);
};

export const getTradeCounterpartyActivityStepCounts = (
  counterparties: CounterpartyWithSummary[]
  // TODO: Implement counts based on direct messages and quotes
  // messages: TradeMessage[],
  // quotes: TradeRequestQuote[],
) => {
  const actionCounts = counterparties.reduce(
    (acc, { actions }) => {
      const actionTypes = actions.map((action) => action.type);
      if (
        actionTypes.some((actionType) =>
          [
            TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST,
            TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST,
            TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST,
          ].includes(actionType)
        )
      ) {
        acc.EXPRESSION_OF_INTEREST++;
      }

      if (
        actionTypes.some((actionType) =>
          [
            TradeCounterpartyActionType.REQUEST_DATA_REQUEST,
            TradeCounterpartyActionType.ACCEPT_DATA_REQUEST,
            TradeCounterpartyActionType.DENY_DATA_REQUEST,
          ].includes(actionType)
        )
      ) {
        acc.DATA_REQUEST++;
      }

      return acc;
    },
    {
      EXPRESSION_OF_INTEREST: 0,
      DATA_REQUEST: 0,
    }
  );

  return {
    ...actionCounts,
    // TODO: Determine if there are any direct messages from counterparty
    // 1) Get direct messages for trade, 2) Filter by counterparty user id
    DIRECT_MESSAGE: 0,
    // TODO: Determine if there are any quotes from counterparty
    // 1) Get quotes for trade, 2) Filter by counterparty user id
    QUOTE: 0,
  };
};

export const canCounterpartyExpressInterest = (actions: TradeCounterpartyActionWithSummary[]) => {
  if (actions.length === 0) {
    return true;
  }

  const actionTypes = actions.map((action) => action.type);

  const areAllRequestsDeclined = actionTypes.every(
    (actionType) => actionType === TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST
  );
  const hasAcceptedRequest = actionTypes.some(
    (actionType) => actionType === TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST
  );
  const hasPendingRequest =
    !hasAcceptedRequest &&
    actionTypes.some(
      (actionType) => actionType === TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST
    );

  return !hasAcceptedRequest && !hasPendingRequest && areAllRequestsDeclined;
};

export const canCounterpartyRequestData = (actions: TradeCounterpartyActionWithSummary[]) => {
  if (actions.length === 0) {
    return true;
  }

  return [
    TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST,
    TradeCounterpartyActionType.DENY_DATA_REQUEST,
  ].includes(actions[actions.length - 1].type);
};

const DEFAULT_STEP_STATUSES = {
  isActive: {
    [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
    [TradeRequestStep.DIRECT_MESSAGE]: false,
    [TradeRequestStep.DATA_REQUEST]: false,
    [TradeRequestStep.QUOTE]: false,
  },
  isPending: {
    [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
    [TradeRequestStep.DIRECT_MESSAGE]: false,
    [TradeRequestStep.DATA_REQUEST]: false,
    [TradeRequestStep.QUOTE]: false,
  },
  isComplete: {
    [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
    [TradeRequestStep.DIRECT_MESSAGE]: false,
    [TradeRequestStep.DATA_REQUEST]: false,
    [TradeRequestStep.QUOTE]: false,
  },
};

const STATUS_TO_STEPS_MAPPING: Partial<
  Record<
    TradeCounterpartyStatus,
    Record<'isActive' | 'isPending' | 'isComplete', Record<TradeRequestStep, boolean>>
  >
> = {
  [TradeCounterpartyStatus.EXPRESSED_INTEREST]: {
    isActive: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isPending: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isComplete: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
  },

  [TradeCounterpartyStatus.EXPRESSED_INTEREST_ACCEPTED]: {
    isActive: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: true,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: false,
    },
    isPending: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isComplete: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
  },

  [TradeCounterpartyStatus.EXPRESSED_INTEREST_DECLINED]: {
    isActive: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isPending: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isComplete: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
  },

  [TradeCounterpartyStatus.DATA_REQUESTED]: {
    isActive: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: true,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isPending: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: false,
    },
    isComplete: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
  },

  [TradeCounterpartyStatus.DATA_REQUEST_ACCEPTED]: {
    isActive: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: true,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: true,
    },
    isPending: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: false,
    },
    isComplete: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: false,
    },
  },

  [TradeCounterpartyStatus.DATA_REQUEST_DECLINED]: {
    isActive: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: true,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: false,
    },
    isPending: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: false,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: false,
      [TradeRequestStep.QUOTE]: false,
    },
    isComplete: {
      [TradeRequestStep.EXPRESSION_OF_INTEREST]: true,
      [TradeRequestStep.DIRECT_MESSAGE]: false,
      [TradeRequestStep.DATA_REQUEST]: true,
      [TradeRequestStep.QUOTE]: false,
    },
  },
};

export const getSellerCounterparty = (counterparties: CounterpartyWithSummary[]) => {
  return counterparties.find((counterparty) => counterparty.type === CounterpartyType.SELLER);
};

export const getStepStatuses = (tradeRequest: InboundTradeRequestWithSummary) => {
  const sellerCounterparty = getSellerCounterparty(tradeRequest.counterparties);
  if (!sellerCounterparty) {
    return DEFAULT_STEP_STATUSES;
  }

  const currentStatus = getLatestCounterpartyStatusFromActions(sellerCounterparty.actions);
  if (!currentStatus) {
    return DEFAULT_STEP_STATUSES;
  }

  return STATUS_TO_STEPS_MAPPING[currentStatus] || DEFAULT_STEP_STATUSES;
};

export const isTradeStepComplete = (status: TradeCounterpartyStatus, step: TradeRequestStep) => {
  const stepStatuses = STATUS_TO_STEPS_MAPPING[status] || DEFAULT_STEP_STATUSES;
  return stepStatuses.isComplete[step];
};
