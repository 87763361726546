import { Name } from './name';
import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOURI,
} from './types';

enum AliasType {
  AlsoKnownAs = 'AlsoKnownAs',
  FormerlyKnownAs = 'FormerlyKnownAs',
  NowKnownAs = 'NowKnownAs',
  Other = 'Other',
}

export type Alias = MISMOEntity &
  Partial<{
    aliasAccountIdentifier: MISMOIdentifier;
    'aliasAccountIdentifier@IdentifierOwnerURI': MISMOURI;
    aliasCreditorName: MISMOString;
    aliasTaxReturnIndicator: MISMOIndicator;
    aliasType: AliasType;
    aliasTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    name: Name;
    // taxpayerIdentifiers: TaxpayerIdentifier[];
  }>;
