import {
  PortfolioBreakdown,
  PortfolioBreakdownRateType,
  PortfolioLoanRateType,
} from '@plus-platform/shared';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import sumBy from 'lodash/sumBy';

import { mapLoansCountByNoteRateForChart } from '../helpers';

export const LARGEST_BUBBLE_SIZE = 6;
export const SMALLEST_BUBBLE_SIZE = 2;

type ChartData = {
  noteRate: number;
  loansCount: number;
};

export type LoanTypesByDefaultChartData = ChartData & {
  rateType: PortfolioLoanRateType;
};

export type LoanTypesByNoteRateChartData = ChartData & {
  rateType: PortfolioBreakdownRateType;
  currentMarketRate: number;
};

export const mapBreakdownLoansCountByNoteRateForChart = (
  data?: PortfolioBreakdown['loansCountByNoteRate']
): LoanTypesByNoteRateChartData[] => {
  if (!data) {
    return [];
  }

  const dataForRateTypes = mapLoansCountByNoteRateForChart(data);

  const allData = Object.entries(groupBy(dataForRateTypes, 'noteRate')).map(
    ([noteRate, groupedItems]) => {
      return {
        noteRate: Number(noteRate),
        loansCount: sumBy(groupedItems, 'loansCount'),
        currentMarketRate: 0,
        rateType: PortfolioBreakdownRateType.ALL,
      };
    }
  );

  return [...allData, ...dataForRateTypes];
};

export const mapDefaultedLoansCountByNoteRateForChart = (
  data?: PortfolioBreakdown['delinquentLoansCountByNoteRate']
): Partial<
  Record<keyof PortfolioBreakdown['delinquentLoansCountByNoteRate'], LoanTypesByDefaultChartData[]>
> => {
  if (!data) {
    return {};
  }

  return mapValues(data, (dataByRateType = {}) => {
    return Object.entries(dataByRateType).flatMap(([rateType, dataItem]) => {
      return Object.entries(dataItem).map(([noteRate, loansCount]) => {
        return {
          noteRate: Number(noteRate),
          loansCount,
          rateType: rateType as PortfolioLoanRateType,
        };
      });
    });
  });
};
