import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from '../types';

enum FHAAlimonyLiabilityTreatmentType {
  AdditionToDebt = 'AdditionToDebt',
  ReductionToIncome = 'ReductionToIncome',
}

enum FHAInsuranceProgramType {
  FHAUpfront = 'FHAUpfront',
  RBP = 'RBP',
  Section530 = 'Section530',
}

enum GovernmentLoanApplicationType {
  HomeLoanGuaranty = 'HomeLoanGuaranty',
  NationalHousingAct = 'NationalHousingAct',
}

enum GovernmentRefinanceType {
  CashOutVA = 'CashOutVA',
  FullDocumentation = 'FullDocumentation',
  InterestRateReductionRefinanceLoan = 'InterestRateReductionRefinanceLoan',
  Other = 'Other',
  StreamlineWithAppraisal = 'StreamlineWithAppraisal',
  StreamlineWithoutAppraisal = 'StreamlineWithoutAppraisal',
}

export enum SectionOfActType {
  SectionOfActType184 = '184',
  '184A' = '184A',
  '201S' = '201S',
  '201SD' = '201SD',
  '201U' = '201U',
  '201UD' = '201UD',
  '203B' = '203B',
  '203B2' = '203B2',
  '203B241' = '203B241',
  '203B251' = '203B251',
  '203H' = '203H',
  '203I' = '203I',
  '203K' = '203K',
  '203K241' = '203K241',
  '203K251' = '203K251',
  '204A' = '204A',
  '204GFHAGoodNeighborNextDoor' = '204GFHAGoodNeighborNextDoor',
  '204GSingleFamilyPropertyDisposition' = '204GSingleFamilyPropertyDisposition',
  SectionOfActType213 = '213',
  SectionOfActType220 = '220',
  SectionOfActType221 = '221',
  '221D2' = '221D2',
  '221D2251' = '221D2251',
  SectionOfActType222 = '222',
  '223E' = '223E',
  SectionOfActType233 = '233',
  '234C' = '234C',
  '234C251' = '234C251',
  SectionOfActType235 = '235',
  SectionOfActType237 = '237',
  SectionOfActType240 = '240',
  SectionOfActType245 = '245',
  '245A' = '245A',
  SectionOfActType247 = '247',
  SectionOfActType248 = '248',
  SectionOfActType251 = '251',
  SectionOfActType255 = '255',
  SectionOfActType256 = '256',
  SectionOfActType257 = '257',
  SectionOfActType26101 = '26101',
  SectionOfActType26102 = '26102',
  SectionOfActType26201 = '26201',
  SectionOfActType26202 = '26202',
  SectionOfActType27001 = '27001',
  SectionOfActType27002 = '27002',
  SectionOfActType27003 = '27003',
  SectionOfActType3710 = '3710',
  '502USDARuralDevelopmentGuaranteedHousingLoan' = '502USDARuralDevelopmentGuaranteedHousingLoan',
  SectionOfActType513 = '513',
  SectionOfActType729 = '729',
  '8Y' = '8Y',
  'FHATitleIInsuranceForManufacturedHomes' = 'FHATitleIInsuranceForManufacturedHomes',
  'FHATitleIInsuranceForPropertyImprovementLoans' = 'FHATitleIInsuranceForPropertyImprovementLoans',
  'Other' = 'Other',
}

enum VAAppraisalType {
  HUDConversion = 'HUDConversion',
  LenderAppraisal = 'LenderAppraisal',
  ManufacturedHome = 'ManufacturedHome',
  MasterCertificateOfReasonableValueCase = 'MasterCertificateOfReasonableValueCase',
  Other = 'Other',
  PropertyManagementCase = 'PropertyManagementCase',
  SingleProperty = 'SingleProperty',
}

enum VAFundingFeeExemptionType {
  CompletelyExempt = 'CompletelyExempt',
  NotExempt = 'NotExempt',
  Other = 'Other',
  PartiallyExempt = 'PartiallyExempt',
}

enum VALoanProcedureType {
  Automatic = 'Automatic',
  AutomaticInterestRateReductionRefinanceLoan = 'AutomaticInterestRateReductionRefinanceLoan',
  Other = 'Other',
  VAPriorApproval = 'VAPriorApproval',
}

enum VALoanProgramType {
  Other = 'Other',
  VADirectHomeLoanProgramForNativeAmericans = 'VADirectHomeLoanProgramForNativeAmericans',
  VAGuaranteedCondominiumUnit = 'VAGuaranteedCondominiumUnit',
  VAGuaranteedFarmResidenceConstruction = 'VAGuaranteedFarmResidenceConstruction',
  VAGuaranteedFarmResidenceEnergyEfficiencyImprovements = 'VAGuaranteedFarmResidenceEnergyEfficiencyImprovements',
  VAGuaranteedFarmResidencePurchase = 'VAGuaranteedFarmResidencePurchase',
  VAGuaranteedFarmResidenceRepairAlternationOrImprovement = 'VAGuaranteedFarmResidenceRepairAlternationOrImprovement',
  VAGuaranteedHomePurchaseOrConstruction = 'VAGuaranteedHomePurchaseOrConstruction',
  VAGuaranteedHomePurchaseWithEnergyEfficiencyImprovements = 'VAGuaranteedHomePurchaseWithEnergyEfficiencyImprovements',
  VAGuaranteedHomeRefinanceWithEnergyEfficiencyImprovements = 'VAGuaranteedHomeRefinanceWithEnergyEfficiencyImprovements',
  VAGuaranteedManufacturedHomeOrLotPurchaseOrRefinance = 'VAGuaranteedManufacturedHomeOrLotPurchaseOrRefinance',
  VAGuaranteedRefinance = 'VAGuaranteedRefinance',
  VAGuaranteedRefinanceLiensSecuredByVADwelling = 'VAGuaranteedRefinanceLiensSecuredByVADwelling',
}

enum VALoanTerminationType {
  AcquiredByVA = 'AcquiredByVA',
  DebtPlusCost = 'DebtPlusCost',
  DeedInLieu = 'DeedInLieu',
  GuarantyCancelled = 'GuarantyCancelled',
  LiquidatedWithNoClaim = 'LiquidatedWithNoClaim',
  Matured = 'Matured',
  Other = 'Other',
  PaidInFull = 'PaidInFull',
  PropertyConveyance = 'PropertyConveyance',
  ShortSale = 'ShortSale',
}

enum VATitleVestingType {
  JointTwoOrMoreVeterans = 'JointTwoOrMoreVeterans',
  JointVeteranAndNonVeteran = 'JointVeteranAndNonVeteran',
  Other = 'Other',
  Veteran = 'Veteran',
  VeteranAndSpouse = 'VeteranAndSpouse',
}

export type GovernmentLoan = MISMOEntity &
  Partial<{
    agencyProgramDescription: MISMOString;
    borrowerFinancedFHADiscountPointsAmount: MISMOAmount;
    borrowerFundingFeePercent: MISMOPercent;
    borrowerHomeInspectionChosenIndicator: MISMOIndicator;
    borrowerPaidFHA_VAClosingCostsAmount: MISMOAmount;
    borrowerPaidFHA_VAClosingCostsPercent: MISMOPercent;
    daysToFHA_MIEligibilityCount: MISMOCount;
    FHA_MIPremiumRefundAmount: MISMOAmount;
    FHAAlimonyLiabilityTreatmentType: FHAAlimonyLiabilityTreatmentType;
    FHAAnniversaryDate: MISMODate;
    FHAAnnualPremiumAmount: MISMOAmount;
    FHAAnnualPremiumPercent: MISMOPercent;
    FHAAssignmentDate: MISMODate;
    FHAAutomatedDataProcessingIdentifier: MISMOIdentifier;
    FHACoverageRenewalRatePercent: MISMOPercent;
    FHAEndorsementDate: MISMODate;
    FHAEnergyRelatedRepairsOrImprovementsAmount: MISMOAmount;
    FHAGeneralServicesAdministrationIdentifier: MISMOIdentifier;
    FHAInsuranceProgramType: FHAInsuranceProgramType;
    FHALimitedDenialParticipationIdentifier: MISMOIdentifier;
    FHALoanLenderIdentifier: MISMOIdentifier;
    FHALoanSponsorIdentifier: MISMOIdentifier;
    FHANonOwnerOccupancyRiderRule248Indicator: MISMOIndicator;
    FHAPendingPremiumAmount: MISMOAmount;
    FHAPendingPremiumChangeDate: MISMODate;
    FHAPremiumAnniversaryYearToDateRemittanceAmount: MISMOAmount;
    FHARefinanceInterestOnExistingLienAmount: MISMOAmount;
    FHARefinanceOriginalExistingFHACaseIdentifier: MISMOIdentifier;
    FHARefinanceOriginalExistingUpfrontMIPremiumAmount: MISMOAmount;
    FHAUnderwriterComputerizedHomesUnderwritingSystemIdentifier: MISMOIdentifier;
    FHAUpfrontPremiumAmount: MISMOAmount;
    FHAUpfrontPremiumPercent: MISMOPercent;
    governmentLoanApplicationType: GovernmentLoanApplicationType;
    governmentMortgageCreditCertificateAmount: MISMOAmount;
    governmentRefinanceType: GovernmentRefinanceType;
    governmentRefinanceTypeOtherDescription: MISMOString;
    HUDAdequateAvailableAssetsIndicator: MISMOIndicator;
    HUDAdequateEffectiveIncomeIndicator: MISMOIndicator;
    HUDCreditCharacteristicsIndicator: MISMOIndicator;
    HUDStableEffectiveIncomeIndicator: MISMOIndicator;
    masterCertificateOfReasonableValueIdentifier: MISMOIdentifier;
    otherPartyPaidFHA_VAClosingCostsAmount: MISMOAmount;
    otherPartyPaidFHA_VAClosingCostsPercent: MISMOPercent;
    previousVAHomeLoanIndicator: MISMOIndicator;
    propertyEnergyEfficientHomeIndicator: MISMOIndicator;
    ruralHousingConditionalGuarantyExpirationDate: MISMODate;
    ruralHousingConditionalGuarantyInterestRatePercent: MISMOPercent;
    sectionOfActType: SectionOfActType;
    sectionOfActTypeOtherDescription: MISMOString;
    sellerPaidFHA_VAClosingCostsPercent: MISMOPercent;
    soldUnderHUDSingleFamilyPropertyDispositionProgramIndicator: MISMOIndicator;
    USDA198021RuralHousingAdjustedAnnualHouseholdIncomeAmount: MISMOAmount;
    VAAppraisalType: VAAppraisalType;
    VAAppraisalTypeOtherDescription: MISMOString;
    VABorrowerCoBorrowerMarriedIndicator: MISMOIndicator;
    VAClaimFolderIdentifier: MISMOIdentifier;
    VAEntitlementAmount: MISMOAmount;
    VAEntitlementIdentifier: MISMOIdentifier;
    VAFundingFeeExemptionType: VAFundingFeeExemptionType;
    VAFundingFeeExemptionTypeOtherDescription: MISMOString;
    VAHouseholdSizeCount: MISMOCount;
    VALoanGuarantyCertificateEffectiveDate: MISMODate;
    VALoanProcedureType: VALoanProcedureType;
    VALoanProcedureTypeOtherDescription: MISMOString;
    VALoanProgramType: VALoanProgramType;
    VALoanProgramTypeOtherDescription: MISMOString;
    VALoanTerminationDate: MISMODate;
    VALoanTerminationType: VALoanTerminationType;
    VALoanTerminationTypeOtherDescription: MISMOString;
    VAMaintenanceExpenseMonthlyAmount: MISMOAmount;
    VAReasonableValueImprovementsCompletionIndicator: MISMOIndicator;
    VAResidualIncomeAmount: MISMOAmount;
    VAResidualIncomeGuidelineAmount: MISMOAmount;
    VATitleVestingType: VATitleVestingType;
    VATitleVestingTypeOtherDescription: MISMOString;
    VAUtilityExpenseMonthlyAmount: MISMOAmount;
  }>;
