import { DocumentCategoryType, Loan } from '@plus-platform/shared';
import React from 'react';

import { BodyTextButton } from '../../../components/BodyTextButton';
import { Card } from '../../../components/Card';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Key, Pair, TitleKey, Value } from '../../../components/KeyValue';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import { EMPTY_VALUE, formatDefaultValue } from '../../../utils/formatUtils';
import * as Styles from '../LoanDetail.styles';

type LoanDetailServicerProps = {
  loan: Loan;
  setDocumentCategory: (documentCategory: DocumentCategoryType) => void;
  isAnonymised: boolean;
  isChatActive: boolean;
  onChatClick?: () => void;
  refs: Record<string, React.MutableRefObject<HTMLDivElement | null>>;
};

const LoanDetailServicer = React.forwardRef<HTMLDivElement, LoanDetailServicerProps>(
  ({ isAnonymised, isChatActive, loan, onChatClick, refs, setDocumentCategory }, ref) => {
    return (
      <Card $hasPadding id="servicer" ref={ref} data-testid="LoanDetail_Servicer">
        <Heading $size="medium">Servicer</Heading>
        <WidgetSectionWrapper>
          <WidgetSection>
            <Heading $size="small">Exception report</Heading>
            <Pair $size="large" $align="right">
              <Key>Company Name</Key>
              <Value>{formatDefaultValue(loan?.servicer?.servicer?.name)}</Value>
            </Pair>

            <Pair $size="large" $align="right">
              <Key>SPOC Group</Key>
              <Value>{formatDefaultValue(loan?.servicer?.servicer?.spocName)}</Value>
            </Pair>

            <Pair $size="large" $align="right">
              <Key>Phone</Key>
              <Value>{formatDefaultValue(loan?.servicer?.servicer?.companyPhoneNumber)}</Value>
            </Pair>

            <Pair $size="large" $align="right">
              <Key>{!isAnonymised && <>Servicer history</>}</Key>

              {/*TODO: Add link to servicer history*/}
              <Value>{EMPTY_VALUE}</Value>
            </Pair>

            <Pair $size="large" $align="right">
              <Key>{!isAnonymised && <>Servicer</>}</Key>
              <Value>
                {onChatClick && (
                  <BodyTextButton disabled={!isChatActive} onClick={onChatClick}>
                    Chat now
                  </BodyTextButton>
                )}
              </Value>
            </Pair>
          </WidgetSection>

          <Divider $flexItem $orientation="vertical" />

          <WidgetSection>
            <Heading $size="small">Performing servicer</Heading>

            <Styles.AdditionalSection>
              <TitleKey>Company Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Company Name</Key>
                <Value>{formatDefaultValue(loan?.servicer?.servicer?.companyName)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Identifier</Key>
                <Value>{formatDefaultValue(loan?.servicer?.servicer?.companyIdentifier)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Address</Key>
                <Value>{formatDefaultValue(loan?.servicer?.servicer?.companyMailingAddress)}</Value>
              </Pair>
            </Styles.AdditionalSection>

            <Styles.AdditionalSection>
              <TitleKey>SPOC Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Name</Key>
                <Value>{formatDefaultValue(loan?.servicer?.servicer?.spocName)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Employee Identifier</Key>
                <Value>{formatDefaultValue(loan?.servicer?.servicer?.spocIdentifier)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Phone</Key>
                <Value>{formatDefaultValue(loan?.servicer?.servicer?.spocPhoneNumber)}</Value>
              </Pair>
            </Styles.AdditionalSection>
          </WidgetSection>

          <Divider $flexItem $orientation="vertical" />

          <WidgetSection>
            <Heading $size="small">Default Servicing Claims</Heading>

            <Styles.AdditionalSection>
              <TitleKey>Company Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Company Name</Key>
                <Value>
                  {formatDefaultValue(loan?.servicer?.servicer?.defaultServicingClaimsCompanyName)}
                </Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Phone Number</Key>
                <Value>
                  {formatDefaultValue(
                    loan?.servicer?.servicer?.defaultServicingClaimsCompanyPhoneNumber
                  )}
                </Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Mailing Address</Key>
                <Value>
                  {formatDefaultValue(
                    loan?.servicer?.servicer?.defaultServicingClaimsCompanyMailingAddress
                  )}
                </Value>
              </Pair>
            </Styles.AdditionalSection>

            <Styles.AdditionalSection>
              <TitleKey>SPOC Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Name</Key>
                <Value>
                  {formatDefaultValue(loan?.servicer?.servicer?.defaultServicingClaimsSpocName)}
                </Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Employee Identifier</Key>
                <Value>
                  {formatDefaultValue(
                    loan?.servicer?.servicer?.defaultServicingClaimsSpocIdentifier
                  )}
                </Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Phone</Key>
                <Value>
                  {formatDefaultValue(
                    loan?.servicer?.servicer?.defaultServicingClaimsSpocPhoneNumber
                  )}
                </Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Documents</Key>
                <Value>
                  <BodyTextButton
                    $size="medium"
                    onClick={() => {
                      if (refs['acquisition-history']?.current) {
                        refs['acquisition-history'].current.scrollIntoView();
                      }
                      setDocumentCategory(DocumentCategoryType.SERVICER_DOCUMENT);
                    }}
                  >
                    View
                  </BodyTextButton>
                </Value>
              </Pair>
            </Styles.AdditionalSection>
          </WidgetSection>
        </WidgetSectionWrapper>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailServicer;
