import { ellipsis, transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

export const QuoteCard = styled.div<{
  $index?: number;
  $count?: number;
  $isStack?: boolean;
  $isActive?: boolean;
}>`
  display: grid;
  grid-template-columns: 10px auto 10px;
  width: 768px;
  height: 100%;
  padding: 14px 8px 16px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 4px ${({ theme }) => theme.colors.purpleDark};
  margin: 12px 0 12px 32px;
  gap: 10px;

  ${({ $count, $index, theme }) => {
    if (typeof $index === 'undefined' || typeof $count === 'undefined') {
      return css`
        background: ${theme.colors.tradeQuoteViolet};
      `;
    }

    const opacity = 1 / $count;
    const multiplier = $count - $index;

    return css`
      background: linear-gradient(
          ${transparentize(opacity * multiplier, theme.colors.tradeQuoteViolet)},
          ${transparentize(opacity * multiplier, theme.colors.tradeQuoteViolet)}
        ),
        linear-gradient(${theme.colors.tradeQuoteCardDark}, ${theme.colors.tradeQuoteCardDark});
    `;
  }};

  ${({ $isStack }) =>
    $isStack === false &&
    css`
      ${QuoteCardDisk} {
        display: none;
      }
    `}

  ${({ $isActive, $isStack, theme }) =>
    $isStack &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 126px;
      cursor: ${$isActive ? 'default' : 'pointer'};

      ${$isActive &&
      css`
        padding-right: 0;

        ${QuoteCardDisk} {
          display: none;
        }
      `}

      &:hover {
        ${QuoteCardDisk} {
          display: none;
        }

        ${$isActive === false &&
        css`
          background: ${theme.colors.greenLight};

          ${QuoteCardContent},
          ${QuoteCardTitle},
            ${QuoteCardSubtitle},
            ${QuoteCardKey},
            ${QuoteCardValue},
            ${QuoteCardDetails},
            ${QuoteCardPriceDetails} > span {
            color: rgba(21, 26, 55, 1);
          }

          ${QuoteCardBorder} {
            background: linear-gradient(
              to right,
              ${transparentize(0, theme.colors.statusBlack)},
              ${transparentize(1, theme.colors.statusBlack)} 90%,
              ${transparentize(1, theme.colors.statusBlack)} 100%
            );
          }
        `}
      }
    `}

  strong {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const QuoteCardBar = styled.div<{ $color: string }>`
  height: 100%;
  width: 6px;
  border-radius: 200px;
  background: ${({ $color }) => $color};
`;

export const QuoteCardDisk = styled.div<{
  $variant: 'quote' | 'counter' | 'rejection';
}>`
  width: 10px;
  height: 10px;
  border-radius: 10px;

  ${({ $variant, theme }) =>
    $variant &&
    css`
      ${$variant === 'quote' &&
      css`
        background: ${transparentize(0.5, theme.colors.white)};
      `}

      ${$variant === 'counter' &&
      css`
        background: ${theme.colors.purpleSecondary};
      `}

    ${$variant === 'rejection' &&
      css`
        background: ${theme.colors.redSecondary};
      `}
    `}
`;

export const QuoteCardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    color: ${transparentize(0.3, theme.colors.white)};
    font-family: ${theme.typography.fontFamily.text};
    font-size: 14px;
    font-weight: 700;
  `}
`;

export const QuoteCardHeader = styled.header`
  display: grid;
  grid-template-columns: max-content auto max-content;
  column-gap: 8px;
`;

export const QuoteCardBorder = styled.div`
  ${({ theme }) => css`
    background: linear-gradient(
      to right,
      ${transparentize(0.5, theme.colors.white)},
      ${transparentize(1, theme.colors.white)} 90%,
      ${transparentize(1, theme.colors.white)} 100%
    );
    height: 1px;
    margin-bottom: 12px;
  `}
`;

export const QuoteCardTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.display};
    margin-bottom: 8px;
  `}
`;

export const QuoteCardSubtitle = styled.div`
  line-height: 1.3;
  ${ellipsis()}
`;

export const QuoteCardRef = styled.span``;

export const QuoteCardDetails = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.text};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 8px;
  `}
`;

export const QuoteCardPriceDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 12px;
    font-family: ${theme.typography.fontFamily.display};
    line-height: 1.2;

    > span:first-of-type {
      color: ${transparentize(0.2, theme.colors.white)};
    }
  `}
`;

export const QuoteSummaryPrice = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

export const QuoteCardKeyValues = styled.div`
  display: grid;
  grid-template-columns: max-content max-content auto;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  gap: 6px 24px;
  margin-top: 4px;
`;

export const QuoteCardKey = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: 13px;
  line-height: 1.2;
`;

export const QuoteCardValue = styled.span`
  color: ${({ theme }) => transparentize(0.2, theme.colors.white)};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;

  ${ellipsis()}
`;

export const QuoteStack = styled.div<{ $activeIndex: number }>`
  position: relative;
  height: 126px;
`;

export const QuoteCardStipulationLink = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.greenLight};
  }
`;
