import {
  OutboundTradeRequestWithSummary,
  StipulationType,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';

import { UserMessage } from '../../../../components/Messages';
import { StipulationPreview } from '../../StipulationPreview';
import { QuoteSummary } from '../QuoteSummary';

type QuoteProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: OutboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const OutboundQuotePending = (props: QuoteProps) => {
  const { currentQuoteIndex, quote, totalQuoteCount, tradeRequest } = props;
  const { fromCounterparty } = quote;

  return (
    <UserMessage
      date={quote.createdAt}
      organizationName={fromCounterparty.user.organization.tradingName}
      lastName={fromCounterparty.user.lastName}
      firstName={fromCounterparty.user.firstName}
    >
      <QuoteSummary
        currentQuoteIndex={currentQuoteIndex}
        quote={quote}
        totalQuoteCount={totalQuoteCount}
        tradeRequest={tradeRequest}
      />
      {quote?.quoteStipulation?.quoteStipulation && (
        <StipulationPreview
          stipulationFileOriginalName={quote.quoteStipulation.quoteStipulation.fileOriginalName}
          stipulationId={quote.quoteStipulation.quoteStipulation.id}
          stipulationType={StipulationType.STIPULATION_QUOTE}
        />
      )}
    </UserMessage>
  );
};
