import { PortfolioLoanRateType } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const usePortfolioBreakdownRatesQuery = (rateType?: PortfolioLoanRateType) =>
  useQuery([QueryKeys.PORTFOLIO_BREAKDOWN_RATES, rateType], async () => {
    if (!rateType) {
      return undefined;
    }
    return await api.getPortfolioBreakdownRates(rateType);
  });
