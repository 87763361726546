import { LoanDetails } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getPropertyScores } from '../../utils/propertiesUtils';
import { QueryKeys } from './types';

type GetPropertyScoresQueryProps = {
  loanNumber: LoanDetails['loanNumber'];
};

export const useGetPropertyScoresQuery = ({ loanNumber }: GetPropertyScoresQueryProps) =>
  useQuery([QueryKeys.PROPERTY_SCORES, loanNumber], async () => {
    return await getPropertyScores(loanNumber);
  });
