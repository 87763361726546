import styled, { css } from 'styled-components/macro';

export const Preview = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    flex-direction: column;
    padding: 12px 12px 0;
    row-gap: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: ${theme.colors.primary02};
    cursor: pointer;
  `
);

export const Header = styled.header`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const FileName = styled.h3(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04}
    color: ${theme.colors.textPrimary01};
  `
);

export const FileType = styled.h4(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular04}
    color: ${theme.colors.textPrimary02};
  `
);

export const PreviewImage = styled.img`
  width: 480px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
