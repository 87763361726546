import {
  MISMOEntity,
  MISMOIndicator,
  MISMONumeric,
  MISMOString,
} from '../types';

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum UnitOfMeasureType {
  Acres = 'Acres',
  Hectares = 'Hectares',
  Other = 'Other',
  SquareFeet = 'SquareFeet',
  SquareMeters = 'SquareMeters',
}

export type SiteSizeLayout = MISMOEntity &
  Partial<{
    conditionRatingType: ConditionRatingType;
    lengthFeetNumber: MISMONumeric;
    lotSizeAreaNumber: MISMONumeric;
    siteDimensionsDescription: MISMOString;
    siteShapeDescription: MISMOString;
    siteSizeShapeTopographyAcceptableIndicator: MISMOIndicator;
    siteSizeShapeTopographyNotAcceptableDescription: MISMOString;
    siteTopographyDescription: MISMOString;
    unitOfMeasureType: UnitOfMeasureType;
    unitOfMeasureTypeOtherDescription: MISMOString;
    widthFeetNumber: MISMONumeric;
  }>;
