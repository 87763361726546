import { MISMOEntity, MISMOSequenceNumber, MISMOString } from '../types';

enum InfluenceImpactType {
  Adverse = 'Adverse',
  Beneficial = 'Beneficial',
  Neutral = 'Neutral',
}

enum SiteInfluenceType {
  Easements = 'Easements',
  Encroachments = 'Encroachments',
  EnvironmentalConditions = 'EnvironmentalConditions',
  LandUses = 'LandUses',
  Location = 'Location',
  Other = 'Other',
  View = 'View',
  WaterRights = 'WaterRights',
}

export type SiteInfluence = MISMOEntity &
  Partial<{
    influenceImpactType: InfluenceImpactType;
    sequenceNumber: MISMOSequenceNumber;
    siteInfluenceDescription: MISMOString;
    siteInfluenceType: SiteInfluenceType;
    siteInfluenceTypeOtherDescription: MISMOString;
  }>;
