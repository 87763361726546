import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOString,
  MISMOURI,
} from './types';

enum ACHAccountType {
  Checking = 'Checking',
  Savings = 'Savings',
}

enum ACHBankAccountPurposeType {
  Other = 'Other',
  PrincipalAndInterest = 'PrincipalAndInterest',
  Settlement = 'Settlement',
  TaxesAndInsurance = 'TaxesAndInsurance',
}

enum ACHDraftFrequencyType {
  Annually = 'Annually',
  Biweekly = 'Biweekly',
  Daily = 'Daily',
}

enum ACHType {
  Current = 'Current',
  Pending = 'Pending',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Other = 'Other',
  Quarterly = 'Quarterly',
  Semiannually = 'Semiannually',
  Semimonthly = 'Semimonthly',
  Weekly = 'Weekly',
}

export type ACH = MISMOEntity &
  Partial<{
    ACH_ABARoutingAndTransitIdentifier: MISMOIdentifier;
    ACHAccountType: ACHAccountType;
    ACHAdditionalEscrowAmount: MISMOAmount;
    ACHAdditionalPrincipalAmount: MISMOAmount;
    ACHBankAccountIdentifier: MISMOIdentifier;
    'ACHBankAccountIdentifier@IdentifierOwnerURI': MISMOURI;
    ACHBankAccountPurposeType: ACHBankAccountPurposeType;
    ACHBankAccountPurposeTypeOtherDescription: MISMOString;
    ACHDraftAfterDueDateDayCount: MISMOCount;
    ACHDraftFrequencyType: ACHDraftFrequencyType;
    ACHDraftFrequencyTypeAdditionalDescription: MISMOString;
    ACHDraftFrequencyTypeOtherDescription: MISMOString;
    ACHDraftingPartyName: MISMOString;
    ACHInstitutionTelegraphicAbbreviationName: MISMOString;
    ACHPendingDraftEffectiveDate: MISMODate;
    ACHReceiverSubaccountName: MISMOString;
    ACHType: ACHType;
    ACHWireAmount: MISMOAmount;
  }>;
