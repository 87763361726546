import { MISMOEntity, MISMOSequenceNumber, MISMOString } from '../types';

enum WaterHeaterType {
  Other = 'Other',
  Solar = 'Solar',
  StandardTank = 'StandardTank',
  Tankless = 'Tankless',
}

enum WaterTreatmentType {
  Other = 'Other',
  Purifier = 'Purifier',
  Softener = 'Softener',
}

export type WaterSystem = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    waterHeaterType: WaterHeaterType;
    waterHeaterTypeOtherDescription: MISMOString;
    waterTreatmentType: WaterTreatmentType;
    waterTreatmentTypeOtherDescription: MISMOString;
  }>;
