import { EarthquakeFeature } from '@plus-platform/shared';
import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import { Key, Pair, Value } from '../../components/KeyValue';
import { ExternalLink } from '../../components/Link';
import { useGetEarthquakesQuery } from '../../hooks/queries';
import { useDebounce } from '../../hooks/useDebounce';
import { formatDefaultValue, formatTimestamp } from '../../utils/formatUtils';
import {
  DEFAULT_INFO_WINDOW_WIDTH,
  formatLatLongToDegrees,
  getEarthquakeColorByMagnitude,
  normalizeEarthquakeMagnitude,
} from '../../utils/mapUtils';

const EarthquakeDetails = styled.div`
  width: ${DEFAULT_INFO_WINDOW_WIDTH};
`;

const EarthquakeTitleLink = styled(ExternalLink)(
  ({ theme }) => css`
    font-size: 16px;
    font-family: ${theme.typography.fontFamily.display};
    font-weight: 600;
    color: ${theme.colors.statusBlack};
  `
);

const EarthquakeContent = styled.div(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    padding: 20px 0;
    display: flex;
    align-items: center;
  `
);

const EarthquakeFooter = styled.div``;

const DetailLink = styled(ExternalLink)(
  ({ theme }) => css`
    display: flex;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid ${theme.colors.darkBlue};
    color: ${theme.colors.statusBlack};

    & + & {
      margin-left: 10px;
    }
  `
);

const IntensityScore = styled.span`
  font-weight: 600;
  margin-left: 4px;
`;

const DetailLabel = styled.span``;

const PropertyRow = styled.div(
  ({ theme }) => css`
    & + & {
      margin-top: 4px;
    }

    ${Key} {
      width: 60px;
      color: ${theme.colors.blue40};
    }

    ${Value} {
      color: ${theme.colors.statusBlack};
      font-weight: 400;
      margin-left: 10px;
    }
  `
);

type EarthquakeMarkerProps = {
  feature: EarthquakeFeature;
  onMarkerClick: (feature: EarthquakeFeature) => void;
  title?: string;
};

const EarthquakeMarker = ({ feature, onMarkerClick }: EarthquakeMarkerProps) => {
  const theme = useTheme();

  const richterMagnitude = feature.properties.mag;
  const [lng, lat] = feature.geometry.coordinates;

  return (
    <Marker
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        scale: normalizeEarthquakeMagnitude(richterMagnitude),
        fillColor: getEarthquakeColorByMagnitude(richterMagnitude),
        fillOpacity: 0.35,
        strokeColor: theme.colors.statusBlack,
        strokeOpacity: 0.8,
        strokeWeight: 1,
      }}
      onClick={() => onMarkerClick(feature)}
      options={{
        clickable: true,
        title: `Earthquake at ${formatLatLongToDegrees(
          lat,
          lng
        )} with magnitude ${richterMagnitude}`,
      }}
      position={{
        lng,
        lat,
      }}
    >
      {feature.type}
    </Marker>
  );
};

type EarthquakesLayerProps = {
  mapBounds: google.maps.LatLngBounds;
  onLoadingStateChanged: (isLoading: boolean) => void;
};

export const EarthquakesLayer = ({ mapBounds, onLoadingStateChanged }: EarthquakesLayerProps) => {
  const [selectedEarthquake, setSelectedEarthquake] = React.useState<EarthquakeFeature | undefined>(
    undefined
  );
  const debouncedBounds = useDebounce<google.maps.LatLngBounds>(mapBounds, 1000);

  const formattedBounds = React.useMemo(
    () => ({
      minLatitude: debouncedBounds.getSouthWest().lat(),
      minLongitude: debouncedBounds.getSouthWest().lng(),
      maxLatitude: debouncedBounds.getNorthEast().lat(),
      maxLongitude: debouncedBounds.getNorthEast().lng(),
    }),
    [debouncedBounds]
  );

  const { data: earthquakesData, isLoading } = useGetEarthquakesQuery(formattedBounds);
  const features = earthquakesData?.features || [];

  useEffect(() => {
    onLoadingStateChanged(isLoading);
  }, [isLoading]);

  const [lng, lat, depth] = selectedEarthquake?.geometry?.coordinates || [];

  return (
    <React.Fragment>
      {selectedEarthquake && lng && lat && (
        <InfoWindow
          onCloseClick={() => setSelectedEarthquake(undefined)}
          options={{
            disableAutoPan: true,
            ariaLabel: `Earthquake at ${formatLatLongToDegrees(lat, lng)} with magnitude ${
              selectedEarthquake.properties.mag
            }`,
          }}
          position={{
            lng,
            lat,
          }}
        >
          <EarthquakeDetails>
            <EarthquakeTitleLink href={selectedEarthquake.properties.url} target="_blank">
              {selectedEarthquake.properties.title}
            </EarthquakeTitleLink>
            <EarthquakeContent>
              <DetailLink href={`${selectedEarthquake.properties.url}/dyfi`} target="_blank">
                <DetailLabel>DYFI</DetailLabel>
                {selectedEarthquake.properties.cdi && (
                  <IntensityScore>
                    ({formatDefaultValue(parseInt(String(selectedEarthquake.properties.cdi)))})
                  </IntensityScore>
                )}
              </DetailLink>

              <DetailLink href={`${selectedEarthquake.properties.url}/shakemap`} target="_blank">
                Shakemap
              </DetailLink>
            </EarthquakeContent>
            <EarthquakeFooter>
              <PropertyRow>
                <Pair $align="left">
                  <Key>Time</Key>
                  <Value>{formatTimestamp(selectedEarthquake.properties.time)}</Value>
                </Pair>
              </PropertyRow>
              <PropertyRow>
                <Pair $align="left">
                  <Key>Location</Key>
                  <Value>{formatLatLongToDegrees(lat, lng)}</Value>
                </Pair>
              </PropertyRow>

              <PropertyRow>
                <Pair $align="left">
                  <Key>Depth</Key>
                  <Value>{depth} km</Value>
                </Pair>
              </PropertyRow>
            </EarthquakeFooter>
          </EarthquakeDetails>
        </InfoWindow>
      )}

      {features.map((feature, index) => (
        <EarthquakeMarker
          feature={feature}
          key={index}
          onMarkerClick={(feature) => setSelectedEarthquake(feature)}
        />
      ))}
    </React.Fragment>
  );
};
