import { MISMOEntity, MISMOSequenceNumber, MISMOString } from '../types';

enum LoanModificationType {
  AmortizationMethod = 'AmortizationMethod',
  InterestRate = 'InterestRate',
  MaturityDate = 'MaturityDate',
  Other = 'Other',
  PaymentAmount = 'PaymentAmount',
  PaymentFrequency = 'PaymentFrequency',
  PrincipalAmount = 'PrincipalAmount',
}

export type ModificationAspect = MISMOEntity &
  Partial<{
    loanModificationType: LoanModificationType;
    loanModificationTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
