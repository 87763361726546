import { UserCreation } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export type CreateUserInvitationResponse = {
  email: string;
  role: string;
};

const createUserInvitation = (data: Pick<UserCreation, 'email' | 'roleId'>) => {
  const url = makeApiUrl('/users/invite');

  return postRequestWithAuth<CreateUserInvitationResponse>(url, {
    body: JSON.stringify(data),
  });
};

export const createUserInvitationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createUserInvitation, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.ORGANIZATION_MEMBERS);
    },
  });
};
