import styled, { css } from 'styled-components/macro';

import { InternalLink } from '../components/Link';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${theme.colors.backgroundAdminSidePanel};
    border-right: 1px solid ${theme.colors.borderAdminSidePanel};
  `
);

export const NavigationMenu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.li`
  margin: 0;
  padding: 0;
`;

export const MenuItemLink = styled(InternalLink)<{ $isActive: boolean }>(
  ({ $isActive, theme }) => css`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 8px 16px;
    color: ${theme.colors.fillAdminSidePanelIcon};

    &:hover,
    &:focus-visible {
      background-color: ${theme.colors.backgroundNavigationHover};
    }

    &:active {
      background-color: ${theme.colors.white30};
    }

    ${$isActive &&
    css`
      ${theme.typography.headings.headingMedium03}

      background-color: ${theme.colors.backgroundNavigationActive};
      color: ${theme.colors.blue};
    `}
  `
);

export const MenuItemLabel = styled.h3<{ $isActive: boolean }>(
  ({ $isActive, theme }) => css`
    ${theme.typography.headings.headingRegular03};
    margin-left: 8px;
    color: ${theme.colors.fillAdminSidePanelLabel};

    ${$isActive &&
    css`
      color: ${theme.colors.blue};
    `}
  `
);

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 24px;
  height: 24px;
`;
