import { useMutation, useQueryClient } from 'react-query';

import { updateAnalyticSettings, UpdateAnalyticSettingsProps } from '../../admin/utils';
import { QueryKeys } from './types';

export const useUpdateAnalyticSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: UpdateAnalyticSettingsProps) => updateAnalyticSettings(data), {
    onSuccess: (analyticId) => {
      queryClient.invalidateQueries([QueryKeys.ANALYTIC_SETTINGS, analyticId]);
    },
  });
};
