import {
  CounterpartyType,
  getNotificationTypesForTradeTab,
  InboundTradeRequestWithSummary,
  OutboundTradeRequestWithSummary,
  TradeRequestTabType,
  TradeRequestWithSummary,
} from '@plus-platform/shared';
import noop from 'lodash/noop';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { Heading } from '../../components/Heading';
import {
  BidLineIcon,
  ChatLineIcon,
  DataRequestLineIcon,
  ThumbsUpLineIcon,
} from '../../components/icons';
import { makeMessengerUrl } from '../../components/Messages/messengerUtils';
import { MessengerParamProps } from '../../components/Messages/types';
import { RecipientList } from '../../components/RecipientList';
import { useMarkAllNotificationsAsReadMutation } from '../../hooks/queries';
import { Header as OutboundHeader } from '../TradeRequestDashboard';
import {
  Layout,
  Sidebar,
  SidebarContent,
  SidebarHeading,
  SidebarItem,
} from '../TradeRequestPageLayout';
import { InboundTradeRequestSidebar, OutboundTradeRequestSidebar } from '../TradeRequestTickets';
import { TradeRequestCategory } from '../types';
import * as Styles from './Messenger.styles';
import { InboundTradeRequestDataRequests } from './TradeRequestDataRequests/InboundTradeRequestDataRequests';
import { OutboundTradeRequestDataRequests } from './TradeRequestDataRequests/OutboundTradeRequestDataRequests';
import { InboundMessagingContactTabs } from './TradeRequestDirectMessaging/InboundMessagingContactTabs';
import { InboundTradeRequestDirectMessaging } from './TradeRequestDirectMessaging/InboundTradeRequestDirectMessaging';
import { OutboundMessagingContactTabs } from './TradeRequestDirectMessaging/OutboundMessagingContactTabs';
import { OutboundTradeRequestDirectMessaging } from './TradeRequestDirectMessaging/OutboundTradeRequestDirectMessaging';
import { InboundTradeRequestExpressionsOfInterest } from './TradeRequestExpressionsOfInterest/InboundTradeRequestExpressionsOfInterest';
import { OutboundTradeRequestExpressionsOfInterest } from './TradeRequestExpressionsOfInterest/OutboundTradeRequestExpressionsOfInterest';
import { InboundTradeRequestQuotes } from './TradeRequestQuotes/InboundTradeRequestQuotes';
import { OutboundTradeRequestQuotes } from './TradeRequestQuotes/OutboundTradeRequestQuotes';
import { TradeRequestTabNotifications } from './TradeRequestTabNotifications';

type MessengerProps = {
  category: TradeRequestCategory;
  tradeRequest: TradeRequestWithSummary;
  disabledTabs: TradeRequestTabType[];
};

export const Messenger = (props: MessengerProps) => {
  const { disabledTabs, tradeRequest } = props;
  const history = useHistory();
  const theme = useTheme();

  const { category, counterpartyId, tabType, tradeId } = useParams<MessengerParamProps>();
  const { mutateAsync: markAllNotificationsAsRead } = useMarkAllNotificationsAsReadMutation();

  const tradeRequestId = Number(tradeId);
  const isInbound = props.category === TradeRequestCategory.INBOUND;
  const isOutbound = props.category === TradeRequestCategory.OUTBOUND;

  const activeTab = tabType ?? TradeRequestTabType.EXPRESSIONS_OF_INTEREST;

  const handleTabClick = (tabType: TradeRequestTabType) => {
    markAllNotificationsAsRead({
      types: getNotificationTypesForTradeTab(tabType),
      tradeRequestId,
    }).catch(noop);

    if (counterpartyId) {
      history.push(makeMessengerUrl(tradeId, category, counterpartyId, tabType));
    }
  };

  const isTabDisabled = (tab?: TradeRequestTabType) => {
    return Boolean(tab && disabledTabs.includes(tab));
  };

  return (
    <Layout>
      {isTabDisabled(tabType) && counterpartyId && (
        <Redirect
          to={makeMessengerUrl(
            tradeId,
            category,
            counterpartyId,
            TradeRequestTabType.EXPRESSIONS_OF_INTEREST
          )}
        />
      )}
      <Sidebar>
        <SidebarContent>
          <SidebarItem>
            <Styles.SidebarHeader>
              {isOutbound && (
                <OutboundHeader tradeRequest={tradeRequest as OutboundTradeRequestWithSummary} />
              )}
              {isInbound && <Heading $size="medium">{tradeRequest.pool.name}</Heading>}
            </Styles.SidebarHeader>
            <Styles.Tabs>
              <Styles.Tab>
                <Styles.TabButton
                  $isActive={activeTab === TradeRequestTabType.EXPRESSIONS_OF_INTEREST}
                  disabled={isTabDisabled(TradeRequestTabType.EXPRESSIONS_OF_INTEREST)}
                  onClick={() => handleTabClick(TradeRequestTabType.EXPRESSIONS_OF_INTEREST)}
                >
                  <ThumbsUpLineIcon />
                  <Styles.TabButtonText>Expression of interest</Styles.TabButtonText>
                  <TradeRequestTabNotifications
                    notificationTypes={getNotificationTypesForTradeTab(
                      TradeRequestTabType.EXPRESSIONS_OF_INTEREST
                    )}
                    tradeRequestId={tradeRequestId}
                    color={theme.colors.statusTurquoise}
                  />
                </Styles.TabButton>
              </Styles.Tab>
              <Styles.Tab>
                <Styles.TabButton disabled $isActive={false}>
                  <ChatLineIcon />
                  <Styles.TabButtonText>Stipulations</Styles.TabButtonText>
                </Styles.TabButton>
              </Styles.Tab>

              <Styles.Tab>
                <Styles.TabButton
                  $isActive={activeTab === TradeRequestTabType.DATA_REQUESTS}
                  disabled={isTabDisabled(TradeRequestTabType.DATA_REQUESTS)}
                  onClick={() => handleTabClick(TradeRequestTabType.DATA_REQUESTS)}
                >
                  <DataRequestLineIcon />
                  <Styles.TabButtonText>Data requests</Styles.TabButtonText>
                  <TradeRequestTabNotifications
                    notificationTypes={getNotificationTypesForTradeTab(
                      TradeRequestTabType.DATA_REQUESTS
                    )}
                    tradeRequestId={tradeRequestId}
                    color={theme.colors.statusOrange}
                  />
                </Styles.TabButton>
              </Styles.Tab>
              <Styles.Tab>
                <Styles.TabButton
                  $isActive={activeTab === TradeRequestTabType.QUOTES}
                  disabled={isTabDisabled(TradeRequestTabType.QUOTES)}
                  onClick={() => handleTabClick(TradeRequestTabType.QUOTES)}
                >
                  <BidLineIcon />
                  <Styles.TabButtonText>Bids</Styles.TabButtonText>
                  <TradeRequestTabNotifications
                    notificationTypes={getNotificationTypesForTradeTab(TradeRequestTabType.QUOTES)}
                    tradeRequestId={tradeRequestId}
                  />
                </Styles.TabButton>
              </Styles.Tab>
            </Styles.Tabs>
          </SidebarItem>

          {isInbound && (
            <InboundMessagingContactTabs
              tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
            />
          )}

          {isOutbound && (
            <OutboundMessagingContactTabs
              tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
            />
          )}

          {isInbound && (
            <>
              <SidebarItem>
                <SidebarHeading>Details</SidebarHeading>
                <InboundTradeRequestSidebar
                  tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
                />
              </SidebarItem>
            </>
          )}

          {isOutbound && (
            <>
              <SidebarItem>
                <SidebarHeading>Details</SidebarHeading>
                <OutboundTradeRequestSidebar
                  tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
                />
              </SidebarItem>
              <SidebarItem>
                <SidebarHeading>Recipients</SidebarHeading>
                <RecipientList
                  counterparties={tradeRequest.counterparties.filter(
                    (counterparty) => counterparty.type === CounterpartyType.BUYER
                  )}
                />
              </SidebarItem>
            </>
          )}
        </SidebarContent>
      </Sidebar>
      <Styles.MessengerContent>
        {activeTab === TradeRequestTabType.EXPRESSIONS_OF_INTEREST && (
          <React.Fragment>
            {isInbound && (
              <InboundTradeRequestExpressionsOfInterest
                tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
              />
            )}

            {isOutbound && (
              <OutboundTradeRequestExpressionsOfInterest
                tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
              />
            )}
          </React.Fragment>
        )}
        {activeTab === TradeRequestTabType.DATA_REQUESTS && (
          <React.Fragment>
            {isInbound && (
              <InboundTradeRequestDataRequests
                tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
              />
            )}

            {isOutbound && (
              <OutboundTradeRequestDataRequests
                tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
              />
            )}
          </React.Fragment>
        )}
        {activeTab === TradeRequestTabType.QUOTES && (
          <React.Fragment>
            {isInbound && (
              <InboundTradeRequestQuotes
                tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
              />
            )}

            {isOutbound && (
              <OutboundTradeRequestQuotes
                tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
              />
            )}
          </React.Fragment>
        )}
        {activeTab === TradeRequestTabType.MESSAGES && counterpartyId && (
          <React.Fragment>
            {isInbound && (
              <InboundTradeRequestDirectMessaging
                tradeCounterpartyId={counterpartyId}
                tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
              />
            )}

            {isOutbound && (
              <OutboundTradeRequestDirectMessaging
                tradeCounterpartyId={counterpartyId}
                tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
              />
            )}
          </React.Fragment>
        )}
      </Styles.MessengerContent>
    </Layout>
  );
};
