export type PropertyValuationDataPoint = {
  date: string;
  value: number;
};

// @todo: remove this once we have real data coming
// from a query hook
export type PropertyValuationHistoryResponse = {
  purchasePrice: PropertyValuationDataPoint[];
  bpo: PropertyValuationDataPoint[];
  avm: PropertyValuationDataPoint[];
  appraisal: PropertyValuationDataPoint[];
};

export type PropertyValuationHistoryChartProps = {
  height?: number;
  width?: number;
};

export type LoanChartProps = {
  isLarge?: boolean;
};

export type PropertyValuationHistoryChartData = {
  x: Date;
  y: number;
};

export enum PropertyValuationType {
  PURCHASE_PRICE = 'Purchase Price',
  BPO = 'BPO',
  AVM = 'AVM',
  APPRAISAL = 'Appraisal',
  COMPARABLE_SALES = 'Comparable Sales',
  MLS = 'MLS',
}
