import { Badge } from './Badge';
import { CheckboxIcon } from './icons';
import * as Styles from './TradeStatus.styles';

type TradeStatusProps = {
  icon: React.ReactNode;
  isComplete?: boolean;
  isInline?: boolean;
  color?: string;
  textColor?: string;
  count?: number;
  total?: number;
  label?: string;
  variant?: 'default' | 'light';
};

export const TradeStatus = ({
  color,
  count,
  icon,
  isComplete,
  isInline = true,
  label,
  textColor,
  total,
  variant,
}: TradeStatusProps) => {
  const isActive = Boolean(isComplete || count);
  return (
    <Styles.Status $isActive={isActive} $isInline={isInline} $variant={variant}>
      {icon}
      {(label || isActive) && (
        <Styles.Content>
          {label && <Styles.Label>{label}</Styles.Label>}
          {isActive && (
            <>
              {isComplete ? (
                <Badge $color={color} $textColor={textColor} $size={16}>
                  <CheckboxIcon />
                </Badge>
              ) : count ? (
                <Badge
                  $color={color}
                  $textColor={textColor}
                  $size={16}
                  $shape={total ? 'pill' : 'circle'}
                >
                  {`${count}${total ? `/${total}` : ''}`}
                </Badge>
              ) : null}
            </>
          )}
        </Styles.Content>
      )}
    </Styles.Status>
  );
};
