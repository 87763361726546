import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export enum KitchenEquipmentType {
  Compactor = 'Compactor',
  Cooktop = 'Cooktop',
  Dishwasher = 'Dishwasher',
  Disposal = 'Disposal',
  FanHood = 'FanHood',
  Microwave = 'Microwave',
  Other = 'Other',
  RangeOven = 'RangeOven',
  Refrigerator = 'Refrigerator',
  SolidSurfaceManmadeCountertop = 'SolidSurfaceManmadeCountertop',
  SolidSurfaceNaturalCountertop = 'SolidSurfaceNaturalCountertop',
  WasherDryer = 'WasherDryer',
  WaterEfficientFaucet = 'WaterEfficientFaucet',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type KitchenEquipment = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    kitchenEquipmentCount: MISMOCount;
    kitchenEquipmentDescription: MISMOString;
    kitchenEquipmentMakeModelDescription: MISMOString;
    kitchenEquipmentType: KitchenEquipmentType;
    kitchenEquipmentTypeOtherDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
  }>;
