import {
  AggregatedActionStipulation,
  AggregatedQuoteStipulation,
  AggregatedTradeRequestMessage,
  AnalyticSettings,
  AnalyticsSettings,
  Borrower,
  BorrowerEmploymentVerification,
  BorrowerIdentityVerification,
  BorrowerVerifierType,
  CounterpartyAction,
  Index,
  IndexRate,
  Insurer,
  LoanChatMessage,
  TradeCounterparty,
  TradeQuoteCounterStatusUpdate,
  TradeQuoteStatusUpdate,
  TradeRequest,
  TradeRequestQuote,
  TradeRequestStipulation,
  UserIdentifier,
} from '.';
import { Custodian } from './custodians';
import { DocumentIngested, DocumentMetadata, DocumentUploaded } from './documents';
import { LoanEscrowAnalysis } from './escrow';
import { Filter } from './filters';
import { Identifier } from './global';
import {
  LoanDetails,
  LoanInsurer,
  LoanInterestRateAdjustment,
  LoanNote,
  LoanProperty,
  LoanTerms,
  Payment,
} from './loans';
import { OrganizationIdentifier } from './organizations';
import { Pool, PoolUpdate } from './pools';
import { PortfolioAnalysis, PortfolioAnalytics, VisualisationType } from './portfolio';
import { Servicer } from './servicers';
import { SubmissionCreation } from './submissions';

export enum EventType {
  DOCUMENT_INGESTED_EVENT = 'DOCUMENT_INGESTED_EVENT',
  DOCUMENT_UPLOADED_EVENT = 'DOCUMENT_UPLOADED_EVENT',
  LOAN_DOCUMENT_ADDED_EVENT = 'LOAN_DOCUMENT_ADDED_EVENT',
  LOAN_DETAILS_CREATED_EVENT = 'LOAN_DETAILS_CREATED_EVENT',
  LOAN_TERMS_CREATED_EVENT = 'LOAN_TERMS_CREATED_EVENT',
  LOAN_INTEREST_RATE_ADJUSTMENT_CREATED_EVENT = 'LOAN_INTEREST_RATE_ADJUSTMENT_CREATED_EVENT',
  LOAN_INTEREST_RATE_ADJUSTMENT_INDEX_ASSIGNED_EVENT = 'LOAN_INTEREST_RATE_ADJUSTMENT_INDEX_ASSIGNED_EVENT',
  LOAN_PROPERTY_CREATED_EVENT = 'LOAN_PROPERTY_CREATED_EVENT',
  LOAN_BORROWER_ASSIGNED_EVENT = 'LOAN_BORROWER_ASSIGNED_EVENT',
  LOAN_PAYMENT_RECEIVED_EVENT = 'LOAN_PAYMENT_RECEIVED_EVENT',
  LOAN_SERVICER_ASSIGNED_EVENT = 'LOAN_SERVICER_ASSIGNED_EVENT',
  LOAN_ESCROW_ANALYSIS_CREATED_EVENT = 'LOAN_ESCROW_ANALYSIS_CREATED_EVENT',
  LOAN_CUSTODIAN_ASSIGNED_EVENT = 'LOAN_CUSTODIAN_ASSIGNED_EVENT',
  LOAN_MORTGAGE_INSURER_ASSIGNED_EVENT = 'LOAN_MORTGAGE_INSURER_ASSIGNED_EVENT',
  LOAN_FLOOD_INSURER_ASSIGNED_EVENT = 'LOAN_FLOOD_INSURER_ASSIGNED_EVENT',
  LOAN_HAZARD_INSURER_ASSIGNED_EVENT = 'LOAN_HAZARD_INSURER_ASSIGNED_EVENT',
  LOAN_CHAT_MESSAGE_SENT_EVENT = 'LOAN_CHAT_MESSAGE_SENT_EVENT',
  LOAN_NOTE_CREATED_EVENT = 'LOAN_NOTE_CREATED_EVENT',
  PORTFOLIO_ANALYSIS_CREATED_EVENT = 'PORTFOLIO_ANALYSIS_CREATED_EVENT',
  POOL_CREATED_EVENT = 'POOL_CREATED_EVENT',
  POOL_UPDATED_EVENT = 'POOL_UPDATED_EVENT',
  POOL_DELETED_EVENT = 'POOL_DELETED_EVENT',
  LOAN_ADDED_TO_POOL_EVENT = 'LOAN_ADDED_TO_POOL_EVENT',
  TRADE_REQUEST_CREATED_EVENT = 'TRADE_REQUEST_CREATED_EVENT',
  TRADE_COUNTERPARTY_CREATED_EVENT = 'TRADE_COUNTERPARTY_CREATED_EVENT',
  TRADE_COUNTERPARTY_EXPRESSED_INTEREST_EVENT = 'TRADE_COUNTERPARTY_EXPRESSED_INTEREST_EVENT',
  TRADE_COUNTERPARTY_EXPRESSED_INTEREST_ACCEPTED_EVENT = 'TRADE_COUNTERPARTY_EXPRESSED_INTEREST_ACCEPTED_EVENT',
  TRADE_COUNTERPARTY_EXPRESSED_INTEREST_DECLINED_EVENT = 'TRADE_COUNTERPARTY_EXPRESSED_INTEREST_DECLINED_EVENT',
  TRADE_COUNTERPARTY_DATA_REQUESTED_EVENT = 'TRADE_COUNTERPARTY_DATA_REQUESTED_EVENT',
  TRADE_COUNTERPARTY_DATA_REQUEST_ACCEPTED_EVENT = 'TRADE_COUNTERPARTY_DATA_REQUEST_ACCEPTED_EVENT',
  TRADE_COUNTERPARTY_DATA_REQUEST_DENIED_EVENT = 'TRADE_COUNTERPARTY_DATA_REQUEST_DENIED_EVENT',
  TRADE_COUNTERPARTY_SOLD_EVENT = 'TRADE_COUNTERPARTY_SOLD_EVENT',
  TRADE_QUOTE_CREATED_EVENT = 'TRADE_QUOTE_CREATED_EVENT',
  TRADE_QUOTE_ACCEPTED_EVENT = 'TRADE_QUOTE_ACCEPTED_EVENT',
  TRADE_QUOTE_REJECTED_EVENT = 'TRADE_QUOTE_REJECTED_EVENT',
  TRADE_QUOTE_COUNTER_CREATED_EVENT = 'TRADE_QUOTE_COUNTER_CREATED_EVENT',
  TRADE_QUOTE_COUNTER_ACCEPTED_EVENT = 'TRADE_QUOTE_COUNTER_ACCEPTED_EVENT',
  TRADE_QUOTE_COUNTER_REJECTED_EVENT = 'TRADE_QUOTE_COUNTER_REJECTED_EVENT',
  TRADE_REQUEST_STIPULATION_ADDED_EVENT = 'TRADE_REQUEST_STIPULATION_ADDED_EVENT',
  QUOTE_STIPULATION_ADDED_EVENT = 'QUOTE_STIPULATION_ADDED_EVENT',
  ACTION_STIPULATION_ADDED_EVENT = 'ACTION_STIPULATION_ADDED_EVENT',
  TRADE_MESSAGE_SENT_EVENT = 'TRADE_MESSAGE_SENT_EVENT',
  SERVICER_CREATED_EVENT = 'SERVICER_CREATED_EVENT',
  INSURER_CREATED_EVENT = 'INSURER_CREATED_EVENT',
  CUSTODIAN_CREATED_EVENT = 'CUSTODIAN_CREATED_EVENT',
  BORROWER_CREATED_EVENT = 'BORROWER_CREATED_EVENT',
  BORROWER_IDENTITY_VERIFIED_EVENT = 'BORROWER_IDENTITY_VERIFIED_EVENT',
  BORROWER_EMPLOYMENT_VERIFIED_EVENT = 'BORROWER_EMPLOYMENT_VERIFIED_EVENT',
  USER_PORTFOLIO_FILTERS_UPDATED_EVENT = 'USER_PORTFOLIO_FILTERS_UPDATED_EVENT',
  SUBMISSION_CREATED_EVENT = 'SUBMISSION_CREATED_EVENT',
  INDEX_CREATED_EVENT = 'INDEX_CREATED_EVENT',
  INDEX_RATE_CREATED_EVENT = 'INDEX_RATE_CREATED_EVENT',
  PORTFOLIO_ANALYTICS_CREATED_EVENT = 'PORTFOLIO_ANALYTICS_CREATED_EVENT',
  PORTFOLIO_ANALYTICS_UPDATED_EVENT = 'PORTFOLIO_ANALYTICS_UPDATED_EVENT',
  PORTFOLIO_ANALYTICS_DELETED_EVENT = 'PORTFOLIO_ANALYTICS_DELETED_EVENT',
  USER_SETTINGS_UPDATED_EVENT = 'USER_SETTINGS_UPDATED_EVENT',
  USER_ANALYTICS_SETTINGS_UPDATED_EVENT = 'USER_ANALYTICS_SETTINGS_UPDATED_EVENT',
  USER_ANALYTICS_SETTINGS_DELETED_EVENT = 'USER_ANALYTICS_SETTINGS_DELETED_EVENT',
}

export enum StreamType {
  STREAM_DOCUMENT = 'STREAM_DOCUMENT',
  STREAM_LOAN = 'STREAM_LOAN',
  STREAM_POOL = 'STREAM_POOL',
  STREAM_PORTFOLIO = 'STREAM_PORTFOLIO',
  STREAM_TRADE_REQUEST = 'STREAM_TRADE_REQUEST',
  STREAM_SERVICER = 'STREAM_SERVICER',
  STREAM_INSURER = 'STREAM_INSURER',
  STREAM_BORROWER = 'STREAM_BORROWER',
  STREAM_USER = 'STREAM_USER',
  STREAM_CUSTODIAN = 'STREAM_CUSTODIAN',
  STREAM_SUBMISSION = 'STREAM_SUBMISSION',
  STREAM_INDEX = 'STREAM_INDEX',
}

export enum EventActor {
  INSURER = 'INSURER',
  INVESTOR = 'INVESTOR',
  ORIGINATOR = 'ORIGINATOR',
  OWNER = 'OWNER',
  SERVICER = 'SERVICER',
  SYSTEM = 'SYSTEM',
}

export enum EventCategory {
  ALERT = 'ALERT',
  INQUIRY = 'INQUIRY',
  NOTIFICATION = 'NOTIFICATION',
  RESOLVED = 'RESOLVED',
  UPDATE = 'UPDATE',
}

export interface EventCommonAttributes {
  id: Identifier;
  actor?: EventActor;
  category?: EventCategory;
  eventType: EventType;
  organizationId: OrganizationIdentifier;
  streamType: StreamType;
  createdAt?: Date;
  updatedAt?: Date;
}

export type LoanStreamPayload = {
  loanId: Identifier;
};

export type GenericEvent<
  S extends StreamType,
  E extends EventType,
  D extends S extends StreamType.STREAM_LOAN ? LoanStreamPayload : Record<string, unknown>
> = EventCommonAttributes & {
  streamType: S;
  eventType: E;
  data: D;
};

export type DocumentIngestedEvent = GenericEvent<
  StreamType.STREAM_DOCUMENT,
  EventType.DOCUMENT_INGESTED_EVENT,
  DocumentIngested
>;

export type DocumentUploadedEvent = GenericEvent<
  StreamType.STREAM_DOCUMENT,
  EventType.DOCUMENT_UPLOADED_EVENT,
  DocumentUploaded
>;

export type WithLoanIdEvent<E extends EventType, D> = GenericEvent<
  StreamType.STREAM_LOAN,
  E,
  D & LoanStreamPayload
>;

export type LoanDocumentAddedEvent = WithLoanIdEvent<
  EventType.LOAN_DOCUMENT_ADDED_EVENT,
  DocumentMetadata
>;
export type LoanDetailsCreatedEvent = WithLoanIdEvent<
  EventType.LOAN_DETAILS_CREATED_EVENT,
  Omit<LoanDetails, 'id'>
>;
export type LoanTermsCreatedEvent = WithLoanIdEvent<
  EventType.LOAN_TERMS_CREATED_EVENT,
  Omit<LoanTerms, 'id'>
>;
export type LoanPropertyCreatedEvent = WithLoanIdEvent<
  EventType.LOAN_PROPERTY_CREATED_EVENT,
  Omit<LoanProperty, 'id'>
>;
export type LoanInterestRateAdjustmentCreatedEvent = WithLoanIdEvent<
  EventType.LOAN_INTEREST_RATE_ADJUSTMENT_CREATED_EVENT,
  Omit<LoanInterestRateAdjustment, 'id'>
>;
export type LoanInterestRateAdjustmentIndexAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_INTEREST_RATE_ADJUSTMENT_INDEX_ASSIGNED_EVENT,
  {
    indexId: Index['id'];
    adjustmentId: LoanInterestRateAdjustment['id'];
    indexStartDate: Date;
    indexEndDate: Date;
  }
>;
export type LoanBorrowerAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_BORROWER_ASSIGNED_EVENT,
  {
    borrowerId: Borrower['id'];
  }
>;
export type LoanPaymentReceivedEvent = WithLoanIdEvent<
  EventType.LOAN_PAYMENT_RECEIVED_EVENT,
  Payment
>;
export type LoanServicerAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_SERVICER_ASSIGNED_EVENT,
  { servicerId: Servicer['id']; yearlyServicingFeeRate: number }
>;

export type LoanEscrowAnalysisCreatedEvent = WithLoanIdEvent<
  EventType.LOAN_ESCROW_ANALYSIS_CREATED_EVENT,
  LoanEscrowAnalysis
>;

export type LoanMortgageInsurerAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_MORTGAGE_INSURER_ASSIGNED_EVENT,
  LoanInsurer
>;
export type LoanFloodInsurerAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_FLOOD_INSURER_ASSIGNED_EVENT,
  LoanInsurer
>;
export type LoanHazardInsurerAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_HAZARD_INSURER_ASSIGNED_EVENT,
  LoanInsurer
>;

export type LoanCustodianAssignedEvent = WithLoanIdEvent<
  EventType.LOAN_CUSTODIAN_ASSIGNED_EVENT,
  { custodianId: Custodian['id'] }
>;

export type LoanChatMessageSentEvent = WithLoanIdEvent<
  EventType.LOAN_CHAT_MESSAGE_SENT_EVENT,
  LoanChatMessage
>;

export type LoanNoteCreatedEvent = WithLoanIdEvent<EventType.LOAN_NOTE_CREATED_EVENT, LoanNote>;

export type LoanAddedToPoolEvent = WithLoanIdEvent<
  EventType.LOAN_ADDED_TO_POOL_EVENT,
  { poolId: Pool['id'] }
>;

export type PortfolioAnalysisCreatedEvent = GenericEvent<
  StreamType.STREAM_PORTFOLIO,
  EventType.PORTFOLIO_ANALYSIS_CREATED_EVENT,
  PortfolioAnalysis
>;

export type PortfolioAnalyticsCreatedEvent = GenericEvent<
  StreamType.STREAM_PORTFOLIO,
  EventType.PORTFOLIO_ANALYTICS_CREATED_EVENT,
  PortfolioAnalytics
>;

export type PortfolioAnalyticsUpdatedEvent = GenericEvent<
  StreamType.STREAM_PORTFOLIO,
  EventType.PORTFOLIO_ANALYTICS_UPDATED_EVENT,
  { analyticsId: Identifier; visualisationType?: VisualisationType }
>;

export type PortfolioAnalyticsDeletedEvent = GenericEvent<
  StreamType.STREAM_PORTFOLIO,
  EventType.PORTFOLIO_ANALYTICS_DELETED_EVENT,
  { analyticsId: Identifier }
>;

export type UserSettingsUpdatedEvent = GenericEvent<
  StreamType.STREAM_USER,
  EventType.USER_SETTINGS_UPDATED_EVENT,
  AnalyticsSettings
>;

export type UserAnalyticsSettingsUpdatedEvent = GenericEvent<
  StreamType.STREAM_USER,
  EventType.USER_ANALYTICS_SETTINGS_UPDATED_EVENT,
  AnalyticSettings
>;

export type UserAnalyticsSettingsDeletedEvent = GenericEvent<
  StreamType.STREAM_USER,
  EventType.USER_ANALYTICS_SETTINGS_DELETED_EVENT,
  { analyticId: Identifier }
>;

export type PoolCreatedEvent = GenericEvent<
  StreamType.STREAM_POOL,
  EventType.POOL_CREATED_EVENT,
  Pool
>;

export type PoolUpdatedEvent = GenericEvent<
  StreamType.STREAM_POOL,
  EventType.POOL_UPDATED_EVENT,
  PoolUpdate & Pick<Pool, 'id'>
>;

export type PoolDeletedEvent = GenericEvent<
  StreamType.STREAM_POOL,
  EventType.POOL_DELETED_EVENT,
  { poolId: Pool['id'] }
>;

export type TradeRequestCreatedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_REQUEST_CREATED_EVENT,
  Omit<TradeRequest, 'details' | 'stipulationsIds'>
>;

export type TradeCounterpartyCreatedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_CREATED_EVENT,
  TradeCounterparty
>;

export type TradeCounterpartyExpressedInterestEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST_EVENT,
  CounterpartyAction & { stipulationId: Identifier }
>;

export type TradeCounterpartyExpressedInterestAcceptedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST_ACCEPTED_EVENT,
  CounterpartyAction
>;

export type TradeCounterpartyExpressedInterestDeclinedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST_DECLINED_EVENT,
  CounterpartyAction
>;

export type TradeCounterpartyDataRequestedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_DATA_REQUESTED_EVENT,
  CounterpartyAction
>;

export type TradeCounterpartyDataRequestAcceptedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_DATA_REQUEST_ACCEPTED_EVENT,
  CounterpartyAction
>;

export type TradeCounterpartyDataRequestDeniedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_DATA_REQUEST_DENIED_EVENT,
  CounterpartyAction
>;

export type TradeCounterpartySoldEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_COUNTERPARTY_SOLD_EVENT,
  CounterpartyAction
>;

export type TradeQuoteCreatedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_QUOTE_CREATED_EVENT,
  TradeRequestQuote
>;

export type TradeQuoteAcceptedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_QUOTE_ACCEPTED_EVENT,
  TradeQuoteStatusUpdate
>;

export type TradeQuoteRejectedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_QUOTE_REJECTED_EVENT,
  TradeQuoteStatusUpdate
>;

export type TradeQuoteCounterCreatedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_QUOTE_COUNTER_CREATED_EVENT,
  TradeRequestQuote
>;

export type TradeQuoteCounterAcceptedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_QUOTE_COUNTER_ACCEPTED_EVENT,
  TradeQuoteCounterStatusUpdate
>;

export type TradeQuoteCounterRejectedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_QUOTE_COUNTER_REJECTED_EVENT,
  TradeQuoteCounterStatusUpdate
>;

export type TradeRequestStipulationAddedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_REQUEST_STIPULATION_ADDED_EVENT,
  TradeRequestStipulation
>;

export type QuoteStipulationAddedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.QUOTE_STIPULATION_ADDED_EVENT,
  AggregatedQuoteStipulation
>;

export type ActionStipulationAddedEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.ACTION_STIPULATION_ADDED_EVENT,
  AggregatedActionStipulation
>;

export type TradeMessageSentEvent = GenericEvent<
  StreamType.STREAM_TRADE_REQUEST,
  EventType.TRADE_MESSAGE_SENT_EVENT,
  AggregatedTradeRequestMessage
>;

export type ServicerCreatedEvent = GenericEvent<
  StreamType.STREAM_SERVICER,
  EventType.SERVICER_CREATED_EVENT,
  Servicer
>;

export type InsurerCreatedEvent = GenericEvent<
  StreamType.STREAM_INSURER,
  EventType.INSURER_CREATED_EVENT,
  Insurer
>;

export type CustodianCreatedEvent = GenericEvent<
  StreamType.STREAM_CUSTODIAN,
  EventType.CUSTODIAN_CREATED_EVENT,
  Custodian
>;

export type IndexCreatedEvent = GenericEvent<
  StreamType.STREAM_INDEX,
  EventType.INDEX_CREATED_EVENT,
  Index
>;

export type IndexRateCreatedEvent = GenericEvent<
  StreamType.STREAM_INDEX,
  EventType.INDEX_RATE_CREATED_EVENT,
  IndexRate
>;

export type BorrowerCreatedEvent = GenericEvent<
  StreamType.STREAM_BORROWER,
  EventType.BORROWER_CREATED_EVENT,
  Borrower
>;

export type BorrowerIdentityVerifiedEvent = GenericEvent<
  StreamType.STREAM_BORROWER,
  EventType.BORROWER_IDENTITY_VERIFIED_EVENT,
  {
    borrowerId: Borrower['id'];
    verifier: BorrowerVerifierType;
    identityVerification: BorrowerIdentityVerification;
  }
>;

export type BorrowerEmploymentVerifiedEvent = GenericEvent<
  StreamType.STREAM_BORROWER,
  EventType.BORROWER_EMPLOYMENT_VERIFIED_EVENT,
  {
    borrowerId: Borrower['id'];
    verifier: BorrowerVerifierType;
    employmentVerification: BorrowerEmploymentVerification;
  }
>;

export type LoanInsurerAssignedEvent =
  | LoanMortgageInsurerAssignedEvent
  | LoanFloodInsurerAssignedEvent
  | LoanHazardInsurerAssignedEvent;

export type UserPortfolioFiltersUpdatedEvent = GenericEvent<
  StreamType.STREAM_USER,
  EventType.USER_PORTFOLIO_FILTERS_UPDATED_EVENT,
  {
    userId: UserIdentifier;
    filters: Filter[];
  }
>;

export type SubmissionCreatedEvent = GenericEvent<
  StreamType.STREAM_SUBMISSION,
  EventType.SUBMISSION_CREATED_EVENT,
  SubmissionCreation
>;

export type SubmissionEvent = SubmissionCreatedEvent;

export type ServicerEvent = ServicerCreatedEvent;

export type InsurerEvent = InsurerCreatedEvent;

export type CustodianEvent = CustodianCreatedEvent;

export type IndexEvent = IndexCreatedEvent | IndexRateCreatedEvent;

export type BorrowerEvent =
  | BorrowerCreatedEvent
  | BorrowerIdentityVerifiedEvent
  | BorrowerEmploymentVerifiedEvent;

export type DocumentEvent = DocumentIngestedEvent | DocumentUploadedEvent;

export type LoanDocumentEvent = LoanDocumentAddedEvent;
export type LoanDetailsEvent = LoanDetailsCreatedEvent;
export type LoanTermsEvent = LoanTermsCreatedEvent;
export type LoanPropertyEvent = LoanPropertyCreatedEvent;
export type LoanAdjustmentEvent =
  | LoanInterestRateAdjustmentCreatedEvent
  | LoanInterestRateAdjustmentIndexAssignedEvent;
export type LoanBorrowerEvent = LoanBorrowerAssignedEvent;
export type LoanPaymentEvent = LoanPaymentReceivedEvent;
export type LoanServicerEvent = LoanServicerAssignedEvent | LoanEscrowAnalysisCreatedEvent;
export type LoanInsurerEvent = LoanInsurerAssignedEvent;
export type LoanCustodianEvent = LoanCustodianAssignedEvent;
export type LoanMessageEvent = LoanChatMessageSentEvent;
export type LoanPoolEvent = LoanAddedToPoolEvent;
export type LoanNoteEvent = LoanNoteCreatedEvent;

export type LoanEvent =
  | LoanDocumentEvent
  | LoanDetailsEvent
  | LoanTermsEvent
  | LoanPropertyEvent
  | LoanBorrowerEvent
  | LoanPaymentEvent
  | LoanServicerEvent
  | LoanInsurerEvent
  | LoanAdjustmentEvent
  | LoanCustodianEvent
  | LoanMessageEvent
  | LoanPoolEvent
  | LoanNoteEvent;

export type PortfolioEvent =
  | PortfolioAnalysisCreatedEvent
  | PortfolioAnalyticsCreatedEvent
  | PortfolioAnalyticsUpdatedEvent
  | PortfolioAnalyticsDeletedEvent;

export type PoolEvent =
  | PoolCreatedEvent
  | PoolUpdatedEvent
  | PoolDeletedEvent
  | LoanAddedToPoolEvent;

export type TradeRequestEvent = TradeRequestCreatedEvent;

export type TradeCounterpartyEvent =
  | TradeCounterpartyCreatedEvent
  | TradeCounterpartyExpressedInterestEvent
  | TradeCounterpartyExpressedInterestAcceptedEvent
  | TradeCounterpartyExpressedInterestDeclinedEvent
  | TradeCounterpartyDataRequestedEvent
  | TradeCounterpartyDataRequestAcceptedEvent
  | TradeCounterpartyDataRequestDeniedEvent
  | TradeCounterpartySoldEvent;

export type TradeQuoteEvent =
  | TradeQuoteCreatedEvent
  | TradeQuoteAcceptedEvent
  | TradeQuoteRejectedEvent;

export type TradeQuoteCounterEvent =
  | TradeQuoteCounterCreatedEvent
  | TradeQuoteCounterAcceptedEvent
  | TradeQuoteCounterRejectedEvent;

export type StipulationEvent =
  | TradeRequestStipulationAddedEvent
  | QuoteStipulationAddedEvent
  | ActionStipulationAddedEvent;

export type TradeMessageEvent = TradeMessageSentEvent;

export type UserEvent =
  | UserPortfolioFiltersUpdatedEvent
  | UserSettingsUpdatedEvent
  | UserAnalyticsSettingsUpdatedEvent
  | UserAnalyticsSettingsDeletedEvent;

export type Event =
  | DocumentEvent
  | LoanEvent
  | PortfolioEvent
  | PoolEvent
  | TradeRequestEvent
  | TradeCounterpartyEvent
  | TradeQuoteEvent
  | TradeQuoteCounterEvent
  | StipulationEvent
  | TradeMessageEvent
  | ServicerEvent
  | InsurerEvent
  | CustodianEvent
  | IndexEvent
  | BorrowerEvent
  | UserEvent
  | SubmissionEvent;

export type EventWithoutId<E extends Event = Event> = Omit<E, 'id'>;

export enum EventTopics {
  PAYMENT = 'Payment',
  ORIGINATION = 'Origination',
  SYSTEM = 'System',
}

export type EventLogItem = {
  date: Date;
  event: Event;
  issueId: string;
  label: string;
  note?: string;
  participant: string;
  topic?: EventTopics;
};
