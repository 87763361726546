import React from 'react';

export const FullRecordIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 19V14.5278H6.16667V16.9972L9.04444 14.1194L9.88056 14.9556L7.00278 17.8333H9.47222V19H5ZM14.5278 19V17.8333H16.9972L14.1389 14.975L14.975 14.1389L17.8333 16.9972V14.5278H19V19H14.5278ZM9.025 9.86111L6.16667 7.00278V9.47222H5V5H9.47222V6.16667H7.00278L9.86111 9.025L9.025 9.86111ZM14.975 9.86111L14.1389 9.025L16.9972 6.16667H14.5278V5H19V9.47222H17.8333V7.00278L14.975 9.86111Z"
        fill="currentColor"
      />
    </svg>
  );
};
