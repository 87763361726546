import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

export enum CitizenshipResidencyType {
  NonPermanentResidentAlien = 'NonPermanentResidentAlien',
  NonResidentAlien = 'NonResidentAlien',
  PermanentResidentAlien = 'PermanentResidentAlien',
  Unknown = 'Unknown',
  USCitizen = 'USCitizen',
}

export enum HomeownerPastThreeYearsType {
  No = 'No',
  Unknown = 'Unknown',
  Yes = 'Yes',
}

export enum IntentToOccupyType {
  No = 'No',
  Unknown = 'Unknown',
  Yes = 'Yes',
}

enum PriorPropertyTitleType {
  JointWithOtherThanSpouse = 'JointWithOtherThanSpouse',
  JointWithSpouse = 'JointWithSpouse',
  Sole = 'Sole',
}

enum PriorPropertyUsageType {
  Investment = 'Investment',
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
}

enum DeclarationExplanationType {
  AlimonyChildSupport = 'AlimonyChildSupport',
  BorrowedDownPayment = 'BorrowedDownPayment',
  CoMakerEndorserOnNote = 'CoMakerEndorserOnNote',
  DeclaredBankruptcyPastSevenYears = 'DeclaredBankruptcyPastSevenYears',
  DelinquencyOrDefault = 'DelinquencyOrDefault',
  DirectIndirectForeclosedPropertyPastSevenYears = 'DirectIndirectForeclosedPropertyPastSevenYears',
  ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment = 'ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment',
  Other = 'Other',
  OutstandingJudgments = 'OutstandingJudgments',
  PartyToLawsuit = 'PartyToLawsuit',
}

type DeclarationExplanation = MISMOEntity &
  Partial<{
    declarationExplanationDescription: MISMOString;
    declarationExplanationType: DeclarationExplanationType;
    declarationExplanationTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type Declaration = MISMOEntity &
  Partial<{
    alimonyChildSupportObligationIndicator: MISMOIndicator;
    bankruptcyIndicator: MISMOIndicator;
    borrowerFirstTimeHomebuyerIndicator: MISMOIndicator;
    borrowerHousingCounselorConsentIndicator: MISMOIndicator;
    citizenshipResidencyType: CitizenshipResidencyType;
    coMakerEndorserOfNoteIndicator: MISMOIndicator;
    FHASecondaryResidenceIndicator: MISMOIndicator;
    homeownerPastThreeYearsType: HomeownerPastThreeYearsType;
    intentToOccupyType: IntentToOccupyType;
    loanForeclosureOrJudgmentIndicator: MISMOIndicator;
    outstandingJudgmentsIndicator: MISMOIndicator;
    partyToLawsuitIndicator: MISMOIndicator;
    presentlyDelinquentIndicator: MISMOIndicator;
    priorPropertyDeedInLieuConveyedIndicator: MISMOIndicator;
    priorPropertyForeclosureCompletedIndicator: MISMOIndicator;
    priorPropertyShortSaleCompletedIndicator: MISMOIndicator;
    priorPropertyTitleType: PriorPropertyTitleType;
    priorPropertyUsageType: PriorPropertyUsageType;
    propertyProposedCleanEnergyLienIndicator: MISMOIndicator;
    specialBorrowerSellerRelationshipIndicator: MISMOIndicator;
    undisclosedBorrowedFundsAmount: MISMOAmount;
    undisclosedBorrowedFundsIndicator: MISMOIndicator;
    undisclosedComakerOfNoteIndicator: MISMOIndicator;
    undisclosedCreditApplicationIndicator: MISMOIndicator;
    undisclosedMortgageApplicationIndicator: MISMOIndicator;
    declarationExplanations: DeclarationExplanation[];
  }>;
