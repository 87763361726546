import { GetPoolsItem, Loan, LoanDetails, Pool } from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { PopupContent, PopupFooter, PopupHeader } from '../components/Popup';
import { Select } from '../components/Select';
import { Stack } from '../components/Stack';
import { usePoolUpdateMutation } from '../hooks/queries';
import { usePoolsQuery } from '../hooks/queries/usePoolsQuery';
import { CreatePoolPopup } from '../pools/CreatePoolPopup';
import * as Styles from './AddToPoolPopup.styles';

const mapPoolsToOptions = (pools: GetPoolsItem[]) => {
  return pools.map((pool) => ({
    value: String(pool.id),
    label: `${pool.name} (${pool.loanIds.length} loans)`,
  }));
};

type SuccessPopupProps = {
  pool: Pool;
  loanNumber: LoanDetails['loanNumber'];
  onDone: () => void;
};

const SuccessPopup = ({ loanNumber, onDone, pool }: SuccessPopupProps) => {
  const history = useHistory();

  return (
    <Styles.SuccessPopupComponent>
      <PopupHeader title="Success!" />
      <PopupContent>
        <p>
          {pool.name} successfully updated with <strong>loan {loanNumber}</strong>
        </p>
      </PopupContent>
      <PopupFooter>
        <Stack>
          <Button $variant="outlined" $color="secondary" onClick={onDone}>
            Done
          </Button>
          <Button onClick={() => history.push(`/pool/${pool.id}`)}>View pool</Button>
        </Stack>
      </PopupFooter>
    </Styles.SuccessPopupComponent>
  );
};

type AddToPoolPopupProps = {
  loanId: Loan['id'];
  loanNumber: string;
  onClose(): void;
};

export const AddToPoolPopup = ({ loanId, loanNumber, onClose }: AddToPoolPopupProps) => {
  const [isCreatePoolPopupDisplayed, setIsCreatePoolPopupDisplayed] = React.useState(false);
  const [isSuccessPopupDisplayed, setIsSuccessPopupDisplayed] = React.useState(false);
  const [selectedPoolId, setSelectedPoolId] = React.useState<string | undefined>(undefined);

  const { data: poolsData, isLoading } = usePoolsQuery();

  const { isLoading: isUpdating, mutateAsync: updatePool } = usePoolUpdateMutation();

  const pages = compact(get(poolsData, 'pages', []));
  const pools: GetPoolsItem[] = flatMap(pages, 'data');
  const selectedPool = pools.find((pool) => String(pool.id) === selectedPoolId);

  const closeCreatePoolPopup = () => {
    setIsCreatePoolPopupDisplayed(false);
  };

  const addLoanToSelectedPool = async () => {
    if (!selectedPool) {
      return;
    }

    await updatePool({
      poolId: selectedPool.id,
      loanIds: uniq([...selectedPool.loanIds, loanId]),
    });

    setIsSuccessPopupDisplayed(true);
  };

  const target = `add-to-pool-popup-select-target-${loanId}`;

  return (
    <React.Fragment>
      <Styles.AddToPoolPopupComponent>
        <PopupHeader title="Add to pool" />
        <PopupContent style={{ minHeight: '160px' }}>
          <Styles.SelectWrapper id={target}>
            <Select
              onChange={(value) => setSelectedPoolId(String(value))}
              options={mapPoolsToOptions(pools)}
              value={selectedPoolId}
              menuPortalTarget={target}
              styles={{
                control: {
                  alignItems: 'center',
                },
                menuPortal: {
                  top: '36px',
                  left: 0,
                  right: 0,
                  width: '100%',
                },
              }}
            />
          </Styles.SelectWrapper>
        </PopupContent>
        <PopupFooter>
          <Styles.NewPoolButton
            $variant="outlined"
            onClick={() => setIsCreatePoolPopupDisplayed(true)}
          >
            New pool
          </Styles.NewPoolButton>
          <Stack>
            <Button $color="tertiary" $variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <ButtonLoader
              disabled={isLoading || !selectedPoolId}
              isLoading={isUpdating}
              onClick={addLoanToSelectedPool}
            >
              Add
            </ButtonLoader>
          </Stack>
        </PopupFooter>
      </Styles.AddToPoolPopupComponent>

      {isCreatePoolPopupDisplayed && (
        <CreatePoolPopup
          onCancel={closeCreatePoolPopup}
          onSubmit={(pool) => {
            setSelectedPoolId(pool?.id ? String(pool.id) : undefined);
            closeCreatePoolPopup();
          }}
        />
      )}

      {isSuccessPopupDisplayed && selectedPool && (
        <SuccessPopup
          loanNumber={loanNumber}
          onDone={() => {
            setIsSuccessPopupDisplayed(false);
            onClose();
          }}
          pool={selectedPool}
        />
      )}
    </React.Fragment>
  );
};
