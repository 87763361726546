import { HouseUser } from '@styled-icons/fa-solid';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components/macro';

import photo from '../../assets/photos/photo-1.jpg';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { useGetPortfolioPropertyQuery } from '../../hooks/queries';
import { formatDate, formatDelinquency, formatMonetary } from '../../utils/formatUtils';
import { DEFAULT_INFO_WINDOW_WIDTH } from '../../utils/mapUtils';
import { Description, DescriptionName, DescriptionValue, PropertyPhoto } from './shared/styles';

const SummaryWrapper = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
`;

const Summary = styled.div`
  padding: 10px 0;
`;

const Address = styled.div`
  color: ${({ theme }) => theme.colors.greyMain};
  display: flex;
  align-items: center;
`;

const HouseIcon = styled(HouseUser)`
  margin-right: 10px;
`;

const Wrapper = styled.div`
  min-width: 100px;
  min-height: 100px;
`;

type PropertyInfoWindowProps = {
  propertyId: number;
};

export const PropertyInfoWindow = (props: PropertyInfoWindowProps) => {
  const { data: propertyDetail, isLoading } = useGetPortfolioPropertyQuery(props.propertyId);

  const currentBpo = get(propertyDetail, 'currentBpo', null);
  const currentBpoDate = get(propertyDetail, 'currentBpoDate', null);
  const isDelinquent = get(propertyDetail, 'isDelinquent', null);
  const fullAddress = get(propertyDetail, 'fullAddress', null);
  const purchasePrice = get(propertyDetail, 'purchasePrice', null);

  return (
    <Wrapper>
      <ActivityIndicator variant="dark" contain isActive={isLoading}>
        <SummaryWrapper width={DEFAULT_INFO_WINDOW_WIDTH} data-testid="PropertyMarker_InfoWindow">
          <PropertyPhoto src={photo} />
          <Summary>
            <Address>
              <HouseIcon size={40} />
              {fullAddress}
            </Address>
            <Description>
              <DescriptionName>Status</DescriptionName>
              <DescriptionValue>{formatDelinquency(isDelinquent)}</DescriptionValue>

              <DescriptionName>Purchase Price</DescriptionName>
              <DescriptionValue>{formatMonetary(purchasePrice)}</DescriptionValue>

              <DescriptionName>Current Value</DescriptionName>
              <DescriptionValue>{formatMonetary(currentBpo)}</DescriptionValue>

              <DescriptionName>Current Value Date</DescriptionName>
              <DescriptionValue>
                {formatDate(currentBpoDate ? new Date(currentBpoDate) : undefined)}
              </DescriptionValue>
            </Description>
          </Summary>
        </SummaryWrapper>
      </ActivityIndicator>
    </Wrapper>
  );
};
