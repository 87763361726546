import { rgba } from 'polished';
import styled from 'styled-components/macro';
import { Swiper } from 'swiper/react';

export const Container = styled.div`
  position: relative;
`;

export const Slider = styled(Swiper)`
  margin-top: 12px;

  .swiper-slide {
    width: auto;
  }
`;

const NavigationButtonOverlay = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 88px;
`;

export const LeftNavigationButtonOverlay = styled(NavigationButtonOverlay)`
  left: -12px;
  padding-left: 12px;
  justify-content: start;
  background-image: linear-gradient(
    to left,
    ${({ theme }) => rgba(theme.colors.primary02, 0)},
    ${({ theme }) => theme.colors.primary02}
  );
`;

export const RightNavigationButtonOverlay = styled(NavigationButtonOverlay)`
  right: -12px;
  padding-right: 12px;
  justify-content: end;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => rgba(theme.colors.primary02, 0)},
    ${({ theme }) => theme.colors.primary02}
  );
`;

export const NavigationButton = styled.button`
  display: inline-flex;
  appearance: none;
  cursor: pointer;
  padding: 3px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary02};
  border: 1px solid ${({ theme }) => theme.colors.white05};
`;
