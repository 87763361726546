import { TradeRequest } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postFormDataRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type CreateTradeRequestQuoteMutation = {
  tradeRequestId: TradeRequest['id'];
  value: number;
  stipulation?: File;
};

const createTradeRequestQuote = (data: CreateTradeRequestQuoteMutation) => {
  const { stipulation, tradeRequestId, value } = data;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/quotes`);
  const body = new FormData();

  body.append('value', String(value));

  if (stipulation) {
    body.append('stipulation', stipulation);
  }

  return postFormDataRequestWithAuth(url, { body });
};

export const useCreateTradeRequestQuoteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createTradeRequestQuote, {
    onSuccess: (_, { tradeRequestId }) => {
      queryClient.invalidateQueries([QueryKeys.TRADE_REQUEST_INBOUND_QUOTES, tradeRequestId]);
    },
  });
};
