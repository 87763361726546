import React from 'react';

export const PDFOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M23.2,16c-0.5-1-2.4-1.3-3.3-1.4c-0.7-0.1-1.4-0.1-2.1-0.1c-0.5,0-1.1,0-1.6,0.1c-0.2,0-0.4,0-0.6,0
	c-0.2-0.2-0.4-0.4-0.6-0.6c-1.2-1.4-2.1-2.9-2.9-4.6c0.2-0.8,0.4-1.6,0.5-2.5c0.2-1.6,0.2-3.3-0.3-4.8c-0.2-0.5-0.7-1.1-1.4-0.8
	c-0.7,0.4-0.9,1.4-1,2.1c0,0.6,0,1.2,0.1,1.7c0.1,0.6,0.3,1.1,0.4,1.7c0.2,0.5,0.3,1,0.5,1.5c-0.1,0.4-0.3,0.8-0.4,1.1
	c-0.3,0.8-0.7,1.6-1,2.4c-0.2,0.4-0.3,0.8-0.5,1.1c-0.5,1.2-1.1,2.3-1.8,3.4c-1.5,0.5-2.9,1.2-4,1.9c-0.6,0.4-1.1,0.8-1.6,1.3
	c-0.4,0.4-0.8,1-0.9,1.6c0,0.4,0.1,0.7,0.4,0.9c0.4,0.3,0.9,0.3,1.4,0.2c1.5-0.3,2.7-1.6,3.7-2.7c0.7-0.8,1.5-1.7,2.3-2.9
	c0,0,0,0,0,0c1.4-0.4,2.9-0.8,4.6-1.1c0.7-0.1,1.5-0.2,2.3-0.3c0.5,0.5,1.1,1,1.8,1.4c0.5,0.3,1,0.6,1.6,0.8
	c0.6,0.2,1.1,0.3,1.7,0.4c0.3,0,0.6,0.1,0.9,0c0.7,0,1.7-0.3,1.7-1.1C23.4,16.4,23.4,16.2,23.2,16z M6.7,17.5
	c-0.3,0.5-0.6,1-0.9,1.3c-0.7,1-1.5,2.1-2.7,2.6c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.6-0.1-0.5-0.5c0-0.2,0.1-0.4,0.2-0.6
	c0.1-0.2,0.3-0.4,0.5-0.6c0.4-0.4,0.9-0.8,1.5-1.2c0.9-0.6,1.9-1.1,3.1-1.5C6.9,17.3,6.8,17.4,6.7,17.5z M10.8,4.9
	c-0.1-0.5-0.1-1.1,0-1.6c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.7,0.4-0.7c0.4-0.1,0.5,0.7,0.5,0.9c0.2,1.3,0,2.6-0.3,3.9
	c0,0.2-0.1,0.4-0.1,0.6c-0.1-0.2-0.2-0.5-0.2-0.7C11,6,10.9,5.5,10.8,4.9z M13.3,14.8c-1.4,0.2-2.7,0.5-3.9,0.9c0.1,0,0.8-1.3,1-1.6
	c0.7-1.2,1.3-2.5,1.7-3.9c0.7,1.4,1.6,2.8,2.7,4.1c0.1,0.1,0.2,0.2,0.3,0.3C14.4,14.6,13.8,14.7,13.3,14.8z M22.5,16.6
	c0,0.3-0.6,0.4-0.9,0.5c-0.8,0.1-1.6,0-2.4-0.2c-0.5-0.2-1-0.4-1.5-0.7c-0.5-0.3-0.9-0.6-1.3-1c0.5,0,1-0.1,1.5,0c0.5,0,1,0,1.6,0.1
	c1,0.1,2.1,0.4,2.8,1.1C22.4,16.4,22.5,16.5,22.5,16.6z"
      />
    </svg>
  );
};
