import React from 'react';
import ReactSelect, {
  components,
  ControlProps,
  CSSObjectWithLabel,
  IndicatorsContainerProps,
  OptionProps,
  Props,
  SelectInstance,
  StylesConfig,
} from 'react-select';
import { DefaultTheme, useTheme } from 'styled-components/macro';

import { CheckIcon, ChevronDownIcon } from './icons';
import * as Styles from './Select.styles';

type OptionValue = string | boolean;

type OptionType = {
  label: string;
  value: OptionValue;
};

type StylesOverrides = Partial<Record<keyof StylesConfig<OptionType, false>, CSSObjectWithLabel>>;

type CustomProps = {
  isInvalid?: boolean;
};

const buildCustomStyles = (
  theme: DefaultTheme,
  props: CustomProps,
  stylesOverrides?: StylesOverrides
) => {
  // React-Select uses Emotion syntax
  const styles: StylesConfig<OptionType, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: theme.colors.inputBackground,
      borderRadius: '0.25em',
      border: '1px solid transparent',
      padding: '0 0.5em',
      alignItems: 'flex-end',
      height: '2.5em',
      minWidth: 250,
      minHeight: 0,
      color: theme.colors.textPrimary02,
      opacity: state.isDisabled ? 0.2 : 1,

      '::placeholder': {
        color: theme.colors.textPrimary02,
      },

      '&:hover': {
        background: theme.colors.inputBackgroundHover,
        borderBottomColor: props.isInvalid
          ? theme.colors.inputBorderInvalidHover
          : theme.colors.inputBorderHover,
      },

      '&:active': {
        background: theme.colors.inputBackgroundActive,
        borderColor: props.isInvalid
          ? theme.colors.inputBorderInvalidActive
          : theme.colors.inputBorderActive,
      },

      '&:focus-within': {
        borderColor: props.isInvalid
          ? theme.colors.inputBorderInvalidActive
          : theme.colors.inputBorderActive,
      },

      ...stylesOverrides?.control,
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: theme.typography.fontFamily.text,
      fontSize: theme.typography.fontSize.body02,
      fontWeight: theme.typography.fontWeight.medium,
      lineHeight: `${theme.typography.lineHeight.body02}px`,
      ...stylesOverrides?.singleValue,
    }),
    input: (provided) => ({
      ...provided,
      caretColor: props.isInvalid ? theme.colors.inputCaretInvalid : theme.colors.inputCaret,
      fontFamily: theme.typography.fontFamily.text,
      fontSize: theme.typography.fontSize.body02,
      fontWeight: theme.typography.fontWeight.medium,
      lineHeight: `${theme.typography.lineHeight.body02}px`,
      ...stylesOverrides?.input,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontFamily: theme.typography.fontFamily.text,
      fontSize: theme.typography.fontSize.body02,
      fontWeight: theme.typography.fontWeight.medium,
      lineHeight: `${theme.typography.lineHeight.body02}px`,
      color: theme.colors.textPrimary02,
      ...stylesOverrides?.noOptionsMessage,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: theme.typography.fontFamily.text,
      fontSize: theme.typography.fontSize.body02,
      fontWeight: theme.typography.fontWeight.medium,
      lineHeight: `${theme.typography.lineHeight.body02}px`,
      ...stylesOverrides?.placeholder,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? 'scale(-1)' : 'none',
      ...stylesOverrides?.indicatorsContainer,
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: theme.typography.fontFamily.text,
      fontSize: theme.typography.fontSize.body02,
      fontWeight: state.isSelected
        ? theme.typography.fontWeight.medium
        : theme.typography.fontWeight.light,
      lineHeight: '24px',
      padding: '0.5em',
      background: state.isSelected
        ? theme.colors.selectOptionBackgroundSelected
        : theme.colors.selectOptionBackground,
      color: state.isSelected
        ? props.isInvalid
          ? theme.colors.selectOptionTextSelectedInvalid
          : theme.colors.selectOptionTextSelected
        : theme.colors.selectOptionText,

      '&:hover': {
        background: theme.colors.inputBackgroundHover,
        color: props.isInvalid
          ? theme.colors.selectOptionTextSelectedInvalid
          : theme.colors.selectOptionTextSelected,
      },

      '&:active': {
        background: theme.colors.inputBackgroundActive,
        color: props.isInvalid
          ? theme.colors.selectOptionTextSelectedInvalid
          : theme.colors.selectOptionTextSelected,
      },

      '&:first-of-type': {
        borderTopLeftRadius: '0.25em',
        borderTopRightRadius: '0.25em',
      },

      '&:last-of-type': {
        borderBottomLeftRadius: '0.25em',
        borderBottomRightRadius: '0.25em',
      },

      ...stylesOverrides?.option,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: props.isInvalid
        ? theme.colors.inputBorderInvalidActive
        : theme.colors.inputBorderActive,
      borderRadius: '0.25em',
      background: theme.colors.inputBackground,
      ...stylesOverrides?.menu,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 10,
      ...stylesOverrides?.menuPortal,
    }),
  };

  return styles;
};

const hasPlaceholder = (placeholder: string) => {
  return Boolean(placeholder);
};

const IndicatorsContainer = (props: IndicatorsContainerProps<OptionType, false>) => {
  return (
    <components.IndicatorsContainer {...props}>
      <ChevronDownIcon />
    </components.IndicatorsContainer>
  );
};

const Option = (props: OptionProps<OptionType, false>) => {
  const { isSelected, label } = props;
  return (
    <components.Option {...props}>
      <Styles.Option>
        {label} {isSelected && <CheckIcon />}
      </Styles.Option>
    </components.Option>
  );
};

export type SelectType = SelectInstance<OptionType, false>;

type SelectProps = Pick<Props, 'onBlur' | 'onFocus' | 'name'> & {
  onChange?: (value: OptionValue) => void;
  value?: OptionValue;
  options: OptionType[];
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  styles?: StylesOverrides;
  isInvalid?: boolean;
  menuPortalTarget?: string;
};

export const Select = React.forwardRef<SelectType, SelectProps>(
  (
    {
      disabled,
      isInvalid = false,
      label,
      menuPortalTarget,
      onChange,
      options,
      placeholder = '',
      styles,
      value,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const Control = (props: ControlProps<OptionType, false>) => {
      const showSmallLabel = hasPlaceholder(placeholder) || props.isFocused || props.hasValue;

      return (
        <components.Control {...props}>
          <Styles.Label $isSmall={showSmallLabel}>{label}</Styles.Label>
          {props.children}
        </components.Control>
      );
    };

    const customProps = {
      isInvalid,
    };

    return (
      <ReactSelect<OptionType>
        {...props}
        value={
          value && options.length > 0
            ? options.find((option) => option.value === value) ?? null
            : undefined
        }
        onChange={
          onChange
            ? (option) => {
                if (option) {
                  onChange(option.value);
                }
              }
            : undefined
        }
        ref={ref}
        components={{ IndicatorsContainer, Control, Option }}
        isDisabled={disabled}
        options={options}
        placeholder={placeholder}
        styles={buildCustomStyles(theme, customProps, styles)}
        menuPortalTarget={document.getElementById(menuPortalTarget || 'modal-root')}
        unstyled
      />
    );
  }
);
