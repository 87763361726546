import { rgba } from 'polished';

import { statusBlack } from './darkTheme/colors';

// TODO: give more appropriate name to this shadow when the intent is clear
// this was moved from the existing servicer chat, not sure if that
// meant to be default shadow across the UI for some modals, or specific to the chat only.
export const chatHeaderShadow = `0px 4px 4px ${rgba(statusBlack, 0.1)}`;
export const sidebarToggleButtonShadow = `0 1px 2px ${rgba(statusBlack, 0.3)}, 0 2px 6px 2px ${rgba(
  statusBlack,
  0.15
)}`;
export const filterPanel = `4px 0px 10px ${rgba(statusBlack, 0.2)}`;
export const poolPopoverPanel = `0px 4px 6px rgba(5, 46, 49, 0.8);`;
export const loanPopoverPanel = `0px 4px 6px rgba(10, 13, 35, 0.7)`;
export const tradeShadow = `0px 4px 4px ${rgba(statusBlack, 0.15)}`;
export const menuShadow = `0px 4px 6px rgba(10, 13, 35, 0.7)`;

export const dropdownPanel = `0 4px 4px 0 rgba(0, 0, 0, 0.16)`;
export const modal = `0 4px 24px 0 #111322`;
