import styled, { css } from 'styled-components/macro';

import { BodyText } from '../components/BodyText';
import { ProgressBar, ProgressBarFillerMixin } from './SubmissionsList.styles';

export const BreakdownWrapper = styled.div`
  background: ${(props) => props.theme.colors.primary03};
  padding: 16px 16px 0;
  border-radius: 4px;
`;

// Todo - replace with new Key Value Wrapper component
export const KVWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DocumentListWrapper = styled.div`
  display: flex;
  padding: 16px 4px;
  overflow-y: auto;
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
`;

export const DocumentContainer = styled.div<{ $hasErrors?: boolean }>`
  background: ${(props) => props.theme.colors.primary10};
  border: 1px solid ${(props) => props.theme.colors.primary09};
  color: ${(props) => props.theme.colors.textSecondary01};
  margin-right: 16px;
  border-radius: 2px;
  padding: 8px;
  min-width: 82px;

  ${(props) =>
    props.$hasErrors &&
    css`
      border-color: ${props.theme.colors.statusOrange};
      /* One off use case for a non-translucent version of a light orange */
      background: #fef1e9;

      ${IconWrapper} {
        background: ${props.theme.colors.statusOrange};
      }
    `}
`;

export const DocumentTypeHeader = styled.div`
  ${(props) => props.theme.typography.headings.headingMedium04};
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const ErrorType = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.typography.bodyText.bodyMedium04};
  padding: 2px 0;
`;

export const ErrorCount = styled.div`
  background: ${(props) => props.theme.colors.primary09};
  border-radius: 2px;
  min-width: 16px;
  display: flex;
  justify-content: center;
`;

export const BreakdownDocumentWrapper = styled.div`
  display: flex;
  text-align: center;
`;

export const BreakdownDocument = styled.div`
  text-align: left;
  width: 80px;
  margin: 0 10px;
  padding: 5px;
`;

export const BreakdownDocumentText = styled(BodyText)`
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const ProgressWrapper = styled.div`
  margin-right: 50px;
  position: relative;
  flex: 1;
  min-width: 200px;
  margin-top: 4px;
`;

export const Progress = styled.div<{ $progress: number; $isComplete?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 100%;
  background: ${(props) => props.theme.colors.primary05};
  border-radius: 2px;

  &::after {
    transition: all 500ms linear;
    ${ProgressBarFillerMixin};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $progress }) => $progress * 100}%;
    bottom: 0;
    border-radius: 2px;
  }
`;
