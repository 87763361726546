import styled, { css } from 'styled-components/macro';

import { Card } from '../../../components/Card';

export const FullHeightCard = styled(Card)`
  height: 100%;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

export const EndorsementHeadingWrapper = styled.div`
  padding: 12px 12px 0 12px;
  display: flex;
  gap: 8px;
`;

export const ChainSectionWrapper = styled.div`
  padding-bottom: 12px;
  height: 100%;
`;

export const ChainWrapper = styled.div`
  height: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorText = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.statusRed};
    ${theme.typography.headings.headingRegular03}
  `
);

export const StatusKey = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.white70};
    ${theme.typography.headings.headingMedium03}
  `
);

export const StatusVal = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.white};
    ${theme.typography.headings.headingRegular03}
  `
);
