import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { BuydownFund } from './buydownFund';

type BuydownFundsSummary = MISMOEntity &
  Partial<{
    buydownTotalSubsidyAmount: MISMOAmount;
  }>;

type BuydownOccurrence = MISMOEntity &
  Partial<{
    buydownInitialEffectiveInterestRatePercent: MISMOPercent;
    buydownOccurrenceEffectiveDate: MISMODate;
    remainingBuydownBalanceAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
  }>;

enum BuydownBaseDateType {
  FirstPaymentDate = 'FirstPaymentDate',
  LastPaymentDate = 'LastPaymentDate',
  NoteDate = 'NoteDate',
  Other = 'Other',
}

enum BuydownCalculationType {
  DecliningLoanBalance = 'DecliningLoanBalance',
  OriginalLoanAmount = 'OriginalLoanAmount',
}

enum BuydownPayoffDisbursementType {
  ApplyToPrincipal = 'ApplyToPrincipal',
  Unknown = 'Unknown',
}

type BuydownRule = MISMOEntity &
  Partial<{
    buydownBaseDateType: BuydownBaseDateType;
    buydownBaseDateTypeOtherDescription: MISMOString;
    buydownCalculationType: BuydownCalculationType;
    buydownChangeFrequencyMonthsCount: MISMOCount;
    buydownDurationMonthsCount: MISMOCount;
    buydownIncreaseRatePercent: MISMOPercent;
    buydownInitialDiscountPercent: MISMOPercent;
    buydownLenderFundingIndicator: MISMOIndicator;
    buydownPayoffDisbursementType: BuydownPayoffDisbursementType;
    buydownReflectedInNoteIndicator: MISMOIndicator;
  }>;

type BuydownSchedule = MISMOEntity &
  Partial<{
    buydownScheduleAdjustmentPercent: MISMOPercent;
    buydownSchedulePeriodicPaymentAmount: MISMOAmount;
    buydownSchedulePeriodicPaymentEffectiveDate: MISMODate;
    buydownSchedulePeriodicPaymentsCount: MISMOCount;
    buydownSchedulePeriodIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type Buydown = MISMOEntity &
  Partial<{
    buydownFunds: BuydownFund[];
    buydownFundsSummary: BuydownFundsSummary;
    buydownOccurrences: BuydownOccurrence[];
    buydownRule: BuydownRule;
    buydownSchedules: BuydownSchedule[];
  }>;
