import { IntegratedDisclosureSectionType } from './integratedDisclosure';
import { FeePaidToType, PaidTo } from './paidTo';
import { SelectedServiceProvider } from './serviceProvider';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
  MISMOValue,
} from './types';

enum MI_LTVCutoffType {
  AppraisedValue = 'AppraisedValue',
  SalesPrice = 'SalesPrice',
}

enum MICertificationStatusType {
  LenderToObtain = 'LenderToObtain',
  Other = 'Other',
  SellerOfLoanToObtain = 'SellerOfLoanToObtain',
}

export enum MICompanyNameType {
  ArchMI = 'ArchMI',
  CMG = 'CMG',
  Essent = 'Essent',
  Genworth = 'Genworth',
  MGIC = 'MGIC',
  NationalMI = 'NationalMI',
  Other = 'Other',
  PMI = 'PMI',
  Radian = 'Radian',
  RMIC = 'RMIC',
  Triad = 'Triad',
  UGI = 'UGI',
}

enum MICoveragePlanType {
  LossLimitCap = 'LossLimitCap',
  Other = 'Other',
  Pool = 'Pool',
  RiskSharing = 'RiskSharing',
  SecondLayer = 'SecondLayer',
  StandardPrimary = 'StandardPrimary',
}

enum MIDurationType {
  Annual = 'Annual',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
  PeriodicMonthly = 'PeriodicMonthly',
  SingleLifeOfLoan = 'SingleLifeOfLoan',
  SingleSpecific = 'SingleSpecific',
  SplitPremium = 'SplitPremium',
}

enum MIInitialPremiumAtClosingType {
  Deferred = 'Deferred',
  Other = 'Other',
  Prepaid = 'Prepaid',
}

enum MIPremiumPaymentType {
  Escrowed = 'Escrowed',
  Financed = 'Financed',
  Other = 'Other',
}

enum MIPremiumRefundableType {
  NotRefundable = 'NotRefundable',
  Other = 'Other',
  Refundable = 'Refundable',
  RefundableWithLimits = 'RefundableWithLimits',
}

export enum MIPremiumSourceType {
  Borrower = 'Borrower',
  BorrowerAndLender = 'BorrowerAndLender',
  Lender = 'Lender',
  Other = 'Other',
}

enum MISourceType {
  FHA = 'FHA',
  Other = 'Other',
  PMI = 'PMI',
  USDA = 'USDA',
}

export enum PrimaryMIAbsenceReasonType {
  CharterParticipation = 'CharterParticipation',
  DelinquencyDisposition = 'DelinquencyDisposition',
  InvestorPurchasedMortgageInsurance = 'InvestorPurchasedMortgageInsurance',
  MICanceledBasedOnCurrentLTV = 'MICanceledBasedOnCurrentLTV',
  NoMIBasedOnOriginalLTV = 'NoMIBasedOnOriginalLTV',
  Other = 'Other',
  PledgedAsset = 'PledgedAsset',
  PoolCoverage = 'PoolCoverage',
  Repurchase = 'Repurchase',
  SelfInsured = 'SelfInsured',
}

export type MIData = MISMOEntity &
  Partial<{
    borrowerMITerminationDate: MISMODate;
    feePaidToType: FeePaidToType;
    feePaidToTypeOtherDescription: MISMOString;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    lenderPaidMIInterestRateAdjustmentPercent: MISMOPercent;
    MI_LTVCutoffPercent: MISMOPercent;
    MI_LTVCutoffType: MI_LTVCutoffType;
    MICancellationDate: MISMODate;
    MICertificateIdentifier: MISMOIdentifier;
    MICertificationStatusType: MICertificationStatusType;
    MICertificationStatusTypeOtherDescription: MISMOString;
    MICollectedNumberOfMonthsCount: MISMOCount;
    MICompanyNameType: MICompanyNameType;
    MICompanyNameTypeOtherDescription: MISMOString;
    MICoverageEffectiveDate: MISMODate;
    MICoveragePercent: MISMOPercent;
    MICoveragePlanType: MICoveragePlanType;
    MICoveragePlanTypeOtherDescription: MISMOString;
    MICushionNumberOfMonthsCount: MISMOCount;
    MIDurationType: MIDurationType;
    MIDurationTypeOtherDescription: MISMOString;
    MIEscrowedIndicator: MISMOIndicator;
    MIEscrowIncludedInAggregateIndicator: MISMOIndicator;
    MIInitialPremiumAmount: MISMOAmount;
    MIInitialPremiumAtClosingType: MIInitialPremiumAtClosingType;
    MIInitialPremiumAtClosingTypeOtherDescription: MISMOString;
    MIInitialPremiumRatePercent: MISMOPercent;
    MIPremiumCalendarYearAmount: MISMOAmount;
    MIPremiumFinancedAmount: MISMOAmount;
    MIPremiumFinancedIndicator: MISMOIndicator;
    MIPremiumFromClosingAmount: MISMOAmount;
    MIPremiumPaymentType: MIPremiumPaymentType;
    MIPremiumPaymentTypeOtherDescription: MISMOString;
    MIPremiumRefundableAmount: MISMOAmount;
    MIPremiumRefundableConditionsDescription: MISMOString;
    MIPremiumRefundableType: MIPremiumRefundableType;
    MIPremiumRefundableTypeOtherDescription: MISMOString;
    MIPremiumSourceType: MIPremiumSourceType;
    MIPremiumSourceTypeOtherDescription: MISMOString;
    MIRemovalIdentifier: MISMOIdentifier;
    MIScheduledTerminationDate: MISMODate;
    MISourceType: MISourceType;
    MISourceTypeOtherDescription: MISMOString;
    primaryMIAbsenceReasonType: PrimaryMIAbsenceReasonType;
    primaryMIAbsenceReasonTypeOtherDescription: MISMOString;
    requiredProviderOfServiceIndicator: MISMOIndicator;
    scheduledAmortizationMidpointDate: MISMODate;
    sellerMIPaidToDate: MISMODate;
    // MIPoolInsurance: MIPoolInsurance;
    // MIPremiums: MIPremium[];
    // MIPremiumTaxes: MIPremiumTax[];
    paidTo: PaidTo;
    selectedServiceProvider: SelectedServiceProvider;
  }>;
