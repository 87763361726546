import {
  OutboundTradeRequestWithSummary,
  StipulationType,
  TradeRequestMessageCreationMetadataQuote,
  TradeRequestQuote,
} from '@plus-platform/shared';
import React from 'react';

import { Button } from '../../../../components/Button';
import { ButtonLoader } from '../../../../components/ButtonLoader';
import { Divider } from '../../../../components/Divider';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { DropzoneProvider } from '../../../../contexts/DropzoneContext';
import {
  useCreateTradeRequestQuoteCounterMutation,
  useRejectTradeRequestQuoteMutation,
} from '../../../../hooks/queries';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { useStipulationResources } from '../../../useStipulationResources';
import { QuoteForm } from '../QuoteForm';

enum View {
  Initial,
  Reject,
  Counter,
}

type OutboundQuoteAcceptRejectCounterProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: OutboundTradeRequestWithSummary;
  onQuoteAccept: (id: TradeRequestQuote['id']) => void;
  onCounterInitial: () => void;
  onRejectInitial: () => void;
  onRejectInitialConfirm: () => void;
  onCancelReject: () => void;
  onCancelCounter: () => void;
};

export const OutboundQuoteAcceptRejectCounter = (props: OutboundQuoteAcceptRejectCounterProps) => {
  const {
    onCancelCounter,
    onCancelReject,
    onCounterInitial,
    onQuoteAccept: onTradeAccept,
    onRejectInitial,
    onRejectInitialConfirm,
    quote,
    tradeRequest,
  } = props;
  const { fromCounterparty } = quote;
  const [view, setView] = React.useState(View.Initial);
  const userName = formatNameWithInitial(
    fromCounterparty.user.lastName,
    fromCounterparty.user.firstName
  );

  const stipulationResources = useStipulationResources(
    StipulationType.STIPULATION_QUOTE,
    quote?.quoteStipulation?.quoteStipulation?.id
  );

  const {
    isLoading: isRejectingQuote,
    isSuccess: hasRejectedQuote,
    mutateAsync: rejectQuote,
  } = useRejectTradeRequestQuoteMutation();

  const {
    isLoading: isCountering,
    isSuccess: hasCountered,
    mutateAsync: counter,
  } = useCreateTradeRequestQuoteCounterMutation();

  const canActOnQuote = !isRejectingQuote && !hasRejectedQuote && !isCountering && !hasCountered;

  return (
    <React.Fragment>
      {view === View.Initial && (
        <PlusMessage>
          <MessageText>
            <TradeMessage id="Trade.Outbound.Quote.Received" values={{ userName }} />
          </MessageText>
          <MessageActions>
            {quote?.quoteStipulation?.quoteStipulation && (
              <React.Fragment>
                <Button $size="large" onClick={stipulationResources.openDocument}>
                  Review Stips
                </Button>
                <Divider $flexItem $orientation="vertical" />
              </React.Fragment>
            )}
            <Button $size="large" disabled={!canActOnQuote} onClick={() => onTradeAccept(quote.id)}>
              Accept bid
            </Button>
            <Button
              $size="large"
              $variant="outlined"
              disabled={!canActOnQuote}
              onClick={() => {
                setView(View.Counter);
                onCounterInitial();
              }}
            >
              Counter
            </Button>
            <Divider $flexItem $orientation="vertical" />
            <Button
              $color="danger"
              $size="large"
              $variant="outlined"
              disabled={!canActOnQuote}
              onClick={() => {
                setView(View.Reject);
                onRejectInitial();
              }}
            >
              Reject without counter
            </Button>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.Reject && (
        <PlusMessage>
          <MessageText>
            <TradeMessage id="Trade.Outbound.Quote.Received" values={{ userName }} />
          </MessageText>
          <MessageText>
            You've chosen to reject without countering the quote-with-stips from {userName} Are you
            sure to want to proceed?
          </MessageText>
          <MessageActions>
            <ButtonLoader
              $size="large"
              isLoading={isRejectingQuote}
              onClick={async () => {
                await rejectQuote({
                  tradeRequestId: tradeRequest.id,
                  tradeQuoteId: quote.id,
                });
                onRejectInitialConfirm();
              }}
            >
              Proceed
            </ButtonLoader>
            <Button
              $color="tertiary"
              $size="large"
              $variant="outlined"
              disabled={!canActOnQuote}
              onClick={() => {
                setView(View.Initial);
                onCancelReject();
              }}
            >
              Cancel
            </Button>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.Counter && (
        <DropzoneProvider accept="application/pdf" multiple={false} noClick>
          {({ getRootProps }) => (
            <div {...getRootProps()}>
              <PlusMessage>
                <MessageText>
                  <TradeMessage id="Trade.Outbound.Quote.Received" values={{ userName }} />
                </MessageText>
                <MessageText>OK, you've chosen to counter</MessageText>
                <QuoteForm
                  isCounter
                  isSubmitting={isCountering}
                  onCancel={() => {
                    setView(View.Initial);
                    onCancelCounter();
                  }}
                  onSubmit={(values) => {
                    counter({
                      stipulation: values?.stipulation,
                      reason: values.reason,
                      value: values.pricePercentage / 100,
                      tradeRequestId: tradeRequest.id,
                      quoteId: quote.id,
                    });
                  }}
                />
              </PlusMessage>
            </div>
          )}
        </DropzoneProvider>
      )}
    </React.Fragment>
  );
};
