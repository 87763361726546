import { Boundary } from './boundary';
import { Housing } from './housing';
import { PresentLandUse, PresentLandUseDetail } from './presentLandUse';
import {
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from './types';

export enum NeighborhoodGrowthPaceType {
  Declining = 'Declining',
  FullyDeveloped = 'FullyDeveloped',
  Rapid = 'Rapid',
  Slow = 'Slow',
  Stable = 'Stable',
}
export enum NeighborhoodPropertyLocationType {
  Other = 'Other',
  Rural = 'Rural',
  RuralUrban = 'RuralUrban',
  Suburban = 'Suburban',
  Urban = 'Urban',
  UrbanSprawl = 'UrbanSprawl',
}

export type Neighborhood = MISMOEntity &
  Partial<{
    adverseNeighborhoodInfluenceIndicator: MISMOIndicator;
    neighborhoodAcceptabilityOfCooperativeOwnershipDescription: MISMOString;
    neighborhoodAcceptanceOfCooperativeOwnershipIndicator: MISMOIndicator;
    neighborhoodBoundariesDescription: MISMOString;
    neighborhoodBuiltUpPercent: MISMOPercent;
    neighborhoodDescription: MISMOString;
    neighborhoodGrowthPaceType: NeighborhoodGrowthPaceType;
    neighborhoodMarketabilityFactorsDescription: MISMOString;
    neighborhoodMarketConditionsDescription: MISMOString;
    neighborhoodName: MISMOString;
    neighborhoodPopulationDensityDescription: MISMOString;
    neighborhoodPotentialManufacturedHomeParkDescription: MISMOString;
    neighborhoodPropertyLocationType: NeighborhoodPropertyLocationType;
    neighborhoodPropertyLocationTypeOtherDescription: MISMOString;
    neighborhoodStreetMeetsStandardsIndicator: MISMOIndicator;
    boundaries: Boundary[];
    housings: Housing[];
    // neighborhoodInfluences: NeighborhoodInfluence[];
    presentLandUseDetail: PresentLandUseDetail;
    presentLandUses: PresentLandUse[];
    // schools: School[];
  }>;
