import styled, { css } from 'styled-components/macro';

export const PanelWrapper = styled.div<{ $isLeftPanelOpen: boolean; $isRightPanelOpen: boolean }>(
  ({ $isLeftPanelOpen, $isRightPanelOpen }) => css`
    position: relative;
    display: grid;
    gap: 8px;
    width: 100%;
    flex: 1;
    min-height: 0;

    ${!$isLeftPanelOpen &&
    !$isRightPanelOpen &&
    css`
      grid-template-columns: auto;
    `}

    ${$isLeftPanelOpen &&
    !$isRightPanelOpen &&
    css`
      grid-template-columns: min-content auto;
    `}

    ${!$isLeftPanelOpen &&
    $isRightPanelOpen &&
    css`
      grid-template-columns: auto min-content;
    `}

    ${$isLeftPanelOpen &&
    $isRightPanelOpen &&
    css`
      grid-template-columns: min-content auto min-content;
    `}
  `
);
