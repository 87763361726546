import { Pool, PortfolioSummary } from '@plus-platform/shared';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { InternalLink } from '../components/Link';
import { LoanDetailedSummary } from '../loans/DeprecatedLoanSummary';
import { PoolMenu } from './PoolMenu';

const Wrapper = styled.div`
  position: relative;
`;

const Link = styled(InternalLink)`
  display: block;
`;

const WidgetWrapper = styled.div`
  border-radius: 6px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.greenTree};
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h2(
  ({ theme }) => css`
    margin-right: 48px;
    font-size: 16px;
    font-weight: 600;
    font-family: ${theme.typography.fontFamily.display};
    color: ${transparentize(0.2, theme.colors.white)};
  `
);

type PoolWidgetProps = {
  id: Pool['id'];
  name: string;
  summary: PortfolioSummary;
};

export const PoolWidget = ({ id, name, summary }: PoolWidgetProps) => {
  return (
    <Wrapper data-testid="PoolWidget">
      <Link to={`pool/${id}`}>
        <WidgetWrapper>
          <Header>
            <Title>{name}</Title>
            <PoolMenu loansCount={summary.loansCount} poolId={String(id)} />
          </Header>
          <LoanDetailedSummary summary={summary} variant="pool" />
        </WidgetWrapper>
      </Link>
    </Wrapper>
  );
};
