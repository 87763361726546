import { Marker } from '@react-google-maps/api';
import { lighten } from 'polished';

import { useAirQualityCurrentQuery } from '../../hooks/queries';

type AirQualityProps = {
  latitude: number;
  longitude: number;
};

export const AirQualityLayer = ({ latitude, longitude }: AirQualityProps) => {
  const { data } = useAirQualityCurrentQuery(latitude, longitude);

  if (!data) {
    return null;
  }

  const { color, label, value } = data;

  return (
    <Marker
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        scale: 25,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: lighten(0.35, color),
        strokeOpacity: 1,
        strokeWeight: 3,
        anchor: new google.maps.Point(0, 3),
      }}
      label={`${value}`}
      options={{
        clickable: false,
      }}
      position={{
        lng: longitude,
        lat: latitude,
      }}
      title={label}
    />
  );
};
