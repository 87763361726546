export const PlusSquareFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.06 0.5H4.94C2.48 0.5 0.5 2.5 0.5 4.94V15.04C0.5 17.5 2.5 19.48 4.94 19.48H15.04C17.5 19.48 19.48 17.48 19.48 15.04V4.94C19.5 2.5 17.5 0.5 15.06 0.5ZM11.02 11.82V14.5C11.02 15.08 10.54 15.58 9.96 15.56C9.7 15.56 9.46 15.44 9.28 15.26C9.1 15.08 8.98 14.82 8.98 14.54V11.82C8.98 11.38 8.62 11.02 8.18 11.02H5.46C5.18 11.02 4.92 10.9 4.74 10.72C4.56 10.54 4.46 10.3 4.44 10.04C4.42 9.46 4.92 8.98 5.5 8.98C6.18 8.98 7.38 8.98 8.18 8.98C8.62 8.98 8.98 8.62 8.98 8.18V5.46C8.98 4.86 9.5 4.38 10.1 4.44C10.62 4.5 11.02 4.96 11.02 5.5V8.18C11.02 8.62 11.38 8.98 11.82 8.98H14.54C15.14 8.98 15.62 9.5 15.56 10.1C15.5 10.62 15.04 11.02 14.5 11.02H11.82C11.38 11.02 11.02 11.38 11.02 11.82Z"
        fill="currentColor"
      />
    </svg>
  );
};
