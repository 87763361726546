import React from 'react';

export const PropertyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7143 13.5238V8.57143L13.9048 5.90476L10.0952 8.57143V9.71429H8.57143V7.80952L13.9048 4L19.2381 7.80952V13.5238H17.7143ZM14.2857 8.95238H15.0476V8.19048H14.2857V8.95238ZM12.7619 8.95238H13.5238V8.19048H12.7619V8.95238ZM14.2857 10.4762H15.0476V9.71429H14.2857V10.4762ZM12.7619 10.4762H13.5238V9.71429H12.7619V10.4762ZM13.9048 20L8.57143 18.5143V19.619H4V11.2381H10.0571L14.781 12.9905C15.2 13.1429 15.5397 13.4095 15.8 13.7905C16.0603 14.1714 16.1905 14.5905 16.1905 15.0476H17.7143C18.3492 15.0476 18.8889 15.2571 19.3333 15.6762C19.7778 16.0952 20 16.6476 20 17.3333V18.0952L13.9048 20ZM5.52381 18.0952H7.04762V12.7619H5.52381V18.0952ZM13.8667 18.4L18.4 16.9905C18.3619 16.8508 18.2762 16.746 18.1429 16.6762C18.0095 16.6063 17.8667 16.5714 17.7143 16.5714H14.0571C13.6635 16.5714 13.3079 16.546 12.9905 16.4952C12.673 16.4444 12.3302 16.3556 11.9619 16.2286L10.6476 15.7714L11.0857 14.3238L12.6095 14.819C12.8381 14.8952 13.1048 14.9524 13.4095 14.9905C13.7143 15.0286 14.1333 15.0476 14.6667 15.0476C14.6667 14.9079 14.6254 14.7746 14.5429 14.6476C14.4603 14.5206 14.3619 14.4381 14.2476 14.4L9.79048 12.7619H8.57143V16.9524L13.8667 18.4Z"
        fill="currentColor"
      />
    </svg>
  );
};
