import { Identifier } from './global';
import { Loan } from './loans';
import { Servicer } from './servicers';

export enum DisbursementCategoryType {
  MORTGAGE_INSURANCE = 'MORTGAGE_INSURANCE',
  TAXES = 'TAXES',
}

export type DisbursementCreation = {
  escrowAnalysisId: LoanEscrowAnalysis['id'];
  monthIndex: number;
  category: DisbursementCategoryType;
  amount: number;
};

export type Disbursement = DisbursementCreation & {
  id: Identifier;
};

export type LoanEscrowAnalysisCreation = {
  loanId: Loan['id'];
  servicerId: Servicer['id'];
  cushionMonths: number;
  analysisDurationMonths: number;
  analysisStartDate: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type LoanEscrowAnalysis = LoanEscrowAnalysisCreation & {
  id: Identifier;
};

export type AggregatedLoanEscrowAnalysis = LoanEscrowAnalysis & {
  disbursements: Disbursement[];
};
