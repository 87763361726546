import React from 'react';

import { ExtraDataEnum } from '../../loans/types';

type ChildrenProps = {
  extraDataToDisplay?: ExtraDataEnum;
  setExtraDataToDisplay: React.Dispatch<React.SetStateAction<ExtraDataEnum | undefined>>;
};

type Props = {
  children: (props: ChildrenProps) => React.ReactNode;
};

export const MapPage = (props: Props) => {
  const [extraDataToDisplay, setExtraDataToDisplay] = React.useState<ExtraDataEnum | undefined>(
    undefined
  );

  return (
    <React.Fragment>{props.children({ extraDataToDisplay, setExtraDataToDisplay })}</React.Fragment>
  );
};
