import { Loan } from '@plus-platform/shared';
import React from 'react';

import { BodyTextExternalLink } from '../../../components/BodyTextLink';
import { Card } from '../../../components/Card';
import { Chain, ChainLink, ChainLinkSubtext, ChainLinkTitle } from '../../../components/Chain';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Key, Pair, Value } from '../../../components/KeyValue';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import * as Styles from './LoanDetailCustodial.styles';

type LoanDetailCustodialProps = {
  loan: Loan;
};

const LoanDetailCustodial = React.forwardRef<HTMLDivElement, LoanDetailCustodialProps>(
  ({ loan }, ref) => {
    return (
      <Card ref={ref} $hasPadding data-testid="LoanDetail_Custodial" id="custodial">
        <Heading $size="medium">Custodial</Heading>
        <Styles.CardWrapper>
          <Card $variant="light" $hasPadding>
            <Heading $size="small">Current custodian</Heading>
            <WidgetSectionWrapper>
              <WidgetSection>
                <Pair $size="large" $align="right">
                  <Key>Name</Key>
                  <Value>First National</Value>
                </Pair>
                <Pair $size="large" $align="right" $isMultiLine>
                  <Key>Address</Key>
                  <Value>3001 Summer Street Dubuque IA 52000</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Custodian file no.</Key>
                  <Value>12345/GG</Value>
                </Pair>
                <Pair $size="large" $align="right" $isMultiLine>
                  <Key>Active</Key>
                  <Value>1 June 2023 – present</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>BAILEE letter</Key>
                  <Value>
                    In place{' '}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      Document
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <Divider $orientation="vertical" />

              <WidgetSection>
                <Heading $size="small">Exception Report</Heading>
                <Pair $size="large" $align="right">
                  <Key>Status</Key>
                  <Value>Good</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Document</Key>
                  <Value>
                    {/* Temporary - this should be a link to the doc */}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      30 June 2023
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <Divider $orientation="vertical" />

              <WidgetSection>
                <Heading $size="small">Inventory Report</Heading>
                <Pair $size="large" $align="right">
                  <Key>Status</Key>
                  <Value>Good</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Document</Key>
                  <Value>
                    {/* Temporary - this should be a link to the doc */}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      30 June 2023
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <WidgetSection $isLarge>
                <Card $variant="light">
                  <Styles.ChainSectionWrapper>
                    <Styles.EndorsementHeadingWrapper>
                      <Heading as="h3" $size="medium">
                        Endorsement chain
                      </Heading>
                      <Divider $orientation="vertical" $flexItem />
                      <Heading as="h3" $size="medium">
                        <Styles.StatusKey>Status</Styles.StatusKey>{' '}
                        <Styles.StatusVal>Unbroken,</Styles.StatusVal>{' '}
                        <Styles.ErrorText>Incomplete</Styles.ErrorText>
                      </Heading>
                    </Styles.EndorsementHeadingWrapper>
                    <Chain variant="custodial">
                      <ChainLink>
                        <ChainLinkTitle>Buck</ChainLinkTitle>
                        <ChainLinkSubtext>Original note</ChainLinkSubtext>
                      </ChainLink>
                      <ChainLink>
                        <ChainLinkTitle>Eric</ChainLinkTitle>
                        <ChainLinkSubtext>Note 2/14/2015</ChainLinkSubtext>
                      </ChainLink>
                      <ChainLink hasError>
                        <ChainLinkTitle>Stu</ChainLinkTitle>
                        <ChainLinkSubtext>2/14/2015</ChainLinkSubtext>
                      </ChainLink>
                      <ChainLink hasError>
                        <ChainLinkTitle>Britton</ChainLinkTitle>
                        <ChainLinkSubtext>2/14/2015</ChainLinkSubtext>
                      </ChainLink>
                    </Chain>
                  </Styles.ChainSectionWrapper>
                  <Styles.ChainSectionWrapper>
                    <Styles.EndorsementHeadingWrapper>
                      <Heading as="h3" $size="medium">
                        Assignment chain
                      </Heading>
                      <Divider $orientation="vertical" $flexItem />
                      <Heading as="h3" $size="medium">
                        <Styles.StatusKey>Status</Styles.StatusKey>{' '}
                        <Styles.StatusVal>Unbroken,</Styles.StatusVal>{' '}
                        <Styles.ErrorText>Incomplete</Styles.ErrorText>
                      </Heading>
                    </Styles.EndorsementHeadingWrapper>
                    <Chain variant="custodial">
                      <ChainLink>
                        <ChainLinkTitle>MERS</ChainLinkTitle>
                        <ChainLinkSubtext>5/29/1995</ChainLinkSubtext>
                      </ChainLink>
                      <ChainLink>
                        <ChainLinkTitle>MERS</ChainLinkTitle>
                        <ChainLinkSubtext>2/14/2015</ChainLinkSubtext>
                      </ChainLink>
                      <ChainLink hasError>
                        <ChainLinkTitle>MERS</ChainLinkTitle>
                        <ChainLinkSubtext>2/14/2018</ChainLinkSubtext>
                      </ChainLink>
                      <ChainLink hasError>
                        <ChainLinkTitle>Britton</ChainLinkTitle>
                        <ChainLinkSubtext>2/14/2023</ChainLinkSubtext>
                      </ChainLink>
                    </Chain>
                  </Styles.ChainSectionWrapper>
                </Card>
              </WidgetSection>
            </WidgetSectionWrapper>
          </Card>
          <Card $variant="light" $hasPadding>
            <Heading $size="small">Previous custodian</Heading>
            <WidgetSectionWrapper>
              <WidgetSection>
                <Pair $size="large" $align="right">
                  <Key>Name</Key>
                  <Value>Federal Audit</Value>
                </Pair>
                <Pair $size="large" $align="right" $isMultiLine>
                  <Key>Address</Key>
                  <Value>9876 Winter Boulevard Sante Fe CA 52000</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Custodian file no.</Key>
                  <Value>12345/GG</Value>
                </Pair>
                <Pair $size="large" $align="right" $isMultiLine>
                  <Key>Active</Key>
                  <Value>1 Nov 2018 - 31 May 2023</Value>
                </Pair>
              </WidgetSection>

              <Divider $orientation="vertical" />

              <WidgetSection>
                <Heading $size="small">Exception Report</Heading>
                <Pair $size="large" $align="right">
                  <Key>Status</Key>
                  <Value $status="statusRed">Exceptions</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Document</Key>
                  <Value>
                    {/* Temporary - this should be a link to the doc */}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      29 Mar 2023
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <Divider $orientation="vertical" />

              <WidgetSection>
                <Heading $size="small">Inventory Report</Heading>
                <Pair $size="large" $align="right">
                  <Key>Status</Key>
                  <Value $status="statusRed">Deficit</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Document</Key>
                  <Value>
                    {/* Temporary - this should be a link to the doc */}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      29 Mar 2023
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <WidgetSection $isLarge>
                <Styles.FullHeightCard $variant="light">
                  <Styles.ChainSectionWrapper>
                    <Styles.EndorsementHeadingWrapper>
                      <Heading as="h3" $size="medium">
                        Endorsement chain
                      </Heading>
                      <Divider $orientation="vertical" $flexItem />
                      <Heading as="h3" $size="medium">
                        <Styles.StatusKey>Status</Styles.StatusKey>{' '}
                        <Styles.StatusVal>Unbroken, Complete</Styles.StatusVal>
                      </Heading>
                    </Styles.EndorsementHeadingWrapper>
                    <Styles.ChainWrapper>
                      <Chain variant="custodial">
                        <ChainLink>
                          <ChainLinkTitle>Buck</ChainLinkTitle>
                          <ChainLinkSubtext>Original note</ChainLinkSubtext>
                        </ChainLink>
                        <ChainLink>
                          <ChainLinkTitle>Eric</ChainLinkTitle>
                          <ChainLinkSubtext>Note 2/14/2015</ChainLinkSubtext>
                        </ChainLink>
                        <ChainLink hasError>
                          <ChainLinkTitle>Stu</ChainLinkTitle>
                          <ChainLinkSubtext>2/14/2015</ChainLinkSubtext>
                        </ChainLink>
                        <ChainLink hasError>
                          <ChainLinkTitle>Britton</ChainLinkTitle>
                          <ChainLinkSubtext>2/14/2015</ChainLinkSubtext>
                        </ChainLink>
                      </Chain>
                    </Styles.ChainWrapper>
                  </Styles.ChainSectionWrapper>
                </Styles.FullHeightCard>
              </WidgetSection>
            </WidgetSectionWrapper>
          </Card>
          <Card $variant="light" $hasPadding>
            <Heading $size="small">Previous custodian</Heading>
            <WidgetSectionWrapper>
              <WidgetSection>
                <Pair $size="large" $align="right">
                  <Key>Name</Key>
                  <Value>FNRA Plc</Value>
                </Pair>
                <Pair $size="large" $align="right" $isMultiLine>
                  <Key>Address</Key>
                  <Value>1003 Autumn Rise Dubuque IA 52000</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Custodian file no.</Key>
                  <Value>12345/GG</Value>
                </Pair>
                <Pair $size="large" $align="right" $isMultiLine>
                  <Key>Active</Key>
                  <Value>1 June 2021 - 31 Oct 2018</Value>
                </Pair>
              </WidgetSection>

              <Divider $orientation="vertical" />

              <WidgetSection>
                <Heading $size="small">Exception Report</Heading>
                <Pair $size="large" $align="right">
                  <Key>Status</Key>
                  <Value>Clear</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Document</Key>
                  <Value>
                    {/* Temporary - this should be a link to the doc */}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      27 Feb 2023
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <Divider $orientation="vertical" />

              <WidgetSection>
                <Heading $size="small">Inventory Report</Heading>
                <Pair $size="large" $align="right">
                  <Key>Status</Key>
                  <Value>Clear</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Document</Key>
                  <Value>
                    {/* Temporary - this should be a link to the doc */}
                    <BodyTextExternalLink href="/blank.pdf" download>
                      27 Feb 2023
                    </BodyTextExternalLink>
                  </Value>
                </Pair>
              </WidgetSection>

              <WidgetSection $isLarge>
                <Styles.FullHeightCard $variant="light">
                  <Styles.ChainSectionWrapper>
                    <Styles.EndorsementHeadingWrapper>
                      <Heading as="h3" $size="medium">
                        Endorsement chain
                      </Heading>
                      <Divider $orientation="vertical" $flexItem />
                      <Heading as="h3" $size="medium">
                        <Styles.StatusKey>Status</Styles.StatusKey>{' '}
                        <Styles.StatusVal>Unbroken, Complete</Styles.StatusVal>
                      </Heading>
                    </Styles.EndorsementHeadingWrapper>
                    <Styles.ChainWrapper style={{ width: '50%' }}>
                      <Chain variant="custodial">
                        <ChainLink>
                          <ChainLinkTitle>Buck</ChainLinkTitle>
                          <ChainLinkSubtext>Original note</ChainLinkSubtext>
                        </ChainLink>
                        <ChainLink>
                          <ChainLinkTitle>Eric</ChainLinkTitle>
                          <ChainLinkSubtext>Note 2/14/2015</ChainLinkSubtext>
                        </ChainLink>
                      </Chain>
                    </Styles.ChainWrapper>
                  </Styles.ChainSectionWrapper>
                </Styles.FullHeightCard>
              </WidgetSection>
            </WidgetSectionWrapper>
          </Card>
        </Styles.CardWrapper>
      </Card>
    );
  }
);

export default LoanDetailCustodial;
