const DEFAULT_DIGITS = 2;
const DEFAULT_LOCALE = 'en-US';
const DEFAULT_CURRENCY = 'USD';

const DEFAULT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const DATE_WITH_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};

const DEFAULT_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};

export const EMPTY_VALUE = '—';
export const EMPTY_RATE_VALUE = 'Invalid Rate';

export const formatDefaultValue = (
  value?: string | number | null,
  defaultValue: string | number = EMPTY_VALUE
) => {
  if (value === undefined || value === null || value === '') {
    return defaultValue;
  }
  return value;
};

export const formatNumber = (value?: number | null) => {
  if (value === undefined || value === null || isNaN(value)) {
    return 0;
  }
  return value.toLocaleString(DEFAULT_LOCALE);
};

export const formatDecimal = (value?: number | null, decimals = 3) => {
  if (value === undefined || value === null || isNaN(value)) {
    return 0;
  }
  return Number(value).toFixed(decimals);
};

export const formatMonetary = (amountInCents?: number | null, digits: number = DEFAULT_DIGITS) => {
  if (amountInCents === undefined || amountInCents === null) {
    return EMPTY_VALUE;
  }

  /* eslint-disable-next-line no-compare-neg-zero */
  const amount = amountInCents === -0 ? 0 : amountInCents;

  return (amount / 100).toLocaleString(DEFAULT_LOCALE, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    style: 'currency',
    currency: DEFAULT_CURRENCY,
  });
};

export const formatSurface = (
  surface?: number | null,
  surfaceUnit = 'sqft',
  digits = DEFAULT_DIGITS
) => {
  if (surface === undefined || surface === null) {
    return EMPTY_VALUE;
  }

  /* eslint-disable-next-line no-compare-neg-zero */
  const amount = surface === -0 ? 0 : surface;

  const formattedDecimal = (amount / 100).toLocaleString(DEFAULT_LOCALE, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    style: 'decimal',
  });
  return `${formattedDecimal} ${surfaceUnit}`;
};

export const formatPercentage = (
  rate?: number | string | null,
  digits: number = DEFAULT_DIGITS
) => {
  if (rate === undefined || rate === null || rate === '' || isNaN(Number(rate))) {
    return EMPTY_RATE_VALUE;
  }

  return Number(rate).toLocaleString(DEFAULT_LOCALE, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    style: 'percent',
  });
};

export const formatBoolean = (
  value?: boolean | null,
  defaultValue: string | number = EMPTY_VALUE
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value ? 'Yes' : 'No';
};

export const formatDelinquency = (
  value?: boolean | null,
  defaultValue: string | number = EMPTY_VALUE
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value ? 'Delinquent' : 'Current';
};

export const formatDate = (
  value?: Date | null,
  options: Intl.DateTimeFormatOptions = DEFAULT_DATE_OPTIONS,
  defaultValue: string = EMPTY_VALUE
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value.toLocaleDateString(DEFAULT_LOCALE, options);
};

export const formatTime = (
  value?: Date | null,
  options: Intl.DateTimeFormatOptions = DEFAULT_TIME_OPTIONS,
  defaultValue: string = EMPTY_VALUE
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value.toLocaleTimeString(DEFAULT_LOCALE, options);
};

export const formatTimestamp = (timestamp?: number | null, defaultValue: string = EMPTY_VALUE) => {
  if (timestamp === undefined || timestamp === null) {
    return defaultValue;
  }
  return new Date(timestamp).toISOString();
};

export const formatUTCDate = (date: Date) => {
  const UTCDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  return new Date(UTCDate);
};

export const formatCompactMonetary = (amountInCents?: number | null) => {
  if (amountInCents === undefined || amountInCents === null) {
    return EMPTY_VALUE;
  }

  /* eslint-disable-next-line no-compare-neg-zero */
  const amount = amountInCents === -0 ? 0 : amountInCents / 100;

  return Intl.NumberFormat('en', { notation: 'compact', compactDisplay: 'short' }).format(amount);
};

export const formatNameToInitials = (lastName: string, firstName?: string) => {
  return `${firstName?.charAt(0) ?? ''}${lastName.charAt(0)}`.toUpperCase();
};
