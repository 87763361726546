import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const Logo = styled.img`
  margin: 50px auto;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    overflow-y: scroll;
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-width: 700px;
    width: 100%;
    max-height: 536px;
    background: ${theme.colors.white80};
    box-shadow: 0 4px 6px ${transparentize(0.3, theme.colors.background01)};
    border-radius: 6px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    background: ${theme.colors.background01};
    padding: 12px 20px;
    gap: 16px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.display};
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: ${theme.colors.white};
  `}
`;

export const SubmitButton = styled.button<{
  $isDisabled?: boolean;
  $slim?: boolean;
}>`
  ${({ $isDisabled, $slim, theme }) => css`
    height: 50px;
    background: ${theme.colors.accent01};
    border: 0.5px solid ${theme.colors.accent01};
    font-family: ${theme.typography.fontFamily.display};
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    width: 100%;

    ${$isDisabled &&
    css`
      background: ${transparentize(0.75, theme.colors.accent01)};
    `}

    ${$slim &&
    css`
      height: 32px;
    `}
  `}
`;
