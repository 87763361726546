import React from 'react';

import { ButtonProps } from './Button';
import * as Styles from './ButtonLoader.styles';
import { Loader } from './Loader';

type ButtonLoaderProps = {
  isLoading?: boolean;
} & ButtonProps;

export const ButtonLoader = ({
  $color = 'primary',
  $isFullWidth = false,
  $size = 'medium',
  $variant = 'contained',
  children,
  disabled,
  endIcon,
  isLoading,
  startIcon,
  ...rest
}: ButtonLoaderProps) => {
  const loaderPosition = startIcon ? 'start' : endIcon ? 'end' : 'center';

  return (
    <Styles.Button
      {...rest}
      $color={$color}
      $variant={$variant}
      $isFullWidth={$isFullWidth}
      $size={$size}
      $isLoading={isLoading}
      $hasStartIcon={Boolean(startIcon)}
      $hasEndIcon={Boolean(endIcon)}
      disabled={disabled}
    >
      {isLoading ? (
        <React.Fragment>
          <Styles.Loader $position={loaderPosition} $size={$size}>
            <Loader size={14} />
          </Styles.Loader>
          {startIcon && <Styles.Wrapper $isHidden>{startIcon}</Styles.Wrapper>}
          <Styles.Wrapper $isHidden={!startIcon && !endIcon}>{children}</Styles.Wrapper>
          {endIcon && <Styles.Wrapper $isHidden>{endIcon}</Styles.Wrapper>}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {startIcon}
          {children}
          {endIcon}
        </React.Fragment>
      )}
    </Styles.Button>
  );
};
