import { transparentize } from 'polished';
import { useTheme } from 'styled-components';

import { LoanMap } from '../../components/LoanMap';
import { AnalyticsChartProps, convertValuesToHashmap } from './utils';

type AnalyticsMapChartProps = Omit<AnalyticsChartProps, 'metric'> & {
  id: string;
};

const COLOR_MAP_COEFFICIENT = 1.1;

export const AnalyticsMapChart = ({ data, id }: AnalyticsMapChartProps) => {
  const theme = useTheme();

  const loansCountByState = convertValuesToHashmap(data);
  const loansValues = data.map((d) => d.value);

  return (
    <LoanMap
      id={id}
      loans={loansCountByState}
      hasLegend={false}
      getMapDefaultColor={(value) => {
        const normalizedValue = value / Math.max(...loansValues);
        return normalizedValue > 0
          ? transparentize(
              1 - normalizedValue * COLOR_MAP_COEFFICIENT,
              theme.colors.statusTurquoise
            )
          : 'rgba(19, 69, 89, 1)';
      }}
      style={{
        strokeWidth: 2,
      }}
    />
  );
};
