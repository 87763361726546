import { QUOTE_COUNTER_REASON_MAX_LENGTH } from '@plus-platform/shared';
import { transparentize } from 'polished';
import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import { Button, NudeButton } from '../../../components/Button';
import { ButtonLoader } from '../../../components/ButtonLoader';
import { Divider } from '../../../components/Divider';
import { AddIcon, PDFIcon } from '../../../components/icons';
import { Tag, TagHelperText, TagLabel } from '../../../components/Tag';
import { UploadOverlay } from '../../../components/UploadOverlay';
import { useDropzoneContext } from '../../../contexts/DropzoneContext';

const Form = styled.form`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: end;
`;

const FormControls = styled.div`
  display: flex;
  column-gap: 16px;
`;

const FormControl = styled.div`
  position: relative;
`;

const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 40px;
  column-gap: 10px;
`;

const Label = styled.label(
  ({ theme }) => css`
    display: block;
    color: ${transparentize(0.3, theme.colors.white)};
    font-family: ${theme.typography.fontFamily.display};
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 8px;
  `
);

// @TODO: Update to use standard Input component
const Input = styled.input`
  ${({ theme }) => css`
    width: 170px;
    height: 40px;
    appearance: none;
    border: none;
    padding: 0 12px;
    font-weight: bold;

    &::placeholder {
      font-family: ${theme.typography.fontFamily.text};
      font-size: 15px;
      font-weight: 500;
    }
  `}
`;

// @TODO: Update to use standard Input component
const ReasonInput = styled(Input).attrs({ as: 'textarea' })`
  ${({ theme }) => css`
    display: flex;
    padding: 11px 10px;
    width: 250px;
    resize: none;

    &::placeholder {
      line-height: 18px;
      font-family: ${theme.typography.fontFamily.text};
      font-size: 15px;
      font-weight: 500;
    }
  `}
`;

const InputHelperText = styled.span`
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 10px;
  font-size: 11px;
  color: ${({ theme }) => transparentize(0.35, theme.colors.white)};
`;

const AddStipulationsButton = styled(NudeButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-top: 12px;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.greenLight};
  border-radius: 4px;
`;

const FileTag = styled(Tag).attrs({ forwardedAs: 'div' })`
  width: 200px;
  gap: 6px;
  margin-bottom: 0;
  padding: 4px 6px 4px 4px;
  font-size: 11px;
  border: 0;
`;

const Icon = styled.span`
  color: ${({ theme }) => theme.colors.tradePDFPurple};

  > svg {
    display: block;
  }
`;

const isValidPercentageInputValue = (value: string) => {
  return /^((\d{1,4}(\.\d*)?))?$/.test(value);
};

type FormValues = {
  pricePercentage: number;
  stipulation: File | undefined;
};

type QuoteFormProps = {
  isSubmitting: boolean;
  onCancel: () => void;
} & (
  | {
      isCounter: true;
      onSubmit: (values: FormValues & { reason: string | undefined }) => void;
    }
  | {
      isCounter?: false;
      onSubmit: (values: FormValues) => void;
    }
);

export const QuoteForm = (props: QuoteFormProps) => {
  const { isCounter, isSubmitting, onCancel } = props;
  const [pricePercentage, setPricePercentage] = React.useState('');
  const [reason, setReason] = React.useState('');

  const { acceptedFiles, clearAcceptedFiles, getInputProps, isDragActive, open } =
    useDropzoneContext();

  const theme = useTheme();

  const stipulation = acceptedFiles[0];
  const isValid = Number(pricePercentage) > 0;

  const updatePricePercentage = (value: string) => {
    if (isValidPercentageInputValue(value)) {
      setPricePercentage(value);
    }
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(event) => {
          event.preventDefault();

          if (!isValid) {
            return;
          }

          const values = {
            pricePercentage: Number(pricePercentage),
            stipulation: stipulation ?? undefined,
          };

          // TODO: This can be replaced with a destructured value as a discriminant property
          // when the project is updated to TypeScript 4.6 or above
          if (!props.isCounter) {
            props.onSubmit(values);
          } else {
            props.onSubmit({ ...values, reason: reason.trim() || undefined });
          }
        }}
      >
        <FormControls>
          <FormControl>
            <Label htmlFor="pricePercentage">Bid Price</Label>
            <Input
              disabled={isSubmitting}
              id="pricePercentage"
              min={0}
              onChange={(event) => updatePricePercentage(event.target.value)}
              placeholder="Indicative percentage"
              required
              value={pricePercentage}
            />
          </FormControl>

          {isCounter && (
            <FormControl>
              <Label htmlFor="reason">Reason or comment</Label>
              <ReasonInput
                disabled={isSubmitting}
                id="reason"
                maxLength={QUOTE_COUNTER_REASON_MAX_LENGTH}
                onChange={(event) => setReason(event.target.value)}
                placeholder="Type your reason or comment here"
                value={reason}
              />
              <InputHelperText>Why are you countering?</InputHelperText>
            </FormControl>
          )}

          <FormControl>
            <Label>Stipulation</Label>

            {stipulation && (
              <FileTag
                icon={
                  <Icon>
                    <PDFIcon height={32} width={32} />
                  </Icon>
                }
                onRemove={() => {
                  if (!isSubmitting) {
                    clearAcceptedFiles();
                  }
                }}
                variant="light"
              >
                <TagLabel>{stipulation.name}</TagLabel>
                <TagHelperText>PDF</TagHelperText>
              </FileTag>
            )}

            {!stipulation && (
              <AddStipulationsButton onClick={open} type="button">
                <AddIcon color={theme.colors.greenLight} height={14} width={14} />
              </AddStipulationsButton>
            )}
          </FormControl>
        </FormControls>
        <FormButtons>
          <Divider $flexItem $orientation="vertical" />
          <Button
            $color="tertiary"
            $size="large"
            $variant="outlined"
            disabled={isSubmitting}
            onClick={() => {
              clearAcceptedFiles();
              onCancel();
            }}
            type="button"
          >
            Cancel
          </Button>
          <ButtonLoader $size="large" disabled={!isValid} isLoading={isSubmitting} type="submit">
            {isCounter ? 'Send counter' : 'Send'}
          </ButtonLoader>
        </FormButtons>
      </Form>

      <input {...getInputProps()} />
      <UploadOverlay isVisible={isDragActive} />
    </React.Fragment>
  );
};
