import { TradeRequestWithSummary } from '@plus-platform/shared';
import { useParams } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { useUserContext } from '../contexts/UserContext';
import { TradeRequestTransactionLogTable } from './TradeRequestTransactionLogTable';
import { TradeRequestCategory } from './types';
import { useTradeRequestTransactionLogs } from './useTradeRequestTransactionLogs';

type TradeRequestTransactionLogPageParams = {
  category: string;
  tradeId: string;
};

type TradeRequestTransactionLogPageProps = {
  isLoading: boolean;
  tradeRequest?: TradeRequestWithSummary;
};

export const TradeRequestTransactionLogPage = ({
  isLoading: isTradeRequestLoading,
  tradeRequest,
}: TradeRequestTransactionLogPageProps) => {
  const { category, tradeId } = useParams<TradeRequestTransactionLogPageParams>();
  const { userProfile } = useUserContext();

  const { allLogIds, fetchNextPage, isFetchingNextPage, isLoading, logs, totalCount } =
    useTradeRequestTransactionLogs(tradeRequest, userProfile);

  return (
    <Page>
      <PageHeader>
        <Heading>Transaction log</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/trade-requests" title="Trade activity" />
          <BreadcrumbItem
            linkTo={`/trade-requests/${category}`}
            title={category === TradeRequestCategory.OUTBOUND ? 'Outbound' : 'Inbound'}
          />
          {category === TradeRequestCategory.OUTBOUND ? (
            <BreadcrumbItem
              linkTo={`/trade-requests/outbound/${tradeId}`}
              title="Trade Dashboard"
            />
          ) : (
            <BreadcrumbItem
              linkTo={`/trade-requests/inbound/${tradeId}`}
              title={`Trade ${tradeId}`}
            />
          )}
          <BreadcrumbItem title="Transaction log" />
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        <ActivityIndicator isActive={isLoading || isTradeRequestLoading}>
          {tradeRequest && (
            <TradeRequestTransactionLogTable
              data={logs}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              total={totalCount}
              tradeRequest={tradeRequest}
              allLogIds={allLogIds}
            />
          )}
        </ActivityIndicator>
      </PageContent>
    </Page>
  );
};
