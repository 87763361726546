import { TradeRequest, TradeRequestQuote } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type AcceptTradeRequestQuoteCounterMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeQuoteCounterId: TradeRequestQuote['id'];
};

const acceptTradeRequestQuoteCounter = (data: AcceptTradeRequestQuoteCounterMutation) => {
  const { tradeQuoteCounterId, tradeRequestId } = data;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/counters/${tradeQuoteCounterId}/accept`);

  return postRequestWithAuth(url);
};

export const useAcceptTradeRequestQuoteCounterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(acceptTradeRequestQuoteCounter, {
    onSuccess: (_, { tradeRequestId }) => {
      queryClient.invalidateQueries([QueryKeys.TRADE_REQUEST_INBOUND_QUOTES, tradeRequestId]);
    },
  });
};
