export function random(lower = 0, upper: number | boolean = 1, floating = false): number {
  let isFloating = floating;

  if (typeof upper === 'boolean') {
    isFloating = upper;
    upper = lower < 0 ? 0 : 1;
  } else if (isNaN(upper)) {
    upper = lower < 0 ? 0 : 1;
  }

  if (typeof isFloating === 'undefined') {
    isFloating = !Number.isInteger(lower) || !Number.isInteger(upper as number);
  }

  const randomNumber = Math.random() * ((upper as number) - lower) + lower;
  return isFloating ? randomNumber : Math.round(randomNumber);
}
