import { useMutation } from 'react-query';

import { makeApiUrl, putRequestWithAuth } from '../../utils/apiUtils';
import { ChangePasswordMutation } from './types';

const changePassword = (data: ChangePasswordMutation) => {
  const url = makeApiUrl('auth/password');

  return putRequestWithAuth(url, {
    body: JSON.stringify(data),
  });
};

export const useChangePasswordMutation = () => useMutation(changePassword);
