import React from 'react';

export const DashboardSquareFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 4H11.5V11.5H4V4Z" fill="currentColor" />
      <path d="M12.5 4H20V11.5H12.5V4Z" fill="currentColor" />
      <path d="M4 12.5H11.5V20H4V12.5Z" fill="currentColor" />
      <path d="M12.5 12.5H20V20H12.5V12.5Z" fill="currentColor" />
    </svg>
  );
};
