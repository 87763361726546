/**
 *
 * Please note - the colors in the file are deprecated
 *
 */

/**
 * Dividers & Strokes
 */

export const strokeBlue01 = '#292F51';

/**
 * Legacy Colors
 */

export const blue03 = '#292F51';

export const greyExtraLight = '#CFCFCF';
export const greyLight = '#dedede';
export const greyDark = '#2A2823';
export const greyMain = '#6C6E7A';
export const greenMain = '#009669';
export const greenMedium = '#10826F';
export const greenLight = '#00E09D';
export const greenTree = '#0A3740';
export const greenTreeLight = '#0C424C';
export const greenDark = '#07282E';
export const redMain = '#FF4141';
export const redSecondary = '#EF5350';
export const darkBlue = '#202541';
export const greyBlue = '#292F51';
export const greyBlueMedium = '#363B54';
export const purple = '#665CFF';
export const purpleSecondary = '#746BFF';

export const ficoGreen = '#86DAA6';
export const experianPurple = '#6867D5';
export const equifaxBrown = '#6D6524';
export const toggleIconGrey = '#C0C0C0';

// New colours from Emily's designs - semantics TBC
export const menuGrey = '#D1D4E6';

export const selectOptions = '#384173';

export const poolSummaryBarGreen = '#105966';

export const purpleSection = '#171A2E';

export const purpleDark = '#1F2440';
export const purpleMedium = '#2B3259';
export const mapOverlayPurple = '#3E4880';
export const purpleLight = '#6F81E5';
export const mauve = '#404780';
export const purpleViolet = '#384073';
export const modalPurple = '#272e51';

export const tradeTagGreen = '#556A6F';
export const tradeMessengerPurple = '#252B4D';
export const tradeDividerPurple = '#323966';
export const tradePDFPurple = '#4B5599';
export const tradeActivityYellow = '#FBFF2A';
export const tradeInactiveActivityPurple = '#1C213B';
export const tradeChatButtonPurple = '#313966';

export const tradeQuoteViolet = '#4D5799';
export const tradeQuoteCardDark = '#333A66';
export const tradeQuoteCardBarViolet = '#8B84FF';
export const tradeQuoteCardBarRed = '#FF5955';

export const buttonDisabledTextGrey = '#313966';

// Temporary - until new designs are implemented
export const adobeAcrobatRed = '#c80a0a';

/**
 * Sorted colors - Labels are the color names in Figma file
 */

export const lightGreen = '#0E4E5A';
export const lightGreen01 = '#0C424D';
export const lightGreen03 = '#126373';
