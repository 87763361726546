import styled, { css, keyframes } from 'styled-components/macro';

const scaleIn = keyframes`
	from {
		transform: scale(0.8) rotate(-120deg);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

	to {
		transform: rotate(360deg);
	}
`;

export const InlineLoaderWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: -3px 8px 0 0;
`;

export const FullWidthLoaderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Wrapper = styled.div<{ size: number; color?: string }>`
  ${({ color, size }) => css`
    position: relative;
    width: ${size}px;
    height: ${size}px;
    opacity: 0.8;

    &::after {
      position: absolute;
      inset: 0;
      content: '';
      width: ${size}px;
      height: ${size}px;
      border-color: ${color ?? 'currentColor'} ${color ?? 'currentColor'} ${color ?? 'currentColor'}
        transparent;
      border-radius: 50%;
      border-style: solid;
      border-width: ${size / 8}px;
      animation: ${scaleIn} 200ms linear 1 forwards, ${spinner} 999ms 200ms linear infinite;
    }

    &::before {
      position: absolute;
      inset: 0;
      content: '';
      width: ${size}px;
      height: ${size}px;
      border-color: ${color ?? 'currentColor'};
      border-radius: 50%;
      border-style: solid;
      border-width: ${size / 8}px;
      opacity: 0.2;
    }
  `}
`;

type LoaderPropTypes = {
  size?: number;
  color?: string;
};

export const Loader = ({ color, size = 24 }: LoaderPropTypes) => (
  <Wrapper color={color} size={size} />
);
