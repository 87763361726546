import React from 'react';
import styled, { css } from 'styled-components/macro';

export const Label = styled.label`
  display: flex;
`;

const smallStyles = css`
  width: 28px;
  height: 16px;
  border-radius: 11px;

  &:before {
    width: 12px;
    height: 12px;
    margin: 2px;
  }
`;

type InputProps = {
  $size?: 'small' | 'large';
  $toggledColor?: React.CSSProperties['backgroundColor'];
};

export const Input = styled.input.attrs<InputProps>((props) => ({
  ...props,
}))<InputProps>`
  ${({ $size, $toggledColor, theme }) => css`
    appearance: none;
    display: inline-flex;
    align-items: center;
    width: 44px;
    height: 24px;
    border-radius: 15px;
    flex-shrink: 0;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-out;

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin: 2px;
      border-radius: 50%;
      transition: transform 0.3s;
    }

    ${$size === 'small' && smallStyles}

    background-color: ${theme.colors.switchOff};

    &::before {
      background-color: ${theme.colors.statusWhite};
    }

    &:checked {
      background-color: ${$toggledColor ?? theme.colors.switchOn};
    }

    &:checked {
      &::before {
        transform: translateX(100%);
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  `}
`;
