import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum NeighborhoodBuiltUpRangeType {
  Over75Percent = 'Over75Percent',
  TwentyFiveToSeventyFivePercent = 'TwentyFiveToSeventyFivePercent',
  Under25Percent = 'Under25Percent',
}

export enum NeighborhoodDemandSupplyType {
  InBalance = 'InBalance',
  OverSupply = 'OverSupply',
  Shortage = 'Shortage',
}

enum NeighborhoodHousingPredominantOccupancyType {
  Owner = 'Owner',
  Tenant = 'Tenant',
  Vacant = 'Vacant',
}

export enum NeighborhoodHousingType {
  Condominium = 'Condominium',
  Cooperative = 'Cooperative',
  ManufacturedHome = 'ManufacturedHome',
  Other = 'Other',
  SingleFamily = 'SingleFamily',
  TwoToFourFamily = 'TwoToFourFamily',
}

export enum NeighborhoodPropertyValueTrendType {
  Declining = 'Declining',
  Increasing = 'Increasing',
  Stable = 'Stable',
}

export enum NeighborhoodTypicalMarketingMonthsDurationType {
  OverSixMonths = 'OverSixMonths',
  ThreeToSixMonths = 'ThreeToSixMonths',
  UnderThreeMonths = 'UnderThreeMonths',
}

export type Housing = MISMOEntity &
  Partial<{
    neighborhoodBuiltUpRangeType: NeighborhoodBuiltUpRangeType;
    neighborhoodDemandSupplyType: NeighborhoodDemandSupplyType;
    neighborhoodHousingHighPriceAmount: MISMOAmount;
    neighborhoodHousingLowPriceAmount: MISMOAmount;
    neighborhoodHousingNewestYearsCount: MISMOCount;
    neighborhoodHousingOldestYearsCount: MISMOCount;
    neighborhoodHousingPredominantAgeYearsCount: MISMOCount;
    neighborhoodHousingPredominantOccupancyType: NeighborhoodHousingPredominantOccupancyType;
    neighborhoodHousingPredominantPriceAmount: MISMOAmount;
    neighborhoodHousingType: NeighborhoodHousingType;
    neighborhoodHousingTypeOtherDescription: MISMOString;
    neighborhoodOwnerOccupancyPercent: MISMOPercent;
    neighborhoodPropertyValueTrendType: NeighborhoodPropertyValueTrendType;
    neighborhoodTypicalMarketingDaysDurationCount: MISMOCount;
    neighborhoodTypicalMarketingMonthsDurationType: NeighborhoodTypicalMarketingMonthsDurationType;
    sequenceNumber: MISMOSequenceNumber;
  }>;
