import { transparentize } from 'polished';
import styled from 'styled-components/macro';

export const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 4px 0 4px;
  column-gap: 16px;
  flex-wrap: wrap;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 8px;
`;

export const Circle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 5px;
`;

export const LegendText = styled.span`
  ${({ theme }) => theme.typography.bodyText.bodyRegular03Tight}
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => transparentize(0.2, theme.colors.white70)};
`;

export const Wrapper = styled.div``;
