import { formatNumber } from '../../../utils/formatUtils';
import {
  SummaryAlertValue,
  SummaryFlagValue,
  SummaryItem,
  SummaryItems,
  SummaryKey,
  SummaryValue,
  SummaryWarningValue,
} from './Summary.styles';

type SummaryProps = {
  flagsCount: number;
  alertsCount: number;
  warningsCount: number;
  totalLoansCount: number;
};

export const Summary = (props: SummaryProps) => {
  const { alertsCount, flagsCount, totalLoansCount, warningsCount } = props;

  return (
    <SummaryItems>
      <SummaryItem>
        <SummaryKey>Flags</SummaryKey>
        <SummaryFlagValue>{formatNumber(flagsCount)}</SummaryFlagValue>
      </SummaryItem>
      <SummaryItem>
        <SummaryKey>Alerts</SummaryKey>
        <SummaryAlertValue>{formatNumber(alertsCount)}</SummaryAlertValue>
      </SummaryItem>
      <SummaryItem>
        <SummaryKey>Warnings</SummaryKey>
        <SummaryWarningValue>{formatNumber(warningsCount)}</SummaryWarningValue>
      </SummaryItem>
      <SummaryItem>
        <SummaryKey>Number of loans in portfolio</SummaryKey>
        <SummaryValue>{formatNumber(totalLoansCount)}</SummaryValue>
      </SummaryItem>
    </SummaryItems>
  );
};
