import React from 'react';
import { useTheme } from 'styled-components/macro';
import {
  VictoryAxis,
  VictoryAxisProps,
  VictoryChart,
  VictoryChartProps,
  VictoryScatter,
} from 'victory';

import { Heading } from '../../components/Heading';
import { BubbleChartFillIcon, LineGraphIcon } from '../../components/icons';
import { FullWidthLoaderWrapper, Loader } from '../../components/Loader';
import { ButtonToggle } from '../../components/Toggles/ButtonToggle';
import { LinkToggle } from '../../components/Toggles/LinkToggle';
import { ToggleGroup } from '../../components/Toggles/ToggleGroup';
import { plusChartTheme } from '../../styles/deprecatedChart';
import { fontFamily } from '../../styles/typography';
import { ChartHeader, Wrapper } from './LoanChart.styles';

const formatDateTick = (date: Date) => {
  const newDate = new Date(date);

  return newDate
    .toLocaleDateString('en-GB', {
      year: '2-digit',
      month: 'short',
    })
    .replace(/,/g, '-');
};

type LoanChartData = {
  x: Date;
  y: number;
};

type LoanChartProps = {
  data: LoanChartData[];
  title: string;
  xAxisProps?: VictoryAxisProps;
  yAxisProps?: VictoryAxisProps;
  chartProps?: VictoryChartProps;
  isLoading?: boolean;
  fontScale?: number;
};

export const LoanChart = ({
  chartProps,
  data,
  fontScale = 1,
  isLoading,
  title,
  xAxisProps,
  yAxisProps,
}: LoanChartProps) => {
  const theme = useTheme();

  const height = 150;
  const width = 300;

  const chartPadding = {
    top: 16,
    bottom: 11,
    left: 48,
    right: 16,
  };

  const xAxisStyles = {
    axis: { stroke: theme.colors.white05 },
    tickLabels: {
      fontFamily: fontFamily.text,
      fontSize: 8 * fontScale,
      fill: theme.colors.white70,
    },
    grid: {
      stroke: theme.colors.white05,
      strokeWidth: 0.5,
    },
  };

  const yAxisStyles = {
    tickLabels: {
      fontFamily: fontFamily.text,
      fontSize: 8 * fontScale,
      fill: theme.colors.white70,
    },
    grid: {
      stroke: theme.colors.white05,
    },
    axis: { stroke: theme.colors.white05 },
  };

  const scatterStyles = { data: { fill: theme.colors.greenLight } };

  return (
    <Wrapper>
      <ChartHeader>
        <Heading $size="medium">{title}</Heading>
        <ToggleGroup size="small">
          <LinkToggle to="#">
            <BubbleChartFillIcon />
          </LinkToggle>
          <ButtonToggle value="line">
            <LineGraphIcon />
          </ButtonToggle>
        </ToggleGroup>
      </ChartHeader>

      {isLoading && (
        <FullWidthLoaderWrapper>
          <Loader />
        </FullWidthLoaderWrapper>
      )}

      {!isLoading && (
        <VictoryChart
          padding={chartPadding}
          height={height}
          width={width}
          theme={plusChartTheme}
          {...chartProps}
        >
          <VictoryAxis
            crossAxis
            orientation="bottom"
            tickFormat={formatDateTick}
            style={{
              ...plusChartTheme,
              ...xAxisStyles,
            }}
            {...xAxisProps}
          />
          <VictoryAxis
            dependentAxis
            style={{
              ...plusChartTheme,
              ...yAxisStyles,
            }}
            {...yAxisProps}
          />

          <VictoryScatter style={scatterStyles} size={5} data={data} />
        </VictoryChart>
      )}
    </Wrapper>
  );
};
