import { GetPortfolioLoansItem, Identifier } from '@plus-platform/shared';
import compact from 'lodash/compact';
import difference from 'lodash/difference';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { ButtonLoader } from '../components/ButtonLoader';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import { getMaxVisibleItems, TableWrapper } from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { usePoolQuery, usePoolUpdateMutation, usePortfolioLoansQuery } from '../hooks/queries';
import { useDoubleClick } from '../hooks/useDoubleClick';
import { LoanDetailPanel } from '../loans/LoanDetailPanel';
import { SelectablePortfolioTable } from '../portfolio/PortfolioTable';
import { useLoanFilters } from './useLoanFilters';

type PoolAddLoansTableProps = {
  poolId: string;
};

export const PoolAddLoansTable = ({ poolId }: PoolAddLoansTableProps) => {
  const [selectedLoanId, setSelectedLoanId] = React.useState<Identifier | undefined>();
  const [checkedLoanIds, setCheckedLoansIds] = React.useState<Identifier[]>([]);
  const [disabledLoanIds, setDisabledLoanIds] = React.useState<Identifier[]>([]);
  const [isUpdatingPoolLoans, setIsUpdatingPoolLoans] = React.useState(false);

  const history = useHistory();

  const { handleClick: handleRowClick, handleDoubleClick: handleRowDoubleClick } = useDoubleClick({
    onDoubleClick: (loanNumber: GetPortfolioLoansItem['loanDetails']['loanNumber']) => {
      history.push(`/loan/${loanNumber}`);
    },
    onClick: (loanId: Identifier) => {
      setSelectedLoanId(selectedLoanId !== loanId ? loanId : undefined);
    },
  });

  const { appliedFilters, filters, updateFilters } = useLoanFilters();

  const { data: poolSummary, isLoading: isPoolSummaryLoading } = usePoolQuery(
    Number(poolId),
    (data) => {
      if (data?.loanIds) {
        setDisabledLoanIds(data.loanIds);
        setCheckedLoansIds(data.loanIds);
      }

      if (isUpdatingPoolLoans) {
        setIsUpdatingPoolLoans(false);
      }
    }
  );

  const {
    data: portfolioLoans,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isPortfolioLoansLoading,
  } = usePortfolioLoansQuery(appliedFilters.map((filter) => filter.id));

  const isLoading = isPoolSummaryLoading || isPortfolioLoansLoading;
  const pages = compact(get(portfolioLoans, 'pages', []));
  const totalLoansCount = get(pages, '0.totalLoansCount', 0);
  const totalFilteredLoansCount = get(pages, '0.totalFilteredLoansCount', 0);
  const loans = flatMap(pages, 'data');
  const selectedLoan = find(loans, {
    id: selectedLoanId,
  });

  const { isLoading: isUpdating, mutateAsync: updatePool } = usePoolUpdateMutation();

  const loanIdsToAdd = poolSummary ? difference(checkedLoanIds, poolSummary.loanIds) : [];

  const handleAddLoansToPool = async () => {
    if (checkedLoanIds.length > 0) {
      setIsUpdatingPoolLoans(true);

      await updatePool({
        poolId: Number(poolId),
        loanIds: checkedLoanIds,
      });

      history.push(`/pool/${poolId}`);
    }
  };

  return (
    <TableFilters
      filters={filters}
      onFiltersUpdate={updateFilters}
      path={`/pool/${poolId}/add-loans`}
    >
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({
            closeLeftPanel,
            closeRightPanel,
            containerHeight,
            isLeftPanelOpen,
            isRightPanelOpen,
            openLeftPanel,
            openRightPanel,
          }) => (
            <>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={updateFilters}
                />
              )}
              <Card $hasFlex>
                <TableToolbar>
                  <TableStats totalCount={totalLoansCount} visibleCount={loans.length} />
                  <Divider $orientation="vertical" $flexItem />
                  <TableFilterBar
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={updateFilters}
                  />
                  {loanIdsToAdd.length > 0 && (
                    <>
                      {appliedFilters.length === 0 && <Divider $orientation="vertical" $flexItem />}
                      <ButtonLoader
                        $color="primary"
                        $variant="outlined"
                        $size="xSmall"
                        isLoading={isUpdating || isUpdatingPoolLoans}
                        onClick={handleAddLoansToPool}
                      >
                        Add {loanIdsToAdd.length} loan
                        {loanIdsToAdd.length > 1 && 's'} to pool
                      </ButtonLoader>
                    </>
                  )}
                </TableToolbar>
                <ActivityIndicator contain isActive={isLoading}>
                  <TableWrapper>
                    <SelectablePortfolioTable
                      checkedLoanIds={checkedLoanIds}
                      disabledLoanIds={disabledLoanIds}
                      fetchNextPage={fetchNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      loans={loans}
                      maxVisibleItems={getMaxVisibleItems({
                        containerHeight,
                        hasHeaderGroups: true,
                      })}
                      onCheckboxClick={(checkedLoanIds) => setCheckedLoansIds(checkedLoanIds)}
                      onRowClick={(loanId: Identifier) => {
                        if (selectedLoanId === loanId) {
                          closeRightPanel();
                        } else {
                          openRightPanel();
                        }
                        handleRowClick(loanId);
                      }}
                      onRowDoubleClick={handleRowDoubleClick}
                      totalLoansCount={totalFilteredLoansCount}
                    />
                  </TableWrapper>
                </ActivityIndicator>
              </Card>
              {isRightPanelOpen && (
                <LoanDetailPanel
                  loan={selectedLoan}
                  onClose={() => {
                    setSelectedLoanId(undefined);
                    closeRightPanel();
                  }}
                />
              )}
            </>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};
