import styled from 'styled-components/macro';

export const SummaryItems = styled.div`
  display: flex;
  align-self: start;
  padding-top: 5px;
`;

export const SummaryItem = styled.div`
  padding: 0 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.white05};

  &:first-child {
    border-left: none;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const SummaryKey = styled.h6`
  color: ${({ theme }) => theme.colors.white70};

  ${({ theme }) => theme.typography.headings.headingRegular04}
`;

export const SummaryValue = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography.bodyText.bodyMedium02Tight}
`;

export const SummaryFlagValue = styled(SummaryValue)`
  color: ${({ theme }) => theme.colors.statusBlue};
`;

export const SummaryAlertValue = styled(SummaryValue)`
  color: ${({ theme }) => theme.colors.statusOrange};
`;

export const SummaryWarningValue = styled(SummaryValue)`
  color: ${({ theme }) => theme.colors.statusRed};
`;
