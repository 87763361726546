import { Identifier } from './global';

export type Role = {
  id: Identifier;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export enum RolesAttributeType {
  ATTRIBUTE_ROLES_NAME = 'ATTRIBUTE_ROLES_NAME',
}
