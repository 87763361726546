import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum ComparableAdjustmentType {
  Additions = 'Additions',
  AdjustedMonthlyRent = 'AdjustedMonthlyRent',
  Age = 'Age',
  BasementArea = 'BasementArea',
  BasementFinish = 'BasementFinish',
  CarStorage = 'CarStorage',
  CommonElements = 'CommonElements',
  Condition = 'Condition',
  DateOfSale = 'DateOfSale',
  DesignAppeal = 'DesignAppeal',
  DesignStyle = 'DesignStyle',
  EffectiveAge = 'EffectiveAge',
  EnergyEfficient = 'EnergyEfficient',
  FencePool = 'FencePool',
  FinancingConcessions = 'FinancingConcessions',
  Fireplaces = 'Fireplaces',
  FloorLocation = 'FloorLocation',
  Forecasting = 'Forecasting',
  FunctionalUtility = 'FunctionalUtility',
  Furnishings = 'Furnishings',
  GarageCarport = 'GarageCarport',
  GrossBuildingArea = 'GrossBuildingArea',
  GrossLivingArea = 'GrossLivingArea',
  HeatingCooling = 'HeatingCooling',
  InteriorAppeal = 'InteriorAppeal',
  Location = 'Location',
  MaintenanceFees = 'MaintenanceFees',
  Make = 'Make',
  MarketChange = 'MarketChange',
  MonthlyFacilityFee = 'MonthlyFacilityFee',
  NeighborhoodAppeal = 'NeighborhoodAppeal',
  Other = 'Other',
  Parking = 'Parking',
  PorchDeck = 'PorchDeck',
  ProjectAmenities = 'ProjectAmenities',
  ProjectSecurity = 'ProjectSecurity',
  ProjectSize = 'ProjectSize',
  PropertyRights = 'PropertyRights',
  Quality = 'Quality',
  RecreationFacilities = 'RecreationFacilities',
  RemodelBath = 'RemodelBath',
  RemodelKitchen = 'RemodelKitchen',
  RentConcessions = 'RentConcessions',
  RoomsBelowGrade = 'RoomsBelowGrade',
  SalesConcessions = 'SalesConcessions',
  SecurityFeatures = 'SecurityFeatures',
  SignificantFeatures = 'SignificantFeatures',
  SiteAppeal = 'SiteAppeal',
  SiteArea = 'SiteArea',
  SiteValue = 'SiteValue',
  TipOut = 'TipOut',
  TotalBathroomCountAboveGrade = 'TotalBathroomCountAboveGrade',
  TotalBedroomCountAboveGrade = 'TotalBedroomCountAboveGrade',
  Utilities = 'Utilities',
  View = 'View',
}

export type ComparableAdjustment = MISMOEntity &
  Partial<{
    comparableAdjustmentAmount: MISMOAmount;
    comparableAdjustmentDescription: MISMOString;
    comparableAdjustmentType: ComparableAdjustmentType;
    comparableAdjustmentTypeOtherDescription: MISMOString;
    salesComparisonUnitOfMeasureDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
