import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Analytics = styled.ul`
  display: flex;
  row-gap: 12px;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  margin: 0;
  overflow: hidden;
`;
