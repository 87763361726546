import { PartialOrNull } from '../utils';
import { EventLogItem, LoanPool, MISMO, OrganizationIdentifier, User } from '.';
import { AggregatedBorrower, Borrower, BorrowerCreation } from './borrowers';
import { Custodian } from './custodians';
import { DocumentCreation } from './documents';
import { AggregatedLoanEscrowAnalysis, LoanEscrowAnalysis } from './escrow';
import { Identifier } from './global';
import { AggregatedIndex, Index } from './indexes';
import { Insurer } from './insurers';
import { AggregatedProperty, Property, PropertyCreation, PropertyDetails } from './properties';
import { Servicer } from './servicers';

export enum LoansAttributeType {
  ATTRIBUTE_LOANS_TERMS_NOTE_AMOUNT = 'ATTRIBUTE_LOANS_TERMS_NOTE_AMOUNT',
  ATTRIBUTE_LOANS_TERMS_NOTE_RATE_PERCENT = 'ATTRIBUTE_LOANS_TERMS_NOTE_RATE_PERCENT',
  ATTRIBUTE_LOANS_TERMS_TERM_MONTHS = 'ATTRIBUTE_LOANS_TERMS_TERM_MONTHS',
  ATTRIBUTE_LOANS_TERMS_AMORTIZATION_TYPE = 'ATTRIBUTE_LOANS_TERMS_AMORTIZATION_TYPE',
  ATTRIBUTE_LOANS_TERMS_IS_BALLOON = 'ATTRIBUTE_LOANS_TERMS_IS_BALLOON',
  ATTRIBUTE_LOANS_TERMS_LIEN_PRIORITY_TYPE = 'ATTRIBUTE_LOANS_TERMS_LIEN_PRIORITY_TYPE',
  ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_MARGIN_RATE = 'ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_MARGIN_RATE',
  ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_EFFECTIVE_DATE = 'ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_EFFECTIVE_DATE',
  ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_LIFETIME_CEILING_RATE = 'ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_LIFETIME_CEILING_RATE',
  ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_LIFETIME_FLOOR_RATE = 'ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_LIFETIME_FLOOR_RATE',
  ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_PERIOD_CEILING_RATE = 'ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_PERIOD_CEILING_RATE',
  ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_PERIOD_FLOOR_RATE = 'ATTRIBUTE_LOANS_TERMS_ADJUSTMENT_PERIOD_FLOOR_RATE',
  ATTRIBUTE_LOANS_LOAN_AMOUNT = 'ATTRIBUTE_LOANS_LOAN_AMOUNT',
  ATTRIBUTE_LOANS_PORTFOLIO_METRICS = 'ATTRIBUTE_LOANS_PORTFOLIO_METRICS',
  ATTRIBUTE_LOANS_REASON_FOR_LOAN = 'ATTRIBUTE_LOANS_REASON_FOR_LOAN',
  ATTRIBUTE_LOANS_PURPOSE_OF_LOAN = 'ATTRIBUTE_LOANS_PURPOSE_OF_LOAN',
  ATTRIBUTE_LOANS_REFINANCE_PURPOSE_TYPE = 'ATTRIBUTE_LOANS_REFINANCE_PURPOSE_TYPE',
  ATTRIBUTE_LOANS_ADDITIONAL_LIENS = 'ATTRIBUTE_LOANS_ADDITIONAL_LIENS',
  ATTRIBUTE_LOANS_AGENCY_CASE_NUMBER = 'ATTRIBUTE_LOANS_AGENCY_CASE_NUMBER',
  ATTRIBUTE_LOANS_LENDER_NAME = 'ATTRIBUTE_LOANS_LENDER_NAME',
  ATTRIBUTE_LOANS_LENDER_ADDRESS = 'ATTRIBUTE_LOANS_LENDER_ADDRESS',
  ATTRIBUTE_LOANS_LENDER_CASE_NUMBER = 'ATTRIBUTE_LOANS_LENDER_CASE_NUMBER',
  ATTRIBUTE_LOANS_QUALIFIED_MORTGAGE = 'ATTRIBUTE_LOANS_QUALIFIED_MORTGAGE',
  ATTRIBUTE_LOANS_QUALIFYING_RATE = 'ATTRIBUTE_LOANS_QUALIFYING_RATE',
  ATTRIBUTE_LOANS_ABILITY_TO_REPAY = 'ATTRIBUTE_LOANS_ABILITY_TO_REPAY',
  ATTRIBUTE_LOANS_ORIGINAL_LTV = 'ATTRIBUTE_LOANS_ORIGINAL_LTV',
  ATTRIBUTE_LOANS_COMBINED_LTV = 'ATTRIBUTE_LOANS_COMBINED_LTV',
  ATTRIBUTE_LOANS_RESERVES_MONTHLY_PAYMENT_COUNT = 'ATTRIBUTE_LOANS_RESERVES_MONTHLY_PAYMENT_COUNT',
  ATTRIBUTE_LOANS_NOTES = 'ATTRIBUTE_LOANS_NOTES',
}

export const ALL_LOANS_ATTRIBUTES = Object.entries(LoansAttributeType).map(([, value]) => value);

export type LoanNoteCreation = {
  id: Identifier;
  loanId: Loan['id'];
  userId: User['id'];
  content: string;
  createdAt?: Date;
};

export type LoanNote = LoanNoteCreation & {
  id: Identifier;
};

export type LoanInsurer = {
  id: Identifier;
  loanId: Identifier;
  insurerId: Insurer['id'];
};

export type LoanInsurerAssignment = {
  loanId: Loan['id'];
  insurerId: Insurer['id'];
};

export type LoanCustodian = Custodian & {
  loanId: Loan['id'];
};

export type LoanCustodianAssignment = {
  loanId: Loan['id'];
  custodianId: Custodian['id'];
};

export type LoanAdditionalPayments = {
  hoaMonthlyAmount: number;
  hazardInsuranceMonthlyAmount: number;
  taxMonthlyAmount: number;
  mortgageInsuranceMonthlyAmount: number;
  firstMortgageMonthlyAmount: number;
  otherFinancingMonthlyAmount: number;
  otherExpensesMonthlyAmount: number;
  totalHousingCostMonthlyAmount: number;
};

export type LoanLTVs = {
  originalLtv?: number;
  combinedLtv?: number;
};

export type LoanAdditionalDetails = {
  companyName: string;
  companyIdentifier: string;
  companyAddress: string;
  originatorPersonnelName: string;
  originatorPersonnelId: string;
  originatorPersonnelPhoneNumber: string;
};

export type LoanQualification = {
  qualifyingRatePercent?: number;
  borrowerReservesMonthlyPaymentCount?: number;
};

export type LoanProperty = Property & {
  loanId: Identifier;
};

export type LoanPropertyDetails = PropertyDetails & {
  loanId: Identifier;
  loanNumber: string;
};

export type LoanOrigination = {
  originatingEntry: string;
  reasonForLoan: MISMO.PropertyUsageType;
  purposeOfLoan: MISMO.LoanPurposeType;
  // NOTE: this should ideally be a MISMO.RefinancePrimaryPurposeType,
  // but the 1008 Loan Purpose is a mix of RefinanceCashOutDeterminationType and LoanPurposeType...
  refinancePurposeType?: MISMO.RefinanceCashOutDeterminationType;
  additionalLiens: string;
  agencyCaseNumber: string;
  lendersName?: string;
  lendersAddress?: string;
  lendersCaseNumber: string;
  qualifiedMortgage: string;
  abilityToRepay: string;
};

export type LoanRemittance = {
  remittanceAmount: number;
  yearlyServicingFeeRate: number;
};

export type LoanFinancials = {
  originalUPB: number;
  currentUPB: number;
  currentLTV: number;
  totalMonthlyPayment: number;
  investorRemittance: LoanRemittance;
  principal: number;
  interest: number;
  taxes: number;
  insurance: number;
  hoaFee: number;
  mortgageInsurance: number;
};

export type LoanTermsCreation = {
  noteAmount: number;
  noteRate: number;
  termMonths: number;
  loanType: MISMO.MortgageType;
  amortizationType: MISMO.LoanAmortizationType;
  startingDate: Date;
  isBalloon: boolean;
  lienPriorityType: MISMO.LienPriorityType;
  createdAt?: Date;
  updatedAt?: Date;
};

export type LoanTerms = LoanTermsCreation & {
  id: Identifier;
  loanId: Loan['id'];
};

export type LoanInterestRateAdjustmentCreation = {
  loanId: Loan['id'];
  marginRate: number;
  lifetimeFloorRate?: number;
  lifetimeCeilingRate?: number;
  periodRateChangeFloorRate?: number;
  periodRateChangeCeilingRate?: number;
  effectiveDate: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type LoanInterestRateAdjustment = LoanInterestRateAdjustmentCreation & {
  id: Identifier;
};

export type AggregatedLoanInterestRateAdjustment = LoanInterestRateAdjustment & {
  adjustmentIndexes: AggregatedLoanAdjustmentIndex[];
};

export type LoanAdjustmentIndexCreation = {
  indexId: Index['id'];
  adjustmentId: LoanInterestRateAdjustment['id'];
};

export type LoanAdjustmentIndex = LoanAdjustmentIndexCreation & {
  id: Identifier;
  indexStartDate: Date;
  indexEndDate: Date;
};

export type AggregatedLoanAdjustmentIndex = LoanAdjustmentIndex & {
  indices: AggregatedIndex[];
};

export type LoanDetailsCreation = LoanOrigination &
  LoanAdditionalDetails &
  LoanQualification &
  LoanAdditionalPayments &
  LoanLTVs & {
    organizationId: OrganizationIdentifier;
    loanNumber: string;
    originalLoanAmount: number;
    encompassLoanId?: string;
    createdAt?: Date;
    updatedAt?: Date;
  };

export type LoanDetails = LoanDetailsCreation & {
  id: Identifier;
};

export type LoanDelinquencyStatus = {
  delinquencyDays: number;
  isDelinquent: boolean;
};

export type PaymentCreation = {
  paymentNumber: number;
  principal: number;
  interest: number;
  paymentDate: Date;
  // TODO: account for curtailment ?
};

export type Payment = PaymentCreation & {
  id: Identifier;
};

export type LoanPayment = Payment & {
  loanId: Loan['id'];
};

export type LoanPaymentCreation = PaymentCreation & {
  loanId: Loan['id'];
};

export type LoanServicer = {
  id: Identifier;
  loanId: Loan['id'];
  servicerId: Servicer['id'];
  yearlyServicingFeeRate: number;
  assignmentEffectiveDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type LoanServicerCreation = Omit<LoanServicer, 'id'>;

export type LoanEscrow = {
  // TODO: modify if needed as we probably want to get historical escrow analyses
  escrowAnalysis?: LoanEscrowAnalysis;
  escrowMonthlyPaymentAmount: number;
  isEscrowMonthlyPaymentCompliant: boolean;
};

export type AggregatedLoanServicer = LoanServicer & {
  servicer: Servicer;
};

export type AggregatedLoanProperty = LoanProperty & {
  property: AggregatedProperty;
};

export type AggregatedLoanInsurer = LoanInsurer & {
  insurer: Insurer;
};

export type AggregatedLoanBorrower = LoanBorrower & {
  borrower: AggregatedBorrower;
};

export type AggregatedDocument = DocumentCreation & { id: Identifier };

export type AggregatedLoanDocument = LoanDocument & {
  document: AggregatedDocument;
};

export type AggregatedLoanEscrow = LoanEscrow & {
  escrowAnalysis: LoanEscrowAnalysis;
};

export type LoanServicerAssignment = {
  loanId: Loan['id'];
  servicerId: Servicer['id'];
  yearlyServicingFeeRate: number;
};

export type LoanBorrower = Borrower & {
  loanId: Loan['id'];
};

export type LoanDocument = {
  id: Identifier;
  loanId: Loan['id'];
  documentId: Identifier;
};

export type LoanCreation = {
  loanDetails: LoanDetailsCreation;
  loanTerms: LoanTermsCreation;
  loanProperty: PropertyCreation;
  loanBorrowers: BorrowerCreation[];
};

export type AggregatedLoan = {
  id: number;
  loanDetails: LoanDetails;
  loanTerms: LoanTerms;
  loanInterestRateAdjustments?: AggregatedLoanInterestRateAdjustment[];
  loanIndexes?: AggregatedIndex[];
  loanPayments: Payment[];
  loanProperty: AggregatedLoanProperty;
  loanBorrowers: AggregatedLoanBorrower[];
  loanInsurers: AggregatedLoanInsurer[];
  loanDocuments: AggregatedLoanDocument[];
  loanEscrowAnalyses: AggregatedLoanEscrowAnalysis[];
  servicer?: AggregatedLoanServicer;
  assetLedger: EventLogItem[];
  pool?: Omit<LoanPool, 'loanIds'>;
};

export type Loan = AggregatedLoan & {
  delinquencyStatus: LoanDelinquencyStatus;
  financials: LoanFinancials;
  loanEscrow: LoanEscrow;
};

export enum ADCOExecutionStatus {
  NotRun = 'NotRun',
  Running = 'Running',
  Success = 'Success',
  Error = 'Error',
  Canceled = 'Canceled',
  Queued = 'Queued',
}

export type ADCOAnalysisStatus = PartialOrNull<{
  executionStatus: ADCOExecutionStatus;
  errorMessage: string;
  hasErrorRecords: boolean;
  currentStep: number;
  totalSteps: number;
  stepDesc: string;
  itemsProcessed: number;
  totalItems: number;
  output: string;
}>;

export type LoanNoteDto = {
  id: Identifier;
  content: string;
  createdAt: string;
  createdBy: {
    id: Identifier;
    firstName: string;
    lastName: string;
    avatarUrl: string | undefined;
  };
};

export type GetNotesForLoanResponse = {
  pagination: {
    offset: number;
    limit: number;
    totalCount: number;
  };
  items: LoanNoteDto[];
};

export type PaginatedLoans = {
  pageNumber?: number;
  pageCount: number;
  totalLoansCount: number;
  totalFilteredLoansCount: number;
  loans: Loan[];
  loanNumbers: Loan['loanDetails']['loanNumber'][];
};
