import { calculateBPOPercent, GetPortfolioLoansItem, Identifier } from '@plus-platform/shared';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import React from 'react';
import { FetchNextPageOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { VirtualItem } from 'react-virtual';
import styled from 'styled-components/macro';

import { Checkbox } from '../components/Checkbox';
import { DelinquencyBadge } from '../components/DelinquencyBadge';
import {
  Cell,
  CheckboxCell,
  CheckboxWrapper,
  ErrorRow,
  GroupedHeaderCell,
  GroupedHeaderRow,
  HeaderCell,
  HeaderRow,
  LoadingRow,
  Row,
  Table,
  Tbody,
  Thead,
} from '../components/Table';
import { VirtualizedTable } from '../components/VirtualizedTable';
import {
  EMPTY_VALUE,
  formatDefaultValue,
  formatMonetary,
  formatPercentage,
} from '../utils/formatUtils';

const SELECTABLE_TABLE_COLUMN_COUNT = 20;
const TABLE_COLUMN_COUNT = 19;

export const RowWrapper = styled.div<{ height: number; offsetY: number }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 16px;
  right: 0;
  height: ${(props) => props.height}px;
  transform: translateY(${(props) => props.offsetY}px);
`;

const PortfolioGroupedHeaderRow = styled(GroupedHeaderRow)`
  grid-template-columns: repeat(19, 1fr);
`;

type PortfolioTableGroupedHeaderProps = {
  columnCount: number;
  isSelectable?: boolean;
};

const PortfolioTableGroupedHeader = ({
  columnCount,
  isSelectable,
}: PortfolioTableGroupedHeaderProps) => {
  return (
    <PortfolioGroupedHeaderRow $isSelectable={isSelectable} $columnCount={columnCount}>
      <GroupedHeaderCell $gridColumn={'1 / span 10'}>Financials</GroupedHeaderCell>
      <GroupedHeaderCell $gridColumn={'11 /span 6'}>Total Monthly Payments</GroupedHeaderCell>
      <GroupedHeaderCell $gridColumn={'17 / -1'}>Mortgage</GroupedHeaderCell>
    </PortfolioGroupedHeaderRow>
  );
};

type PortfolioTableHeaderCellsProps = {
  variant?: 'portfolio' | 'pool';
};

const PortfolioTableHeaderCells = ({ variant }: PortfolioTableHeaderCellsProps) => {
  return (
    <React.Fragment>
      <HeaderCell>Loan ID</HeaderCell>
      {variant !== 'pool' && <HeaderCell>Pool</HeaderCell>}
      <HeaderCell>Status</HeaderCell>
      <HeaderCell>State</HeaderCell>
      <HeaderCell>Purchase Price</HeaderCell>
      <HeaderCell>Current Value</HeaderCell>
      <HeaderCell>Down Payment</HeaderCell>
      <HeaderCell>Orig UPB</HeaderCell>
      <HeaderCell>Cur UPB</HeaderCell>
      <HeaderCell>Cur LTV</HeaderCell>
      <HeaderCell>Principal</HeaderCell>
      <HeaderCell>Interest</HeaderCell>
      <HeaderCell>Taxes</HeaderCell>
      <HeaderCell>Insurance</HeaderCell>
      <HeaderCell>HOA</HeaderCell>
      <HeaderCell>MI</HeaderCell>
      <HeaderCell>Originator</HeaderCell>
      <HeaderCell>Loan Type</HeaderCell>
      <HeaderCell>Interest Rate</HeaderCell>
    </React.Fragment>
  );
};

type PortfolioTableHeaderProps = {
  variant?: 'portfolio' | 'pool';
  isSelectable?: boolean;
};

const SelectablePortfolioTableHeader = ({ variant }: PortfolioTableHeaderProps) => {
  return (
    <Thead>
      <PortfolioTableGroupedHeader columnCount={SELECTABLE_TABLE_COLUMN_COUNT} isSelectable />
      <HeaderRow $isSelectable $variant={variant} $columnCount={SELECTABLE_TABLE_COLUMN_COUNT}>
        <HeaderCell />
        <PortfolioTableHeaderCells variant={variant} />
      </HeaderRow>
    </Thead>
  );
};

const PortfolioTableHeader = ({ isSelectable, variant }: PortfolioTableHeaderProps) => {
  return (
    <Thead>
      <PortfolioTableGroupedHeader columnCount={TABLE_COLUMN_COUNT} isSelectable={isSelectable} />
      <HeaderRow $variant={variant} $columnCount={TABLE_COLUMN_COUNT}>
        <PortfolioTableHeaderCells variant={variant} />
      </HeaderRow>
    </Thead>
  );
};

type PortfolioRowCellsProps = {
  loan: GetPortfolioLoansItem;
  variant?: 'portfolio' | 'pool';
};

const PortfolioRowCells = ({ loan, variant }: PortfolioRowCellsProps) => {
  const BPOAmount = loan?.loanProperty?.property?.currentBpo;
  const purchasePrice = loan?.loanProperty?.property?.purchasePrice;
  const BPOPercent = calculateBPOPercent(BPOAmount, purchasePrice);

  return (
    <React.Fragment>
      <Cell>{loan.id}</Cell>
      {variant !== 'pool' && <Cell>{formatDefaultValue(loan.pool?.name)}</Cell>}
      <Cell>
        <DelinquencyBadge
          isDelinquent={loan.delinquencyStatus.isDelinquent}
          delinquencyDays={loan.delinquencyStatus.delinquencyDays}
        />
      </Cell>
      <Cell>{formatDefaultValue(loan.loanProperty?.property?.state)}</Cell>
      <Cell>{formatMonetary(loan.loanProperty?.property?.purchasePrice)}</Cell>
      <Cell>
        {BPOPercent !== undefined ? (
          <span>{formatPercentage(Math.abs(BPOPercent))}</span>
        ) : (
          EMPTY_VALUE
        )}
      </Cell>
      <Cell>{formatMonetary(loan.loanProperty?.property?.downPaymentAmount)}</Cell>
      <Cell>{formatMonetary(loan.financials.originalUPB)}</Cell>
      <Cell>{formatMonetary(loan.financials.currentUPB)}</Cell>
      <Cell>{formatPercentage(loan.financials.currentLTV)}</Cell>
      <Cell>{formatMonetary(loan.financials.principal)}</Cell>
      <Cell>{formatMonetary(loan.financials.interest)}</Cell>
      <Cell>{formatMonetary(loan.financials.taxes)}</Cell>
      <Cell>{formatMonetary(loan.financials.insurance)}</Cell>
      <Cell>{formatMonetary(loan.financials.hoaFee)}</Cell>
      <Cell>{formatMonetary(loan.financials.mortgageInsurance)}</Cell>
      <Cell>{formatDefaultValue(loan.loanDetails?.originatingEntry)}</Cell>
      <Cell>{formatDefaultValue(loan.loanTerms?.loanType)}</Cell>
      <Cell>{formatPercentage(loan.loanTerms?.noteRate)}</Cell>
    </React.Fragment>
  );
};

type RenderPortfolioTableProps = {
  isSelected: boolean;
  isLoaderRow: boolean;
  totalLoansCount: number;
  virtualRow: VirtualItem;
  loan?: GetPortfolioLoansItem;
  onRowClick?: (loanId: Identifier) => void;
  onRowDoubleClick?: (loanNumber: string) => void;
  variant?: 'portfolio' | 'pool';
};

type RenderSelectablePortfolioTableProps = RenderPortfolioTableProps & {
  isChecked: boolean;
  isDisabled: boolean;
  handleCheckboxChange: (loanId: GetPortfolioLoansItem['id'], isChecked: boolean) => void;
};

const renderSelectablePortfolioTableRow = ({
  handleCheckboxChange,
  isChecked,
  isDisabled,
  isLoaderRow,
  isSelected,
  loan,
  onRowClick,
  onRowDoubleClick,
  totalLoansCount,
  variant,
  virtualRow,
}: RenderSelectablePortfolioTableProps) => {
  return (
    <RowWrapper
      height={virtualRow.size}
      offsetY={virtualRow.start}
      data-testid="PortfolioTable_Row"
    >
      {isLoaderRow && (
        <LoadingRow>
          {virtualRow.index < totalLoansCount ? 'Loading more...' : 'Nothing more to load'}
        </LoadingRow>
      )}

      {!isLoaderRow && !loan && <ErrorRow>Error loading row</ErrorRow>}

      {!isLoaderRow && loan && (
        <Row
          $columnCount={SELECTABLE_TABLE_COLUMN_COUNT}
          $isDisabled={isDisabled}
          $isSelectable
          $isSelected={isSelected}
          $variant={variant}
          onClick={() => onRowClick?.(loan.id)}
          onDoubleClick={() => onRowDoubleClick?.(loan.loanDetails.loanNumber)}
        >
          <CheckboxCell onClick={(event) => event.stopPropagation()}>
            <CheckboxWrapper>
              <Checkbox
                checked={isChecked}
                disabled={isDisabled}
                onChange={(event) => handleCheckboxChange(loan.id, event.target.checked)}
              />
            </CheckboxWrapper>
          </CheckboxCell>
          <PortfolioRowCells loan={loan} variant={variant} />
        </Row>
      )}
    </RowWrapper>
  );
};

const renderPortfolioTableRow = ({
  isLoaderRow,
  isSelected,
  loan,
  onRowClick,
  onRowDoubleClick,
  totalLoansCount,
  variant,
  virtualRow,
}: RenderPortfolioTableProps) => {
  return (
    <RowWrapper height={virtualRow.size} offsetY={virtualRow.start}>
      {isLoaderRow && (
        <LoadingRow>
          {virtualRow.index < totalLoansCount ? 'Loading more...' : 'Nothing more to load'}
        </LoadingRow>
      )}

      {!isLoaderRow && !loan && <ErrorRow>Error loading row</ErrorRow>}

      {!isLoaderRow && loan && (
        <Row
          $variant={variant}
          $columnCount={TABLE_COLUMN_COUNT}
          $isSelected={isSelected}
          onClick={() => onRowClick?.(loan.id)}
          onDoubleClick={() => onRowDoubleClick?.(loan.loanDetails.loanNumber)}
          data-testid="PortfolioTable_Row"
        >
          <PortfolioRowCells loan={loan} variant={variant} />
        </Row>
      )}
    </RowWrapper>
  );
};

type PortfolioTableProps = {
  shouldRedirectToLoan?: boolean;
  loans: GetPortfolioLoansItem[];
  totalLoansCount: number;
  isFetchingNextPage: boolean;
  selectedLoanId?: string;
  onRowClick?: (loanId: Identifier) => void;
  onRowDoubleClick?: (loanNumber: string) => void;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  maxVisibleItems?: number;
  variant?: 'portfolio' | 'pool';
};

export const PortfolioTable = ({
  fetchNextPage,
  isFetchingNextPage,
  loans,
  maxVisibleItems,
  onRowClick,
  onRowDoubleClick,
  selectedLoanId,
  shouldRedirectToLoan,
  totalLoansCount,
  variant,
}: PortfolioTableProps) => {
  const history = useHistory();

  const parentRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <Table>
      <PortfolioTableHeader variant={variant} />
      <Tbody>
        <VirtualizedTable<GetPortfolioLoansItem>
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          items={loans}
          maxVisibleItems={maxVisibleItems}
          parentRef={parentRef}
          renderRow={(isLoaderRow, virtualRow, loan) => {
            if (!loan) {
              return null;
            }

            const isSelected = selectedLoanId ? selectedLoanId === String(loan.id) : false;

            return renderPortfolioTableRow({
              variant,
              isSelected,
              isLoaderRow,
              totalLoansCount,
              virtualRow,
              loan,
              onRowClick: shouldRedirectToLoan
                ? () => history.push(`/loan/${loan.loanDetails.loanNumber}/summary`)
                : onRowClick,
              onRowDoubleClick,
            });
          }}
          totalItemsCount={totalLoansCount}
        />
      </Tbody>
    </Table>
  );
};

type SelectablePortfolioTableProps = PortfolioTableProps & {
  checkedLoanIds?: Identifier[];
  disabledLoanIds?: Identifier[];
  onCheckboxClick?: (selectedIds: Identifier[]) => void;
};

export const SelectablePortfolioTable = ({
  shouldRedirectToLoan,
  fetchNextPage,
  isFetchingNextPage,
  loans,
  onRowClick,
  onRowDoubleClick,
  onCheckboxClick,
  selectedLoanId,
  disabledLoanIds = [],
  checkedLoanIds = [],
  totalLoansCount,
  maxVisibleItems,
  variant,
}: SelectablePortfolioTableProps) => {
  const history = useHistory();
  const parentRef = React.useRef<HTMLDivElement | null>(null);

  const handleCheckboxChange = (loanId: GetPortfolioLoansItem['id'], isChecked: boolean) => {
    if (isChecked) {
      onCheckboxClick?.(uniq([...checkedLoanIds, loanId]));
    } else if (checkedLoanIds.includes(loanId)) {
      onCheckboxClick?.(without(checkedLoanIds, loanId));
    }
  };

  return (
    <Table>
      <SelectablePortfolioTableHeader variant={variant} />
      <Tbody>
        <VirtualizedTable<GetPortfolioLoansItem>
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          items={loans}
          maxVisibleItems={maxVisibleItems}
          parentRef={parentRef}
          renderRow={(isLoaderRow, virtualRow, loan) => {
            if (!loan) {
              return null;
            }

            const isChecked = checkedLoanIds.includes(loan.id);
            const isDisabled = disabledLoanIds.includes(loan.id);
            const isSelected = selectedLoanId
              ? selectedLoanId === String(loan.id)
              : checkedLoanIds.includes(loan.id);

            return renderSelectablePortfolioTableRow({
              isChecked,
              isSelected,
              isDisabled,
              isLoaderRow,
              totalLoansCount,
              virtualRow,
              loan,
              variant,
              onRowClick: shouldRedirectToLoan
                ? () => history.push(`/loan/${loan.loanDetails.loanNumber}`)
                : onRowClick,
              onRowDoubleClick,
              handleCheckboxChange,
            });
          }}
          totalItemsCount={totalLoansCount}
        />
      </Tbody>
    </Table>
  );
};
