import { PropertyScores } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataPropertyScoresQuery = {
  tradeRequestId: string;
  loanId: string;
};

const getTradeRequestDataPropertyScores = ({
  loanId,
  tradeRequestId,
}: TradeRequestDataPropertyScoresQuery) => {
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/loans/${loanId}/property-score`);
  return getRequestWithAuth<PropertyScores>(url);
};

export const useTradeRequestDataPropertyScoresQuery = (
  query: TradeRequestDataPropertyScoresQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_PROPERTY_SCORES, query], () =>
    getTradeRequestDataPropertyScores(query)
  );
