import { Divider } from './Divider';
import { Heading } from './Heading';
import type { ModalProps } from './Modal';
import { Modal } from './Modal';
import * as Styles from './Popup.styles';

type PopupProps = ModalProps & {
  children: React.ReactNode;
};

export const Popup = ({ children, ...rest }: PopupProps) => {
  return (
    <Modal hasOverlay {...rest}>
      <Styles.Popup>{children}</Styles.Popup>
    </Modal>
  );
};

type PopupHeaderProps = {
  title: string;
  children?: React.ReactNode;
};

export const PopupHeader = ({ children, title }: PopupHeaderProps) => {
  return (
    <>
      <Styles.Header>
        <Heading $size="large">{title}</Heading>
        {children}
      </Styles.Header>
      <Divider />
    </>
  );
};

export const PopupContent = Styles.Content;

export const PopupFooter = Styles.Footer;
