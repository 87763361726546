import styled, { css } from 'styled-components/macro';

export const ButtonImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 120vh; // This looks weird, but it's here to handle the case where the screen is wide enough but too short
  width: calc(100% - 64px);
  align-self: center;
`;

export const LightboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-self: center;
`;

export const ImageAspectRatioWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56%;
`;

export const ImageAspectRatioInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

type TileSelectorProps = {
  $isSelected: boolean;
};

export const TileSelector = styled.div<TileSelectorProps>(
  ({ $isSelected, theme }) => css`
    border-radius: 8px;
    border: 2px solid ${$isSelected ? theme.colors.accent01 : 'transparent'};
    padding: 2px;
    cursor: pointer;
  `
);

export const ImageRibbon = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ImageRibbonScrollWrapper = styled.div`
  overflow-x: auto;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
