import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOURI,
} from './types';

type HELOCOccurrence = Partial<{
  currentHELOCMaximumBalanceAmount: MISMOAmount;
  HELOCBalanceAmount: MISMOAmount;
  HELOCDailyPeriodicInterestRatePercent: MISMOPercent;
  HELOCTeaserTermEndDate: MISMODate;
  sequenceNumber: MISMOSequenceNumber;
}>;

enum HELOCDailyPeriodicInterestRateCalculationType {
  HELOCDailyPeriodicInterestRateCalculationType360 = '360',
  HELOCDailyPeriodicInterestRateCalculationType365 = '365',
}

enum HELOCTeaserRateType {
  Adjustable = 'Adjustable',
  Fixed = 'Fixed',
}

type HELOCRule = Partial<{
  HELOCAnnualFeeAmount: MISMOAmount;
  HELOCCreditCardAccountIdentifier: MISMOIdentifier;
  'HELOCCreditCardAccountIdentifier@IdentifierOwnerURI': MISMOURI;
  HELOCCreditCardIndicator: MISMOIndicator;
  HELOCCreditLineDrawAccessFeeAmount: MISMOAmount;
  HELOCDailyPeriodicInterestRateCalculationType: HELOCDailyPeriodicInterestRateCalculationType;
  HELOCInitialAdvanceAmount: MISMOAmount;
  HELOCMaximumAPRPercent: MISMOPercent;
  HELOCMaximumBalanceAmount: MISMOAmount;
  HELOCMinimumAdvanceAmount: MISMOAmount;
  HELOCMinimumPaymentAmount: MISMOAmount;
  HELOCMinimumPaymentPercent: MISMOPercent;
  HELOCRepayPeriodMonthsCount: MISMOCount;
  HELOCReturnedCheckChargeAmount: MISMOAmount;
  HELOCStopPaymentChargeAmount: MISMOAmount;
  HELOCTeaserMarginRatePercent: MISMOPercent;
  HELOCTeaserRateType: HELOCTeaserRateType;
  HELOCTeaserTermMonthsCount: MISMOCount;
  HELOCTerminationFeeAmount: MISMOAmount;
  HELOCTerminationPeriodMonthsCount: MISMOCount;
}>;

export type HELOC = MISMOEntity &
  Partial<{
    HELOCOccurrences: HELOCOccurrence[];
    HELOCRule: HELOCRule;
  }>;
