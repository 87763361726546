import { AttributeType } from '@plus-platform/shared';

import { DashboardLineIcon, EditIcon, SignOutIcon, UsersIcon } from '../../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection } from '../../components/Menu';
import { ReadPermissions } from '../../components/Permissions';
import { useUserContext } from '../../contexts/UserContext';
import { formatDefaultValue, formatNameToInitials } from '../../utils/formatUtils';
import { ProfileToggle } from './ProfileToggle';
import * as Styles from './UserProfileMenu.styles';

type UserProfileMenuProps = {
  onLogoutClick: () => void;
};

export const UserProfileMenu = ({ onLogoutClick }: UserProfileMenuProps) => {
  const { userProfile } = useUserContext();

  const userFullName = userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '';
  const userAvatar = userProfile?.avatarUrl;
  const userOrganizationTradingName = userProfile?.organization?.tradingName ?? '';
  const userInitials = userProfile
    ? formatNameToInitials(userProfile.lastName, userProfile.firstName)
    : '';

  return (
    <Menu
      toggleProps={{
        customToggle: ({ isActive, onClick }) => (
          <ProfileToggle
            src={userAvatar}
            firstName={userProfile?.firstName}
            lastName={userProfile?.lastName}
            isActive={isActive}
            onClick={onClick}
            data-testid="ActionsMenu_Button"
          />
        ),
      }}
      title="Profile"
      data-testid="UserProfileMenu"
    >
      <MenuSection>
        <Styles.ProfileInformation>
          <Styles.Disk $hasSrc={Boolean(userAvatar)}>
            {userAvatar ? (
              <Styles.Avatar src={userAvatar} />
            ) : (
              <Styles.Initials>{formatDefaultValue(userInitials)}</Styles.Initials>
            )}
          </Styles.Disk>
          <Styles.Username>{formatDefaultValue(userFullName)}</Styles.Username>
          <Styles.Organization>
            {formatDefaultValue(userOrganizationTradingName)}
          </Styles.Organization>
          <Styles.Email>{formatDefaultValue(userProfile?.email)}</Styles.Email>
        </Styles.ProfileInformation>
      </MenuSection>
      <MenuSection>
        <MenuItem to="/profile">
          <EditIcon />
          <MenuItemText>Edit</MenuItemText>
        </MenuItem>
      </MenuSection>
      <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_ORGANIZATION]}>
        <MenuSection>
          <MenuItem to="/admin">
            <DashboardLineIcon />
            <MenuItemText>Dashboard</MenuItemText>
          </MenuItem>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_ORGANIZATION_USER]}>
            <MenuItem to="/admin/users">
              <UsersIcon />
              <MenuItemText>Users</MenuItemText>
            </MenuItem>
          </ReadPermissions>
        </MenuSection>
      </ReadPermissions>
      <MenuSection>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            onLogoutClick();
          }}
          data-testid="UserProfileMenu_SignOut"
        >
          <SignOutIcon />
          <MenuItemText>Sign out</MenuItemText>
        </MenuItem>
      </MenuSection>
    </Menu>
  );
};
