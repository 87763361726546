import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

export type HeadingButtonStyleProps = {
  $size: 'large' | 'medium' | 'small';
};

const largeSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium01}
  padding: 0.25em;
`;

const mediumSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium03}
  padding: 0 0.25em;
`;

const smallSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium04}
  padding: 0 0.25em;
`;

export const HeadingButton = styled.button<
  HeadingButtonStyleProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  ({ $size = 'large', theme }) => css`
    display: inline-flex;
    text-decoration: none;
    border-radius: 0.25em;
    color: ${theme.colors.anchorColor};
    background-color: ${theme.colors.anchorBGColor};
    border: 0;
    cursor: pointer;

    ${$size === 'large' && largeSizeStyles}
    ${$size === 'medium' && mediumSizeStyles}
    ${$size === 'small' && smallSizeStyles}

    &:visited {
      color: ${theme.colors.anchorColor};
      background-color: ${theme.colors.anchorBGColor};
    }

    &:hover {
      color: ${theme.colors.anchorColorHover};
      background-color: ${theme.colors.anchorBGColorHover};
    }

    &:focus-visible {
      color: ${theme.colors.anchorColorHover};
      background-color: ${theme.colors.anchorBGColorHover};
      outline: none;
    }

    &:active {
      color: ${theme.colors.anchorColorActive};
      background-color: ${theme.colors.anchorBGColorActive};
    }
  `
);
