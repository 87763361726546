import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type OutboundExpressionOfInterestAcceptedProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundExpressionOfInterestAccepted = ({
  action,
}: OutboundExpressionOfInterestAcceptedProps) => {
  const { createdAt, toCounterparty } = action;

  return (
    <PlusMessage date={createdAt}>
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.ExpressionOfInterest.Accepted"
          values={{
            userName: formatNameWithInitial(
              toCounterparty.user.lastName,
              toCounterparty.user.firstName
            ),
            organizationName: toCounterparty.user.organization.tradingName,
          }}
        />
      </MessageText>
    </PlusMessage>
  );
};
