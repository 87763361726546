import { Appraiser } from './appraiser';
import { Borrower } from './borrower';
import { Lender } from './lender';
import { License } from './license';
import { LoanOriginator } from './loan/loanOriginator';
import {
  MISMOCode,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOURI,
} from './types';

export enum PartyRoleType {
  Appraiser = 'Appraiser',
  AppraiserSupervisor = 'AppraiserSupervisor',
  AssignFrom = 'AssignFrom',
  AssignTo = 'AssignTo',
  Attorney = 'Attorney',
  AttorneyInFact = 'AttorneyInFact',
  AuthorizedRepresentative = 'AuthorizedRepresentative',
  AuthorizedThirdParty = 'AuthorizedThirdParty',
  BankruptcyFiler = 'BankruptcyFiler',
  BankruptcyTrustee = 'BankruptcyTrustee',
  BeneficialInterestParty = 'BeneficialInterestParty',
  BillToParty = 'BillToParty',
  Borrower = 'Borrower',
  Builder = 'Builder',
  Client = 'Client',
  ClosingAgent = 'ClosingAgent',
  Conservator = 'Conservator',
  ConsumerReportingAgency = 'ConsumerReportingAgency',
  CooperativeCompany = 'CooperativeCompany',
  CorrespondentLender = 'CorrespondentLender',
  Cosigner = 'Cosigner',
  CreditCounselingAgent = 'CreditCounselingAgent',
  CreditEnhancementRiskHolder = 'CreditEnhancementRiskHolder',
  CustodianNotePayTo = 'CustodianNotePayTo',
  DebtCollector = 'DebtCollector',
  Defendant = 'Defendant',
  DeliverRescissionTo = 'DeliverRescissionTo',
  DesignatedContact = 'DesignatedContact',
  DocumentCustodian = 'DocumentCustodian',
  ENoteController = 'ENoteController',
  ENoteControllerTransferee = 'ENoteControllerTransferee',
  ENoteCustodian = 'ENoteCustodian',
  ENoteCustodianTransferee = 'ENoteCustodianTransferee',
  ENoteDelegateeForTransfers = 'ENoteDelegateeForTransfers',
  ENoteRegisteringParty = 'ENoteRegisteringParty',
  ENoteServicer = 'ENoteServicer',
  ENoteServicerTransferee = 'ENoteServicerTransferee',
  ENoteTransferInitiator = 'ENoteTransferInitiator',
  Executor = 'Executor',
  FHASponsor = 'FHASponsor',
  FloodCertificateProvider = 'FloodCertificateProvider',
  FulfillmentParty = 'FulfillmentParty',
  GiftDonor = 'GiftDonor',
  Grantee = 'Grantee',
  Grantor = 'Grantor',
  Guarantor = 'Guarantor',
  HazardInsuranceAgent = 'HazardInsuranceAgent',
  HazardInsuranceCompany = 'HazardInsuranceCompany',
  HomeownersAssociation = 'HomeownersAssociation',
  HousingCounselingAgency = 'HousingCounselingAgency',
  HousingCounselingAgent = 'HousingCounselingAgent',
  HUD1SettlementAgent = 'HUD1SettlementAgent',
  Interviewer = 'Interviewer',
  InterviewerEmployer = 'InterviewerEmployer',
  Investor = 'Investor',
  IRSTaxFormThirdParty = 'IRSTaxFormThirdParty',
  LawFirm = 'LawFirm',
  Lender = 'Lender',
  LenderBranch = 'LenderBranch',
  LienHolder = 'LienHolder',
  LoanCloser = 'LoanCloser',
  LoanDeliveryFilePreparer = 'LoanDeliveryFilePreparer',
  LoanFunder = 'LoanFunder',
  LoanOfficer = 'LoanOfficer',
  LoanOriginationCompany = 'LoanOriginationCompany',
  LoanOriginator = 'LoanOriginator',
  LoanProcessor = 'LoanProcessor',
  LoanSeller = 'LoanSeller',
  LoanUnderwriter = 'LoanUnderwriter',
  LossPayee = 'LossPayee',
  ManagementCompany = 'ManagementCompany',
  MICompany = 'MICompany',
  MortgageBroker = 'MortgageBroker',
  NonTitleNonSpouseOwnershipInterest = 'NonTitleNonSpouseOwnershipInterest',
  NonTitleSpouse = 'NonTitleSpouse',
  Notary = 'Notary',
  NotePayTo = 'NotePayTo',
  NotePayToRecipient = 'NotePayToRecipient',
  Other = 'Other',
  Payee = 'Payee',
  Plaintiff = 'Plaintiff',
  PoolInsurer = 'PoolInsurer',
  PoolIssuer = 'PoolIssuer',
  PoolIssuerTransferee = 'PoolIssuerTransferee',
  PreparedBy = 'PreparedBy',
  ProjectDeveloper = 'ProjectDeveloper',
  ProjectManagementAgent = 'ProjectManagementAgent',
  PropertyAccessContact = 'PropertyAccessContact',
  PropertyJurisdictionalAuthority = 'PropertyJurisdictionalAuthority',
  PropertyOwner = 'PropertyOwner',
  PropertyPreservationAgent = 'PropertyPreservationAgent',
  PropertyPurchaser = 'PropertyPurchaser',
  PropertySeller = 'PropertySeller',
  RealEstateAgent = 'RealEstateAgent',
  ReceivingParty = 'ReceivingParty',
  RegistryOperator = 'RegistryOperator',
  RegulatoryAgency = 'RegulatoryAgency',
  RequestingParty = 'RequestingParty',
  RespondingParty = 'RespondingParty',
  RespondToParty = 'RespondToParty',
  ReturnTo = 'ReturnTo',
  ReviewAppraiser = 'ReviewAppraiser',
  SecurityIssuer = 'SecurityIssuer',
  ServiceBureau = 'ServiceBureau',
  ServiceProvider = 'ServiceProvider',
  Servicer = 'Servicer',
  ServicerPaymentCollection = 'ServicerPaymentCollection',
  Settlor = 'Settlor',
  Spouse = 'Spouse',
  SubmittingParty = 'SubmittingParty',
  SuccessorInInterest = 'SuccessorInInterest',
  TaxableParty = 'TaxableParty',
  TaxAssessor = 'TaxAssessor',
  TaxCollector = 'TaxCollector',
  Taxpayer = 'Taxpayer',
  TaxServiceProvider = 'TaxServiceProvider',
  TaxServicer = 'TaxServicer',
  ThirdPartyInvestor = 'ThirdPartyInvestor',
  ThirdPartyOriginator = 'ThirdPartyOriginator',
  TitleCompany = 'TitleCompany',
  TitleHolder = 'TitleHolder',
  TitleUnderwriter = 'TitleUnderwriter',
  Trust = 'Trust',
  TrustBeneficiary = 'TrustBeneficiary',
  Trustee = 'Trustee',
  Unspecified = 'Unspecified',
  WarehouseLender = 'WarehouseLender',
  Witness = 'Witness',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum PartyRoleTypeOtherDescription {
  HomeownersAssociation = 'HomeownersAssociation',
}

export type PartyRoleIdentifier = MISMOEntity &
  Partial<{
    partyRoleIdentifier: MISMOIdentifier;
    'partyRoleIdentifier@IdentifierOwnerURI': MISMOURI;
    partyRoleIdentifierCertificationStatusCode: MISMOCode;
    partyRoleIdentifierCertificationStatusDate: MISMODate;
    partyRoleIdentifierDescription: MISMOString;
    partyRoleIdentifierExpiryDate: MISMODate;
    partyRoleIdentifierExpiryReasonCode: MISMOCode;
    partyRoleIdentifierExpiryReasonDescription: MISMOString;
    partyRoleIdentifierFirstAssignmentDate: MISMODate;
    partyRoleIdentifierLastUpdateDate: MISMODate;
    partyRoleIdentifierOfficialRegistryName: MISMOString;
    partyRoleIdentifierReferenceDescription: MISMOString;
    partyRoleIdentifierScheduledCertificationStatusDate: MISMODate;
    partyRoleIdentifierStatusCode: MISMOCode;
    partyRoleIdentifierStatusDate: MISMODate;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type Role = MISMOEntity &
  Partial<{
    consentToReceiveDocumentsElectronicallyIndicator: MISMOIndicator;
    consentToSignUsingElectronicSignatureIndicator: MISMOIndicator;
    partyRoleType: PartyRoleType;
    partyRoleTypeAdditionalDescription: MISMOString;
    partyRoleTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    licenses: License[];
    partyRoleIdentifiers: PartyRoleIdentifier[];
  }> &
  (
    | Appraiser
    // | AppraiserSupervisor
    // | Attorney
    // | AttorneyInFact
    | Borrower
    // | ClosingAgent
    // | Defendant
    // | FulfillmentParty
    // | HousingCounselingAgency
    | Lender
    // | LienHolder
    | LoanOriginator
  );
// | LossPayee
// | Notary
// | Payee
// | Plaintiff
// | PropertyOwner
// | PropertySeller
// | RealEstateAgent
// | RegulatoryAgency
// | RequestingParty
// | RespondingParty
// | ReturnTo
// | ReviewAppraiser
// | ServiceProvider
// | Servicer
// | ServicingTransferor
// | SubmittingParty
// | Taxpayer
// | Trust
// | Trustee
