import { SVGAttributes } from 'react';
import styled, { css } from 'styled-components';

export type RadioDialProps = {
  $value: number;
  $colorValue: number;
};

export const SvgContainer = styled.svg.attrs<SVGAttributes<SVGSVGElement>>({
  viewBox: '0 0 32 32',
  width: 32,
  height: 32,
  fill: 'none',
})`
  transform: rotate(-90deg);
  transition: all 1s ease-in-out;
`;

const circleRadius = 14;

const circleAttributes = {
  cx: 16,
  cy: 16,
  r: circleRadius,
};

export const CircleBackground = styled.circle.attrs(circleAttributes)(
  ({ theme }) => css`
    stroke: ${theme.colors.white50};
    stroke-width: 4;
  `
);

export const Circle = styled.circle.attrs(circleAttributes)<RadioDialProps>(
  ({ $colorValue, $value, theme }) =>
    $value > 0 &&
    css`
      stroke: ${() => {
        if ($colorValue < 50) {
          return theme.colors.statusRed;
        }
        if ($colorValue < 75) {
          return theme.colors.statusYellow;
        }
        return theme.colors.statusGreen;
      }};
      stroke-width: 4;
      stroke-linecap: round;
      transition: all 1s ease-in-out;
      stroke-dasharray: ${() => {
        const circumference = 2 * circleRadius * Math.PI;
        const drawLength = ($value / 100) * circumference;
        return `${drawLength} 999`;
      }};
    `
);

export const Text = styled.text.attrs({
  x: '50%',
  y: '50%',
  textAnchor: 'middle',
  textRendering: 'optimizeLegibility',
})(
  ({ theme }) => css`
    fill: ${theme.colors.white};
    ${theme.typography.bodyText.bodyRegular02}
    transform-origin: center;
    transform: rotate(90deg) translateY(4px);
  `
);
