import React from 'react';

export const FolderIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.6 18.8C5.16 18.8 4.78333 18.6433 4.47 18.33C4.15667 18.0167 4 17.64 4 17.2V7.6C4 7.16 4.15667 6.78333 4.47 6.47C4.78333 6.15667 5.16 6 5.6 6H10.4L12 7.6H18.4C18.84 7.6 19.2167 7.75667 19.53 8.07C19.8433 8.38333 20 8.76 20 9.2V17.2C20 17.64 19.8433 18.0167 19.53 18.33C19.2167 18.6433 18.84 18.8 18.4 18.8H5.6ZM5.6 17.2H18.4V9.2H11.34L9.74 7.6H5.6V17.2Z"
        fill="currentColor"
      />
    </svg>
  );
};
