import { rem } from 'polished';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

export const BadgeWrapper = styled.div<{ $gap?: number }>`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  column-gap: ${({ $gap }) => (typeof $gap === 'number' ? $gap : 8)}px;
`;

type BadgeProps = {
  $color?: string;
  $margin?: number;
  $size?: number;
  $textColor?: string;
  $shape?: 'circle' | 'pill';
};

const getBadgeTypographyBySize = ({ $size, theme }: ThemeProps<DefaultTheme> & BadgeProps) => {
  if (!$size) {
    return theme.typography.headings.headingMedium04;
  }

  if ($size < 24) {
    return theme.typography.headings.headingMedium04;
  }

  if ($size < 32) {
    return theme.typography.headings.headingMedium03Tight;
  }

  if ($size < 40) {
    return theme.typography.headings.headingMedium03;
  }

  if ($size < 48) {
    return theme.typography.headings.headingMedium02;
  }

  if ($size < 56) {
    return theme.typography.headings.headingMedium01;
  }

  return css`
    font-family: ${theme.typography.fontFamily.heading};
    font-weight: ${theme.typography.fontWeight.medium};
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `;
};

export const Badge = styled.div<BadgeProps>(
  ({ $color, $margin = 0, $shape = 'circle', $size = 12, $textColor, theme }) => css`
    ${getBadgeTypographyBySize}

    width: ${$size}px;
    height: ${$size}px;
    margin: ${$margin}px;
    border-radius: ${$size / 2}px;
    background-color: ${$color ?? theme.colors.statusYellow};
    color: ${$textColor ?? theme.colors.statusBlack};
    display: inline-flex;
    justify-content: center;
    align-items: center;

    ${$shape === 'pill' &&
    css`
      width: auto;
      padding: 0 4px;
    `}
  `
);

type CountBadgeProps = {
  $color: string;
};

export const CountBadge = styled.div<CountBadgeProps>`
  ${({ $color, theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 20px;
    color: ${theme.colors.statusBlack};
    background-color: ${$color};
    border-radius: 2px;
    font-family: ${theme.typography.fontFamily.display};
    font-size: 13px;
    font-weight: 600;
  `}
`;
