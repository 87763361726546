import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

export type PairProps = {
  $align?: 'left' | 'right';
  $direction?: 'horizontal' | 'vertical';
  $isMultiLine?: boolean;
  $size?: 'small' | 'medium' | 'large';
  $templateColumns?: string;
  $type?: 'standalone' | 'list';
  $isLight?: boolean;
};

type styleProps = PairProps & ThemeProps<DefaultTheme>;

const horizontalStyles = ({ $align, $size }: styleProps) => css`
  ${$align === 'right' && rightHorizontalStyles}
  ${$align === 'left' && leftHorizontalStyles}
  ${$size === 'small' && smallHorizontalStyles}
  ${$size === 'medium' && mediumHorizontalStyles}
  ${$size === 'large' && largeHorizontalStyles}
`;

const rightHorizontalStyles = ({ $templateColumns }: styleProps) => css`
  display: grid;
  grid-template-columns: ${$templateColumns ?? 'repeat(2, 1fr)'};
  align-items: center;
`;

const leftHorizontalStyles = css`
  display: flex;
  align-items: center;
`;

const smallHorizontalStyles = ({ $type }: styleProps) => css`
  column-gap: ${$type === 'list' ? 0 : 4}px;
`;

const mediumHorizontalStyles = ({ $type }: styleProps) => css`
  column-gap: ${$type === 'list' ? 0 : 4}px;
`;

const largeHorizontalStyles = ({ $type }: styleProps) => css`
  column-gap: ${$type === 'list' ? 0 : 16}px;
`;

const verticalStyles = css`
  display: flex;
  flex-direction: column;
`;

type KeyProps = {
  $hasPadding?: boolean;
};

export const KVListWrapper = styled.div<{ $rowGap: number }>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ $rowGap }) => $rowGap}px;
`;

export const Key = styled.dt.attrs<KeyProps>(({ children, ...props }) => ({
  title: children,
  ...props,
}))<KeyProps>(
  ({ $hasPadding, theme }) => css`
    display: inline-block;
    color: ${theme.colors.white70};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: inherit;

    ${$hasPadding &&
    css`
      padding: 0 4px;
    `}
  `
);

export const TitleKey = styled(Key).attrs({ as: 'h3' })(
  ({ theme }) => css`
    display: flex;
    width: calc(50% - 8px);
    justify-content: right;
    color: ${theme.colors.textPrimary01};
    ${theme.typography.bodyText.bodyMedium02}
  `
);

type StatusColorNames =
  | 'statusYellow'
  | 'statusOrange'
  | 'statusRed'
  | 'statusTurquoise'
  | 'statusGreen'
  | 'statusBlue'
  | 'lightGreen';

type ValueProps = {
  $status?: StatusColorNames;
  $hasPadding?: boolean;
};

export const Value = styled.dd<ValueProps>(
  ({ $hasPadding, $status, theme }) => css`
    display: inline-block;
    margin: 0;
    color: ${$status ? theme.colors[$status] : theme.colors.textPrimary01};
    font-weight: ${theme.typography.fontWeight.medium};
    text-align: left;

    ${$hasPadding &&
    css`
      padding: 0 4px;
    `}
  `
);

export const Pair = styled.dl.attrs<PairProps>((props) => ({
  $align: props.$align ?? 'right',
  $direction: props.$direction ?? 'horizontal',
  $isMultiLine: props.$isMultiLine ?? false,
  $size: props.$size ?? 'medium',
  $type: props.$type ?? 'standalone',
}))<PairProps>(
  ({ $align, $direction, $isMultiLine, $size, $type, theme }) => css`
    margin: 0;

    /**
     * Type
     */

    ${$type === 'standalone' &&
    css`
      padding: 0 4px;
    `}

    /**
     * Direction
     */

    ${$direction === 'horizontal' && horizontalStyles}
    ${$direction === 'vertical' && verticalStyles}

    /**
     * Size
     */

    ${$size === 'small' && theme.typography.bodyText.bodyRegular03Tight}
    ${$size === 'medium' && theme.typography.bodyText.bodyRegular02Tight}
    ${$size === 'large' && theme.typography.bodyText.bodyRegular02}

    /**
     * Align
     */

    ${$align === 'right' &&
    css`
      text-align: right;
    `}

    /**
     * Multi-line
     */

    ${$isMultiLine &&
    css`
      align-items: start;
    `}
  `
);

export const ValueWithAlert = styled(Value)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
