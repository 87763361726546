import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum SaleType {
  CourtOrderedNonForeclosureSale = 'CourtOrderedNonForeclosureSale',
  Other = 'Other',
  ProbateSale = 'ProbateSale',
  RelocationSale = 'RelocationSale',
  REOPostForeclosureSale = 'REOPostForeclosureSale',
  ShortSale = 'ShortSale',
  TrusteeJudicialForeclosureSale = 'TrusteeJudicialForeclosureSale',
  TrusteeNonJudicialForeclosureSale = 'TrusteeNonJudicialForeclosureSale',
  TypicallyMotivated = 'TypicallyMotivated',
}

export type SalesContract = MISMOEntity &
  Partial<{
    armsLengthIndicator: MISMOIndicator;
    buyerSellerSameAgentIndicator: MISMOIndicator;
    excessiveFinancingConcesssionsAmount: MISMOAmount;
    netSaleProceedsAmount: MISMOAmount;
    personalPropertyAmount: MISMOAmount;
    personalPropertyIncludedIndicator: MISMOIndicator;
    preForeclosureSaleClosingDate: MISMODate;
    preForeclosureSaleIndicator: MISMOIndicator;
    projectedClosingDate: MISMODate;
    proposedFinancingDescription: MISMOString;
    realEstateAgentCount: MISMOCount;
    realPropertyAmount: MISMOAmount;
    salesConcessionDescription: MISMOString;
    salesConcessionIndicator: MISMOIndicator;
    salesConditionsOfSaleDescription: MISMOString;
    salesContractAcceptedIndicator: MISMOIndicator;
    salesContractAmount: MISMOAmount;
    salesContractAnalysisDescription: MISMOString;
    salesContractDate: MISMODate;
    salesContractInvoiceRevewCommentDescription: MISMOString;
    salesContractInvoiceReviewedIndicator: MISMOIndicator;
    salesContractRetailerName: MISMOString;
    salesContractReviewCommentDescription: MISMOString;
    salesContractReviewDescription: MISMOString;
    salesContractReviewedIndicator: MISMOIndicator;
    salesContractTermsConsistentWithMarketIndicator: MISMOIndicator;
    saleType: SaleType;
    saleTypeOtherDescription: MISMOString;
    sellerIsOwnerIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
    totalSalesConcessionAmount: MISMOAmount;
    // salesConcessions: SalesConcession[];
    // salesContingencies: SalesContingency[];
  }>;
