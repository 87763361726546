import { REACT_APP_API_URL, REACT_APP_WS_URL } from '../config';
import { logout } from './authUtils';
import { getAccessToken, isTokenExpired } from './tokenUtils';

const NO_CONTENT_STATUS = 204;

type ServerResponseType<T> = {
  success: boolean;
  data: T | null;
  error?: string;
};

type RequestMethodType = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

type RequestOptionsType = {
  body?:
    | Blob
    | BufferSource
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | string
    | null;
  headers?: Record<string, string>;
  redirect?: RequestRedirect;
  credentials?: RequestCredentials;
  method?: RequestMethodType;
};

const createBlobUrl = (blob: Blob) => {
  return window.URL.createObjectURL(blob);
};

export const downloadUrl = (fileName: string, url: string) => {
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = fileName;

  anchor.click();
  anchor.remove();
};

export const downloadBlob = (fileName: string, blob: Blob) => {
  const url = createBlobUrl(blob);
  downloadUrl(fileName, url);
};

const getFileNameFromResponse = (response: Response) => {
  const contentDisposition = response.headers.get('Content-Disposition');
  const matches = contentDisposition?.match(/filename="(.*)"/);

  return matches?.[1];
};

export const makeApiUrl = (path: string, params?: URLSearchParams) => {
  const stringifiedParams = params?.toString();
  return `${REACT_APP_API_URL}/api/${path}${stringifiedParams ? `?${stringifiedParams}` : ''}`;
};

export const getSocketApiUrl = () => `${REACT_APP_WS_URL}?token=${getAccessToken()}`;

const request = async <T>(url: string, options: RequestOptionsType = {}) => {
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      /* eslint-disable-next-line no-console */
      console.error(response.statusText);
    }

    if (response.status === NO_CONTENT_STATUS) {
      return '' as unknown as T;
    }

    const data = (await response.json()) as ServerResponseType<T>;

    if (!data) {
      throw new Error('Unknown server Error. Please try again in a few moments.');
    }

    if (data?.error) {
      throw new Error(data.error);
    }

    return data?.data || undefined;
  } catch (error) {
    // TODO: log this error message somewhere
    throw new Error(error instanceof Error ? error.message : 'Unknown server Error');
  }
};

const jsonRequest = async <T>(url: string, options: RequestOptionsType = {}) => {
  return request<T>(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
  });
};

const blobRequest = async (url: string, options: RequestOptionsType = {}) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    /* eslint-disable-next-line no-console */
    console.error(response.statusText);
  }

  const blob = await response.blob();

  return createBlobUrl(blob);
};

const downloadRequest = async (url: string, options: RequestOptionsType = {}) => {
  const response = await fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
  });

  if (!response.ok) {
    /* eslint-disable-next-line no-console */
    console.error(response.statusText);
  }

  const blob = await response.blob();
  const name = getFileNameFromResponse(response);

  if (!blob || !name) {
    throw new Error('Unknown server Error. Please try again in a few moments.');
  }

  downloadBlob(name, blob);
};

export const getRequest = <T>(url: string, options: RequestOptionsType = {}) => {
  return jsonRequest<T>(url, { ...options, method: 'GET' });
};

export const getBlobRequest = (url: string, options: RequestOptionsType = {}) => {
  return blobRequest(url, { ...options, method: 'GET' });
};

export const postRequest = <T>(url: string, options: RequestOptionsType = {}) => {
  return jsonRequest<T>(url, { ...options, method: 'POST' });
};

export const postDownloadRequest = (url: string, options: RequestOptionsType = {}) => {
  return downloadRequest(url, { ...options, method: 'POST' });
};

export const postFormDataRequest = <T>(url: string, options: RequestOptionsType = {}) => {
  return request<T>(url, { ...options, method: 'POST' });
};

export const putRequest = <T>(url: string, options: RequestOptionsType = {}) => {
  return jsonRequest<T>(url, { ...options, method: 'PUT' });
};

export const patchRequest = <T>(url: string, options: RequestOptionsType = {}) => {
  return jsonRequest<T>(url, { ...options, method: 'PATCH' });
};

export const deleteRequest = <T>(url: string, options: RequestOptionsType = {}) => {
  return jsonRequest<T>(url, { ...options, method: 'DELETE' });
};

const getAuthHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

export const requestWithAuth = async <T>(
  request: (url: string, options?: RequestOptionsType) => Promise<T | undefined>,
  url: string,
  onTokenInvalid: () => void,
  options: RequestOptionsType = {}
) => {
  const token = getAccessToken();

  if (!token || isTokenExpired(token)) {
    onTokenInvalid();
    return Promise.resolve(undefined);
  }

  const requestOptions: RequestOptionsType = {
    headers: {
      ...getAuthHeaders(token),
      ...options.headers,
    },
    ...options,
  };

  return request(url, requestOptions);
};

export const postRequestWithAuth = <T>(url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth<T>(postRequest, url, logout, options);
};

export const getRequestWithAuth = <T>(url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth<T>(getRequest, url, logout, options);
};

export const getBlobRequestWithAuth = (url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth(getBlobRequest, url, logout, options);
};

export const postDownloadRequestWithAuth = (url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth(postDownloadRequest, url, logout, options);
};

export const postFormDataRequestWithAuth = <T>(url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth<T>(postFormDataRequest, url, logout, options);
};

export const putRequestWithAuth = <T>(url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth<T>(putRequest, url, logout, options);
};

export const patchRequestWithAuth = <T>(url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth<T>(patchRequest, url, logout, options);
};

export const deleteRequestWithAuth = <T>(url: string, options: RequestOptionsType = {}) => {
  return requestWithAuth<T>(deleteRequest, url, logout, options);
};
