import React from 'react';

import { formatDefaultValue, formatMonetary, formatPercentage } from '../../utils/formatUtils';
import { BreakdownTileContent } from './BreakdownTile';

export type DelinquencyFinancialsProps = {
  upbAmount?: number;
  pAndIArrearageAmount?: number;
  weightedAverageCoupon?: number;
  weightedAverageLTV?: number;
  judicialCount?: number;
  nonJudicialCount?: number;
};

export const DelinquencyFinancials = (props: DelinquencyFinancialsProps) => {
  const {
    judicialCount,
    nonJudicialCount,
    pAndIArrearageAmount,
    upbAmount,
    weightedAverageCoupon,
    weightedAverageLTV,
  } = props;

  return (
    <React.Fragment>
      <BreakdownTileContent label="UPB" value={formatMonetary(upbAmount)} />
      <BreakdownTileContent label="P+I arrearage" value={formatMonetary(pAndIArrearageAmount)} />
      <BreakdownTileContent label="WAC" value={formatPercentage(weightedAverageCoupon)} />
      <BreakdownTileContent label="Wtd Avg LTV" value={formatPercentage(weightedAverageLTV)} />
      <BreakdownTileContent label="Judicial" value={formatDefaultValue(judicialCount)} />
      <BreakdownTileContent label="Non-Judicial" value={formatDefaultValue(nonJudicialCount)} />
    </React.Fragment>
  );
};
