import React from 'react';

export const DuplicateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.23529 21C5.90588 21 5.61765 20.865 5.37059 20.595C5.12353 20.325 5 20.01 5 19.65V6.0825H6.23529V19.65H15.9941V21H6.23529ZM8.70588 18.3C8.37647 18.3 8.08824 18.165 7.84118 17.895C7.59412 17.625 7.47059 17.31 7.47059 16.95V4.35C7.47059 3.99 7.59412 3.675 7.84118 3.405C8.08824 3.135 8.37647 3 8.70588 3H17.7647C18.0941 3 18.3824 3.135 18.6294 3.405C18.8765 3.675 19 3.99 19 4.35V16.95C19 17.31 18.8765 17.625 18.6294 17.895C18.3824 18.165 18.0941 18.3 17.7647 18.3H8.70588ZM8.70588 16.95H17.7647V4.35H8.70588V16.95Z"
        fill="currentColor"
      />
    </svg>
  );
};
