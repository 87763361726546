import { Address } from './address';
import { Individual } from './individual';
import { LegalEntity } from './legalEntity';
import { MISMOEntity } from './types';

export enum FeePaidToType {
  Broker = 'Broker',
  BrokerAffiliate = 'BrokerAffiliate',
  Investor = 'Investor',
  Lender = 'Lender',
  LenderAffiliate = 'LenderAffiliate',
  Other = 'Other',
  ThirdPartyProvider = 'ThirdPartyProvider',
}

export type PaidTo = MISMOEntity &
  Partial<{
    address: Address;
  }> &
  (Individual | LegalEntity);
