import styled, { css } from 'styled-components/macro';

import { Badge } from '../../components/Badge';
import { NudeButton } from '../../components/Button';

export const Search = styled.div``;

export const Wrapper = styled.div`
  width: 100%;
`;

export const SelectLoanButton = styled(NudeButton)``;

export const Overlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    inset: 0;
    top: 64px;
    background: ${theme.colors.blue70};
    display: flex;
    align-items: flex-start;
    justify-content: center;
  `
);

export const SearchResultsWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: grid;
    grid-template-columns: 236px 790px;
    background: ${theme.colors.background01};
    border-color: ${theme.colors.white05};
    border-width: 0 1px 1px;
    border-style: solid;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 5;
    height: 436px;
  `
);

export const SearchResults = styled.div`
  height: 436px;
  overflow: auto;
`;

export const NoLoansFound = styled.p(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02Tight}

    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: ${theme.colors.white70};
    padding-left: 12px;
  `
);

export const ResultMain = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 12px;
  padding: 0 16px 16px;
`;

export const ResultContent = styled.div``;

export const ResultDetail = styled.div`
  background: ${({ theme }) => theme.colors.deprecatedPrimary01};
  border-bottom-right-radius: 8px;
`;

export const ResultHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResultTitle = styled.h2(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium03}

    color: ${theme.colors.white};
    padding: 12px 16px;
  `
);

export const ResultImage = styled.img`
  width: 446px;
  height: 251px;
  object-fit: fill;
  margin-top: 32px;
  place-self: start end;
  border-radius: 4px;
`;

export const ResultStatus = styled.div(
  ({ theme }) => css`
    ${theme.typography.headings.headingRegular03}

    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 12px 16px;
    color: ${theme.colors.white50};
  `
);

export const ResultSubtitle = styled.h3(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04}

    color: ${theme.colors.white};
    padding: 12px 0 8px 0;
  `
);

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li``;

export const ListItemButton = styled(NudeButton)<{ $isSelected: boolean }>`
  ${({ $isSelected, theme }) => css`
    display: block;
    padding: 12px;

    ${theme.typography.headings.headingMedium03}

    color: ${theme.colors.white};

    &:hover {
      background-color: ${theme.colors.deprecatedPrimary01};
      color: ${theme.colors.accent01};
    }
    ${$isSelected &&
    css`
      background-color: ${theme.colors.deprecatedPrimary01};
      color: ${theme.colors.accent01};
    `}
  `}
`;

export const SearchBadge = styled(Badge)`
  margin: 0 8px 0 16px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  padding: 4px 0;
`;

export const Key = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02Tight}
    color: ${theme.colors.white70};
  `
);

export const Value = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyMedium02Tight}
    color: ${theme.colors.white};
  `
);

export const ValueWithAlert = styled(Value)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
