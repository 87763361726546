import { DocumentType, UserCreation } from '@plus-platform/shared';

export enum QueryKeys {
  PORTFOLIO_LOANS = 'portfolio-loans',
  PORTFOLIO_LOANS_DOCUMENT_SUMMARY = 'portfolio-loans-document-summary',
  PORTFOLIO_SUMMARY = 'portfolio-summary',
  PORTFOLIO_METRICS = 'portfolio-metrics',
  PORTFOLIO_ANALYSIS_STATUS = 'portfolio-analysis-status',
  PORTFOLIO_LATEST_ANALYSIS = 'portfolio-latest-analysis',
  PORTFOLIO_BREAKDOWN = 'portfolio-breakdown',
  PORTFOLIO_BREAKDOWN_RATES = 'portfolio-breakdown-rates',
  PORTFOLIO_ANALYTICS = 'portfolio-analytics',
  PORTFOLIO_ANALYTICS_BY_ID = 'portfolio-analytics-by-id',
  PORTFOLIO_SETTINGS = 'portfolio-settings',
  PORTFOLIO_HOME_ANALYTICS = 'portfolio-home-analytics',
  PORTFOLIO_HOME_ANALYTIC = 'portfolio-home-analytic',
  PORTFOLIO_HOME_ANALYTIC_GROUP_SUMMARY = 'portfolio-home-analytic-group-summary',
  PORTFOLIO_HOME_ANALYTICS_PREVIEW = 'portfolio-home-analytics-preview',
  PORTFOLIO_LOAN_TYPES_BY_NOTE_RATE = 'portfolio-loan-types-by-note-rate',
  PORTFOLIO_LOAN_TYPES_BY_NOTE_RATE_DETAILS = 'portfolio-loan-types-by-note-rate-details',
  PORTFOLIO_LOAN_TYPES_BY_NOTE_RATE_LOANS = 'portfolio-loan-types-by-note-rate-loans',
  PORTFOLIO_DELINQUENT_LOAN_TYPES_BY_NOTE_RATE = 'portfolio-delinquent-loan-types-by-note-rate',
  PORTFOLIO_DELINQUENT_LOAN_TYPES_BY_NOTE_RATE_DETAILS = 'portfolio-delinquent-loan-types-by-note-rate-details',
  PORTFOLIO_DELINQUENT_LOAN_TYPES_BY_NOTE_RATE_LOANS = 'portfolio-delinquent-loan-types-by-note-rate-loans',
  USER_PERMISSIONS = 'user-permissions',
  USER_PROFILE = 'user-profile',
  LOAN_AMORTIZATION = 'loan-amortization',
  LOAN_ASSET_LEDGER = 'loan-asset-ledger',
  LOAN_EVENT_LOG = 'loan-event-log',
  LOAN_DETAILS = 'loan-details',
  LOAN_SUMMARY = 'loan-summary',
  LOAN_DOCUMENTS = 'loan-documents',
  LOAN_SEARCH = 'loan-search',
  LOAN_NOTES = 'loan-notes',
  GEODATA_PROPERTIES = 'geodata-properties',
  DOCUMENT_SEARCH = 'document-search',
  NEARBY_PROPERTIES = 'nearby-properties',
  PROPERTY_SCORES = 'property-scores',
  POOLS_SUMMARY = 'pools-summary',
  POOL = 'pool',
  POOL_LOANS = 'pool-loans',
  PORTFOLIO_PROPERTY = 'portfolio-property',
  COMPLETED_TRADE_REQUESTS = 'completed-trade-requests',
  TRADE_REQUESTS_SUMMARY = 'trade-requests-summary',
  TRADE_REQUESTS_INBOUND_SUMMARY = 'trade-requests-inbound-summary',
  TRADE_REQUESTS_OUTBOUND_SUMMARY = 'trade-requests-outbound-summary',
  TRADE_REQUEST_SUMMARY = 'trade-request-summary',
  TRADE_REQUEST_DATA = 'trade-request-data',
  TRADE_REQUEST_DATA_LOAN = 'trade-request-data-loan',
  TRADE_REQUEST_DATA_LOAN_DOCUMENTS = 'trade-request-data-loan-documents',
  TRADE_REQUEST_DATA_LOAN_ASSET_LEDGER = 'trade-request-data-loan-asset-ledger',
  TRADE_REQUEST_DATA_LOAN_EVENT_LOG = 'trade-request-data-loan-event-log',
  TRADE_REQUEST_DATA_LOAN_BORROWER_CREDIT_SCORES = 'trade-request-data-loan-borrower-credit-scores',
  TRADE_REQUEST_DATA_LOAN_BORROWER_VERIFICATIONS = 'trade-request-data-loan-borrower-verifications',
  TRADE_REQUEST_DATA_LOAN_AMORTIZATION = 'trade-request-data-loan-amortization',
  TRADE_REQUEST_DATA_LOAN_PROPERTY_SCORES = 'trade-request-data-loan-property-scores',
  TRADE_REQUEST_DATA_LOAN_PROPERTY_VALUATION = 'trade-request-data-loan-property-valuation',
  TRADE_REQUEST_DATA_NEARBY_PROPERTIES = 'trade-request-data-nearby-properties',
  TRADE_REQUEST_BY_POOL_ID = 'trade-request-by-pool-id',
  TRADE_REQUEST_INBOUND_QUOTES = 'trade-request-inbound-quotes',
  TRADE_REQUEST_OUTBOUND_QUOTES = 'trade-request-outbound-quotes',
  TRADE_REQUEST_QUOTE_CONFIRMATION_SUMMARY = 'trade-request-quote-confirmation-summary',
  TRADE_REQUEST_QUOTE_COUNTER_CONFIRMATION_SUMMARY = 'trade-request-quote-counter-confirmation-summary',
  TRADE_STIPULATION_PREVIEW = 'trade-stipulation-preview',
  TRADE_STIPULATION_DOCUMENT = 'trade-stipulation-document',
  TRADE_REQUEST_TRANSACTION_LOG = 'trade-request-transaction-log',
  TRADE_REQUEST_INBOUND_ACTION_HISTORY = 'trade-request-inbound-action-history',
  TRADE_REQUEST_OUTBOUND_ACTION_HISTORY = 'trade-request-outbound-action-history',
  TRADE_REQUEST_COUNTERPARTY_MESSAGES = 'trade-request-counterparty-messages',
  TRADE_REQUEST_MESSAGES = 'trade-request-messages',
  LOAN_CHAT_MESSAGES = 'loan-chat-messages',
  ORGANIZATIONS = 'organizations',
  CUSTODIANS = 'custodians',
  BORROWER_CREDIT_SCORES = 'borrower-credit-scores',
  BORROWER_VERIFICATIONS = 'borrower-verifications',
  EARTHQUAKES = 'earthquakes',
  PORTFOLIO_FILTERS = 'portfolio-filters',
  PORTFOLIO_FILTER = 'portfolio-filter',
  ZIP_CODE_DATA = 'zip-code-data',
  GREATSCHOOLS_NEARBY = 'greatschools-nearby',
  EV_CHARGING_STATIONS_NEARBY = 'ev-charging-stations-nearby',
  AIR_QUALITY_CURRENT = 'air-quality-current',
  LOS_LOANS = 'los-loans',
  FIXED_BROADBAND = 'fixed-broadband',
  TRADING_CONTACTS = 'trading-contacts',
  USER_NOTIFICATIONS = 'user-notifications',
  USER_NOTIFICATIONS_COUNT = 'user-notifications-count',
  ROLES = 'roles',
  PROPERTY_VALUATION_HISTORY = 'property-valuations',
  ORGANIZATION_MEMBERS = 'organization-members',
  MARKET_RATES = 'market-rates',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  SUBMISSIONS = 'submissions',
  SUBMISSIONS_SUMMARY = 'submissions-summary',
  SUBMISSIONS_DOCUMENTS = 'submissions-documents',
  SUBMISSION_DOCUMENT_IMAGE = 'submission-document-image',
  SUBMISSION_DOCUMENT_DETAILS = 'submission-document-details',
  SUBMISSIONS_HOLDING_TANK = 'submissions-holding-tank',
  POOLS = 'pools',
  ANALYTICS_SETTINGS = 'analytics-settings',
  ANALYTIC_SETTINGS = 'analytic-settings',
}

export enum MutationKeys {
  LOGIN = 'login',
  CREATE_ACCOUNT = 'create-account',
}

export type LoginMutation = {
  email: string;
  password: string;
};

export type CreateAccountMutation = {
  firstName: UserCreation['firstName'];
  lastName: UserCreation['lastName'];
  password: UserCreation['password'];
  token: string;
};

export type SendLoanChatMessageMutation = {
  message: string;
};

export type AddLoanDocumentMutation = {
  loanNumber: string;
  file: File;
  type: DocumentType;
};

export type ChangePasswordMutation = {
  currentPassword: string;
  newPassword: string;
};
