import { TradeRequestWithSummary } from '@plus-platform/shared';
import styled, { css } from 'styled-components/macro';

import { Key, Pair, PairProps, Value } from '../../components/KeyValue';
import {
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../../utils/formatUtils';
import {
  formatNameWithInitial,
  formatTradeCreatedAtDate,
  formatTradeCreatedAtTime,
} from '../tradeRequestsUtils';

export const NoTrades = styled.div(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02}
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.textPrimary03};
  `
);

type TradeRequestProps = PairProps & {
  tradeRequest: TradeRequestWithSummary;
};

export const TradeTicketName = ({ tradeRequest, ...rest }: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Ticket ID</Key>
    <Value>{tradeRequest.pool.name}</Value>
  </Pair>
);

export const TradeTicketSendDate = ({ tradeRequest, ...rest }: TradeRequestProps & PairProps) => {
  return (
    <Pair $isMultiLine {...rest}>
      <Key>RFQ Sent</Key>
      <Value>
        {formatTradeCreatedAtDate(new Date(tradeRequest.createdAt))} @{' '}
        {formatTradeCreatedAtTime(new Date(tradeRequest.createdAt))}
      </Value>
    </Pair>
  );
};

export const TradeTicketSender = ({ tradeRequest, ...rest }: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Sender</Key>
    <Value>{tradeRequest.user.organization.tradingName}</Value>
  </Pair>
);

export const TradeTicketSeller = ({ tradeRequest, ...rest }: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Seller</Key>
    <Value>{formatNameWithInitial(tradeRequest.user.lastName, tradeRequest.user?.firstName)}</Value>
  </Pair>
);

export const TradeTicketLoansCount = ({ tradeRequest, ...rest }: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Loan count</Key>
    <Value>{formatNumber(tradeRequest.pool.summary.loansCount)}</Value>
  </Pair>
);

export const TradeTicketLoansUPB = ({ tradeRequest, ...rest }: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>UPB</Key>
    <Value>{formatMonetary(tradeRequest.pool.summary.loansUPBAmount, 0)}</Value>
  </Pair>
);

export const TradeTicketLoansCoupon = ({
  tradeRequest,
  ...rest
}: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Coupon (WAC)</Key>
    <Value>{formatPercentage(tradeRequest.pool.summary.loansWeightedAverageCoupon)}</Value>
  </Pair>
);

export const TradeTicketLoansJudicialSplit = ({
  tradeRequest,
  ...rest
}: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Judicial / Non-Judicial</Key>
    <Value>
      {formatDefaultValue(tradeRequest.pool.summary.judicialCount)} /{' '}
      {formatDefaultValue(tradeRequest.pool.summary.nonJudicialCount)}
    </Value>
  </Pair>
);

export const TradeTicketLoansRemittance = ({
  tradeRequest,
  ...rest
}: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Remittance</Key>
    <Value>{formatMonetary(tradeRequest.pool.summary.loansRemittanceAmount, 0)}</Value>
  </Pair>
);

export const TradeTicketLoansWeightedAverageLTV = ({
  tradeRequest,
  ...rest
}: TradeRequestProps & PairProps) => (
  <Pair {...rest}>
    <Key>Wtd Avg LTV</Key>
    <Value>{formatPercentage(tradeRequest.pool.summary.loansWeightedAverageLTV)}</Value>
  </Pair>
);
