import styled from 'styled-components/macro';

export const Toggles = styled.ul`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: end;
  margin: 0;
  list-style: none;
  padding: 3px 0 6px 0;
`;

export const Toggle = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.white70};

  ${({ theme }) => theme.typography.bodyText.bodyRegular03Tight}
`;

export const Emphasis = styled.em`
  font-style: normal;
  color: ${({ theme }) => theme.colors.white};
`;
