import styled, { css } from 'styled-components/macro';

import { Popup } from '../components/Popup';

export const PopupComponent = styled(Popup)`
  width: 100%;
  max-width: 600px !important;
`;

export const Textarea = styled.textarea`
  ${({ theme }) => css`
    width: 100%;
    display: block;
    margin: 0;
    padding: 14px 14px;
    background: none;
    border: 1px solid ${theme.colors.white05};
    border-radius: 6px;
    color: ${theme.colors.white};
    resize: vertical;

    &:hover,
    &:focus,
    &:active {
      outline: 1px solid ${theme.colors.white05};
    }

    &::placeholder {
      color: ${theme.colors.white05};
    }
  `}
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.statusError};
  font-size: 14px;
  margin: 0;
  padding: 8px 0 16px;
`;
