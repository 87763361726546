import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import * as Base from '../components/Table';

export const columns = css`
  grid-template-columns: 110px 50px 70px 120px 130px 6fr 2fr;
`;

export const HeaderRow = styled(Base.HeaderRow)`
  ${columns};
`;

export const Row = styled(Base.Row)`
  ${columns};
`;

export const RowWrapper = styled.div<{ $height: number; $offsetY: number }>`
  ${({ $height, $offsetY }) => css`
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 16px;
    right: 0;
    height: ${$height}px;
    transform: translateY(${$offsetY}px);
  `}
`;

export const MenusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const MenuWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: 18px;
    padding-left: 18px;
    border-left: 1px solid ${theme.colors.white10};
  `}
`;

export const RowActionsMenu = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.selectOptions};
    box-shadow: ${theme.shadows.menuShadow};
  `}
`;

export const RowActionsMenuItem = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 14px;
    border: 0;
    outline: 0;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    color: ${theme.colors.white};
    width: 100%;
    padding: 6px 14px 6px 14px;
    text-align: left;
    font-size: 15px;
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 400;
    letter-spacing: 0.02em;

    svg {
      width: 24px;
      color: ${theme.colors.menuGrey};
    }

    &:hover {
      background-color: ${theme.colors.greenLight};
      color: ${theme.colors.statusBlack};
      font-weight: 500;

      svg {
        color: inherit;
      }
    }

    &:disabled {
      pointer-events: none;
      color: ${transparentize(0.3, theme.colors.white)};
    }
  `}
`;
