import { MISMOAmount, MISMOEntity, MISMOString } from '../types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum FloorCoveringType {
  Carpet = 'Carpet',
  CeramicTile = 'CeramicTile',
  Hardwood = 'Hardwood',
  Other = 'Other',
  Vinyl = 'Vinyl',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type FloorCovering = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    floorCoveringDescription: MISMOString;
    floorCoveringType: FloorCoveringType;
    floorCoveringTypeOtherDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
  }>;
