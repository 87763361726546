import { LoanAmortizationData, ScheduleLine } from '@plus-platform/shared';
import debounce from 'lodash/debounce';
import mean from 'lodash/mean';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTheme } from 'styled-components/macro';
import {
  VictoryArea,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryStack,
} from 'victory';
import { DomainTuple } from 'victory-core';

import { plusChartTheme } from '../../styles/chart';
import { formatMonetary } from '../../utils/formatUtils';

type AmortizationChartProps = {
  allowDrag?: boolean;
  selectedScheduleLine: ScheduleLine;
  setSelectedScheduleLine: Dispatch<SetStateAction<ScheduleLine | undefined>>;
  width?: number;
  height?: number;
} & Pick<LoanAmortizationData, 'amortizationSchedule'>;

const BRUSH_WIDTH = 12;

const getBrushDomain = (
  amortizationSchedule: ScheduleLine[],
  selectedScheduleLine: ScheduleLine
): DomainTuple => {
  if (!selectedScheduleLine || !amortizationSchedule) {
    return [1, BRUSH_WIDTH];
  }

  if (selectedScheduleLine.paymentNumber < BRUSH_WIDTH / 2) {
    return [1, BRUSH_WIDTH];
  }

  const lastSchedule = amortizationSchedule[amortizationSchedule.length - 1];

  if (selectedScheduleLine.paymentNumber >= lastSchedule.paymentNumber - BRUSH_WIDTH / 2) {
    return [lastSchedule.paymentNumber - BRUSH_WIDTH, lastSchedule.paymentNumber];
  }

  return [
    selectedScheduleLine.paymentNumber - BRUSH_WIDTH / 2,
    selectedScheduleLine.paymentNumber + BRUSH_WIDTH / 2,
  ];
};

export const AmortizationChart = ({
  allowDrag = true,
  amortizationSchedule,
  height = 160,
  selectedScheduleLine,
  setSelectedScheduleLine,
  width = 450,
}: AmortizationChartProps) => {
  const theme = useTheme();

  const debouncedOnBrushDomainChangeEnd = useMemo(
    () =>
      debounce(({ x }) => {
        setSelectedScheduleLine(amortizationSchedule?.[Math.round(mean(x))]);
      }, 10),
    [amortizationSchedule, setSelectedScheduleLine]
  );

  return (
    <VictoryChart
      containerComponent={
        <VictoryBrushContainer
          allowDrag={allowDrag}
          allowDraw={false}
          allowResize={false}
          brushDimension="x"
          brushDomain={{
            x: getBrushDomain(amortizationSchedule, selectedScheduleLine),
          }}
          brushStyle={{
            fill: theme.colors.white,
            stroke: theme.colors.white,
            fillOpacity: 0.1,
          }}
          onBrushDomainChangeEnd={debouncedOnBrushDomainChangeEnd}
        />
      }
      height={height}
      padding={{ top: 0, right: 0, bottom: 30, left: 50 }}
      theme={plusChartTheme}
      width={width}
    >
      <VictoryStack colorScale={[theme.colors.statusBlue, theme.colors.statusGreen]}>
        <VictoryArea data={amortizationSchedule} x="paymentNumber" y="interestPayment" />
        <VictoryArea data={amortizationSchedule} x="paymentNumber" y="principalPayment" />
      </VictoryStack>
      <VictoryAxis
        label="Years"
        style={{
          axisLabel: {
            padding: 18,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            fill: theme.colors.white70,
          },
        }}
        tickFormat={(x: number) => Math.round(x / 12)}
      />
      <VictoryAxis
        dependentAxis
        label="Monthly Payments"
        style={{
          axisLabel: {
            padding: 36,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            fill: theme.colors.white70,
          },
        }}
        tickFormat={(y: number) => formatMonetary(y, 0)}
      />
    </VictoryChart>
  );
};
