import { PortfolioBreakdownRateType } from '@plus-platform/shared';
import { useInfiniteQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const usePortfolioLoanTypesByNotRateLoansQuery = (
  rateType: PortfolioBreakdownRateType,
  noteRate: number
) =>
  useInfiniteQuery(
    [QueryKeys.PORTFOLIO_LOAN_TYPES_BY_NOTE_RATE_LOANS, rateType, noteRate],
    ({ pageParam = 0 }) => {
      return api.getPortfolioLoanTypesByNoteRateLoans(rateType, noteRate, pageParam);
    },
    {
      getNextPageParam: (lastPage) => lastPage?.pageNumber ?? 0,
    }
  );
