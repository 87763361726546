import {
  CounterpartyWithSummary,
  InboundTradeRequestWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';
import { useHistory } from 'react-router-dom';

export const useTradeRequestInboundNavigation = () => {
  const history = useHistory();

  return {
    goToExpressionOfInterest: (
      id: InboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/inbound/${id}/${counterpartyId}/expressions-of-interest`),
    goToDirectMessages: (
      id: InboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/inbound/${id}/${counterpartyId}/messages`),
    goToDataRequest: (
      id: InboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/inbound/${id}/${counterpartyId}/data-requests`),
    goToDataView: (
      id: InboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/inbound/${id}/${counterpartyId}/data-view`),
    goToQuotes: (
      id: InboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/inbound/${id}/${counterpartyId}/quotes`),
    goToCompletedTrades: () => history.push(`/trade-requests/completed`),
  };
};

export const useTradeRequestOutboundNavigation = () => {
  const history = useHistory();

  return {
    goToTransactionLog: () => history.push(`${history.location.pathname}/transaction-log`),
    goToExpressionOfInterest: (
      id: OutboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/outbound/${id}/${counterpartyId}/expressions-of-interest`),
    goToDirectMessages: (
      id: OutboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) =>
      history.push(`/trade-requests/outbound/${id}/${counterpartyId}/messages/${counterpartyId}`),
    goToDataRequest: (
      id: OutboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/outbound/${id}/${counterpartyId}/data-requests`),
    goToQuotes: (
      id: OutboundTradeRequestWithSummary['id'],
      counterpartyId: CounterpartyWithSummary['id']
    ) => history.push(`/trade-requests/outbound/${id}/${counterpartyId}/quotes`),
    goToCompletedTrades: () => history.push(`/trade-requests/completed`),
  };
};
