import { PropertyMedia } from '@plus-platform/shared';
import { useMemo, useState } from 'react';

import { ButtonIcon } from '../../../components/ButtonIcon';
import { Heading } from '../../../components/Heading';
import { CloseLineIcon } from '../../../components/icons';
import { ImageGallery } from '../../../components/ImageGallery';
import { ImageLightbox } from '../../../components/ImageLightbox';
import { ImageTile } from '../../../components/ImageTile';
import { FullWidthLoaderWrapper, Loader } from '../../../components/Loader';
import { Popup, PopupContent, PopupHeader } from '../../../components/Popup';
import { useLoanNearbyProperties } from '../../LoanData';
import * as Styles from './LoanDetailPhotoLibrary.styles';

const LoanDetailPhotoLibrary = () => {
  const [modalPhotoId, setModalPhotoId] = useState<number | undefined>(undefined);
  const { data: nearbyPropertiesData, isLoading } = useLoanNearbyProperties();

  const imageArray = useMemo<PropertyMedia[]>(
    () => nearbyPropertiesData?.properties?.map(({ media }) => media).flat() || [],
    [nearbyPropertiesData]
  );

  function showModal(index: number) {
    return function () {
      setModalPhotoId(index);
    };
  }

  function closeModal() {
    setModalPhotoId(undefined);
  }

  return (
    <>
      <Styles.PhotosSectionWrapper data-testid="LoanDetail_Photos">
        <Heading $size="medium">Photos ({imageArray.length})</Heading>

        <Styles.GalleryWrapper>
          {isLoading && (
            <FullWidthLoaderWrapper>
              <Loader />
            </FullWidthLoaderWrapper>
          )}

          {/* TODO: When API starts sending real property photos, make sure that for anonymised version they are replaced with generic placeholders */}
          {!isLoading && (
            <>
              {imageArray?.length > 0 ? (
                <ImageGallery>
                  {imageArray &&
                    imageArray.length &&
                    imageArray.map((photo, i) => (
                      <ImageTile
                        key={photo.id + i}
                        src={photo.thumbnailUrl}
                        title={photo.description}
                        callback={showModal(i)}
                        width={220}
                        height={180}
                      />
                    ))}
                </ImageGallery>
              ) : (
                <Styles.NoImages>No images found</Styles.NoImages>
              )}
            </>
          )}
        </Styles.GalleryWrapper>
      </Styles.PhotosSectionWrapper>
      {!isNaN(modalPhotoId as number) && (
        <Popup hasOverlay width="calc(100% - 64px)" maxWidth="140vh">
          <PopupHeader title="Photos">
            <ButtonIcon onClick={closeModal}>
              <CloseLineIcon />
            </ButtonIcon>
          </PopupHeader>
          <PopupContent>
            <ImageLightbox images={imageArray} setImageIndex={modalPhotoId as number} />
          </PopupContent>
        </Popup>
      )}
    </>
  );
};

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailPhotoLibrary;
