import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMONumeric,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum AmenityType {
  Balcony = 'Balcony',
  Deck = 'Deck',
  Fence = 'Fence',
  Fireplace = 'Fireplace',
  HomeTheater = 'HomeTheater',
  Intercom = 'Intercom',
  JettedTub = 'JettedTub',
  Other = 'Other',
  Patio = 'Patio',
  Pool = 'Pool',
  Porch = 'Porch',
  SecuritySystem = 'SecuritySystem',
  Spa = 'Spa',
  WoodStove = 'WoodStove',
}

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type Amenity = MISMOEntity &
  Partial<{
    amenityCount: MISMOCount;
    amenityDescription: MISMOString;
    amenityType: AmenityType;
    amenityTypeOtherDescription: MISMOString;
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
    squareFeetNumber: MISMONumeric;
  }>;
