import { HTMLAttributes, useEffect, useState } from 'react';

import { WarningIcon } from './icons';
import * as Styles from './ImageTile.styles';
import { Loader } from './Loader';

export type ImageTileProps = HTMLAttributes<HTMLDivElement> & {
  src: string;
  alt?: string;
  height?: number;
  width?: number;
  callback?: () => void;
  variant?: 'default' | 'fit-container';
};

export const ImageTile = ({
  alt = '',
  callback,
  children,
  height = 180,
  src,
  variant,
  width = 180,
  ...rest
}: ImageTileProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasLoaded(false);
    setHasError(false);
  }, [src, setHasLoaded, setHasError]);

  return (
    <Styles.Wrapper
      $hasCursorPointer={!!callback}
      $height={height}
      $width={width}
      $variant={variant}
      {...rest}
      onClick={callback}
    >
      {hasError && (
        <Styles.Error>
          <WarningIcon />
          <span>Not found</span>
        </Styles.Error>
      )}
      {!hasError && (
        <>
          <Styles.Image
            src={src}
            alt={alt}
            onLoad={() => setHasLoaded(true)}
            onError={() => setHasError(true)}
            $hasLoaded={hasLoaded}
          />
          {children && <Styles.Label>{children}</Styles.Label>}
          <Styles.Loader $hasLoaded={hasLoaded}>{!hasLoaded && <Loader size={24} />}</Styles.Loader>
        </>
      )}
    </Styles.Wrapper>
  );
};
