import 'react-range-slider-input/dist/style.css';

import { useRef } from 'react';
import { useTheme } from 'styled-components';
import { VictoryBar, VictoryChart } from 'victory';

import { ChevronRightIcon } from '../../components/icons';
import { plusChartTheme } from '../../styles/deprecatedChart';
import { MIN_SLIDER_BOX_LENGTH } from './breakdownUtils';
import * as Styles from './GraphSlider.styles';
import { GraphData } from './types';

type RatesGraphSliderProps = {
  graphData: GraphData[];
  graphIndexes: [number, number];
  onChange: (params: { startIndex: number; endIndex: number }) => void;
};

const SliderNavigationIcon = () => <ChevronRightIcon height={20} />;

export const RatesGraphSlider = ({ graphData, graphIndexes, onChange }: RatesGraphSliderProps) => {
  const theme = useTheme();

  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const maxSliderValue = graphData.length;

  const axisStyle = {
    grid: { stroke: 'transparent', fill: 'transparent' },
    axis: { stroke: 'transparent', fill: 'transparent' },
    tickLabels: {
      fontSize: 1,
      fill: 'transparent',
    },
  };

  // NOTE :: below func is for when the arrows are used by holding the mouse down
  // so we use interval to keep updating the sliderValue based on direction (the arrow that's clicked)
  const updateSliderByNavigation = (direction: 'right' | 'left') => {
    if (direction === 'left' && graphIndexes[0] > 0) {
      timer.current = setInterval(() => {
        const startSliderValue = graphIndexes[0] - 1;
        const endSliderValue = graphIndexes[1] - 1;

        const newStartIndex = startSliderValue >= 0 ? startSliderValue : graphIndexes[0];
        const newEndIndex = startSliderValue >= 0 ? endSliderValue : graphIndexes[1];

        onChange({ startIndex: newStartIndex, endIndex: newEndIndex });
      }, 10);
    }

    if (direction === 'right' && graphIndexes[1] < maxSliderValue) {
      timer.current = setInterval(() => {
        const startSliderValue = graphIndexes[0] + 1;
        const endSliderValue = graphIndexes[1] + 1;

        const newStartIndex = endSliderValue <= maxSliderValue ? startSliderValue : graphIndexes[0];
        const newEndIndex = endSliderValue <= maxSliderValue ? endSliderValue : graphIndexes[1];

        onChange({ startIndex: newStartIndex, endIndex: newEndIndex });
      }, 20);
    }
  };

  const timeoutClear = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  return (
    <Styles.GraphSliderWrapper>
      <Styles.SliderNavigation
        onMouseDown={() => updateSliderByNavigation('left')}
        onMouseUp={() => timeoutClear()}
        onMouseLeave={() => timeoutClear()}
      >
        <SliderNavigationIcon />
      </Styles.SliderNavigation>
      <VictoryChart
        theme={{
          ...plusChartTheme,
          axis: {
            ...plusChartTheme.axis,
            style: axisStyle,
          },
          dependentAxis: {
            ...plusChartTheme.dependentAxis,
            style: axisStyle,
          },
        }}
        height={100}
        width={2500}
        padding={{ top: 0, bottom: 20, left: 120, right: 120 }}
        domain={{
          y: [
            Math.floor(Math.min(...graphData.map((data) => data.rate))) - 1,
            Math.ceil(Math.max(...graphData.map((data) => data.rate))),
          ],
        }}
      >
        <VictoryBar
          alignment="start"
          x="label"
          y="rate"
          style={{
            data: {
              fill: theme.colors.white,
              fillOpacity: '50%',
              stroke: 'none',
            },
          }}
          data={graphData.map((data) => ({
            date: data.xValue,
            rate: data.rate,
          }))}
        />
      </VictoryChart>
      <Styles.SliderRangeWrapper>
        <Styles.SliderComponent
          step={0.2}
          min={0}
          max={maxSliderValue}
          value={graphIndexes}
          onInput={(values: number[]) => {
            if (values[1] - values[0] > MIN_SLIDER_BOX_LENGTH) {
              onChange({ startIndex: values[0], endIndex: values[1] });
            }
          }}
        />
      </Styles.SliderRangeWrapper>
      <Styles.SliderNavigation
        alignment="right"
        onMouseDown={() => updateSliderByNavigation('right')}
        onMouseUp={() => timeoutClear()}
        onMouseLeave={() => timeoutClear()}
      >
        <SliderNavigationIcon />
      </Styles.SliderNavigation>
    </Styles.GraphSliderWrapper>
  );
};
