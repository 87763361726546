export enum NotificationsAttributeType {
  ATTRIBUTE_NOTIFICATION = 'ATTRIBUTE_NOTIFICATION',
}

export enum NotificationType {
  IMPORTED_ENCOMPASS_LOAN = 'IMPORTED_ENCOMPASS_LOAN',
  SUBMITTED_LOAN_DOCUMENTS_PROCESSING = 'SUBMITTED_LOAN_DOCUMENTS_PROCESSING',
  SUBMISSION_PROCESSING = 'SUBMISSION_PROCESSING',
  SUBMISSION_COMPLETE = 'SUBMISSION_COMPLETE',
  SUBMITTED_LOAN_DOCUMENTS_READY_FOR_REVIEW = 'SUBMITTED_LOAN_DOCUMENTS_READY_FOR_REVIEW',
  TRADE_SELLER_INVITED_COUNTERPARTY = 'TRADE_SELLER_INVITED_COUNTERPARTY',
  TRADE_COUNTERPARTY_EXPRESSED_INTEREST = 'TRADE_COUNTERPARTY_EXPRESSED_INTEREST',
  TRADE_SELLER_ACCEPTED_STIPULATIONS = 'TRADE_SELLER_ACCEPTED_STIPULATIONS',
  TRADE_SELLER_DECLINED_STIPULATIONS = 'TRADE_SELLER_DECLINED_STIPULATIONS',
  TRADE_COUNTERPARTY_REQUESTED_DATA = 'TRADE_COUNTERPARTY_REQUESTED_DATA',
  TRADE_SELLER_ACCEPTED_DATA_REQUEST = 'TRADE_SELLER_ACCEPTED_DATA_REQUEST',
  TRADE_SELLER_DECLINED_DATA_REQUEST = 'TRADE_SELLER_DECLINED_DATA_REQUEST',
  TRADE_COUNTERPARTY_CREATED_QUOTE = 'TRADE_COUNTERPARTY_CREATED_QUOTE',
  TRADE_SELLER_ACCEPTED_QUOTE = 'TRADE_SELLER_ACCEPTED_QUOTE',
  TRADE_SELLER_REJECTED_QUOTE = 'TRADE_SELLER_REJECTED_QUOTE',
  TRADE_SELLER_CREATED_QUOTE_COUNTER = 'TRADE_SELLER_CREATED_QUOTE_COUNTER',
  TRADE_COUNTERPARTY_CREATED_QUOTE_COUNTER = 'TRADE_COUNTERPARTY_CREATED_QUOTE_COUNTER',
  TRADE_SELLER_ACCEPTED_QUOTE_COUNTER = 'TRADE_SELLER_ACCEPTED_QUOTE_COUNTER',
  TRADE_COUNTERPARTY_ACCEPTED_QUOTE_COUNTER = 'TRADE_COUNTERPARTY_ACCEPTED_QUOTE_COUNTER',
  TRADE_SELLER_SENT_DIRECT_MESSAGE = 'TRADE_SELLER_SENT_DIRECT_MESSAGE',
  TRADE_COUNTERPARTY_SENT_DIRECT_MESSAGE = 'TRADE_COUNTERPARTY_SENT_DIRECT_MESSAGE',
}

export type NotificationDto = {
  id: number;
  type: NotificationType;
  message: string;
  metadata: Record<string, string | number> | null;
  actionUrl: string;
  read: boolean;
  seen: boolean;
  createdAt: string;
};

export type GetNotificationsForUserResponse = {
  pagination: {
    offset: number;
    limit: number;
  };
  items: NotificationDto[];
};

export type GetNotificationsCountForUserResponse = {
  unseenCount: number;
  unreadCount: number;
};
