import { TradeCounterparty, TradeRequest } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

type DeclineTradeRequestExpressionOfInterestMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId: TradeCounterparty['id'];
};

const declineTradeRequestExpressionOfInterest = (
  data: DeclineTradeRequestExpressionOfInterestMutation
) => {
  const { tradeCounterpartyId, tradeRequestId } = data;
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/counterparties/${tradeCounterpartyId}/decline-expressed-interest`
  );

  return postRequestWithAuth(url);
};

export const useDeclineTradeRequestExpressionOfInterestMutation = () => {
  return useMutation(declineTradeRequestExpressionOfInterest);
};
