import {
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMONumeric,
  MISMOString,
  MISMOYear,
} from './types';

enum ManufacturedHomeConditionDescriptionType {
  New = 'New',
  USed = 'Used',
}

enum ManufacturedHomeConstructionQualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Poor = 'Poor',
}

export enum ManufacturedHomeWidthType {
  MultiWide = 'MultiWide',
  SingleWide = 'SingleWide',
}

export type ManufacturedHome = MISMOEntity &
  Partial<{
    lengthFeetNumber: MISMONumeric;
    manufacturedHomeAttachedToFoundationDescription: MISMOString;
    manufacturedHomeAttachedToFoundationIndicator: MISMOIndicator;
    manufacturedHomeConditionDescriptionType: ManufacturedHomeConditionDescriptionType;
    manufacturedHomeConnectedToUtilitiesIndicator: MISMOIndicator;
    manufacturedHomeConnectionToUtilitiesDescription: MISMOString;
    manufacturedHomeConstructionQualityRatingType: ManufacturedHomeConstructionQualityRatingType;
    manufacturedHomeConstructionQualitySourceDescription: MISMOString;
    manufacturedHomeDeckDescription: MISMOString;
    manufacturedHomeHUDDataPlateAttachedIndicator: MISMOIndicator;
    manufacturedHomeHUDDataPlateDataSourceDescription: MISMOString;
    manufacturedHomeHUDDataPlateIdentifier: MISMOIdentifier;
    manufacturedHomeInstalledDate: MISMODate;
    manufacturedHomeInstallerName: MISMOString;
    manufacturedHomeInteriorSpaceAcceptabilityToMarketDescription: MISMOString;
    manufacturedHomeInteriorSpaceAcceptableToMarketIndicator: MISMOIndicator;
    manufacturedHomeInvoiceReviewDescription: MISMOString;
    manufacturedHomeInvoiceReviewedIndicator: MISMOIndicator;
    manufacturedHomeManufacturerName: MISMOString;
    manufacturedHomeManufactureYear: MISMOYear;
    manufacturedHomeMeetsHUDRequirementsForLocationDescription: MISMOString;
    manufacturedHomeMeetsHUDRequirementsForLocationIndicator: MISMOIndicator;
    manufacturedHomeModelIdentifier: MISMOIdentifier;
    manufacturedHomeModelYear: MISMOYear;
    manufacturedHomeModificationsDescription: MISMOString;
    manufacturedHomePorchDescription: MISMOString;
    manufacturedHomeRetailerName: MISMOString;
    manufacturedHomeSectionCount: MISMOCount;
    manufacturedHomeSerialNumberIdentifier: MISMOIdentifier;
    manufacturedHomeTipOutDescription: MISMOString;
    manufacturedHomeWaniganDescription: MISMOString;
    manufacturedHomeWheelsDescription: MISMOString;
    manufacturedHomeWheelsRemovedIndicator: MISMOIndicator;
    manufacturedHomeWidthType: ManufacturedHomeWidthType;
    mobileHomeParkIndicator: MISMOIndicator;
    squareFeetNumber: MISMONumeric;
    widthFeetNumber: MISMONumeric;
    // manufacturedHomeIdentification: ManufacturedHomeIdentification;
    // manufacturedHomeSections: ManufacturedHomeSection[];
  }>;
