import styled, { css } from 'styled-components/macro';

import { BodyText as _BodyText } from '../components/BodyText.styles';

export const PopupTitleWrapper = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeadingWrapper = styled.div`
  padding: 12px;
`;

export const Section = styled.section`
  padding: 0 12px 12px 12px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  min-height: 387px;
`;

export const ErrorWrapper = styled.div`
  padding: 12px;
`;

export const ErrorMessage = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.statusRed};
    padding-left: 0.25em;

    ${theme.typography.bodyText.bodyMedium01}
  `
);

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 16px;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary03};
  width: 100%;
  justify-content: end;
`;

export const BodyText = styled(_BodyText)`
  padding: 0 4px;
  margin: 0;
`;
