import { Identifier, UpdatePortfolioHomeAnalyticBody } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

type MutationProps = {
  id: Identifier;
  data: UpdatePortfolioHomeAnalyticBody;
};

export const useUpdatePortfolioHomeAnalyticMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (props: MutationProps) => {
      const { data, id } = props;

      return api.updatePortfolioHomeAnalytic(id, data);
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([QueryKeys.PORTFOLIO_HOME_ANALYTICS]);
        queryClient.invalidateQueries([QueryKeys.PORTFOLIO_HOME_ANALYTIC, id]);
      },
    }
  );
};
