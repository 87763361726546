import { useMutation, useQueryClient } from 'react-query';

import { updatePortfolioAnalytics, UpdatePortfolioAnalyticsProps } from '../../api';
import { QueryKeys } from './types';

export const useUpdatePortfolioAnalyticsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: UpdatePortfolioAnalyticsProps) => updatePortfolioAnalytics(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.PORTFOLIO_ANALYTICS]);
    },
  });
};
