import styled, { css } from 'styled-components/macro';

export const Details = styled.details`
  margin-top: 8px;
  margin-left: 32px;
`;

export const Summary = styled.summary(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04}
    color: ${theme.colors.textPrimary03};
    margin-bottom: 4px;
    cursor: pointer;
  `
);
