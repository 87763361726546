import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { ButtonLoader } from '../../../../components/ButtonLoader';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import {
  useAcceptTradeRequestExpressionOfInterestMutation,
  useDeclineTradeRequestExpressionOfInterestMutation,
} from '../../../../hooks/queries';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type OutboundExpressionOfInterestAcceptDeclineProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
  onAccept: () => void;
  onDecline: () => void;
};

export const OutboundExpressionOfInterestAcceptDecline = ({
  action,
  onAccept,
  onDecline,
  tradeRequest,
}: OutboundExpressionOfInterestAcceptDeclineProps) => {
  const { fromCounterparty } = action;

  const {
    isLoading: isAcceptingExpressionOfInterest,
    isSuccess: hasAcceptedExpressionOfInterest,
    mutateAsync: acceptExpressionOfInterest,
  } = useAcceptTradeRequestExpressionOfInterestMutation();
  const {
    isLoading: isDecliningExpressionOfInterest,
    isSuccess: hasDeclinedExpressionOfInterest,
    mutateAsync: declineExpressionOfInterest,
  } = useDeclineTradeRequestExpressionOfInterestMutation();

  return (
    <PlusMessage>
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.ExpressionOfInterest.AcceptDecline"
          values={{
            userName: formatNameWithInitial(
              fromCounterparty.user.lastName,
              fromCounterparty.user.firstName
            ),
            organizationName: fromCounterparty.user.organization.tradingName,
            poolName: tradeRequest.pool.name,
          }}
        />
      </MessageText>
      <MessageActions>
        <ButtonLoader
          $size="large"
          disabled={hasAcceptedExpressionOfInterest}
          isLoading={isAcceptingExpressionOfInterest}
          onClick={async () => {
            await acceptExpressionOfInterest({
              tradeRequestId: tradeRequest.id,
              tradeCounterpartyId: action.fromCounterparty.id,
            });
            onAccept();
          }}
        >
          Accept stipulations
        </ButtonLoader>
        <ButtonLoader
          $color="danger"
          $size="large"
          $variant="outlined"
          disabled={hasDeclinedExpressionOfInterest}
          isLoading={isDecliningExpressionOfInterest}
          onClick={async () => {
            await declineExpressionOfInterest({
              tradeRequestId: tradeRequest.id,
              tradeCounterpartyId: action.fromCounterparty.id,
            });
            onDecline();
          }}
        >
          Decline stipulations
        </ButtonLoader>
      </MessageActions>
    </PlusMessage>
  );
};
