import styled, { css } from 'styled-components/macro';

export const Stack = styled.div`
  position: relative;
  padding: 12px 16px 0;
`;

export const StackItem = styled.div<{ $index: number; $maxItemCount: number }>`
  ${({ $index, $maxItemCount, theme }) => {
    const zIndex = $maxItemCount - $index;
    const marginTop = $index * 10;
    const scale = 1 - $index * 0.05;

    return css`
      position: absolute;
      width: calc(100% - 32px);
      height: 92px;
      padding: 12px;
      background: ${$index === 0
        ? theme.colors.primary03
        : $index === 1
        ? theme.colors.primary04
        : theme.colors.primary05};
      border-radius: 8px;
      z-index: ${zIndex};
      margin-top: ${marginTop}px;
      transform: scale(${scale});
      display: grid;
      row-gap: 12px;
    `;
  }}
`;

export const StackHeader = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary10};
`;

export const StackStats = styled.div`
  font-size: 12px;
  display: flex;
  column-gap: 16px;
`;
