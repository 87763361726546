import React from 'react';

export const ImportLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15.6531L7.77813 11.4313L8.71875 10.4906L11.3438 13.1156V5H12.6562V13.1156L15.2812 10.4906L16.2219 11.4313L12 15.6531ZM6.3125 19C5.9625 19 5.65625 18.8687 5.39375 18.6062C5.13125 18.3438 5 18.0375 5 17.6875V14.5594H6.3125V17.6875H17.6875V14.5594H19V17.6875C19 18.0375 18.8687 18.3438 18.6062 18.6062C18.3438 18.8687 18.0375 19 17.6875 19H6.3125Z"
        fill="currentColor"
      />
    </svg>
  );
};
