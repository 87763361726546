import React from 'react';

import { Message } from './Message';
import { PlusAvatar } from './PlusAvatar';

type PlusMessageProps = {
  children: React.ReactNode;
  date?: Date;
  style?: React.CSSProperties;
};

export const PlusMessage = (props: PlusMessageProps) => {
  const { children, date, ...rest } = props;

  return (
    <Message icon={<PlusAvatar size={24} />} userName="Plus" date={date} {...rest}>
      {children}
    </Message>
  );
};
