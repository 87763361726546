import {
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from '../types';

enum LocationType {
  AdjacentToPark = 'AdjacentToPark',
  AdjacentToPowerLines = 'AdjacentToPowerLines',
  BusyRoad = 'BusyRoad',
  Commercial = 'Commercial',
  GolfCourse = 'GolfCourse',
  Industrial = 'Industrial',
  Landfill = 'Landfill',
  Other = 'Other',
  PublicTransportation = 'PublicTransportation',
  Residential = 'Residential',
  WaterFront = 'WaterFront',
}

export type SiteLocation = MISMOEntity &
  Partial<{
    locationType: LocationType;
    locationTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    walkScoreValue: MISMOValue;
  }>;
