import {
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
  MISMOURL,
} from './types';

enum DataSourceType {
  AssessmentAndTaxRecords = 'AssessmentAndTaxRecords',
  CooperativeBoard = 'CooperativeBoard',
  Developer = 'Developer',
  EngagementLetter = 'EngagementLetter',
  ExteriorInspectionOnly = 'ExteriorInspectionOnly',
  HomeownersAssociation = 'HomeownersAssociation',
  InteriorExteriorInspection = 'InteriorExteriorInspection',
  InvestorGuidance = 'InvestorGuidance',
  LoanFile = 'LoanFile',
  ManagementAgency = 'ManagementAgency',
  MultipleListingService = 'MultipleListingService',
  Other = 'Other',
  PreviousAppraisalFile = 'PreviousAppraisalFile',
  PriorInspection = 'PriorInspection',
  PropertyOwner = 'PropertyOwner',
  PurchaseAgreement = 'PurchaseAgreement',
  ThirdPartyAppraisalReport = 'ThirdPartyAppraisalReport',
}

export type DataSource = MISMOEntity &
  Partial<{
    dataSourceDescription: MISMOString;
    dataSourceEffectiveDate: MISMODate;
    dataSourceName: MISMOString;
    dataSourceType: DataSourceType;
    dataSourceTypeOtherDescription: MISMOString;
    dataSourceURL: MISMOURL;
    dataSourceVerificationDescription: MISMOString;
    dataSourceVersionIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
  }>;
