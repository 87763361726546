import { hasPermissions, UserPermissionHierarchy } from '@plus-platform/shared';

import { useUserContext } from '../../contexts/UserContext';

/**
 * Helper hook that returns the permissions
 * currently in the user context and a utility
 * function to do on the fly checking
 */
export const usePermissions = () => {
  const { userPermissions } = useUserContext();

  return {
    hasPermissions: (permissions: UserPermissionHierarchy) =>
      hasPermissions(permissions, userPermissions),
    userPermissions,
  };
};
