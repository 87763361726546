import { CalculationType, PortfolioAnalytics, VisualisationType } from '@plus-platform/shared';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

type PortfolioAnalyticsProps = {
  metric: string;
  metricBy: string;
  visualisationType: VisualisationType;
  metricCalculationType?: CalculationType;
};

export const createPortfolioAnalytics = async ({
  metric,
  metricBy,
  metricCalculationType,
  visualisationType,
}: PortfolioAnalyticsProps) => {
  const url = makeApiUrl(`portfolio/analytics`);
  return postRequestWithAuth<PortfolioAnalytics>(url, {
    body: JSON.stringify({ metric, metricBy, visualisationType, metricCalculationType }),
  });
};
