import { Address } from '../address';
import { Comparable } from '../comparable';
import { DataSource } from '../dataSource';
import { FloodDetermination } from '../floodDetermination';
import { Improvement } from '../improvement';
import { LegalDescription } from '../legalDescription';
import { ManufacturedHome } from '../manufacturedHome';
import { Neighborhood } from '../neighborhood';
import { Project } from '../project';
import { SalesContract } from '../salesContract';
import { Site } from '../site';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMONumeric,
  MISMOSequenceNumber,
  MISMOString,
  MISMOYear,
} from '../types';
import { LocationIdentifier } from './locationIdentifier';
import { PropertyTax } from './propertyTax';
import { PropertyTitle } from './propertyTitle';
import { PropertyValuation } from './valuation';

export enum AttachmentType {
  Attached = 'Attached',
  Detached = 'Detached',
  SemiDetached = 'SemiDetached',
}

export enum ConstructionMethodType {
  Manufactured = 'Manufactured',
  MobileHome = 'MobileHome',
  Modular = 'Modular',
  OnFrameModular = 'OnFrameModular',
  Other = 'Other',
  SiteBuilt = 'SiteBuilt',
}

export enum ConstructionStatusType {
  Existing = 'Existing',
  Other = 'Other',
  Planned = 'Planned',
  Proposed = 'Proposed',
  SubjectToAlteration = 'SubjectToAlteration',
  SubjectToAlterationImprovementRepairAndRehabilitation = 'SubjectToAlterationImprovementRepairAndRehabilitation',
  SubstantiallyRehabilitated = 'SubstantiallyRehabilitated',
  UnderConstruction = 'UnderConstruction',
}

enum LandTrustType {
  CommunityLandTrust = 'CommunityLandTrust',
  IllinoisLandTrust = 'IllinoisLandTrust',
  LandTrust = 'LandTrust',
  Other = 'Other',
}

enum LandUseType {
  Agricultural = 'Agricultural',
  Commercial = 'Commercial',
  Income = 'Income',
  Industrial = 'Industrial',
  Other = 'Other',
  PublicAndSemipublic = 'PublicAndSemipublic',
  Recreational = 'Recreational',
  Residential = 'Residential',
  TransportationAndUtility = 'TransportationAndUtility',
  Vacant = 'Vacant',
}

enum NativeAmericanLandsType {
  AlaskaNativeCorporationLand = 'AlaskaNativeCorporationLand',
  FeeSimple = 'FeeSimple',
  HawaiianHomeLands = 'HawaiianHomeLands',
  IndividualTrustLand = 'IndividualTrustLand',
  Other = 'Other',
  TribalTrustLand = 'TribalTrustLand',
  TribalTrustLandOffReservation = 'TribalTrustLandOffReservation',
  TribalTrustLandOnAReservation = 'TribalTrustLandOnAReservation',
}

export enum PropertyCurrentOccupancyType {
  Abandoned = 'Abandoned',
  AdverseOccupied = 'AdverseOccupied',
  OccupiedByUnknown = 'OccupiedByUnknown',
  OwnerOccupied = 'OwnerOccupied',
  PartiallyVacant = 'PartiallyVacant',
  TenantOccupied = 'TenantOccupied',
  Unknown = 'Unknown',
  Vacant = 'Vacant',
}

export enum PropertyUsageType {
  Investment = 'Investment',
  Other = 'Other',
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
}

enum PropertyDispositionStatusType {
  Other = 'Other',
  Retain = 'Retain',
  Sell = 'Sell',
  Undecided = 'Undecided',
  Unknown = 'Unknown',
  Vacate = 'Vacate',
}

export enum PropertyEstateType {
  FeeSimple = 'FeeSimple',
  Fractional = 'Fractional',
  Leasehold = 'Leasehold',
  Other = 'Other',
}

enum UndefinedPropertyType {
  NonDescriptive = 'NonDescriptive',
  Unavailable = 'Unavailable',
  Unknown = 'Unknown',
}

enum UniqueDwellingType {
  EarthShelterHome = 'EarthShelterHome',
  GeodesicDome = 'GeodesicDome',
  Houseboat = 'Houseboat',
  LogHome = 'LogHome',
  Other = 'Other',
}

enum ValuationUseType {
  Comparable = 'Comparable',
  SubjectProperty = 'SubjectProperty',
}

enum UnitOccupancyType {
  OwnerOccupied = 'OwnerOccupied',
  Tenant = 'Tenant',
  Unknown = 'Unknown',
  Vacant = 'Vacant',
}

export type PropertyUnit = MISMOEntity &
  Partial<{
    bathroomNumber: MISMONumeric;
    bedroomCount: MISMOCount;
    cooperativeAssignmentOfLeaseDate: MISMODate;
    cooperativeProprietaryLeaseDate: MISMODate;
    cooperativeStockCertificateNumberIdentifier: MISMOIdentifier;
    cooperativeUnitSharesCount: MISMOCount;
    floorIdentifier: MISMOIdentifier;
    leaseExpirationDate: MISMODate;
    levelCount: MISMOCount;
    propertyDwellingUnitEligibleRentAmount: MISMOAmount;
    propertyDwellingUnitLeaseProvidedIndicator: MISMOIndicator;
    propertyDwellingUnitPastDueRentAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
    squareFeetPerUnitNumber: MISMONumeric;
    totalRoomCount: MISMOCount;
    unitIdentifier: MISMOIdentifier;
    unitOccupancyType: UnitOccupancyType;
    address: Address;
    // unitCharge: UnitCharge;
  }>;

export type Property = MISMOEntity &
  Partial<{
    affordableUnitsCount: MISMOCount;
    assignmentOfRentsIndicator: MISMOIndicator;
    attachmentType: AttachmentType;
    borrowerContinuesToOwnPropertyIndicator: MISMOIndicator;
    communityLandTrustIndicator: MISMOIndicator;
    communityPropertyStateIndicator: MISMOIndicator;
    communityReinvestmentActDelineatedCommunityIndicator: MISMOIndicator;
    constructionMethodType: ConstructionMethodType;
    constructionMethodTypeOtherDescription: MISMOString;
    constructionStatusType: ConstructionStatusType;
    constructionStatusTypeOtherDescription: MISMOString;
    deedRestrictionIndicator: MISMOIndicator;
    disasterIndicator: MISMOIndicator;
    FHASecondaryResidenceIndicator: MISMOIndicator;
    financedUnitCount: MISMOCount;
    grossLivingAreaSquareFeetNumber: MISMONumeric;
    groundRentIndicator: MISMOIndicator;
    groupHomeIndicator: MISMOIndicator;
    investmentRentalIncomePresentIndicator: MISMOIndicator;
    investorREOPropertyIdentifier: MISMOIdentifier;
    landTrustType: LandTrustType;
    landTrustTypeOtherDescription: MISMOString;
    landUseDescription: MISMOString;
    landUseType: LandUseType;
    landUseTypeOtherDescription: MISMOString;
    lenderDesignatedDecliningMarketIdentifier: MISMOIdentifier;
    metropolitanDivisionIndicator: MISMOIndicator;
    MSAIndicator: MISMOIndicator;
    nativeAmericanLandsType: NativeAmericanLandsType;
    nativeAmericanLandsTypeOtherDescription: MISMOString;
    priorSaleInLastTwelveMonthsIndicator: MISMOIndicator;
    propertyAcquiredDate: MISMODate;
    propertyAcquiredYear: MISMOYear;
    propertyAcreageNumber: MISMONumeric;
    propertyConditionDescription: MISMOString;
    propertyCurrentOccupancyType: PropertyCurrentOccupancyType;
    propertyCurrentOccupantName: MISMOString;
    propertyCurrentUsageType: PropertyUsageType;
    propertyCurrentUsageTypeOtherDescription: MISMOString;
    propertyDispositionStatusType: PropertyDispositionStatusType;
    propertyDispositionStatusTypeOtherDescription: MISMOString;
    propertyEarthquakeInsuranceIndicator: MISMOIndicator;
    propertyEstateType: PropertyEstateType;
    propertyEstateTypeOtherDescription: MISMOString;
    propertyEstimatedValueAmount: MISMOAmount;
    propertyExistingCleanEnergyLienIndicator: MISMOIndicator;
    propertyExistingLienAmount: MISMOAmount;
    propertyFloodInsuranceIndicator: MISMOIndicator;
    propertyGroundLeaseExpirationDate: MISMODate;
    propertyGroundLeasePerpetualIndicator: MISMOIndicator;
    propertyInclusionaryZoningIndicator: MISMOIndicator;
    propertyInProjectIndicator: MISMOIndicator;
    propertyMixedUsageIndicator: MISMOIndicator;
    propertyOriginalCostAmount: MISMOAmount;
    propertyPreviouslyOccupiedIndicator: MISMOIndicator;
    propertyStructureBuiltYear: MISMOYear;
    propertyStructureBuiltYearEstimatedIndicator: MISMOIndicator;
    propertyStructureHabitableYearRoundIndicator: MISMOIndicator;
    propertyUsageType: PropertyUsageType;
    propertyUsageTypeOtherDescription: MISMOString;
    propertyVacancyDate: MISMODate;
    PUDIndicator: MISMOIndicator;
    rentalEstimatedGrossMonthlyRentAmount: MISMOAmount;
    rentalEstimatedNetMonthlyRentAmount: MISMOAmount;
    ruralUnderservedCountyIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
    undefinedPropertyType: UndefinedPropertyType;
    uniqueDwellingType: UniqueDwellingType;
    uniqueDwellingTypeOtherDescription: MISMOString;
    valuationUseType: ValuationUseType;
    address: Address;
    // buildingPermits: BuildingPermit[];
    // certificates: Certificate[];
    comparable: Comparable;
    dataSources: DataSource[];
    // disasters: Disaster[];
    // encumbrances: Encumbrance[];
    // environmentalConditions: EnvironmentalCondition[];
    floodDetermination: FloodDetermination;
    // hazardInsurances: HazardInsurance[];
    // homeownersAssociations: HomeownersAssociation[];
    improvement: Improvement;
    // inspections: Inspection[];
    legalDescriptions: LegalDescription[];
    // licenses: License[];
    // listingInformations: ListingInformation[];
    locationIdentifier: LocationIdentifier;
    manufacturedHome: ManufacturedHome;
    // market: Market;
    neighborhood: Neighborhood;
    project: Project;
    propertyTaxes: PropertyTax[];
    propertyTitle: PropertyTitle;
    propertyUnits: PropertyUnit[];
    propertyValuations: PropertyValuation[];
    // repair: Repair;
    salesContracts: SalesContract[];
    // salesHistories: SalesHistory[];
    // servitudes: Servitude[];
    site: Site;
  }>;
