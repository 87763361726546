import {
  AggregatedLoanDocument,
  EventLogItem,
  Filter,
  ForeclosureMethodType,
  Loan,
  LoanPropertyDetails,
  MISMO,
  PaginatedLoans,
  PortfolioBreakdownRateType,
  PortfolioLoanRateType,
} from '@plus-platform/shared';
import words from 'lodash/words';

import { getPropertyPhotoForLoan } from '../assets/photos';
import { getRequestWithAuth, makeApiUrl } from './apiUtils';
import { EMPTY_VALUE, formatDefaultValue } from './formatUtils';

const amortizationTypeLabels: Record<MISMO.LoanAmortizationType, string> = {
  [MISMO.LoanAmortizationType.AdjustableRate]: 'Adjustable Rate',
  [MISMO.LoanAmortizationType.Fixed]: 'Fixed',
  [MISMO.LoanAmortizationType.GEM]: 'GEM',
  [MISMO.LoanAmortizationType.GPM]: 'GPM',
  [MISMO.LoanAmortizationType.GraduatedPaymentARM]: 'Graduated Payment ARM',
  [MISMO.LoanAmortizationType.Other]: 'Other',
  [MISMO.LoanAmortizationType.RateImprovementMortgage]: 'Rate Improvement Mortgage',
  [MISMO.LoanAmortizationType.Step]: 'Step',
};

export const formatAmortizationType = (amortizationType?: MISMO.LoanAmortizationType) => {
  if (!amortizationType) {
    return EMPTY_VALUE;
  }
  return amortizationTypeLabels[amortizationType] || amortizationType;
};

const lienPriorityTypeLabels: Record<MISMO.LienPriorityType, string> = {
  [MISMO.LienPriorityType.FirstLien]: 'First Lien',
  [MISMO.LienPriorityType.SecondLien]: 'Second Lien',
  [MISMO.LienPriorityType.ThirdLien]: 'Third Lien',
  [MISMO.LienPriorityType.FourthLien]: 'Fourth Lien',
  [MISMO.LienPriorityType.Other]: 'Other',
};

export const formatLienPriorityType = (lienPriorityType?: MISMO.LienPriorityType) => {
  if (!lienPriorityType) {
    return EMPTY_VALUE;
  }
  return lienPriorityTypeLabels[lienPriorityType] || lienPriorityType;
};

const loanTypeLabels: Record<MISMO.MortgageType, string> = {
  [MISMO.MortgageType.Conventional]: 'Conventional',
  [MISMO.MortgageType.FHA]: 'FHA',
  [MISMO.MortgageType.LocalAgency]: 'Local Agency',
  [MISMO.MortgageType.Other]: 'Other',
  [MISMO.MortgageType.PublicAndIndianHousing]: 'Public And Indian Housing',
  [MISMO.MortgageType.StateAgency]: 'State Agency',
  [MISMO.MortgageType.USDARuralDevelopment]: 'USDA Rural Development',
  [MISMO.MortgageType.VA]: 'VA',
};

export const formatLoanType = (loanType?: MISMO.MortgageType) => {
  if (!loanType) {
    return EMPTY_VALUE;
  }

  return loanTypeLabels[loanType] || loanType;
};

export const loanRateTypeLabels: Record<PortfolioLoanRateType, string> = {
  [PortfolioLoanRateType.FIXEDRATE30YR]: '30 YR Fixed',
  [PortfolioLoanRateType.FIXEDRATE15YR]: '15 YR Fixed',
  [PortfolioLoanRateType.FIXEDFHA30YR]: 'FHA 30 YR',
  [PortfolioLoanRateType.FIXEDVA30YR]: 'VA 30 YR',
  [PortfolioLoanRateType.JUMBO30YR]: 'Jumbo 30 YR',
  [PortfolioLoanRateType.ARM5YR]: '5/1 YR ARM',
};

export const orderedLoanRateTypes = Object.keys(loanRateTypeLabels) as PortfolioLoanRateType[];
export const orderedLoanRateTypeLabels = Object.values(loanRateTypeLabels);

export const breakdownRateTypeLabels: Record<PortfolioBreakdownRateType, string> = {
  [PortfolioBreakdownRateType.ALL]: 'All',
  ...loanRateTypeLabels,
};

export const orderedBreakdownRateTypes = Object.keys(
  breakdownRateTypeLabels
) as PortfolioBreakdownRateType[];
export const orderedBreakdownRateTypeLabels = Object.values(breakdownRateTypeLabels);

export const formatLoanRateType = (loanRateType?: PortfolioLoanRateType) => {
  if (!loanRateType) {
    return 'unknown';
  }

  return loanRateTypeLabels[loanRateType] || 'unknown';
};

export const formatBreakdownRateType = (breakdownRateType?: PortfolioBreakdownRateType) => {
  if (!breakdownRateType) {
    return 'unknown';
  }

  return breakdownRateTypeLabels[breakdownRateType] || 'unknown';
};

const loanPurposeTypeLabels: Record<MISMO.LoanPurposeType, string> = {
  [MISMO.LoanPurposeType.MortgageModification]: 'Mortgage Modification',
  [MISMO.LoanPurposeType.Other]: 'Other',
  [MISMO.LoanPurposeType.Purchase]: 'Purchase',
  [MISMO.LoanPurposeType.Refinance]: 'Refinance',
  [MISMO.LoanPurposeType.Unknown]: 'Unknown',
};

export const formatLoanPurposeType = (loanPurposeType?: MISMO.LoanPurposeType) => {
  if (!loanPurposeType) {
    return EMPTY_VALUE;
  }

  return loanPurposeTypeLabels[loanPurposeType] || loanPurposeType;
};

const refinanceCashoutDeterminationTypeLabels: Record<
  MISMO.RefinanceCashOutDeterminationType,
  string
> = {
  [MISMO.RefinanceCashOutDeterminationType.CashOut]: 'Cash-Out',
  [MISMO.RefinanceCashOutDeterminationType.LimitedCashOut]: 'Limited Cash-Out',
  [MISMO.RefinanceCashOutDeterminationType.NoCashOut]: 'No Cash-Out',
  [MISMO.RefinanceCashOutDeterminationType.Unknown]: 'Unknown',
};

export const formatRefinancePurposeType = (
  refinancePurposeType?: MISMO.RefinanceCashOutDeterminationType | MISMO.LoanPurposeType
) => {
  if (!refinancePurposeType) {
    return EMPTY_VALUE;
  }

  return (
    refinanceCashoutDeterminationTypeLabels[
      refinancePurposeType as MISMO.RefinanceCashOutDeterminationType
    ] ||
    loanPurposeTypeLabels[refinancePurposeType as MISMO.LoanPurposeType] ||
    refinancePurposeType
  );
};

const propertyUsageTypeLabels: Record<MISMO.PropertyUsageType, string> = {
  [MISMO.PropertyUsageType.Investment]: 'Investment',
  [MISMO.PropertyUsageType.Other]: 'Other',
  [MISMO.PropertyUsageType.PrimaryResidence]: 'Primary Residence',
  [MISMO.PropertyUsageType.SecondHome]: 'Second Home',
};

export const formatPropertyUsageType = (propertyUsageType?: MISMO.PropertyUsageType) => {
  if (!propertyUsageType) {
    return EMPTY_VALUE;
  }

  return propertyUsageTypeLabels[propertyUsageType] || propertyUsageType;
};

const propertyAttachmentTypeLabels: Record<MISMO.AttachmentType, string> = {
  [MISMO.AttachmentType.Attached]: 'Attached',
  [MISMO.AttachmentType.Detached]: 'Detached',
  [MISMO.AttachmentType.SemiDetached]: 'Semi Detached',
};

export const formatPropertyAttachmentType = (propertyAttachmentType?: MISMO.AttachmentType) => {
  if (!propertyAttachmentType) {
    return formatDefaultValue();
  }

  return propertyAttachmentTypeLabels[propertyAttachmentType] || propertyAttachmentType;
};

const propertyEstateTypeLabels: Record<MISMO.PropertyEstateType, string> = {
  [MISMO.PropertyEstateType.FeeSimple]: 'Fee Simple',
  [MISMO.PropertyEstateType.Fractional]: 'Fractional',
  [MISMO.PropertyEstateType.Leasehold]: 'Leasehold',
  [MISMO.PropertyEstateType.Other]: 'Other',
};

export const formatPropertyEstateType = (propertyEstateType?: MISMO.PropertyEstateType) => {
  if (!propertyEstateType) {
    return EMPTY_VALUE;
  }

  return propertyEstateTypeLabels[propertyEstateType] || propertyEstateType;
};

const propertyCurrentOccupancyTypeLabels: Record<MISMO.PropertyCurrentOccupancyType, string> = {
  [MISMO.PropertyCurrentOccupancyType.Abandoned]: 'Abandoned',
  [MISMO.PropertyCurrentOccupancyType.AdverseOccupied]: 'Adverse Occupied',
  [MISMO.PropertyCurrentOccupancyType.OccupiedByUnknown]: 'Occupied By Unknown',
  [MISMO.PropertyCurrentOccupancyType.OwnerOccupied]: 'Owner Occupied',
  [MISMO.PropertyCurrentOccupancyType.PartiallyVacant]: 'Partially Vacant',
  [MISMO.PropertyCurrentOccupancyType.TenantOccupied]: 'Tenant Occupied',
  [MISMO.PropertyCurrentOccupancyType.Unknown]: 'Unknown',
  [MISMO.PropertyCurrentOccupancyType.Vacant]: 'Vacant',
};

export const formatPropertyOccupancyType = (
  propertyOccupancyType?: MISMO.PropertyCurrentOccupancyType
) => {
  if (!propertyOccupancyType) {
    return EMPTY_VALUE;
  }

  return propertyCurrentOccupancyTypeLabels[propertyOccupancyType] || propertyOccupancyType;
};

const neighborhoodLocationTypeLabels: Record<MISMO.NeighborhoodPropertyLocationType, string> = {
  [MISMO.NeighborhoodPropertyLocationType.Other]: 'Other',
  [MISMO.NeighborhoodPropertyLocationType.Rural]: 'Rural',
  [MISMO.NeighborhoodPropertyLocationType.RuralUrban]: 'Rural Urban',
  [MISMO.NeighborhoodPropertyLocationType.Suburban]: 'Suburban',
  [MISMO.NeighborhoodPropertyLocationType.Urban]: 'Urban',
  [MISMO.NeighborhoodPropertyLocationType.UrbanSprawl]: 'Urban Sprawl',
};

export const formatNeighborhoodLocationType = (
  locationType?: MISMO.NeighborhoodPropertyLocationType
) => {
  if (!locationType) {
    return EMPTY_VALUE;
  }

  return neighborhoodLocationTypeLabels[locationType] || locationType;
};

const neighborhoodBuiltUpRangeTypeLabels: Record<MISMO.NeighborhoodBuiltUpRangeType, string> = {
  [MISMO.NeighborhoodBuiltUpRangeType.Over75Percent]: 'Over 75 Percent',
  [MISMO.NeighborhoodBuiltUpRangeType.TwentyFiveToSeventyFivePercent]: '25 To 75 Percent',
  [MISMO.NeighborhoodBuiltUpRangeType.Under25Percent]: 'Under 25 Percent',
};

export const formatNeighborhoodBuiltUpRangeType = (
  builtUpRangeType?: MISMO.NeighborhoodBuiltUpRangeType
) => {
  if (!builtUpRangeType) {
    return EMPTY_VALUE;
  }

  return neighborhoodBuiltUpRangeTypeLabels[builtUpRangeType] || builtUpRangeType;
};

const neighborhoodGrowthPaceTypeLabels: Record<MISMO.NeighborhoodGrowthPaceType, string> = {
  [MISMO.NeighborhoodGrowthPaceType.Declining]: 'Declining',
  [MISMO.NeighborhoodGrowthPaceType.FullyDeveloped]: 'Fully Developed',
  [MISMO.NeighborhoodGrowthPaceType.Rapid]: 'Rapid',
  [MISMO.NeighborhoodGrowthPaceType.Slow]: 'Slow',
  [MISMO.NeighborhoodGrowthPaceType.Stable]: 'Stable',
};

export const formatNeighborhoodGrowthPaceType = (
  growthPaceType?: MISMO.NeighborhoodGrowthPaceType
) => {
  if (!growthPaceType) {
    return EMPTY_VALUE;
  }

  return neighborhoodGrowthPaceTypeLabels[growthPaceType] || growthPaceType;
};

const neighborhoodTrendTypeLabels: Record<MISMO.NeighborhoodPropertyValueTrendType, string> = {
  [MISMO.NeighborhoodPropertyValueTrendType.Declining]: 'Declining',
  [MISMO.NeighborhoodPropertyValueTrendType.Increasing]: 'Increasing',
  [MISMO.NeighborhoodPropertyValueTrendType.Stable]: 'Stable',
};

export const formatNeighborhoodTrendType = (
  trendType?: MISMO.NeighborhoodPropertyValueTrendType
) => {
  if (!trendType) {
    return EMPTY_VALUE;
  }

  return neighborhoodTrendTypeLabels[trendType] || trendType;
};

const neighborhoodDemandSupplyTypeLabels: Record<MISMO.NeighborhoodDemandSupplyType, string> = {
  [MISMO.NeighborhoodDemandSupplyType.InBalance]: 'In Balance',
  [MISMO.NeighborhoodDemandSupplyType.OverSupply]: 'Over Supply',
  [MISMO.NeighborhoodDemandSupplyType.Shortage]: 'Shortage',
};

export const formatNeighborhoodDemandSupplyType = (
  demandSupplyType?: MISMO.NeighborhoodDemandSupplyType
) => {
  if (!demandSupplyType) {
    return EMPTY_VALUE;
  }

  return neighborhoodDemandSupplyTypeLabels[demandSupplyType] || demandSupplyType;
};

const marketingMonthsDurationTypeLabels: Record<
  MISMO.NeighborhoodTypicalMarketingMonthsDurationType,
  string
> = {
  [MISMO.NeighborhoodTypicalMarketingMonthsDurationType.OverSixMonths]: 'Over 6 Months',
  [MISMO.NeighborhoodTypicalMarketingMonthsDurationType.ThreeToSixMonths]: '3 To 6 Months',
  [MISMO.NeighborhoodTypicalMarketingMonthsDurationType.UnderThreeMonths]: 'Under 3 Months',
};

export const formatNeighborhoodMarketingMonthsDurationType = (
  marketingMonthsDurationType?: MISMO.NeighborhoodTypicalMarketingMonthsDurationType
) => {
  if (!marketingMonthsDurationType) {
    return EMPTY_VALUE;
  }

  return (
    marketingMonthsDurationTypeLabels[marketingMonthsDurationType] || marketingMonthsDurationType
  );
};

const foreclosureMethodTypeLabels: Record<ForeclosureMethodType, string> = {
  [ForeclosureMethodType.Judicial]: 'Judicial',
  [ForeclosureMethodType.NonJudicial]: 'Non Judicial',
  [ForeclosureMethodType.Other]: 'Other',
};

export const formatForeclosureMethodType = (foreclosureMethodType?: ForeclosureMethodType) => {
  if (!foreclosureMethodType) {
    return EMPTY_VALUE;
  }

  return foreclosureMethodTypeLabels[foreclosureMethodType] || foreclosureMethodType;
};

const formatCamelCaseValue = (value: string) => {
  return words(value).join(' ');
};

export const formatListOfEnumValues = (values?: string[]) => {
  if (!values || values.length === 0) {
    return EMPTY_VALUE;
  }

  return values.map(formatCamelCaseValue).join(', ');
};

export const addPhotosToLoan = (loan: Loan) => ({
  ...loan,
  loanProperty: {
    ...loan.loanProperty,
    photos: [
      { path: getPropertyPhotoForLoan(loan.id) },
      // this is a bit of an awkward use of the `getPropertyPhotoForLoan`
      // function here, but seeing as these are dummy images and will be
      // replaced later, using loan.id + 1 seems reasonable.
      { path: getPropertyPhotoForLoan(loan.id + 1) },
    ],
  },
});

export const findPaginatedLoanById = (items: PaginatedLoans[], loanId?: string) => {
  if (loanId === undefined) {
    return undefined;
  }

  return items.reduce<Loan | undefined>((acc, currentPage) => {
    const matchingLoan = currentPage
      ? currentPage.loans.find((loan) => String(loan.id) === loanId)
      : undefined;
    return matchingLoan || acc;
  }, undefined);
};

export const getLoanSummary = async (loanNumber: string) => {
  const url = makeApiUrl(`loan/${loanNumber}/summary`);
  const summary = await getRequestWithAuth<Loan>(url);
  if (!summary) {
    return undefined;
  }

  return addPhotosToLoan(summary);
};

export const getLoanDetail = async (loanNumber: string) => {
  const url = makeApiUrl(`loan/${loanNumber}`);
  const loanDetail = await getRequestWithAuth<Loan>(url);

  if (!loanDetail) {
    return undefined;
  }

  return addPhotosToLoan(loanDetail);
};

export const getLoanAssetLedger = async (loanNumber: string) => {
  const url = makeApiUrl(`loan/${loanNumber}/asset-ledger`);
  return getRequestWithAuth<EventLogItem[]>(url);
};

export const getLoanDocuments = async (loanNumber: string, searchQuery?: string) => {
  const query = new URLSearchParams({
    search: searchQuery ?? '',
  });
  const url = makeApiUrl(`loan/${loanNumber}/documents${searchQuery ? `?${query}` : ''}`);
  return getRequestWithAuth<AggregatedLoanDocument[]>(url);
};

export const getGeodataProperties = (filters: Filter[] = []) => {
  const query = new URLSearchParams({
    filterIds: JSON.stringify(filters.map((filter) => filter.id)),
  });
  const url = makeApiUrl(`loans/geodata/properties?${query}`);

  return getRequestWithAuth<LoanPropertyDetails[]>(url);
};
