import styled, { css } from 'styled-components/macro';

type DividerProps = {
  $flexItem?: boolean;
  $orientation?: 'horizontal' | 'vertical';
  $variant?: 'fullWidth' | 'middle';
};

export const Divider = styled.div.attrs<DividerProps>(({ $orientation, $variant, ...rest }) => ({
  ...rest,
  $orientation: $orientation ?? 'horizontal',
  $variant: $variant ?? 'fullWidth',
}))<DividerProps>`
  ${({ $flexItem, $orientation, $variant, theme }) => css`
    position: relative;

    ${
      $orientation === 'horizontal' &&
      css`
        width: 100%;
        height: 1px;
      `
    }

    ${
      $orientation === 'vertical' &&
      css`
        width: 1px;
        height: auto;

        ${$flexItem &&
        css`
          align-self: stretch;
        `}

        ${$variant === 'middle' &&
        css`
          margin: auto 0;
          height: 24px;
        `}
      `
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: ${theme.colors.white05}};
    }
  `}
`;
