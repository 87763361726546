import React from 'react';
import { AnyStyledComponent } from 'styled-components';

import type { HeadingStyleProps } from './Heading.styles';
import * as Styles from './Heading.styles';

export type HeadingProps = HeadingStyleProps &
  React.HTMLAttributes<HTMLHeadingElement> & {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any> | AnyStyledComponent;
  };

export const Heading = ({ $size, as = 'h2', ...props }: HeadingProps) => {
  return <Styles.Heading {...props} as={as} $size={$size} />;
};
