import styled from 'styled-components/macro';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const TicketList = styled.div`
  display: grid;
  row-gap: 8px;
  margin-top: 16px;
`;
