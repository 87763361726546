import styled from 'styled-components/macro';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Card } from '../components/Card';
import { Heading } from '../components/Heading';
import { HeadingLink } from '../components/HeadingLink';
import { InboundIcon, OutboundIcon, TradeLineIcon } from '../components/icons';
import { Page, PageContent, PageHeader } from '../components/Page';
import { TradeRequestsCompleted } from './TradeRequestsCompleted';
import {
  InboundTradeRequestMedium,
  NoTrades,
  OutboundTradeRequestMedium,
} from './TradeRequestTickets';
import { useTradeRequestsSummary } from './useTradeRequestsSummary';

const TradeRequestsPanels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'inbound outbound'
    'completed completed';
  grid-template-rows: 60% 1fr;
  gap: 8px;
  height: 100%;

  > :nth-child(1) {
    grid-area: inbound;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  > :nth-child(2) {
    grid-area: outbound;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  > :nth-child(3) {
    grid-area: completed;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;

const Header = styled.div`
  margin-bottom: 12px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const TradeRequestsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const TradeRequestItem = styled.li`
  margin-bottom: 8px;
`;

export const TradeRequests = () => {
  const { data: tradeRequestData, isLoading } = useTradeRequestsSummary();

  const inboundTradeRequests = tradeRequestData?.inbound ?? [];
  const outboundTradeRequests = tradeRequestData?.outbound ?? [];
  const completedTradeRequests = tradeRequestData?.completed ?? [];

  return (
    <Page>
      <PageHeader>
        <Heading>Trade activity</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem title="Trade activity" />
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        <TradeRequestsPanels>
          <Card $hasPadding>
            <Header>
              <HeadingLink $size="medium" to="/trade-requests/inbound">
                Inbound {inboundTradeRequests?.length ? `(${inboundTradeRequests.length})` : ''}
              </HeadingLink>
            </Header>
            <ContentWrapper>
              <ActivityIndicator isActive={isLoading}>
                {inboundTradeRequests?.length ? (
                  <TradeRequestsList>
                    {inboundTradeRequests?.map((tradeRequest) => (
                      <TradeRequestItem key={tradeRequest.id}>
                        <InboundTradeRequestMedium
                          tradeRequest={tradeRequest}
                          url={`/trade-requests/inbound/${tradeRequest.id}`}
                          variant="light"
                        />
                      </TradeRequestItem>
                    ))}
                  </TradeRequestsList>
                ) : (
                  <NoTrades>
                    <InboundIcon /> No inbound trade activity
                  </NoTrades>
                )}
              </ActivityIndicator>
            </ContentWrapper>
          </Card>
          <Card $hasPadding>
            <Header>
              <HeadingLink $size="medium" to="/trade-requests/outbound">
                Outbound {outboundTradeRequests?.length ? `(${outboundTradeRequests.length})` : ''}
              </HeadingLink>
            </Header>
            <ContentWrapper>
              <ActivityIndicator isActive={isLoading}>
                {outboundTradeRequests?.length ? (
                  <TradeRequestsList>
                    {outboundTradeRequests?.map((tradeRequest) => (
                      <TradeRequestItem key={tradeRequest.id}>
                        <OutboundTradeRequestMedium
                          variant="light"
                          url={`/trade-requests/outbound/${tradeRequest.id}`}
                          tradeRequest={tradeRequest}
                        />
                      </TradeRequestItem>
                    ))}
                  </TradeRequestsList>
                ) : (
                  <NoTrades>
                    <OutboundIcon />
                    No outbound trade activity
                  </NoTrades>
                )}
              </ActivityIndicator>
            </ContentWrapper>
          </Card>
          <Card $hasPadding>
            <Header>
              <HeadingLink $size="medium" to="/trade-requests/completed">
                Completed trades{' '}
                {completedTradeRequests?.length ? `(${completedTradeRequests.length})` : ''}
              </HeadingLink>
            </Header>
            <ContentWrapper>
              <ActivityIndicator isActive={isLoading}>
                {completedTradeRequests?.length ? (
                  <TradeRequestsCompleted />
                ) : (
                  <NoTrades>
                    <TradeLineIcon />
                    No completed trades
                  </NoTrades>
                )}
              </ActivityIndicator>
            </ContentWrapper>
          </Card>
        </TradeRequestsPanels>
      </PageContent>
    </Page>
  );
};
