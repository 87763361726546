import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

import createPassword from '../../assets/create-password.svg';
// @todo: this could be moved to a more generic location
import { ErrorText, Input } from '../Login.styles';
import { SubmitButton } from './styles';

const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const HeaderImage = styled.img`
  height: 70px;
  width: auto;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
`;

const Message = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.colors.background01};
    margin-bottom: 30px;
  `}
`;

const Constrain = styled.div`
  max-width: 485px;
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.colors.background01};
    margin-bottom: 8px;
    display: block;
  `}
`;

const StyledInput = styled(Input)`
  ${({ theme }) => css`
    color: ${theme.colors.background01};
    background-color: ${theme.colors.white};
  `}
`;

const TwoColumnFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export type CreateAccountFormValues = {
  firstName: string;
  lastName: string;
  password: string;
};

type CreateAccountFormProps = {
  onSubmit: (event: React.FormEvent) => void;
  isLoading: boolean;
  formErrors: FieldErrors<CreateAccountFormValues>;
  register: UseFormRegister<CreateAccountFormValues>;
  submitError?: string;
};

export const CreatePasswordForm = ({
  formErrors,
  isLoading,
  onSubmit,
  register,
  submitError,
}: CreateAccountFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Constrain>
        <HeaderImage src={createPassword} alt="" />
        <Message>
          Create a new, strong password that you don’t use for other applications or services.
        </Message>
        {submitError && <ErrorText>{submitError}</ErrorText>}
        <div>
          <TwoColumnFlex>
            <FieldWrapper>
              <Label htmlFor="ca-firstname">First name</Label>
              <StyledInput
                autoComplete="firstName"
                id="ca-firstname"
                placeholder="Your first name"
                title="Your first name"
                type="text"
                {...register('firstName', {
                  required: {
                    value: true,
                    message: 'First name is required',
                  },
                  minLength: {
                    value: 2,
                    message: 'First name must be at least 2 characters',
                  },
                  maxLength: {
                    value: 50,
                    message: 'First name must be less than 50 characters',
                  },
                })}
              />
              {formErrors.firstName && <ErrorText>{formErrors.firstName.message}</ErrorText>}
            </FieldWrapper>
            <FieldWrapper>
              <Label htmlFor="ca-firstname">Last name</Label>
              <StyledInput
                autoComplete="lastName"
                id="ca-lastname"
                placeholder="Your last name"
                required
                title="Your last name"
                type="text"
                {...register('lastName', {
                  required: {
                    value: true,
                    message: 'Last name is required',
                  },
                  minLength: {
                    value: 2,
                    message: 'Last name must be at least 2 characters',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Last name must be less than 50 characters',
                  },
                })}
              />
              {formErrors.lastName && <ErrorText>{formErrors.lastName.message}</ErrorText>}
            </FieldWrapper>
          </TwoColumnFlex>
          <FieldWrapper>
            <Label htmlFor="ca-password">Create password</Label>
            <StyledInput
              id="ca-password"
              placeholder="Password"
              required
              title="Password"
              type="password"
              {...register('password', {
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters',
                },
                maxLength: {
                  value: 50,
                  message: 'Password must be less than 50 characters',
                },
              })}
            />
            {formErrors.password && <ErrorText>{formErrors.password.message}</ErrorText>}
          </FieldWrapper>

          <ButtonWrapper>
            <SubmitButton $isDisabled={isLoading} type="submit">
              Create account
            </SubmitButton>
          </ButtonWrapper>
        </div>
      </Constrain>
    </form>
  );
};
