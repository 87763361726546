import { useTheme } from 'styled-components/macro';

import { getDelinquencyStatusColor } from '../styles/delinquency';
import { formatDelinquency } from '../utils/formatUtils';
import { Badge, BadgeWrapper } from './Badge';
import * as Styles from './DelinquencyStatus.styles';

type DelinquencyStatusProps = {
  isDelinquent: boolean;
  variant?: 'heading' | 'body';
};

export const DelinquencyStatus = ({
  isDelinquent,
  variant = 'heading',
}: DelinquencyStatusProps) => {
  const theme = useTheme();
  const color = getDelinquencyStatusColor(isDelinquent, theme);

  return (
    <BadgeWrapper $gap={4}>
      <Badge $color={color} $size={8} />
      <Styles.Status $variant={variant} $color={color}>
        {formatDelinquency(isDelinquent)}
      </Styles.Status>
    </BadgeWrapper>
  );
};
