import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type OutboundDataRequestAcceptedProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundDataRequestAccepted = ({
  action,
  tradeRequest,
}: OutboundDataRequestAcceptedProps) => {
  const { createdAt, toCounterparty } = action;

  return (
    <PlusMessage date={createdAt}>
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.DataRequest.Accepted"
          values={{
            userName: formatNameWithInitial(
              toCounterparty.user.lastName,
              toCounterparty.user.firstName
            ),
            organizationName: toCounterparty.user.organization.tradingName,
            poolName: tradeRequest.pool.name,
          }}
        />
      </MessageText>
    </PlusMessage>
  );
};
