import { getUserPermissions, getUserProfile } from '../../utils/userUtils';
import { useQueriesTyped } from '../useQueriesTyped';
import { QueryKeys } from './types';

/**
 * Query hook that returns multiple user data points
 */
export const useUserQueries = () =>
  useQueriesTyped([
    {
      queryFn: getUserProfile,
      queryKey: QueryKeys.USER_PROFILE,
    },
    {
      queryFn: getUserPermissions,
      queryKey: QueryKeys.USER_PERMISSIONS,
    },
  ]);
