import { UserPermissionHierarchy } from '../types/users';

/**
 * Take an object of required permissions and an
 * object of permissions to compare against and return
 * if the the current permissions have all the required
 * permissions
 *
 * @param requiredPermissions
 * @param currentPermissions
 */
export const hasPermissions = (
  requiredPermissions: UserPermissionHierarchy,
  currentPermissions?: UserPermissionHierarchy
) =>
  Object.entries(requiredPermissions).every(
    ([requiredPermission, requiredAttributes]) =>
      // Current permissions include the root permission and
      // ensure that the attributes are there
      (currentPermissions?.[requiredPermission] &&
        requiredAttributes.length > 0 &&
        // ... and every required attribute is assigned
        // to the current permission
        requiredAttributes.every((attribute) =>
          currentPermissions[requiredPermission].includes(attribute)
        )) ||
      false
  );
