import styled, { css } from 'styled-components/macro';

export const LoanDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  gap: 8px;
  max-width: 1408px;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  margin-left: 24px;

  input {
    height: 32px;
    border-color: rgba(255, 255, 255, 0.6);
    font-family: ${(props) => props.theme.typography.fontFamily.text};
    font-size: 14px;
    font-weight: 400;
    color: white;

    &::placeholder {
      font-family: ${(props) => props.theme.typography.fontFamily.text};
      font-size: 14px;
      line-height: 32px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
    }
  }
`;

export const AdditionalSection = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const MapWrapper = styled.div`
  margin-bottom: 8px;
`;

export const LoanDetailAdditionalSection = styled(AdditionalSection)`
  align-self: flex-start;
  width: 100%;

  & + & {
    margin-top: 16px;
  }
`;

export const LoanDetailSectionLoaderWrapper = styled.div`
  position: relative;
  min-height: 32px;
`;

export const CensusTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.white70};
    ${theme.typography.bodyText.bodyRegular02}
  `
);
