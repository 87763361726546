import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { Button as StyledButton } from '../Button.styles';

type styleProps = ThemeProps<DefaultTheme>;

export const activeStyles = ({ theme }: styleProps) => css`
  color: ${theme.colors.textSecondary01};
  background: ${theme.colors.primary10};
`;

export const hoverStyles = ({ theme }: styleProps) => css`
  color: ${theme.colors.textPrimary02};
  background: ${theme.colors.primary04};
`;

type ButtonIconStyleProps = {
  $isActive: boolean;
  $size?: 'small' | 'medium' | 'large';
  $lightBackground?: boolean;
};

export const largeStyles = ({ theme }: styleProps) => css`
  height: 32px;
  ${theme.typography.headings.headingMedium02}
`;

export const mediumStyles = ({ theme }: styleProps) => css`
  height: 24px;
  ${theme.typography.headings.headingMedium03}
`;

export const smallStyles = ({ theme }: styleProps) => css`
  height: 22px;
  ${theme.typography.headings.headingMedium04}
`;

export const lightBackgroundStyles = ({ theme }: styleProps) => css`
  background: ${theme.colors.white05};
`;

export const baseStyles = ({ theme }: styleProps) => css`
  padding: 0 0.5em;
  border-radius: 3px;
  color: ${theme.colors.textPrimary02};

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  // This allows icons to be have the correct amount of whitespace
  // when used as the first or last element in the button
  > svg {
    margin: -4px 0;
  }

  > svg:first-child {
    margin-left: -4px;
  }

  > svg:last-child {
    margin-right: -4px;
  }
`;

export const Button = styled(StyledButton)<ButtonIconStyleProps>(
  ({ $isActive, $lightBackground, $size = 'large' }) => css`
    ${baseStyles}

    ${$lightBackground && lightBackgroundStyles}

    ${$size === 'large' && largeStyles};
    ${$size === 'medium' && mediumStyles};
    ${$size === 'small' && smallStyles};

    ${$isActive && activeStyles}

    &:hover,
    &:focus-visible {
      ${$isActive ? activeStyles : hoverStyles}
    }

    &:active {
      ${activeStyles}
    }
  `
);
