import React from 'react';

export const SignOutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33333 20C4.97778 20 4.66667 19.8667 4.4 19.6C4.13333 19.3333 4 19.0222 4 18.6667V5.33333C4 4.97778 4.13333 4.66667 4.4 4.4C4.66667 4.13333 4.97778 4 5.33333 4H11.9778V5.33333H5.33333V18.6667H11.9778V20H5.33333ZM16.1333 15.8889L15.1778 14.9333L17.4444 12.6667H9.33333V11.3333H17.4L15.1333 9.06667L16.0889 8.11111L20 12.0222L16.1333 15.8889Z"
        fill="currentColor"
      />
    </svg>
  );
};
