import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const ScrollArea = styled.div`
  display: flex;
  overflow-y: auto;
  gap: 8px;
  scroll-snap-type: x proximity;
  margin-right: auto;
`;
