import {
  getNotificationTypesForTradeTab,
  hasSellerCounterpartyExpressedInterest,
  InboundTradeRequestWithSummary,
  TradeRequestTabType,
} from '@plus-platform/shared';
import noop from 'lodash/noop';
import React from 'react';

import { AvatarWithFallback } from '../../../components/AvatarWithFallback';
import { useUserContext } from '../../../contexts/UserContext';
import { useMarkAllNotificationsAsReadMutation } from '../../../hooks/queries/useMarkAllNotificationsAsReadMutation';
import { SidebarHeading, SidebarItem } from '../../TradeRequestPageLayout';
import { formatNameWithInitial, getSellerCounterparty } from '../../tradeRequestsUtils';
import * as Styles from '../Messenger.styles';
import { TradeRequestTabNotifications } from '../TradeRequestTabNotifications';
import { useMessagingNavigation } from './useMessagingNavigation';

type InboundMessagingContactTabsProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundMessagingContactTabs = (props: InboundMessagingContactTabsProps) => {
  const { counterparties, user } = props.tradeRequest;
  const { goToMessagingWithCounterparty, isCounterpartyMessagingActive } = useMessagingNavigation();
  const { userProfile } = useUserContext();
  const { mutateAsync: markAllNotificationsAsRead } = useMarkAllNotificationsAsReadMutation();

  const sellerCounterparty = getSellerCounterparty(counterparties);
  const buyerCounterparty = counterparties.find(
    (counterparty) => String(counterparty.user.id) === String(userProfile?.id)
  );

  if (
    !sellerCounterparty ||
    !buyerCounterparty ||
    !hasSellerCounterpartyExpressedInterest(sellerCounterparty.actions.map((action) => action.type))
  ) {
    return null;
  }

  const handleTabClick = () => {
    markAllNotificationsAsRead({
      types: getNotificationTypesForTradeTab(TradeRequestTabType.MESSAGES),
      tradeRequestId: props.tradeRequest.id,
      counterpartyId: buyerCounterparty.id,
    }).catch(noop);
    goToMessagingWithCounterparty(String(sellerCounterparty.id));
  };

  return (
    <SidebarItem>
      <SidebarHeading>Direct messages</SidebarHeading>
      <Styles.Tabs>
        <Styles.Tab>
          <Styles.TabButton
            $isActive={isCounterpartyMessagingActive(String(sellerCounterparty.id))}
            onClick={handleTabClick}
          >
            <AvatarWithFallback
              firstName={user.firstName}
              lastName={user.lastName}
              src={user.avatarUrl}
              size={24}
            />
            <Styles.TabButtonText>
              {formatNameWithInitial(user.lastName, user.firstName)}
            </Styles.TabButtonText>
            <TradeRequestTabNotifications
              counterpartyId={buyerCounterparty.id}
              notificationTypes={getNotificationTypesForTradeTab(TradeRequestTabType.MESSAGES)}
              tradeRequestId={props.tradeRequest.id}
            />
          </Styles.TabButton>
        </Styles.Tab>
      </Styles.Tabs>
    </SidebarItem>
  );
};
