import { TradeRequest, TradeRequestQuote } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type RejectTradeRequestQuoteMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeQuoteId: TradeRequestQuote['id'];
};

const rejectTradeRequestQuote = (data: RejectTradeRequestQuoteMutation) => {
  const { tradeQuoteId, tradeRequestId } = data;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/quotes/${tradeQuoteId}/reject`);

  return postRequestWithAuth(url);
};

export const useRejectTradeRequestQuoteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(rejectTradeRequestQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_INBOUND_QUOTES);
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_OUTBOUND_QUOTES);
    },
  });
};
