import styled from 'styled-components/macro';

import { BodyText } from '../../../components/BodyText.styles';

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 12px;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0 0 16px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div``;

export const DateDividerWrapper = styled.div`
  padding: 0 12px;
`;

export const Loading = styled.p`
  text-align: center;
  margin: 24px 0 8px;
`;

export const NoteText = styled(BodyText)`
  padding: 0 12px 12px 14px;
  margin: 0;
  color: ${({ theme }) => theme.colors.white70};
`;

export const NoteContentWrapper = styled.div`
  white-space: pre-wrap;
`;
