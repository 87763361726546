import React from 'react';

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="57"
      viewBox="0 0 68 57"
      width="68"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.9028 20.2676C36.8777 20.2676 39.2992 17.8465 39.2992 14.8721C39.2992 12.4511 37.7079 10.3759 35.494 9.68416V1.59097C35.494 0.691726 34.8022 0 33.9028 0C33.0033 0 32.3115 0.691726 32.3115 1.59097V9.68416C30.0976 10.3759 28.5063 12.4511 28.5063 14.8721C28.4371 17.8465 30.9278 20.2676 33.9028 20.2676ZM33.9028 12.6586C35.1481 12.6586 36.1167 13.627 36.1167 14.8721C36.1167 16.1172 35.1481 17.0856 33.9028 17.0856C32.6574 17.0856 31.6888 16.1172 31.6888 14.8721C31.6888 13.627 32.6574 12.6586 33.9028 12.6586Z"
        fill="currentColor"
      />
      <path
        d="M47.4623 20.2675C50.4373 20.2675 52.8588 17.8464 52.8588 14.872C52.8588 11.8976 50.4373 9.47656 47.4623 9.47656C44.4874 9.47656 42.0659 11.8976 42.0659 14.872C41.9967 17.8464 44.4874 20.2675 47.4623 20.2675ZM47.4623 12.6585C48.7077 12.6585 49.6763 13.6269 49.6763 14.872C49.6763 16.1171 48.7077 17.0855 47.4623 17.0855C46.217 17.0855 45.2484 16.1171 45.2484 14.872C45.2484 13.6269 46.217 12.6585 47.4623 12.6585Z"
        fill="currentColor"
      />
      <path
        d="M20.3422 20.2675C23.3172 20.2675 25.7387 17.8464 25.7387 14.872C25.7387 11.8976 23.3172 9.47656 20.3422 9.47656C17.3672 9.47656 14.9457 11.8976 14.9457 14.872C14.8766 17.8464 17.3672 20.2675 20.3422 20.2675ZM20.3422 12.6585C21.5875 12.6585 22.5561 13.6269 22.5561 14.872C22.5561 16.1171 21.5875 17.0855 20.3422 17.0855C19.0969 17.0855 18.1283 16.1171 18.1283 14.872C18.1283 13.6269 19.0969 12.6585 20.3422 12.6585Z"
        fill="currentColor"
      />
      <path
        d="M65.7289 26.8392H59.433C58.7412 24.6256 56.6656 23.0347 54.2441 23.0347C51.2691 23.0347 48.8477 25.4557 48.8477 28.4301C48.8477 31.4045 51.2691 33.8256 54.2441 33.8256C56.6656 33.8256 58.7412 32.2346 59.433 30.0211H65.7289C66.6283 30.0211 67.3202 29.3294 67.3202 28.4301C67.3202 27.5309 66.6283 26.8392 65.7289 26.8392ZM54.2441 30.6437C52.9988 30.6437 52.0302 29.6752 52.0302 28.4301C52.0302 27.185 52.9988 26.2166 54.2441 26.2166C55.4895 26.2166 56.4581 27.185 56.4581 28.4301C56.4581 29.6752 55.4895 30.6437 54.2441 30.6437Z"
        fill="currentColor"
      />
      <path
        d="M32.5195 28.4301C32.5195 25.4557 30.098 23.0347 27.123 23.0347C24.1481 23.0347 21.7266 25.4557 21.7266 28.4301C21.7266 31.4045 24.1481 33.8256 27.123 33.8256C30.098 33.8256 32.5195 31.4045 32.5195 28.4301ZM27.123 30.6437C25.8777 30.6437 24.9091 29.6752 24.9091 28.4301C24.9091 27.185 25.8777 26.2166 27.123 26.2166C28.3684 26.2166 29.337 27.185 29.337 28.4301C29.337 29.6752 28.3684 30.6437 27.123 30.6437Z"
        fill="currentColor"
      />
      <path
        d="M46.08 28.4301C46.08 25.4557 43.6585 23.0347 40.6836 23.0347C37.7086 23.0347 35.2871 25.4557 35.2871 28.4301C35.2871 31.4045 37.7086 33.8256 40.6836 33.8256C43.6585 33.8256 46.08 31.4045 46.08 28.4301ZM40.6836 30.6437C39.4382 30.6437 38.4696 29.6752 38.4696 28.4301C38.4696 27.185 39.4382 26.2166 40.6836 26.2166C41.9289 26.2166 42.8975 27.185 42.8975 28.4301C42.8975 29.6752 41.9289 30.6437 40.6836 30.6437Z"
        fill="currentColor"
      />
      <path
        d="M18.9588 28.4301C18.9588 25.4557 16.5373 23.0347 13.5624 23.0347C11.1409 23.0347 9.06532 24.6256 8.37346 26.8392H2.07759C1.17818 26.8392 0.486328 27.5309 0.486328 28.4301C0.486328 29.3294 1.17818 30.0211 2.07759 30.0211H8.37346C9.06532 32.2346 11.1409 33.8256 13.5624 33.8256C16.5373 33.8256 18.9588 31.4045 18.9588 28.4301ZM13.5624 30.6437C12.317 30.6437 11.3484 29.6752 11.3484 28.4301C11.3484 27.185 12.317 26.2166 13.5624 26.2166C14.8077 26.2166 15.7763 27.185 15.7763 28.4301C15.7763 29.6752 14.8077 30.6437 13.5624 30.6437Z"
        fill="currentColor"
      />
      <path
        d="M33.9023 36.5229C30.9274 36.5229 28.5059 38.944 28.5059 41.9184C28.5059 44.3395 30.0971 46.4146 32.3111 47.1064V55.1996C32.3111 56.0988 33.0029 56.7905 33.9023 56.7905C34.8017 56.7905 35.4936 56.0988 35.4936 55.1996V47.1755C37.7075 46.4838 39.2988 44.4086 39.2988 41.9876C39.2988 39.0132 36.8773 36.5229 33.9023 36.5229ZM33.9023 44.2011C32.657 44.2011 31.6884 43.2327 31.6884 41.9876C31.6884 40.7425 32.657 39.7741 33.9023 39.7741C35.1477 39.7741 36.1163 40.7425 36.1163 41.9876C36.1163 43.2327 35.1477 44.2011 33.9023 44.2011Z"
        fill="currentColor"
      />
      <path
        d="M47.4629 36.5229C44.4879 36.5229 42.0664 38.944 42.0664 41.9184C42.0664 44.8928 44.4879 47.3139 47.4629 47.3139C50.4378 47.3139 52.8593 44.8928 52.8593 41.9184C52.8593 39.0132 50.4378 36.5229 47.4629 36.5229ZM47.4629 44.2011C46.2175 44.2011 45.2489 43.2327 45.2489 41.9876C45.2489 40.7425 46.2175 39.7741 47.4629 39.7741C48.7082 39.7741 49.6768 40.7425 49.6768 41.9876C49.6768 43.2327 48.7082 44.2011 47.4629 44.2011Z"
        fill="currentColor"
      />
      <path
        d="M20.3427 36.5229C17.3678 36.5229 14.9463 38.944 14.9463 41.9184C14.9463 44.8928 17.3678 47.3139 20.3427 47.3139C23.3177 47.3139 25.7392 44.8928 25.7392 41.9184C25.7392 39.0132 23.3177 36.5229 20.3427 36.5229ZM20.3427 44.2011C19.0974 44.2011 18.1288 43.2327 18.1288 41.9876C18.1288 40.7425 19.0974 39.7741 20.3427 39.7741C21.5881 39.7741 22.5567 40.7425 22.5567 41.9876C22.5567 43.2327 21.5881 44.2011 20.3427 44.2011Z"
        fill="currentColor"
      />
    </svg>
  );
};
