import { PortfolioAnalyticMetric } from '@plus-platform/shared';

import { formatCompactMonetary, formatPercentage } from '../../utils/formatUtils';
import {
  Bubble,
  BubbleWrapper,
  ChartWrapper,
  LoansLabel,
  ValueLabel,
} from './AnalyticsBubbleChart.styles';
import { AnalyticsChartProps, LabelsFormatter } from './utils';

const BUBBLE_SIZE_THRESHOLD = 75;
const BUBBLE_MIN_SIZE = 30;

const bubbleChartsLabelFormatters: Partial<Record<PortfolioAnalyticMetric, LabelsFormatter>> = {
  [PortfolioAnalyticMetric.DELINQUENCY_RATE]: ({ datum }) => formatPercentage(datum.value),
  [PortfolioAnalyticMetric.UPB]: ({ datum }) => formatCompactMonetary(datum.value),
};

const normalizeValue = (value: number, max: number) => {
  if (max === 0) {
    return 0;
  }
  return value / max;
};

export const AnalyticsBubbleChart = ({ data, metric, style }: AnalyticsChartProps) => {
  const sortedData = [...data].sort((d1, d2) => d2.value - d1.value);

  const maxValue = Math.max(...sortedData.map((d) => d.value));

  return (
    <ChartWrapper style={style}>
      {sortedData.map((d, index) => {
        const normalizedValue = normalizeValue(d.value, maxValue);
        const bubbleSize = Math.max(normalizedValue * BUBBLE_SIZE_THRESHOLD, BUBBLE_MIN_SIZE);
        const formattedValue =
          bubbleChartsLabelFormatters?.[metric]?.({ datum: { value: d.value } }) || d.value;

        return (
          <BubbleWrapper key={`${d.groupedByValue}-${index}`}>
            <Bubble size={bubbleSize}>{formattedValue}</Bubble>
            <ValueLabel>{d.groupedByValue}</ValueLabel>
            <LoansLabel>{d.loansCount} loans</LoansLabel>
          </BubbleWrapper>
        );
      })}
    </ChartWrapper>
  );
};
