import { FundsSourceType } from '../funds';
import { Name } from '../name';
import {
  MISMOAmount,
  MISMOEntity,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum BuydownFundingType {
  Financed = 'Financed',
  IncreaseInInterestRate = 'IncreaseInInterestRate',
  LumpSumCash = 'LumpSumCash',
  Other = 'Other',
}

export type BuydownFund = MISMOEntity &
  Partial<{
    buydownAmount: MISMOAmount;
    buydownFundingType: BuydownFundingType;
    buydownFundingTypeOtherDescription: MISMOString;
    buydownPercent: MISMOPercent;
    fundsSourceType: FundsSourceType;
    fundsSourceTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    name: Name;
  }>;
