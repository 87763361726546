import propertyExternalPhoto1 from './property-external-1.jpg';
import propertyExternalPhoto2 from './property-external-2.jpg';
import propertyExternalPhoto3 from './property-external-3.jpg';
import propertyExternalPhoto4 from './property-external-4.jpg';
import propertyExternalPhoto5 from './property-external-5.jpg';
import propertyExternalPhoto6 from './property-external-6.jpg';
import propertyExternalPhoto7 from './property-external-7.jpg';
import propertyExternalPhoto8 from './property-external-8.jpg';
import propertyExternalPhoto9 from './property-external-9.jpg';
import propertyExternalPhoto10 from './property-external-10.jpg';
import propertyExternalPhoto11 from './property-external-11.jpg';
import propertyExternalPhoto12 from './property-external-12.jpg';
import propertyExternalPhoto13 from './property-external-13.jpg';

export const propertyExternalPhotos = [
  propertyExternalPhoto1,
  propertyExternalPhoto2,
  propertyExternalPhoto3,
  propertyExternalPhoto4,
  propertyExternalPhoto5,
  propertyExternalPhoto6,
  propertyExternalPhoto7,
  propertyExternalPhoto8,
  propertyExternalPhoto9,
  propertyExternalPhoto10,
  propertyExternalPhoto11,
  propertyExternalPhoto12,
  propertyExternalPhoto13,
];

export const getPropertyPhotoForLoan = (loanId: number) => {
  const safeLoanId = Number.isInteger(loanId) ? loanId : 1;
  return propertyExternalPhotos[safeLoanId % propertyExternalPhotos.length];
};
