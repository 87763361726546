import { GFEAggregationType } from './compensation';
import { FeePayment } from './feePayment';
import {
  IntegratedDisclosureSectionType,
  IntegratedDisclosureSubsectionType,
} from './integratedDisclosure';
import { FeePaidToType, PaidTo } from './paidTo';
import { SelectedServiceProvider } from './serviceProvider';
import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

export enum FeePercentBasisType {
  BaseLoanAmount = 'BaseLoanAmount',
  OriginalLoanAmount = 'OriginalLoanAmount',
  Other = 'Other',
  PropertyAppraisedValueAmount = 'PropertyAppraisedValueAmount',
  PurchasePriceAmount = 'PurchasePriceAmount',
}

export enum FeeProcessType {
  Origination = 'Origination',
  Other = 'Other',
  Servicing = 'Servicing',
}

export enum FeeType {
  '203KConsultantFee' = '203KConsultantFee',
  '203KDiscountOnRepairs' = '203KDiscountOnRepairs',
  '203KInspectionFee' = '203KInspectionFee',
  '203KPermits' = '203KPermits',
  '203KSupplementalOriginationFee' = '203KSupplementalOriginationFee',
  '203KTitleUpdate' = '203KTitleUpdate',
  ApplicationFee = 'ApplicationFee',
  AppraisalDeskReviewFee = 'AppraisalDeskReviewFee',
  AppraisalFee = 'AppraisalFee',
  AppraisalFieldReviewFee = 'AppraisalFieldReviewFee',
  AppraisalManagementCompanyFee = 'AppraisalManagementCompanyFee',
  ARMConversionFee = 'ARMConversionFee',
  AsbestosInspectionFee = 'AsbestosInspectionFee',
  AssignmentPreparationFee = 'AssignmentPreparationFee',
  AssumptionFee = 'AssumptionFee',
  AttorneyFee = 'AttorneyFee',
  AutomatedUnderwritingFee = 'AutomatedUnderwritingFee',
  AVMFee = 'AVMFee',
  BankruptcyMonitoringFee = 'BankruptcyMonitoringFee',
  BondFee = 'BondFee',
  BondReviewFee = 'BondReviewFee',
  CertificationFee = 'CertificationFee',
  ChosenInterestRateCreditOrChargeTotal = 'ChosenInterestRateCreditOrChargeTotal',
  CommitmentFee = 'CommitmentFee',
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  ConstructionHandlingFee = 'ConstructionHandlingFee',
  ConstructionInspectionFee = 'ConstructionInspectionFee',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  CopyOrFaxFee = 'CopyOrFaxFee',
  CourierFee = 'CourierFee',
  CreditDisabilityInsurancePremium = 'CreditDisabilityInsurancePremium',
  CreditLifeInsurancePremium = 'CreditLifeInsurancePremium',
  CreditPropertyInsurancePremium = 'CreditPropertyInsurancePremium',
  CreditReportFee = 'CreditReportFee',
  CreditUnemploymentInsurancePremium = 'CreditUnemploymentInsurancePremium',
  DebtCancellationInsurancePremium = 'DebtCancellationInsurancePremium',
  DebtSuspensionInsurancePremium = 'DebtSuspensionInsurancePremium',
  DeedPreparationFee = 'DeedPreparationFee',
  DisasterInspectionFee = 'DisasterInspectionFee',
  DiscountOnRepairsFee = 'DiscountOnRepairsFee',
  DocumentaryStampFee = 'DocumentaryStampFee',
  DocumentPreparationFee = 'DocumentPreparationFee',
  DownPaymentProtectionFee = 'DownPaymentProtectionFee',
  DryWallInspectionFee = 'DryWallInspectionFee',
  ElectricalInspectionFee = 'ElectricalInspectionFee',
  ElectronicDocumentDeliveryFee = 'ElectronicDocumentDeliveryFee',
  EnvironmentalInspectionFee = 'EnvironmentalInspectionFee',
  EscrowHoldbackFee = 'EscrowHoldbackFee',
  EscrowServiceFee = 'EscrowServiceFee',
  EscrowWaiverFee = 'EscrowWaiverFee',
  FilingFee = 'FilingFee',
  FloodCertification = 'FloodCertification',
  FoundationInspectionFee = 'FoundationInspectionFee',
  HeatingCoolingInspectionFee = 'HeatingCoolingInspectionFee',
  HELOCAnnualFee = 'HELOCAnnualFee',
  HELOCOverLimitFee = 'HELOCOverLimitFee',
  HighCostMortgageCounselingFee = 'HighCostMortgageCounselingFee',
  HomeInspectionFee = 'HomeInspectionFee',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  HomeownersAssociationServiceFee = 'HomeownersAssociationServiceFee',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeWarrantyFee = 'HomeWarrantyFee',
  LateCharge = 'LateCharge',
  LeadInspectionFee = 'LeadInspectionFee',
  LendersAttorneyFee = 'LendersAttorneyFee',
  LoanDiscountPoints = 'LoanDiscountPoints',
  LoanLevelPriceAdjustment = 'LoanLevelPriceAdjustment',
  LoanOriginationFee = 'LoanOriginationFee',
  LoanOriginatorCompensation = 'LoanOriginatorCompensation',
  ManualUnderwritingFee = 'ManualUnderwritingFee',
  ManufacturedHousingInspectionFee = 'ManufacturedHousingInspectionFee',
  ManufacturedHousingProcessingFee = 'ManufacturedHousingProcessingFee',
  MERSRegistrationFee = 'MERSRegistrationFee',
  MIInitialPremium = 'MIInitialPremium',
  MIUpfrontPremium = 'MIUpfrontPremium',
  ModificationFee = 'ModificationFee',
  MoldInspectionFee = 'MoldInspectionFee',
  MortgageBrokerFee = 'MortgageBrokerFee',
  MortgageSurchargeCountyOrParish = 'MortgageSurchargeCountyOrParish',
  MortgageSurchargeMunicipal = 'MortgageSurchargeMunicipal',
  MortgageSurchargeState = 'MortgageSurchargeState',
  MortgageTaxCreditServiceFee = 'MortgageTaxCreditServiceFee',
  MultipleLoansClosingFee = 'MultipleLoansClosingFee',
  MunicipalLienCertificateFee = 'MunicipalLienCertificateFee',
  NotaryFee = 'NotaryFee',
  NSFFee = 'NSFFee',
  Other = 'Other',
  OurOriginationChargeTotal = 'OurOriginationChargeTotal',
  PartialLienReleaseFee = 'PartialLienReleaseFee',
  PayoffRequestFee = 'PayoffRequestFee',
  PestInspectionFee = 'PestInspectionFee',
  PlumbingInspectionFee = 'PlumbingInspectionFee',
  PowerOfAttorneyPreparationFee = 'PowerOfAttorneyPreparationFee',
  PowerOfAttorneyRecordingFee = 'PowerOfAttorneyRecordingFee',
  PreclosingVerificationControlFee = 'PreclosingVerificationControlFee',
  ProcessingFee = 'ProcessingFee',
  ProgramGuaranteeFee = 'ProgramGuaranteeFee',
  PropertyInspectionWaiverFee = 'PropertyInspectionWaiverFee',
  RadonInspectionFee = 'RadonInspectionFee',
  RateLockFee = 'RateLockFee',
  RealEstateCommissionBuyersBroker = 'RealEstateCommissionBuyersBroker',
  RealEstateCommissionSellersBroker = 'RealEstateCommissionSellersBroker',
  RecastFee = 'RecastFee',
  ReconveyanceFee = 'ReconveyanceFee',
  ReconveyanceTrackingFee = 'ReconveyanceTrackingFee',
  RecordingFeeForAssignment = 'RecordingFeeForAssignment',
  RecordingFeeForDeed = 'RecordingFeeForDeed',
  RecordingFeeForMortgage = 'RecordingFeeForMortgage',
  RecordingFeeForMunicipalLienCertificate = 'RecordingFeeForMunicipalLienCertificate',
  RecordingFeeForOtherDocument = 'RecordingFeeForOtherDocument',
  RecordingFeeForRelease = 'RecordingFeeForRelease',
  RecordingFeeForSubordination = 'RecordingFeeForSubordination',
  RecordingFeeTotal = 'RecordingFeeTotal',
  RecordingServiceFee = 'RecordingServiceFee',
  RedrawFee = 'RedrawFee',
  ReinspectionFee = 'ReinspectionFee',
  RenovationConsultantFee = 'RenovationConsultantFee',
  RepairsFee = 'RepairsFee',
  RoofInspectionFee = 'RoofInspectionFee',
  SepticInspectionFee = 'SepticInspectionFee',
  SettlementFee = 'SettlementFee',
  SigningAgentFee = 'SigningAgentFee',
  SmokeDetectorInspectionFee = 'SmokeDetectorInspectionFee',
  StateTitleInsuranceFee = 'StateTitleInsuranceFee',
  StructuralInspectionFee = 'StructuralInspectionFee',
  SubordinationFee = 'SubordinationFee',
  SurveyFee = 'SurveyFee',
  TaxServiceFee = 'TaxServiceFee',
  TaxStampForCityDeed = 'TaxStampForCityDeed',
  TaxStampForCityMortgage = 'TaxStampForCityMortgage',
  TaxStampForCountyDeed = 'TaxStampForCountyDeed',
  TaxStampForCountyMortgage = 'TaxStampForCountyMortgage',
  TaxStampForStateDeed = 'TaxStampForStateDeed',
  TaxStampForStateMortgage = 'TaxStampForStateMortgage',
  TaxStatusResearchFee = 'TaxStatusResearchFee',
  TemporaryBuydownAdministrationFee = 'TemporaryBuydownAdministrationFee',
  TemporaryBuydownPoints = 'TemporaryBuydownPoints',
  TitleAbstractFee = 'TitleAbstractFee',
  TitleBorrowerClosingProtectionLetterFee = 'TitleBorrowerClosingProtectionLetterFee',
  TitleCertificationFee = 'TitleCertificationFee',
  TitleClosingCoordinationFee = 'TitleClosingCoordinationFee',
  TitleClosingFee = 'TitleClosingFee',
  TitleClosingProtectionLetterFee = 'TitleClosingProtectionLetterFee',
  TitleCommitmentFee = 'TitleCommitmentFee',
  TitleDocumentPreparationFee = 'TitleDocumentPreparationFee',
  TitleEndorsementFee = 'TitleEndorsementFee',
  TitleExaminationFee = 'TitleExaminationFee',
  TitleFinalPolicyShortFormFee = 'TitleFinalPolicyShortFormFee',
  TitleInsuranceBinderFee = 'TitleInsuranceBinderFee',
  TitleInsuranceFee = 'TitleInsuranceFee',
  TitleLendersCoveragePremium = 'TitleLendersCoveragePremium',
  TitleNotaryFee = 'TitleNotaryFee',
  TitleOwnersCoveragePremium = 'TitleOwnersCoveragePremium',
  TitleSearchFee = 'TitleSearchFee',
  TitleServicesFeeTotal = 'TitleServicesFeeTotal',
  TitleServicesSalesTax = 'TitleServicesSalesTax',
  TitleSubEscrowFee = 'TitleSubEscrowFee',
  TitleSubordinationProcessingFee = 'TitleSubordinationProcessingFee',
  TitleUnderwritingIssueResolutionFee = 'TitleUnderwritingIssueResolutionFee',
  TransferTaxTotal = 'TransferTaxTotal',
  UnderwritingFee = 'UnderwritingFee',
  USDARuralDevelopmentGuaranteeFee = 'USDARuralDevelopmentGuaranteeFee',
  VAFundingFee = 'VAFundingFee',
  VerificationOfAssetsFee = 'VerificationOfAssetsFee',
  VerificationOfEmploymentFee = 'VerificationOfEmploymentFee',
  VerificationOfIncomeFee = 'VerificationOfIncomeFee',
  VerificationOfResidencyStatusFee = 'VerificationOfResidencyStatusFee',
  VerificationOfTaxpayerIdentificationFee = 'VerificationOfTaxpayerIdentificationFee',
  VerificationOfTaxReturnFee = 'VerificationOfTaxReturnFee',
  WaterTestingFee = 'WaterTestingFee',
  WellInspectionFee = 'WellInspectionFee',
  WireTransferFee = 'WireTransferFee',
}

enum SectionClassificationType {
  'SectionClassificationType100' = '100',
  'SectionClassificationType1000' = '1000',
  'SectionClassificationType1100' = '1100',
  'SectionClassificationType1200' = '1200',
  'SectionClassificationType1300' = '1300',
  'SectionClassificationType200' = '200',
  'SectionClassificationType300' = '300',
  'SectionClassificationType400' = '400',
  'SectionClassificationType500' = '500',
  'SectionClassificationType600' = '600',
  'SectionClassificationType700' = '700',
  'SectionClassificationType800' = '800',
  'SectionClassificationType900' = '900',
}

// NOTE enums doesnt allow numeric values as keys, below func gets us the value from
// the above enum based on a prefix without having to scatter this around the codebase
export const getSectionClassificationType = (
  key: string
): SectionClassificationType => {
  const formattedKey =
    `SectionClassificationType${key}` as keyof typeof SectionClassificationType;
  return SectionClassificationType[formattedKey];
};

export type Fee = MISMOEntity &
  Partial<{
    borrowerChosenProviderIndicator: MISMOIndicator;
    feeActualTotalAmount: MISMOAmount;
    feeAssessedAmount: MISMOAmount;
    feeAssessedDate: MISMODate;
    feeCollectedPostConsummationIndicator: MISMOIndicator;
    feeEstimatedTotalAmount: MISMOAmount;
    feePaidToType: FeePaidToType;
    feePaidToTypeOtherDescription: MISMOString;
    feePercentBasisType: FeePercentBasisType;
    feePercentBasisTypeOtherDescription: MISMOString;
    feeProcessType: FeeProcessType;
    feeProcessTypeOtherDescription: MISMOString;
    feeSpecifiedFixedAmount: MISMOAmount;
    feeSpecifiedHUD1LineNumberValue: MISMOValue;
    feeSpecifiedLineNumberValue: MISMOValue;
    feeTotalPercent: MISMOPercent;
    feeType: FeeType;
    'feeType@DisplayLabelText': MISMOString;
    feeTypeAdditionalDescription: MISMOString;
    feeTypeOtherDescription: MISMOString;
    GFEAggregationType: GFEAggregationType;
    GFEAggregationTypeOtherDescription: MISMOString;
    GFEDisclosedFeeAmount: MISMOAmount;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    integratedDisclosureSubsectionType: IntegratedDisclosureSubsectionType;
    integratedDisclosureSubsectionTypeOtherDescription: MISMOString;
    optionalCostIndicator: MISMOString;
    regulationZPointsAndFeesIndicator: MISMOIndicator;
    requiredProviderOfServiceIndicator: MISMOIndicator;
    sectionClassificationType: SectionClassificationType;
    feePayments: FeePayment[];
    // feeWaivers: FeeWaiver[]
    paidTo: PaidTo;
    selectedServiceProvider: SelectedServiceProvider;
    sequenceNumber: MISMOSequenceNumber;
  }>;
