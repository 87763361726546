import {
  InboundTradeRequestWithSummary,
  TradeCounterpartyActionHistoryFilter,
  TradeCounterpartyActionType,
} from '@plus-platform/shared';
import React from 'react';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { ButtonLoader } from '../../../components/ButtonLoader';
import { Heading } from '../../../components/Heading';
import { MessageActions, MessageText, PlusMessage } from '../../../components/Messages';
import { DateDivider } from '../../../components/Messages/DateDivider';
import {
  isMessageDate,
  isMessageInboundCTA,
  shouldDisplayDateDivider,
} from '../../../components/Messages/messengerUtils';
import {
  DateMessage,
  InboundActionMessage,
  InboundCTAMessage,
  InboundMessage,
  MessageType,
} from '../../../components/Messages/types';
import { useUserContext } from '../../../contexts/UserContext';
import { useTradeRequestDataRequestMutation } from '../../../hooks/queries';
import { canCounterpartyRequestData } from '../../tradeRequestsUtils';
import {
  getActionsFromData,
  useTradeRequestInboundActionsHistory,
} from '../../useTradeRequestInboundActionsHistory';
import * as Styles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import { InboundDataRequestAccepted } from './Messages/InboundDataRequestAccepted';
import { InboundDataRequestDenied } from './Messages/InboundDataRequestDenied';
import { InboundDataRequestRequested } from './Messages/InboundDataRequestRequested';

type InboundTradeRequestDataRequestsProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundTradeRequestDataRequests = (props: InboundTradeRequestDataRequestsProps) => {
  const { tradeRequest } = props;
  const [canRequestData, setCanRequestData] = React.useState(false);
  const { isUserDataLoading, userProfile } = useUserContext();

  const { actions, fetchMoreActions, isLoading } = useTradeRequestInboundActionsHistory(
    String(tradeRequest.id),
    TradeCounterpartyActionHistoryFilter.DATA_REQUEST,
    (data) => {
      const actions = getActionsFromData(data);
      setCanRequestData(canCounterpartyRequestData(actions));
    }
  );

  const { isLoading: isRequestingData, mutateAsync: requestTradeRequestData } =
    useTradeRequestDataRequestMutation();

  let messageList = React.useMemo(
    () =>
      actions.flatMap((action, index) => {
        const previousAction = actions[index - 1];
        let messages: InboundMessage[] = [];

        if (
          action.createdAt &&
          shouldDisplayDateDivider(action.createdAt, previousAction?.createdAt)
        ) {
          messages = [
            ...messages,
            {
              type: MessageType.DATE_DIVIDER,
              data: { date: new Date(action.createdAt) },
            },
          ];
        }

        if (
          [
            TradeCounterpartyActionType.REQUEST_DATA_REQUEST,
            TradeCounterpartyActionType.ACCEPT_DATA_REQUEST,
            TradeCounterpartyActionType.DENY_DATA_REQUEST,
          ].includes(action.type) &&
          userProfile
        ) {
          const message: InboundActionMessage = {
            type: MessageType.ACTION,
            data: { action, tradeRequest, userProfile },
          };

          messages = [...messages, message];
        }

        return messages;
      }),
    [actions, tradeRequest, userProfile]
  );

  if (canRequestData) {
    const message: InboundCTAMessage = {
      type: MessageType.CTA,
    };
    messageList = [...messageList, message];
  }

  return (
    <ActivityIndicator contain isActive={isLoading || isUserDataLoading}>
      <Styles.Header>
        <Heading $size="medium">Data requests</Heading>
        <Styles.IntroText>
          This is the very beginning of Data requests on trading pool{' '}
          <strong>{tradeRequest.pool.name}</strong> ticket ID <strong>{tradeRequest.id}</strong>.
        </Styles.IntroText>
      </Styles.Header>
      <MessengerList loadMore={fetchMoreActions} messages={messageList}>
        {({ index, message }) => {
          const previousMessage = messageList[index - 1];
          const isPreviousMessageDenyDataRequest =
            previousMessage?.type === MessageType.ACTION
              ? previousMessage?.data?.action?.type ===
                TradeCounterpartyActionType.DENY_DATA_REQUEST
              : false;

          return (
            <React.Fragment>
              {isMessageDate(message) && (
                <DateDivider
                  key={`${(message as DateMessage).data.date}`}
                  variant="outline"
                  {...(message as DateMessage).data}
                />
              )}
              {message.type === MessageType.ACTION && (
                <React.Fragment>
                  {message.data.action.type ===
                    TradeCounterpartyActionType.REQUEST_DATA_REQUEST && (
                    <InboundDataRequestRequested {...message.data} />
                  )}
                  {message.data.action.type === TradeCounterpartyActionType.ACCEPT_DATA_REQUEST && (
                    <InboundDataRequestAccepted {...message.data} />
                  )}
                  {message.data.action.type === TradeCounterpartyActionType.DENY_DATA_REQUEST && (
                    <InboundDataRequestDenied {...message.data} />
                  )}
                </React.Fragment>
              )}
              {isMessageInboundCTA(message) && !isPreviousMessageDenyDataRequest && (
                <PlusMessage>
                  <MessageText>
                    You're now able to request access to all of the relevant data in the pool{' '}
                    <strong>{tradeRequest.pool.name}</strong> ticket ID{' '}
                    <strong>{tradeRequest.id}</strong>. The data will be Privacy Sanitised, i.e.
                    stripped of the personal details of all borrowers to protect their privacy.
                  </MessageText>
                  <MessageActions>
                    <ButtonLoader
                      $size="large"
                      isLoading={isRequestingData}
                      onClick={async () => await requestTradeRequestData(tradeRequest.id)}
                    >
                      Request data access
                    </ButtonLoader>
                  </MessageActions>
                </PlusMessage>
              )}
            </React.Fragment>
          );
        }}
      </MessengerList>
    </ActivityIndicator>
  );
};
