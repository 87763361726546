import React from 'react';

import { Button } from '../components/Button';
import { AddNotePopup } from './AddNotePopup';

type AddNoteButtonProps = {
  onClose?: () => void;
  loanNumber: string;
};

export const AddNoteButton = ({ loanNumber, onClose }: AddNoteButtonProps) => {
  const [isAddNotePopupDisplayed, setIsAddNotePopupDisplayed] = React.useState(false);

  return (
    <React.Fragment>
      {isAddNotePopupDisplayed && (
        <AddNotePopup
          loanNumber={loanNumber}
          onClose={() => {
            onClose?.();
            setIsAddNotePopupDisplayed(false);
          }}
        />
      )}
      <Button $size="xSmall" $variant="text" onClick={() => setIsAddNotePopupDisplayed(true)}>
        Add note
      </Button>
    </React.Fragment>
  );
};
