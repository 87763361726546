/**
 * Primaries
 */

export const primary01 = '#111322';
export const primary02 = '#1B1F37';
export const primary03 = '#262A41';
export const primary04 = '#31354B';
export const primary05 = '#3D4155';
export const primary06 = '#474B5E';
export const primary07 = '#BBBBC0';
export const primary08 = '#C7C7CB';
export const primary09 = '#E7E7EC';
export const primary10 = '#FFFFFF';

/**
 * Colors
 */

export const statusYellow = '#FFD233';
export const statusOrange = '#F27622';
export const statusRed = '#DE4137';
export const statusTurquoise = '#009DA7';
export const statusGreen = '#007854';
export const statusBlue = '#1D7BE9';
export const statusWhite = '#FFFFFF';
export const statusBlack = '#111322';
export const statusLightGrey = '#E7E7EC';
export const statusDarkGrey = '#C7C7CB';

/**
 * Accents
 */

export const accent01 = '#00E09D';

/**
 * Backgrounds
 */

export const background01 = primary01;

/**
 * Text Primary
 */

export const textPrimary01 = primary10;
export const textPrimary02 = primary09;
export const textPrimary03 = primary07;

/**
 * Text Secondary
 */

export const textSecondary01 = primary01;
export const textSecondary02 = primary06;
export const textSecondary03 = primary07;

/**
 * DEPRECATED: Primary (Blues)
 */

export const deprecatedPrimary01 = '#1B1F37';
export const deprecatedPrimary02 = '#242A4E';
export const deprecatedPrimary03 = '#2D335B';

/**
 * Whites
 */

export const white = 'rgba(255, 255, 255, 1)';
export const white90 = 'rgba(255, 255, 255, 0.9)';
export const white80 = 'rgba(255, 255, 255, 0.8)';
export const white70 = 'rgba(255, 255, 255, 0.7)';
export const white60 = 'rgba(255, 255, 255, 0.6)';
export const white50 = 'rgba(255, 255, 255, 0.5)';
export const white40 = 'rgba(255, 255, 255, 0.4)';
export const white30 = 'rgba(255, 255, 255, 0.3)';
export const white20 = 'rgba(255, 255, 255, 0.2)';
export const white15 = 'rgba(255, 255, 255, 0.15)';
export const white10 = 'rgba(255, 255, 255, 0.1)';
export const white05 = 'rgba(255, 255, 255, 0.05)';

/**
 * DEPRECATED: Blues
 */

export const blue = 'rgba(17, 19, 34, 1)';
export const blue90 = 'rgba(17, 19, 34, 0.9)';
export const blue80 = 'rgba(17, 19, 34, 0.8)';
export const blue70 = 'rgba(17, 19, 34, 0.7)';
export const blue60 = 'rgba(17, 19, 34, 0.6)';
export const blue50 = 'rgba(17, 19, 34, 0.5)';
export const blue40 = 'rgba(17, 19, 34, 0.4)';
export const blue30 = 'rgba(17, 19, 34, 0.3)';
export const blue20 = 'rgba(17, 19, 34, 0.2)';
export const blue15 = 'rgba(17, 19, 34, 0.15)';
export const blue10 = 'rgba(17, 19, 34, 0.1)';
export const blue05 = 'rgba(17, 19, 34, 0.05)';

/**
 * DEPRECATED
 */

export const lightGreen = '#00E09D';

/**
 * Flags, alerts, warnings
 */

export const flag = statusBlue;
export const alert = statusOrange;
export const warning = statusRed;

/**
 * Defaults
 */

export const delinquency30Days = statusYellow;
export const delinquency60Days = statusOrange;
export const delinquency90Days = statusRed;

/**
 * Toggle Switch
 */

export const switchOn = accent01;
export const switchOff = primary04;

/**
 * Text
 */

export const text01 = white;
export const textNavigation = textPrimary02;
export const textNavigationActive = textPrimary01;
export const textGraduatedRangeLabel = white;

/**
 * Anchors
 */

export const anchorColor = textPrimary01;
export const anchorColorHover = accent01;
export const anchorColorActive = accent01;
export const anchorBGColor = 'transparent';
export const anchorBGColorHover = primary03;
export const anchorBGColorActive = primary04;

// TODO: Temporary - replace with $elevation prop
export const anchorLightBGColorHover = primary04;
export const anchorLightBGColorActive = primary05;

/**
 * Selects
 */

export const selectOptionText = textPrimary01;
export const selectOptionTextSelected = accent01;
export const selectOptionTextSelectedInvalid = statusOrange;
export const selectOptionBackground = 'transparent';
export const selectOptionBackgroundSelected = primary04;

/**
 * Inputs
 */

export const inputBackground = primary03;
export const inputBackgroundHover = primary04;
export const inputBackgroundActive = primary03;
export const inputBorderHover = accent01;
export const inputBorderActive = accent01;
export const inputBorderInvalidHover = statusOrange;
export const inputBorderInvalidActive = statusOrange;
export const inputCaret = accent01;
export const inputCaretInvalid = statusOrange;
export const inputLabelText = textPrimary02;
export const inputText = textPrimary01;

/**
 * Backgrounds
 */

export const backgroundHeader = background01;
export const backgroundPage = background01;
// TODO: replace by correct color as this one is apparently deprecated
export const backgroundPageSection = '#171A2E';
export const backgroundNavigationActive = deprecatedPrimary01;
export const backgroundNavigationHover = white15;

export const backgroundButtonHover = white15;
export const backgroundButtonLight = white;

export const backgroundButtonIconToggleActive = white;
export const backgroundButtonIconToggleHover = white15;

export const backgroundHeaderSearchBar = deprecatedPrimary01;

export const backgroundAdminSidePanel = background01;

export const backgroundAdminMenuItem = blue05;
export const backgroundAdminMenuItemHover = blue10;

export const backgroundAdminSubsection = blue05;

export const backgroundAdminBackLinkIcon = 'transparent';
export const backgroundWhite05onPrimary01 = '#282a41'; // This is used to simulate a white background with 5% opacity on a deprecatedPrimary01 background

/**
 * Borders
 */
export const borderColorHeader = white05;
export const borderHeaderSearchBar = 'transparent';

export const borderAdminSidePanel = white;
export const borderAdminMenuItem = white;

export const borderAdminBackLinkIcon = white;

/**
 * Fills
 */
export const fillButtonIconHover = accent01;
export const fillSearchIcon = accent01;

export const fillButtonLight = background01;

export const fillButtonIconToggleActive = background01;
export const fillButtonIconToggleHover = white;

export const fillAdminSidePanelLabel = white50;
export const fillAdminSidePanelIcon = white50;

export const fillHeading = textPrimary01;
export const fillAdminMenuItemText = textPrimary01;

export const fillAdminBackLinkIcon = text01;

export const fillGraduatedRangeTick = white10;
export const fillGraduatedRangeSelectedTick = white;

export const fillSettingsSpinnerIcon = white10;
