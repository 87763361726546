import { MISMOAmount, MISMOEntity, MISMOString } from './types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum RoofMaterialType {
  Aluminum = 'Aluminum',
  Asphalt = 'Asphalt',
  Clay = 'Clay',
  Composition = 'Composition',
  Concrete = 'Concrete',
  FireRetardant = 'FireRetardant',
  Gravel = 'Gravel',
  Metal = 'Metal',
  Other = 'Other',
  Roll = 'Roll',
  Shake = 'Shake',
  Shingle = 'Shingle',
  Slate = 'Slate',
  SpanishTile = 'SpanishTile',
  Stone = 'Stone',
  Synthetic = 'Synthetic',
  Tar = 'Tar',
  Tile = 'Tile',
  Tin = 'Tin',
  Wood = 'Wood',
}

export type Roof = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    roofMaterialType: RoofMaterialType;
    roofMaterialTypeOtherDescription: MISMOString;
  }>;
