import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(33.33% - 16px);
    background-color: ${theme.colors.deprecatedPrimary01};
    padding: 15px;
    border-radius: 4px;
  `}
`;

export const AnalyticsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const TableWrapper = styled.div`
  max-height: 185px;
  overflow-y: auto;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    width: 100%;
    margin: 0;
    font-family: ${theme.typography.fontFamily.heading};
    font-size: 14px;
    font-weight: 400;
    align-self: center;
    text-align: center;
  `}
`;

export const HeaderContent = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnalyticsMetrics = styled.div`
  display: flex;
  align-items: center;
`;

export const Metrics = styled.div`
  display: flex;
  align-items: center;
`;

export const LoansCount = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: 14px;
    font-weight: 500;
  `}
`;

export const PortfolioCount = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: ${theme.typography.fontFamily.heading};
    font-size: 12px;
    font-weight: 400;
    ${(props) => transparentize(0.3, props.theme.colors.white)};
  `}
`;

export const PageTitle = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.heading};
    margin: 10px 0 16px;
    color: ${theme.colors.accentPrimary};
    font-size: 20px;
    font-weight: 500;
  `}
`;

export const AnalyticsMenuItem = styled.span`
  ${({ theme }) => css`
    display: block;
    padding: 8px 16px;
    background-color: ${theme.colors.white05};
    font-family: ${theme.typography.fontFamily.heading};
    color: ${theme.colors.accentPrimary};
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;

    &:hover,
    &:active {
      background-color: ${theme.colors.white};
      color: ${theme.colors.blue};
    }
  `}
`;

export const Headline = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.heading};
    font-size: 16px;
    align-self: flex-start;
    color: rgba(255, 255, 255, 0.65);
  `}
`;

export const AnalyticsMenu = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  gap: 8px;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white70};
  ${({ theme }) => theme.typography.headings.headingRegular03};
`;

export const SelectLabel = styled.label`
  ${({ theme }) => css`
    font-weight: 700;
    color: ${theme.colors.white};
    margin-right: 16px;
  `}
`;

export const Footer = styled.footer`
  display: block;
  width: 100%;
  margin-top: 8px;

  button {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const Legend = styled.p`
  ${({ theme }) => css`
    margin: 0 0 8px 0;
    font-family: ${theme.typography.fontFamily.heading};
    font-size: 12px;
  `}
`;

export const ErrorLegend = styled(Legend)`
  ${({ theme }) => css`
    color: ${theme.colors.statusRed};
  `}
`;

export const ChartWrapper = styled.div``;
