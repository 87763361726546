import React from 'react';

export const MapLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2269 16.4327L10.896 15.7412C10.769 15.4756 10.5313 15.2796 10.2465 15.2052L10.1202 15.689L10.2465 15.2052L9.9387 15.1249L9.16459 14.8553L8.20242 14.3525L8.20241 14.3525C8.05947 14.2778 7.90059 14.2388 7.73932 14.2388L6.91562 14.2388L5.51546 13.2634L5.17823 12.3821L5.17821 12.382L4.52545 10.6766L5.44234 7.32304L7.59118 8.10912C7.72189 8.15694 7.86115 8.17694 8.00004 8.16785L9.27394 8.08448L9.27403 8.08447L12.1972 7.89373L12.7079 8.09397C12.5569 8.62733 12.9554 9.18495 13.5369 9.18495H13.6729C13.9987 9.18495 14.2981 9.01613 14.4702 8.7477L14.6975 8.95129L14.3294 9.72066C14.0358 10.3343 14.4273 11.0556 15.1018 11.1438L15.2703 11.1659C15.586 11.2071 15.9024 11.0957 16.1225 10.8657L16.9032 10.0498L17.2277 10.0498C17.5859 10.0498 17.9167 9.85818 18.095 9.54748L18.2652 9.25093L18.8842 9.06614C18.9753 9.03894 19.0615 8.99907 19.1404 8.94813L18.9646 9.27873C18.853 9.4886 18.845 9.7322 18.9315 9.94314L18.4463 10.1685C18.2219 10.2727 18.0441 10.4563 17.9473 10.684L17.6038 11.4915L18.0638 11.6872L17.6038 11.4915C17.4499 11.853 17.5221 12.2712 17.7883 12.5603L17.833 12.6089L18.2009 12.2702L17.833 12.6089L16.6663 14.1605C16.536 14.3338 16.4655 14.5447 16.4655 14.7615V15.2019C16.4655 15.2433 16.4681 15.2846 16.4732 15.3256C16.2855 15.0964 16.0027 14.9594 15.6997 14.9594H14.5613C14.2665 14.9594 14.0133 15.1375 13.9034 15.3919H13.6388C13.3593 15.2595 13.0291 15.2862 12.7739 15.464L12.2118 15.8557L11.9072 15.9194C11.6123 15.9811 11.3643 16.1714 11.2269 16.4327ZM17.0067 16.2879L17.0066 16.2877L17.0067 16.2879Z"
        stroke="currentColor"
      />
    </svg>
  );
};
