import styled, { css, DefaultTheme } from 'styled-components/macro';

import { PAGE_MAX_WIDTH, PAGE_MIN_MARGIN, PAGE_MIN_WIDTH } from '../../components/Page';

const getHeaderStyles = (theme: DefaultTheme) => css`
  background-color: ${theme.colors.backgroundHeader};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.colors.borderColorHeader};
`;

export const Header = styled.header(
  ({ theme }) => css`
    ${getHeaderStyles(theme)}

    display: grid;
    grid-template-columns:
      [page-start] minmax(${PAGE_MIN_MARGIN}px, auto) [main-start] minmax(
        ${PAGE_MIN_WIDTH}px,
        ${PAGE_MAX_WIDTH}px
      )
      [main-end] minmax(${PAGE_MIN_MARGIN}px, auto) [page-end];
  `
);

export const AdminHeader = styled.header(
  ({ theme }) => css`
    ${getHeaderStyles(theme)}

    padding: 0 16px;
    display: flex;
    justify-content: space-between;
  `
);

export const Grid = styled.div`
  grid-column: main-start / main-end;
  display: grid;
  grid-template-columns: 302px 1fr 302px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  height: 63px;
`;

export const Subgrid = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
`;
