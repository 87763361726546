import { ALL_LOANS_ATTRIBUTES, AttributeType, PermissionType } from '@plus-platform/shared';
import { Redirect, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../global/PrivateRoute';
import { AssignCustodianPage } from './AssignCustodianPage';
import { SelectLoansPage } from './SelectLoansPage';

export const ImportPage = () => {
  return (
    <Switch>
      <PrivateRoute
        component={SelectLoansPage}
        path={`/import/select-loans`}
        requiredPermissions={{
          [PermissionType.PERMISSION_CREATE]: ALL_LOANS_ATTRIBUTES,
        }}
      />
      <PrivateRoute
        component={AssignCustodianPage}
        path={`/import/assign-custodian`}
        requiredPermissions={{
          [PermissionType.PERMISSION_CREATE]: ALL_LOANS_ATTRIBUTES,
          [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_CUSTODIAN],
        }}
      />
      <Redirect to="/import/select-loans" />
    </Switch>
  );
};
