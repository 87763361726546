import { Filter, SubmissionAttributes } from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import React, { useState } from 'react';
import { FetchNextPageOptions } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DashboardSquareFillIcon,
  DashboardSquareLineIcon,
  TableFillIcon,
  TableLineIcon,
} from '../components/icons';
import {
  Cell,
  ErrorRow,
  GroupedHeaderCell,
  LoadingRow,
  Row,
  Table,
  TableWrapper,
  Tbody,
  Thead,
} from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableOptions } from '../components/TableOptions';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { ButtonToggle } from '../components/Toggles/ButtonToggle';
import { ToggleGroup } from '../components/Toggles/ToggleGroup';
import { VirtualizedTable } from '../components/VirtualizedTable';
import { useGetSubmissionsQuery } from '../hooks/queries/useGetSubmissionsQuery';
import { formatDate, formatTime } from '../utils/formatUtils';
import * as Styles from './SubmissionsList.styles';
import {
  getFilesProcessedOrErrored,
  getIsSubmissionComplete,
  GroupedOriginName,
  groupValidationErrors,
} from './utils';

type SubmissionsListProps = {
  itemsPerPage?: number;
  path: string;
};

export const SubmissionsList = (props: SubmissionsListProps) => {
  const { itemsPerPage = 20, path } = props;
  const [isCardDisplayed, setIsCardDisplayed] = useState<boolean>(false);
  const [isDateASC, setIsDateASC] = useState<boolean>(false);

  // TODO implement filter functionality
  const filters: Filter[] = [];
  const isFiltersLoading = false;
  const updateFilters = () => null;

  const {
    data: submissionsData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetSubmissionsQuery({
    itemCount: itemsPerPage,
    sortOrder: isDateASC ? 'ASC' : 'DESC',
  });

  const pages = compact(get(submissionsData, 'pages', []));

  const submissions: SubmissionAttributes[] = flatMap(pages, 'data');
  const totalSubmissionsCount = get(pages, '0.totalSubmissionsCount', 0);

  const ViewComponent = isCardDisplayed ? SubmissionsCardView : SubmissionsTableView;

  const onHeaderClick = (fieldName: string) => {
    // TODO support other fields as well (like id once BE work is done)

    if (['createdAt'].includes(fieldName)) {
      setIsDateASC(!isDateASC);
    }
  };

  return (
    <TableFilters
      filters={filters}
      isLoading={isFiltersLoading}
      onFiltersUpdate={updateFilters}
      path={path}
    >
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({ closeLeftPanel, isLeftPanelOpen, openLeftPanel }) => (
            <>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={updateFilters}
                />
              )}
              <Card $hasFlex>
                <TableToolbar>
                  <TableStats
                    totalCount={totalSubmissionsCount}
                    visibleCount={submissions.length}
                  />

                  <Divider $orientation="vertical" $flexItem />
                  <TableFilterBar
                    isDisabled={true}
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={updateFilters}
                  />
                  <TableOptions>
                    <ToggleGroup size="small" value={isCardDisplayed ? 'card' : 'table'}>
                      <ButtonToggle value="table" onClick={() => setIsCardDisplayed(false)}>
                        {!isCardDisplayed ? <TableFillIcon /> : <TableLineIcon />}
                      </ButtonToggle>

                      <ButtonToggle value="card" onClick={() => setIsCardDisplayed(true)}>
                        {isCardDisplayed ? (
                          <DashboardSquareFillIcon />
                        ) : (
                          <DashboardSquareLineIcon />
                        )}
                      </ButtonToggle>
                    </ToggleGroup>
                  </TableOptions>
                </TableToolbar>

                <ActivityIndicator contain isActive={isLoading}>
                  <ViewComponent
                    submissions={submissions}
                    itemsPerPage={itemsPerPage}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    totalSubmissionsCount={totalSubmissionsCount}
                    onHeaderClick={onHeaderClick}
                    isDateAsc={isDateASC}
                  />
                </ActivityIndicator>
              </Card>
            </>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};

type SubmissionsViewProps = {
  itemsPerPage: number;
  submissions: SubmissionAttributes[];
  totalSubmissionsCount: number;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  onHeaderClick: (fieldName: string) => void;
  isDateAsc: boolean;
};

const SubmissionsTableView = ({
  fetchNextPage,
  isDateAsc,
  isFetchingNextPage,
  itemsPerPage,
  onHeaderClick,
  submissions,
  totalSubmissionsCount,
}: SubmissionsViewProps) => {
  const history = useHistory();
  const parentRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <TableWrapper>
      <Table>
        <Thead>
          <Styles.GroupedHeaderRow $columnCount={10}>
            <GroupedHeaderCell $gridColumn={'1 / span 10'}></GroupedHeaderCell>
            <GroupedHeaderCell $gridColumn={'11 / span 5'}>
              Data validation issues
            </GroupedHeaderCell>
          </Styles.GroupedHeaderRow>

          <Styles.HeaderRow $columnCount={10}>
            <Styles.HeaderCell>Submission ID</Styles.HeaderCell>
            <Styles.HeaderCell onClick={() => onHeaderClick('createdAt')}>
              {isDateAsc ? (
                <ChevronUpIcon height={16} width={16} />
              ) : (
                <ChevronDownIcon height={16} width={16} />
              )}
              Date of ingestion
            </Styles.HeaderCell>
            <Styles.HeaderCell>Time</Styles.HeaderCell>
            <Styles.HeaderCell>Status</Styles.HeaderCell>
            <Styles.HeaderCell>Progress</Styles.HeaderCell>

            <Styles.HeaderCell>OCR issues</Styles.HeaderCell>
            <Styles.HeaderCell>MISMO 3.4/ULDD/UCD issues</Styles.HeaderCell>
            <Styles.HeaderCell>Plus issues</Styles.HeaderCell>

            <Styles.HeaderCell>Files submitted</Styles.HeaderCell>
            <Styles.HeaderCell>Files processed</Styles.HeaderCell>
          </Styles.HeaderRow>
        </Thead>

        <Tbody>
          <VirtualizedTable<SubmissionAttributes>
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            maxVisibleItems={itemsPerPage}
            parentRef={parentRef}
            items={submissions}
            totalItemsCount={totalSubmissionsCount}
            renderRow={(isLoaderRow, virtualRow, submission) => {
              if (!submission) {
                return null;
              }
              const total = submission.filesReceived;
              const filesProcessedOrErrored = getFilesProcessedOrErrored(submission);
              const isComplete = getIsSubmissionComplete(submission);
              const progress = filesProcessedOrErrored / total;

              const groupedValidationErrors = groupValidationErrors(
                submission.validationErrors ?? []
              );
              return (
                <Styles.RowWrapper height={virtualRow.size} offsetY={virtualRow.start}>
                  {isLoaderRow && (
                    <LoadingRow>
                      {virtualRow.index < totalSubmissionsCount
                        ? 'Loading more...'
                        : 'Nothing more to load'}
                    </LoadingRow>
                  )}

                  {!isLoaderRow && !submission && <ErrorRow>Error loading row</ErrorRow>}

                  {!isLoaderRow && submission && (
                    <Row
                      $columnCount={10}
                      onClick={() => history.push(`/documents/submissions/${submission.id}`)}
                    >
                      <Cell>{submission.submissionNumber}</Cell>
                      <Cell>{formatDate(new Date(submission.createdAt))}</Cell>
                      <Cell>{formatTime(new Date(submission.createdAt))}</Cell>

                      <Cell>{isComplete ? 'Completed' : 'Underway'}</Cell>
                      <Cell>
                        <Styles.ProgressBar $isComplete={isComplete}>
                          <progress value={progress} />
                        </Styles.ProgressBar>
                      </Cell>
                      <Cell>{groupedValidationErrors[GroupedOriginName.OCR].length}</Cell>
                      <Cell>{groupedValidationErrors[GroupedOriginName.SMD].length}</Cell>
                      <Cell>{groupedValidationErrors[GroupedOriginName.CUSTOM].length}</Cell>
                      <Cell>{submission.filesReceived}</Cell>
                      <Cell>
                        {submission.filesProcessed} of {submission.filesReceived}
                      </Cell>
                    </Row>
                  )}
                </Styles.RowWrapper>
              );
            }}
          />
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

// TODO style this view
const SubmissionsCardView = (props: SubmissionsViewProps) => {
  const { submissions } = props;
  return (
    <Card>
      {submissions.map((submission) => {
        return <p key={submission.id}>Submission Id: {submission.submissionNumber}</p>;
      })}
    </Card>
  );
};
