import { MISMOEntity, MISMOSequenceNumber, MISMOString } from '../types';

enum IrrigationSystemSourceType {
  CatchmentSystemFed = 'CatchmentSystemFed',
  CisternFed = 'CisternFed',
  CityWaterFed = 'CityWaterFed',
  Other = 'Other',
  ReclaimedWater = 'ReclaimedWater',
  WellFed = 'WellFed',
}

enum LandscapeLightingPowerSourceType {
  Other = 'Other',
  Solar = 'Solar',
  TraditionalElectric = 'TraditionalElectric',
}

enum LandscapingType {
  HardScape = 'HardScape',
  Natural = 'Natural',
  Other = 'Other',
  ProfessionalPlantings = 'ProfessionalPlantings',
  XeriScape = 'XeriScape',
  ZeroImpact = 'ZeroImpact',
}

export type SiteFeature = MISMOEntity &
  Partial<{
    irrigationSystemSourceType: IrrigationSystemSourceType;
    irrigationSystemSourceTypeOtherDescription: MISMOString;
    landscapeLightingPowerSourceType: LandscapeLightingPowerSourceType;
    landscapeLightingPowerSourceTypeOtherDescription: MISMOString;
    landscapingType: LandscapingType;
    landscapingTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
