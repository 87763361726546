import React from 'react';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';

type DropzoneContextProps = DropzoneState & {
  clearAcceptedFiles: () => void;
};

const DropzoneContext = React.createContext<DropzoneContextProps | null>(null);

type DropzoneProviderProps = DropzoneOptions & {
  children: (state: DropzoneState) => React.ReactNode;
};

export const DropzoneProvider = (props: DropzoneProviderProps) => {
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
  const { children, onDropAccepted, ...dropZoneProps } = props;

  const state = useDropzone({
    ...dropZoneProps,
    onDropAccepted: (files, ...args) => {
      // Although the dropzone library already returns a list of accepted files
      // as a part of it's state, there is no way to clear them or reset the
      // library instance state. We store them separately so that we have control
      // over the list
      setAcceptedFiles(files);
      return onDropAccepted?.(files, ...args);
    },
  });

  const clearAcceptedFiles = () => {
    setAcceptedFiles([]);
  };

  const value = { ...state, acceptedFiles, clearAcceptedFiles };

  return <DropzoneContext.Provider value={value}>{children(value)}</DropzoneContext.Provider>;
};

export const useDropzoneContext = () => {
  const value = React.useContext(DropzoneContext);

  if (!value) {
    throw new Error('useDropzoneContext must be used within DrpozoneProvider');
  }

  return value;
};
