import {
  AggregatedQuoteStipulation,
  AggregatedTradeRequest,
  CounterpartyType,
  CounterpartyWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';
import { useTheme } from 'styled-components/macro';

import { Badge } from '../../components/Badge';
import { Card } from '../../components/Card';
import { HeadingLink } from '../../components/HeadingLink';
import {
  BidLineIcon,
  ChatLineIcon,
  DataRequestLineIcon,
  StipulationsLineIcon,
  ThumbsUpLineIcon,
} from '../../components/icons';
import { Key, KeyValueList, Pair, PairProps, Value } from '../../components/KeyValue';
import { RecipientCard } from '../../components/RecipientCard';
import { TradeStatus } from '../../components/TradeStatus';
import { formatDefaultValue } from '../../utils/formatUtils';
import { getTradeCounterpartyActivityStepCounts } from '../tradeRequestsUtils';
import { TradeRequestStep } from '../types';
import { TradeTicketSendDate } from '.';
import {
  TradeTicketLoansCount,
  TradeTicketLoansCoupon,
  TradeTicketLoansJudicialSplit,
  TradeTicketLoansRemittance,
  TradeTicketLoansUPB,
  TradeTicketLoansWeightedAverageLTV,
} from './TradeTicket';
import * as Styles from './TradeTicket.styles';

type TradeTicketIdProps = {
  externalTradeId: AggregatedTradeRequest['externalTradeId'];
} & PairProps;

export const TradeTicketId = ({ externalTradeId, ...rest }: TradeTicketIdProps) => (
  <Pair {...rest}>
    <Key>Ticket ID</Key>
    <Value>{formatDefaultValue(externalTradeId)}</Value>
  </Pair>
);

// TODO: Make status dynamic
type TradeTicketStatusProps = PairProps;

export const TradeTicketStatus = ({ ...rest }: TradeTicketStatusProps) => (
  <Pair {...rest}>
    <Key>Status</Key>
    <Value>RFQ Sent</Value>
  </Pair>
);

const getActivityCountData = (
  step: TradeRequestStep,
  counterparties: CounterpartyWithSummary[]
) => {
  const counts = getTradeCounterpartyActivityStepCounts(counterparties);
  const count = counts[step];

  return {
    isComplete: counterparties.length > 0 && count === counterparties.length,
    count: counts[step],
    total: counterparties.length,
  };
};

type TradeTicketActivityProps = {
  counterparties: CounterpartyWithSummary[];
  size: 'small' | 'medium' | 'large';
  variant?: 'default' | 'light';
};

const TradeTicketActivity = ({ counterparties, size, variant }: TradeTicketActivityProps) => {
  const theme = useTheme();
  const expressionOfInterest = getActivityCountData(
    TradeRequestStep.EXPRESSION_OF_INTEREST,
    counterparties
  );
  const dataRequest = getActivityCountData(TradeRequestStep.DATA_REQUEST, counterparties);
  const messages = [];
  const bids = [];
  const stipulations = [];

  const showTotal = ['medium', 'large'].includes(size);
  const showLabel = size === 'large';

  return (
    <Styles.Statuses $count={5} $size={size}>
      <TradeStatus
        color={theme.colors.statusTurquoise}
        count={expressionOfInterest.count}
        icon={<ThumbsUpLineIcon />}
        isInline={false}
        label={showLabel ? 'Interests' : undefined}
        total={showTotal ? expressionOfInterest.total : undefined}
        variant={variant}
      />
      <TradeStatus
        count={messages.length}
        icon={<ChatLineIcon />}
        isInline={false}
        label={showLabel ? 'Messages' : undefined}
        variant={variant}
      />
      <TradeStatus
        color={theme.colors.statusTurquoise}
        count={stipulations.length}
        icon={<StipulationsLineIcon />}
        isInline={false}
        label={showLabel ? 'Stipulations' : undefined}
        variant={variant}
      />
      <TradeStatus
        color={theme.colors.statusOrange}
        count={dataRequest.count}
        icon={<DataRequestLineIcon />}
        isInline={false}
        label={showLabel ? 'Data requests' : undefined}
        total={showTotal ? dataRequest.total : undefined}
        variant={variant}
      />
      <TradeStatus
        count={bids.length}
        icon={<BidLineIcon />}
        isInline={false}
        label={showLabel ? 'Bids' : undefined}
        variant={variant}
      />
    </Styles.Statuses>
  );
};

type TradeTicketRecipientsProps = {
  counterparties: CounterpartyWithSummary[];
  size: 'small' | 'medium' | 'large';
  variant?: 'default' | 'light';
};

const TradeTicketRecipients = ({ counterparties, size, variant }: TradeTicketRecipientsProps) => {
  const isCompact = size === 'small';

  return (
    <Styles.RecipientsWrapper $size={size}>
      {!isCompact && <Styles.RecipientsLabel>Recipients</Styles.RecipientsLabel>}
      <Styles.Recipients>
        {counterparties.map((counterparty) => {
          const {
            avatarUrl,
            firstName,
            lastName,
            organization: { tradingName },
          } = counterparty.user;

          return (
            <Styles.Recipient key={`${lastName}-${firstName}-${tradingName}`}>
              <RecipientCard
                size={isCompact ? 'small' : 'large'}
                firstName={firstName}
                lastName={lastName}
                organization={tradingName}
                imgSrc={avatarUrl}
                variant={variant}
              />
            </Styles.Recipient>
          );
        })}
      </Styles.Recipients>
    </Styles.RecipientsWrapper>
  );
};

type OutboundTicketCardProps = {
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  tradeRequest: OutboundTradeRequestWithSummary;
  url: string;
  variant?: 'default' | 'light';
};

const OutboundTicketCard = ({
  children,
  size,
  tradeRequest,
  url,
  variant,
}: OutboundTicketCardProps) => {
  const { counterparties } = tradeRequest;
  const recipients = counterparties.filter(
    (counterparty) => counterparty.type === CounterpartyType.BUYER
  );

  return (
    <Card $variant={variant}>
      <Styles.Header $variant={variant}>
        <HeadingLink $size="medium" $variant={variant} to={url}>
          Trade in progress <Styles.Dull> on pool </Styles.Dull> {tradeRequest.pool.name}
        </HeadingLink>
        <TradeTicketActivity size={size} counterparties={recipients} variant={variant} />
      </Styles.Header>
      {children}
      <TradeTicketRecipients size={size} counterparties={recipients} variant={variant} />
    </Card>
  );
};

type OutboundTradeRequestProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
  counterparties?: CounterpartyWithSummary[];
  url: string;
  variant?: 'default' | 'light';
};

export const OutboundTradeRequestSmall = (props: OutboundTradeRequestProps) => {
  const { tradeRequest } = props;
  return (
    <OutboundTicketCard size="small" {...props}>
      <Styles.TradeInfo $size="small">
        <KeyValueList $size="small" $templateColumns="60px auto" $type="list">
          <TradeTicketId externalTradeId={tradeRequest.externalTradeId} />
          <TradeTicketSendDate tradeRequest={tradeRequest} />
          <TradeTicketStatus />
        </KeyValueList>
        <KeyValueList $size="small" $templateColumns="82px auto" $type="list">
          <TradeTicketLoansCount tradeRequest={tradeRequest} />
          <TradeTicketLoansUPB tradeRequest={tradeRequest} />
          <TradeTicketLoansCoupon tradeRequest={tradeRequest} />
        </KeyValueList>
      </Styles.TradeInfo>
    </OutboundTicketCard>
  );
};

export const OutboundTradeRequestMedium = (props: OutboundTradeRequestProps) => {
  const { tradeRequest } = props;
  return (
    <OutboundTicketCard size="medium" {...props}>
      <Styles.TradeInfo $size="medium">
        <KeyValueList $size="large" $templateColumns="80px auto" $type="list">
          <TradeTicketId externalTradeId={tradeRequest.externalTradeId} />
          <TradeTicketSendDate tradeRequest={tradeRequest} />
          <TradeTicketStatus />
        </KeyValueList>
        <KeyValueList $size="large" $templateColumns="82px auto" $type="list">
          <TradeTicketLoansCount tradeRequest={tradeRequest} />
          <TradeTicketLoansUPB tradeRequest={tradeRequest} />
          <TradeTicketLoansRemittance tradeRequest={tradeRequest} />
        </KeyValueList>
        <KeyValueList $size="large" $templateColumns="140px auto" $type="list">
          <TradeTicketLoansCoupon tradeRequest={tradeRequest} />
          <TradeTicketLoansWeightedAverageLTV tradeRequest={tradeRequest} />
          <TradeTicketLoansJudicialSplit tradeRequest={tradeRequest} />
        </KeyValueList>
      </Styles.TradeInfo>
    </OutboundTicketCard>
  );
};

export const OutboundTradeRequestLarge = (props: OutboundTradeRequestProps) => {
  const { tradeRequest } = props;
  return (
    <OutboundTicketCard size="large" {...props}>
      <Styles.TradeInfo $size="large">
        <TradeTicketId
          externalTradeId={tradeRequest.externalTradeId}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketSendDate
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketStatus $direction="vertical" $size="medium" $align="left" />
        <TradeTicketLoansCount
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansUPB
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansRemittance
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansCoupon
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansWeightedAverageLTV
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
        <TradeTicketLoansJudicialSplit
          tradeRequest={tradeRequest}
          $direction="vertical"
          $size="medium"
          $align="left"
        />
      </Styles.TradeInfo>
    </OutboundTicketCard>
  );
};

type OutboundTradeRequestSidebarProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundTradeRequestSidebar = ({ tradeRequest }: OutboundTradeRequestSidebarProps) => {
  return (
    <Styles.TradeInfoVertical>
      <TradeTicketId
        externalTradeId={tradeRequest.externalTradeId}
        $align="left"
        $direction="vertical"
      />
      <TradeTicketSendDate tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketStatus $align="left" $direction="vertical" />
      <TradeTicketLoansCount tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansUPB tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansRemittance tradeRequest={tradeRequest} $align="left" $direction="vertical" />
      <TradeTicketLoansWeightedAverageLTV
        tradeRequest={tradeRequest}
        $align="left"
        $direction="vertical"
      />
      <TradeTicketLoansJudicialSplit
        tradeRequest={tradeRequest}
        $align="left"
        $direction="vertical"
      />
      <TradeTicketLoansCoupon tradeRequest={tradeRequest} $align="left" $direction="vertical" />
    </Styles.TradeInfoVertical>
  );
};

type ActivityListProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const SummaryActivityList = ({ tradeRequest }: ActivityListProps) => {
  const { bids, messages } = tradeRequest;
  const theme = useTheme();

  const buyerCounterparties = (tradeRequest?.counterparties || []).filter(
    (counterparty) => counterparty.type === CounterpartyType.BUYER
  );
  const counters = getTradeCounterpartyActivityStepCounts(buyerCounterparties);

  const stipulations = buyerCounterparties.flatMap<AggregatedQuoteStipulation>((counterparty) => {
    const counterpartyBids = tradeRequest.bids.filter((bid) => {
      return bid.fromCounterpartyId === counterparty.id;
    });

    return counterpartyBids
      .map<AggregatedQuoteStipulation | undefined>((quote) => quote.stipulation)
      .filter(
        (stipulation): stipulation is AggregatedQuoteStipulation => stipulation !== undefined
      );
  });

  return (
    <Styles.ActivityList>
      <Styles.Step>
        <ThumbsUpLineIcon height={16} width={16} />
        <Styles.StepLabel>Interests</Styles.StepLabel>
        <Badge $size={16} $color={theme.colors.statusTurquoise}>
          <Styles.StepCount>
            {counters[TradeRequestStep.EXPRESSION_OF_INTEREST] ?? 0}
          </Styles.StepCount>
        </Badge>
      </Styles.Step>
      <Styles.Step>
        <ChatLineIcon height={16} width={16} />
        <Styles.StepLabel>Messages</Styles.StepLabel>
        <Badge $size={16} $color={theme.colors.statusYellow}>
          <Styles.StepCount>{messages.length ?? 0}</Styles.StepCount>
        </Badge>
      </Styles.Step>
      <Styles.Step>
        <StipulationsLineIcon height={16} width={16} />
        <Styles.StepLabel>Stipulations</Styles.StepLabel>
        <Badge $size={16} $color={theme.colors.statusYellow}>
          <Styles.StepCount>{stipulations.length ?? 0}</Styles.StepCount>
        </Badge>
      </Styles.Step>
      <Styles.Step>
        <DataRequestLineIcon height={16} width={16} />
        <Styles.StepLabel>Data requests</Styles.StepLabel>
        <Badge $size={16} $color={theme.colors.statusOrange}>
          <Styles.StepCount>{counters[TradeRequestStep.DATA_REQUEST] ?? 0}</Styles.StepCount>
        </Badge>
      </Styles.Step>
      <Styles.Step>
        <BidLineIcon height={16} width={16} />
        <Styles.StepLabel>Bids</Styles.StepLabel>
        <Badge $size={16} $color={theme.colors.statusYellow}>
          <Styles.StepCount>{bids.length ?? 0}</Styles.StepCount>
        </Badge>
      </Styles.Step>
    </Styles.ActivityList>
  );
};
