import type { DelinquencyType } from '../types';

export const DELINQUENCIES_DAYS_MAP: Record<
  DelinquencyType,
  {
    start: number;
    end?: number;
  }
> = {
  thirtyDays: { start: 1, end: 30 },
  sixtyDays: { start: 31, end: 60 },
  ninetyDays: { start: 61, end: 90 },
  ninetyPlusDays: { start: 91 },
};
