import styled, { css, DefaultTheme } from 'styled-components/macro';

import { bodyText } from '../styles/typography';

const getSliderThumbStyles = (theme: DefaultTheme) => css`
  position: relative;
  top: -1px;
  -webkit-appearance: none;
  background: transparent;
  background-color: ${theme.colors.fillGraduatedRangeSelectedTick};
  cursor: -moz-grab;
  cursor: -webkit-grab;
  width: 16px;
  height: 16px;
  border: 0 none;
  border-radius: 16px;
  z-index: 3;
`;

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    height: 40px;

    input::-webkit-slider-thumb {
      ${getSliderThumbStyles(theme)}
    }

    input::-webkit-slider-container {
      height: 0;
      min-block-size: 0;
    }

    input::-webkit-slider-runnable-track {
      height: 0;
    }

    input::-moz-range-thumb {
      ${getSliderThumbStyles(theme)}
    }

    input::-ms-thumb {
      ${getSliderThumbStyles(theme)}
    }
  `
);

export const Range = styled.datalist<{ $width: number }>`
  position: relative;
  top: -14px;
  display: flex;
  justify-content: space-between;
  width: ${(props) => `${props.$width}px`};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
`;

export const CustomInput = styled.input(
  ({ theme }) => css`
    appearance: none;
    outline: none;
    width: 100%;
    height: 15px;
    background-color: ${theme.colors.backgroundAdminSubsection};
  `
);

export const Label = styled.option<{ $alignSelf: string }>(
  ({ $alignSelf, theme }) => css`
    ${bodyText.bodyRegular03};
    color: ${theme.colors.textGraduatedRangeLabel};
    display: flex;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    align-self: ${$alignSelf};
  `
);

export const Bar = styled.div(
  ({ theme }) => css`
    position: relative;
    top: -5px;
    height: 8px;
    width: 2px;
    background-color: ${theme.colors.fillGraduatedRangeTick};
  `
);

export const Tick = styled.div<{
  $offsetX: number;
  $isCurrent: boolean;
  $width: number;
  $isSelected: boolean;
  $hasExtraElement: boolean;
  $shouldBeCentered: boolean;
}>(
  ({
    $hasExtraElement,
    $isCurrent,
    $isSelected,
    $offsetX,
    $shouldBeCentered,
    $width,
    theme,
  }) => css`
    position: absolute;
    left: ${$offsetX}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: ${$width}px;
    height: 40px;
    z-index: 2;
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: ${$isSelected
      ? theme.colors.fillGraduatedRangeSelectedTick
      : theme.colors.fillGraduatedRangeTick};

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: -2px;
      left: 0;
      width: 7px;
      height: 2px;
      z-index: 3;
      background-color: ${theme.colors.fillGraduatedRangeSelectedTick};
    }

    ${$hasExtraElement &&
    `
        &:after {
          display: block;
        }
      `}

    ${Bar} {
      background-color: ${$isCurrent
        ? 'transparent'
        : $isSelected
        ? theme.colors.fillGraduatedRangeSelectedTick
        : theme.colors.fillGraduatedRangeTick};
    }

    ${Label} {
      ${$shouldBeCentered &&
      `
        align-self: center;
        margin-left: ${-$width}px;
      `}

      color: ${$isCurrent
        ? theme.colors.fillGraduatedRangeSelectedTick
        : theme.colors.textGraduatedRangeLabel};
    }
  `
);
