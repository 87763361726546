import React from 'react';
import { FetchNextPageOptions } from 'react-query';
import { useVirtual, VirtualItem } from 'react-virtual';
import styled, { css } from 'styled-components/macro';

import { DEPRECATED_TABLE_ROW_HEIGHT } from './Table';

const Wrapper = styled.div<{ height?: number }>`
  ${({ height }) => css`
    overflow: auto;

    ${height &&
    css`
      height: ${height}px;
    `}

    ${!height &&
    css`
      flex: 1;
    `}
  `}
`;

const TableWrapper = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  position: relative;
`;

type VirtualizedTableProps<T> = {
  items: T[];
  totalItemsCount: number;
  isFetchingNextPage: boolean;
  maxVisibleItems?: number;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  renderRow: (isLoaderRow: boolean, virtualRow: VirtualItem, row?: T) => JSX.Element | null;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  estimatedRowHeight?: number;
};

export const VirtualizedTable = <T,>({
  items,
  totalItemsCount,
  isFetchingNextPage,
  maxVisibleItems,
  parentRef,
  renderRow,
  fetchNextPage,
  estimatedRowHeight = DEPRECATED_TABLE_ROW_HEIGHT,
}: VirtualizedTableProps<T>) => {
  const visibleHeight =
    maxVisibleItems && estimatedRowHeight ? estimatedRowHeight * maxVisibleItems : undefined;
  const canFetchNextPage = items.length < totalItemsCount;

  const rowVirtualizer = useVirtual({
    size: canFetchNextPage ? items.length + 1 : items.length,
    parentRef,
    overscan: 5,
    estimateSize: React.useCallback(() => estimatedRowHeight, [estimatedRowHeight]),
  });

  React.useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index === items.length && canFetchNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [
    canFetchNextPage,
    fetchNextPage,
    items.length,
    isFetchingNextPage,
    rowVirtualizer.virtualItems,
  ]);

  return (
    <Wrapper height={visibleHeight} ref={parentRef}>
      <TableWrapper height={rowVirtualizer.totalSize}>
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          const row = items[virtualRow.index];
          const isLoaderRow = virtualRow.index > items.length - 1;
          return (
            // TODO: Once we're sure we can get an unique id for every type of data passed here,
            // let's use it (row.id) instead of virtualRow.index
            <React.Fragment key={virtualRow.index}>
              {renderRow(isLoaderRow, virtualRow, row)}
            </React.Fragment>
          );
        })}
      </TableWrapper>
    </Wrapper>
  );
};
