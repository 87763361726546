import React, { useLayoutEffect, useRef, useState } from 'react';

import * as Styles from './TableLayout.styles';

type ChildrenProps = {
  openLeftPanel: () => void;
  closeLeftPanel: () => void;
  openRightPanel: () => void;
  closeRightPanel: () => void;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  containerHeight?: number;
};

type TableLayoutProps = {
  children: (props: ChildrenProps) => React.ReactNode;
  value?: {
    defaults?: {
      isLeftPanelOpen?: boolean;
      isRightPanelOpen?: boolean;
    };
  };
};

export const TableLayout = ({ children, value }: TableLayoutProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(value?.defaults?.isLeftPanelOpen ?? false);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(
    value?.defaults?.isRightPanelOpen ?? false
  );

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const handleResize = () => {
      if (containerRef.current) {
        const height = containerRef.current.getBoundingClientRect().height;

        if (containerHeight !== height) {
          setContainerHeight(height);
        }
      }
    };

    handleResize();

    const observer = new ResizeObserver(handleResize);
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  const openLeftPanel = () => {
    setIsLeftPanelOpen(true);
  };

  const closeLeftPanel = () => {
    setIsLeftPanelOpen(false);
  };

  const openRightPanel = () => {
    setIsRightPanelOpen(true);
  };

  const closeRightPanel = () => {
    setIsRightPanelOpen(false);
  };

  return (
    <Styles.PanelWrapper
      ref={containerRef}
      $isLeftPanelOpen={isLeftPanelOpen}
      $isRightPanelOpen={isRightPanelOpen}
    >
      {children({
        openLeftPanel,
        closeLeftPanel,
        openRightPanel,
        closeRightPanel,
        isLeftPanelOpen,
        isRightPanelOpen,
        containerHeight,
      })}
    </Styles.PanelWrapper>
  );
};
