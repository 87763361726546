import React from 'react';

import * as Styles from './Checkbox.styles';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  children?: React.ReactNode;
};

export const Checkbox = ({ children, disabled, ...rest }: CheckboxProps) => {
  return (
    <Styles.Label $isDisabled={disabled}>
      <Styles.Input disabled={disabled} {...rest} />
      <Styles.CheckIndicator width="16px" height="16px" />
      {children}
    </Styles.Label>
  );
};
