import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';
import React from 'react';

import { Button } from '../../../../components/Button';
import { ButtonLoader } from '../../../../components/ButtonLoader';
import { Divider } from '../../../../components/Divider';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { useAcceptTradeRequestDataRequestMutation } from '../../../../hooks/queries/useAcceptTradeRequestDataRequestMutation';
import { useDeclineTradeRequestDataRequestMutation } from '../../../../hooks/queries/useDeclineTradeRequestDataRequestMutation';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { useMessagingNavigation } from '../../TradeRequestDirectMessaging/useMessagingNavigation';
import * as Styles from './OutboundDataRequestAcceptDeny.styles';

enum View {
  Initial,
  DataTransferConfirmation,
  DeclinedConfirmation,
  DeclinedWithReasonConfirmation,
}

type OutboundDataRequestAcceptDeclineProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
  onTransferData: () => void;
  onTransferDataConfirmation: () => void;
  onTransferDataCancellation: () => void;
  onTransferDataDecline: () => void;
  onTransferDataDeclineWithoutExplanation: () => void;
  onTransferDataDeclineWithExplanation: () => void;
  onTransferDataDeclineWithExplanationConfirm: () => void;
};

export const OutboundDataRequestAcceptDecline = (props: OutboundDataRequestAcceptDeclineProps) => {
  const {
    action,
    onTransferData,
    onTransferDataCancellation,
    onTransferDataConfirmation,
    onTransferDataDecline,
    onTransferDataDeclineWithExplanation,
    onTransferDataDeclineWithExplanationConfirm,
    onTransferDataDeclineWithoutExplanation,
    tradeRequest,
  } = props;
  const [view, setView] = React.useState(View.Initial);
  const { goToMessagingWithCounterparty } = useMessagingNavigation();

  const userName = formatNameWithInitial(
    action.fromCounterparty.user.lastName,
    action.fromCounterparty.user.firstName
  );
  const {
    isLoading: isAcceptingDataRequest,
    isSuccess: hasAcceptedDataRequest,
    mutateAsync: acceptDataRequest,
  } = useAcceptTradeRequestDataRequestMutation();
  const {
    isLoading: isDecliningDataRequest,
    isSuccess: hasDecliningDataRequest,
    mutateAsync: declineTradeRequestDataRequest,
  } = useDeclineTradeRequestDataRequestMutation();

  const canActOnDataRequest =
    !isAcceptingDataRequest &&
    !hasAcceptedDataRequest &&
    !isDecliningDataRequest &&
    !hasDecliningDataRequest;

  const declineDataRequest = async (reason?: string) => {
    await declineTradeRequestDataRequest({
      tradeRequestId: tradeRequest.id,
      tradeCounterpartyId: action.fromCounterparty.id,
      reason,
    });
  };

  return (
    <React.Fragment>
      {view === View.Initial && (
        <PlusMessage>
          <MessageText>{userName} has initiated a Data Request on your pool.</MessageText>
          <MessageActions>
            <div style={{ width: 'min-content' }}>
              <ButtonLoader
                $size="large"
                onClick={() => {
                  setView(View.DataTransferConfirmation);
                  onTransferData();
                }}
              >
                Send data to {userName}
              </ButtonLoader>
              <Styles.MessageActionHint>
                Only {userName} will receive the data. No one else will be a recipient.
              </Styles.MessageActionHint>
            </div>
            <Button
              $color="danger"
              $size="large"
              $variant="outlined"
              onClick={() => {
                setView(View.DeclinedConfirmation);
                onTransferDataDecline();
              }}
            >
              Decline the request
            </Button>
            <Divider $flexItem $orientation="vertical" />
            <div style={{ width: 'min-content' }}>
              {/* TODO: Integrate with API, when appropriate flow is available */}
              <ButtonLoader $size="large" disabled>
                Send Data To All
              </ButtonLoader>
              <Styles.MessageActionHint>
                Anyone who initiates a data request in future will see your data automatically.
              </Styles.MessageActionHint>
            </div>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.DataTransferConfirmation && (
        <PlusMessage>
          <MessageText>{userName} has initiated a Data Request on your pool.</MessageText>
          <MessageText>
            You've chosen to send data ONLY to {userName} Transfer your pool data using the button
            below.
          </MessageText>
          <MessageActions>
            <ButtonLoader
              $size="large"
              disabled={hasAcceptedDataRequest}
              isLoading={isAcceptingDataRequest}
              onClick={async () => {
                await acceptDataRequest({
                  tradeRequestId: tradeRequest.id,
                  tradeCounterpartyId: action.fromCounterparty.id,
                });
                onTransferDataConfirmation();
              }}
            >
              Transfer data
            </ButtonLoader>
            <Button
              $color="tertiary"
              $size="large"
              $variant="outlined"
              onClick={() => {
                setView(View.Initial);
                onTransferDataCancellation();
              }}
            >
              Cancel
            </Button>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.DeclinedConfirmation && (
        <PlusMessage>
          <MessageText>{userName} has initiated a Data Request on your pool.</MessageText>
          <MessageText>
            Understood! You've chosen to decline the Data Request from {userName} Would you like to
            add an explanation?
          </MessageText>
          <MessageActions>
            <Button
              $size="large"
              onClick={() => {
                setView(View.DeclinedWithReasonConfirmation);
                onTransferDataDeclineWithExplanation();
              }}
            >
              Decline with explanation
            </Button>
            <ButtonLoader
              $color="tertiary"
              $size="large"
              $variant="outlined"
              disabled={hasDecliningDataRequest}
              isLoading={isDecliningDataRequest}
              onClick={async () => {
                await declineDataRequest();
                onTransferDataDeclineWithoutExplanation();
              }}
            >
              Decline without explanation
            </ButtonLoader>
          </MessageActions>
        </PlusMessage>
      )}

      {view === View.DeclinedWithReasonConfirmation && (
        <PlusMessage>
          <MessageText>{userName} has initiated a Data Request on your pool.</MessageText>
          <MessageText>
            Understood! You've chosen to decline the Data Request from {userName} Would you like to
            add an explanation?
          </MessageText>
          <MessageText>
            Almost done. Add your explanation below, or DM. And <strong>{userName}</strong> @{' '}
            <strong>{action.fromCounterparty.user.organization.tradingName}</strong> will be
            informed that their data request has been denied.
          </MessageText>
          <MessageActions>
            <Button
              $size="large"
              disabled={!canActOnDataRequest}
              onClick={async () => {
                await declineDataRequest('Problem with stips');
                onTransferDataDeclineWithExplanationConfirm();
              }}
            >
              Problem with stipulations
            </Button>
            <Button
              $color="tertiary"
              $size="large"
              $variant="outlined"
              disabled={!canActOnDataRequest}
              onClick={async () => {
                await declineDataRequest();
                goToMessagingWithCounterparty(String(action.fromCounterparty.id));
              }}
            >
              DM {userName}
            </Button>
          </MessageActions>
        </PlusMessage>
      )}
    </React.Fragment>
  );
};
