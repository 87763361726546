import styled, { css } from 'styled-components/macro';

import { FlagType } from './FlagsAlertsWarnings';

export type VariantType = 'verbose' | 'compact';

export const Wrapper = styled.div<{ $variant: VariantType }>`
  justify-content: space-evenly;
  align-items: center;
  gap: ${({ $variant }) => ($variant === 'compact' ? 4 : 8)}px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: ${({ $variant }) => ($variant === 'compact' ? '-4px' : 0)};
  margin-left: ${({ $variant }) => ($variant === 'compact' ? '4px' : 0)};
  display: ${({ $variant }) => ($variant === 'compact' ? 'inline-flex' : 'flex')};
`;

export const baseItemStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  height: 24px;
`;

export const itemZeroCountStyles = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.white05};
  color: ${({ theme }) => theme.colors.white50};
`;

export const Item = styled.div<{ $variant?: VariantType; $flagType: FlagType; $noItems: boolean }>`
  ${baseItemStyles};
  border: 1px solid ${({ theme }) => theme.colors.white05};
  background-color: ${({ $flagType, theme }) => {
    if ($flagType === 'flag') {
      return theme.colors.flag;
    }
    if ($flagType === 'alert') {
      return theme.colors.alert;
    }
    if ($flagType === 'warning') {
      return theme.colors.warning;
    }
  }};

  ${({ $variant }) =>
    $variant === 'compact' &&
    css`
      width: auto;
      gap: 4px;
    `}
  ${({ $noItems }) => $noItems && itemZeroCountStyles}
`;

export const ItemTitle = styled.h4`
  margin: 0 0 0 4px;
  padding: 0;
  ${(props) => props.theme.typography.headings.headingRegular04};
`;

export const ItemCount = styled.span`
  ${(props) => props.theme.typography.headings.headingMedium03};
  background-color: ${(props) => props.theme.colors.blue20};
  padding: 2px 3px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  min-width: 16px;
  height: 16px;

  ${(props) => props.theme.typography.headings.headingMedium03Tight};
`;
