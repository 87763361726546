import { CalculationType, VisualisationType } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export type PortfolioAnalyticsCreationMutation = {
  metric: string;
  metricBy: string;
  visualisationType: VisualisationType;
  metricCalculationType?: CalculationType;
};

export const useCreatePortfolioAnalyticsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: PortfolioAnalyticsCreationMutation) => api.createPortfolioAnalytics(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PORTFOLIO_ANALYTICS]);
      },
    }
  );
};
