import { GetPortfolioGeodataPropertiesResponse, Identifier } from '@plus-platform/shared';
import { useQuery, UseQueryResult } from 'react-query';

import * as api from '../../api/portfolio';
import { QueryKeys } from './types';

export const useGeodataPropertiesQuery = (
  filterIds: Identifier[] = []
): UseQueryResult<GetPortfolioGeodataPropertiesResponse> =>
  useQuery([QueryKeys.GEODATA_PROPERTIES, filterIds], () =>
    api.getPortfolioGeodataProperties(filterIds)
  );
