import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum RentControlStatusType {
  Likely = 'Likely',
  No = 'No',
  Yes = 'Yes',
}

enum StructureFeatureType {
  Age = 'Age',
  Appeal = 'Appeal',
  Design = 'Design',
  GrossLivingArea = 'GrossLivingArea',
  LotShapeAndTopography = 'LotShapeAndTopography',
  LotSize = 'LotSize',
  MaterialAndConstruction = 'MaterialAndConstruction',
  Other = 'Other',
  Overall = 'Overall',
  Utilities = 'Utilities',
  View = 'View',
}

enum TypicalApartmentRentTrendType {
  Decreasing = 'Decreasing',
  Increasing = 'Increasing',
  Stable = 'Stable',
}

enum TypicalApartmentVacancyTrendType {
  Decreasing = 'Decreasing',
  Increasing = 'Increasing',
  Stable = 'Stable',
}

export type ComparisonToNeighborhood = MISMOEntity &
  Partial<{
    livingUnitCount: MISMOCount;
    rentControlDescription: MISMOString;
    rentControlStatusType: RentControlStatusType;
    sequenceNumber: MISMOSequenceNumber;
    storiesCount: MISMOCount;
    structureFeatureType: StructureFeatureType;
    structureFeatureTypeOtherDescription: MISMOString;
    structureTypicalToNeighborhoodAtypicalDescription: MISMOString;
    structureTypicalToNeighborhoodExistsIndicator: MISMOIndicator;
    typicalApartmentDescription: MISMOString;
    typicalApartmentEstimatedVacancyPercent: MISMOPercent;
    typicalApartmentRentHighPriceAmount: MISMOAmount;
    typicalApartmentRentLowPriceAmount: MISMOAmount;
    typicalApartmentRentTrendType: TypicalApartmentRentTrendType;
    typicalApartmentVacancyTrendType: TypicalApartmentVacancyTrendType;
  }>;
