import {
  MISMOEntity,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum NeighborhoodLandUseChangeStatusType {
  InProcess = 'InProcess',
  Likely = 'Likely',
  NotLikely = 'NotLikely',
}

export type PresentLandUseDetail = MISMOEntity &
  Partial<{
    neighborhoodLandUseChangeStatusType: NeighborhoodLandUseChangeStatusType;
    neighborhoodLandUseCurrentDescription: MISMOString;
    neighborhoodLandUseFutureDescription: MISMOString;
  }>;

enum NeighborhoodLandUseType {
  Apartment = 'Apartment',
  Commercial = 'Commercial',
  Condominium = 'Condominium',
  Cooperative = 'Cooperative',
  Industrial = 'Industrial',
  ManufacturedHome = 'ManufacturedHome',
  MultiFamily = 'MultiFamily',
  Other = 'Other',
  SingleFamily = 'SingleFamily',
  TwoToFourFamily = 'TwoToFourFamily',
  Vacant = 'Vacant',
}

export type PresentLandUse = MISMOEntity &
  Partial<{
    neighborhoodLandUseType: NeighborhoodLandUseType;
    neighborhoodLandUseTypeOtherDescription: MISMOString;
    neighborhoodPresentLandUsePercent: MISMOPercent;
    presentLandUseTotalPercent: MISMOPercent;
    sequenceNumber: MISMOSequenceNumber;
  }>;
