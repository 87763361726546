import styled from 'styled-components/macro';

export const ScoresWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1rem;
  max-width: 230px;
  margin: 0 auto;
  min-height: 32px;
`;

export const ScoreIconPair = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: ${({ theme }) => theme.colors.white};
`;
