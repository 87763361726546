import styled from 'styled-components';

export type BodyTextStyleProps = {
  $size?: 'large' | 'medium' | 'small' | 'xSmall';
  $weight?: 'regular' | 'medium' | 'bold';
  $lineHeight?: 'normal' | 'tight';
  $color?: string;
};

export const BodyText = styled.p<BodyTextStyleProps>`
  ${({ $lineHeight = 'normal', $size = 'large', $weight = 'regular', theme }) => {
    if ($lineHeight === 'tight') {
      if ($weight === 'regular') {
        if ($size === 'medium') {
          return theme.typography.bodyText.bodyRegular02Tight;
        }
        if ($size === 'small') {
          return theme.typography.bodyText.bodyRegular03Tight;
        }
      }
      if ($weight === 'medium') {
        if ($size === 'medium') {
          return theme.typography.bodyText.bodyMedium02Tight;
        }
        if ($size === 'small') {
          return theme.typography.bodyText.bodyMedium03Tight;
        }
      }
      if ($weight === 'bold') {
        if ($size === 'medium') {
          return theme.typography.bodyText.bodyBold02Tight;
        }
        if ($size === 'small') {
          return theme.typography.bodyText.bodyBold03Tight;
        }
      }
      // eslint-disable-next-line no-console
      console.warn(
        `BodyText Component style warning: Tight spacing not defined for the "${$size}" font-size`
      );
    }
    if ($weight === 'regular') {
      if ($size === 'large') {
        return theme.typography.bodyText.bodyRegular01;
      }
      if ($size === 'medium') {
        return theme.typography.bodyText.bodyRegular02;
      }
      if ($size === 'small') {
        return theme.typography.bodyText.bodyRegular03;
      }
      if ($size === 'xSmall') {
        return theme.typography.bodyText.bodyRegular04;
      }
    }
    if ($weight === 'medium') {
      if ($size === 'large') {
        return theme.typography.bodyText.bodyMedium01;
      }
      if ($size === 'medium') {
        return theme.typography.bodyText.bodyMedium02;
      }
      if ($size === 'small') {
        return theme.typography.bodyText.bodyMedium03;
      }
      if ($size === 'xSmall') {
        return theme.typography.bodyText.bodyMedium04;
      }
    }
    if ($weight === 'bold') {
      if ($size === 'large') {
        return theme.typography.bodyText.bodyBold01;
      }
      if ($size === 'medium') {
        return theme.typography.bodyText.bodyBold02;
      }
      if ($size === 'small') {
        return theme.typography.bodyText.bodyBold03;
      }
      if ($size === 'xSmall') {
        return theme.typography.bodyText.bodyBold04;
      }
    }
    return theme.typography.bodyText.bodyRegular01;
  }}
  color: ${({ $color, theme }) => $color || theme.colors.textPrimary01};
`;
