import styled from 'styled-components/macro';

import { Page, PageContent } from '../components/Page';

export const AdminPage = styled(Page)`
  display: grid;
  grid-template-columns: 216px 1fr;
  grid-auto-rows: 1fr;
  padding: 0;
`;

export const AdminPageContent = styled(PageContent)`
  grid-column: initial;
  overflow-y: auto;
`;
