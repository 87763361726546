import { PortfolioAnalyticMetric } from '@plus-platform/shared';
import { useTheme } from 'styled-components';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { plusChartTheme } from '../../styles/deprecatedChart';
import { formatCompactMonetary, formatPercentage } from '../../utils/formatUtils';
import { ChartWrapper } from './Analytics.styles';
import { AnalyticsChartProps, LabelsFormatter } from './utils';

const barChartLabelFormatters: Partial<Record<PortfolioAnalyticMetric, LabelsFormatter>> = {
  [PortfolioAnalyticMetric.DELINQUENCY_RATE]: (tick) => formatPercentage(tick),
  [PortfolioAnalyticMetric.UPB]: (tick) => formatCompactMonetary(tick),
};

export const AnalyticsBarChart = ({ data, metric }: AnalyticsChartProps) => {
  const theme = useTheme();

  const axisStyles = {
    grid: { stroke: 'transparent', fill: 'transparent' },
    axis: { stroke: theme.colors.white30 },
    tickLabels: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily.heading,
      fill: theme.colors.white,
      padding: 5,
    },
  };

  return (
    <ChartWrapper>
      <VictoryChart
        theme={{
          ...plusChartTheme,
          axis: {
            ...plusChartTheme.axis,
            style: axisStyles,
          },
          dependentAxis: {
            ...plusChartTheme.dependentAxis,
            style: axisStyles,
          },
        }}
        domainPadding={{ x: [50, 50] }}
      >
        <VictoryAxis />
        <VictoryAxis dependentAxis tickFormat={barChartLabelFormatters?.[metric]} />
        <VictoryBar
          alignment="middle"
          barRatio={0.8}
          cornerRadius={{
            topLeft: 4,
            topRight: 4,
          }}
          style={{
            data: {
              fill: theme.colors.statusTurquoise,
              fillOpacity: '100%',
              stroke: 'none',
            },
            labels: { fontSize: '10px', fill: theme.colors.white },
          }}
          data={data}
          x="valueLabel"
          y="value"
        />
      </VictoryChart>
    </ChartWrapper>
  );
};
