import styled, { css } from 'styled-components/macro';

type LabelProps = React.HTMLAttributes<HTMLSpanElement> & {
  $isSmall: boolean;
};

const dirtyCss = css`
  transform: scale(0.8) translateY(-0.6em) translateX(0);
  transform-origin: top left;
  opacity: 0.9;
`;

export const Label = styled.span<LabelProps>(
  ({ $isSmall, theme }) => css`
    color: ${theme.colors.inputLabelText};
    display: inline-block;
    background: transparent;
    ${theme.typography.bodyText.bodyMedium02};
    position: absolute;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    margin-bottom: 0.5em;

    ${$isSmall && dirtyCss}
  `
);

export const Option = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;
