import styled, { css } from 'styled-components/macro';

import { MenuLineIcon } from '../icons';

const Avatar = styled.div<{ $size: number }>(
  ({ $size, theme }) => css`
    width: ${$size}px;
    height: ${$size}px;
    background: ${theme.colors.primary03};
    color: ${theme.colors.accent01};
    border-radius: ${$size / 2}px;
  `
);

type PlusAvatarProps = {
  size?: number;
};

export const PlusAvatar = ({ size = 24 }: PlusAvatarProps) => {
  return (
    <Avatar $size={size}>
      <MenuLineIcon width={size} height={size} />
    </Avatar>
  );
};
