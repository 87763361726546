import { GetSummaryByAnalyticGroupResponse } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export type SummaryByAnalyticGroupProps = {
  analyticId: string;
  groupValue: string;
};

export const getSummaryByAnalyticGroup = async ({
  analyticId,
  groupValue,
}: SummaryByAnalyticGroupProps) => {
  const url = makeApiUrl(`/portfolio/analytics/${analyticId}/group/${groupValue}`);
  return getRequestWithAuth<GetSummaryByAnalyticGroupResponse>(url);
};
