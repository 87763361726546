import {
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ConditionSatisfactionResponsiblePartyType {
  Attorney = 'Attorney',
  Broker = 'Broker',
  Closer = 'Closer',
  ClosingAgent = 'ClosingAgent',
  EscrowCompany = 'EscrowCompany',
  LoanOfficer = 'LoanOfficer',
  Originator = 'Originator',
  Other = 'Other',
  Processor = 'Processor',
  SettlementAgent = 'SettlementAgent',
  TitleCompany = 'TitleCompany',
}

enum ConditionSatisfactionTimeframeType {
  Other = 'Other',
  PriorToApproval = 'PriorToApproval',
  PriorToDocuments = 'PriorToDocuments',
  PriorToFunding = 'PriorToFunding',
  PriorToSigning = 'PriorToSigning',
  UnderwriterToReview = 'UnderwriterToReview',
}

export type Condition = MISMOEntity &
  Partial<{
    conditionDescription: MISMOString;
    conditionMetIndicator: MISMOIndicator;
    conditionSatisfactionApprovedByName: MISMOString;
    conditionSatisfactionDate: MISMODate;
    conditionSatisfactionDueDate: MISMODate;
    conditionSatisfactionResponsiblePartyType: ConditionSatisfactionResponsiblePartyType;
    conditionSatisfactionResponsiblePartyTypeOtherDescription: MISMOString;
    conditionSatisfactionTimeframeType: ConditionSatisfactionTimeframeType;
    conditionSatisfactionTimeframeTypeOtherDescription: MISMOString;
    conditionWaivedIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
  }>;
