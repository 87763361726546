import styled from 'styled-components/macro';

export const Content = styled.div`
  flex: 1;
  position: relative;
`;

export const DocumentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 36px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  margin-left: 24px;

  input {
    height: 32px;
    border-color: rgba(255, 255, 255, 0.6);
    font-family: ${(props) => props.theme.typography.fontFamily.text};
    font-size: 14px;
    font-weight: 400;
    color: white;

    &::placeholder {
      font-family: ${(props) => props.theme.typography.fontFamily.text};
      font-size: 14px;
      line-height: 32px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
    }
  }
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
`;
