import React from 'react';
import { AnyStyledComponent } from 'styled-components';

import type { BodyTextStyleProps } from './BodyText.styles';
import * as Styled from './BodyText.styles';

export type BodyTextProps = BodyTextStyleProps &
  React.HTMLAttributes<HTMLElement> & {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any> | AnyStyledComponent;
  };

export const BodyText = ({ as = 'p', ...props }: BodyTextProps) => {
  return <Styled.BodyText {...props} as={as} />;
};
