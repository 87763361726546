import React from 'react';
import { NavLinkProps } from 'react-router-dom';

import * as Styles from './LinkToggle.styles';

type LinkIconProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  NavLinkProps & {
    size?: 'large' | 'medium' | 'small';
    lightBackground?: boolean;
  };

export const LinkToggle = ({ children, lightBackground, size, ...rest }: LinkIconProps) => {
  return (
    <Styles.Link $size={size} $lightBackground={lightBackground} {...rest}>
      {children}
    </Styles.Link>
  );
};
