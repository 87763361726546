import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, UserMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';

type OutboundDataRequestRequestedProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundDataRequestRequested = (props: OutboundDataRequestRequestedProps) => {
  const { action, tradeRequest } = props;
  const { createdAt, fromCounterparty } = action;

  return (
    <UserMessage
      date={createdAt}
      organizationName={fromCounterparty.user.organization.tradingName}
      lastName={fromCounterparty.user.lastName}
      firstName={fromCounterparty.user.firstName}
      userAvatar={fromCounterparty.user.avatarUrl}
    >
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.DataRequest.Requested"
          values={{
            poolName: tradeRequest.pool.name,
            ticketId: tradeRequest.id,
          }}
        />
      </MessageText>
    </UserMessage>
  );
};
