import { FixedBroadbandData } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type FixedBroadbandResponse = FixedBroadbandData;

export const getFixedBroadband = async (params: URLSearchParams) => {
  return getRequestWithAuth<FixedBroadbandResponse>(
    makeApiUrl(`fixed-broadband/providers?${params}`)
  );
};

export const useFixedBroadbandQuery = (latitude?: number, longitude?: number) => {
  const params = new URLSearchParams({
    latitude: String(latitude),
    longitude: String(longitude),
  });

  return useQuery([QueryKeys.FIXED_BROADBAND, params.toString()], () => getFixedBroadband(params), {
    enabled: Boolean(latitude && longitude),
  });
};
