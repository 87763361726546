import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
  MISMOYear,
} from '../types';

enum TaxAuthorityType {
  AnnualCityBondAuthorityTax = 'AnnualCityBondAuthorityTax',
  AnnualCountyBondAuthorityTax = 'AnnualCountyBondAuthorityTax',
  AssessmentDistrictTax = 'AssessmentDistrictTax',
  BondAuthorityTax = 'BondAuthorityTax',
  BoroughTax = 'BoroughTax',
  CentralAppraisalTaxingAuthorityTax = 'CentralAppraisalTaxingAuthorityTax',
  CentralCollectionTaxingAuthorityTax = 'CentralCollectionTaxingAuthorityTax',
  CityAndSchoolTax = 'CityAndSchoolTax',
  CityTax = 'CityTax',
  CombinationCollectionTax = 'CombinationCollectionTax',
  CondoAssessmentTax = 'CondoAssessmentTax',
  ConservancyTax = 'ConservancyTax',
  CountyCollectedByOtherTaxingAuthorityTax = 'CountyCollectedByOtherTaxingAuthorityTax',
  CountyTax = 'CountyTax',
  DrainageTax = 'DrainageTax',
  FireOrPoliceTax = 'FireOrPoliceTax',
  GroundRentTax = 'GroundRentTax',
  HomeownersAssociationTax = 'HomeownersAssociationTax',
  HospitalsTax = 'HospitalsTax',
  ImprovementTax = 'ImprovementTax',
  IrrigationTax = 'IrrigationTax',
  JuniorCollegesTax = 'JuniorCollegesTax',
  LightingTax = 'LightingTax',
  MobileHomeAuthorityTax = 'MobileHomeAuthorityTax',
  MunicipalityTax = 'MunicipalityTax',
  MunicipalServicesTax = 'MunicipalServicesTax',
  OccupancyTax = 'OccupancyTax',
  Other = 'Other',
  ParishTax = 'ParishTax',
  PavingTax = 'PavingTax',
  PUDTax = 'PUDTax',
  ReclamationDistrictTax = 'ReclamationDistrictTax',
  RoadBondTax = 'RoadBondTax',
  RoadsOrBridgesTax = 'RoadsOrBridgesTax',
  SanitationTax = 'SanitationTax',
  SchoolDistrictTax = 'SchoolDistrictTax',
  SemiannualCityBondAuthorityTax = 'SemiannualCityBondAuthorityTax',
  SemiannualCountyBondAuthorityTax = 'SemiannualCountyBondAuthorityTax',
  SpecialApplicationsTax = 'SpecialApplicationsTax',
  SpecialAssessment = 'SpecialAssessment',
  SpecialDistrictTax = 'SpecialDistrictTax',
  StateAndCountyTax = 'StateAndCountyTax',
  StateTaxingAuthorityTax = 'StateTaxingAuthorityTax',
  StormTax = 'StormTax',
  SupplementalTax = 'SupplementalTax',
  TaxBillFee = 'TaxBillFee',
  TownshipAndCountyTax = 'TownshipAndCountyTax',
  TownshipTax = 'TownshipTax',
  TownTax = 'TownTax',
  UnsecuredCountyTaxes = 'UnsecuredCountyTaxes',
  UtilityDistrictTax = 'UtilityDistrictTax',
  VillageTax = 'VillageTax',
  WaterControlTax = 'WaterControlTax',
  WaterOrSewerDistrictTax = 'WaterOrSewerDistrictTax',
}

export type PropertyTax = MISMOEntity &
  Partial<{
    propertyTaxAssessmentEndYear: MISMOYear;
    propertyTaxAssessmentStartYear: MISMOYear;
    propertyTaxAtypicalCommentDescription: MISMOString;
    propertyTaxCertificateDate: MISMODate;
    propertyTaxCountyRateAreaIdentifier: MISMOIdentifier;
    propertyTaxDelinquentIndicator: MISMOIndicator;
    propertyTaxEndYear: MISMOYear;
    propertyTaxExemptionIndicator: MISMOIndicator;
    propertyTaxImprovementValueAmount: MISMOAmount;
    propertyTaxLandValueAmount: MISMOAmount;
    propertyTaxTotalAssessedValueAmount: MISMOAmount;
    propertyTaxTotalSpecialTaxAmount: MISMOAmount;
    propertyTaxTotalTaxableValueAmount: MISMOAmount;
    propertyTaxTotalTaxAmount: MISMOAmount;
    propertyTaxTotalTaxWithoutSpecialAssessmentsAmount: MISMOAmount;
    propertyTaxToValueRatePercent: MISMOPercent;
    propertyTaxTypicalTaxIndicator: MISMOIndicator;
    propertyTaxYearIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
    taxAuthorityAccountBillIdentifier: MISMOIdentifier;
    taxAuthorityType: TaxAuthorityType;
    taxAuthorityTypeOtherDescription: MISMOString;
    taxContractIdentifier: MISMOIdentifier;
    taxContractLifeOfLoanIndicator: MISMOIndicator;
    taxContractOrderDate: MISMODate;
    taxContractSuffixIdentifier: MISMOIdentifier;
    taxEscrowedIndicator: MISMOIndicator;
    taxItemExemptionIndicator: MISMOIndicator;
    // propertyTaxExemptions: PropertyTaxExemption[];
    // propertyTaxSpecials: PropertyTaxSpecial[];
  }>;
