import styled, { css } from 'styled-components/macro';

import { useGetTermsAndConditionsQuery } from '../../hooks/queries/useGetTermsAndConditionsQuery';
import { SubmitButton } from './styles';

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  ${({ theme }) => css`
    overflow-y: scroll;
    padding: 30px 60px 15px;
    font-size: 16px;
    line-height: 140%;
    white-space: pre-wrap;
    color: ${theme.colors.statusBlack};

    p {
      margin: 0;
    }
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 60px;
`;

type TermsAndConditionsFormProps = {
  onAccept: () => void;
};

export const TermsAndConditionsForm = ({ onAccept }: TermsAndConditionsFormProps) => {
  // @todo: add loading and error states when fetching terms and conditions from the API
  const { data: termsAndConditions } = useGetTermsAndConditionsQuery();

  return (
    <Container>
      <Content>
        <p>{termsAndConditions.terms}</p>
      </Content>
      <ButtonWrapper>
        <SubmitButton $slim onClick={onAccept}>
          Accept
        </SubmitButton>
      </ButtonWrapper>
    </Container>
  );
};
