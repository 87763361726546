import { PortfolioBreakdown } from '@plus-platform/shared';

import { formatNumber } from '../../utils/formatUtils';
import {
  Analytic,
  AnalyticContainer,
  AnalyticCount,
  AnalyticHeader,
  AnalyticTitle,
} from './Analytic';
import { LoanRateChart } from './BreakdownTile';
import { mapBreakdownForLoanRateChart } from './breakdownUtils';

type InterestRateSensitivityMetricsProps = {
  portfolioBreakdown?: PortfolioBreakdown;
};

export const InterestRateSensitivityMetrics = (props: InterestRateSensitivityMetricsProps) => {
  const { portfolioBreakdown } = props;

  const data = mapBreakdownForLoanRateChart(portfolioBreakdown);

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Interest rate sensitivity</AnalyticTitle>
          <AnalyticCount>{formatNumber(data.length)}</AnalyticCount>
        </AnalyticHeader>

        <LoanRateChart data={data} />
      </AnalyticContainer>
    </Analytic>
  );
};
