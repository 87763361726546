import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

const resendUserInvitation = (inviteId: string) => {
  const url = makeApiUrl(`/users/invite/${inviteId}/resend`);
  return postRequestWithAuth(url);
};

export const useResendUserInvitationMutation = () => {
  return useMutation(resendUserInvitation);
};
