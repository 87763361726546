import { Party } from '../party';
import { Property } from '../property';
import {
  MISMODatetime,
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { LegalAndVesting } from './legalAndVesting';

enum TitleProductType {
  ClosingProtectionLetter = 'ClosingProtectionLetter',
  Other = 'Other',
  TitleCommitment = 'TitleCommitment',
  TitlePolicy = 'TitlePolicy',
}

type TitleCommitment = MISMOEntity &
  Partial<{
    titleCommitmentIdentifier: MISMOIdentifier;
    legalAndVestings: LegalAndVesting[];
    parties: Party[];
    // titleAdditionalExceptions: TitleAdditionalException[];
    // titleAdditionalRequirements: TitleAdditionalRequirement[];
    // titleCommitmentProposedPolicies: TitleCommitmentProposedPolicy[];
    // titleExistingLoansAndLiabilitiesInformation: TitleExistingLoansAndLiabilitiesInformation;
  }>;

type TitleProduct = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    titleProductEffectiveDatetime: MISMODatetime;
    titleProductTitleSearchPerformedDescription: MISMOString;
    titleProductType: TitleProductType;
    titleProductTypeOtherDescription: MISMOString;
    property: Property;
  }> &
  // ClosingProtectionLetter |
  TitleCommitment;
//  TitlePolicies

type TitleResponse = MISMOEntity &
  Partial<{
    // agentValidation: AgentValidation;
    // executions: Execution[];
    // parties: Party[];
    // remittances: Remittance[];
    titleProducts: TitleProduct[];
  }>;

export type Title = MISMOEntity &
  Partial<{
    // titleRequest: TitleRequest;
    titleResponse: TitleResponse;
  }>;
