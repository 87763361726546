import { FoundationWall } from './foundationWall';
import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export enum FoundationDeficienciesType {
  Dampness = 'Dampness',
  Infestation = 'Infestation',
  Other = 'Other',
  Settlement = 'Settlement',
}

enum FoundationMaterialType {
  BlockAndPier = 'BlockAndPier',
  ConcreteRunners = 'ConcreteRunners',
  Other = 'Other',
  PouredConcrete = 'PouredConcrete',
}

export enum FoundationType {
  Basement = 'Basement',
  Crawlspace = 'Crawlspace',
  Other = 'Other',
  PartialBasement = 'PartialBasement',
  Slab = 'Slab',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type Foundation = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    foundationConditionDescription: MISMOString;
    foundationDeficienciesType: FoundationDeficienciesType;
    foundationDeficienciesTypeOtherDescription: MISMOString;
    foundationDescription: MISMOString;
    foundationExistsIndicator: MISMOIndicator;
    foundationMaterialType: FoundationMaterialType;
    foundationMaterialTypeOtherDescription: MISMOString;
    foundationType: FoundationType;
    foundationTypeOtherDescription: MISMOString;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
    // foundationFeatures: FoundationFeature[];
    foundationWalls: FoundationWall[];
  }>;
