import { UserIdentifier } from '@plus-platform/shared';
import at from 'lodash/at';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import xor from 'lodash/xor';
import React, { useCallback, useMemo } from 'react';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { AvatarWithFallback } from '../components/AvatarWithFallback';
import { Button } from '../components/Button';
import { ButtonIcon } from '../components/ButtonIcon';
import { Checkbox } from '../components/Checkbox';
import { Heading } from '../components/Heading';
import { AddIcon, CloseLineIcon } from '../components/icons';
import { MenuSection } from '../components/Menu';
import { Popup } from '../components/Popup';
import { SearchBar } from '../components/SearchBar';
import { useTradingContactsQuery } from '../hooks/queries/useTradingContactsQuery';
import { UserProfile } from '../users/types';
import * as Styles from './DistributionListDialog.styles';
import { formatNameWithInitial } from './tradeRequestsUtils';

const getSectionTitleFromInitial = (initial: string) => {
  return `${initial.toUpperCase()}${initial.toLowerCase()}`;
};

const getNameInitial = (name: string) => {
  return name.toUpperCase()[0];
};

const getGroupedContacts = (contacts: UserProfile[]) => {
  const groupedContacts = groupBy(sortBy(contacts, ['lastName']), (contact) =>
    getNameInitial(contact.lastName)
  );

  return Object.entries(groupedContacts).map(([initial, contacts]) => ({
    title: getSectionTitleFromInitial(initial),
    contacts,
  }));
};

const getFilteredContacts = (contacts: UserProfile[], filter: string) => {
  return contacts.filter((contact) => {
    const searchableValues = at<string>(contact, [
      'lastName',
      'firstName',
      'email',
      'organization.tradingName',
    ]);

    return searchableValues.some((value = '') =>
      value.toLowerCase().includes(filter.toLowerCase())
    );
  });
};

type DistributionListDialogProps = {
  selectedContacts?: UserIdentifier[];
  onContactsSelect(contacts: UserIdentifier[]): void;
  onCancel(): void;
};

type UserGroup = { title: string; contacts: UserProfile[] };

export const DistributionListDialog = (props: DistributionListDialogProps) => {
  const { selectedContacts = [], onContactsSelect, onCancel } = props;
  const [filter, setFilter] = React.useState('');
  const [selectedContactIds, setSelectedContactIds] =
    React.useState<UserIdentifier[]>(selectedContacts);
  const { data = [], isLoading } = useTradingContactsQuery();

  const hasSelectedContacts = useMemo(() => selectedContactIds.length > 0, [selectedContactIds]);
  const filteredContacts = useMemo(() => getFilteredContacts(data, filter), [data, filter]);

  const contactsGroupedByInitial = useMemo(() => {
    return getGroupedContacts(filteredContacts);
  }, [filteredContacts]);

  const [selectedGroup, setSelectedGroup] = React.useState(`all`);

  const contactGroups: UserGroup[] = []; // TODO: Get groups from API

  const isContactSelected = (contact: UserProfile) => {
    return selectedContactIds.includes(contact.id);
  };

  const toggleSelectedContact = (contact: UserProfile) => {
    setSelectedContactIds((ids) => xor(ids, [contact.id]));
  };

  const handleClose = useCallback(() => {
    return onContactsSelect(selectedContactIds);
  }, [hasSelectedContacts, onContactsSelect, onCancel, selectedContactIds]);

  const handleAddGroup = useCallback(() => {
    // TODO: Add API call for adding groups;
  }, []);

  return (
    <Popup width="1120px" minHeight="518px">
      <Styles.PopupTitleWrapper>
        <Heading $size="large">Add recipients</Heading>
        <ButtonIcon onClick={handleClose} size="medium">
          <CloseLineIcon />
        </ButtonIcon>
      </Styles.PopupTitleWrapper>
      <ActivityIndicator contain isActive={isLoading}>
        <Styles.MenuContactsWrapper>
          <Styles.MenuSectionWrapper>
            <MenuSection>
              <Styles.GroupSelector
                onClick={() => setSelectedGroup('all')}
                disabled={selectedGroup === 'all'}
              >
                All contacts ({filteredContacts.length})
              </Styles.GroupSelector>
              {contactGroups.map((group) => (
                <Styles.GroupSelector
                  key={group.title}
                  onClick={() => setSelectedGroup(group.title)}
                  disabled={selectedGroup === group.title}
                >
                  {group.title} ({group.contacts.length})
                </Styles.GroupSelector>
              ))}
            </MenuSection>
            <Styles.AddButtonWrapper>
              <Button $hasStartIcon $variant="outlined" $size="small" onClick={handleAddGroup}>
                <AddIcon />
                Add group
              </Button>
            </Styles.AddButtonWrapper>
          </Styles.MenuSectionWrapper>
          <Styles.ContactsWrapper>
            <Styles.ContactsTitleWrapper>
              <Styles.HeadingWrapper>
                <Heading as="h3" $size="medium">
                  {selectedContactIds.length} contacts selected
                </Heading>
              </Styles.HeadingWrapper>
              <Styles.SearchWrapper>
                <SearchBar
                  // TODO: Update id/name/placeholder/testid to be more specific
                  id="search-loans"
                  name="search-loans"
                  placeholder="Search..."
                  data-testid="SearchBar_Input"
                  onChange={(event) => setFilter(event.target.value)}
                  onClear={() => setFilter('')}
                  value={filter}
                  $lightBackground
                />
              </Styles.SearchWrapper>
            </Styles.ContactsTitleWrapper>
            {contactsGroupedByInitial.map((group) => (
              <Styles.ContactList key={group.title}>
                <Heading $size="medium">{group.title[0]}</Heading>
                {group.contacts.map((contact) => (
                  <Styles.ContactListItem key={contact.id}>
                    <Styles.ContactLabel $isSelected={isContactSelected(contact)}>
                      <Checkbox
                        checked={isContactSelected(contact)}
                        onChange={() => toggleSelectedContact(contact)}
                      />
                      <Styles.ContactAvatarWrapper>
                        <AvatarWithFallback
                          firstName={contact.firstName}
                          lastName={contact.lastName}
                          src={contact.avatarUrl}
                          size={24}
                        />
                        <Styles.ContactName>
                          {formatNameWithInitial(contact.lastName, contact.firstName)}
                        </Styles.ContactName>
                      </Styles.ContactAvatarWrapper>
                      <Styles.ContactOrganization>
                        {contact.organization?.tradingName}
                      </Styles.ContactOrganization>
                    </Styles.ContactLabel>
                  </Styles.ContactListItem>
                ))}
              </Styles.ContactList>
            ))}
          </Styles.ContactsWrapper>
        </Styles.MenuContactsWrapper>
      </ActivityIndicator>
    </Popup>
  );
};
