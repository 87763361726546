import { CompletedTradeRequestWithSummary, CounterpartyType } from '@plus-platform/shared';
import { useRef } from 'react';
import { FetchNextPageOptions } from 'react-query';
import styled, { useTheme } from 'styled-components/macro';

import { Badge, BadgeWrapper } from '../components/Badge';
import {
  Cell,
  HeaderCell,
  HeaderRow,
  LoadingRow,
  Row,
  Table,
  Tbody,
  Thead,
} from '../components/Table';
import { VirtualizedTable } from '../components/VirtualizedTable';
import { RowWrapper } from '../portfolio/PortfolioTable';
import {
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../utils/formatUtils';
import { formatNameWithInitial } from './tradeRequestsUtils';

const TABLE_COLUMN_COUNT = 6;

type CompletedTradeTableProps = {
  tradeRequests: CompletedTradeRequestWithSummary[];
  totalCount: number;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  maxVisibleItems?: number;
};

const TradeRequestStatus = styled.span`
  display: inline-flex;
  align-items: center;

  & + & {
    margin-left: 10px;
  }
`;

const COMPLETED_TABLE_COLUMN_CONFIG = `1fr 350px 100px 1fr 1fr 1fr`;

const CompletedTableHeaderRow = styled(HeaderRow)`
  grid-template-columns: ${COMPLETED_TABLE_COLUMN_CONFIG};
`;

const CompletedTableRow = styled(Row)`
  grid-template-columns: ${COMPLETED_TABLE_COLUMN_CONFIG};
`;

export const CompletedTradesTable = ({
  fetchNextPage,
  isFetchingNextPage,
  maxVisibleItems,
  totalCount,
  tradeRequests,
}: CompletedTradeTableProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  return (
    <Table>
      <Thead>
        <CompletedTableHeaderRow $columnCount={TABLE_COLUMN_COUNT}>
          <HeaderCell>Pool name</HeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell>Buy/Sell</HeaderCell>
          <HeaderCell>Transaction price $</HeaderCell>
          <HeaderCell>Purchaser</HeaderCell>
          <HeaderCell>Number of loans in pool</HeaderCell>
        </CompletedTableHeaderRow>
      </Thead>
      <Tbody>
        <VirtualizedTable
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          items={tradeRequests}
          maxVisibleItems={maxVisibleItems}
          parentRef={parentRef}
          renderRow={(isLoaderRow, virtualRow, tradeRequest) => {
            if (!tradeRequest) {
              return null;
            }

            return (
              <RowWrapper height={virtualRow.size} offsetY={virtualRow.start}>
                {isLoaderRow && virtualRow.index < totalCount && (
                  <LoadingRow>Loading more...</LoadingRow>
                )}

                {!isLoaderRow && (
                  <CompletedTableRow $columnCount={TABLE_COLUMN_COUNT}>
                    <Cell>{formatDefaultValue(tradeRequest.pool.name)}</Cell>
                    <Cell>
                      <TradeRequestStatus>
                        <BadgeWrapper>
                          <Badge $color={theme.colors.statusGreen} /> Sold
                        </BadgeWrapper>
                      </TradeRequestStatus>
                      <TradeRequestStatus>
                        <BadgeWrapper>
                          <Badge $color={theme.colors.statusYellow} /> Settlement pending
                        </BadgeWrapper>
                      </TradeRequestStatus>
                      <TradeRequestStatus>
                        <BadgeWrapper>
                          <Badge $color={theme.colors.statusYellow} /> Transfer pending
                        </BadgeWrapper>
                      </TradeRequestStatus>
                    </Cell>
                    <Cell>
                      {tradeRequest.purchaser.type === CounterpartyType.BUYER ? 'Buy' : 'Sell'}
                    </Cell>
                    <Cell>
                      {formatMonetary(
                        tradeRequest.pool.summary.loansUPBAmount * tradeRequest.pricePercentage
                      )}{' '}
                      ({formatPercentage(tradeRequest.pricePercentage)})
                    </Cell>
                    <Cell>
                      {formatNameWithInitial(
                        tradeRequest.purchaser.user.lastName,
                        tradeRequest.purchaser.user.firstName
                      )}
                    </Cell>
                    <Cell>{formatNumber(tradeRequest.pool.loanIds.length)}</Cell>
                  </CompletedTableRow>
                )}
              </RowWrapper>
            );
          }}
          totalItemsCount={totalCount}
        />
      </Tbody>
    </Table>
  );
};
