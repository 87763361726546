import React from 'react';

export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3172 17L5.5 12.234L6.42473 11.3191L10.3172 15.1702L18.5753 7L19.5 7.91489L10.3172 17Z"
        fill="currentColor"
      />
      <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
};
