import {
  MISMOCode,
  MISMODate,
  MISMOEntity,
  MISMOString,
  MISMOURI,
} from './types';

export enum CreditCommentCodeSourceType {
  EquifaxBankruptcy = 'EquifaxBankruptcy',
  EquifaxCollectionStatus = 'EquifaxCollectionStatus',
  EquifaxLegalStatus = 'EquifaxLegalStatus',
  EquifaxNarrative = 'EquifaxNarrative',
  ExperianAccountPurpose = 'ExperianAccountPurpose',
  ExperianAccountStatus = 'ExperianAccountStatus',
  ExperianLegalStatus = 'ExperianLegalStatus',
  ExperianSpecialComment = 'ExperianSpecialComment',
  Other = 'Other',
  TransUnionLoanType = 'TransUnionLoanType',
  TransUnionPublicRecordType = 'TransUnionPublicRecordType',
  TransUnionRemarks = 'TransUnionRemarks',
}

export enum CreditCommentSourceType {
  Borrower = 'Borrower',
  CreditBureau = 'CreditBureau',
  Equifax = 'Equifax',
  Experian = 'Experian',
  Lender = 'Lender',
  RepositoryBureau = 'RepositoryBureau',
  TransUnion = 'TransUnion',
}

export enum CreditCommentType {
  BureauRemarks = 'BureauRemarks',
  ConsumerStatement = 'ConsumerStatement',
  Instruction = 'Instruction',
  Other = 'Other',
  PublicRecordText = 'PublicRecordText',
  StatusCode = 'StatusCode',
  Warning = 'Warning',
}

export type CreditComment = MISMOEntity &
  Partial<{
    creditCommentCode: MISMOCode;
    'creditCommentCode@CodeOwnerURI': MISMOURI;
    creditCommentCodeSourceType: CreditCommentCodeSourceType;
    creditCommentCodeSourceTypeOtherDescription: MISMOString;
    creditCommentReportedDate: MISMODate;
    creditCommentSourceType: CreditCommentSourceType;
    creditCommentText: MISMOString;
    creditCommentType: CreditCommentType;
    creditCommentTypeOtherDescription: MISMOString;
  }>;
