import 'swiper/css';

import React from 'react';
import { SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react';

import { ChevronLeftIcon, ChevronRightIcon } from '../../components/icons';
import {
  Container,
  LeftNavigationButtonOverlay,
  NavigationButton,
  RightNavigationButtonOverlay,
  Slider,
} from './AnalyticSlider.styles';

type Props = {
  children: React.ReactNode;
};

export const AnalyticSlider = (props: Props) => {
  const swiper = React.useRef<SwiperRef | null>(null);
  const [isAtStart, setIsAtStart] = React.useState(true);
  const [isAtEnd, setIsAtEnd] = React.useState(false);

  const goToPreviousSlide = () => {
    swiper.current?.swiper.slidePrev();
  };

  const goToNextSlide = () => {
    swiper.current?.swiper.slideNext();
  };

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.initialized) {
      setIsAtStart(swiper.isBeginning);
      setIsAtEnd(swiper.isEnd);
    }
  };

  return (
    <Container>
      <Slider
        slidesPerView="auto"
        spaceBetween={12}
        ref={swiper}
        onToEdge={onSlideChange}
        onFromEdge={onSlideChange}
      >
        {props.children}
      </Slider>

      {!isAtStart && (
        <LeftNavigationButtonOverlay>
          <NavigationButton onClick={goToPreviousSlide}>
            <ChevronLeftIcon />
          </NavigationButton>
        </LeftNavigationButtonOverlay>
      )}

      {!isAtEnd && (
        <RightNavigationButtonOverlay>
          <NavigationButton onClick={goToNextSlide}>
            <ChevronRightIcon />
          </NavigationButton>
        </RightNavigationButtonOverlay>
      )}
    </Container>
  );
};

export const AnalyticSlide = SwiperSlide;
