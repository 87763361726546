import { InboundTradeRequestWithSummary } from '@plus-platform/shared';
import React from 'react';
import { Redirect, Switch, useParams } from 'react-router-dom';

import { PrivateRoute } from '../global/PrivateRoute';
import { TradeRequestDetailPage } from './TradeRequestDetailPage';
import { getSellerCounterparty } from './tradeRequestsUtils';
import { TradeRequestTransactionLogPage } from './TradeRequestTransactionLogPage';
import { useTradeRequestSummary } from './useTradeRequestSummary';

type TradeRequestPageParams = {
  category: string;
  tradeId: string;
};

export const TradeRequestInboundPage = () => {
  const { category, tradeId } = useParams<TradeRequestPageParams>();
  const { data: tradeRequest, isLoading } = useTradeRequestSummary<InboundTradeRequestWithSummary>(
    tradeId,
    category
  );

  const sellerCounterparty = getSellerCounterparty(tradeRequest?.counterparties || []);
  if (!sellerCounterparty) {
    return null;
  }

  return (
    <Switch>
      <PrivateRoute
        path="/trade-requests/:category/:tradeId/:counterpartyId/transaction-log"
        render={() => (
          <TradeRequestTransactionLogPage isLoading={isLoading} tradeRequest={tradeRequest} />
        )}
      />
      <PrivateRoute
        path={[
          '/trade-requests/:category/:tradeId/:counterpartyId/:tabType',
          '/trade-requests/:category/:tradeId/:counterpartyId',
        ]}
        render={() => <TradeRequestDetailPage />}
      />
      <React.Fragment>
        <Redirect to={`/trade-requests/${category}/${tradeId}/${sellerCounterparty.id}`} />
      </React.Fragment>
    </Switch>
  );
};
