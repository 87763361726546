import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum CarStorageAttachmentType {
  Attached = 'Attached',
  BuiltIn = 'BuiltIn',
  Detached = 'Detached',
}

export enum CarStorageType {
  Carport = 'Carport',
  Covered = 'Covered',
  Driveway = 'Driveway',
  Garage = 'Garage',
  OffStreet = 'OffStreet',
  Open = 'Open',
  Other = 'Other',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum ProjectParkingSpaceAssignmentType {
  Assigned = 'Assigned',
  Other = 'Other',
  Owned = 'Owned',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type CarStorage = MISMOEntity &
  Partial<{
    adequateIndicator: MISMOIndicator;
    carStorageAttachmentType: CarStorageAttachmentType;
    carStorageDescription: MISMOString;
    carStorageIndicator: MISMOIndicator;
    carStorageType: CarStorageType;
    carStorageTypeOtherDescription: MISMOString;
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    materialDescription: MISMOString;
    parkingSpaceIdentifier: MISMOIdentifier;
    parkingSpacesCount: MISMOCount;
    projectCarStorageAdequacyEffectOnMarketabilityDescription: MISMOString;
    projectCarStorageDescription: MISMOString;
    projectCarStorageSpacesToUnitsRatioPercent: MISMOPercent;
    projectGuestParkingIndicator: MISMOIndicator;
    projectGuestParkingSpacesCount: MISMOCount;
    projectParkingSpaceAssignmentType: ProjectParkingSpaceAssignmentType;
    projectParkingSpaceAssignmentTypeOtherDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
  }>;
