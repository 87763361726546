import styled, { css } from 'styled-components/macro';

import { DelinquencyVariant } from '../../components/DelinquencyPanes/DelinquencyPanes';
import { DeltaIcon } from '../../components/icons';

const VERTICAL_BAR_SPACING = '4px';

export const Wrapper = styled.div`
  border-radius: 8px;
  height: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.div<{
  $withoutBorder: boolean;
}>`
  border-bottom: 4px solid ${({ theme }) => theme.colors.strokeBlue01};
  padding-bottom: 16px;
  margin-bottom: 16px;
  ${(props) =>
    props.$withoutBorder &&
    `
      border: none;
      padding: 0;
      margin: 0;
  `};
`;

const Title = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
`;

export const Heading = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  font-weight: 500;
`;

export const Subheading = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  font-weight: 500;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily.text};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  margin: 4px 0;
`;

export const Container = styled.div<{ $variant?: DelinquencyVariant }>(
  ({ $variant }) => css`
    display: flex;
    flex-direction: column;
    gap: ${$variant === 'pool' ? 4 : 9}px;
    padding: 0 4px;
  `
);

export const Row = styled.div<{ $variant?: DelinquencyVariant }>(
  ({ $variant }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: ${$variant === 'pool' ? 8 : 16}px;
  `
);

export const Label = styled(Text)<{ $variant?: DelinquencyVariant }>(
  ({ $variant, theme }) => css`
    text-align: right;
    width: 100%;
    color: ${theme.colors.textPrimary01};
    margin: 0;

    ${$variant === 'pool' &&
    css`
      ${theme.typography.bodyText.bodyRegular03Tight};
      margin-bottom: 0;
      color: ${theme.colors.textPrimary02};
    `}
  `
);

export const CountChart = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const CountChartBase = styled.div<{
  $width: number;
  $offSet?: number;
}>(
  ({ $offSet, $width, theme }) => css`
    background: ${theme.colors.white20};
    border-radius: 2px;
    width: 100%;
    height: 12px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      background: ${theme.colors.primary10};
      width: ${$width}%;
      height: 100%;
      position: absolute;
      left: ${$offSet ? `${$offSet}%` : 0};
      top: 0;
    }
  `
);

export const BarGraphWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const PositiveVerticalBarMixin = css<{ $width: number }>`
  background: ${(props) => props.theme.colors.statusGreen};
  ${(props) =>
    props.$width !== 100 &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

const NegativeVerticalBarMixin = css<{ $width: number }>`
  background: ${(props) => props.theme.colors.statusRed};
  margin-left: ${(props) => props.$width > 0 && VERTICAL_BAR_SPACING};

  ${(props) =>
    props.$width !== 100 &&
    `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}
`;

export const VerticalBarChart = styled.div<{
  $isPositive: boolean;
  $width: number;
}>`
  height: 8px;
  width: ${(props) =>
    props.$width !== 100 ? `calc(${props.$width}% - ${VERTICAL_BAR_SPACING})` : `${props.$width}%`};
  border-radius: 4px;

  ${(props) => (props.$isPositive ? PositiveVerticalBarMixin : NegativeVerticalBarMixin)}
`;

export const EmptyVerticalBarChart = styled.div`
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white05};
`;

export const RatesGraphWrapper = styled.div`
  background: ${(props) => props.theme.colors.blue03};
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const RatesTableWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.strokeBlue01};
  border-radius: 8px;
  margin-top: 24px;
  font-family: ${(props) => props.theme.typography.fontFamily.heading};
`;

export const RatesHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.strokeBlue01};
  margin-bottom: 20px;
`;

export const RatesTable = styled.table`
  width: 100%;
  margin: 24px 0;
`;

export const RatesTableRow = styled.tr<{ $isActive?: boolean }>`
  ${(props) => props.$isActive && `background: ${props.theme.colors.blue03};`}
  cursor: pointer;
`;

export const RatesTableHeader = styled.th`
  font-size: 12px;
  font-weight: 500;
  font-family: ${(props) => props.theme.typography.fontFamily.heading};
  color: ${(props) => props.theme.colors.white50};
  padding: 0 12px 12px;
  text-align: left;
`;

export const RatesTableData = styled.td<{
  $isLabel?: boolean;
  $hasIcon?: boolean;
}>`
  border-top: 1px solid ${(props) => props.theme.colors.blue03};
  padding: 12px;
  font-size: 12px;
  font-weight: 500;
  font-family: ${(props) => props.theme.typography.fontFamily.heading};
  color: ${(props) => (props.$isLabel ? props.theme.colors.white50 : props.theme.colors.white)};
  text-align: left;
  position: relative;
  ${(props) =>
    props.$hasIcon &&
    `
      padding-left: 45px;
  `}
`;

export const ChangeIcon = styled(DeltaIcon)<{ $isNegative?: boolean }>`
  position: absolute;
  left: 20%;
  top: 40%;

  ${(props) => props.$isNegative && `transform: rotate(180deg);`}
`;

export const RateGraphHeading = styled(RatesHeader)`
  margin: 0;
`;

export const RatesGraphContainer = styled.div`
  margin-top: 15px;
`;
