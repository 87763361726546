import { useMutation } from 'react-query';

import { deleteRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

const resetData = () => {
  const url = makeApiUrl('admin/data');
  return deleteRequestWithAuth(url);
};

export const useResetDataMutation = () => useMutation(resetData);
