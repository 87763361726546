import { Loan } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanQuery = {
  tradeRequestId: string;
  loanId: string;
};

const getTradeRequestDataLoan = (query: TradeRequestDataLoanQuery) => {
  const { loanId, tradeRequestId } = query;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/loans/${loanId}`);

  return getRequestWithAuth<Loan>(url);
};

export const useTradeRequestDataLoanQuery = (query: TradeRequestDataLoanQuery) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN, query], () => getTradeRequestDataLoan(query));
