import { MISMOEntity, MISMOIndicator, MISMOString } from './types';

export enum AbilityToRepayExemptionCreditorOrganizationType {
  CommunityDevelopmentFinancialInstitution = 'CommunityDevelopmentFinancialInstitution',
  CommunityHousingDevelopmentOrganization = 'CommunityHousingDevelopmentOrganization',
  DownpaymentAssistanceProvider = 'DownpaymentAssistanceProvider',
  NonProfitOrganization = 'NonProfitOrganization',
  Other = 'Other',
}

export enum AbilityToRepayExemptionLoanProgramType {
  EmergencyEconomicStabilizationAct = 'EmergencyEconomicStabilizationAct',
  HELOC = 'HELOC',
  HousingFinanceAgency = 'HousingFinanceAgency',
  NonStandardToStandardRefinance = 'NonStandardToStandardRefinance',
  Other = 'Other',
  ReverseMortgage = 'ReverseMortgage',
  TemporaryLoan = 'TemporaryLoan',
  TimeSharePlan = 'TimeSharePlan',
}

export enum AbilityToRepayExemptionReasonType {
  CreditorOrganization = 'CreditorOrganization',
  LoanProgram = 'LoanProgram',
  NumberOfFinancedUnits = 'NumberOfFinancedUnits',
  Other = 'Other',
  PropertyUsage = 'PropertyUsage',
}

export type Exemption = MISMOEntity &
  Partial<{
    abilityToRepayExemptionCreditExtendedPurposeIndicator: MISMOIndicator;
    abilityToRepayExemptionCreditorOrganizationType: AbilityToRepayExemptionCreditorOrganizationType;
    abilityToRepayExemptionCreditorOrganizationTypeOtherDescription: MISMOString;
    abilityToRepayExemptionLoanProgramType: AbilityToRepayExemptionLoanProgramType;
    abilityToRepayExemptionLoanProgramTypeOtherDescription: MISMOString;
    abilityToRepayExemptionReasonType: AbilityToRepayExemptionReasonType;
    abilityToRepayExemptionReasonTypeOtherDescription: MISMOString;
  }>;
