import { TradeRequest, TradeRequestQuote } from '@plus-platform/shared';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import {
  useExecuteTradeRequestQuoteMutation,
  useTradeRequestQuoteConfirmationSummary,
} from '../../../hooks/queries';
import { TradeConfirmation } from './TradeConfirmation';

type TradeQuoteConfirmationProps = {
  tradeRequestId: TradeRequest['id'];
  quoteId: TradeRequestQuote['id'];
  onAccept: () => void;
  onCancel: () => void;
};

export const TradeQuoteConfirmation = (props: TradeQuoteConfirmationProps) => {
  const { onAccept, onCancel, quoteId, tradeRequestId } = props;

  const { data: summary, isLoading } = useTradeRequestQuoteConfirmationSummary({
    tradeRequestId,
    quoteId: String(quoteId),
  });
  const {
    isLoading: isExecutingQuote,
    isSuccess: hasExecutedQuote,
    mutateAsync: executeQuote,
  } = useExecuteTradeRequestQuoteMutation();

  return (
    <ActivityIndicator contain isActive={isLoading}>
      {summary && (
        <TradeConfirmation
          isEnabled={!isExecutingQuote && !hasExecutedQuote}
          onAccept={async () => {
            await executeQuote({
              tradeRequestId,
              tradeQuoteId: quoteId,
            });
            onAccept();
          }}
          onCancel={onCancel}
          summary={summary}
        />
      )}
    </ActivityIndicator>
  );
};
