import { Filter, FilterKey, US_STATES } from '@plus-platform/shared';
import differenceBy from 'lodash/differenceBy';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Controller, ControllerRenderProps, useFieldArray, useForm } from 'react-hook-form';

import { columns } from '../../utils/portfolioFilterUtils';
import { ActivityIndicator } from '../ActivityIndicator';
import { BodyText } from '../BodyText';
import { Button } from '../Button';
import { ButtonIcon } from '../ButtonIcon';
import { ButtonLoader } from '../ButtonLoader';
import { AddIcon, CloseLineIcon } from '../icons';
import { InputText } from '../InputText';
import { Popup, PopupContent, PopupFooter, PopupHeader } from '../Popup';
import { Select, SelectType } from '../Select';
import { Stack } from '../Stack';
import { ToggleSwitch } from '../ToggleSwitch';
import * as Styles from './FilterPopup.styles';
import { FormValues } from './types';
import {
  getOperatorOptionsForColumn,
  isColumnWithNumericValue,
  mapColumnsToSelectOptions,
  mapFormFiltersToPortfolioFilters,
  mapPortfolioFiltersToFormFilters,
} from './utils';

const newFilter = {
  isApplied: true,
  value: '',
  name: '',
  key: FilterKey.EMPTY,
  operator: '',
};

const stateOptions = US_STATES.map((state) => ({
  label: `${state.code} - ${state.name}`,
  value: state.code,
}));

const statusOptions = [
  { value: 'true', label: 'Delinquent' },
  { value: 'false', label: 'Current' },
];

const columnOptions = mapColumnsToSelectOptions(columns);

type FilterValueInputProps = ControllerRenderProps & {
  invalid?: boolean;
  columnKey: FilterKey;
};

const FilterValueInput = React.forwardRef<HTMLInputElement | SelectType, FilterValueInputProps>(
  ({ columnKey, invalid, ...props }, ref) => {
    const clearField = () => props.onChange('');

    if (isColumnWithNumericValue(columnKey)) {
      return (
        <InputText
          {...props}
          ref={ref as React.ForwardedRef<HTMLInputElement>}
          label="Value"
          onClear={clearField}
          $isInvalid={invalid}
          // TODO: Rather than using a number type, we should create a wrapper component that restricts keys to numerical only
          // type="number"
        />
      );
    }

    if (columnKey === FilterKey.IS_DELINQUENT) {
      return (
        <Select
          {...props}
          ref={ref as React.ForwardedRef<SelectType>}
          label="Value"
          aria-label="Value"
          options={statusOptions}
          isInvalid={invalid}
        />
      );
    }

    if (columnKey === FilterKey.STATE) {
      return (
        <Select
          {...props}
          ref={ref as React.ForwardedRef<SelectType>}
          label="Value"
          aria-label="Value"
          options={stateOptions}
          isInvalid={invalid}
        />
      );
    }

    return (
      <InputText
        {...props}
        ref={ref as React.ForwardedRef<HTMLInputElement>}
        label="Value"
        onClear={clearField}
        $isInvalid={invalid}
      />
    );
  }
);

type FilterPopupProps = {
  filters: Filter[];
  onSave(filters: Omit<Filter, 'id'>[]): void;
  onCancel(): void;
  isLoading?: boolean;
};

export const FilterPopup = ({ isLoading, ...rest }: FilterPopupProps) => {
  return (
    <Popup width="1200px" height="650px" data-testid="PortfolioFilterPopup">
      <PopupHeader title="Filters" />
      <ActivityIndicator contain isActive={isLoading}>
        <FilterForm {...rest} />
      </ActivityIndicator>
    </Popup>
  );
};

type FilterFormProps = {
  filters: Filter[];
  onSave(filters: Omit<Filter, 'id'>[]): void;
  onCancel(): void;
};

const FilterForm = ({ filters, onCancel, onSave }: FilterFormProps) => {
  const portfolioFilters = mapPortfolioFiltersToFormFilters(filters);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      filters: portfolioFilters?.length ? portfolioFilters : [newFilter],
    },
  });
  // TODO: Re-evalute once/if we update our TS version. Currently there is an issue with react-hook-form as TS 4.4.4
  // https://github.com/react-hook-form/react-hook-form/issues/6679
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { append, fields, update } = useFieldArray({
    control,
    name: 'filters',
  });
  const formFilters = watch('filters');

  const hasAvailableColumns = !isEmpty(differenceBy(columns, formFilters, 'key'));

  const onSubmit = (values: FormValues) => {
    const filters = mapFormFiltersToPortfolioFilters(values.filters);
    onSave(filters);
  };

  const onColumnKeyChange = (columnKey: string, index: number) => {
    const formFilter = formFilters[index];

    if (formFilter && formFilter.key !== columnKey) {
      update(index, { ...formFilter, value: '' });
    }
  };

  return (
    <>
      <PopupContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styles.Filters>
            {fields.map((field, index) => (
              <Styles.FilterRow $isDisplayed={!field.isDeleted} key={field.id}>
                <ButtonIcon
                  onClick={() => {
                    const formFilter = formFilters[index];
                    if (formFilter) {
                      update(index, { ...formFilter, isDeleted: true });
                    }
                  }}
                  size="medium"
                  variant="dark"
                  data-testid="PortfolioFilterPopup_RemoveFilterButton"
                >
                  <CloseLineIcon />
                </ButtonIcon>
                <Controller
                  control={control}
                  name={`filters.${index}.key`}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Column"
                      aria-label="Column"
                      onChange={(value) => {
                        onColumnKeyChange(String(value), index);
                        field.onChange(value);
                      }}
                      options={columnOptions}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Controller
                  control={control}
                  name={`filters.${index}.operator`}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Query"
                      aria-label="Query"
                      options={getOperatorOptionsForColumn(formFilters[index].key)}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Controller
                  control={control}
                  name={`filters.${index}.value`}
                  render={({ field }) => (
                    <FilterValueInput
                      {...field}
                      columnKey={formFilters[index]?.key}
                      invalid={Boolean(errors.filters?.[index]?.value)}
                    />
                  )}
                  rules={{
                    validate: (value) => isBoolean(value) || Boolean(value),
                  }}
                />
                <InputText {...register(`filters.${index}.name`)} label="Name your filter" />
                <ToggleSwitch
                  {...register(`filters.${index}.isApplied`)}
                  data-testid="PortfolioFilterPopup_IsAppliedToggle"
                />
              </Styles.FilterRow>
            ))}
            {fields.length === 0 && <BodyText>No filters to show</BodyText>}
          </Styles.Filters>
        </form>
      </PopupContent>
      <PopupFooter>
        <Styles.Buttons>
          <Button
            $size="large"
            $variant="outlined"
            disabled={!hasAvailableColumns}
            onClick={() => append(newFilter)}
            startIcon={<AddIcon />}
            data-testid="PortfolioFilterPopup_AddFilterButton"
          >
            Add filter
          </Button>
          <Stack>
            <Button
              $color="tertiary"
              $size="large"
              $variant="outlined"
              onClick={onCancel}
              data-testid="PortfolioFilterPopup_CancelButton"
            >
              Cancel
            </Button>
            <ButtonLoader
              $size="large"
              disabled={!isValid}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              data-testid="PortfolioFilterPopup_SaveButton"
            >
              Save
            </ButtonLoader>
          </Stack>
        </Styles.Buttons>
      </PopupFooter>
    </>
  );
};
