import { Compensation } from '../compensation';
import { Condition } from '../condition';
import { FundsSourceType, FundsType } from '../funds';
import {
  IntegratedDisclosureSectionType,
  IntegratedDisclosureSubsectionType,
} from '../integratedDisclosure';
import { PaidBy } from '../paidBy';
import { PaidTo } from '../paidTo';
import { PrepaidItem } from '../prepaidItem';
import { ProrationItem } from '../prorationItem';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
  MISMOTime,
  MISMOValue,
} from '../types';

enum DocumentOrderClassificationType {
  Final = 'Final',
  Preliminary = 'Preliminary',
}

enum EstimatedPrepaidDaysPaidByType {
  Buyer = 'Buyer',
  Lender = 'Lender',
  Other = 'Other',
  Seller = 'Seller',
}

enum ClosingAdjustmentItemPaidByType {
  Broker = 'Broker',
  Buyer = 'Buyer',
  Correspondent = 'Correspondent',
  Lender = 'Lender',
  Seller = 'Seller',
  ThirdParty = 'ThirdParty',
}

export enum ClosingAdjustmentItemType {
  FuelCosts = 'FuelCosts',
  Gift = 'Gift',
  Grant = 'Grant',
  LenderCredit = 'LenderCredit',
  Other = 'Other',
  PrincipalReduction = 'PrincipalReduction',
  ProceedsOfSubordinateLiens = 'ProceedsOfSubordinateLiens',
  RebateCredit = 'RebateCredit',
  RelocationFunds = 'RelocationFunds',
  RentFromSubjectProperty = 'RentFromSubjectProperty',
  RepairCompletionEscrowHoldback = 'RepairCompletionEscrowHoldback',
  Repairs = 'Repairs',
  SatisfactionOfSubordinateLien = 'SatisfactionOfSubordinateLien',
  SellerCredit = 'SellerCredit',
  SellersEscrowAssumption = 'SellersEscrowAssumption',
  SellersMortgageInsuranceAssumption = 'SellersMortgageInsuranceAssumption',
  SellersReserveAccountAssumption = 'SellersReserveAccountAssumption',
  Services = 'Services',
  SimultaneousTitleDiscountCredit = 'SimultaneousTitleDiscountCredit',
  SubordinateFinancingProceeds = 'SubordinateFinancingProceeds',
  SweatEquity = 'SweatEquity',
  TenantSecurityDeposit = 'TenantSecurityDeposit',
  TitlePremiumAdjustment = 'TitlePremiumAdjustment',
  TradeEquity = 'TradeEquity',
  UnpaidUtilityEscrowHoldback = 'UnpaidUtilityEscrowHoldback',
}

export type ClosingAdjustmentItem = MISMOEntity &
  Partial<{
    closingAdjustmentItemAmount: MISMOAmount;
    closingAdjustmentItemPaidByType: ClosingAdjustmentItemPaidByType;
    closingAdjustmentItemPaidOutsideOfClosingIndicator: MISMOIndicator;
    closingAdjustmentItemType: ClosingAdjustmentItemType;
    'closingAdjustmentItemType@DisplayLabelText': MISMOString;
    closingAdjustmentItemTypeOtherDescription: MISMOString;
    closingAdjustmentPaidFromDate: MISMODate;
    closingAdjustmentPaidThroughDate: MISMODate;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    integratedDisclosureSubsectionType: IntegratedDisclosureSubsectionType;
    integratedDisclosureSubsectionTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    paidBy: PaidBy;
    paidTo: PaidTo;
  }>;

export type ClosingCostFund = MISMOEntity &
  Partial<{
    closingCostFundAmount: MISMOAmount;
    fundsSourceType: FundsSourceType;
    fundsSourceTypeOtherDescription: MISMOString;
    fundsType: FundsType;
    fundsTypeOtherDescription: MISMOString;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

type ClosingCostFundSummary = MISMOEntity &
  Partial<{
    totalClosingCostsToBePaidBySellerAndOthersAmount: MISMOAmount;
  }>;

type ClosingInstruction = MISMOEntity &
  Partial<{
    closingInstructionsConsolidatedClosingConditionsDescription: MISMOString;
    closingInstructionsPropertyTaxMessageDescription: MISMOString;
    closingInstructionsTermiteReportRequiredIndicator: MISMOIndicator;
    fundingCutoffTime: MISMOTime;
    hoursDocumentsNeededPriorToDisbursementCount: MISMOCount;
    leadBasedPaintCertificationRequiredIndicator: MISMOIndicator;
    conditions: Condition[];
  }>;

enum CollectedOtherFundPurposeType {
  AdvancePITIPayment = 'AdvancePITIPayment',
  EscrowFunds = 'EscrowFunds',
  Other = 'Other',
  PrincipalCurtailment = 'PrincipalCurtailment',
}

type CollectedOtherFund = MISMOEntity &
  Partial<{
    collectedOtherFundAmount: MISMOAmount;
    collectedOtherFundPercent: MISMOPercent;
    collectedOtherFundPurposeType: CollectedOtherFundPurposeType;
    collectedOtherFundPurposeTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type ClosingInformation = MISMOEntity &
  Partial<{
    cashFromBorrowerAtClosingAmount: MISMOAmount;
    cashFromSellerAtClosingAmount: MISMOAmount;
    cashToBorrowerAtClosingAmount: MISMOAmount;
    cashToSellerAtClosingAmount: MISMOAmount;
    closingAgentOrderNumberIdentifier: MISMOIdentifier;
    closingDate: MISMODate;
    closingDocumentReceivedDate: MISMODate;
    closingDocumentsExpirationDate: MISMODate;
    closingDocumentsExpirationTime: MISMOTime;
    currentRateSetDate: MISMODate;
    disbursementDate: MISMODate;
    documentOrderClassificationType: DocumentOrderClassificationType;
    documentPreparationDate: MISMODate;
    estimatedPrepaidDaysCount: MISMOCount;
    estimatedPrepaidDaysPaidByType: EstimatedPrepaidDaysPaidByType;
    estimatedPrepaidDaysPaidByTypeOtherDescription: MISMOString;
    fundByDate: MISMODate;
    loanEstimatedClosingDate: MISMODate;
    loanScheduledClosingDate: MISMODate;
    rescissionDate: MISMODate;
    closingAdjustmentItems: ClosingAdjustmentItem[];
    closingCostFunds: ClosingCostFund[];
    closingCostFundSummary: ClosingCostFundSummary;
    closingInstruction: ClosingInstruction;
    collectedOtherFunds: CollectedOtherFund[];
    compensations: Compensation[];
    prepaidItems: PrepaidItem[];
    prorationItems: ProrationItem[];
  }>;
