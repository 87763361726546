import React from 'react';

export const CloseCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.625 16.32L12 12.945L15.375 16.32L16.32 15.375L12.945 12L16.32 8.625L15.375 7.68L12 11.055L8.625 7.68L7.68 8.625L11.055 12L7.68 15.375L8.625 16.32ZM12 21C10.77 21 9.6075 20.7638 8.5125 20.2913C7.4175 19.8188 6.46125 19.1737 5.64375 18.3563C4.82625 17.5388 4.18125 16.5825 3.70875 15.4875C3.23625 14.3925 3 13.23 3 12C3 10.755 3.23625 9.585 3.70875 8.49C4.18125 7.395 4.82625 6.4425 5.64375 5.6325C6.46125 4.8225 7.4175 4.18125 8.5125 3.70875C9.6075 3.23625 10.77 3 12 3C13.245 3 14.415 3.23625 15.51 3.70875C16.605 4.18125 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8188 7.395 20.2913 8.49C20.7638 9.585 21 10.755 21 12C21 13.23 20.7638 14.3925 20.2913 15.4875C19.8188 16.5825 19.1775 17.5388 18.3675 18.3563C17.5575 19.1737 16.605 19.8188 15.51 20.2913C14.415 20.7638 13.245 21 12 21Z"
        fill="currentColor"
      />
    </svg>
  );
};
