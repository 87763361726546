import React from 'react';

export const WalkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.74419 20L9.82791 9.50698L8.48837 10.0279V12.5581H7V9.06046L10.7581 7.46047C10.9318 7.38605 11.1147 7.34264 11.307 7.33023C11.4992 7.31783 11.6822 7.34264 11.8558 7.40465C12.0295 7.46667 12.1938 7.55349 12.3488 7.66512C12.5039 7.77674 12.631 7.91938 12.7302 8.09302L13.4744 9.28372C13.7969 9.80465 14.2341 10.2326 14.786 10.5674C15.338 10.9023 15.9674 11.0698 16.6744 11.0698V12.5581C15.8062 12.5581 15.031 12.3783 14.3488 12.0186C13.6667 11.6589 13.0837 11.2 12.6 10.6419L12.1349 12.9302L13.6977 14.4186V20H12.2093V15.1628L10.6465 13.9721L9.30698 20H7.74419ZM12.5814 6.97674C12.1721 6.97674 11.8217 6.83101 11.5302 6.53953C11.2388 6.24806 11.093 5.89767 11.093 5.48837C11.093 5.07907 11.2388 4.72868 11.5302 4.43721C11.8217 4.14574 12.1721 4 12.5814 4C12.9907 4 13.3411 4.14574 13.6326 4.43721C13.924 4.72868 14.0698 5.07907 14.0698 5.48837C14.0698 5.89767 13.924 6.24806 13.6326 6.53953C13.3411 6.83101 12.9907 6.97674 12.5814 6.97674Z"
        fill="currentColor"
      />
    </svg>
  );
};
