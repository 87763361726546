import xor from 'lodash/xor';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import {
  BikePathIcon,
  CloseSquareFillIcon,
  EarthquakeIcon,
  EVChargingIcon,
  MLSIcon,
  NFHLIcon,
  PlusIcon,
  PlusSquareFillIcon,
  StormIcon,
  TrafficIcon,
  TransitIcon,
  WeatherIcon,
} from '../../components/icons';
import { ExtraDataEnum, MapLayerEnum } from '../types';
import * as Styles from './LayerControls.styles';

export const SIDE_PANEL_WIDTH = 390;

type LayerToggleProps = {
  isToggled?: boolean;
  onToggle?(): void;
  children: React.ReactNode;
  icon: React.ReactNode;
  hideBorder?: boolean;
  'data-testid'?: string;
};

const LayerToggle = ({
  children,
  'data-testid': dataTestId,
  hideBorder,
  icon,
  isToggled,
  onToggle,
}: LayerToggleProps) => {
  return (
    <Styles.LayerToggleButton isToggled={isToggled} onClick={onToggle} data-testid={dataTestId}>
      <Styles.LayerToggleButtonIcon $hideBorder={hideBorder}>{icon}</Styles.LayerToggleButtonIcon>
      {children}
    </Styles.LayerToggleButton>
  );
};

type CommonTogglesProps = {
  mapLayers: MapLayerEnum[];
  onToggleMapLayer: (value: React.SetStateAction<MapLayerEnum[]>) => void;
};

type TogglesProps = CommonTogglesProps & {
  areMoreLayersDisplayed: boolean;
};

const Toggles = ({ areMoreLayersDisplayed, mapLayers, onToggleMapLayer }: TogglesProps) => {
  const toggleMapLayer = (layer: MapLayerEnum) => {
    onToggleMapLayer((layers) => xor(layers, [layer]));
  };

  const isMapLayerEnabled = (layer: MapLayerEnum) => {
    return mapLayers.includes(layer);
  };

  return (
    <React.Fragment>
      <LayerToggle
        icon={<NFHLIcon />}
        isToggled={isMapLayerEnabled(MapLayerEnum.NFHL)}
        onToggle={() => toggleMapLayer(MapLayerEnum.NFHL)}
        data-testid={`MapLayerToggle_${MapLayerEnum.NFHL}`}
      >
        NFHL
      </LayerToggle>
      <LayerToggle
        icon={<EarthquakeIcon />}
        isToggled={isMapLayerEnabled(MapLayerEnum.EARTHQUAKES)}
        onToggle={() => toggleMapLayer(MapLayerEnum.EARTHQUAKES)}
        data-testid={`MapLayerToggle_${MapLayerEnum.EARTHQUAKES}`}
      >
        Earthquake
      </LayerToggle>
      <LayerToggle
        icon={<WeatherIcon />}
        isToggled={isMapLayerEnabled(MapLayerEnum.WEATHER)}
        onToggle={() => toggleMapLayer(MapLayerEnum.WEATHER)}
        data-testid={`MapLayerToggle_${MapLayerEnum.WEATHER}`}
      >
        Weather
      </LayerToggle>
      <LayerToggle
        icon={<StormIcon />}
        isToggled={isMapLayerEnabled(MapLayerEnum.STORMS)}
        onToggle={() => toggleMapLayer(MapLayerEnum.STORMS)}
        data-testid={`MapLayerToggle_${MapLayerEnum.STORMS}`}
      >
        Storms
      </LayerToggle>

      {areMoreLayersDisplayed && (
        <Styles.LayerCategories>
          <Styles.LayerCategoryHeading>Category 1</Styles.LayerCategoryHeading>
          <Styles.LayerCategory>
            <LayerToggle
              icon={<TransitIcon />}
              isToggled={isMapLayerEnabled(MapLayerEnum.TRANSIT)}
              onToggle={() => toggleMapLayer(MapLayerEnum.TRANSIT)}
              data-testid={`MapLayerToggle_${MapLayerEnum.TRANSIT}`}
            >
              Transit
            </LayerToggle>
            <LayerToggle
              icon={<TrafficIcon />}
              isToggled={isMapLayerEnabled(MapLayerEnum.TRAFFIC)}
              onToggle={() => toggleMapLayer(MapLayerEnum.TRAFFIC)}
              data-testid={`MapLayerToggle_${MapLayerEnum.TRAFFIC}`}
            >
              Traffic
            </LayerToggle>
          </Styles.LayerCategory>
          <Styles.LayerCategoryHeading>Category 2</Styles.LayerCategoryHeading>
          <Styles.LayerCategory>
            <LayerToggle
              icon={<BikePathIcon />}
              isToggled={isMapLayerEnabled(MapLayerEnum.BICYCLING)}
              onToggle={() => toggleMapLayer(MapLayerEnum.BICYCLING)}
              data-testid={`MapLayerToggle_${MapLayerEnum.BICYCLING}`}
            >
              Bike paths
            </LayerToggle>
            <LayerToggle
              icon={<EVChargingIcon />}
              isToggled={isMapLayerEnabled(MapLayerEnum.EV_CHARGING_STATIONS)}
              onToggle={() => toggleMapLayer(MapLayerEnum.EV_CHARGING_STATIONS)}
              data-testid={`MapLayerToggle_${MapLayerEnum.EV_CHARGING_STATIONS}`}
            >
              EV charging
            </LayerToggle>
          </Styles.LayerCategory>

          {/* TODO: Re-enable once new data source has been found
          <LayerToggle
          icon={<PlusIcon />}
          isToggled={isMapLayerEnabled(MapLayerEnum.AIR_QUALITY)}
          onToggle={() => toggleMapLayer(MapLayerEnum.AIR_QUALITY)}
        >
          Air Quality
          </LayerToggle>
          */}
        </Styles.LayerCategories>
      )}
    </React.Fragment>
  );
};

type LayerControlsProps = CommonTogglesProps & {
  isSidebarVisible: boolean;
  isMLSDataSelected: boolean;
  shouldHideMLSData?: boolean;
  onToggleMapLayer: (value: React.SetStateAction<MapLayerEnum[]>) => void;
  onMLSButtonClick: (data?: ExtraDataEnum) => void;
};

export const LayerControlsWithoutExtraData = ({
  mapLayers,
  onToggleMapLayer,
}: CommonTogglesProps) => {
  const [areMoreLayersDisplayed, setAreMoreLayersDisplayed] = React.useState(false);

  return (
    <Styles.Wrapper>
      <Styles.LayersButton data-testid="MapLayerControls">Plus layers</Styles.LayersButton>
      <Styles.LayersOverlay>
        <Styles.LayersContent>
          <Styles.LayerToggles>
            <Toggles
              areMoreLayersDisplayed={areMoreLayersDisplayed}
              mapLayers={mapLayers}
              onToggleMapLayer={onToggleMapLayer}
            />
            <Styles.LayerExpandToggle>
              {!areMoreLayersDisplayed && (
                <LayerToggle
                  icon={<PlusSquareFillIcon />}
                  onToggle={() => setAreMoreLayersDisplayed(true)}
                >
                  More
                </LayerToggle>
              )}
              {areMoreLayersDisplayed && (
                <LayerToggle
                  hideBorder
                  icon={<CloseSquareFillIcon />}
                  onToggle={() => setAreMoreLayersDisplayed(false)}
                >
                  Less
                </LayerToggle>
              )}
            </Styles.LayerExpandToggle>
          </Styles.LayerToggles>
        </Styles.LayersContent>
      </Styles.LayersOverlay>
    </Styles.Wrapper>
  );
};

export const LayerControls = ({
  isMLSDataSelected,
  isSidebarVisible,
  mapLayers,
  onMLSButtonClick,
  onToggleMapLayer,
}: LayerControlsProps) => {
  const [areMoreLayersDisplayed, setAreMoreLayersDisplayed] = React.useState(false);
  const theme = useTheme();

  return (
    <Styles.Wrapper isSidebarVisible={isSidebarVisible}>
      <Styles.LayersButton>
        <PlusIcon color={theme.colors.greenLight} height={20} width={23} />
        Plus layers
      </Styles.LayersButton>
      <Styles.LayersOverlay>
        <Styles.LayersContent>
          <Styles.LayerToggles>
            <LayerToggle
              icon={<MLSIcon />}
              isToggled={isMLSDataSelected}
              onToggle={() =>
                onMLSButtonClick(isMLSDataSelected ? undefined : ExtraDataEnum.EXTRA_DATA_MLS)
              }
            >
              MLS
            </LayerToggle>
            <Toggles
              areMoreLayersDisplayed={areMoreLayersDisplayed}
              mapLayers={mapLayers}
              onToggleMapLayer={onToggleMapLayer}
            />
            <Styles.LayerExpandToggle>
              {!areMoreLayersDisplayed && (
                <LayerToggle
                  icon={<PlusSquareFillIcon />}
                  onToggle={() => setAreMoreLayersDisplayed(true)}
                >
                  More
                </LayerToggle>
              )}
              {areMoreLayersDisplayed && (
                <LayerToggle
                  hideBorder
                  icon={<CloseSquareFillIcon />}
                  onToggle={() => setAreMoreLayersDisplayed(false)}
                >
                  Less
                </LayerToggle>
              )}
            </Styles.LayerExpandToggle>
          </Styles.LayerToggles>
        </Styles.LayersContent>
      </Styles.LayersOverlay>
    </Styles.Wrapper>
  );
};
