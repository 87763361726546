import { transparentize } from 'polished';
import styled from 'styled-components/macro';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;

  & + & {
    margin-top: 9px;
  }
`;

export const Key = styled.div`
  display: inline-block;
  width: 66%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  color: ${({ theme }) => transparentize(0.3, theme.colors.white)};
`;

export const Value = styled.div`
  display: inline-block;
  width: 33%;
  font-weight: 700;
  color: ${({ theme }) => transparentize(0.2, theme.colors.white)};
`;

export const ValueWithAlert = styled(Value)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
