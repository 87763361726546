export enum FundsSourceType {
  Borrower = 'Borrower',
  Builder = 'Builder',
  CommunityNonProfit = 'CommunityNonProfit',
  Employer = 'Employer',
  FederalAgency = 'FederalAgency',
  Institutional = 'Institutional',
  Lender = 'Lender',
  LocalAgency = 'LocalAgency',
  NonOriginatingFinancialInstitution = 'NonOriginatingFinancialInstitution',
  NonParentRelative = 'NonParentRelative',
  Other = 'Other',
  Parent = 'Parent',
  PropertySeller = 'PropertySeller',
  Relative = 'Relative',
  ReligiousNonProfit = 'ReligiousNonProfit',
  RuralHousingService = 'RuralHousingService',
  StateAgency = 'StateAgency',
  Unknown = 'Unknown',
  UnmarriedPartner = 'UnmarriedPartner',
  UnrelatedFriend = 'UnrelatedFriend',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum FundsSourceTypeOtherDescription {
  AggregatedRemainingSourceTypes = 'AggregatedRemainingSourceTypes',
  FHLBAffordableHousingProgram = 'FHLBAffordableHousingProgram',
  USDARuralHousing = 'USDARuralHousing',
}

export enum FundsType {
  BridgeLoan = 'BridgeLoan',
  CashOnHand = 'CashOnHand',
  CashOrOtherEquity = 'CashOrOtherEquity',
  CheckingSavings = 'CheckingSavings',
  Contribution = 'Contribution',
  CreditCard = 'CreditCard',
  DepositOnSalesContract = 'DepositOnSalesContract',
  EquityOnPendingSale = 'EquityOnPendingSale',
  EquityOnSoldProperty = 'EquityOnSoldProperty',
  EquityOnSubjectProperty = 'EquityOnSubjectProperty',
  ExcessDeposit = 'ExcessDeposit',
  ForgivableSecuredLoan = 'ForgivableSecuredLoan',
  GiftFunds = 'GiftFunds',
  Grant = 'Grant',
  HousingRelocation = 'HousingRelocation',
  LifeInsuranceCashValue = 'LifeInsuranceCashValue',
  LotEquity = 'LotEquity',
  MortgageCreditCertificates = 'MortgageCreditCertificates',
  MortgageRevenueBond = 'MortgageRevenueBond',
  Other = 'Other',
  OtherEquity = 'OtherEquity',
  PledgedCollateral = 'PledgedCollateral',
  PremiumFunds = 'PremiumFunds',
  RentWithOptionToPurchase = 'RentWithOptionToPurchase',
  RetirementFunds = 'RetirementFunds',
  SaleOfChattel = 'SaleOfChattel',
  SalesPriceAdjustment = 'SalesPriceAdjustment',
  SecondaryFinancing = 'SecondaryFinancing',
  SecuredLoan = 'SecuredLoan',
  StocksAndBonds = 'StocksAndBonds',
  SweatEquity = 'SweatEquity',
  TradeEquity = 'TradeEquity',
  TrustFunds = 'TrustFunds',
  UnsecuredBorrowedFunds = 'UnsecuredBorrowedFunds',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum FundsTypeOtherDescription {
  AggregatedRemainingTypes = 'AggregatedRemainingTypes',
  SecondaryFinancingClosedEnd = 'SecondaryFinancingClosedEnd',
  SecondaryFinancingHELOC = 'SecondaryFinancingHELOC',
}
