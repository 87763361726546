export enum AdminAttributeType {
  ATTRIBUTE_USER_SETTINGS = 'ATTRIBUTE_USER_SETTINGS',
  ATTRIBUTE_ANALYTICS_SETTINGS = 'ATTRIBUTE_ANALYTICS_SETTINGS',
}

export enum SettingsAlertType {
  NONE = 'None',
  BANNERS = 'Banners',
  ALERTS = 'Alerts',
}

export type GeneralBehaviorSettings = {
  general?: number;
  vigilance?: number;
  significance?: number;
};

export type AnalyticsSettings = {
  generalBehaviorUpdateFrequency: GeneralBehaviorSettings;
};

export type AnalyticSettings = {
  generalBehaviorUpdateFrequency?: GeneralBehaviorSettings;
  notifications?: {
    shouldAllowNotifications: boolean;
  };
  bannersAlertsGroups?: {
    alertType: SettingsAlertType;
  };
  shouldAllowTimeSensitiveNotifications?: boolean;
  shouldPreviewNotifications?: boolean;
  shouldAllowNotificationGrouping?: boolean;
};
