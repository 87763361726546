import { Document, Loan } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from './apiUtils';
import { addPhotosToLoan } from './loanUtils';

export const searchLoans = async (searchQuery: string) => {
  const url = makeApiUrl(`loans/search?query=${searchQuery}`);
  const loans = await getRequestWithAuth<Loan[]>(url);
  return (loans || []).map(addPhotosToLoan);
};

export const searchDocuments = (searchQuery: string) => {
  const url = makeApiUrl(`documents/search?query=${searchQuery}`);
  return getRequestWithAuth<Document[]>(url);
};
