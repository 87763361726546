import { TradeRequest } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

const requestTradeRequestData = (tradeRequestId: TradeRequest['id']) => {
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/request-data`);

  return postRequestWithAuth(url);
};

export const useTradeRequestDataRequestMutation = () => {
  return useMutation(requestTradeRequestData);
};
