import { LoanAmortizationData } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanAmortizationQuery = {
  tradeRequestId: string;
  loanId: string;
};

const getTradeRequestDataLoanAmortizationDetails = ({
  loanId,
  tradeRequestId,
}: TradeRequestDataLoanAmortizationQuery) => {
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/loans/${loanId}/amortization`);
  return getRequestWithAuth<LoanAmortizationData[]>(url);
};

export const useTradeRequestDataLoanAmortizationDetailsQuery = (
  query: TradeRequestDataLoanAmortizationQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_AMORTIZATION, query], () =>
    getTradeRequestDataLoanAmortizationDetails(query)
  );
