import RangeSlider from 'react-range-slider-input';
import styled, { css } from 'styled-components/macro';

const SLIDER_BAR_HEIGHT = '40px';

export const GraphSliderWrapper = styled.div`
  margin-top: 16px;
  position: relative;
`;

const NavigationLeftMixin = css`
  left: 10px;
  rotate: 180deg;
`;

const NavigationRightMixin = css`
  right: 10px;
`;

export const SliderNavigation = styled.div<{ alignment?: 'right' | 'left' }>`
  width: 24px;
  height: 24px;
  background: ${(props) => props.theme.colors.mauve};
  position: absolute;
  bottom: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;

  ${(props) => (props.alignment === 'right' ? NavigationRightMixin : NavigationLeftMixin)}
`;

export const SliderRangeWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: calc(${SLIDER_BAR_HEIGHT} / 3);
`;

const SliderGrabberMixin = css`
  content: '';
  height: 20px;
  width: 5px;
  border-radius: 0.5px;
  border: 1px solid ${(props) => props.theme.colors.statusBlack};
  border-top: none;
  border-bottom: none;
  position: absolute;
  top: 10px;
`;

export const SliderComponent = styled(RangeSlider)`
  background: none;
  margin: auto;
  width: calc(100% - 80px);

  & .range-slider__range {
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.statusYellow};
    border-left: 0;
    border-right: 0;
    height: ${SLIDER_BAR_HEIGHT};
    width: 80px;
    left: 10px;
  }

  & .range-slider__thumb {
    height: ${SLIDER_BAR_HEIGHT}!important;
    background: ${(props) => props.theme.colors.statusYellow};
    width: 15px !important;
    top: 4px !important;
    border: none !important;
    border-radius: 0;

    &:after {
      ${SliderGrabberMixin}
      right: 5px;
    }

    &[data-lower] {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &[data-upper] {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;
