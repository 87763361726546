import styled from 'styled-components/macro';

import { GroupedHeaderRow, Row, TABLE_MARGIN } from '../components/Table';

export const RowWrapper = styled.div<{ height: number; offsetY: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  transform: translateY(${(props) => props.offsetY}px);
`;

export const DocumentsGroupedHeaderRow = styled(GroupedHeaderRow)`
  grid-template-columns: 3fr 3fr 3fr 1fr 2fr;
`;

export const StatusGroup = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const DocumentsRow = styled(Row)`
  padding: 0 ${TABLE_MARGIN}px;

  &:hover {
    margin: 0;
  }
`;
