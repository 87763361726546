import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const Badge = styled.div(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyBold04}

    background-color: ${theme.colors.statusYellow};
    color: ${theme.colors.statusBlack};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

export const HeaderWrapper = styled.div`
  display: flex;
`;
