import { Filter, InboundTradeRequestWithSummary } from '@plus-platform/shared';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { DelinquencyBadge } from '../components/DelinquencyBadge';
import { Divider } from '../components/Divider';
import { CloseLineIcon, ExportLineIcon } from '../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection } from '../components/Menu';
import {
  Cell,
  getMaxVisibleItems,
  HeaderCell,
  HeaderRow,
  LoadingRow,
  Row,
  Table,
  TableWrapper,
  Tbody,
  Thead,
} from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableOptions } from '../components/TableOptions';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { VirtualizedTable } from '../components/VirtualizedTable';
import { RowWrapper } from '../portfolio/PortfolioTable';
import {
  formatDate,
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../utils/formatUtils';
import { exportTradeRequestData } from './tradeRequestsUtils';
import { useTradeRequestData } from './useTradeRequestData';

const TABLE_COLUMN_COUNT = 15;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

type TradeRequestDataProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

type TradeRequestDataParams = {
  category: 'outbound' | 'inbound';
  tradeId: string;
  counterpartyId: string;
};

export const TradeRequestData = (props: TradeRequestDataProps) => {
  const { tradeRequest } = props;

  const { category, counterpartyId, tradeId } = useParams<TradeRequestDataParams>();
  const { fetchNextPage, isFetchingNextPage, isLoading, loans, totalCount } = useTradeRequestData(
    tradeRequest.id
  );
  const parentRef = React.useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  // TODO: Replace once filters for log have been implemented
  const filters: Filter[] = [];
  const updateFilters = () => undefined;

  return (
    <Wrapper>
      <TableFilters
        filters={filters}
        onFiltersUpdate={updateFilters}
        path={`/trade-requests/${category}/${tradeId}/${counterpartyId}/data-view`}
      >
        {({ filterPopupPath, isFiltersActive }) => (
          <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
            {({ closeLeftPanel, containerHeight, isLeftPanelOpen, openLeftPanel }) => (
              <>
                {isLeftPanelOpen && (
                  <TableFilterPanel
                    filters={filters}
                    filtersPopupPath={filterPopupPath}
                    onClose={closeLeftPanel}
                    onFiltersUpdate={updateFilters}
                  />
                )}
                <Card $hasFlex>
                  <TableToolbar>
                    <TableStats totalCount={totalCount} visibleCount={loans.length} />
                    <Divider $orientation="vertical" $flexItem />
                    <TableFilterBar
                      filters={filters}
                      onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                      onFiltersUpdate={updateFilters}
                      isDisabled
                    />
                    <TableOptions>
                      <Menu title="Grid tools">
                        <MenuSection>
                          <MenuItem onClick={() => exportTradeRequestData(tradeRequest.id)}>
                            <ExportLineIcon />
                            <MenuItemText>Export all</MenuItemText>
                          </MenuItem>
                        </MenuSection>
                        {/* TODO: Add additional actions */}
                      </Menu>
                      <Divider $orientation="vertical" $flexItem />
                      <Button
                        $color="tertiary"
                        $variant="text"
                        onClick={() => history.goBack()}
                        startIcon={<CloseLineIcon />}
                      >
                        Close data
                      </Button>
                    </TableOptions>
                  </TableToolbar>
                  <TableWrapper>
                    <ActivityIndicator contain isActive={isLoading}>
                      <Table>
                        <Thead>
                          <HeaderRow $columnCount={TABLE_COLUMN_COUNT}>
                            <HeaderCell>Loan ID</HeaderCell>
                            <HeaderCell>Status</HeaderCell>
                            <HeaderCell>State</HeaderCell>
                            <HeaderCell>Orig UPB $</HeaderCell>
                            <HeaderCell>Cur UPB $</HeaderCell>
                            <HeaderCell>Cur note rate $</HeaderCell>
                            <HeaderCell>Product type</HeaderCell>
                            <HeaderCell>Maturity date</HeaderCell>
                            <HeaderCell>Orig LTV $</HeaderCell>
                            <HeaderCell>Cur LTV $</HeaderCell>
                            <HeaderCell>Orig DTI %</HeaderCell>
                            <HeaderCell>Orig credit score</HeaderCell>
                            <HeaderCell>Cur credit score</HeaderCell>
                            <HeaderCell>Originator</HeaderCell>
                            <HeaderCell>Loan type</HeaderCell>
                          </HeaderRow>
                        </Thead>
                        <Tbody>
                          <VirtualizedTable
                            maxVisibleItems={getMaxVisibleItems({ containerHeight })}
                            fetchNextPage={fetchNextPage}
                            isFetchingNextPage={isFetchingNextPage}
                            items={loans}
                            parentRef={parentRef}
                            renderRow={(isLoaderRow, virtualRow, loan) => {
                              if (!loan) {
                                return null;
                              }

                              return (
                                <RowWrapper height={virtualRow.size} offsetY={virtualRow.start}>
                                  {isLoaderRow && virtualRow.index < totalCount && (
                                    <LoadingRow>Loading more...</LoadingRow>
                                  )}

                                  {!isLoaderRow && (
                                    <Row
                                      $columnCount={TABLE_COLUMN_COUNT}
                                      onClick={() =>
                                        history.push(
                                          `/trade-requests/inbound/${tradeRequest.id}/loans/${loan.id}`
                                        )
                                      }
                                    >
                                      <Cell>{formatDefaultValue(loan.id)}</Cell>
                                      <Cell>
                                        <DelinquencyBadge {...loan.delinquencyStatus} />
                                      </Cell>
                                      <Cell>{formatDefaultValue(loan.state)}</Cell>
                                      <Cell>{formatMonetary(loan.originalUPB)}</Cell>
                                      <Cell>{formatMonetary(loan.currentUPB)}</Cell>
                                      <Cell>{formatPercentage(loan.currentNoteRate)}</Cell>
                                      <Cell>{formatDefaultValue(loan.productType)}</Cell>
                                      <Cell>
                                        {loan.maturityDate &&
                                          formatDate(new Date(loan.maturityDate), {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                          })}
                                      </Cell>
                                      <Cell>{formatPercentage(loan.originalLTV)}</Cell>
                                      <Cell>{formatPercentage(loan.currentLTV)}</Cell>
                                      <Cell>{formatPercentage(loan.originalDTI)}</Cell>
                                      <Cell>{formatNumber(loan.originalCreditScore)}</Cell>
                                      <Cell>{formatNumber(loan.currentCreditScore)}</Cell>
                                      <Cell>{formatDefaultValue(loan.originator)}</Cell>
                                      <Cell>{formatDefaultValue(loan.loanType)}</Cell>
                                    </Row>
                                  )}
                                </RowWrapper>
                              );
                            }}
                            totalItemsCount={totalCount}
                          />
                        </Tbody>
                      </Table>
                    </ActivityIndicator>
                  </TableWrapper>
                </Card>
              </>
            )}
          </TableLayout>
        )}
      </TableFilters>
    </Wrapper>
  );
};
