import { ButtonProps } from './Button';
import * as Styles from './Button.styles';
import { InternalLink } from './Link';

type ButtonLinkProps = {
  to: string;
} & ButtonProps;

export const ButtonLink = (props: ButtonLinkProps) => {
  const { children, endIcon, startIcon, ...rest } = props;

  return (
    <Styles.Button as={InternalLink} {...rest}>
      {startIcon}
      {children}
      {endIcon}
    </Styles.Button>
  );
};
