import {
  DelinquencyType,
  LoansCountByDelinquencyType,
  LoansCountByRateType,
  PortfolioBreakdownRateType,
  PortfolioLoanRateType,
} from '@plus-platform/shared';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';

import { formatNumber } from '../../../utils/formatUtils';
import {
  formatBreakdownRateType,
  formatLoanRateType,
  orderedBreakdownRateTypes,
  orderedLoanRateTypes,
} from '../../../utils/loanUtils';

export const LARGEST_NAVIGATION_BUBBLE_SIZE = 100;
export const SMALLEST_NAVIGATION_BUBBLE_SIZE = 40;
export const LARGEST_BUBBLE_SIZE = 32;
export const LARGEST_BUBBLE_FONT_SIZE = 18;
export const SMALLEST_BUBBLE_SIZE = 8;
export const SMALLEST_BUBBLE_FONT_SIZE = 9;

export type DelinquentChartData = {
  rateType: PortfolioLoanRateType;
  noteRate: number;
  loansCount: number;
  delinquencyType: DelinquencyType;
};

export const mapLoansCountByNoteRateForNavigation = (data?: LoansCountByRateType) => {
  if (!data) {
    return [];
  }

  const rateTypeByNoteRateData = Object.entries(data).flatMap(([rateType, dataItem]) => {
    const { currentMarketRate } = dataItem;

    return Object.entries(dataItem.countsByNoteRate).map(([noteRate, loansCount]) => {
      return {
        noteRate: Number(noteRate),
        currentMarketRate,
        loansCount,
        rateType,
        label: formatBreakdownRateType(rateType as PortfolioBreakdownRateType),
      };
    });
  });

  return sortBy(rateTypeByNoteRateData, [
    (dataItem) =>
      orderedBreakdownRateTypes.indexOf(dataItem.rateType as PortfolioBreakdownRateType),
    'noteRate',
  ]);
};

export const mapLoansCountByDelinquencyTypeNavigation = (data?: LoansCountByDelinquencyType) => {
  if (!data) {
    return [];
  }

  const rateTypeByNoteRateData = Object.entries(data).flatMap(([delinquencyType, dataItem]) => {
    return Object.entries(dataItem.countsByRateType).flatMap(([rateType, dataItem]) => {
      return Object.entries(dataItem.countsByNoteRate).map(([noteRate, loansCount]) => {
        return {
          noteRate: Number(noteRate),
          delinquencyType: delinquencyType as DelinquencyType,
          loansCount,
          rateType,
          label: formatLoanRateType(rateType as PortfolioLoanRateType),
        };
      });
    });
  });

  // TODO: For now, we show only 3 delinquency groups, for consistency with the rest of the application.
  // When other parts of the UI are updated to include all 4 delinquency groups
  // make sure to remove this filtering
  const rateTypeByNoteRateDataForSupportDelinquencyTypes = rateTypeByNoteRateData.filter(
    (dataItem) => dataItem.delinquencyType !== DelinquencyType.NINETY_PLUS_DAYS
  );

  return sortBy(rateTypeByNoteRateDataForSupportDelinquencyTypes, [
    (dataItem) => orderedLoanRateTypes.indexOf(dataItem.rateType as PortfolioLoanRateType),
    'noteRate',
    'delinquencyType',
  ]);
};

export const mapLoansCountByStateToMapLoans = (data: Record<string, number>) => {
  return mapValues(data, (loansCount) => ({
    value: loansCount,
    formattedValue: String(formatNumber(loansCount)),
  }));
};

export const getDelinquentLoanCountsGroupedByRateType = (data?: LoansCountByDelinquencyType) => {
  if (!data) {
    return {};
  }

  const allCountsByRateType = Object.values(data).flatMap((dataItem) =>
    Object.entries(dataItem.countsByRateType)
  );

  return allCountsByRateType.reduce<Record<string, number>>(
    (countsByRateType, [rateType, data]) => {
      const currentCountForRateType = countsByRateType[rateType] ?? 0;

      return {
        ...countsByRateType,
        [rateType]: currentCountForRateType + data.loansCount,
      };
    },
    {}
  );
};

export const mapDelinquentLoansCountByNoteRateForChart = (
  data?: LoansCountByDelinquencyType
): Partial<Record<DelinquencyType, DelinquentChartData[]>> => {
  if (!data) {
    return {};
  }

  return mapValues(data, (dataItem, delinquencyType) => {
    const countsByRateType = dataItem?.countsByRateType ?? {};

    return Object.entries(countsByRateType).flatMap(([rateType, dataItem]) => {
      return Object.entries(dataItem.countsByNoteRate).map(([noteRate, loansCount]) => {
        return {
          noteRate: Number(noteRate),
          loansCount,
          rateType: rateType as PortfolioLoanRateType,
          delinquencyType: delinquencyType as DelinquencyType,
        };
      });
    });
  });
};
