import { UserPermissionHierarchy } from '@plus-platform/shared';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AccessDenied } from '../components/AccessDenied';
import { usePermissions } from '../components/Permissions';
import { useUserContext } from '../contexts/UserContext';

type PrivateRoutePropTypes = RouteProps & {
  requiredPermissions?: UserPermissionHierarchy;
};

export const PrivateRoute: React.FC<PrivateRoutePropTypes> = ({
  requiredPermissions: permissions,
  ...props
}) => {
  const { userProfile } = useUserContext();
  const { hasPermissions } = usePermissions();

  if (!userProfile) {
    return <Redirect to="/login" />;
  }

  if (permissions && !hasPermissions(permissions)) {
    return <Route {...props} children={undefined} component={AccessDenied} render={undefined} />;
  }

  return <Route {...props} />;
};
