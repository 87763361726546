import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from './types';

export enum FeePaymentCollectedByType {
  Broker = 'Broker',
  Investor = 'Investor',
  Lender = 'Lender',
  Other = 'Other',
}

export enum FeePaymentPaidByType {
  Borrower = 'Borrower',
  Broker = 'Broker',
  Buyer = 'Buyer',
  Correspondent = 'Correspondent',
  Lender = 'Lender',
  Seller = 'Seller',
  Servicer = 'Servicer',
  ThirdParty = 'ThirdParty',
}

export enum FeePaymentProcessType {
  Closing = 'Closing',
  Processing = 'Processing',
}

export enum FeePaymentResponsiblePartyType {
  Branch = 'Branch',
  Broker = 'Broker',
  Buyer = 'Buyer',
  Lender = 'Lender',
  Other = 'Other',
  Seller = 'Seller',
}

export type FeePayment = MISMOEntity &
  Partial<{
    feeActualPaymentAmount: MISMOAmount;
    feeEstimatedPaymentAmount: MISMOAmount;
    feePaymentAllowableFHAClosingCostIndicator: MISMOIndicator;
    feePaymentCollectedByType: FeePaymentCollectedByType;
    feePaymentCollectedByTypeOtherDescription: MISMOString;
    feePaymentNetDueAmount: MISMOAmount;
    feePaymentPaidByType: FeePaymentPaidByType;
    feePaymentPaidByTypeThirdPartyName: MISMOString;
    feePaymentPaidDate: MISMODate;
    feePaymentPaidOutsideOfClosingIndicator: MISMOIndicator;
    feePaymentPercent: MISMOPercent;
    feePaymentProcessType: FeePaymentProcessType;
    feePaymentRefundableAmount: MISMOAmount;
    feePaymentRefundableConditionsDescription: MISMOString;
    feePaymentRefundableIndicator: MISMOIndicator;
    feePaymentResponsiblePartyType: FeePaymentResponsiblePartyType;
    feePaymentResponsiblePartyTypeOtherDescription: MISMOString;
    paymentFinancedIndicator: MISMOIndicator;
    paymentIncludedInAPRIndicator: MISMOIndicator;
    paymentIncludedInJurisdictionHighCostIndicator: MISMOIndicator;
    prepaidFinanceChargeIndicator: MISMOIndicator;
    regulationZPointsAndFeesIndicator: MISMOIndicator;
  }>;
