import styled from 'styled-components/macro';

export const ValidationHeader = styled.div`
  padding: 16px;
  ${(props) => props.theme.typography.headings.headingMedium03};
`;

export const ValidationWrapper = styled.div<{ $hasBackground?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.primary02};
  color: ${({ theme }) => theme.colors.textPrimary01};
  border-radius: 4px;
  margin-top: 8px;

  ${(props) =>
    props.$hasBackground &&
    `
  background: ${props.theme.colors.primary02};

  ${ValidationHeader} {
    border-bottom: 1px solid ${props.theme.colors.primary03};
  }
`}
`;

export const IngestionMessage = styled.div`
  padding: 16px 0;
  ${(props) => props.theme.typography.bodyText.bodyRegular02Tight};
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  padding: 80px 0;
  ${(props) => props.theme.typography.headings.headingMedium02};
`;

export const DocumentImageWrapper = styled.div`
  height: calc(100% - 20px);
  width: 100%;
  vertical-align: middle;
  text-align: center;
  margin-top: 20px;
`;

export const DocumentImage = styled.div`
  height: 65px;
  width: 50px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary02};
  margin: auto;
`;

export const ValidationGrid = styled.div`
  display: grid;
  grid-template-columns: 68px repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 16px;
`;

export const ErrorHeading = styled.div`
  padding: 0px 16px;
  ${(props) => props.theme.typography.headings.headingMedium04};
  color: ${({ theme }) => theme.colors.textPrimary01};
`;

export const DocumentErrorHeader = styled(ErrorHeading)`
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.primary03};
`;

export const DocumentErrorContent = styled.div`
  ${(props) => props.theme.typography.bodyText.bodyRegular02Tight};
  color: ${({ theme }) => theme.colors.textPrimary02};
  background: ${({ theme }) => theme.colors.primary04};
  padding: 8px 16px;
`;

export const DocumentErrorWrapper = styled.div`
  ${DocumentErrorContent}:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary05};
  }
`;

export const ValidationContent = styled.div`
  padding: 16px;
`;
