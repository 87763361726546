import styled, { css } from 'styled-components/macro';

type WrapperProps = {
  $variant?: 'default' | 'light';
};

export const Wrapper = styled.div<WrapperProps>(
  ({ $variant, theme }) => css`
    display: flex;
    padding: 3px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid ${$variant === 'light' ? theme.colors.primary04 : theme.colors.primary03};
    width: fit-content;
  `
);

export const TextWrapper = styled.div``;

export const NameText = styled.div(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04}
    color: ${theme.colors.textPrimary01};
  `
);

export const InstitutionText = styled.div(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular04}
    color: ${theme.colors.textPrimary01};
  `
);

export const IconWrapper = styled.div`
  align-self: flex-start;
  justify-self: flex-end;
`;
