import {
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from '../types';

enum InterestRateAdjustmentCalculationMethodType {
  AddFixedPercentageToCurrentInterestRate = 'AddFixedPercentageToCurrentInterestRate',
  AddFixedPercentageToOriginalInterestRate = 'AddFixedPercentageToOriginalInterestRate',
  AddImpliedMarginToValueOfFinancialIndex = 'AddImpliedMarginToValueOfFinancialIndex',
  AddIndexChangeToOriginalInterestRate = 'AddIndexChangeToOriginalInterestRate',
  AddMarginToValueOfFinancialIndex = 'AddMarginToValueOfFinancialIndex',
  Other = 'Other',
  Renegotiated = 'Renegotiated',
}

enum InterestRateLifetimeAdjustmentCeilingType {
  AbsoluteRequiredCeiling = 'AbsoluteRequiredCeiling',
  FactorAddedToTheGreaterOfTheNetCommitmentYieldOrTheOriginalNoteRate = 'FactorAddedToTheGreaterOfTheNetCommitmentYieldOrTheOriginalNoteRate',
  FactorAddedToTheNetCommitmentYield = 'FactorAddedToTheNetCommitmentYield',
  FactorAddedToTheOriginalNoteRate = 'FactorAddedToTheOriginalNoteRate',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
}

enum InterestRateLifetimeAdjustmentFloorType {
  AbsoluteMinimumNetInterestRate = 'AbsoluteMinimumNetInterestRate',
  FactorAddedToOriginalNoteRate = 'FactorAddedToOriginalNoteRate',
  FloorIsMargin = 'FloorIsMargin',
  InitialNoteRate = 'InitialNoteRate',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
}

export enum InterestRateRoundingType {
  Down = 'Down',
  Nearest = 'Nearest',
  NoRounding = 'NoRounding',
  Up = 'Up',
}

enum LoanMarginCalculationMethodType {
  BasedOnProduct = 'BasedOnProduct',
  Constant = 'Constant',
}

export type InterestRateLifetimeAdjustmentRule = MISMOEntity &
  Partial<{
    carryoverRateIndicator: MISMOIndicator;
    ceilingRatePercent: MISMOPercent;
    ceilingRatePercentEarliestEffectiveMonthsCount: MISMOCount;
    deferredInterestExclusionIndicator: MISMOIndicator;
    firstRateChangeMonthsCount: MISMOCount;
    firstRateChangePaymentEffectiveDate: MISMODate;
    floorRatePercent: MISMOPercent;
    interestRateAdjustmentCalculationMethodType: InterestRateAdjustmentCalculationMethodType;
    interestRateAdjustmentCalculationMethodTypeOtherDescription: MISMOString;
    interestRateAverageValueCount: MISMOCount;
    interestRateLifetimeAdjustmentCeilingType: InterestRateLifetimeAdjustmentCeilingType;
    interestRateLifetimeAdjustmentCeilingTypeOtherDescription: MISMOString;
    interestRateLifetimeAdjustmentFloorType: InterestRateLifetimeAdjustmentFloorType;
    interestRateLifetimeAdjustmentFloorTypeOtherDescription: MISMOString;
    interestRateRoundingPercent: MISMOPercent;
    interestRateRoundingType: InterestRateRoundingType;
    interestRateTruncatedDigitsCount: MISMOCount;
    loanMarginCalculationMethodType: LoanMarginCalculationMethodType;
    marginRatePercent: MISMOPercent;
    maximumDecreaseRatePercent: MISMOPercent;
    maximumIncreaseRatePercent: MISMOPercent;
    paymentsBetweenInterestRateValuesCount: MISMOCount;
    paymentsBetweenRateChangesCount: MISMOCount;
    timelyPaymentRateReductionPercent: MISMOPercent;
    totalStepCount: MISMOCount;
  }>;
