import { OutboundTradeCounterpartyActionWithSummary } from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type OutboundDataRequestDeniedProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
};

export const OutboundDataRequestDenied = ({ action }: OutboundDataRequestDeniedProps) => {
  const { createdAt, reason, toCounterparty } = action;

  const userName = formatNameWithInitial(
    toCounterparty.user.lastName,
    toCounterparty.user.firstName
  );
  const organizationName = toCounterparty.user.organization.tradingName;

  return (
    <PlusMessage date={createdAt}>
      <MessageText>
        {reason ? (
          <TradeMessage
            id="Trade.Outbound.DataRequest.DeniedWithReason"
            values={{ userName, organizationName, reason }}
          />
        ) : (
          <TradeMessage
            id="Trade.Outbound.DataRequest.DeniedWithoutReason"
            values={{ userName, organizationName }}
          />
        )}
      </MessageText>
    </PlusMessage>
  );
};
