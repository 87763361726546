import {
  InboundTradeRequestWithSummary,
  OutboundTradeRequestWithSummary,
  TradeCounterpartyActionType,
  TradeRequestTabType,
} from '@plus-platform/shared';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { useGetTradeRequestSummaryQuery } from '../hooks/queries';
import { Messenger } from './TradeRequestMessenger/Messenger';
import { InboundTradeRequestDetail } from './TradeRequestTickets';
import { useTradeRequestOutboundNavigation } from './TradeRequestTickets/useTradeRequestNavigation';
import { TradeRequestCategory } from './types';

type TradeRequestDetailsParams = {
  category: string;
  tradeId: string;
  tabId?: TradeRequestTabType;
  counterpartyId?: string;
};

export const TradeRequestDetailPage = () => {
  const { category, tradeId } = useParams<TradeRequestDetailsParams>();
  const { goToCompletedTrades } = useTradeRequestOutboundNavigation();

  const { data: tradeRequest, isLoading } = useGetTradeRequestSummaryQuery(
    tradeId,
    category,
    (tradeRequest) => {
      if (tradeRequest) {
        const isTradeRequestSoldOrSettled = tradeRequest.counterparties.some((counterparty) => {
          return counterparty.actions.some(
            (action) =>
              action.type === TradeCounterpartyActionType.MARK_AS_SOLD ||
              action.type === TradeCounterpartyActionType.MARK_AS_SETTLED
          );
        });
        if (isTradeRequestSoldOrSettled) {
          goToCompletedTrades();
        }
      }
    }
  );

  const isOutbound = category === TradeRequestCategory.OUTBOUND;

  return (
    <Page>
      <PageHeader>
        <Heading>Trade {tradeId}</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/trade-requests" title="Trade activity" />
          <BreadcrumbItem linkTo={`/trade-requests/${category}`} title={capitalize(category)} />
          {isOutbound && (
            <BreadcrumbItem
              linkTo={`/trade-requests/${category}/${tradeId}`}
              title="Trade Dashboard"
            />
          )}
          <BreadcrumbItem title={`Trade ${tradeId}`} />
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        <ActivityIndicator contain isActive={isLoading}>
          {tradeRequest && (
            <React.Fragment>
              {category === TradeRequestCategory.INBOUND && (
                <InboundTradeRequestDetail
                  tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
                />
              )}
              {category === TradeRequestCategory.OUTBOUND && (
                <Messenger
                  category={TradeRequestCategory.OUTBOUND}
                  disabledTabs={[]}
                  tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
                />
              )}
            </React.Fragment>
          )}
        </ActivityIndicator>
      </PageContent>
    </Page>
  );
};
