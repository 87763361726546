import { AttributeType, PermissionType } from '@plus-platform/shared';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { HeadingLink } from '../components/HeadingLink';
import { Page, PageContent, PageHeader } from '../components/Page';
import { DocumentsTableOverview } from '../documents/DocumentsTableOverview';
import { DocumentsTablePage } from '../documents/DocumentsTablePage';
import { DocumentViewerPage } from '../documents/submissions/DocumentViewerPage';
import { PrivateRoute } from '../global/PrivateRoute';
import { HoldingTankTable } from './HoldingTankTable';
import * as Styles from './IngestionPage.styles';
import { SubmissionPage } from './SubmissionPage';
import { SubmissionsList } from './SubmissionsList';
import { SubmissionsPage } from './SubmissionsPage';
import { SubmissionsSummary } from './SubmissionsSummary';

export const IngestionPage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <Page>
          <PageHeader>
            <Heading>Ingestion</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem title="Ingestion" />
            </Breadcrumb>

            <SubmissionsSummary />
          </PageHeader>

          <PageContent>
            <Styles.Wrapper>
              <Styles.Section>
                <HeadingLink to="/documents/submissions" $size="medium">
                  Submissions
                </HeadingLink>

                <SubmissionsList itemsPerPage={5} path="/documents" />
              </Styles.Section>

              <Styles.Section>
                <HeadingLink to="/documents/submissions" $size="medium">
                  Holding Tank
                </HeadingLink>

                <HoldingTankTable maxVisibleItems={10} />
              </Styles.Section>

              <Styles.Section>
                <HeadingLink
                  to="/documents/library"
                  $size="medium"
                  data-testid="LoanDocuments_PortfolioDocumentLibrary"
                >
                  Portfolio Document Library
                </HeadingLink>
                <DocumentsTableOverview maxVisibleItems={10} />
              </Styles.Section>
            </Styles.Wrapper>
          </PageContent>
        </Page>
      </PrivateRoute>

      <PrivateRoute
        component={DocumentsTablePage}
        path="/documents/library"
        requiredPermissions={{
          [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_DOCUMENT],
        }}
      />
      <PrivateRoute
        component={DocumentViewerPage}
        path="/documents/document/:documentId"
        requiredPermissions={{
          [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_DOCUMENT],
        }}
      />
      <PrivateRoute
        component={SubmissionPage}
        path="/documents/submissions/:submissionId"
        requiredPermissions={{
          [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_DOCUMENT],
        }}
      />
      <PrivateRoute
        component={SubmissionsPage}
        path="/documents/submissions"
        requiredPermissions={{
          [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_DOCUMENT],
        }}
      />
    </Switch>
  );
};
