import styled from 'styled-components/macro';

export const MainContent = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
`;

export const Filters = styled.div`
  background-color: ${({ theme }) => theme.colors.deprecatedPrimary02};
  border-radius: 4px;
  min-width: 282px;
`;

export const FiltersHeader = styled.header`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white05};
`;

export const FiltersGroup = styled.div`
  padding: 12px;
`;

export const FilterItems = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
  margin: 0;
  list-style: none;
`;

export const FilterItem = styled.li`
  display: flex;
  gap: 8px;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
`;

export const FilterLabel = styled.span`
  display: inline-flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white70};

  ${({ theme }) => theme.typography.bodyText.bodyRegular02}
`;

export const Emphasis = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;
