import React from 'react';

export const SettingsFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.16 20L9.76 17.48C9.50667 17.3867 9.24 17.26 8.96 17.1C8.68 16.94 8.43333 16.7733 8.22 16.6L5.86 17.68L4 14.4L6.16 12.82C6.13333 12.7 6.11667 12.5633 6.11 12.41C6.10333 12.2567 6.1 12.12 6.1 12C6.1 11.88 6.10333 11.7433 6.11 11.59C6.11667 11.4367 6.13333 11.3 6.16 11.18L4 9.6L5.86 6.32L8.22 7.4C8.43333 7.22667 8.68 7.06 8.96 6.9C9.24 6.74 9.50667 6.62 9.76 6.54L10.16 4H13.84L14.24 6.52C14.4933 6.61333 14.7633 6.73667 15.05 6.89C15.3367 7.04333 15.58 7.21333 15.78 7.4L18.14 6.32L20 9.6L17.84 11.14C17.8667 11.2733 17.8833 11.4167 17.89 11.57C17.8967 11.7233 17.9 11.8667 17.9 12C17.9 12.1333 17.8967 12.2733 17.89 12.42C17.8833 12.5667 17.8667 12.7067 17.84 12.84L20 14.4L18.14 17.68L15.78 16.6C15.5667 16.7733 15.3233 16.9433 15.05 17.11C14.7767 17.2767 14.5067 17.4 14.24 17.48L13.84 20H10.16ZM12 14.6C12.72 14.6 13.3333 14.3467 13.84 13.84C14.3467 13.3333 14.6 12.72 14.6 12C14.6 11.28 14.3467 10.6667 13.84 10.16C13.3333 9.65333 12.72 9.4 12 9.4C11.28 9.4 10.6667 9.65333 10.16 10.16C9.65333 10.6667 9.4 11.28 9.4 12C9.4 12.72 9.65333 13.3333 10.16 13.84C10.6667 14.3467 11.28 14.6 12 14.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
