import { GetPortfolioLoansResponse } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioLoans = async (
  filterIds: number[],
  offset = 0,
  limit: number | undefined = undefined,
  sortBy: string | undefined = undefined
) => {
  // TODO: add sort direction support, for now asc only
  const sortSymbol = '';
  const sortQuery = sortBy ? `${sortSymbol}${sortBy}` : '';

  const query = new URLSearchParams({
    offset: `${offset}`,
    limit: limit ? `${limit}` : '',
    sort: sortQuery,
  });

  filterIds.forEach((id) => query.append('filterIds', `${id}`));

  const url = makeApiUrl(`portfolio/loans?${query}`);
  return getRequestWithAuth<GetPortfolioLoansResponse>(url);
};
