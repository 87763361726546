import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

export enum InterestAccrualType {
  AverageDailyInterestAccrual = 'AverageDailyInterestAccrual',
  DailyInterestAccrual = 'DailyInterestAccrual',
}

enum InterestCalculationBasisDaysInPeriodType {
  '30Days' = '30Days',
  DaysBetweenPayments = 'DaysBetweenPayments',
  DaysInCalendarMonth = 'DaysInCalendarMonth',
  Other = 'Other',
}

export enum InterestCalculationBasisDaysInYearCountType {
  InterestCalculationBasisDaysInYearCount360 = '360',
  InterestCalculationBasisDaysInYearCount365 = '365',
  'InterestCalculationBasisDaysInYearCount365.25' = '365.25',
  InterestCalculationBasisDaysInYearCount365Or366 = '365Or366',
  InterestCalculationBasisDaysInYearCount366 = '366',
}

export enum InterestCalculationBasisType {
  AverageBalance = 'AverageBalance',
  DailyLoanBalance = 'DailyLoanBalance',
  EndOfPeriod = 'EndOfPeriod',
  MaximumBalance = 'MaximumBalance',
  Other = 'Other',
}

export enum InterestCalculationPeriodType {
  Biweekly = 'Biweekly',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Semimonthly = 'Semimonthly',
  Week = 'Week',
  Year = 'Year',
}

enum InterestCalculationPurposeType {
  Draw = 'Draw',
  Other = 'Other',
  Payoff = 'Payoff',
  ServicerAdvance = 'ServicerAdvance',
  Standard = 'Standard',
}

export enum InterestCalculationType {
  Compound = 'Compound',
  Other = 'Other',
  RuleOf78s = 'RuleOf78s',
  Simple = 'Simple',
}

export type InterestCalculationRule = MISMOEntity &
  Partial<{
    initialUnearnedInterestAmount: MISMOAmount;
    interestAccrualType: InterestAccrualType;
    interestCalculationBasisDaysInPeriodType: InterestCalculationBasisDaysInPeriodType;
    interestCalculationBasisDaysInPeriodTypeOtherDescription: MISMOString;
    interestCalculationBasisDaysInYearCountType: InterestCalculationBasisDaysInYearCountType;
    interestCalculationBasisType: InterestCalculationBasisType;
    interestCalculationBasisTypeOtherDescription: MISMOString;
    interestCalculationEffectiveDate: MISMODate;
    interestCalculationEffectiveMonthsCount: MISMOCount;
    interestCalculationExpirationDate: MISMODate;
    interestCalculationPeriodAdjustmentIndicator: MISMOIndicator;
    interestCalculationPeriodType: InterestCalculationPeriodType;
    interestCalculationPurposeType: InterestCalculationPurposeType;
    interestCalculationPurposeTypeOtherDescription: MISMOString;
    interestCalculationType: InterestCalculationType;
    interestCalculationTypeOtherDescription: MISMOString;
    interestInAdvanceIndicator: MISMOIndicator;
    loanInterestAccrualStartDate: MISMODate;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type InterestCalculationOccurrence = MISMOEntity &
  Partial<{
    currentAccruedInterestAmount: MISMOAmount;
    interestDueFromClosingAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type InterestCalculation = MISMOEntity &
  Partial<{
    interestCalculationOccurrences: InterestCalculationOccurrence[];
    interestCalculationRules: InterestCalculationRule[];
  }>;
