import { Message, MessageType, TradeRequestWithSummary } from '@plus-platform/shared';
import React from 'react';
import { useQueryClient } from 'react-query';

import { useSocketContext } from '../contexts/SocketContext';
import { QueryKeys, useGetTradeRequestSummaryQuery } from '../hooks/queries';

export const useTradeRequestSummary = <T = TradeRequestWithSummary>(
  tradeRequestId: string,
  category: string,
  onSuccess?: (data?: T) => void
) => {
  const query = useGetTradeRequestSummaryQuery<T>(tradeRequestId, category, onSuccess);

  const { subscribe, unsubscribe } = useSocketContext();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const handleMessage = (message: Message) => {
      // TODO: Replace with setQueryData including payload once outbound and inbound types share same type structure
      if (message.type === MessageType.TradeRequestChatMessage) {
        queryClient.invalidateQueries([QueryKeys.TRADE_REQUEST_SUMMARY, tradeRequestId, category]);
      }

      // TODO: Replace setQueryData including payload once outbound and inbound types share same type structure
      if (message.type === MessageType.TradeRequestActionMessage) {
        queryClient.invalidateQueries([QueryKeys.TRADE_REQUEST_SUMMARY, tradeRequestId, category]);
      }

      return;
    };

    subscribe(handleMessage);

    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, queryClient]);

  return query;
};
