import {
  DelinquencyType,
  LoansCountByRateType,
  PortfolioBreakdownDelinquencyType,
  PortfolioBreakdownRateType,
  PortfolioBreakdownType,
} from '@plus-platform/shared';
import merge from 'lodash/fp/merge';
import { rgba } from 'polished';
import React from 'react';
import { VictoryThemeDefinition } from 'victory-core';

import { plusChartTheme } from '../../styles/chart';
import {
  statusGreen,
  statusOrange,
  statusRed,
  statusTurquoise,
  statusYellow,
  white,
  white05,
} from '../../styles/darkTheme/colors';

export const BELOW_MARKET_RATE_CHART_COLOR = rgba(statusGreen, 0.7);
export const ABOVE_MARKET_RATE_CHART_COLOR = rgba(statusRed, 0.7);

export const DELINQUENT_CHART_COLOR_MAPPING: Record<
  PortfolioBreakdownDelinquencyType,
  React.CSSProperties['color']
> = {
  [PortfolioBreakdownType.ALL]: rgba(statusTurquoise, 0.7),
  [DelinquencyType.THIRTY_DAYS]: rgba(statusYellow, 0.7),
  [DelinquencyType.SIXTY_DAYS]: rgba(statusOrange, 0.7),
  [DelinquencyType.NINETY_DAYS]: rgba(statusRed, 0.7),
  [DelinquencyType.NINETY_PLUS_DAYS]: rgba(statusRed, 0.7),
};

export const defaultLoanTypesChartTheme = merge<typeof plusChartTheme, VictoryThemeDefinition>(
  plusChartTheme,
  {
    axis: {
      style: {
        tickLabels: { fontSize: 11.5, fill: white },
        axis: { stroke: white05 },
        grid: { stroke: white05, pointerEvents: 'none' },
      },
    },
    dependentAxis: {
      style: {
        tickLabels: { fontSize: 11.5, fill: white, fontWeight: 400 },
        grid: {
          stroke: white05,
          strokeOpacity: ({ index }) =>
            Number(index) % 2 ? 0 : plusChartTheme.dependentAxis.style.grid.strokeOpacity,
        },
        axis: { stroke: white05 },
      },
    },
  }
);

export const mapLoansCountByNoteRateForChart = (data?: LoansCountByRateType) => {
  if (!data) {
    return [];
  }

  return Object.entries(data).flatMap(([rateType, dataItem]) => {
    const { countsByNoteRate, currentMarketRate } = dataItem;

    return Object.entries(countsByNoteRate).map(([noteRate, loansCount]) => {
      return {
        currentMarketRate,
        noteRate: Number(noteRate),
        loansCount,
        rateType: rateType as PortfolioBreakdownRateType,
      };
    });
  });
};
