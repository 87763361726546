import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from './types';

enum IncomeReportedType {
  PostTax = 'PostTax',
  PreTax = 'PreTax',
}

enum QualifyingMethodType {
  FirstYearCapRate = 'FirstYearCapRate',
  FullyIndexed = 'FullyIndexed',
  InterestOnly = 'InterestOnly',
  MinimumPayment = 'MinimumPayment',
  Other = 'Other',
  StartRate = 'StartRate',
}

export enum QualifyingRateType {
  AboveNoteRate = 'AboveNoteRate',
  AtNoteRate = 'AtNoteRate',
  BelowNoteRate = 'BelowNoteRate',
  BoughtDownRate = 'BoughtDownRate',
  Other = 'Other',
}

export type Qualification = MISMOEntity &
  Partial<{
    borrowerReservesMonthlyPaymentCount: MISMOCount;
    combinedLTVRatioConsideredInDecisionIndicator: MISMOIndicator;
    creditScoreConsideredInDecisionIndicator: MISMOIndicator;
    householdOccupancyCount: MISMOCount;
    housingExpenseRatioPercent: MISMOPercent;
    incomeConsideredInDecisionIndicator: MISMOIndicator;
    incomeReportedType: IncomeReportedType;
    projectedReservesAmount: MISMOAmount;
    qualifyingMethodType: QualifyingMethodType;
    qualifyingMethodTypeOtherDescription: MISMOString;
    qualifyingMIPaymentAmount: MISMOAmount;
    qualifyingPaymentAmount: MISMOAmount;
    qualifyingPrincipalAndInterestPaymentAmount: MISMOAmount;
    qualifyingRatePercent: MISMOPercent;
    qualifyingRateType: QualifyingRateType;
    qualifyingRateTypeOtherDescription: MISMOString;
    subjectPropertyNetCashFlowAmount: MISMOAmount;
    summaryInterestedPartyContributionsPercent: MISMOPercent;
    totalDebtExpenseRatioConsideredInDecisionIndicator: MISMOIndicator;
    totalDebtExpenseRatioPercent: MISMOPercent;
    totalExpensesMonthlyPaymentAmount: MISMOAmount;
    totalLiabilitiesMonthlyPaymentAmount: MISMOAmount;
    totalMonthlyCurrentHousingExpenseAmount: MISMOAmount;
    totalMonthlyIncomeAmount: MISMOAmount;
    totalMonthlyProposedHousingExpenseAmount: MISMOAmount;
    totalNonFirstLienMortgageDebtAmount: MISMOAmount;
    totalVerifiedReservesAmount: MISMOAmount;
    verifiedAssetsTotalAmount: MISMOAmount;
  }>;
