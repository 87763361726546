import { useEffect, useRef, useState } from 'react';

import { Portal } from './Portal';
import * as Styles from './TableCellActions.styles';

type TableCellActionsChildrenProps = {
  close: () => void;
};

type TableCellActionsProps = {
  children: (props: TableCellActionsChildrenProps) => React.ReactNode;
  isActive?: boolean;
};

export const TableCellActions = ({ children, isActive }: TableCellActionsProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [showActions, setShowActions] = useState(false);
  const [bounds, setBounds] = useState<DOMRect>();

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (
        !parentRef.current ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (parentRef.current as any).contains?.(event.target) ||
        !contentRef.current ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (contentRef.current as any).contains?.(event.target)
      ) {
        return;
      }

      setShowActions(false);
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [parentRef, contentRef]);

  const handleClick = () => {
    if (!parentRef.current || !isActive) {
      return;
    }

    setBounds(parentRef.current.getBoundingClientRect());
    setShowActions(true);
  };

  if (!isActive) {
    return null;
  }

  return (
    <Styles.Wrapper ref={parentRef} onClick={handleClick}>
      {showActions && bounds && (
        <Portal>
          <Styles.Positioner ref={contentRef} $top={bounds.top} $left={bounds.left}>
            {children({ close: () => setShowActions(false) })}
          </Styles.Positioner>
        </Portal>
      )}
    </Styles.Wrapper>
  );
};
