import { BikeIcon, TransitIcon, WalkIcon } from '../components/icons';
import { FullWidthLoaderWrapper, Loader } from '../components/Loader';
import { RadioDial } from '../components/RadioDial';
import { useLoanPropertyScores } from './LoanData';
import * as Styles from './ScoresCharts.styles';

const ScoresCharts = () => {
  const { data: scores, isLoading } = useLoanPropertyScores();

  if (isLoading) {
    return (
      <Styles.ScoresWrapper>
        <FullWidthLoaderWrapper>
          <Loader />
        </FullWidthLoaderWrapper>
      </Styles.ScoresWrapper>
    );
  }

  if (!scores) {
    return null;
  }

  return (
    <Styles.ScoresWrapper>
      <Styles.ScoreIconPair title={`${scores.walk?.score} Walk score`}>
        <WalkIcon />
        <RadioDial value={scores.walk?.score} svgTitle="Walk score" />
      </Styles.ScoreIconPair>

      <Styles.ScoreIconPair title={`${scores.transit?.score} Transit score`}>
        <TransitIcon />
        <RadioDial value={scores.transit?.score} svgTitle="Transit score" />
      </Styles.ScoreIconPair>

      <Styles.ScoreIconPair title={`${scores.bike?.score} Bike score`}>
        <BikeIcon />
        <RadioDial value={scores.bike?.score} svgTitle="Bike score" />
      </Styles.ScoreIconPair>
    </Styles.ScoresWrapper>
  );
};

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default ScoresCharts;
