import { NotificationDto } from '@plus-platform/shared';
import formatDistance from 'date-fns/formatDistance';
import React, { useCallback } from 'react';

import { useMarkNotificationAsReadMutation } from '../../hooks/queries';
import * as Styles from './NotificationsPanelItem.styles';

interface NotificationsPanelItemProps {
  notification: NotificationDto;
}

export const NotificationsPanelItem = ({ notification }: NotificationsPanelItemProps) => {
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadMutation();

  const handleMarkNotificationAsRead = useCallback(async () => {
    if (!notification.read) {
      await markNotificationAsRead(notification.id);
    }
  }, [markNotificationAsRead, notification]);

  return (
    <Styles.ItemWrapper>
      <Styles.ItemLink onClick={handleMarkNotificationAsRead} to={notification.actionUrl}>
        <Styles.TitleWrapper>
          <Styles.Title>{notification.message}</Styles.Title>
          <Styles.TimeAgo>
            {formatDistance(new Date(notification.createdAt), new Date())} ago
          </Styles.TimeAgo>
        </Styles.TitleWrapper>
      </Styles.ItemLink>
    </Styles.ItemWrapper>
  );
};
