import React from 'react';

export const CheckboxIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.81711 17.7034L4.28898 12.2341L5.92463 10.6158L9.81711 14.4669L18.0752 6.29667L19.7108 7.91492L9.81711 17.7034Z"
        fill="currentColor"
      />
    </svg>
  );
};
