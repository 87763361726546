import styled from 'styled-components/macro';

export const SummaryWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
`;

export const SummaryColumns = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const Highlight = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;
