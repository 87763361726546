const APP_PREFIX = 'plus.';
export const makeKey = (key: string) => `${APP_PREFIX}${key}`;

export const STORAGE_EVENT = 'storage';

export const setStorage = <Value>(key: string, value: Value) => {
  const stringifiedValue = JSON.stringify(value);
  localStorage.setItem(makeKey(key), stringifiedValue);
};

export const getStorage = (key: string) => {
  const stringifiedValue = localStorage.getItem(makeKey(key));
  if (!stringifiedValue) {
    return '';
  }

  try {
    return JSON.parse(stringifiedValue);
  } catch (err) {
    return stringifiedValue;
  }
};

export const removeStorage = (key: string) => {
  localStorage.removeItem(makeKey(key));
};
