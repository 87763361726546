import { useQueries, UseQueryOptions, UseQueryResult } from 'react-query';

export type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

/**
 * Hook wrapper to strongly type the useQueries hook
 *
 * @see https://github.com/tannerlinsley/react-query/issues/1675#issuecomment-767323572
 *
 * @param queries
 */
export function useQueriesTyped<TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<
          ReturnType<NonNullable<Extract<TQueries[ArrayElement], UseQueryOptions>['queryFn']>>
        >
  >;
} {
  return useQueries(queries as UseQueryOptions<unknown, unknown, unknown>[]) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
