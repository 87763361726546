import React from 'react';

export const InboundIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 7.29231L7.29231 6L16.1538 14.8615L16.1538 6.92308L18 6.92308L18 18L6.92308 18L6.92308 16.1538L14.8615 16.1538L6 7.29231Z"
        fill="currentColor"
      />
    </svg>
  );
};
