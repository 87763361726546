import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import logo from '../../assets/plus-logo-combined.svg';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { PlusIcon } from '../../components/icons/PlusIcon';
import { useCreateAccountMutation } from '../../hooks/queries/useCreateAccountMutation';
import { useInviteTokenValidation } from '../../hooks/useInviteTokenValidation';
import { CreateAccountErrorMessage } from './CreateAccountErrorMessage';
import { CreateAccountFormValues, CreatePasswordForm } from './CreatePasswordForm';
import { Container, Header, Logo, Title, Wrapper } from './styles';
import { TermsAndConditionsForm } from './TermsAndConditionsForm';

export const CreateAccount = () => {
  const theme = useTheme();
  const location = useLocation();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const {
    error: tokenError,
    isError: isTokenError,
    isLoading: checkingToken,
    token,
  } = useInviteTokenValidation(location);
  const {
    error: createAccountError,
    isError: isCreateAccountError,
    isLoading,
    isSuccess,
    mutate,
  } = useCreateAccountMutation();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<CreateAccountFormValues>();

  const onSubmit = handleSubmit((values) => {
    if (token) {
      // @todo: post the terms acceptance along with this payload
      mutate({
        ...values,
        token,
      });
    }
  });

  const handleAcceptTerms = () => {
    // TODO: Handle accepting terms, maybe store the version & date the user accepted
    setTermsAccepted(true);
  };

  if (isSuccess) {
    return <Redirect to="/home" />;
  }

  const showForm = token && !checkingToken && !tokenError;
  const hasError = isCreateAccountError || isTokenError;
  const error = createAccountError || tokenError;
  const errorMessage = hasError && error instanceof Error ? error.message : '';

  const getContent = () => {
    if (checkingToken) {
      return { title: '', content: <ActivityIndicator isActive /> };
    }

    if (tokenError) {
      return {
        title: '',
        content: <CreateAccountErrorMessage errorMessage={errorMessage} />,
      };
    }

    if (!termsAccepted) {
      return {
        title: 'Terms & Conditions',
        content: <TermsAndConditionsForm onAccept={handleAcceptTerms} />,
      };
    }

    if (showForm) {
      return {
        title: 'Create a password',
        content: (
          <CreatePasswordForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitError={errorMessage}
            formErrors={errors}
            register={register}
          />
        ),
      };
    }

    return { title: '', content: null };
  };

  const { content, title } = getContent();

  return (
    <Container>
      <Logo src={logo} alt="Plus logo" />
      <Wrapper>
        <Header>
          <PlusIcon color={theme.colors.white} width={36} />
          <Title>{title}</Title>
        </Header>
        {content}
      </Wrapper>
    </Container>
  );
};
