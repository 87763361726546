import { GetPortfolioLoansDocumentSummaryItem } from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import React from 'react';

import {
  usePortfolioFiltersQuery,
  usePortfolioLoansDocumentSummaryQuery,
  useSavePortfolioFiltersMutation,
} from '../hooks/queries';
import { getAppliedFilters } from '../utils/portfolioFilterUtils';
import { DocumentsTable } from './DocumentsTable';

const DEFAULT_DOCUMENTS_PAGE_ITEMS = 20;

type DocumentsTableOverviewProps = {
  maxVisibleItems?: number;
};

export const DocumentsTableOverview = ({
  maxVisibleItems = DEFAULT_DOCUMENTS_PAGE_ITEMS,
}: DocumentsTableOverviewProps) => {
  const { data: filters = [] } = usePortfolioFiltersQuery();
  const appliedFilters = getAppliedFilters(filters);
  const { mutateAsync: updateFilters } = useSavePortfolioFiltersMutation();
  const {
    data: portfolioLoans,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = usePortfolioLoansDocumentSummaryQuery(
    appliedFilters.map((filter) => filter.id),
    maxVisibleItems
  );

  const pages = compact(portfolioLoans?.pages || []);
  const totalLoansCount = get(pages, '0.totalLoansCount', 0);
  const totalFilteredLoansCount = get(pages, '0.totalFilteredLoansCount', 0);
  const loansWithDocumentSummary: GetPortfolioLoansDocumentSummaryItem[] = flatMap(pages, 'data');

  return (
    <React.Fragment>
      {!isLoading && totalFilteredLoansCount === 0 ? (
        <div>No documents found</div>
      ) : (
        <DocumentsTable
          fetchNextPage={fetchNextPage}
          filters={filters}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          loansWithDocumentSummary={loansWithDocumentSummary}
          maxVisibleItems={maxVisibleItems}
          onFiltersUpdate={updateFilters}
          totalFilteredLoansCount={totalFilteredLoansCount}
          totalLoansCount={totalLoansCount}
        />
      )}
    </React.Fragment>
  );
};
