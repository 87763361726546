import {
  ADMIN_ANALYTIC_DEFAULT_SETTINGS,
  AnalyticSettings,
  SettingsAlertType,
} from '@plus-platform/shared';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import checkboxAlertsImage from '../assets/analytics-alerts.svg';
import checkboxBannersImage from '../assets/analytics-banners.svg';
import checkboxNoneImage from '../assets/analytics-none.svg';
import { ChevronLeftIcon, HelpIcon } from '../components/icons';
import { FullWidthLoaderWrapper, Loader } from '../components/Loader';
import { PageSection } from '../components/Page';
import { Radio } from '../components/Radio';
import { ToggleSwitch } from '../components/ToggleSwitch';
import {
  useGetAnalyticSettings,
  useGetPortfolioAnalyticsById,
  useUpdateAnalyticSettingsMutation,
} from '../hooks/queries';
import { getLabelFromAnalyticResult } from '../portfolio/analytics/utils';
import { MenuItemButton, SpecificMenuItemButton } from './AdminMenuItemButton';
import {
  GeneralMenu,
  Header,
  MenuItem,
  SpecificMenu,
  SpecificMenuItem,
  Wrapper,
} from './FlagsAlertsWarningsPage.styles';
import { GeneralSettings } from './GeneralSettings';
import {
  CheckboxGroup,
  CheckboxGroupWrapper,
  CheckboxImage,
  CheckboxItem,
  CheckboxLabel,
} from './QATilePage.styles';
import {
  AnalyticsHeader,
  AnalyticsSubtitle,
  AnalyticsTitle,
  Description,
  HeaderLink,
  LoaderOpacityMask,
  Subsection,
  ToggleWrapper,
} from './SettingsAnalyticsPage.styles';
import { AdminRouteType } from './types';

type OtherSettingsProps = {
  isLoading: boolean;
  title: string;
  settings?: AnalyticSettings;
  onSettingsChange: (settings: AnalyticSettings) => void;
};

const OtherSettings = ({ isLoading, onSettingsChange, settings, title }: OtherSettingsProps) => {
  const [otherSettings, setOtherSettings] = React.useState<AnalyticSettings | undefined>(settings);

  React.useEffect(() => {
    setOtherSettings(settings);
  }, [settings]);

  const theme = useTheme();

  const updateSettings = (newSettings: AnalyticSettings) => {
    setOtherSettings(newSettings);
    onSettingsChange(newSettings);
  };

  const isAllowingTimeSensitiveNotifications =
    otherSettings?.shouldAllowTimeSensitiveNotifications ??
    ADMIN_ANALYTIC_DEFAULT_SETTINGS.shouldAllowTimeSensitiveNotifications;
  const isAllowingNotificationPreview =
    otherSettings?.shouldPreviewNotifications ??
    ADMIN_ANALYTIC_DEFAULT_SETTINGS.shouldPreviewNotifications;
  const isAllowingNotificationGrouping =
    otherSettings?.shouldAllowNotificationGrouping ??
    ADMIN_ANALYTIC_DEFAULT_SETTINGS.shouldAllowNotificationGrouping;

  const alertType =
    otherSettings?.bannersAlertsGroups?.alertType ??
    ADMIN_ANALYTIC_DEFAULT_SETTINGS.bannersAlertsGroups.alertType;

  return (
    <>
      <PageSection>
        <Header>
          <AnalyticsSubtitle>Notifications</AnalyticsSubtitle>
        </Header>

        <GeneralMenu style={{ minHeight: '43px' }}>
          {isLoading && (
            <FullWidthLoaderWrapper>
              <LoaderOpacityMask>
                <Loader color={theme.colors.fillSettingsSpinnerIcon} />
              </LoaderOpacityMask>
            </FullWidthLoaderWrapper>
          )}

          <MenuItem>
            <MenuItemButton
              title={title}
              actionComponent={() => (
                <ToggleWrapper>
                  <span>Allow notifications</span>
                  <ToggleSwitch id="allow-notifications" name="allow-notifications" disabled />
                </ToggleWrapper>
              )}
              icon={<HelpIcon />}
            />
          </MenuItem>
        </GeneralMenu>
      </PageSection>

      <PageSection>
        <Header>
          <AnalyticsSubtitle>Banners, Alerts & Groups</AnalyticsSubtitle>
          <HelpIcon color={theme.colors.statusGreen} />
        </Header>

        <Subsection>
          <CheckboxGroupWrapper>
            <CheckboxItem>
              <CheckboxImage src={checkboxNoneImage} alt="No notifications" />
              <CheckboxGroup>
                <Radio
                  id="notification-none"
                  name="notification-none"
                  $size="small"
                  checked={alertType === SettingsAlertType.NONE}
                  onChange={(event) =>
                    updateSettings({
                      ...otherSettings,
                      bannersAlertsGroups: {
                        ...otherSettings?.bannersAlertsGroups,
                        alertType: event.target.checked ? SettingsAlertType.NONE : alertType,
                      },
                    })
                  }
                />
                <CheckboxLabel
                  htmlFor="notification-none"
                  $isSelected={alertType === SettingsAlertType.NONE}
                >
                  None
                </CheckboxLabel>
              </CheckboxGroup>
            </CheckboxItem>
            <CheckboxItem>
              <CheckboxImage src={checkboxBannersImage} alt="Banner notifications" />
              <CheckboxGroup>
                <Radio
                  id="notification-banners"
                  name="notification-banners"
                  $size="small"
                  checked={alertType === SettingsAlertType.BANNERS}
                  onChange={(event) =>
                    updateSettings({
                      ...otherSettings,
                      bannersAlertsGroups: {
                        ...otherSettings?.bannersAlertsGroups,
                        alertType: event.target.checked ? SettingsAlertType.BANNERS : alertType,
                      },
                    })
                  }
                />
                <CheckboxLabel
                  htmlFor="notification-banners"
                  $isSelected={alertType === SettingsAlertType.BANNERS}
                >
                  Banners
                </CheckboxLabel>
              </CheckboxGroup>
            </CheckboxItem>
            <CheckboxItem>
              <CheckboxImage src={checkboxAlertsImage} alt="Alert notifications" />
              <CheckboxGroup>
                <Radio
                  id="notification-alerts"
                  name="notification-alerts"
                  $size="small"
                  checked={alertType === SettingsAlertType.ALERTS}
                  onChange={(event) =>
                    updateSettings({
                      ...otherSettings,
                      bannersAlertsGroups: {
                        ...otherSettings?.bannersAlertsGroups,
                        alertType: event.target.checked ? SettingsAlertType.ALERTS : alertType,
                      },
                    })
                  }
                />
                <CheckboxLabel
                  htmlFor="notification-alerts"
                  $isSelected={alertType === SettingsAlertType.ALERTS}
                >
                  Alerts
                </CheckboxLabel>
              </CheckboxGroup>
            </CheckboxItem>
          </CheckboxGroupWrapper>

          <Description>
            Banners appear in the upper right corner of the screen with the notifications icon and
            go away automatically. Alerts stay on the screen until dismissed.{' '}
          </Description>
        </Subsection>

        <SpecificMenu style={{ minHeight: '130px' }}>
          {isLoading && (
            <FullWidthLoaderWrapper>
              <LoaderOpacityMask>
                <Loader color={theme.colors.fillSettingsSpinnerIcon} />
              </LoaderOpacityMask>
            </FullWidthLoaderWrapper>
          )}

          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Allow time-sensitive notifications"
              actionComponent={() => (
                <ToggleSwitch
                  id="notification-time-sensitive"
                  name="notification-time-sensitive"
                  checked={isAllowingTimeSensitiveNotifications}
                  onChange={() =>
                    updateSettings({
                      ...otherSettings,
                      shouldAllowTimeSensitiveNotifications: !isAllowingTimeSensitiveNotifications,
                    })
                  }
                />
              )}
              style={{
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Show previews of notifications"
              actionComponent={() => (
                <ToggleSwitch
                  id="notification-previews"
                  name="notification-previews"
                  checked={isAllowingNotificationPreview}
                  onChange={() =>
                    updateSettings({
                      ...otherSettings,
                      shouldPreviewNotifications: !isAllowingNotificationPreview,
                    })
                  }
                />
              )}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Notification grouping"
              actionComponent={() => (
                <ToggleSwitch
                  id="notification-grouping"
                  name="notification-grouping"
                  checked={isAllowingNotificationGrouping}
                  onChange={() =>
                    updateSettings({
                      ...otherSettings,
                      shouldAllowNotificationGrouping: !isAllowingNotificationGrouping,
                    })
                  }
                />
              )}
              style={{
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
              }}
            />
          </SpecificMenuItem>
        </SpecificMenu>
      </PageSection>
    </>
  );
};

type QATileParams = {
  analyticId: string;
};

export const QATilePage = () => {
  const [liveSettings, setLiveSettings] = React.useState<AnalyticSettings>(
    ADMIN_ANALYTIC_DEFAULT_SETTINGS
  );

  const theme = useTheme();

  const { analyticId } = useParams<QATileParams>();

  const { data: portfolioAnalyticData } = useGetPortfolioAnalyticsById(analyticId);
  const { data: analyticSettingsData } = useGetAnalyticSettings(analyticId);

  React.useEffect(() => {
    if (analyticSettingsData) {
      setLiveSettings(analyticSettingsData);
    }
  }, [analyticSettingsData]);

  const { isLoading: areSettingsUpdating, mutateAsync: updateSettings } =
    useUpdateAnalyticSettingsMutation();

  const updateAnalyticSettings = (newSettings: AnalyticSettings) => {
    const newLiveSettings = {
      ...liveSettings,
      ...newSettings,
    };
    setLiveSettings(newLiveSettings);
    updateSettings({
      analyticId,
      settings: newLiveSettings,
    });
  };

  const title = portfolioAnalyticData ? getLabelFromAnalyticResult(portfolioAnalyticData) : '';

  return (
    <Wrapper>
      <AnalyticsHeader>
        <HeaderLink to={AdminRouteType.AnalyticsQATiles}>
          <ChevronLeftIcon color={theme.colors.fillAdminBackLinkIcon} />
        </HeaderLink>

        {title && <AnalyticsTitle>{title}</AnalyticsTitle>}
      </AnalyticsHeader>

      <PageSection>
        <Header>
          <AnalyticsSubtitle>Behaviour</AnalyticsSubtitle>
          <HelpIcon color={theme.colors.statusGreen} />
        </Header>

        <Description>
          Changing Frequency, Vigilance or Significance here will effect this tile only.
        </Description>

        <Subsection style={{ minHeight: '240px' }}>
          {areSettingsUpdating && (
            <FullWidthLoaderWrapper>
              <LoaderOpacityMask>
                <Loader color={theme.colors.fillSettingsSpinnerIcon} />
              </LoaderOpacityMask>
            </FullWidthLoaderWrapper>
          )}

          <GeneralSettings
            defaultSettings={ADMIN_ANALYTIC_DEFAULT_SETTINGS.generalBehaviorUpdateFrequency}
            settings={liveSettings?.generalBehaviorUpdateFrequency}
            onSettingsChange={(newSettings) =>
              updateAnalyticSettings({
                generalBehaviorUpdateFrequency: newSettings,
              })
            }
          />
        </Subsection>
      </PageSection>

      <OtherSettings
        isLoading={areSettingsUpdating}
        title={title}
        settings={liveSettings}
        onSettingsChange={updateAnalyticSettings}
      />
    </Wrapper>
  );
};
