import styled, { css } from 'styled-components/macro';

type ChartWrapperProps = {
  $hoveredIndex?: number;
};

export const ChartWrapper = styled.div<ChartWrapperProps>`
  [data-index] {
    transition: opacity 0.1s;
  }

  ${(props) =>
    props.$hoveredIndex &&
    css`
      [data-index] {
        opacity: 0.2;
      }

      [data-index='${props.$hoveredIndex}'] {
        opacity: 1;
      }
    `}
`;
