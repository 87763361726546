import { useQuery } from 'react-query';

import { UserProfile } from '../../users/types';
import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const getTradingContacts = () => {
  const url = makeApiUrl('users/trading');

  return getRequestWithAuth<UserProfile[]>(url);
};

export const useTradingContactsQuery = () =>
  useQuery([QueryKeys.TRADING_CONTACTS], getTradingContacts);
