import { PortfolioAnalyticMetric, VisualisationType } from '@plus-platform/shared';

import { AnalyticsBarChart } from './AnalyticsBarChart';
import { AnalyticsBubbleChart } from './AnalyticsBubbleChart';
import { AnalyticsMapChart } from './AnalyticsMapChart';
import { AnalyticsChartProps } from './utils';

const VISUALISATION_COMPONENT_MAPPING: Record<
  VisualisationType,
  (props: ChartProps) => JSX.Element
> = {
  [VisualisationType.CHLOROPLETH]: (props) => <AnalyticsMapChart {...props} />,
  [VisualisationType.BAR_CHART]: (props) => <AnalyticsBarChart {...props} />,
  [VisualisationType.BUBBLE_CHART]: (props) => <AnalyticsBubbleChart {...props} />,
};

type ChartProps = AnalyticsChartProps & {
  id: string;
  metricBy: PortfolioAnalyticMetric;
};

export const AnalyticsChart = ({
  data,
  id,
  metric,
  metricBy,
  style,
  visualisationType,
}: ChartProps) => {
  const ChartComponent = VISUALISATION_COMPONENT_MAPPING[visualisationType];
  if (!ChartComponent) {
    return null;
  }

  return ChartComponent({ data, id, metric, metricBy, visualisationType, style });
};
