import {
  InboundTradeCounterpartyActionWithSummary,
  Message,
  MessageType,
  TradeCounterpartyActionHistoryFilter,
} from '@plus-platform/shared';
import compact from 'lodash/compact';
import React from 'react';
import { InfiniteData, useQueryClient } from 'react-query';

import { useSocketContext } from '../contexts/SocketContext';
import {
  QueryKeys,
  TradeRequestInboundActionsHistoryResponse,
  useTradeRequestInboundActionsHistoryQuery,
} from '../hooks/queries';

export const getActionsFromData = (
  data?: InfiniteData<TradeRequestInboundActionsHistoryResponse | undefined>
) => {
  return (
    compact(data?.pages)
      .reverse()
      .flatMap((page) => page.actions) ?? []
  );
};

const getTotalCountFromData = (
  data?: InfiniteData<TradeRequestInboundActionsHistoryResponse | undefined>
) => {
  return data?.pages[data?.pages.length - 1]?.totalCount ?? 0;
};

export const useTradeRequestInboundActionsHistory = (
  tradeRequestId: string,
  filter: TradeCounterpartyActionHistoryFilter,
  onSuccess?: (data: InfiniteData<TradeRequestInboundActionsHistoryResponse | undefined>) => void
) => {
  const queryClient = useQueryClient();
  const { subscribe, unsubscribe } = useSocketContext();
  const { data, ...query } = useTradeRequestInboundActionsHistoryQuery(
    tradeRequestId,
    filter,
    onSuccess
  );

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

  const canFetchMoreActions = hasNextPage && !isFetchingNextPage;

  const fetchMoreActions = React.useCallback(() => {
    if (canFetchMoreActions) {
      fetchNextPage();
    }
  }, [canFetchMoreActions, fetchNextPage]);

  const tradeData = React.useMemo(
    () => ({
      actions: getActionsFromData(data),
      totalCount: getTotalCountFromData(data),
      canFetchMoreActions,
      fetchMoreActions,
    }),
    [data, canFetchMoreActions, fetchMoreActions]
  );

  React.useEffect(() => {
    const handleMessage = (message: Message) => {
      if (message.type !== MessageType.TradeRequestActionMessage) {
        return;
      }

      queryClient.invalidateQueries([QueryKeys.TRADE_REQUEST_SUMMARY, tradeRequestId, 'inbound']);
      queryClient.setQueryData<InfiniteData<TradeRequestInboundActionsHistoryResponse> | undefined>(
        [QueryKeys.TRADE_REQUEST_INBOUND_ACTION_HISTORY, tradeRequestId, filter],
        (data) => {
          if (!data) {
            return data;
          }

          const [firstPage, ...restPages] = data.pages;
          const pages = [
            {
              ...firstPage,
              actions: [
                ...firstPage.actions,
                message.payload as InboundTradeCounterpartyActionWithSummary,
              ],
            },
            ...restPages,
          ];

          return { ...data, pages };
        }
      );
    };

    subscribe(handleMessage);

    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, queryClient, tradeRequestId, filter]);

  return { ...tradeData, ...query };
};
