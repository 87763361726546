import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../global/PrivateRoute';
import { usePortfolioHomeAnalyticQuery } from '../../hooks/queries';
import { HomeAnalyticDetailPage } from './HomeAnalyticDetailPage';
import { HomeAnalyticGroupDetailPage } from './HomeAnalyticGroupDetailPage';

type AnalyticParams = {
  analyticId: string;
};

export const HomeAnalyticPage = () => {
  const { analyticId } = useParams<AnalyticParams>();
  const match = useRouteMatch();

  const { data: analytic, isLoading } = usePortfolioHomeAnalyticQuery(Number(analyticId));

  return (
    <Switch>
      <PrivateRoute
        render={() => <HomeAnalyticDetailPage analytic={analytic} isLoading={isLoading} />}
        path={match.path}
        exact
      />
      <PrivateRoute
        render={() => <HomeAnalyticGroupDetailPage analytic={analytic} isLoading={isLoading} />}
        path={`${match.path}/group/:groupValue`}
      />
    </Switch>
  );
};
