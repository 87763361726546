import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, putRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const markAllUserNotificationsAsSeenRequest = () => {
  const url = makeApiUrl(`user/notifications/seen`);
  return putRequestWithAuth(url);
};

export const useMarkAllNotificationsAsSeenMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(markAllUserNotificationsAsSeenRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.USER_NOTIFICATIONS_COUNT);
    },
  });
};
