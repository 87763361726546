import { FilterKey, FilterOperator } from '@plus-platform/shared';
import { useHistory } from 'react-router-dom';

import { useSinglePortfolioFilterMutation } from '../hooks/queries';
import { Chloropleth, ChloroplethProps } from './Chloropleth';

type LoanMapProps = Omit<ChloroplethProps, 'data'> & {
  loans: { [key: string]: { value: number; formattedValue: string } };
};

export const LoanMap = ({
  getMapDefaultColor,
  hasLabels,
  hasLegend = true,
  id = '',
  loans,
  style,
}: LoanMapProps) => {
  const { mutateAsync: applySingleFilter } = useSinglePortfolioFilterMutation();
  const history = useHistory();

  const applyFilter = async (state: string) => {
    await applySingleFilter({
      key: FilterKey.STATE,
      operator: FilterOperator.IS,
      value: state,
      name: `state IS ${state}`,
    });
    history.push('/portfolio');
  };

  return (
    <Chloropleth
      id={id}
      data={loans}
      getMapDefaultColor={getMapDefaultColor}
      hasLabels={hasLabels}
      hasLegend={hasLegend}
      onStateClick={applyFilter}
      style={style}
    />
  );
};
