import {
  Filter,
  StipulationType,
  TradeRequestLog,
  TradeRequestWithSummary,
} from '@plus-platform/shared';
import React from 'react';
import { FetchNextPageOptions } from 'react-query';
import { useParams } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import { ExportLineIcon, FilterLineIcon, ViewIcon } from '../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection } from '../components/Menu';
import {
  Cell,
  getMaxVisibleItems,
  HeaderCell,
  Table,
  TableWrapper,
  Tbody,
  Thead,
} from '../components/Table';
import { TableCellActions } from '../components/TableCellActions';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableOptions } from '../components/TableOptions';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { VirtualizedTable } from '../components/VirtualizedTable';
import { useUserContext } from '../contexts/UserContext';
import { getStipulationDocument } from '../hooks/queries';
import { downloadUrl } from '../utils/apiUtils';
import { formatDate, formatDefaultValue, formatTime } from '../utils/formatUtils';
import * as Styles from './TradeRequestTransactionLogTable.styles';
import { downloadLogs, formatFromUserName } from './transactionLogUtils';
import { TradeTransactionLog } from './types';
import { useTradeRequestTransactionLogsExport } from './useTradeRequestTransactionLogsExport';

const COLUMN_COUNT = 7;

type TradeRequestTransactionLogTableProps = {
  maxVisibleItems?: number;
  data: TradeTransactionLog[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
  total: number;
  tradeRequest: TradeRequestWithSummary;
  allLogIds: TradeRequestLog['id'][];
};

type TradeRequestTransactionLogParams = {
  category: 'outbound' | 'inbound';
  tradeId: string;
};

export const TradeRequestTransactionLogTable = (props: TradeRequestTransactionLogTableProps) => {
  const { allLogIds, data, fetchNextPage, isFetchingNextPage, isLoading, total, tradeRequest } =
    props;

  const { category, tradeId } = useParams<TradeRequestTransactionLogParams>();
  const parentRef = React.useRef<HTMLDivElement | null>(null);
  const { userProfile } = useUserContext();
  const { exportLogs } = useTradeRequestTransactionLogsExport();

  // TODO: Replace once filters for log have been implemented
  const filters: Filter[] = [];
  const updateFilters = () => undefined;

  const handleExportAll = async () => {
    try {
      const data = await exportLogs(allLogIds, tradeRequest, userProfile);

      if (!data) {
        throw new Error('Logs export failed to return any data');
      }

      downloadLogs(data.logs, tradeRequest.pool.name);
    } catch (error) {
      // TODO: Handle error - notify user of failure
    }
  };

  return (
    <TableFilters
      filters={filters}
      onFiltersUpdate={updateFilters}
      path={`/trade-requests/${category}/${tradeId}/transaction-log`}
    >
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({ closeLeftPanel, containerHeight, isLeftPanelOpen, openLeftPanel }) => (
            <>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={updateFilters}
                />
              )}
              <Card $hasFlex>
                <TableToolbar>
                  <TableStats label="logs" totalCount={total} visibleCount={data.length} />
                  <Divider $orientation="vertical" $flexItem />
                  <TableFilterBar
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={updateFilters}
                    isDisabled
                  />
                  <TableOptions>
                    <Menu title="Grid tools">
                      <MenuSection>
                        <MenuItem onClick={handleExportAll}>
                          <ExportLineIcon />
                          <MenuItemText>Export all</MenuItemText>
                        </MenuItem>
                      </MenuSection>
                      <MenuSection>
                        <MenuItem disabled onClick={openLeftPanel}>
                          <FilterLineIcon />
                          <MenuItemText>Filters</MenuItemText>
                        </MenuItem>
                      </MenuSection>
                    </Menu>
                  </TableOptions>
                </TableToolbar>
                <ActivityIndicator contain isActive={isLoading}>
                  <TableWrapper>
                    <Table>
                      <Thead>
                        <Styles.HeaderRow $columnCount={COLUMN_COUNT}>
                          <HeaderCell>Date</HeaderCell>
                          <HeaderCell>Time</HeaderCell>
                          <HeaderCell>From</HeaderCell>
                          <HeaderCell>Organization</HeaderCell>
                          <HeaderCell>Type</HeaderCell>
                          <HeaderCell>Message</HeaderCell>
                          <HeaderCell>Documents</HeaderCell>
                        </Styles.HeaderRow>
                      </Thead>
                      <Tbody>
                        <VirtualizedTable<TradeTransactionLog>
                          fetchNextPage={fetchNextPage}
                          isFetchingNextPage={isFetchingNextPage}
                          items={data}
                          maxVisibleItems={getMaxVisibleItems({
                            containerHeight,
                          })}
                          parentRef={parentRef}
                          renderRow={(isLoaderRow, virtualRow, item) => {
                            if (!item) {
                              return null;
                            }

                            const { category, createdAt, fromCounterparty, message, stipulation } =
                              item;

                            return (
                              <Styles.RowWrapper
                                $height={virtualRow.size}
                                $offsetY={virtualRow.start}
                              >
                                <Styles.Row
                                  key={createdAt.getTime()}
                                  $columnCount={COLUMN_COUNT}
                                  $isClickable={false}
                                >
                                  <Cell>
                                    {formatDate(createdAt, {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                    })}
                                  </Cell>
                                  <Cell>
                                    {formatTime(createdAt, {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: false,
                                    })}
                                  </Cell>
                                  <Cell>
                                    {formatDefaultValue(
                                      formatFromUserName(
                                        tradeRequest.counterparties,
                                        fromCounterparty,
                                        userProfile?.id
                                      )
                                    )}
                                  </Cell>
                                  <Cell>
                                    {formatDefaultValue(fromCounterparty.organization.tradingName)}
                                  </Cell>
                                  <Cell>{category}</Cell>
                                  <Cell>{message}</Cell>
                                  <Cell $isClickable={Boolean(stipulation)}>
                                    {formatDefaultValue(stipulation?.fileName)}
                                    <TableCellActions isActive={Boolean(stipulation)}>
                                      {({ close }) => (
                                        <Styles.RowActionsMenu>
                                          <MenuSection>
                                            <Styles.RowActionsMenuItem
                                              onClick={async () => {
                                                if (!stipulation) {
                                                  return;
                                                }

                                                const url = await getStipulationDocument(
                                                  category === 'Bids'
                                                    ? StipulationType.STIPULATION_QUOTE
                                                    : StipulationType.STIPULATION_ACTION,
                                                  stipulation.id
                                                );

                                                // TODO: Replace with modal - see document viewer
                                                window.open(url, '_blank');

                                                close();
                                              }}
                                            >
                                              <ViewIcon />
                                              <MenuItemText>View document</MenuItemText>
                                            </Styles.RowActionsMenuItem>
                                            <Styles.RowActionsMenuItem
                                              onClick={async () => {
                                                if (!stipulation) {
                                                  return;
                                                }

                                                const url = await getStipulationDocument(
                                                  category === 'Bids'
                                                    ? StipulationType.STIPULATION_QUOTE
                                                    : StipulationType.STIPULATION_ACTION,
                                                  stipulation.id
                                                );

                                                if (!url) {
                                                  return;
                                                }

                                                downloadUrl(stipulation.fileName, url);
                                                close();
                                              }}
                                            >
                                              <ExportLineIcon />
                                              <MenuItemText>Download document</MenuItemText>
                                            </Styles.RowActionsMenuItem>
                                          </MenuSection>
                                        </Styles.RowActionsMenu>
                                      )}
                                    </TableCellActions>
                                  </Cell>
                                </Styles.Row>
                              </Styles.RowWrapper>
                            );
                          }}
                          totalItemsCount={total}
                        />
                      </Tbody>
                    </Table>
                  </TableWrapper>
                </ActivityIndicator>
              </Card>
            </>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};
