import {
  DelinquencyType,
  PortfolioBreakdownDelinquencyType,
  PortfolioBreakdownType,
  PortfolioLoanRateType,
} from '@plus-platform/shared';
import pick from 'lodash/pick';
import React from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { CallbackArgs } from 'victory-core';
import { VictoryScatter } from 'victory-scatter';

import { Breadcrumb, BreadcrumbItem } from '../../../components/Breadcrumb';
import { Heading } from '../../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../../components/Page';
import { Skeleton } from '../../../components/Skeleton';
import { ToggleSwitch } from '../../../components/ToggleSwitch';
import { usePortfolioDelinquentLoanTypesByNotRateQuery } from '../../../hooks/queries';
import { formatPercentage } from '../../../utils/formatUtils';
import { loanRateTypeLabels, orderedLoanRateTypes } from '../../../utils/loanUtils';
import { DELINQUENT_CHART_COLOR_MAPPING } from '../helpers';
import {
  getDelinquentLoanCountsGroupedByRateType,
  LARGEST_BUBBLE_FONT_SIZE,
  LARGEST_BUBBLE_SIZE,
  mapDelinquentLoansCountByNoteRateForChart,
  SMALLEST_BUBBLE_FONT_SIZE,
  SMALLEST_BUBBLE_SIZE,
} from './helpers';
import {
  Emphasis,
  FilterItem,
  FilterItems,
  FilterLabel,
  Filters,
  FiltersGroup,
  FiltersHeader,
  MainContent,
} from './LoanTypesByDefaultPage.styles';
import { ContentWrapper } from './LoanTypesByPage.styles';
import { LoanTypesByPageChart, ScatterChartLabel, ScatterChartPoint } from './LoanTypesByPageChart';
import { LoanTypesChartHeader } from './LoanTypesChartHeader';
import { Summary } from './Summary';

export const LoanTypesByDefaultPage = () => {
  const { data, isLoading } = usePortfolioDelinquentLoanTypesByNotRateQuery();
  const { colors } = useTheme();
  const history = useHistory();

  const [selectedDelinquencyType, setSelectedDelinquencyType] =
    React.useState<PortfolioBreakdownDelinquencyType>(PortfolioBreakdownType.ALL);

  const selectedCountsByDelinquencyType = pick(
    data?.countsByDelinquencyType,
    selectedDelinquencyType
  );

  const countsGroupedByRateType = getDelinquentLoanCountsGroupedByRateType(
    selectedCountsByDelinquencyType
  );

  const labels = Object.entries(loanRateTypeLabels).map(([rateType, label]) => {
    const loansCount = countsGroupedByRateType[rateType] ?? 0;

    return `${label} (${loansCount})`;
  });
  const tickFormat = labels.flatMap((label) => ['', label]);

  const countsByNoteRate = mapDelinquentLoansCountByNoteRateForChart(data?.countsByDelinquencyType);
  const flatData = Object.values(countsByNoteRate).flat();

  const totalDelinquentLoansCount = data?.countsByDelinquencyType.all?.loansCount ?? 0;
  const thirtyDayLoansCount = data?.countsByDelinquencyType.thirtyDays?.loansCount ?? 0;
  const sixtyDayLoansCount = data?.countsByDelinquencyType.sixtyDays?.loansCount ?? 0;
  const ninetyDayLoansCount = data?.countsByDelinquencyType.ninetyDays?.loansCount ?? 0;

  const smallestNoteRate = Math.min(...flatData.map((dataItem) => dataItem.noteRate));
  const largestNoteRate = Math.max(...flatData.map((dataItem) => dataItem.noteRate));
  const largestLoanCount = Math.max(...flatData.map((dataItem) => dataItem.loansCount));

  const chartData = Object.values(pick(countsByNoteRate, selectedDelinquencyType))
    .flat()
    .map((dataItem) => {
      const { delinquencyType, loansCount, noteRate, rateType } = dataItem;
      const loanCountRatio = largestLoanCount ? loansCount / largestLoanCount : 0;
      const labelColor =
        delinquencyType === DelinquencyType.THIRTY_DAYS ? colors.statusBlack : colors.white;

      return {
        x: noteRate * 100,
        y: orderedLoanRateTypes.indexOf(rateType) * 2 + 1,
        size: Math.max(LARGEST_BUBBLE_SIZE * loanCountRatio, SMALLEST_BUBBLE_SIZE),
        labelFontSize: Math.max(
          LARGEST_BUBBLE_FONT_SIZE * loanCountRatio,
          SMALLEST_BUBBLE_FONT_SIZE
        ),
        fill: DELINQUENT_CHART_COLOR_MAPPING[delinquencyType],
        labelColor,
        rateType,
        loansCount,
        noteRate,
        delinquencyType,
      };
    });

  const isDelinquencyToggleSelected = (type: PortfolioBreakdownDelinquencyType) => {
    return selectedDelinquencyType === type;
  };

  const toggleSelectedDelinquencyType = (type: PortfolioBreakdownDelinquencyType) => {
    setSelectedDelinquencyType(type);
  };

  const goToDetails = (
    delinquencyType: DelinquencyType,
    rateType: PortfolioLoanRateType,
    noteRate: number
  ) => {
    history.push(
      `/home/breakdown/delinquent/${delinquencyType}/types/${rateType}/rates/${noteRate}`
    );
  };

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>Loans by type by default</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem title="Loans by type by default" />
            </Breadcrumb>
          </div>

          {data && (
            <Summary
              flagsCount={data.flagsCount}
              alertsCount={data.alertsCount}
              warningsCount={data.warningsCount}
              totalLoansCount={data.totalLoansCount}
            />
          )}
        </PageHeaderContent>
      </PageHeader>
      <PageContent>
        {isLoading && <Skeleton height={307} />}

        {!isLoading && (
          <ContentWrapper>
            <LoanTypesChartHeader />
            <MainContent>
              <Filters>
                <FiltersHeader>
                  <Heading $size="medium">Filters</Heading>
                </FiltersHeader>
                <FiltersGroup>
                  <Heading $size="small">Default</Heading>
                  {/* TODO: For now, we show only 3 delinquency groups, for consistency with the rest of the application.
                  When other parts of the UI are updated to include all 4 delinquency groups
                  make sure to update this section as well and add a toggle for 90 plus days group. */}
                  <FilterItems>
                    <FilterItem>
                      <FilterLabel>
                        All <Emphasis>{totalDelinquentLoansCount}</Emphasis>
                      </FilterLabel>
                      <ToggleSwitch
                        $toggledColor={colors.statusTurquoise}
                        checked={isDelinquencyToggleSelected(PortfolioBreakdownType.ALL)}
                        onChange={() => toggleSelectedDelinquencyType(PortfolioBreakdownType.ALL)}
                      />
                    </FilterItem>
                    <FilterItem>
                      <FilterLabel>
                        30-day{' '}
                        <Emphasis>
                          {thirtyDayLoansCount} /{' '}
                          {formatPercentage(thirtyDayLoansCount / totalDelinquentLoansCount, 0)}
                        </Emphasis>
                      </FilterLabel>
                      <ToggleSwitch
                        $toggledColor={colors.statusYellow}
                        checked={isDelinquencyToggleSelected(DelinquencyType.THIRTY_DAYS)}
                        onChange={() => toggleSelectedDelinquencyType(DelinquencyType.THIRTY_DAYS)}
                      />
                    </FilterItem>
                    <FilterItem>
                      <FilterLabel>
                        60-day{' '}
                        <Emphasis>
                          {sixtyDayLoansCount} /{' '}
                          {formatPercentage(sixtyDayLoansCount / totalDelinquentLoansCount, 0)}
                        </Emphasis>
                      </FilterLabel>
                      <ToggleSwitch
                        $toggledColor={colors.statusOrange}
                        checked={isDelinquencyToggleSelected(DelinquencyType.SIXTY_DAYS)}
                        onChange={() => toggleSelectedDelinquencyType(DelinquencyType.SIXTY_DAYS)}
                      />
                    </FilterItem>
                    <FilterItem>
                      <FilterLabel>
                        90-day{' '}
                        <Emphasis>
                          {ninetyDayLoansCount} /{' '}
                          {formatPercentage(ninetyDayLoansCount / totalDelinquentLoansCount, 0)}
                        </Emphasis>
                      </FilterLabel>
                      <ToggleSwitch
                        $toggledColor={colors.statusRed}
                        checked={isDelinquencyToggleSelected(DelinquencyType.NINETY_DAYS)}
                        onChange={() => toggleSelectedDelinquencyType(DelinquencyType.NINETY_DAYS)}
                      />
                    </FilterItem>
                  </FilterItems>
                </FiltersGroup>
              </Filters>
              <LoanTypesByPageChart
                tickFormat={tickFormat}
                width={1100}
                padding={{
                  top: 16,
                  left: 140,
                  right: 0,
                  bottom: 48,
                }}
                domain={{ x: [smallestNoteRate * 100, largestNoteRate * 100] }}
              >
                <VictoryScatter
                  name="scatter"
                  data={chartData}
                  labels={({ datum }) => datum.loansCount}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.fill,
                    },
                    labels: {
                      fontSize: ({ datum }) => datum.labelFontSize,
                      fill: ({ datum }: CallbackArgs) => datum.labelColor,
                    },
                  }}
                  labelComponent={<ScatterChartLabel />}
                  dataComponent={<ScatterChartPoint />}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onClick: (_, { datum }) =>
                          goToDetails(datum.delinquencyType, datum.rateType, datum.noteRate),
                      },
                    },
                    {
                      target: 'labels',
                      eventHandlers: {
                        onClick: (_, { datum }) =>
                          goToDetails(datum.delinquencyType, datum.rateType, datum.noteRate),
                      },
                    },
                  ]}
                />
              </LoanTypesByPageChart>
            </MainContent>
          </ContentWrapper>
        )}
      </PageContent>
    </Page>
  );
};
