import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from './types';

export enum CurrentFirstMortgageHolderType {
  FNM = 'FNM',
  FRE = 'FRE',
  Other = 'Other',
  Unknown = 'Unknown',
}

export enum RefinanceCashOutDeterminationType {
  CashOut = 'CashOut',
  LimitedCashOut = 'LimitedCashOut',
  NoCashOut = 'NoCashOut',
  Unknown = 'Unknown',
}

enum RefinanceInitiatorType {
  BorrowerInitiated = 'BorrowerInitiated',
  LenderInitiated = 'LenderInitiated',
  Other = 'Other',
}

export enum RefinancePrimaryPurposeType {
  AssetAcquisition = 'AssetAcquisition',
  CapitalizedInterestTaxesInsuranceOrFees = 'CapitalizedInterestTaxesInsuranceOrFees',
  Cash = 'Cash',
  Convenience = 'Convenience',
  DebtConsolidation = 'DebtConsolidation',
  Education = 'Education',
  EquityBuyout = 'EquityBuyout',
  HomeImprovement = 'HomeImprovement',
  InterestRateReduction = 'InterestRateReduction',
  Medical = 'Medical',
  MortgageTermReduction = 'MortgageTermReduction',
  Other = 'Other',
  PayoffLeaseholdInterest = 'PayoffLeaseholdInterest',
  PrimaryLienPayoff = 'PrimaryLienPayoff',
  SecondaryLienPayoff = 'SecondaryLienPayoff',
  SpecialPurpose = 'SpecialPurpose',
  UnsecuredLienPayoff = 'UnsecuredLienPayoff',
  Unspecified = 'Unspecified',
}

enum StateRefinanceProgramType {
  ConsolidationExtensionAndModificationAgreement = 'ConsolidationExtensionAndModificationAgreement',
  Other = 'Other',
  TexasHomeEquity = 'TexasHomeEquity',
}

export type Refinance = MISMOEntity &
  Partial<{
    currentFirstMortgageHolderType: CurrentFirstMortgageHolderType;
    currentFirstMortgageHolderTypeOtherDescription: MISMOString;
    estimatedCashOutAmount: MISMOAmount;
    nonStructuralAlterationsConventionalAmount: MISMOAmount;
    refinanceAssetsAcquiredAmount: MISMOAmount;
    refinanceCashOutAmount: MISMOAmount;
    refinanceCashOutDeterminationType: RefinanceCashOutDeterminationType;
    refinanceCashOutPercent: MISMOPercent;
    refinanceDebtReductionAmount: MISMOAmount;
    refinanceHomeImprovementAmount: MISMOAmount;
    refinanceInitiatorType: RefinanceInitiatorType;
    refinanceInitiatorTypeOtherDescription: MISMOString;
    refinancePrimaryPurposeType: RefinancePrimaryPurposeType;
    refinancePrimaryPurposeTypeOtherDescription: MISMOString;
    refinanceSameLenderIndicator: MISMOIndicator;
    secondaryFinancingRefinanceIndicator: MISMOIndicator;
    stateRefinanceProgramType: StateRefinanceProgramType;
    stateRefinanceProgramTypeOtherDescription: MISMOString;
    structuralAlterationsConventionalAmount: MISMOAmount;
    totalPriorLienPayoffAmount: MISMOAmount;
    totalPriorLienPrepaymentPenaltyAmount: MISMOAmount;
  }>;
