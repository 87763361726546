import styled from 'styled-components/macro';

export const VisualisationToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const SelectWrapper = styled.div`
  width: 100%;
`;

export const ChoiceForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;
