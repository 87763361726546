import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { baseButtonStyles } from '../../components/Button.styles';

export const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  gap: 4px;
`;

export const NavigationLink = styled(NavLink)<{ $isActive: boolean; $width?: number }>(
  ({ $isActive, $width, theme }) => css`
    ${baseButtonStyles}
    ${theme.typography.headings.headingRegular03}

    justify-content: flex-start;
    width: ${$width ? `${$width}px` : 'auto'};
    height: 32px;
    padding-left: 8px;
    padding-right: ${$width ? 0 : 12}px;
    text-decoration: none;
    color: ${theme.colors.textNavigation};

    &:disabled {
      opacity: 0.2;
    }

    &:hover,
    &:focus-visible {
      background: ${theme.colors.backgroundNavigationHover};
      color: ${theme.colors.textNavigation};
    }

    &:active {
      background: ${theme.colors.white30};
      color: ${theme.colors.textNavigation};
    }

    ${$isActive &&
    css`
      ${theme.typography.headings.headingMedium03}

      background: ${theme.colors.backgroundNavigationActive};
      color: ${theme.colors.textNavigationActive};
    `}
  `
);
