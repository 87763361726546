import { LoanChatMessage } from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import { useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE = 10;

export const useLoanChatMessagesQuery = (
  loanNumber: string,
  messagesPerPage = DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE
) => {
  return useInfiniteQuery(
    [QueryKeys.LOAN_CHAT_MESSAGES, loanNumber, messagesPerPage],
    async ({ pageParam = 0 }) => {
      const query = new URLSearchParams({
        limit: String(messagesPerPage),
        offset: String(pageParam),
      });
      const url = makeApiUrl(`loans/${loanNumber}/messages?${query}`);
      const messages = (await getRequestWithAuth<LoanChatMessage[]>(url)) || [];

      return messages;
    },
    {
      getNextPageParam(lastPage, pages) {
        return lastPage.length < messagesPerPage ? undefined : sumBy(pages, 'length');
      },
    }
  );
};
