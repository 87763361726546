import { MISMOEntity, MISMOSequenceNumber, MISMOString } from './types';

enum BoundaryDirectionType {
  East = 'East',
  North = 'North',
  NorthEast = 'NorthEast',
  NorthWest = 'NorthWest',
  Other = 'Other',
  South = 'South',
  SouthEast = 'SouthEast',
  SouthWest = 'SouthWest',
  West = 'West',
}

enum BoundaryType {
  Alley = 'Alley',
  Other = 'Other',
  Street = 'Street',
  Waterway = 'Waterway',
}

export type Boundary = MISMOEntity &
  Partial<{
    boundaryDirectionType: BoundaryDirectionType;
    boundaryDirectionTypeOtherDescription: MISMOString;
    boundaryGeospatialCoordinatesDescription: MISMOString;
    boundaryType: BoundaryType;
    boundaryTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
