import {
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum SiteUtilityAlternativeEnergySourceType {
  Biomass = 'Biomass',
  Ethanol = 'Ethanol',
  Geothermal = 'Geothermal',
  Hydrogen = 'Hydrogen',
  Other = 'Other',
  Propane = 'Propane',
  RecycledOils = 'RecycledOils',
  Solar = 'Solar',
  Wind = 'Wind',
}

export enum SiteUtilityOwnershipType {
  NonPublic = 'NonPublic',
  Public = 'Public',
  Unknown = 'Unknown',
}

enum SiteUtilityType {
  Electricity = 'Electricity',
  Gas = 'Gas',
  Other = 'Other',
  RenewableEnergyBiomass = 'RenewableEnergyBiomass',
  RenewableEnergyHydropower = 'RenewableEnergyHydropower',
  RenewableEnergyWind = 'RenewableEnergyWind',
  SanitarySewer = 'SanitarySewer',
  StormSewer = 'StormSewer',
  Water = 'Water',
}

export type SiteUtility = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    siteUndergroundUtilitiesIndicator: MISMOIndicator;
    siteUtilityAlternativeEnergySourceType: SiteUtilityAlternativeEnergySourceType;
    siteUtilityAlternativeEnergySourceTypeOtherDescription: MISMOString;
    siteUtilityNonPublicOwnershipDescription: MISMOString;
    siteUtilityNotTypicalDescription: MISMOString;
    siteUtilityOwnershipType: SiteUtilityOwnershipType;
    siteUtilityType: SiteUtilityType;
    siteUtilityTypeOtherDescription: MISMOString;
    utilityTypicalIndicator: MISMOIndicator;
  }>;
