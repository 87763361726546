import {
  AggregatedLoan,
  DelinquencySummary,
  Loan,
  PortfolioSummary,
  TradeRequest,
} from '.';
import { Identifier } from './global';

export enum PoolAttributeType {
  ATTRIBUTE_POOL = 'ATTRIBUTE_POOL',
}

export type PoolUpdate = {
  loanIds: Loan['id'][];
};

export type PoolCreation = {
  name: string;
  loanIds: Identifier[];
};

export type Pool = PoolCreation & {
  id: Identifier;
};

export type AggregatedPool = Pool & {
  loans: AggregatedLoan[];
};

export type PoolWithSummary = Pool & {
  summary: PortfolioSummary;
};

export type PoolDetail = PoolWithSummary;

export type PoolsSummary = {
  totalRemittanceAmount: number;
  totalUPBAmount: number;
  totalLoansCount: number;
  totalLoansInDefault: DelinquencySummary;
  totalLoansWeightedAverageCoupon: number;
  totalLoansWeightedAverageLTV: number;
  pools: PoolWithSummary[];
};

export type PoolWithTradeRequest = Pool & {
  tradeRequest?: TradeRequest;
};

export type PoolDetailWithTradeRequest = PoolDetail & {
  tradeRequest?: TradeRequest;
};

export type LoanPool = Pool & {
  summary?: PortfolioSummary;
  tradeRequest?: TradeRequest;
};
