import styled, { css } from 'styled-components/macro';

export type BodyTextButtonStyleProps = {
  $size?: 'large' | 'medium' | 'small' | 'xSmall';
};

export const BodyTextButton = styled.button<
  BodyTextButtonStyleProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  ({ $size = 'medium', theme }) => css`
    ${() => {
      if ($size === 'large') {
        return theme.typography.bodyText.bodyMedium01;
      }
      if ($size === 'medium') {
        return theme.typography.bodyText.bodyMedium02;
      }
      if ($size === 'small') {
        return theme.typography.bodyText.bodyMedium03;
      }
      if ($size === 'xSmall') {
        return theme.typography.bodyText.bodyMedium04;
      }
      return theme.typography.bodyText.bodyMedium02;
    }}
    display: inline-flex;
    text-decoration: none;
    padding: 0 0.25em;
    margin: 0 -0.25em;
    border-radius: 0.25em;
    color: ${theme.colors.anchorColorHover};
    background-color: ${theme.colors.anchorBGColor};
    border: 0;
    cursor: pointer;
    line-height: inherit;

    &:visited {
      background-color: ${theme.colors.anchorBGColor};
    }

    &:hover {
      background-color: ${theme.colors.anchorBGColorHover};
    }

    &:focus-visible {
      color: ${theme.colors.anchorColorHover};
      background-color: ${theme.colors.anchorBGColorHover};
      outline: none;
    }

    &:active {
      color: ${theme.colors.anchorColorActive};
      background-color: ${theme.colors.anchorBGColorActive};
    }
  `
);
