import React from 'react';

export const NotificationsFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 18.3V16.5H6V10.2C6 8.955 6.41667 7.84875 7.25 6.88125C8.08333 5.91375 9.16667 5.28 10.5 4.98V4.35C10.5 3.975 10.6458 3.65625 10.9375 3.39375C11.2292 3.13125 11.5833 3 12 3C12.4167 3 12.7708 3.13125 13.0625 3.39375C13.3542 3.65625 13.5 3.975 13.5 4.35V4.98C14.8333 5.28 15.9167 5.91375 16.75 6.88125C17.5833 7.84875 18 8.955 18 10.2V16.5H20V18.3H4ZM12 21C11.45 21 10.9792 20.8238 10.5875 20.4713C10.1958 20.1188 10 19.695 10 19.2H14C14 19.695 13.8042 20.1188 13.4125 20.4713C13.0208 20.8238 12.55 21 12 21Z"
        fill="currentColor"
      />
    </svg>
  );
};
