import React from 'react';

export const AddNoteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.05131 17.6158C5.7622 17.6158 5.51471 17.5128 5.30882 17.3069C5.10294 17.1011 5 16.8536 5 16.5645V6.05131C5 5.7622 5.10294 5.51471 5.30882 5.30882C5.51471 5.10294 5.7622 5 6.05131 5H16.5645C16.8536 5 17.1011 5.10294 17.3069 5.30882C17.5128 5.51471 17.6158 5.7622 17.6158 6.05131V11.816C17.4522 11.7226 17.2799 11.6466 17.0989 11.5882C16.9178 11.5298 16.7397 11.4831 16.5645 11.4481V6.05131H6.05131V16.5645H11.4656C11.5123 16.7514 11.565 16.9334 11.6237 17.1106C11.6823 17.2879 11.7523 17.4563 11.8335 17.6158H6.05131ZM6.05131 15.7409V16.5645V6.05131V11.4481V11.378V15.7409ZM15.7322 19C14.8269 19 14.053 18.6758 13.4105 18.0275C12.768 17.3792 12.4468 16.6112 12.4468 15.7234C12.4468 14.8072 12.768 14.0262 13.4103 13.3804C14.0527 12.7347 14.8296 12.4118 15.7409 12.4118C16.6404 12.4118 17.4143 12.7347 18.0626 13.3804C18.7109 14.0262 19.035 14.8072 19.035 15.7234C19.035 16.6112 18.7109 17.3792 18.0626 18.0275C17.4143 18.6758 16.6375 19 15.7322 19ZM15.4781 17.9487H16.0563V16.0213H17.9837V15.4431H16.0563V13.5156H15.4781V15.4431H13.5507V16.0213H15.4781V17.9487Z"
        fill="currentColor"
      />
    </svg>
  );
};
