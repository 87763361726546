import { Loan, LoanChatMessage, LoanDetails } from '@plus-platform/shared';
import { timingFunctions } from 'polished';
import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import plusLogoPath from '../assets/logo.svg';
import { ActivityIndicator } from '../components/ActivityIndicator';
import { DefaultButton } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { ChevronDownIcon, ChevronUpIcon, CloseLineIcon } from '../components/icons';
import { Input } from '../components/Input';
import { CloseButton } from '../components/Popover.styles';
import { useUserContext } from '../contexts/UserContext';
import { DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE } from '../hooks/queries/useLoanChatMessagesQuery';
import { useReverseVirtual } from '../hooks/useReverseVirtual';
import { fontFamily } from '../styles/typography';
import { DATE_WITH_TIME_OPTIONS, EMPTY_VALUE, formatDate } from '../utils/formatUtils';
import { useLoanChatMessages } from './useMessages';

const NUMBER_OF_PAGES_TO_OVERSCAN = 2;

const ServicerChatWrapper = styled.div<{ isExpanded: boolean }>`
  z-index: 2;
  position: fixed;
  width: 330px;
  bottom: 0;
  right: 24px;
  transition: transform 666ms ${timingFunctions('easeOutExpo')};
  transform: ${(props) => (props.isExpanded ? 'none' : 'translateY(calc(100% - 40px))')};
`;

const ChatHeader = styled.header(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    height: 40px;
    background-color: ${theme.colors.greenMain};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: ${theme.shadows.chatHeaderShadow};
  `
);

const ChatContent = styled.div(
  ({ theme }) => css`
    padding: 16px;
    background-color: ${theme.colors.white};
    border: 2px solid ${theme.colors.greenMain};
    height: 400px;
    overflow-y: auto;
  `
);

const VirtualContent = styled.div`
  position: relative;
`;

const ChatMessage = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  & + & {
    padding-top: 32px;
  }
`;

const MessageDate = styled.p(
  ({ theme }) => css`
    text-align: center;
    margin: 0;
    padding: 0 0 16px 0;
    font-size: 11px;
    font-weight: 400;
    color: ${theme.colors.darkBlue};
    font-family: ${theme.typography.fontFamily.display};
    text-transform: uppercase;
  `
);

const Message = styled.div`
  display: flex;
`;

const MessageText = styled.div`
  padding: 12px;
  border-radius: 6px;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: ${fontFamily.text};
`;

const IncomingMessageText = styled(MessageText)(
  ({ theme }) => css`
    background-color: ${theme.colors.greyLight};
    color: ${theme.colors.darkBlue};
    margin-left: 12px;
  `
);

const OutgoingMessageText = styled(MessageText)(
  ({ theme }) => css`
    background-color: ${theme.colors.darkBlue};
    color: ${theme.colors.white};
    margin-left: auto;
    margin-right: 12px;
  `
);

const MessageImage = styled.img`
  align-self: flex-start;
  width: 28px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const ExpandButton = styled(DefaultButton)`
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
`;

const ChatTitle = styled.h3(
  ({ theme }) => css`
    color: ${theme.colors.white};
    font-size: 15px;
    font-weight: 700;
    font-family: ${theme.typography.fontFamily.display};
  `
);

const SendForm = styled.form`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 2px;
  align-items: center;
`;

const MessageInput = styled(Input)`
  height: auto;
  padding: 8px;
  margin-right: 2px;
`;

type ServicerChatProps = {
  loanId: Loan['id'];
  loanNumber: LoanDetails['loanNumber'];
  onClose(): void;
};

export const ServicerChat = ({ loanId, loanNumber, onClose }: ServicerChatProps) => {
  const { userProfile } = useUserContext();
  const [isServicerChatExpanded, setIsServicerChatExpanded] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const theme = useTheme();

  const { isLoading, loadMore, messages, send } = useLoanChatMessages({
    loanId,
    loanNumber,
  });
  const { onContentScroll, totalSize, virtualItems } = useReverseVirtual({
    size: messages.length,
    parentRef: contentRef,
    overscan: NUMBER_OF_PAGES_TO_OVERSCAN * DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE,
    onLoadMore: loadMore,
  });

  const isOutgoingMessage = (message: LoanChatMessage) => {
    return userProfile && String(userProfile.id) === String(message.fromId);
  };

  const sendMessage = () => {
    send({ message });
    setMessage('');
  };

  return (
    <ServicerChatWrapper isExpanded={isServicerChatExpanded}>
      <ChatHeader>
        <ChatTitle>Plus chat</ChatTitle>
        <Buttons>
          <ExpandButton
            onClick={() => setIsServicerChatExpanded(!isServicerChatExpanded)}
            type="button"
          >
            {isServicerChatExpanded && (
              <ChevronDownIcon
                color={theme.colors.white}
                style={{ marginTop: '-2px' }}
                width="32px"
              />
            )}

            {!isServicerChatExpanded && (
              <ChevronUpIcon color={theme.colors.white} style={{ marginTop: '1px' }} width="32px" />
            )}
          </ExpandButton>
          <CloseButton onClick={onClose} type="button">
            <CloseLineIcon color={theme.colors.white} width="24px" />
          </CloseButton>
        </Buttons>
      </ChatHeader>
      <ActivityIndicator isActive={isLoading}>
        <ChatContent onScroll={onContentScroll} ref={contentRef}>
          <VirtualContent style={{ height: `${totalSize}px` }}>
            {virtualItems.map((item) => {
              const message = messages[item.index];

              return (
                <ChatMessage
                  key={message.id}
                  ref={item.measureRef}
                  style={{ transform: `translateY(${item.start}px)` }}
                >
                  <MessageDate>
                    {message.createdAt
                      ? formatDate(new Date(message.createdAt), DATE_WITH_TIME_OPTIONS)
                      : EMPTY_VALUE}
                  </MessageDate>

                  <Message>
                    {isOutgoingMessage(message) && (
                      <React.Fragment>
                        <OutgoingMessageText>{message.message}</OutgoingMessageText>
                        <MessageImage alt="Plus logo" src={plusLogoPath} />
                      </React.Fragment>
                    )}

                    {!isOutgoingMessage(message) && (
                      <React.Fragment>
                        <MessageImage alt="Plus logo" src={plusLogoPath} />
                        <IncomingMessageText>{message.message}</IncomingMessageText>
                      </React.Fragment>
                    )}
                  </Message>
                </ChatMessage>
              );
            })}
          </VirtualContent>
        </ChatContent>
      </ActivityIndicator>
      <SendForm
        onSubmit={(event) => {
          event.preventDefault();
          if (message.trim()) {
            sendMessage();
          }
        }}
      >
        <MessageInput onChange={(event) => setMessage(event.target.value)} value={message} />
        <ButtonLoader isLoading={isLoading}>Send</ButtonLoader>
      </SendForm>
    </ServicerChatWrapper>
  );
};
