import { useQuery } from 'react-query';

import { getLoanDocuments } from '../../utils/loanUtils';
import { QueryKeys } from './types';

type UseLoanDocumentsQueryProps = {
  loanNumber: string;
  searchQuery?: string;
};

export const useLoanDocumentsQuery = (props: UseLoanDocumentsQueryProps) => {
  return useQuery(
    [QueryKeys.LOAN_DOCUMENTS, props.loanNumber, props.searchQuery],
    () => getLoanDocuments(props.loanNumber, props.searchQuery),
    { enabled: Boolean(props.loanNumber) }
  );
};
