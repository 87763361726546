import React from 'react';
import { useParams } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Button } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { Popup, PopupContent, PopupFooter, PopupHeader } from '../components/Popup';
import { Stack } from '../components/Stack';
import { useGetPoolWithTradeRequestQuery, useLoanPoolDeleteMutation } from '../hooks/queries';
import * as Styled from './DeletePoolPopup.styles';

type PoolDetailPageRouteParams = {
  poolId: string;
};

type CreatePoolPopupProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

export const DeletePoolPopup = ({ onCancel, onSubmit }: CreatePoolPopupProps) => {
  const { poolId } = useParams<PoolDetailPageRouteParams>();

  const { data: pool, isLoading: isPoolLoading } = useGetPoolWithTradeRequestQuery(poolId);

  const { isLoading: isNewPoolDeleting, mutateAsync: deletePool } = useLoanPoolDeleteMutation();

  const isLoading = isPoolLoading || isNewPoolDeleting;
  const isPoolAlreadyTraded = Boolean(pool?.tradeRequest);

  return (
    <Popup
      style={{
        width: '500px',
      }}
    >
      <PopupHeader title="Delete pool" />
      <PopupContent>
        <ActivityIndicator contain isActive={isLoading}>
          {isPoolAlreadyTraded && (
            <Styled.ErrorMessage>
              The selected pool is already assigned to a Trade Request
            </Styled.ErrorMessage>
          )}
          {!isPoolAlreadyTraded && <Styled.Text>Are you sure to delete this pool?</Styled.Text>}
        </ActivityIndicator>
      </PopupContent>
      <PopupFooter>
        <Stack>
          <Button
            $color="tertiary"
            $variant="outlined"
            disabled={isNewPoolDeleting}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <ButtonLoader
            disabled={isLoading || isPoolAlreadyTraded}
            isLoading={isLoading}
            onClick={async () => {
              try {
                await deletePool(poolId, {
                  onSuccess: () => {
                    onSubmit();
                  },
                });
              } catch (error) {
                // TODO: display error message once error states are defined
              }
            }}
          >
            Delete
          </ButtonLoader>
        </Stack>
      </PopupFooter>
    </Popup>
  );
};
