import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export enum CoolingSystemType {
  Centralized = 'Centralized',
  Individual = 'Individual',
  Other = 'Other',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type CoolingSystem = MISMOEntity &
  Partial<{
    adequateIndicator: MISMOIndicator;
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    coolingSystemDescription: MISMOString;
    coolingSystemPrimaryIndicator: MISMOIndicator;
    coolingSystemType: CoolingSystemType;
    coolingSystemTypeOtherDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
    systemPermanentIndicator: MISMOIndicator;
  }>;
