import { OutboundTradeRequestWithSummary } from '@plus-platform/shared';

import { formatNameWithInitial } from '../../tradeRequestsUtils';
import { TradeRequestDirectMessaging } from './TradeRequestDirectMessaging';

type OutboundTradeRequestDirectMessagingProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
  tradeCounterpartyId: string;
};

export const OutboundTradeRequestDirectMessaging = (
  props: OutboundTradeRequestDirectMessagingProps
) => {
  const { tradeCounterpartyId, tradeRequest } = props;
  const tradeCounterparty = tradeRequest.counterparties.find(
    (counterparty) => String(counterparty.id) === tradeCounterpartyId
  );

  const counterparty = {
    id: tradeCounterparty?.user.id,
    userName: formatNameWithInitial(
      tradeCounterparty?.user.lastName ?? '',
      tradeCounterparty?.user.firstName
    ),
    organizationName: tradeCounterparty?.user?.organization.tradingName ?? '',
  };

  return (
    <TradeRequestDirectMessaging
      recipient={counterparty}
      tradeCounterpartyId={tradeCounterpartyId}
      tradeRequest={tradeRequest}
    />
  );
};
