import { Borrower } from '@plus-platform/shared';
import React, { useContext } from 'react';
import { UseQueryResult } from 'react-query';

import {
  useGetBorrowerCreditScoresQuery,
  useGetBorrowerVerificationsQuery,
  useGetLoanAmortizationQuery,
  useGetNearbyPropertiesQuery,
  useGetPropertyScoresQuery,
  useGetPropertyValuationHistoryQuery,
  useLoanAssetLedgerQuery,
  useLoanDocumentsQuery,
  useLoanEventLogQuery,
  useTradeRequestDataLoanAmortizationDetailsQuery,
  useTradeRequestDataLoanAssetLedgerQuery,
  useTradeRequestDataLoanBorrowerCreditScoresQuery,
  useTradeRequestDataLoanBorrowerVerificationsQuery,
  useTradeRequestDataLoanDocumentsQuery,
  useTradeRequestDataLoanEventLogQuery,
  useTradeRequestDataNearbyPropertiesQuery,
  useTradeRequestDataPropertyScoresQuery,
  useTradeRequestDataPropertyValuationsQuery,
} from '../hooks/queries';
import { useLoanContext } from './context';

const QueryDataContext = React.createContext<UseQueryResult | undefined>(undefined);

type QueryDataProviderProps<QueryProps> = {
  queryProps: QueryProps;
  children: React.ReactNode;
};

const createQueryDataProvider =
  <QueryProps,>(query: (props: QueryProps) => UseQueryResult) =>
  (props: QueryDataProviderProps<QueryProps>) => {
    const queryData = query(props.queryProps);

    return (
      <QueryDataContext.Provider value={queryData}>{props.children}</QueryDataContext.Provider>
    );
  };

const createQueryDataConsumerHook =
  <QueryDataReturn extends UseQueryResult>() =>
  () => {
    const value = useContext(QueryDataContext);

    if (!value) {
      throw new Error('useQueryData must be used within QueryDataProvider');
    }

    return value as QueryDataReturn;
  };

type DataProviderProps = {
  children: React.ReactNode;
};

const LoanDocumentsProvider = createQueryDataProvider(useLoanDocumentsQuery);
const TradeRequestDataLoanDocumentsProvider = createQueryDataProvider(
  useTradeRequestDataLoanDocumentsQuery
);

export const useLoanDocuments =
  createQueryDataConsumerHook<ReturnType<typeof useLoanDocumentsQuery>>();

type LoanDocumentsDataProps = DataProviderProps & {
  searchQuery?: string;
};

export const LoanDocumentsData = (props: LoanDocumentsDataProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanDocumentsProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
          ...(props.searchQuery && { searchQuery: props.searchQuery }),
        }}
      >
        {props.children}
      </TradeRequestDataLoanDocumentsProvider>
    );
  }

  return (
    <LoanDocumentsProvider
      queryProps={{
        loanNumber: value.loanNumber,
        ...(props.searchQuery && { searchQuery: props.searchQuery }),
      }}
    >
      {props.children}
    </LoanDocumentsProvider>
  );
};

const LoanAmortizationProvider = createQueryDataProvider(useGetLoanAmortizationQuery);
const TradeRequestDataLoanAmortizationProvider = createQueryDataProvider(
  useTradeRequestDataLoanAmortizationDetailsQuery
);

export const useLoanAmortization =
  createQueryDataConsumerHook<ReturnType<typeof useGetLoanAmortizationQuery>>();

export const LoanAmortizationData = (props: DataProviderProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanAmortizationProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {props.children}
      </TradeRequestDataLoanAmortizationProvider>
    );
  }

  return (
    <LoanAmortizationProvider
      queryProps={{
        loanNumber: value.loanNumber,
      }}
    >
      {props.children}
    </LoanAmortizationProvider>
  );
};

const LoanPropertyValuationsProvider = createQueryDataProvider(useGetPropertyValuationHistoryQuery);
const TradeRequestDataLoanPropertyValuationsProvider = createQueryDataProvider(
  useTradeRequestDataPropertyValuationsQuery
);

export const useLoanPropertyValuations =
  createQueryDataConsumerHook<ReturnType<typeof useGetPropertyValuationHistoryQuery>>();

export const LoanPropertyValuationsData = (props: DataProviderProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanPropertyValuationsProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {props.children}
      </TradeRequestDataLoanPropertyValuationsProvider>
    );
  }

  return (
    <LoanPropertyValuationsProvider
      queryProps={{
        loanNumber: value.loanNumber,
      }}
    >
      {props.children}
    </LoanPropertyValuationsProvider>
  );
};

const LoanPropertyScoresProvider = createQueryDataProvider(useGetPropertyScoresQuery);

const TradeRequestDataLoanPropertyScoresProvider = createQueryDataProvider(
  useTradeRequestDataPropertyScoresQuery
);

export const useLoanPropertyScores =
  createQueryDataConsumerHook<ReturnType<typeof useGetPropertyScoresQuery>>();

export const LoanPropertyScoresData = (props: DataProviderProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanPropertyScoresProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {props.children}
      </TradeRequestDataLoanPropertyScoresProvider>
    );
  }

  return (
    <LoanPropertyScoresProvider
      queryProps={{
        loanNumber: value.loanNumber,
      }}
    >
      {props.children}
    </LoanPropertyScoresProvider>
  );
};

export const LoanNearbyPropertiesProvider = createQueryDataProvider(useGetNearbyPropertiesQuery);
const TradeRequestDataLoanNearbyPropertiesProvider = createQueryDataProvider(
  useTradeRequestDataNearbyPropertiesQuery
);

export const useLoanNearbyProperties =
  createQueryDataConsumerHook<ReturnType<typeof useGetNearbyPropertiesQuery>>();

export const LoanNearbyPropertiesData = (props: DataProviderProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanNearbyPropertiesProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {props.children}
      </TradeRequestDataLoanNearbyPropertiesProvider>
    );
  }

  return (
    <LoanNearbyPropertiesProvider
      queryProps={{
        loanNumber: value.loanNumber,
      }}
    >
      {props.children}
    </LoanNearbyPropertiesProvider>
  );
};

const LoanAssetLedgerProvider = createQueryDataProvider(useLoanAssetLedgerQuery);
const TradeRequestDataLoanAssetLedgerProvider = createQueryDataProvider(
  useTradeRequestDataLoanAssetLedgerQuery
);

export const useLoanAssetLedger =
  createQueryDataConsumerHook<ReturnType<typeof useLoanAssetLedgerQuery>>();

export const LoanAssetLedgerData = (props: DataProviderProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanAssetLedgerProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {props.children}
      </TradeRequestDataLoanAssetLedgerProvider>
    );
  }

  return (
    <LoanAssetLedgerProvider queryProps={value.loanNumber}>
      {props.children}
    </LoanAssetLedgerProvider>
  );
};

const LoanEventLogProvider = createQueryDataProvider(useLoanEventLogQuery);
const TradeRequestDataLoanEventLogProvider = createQueryDataProvider(
  useTradeRequestDataLoanEventLogQuery
);

export const useLoanEventLog =
  createQueryDataConsumerHook<ReturnType<typeof useLoanEventLogQuery>>();

export const LoanEventLogData = (props: DataProviderProps) => {
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanEventLogProvider
        queryProps={{
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {props.children}
      </TradeRequestDataLoanEventLogProvider>
    );
  }

  return (
    <LoanEventLogProvider queryProps={value.loanNumber}>{props.children}</LoanEventLogProvider>
  );
};

const BorrowerCreditScoresProvider = createQueryDataProvider(useGetBorrowerCreditScoresQuery);
const TradeRequestDataLoanBorrowerCreditScoresProvider = createQueryDataProvider(
  useTradeRequestDataLoanBorrowerCreditScoresQuery
);

export const useBorrowerCreditScores =
  createQueryDataConsumerHook<ReturnType<typeof useGetBorrowerCreditScoresQuery>>();

type BorrowerDataProviderProps = DataProviderProps & {
  borrower: Borrower;
};

export const BorrowerCreditScoresData = (props: BorrowerDataProviderProps) => {
  const { borrower, children } = props;
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanBorrowerCreditScoresProvider
        queryProps={{
          borrowerId: borrower.id,
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {children}
      </TradeRequestDataLoanBorrowerCreditScoresProvider>
    );
  }

  return (
    <BorrowerCreditScoresProvider
      queryProps={{
        id: borrower.id,
        name: borrower.name,
        ssn: borrower.ssn,
        dateOfBirth: borrower.dateOfBirth,
        address: borrower.presentAddressFullAddress,
        phoneNumber: borrower.phoneNumber,
      }}
    >
      {children}
    </BorrowerCreditScoresProvider>
  );
};

const BorrowerVerificationsProvider = createQueryDataProvider(useGetBorrowerVerificationsQuery);
const TradeRequestDataLoanBorrowerVerificationsProvider = createQueryDataProvider(
  useTradeRequestDataLoanBorrowerVerificationsQuery
);

export const useBorrowerVerifications =
  createQueryDataConsumerHook<ReturnType<typeof useGetBorrowerVerificationsQuery>>();

export const BorrowerVerificationsData = (props: BorrowerDataProviderProps) => {
  const { borrower, children } = props;
  const value = useLoanContext();

  if (value.isAnonymised) {
    return (
      <TradeRequestDataLoanBorrowerVerificationsProvider
        queryProps={{
          borrowerId: borrower.id,
          loanId: value.loanId,
          tradeRequestId: value.tradeRequestId,
        }}
      >
        {children}
      </TradeRequestDataLoanBorrowerVerificationsProvider>
    );
  }

  return (
    <BorrowerVerificationsProvider
      queryProps={{
        id: borrower.id,
        name: borrower.name,
        ssn: borrower.ssn,
        dateOfBirth: borrower.dateOfBirth,
        address: borrower.presentAddressFullAddress,
        phoneNumber: borrower.phoneNumber,
      }}
    >
      {children}
    </BorrowerVerificationsProvider>
  );
};
