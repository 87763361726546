import { TradeCounterparty, TradeRequest } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

type DeclineTradeRequestDataRequestMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId: TradeCounterparty['id'];
  reason?: string;
};

const declineTradeRequestDataRequest = (data: DeclineTradeRequestDataRequestMutation) => {
  const { reason, tradeCounterpartyId, tradeRequestId } = data;
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/counterparties/${tradeCounterpartyId}/decline-data-request`
  );

  return postRequestWithAuth(url, {
    body: JSON.stringify({ reason }),
  });
};

export const useDeclineTradeRequestDataRequestMutation = () => {
  return useMutation(declineTradeRequestDataRequest);
};
