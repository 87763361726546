import { TradeRequest } from '@plus-platform/shared';
import compact from 'lodash/compact';
import React from 'react';
import { InfiniteData } from 'react-query';

import {
  TradeRequestDataResponse,
  useTradeRequestDataQuery,
} from '../hooks/queries/useTradeRequestDataQuery';

const getLoansFromData = (data?: InfiniteData<TradeRequestDataResponse | undefined>) => {
  return compact(data?.pages).flatMap((page) => page.loans) ?? [];
};

const getTotalCountFromData = (data?: InfiniteData<TradeRequestDataResponse | undefined>) => {
  return data?.pages[data?.pages.length - 1]?.totalCount ?? 0;
};

export const useTradeRequestData = (tradeRequestId: TradeRequest['id']) => {
  const { data, ...query } = useTradeRequestDataQuery(tradeRequestId);

  const tradeData = React.useMemo(
    () => ({
      loans: getLoansFromData(data),
      totalCount: getTotalCountFromData(data),
    }),
    [data]
  );

  return { ...tradeData, ...query };
};
