import {
  GetPortfolioLoanTypesByNoteRateDetailsResponse,
  PortfolioBreakdownRateType,
} from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioLoanTypesByNoteRateDetails = (
  rateType: PortfolioBreakdownRateType,
  noteRate: number
) => {
  const url = makeApiUrl(`/portfolio/types/${rateType}/rates/${noteRate}`);

  return getRequestWithAuth<GetPortfolioLoanTypesByNoteRateDetailsResponse>(url);
};
