import { useQuery } from 'react-query';

import { getLoanSummary } from '../../utils/loanUtils';
import { QueryKeys } from './types';

/**
 * Query hook for fetching loan summary
 */
export const useLoanSummaryQuery = (loanId?: string) =>
  useQuery(
    [QueryKeys.LOAN_SUMMARY, loanId],
    () => {
      if (loanId) {
        return getLoanSummary(loanId);
      }
    },
    { enabled: Boolean(loanId) }
  );
