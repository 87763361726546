import { AttributeType } from '@plus-platform/shared';
import { NavLinkProps, useLocation } from 'react-router-dom';

import {
  AnalyticsFillIcon,
  AnalyticsLineIcon,
  // TODO: Enable conduit when built
  // ConduitFillIcon,
  // ConduitLineIcon,
  HomeFillIcon,
  HomeLineIcon,
  IngestionFillIcon,
  IngestionLineIcon,
  PoolFillIcon,
  PoolLineIcon,
  PortfolioFillIcon,
  PortfolioLineIcon,
  TradeFillIcon,
  TradeLineIcon,
} from '../../components/icons';
import { SettingsFillIcon } from '../../components/icons/SettingsFillIcon';
import { SettingsLineIcon } from '../../components/icons/SettingsLineIcon';
import { UsersIcon } from '../../components/icons/UsersIcon';
import { ReadPermissions } from '../../components/Permissions';
import * as Styles from './Navigation.styles';

const AdminNavigation = () => {
  return (
    <Styles.Navigation>
      <NavigationLink to="/admin" width={86} data-testid="MainNav_Home">
        {({ isActive }: NavigationRenderProps) => (
          <>
            {isActive ? <HomeFillIcon /> : <HomeLineIcon />}
            Home
          </>
        )}
      </NavigationLink>
      <NavigationLink to="/profile" data-testid="MainNav_Admin_Profile">
        {() => (
          <>
            <UsersIcon />
            Profile
          </>
        )}
      </NavigationLink>

      <NavigationLink to="/admin/preferences" data-testid="MainNav_Admin_Preferences">
        {({ isActive }: NavigationRenderProps) => (
          <>
            {isActive ? <SettingsFillIcon /> : <SettingsLineIcon />}
            Preferences
          </>
        )}
      </NavigationLink>

      <NavigationLink to="/admin/analytics" data-testid="MainNav_Admin_Analytics">
        {({ isActive }: NavigationRenderProps) => (
          <>
            {isActive ? <AnalyticsFillIcon /> : <AnalyticsLineIcon />}
            Analytics
          </>
        )}
      </NavigationLink>
    </Styles.Navigation>
  );
};

const StandardNavigation = () => {
  return (
    <Styles.Navigation>
      <NavigationLink to="/home" width={86} data-testid="MainNav_Home">
        {({ isActive }: NavigationRenderProps) => (
          <>
            {isActive ? <HomeFillIcon /> : <HomeLineIcon />}
            Home
          </>
        )}
      </NavigationLink>
      <NavigationLink to="/portfolio" width={103} data-testid="MainNav_Portfolio">
        {({ isActive }: NavigationRenderProps) => (
          <>
            {isActive ? <PortfolioFillIcon /> : <PortfolioLineIcon />}
            Portfolio
          </>
        )}
      </NavigationLink>
      <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_POOL]}>
        <NavigationLink to="/pools" width={84} data-testid="MainNav_Pools">
          {({ isActive }: NavigationRenderProps) => (
            <>
              {isActive ? <PoolFillIcon /> : <PoolLineIcon />}
              Pools
            </>
          )}
        </NavigationLink>
      </ReadPermissions>
      <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_TRADE_REQUEST]}>
        <NavigationLink to="/trade-requests" width={135} data-testid="MainNav_TradeActivity">
          {({ isActive }: NavigationRenderProps) => (
            <>
              {isActive ? <TradeFillIcon /> : <TradeLineIcon />}
              Trade activity
            </>
          )}
        </NavigationLink>
      </ReadPermissions>
      {/* TODO: Enable conduit when built */}
      {/* <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_CONDUIT]}>
        <NavigationLink to="/conduit" width={97} data-testid="MainNav_Conduit">
          {({ isActive }: NavigationRenderProps) => (
            <>
              {isActive ? <ConduitFillIcon /> : <ConduitLineIcon />}
              Conduit
            </>
          )}
        </NavigationLink>
      </ReadPermissions> */}
      <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_DOCUMENT]}>
        <NavigationLink to="/documents" width={107} data-testid="MainNav_Ingestion">
          {({ isActive }: NavigationRenderProps) => (
            <>
              {isActive ? <IngestionFillIcon /> : <IngestionLineIcon />}
              Ingestion
            </>
          )}
        </NavigationLink>
      </ReadPermissions>
      <NavigationLink to="/analytics" width={106} data-testid="MainNav_Analytics">
        {({ isActive }: NavigationRenderProps) => (
          <>
            {isActive ? <AnalyticsFillIcon /> : <AnalyticsLineIcon />}
            Analytics
          </>
        )}
      </NavigationLink>
    </Styles.Navigation>
  );
};

export const Navigation = () => {
  const location = useLocation();
  const isAdmin = location.pathname.includes('/admin');

  if (isAdmin) {
    return <AdminNavigation />;
  }

  return <StandardNavigation />;
};

type NavigationRenderProps = {
  isActive: boolean;
};

type NavigationLinkProps = NavLinkProps & {
  width?: number;
  children: (props: NavigationRenderProps) => React.ReactNode;
};

const NavigationLink = ({ children, to, width, ...rest }: NavigationLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to as string);

  return (
    <Styles.NavigationLink {...rest} to={to} $isActive={isActive} $width={width}>
      {children({ isActive })}
    </Styles.NavigationLink>
  );
};
