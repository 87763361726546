import { Identifier } from '.';

export enum BorrowersAttributeType {
  ATTRIBUTE_BORROWER = 'ATTRIBUTE_BORROWER',
}

export type CreditScores = {
  date: string;
  fico?: number;
  experian?: number;
  equifax?: number;
};

export type BorrowerDetails = {
  id: Borrower['id'];
  name: string;
  ssn: string;
  dateOfBirth?: string;
  address?: string;
  phoneNumber?: string;
};

export type BorrowerPresentEmployment = {
  isSelfEmployed?: boolean;
  employerName?: string;
  employerAddressFullAddress?: string;
  employerAddressFullAddressLine1?: string;
  employerAddressFullAddressCity?: string;
  employerAddressFullAddressState?: string;
  employerAddressFullAddressZipCode?: string;
  employerAddressFullAddressCounty?: string;
  yearsOnTheJob?: number;
  yearsEmployedInThisProfession?: number;
  positionTitle?: string;
  employmentPhone?: string;
};

export type TaxFormW2 = {
  formW2TotalReportedAnnualIncome?: number;
  formW2FederalTaxAmount?: number;
  formW2SocialSecurityTaxAmount?: number;
  formW2MedicareTaxAmount?: number;
  formW2StateTaxAmount?: number;
  formW2LocalTaxAmount?: number;
  formW2TotalTaxPaid?: number;
};

export type TaxForm1099 = {
  form1099TotalReportedAnnualIncome?: number;
  form1099FederalTaxAmount?: number;
  form1099SocialSecurityTaxAmount?: number;
  form1099MedicareTaxAmount?: number;
  form1099StateTaxAmount?: number;
  form1099LocalTaxAmount?: number;
  form1099TotalTaxPaid?: number;
};

export type BorrowerMonthlyIncome = TaxFormW2 &
  TaxForm1099 & {
    monthlyIncomeBaseSalary?: number;
    monthlyIncomeOvertime?: number;
    monthlyIncomeBonuses?: number;
    monthlyIncomeCommissions?: number;
    monthlyIncomeDividendsAndInterest?: number;
    monthlyIncomeNetRentalIncome?: number;
    monthlyIncomeOther?: number;
    monthlyIncomeTotal?: number;
    federalEffectiveTaxRate?: number;
    socialSecurityEffectiveTaxRate?: number;
    medicareEffectiveTaxRate?: number;
    additionalMedicareEffectiveTaxRate?: number;
    stateEffectiveTaxRate?: number;
    localEffectiveTaxRate?: number;
    taxPaidEffectiveTaxRate?: number;
    totalMonthlyIncomeAfterTax?: number;
    totalAnnualIncomeAfterTax?: number;
  };

export type OtherDebtLines = {
  dti?: number;
  backendDti?: number;
};

export type BorrowerCreation = BorrowerPresentEmployment &
  BorrowerMonthlyIncome &
  OtherDebtLines & {
    name: string;
    ssn: string;
    phoneNumber?: string;
    businessPhoneNumber?: string;
    email?: string;
    dateOfBirth?: string;
    yearsOfSchool?: number;
    maritalStatus?: string;
    // TODO: put in BorrowerAddress table
    formerAddressFullAddress?: string;
    formerAddressFullAddressLine1?: string;
    formerAddressFullAddressCity?: string;
    formerAddressFullAddressState?: string;
    formerAddressFullAddressZipCode?: string;
    formerAddressFullAddressCounty?: string;
    presentAddressFullAddress?: string;
    presentAddressFullAddressLine1?: string;
    presentAddressFullAddressCity?: string;
    presentAddressFullAddressState?: string;
    presentAddressFullAddressZipCode?: string;
    presentAddressFullAddressCounty?: string;
    representativeCreditScore?: number;
    lastFicoScore?: number;
    lastFicoScoreDate?: Date;
    hasMoreThanOneEmployer?: boolean;

    createdAt?: Date;
    updatedAt?: Date;
  };

export type Borrower = BorrowerCreation & {
  id: Identifier;
};

export type AggregatedBorrower = Borrower & {
  identityVerifications: BorrowerIdentityVerification[];
  employmentVerifications: BorrowerEmploymentVerification[];
};

export type EmploymentIncome = {
  yearNumber?: number;
  totalAnnualIncomeAmountInCents?: number;
  totalAnnualIncomeCurrencyCode?: string;
};

export type BorrowerEmployment = EmploymentIncome & {
  asOfDate?: Date;
  employerName?: string;
  employmentStatus?: string;
  position?: string;
  workStatus?: string;
  hiringDate?: Date;
};

export enum BorrowerVerifierType {
  EQUIFAX = 'EQUIFAX',
  EXPERIAN = 'EXPERIAN',
}

export type BorrowerEmploymentVerification = EmploymentIncome & {
  id: Identifier;
  borrowerId: Borrower['id'];
  verifierType: BorrowerVerifierType;
  asOfDate?: Date;
  employerName?: string;
  employmentStatus?: string;
  position?: string;
  workStatus?: string;
  hiringDate?: Date;

  createdAt?: Date;
  updatedAt?: Date;
};

export type BorrowerIdentityVerification = {
  id: Identifier;
  borrowerId: Borrower['id'];
  verifierType: BorrowerVerifierType;
  ssn?: string;
  name?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  fullAddress?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  isDeceased?: boolean;

  createdAt?: Date;
  updatedAt?: Date;
};

export type BorrowerIdentityVerificationCreation = Omit<
  BorrowerIdentityVerification,
  'id'
>;

export type BorrowerEmploymentVerificationCreation = Omit<
  BorrowerEmploymentVerification,
  'id'
>;

export type BorrowerVerification = {
  identity?: BorrowerIdentityVerification;
  employment?: BorrowerEmploymentVerification;
};

export type BorrowerVerifications = {
  equifax: BorrowerVerification;
  experian: BorrowerVerification;
};
