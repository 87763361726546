import { GetSubmissionsResponse } from '@plus-platform/shared';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const getSubmissionsQuery = async (
  filterIds: number[],
  offset = 0,
  limit: number | undefined = undefined,
  sortField = '',
  sortOrder = ''
) => {
  const query = new URLSearchParams({
    offset: `${offset}`,
    limit: limit ? `${limit}` : '',
    sortField,
    sortOrder,
  });

  filterIds.forEach((id) => query.append('filterIds', `${id}`));

  const url = makeApiUrl(`submissions?${query}`);
  return getRequestWithAuth<GetSubmissionsResponse>(url);
};

type UseGetSubmissionsQueryProps = {
  itemCount: number;
  filterIds?: number[];
  sortField?: 'id' | 'createdAt';
  sortOrder?: 'ASC' | 'DESC';
};

export const useGetSubmissionsQuery = ({
  itemCount,
  filterIds = [],
  sortField = 'createdAt',
  sortOrder = 'DESC',
}: UseGetSubmissionsQueryProps): UseInfiniteQueryResult<GetSubmissionsResponse> =>
  useInfiniteQuery(
    [QueryKeys.SUBMISSIONS, itemCount, filterIds, sortField, sortOrder],
    async ({ pageParam = 0 }) => {
      return getSubmissionsQuery(filterIds, pageParam, itemCount, sortField, sortOrder);
    },
    {
      getNextPageParam: (lastPage) => (lastPage !== undefined ? lastPage.pageNumber : 0),
    }
  );
