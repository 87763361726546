import { InboundTradeRequestWithSummary, TradeQuoteWithSummary } from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type InboundQuoteCounterbackedProps = {
  date: TradeQuoteWithSummary['createdAt'];
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundQuoteCounterbacked = ({
  date,
  tradeRequest,
}: InboundQuoteCounterbackedProps) => {
  return (
    <PlusMessage date={date}>
      <MessageText>
        <TradeMessage
          id="Trade.Inbound.Quote.Counterbacked"
          values={{
            userName: formatNameWithInitial(
              tradeRequest.user.lastName,
              tradeRequest.user.firstName
            ),
            organizationName: tradeRequest.user.organization.tradingName,
          }}
        />
      </MessageText>
    </PlusMessage>
  );
};
