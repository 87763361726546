import { PortfolioBreakdownRateType } from '@plus-platform/shared';
import compact from 'lodash/compact';
import React from 'react';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components/macro';

import { Breadcrumb, BreadcrumbItem } from '../../../components/Breadcrumb';
import { Heading } from '../../../components/Heading';
import { LoanMap } from '../../../components/LoanMap';
import {
  Page,
  PageContent,
  PageHeader,
  PageHeaderContent,
  PageSection,
  PageSectionContent,
} from '../../../components/Page';
import { Skeleton } from '../../../components/Skeleton';
import {
  usePortfolioLoanTypesByNotRateDetailsQuery,
  usePortfolioLoanTypesByNotRateLoansQuery,
  usePortfolioLoanTypesByNotRateQuery,
} from '../../../hooks/queries';
import { formatNumber, formatPercentage } from '../../../utils/formatUtils';
import {
  LARGEST_NAVIGATION_BUBBLE_SIZE,
  mapLoansCountByNoteRateForNavigation,
  mapLoansCountByStateToMapLoans,
  SMALLEST_NAVIGATION_BUBBLE_SIZE,
} from './helpers';
import {
  Bubble,
  BubbleWrapper,
  ContentWrapper,
  MainContent,
  RateTypeSubtitle,
  RateTypeTitle,
  Sidebar,
} from './LoanTypesByDetailPage.styles';
import { LoanTypesByLoansTable } from './LoanTypesByLoansTable';
import { RateTypeLink } from './LoanTypesByNoteRateDetailPage.styles';
import { Summary } from './Summary';
import { SummaryKey, SummaryValue } from './Summary.styles';

type LoanTypesByNoteRateDetailParams = {
  rateType: PortfolioBreakdownRateType;
  noteRate: string;
};

export const LoanTypesByNoteRateDetailPage = () => {
  const { noteRate, rateType } = useParams<LoanTypesByNoteRateDetailParams>();
  const theme = useTheme();
  const sidebar = React.useRef<HTMLElement | null>(null);

  const { data: details, isLoading: isLoadingDetails } = usePortfolioLoanTypesByNotRateDetailsQuery(
    rateType,
    Number(noteRate)
  );
  const { data: summary, isLoading: isLoadingSummary } = usePortfolioLoanTypesByNotRateQuery();
  const {
    data: loansData,
    fetchNextPage: fetchNextLoansPage,
    isFetchingNextPage: isFetchingNextLoansPage,
    isLoading: isLoadingLoans,
  } = usePortfolioLoanTypesByNotRateLoansQuery(rateType, Number(noteRate));

  const loans = compact(loansData?.pages).flatMap((page) => page.data) ?? [];
  const totalLoansCount = loansData?.pages[0]?.totalLoansCount ?? 0;

  const rateTypeByNoteRateData = mapLoansCountByNoteRateForNavigation(summary?.countsByRateType);
  const largestLoanCount = Math.max(
    ...rateTypeByNoteRateData.map((dataItem) => dataItem.loansCount)
  );
  const navigationData = rateTypeByNoteRateData.map((dataItem) => {
    const { currentMarketRate, label, loansCount, noteRate, rateType } = dataItem;
    const loanCountRatio = largestLoanCount ? loansCount / largestLoanCount : 0;

    return {
      noteRate,
      loansCount,
      rateType,
      label,
      isBelowCurrentMarketRate: !currentMarketRate || noteRate <= currentMarketRate,
      size: Math.max(
        LARGEST_NAVIGATION_BUBBLE_SIZE * loanCountRatio,
        SMALLEST_NAVIGATION_BUBBLE_SIZE
      ),
    };
  });

  React.useLayoutEffect(() => {
    sidebar.current?.querySelector('.active')?.scrollIntoView();
  }, [isLoadingSummary]);

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>Loans by type by note rate</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem
                linkTo="/home/breakdown/types/rates"
                title="Loans by type by note rate"
              />
              <BreadcrumbItem title="Detail" />
            </Breadcrumb>
          </div>

          {details && summary && (
            <Summary
              flagsCount={details.flagsCount}
              alertsCount={details.alertsCount}
              warningsCount={details.warningsCount}
              totalLoansCount={summary.totalLoansCount}
            />
          )}
        </PageHeaderContent>
      </PageHeader>
      <PageContent>
        {isLoadingSummary && isLoadingDetails && isLoadingLoans && <Skeleton height={307} />}

        <ContentWrapper>
          {summary && (
            <Sidebar ref={sidebar}>
              {navigationData.map((data) => (
                <RateTypeLink
                  to={`/home/breakdown/types/${data.rateType}/rates/${data.noteRate}`}
                  key={`${data.rateType}-${data.noteRate}`}
                  $isBelowCurrentMarketRate={data.isBelowCurrentMarketRate}
                >
                  <BubbleWrapper>
                    <Bubble $size={data.size}>{data.loansCount}</Bubble>
                  </BubbleWrapper>
                  <RateTypeTitle>{data.label}</RateTypeTitle>
                  <RateTypeSubtitle>{formatPercentage(data.noteRate)}</RateTypeSubtitle>
                </RateTypeLink>
              ))}
            </Sidebar>
          )}

          <MainContent>
            {details && (
              <React.Fragment>
                <PageSection>
                  <Heading $size="medium">In this selection</Heading>
                  <PageSectionContent>
                    <SummaryKey>Number of loans</SummaryKey>
                    <SummaryValue>{formatNumber(details?.totalLoansCount)}</SummaryValue>
                  </PageSectionContent>
                </PageSection>
                <PageSection>
                  <Heading $size="medium">By location</Heading>
                  <PageSectionContent>
                    <LoanMap
                      loans={mapLoansCountByStateToMapLoans(details.byState)}
                      getMapDefaultColor={(threshold) =>
                        threshold > 0 ? theme.colors.white20 : theme.colors.white50
                      }
                    />
                  </PageSectionContent>
                </PageSection>
              </React.Fragment>
            )}

            {loansData && (
              <LoanTypesByLoansTable
                loans={loans}
                totalLoansCount={totalLoansCount}
                isFetchingNextPage={isFetchingNextLoansPage}
                fetchNextPage={fetchNextLoansPage}
              />
            )}
          </MainContent>
        </ContentWrapper>
      </PageContent>
    </Page>
  );
};
