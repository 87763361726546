import styled, { css, CSSProperties } from 'styled-components/macro';

import { fontFamily } from '../styles/typography';

export const WidgetFooter = styled.footer`
  margin: 24px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: ${fontFamily.text};
`;

export const WidgetSection = styled.div<{
  $isLarge?: boolean;
  $hasCentralisedLayout?: boolean;
}>`
  ${({ $isLarge }) => css`
    width: ${$isLarge ? '50%' : '33%'};
    padding: 0 12px;

    & + & {
      margin-left: 8px;
    }
  `}

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;

export const WidgetSectionWrapper = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  display: flex;
`;

export const WidgetContent = styled.div.withConfig<{
  direction?: CSSProperties['flexDirection'];
  shouldWrap?: boolean;
}>({
  shouldForwardProp: (prop, defaultValidationFn) =>
    !['direction', 'shouldWrap'].includes(prop) && defaultValidationFn(prop),
})`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  padding: 4px;

  ${({ shouldWrap }) =>
    shouldWrap &&
    css`
      flex-wrap: wrap;
    `}
  & + ${WidgetFooter} {
    margin-top: 20px;
  }
`;
