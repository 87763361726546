import { ALL_LOANS_ATTRIBUTES, PermissionType } from '@plus-platform/shared';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { PrivateRoute } from '../../global/PrivateRoute';
import { useLoanDetailsQuery } from '../../hooks/queries';
import { useLoanContext } from '../context';
import { LoanDetailBreadcrumb } from '../LoanDetailBreadcrumb';
import { LoanSummaryMetaInfo } from '../LoanSummaryMetaInfo';
import { ServicerChat } from '../ServicerChat';
import { ExtraDataEnum } from '../types';
import { LoanDetail, LoanDetailMap } from './LoanDetail';

const DETAIL_MAP_PATH = 'map';

const TextLight = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white70};
`;

export const LoanDetailPage = () => {
  const [extraDataToDisplay, setExtraDataToDisplay] = React.useState<ExtraDataEnum | undefined>(
    undefined
  );
  const [isServicerChatOpened, setIsServicerChatOpened] = React.useState(false);

  const { loanNumber } = useLoanContext();

  const { data: loan, isLoading } = useLoanDetailsQuery(loanNumber);

  const loanTitle = `Loan ID ${loanNumber || ''}`;

  return (
    <React.Fragment>
      <Page>
        <PageHeader>
          <PageHeaderContent>
            <div>
              <Heading>
                Full record <TextLight>for</TextLight> {loanTitle}
              </Heading>
              <Breadcrumb>
                <BreadcrumbItem linkTo="/" title="Home" />
                <BreadcrumbItem linkTo="/portfolio" title="Portfolio" />
                <BreadcrumbItem linkTo={`/loan/${loanNumber}`} title={`Loan ${loanNumber}`} />
                <BreadcrumbItem title="Full record" />
              </Breadcrumb>
            </div>
            <LoanSummaryMetaInfo loan={loan} />
          </PageHeaderContent>
          <LoanDetailBreadcrumb loan={loan} />
        </PageHeader>
        <PageContent>
          {isServicerChatOpened && loan && (
            <ServicerChat
              loanId={loan.id}
              loanNumber={loanNumber}
              onClose={() => setIsServicerChatOpened(false)}
            />
          )}
          <Switch>
            <Redirect exact from="/loan/:loanNumber/detail" to="/loan/:loanNumber/detail/grid" />
            <PrivateRoute
              path="/loan/:loanNumber/detail/grid"
              render={() => (
                <LoanDetail
                  isChatActive={!isServicerChatOpened}
                  isLoanLoading={isLoading}
                  loan={loan}
                  onChatClick={() => setIsServicerChatOpened(true)}
                />
              )}
              requiredPermissions={{
                [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
              }}
            />
            <PrivateRoute
              path={`/loan/:loanNumber/detail/${DETAIL_MAP_PATH}`}
              render={() => (
                <LoanDetailMap
                  extraDataToDisplay={extraDataToDisplay}
                  isChatActive={!isServicerChatOpened}
                  isLoanLoading={isLoading}
                  loan={loan}
                  onChatClick={() => setIsServicerChatOpened(true)}
                  onDetailsButtonClick={(data) => {
                    setExtraDataToDisplay(data);
                  }}
                  onMLSButtonClick={(data) => {
                    setExtraDataToDisplay(data);
                  }}
                  onSidebarClose={() => setExtraDataToDisplay(undefined)}
                />
              )}
              requiredPermissions={{
                [PermissionType.PERMISSION_READ]: ALL_LOANS_ATTRIBUTES,
              }}
            />
          </Switch>
        </PageContent>
      </Page>
    </React.Fragment>
  );
};
