import { transparentize } from 'polished';
import styled from 'styled-components/macro';

export const PDFIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.statusRed)};
  color: ${({ theme }) => theme.colors.statusRed};
`;
