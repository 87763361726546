import React from 'react';

export const TicketIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 16.34C12.16 16.34 12.3 16.28 12.42 16.16C12.54 16.04 12.6 15.9 12.6 15.74C12.6 15.58 12.54 15.44 12.42 15.32C12.3 15.2 12.16 15.14 12 15.14C11.84 15.14 11.7 15.2 11.58 15.32C11.46 15.44 11.4 15.58 11.4 15.74C11.4 15.9 11.46 16.04 11.58 16.16C11.7 16.28 11.84 16.34 12 16.34ZM12 13C12.16 13 12.3 12.94 12.42 12.82C12.54 12.7 12.6 12.56 12.6 12.4C12.6 12.24 12.54 12.1 12.42 11.98C12.3 11.86 12.16 11.8 12 11.8C11.84 11.8 11.7 11.86 11.58 11.98C11.46 12.1 11.4 12.24 11.4 12.4C11.4 12.56 11.46 12.7 11.58 12.82C11.7 12.94 11.84 13 12 13ZM12 9.66C12.16 9.66 12.3 9.6 12.42 9.48C12.54 9.36 12.6 9.22 12.6 9.06C12.6 8.9 12.54 8.76 12.42 8.64C12.3 8.52 12.16 8.46 12 8.46C11.84 8.46 11.7 8.52 11.58 8.64C11.46 8.76 11.4 8.9 11.4 9.06C11.4 9.22 11.46 9.36 11.58 9.48C11.7 9.6 11.84 9.66 12 9.66ZM18.8 18.8H5.2C4.87 18.8 4.5875 18.6825 4.3525 18.4475C4.1175 18.2125 4 17.93 4 17.6V14.54C4.49333 14.4333 4.90333 14.1833 5.23 13.79C5.55667 13.3967 5.72 12.9333 5.72 12.4C5.72 11.8667 5.55667 11.4 5.23 11C4.90333 10.6 4.49333 10.3533 4 10.26V7.2C4 6.87 4.1175 6.5875 4.3525 6.3525C4.5875 6.1175 4.87 6 5.2 6H18.8C19.13 6 19.4125 6.1175 19.6475 6.3525C19.8825 6.5875 20 6.87 20 7.2V10.26C19.5067 10.3533 19.0967 10.6 18.77 11C18.4433 11.4 18.28 11.8667 18.28 12.4C18.28 12.9333 18.4433 13.3967 18.77 13.79C19.0967 14.1833 19.5067 14.4333 20 14.54V17.6C20 17.93 19.8825 18.2125 19.6475 18.4475C19.4125 18.6825 19.13 18.8 18.8 18.8ZM18.8 17.6V15.42C18.2933 15.0733 17.88 14.64 17.56 14.12C17.24 13.6 17.08 13.0267 17.08 12.4C17.08 11.7733 17.24 11.2 17.56 10.68C17.88 10.16 18.2933 9.72667 18.8 9.38V7.2H5.2V9.38C5.72 9.72667 6.13667 10.16 6.45 10.68C6.76333 11.2 6.92 11.7733 6.92 12.4C6.92 13.0267 6.76333 13.6 6.45 14.12C6.13667 14.64 5.72 15.0733 5.2 15.42V17.6H18.8Z"
        fill="currentColor"
      />
    </svg>
  );
};
