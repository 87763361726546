import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOString,
} from '../types';

export enum EmploymentClassificationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum EmploymentStatusType {
  Current = 'Current',
  Previous = 'Previous',
}

enum OwnershipInterestType {
  GreaterThanOrEqualTo25Percent = 'GreaterThanOrEqualTo25Percent',
  LessThan25Percent = 'LessThan25Percent',
  Other = 'Other',
}

enum SpecialBorrowerEmployerRelationshipType {
  EmployedByRelative = 'EmployedByRelative',
  Other = 'Other',
  PropertySeller = 'PropertySeller',
  RealEstateBroker = 'RealEstateBroker',
}

export type Employment = MISMOEntity &
  Partial<{
    employedAbroadIndicator: MISMOIndicator;
    employmentBorrowerHomeOfficeIndicator: MISMOIndicator;
    employmentBorrowerSelfEmployedIndicator: MISMOIndicator;
    employmentClassificationType: EmploymentClassificationType;
    employmentEndDate: MISMODate;
    employmentMonthlyIncomeAmount: MISMOAmount;
    employmentMonthsOnJobCount: MISMOCount;
    employmentPositionDescription: MISMOString;
    employmentReportedDate: MISMODate;
    employmentSelfOwnedBusinessOperationsEndDate: MISMODate;
    employmentSelfOwnedBusinessOperationsStartDate: MISMODate;
    employmentStartDate: MISMODate;
    employmentStatusType: EmploymentStatusType;
    employmentTimeInLineOfWorkMonthsCount: MISMOCount;
    employmentTimeInLineOfWorkYearsCount: MISMOCount;
    employmentTypeOfBusinessDescription: MISMOString;
    employmentYearsOnJobCount: MISMOCount;
    ownershipInterestType: OwnershipInterestType;
    ownershipInterestTypeOtherDescription: MISMOString;
    specialBorrowerEmployerRelationshipIndicator: MISMOIndicator;
    specialBorrowerEmployerRelationshipType: SpecialBorrowerEmployerRelationshipType;
    specialBorrowerEmployerRelationshipTypeOtherDescription: MISMOString;
  }>;
