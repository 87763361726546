import { EventTopics, EventType } from '../types';

/**
 * Object containing translated labels for the different event types
 */
export const EVENT_TYPES_LABELS: Readonly<Record<EventType, string>> = {
  [EventType.DOCUMENT_INGESTED_EVENT]: 'Document Ingested',
  [EventType.DOCUMENT_UPLOADED_EVENT]: 'Document Uploaded',
  [EventType.LOAN_BORROWER_ASSIGNED_EVENT]: 'Borrower Assigned to Loan',
  [EventType.LOAN_DETAILS_CREATED_EVENT]: 'Details Created',
  [EventType.LOAN_DOCUMENT_ADDED_EVENT]: 'Document Added',
  [EventType.LOAN_PAYMENT_RECEIVED_EVENT]: 'Payment Received',
  [EventType.LOAN_PROPERTY_CREATED_EVENT]: 'Property Created',
  [EventType.LOAN_TERMS_CREATED_EVENT]: 'Terms Created',
  [EventType.LOAN_INTEREST_RATE_ADJUSTMENT_CREATED_EVENT]: 'Interest Rate Adjustment Created',
  [EventType.LOAN_INTEREST_RATE_ADJUSTMENT_INDEX_ASSIGNED_EVENT]:
    'Interest Rate Adjustment Assigned To Index',
  [EventType.LOAN_SERVICER_ASSIGNED_EVENT]: 'Servicer Assigned to Loan',
  [EventType.LOAN_ESCROW_ANALYSIS_CREATED_EVENT]: 'Loan Escrow Analysis Created',
  [EventType.LOAN_CUSTODIAN_ASSIGNED_EVENT]: 'Custodian Assigned to Loan',
  [EventType.LOAN_MORTGAGE_INSURER_ASSIGNED_EVENT]: 'Mortgage Insurer Added',
  [EventType.LOAN_FLOOD_INSURER_ASSIGNED_EVENT]: 'Flood Insurer Added',
  [EventType.LOAN_HAZARD_INSURER_ASSIGNED_EVENT]: 'Hazard Insurer Added',
  [EventType.LOAN_CHAT_MESSAGE_SENT_EVENT]: 'Loan Chat Message Sent',
  [EventType.LOAN_NOTE_CREATED_EVENT]: 'Loan Note Created',
  [EventType.PORTFOLIO_ANALYSIS_CREATED_EVENT]: 'Portfolio Analysis Created',
  [EventType.PORTFOLIO_ANALYTICS_CREATED_EVENT]: 'Portfolio Analytics Created',
  [EventType.PORTFOLIO_ANALYTICS_UPDATED_EVENT]: 'Portfolio Analysis Updated',
  [EventType.PORTFOLIO_ANALYTICS_DELETED_EVENT]: 'Portfolio Analytics Deleted',
  [EventType.POOL_CREATED_EVENT]: 'Pool Created',
  [EventType.POOL_UPDATED_EVENT]: 'Pool Updated',
  [EventType.POOL_DELETED_EVENT]: 'Pool Deleted',
  [EventType.LOAN_ADDED_TO_POOL_EVENT]: 'Loan Added to Pool',
  [EventType.TRADE_REQUEST_CREATED_EVENT]: 'Trade Request Created',
  [EventType.TRADE_COUNTERPARTY_CREATED_EVENT]: 'Trade Counterparty Created',
  [EventType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST_EVENT]: 'Trade Counterparty Expressed Interest',
  [EventType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST_ACCEPTED_EVENT]:
    'Trade Counterparty Expressed Interest Accepted',
  [EventType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST_DECLINED_EVENT]:
    'Trade Counterparty Expressed Interest Declined',
  [EventType.TRADE_COUNTERPARTY_DATA_REQUESTED_EVENT]: 'Trade Counterparty Data Requested',
  [EventType.TRADE_COUNTERPARTY_DATA_REQUEST_ACCEPTED_EVENT]:
    'Trade Counterparty Data Request Accepted',
  [EventType.TRADE_COUNTERPARTY_DATA_REQUEST_DENIED_EVENT]:
    'Trade Counterparty Data Request Denied',
  [EventType.TRADE_COUNTERPARTY_SOLD_EVENT]: 'Trade Counterparty Sold',
  [EventType.TRADE_QUOTE_CREATED_EVENT]: 'Trade Quote Created',
  [EventType.TRADE_QUOTE_ACCEPTED_EVENT]: 'Trade Quote Accepted',
  [EventType.TRADE_QUOTE_REJECTED_EVENT]: 'Trade Quote Rejected',
  [EventType.TRADE_QUOTE_COUNTER_CREATED_EVENT]: 'Trade Quote Counter Created',
  [EventType.TRADE_QUOTE_COUNTER_ACCEPTED_EVENT]: 'Trade Quote Counter Accepted',
  [EventType.TRADE_QUOTE_COUNTER_REJECTED_EVENT]: 'Trade Quote Counter Rejected',
  [EventType.TRADE_REQUEST_STIPULATION_ADDED_EVENT]: 'Trade Stipulation Added Event',
  [EventType.QUOTE_STIPULATION_ADDED_EVENT]: 'Quote Stipulation Added Event',
  [EventType.ACTION_STIPULATION_ADDED_EVENT]: 'Action Stipulation Added Event',
  [EventType.TRADE_MESSAGE_SENT_EVENT]: 'Trade Message Sent Event',
  [EventType.SERVICER_CREATED_EVENT]: 'Servicer Created',
  [EventType.INSURER_CREATED_EVENT]: 'Insurer Created',
  [EventType.CUSTODIAN_CREATED_EVENT]: 'Custodian Created',
  [EventType.BORROWER_CREATED_EVENT]: 'Borrower Created',
  [EventType.BORROWER_IDENTITY_VERIFIED_EVENT]: 'Borrower Identity Verified',
  [EventType.BORROWER_EMPLOYMENT_VERIFIED_EVENT]: 'Borrower Employment Verified',
  [EventType.USER_PORTFOLIO_FILTERS_UPDATED_EVENT]: 'User Portfolio Filters Updated',
  [EventType.SUBMISSION_CREATED_EVENT]: 'Submission Created',
  [EventType.INDEX_CREATED_EVENT]: 'Index Created',
  [EventType.INDEX_RATE_CREATED_EVENT]: 'Index Rate Created',
  [EventType.USER_SETTINGS_UPDATED_EVENT]: 'User Settings Updated',
  [EventType.USER_ANALYTICS_SETTINGS_UPDATED_EVENT]: 'User Analytic Settings Updated',
  [EventType.USER_ANALYTICS_SETTINGS_DELETED_EVENT]: 'User Analytic Settings Deleted',
};

/**
 * Groups events into their topic so the topic can be easily searched
 */
export const EVENT_TOPICS: Readonly<Record<EventTopics, EventType[]>> = {
  [EventTopics.PAYMENT]: [EventType.LOAN_PAYMENT_RECEIVED_EVENT],
  [EventTopics.ORIGINATION]: [
    EventType.LOAN_DOCUMENT_ADDED_EVENT,
    EventType.LOAN_BORROWER_ASSIGNED_EVENT,
    EventType.LOAN_DETAILS_CREATED_EVENT,
    EventType.LOAN_DOCUMENT_ADDED_EVENT,
    EventType.LOAN_MORTGAGE_INSURER_ASSIGNED_EVENT,
    EventType.LOAN_FLOOD_INSURER_ASSIGNED_EVENT,
    EventType.LOAN_HAZARD_INSURER_ASSIGNED_EVENT,
    EventType.LOAN_PROPERTY_CREATED_EVENT,
    EventType.LOAN_SERVICER_ASSIGNED_EVENT,
    EventType.LOAN_ESCROW_ANALYSIS_CREATED_EVENT,
    EventType.LOAN_CUSTODIAN_ASSIGNED_EVENT,
    EventType.LOAN_TERMS_CREATED_EVENT,
    EventType.LOAN_ADDED_TO_POOL_EVENT,
    EventType.LOAN_INTEREST_RATE_ADJUSTMENT_CREATED_EVENT,
    EventType.LOAN_INTEREST_RATE_ADJUSTMENT_INDEX_ASSIGNED_EVENT,
    EventType.LOAN_NOTE_CREATED_EVENT,
  ],
  [EventTopics.SYSTEM]: [EventType.DOCUMENT_INGESTED_EVENT, EventType.DOCUMENT_UPLOADED_EVENT],
};
