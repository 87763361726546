import { ApproachToValue } from '../../approachToValue';
import { ScopeOfWork } from '../../scopeOfWork';
import {
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
} from '../../types';
import { ValuationReconciliation } from './valuationReconciliation';
import { ValuationReview } from './valuationReview';

enum ValuationReportContentType {
  AppraisalForm = 'AppraisalForm',
  AppraisalLicense = 'AppraisalLicense',
  Certification = 'Certification',
  CommentAddendum = 'CommentAddendum',
  CoverPage = 'CoverPage',
  Exhibit = 'Exhibit',
  ExtraListings = 'ExtraListings',
  ExtraRentals = 'ExtraRentals',
  ExtraSales = 'ExtraSales',
  FloodMap = 'FloodMap',
  Invoice = 'Invoice',
  ListingPhotos = 'ListingPhotos',
  LocationMap = 'LocationMap',
  Other = 'Other',
  PlatMap = 'PlatMap',
  RentalPhotos = 'RentalPhotos',
  SalePhotos = 'SalePhotos',
  SalesContract = 'SalesContract',
  Sketch = 'Sketch',
  SubjectPhotos = 'SubjectPhotos',
}

type ValuationAnalysis = MISMOEntity &
  Partial<{
    priorSaleCommentDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type ValuationReport = MISMOEntity &
  Partial<{
    appraiserAdditionalFileIdentifier: MISMOIdentifier;
    appraiserFileAdditionalIdentifierDescription: MISMOString;
    appraiserFileIdentifier: MISMOIdentifier;
    appraiserFileIdentifierDescription: MISMOString;
    appraiserReportSignedDate: MISMODate;
    loanOriginationSystemLoanIdentifier: MISMOIdentifier;
    supervisorReportSignedDate: MISMODate;
    valuationAddendumText: MISMOString;
    valuationReportContentDescription: MISMOString;
    valuationReportContentIdentifier: MISMOIdentifier;
    valuationReportContentName: MISMOString;
    valuationReportContentType: ValuationReportContentType;
    valuationReportContentTypeOtherDescription: MISMOString;
    approachToValue: ApproachToValue;
    scopeOfWork: ScopeOfWork;
    valuationAnalyses: ValuationAnalysis[];
    // valuationForms: ValuationForm[];
    valuationReconciliation: ValuationReconciliation;
    valuationReview: ValuationReview;
    // valuationUpdate: ValuationUpdate;
  }>;
