import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMONumeric,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum BasementFeatureType {
  FloorDrain = 'FloorDrain',
  Other = 'Other',
  OutsideEntry = 'OutsideEntry',
  SumpPump = 'SumpPump',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

type BasementFeature = MISMOEntity &
  Partial<{
    basementFeatureDescription: MISMOString;
    basementFeatureType: BasementFeatureType;
    basementFeatureTypeOtherDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
  }>;

enum BasementExitType {
  InteriorStairs = 'InteriorStairs',
  Walkout = 'Walkout',
  Walkup = 'Walkup',
}

export type Basement = MISMOEntity &
  Partial<{
    basementCeilingMaterialDescription: MISMOString;
    basementExitType: BasementExitType;
    basementFinishDescription: MISMOString;
    basementFinishedAreaSquareFeetNumber: MISMONumeric;
    basementFinishedIndicator: MISMOIndicator;
    basementFinishedPercent: MISMOPercent;
    basementFirstFloorAreaPercent: MISMOPercent;
    basementFloorMaterialDescription: MISMOString;
    basementWallMaterialDescription: MISMOString;
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    squareFeetNumber: MISMONumeric;
    basementFeatures: BasementFeature[];
  }>;
