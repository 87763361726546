import styled from 'styled-components/macro';

import { fontFamily } from '../styles/typography';

export const PropertyImage = styled.img`
  width: 100%;
  margin-bottom: 8px;
  min-width: 170px;
  min-height: 113px;
  background-color: lightgray;
`;

export const PropertyTitle = styled.h2`
  font-family: ${fontFamily.display};
  font-weight: bold;
  font-size: 21px;
  margin: 4px 0 12px;
  color: ${(props) => props.theme.colors.greyDark};
`;

export const Tag = styled.span<{ color: string }>`
  padding: 0 4px;
  border-radius: 3px;
  font-family: ${fontFamily.text};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${(props) => props.color};
`;

export const PropertyDescription = styled.div`
  font-size: 13px;
  margin: 5px 0;
  color: ${(props) => props.theme.colors.greyDark};
`;

export const ContactDetails = styled.footer`
  padding-top: 4px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.greyDark};
`;

export const DataAttribute = styled.p`
  padding: 0;
  margin: 0 0 4px;
`;
