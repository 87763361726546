import React from 'react';

export const AnalyticsLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 19V14.3333H6.55556V19H5ZM8.11111 19V9.66667H9.66667V19H8.11111ZM11.2222 19V12.0292H12.7778V19H11.2222ZM14.3333 19V9.69583H15.8889V19H14.3333ZM17.4444 19V5H19V19H17.4444Z"
        fill="currentColor"
      />
    </svg>
  );
};
