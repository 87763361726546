import {
  AggregatedQuoteStipulation,
  Organization,
  Stipulation,
  StipulationType,
  User,
} from '@plus-platform/shared';
import capitalize from 'lodash/capitalize';
import { useTheme } from 'styled-components/macro';

import { formatDate, formatPercentage, formatTime } from '../../../utils/formatUtils';
import { formatNameWithInitial } from '../../tradeRequestsUtils';
import { useStipulationResources } from '../../useStipulationResources';
import {
  QuoteCard,
  QuoteCardBar,
  QuoteCardBorder,
  QuoteCardContent,
  QuoteCardDisk,
  QuoteCardHeader,
  QuoteCardKey,
  QuoteCardKeyValues,
  QuoteCardRef,
  QuoteCardStipulationLink,
  QuoteCardSubtitle,
  QuoteCardTitle,
  QuoteCardValue,
} from './Quote.styles';
import { QuoteSummaryType } from './types';

type QuoteCounterSummaryProps = {
  sender: User;
  senderOrganization: Organization;
  sentAt?: Date;
  reference: string;
  value: number;
  currentCounterIndex: number;
  totalCounterCount: number;
  type: QuoteSummaryType;
  reason?: string;
  stipulation?: Omit<AggregatedQuoteStipulation, 'quoteStipulation'> & {
    quoteStipulation?: Stipulation;
  };
};

export const QuoteCounterSummary = (props: QuoteCounterSummaryProps) => {
  const {
    currentCounterIndex,
    reason,
    reference,
    sender,
    senderOrganization,
    sentAt,
    stipulation,
    totalCounterCount,
    type,
    value,
    ...rest
  } = props;

  const { openDocument } = useStipulationResources(
    StipulationType.STIPULATION_QUOTE,
    stipulation?.quoteStipulation?.id
  );

  const theme = useTheme();

  return (
    <QuoteCard {...rest}>
      <QuoteCardBar $color={theme.colors.tradeQuoteCardBarViolet} />
      <QuoteCardContent>
        <QuoteCardHeader>
          <QuoteCardTitle>
            {`${capitalize(type)} ${currentCounterIndex + 1} of ${totalCounterCount}`}
          </QuoteCardTitle>
          <QuoteCardSubtitle>
            Received from {formatNameWithInitial(sender.lastName, sender.firstName)} @{' '}
            {senderOrganization.tradingName} on{' '}
            {formatDate(sentAt ? new Date(sentAt) : undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              weekday: 'short',
            })}{' '}
            @{' '}
            {formatTime(sentAt ? new Date(sentAt) : undefined, {
              hour: 'numeric',
              minute: 'numeric',
              hour12: false,
            })}
          </QuoteCardSubtitle>
          <QuoteCardRef>Ref: {reference}</QuoteCardRef>
        </QuoteCardHeader>
        <QuoteCardBorder />
        <QuoteCardKeyValues>
          <QuoteCardKey>Counter percentage</QuoteCardKey>
          <QuoteCardValue>{formatPercentage(value)}</QuoteCardValue>
          <QuoteCardKey>Reason or comment</QuoteCardKey>
          <QuoteCardValue>{reason ?? 'N/A'}</QuoteCardValue>
          <QuoteCardKey>Stipulations</QuoteCardKey>
          <QuoteCardValue>
            {stipulation?.quoteStipulation ? (
              <QuoteCardStipulationLink onClick={openDocument}>
                {stipulation.quoteStipulation.fileName}
              </QuoteCardStipulationLink>
            ) : (
              'N/A'
            )}
          </QuoteCardValue>
        </QuoteCardKeyValues>
      </QuoteCardContent>
      <QuoteCardDisk $variant="counter" />
    </QuoteCard>
  );
};
