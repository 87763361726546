import {
  PortfolioAnalyticMetric,
  PortfolioAnalyticOneMetricDataItem,
  VisualisationType,
} from '@plus-platform/shared';

import {
  FlagsAlertsWarnings,
  FlagsAlertsWarningsProps,
} from '../../../components/FlagsAlertsWarnings';
import { Title, Wrapper } from '../Analytics.styles';
import { Emphasis, Header } from '../AnalyticWidget.styles';
import { portfolioAnalyticMetricLabels } from '../utils';
import { BubbleChart } from './BubbleChart';

type OneMetricAnalyticTileProps = FlagsAlertsWarningsProps & {
  metric: PortfolioAnalyticMetric;
  visualisationType: VisualisationType;
  data: PortfolioAnalyticOneMetricDataItem[];
};

export const OneMetricAnalyticTile = (props: OneMetricAnalyticTileProps) => {
  const { alertsCount, data, flagsCount, metric, visualisationType, warningsCount } = props;

  const title = portfolioAnalyticMetricLabels[metric];

  return (
    <Wrapper>
      <Header>
        <Title>
          <Emphasis>{title}</Emphasis>
        </Title>
      </Header>

      {visualisationType === VisualisationType.BUBBLE_CHART && <BubbleChart data={data} />}

      <FlagsAlertsWarnings
        flagsCount={flagsCount}
        warningsCount={warningsCount}
        alertsCount={alertsCount}
      />
    </Wrapper>
  );
};
