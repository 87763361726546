import { BorrowerDetails } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getBorrowerVerifications } from '../../utils/borrowerUtils';
import { QueryKeys } from './types';

export const useGetBorrowerVerificationsQuery = (borrowerDetails: BorrowerDetails) =>
  useQuery([QueryKeys.BORROWER_VERIFICATIONS, borrowerDetails], async () => {
    return await getBorrowerVerifications(borrowerDetails);
  });
