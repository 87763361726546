import { getTradeMessage, TradeMessageId } from './tradeMessagesUtil';

type TradeMessageProps = {
  id: TradeMessageId;
  values?: Record<string, string | number>;
};

export const TradeMessage = ({ id, values }: TradeMessageProps) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: getTradeMessage(id, values),
      }}
    />
  );
};
