import { OrganizationMember } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const getOrganizationMembers = async () => {
  const url = makeApiUrl(`organizations/members`);
  return getRequestWithAuth<OrganizationMember[]>(url);
};

export const useGetOrganizationMembersQuery = () =>
  useQuery([QueryKeys.ORGANIZATION_MEMBERS], async () => {
    return await getOrganizationMembers();
  });
