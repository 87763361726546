import { PortfolioSettings } from '@plus-platform/shared';

import { makeApiUrl, putRequestWithAuth } from '../../utils/apiUtils';

export const updatePortfolioSettings = (settings: PortfolioSettings) => {
  const url = makeApiUrl('portfolio/settings');

  return putRequestWithAuth(url, {
    body: JSON.stringify(settings),
  });
};
