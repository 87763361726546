import styled from 'styled-components/macro';

export const AccessDenied = () => (
  <Wrapper>
    <p>You have insufficient permissions to view this content.</p>
    <p>If you believe this is a mistake, please contact us!</p>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
`;
