import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { AddCircleIcon } from '../../components/icons/AddCircleIcon';
import { PlusIcon } from '../../components/icons/PlusIcon';
import { InviteUserModal } from '../InviteUserModal/InviteUserModal';

export const MenuItem = styled.div<{
  $isDisabled?: boolean;
  $isTitle?: boolean;
}>`
  ${({ $isDisabled, $isTitle, theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: ${$isTitle ? '700' : '500'};
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: ${$isTitle ? theme.colors.darkBlue : theme.colors.blue40};
    opacity: ${$isDisabled ? '0.5' : '1'};
    margin: 10px 0;
    text-decoration: none;
    background: none;
    outline: 0;
    width: 100%;
    border: 0;
    padding: 0;
    white-space: nowrap;

    &:hover {
      cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};
    }

    &:first-child {
      margin-top: 2px;
    }

    &:last-child {
      margin-bottom: 2px;
    }
  `}
`;

export const IconWrapper = styled.div<{ $disabled?: boolean; $color?: string }>`
  ${({ $color = '#203D44', $disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background: white;
    opacity: ${$disabled ? '0.5' : '1'};
    margin-right: 8px;
    color: ${$color};

    svg {
      stroke: ${$color};
      fill: ${$color};
    }
  `}
`;

const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 6px 12px;
  gap: 10px;

  ${MenuItem} {
    margin: 0;
  }
`;

export const OrganizationMembersActionBar = () => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

  return (
    <ActionsBar>
      <MenuItem $isTitle>
        <IconWrapper $color="#00E09D">
          <PlusIcon />
        </IconWrapper>
        You can...
      </MenuItem>
      <MenuItem onClick={() => setIsInviteModalOpen(true)}>
        <IconWrapper>
          <AddCircleIcon />
        </IconWrapper>
        Add new user
      </MenuItem>
      {isInviteModalOpen && (
        <InviteUserModal isOpen={isInviteModalOpen} onClose={() => setIsInviteModalOpen(false)} />
      )}
      <MenuItem $isDisabled>
        <IconWrapper $disabled>
          <AddCircleIcon />
        </IconWrapper>
        Edit profile
      </MenuItem>
      <MenuItem $isDisabled>
        <IconWrapper $disabled>
          <AddCircleIcon />
        </IconWrapper>
        Change account type
      </MenuItem>
      <MenuItem $isDisabled>
        <IconWrapper $disabled>
          <AddCircleIcon />
        </IconWrapper>
        Deactivate user account
      </MenuItem>
      <MenuItem $isDisabled>
        <IconWrapper $disabled>
          <AddCircleIcon />
        </IconWrapper>
        Delete user account
      </MenuItem>
    </ActionsBar>
  );
};
