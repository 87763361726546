import styled, { css } from 'styled-components/macro';

export const Bar = styled.div<{
  $color?: string;
  $percentage?: number;
}>(
  ({ $color, $percentage, theme }) => css`
    width: ${$percentage ?? 33}%;
    height: 4px;
    background-color: ${$color ?? theme.colors.white05};
  `
);

export const ProgressBar = styled.div`
  display: flex;
  column-gap: 4px;

  ${Bar} {
    &:first-of-type {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }

    &:last-of-type {
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }
`;
