import { transparentize } from 'polished';
import styled from 'styled-components/macro';

export const MessageActionHint = styled.span`
  display: block;
  padding-top: 10px;
  color: ${({ theme }) => transparentize(0.5, theme.colors.white)};
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
`;
