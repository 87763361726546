import { PortfolioSummary } from '@plus-platform/shared';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Button } from '../components/Button';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader, PageSectionTitleWrapper } from '../components/Page';
import { Skeleton } from '../components/Skeleton';
import { LoanDetailedSummary } from '../loans/DeprecatedLoanSummary';
import { formatDefaultValue } from '../utils/formatUtils';
import { PoolAddLoansTable } from './PoolAddLoansTable';
import { PoolPanel, PoolWrapper } from './PoolDetailPage';
import { PoolMenu } from './PoolMenu';

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

type AddLoansToPoolPageProps = {
  poolName?: string;
  summary?: PortfolioSummary;
};

type AddLoansToPoolRouteParams = {
  poolId: string;
};

export const AddLoansToPoolPage = ({ poolName, summary }: AddLoansToPoolPageProps) => {
  const { poolId } = useParams<AddLoansToPoolRouteParams>();
  const history = useHistory();
  const theme = useTheme();

  return (
    <Page>
      <PageHeader>
        <Heading>{formatDefaultValue(poolName)}</Heading>
        <Breadcrumb>
          {poolName && (
            <React.Fragment>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem linkTo="/pools" title="Pools" />
              <BreadcrumbItem linkTo={`/pool/${poolId}`} title={poolName ?? ''} />
              <BreadcrumbItem title="Add Loans" />
            </React.Fragment>
          )}
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        {poolName && summary ? (
          <PoolPanel style={{ marginBottom: 8 }}>
            <PoolWrapper>
              <PageSectionTitleWrapper>
                <div />
                <PoolMenu loansCount={summary.loansCount} poolId={poolId} />
              </PageSectionTitleWrapper>
              <LoanDetailedSummary summary={summary} variant="pool" />
              <Content>
                <Button
                  $variant="outlined"
                  onClick={() => {
                    history.push(`/pool/${poolId}`);
                  }}
                  $color="tertiary"
                >
                  Back to Pool view
                </Button>
              </Content>
            </PoolWrapper>
          </PoolPanel>
        ) : (
          <Skeleton background={theme.colors.greenTree} height={205} />
        )}

        {poolId && <PoolAddLoansTable poolId={poolId} />}
      </PageContent>
    </Page>
  );
};
