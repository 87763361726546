import { SettingsAlertType } from '../types';

export const ADMIN_GENERAL_DEFAULT_SETTINGS = {
  general: 4,
  vigilance: 4,
  significance: 4,
};

export const ADMIN_ANALYTIC_DEFAULT_SETTINGS = {
  generalBehaviorUpdateFrequency: {
    general: 4,
    vigilance: 4,
    significance: 4,
  },
  notifications: {
    shouldAllowNotifications: true,
  },
  bannersAlertsGroups: {
    alertType: SettingsAlertType.BANNERS,
  },
  shouldAllowTimeSensitiveNotifications: true,
  shouldPreviewNotifications: true,
  shouldAllowNotificationGrouping: true,
};
