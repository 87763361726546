import { Filter } from '@plus-platform/shared';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import { getMaxVisibleItems, TableWrapper } from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { CompletedTradesTable } from './CompletedTradesTable';
import { useCompletedTradeRequests } from './useCompletedTradeRequests';

export const TradeRequestsCompleted = () => {
  const { fetchNextPage, isFetchingNextPage, isLoading, totalCount, tradeRequests } =
    useCompletedTradeRequests();

  // TODO: Replace with real filter implementation
  const filters: Filter[] = [];
  const isFiltersLoading = false;
  const updateFilters = () => undefined;

  return (
    <TableFilters
      filters={filters}
      isLoading={isFiltersLoading}
      onFiltersUpdate={updateFilters}
      path="/trades/completed"
    >
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({ closeLeftPanel, containerHeight, isLeftPanelOpen, openLeftPanel }) => (
            <>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={updateFilters}
                />
              )}
              <Card $hasFlex>
                <TableToolbar>
                  <TableStats totalCount={totalCount} visibleCount={tradeRequests.length} />
                  <Divider $orientation="vertical" $flexItem />
                  <TableFilterBar
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={updateFilters}
                  />
                </TableToolbar>
                <ActivityIndicator contain isActive={isLoading}>
                  <TableWrapper>
                    <CompletedTradesTable
                      fetchNextPage={fetchNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      tradeRequests={tradeRequests}
                      maxVisibleItems={getMaxVisibleItems({
                        containerHeight,
                        hasHeaderGroups: false,
                      })}
                      totalCount={totalCount}
                    />
                  </TableWrapper>
                </ActivityIndicator>
              </Card>
            </>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};
