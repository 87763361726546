import { Identifier } from './global';

export type FilterValue = string | number | boolean;

export enum FilterType {
  PORTFOLIO_FILTER = 'PORTFOLIO_FILTER',
}

export enum FilterKey {
  EMPTY = '',
  ID = 'id',
  IS_DELINQUENT = 'isDelinquent',
  STATE = 'state',
  PURCHASE_PRICE = 'purchasePrice',
  CURRENT_BPO = 'currentBPO',
  DOWN_PAYMENT_AMOUNT = 'downPaymentAmount',
  ORIGINAL_UPB = 'originalUPB',
  CURRENT_UPB = 'currentUPB',
  CURRENT_LTV = 'currentLTV',
  PRINCIPAL = 'principal',
  INTEREST = 'interest',
  TAXES = 'taxes',
  INSURANCE = 'insurance',
  HOA_FEE = 'hoaFee',
  MORTGAGE_INSURANCE = 'mortgageInsurance',
  ORIGINATOR = 'originator',
  LOAN_TYPE = 'loanType',
  NOTE_RATE = 'noteRate',
}

export enum FilterOperator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

export type Filter = {
  id: Identifier;
  key: FilterKey;
  operator: FilterOperator;
  name?: string;
  value?: FilterValue;
  isApplied: boolean;
  isDeleted?: boolean;
};

export type FilterCreation = Omit<Filter, 'id'>;
