import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { CardVariant } from '../components/Card';
import { HeadingLink } from '../components/HeadingLink';
import { Key } from '../components/KeyValue';
import { Cell, HeaderCell, HeaderRow, Row as TableRow, Table, Thead } from '../components/Table';

export const Widgets = styled.div`
  display: flex;
  padding-bottom: 8px;
`;

export const WidgetCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 0;
  flex-shrink: 0;

  & + & {
    margin-left: 8px;
  }
`;

export const LoanWidget = styled.div<{ $variant?: CardVariant }>(
  ({ $variant = 'default', theme }) => css`
    border-radius: 4px;
    background: ${$variant === 'light' ? theme.colors.white05 : theme.colors.deprecatedPrimary01};
    padding: 8px 12px 12px 12px;
    scroll-margin-top: 50px;

    ${HeadingLink} {
      margin-bottom: 10px;
    }

    & + & {
      margin-top: 8px;
    }
  `
);

export const CensusTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.white70};
    ${theme.typography.bodyText.bodyRegular02}
  `
);

export const WidgetTitle = styled.h2(
  ({ theme }) => css`
    margin: 0 0 12px;
    padding: 0;
    font-family: ${theme.typography.fontFamily.display};
    color: ${theme.colors.white};
    ${theme.typography.headings.headingMedium03}
  `
);

export const Actions = styled.div`
  color: ${({ theme }) => transparentize(0.4, theme.colors.white)};
  font-size: 14px;
  line-height: 22px;
  font-family: ${(props) => props.theme.typography.fontFamily.text};
  font-weight: 400;
`;

export const ActionTitle = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: ${({ theme }) => transparentize(0.2, theme.colors.white)};
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionCategory = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  svg {
    margin-right: 10px;
  }
`;

export const ActionText = styled.p`
  margin: 0;
  padding: 0;
`;

export const DocumentThead = styled(Thead)`
  margin: 0;
`;

export const DocumentTableHeaderRow = styled(HeaderRow)`
  grid-template-columns: 144px repeat(3, 1fr);
  box-shadow: none;
`;

export const DocumentHeaderCell = styled(HeaderCell)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    line-height: 20px;
    color: ${theme.colors.white};
  `
);

export const DocumentTableRow = styled(TableRow)`
  grid-template-columns: 144px repeat(3, 1fr);
  pointer-events: none;
  box-shadow: none;
`;

export const DocumentCell = styled(Cell)`
  height: auto;
  ${({ theme }) => theme.typography.bodyText.bodyMedium02}
  text-align: center;

  &:nth-of-type(1) {
    text-align: right;
    color: ${({ theme }) => theme.colors.white70};
    ${({ theme }) => theme.typography.bodyText.bodyRegular02}
  }
`;

export const DocumentTable = styled(Table)`
  width: auto;
`;

export const PropertyScores = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const CitationText = styled.p`
  font-size: 12px;
`;

export const TitleKey = styled(Key)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    ${theme.typography.bodyText.bodyMedium02}
  `
);
