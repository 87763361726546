import { transparentize } from 'polished';
import styled from 'styled-components/macro';

import { ButtonLoader as _ButtonLoader } from '../components/ButtonLoader';

export const SubTitle = styled.h2`
  margin: 0;
  padding: 0;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  font-family: ${(props) => props.theme.typography.fontFamily.display};
  color: ${(props) => transparentize(0.2, props.theme.colors.white)};
`;

export const Section = styled.section`
  padding: 24px 0;
`;

export const PasswordForm = styled.form``;

export const PasswordFields = styled.div`
  display: flex;
  column-gap: 16px;
  padding-bottom: 16px;
`;

export const ButtonLoader = styled(_ButtonLoader)`
  margin-left: auto;
`;
