import styled, { css } from 'styled-components/macro';

export type ToggleGroupStyleProps = {
  $spaced?: boolean;
};

export const ToggleGroup = styled.div<ToggleGroupStyleProps>(
  ({ $spaced, theme }) => css`
    display: inline-flex;
    flex-direction: row;
    gap: ${$spaced ? 8 : 2}px;
    background: ${$spaced ? 'transparent' : theme.colors.white05};
    border-radius: 4px;
    padding: 1px;
    width: fit-content;
    height: fit-content;
  `
);
