import {
  MISMOAmount,
  MISMOCount,
  MISMODatetime,
  MISMOEntity,
  MISMOIdentifier,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum AntiSteeringComparisonType {
  LowestInterestRate = 'LowestInterestRate',
  LowestInterestRateWithoutCertainFeatures = 'LowestInterestRateWithoutCertainFeatures',
  LowestTotalAmountForOriginationPointsOrFeesAndDiscountPoints = 'LowestTotalAmountForOriginationPointsOrFeesAndDiscountPoints',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
}

enum BorrowerSelectedAntiSteeringComparisonType {}

export enum FNMHomeImprovementProductType {
  ActualActualBiweekly = 'ActualActualBiweekly',
  ConstructionToPermanent = 'ConstructionToPermanent',
  DailySimpleInterestCashConventional = 'DailySimpleInterestCashConventional',
  DailySimpleInterestMBS = 'DailySimpleInterestMBS',
  GovernmentTitleI = 'GovernmentTitleI',
  Universal = 'Universal',
}

enum GFEComparisonType {
  NotApplicable = 'NotApplicable',
  Other = 'Other',
  SameLoanWithLowerInterestRate = 'SameLoanWithLowerInterestRate',
  SameLoanWithLowerSettlementCharges = 'SameLoanWithLowerSettlementCharges',
}

enum LoanProductStatusType {
  Available = 'Available',
  Eligible = 'Eligible',
  Ineligible = 'Ineligible',
  Offered = 'Offered',
  Requested = 'Requested',
  Selected = 'Selected',
}

enum ProductProviderType {
  Investor = 'Investor',
  Lender = 'Lender',
  Other = 'Other',
}

enum LoanPriceQuoteType {
  BuySide = 'BuySide',
  Other = 'Other',
  SellSide = 'SellSide',
}

enum LockStatusType {
  CanceledOrWithdrawn = 'CanceledOrWithdrawn',
  Expired = 'Expired',
  Locked = 'Locked',
  ModificationRequested = 'ModificationRequested',
  Modified = 'Modified',
  None = 'None',
  Other = 'Other',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Requested = 'Requested',
}

export type Lock = MISMOEntity &
  Partial<{
    loanPriceQuoteIdentifier: MISMOIdentifier;
    loanPriceQuoteType: LoanPriceQuoteType;
    loanPriceQuoteTypeOtherDescription: MISMOString;
    lockDatetime: MISMODatetime;
    lockDurationDaysCount: MISMOCount;
    lockExpirationDatetime: MISMODatetime;
    lockIdentifier: MISMOIdentifier;
    lockRequestedExtensionDaysCount: MISMOCount;
    lockStatusType: LockStatusType;
    lockStatusTypeOtherDescription: MISMOString;
    priceRequestIdentifier: MISMOIdentifier;
    priceResponseIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type LoanProduct = MISMOEntity &
  Partial<{
    antiSteeringComparisonType: AntiSteeringComparisonType;
    antiSteeringComparisonTypeOtherDescription: MISMOString;
    borrowerSelectedAntiSteeringComparisonType: BorrowerSelectedAntiSteeringComparisonType;
    borrowerSelectedAntiSteeringComparisonTypeOtherDescription: MISMOString;
    discountPointsTotalAmount: MISMOAmount;
    FNMHomeImprovementProductType: FNMHomeImprovementProductType;
    GFEComparisonType: GFEComparisonType;
    GFEComparisonTypeOtherDescription: MISMOString;
    loanProductStatusType: LoanProductStatusType;
    negotiatedRequirementDescription: MISMOString;
    originationPointsOrFeesTotalAmount: MISMOAmount;
    originationPointsOrFeesTotalRatePercent: MISMOPercent;
    productDescription: MISMOString;
    productIdentifier: MISMOIdentifier;
    productName: MISMOString;
    productProviderName: MISMOString;
    productProviderType: ProductProviderType;
    productProviderTypeOtherDescription: MISMOString;
    refinanceProgramIdentifier: MISMOIdentifier;
    settlementChargesAmount: MISMOAmount;
    // disqualificationReasons: DisqualificationReason[];
    // loanPriceQuotes: LoanPriceQuote[];
    // NOTE: this has been renamed from PRICE_LOCKS in ULDD
    locks: Lock[];
    // productCategory: ProductCategory;
    // productComponents: ProductComponent[];
  }>;
