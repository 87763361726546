import styled, { css } from 'styled-components/macro';

export const Divider = styled.div`
  display: grid;
  grid-template-columns: auto max-content auto;
  align-items: center;
  overflow-anchor: none;
`;

type DateBlockProps = {
  $variant?: 'solid' | 'outline';
};

export const DateBlock = styled.div<DateBlockProps>(
  ({ $variant = 'solid', theme }) => css`
    ${theme.typography.headings.headingMedium04}

    display: inline-block;
    width: auto;
    padding: 2px 9px;
    text-align: center;
    box-shadow: inset 0 0 0 1px ${theme.colors.primary03};
    border-radius: 10px;
    color: ${theme.colors.textPrimary03};

    ${$variant === 'solid' &&
    css`
      background: ${theme.colors.primary03};
    `}

    ${$variant === 'outline' &&
    css`
      background: transparent;
    `}
  `
);

export const Line = styled.div(
  ({ theme }) => css`
    height: 1px;
    background: ${theme.colors.primary03};
  `
);
