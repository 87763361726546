import React from 'react';

export const BurgerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 18 16"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2" cy="2" fill="currentColor" r="2" />
      <circle cx="2" cy="8" fill="currentColor" r="2" />
      <circle cx="2" cy="14" fill="currentColor" r="2" />
      <circle cx="9" cy="2" fill="currentColor" r="2" />
      <circle cx="9" cy="8" fill="currentColor" r="2" />
      <circle cx="9" cy="14" fill="currentColor" r="2" />
      <circle cx="16" cy="2" fill="currentColor" r="2" />
      <circle cx="16" cy="8" fill="currentColor" r="2" />
      <circle cx="16" cy="14" fill="currentColor" r="2" />
    </svg>
  );
};
