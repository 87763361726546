import { ellipsis, transparentize } from 'polished';
import React from 'react';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { fontFamily } from '../styles/typography';

export const TABLE_MARGIN = 16;
export const TABLE_TOOLBAR_HEIGHT = 49;
export const TABLE_ROW_HEIGHT = 34;
export const DEPRECATED_TABLE_ROW_HEIGHT = 33;
export const TABLE_HEADER_HEIGHT_WITH_HEADER_GROUP = 83;
export const TABLE_HEADER_ROW_HEIGHT = 41;
export const TABLE_HEADER_GROUP_ROW_HEIGHT = 41;

export const getMaxVisibleItems = ({
  containerHeight,
  hasHeaderGroups,
}: {
  containerHeight?: number;
  hasHeaderGroups?: boolean;
}) => {
  if (!containerHeight) {
    return;
  }

  const offset = hasHeaderGroups ? TABLE_HEADER_HEIGHT_WITH_HEADER_GROUP : TABLE_HEADER_ROW_HEIGHT;

  const totalHeight = containerHeight - offset - TABLE_MARGIN;

  return Math.max(Math.floor(totalHeight / TABLE_ROW_HEIGHT), 0);
};

type TableWrapperProps = {
  $maxVisibleItems?: number;
  $headerHeightOffset?: number;
};

const getTableHeight = (maxVisibleItems?: number, headerHeightOffset?: number) => {
  if (!maxVisibleItems) {
    return 600;
  }
  const headerHeight = TABLE_HEADER_ROW_HEIGHT + (headerHeightOffset ?? 0);
  const rowsHeight = maxVisibleItems * TABLE_ROW_HEIGHT;

  return headerHeight + rowsHeight;
};

export const TableWrapper = styled.div<TableWrapperProps>(
  ({ $headerHeightOffset, $maxVisibleItems }) => css`
    position: relative;

    ${$maxVisibleItems &&
    css`
      height: ${getTableHeight($maxVisibleItems, $headerHeightOffset)}px;
    `}

    ${!$maxVisibleItems &&
    css`
      flex: 1;
    `}
  `
);

export const Table = styled.div`
  display: table;
  width: 100%;
`;

export const Thead = styled.div`
  margin: 0 ${TABLE_MARGIN}px;
`;

export const Tbody = styled.div`
  user-select: none;
`;

type CellProps = {
  align?: React.CSSProperties['textAlign'];
  maxWidth?: string;
  $variant?: 'portfolio' | 'pool';
  $isClickable?: boolean;
};

const cellStyles = css<CellProps>`
  ${({ $isClickable, align, maxWidth, theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}

    height: ${TABLE_ROW_HEIGHT}px;
    line-height: ${TABLE_ROW_HEIGHT}px;
    text-align: ${align ?? 'left'};
    color: ${theme.colors.textPrimary01};

    ${ellipsis()}

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `};

    ${$isClickable &&
    css`
      position: relative;
      cursor: pointer;

      &:hover {
        color: ${theme.colors.greenLight};
      }
    `}

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  `}
`;

export const HeaderCell = styled.div<
  CellProps & { $gridColumn?: React.CSSProperties['gridColumn'] }
>(
  ({ $gridColumn, theme }) => css`
    ${cellStyles}
    ${theme.typography.headings.headingMedium04}

    color: ${theme.colors.textPrimary03};
    height: ${TABLE_HEADER_ROW_HEIGHT}px;
    line-height: ${TABLE_HEADER_ROW_HEIGHT}px;

    ${$gridColumn &&
    css`
      grid-column: ${$gridColumn};
    `}
  `
);

export const GroupedHeaderCell = styled(HeaderCell)(
  ({ theme }) => css`
    position: relative;
    height: ${TABLE_HEADER_GROUP_ROW_HEIGHT}px;
    line-height: ${TABLE_HEADER_GROUP_ROW_HEIGHT}px;

    &:not(:last-of-type) {
      &::after {
        content: '';
        position: absolute;
        right: 4px;
        top: 8px;
        width: 1px;
        height: 24px;
        background: ${theme.colors.white05};
      }
    }
  `
);

export const Cell = styled.div<CellProps>`
  ${cellStyles}
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const CheckboxCell = styled(Cell)``;

type RowProps = {
  $columnCount: number;
  $isSelectable?: boolean;
  $isSelected?: boolean;
  $isDisabled?: boolean;
  $isClickable?: boolean;
  $variant?: 'portfolio' | 'pool';
};

const CHECKBOX_WIDTH = 30;

type rowStyles = ThemeProps<DefaultTheme> & RowProps;

const selectedRowStyles = ({ theme }: rowStyles) => css`
  margin: 0 -${TABLE_MARGIN}px;
  padding: 0 ${TABLE_MARGIN}px;
  background-color: color-mix(
    in srgb,
    ${theme.colors.deprecatedPrimary01},
    ${theme.colors.white} 15%
  );
  box-shadow: inset 0 -1px 0 0 color-mix(in srgb, ${theme.colors.deprecatedPrimary01}, ${theme
          .colors.white}
        15%);
  color: ${theme.colors.white};
`;

const disabledRowStyles = () => css`
  margin: 0 -${TABLE_MARGIN}px;
  padding: 0 ${TABLE_MARGIN}px;
  pointer-events: none;
  opacity: 0.2;
`;

const clickableRowStyles = ({ theme }: rowStyles) => css`
  &:hover {
    margin: 0 -${TABLE_MARGIN}px;
    padding: 0 ${TABLE_MARGIN}px;
    cursor: pointer;
    background-color: ${theme.colors.white05};
    box-shadow: inset 0 1px
      color-mix(in srgb, ${theme.colors.deprecatedPrimary01}, ${theme.colors.white} 5%);
  }
`;

export const Row = styled.div.attrs<RowProps>((props) => ({
  ...props,
  $isClickable: props.$isClickable ?? true,
  $variant: props.$variant ?? 'portfolio',
}))<RowProps>(
  ({ $columnCount, $isClickable, $isDisabled, $isSelectable, $isSelected, $variant, theme }) => {
    let columnCount = $variant === 'pool' ? $columnCount - 1 : $columnCount;

    if ($isSelectable) {
      columnCount = columnCount - 1;
    }

    return css`
      display: grid;
      column-gap: 8px;
      grid-template-columns: ${$isSelectable
        ? `${CHECKBOX_WIDTH}px repeat(${columnCount}, 1fr)`
        : `repeat(${columnCount}, 1fr)`};
      box-shadow: inset 0 -1px ${theme.colors.primary03};

      ${$isSelected && selectedRowStyles}

      ${$isDisabled && disabledRowStyles}

      ${$isClickable && clickableRowStyles}
    `;
  }
);

export const HeaderRow = styled(Row)`
  pointer-events: none;
  border-top: none;
  margin: 0;
  padding: 0;
`;

export const GroupedHeaderRow = styled(HeaderRow)`
  ${({ $isSelectable }) => css`
    pointer-events: none;

    ${$isSelectable &&
    css`
      /* Padding for Checkbox + Gap */
      padding-left: calc(30px + 8px);
    `}

    div:first-child {
      padding-left: 0;
    }
  `}
`;

const groupedRowStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${TABLE_ROW_HEIGHT}px;
  font-size: 12px;
  font-family: ${fontFamily.text};
  font-weight: 300;
`;

export const LoadingRow = styled.div`
  ${groupedRowStyles};
  color: ${({ theme }) => theme.colors.white};
`;

export const ErrorRow = styled.div`
  ${groupedRowStyles};
  color: ${({ theme }) => theme.colors.redMain};
`;

export const TableSummaryHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
`;

export const TableSummaryCount = styled.span(
  ({ theme }) => css`
    color: ${transparentize(0.5, theme.colors.white)};
    font-size: 12px;

    > strong {
      color: ${theme.colors.white};
      font-weight: bold;
    }
  `
);
