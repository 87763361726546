import { transparentize } from 'polished';
import { useEffect } from 'react';
import styled from 'styled-components/macro';

import { DefaultButton } from '../../components/Button';
import { useGetSubmissionDocumentImageQuery } from '../../hooks/queries';

export type DocumentViewerPage = {
  id: number;
  filePageNumber: number;
  imageUrl: string;
};

const PageButton = styled(DefaultButton)<{ $isSelected?: boolean }>`
  width: auto;
  height: auto;
  padding: 8px;
  background: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.purpleSecondary : theme.colors.tradeChatButtonPurple};
  border-radius: 4px;

  & + & {
    margin-top: 8px;
  }

  color: ${({ $isSelected, theme }) =>
    $isSelected ? 'inherit' : transparentize(0.2, theme.colors.white)};
`;

const PageTitle = styled.h3`
  margin: 6px 0 2px;
  font-size: 13px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  text-align: center;
`;

type OcrulusPageViewProps = {
  id: number;
  submissionId: string;
  documentName: string;
  selectedPageId?: DocumentViewerPage['id'];
  loadedPageIds: DocumentViewerPage['id'][];
  onPageClick: (pageId: DocumentViewerPage['id']) => void;
  onPageLoaded: (pageId: DocumentViewerPage['id']) => void;
};

export const PageView = ({
  documentName,
  id,
  loadedPageIds,
  onPageClick,
  onPageLoaded,
  selectedPageId,
  submissionId,
}: OcrulusPageViewProps) => {
  const { data, isSuccess } = useGetSubmissionDocumentImageQuery(submissionId, documentName, id);

  useEffect(() => {
    if (isSuccess && !loadedPageIds.includes(id)) {
      onPageLoaded(id);
    }
  }, [isSuccess, loadedPageIds, id, onPageLoaded]);

  if (!data) {
    return null;
  }

  return (
    <PageButton $isSelected={selectedPageId === id} onClick={() => onPageClick(id)}>
      <Image alt={`Page ${id}`} src={data} />
      <PageTitle>Page {id}</PageTitle>
    </PageButton>
  );
};
