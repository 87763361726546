import {
  ADCOCouponYieldData,
  ADCOSupportedIndices,
  ADCOSwaptionTerm,
  ADCOSwaptionVolatilitiesData,
  ADCOYieldCurveData,
  ADCOYieldCurveTerm,
} from '../types';

export const MOCK_YIELD_CURVES: ADCOYieldCurveData[] = [
  {
    term: ADCOYieldCurveTerm['1M'],
    treasury: 4.65,
    libor: 4.662,
  },
  {
    term: ADCOYieldCurveTerm['3M'],
    treasury: 4.88,
    libor: 4.96243,
  },
  {
    term: ADCOYieldCurveTerm['6M'],
    treasury: 5.17,
    libor: 5.27673,
  },
  {
    term: ADCOYieldCurveTerm['1Y'],
    treasury: 5.02,
    libor: 5.5407,
  },
  {
    term: ADCOYieldCurveTerm['2Y'],
    treasury: 4.81,
    libor: 5.1671,
  },
  {
    term: ADCOYieldCurveTerm['3Y'],
    treasury: 4.51,
    libor: 4.7352,
  },
  {
    term: ADCOYieldCurveTerm['4Y'],
    treasury: null,
    libor: 4.4584,
  },
  {
    term: ADCOYieldCurveTerm['5Y'],
    treasury: 4.18,
    libor: 4.272,
  },
  {
    term: ADCOYieldCurveTerm['7Y'],
    treasury: 4.07,
    libor: 4.0695,
  },
  {
    term: ADCOYieldCurveTerm['10Y'],
    treasury: 3.92,
    libor: 3.9336,
  },
  {
    term: ADCOYieldCurveTerm['15Y'],
    treasury: null,
    libor: 3.8669,
  },
  {
    term: ADCOYieldCurveTerm['20Y'],
    treasury: 4.1,
    libor: 3.7789,
  },
  {
    term: ADCOYieldCurveTerm['25Y'],
    treasury: null,
    libor: 3.6482,
  },
  {
    term: ADCOYieldCurveTerm['30Y'],
    treasury: 3.93,
    libor: 3.5226,
  },
];

export const MOCK_CURRENT_COUPON_YIELDS: ADCOCouponYieldData[] = [
  {
    index: ADCOSupportedIndices['FN30'],
    rate: 5.515,
  },
  {
    index: ADCOSupportedIndices['FN15'],
    rate: 5.036,
  },
  {
    index: ADCOSupportedIndices['FN07'],
    rate: 3.225,
  },
  {
    index: ADCOSupportedIndices['FG30'],
    rate: 1.855,
  },
  {
    index: ADCOSupportedIndices['FG15'],
    rate: 1.178,
  },
  {
    index: ADCOSupportedIndices['FG07'],
    rate: 3.395,
  },
  {
    index: ADCOSupportedIndices['FG05'],
    rate: 3.339,
  },
  {
    index: ADCOSupportedIndices['GN30'],
    rate: 5.3727,
  },
  {
    index: ADCOSupportedIndices['GN15'],
    rate: 5.007,
  },
  {
    index: ADCOSupportedIndices['LIB6MC'],
    rate: 5.2711,
  },
  {
    index: ADCOSupportedIndices['LIB1YC'],
    rate: 5.6914,
  },
  {
    index: ADCOSupportedIndices['COFINA'],
    rate: 1.16,
  },
  {
    index: ADCOSupportedIndices['COFI'],
    rate: 0.223,
  },
  {
    index: ADCOSupportedIndices['PRIME'],
    rate: 7.75,
  },
  {
    index: ADCOSupportedIndices['SOFR'],
    rate: 4.55,
  },
  {
    index: ADCOSupportedIndices['SOFR30A'],
    rate: 4.5256,
  },
  {
    index: ADCOSupportedIndices['SOFR90A'],
    rate: 4.3147,
  },
  {
    index: ADCOSupportedIndices['SOFR180A'],
    rate: 3.7204,
  },
];

export const MOCK_SWAPTION_VOLATILITIES: ADCOSwaptionVolatilitiesData[] = [
  {
    term: ADCOSwaptionTerm['3M'],
    vol_1Y: 26.005,
    vol_2Y: 36.52,
    vol_10Y: 32.479,
  },
  {
    term: ADCOSwaptionTerm['6M'],
    vol_1Y: 26.851,
    vol_2Y: 31.178,
    vol_10Y: 29.219,
  },
  {
    term: ADCOSwaptionTerm['1Y'],
    vol_1Y: 30.193,
    vol_2Y: 35.52,
    vol_10Y: 28.754,
  },
  {
    term: ADCOSwaptionTerm['2Y'],
    vol_1Y: 37.742,
    vol_2Y: 37.144,
    vol_10Y: 31.025,
  },
  {
    term: ADCOSwaptionTerm['3Y'],
    vol_1Y: 37.86,
    vol_2Y: 36.49,
    vol_10Y: 27.059,
  },
  {
    term: ADCOSwaptionTerm['5Y'],
    vol_1Y: 32.89,
    vol_2Y: 31.52,
    vol_10Y: 25.572,
  },
  {
    term: ADCOSwaptionTerm['7Y'],
    vol_1Y: 28.51,
    vol_2Y: 27.47,
    vol_10Y: 23.586,
  },
  {
    term: ADCOSwaptionTerm['10Y'],
    vol_1Y: 23.83,
    vol_2Y: 23.06,
    vol_10Y: 21.081,
  },
];
