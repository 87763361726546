import React from 'react';

export const SendIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 18 18"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2.05469 1.68896L3.07844 8.85524L3.65725 8.99994L3.07844 9.14464L2.05469 16.3109L16.6766 8.99994L2.05469 1.68896ZM4.42074 7.64464L3.9445 4.31091L13.3225 8.99993L3.9445 13.689L4.42074 10.3552L9.84193 8.99993L4.42074 7.64464Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
