import React from 'react';

export const ChatLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 16.8C7.77333 16.8 7.58333 16.7233 7.43 16.57C7.27667 16.4167 7.2 16.2267 7.2 16V14.4H17.6V7.2H19.2C19.4267 7.2 19.6167 7.27667 19.77 7.43C19.9233 7.58333 20 7.77333 20 8V20L16.8 16.8H8ZM4 16V4.8C4 4.57333 4.07667 4.38333 4.23 4.23C4.38333 4.07667 4.57333 4 4.8 4H15.2C15.4267 4 15.6167 4.07667 15.77 4.23C15.9233 4.38333 16 4.57333 16 4.8V12C16 12.2267 15.9233 12.4167 15.77 12.57C15.6167 12.7233 15.4267 12.8 15.2 12.8H7.2L4 16ZM14.4 11.2V5.6H5.6V11.2H14.4Z"
        fill="currentColor"
      />
    </svg>
  );
};
