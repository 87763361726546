import styled from 'styled-components/macro';

export const Title = styled.h2`
  padding: 4px;

  ${({ theme }) => theme.typography.headings.headingMedium01}
`;

export const Tags = styled.ul`
  display: flex;
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 12px 4px 16px;
`;

export const Tag = styled.li`
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.colors.white05};
  border-radius: 1em;
  border: 1px solid ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography.headings.headingRegular04}
`;

export const Analytics = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  > * {
    width: auto;
    background-color: ${({ theme }) => theme.colors.white05};
  }
`;
