import { ellipsis, transparentize } from 'polished';
import styled, {
  css,
  DefaultTheme,
  StyledComponentPropsWithRef,
  ThemeProps,
} from 'styled-components/macro';

import { DefaultButton } from './Button';
import { ChevronDownIcon, CloseLineIcon } from './icons';

type variantStyleProps = ThemeProps<DefaultTheme>;

const variantDarkStyles = ({ theme }: variantStyleProps) => css`
  background-color: ${theme.colors.tradeMessengerPurple};
  box-shadow: ${theme.shadows.tradeShadow};

  ${TagLabel} {
    color: ${transparentize(0.4, theme.colors.white)};
  }

  ${TagHelperText} {
    color: ${transparentize(0.25, theme.colors.white)};
  }

  ${TagActionButton} {
    color: ${transparentize(0.5, theme.colors.white)};

    &:hover {
      color: ${({ theme }) => transparentize(0.25, theme.colors.white)};
    }
  }
`;

const variantVioletStyles = ({ theme }: variantStyleProps) => css`
  background-color: ${theme.colors.purpleViolet};
  border: 1px solid ${transparentize(0.95, theme.colors.white)};
  box-shadow: 0px 4px 4px rgba(6, 9, 32, 0.15);

  ${TagLabel} {
    color: ${transparentize(0.2, theme.colors.white)};
  }

  ${TagHelperText} {
    color: ${transparentize(0.6, theme.colors.white)};
    font-weight: 500;
  }

  ${TagActionButton} {
    &:hover {
      color: ${({ theme }) => transparentize(0.25, theme.colors.white)};
    }
  }
`;

const variantLightStyles = ({ theme }: variantStyleProps) => css`
  background-color: ${theme.colors.white};
  border: 1px solid ${transparentize(0.75, theme.colors.statusBlack)};

  ${TagLabel} {
    color: ${theme.colors.tradeTagGreen};
    font-family: ${theme.typography.fontFamily.text};
    font-size: 13px;
    font-weight: 500;
    line-height: 12px;
  }

  ${TagHelperText} {
    color: ${transparentize(0.5, theme.colors.greyBlueMedium)};
    font-family: ${theme.typography.fontFamily.text};
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
  }

  ${TagActionButton} {
    color: ${transparentize(0.65, theme.colors.greenDark)};

    &:hover {
      color: ${({ theme }) => transparentize(0.25, theme.colors.greenDark)};
    }
  }
`;

type WrapperProps = {
  $variant?: 'light' | 'dark' | 'violet';
  $width?: number;
};

export const Wrapper = styled.li<WrapperProps>`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  padding: 6px 8px;
  border-radius: 4px;

  ${({ $variant, $width }) => css`
    ${$width &&
    css`
      width: ${$width}px;
    `}

    ${$variant === 'dark' && variantDarkStyles}
    ${$variant === 'violet' && variantVioletStyles}
    ${$variant === 'light' && variantLightStyles}
  `}
`;

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  margin: 0;
  font-size: 13px;
`;

export const TagContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  row-gap: 4px;
`;

export const TagLabel = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.02em;

  ${ellipsis()}
`;

export const TagHelperText = styled.span`
  color: ${({ theme }) => transparentize(0.25, theme.colors.white)};
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: 0.9em;
  font-weight: 500;

  ${ellipsis()}
`;

export const TagActionButton = styled(DefaultButton)`
  display: inline-flex;
  width: auto;
  height: 100%;
  place-items: center;
  padding: 0;
  flex-shrink: 0;
  color: ${({ theme }) => transparentize(0.5, theme.colors.white)};
`;

export const TagChevron = styled.span`
  place-self: start;
  margin-top: -3px;
`;

type TagProps = {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  onRemove?: () => void;
  onOptionsClick?: (event: React.MouseEvent) => void;
  variant?: 'light' | 'dark' | 'violet';
  width?: number;
} & StyledComponentPropsWithRef<typeof Wrapper>;

export const Tag = (props: TagProps) => {
  const {
    children,
    icon,
    onOptionsClick,
    onRemove,
    variant = 'dark',
    width,
    ...styledProps
  } = props;

  return (
    <Wrapper {...styledProps} $variant={variant} $width={width}>
      {icon} <TagContent>{children}</TagContent>
      {onRemove && (
        <TagActionButton onClick={onRemove}>
          <CloseLineIcon height={9} width={9} />
        </TagActionButton>
      )}
      {!onRemove && onOptionsClick && (
        <TagActionButton
          onClick={(event) => {
            event.stopPropagation();
            onOptionsClick(event);
          }}
        >
          <TagChevron>
            <ChevronDownIcon />
          </TagChevron>
        </TagActionButton>
      )}
    </Wrapper>
  );
};
