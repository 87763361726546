import React from 'react';

export const AnalyticsFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 19V17.4444L6.55556 15.8889V19H5ZM8.11111 19V14.3333L9.66667 12.7778V19H8.11111ZM11.2222 19V12.7778L12.7778 14.3528V19H11.2222ZM14.3333 19V14.3528L15.8889 12.7972V19H14.3333ZM17.4444 19V11.2222L19 9.66667V19H17.4444ZM5 14.975V12.7778L10.4444 7.33333L13.5556 10.4444L19 5V7.19722L13.5556 12.6417L10.4444 9.53056L5 14.975Z"
        fill="currentColor"
      />
    </svg>
  );
};
