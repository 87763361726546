import mergeAll from 'lodash/fp/mergeAll';
import range from 'lodash/range';
import React from 'react';
import { VictoryAxis, VictoryAxisProps } from 'victory-axis';
import { VictoryChart, VictoryChartProps } from 'victory-chart';
import {
  Point,
  PointProps,
  VictoryLabel,
  VictoryLabelProps,
  VictoryThemeDefinition,
} from 'victory-core';

import { white } from '../../../styles/darkTheme/colors';
import { defaultLoanTypesChartTheme } from '../helpers';
import { ChartWrapper } from './LoanTypesByPageChart.styles';

const defaultLabelStyle = {
  fontFamily: 'Roboto',
  fontWeight: 500,
};

const defaultTheme: VictoryThemeDefinition = {
  axis: {
    style: {
      tickLabels: { ...defaultLabelStyle, fontSize: 12 },
    },
  },
  dependentAxis: {
    style: {
      tickLabels: { ...defaultLabelStyle, fontSize: 12 },
      grid: { pointerEvents: 'none' },
    },
  },
  scatter: {
    style: {
      data: { cursor: 'pointer' },
      labels: { ...defaultLabelStyle, fill: white, cursor: 'pointer' },
    },
  },
};

export const ScatterChartLabel = (props: VictoryLabelProps & { index?: number }) => {
  return <VictoryLabel {...props} verticalAnchor="middle" data-index={props.index} />;
};

export const ScatterChartPoint = (props: PointProps) => {
  return <Point {...props} data-index={props.index} />;
};

type LoanTypesByPageChartProps = Pick<
  VictoryChartProps,
  'theme' | 'width' | 'height' | 'padding' | 'domain'
> & {
  children?: React.ReactNode;
  tickFormat: Exclude<VictoryAxisProps['tickFormat'], undefined>;
};

export const LoanTypesByPageChart = (props: LoanTypesByPageChartProps) => {
  const { children, theme, tickFormat, ...chartProps } = props;

  const [hoveredIndex, setHoveredIndex] = React.useState<number | undefined>(undefined);

  const events = React.useMemo(() => {
    return ['data', 'labels'].map<Exclude<VictoryChartProps['events'], undefined>[number]>(
      (target) => ({
        childName: ['scatter'],
        target,
        eventHandlers: {
          onMouseOver: (_, { index }) => setHoveredIndex(index),
          onMouseLeave: () => setHoveredIndex(undefined),
        },
      })
    );
  }, []);

  return (
    <ChartWrapper $hoveredIndex={hoveredIndex}>
      <VictoryChart
        theme={mergeAll([defaultLoanTypesChartTheme, defaultTheme, theme])}
        height={565}
        domainPadding={{ x: 40 }}
        events={events}
        {...chartProps}
      >
        {children}
        <VictoryAxis tickLabelComponent={<VictoryLabel dy={16} />} />
        <VictoryAxis
          dependentAxis
          tickFormat={tickFormat}
          tickValues={range(tickFormat.length + 1)}
          invertAxis
          tickLabelComponent={<VictoryLabel dx={-16} />}
        />
      </VictoryChart>
    </ChartWrapper>
  );
};
