import { Identifier } from '.';
import { IndexSourceType } from './mismo';

export enum IndexAttributeType {
  ATTRIBUTE_INDEX_NAME = 'ATTRIBUTE_INDEX_NAME',
  ATTRIBUTE_INDEX_RATE = 'ATTRIBUTE_INDEX_RATE',
}

export enum IndexNames {
  LIBOR = 'LIBOR',
  SOFR = 'SOFR',
  THIRTY_YR_FIXED_MR = '30YRFIXEDMR',
  FIFTEEN_YR_FIXED_MR = '15YRFIXEDMR',
  FHA_MR = '30YRFHAMR',
  VA_MR = '30YRVAMR',
  ARM_MR = '5YRARMMR',
  JUMBO_MR = '30YRJUMBOMR',
}

export type IndexCreation = {
  indexName: IndexNames;
  indexSourceType: IndexSourceType;
  indexSourceTypeOtherDescription?: string;
  inceptionDate: Date;
};

export type Index = IndexCreation & {
  id: Identifier;
};

export type IndexRateCreation = {
  indexId: Index['id'];
  rate: number;
  effectiveDate: Date;
};

export type IndexRate = IndexRateCreation & {
  id: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedIndex = Index & {
  indexRates: IndexRate[];
};
