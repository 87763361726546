import {
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from '../types';
import { CreditScoreProvider } from './creditScoreProvider';

enum CreditReportType {
  Error = 'Error',
  LoanQuality = 'LoanQuality',
  LoanQualityCompare = 'LoanQualityCompare',
  Merge = 'Merge',
  MergePlus = 'MergePlus',
  MortgageOnly = 'MortgageOnly',
  NonTraditional = 'NonTraditional',
  Other = 'Other',
  RiskScoresOnly = 'RiskScoresOnly',
  RMCR = 'RMCR',
  RMCRForeign = 'RMCRForeign',
  Status = 'Status',
}

export enum CreditRepositorySourceType {
  Equifax = 'Equifax',
  Experian = 'Experian',
  Innovis = 'Innovis',
  LexisNexis = 'LexisNexis',
  Other = 'Other',
  TransUnion = 'TransUnion',
  Unspecified = 'Unspecified',
}

enum CreditScoreCategoryType {
  Bankruptcy = 'Bankruptcy',
  CreditRepository = 'CreditRepository',
  FICO = 'FICO',
  FICONextGen = 'FICONextGen',
  Other = 'Other',
  ThinFile = 'ThinFile',
  Vantage = 'Vantage',
}

enum CreditScoreCategoryVersionType {
  FICO4 = 'FICO4',
  FICO8 = 'FICO8',
  FICO9 = 'FICO9',
  FICO98 = 'FICO98',
  Other = 'Other',
  Vantage2 = 'Vantage2',
  Vantage3 = 'Vantage3',
  Vantage4 = 'Vantage4',
}

enum CreditScoreExclusionReasonType {
  InvalidScoreRequest = 'InvalidScoreRequest',
  NotScoredCreditDataNotAvailable = 'NotScoredCreditDataNotAvailable',
  NotScoredFileCannotBeScored = 'NotScoredFileCannotBeScored',
  NotScoredFileIsUnderReview = 'NotScoredFileIsUnderReview',
  NotScoredFileTooLong = 'NotScoredFileTooLong',
  NotScoredInsufficientCredit = 'NotScoredInsufficientCredit',
  NotScoredNoQualifyingAccount = 'NotScoredNoQualifyingAccount',
  NotScoredNoRecentAccountInformation = 'NotScoredNoRecentAccountInformation',
  NotScoredRequirementsNotMet = 'NotScoredRequirementsNotMet',
  NotScoredSubjectDeceased = 'NotScoredSubjectDeceased',
  ScoringNotAvailable = 'ScoringNotAvailable',
  UnauthorizedScoreRequest = 'UnauthorizedScoreRequest',
}

enum CreditScoreImpairmentType {
  ImmaterialDisputedAccount = 'ImmaterialDisputedAccount',
  InsufficientCreditHistory = 'InsufficientCreditHistory',
  NonPredictiveScore = 'NonPredictiveScore',
  Other = 'Other',
  SignificantErrorsScore = 'SignificantErrorsScore',
  Unscorable = 'Unscorable',
}

enum CreditScoreModelNameType {
  Beacon09MortgageIndustryOption = 'Beacon09MortgageIndustryOption',
  'EquifaxBankruptcyNavigator4.0Index05146' = 'EquifaxBankruptcyNavigator4.0Index05146',
  'EquifaxBankruptcyNavigator4.0Index05147' = 'EquifaxBankruptcyNavigator4.0Index05147',
  'EquifaxBankruptcyNavigator4.0Index05276' = 'EquifaxBankruptcyNavigator4.0Index05276',
  'EquifaxBankruptcyNavigator4.0Index05277' = 'EquifaxBankruptcyNavigator4.0Index05277',
  'EquifaxBankruptcyNavigator4.0Index05373' = 'EquifaxBankruptcyNavigator4.0Index05373',
  'EquifaxBankruptcyNavigator4.0Index05374' = 'EquifaxBankruptcyNavigator4.0Index05374',
  'EquifaxBankruptcyNavigator4.0Index05375' = 'EquifaxBankruptcyNavigator4.0Index05375',
  'EquifaxBankruptcyNavigator4.0Index05376' = 'EquifaxBankruptcyNavigator4.0Index05376',
  EquifaxBankruptcyNavigatorIndex02781 = 'EquifaxBankruptcyNavigatorIndex02781',
  EquifaxBankruptcyNavigatorIndex02782 = 'EquifaxBankruptcyNavigatorIndex02782',
  EquifaxBankruptcyNavigatorIndex02783 = 'EquifaxBankruptcyNavigatorIndex02783',
  EquifaxBankruptcyNavigatorIndex02784 = 'EquifaxBankruptcyNavigatorIndex02784',
  EquifaxBeacon = 'EquifaxBeacon',
  'EquifaxBeacon5.0' = 'EquifaxBeacon5.0',
  'EquifaxBeacon5.0Auto' = 'EquifaxBeacon5.0Auto',
  'EquifaxBeacon5.0BankCard' = 'EquifaxBeacon5.0BankCard',
  'EquifaxBeacon5.0Installment' = 'EquifaxBeacon5.0Installment',
  'EquifaxBeacon5.0PersonalFinance' = 'EquifaxBeacon5.0PersonalFinance',
  EquifaxBeaconAuto = 'EquifaxBeaconAuto',
  EquifaxBeaconBankcard = 'EquifaxBeaconBankcard',
  EquifaxBeaconInstallment = 'EquifaxBeaconInstallment',
  EquifaxBeaconPersonalFinance = 'EquifaxBeaconPersonalFinance',
  EquifaxDAS = 'EquifaxDAS',
  EquifaxEnhancedBeacon = 'EquifaxEnhancedBeacon',
  EquifaxEnhancedDAS = 'EquifaxEnhancedDAS',
  EquifaxFICOAutoScore8 = 'EquifaxFICOAutoScore8',
  EquifaxFICOAutoScore9 = 'EquifaxFICOAutoScore9',
  EquifaxFICOBankcardScore8 = 'EquifaxFICOBankcardScore8',
  EquifaxFICOBankcardScore9 = 'EquifaxFICOBankcardScore9',
  EquifaxFICOMortgageScore8 = 'EquifaxFICOMortgageScore8',
  EquifaxFICOScore8 = 'EquifaxFICOScore8',
  EquifaxFICOScore9 = 'EquifaxFICOScore9',
  EquifaxMarketMax = 'EquifaxMarketMax',
  EquifaxMortgageScore = 'EquifaxMortgageScore',
  EquifaxPinnacle = 'EquifaxPinnacle',
  'EquifaxPinnacle2.0' = 'EquifaxPinnacle2.0',
  EquifaxVantageScore = 'EquifaxVantageScore',
  'EquifaxVantageScore2.0' = 'EquifaxVantageScore2.0',
  'EquifaxVantageScore3.0' = 'EquifaxVantageScore3.0',
  'EquifaxVantageScore4.0' = 'EquifaxVantageScore4.0',
  ExperianFairIsaac = 'ExperianFairIsaac',
  ExperianFairIsaacAdvanced = 'ExperianFairIsaacAdvanced',
  'ExperianFairIsaacAdvanced2.0' = 'ExperianFairIsaacAdvanced2.0',
  ExperianFairIsaacAuto = 'ExperianFairIsaacAuto',
  ExperianFairIsaacBankcard = 'ExperianFairIsaacBankcard',
  ExperianFairIsaacInstallment = 'ExperianFairIsaacInstallment',
  ExperianFairIsaacPersonalFinance = 'ExperianFairIsaacPersonalFinance',
  ExperianFICOAutoScore3 = 'ExperianFICOAutoScore3',
  ExperianFICOAutoScore8 = 'ExperianFICOAutoScore8',
  ExperianFICOAutoScore9 = 'ExperianFICOAutoScore9',
  ExperianFICOBankcardScore3 = 'ExperianFICOBankcardScore3',
  ExperianFICOBankcardScore8 = 'ExperianFICOBankcardScore8',
  ExperianFICOBankcardScore9 = 'ExperianFICOBankcardScore9',
  ExperianFICOClassicV3 = 'ExperianFICOClassicV3',
  ExperianFICOInstallmentScore3 = 'ExperianFICOInstallmentScore3',
  ExperianFICOMortgageScore8 = 'ExperianFICOMortgageScore8',
  ExperianFICOPersonalFinanceScore3 = 'ExperianFICOPersonalFinanceScore3',
  ExperianFICOScore8 = 'ExperianFICOScore8',
  'ExperianFICOScore8.0.1' = 'ExperianFICOScore8.0.1',
  ExperianFICOScore9 = 'ExperianFICOScore9',
  ExperianMDSBankruptcyII = 'ExperianMDSBankruptcyII',
  ExperianNewNationalEquivalency = 'ExperianNewNationalEquivalency',
  ExperianNewNationalRisk = 'ExperianNewNationalRisk',
  ExperianOldNationalRisk = 'ExperianOldNationalRisk',
  ExperianScorexPLUS = 'ExperianScorexPLUS',
  ExperianVantageScore = 'ExperianVantageScore',
  'ExperianVantageScore2.0' = 'ExperianVantageScore2.0',
  'ExperianVantageScore3.0' = 'ExperianVantageScore3.0',
  'ExperianVantageScore4.0' = 'ExperianVantageScore4.0',
  FICOExpansionScore = 'FICOExpansionScore',
  FICORiskScoreClassic04 = 'FICORiskScoreClassic04',
  FICORiskScoreClassic98 = 'FICORiskScoreClassic98',
  FICORiskScoreClassicAuto98 = 'FICORiskScoreClassicAuto98',
  FICORiskScoreClassicBankcard98 = 'FICORiskScoreClassicBankcard98',
  FICORiskScoreClassicInstallmentLoan98 = 'FICORiskScoreClassicInstallmentLoan98',
  FICORiskScoreClassicPersonalFinance98 = 'FICORiskScoreClassicPersonalFinance98',
  FICORiskScoreNextGen00 = 'FICORiskScoreNextGen00',
  FICORiskScoreNextGen03 = 'FICORiskScoreNextGen03',
  Other = 'Other',
  TransUnionDelphi = 'TransUnionDelphi',
  TransUnionEmpirica = 'TransUnionEmpirica',
  TransUnionEmpiricaAuto = 'TransUnionEmpiricaAuto',
  TransUnionEmpiricaBankcard = 'TransUnionEmpiricaBankcard',
  TransUnionEmpiricaInstallment = 'TransUnionEmpiricaInstallment',
  TransUnionEmpiricaPersonalFinance = 'TransUnionEmpiricaPersonalFinance',
  TransUnionFICOAutoScore4 = 'TransUnionFICOAutoScore4',
  TransUnionFICOAutoScore8 = 'TransUnionFICOAutoScore8',
  TransUnionFICOAutoScore9 = 'TransUnionFICOAutoScore9',
  TransUnionFICOBankcardScore4 = 'TransUnionFICOBankcardScore4',
  TransUnionFICOBankcardScore8 = 'TransUnionFICOBankcardScore8',
  TransUnionFICOBankcardScore9 = 'TransUnionFICOBankcardScore9',
  TransUnionFICOBankruptcyScore2 = 'TransUnionFICOBankruptcyScore2',
  TransUnionFICOInstallmentScore4 = 'TransUnionFICOInstallmentScore4',
  TransUnionFICOMortgageScore8 = 'TransUnionFICOMortgageScore8',
  TransUnionFICOPersonalFinanceScore4 = 'TransUnionFICOPersonalFinanceScore4',
  TransUnionFICORevenueScore = 'TransUnionFICORevenueScore',
  TransUnionFICOScore8 = 'TransUnionFICOScore8',
  TransUnionFICOScore9 = 'TransUnionFICOScore9',
  TransUnionNewDelphi = 'TransUnionNewDelphi',
  TransUnionPrecision = 'TransUnionPrecision',
  TransUnionPrecision03 = 'TransUnionPrecision03',
  TransUnionVantageScore = 'TransUnionVantageScore',
  'TransUnionVantageScore2.0' = 'TransUnionVantageScore2.0',
  'TransUnionVantageScore3.0' = 'TransUnionVantageScore3.0',
  'TransUnionVantageScore4.0' = 'TransUnionVantageScore4.0',
}

export type CreditScore = MISMOEntity &
  Partial<{
    creditReportIdentifier: MISMOIdentifier;
    creditReportType: CreditReportType;
    creditReportTypeOtherDescription: MISMOString;
    creditRepositorySingleSourceIndicator: MISMOIndicator;
    creditRepositorySourceIndicator: MISMOIndicator;
    creditRepositorySourceType: CreditRepositorySourceType;
    creditRepositorySourceTypeOtherDescription: MISMOString;
    creditScoreCategoryType: CreditScoreCategoryType;
    creditScoreCategoryTypeOtherDescription: MISMOString;
    creditScoreCategoryVersionType: CreditScoreCategoryVersionType;
    creditScoreCategoryVersionTypeOtherDescription: MISMOString;
    creditScoreDate: MISMODate;
    creditScoreExclusionReasonType: CreditScoreExclusionReasonType;
    creditScoreFACTAInquiriesIndicator: MISMOIndicator;
    creditScoreImpairmentType: CreditScoreImpairmentType;
    creditScoreImpairmentTypeOtherDescription: MISMOString;
    creditScoreModelNameType: CreditScoreModelNameType;
    creditScoreModelNameTypeOtherDescription: MISMOString;
    creditScoreRankPercentileDescription: MISMOString;
    creditScoreRankPercentileValue: MISMOValue;
    creditScoreValue: MISMOValue;
    selectedCreditScoreIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
    // creditScoreFactors: CreditScoreFactor[];
    creditScoreProvider: CreditScoreProvider;
  }>;
