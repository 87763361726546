import { AvatarWithFallback } from '../../components/AvatarWithFallback';
import * as Styles from './ProfileToggle.styles';

type ProfileToggleProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive: boolean;
  firstName?: string;
  lastName?: string;
  src?: string;
};

export const ProfileToggle = ({
  firstName,
  isActive = false,
  lastName,
  src,
  ...rest
}: ProfileToggleProps) => {
  return (
    <Styles.Toggle $isActive={isActive} $hasSrc={Boolean(src)} {...rest}>
      <AvatarWithFallback {...{ firstName, lastName, src }} />
    </Styles.Toggle>
  );
};
