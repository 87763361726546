import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';

import { Button } from './Button';
import { Error } from './Error';

type Props = {
  buttonText?: string;
  children?: ReactNode;
  message?: string;
};

export const QueryErrorBoundary = ({
  buttonText = 'Try again',
  children,
  message = 'There was an error fetching data!',
}: Props) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary
        fallbackRender={({ resetErrorBoundary }) => (
          <Error message={message}>
            <Button onClick={resetErrorBoundary}>{buttonText}</Button>
          </Error>
        )}
        onReset={reset}
      >
        {children}
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);
