import { Stipulation, StipulationType } from '@plus-platform/shared';

import { useStipulationDocumentQuery, useStipulationPreviewQuery } from '../hooks/queries';

export const useStipulationResources = (
  stipulationType: StipulationType,
  stipulationId?: Stipulation['id']
) => {
  // TODO: To avoid loading these resources into memory, consider reviewing the current
  // way authentication details are supplied with requests, so that there is no need
  // for custom code that does it manually, and/or consider switching to redirect
  // responses with direct urls to files
  const { data: preview } = useStipulationPreviewQuery(
    stipulationType,
    stipulationId,
    Boolean(stipulationId)
  );
  const { refetch: getDocumentData } = useStipulationDocumentQuery(
    stipulationType,
    stipulationId,
    false
  );

  const openDocument = async () => {
    const { data } = await getDocumentData();

    window.open(data);
  };

  return { openDocument, preview };
};
