export const BORROWER_ROLE_INDEX = 0;

export const LEGAL_DESCRIPTION_DEFAULT_INDEX = 0;
export const FORM_1003_UNPARSED_LEGAL_DESCRIPTION_DEFAULT_INDEX = 0;
export const FORM_1004_UNPARSED_LEGAL_DESCRIPTION_DEFAULT_INDEX = 1;

export const LENDER_PARTY_INDEX = 0;
export const LOAN_ORIGINATOR_PARTY_INDEX = 1;
export const LOAN_UNDERWRITER_PARTY_INDEX = 2;
export const LOAN_APPRAISER_PARTY_INDEX = 3;
export const MORTGAGE_SELLER_PARTY_INDEX = 4;
export const LOAN_CORRESPONDENT_PARTY_INDEX = 5;
export const PARTY_STATIC_INDEXES = [
  LENDER_PARTY_INDEX,
  LOAN_ORIGINATOR_PARTY_INDEX,
  LOAN_UNDERWRITER_PARTY_INDEX,
  LOAN_APPRAISER_PARTY_INDEX,
  MORTGAGE_SELLER_PARTY_INDEX,
  LOAN_CORRESPONDENT_PARTY_INDEX,
];

export const LENDER_ADDRESS_DEFAULT_INDEX = 0;

export const COMBINED_LTV_DEFAULT_INDEX = 0;

export const BORROWER_CREDIT_SCORES_DEFAULT_INDEX = 0;

export const URLA_DOCUMENT_INDEX = 0;

export const LOAN_ORIGINATOR_DEFAULT_CONTACT_INDEX = 0;
export const LOAN_ORIGINATOR_CONTACT_POINT_TELEPHONE_INDEX = 0;
export const LOAN_ORIGINATOR_PRESENT_COMPANY_ADDRESS_INDEX = 0;

export const SOCIAL_SECURITY_NUMBER_TAXPAYER_IDENTIFIER_INDEX = 0;
export const CONTACT_POINT_TELEPHONE_HOME_INDEX = 0;
export const CONTACT_POINT_TELEPHONE_CURRENT_WORK_INDEX = 1;
export const CONTACT_POINT_TELEPHONE_PAST_WORK_1_INDEX = 2;
export const CONTACT_POINT_TELEPHONE_PAST_WORK_2_INDEX = 3;

export const PRESENT_ADDRESS_INDEX = 0;
export const MAILING_ADDRESS_INDEX = 1;
export const FORMER_ADDRESS_INDEX = 2;

export const PRESENT_EMPLOYER_INDEX = 0;
export const PAST_EMPLOYER_1_INDEX = 1;
export const PAST_EMPLOYER_2_INDEX = 2;

export const INCOME_BASE_INDEX = 0;
export const INCOME_OVERTIME_INDEX = 1;
export const INCOME_BONUSES_INDEX = 2;
export const INCOME_COMMISSIONS_INDEX = 3;
export const INCOME_DIVIDENDS_INDEX = 4;
export const INCOME_NET_RENTAL_INDEX = 5;

export const HOUSING_EXPENSE_PRESENT_RENT_INDEX = 0;
export const HOUSING_EXPENSE_PRESENT_FIRST_MORTGAGE_PI_INDEX = 1;
export const HOUSING_EXPENSE_PRESENT_OTHER_FINANCING_PI_INDEX = 2;
export const HOUSING_EXPENSE_PRESENT_HAZARD_INSURANCE_INDEX = 3;
export const HOUSING_EXPENSE_PRESENT_REAL_ESTATE_TAXES_INDEX = 4;
export const HOUSING_EXPENSE_PRESENT_MORTGAGE_INSURANCE_INDEX = 5;
export const HOUSING_EXPENSE_PRESENT_MORTGAGE_HOA_INDEX = 6;
export const HOUSING_EXPENSE_PRESENT_OTHER_INDEX = 7;
export const HOUSING_EXPENSE_PROPOSED_FIRST_MORTGAGE_PI_INDEX = 8;
export const HOUSING_EXPENSE_PROPOSED_OTHER_FINANCING_PI_INDEX = 9;
export const HOUSING_EXPENSE_PROPOSED_HAZARD_INSURANCE_INDEX = 10;
export const HOUSING_EXPENSE_PROPOSED_REAL_ESTATE_TAXES_INDEX = 11;
export const HOUSING_EXPENSE_PROPOSED_MORTGAGE_INSURANCE_INDEX = 12;
export const HOUSING_EXPENSE_PROPOSED_MORTGAGE_HOA_INDEX = 13;
export const HOUSING_EXPENSE_PROPOSED_OTHER_INDEX = 14;

export const VALUATION_RECONCILIATION_SUMMARY_DEFAULT_INDEX = 0;
export const VALUATION_RECONCILIATION_SUMMARY_AS_IS_INDEX = 1;
export const VALUATION_RECONCILIATION_SUMMARY_SUBJECT_TO_COMPLETION_PER_PLANS_INDEX = 2;
export const VALUATION_RECONCILIATION_SUMMARY_SUBJECT_TO_REPAIRS_AND_CONDITIONS_INDEX = 3;
export const VALUATION_RECONCILIATION_SUMMARY_SUBJECT_TO_REPAIRS_UNNECESSARY_INDEX = 4;

export const DOWN_PAYMENT_DEFAULT_INDEX = 0;

export const INTERIOR_ROOM_SUMMARY_ALL_ROOMS_INDEX = 0;
export const INTERIOR_ROOM_SUMMARY_BEDROOM_INDEX = 1;
export const INTERIOR_ROOM_SUMMARY_BATHROOM_INDEX = 2;

export const BASEMENT_FEATURE_OUTSIDE_ENTRY_EXIT_INDEX = 0;
export const BASEMENT_FEATURE_SUMP_PUMP_EXIT_INDEX = 1;

export const CAR_STORAGE_DRIVEWAY_INDEX = 0;
export const CAR_STORAGE_GARAGE_INDEX = 1;
export const CAR_STORAGE_CARPORT_INDEX = 2;

export const EXTERIOR_FEATURE_GUTTERS_AND_DOWNSPOUTS_INDEX = 0;

export const FOUNDATION_DEFAULT_INDEX = 0;
export const FOUNDATION_DEFICIENCY_INFESTATION_INDEX = 1;
export const FOUNDATION_DEFICIENCY_DAMPNESS_INDEX = 2;
export const FOUNDATION_DEFICIENCY_SETTLEMENT_INDEX = 3;

export const FOUNDATION_WALL_DEFAULT_INDEX = 0;

export const EXTERIOR_WALL_DEFAULT_INDEX = 0;

export const ROOM_ALL_ROOMS_INDEX = 0;
export const ROOM_BATH_INDEX = 1;

export const ROOM_FEATURE_BATHROOM_WAINSCOT_INDEX = 0;
export const ROOM_FEATURE_TRIM_AND_FINISH_INDEX = 1;
export const ROOM_FEATURE_WALL_INDEX = 2;

export const HOUSING_ONE_UNIT_INDEX = 0;

export const WAGE_1_INDEX = 0;
export const WAGE_2_INDEX = 1;

export const WITHHOLD_1_INDEX = 0;
export const WITHHOLD_2_INDEX = 1;
