import styled from 'styled-components/macro';

export const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`;

export const PhotosSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const NoImages = styled.div`
  padding-left: 4px;
`;
