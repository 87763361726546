import {
  InboundTradeRequestWithSummary,
  StipulationType,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';
import React from 'react';

import { MessageText, PlusMessage, UserMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { StipulationPreview } from '../../StipulationPreview';
import { QuoteSummary } from '../QuoteSummary';

type InboundQuotePendingProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: InboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const InboundQuotePending = ({
  currentQuoteIndex,
  quote,
  totalQuoteCount,
  tradeRequest,
}: InboundQuotePendingProps) => {
  const { fromCounterparty } = quote;

  return (
    <React.Fragment>
      <PlusMessage date={quote.createdAt}>
        <MessageText>
          <TradeMessage
            id="Trade.Inbound.Quote.Pending"
            values={{
              poolName: tradeRequest.pool.name,
              ticketId: tradeRequest.id,
              userName: formatNameWithInitial(
                tradeRequest.user.lastName,
                tradeRequest.user.firstName
              ),
              organizationName: tradeRequest.user.organization.tradingName,
            }}
          />
        </MessageText>
      </PlusMessage>
      <UserMessage
        date={quote.createdAt}
        organizationName={fromCounterparty.user.organization.tradingName}
        lastName={fromCounterparty.user.lastName}
        firstName={fromCounterparty.user.firstName}
      >
        <QuoteSummary
          currentQuoteIndex={currentQuoteIndex}
          quote={quote}
          totalQuoteCount={totalQuoteCount}
          tradeRequest={tradeRequest}
        />
        {quote?.quoteStipulation?.quoteStipulation && (
          <StipulationPreview
            stipulationFileOriginalName={quote.quoteStipulation.quoteStipulation.fileOriginalName}
            stipulationId={quote.quoteStipulation.quoteStipulation.id}
            stipulationType={StipulationType.STIPULATION_QUOTE}
          />
        )}
      </UserMessage>
    </React.Fragment>
  );
};
