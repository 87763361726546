import { Filter as FilterType } from '@plus-platform/shared';
import { useState } from 'react';

import { getFilterLabel } from '../utils/portfolioFilterUtils';
import { ButtonIcon } from './ButtonIcon';
import { ButtonLink } from './ButtonLink';
import { Card } from './Card';
import { AddIcon, CloseLineIcon } from './icons';
import * as Styles from './TableFilterPanel.styles';
import { ToggleSwitch } from './ToggleSwitch';

type TableFilterPanelProps = {
  filters: FilterType[];
  filtersPopupPath: string;
  onFiltersUpdate: (filters: FilterType[]) => unknown | Promise<unknown>;
  onClose: () => void;
};

export const TableFilterPanel = ({
  filters,
  filtersPopupPath,
  onClose,
  onFiltersUpdate,
}: TableFilterPanelProps) => {
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false);

  const activeFilters = filters.filter((filter) => !filter.isDeleted);
  const hasFilters = activeFilters.length > 0;

  const toggleAppliedFilter = async (toggledFilter: FilterType) => {
    try {
      const updatedFilters = filters.map((filter) => {
        return {
          ...filter,
          isApplied: filter.id === toggledFilter.id ? !filter.isApplied : filter.isApplied,
        };
      });

      setIsUpdatingFilters(true);
      await onFiltersUpdate(updatedFilters);
    } finally {
      setIsUpdatingFilters(false);
    }
  };

  return (
    <Card>
      <Styles.Wrapper data-testid="TableFilterPanel">
        <Styles.Header>
          Filters
          <ButtonIcon onClick={onClose} size="medium" variant="dark">
            <CloseLineIcon />
          </ButtonIcon>
        </Styles.Header>
        <Styles.Content>
          <Styles.FiltersSection>
            {hasFilters ? (
              <Styles.Filters>
                {activeFilters.map((filter, index) => (
                  <Styles.Filter
                    key={`${filter.key}-${index}`}
                    data-testid={`TableFilterPanel_Filter`}
                  >
                    <Styles.FilterLabel
                      $isApplied={filter.isApplied}
                      title={getFilterLabel(filter)}
                    >
                      {getFilterLabel(filter)}
                    </Styles.FilterLabel>
                    <ToggleSwitch
                      $size="small"
                      checked={filter.isApplied}
                      disabled={isUpdatingFilters}
                      onChange={() => toggleAppliedFilter(filter)}
                    />
                  </Styles.Filter>
                ))}
              </Styles.Filters>
            ) : (
              <span>No filters to show</span>
            )}
          </Styles.FiltersSection>
          <ButtonLink
            $color="primary"
            $variant="outlined"
            $size="large"
            to={filtersPopupPath}
            data-testid={
              hasFilters
                ? 'TableFilterPanel_ManageFiltersButton'
                : 'TableFilterPanel_CreateFilterButton'
            }
            startIcon={<AddIcon />}
          >
            {hasFilters ? ' Create another filter' : 'Create filter'}
          </ButtonLink>
        </Styles.Content>
      </Styles.Wrapper>
    </Card>
  );
};
