import { forwardRef, HTMLAttributes } from 'react';
import { CSSProperties } from 'styled-components';

import * as Styles from './Modal.styles';
import { Portal } from './Portal';

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  hasOverlay?: boolean;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  maxWidth?: CSSProperties['maxWidth'];
  minHeight?: CSSProperties['minHeight'];
  minWidth?: CSSProperties['minWidth'];
  variant?: 'light' | 'dark';
};

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      bottom,
      children,
      hasOverlay = false,
      height,
      left,
      maxWidth,
      minHeight,
      minWidth,
      right,
      top,
      variant = 'dark',
      width,
      ...rest
    },
    ref
  ) => {
    return (
      <Portal>
        <Overlay hasOverlay={hasOverlay}>
          <Styles.Modal
            ref={ref}
            $right={right}
            $left={left}
            $top={top}
            $bottom={bottom}
            $width={width}
            $height={height}
            $maxWidth={maxWidth}
            $minHeight={minHeight}
            $minWidth={minWidth}
            $variant={variant}
            {...rest}
          >
            {children}
          </Styles.Modal>
        </Overlay>
      </Portal>
    );
  }
);

type OverlayProps = {
  hasOverlay: boolean;
  children: React.ReactNode;
};

const Overlay = ({ children, hasOverlay }: OverlayProps) => {
  return hasOverlay ? <Styles.Overlay>{children}</Styles.Overlay> : <>{children}</>;
};
