import React from 'react';

export const HistoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9333 20C9.72593 20 7.85185 19.2185 6.31111 17.6556C4.77037 16.0926 4 14.2 4 11.9778H5.33333C5.33333 13.8296 5.97037 15.4074 7.24444 16.7111C8.51852 18.0148 10.0815 18.6667 11.9333 18.6667C13.8148 18.6667 15.4074 18.0074 16.7111 16.6889C18.0148 15.3704 18.6667 13.7704 18.6667 11.8889C18.6667 10.0519 18.0074 8.5 16.6889 7.23333C15.3704 5.96667 13.7852 5.33333 11.9333 5.33333C10.9259 5.33333 9.98148 5.56296 9.1 6.02222C8.21852 6.48148 7.45185 7.08889 6.8 7.84444H9.13333V9.17778H4.48889V4.55556H5.82222V6.91111C6.59259 6.00741 7.50741 5.2963 8.56667 4.77778C9.62593 4.25926 10.7481 4 11.9333 4C13.0444 4 14.0889 4.20741 15.0667 4.62222C16.0444 5.03704 16.9 5.6037 17.6333 6.32222C18.3667 7.04074 18.9444 7.88148 19.3667 8.84444C19.7889 9.80741 20 10.8444 20 11.9556C20 13.0667 19.7889 14.1111 19.3667 15.0889C18.9444 16.0667 18.3667 16.9185 17.6333 17.6444C16.9 18.3704 16.0444 18.9444 15.0667 19.3667C14.0889 19.7889 13.0444 20 11.9333 20ZM14.7778 15.6222L11.3556 12.2444V7.48889H12.6889V11.6889L15.7333 14.6667L14.7778 15.6222Z"
        fill="currentColor"
      />
    </svg>
  );
};
