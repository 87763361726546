import styled from 'styled-components';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { NoTrades } from './TradeRequestTickets';

const List = styled.div`
  display: grid;
  row-gap: 8px;
`;

type TradeRequestsViewProps<T> = {
  isLoading: boolean;
  tradeRequests: T[];
  noItemsMessage: string;
  renderItem: (tradeRequest: T) => JSX.Element;
};

export const TradeRequestsView = <T,>({
  isLoading,
  noItemsMessage,
  renderItem,
  tradeRequests,
}: TradeRequestsViewProps<T>) => {
  return (
    <ActivityIndicator contain isActive={isLoading}>
      <List>
        {tradeRequests.length > 0 ? (
          tradeRequests.map((tradeRequest) => renderItem(tradeRequest))
        ) : (
          <NoTrades>{noItemsMessage}</NoTrades>
        )}
      </List>
    </ActivityIndicator>
  );
};
