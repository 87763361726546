import { formatNameToInitials } from '../utils/formatUtils';
import * as Styles from './AvatarWithFallback.styles';

type AvatarWithFallbackProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  firstName?: string;
  lastName?: string;
  src?: string;
  size?: number;
  variant?: 'solid' | 'outline';
};

export const AvatarWithFallback = ({
  firstName,
  lastName,
  size = 32,
  src,
  variant = 'solid',
}: AvatarWithFallbackProps) => {
  return (
    <Styles.Wrapper $hasSrc={Boolean(src)} $size={size} $variant={variant}>
      {src ? (
        <Styles.Avatar $size={size} alt={`${firstName} ${lastName}'s avatar`} src={src} />
      ) : (
        <Styles.Initials $size={size}>
          {lastName && formatNameToInitials(lastName, firstName)}
        </Styles.Initials>
      )}
    </Styles.Wrapper>
  );
};
