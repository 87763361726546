import { useQuery } from 'react-query';

import { getLoanAssetLedger } from '../../utils/loanUtils';
import { QueryKeys } from './types';

/**
 * Query hook for fetching loan asset ledger
 */
export const useLoanAssetLedgerQuery = (loanId: string) =>
  useQuery([QueryKeys.LOAN_ASSET_LEDGER, loanId], () => getLoanAssetLedger(loanId));
