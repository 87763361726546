import React from 'react';

export const BarChartIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 13.5C3 13.2239 3.22386 13 3.5 13H5.5C5.77614 13 6 13.2239 6 13.5V21H3V13.5Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
      <path
        d="M8 3.5C8 3.22386 8.22386 3 8.5 3H10.5C10.7761 3 11 3.22386 11 3.5V21H8V3.5Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M13 7.5C13 7.22386 13.2239 7 13.5 7H15.5C15.7761 7 16 7.22386 16 7.5V21H13V7.5Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
      <path
        d="M18 3.5C18 3.22386 18.2239 3 18.5 3H20.5C20.7761 3 21 3.22386 21 3.5V21H18V3.5Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </svg>
  );
};
