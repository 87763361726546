import React from 'react';
import styled, { css } from 'styled-components';

import { CheckboxIcon } from './icons';

export type CheckboxProps = React.HTMLAttributes<HTMLInputElement>;

export const checkboxDisabledStyles = css`
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
`;

export const Input = styled.input.attrs<CheckboxProps>({
  type: 'checkbox',
})(
  ({ theme }) => css`
    display: inline-flex;
    position: relative;
    appearance: none;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.primary04};
      background-color: ${theme.colors.statusWhite};
      transition: background-color 0.2s ease-out;
    }

    &:not(:checked):hover::before {
      background-color: ${theme.colors.statusLightGrey};
    }

    &:not(:checked):active::before {
      background-color: ${theme.colors.statusDarkGrey};
    }

    &:checked {
      &::before {
        background-color: ${theme.colors.statusWhite};
      }
    }
  `
);

export const Label = styled.label<{ $isDisabled?: boolean }>`
  position: relative;
  display: inline-flex;
  gap: 4px;
  cursor: pointer;

  ${({ $isDisabled }) => $isDisabled && checkboxDisabledStyles}
`;

export const CheckIndicator = styled(CheckboxIcon)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    display: none;
    color: ${theme.colors.statusBlack};

    ${Input}:checked + & {
      display: block;
    }
  `
);
