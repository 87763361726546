import React from 'react';
import styled from 'styled-components/macro';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { useGetSubmissionDocumentImageQuery } from '../../hooks/queries';
import { useWindowSize } from '../../hooks/useWindowSize';

const PageViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
`;

const PageViewContent = styled.div`
  overflow: auto;
  height: 100%;
`;

const PageViewImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PageImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

type OcrulusSelectedPageViewProps = {
  submissionId: string;
  id: number;
  documentName: string;
  onImageLoad: (imageHeight: number) => void;
};

export const SelectedPageView = ({
  documentName,
  id,
  onImageLoad,
  submissionId,
}: OcrulusSelectedPageViewProps) => {
  const { data: imageData, isLoading: areImagesLoading } = useGetSubmissionDocumentImageQuery(
    submissionId,
    documentName,
    id
  );

  const imageRef = React.useRef<HTMLImageElement | null>(null);

  const handleImageDimensions = React.useCallback(() => {
    if (imageRef && imageRef?.current) {
      const bounds = imageRef.current.getBoundingClientRect();
      onImageLoad(bounds.height);
    }
  }, [imageRef, onImageLoad]);

  useWindowSize(handleImageDimensions);

  if (!imageData) {
    return null;
  }

  const isLoading = areImagesLoading;

  return (
    <ActivityIndicator contain isActive={isLoading} isFullScreen>
      <React.Fragment>
        <PageViewWrapper>
          <PageViewContent>
            <PageViewImageWrapper>
              <PageImage
                alt=""
                onLoad={() => handleImageDimensions()}
                ref={imageRef}
                src={imageData}
              />
            </PageViewImageWrapper>
          </PageViewContent>
        </PageViewWrapper>
      </React.Fragment>
    </ActivityIndicator>
  );
};
