import { Badge } from '../../../components/Badge';
import { HeadingLink } from '../../../components/HeadingLink';
import { Key, Pair, Value } from '../../../components/KeyValue';
import { formatTradeCreatedAtDate } from '../../tradeRequestsUtils';
import * as Styles from './Stack.styles';

type StackProps<T> = {
  icon: React.ReactNode;
  title: string;
  items: T[];
  maxItemCount?: number;
};

type StackItem = {
  createdAt?: Date;
};

export const Stack = <T extends StackItem>({
  icon,
  items,
  maxItemCount = 3,
  title,
}: StackProps<T>) => {
  const stackedItems = items
    .reverse()
    .slice(0, items.length < maxItemCount ? items.length : maxItemCount);

  return (
    <Styles.Stack>
      {stackedItems.map((stack, index) => (
        <Styles.StackItem $index={index} $maxItemCount={maxItemCount} key={`stack-${index}`}>
          <Styles.StackHeader>
            {icon}
            <HeadingLink $size="medium" to="/">
              {title}
            </HeadingLink>
            <Badge $size={24}>{items.length}</Badge>
          </Styles.StackHeader>
          <Styles.StackStats>
            <Pair $align="left" $direction="vertical">
              <Key>Status</Key>
              {/* TODO: Make status dynamic once stipulations can have status */}
              <Value>Pending</Value>
            </Pair>
            <Pair $align="left" $direction="vertical">
              <Key>Date</Key>
              <Value>
                {stack?.createdAt && formatTradeCreatedAtDate(new Date(stack.createdAt))}
              </Value>
            </Pair>
          </Styles.StackStats>
        </Styles.StackItem>
      ))}
    </Styles.Stack>
  );
};
