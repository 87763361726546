import { GetPoolLoansResponse, Identifier } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPoolLoans = (
  poolId: Identifier,
  filterIds: number[] = [],
  offset = 0,
  limit: number | undefined = undefined
) => {
  const query = new URLSearchParams({
    offset: `${offset}`,
    limit: limit ? `${limit}` : '',
  });

  filterIds.forEach((id) => query.append('filterIds', `${id}`));

  const url = makeApiUrl(`pool/${poolId}/loans?${query}`);
  return getRequestWithAuth<GetPoolLoansResponse>(url);
};
