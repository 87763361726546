import { Filter } from '@plus-platform/shared';
import React from 'react';

import { usePortfolioFiltersQuery, useSavePortfolioFiltersMutation } from '../hooks/queries';
import { getAppliedFilters } from '../utils/portfolioFilterUtils';

export const useLoanFilters = () => {
  const { data: savedFilters = [] } = usePortfolioFiltersQuery();
  const { mutateAsync: saveFilters } = useSavePortfolioFiltersMutation();
  const [localFilters, setLocalFilters] = React.useState<Filter[]>([]);

  const filters = React.useMemo(() => {
    return savedFilters.map((filter) => {
      const localFilter = localFilters.find(({ id }) => filter.id === id);
      const isApplied = localFilter?.isApplied ?? false;

      return { ...filter, isApplied };
    });
  }, [savedFilters, localFilters]);

  const appliedFilters = getAppliedFilters(filters);

  const updateFilters = async (filters: Filter[]) => {
    const filtersToSave = filters.map((filter) => {
      const savedFilter = savedFilters.find(({ id }) => filter.id === id);
      const isApplied = savedFilter?.isApplied ?? false;

      return { ...filter, isApplied };
    });

    const updatedFilters = await saveFilters(filtersToSave);

    if (!updatedFilters) {
      return;
    }

    const mergedFilters = filters.map((filter, index) => ({
      ...filter,
      id: updatedFilters[index].id,
    }));

    setLocalFilters(mergedFilters);
  };

  return { filters, appliedFilters, updateFilters };
};
