import { EvChargingStation } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type EvChargingStationsNearbyResponse = EvChargingStation[];

export const getEvChargingStationsNearby = async (params: URLSearchParams) => {
  return getRequestWithAuth<EvChargingStationsNearbyResponse>(
    makeApiUrl(`ev-charging-stations/nearby?${params}`)
  );
};

export const useEvChargingStationsNearbyQuery = (
  latitude: number,
  longitude: number,
  distance?: number | string
) => {
  const params = new URLSearchParams({
    latitude: String(latitude),
    longitude: String(longitude),
  });

  if (distance) {
    params.append('distance', String(distance <= 500 ? distance : 500));
  }

  return useQuery(
    [QueryKeys.EV_CHARGING_STATIONS_NEARBY, params.toString()],
    () => getEvChargingStationsNearby(params),
    { enabled: Boolean(latitude && longitude) }
  );
};
