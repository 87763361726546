import { GetPoolResponse, Identifier } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import * as api from '../../api/pools';
import { QueryKeys } from './types';

export const usePoolQuery = (poolId?: Identifier, onSuccess?: (data: GetPoolResponse) => void) =>
  useQuery([QueryKeys.POOL, poolId], () => api.getPool(poolId!), {
    enabled: Boolean(poolId),
    onSuccess: onSuccess ?? undefined,
  });
