import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMONumeric,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { FloorCovering } from './floorCovering';
import { RoomFeature } from './roomFeature';

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum LevelType {
  Attic = 'Attic',
  Basement = 'Basement',
  LevelFive = 'LevelFive',
  LevelFour = 'LevelFour',
  LevelOne = 'LevelOne',
  LevelThree = 'LevelThree',
  LevelTwo = 'LevelTwo',
  Other = 'Other',
}

enum RoomType {
  AllRooms = 'AllRooms',
  Bedroom = 'Bedroom',
  BonusRoom = 'BonusRoom',
  Den = 'Den',
  DiningRoom = 'DiningRoom',
  EnsuiteFullBath = 'EnsuiteFullBath',
  ExerciseRoom = 'ExerciseRoom',
  FamilyRoom = 'FamilyRoom',
  Foyer = 'Foyer',
  FullBathroom = 'FullBathroom',
  GameRoom = 'GameRoom',
  GreatRoom = 'GreatRoom',
  HalfBath = 'HalfBath',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  Library = 'Library',
  LivingRoom = 'LivingRoom',
  MasterBedroom = 'MasterBedroom',
  MediaRoom = 'MediaRoom',
  Office = 'Office',
  Other = 'Other',
  RecreationRoom = 'RecreationRoom',
  UtilityRoom = 'UtilityRoom',
  Workshop = 'Workshop',
}

export type Room = MISMOEntity &
  Partial<{
    aboveGradeIndicator: MISMOIndicator;
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    lengthFeetNumber: MISMONumeric;
    levelType: LevelType;
    levelTypeOtherDescription: MISMOString;
    roomType: RoomType;
    roomTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    squareFeetNumber: MISMONumeric;
    widthFeetNumber: MISMONumeric;
    floorCovering: FloorCovering;
    roomFeatures: RoomFeature[];
  }>;
