import { BorrowerDetails } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getBorrowerCreditScores } from '../../utils/borrowerUtils';
import { QueryKeys } from './types';

export const useGetBorrowerCreditScoresQuery = (borrowerDetails: BorrowerDetails) =>
  useQuery([QueryKeys.BORROWER_CREDIT_SCORES, borrowerDetails], () =>
    getBorrowerCreditScores(borrowerDetails)
  );
