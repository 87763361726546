import { lighten, rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { skeletonLoading } from '../styles/keyframes';

export const Skeleton = styled.div<{
  height?: number | string;
  width?: number | string;
  background?: string;
}>(
  ({ background, height, theme, width }) => css`
    position: relative;
    background: ${background || theme.colors.purpleDark};
    width: ${width ? rem(width) : `100%`};
    height: ${height ? rem(height) : `30px`};
    border-radius: 4px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      animation: ${skeletonLoading} 2.5s infinite 1s;
      background-image: linear-gradient(
        90deg,
        ${background || theme.colors.purpleDark} 0%,
        ${lighten(0.05, background || theme.colors.purpleDark)} 25%,
        ${background || theme.colors.purpleDark} 50%
      );
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  `
);
