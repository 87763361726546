import React from 'react';

export const BubbleChartIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx="17" cy="16" rx="3" ry="3" fill="currentColor" fillOpacity="0.7" />
      <circle cx="7" cy="7" r="3" fill="currentColor" fillOpacity="0.7" />
      <ellipse cx="4" cy="13" rx="1" ry="1" fill="currentColor" fillOpacity="0.5" />
      <circle cx="20" cy="11" r="1" fill="currentColor" fillOpacity="0.5" />
      <ellipse cx="6" cy="17" rx="2" ry="2" fill="currentColor" fillOpacity="0.5" />
      <ellipse cx="18" cy="7" rx="2" ry="2" fill="currentColor" fillOpacity="0.5" />
      <circle cx="12" cy="12" r="5.75" fill="white" stroke="#1B1F37" strokeWidth="0.5" />
    </svg>
  );
};
