import { US_STATES } from '@plus-platform/shared';
import { geoCentroid } from 'd3-geo';
import { useState } from 'react';
import { ComposableMap, Geographies } from 'react-simple-maps';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useTheme } from 'styled-components';

import GEOGRAPHY_URL from '../assets/topologyUSA.json';
import * as Styles from './Chloropleth.styles';
import { StatesTable } from './StatesTable';

const STATES_OFFSETS: { [key: string]: [number, number] } = {
  VT: [124, -36],
  NH: [58, 7],
  MA: [130, 0],
  RI: [120, 30],
  CT: [64, 20],
  NJ: [44, 20],
  DE: [106, 36],
  MD: [64, 54],
  DC: [70, 100],
};

export type ChloroplethProps = {
  id?: string;
  data: { [key: string]: { value: number; formattedValue: string } };
  hasLegend?: boolean;
  hasLabels?: boolean;
  style?: React.CSSProperties;
  getMapDefaultColor: (value: number) => string;
  onStateClick?: (state: string) => void;
};

export const Chloropleth = ({
  data,
  getMapDefaultColor,
  hasLabels,
  hasLegend = true,
  id = '',
  onStateClick,
  style,
}: ChloroplethProps) => {
  const [selectedState, setSelectedState] = useState<string>(US_STATES[0].code);

  const theme = useTheme();

  const mapColCount = hasLegend ? 6 : 12;

  return (
    <Styles.MapWrapper>
      <Container fluid style={{ padding: 0 }}>
        <Row style={{ margin: 0 }}>
          {hasLegend && (
            <StatesTable
              id={id}
              data={data}
              styledColumnCount={6}
              selectedState={selectedState}
              onStateClick={(stateCode) => setSelectedState(stateCode)}
            />
          )}

          <Col col={mapColCount}>
            <ComposableMap
              projection="geoAlbers"
              height={600}
              width={1200}
              projectionConfig={{
                scale: 1200,
              }}
            >
              <Geographies geography={GEOGRAPHY_URL}>
                {({ geographies }) => (
                  <>
                    {geographies.map((geo) => {
                      const geoData = data[geo.properties.iso_3166_2];

                      const threshold = geoData?.value || 0;
                      const defaultColor = getMapDefaultColor(threshold);

                      return (
                        <Styles.Map
                          key={`${id}${geo.rsmKey}`}
                          geography={geo}
                          fill={defaultColor}
                          style={{
                            default: {
                              fill:
                                geo.properties.iso_3166_2 === selectedState
                                  ? theme.colors.accent01
                                  : defaultColor,
                              stroke: theme.colors.white,
                              ...style,
                            },
                            hover: { fill: theme.colors.accent01 },
                          }}
                          onMouseEnter={() => {
                            setSelectedState(geo.properties.iso_3166_2);
                          }}
                          onMouseDown={() => {
                            onStateClick?.(geo.properties.iso_3166_2);
                          }}
                        />
                      );
                    })}
                    {hasLabels &&
                      geographies.map((geo) => {
                        const centroid = geoCentroid(geo);

                        const geoData = data[geo.properties.iso_3166_2];

                        if (geoData?.value === undefined) {
                          return null;
                        }

                        const isStateInland =
                          centroid[0] > -160 &&
                          centroid[0] < -67 &&
                          Object.keys(STATES_OFFSETS).indexOf(geo.properties.iso_3166_2) === -1;

                        return (
                          <g key={`${id}${geo.rsmKey}-name`}>
                            {isStateInland ? (
                              <Styles.MapMarker coordinates={centroid}>
                                <Styles.MapCircle cx="-2" cy="-4" r={26} />
                                <Styles.MapText y="2" fontSize={28} textAnchor="middle">
                                  {data?.formattedValue}
                                </Styles.MapText>
                              </Styles.MapMarker>
                            ) : (
                              <Styles.MapAnnotation
                                subject={centroid}
                                dx={STATES_OFFSETS?.[geo.properties.iso_3166_2]?.[0]}
                                dy={STATES_OFFSETS?.[geo.properties.iso_3166_2]?.[1]}
                                connectorProps={{
                                  stroke: theme.colors.white,
                                  strokeWidth: 2,
                                  strokeLinecap: 'round',
                                }}
                                curve={0.2}
                              >
                                <Styles.MapCircle cx="-2" cy="-4" r={26} />
                                <Styles.MapText x="-24" fontSize={28} alignmentBaseline="middle">
                                  {geoData?.formattedValue}
                                </Styles.MapText>
                              </Styles.MapAnnotation>
                            )}
                          </g>
                        );
                      })}
                  </>
                )}
              </Geographies>
            </ComposableMap>
          </Col>
        </Row>
      </Container>
    </Styles.MapWrapper>
  );
};
