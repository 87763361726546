import React from 'react';
import styled, { css } from 'styled-components/macro';

import { AnalyticCount } from './Analytic';
import { DelinquencyByDaysVariant } from './types';

type LoanCountProps = {
  $variant: DelinquencyByDaysVariant;
};

export const LoanCount = styled(AnalyticCount)<LoanCountProps>`
  ${(props) =>
    props.$variant === '30-day' &&
    css`
      color: ${props.theme.colors.statusYellow};
    `}

  ${(props) =>
    props.$variant === '60-day' &&
    css`
      color: ${props.theme.colors.statusOrange};
    `}

  ${(props) =>
    props.$variant === '90-day' &&
    css`
      color: ${props.theme.colors.statusRed};
    `}
`;

export const Bars = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  column-gap: 4px;
  padding-bottom: 10px;
`;

type BarProps = {
  $variant: DelinquencyByDaysVariant;
  $isHighlighted: boolean;
  $width?: React.CSSProperties['width'];
};

export const Bar = styled.li<BarProps>`
  flex: 1;
  height: 4px;
  flex-basis: ${(props) => props.$width};

  &:first-child {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
  }

  &:last-child {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
  }

  ${(props) =>
    props.$variant === '30-day' &&
    css`
      background-color: ${({ theme }) => theme.colors.statusYellow};
    `}

  ${(props) =>
    props.$variant === '60-day' &&
    css`
      background-color: ${({ theme }) => theme.colors.statusOrange};
    `}

  ${(props) =>
    props.$variant === '90-day' &&
    css`
      background-color: ${({ theme }) => theme.colors.statusRed};
    `}

  ${(props) =>
    !props.$isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.deprecatedPrimary03};
    `}
`;
