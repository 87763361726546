import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum BathEquipmentType {
  AutomaticVentSystem = 'AutomaticVentSystem',
  DualSinks = 'DualSinks',
  GardenTub = 'GardenTub',
  HeatedFloors = 'HeatedFloors',
  HeatLamps = 'HeatLamps',
  LowFlowShowerheads = 'LowFlowShowerheads',
  LowFlushToliet = 'LowFlushToliet',
  Other = 'Other',
  RomanShower = 'RomanShower',
  SingleSink = 'SingleSink',
  SolidSurfaceManmadeCountertop = 'SolidSurfaceManmadeCountertop',
  SolidSurfaceNaturalCountertop = 'SolidSurfaceNaturalCountertop',
  StallShower = 'StallShower',
  SteamShower = 'SteamShower',
  TubShower = 'TubShower',
  Vanity = 'Vanity',
  WaterEffecientFaucet = 'WaterEffecientFaucet',
}

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type BathEquipment = MISMOEntity &
  Partial<{
    bathEquipmentCount: MISMOCount;
    bathEquipmentDescription: MISMOString;
    bathEquipmentType: BathEquipmentType;
    bathEquipmentTypeOtherDescription: MISMOString;
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
  }>;
