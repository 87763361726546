import React, { useRef, useState } from 'react';
import { useTheme } from 'styled-components/macro';

import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { isDarkTheme } from '../styles/theme';
import { MenuFillIcon, MenuLineIcon } from './icons';
import * as Styles from './Menu.styles';
import { Modal } from './Modal';
import { ButtonIconToggle } from './Toggles/ButtonIconToggle';

export type MenuProps = {
  children: React.ReactNode;
  title: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  'data-testid'?: string;
  toggleProps?: MenuToggleProps;
  width?: React.CSSProperties['width'];
  direction?: 'left' | 'right';
  paddingTop?: number;
  paddingSide?: number;
};

type CustomToggleProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
};

type MenuToggleProps = {
  icon?: React.ReactElement;
  activeIcon?: React.ReactElement;
  size?: 'small' | 'medium' | 'large';
  customToggle?: (props: CustomToggleProps) => React.ReactNode;
};

export const Menu = ({
  children,
  'data-testid': dataTestId,
  direction = 'left',
  onMenuClose,
  onMenuOpen,
  paddingSide = 9,
  paddingTop = 9,
  title,
  toggleProps,
  width,
}: MenuProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [top, setTop] = useState<number | undefined>(undefined);
  const [left, setLeft] = useState<number | undefined>(undefined);
  const [right, setRight] = useState<number | undefined>(undefined);

  useOnClickOutside(menuRef, () => setIsVisible(false));

  const setModalBounds = () => {
    if (!triggerRef || !triggerRef.current) {
      return;
    }

    const bounds = triggerRef && triggerRef.current.getBoundingClientRect();

    setTop(bounds.top - paddingTop + window.scrollY);

    if (direction === 'right') {
      setLeft(bounds.left - paddingSide);
    }

    if (direction === 'left') {
      setRight(window.innerWidth - bounds.right - paddingSide);
    }
  };

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isVisible) {
      onMenuOpen?.();
    } else {
      onMenuClose?.();
    }

    setIsVisible(!isVisible);
    setModalBounds();
  };

  return (
    <Styles.Menu ref={triggerRef} data-testid={dataTestId}>
      <Toggle
        icon={toggleProps?.icon ?? <MenuLineIcon />}
        onClick={handleToggle}
        customToggle={toggleProps?.customToggle}
        size={toggleProps?.size}
      />
      {isVisible && (
        <Modal
          ref={menuRef}
          top={top}
          right={right}
          left={left}
          width={width}
          onClick={() => setIsVisible(false)}
          data-testid="ActionsMenu_Content"
        >
          <Styles.MenuHeader $direction={direction}>
            <>
              <Styles.MenuTitle>{title}</Styles.MenuTitle>
              <Toggle
                icon={toggleProps?.activeIcon ?? <MenuFillIcon />}
                onClick={handleToggle}
                customToggle={toggleProps?.customToggle}
                size={toggleProps?.size}
                isActive
              />
            </>
          </Styles.MenuHeader>
          {children}
        </Modal>
      )}
    </Styles.Menu>
  );
};

type ToggleProps = {
  customToggle?: (props: CustomToggleProps) => React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: MenuToggleProps['icon'];
  size?: MenuToggleProps['size'];
  isActive?: boolean;
};

const Toggle = ({
  customToggle,
  icon,
  isActive = false,
  onClick,
  size = 'medium',
}: ToggleProps) => {
  const theme = useTheme();
  const variant = isDarkTheme(theme) ? 'dark' : 'light';

  return (
    <>
      {customToggle?.({ onClick, isActive }) ?? (
        <ButtonIconToggle
          onClick={onClick}
          size={size}
          isActive={isActive}
          value=""
          data-testid="ActionsMenu_Button"
          variant={variant}
        >
          {icon}
        </ButtonIconToggle>
      )}
    </>
  );
};

type MenuItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  to?: string;
  isSubmenu?: boolean;
};

export const MenuItem = ({ children, disabled, isSubmenu, to, ...rest }: MenuItemProps) => {
  if (isSubmenu) {
    return (
      <Styles.MenuListItem>
        <Styles.MenuItemDiv $hasSubmenu={isSubmenu} $isDisabled={disabled}>
          {children}
        </Styles.MenuItemDiv>
      </Styles.MenuListItem>
    );
  }

  if (to) {
    return (
      <Styles.MenuListItem>
        {disabled ? (
          <Styles.MenuItemDiv $isDisabled={disabled}>{children}</Styles.MenuItemDiv>
        ) : (
          <Styles.MenuItemLink to={to}>{children}</Styles.MenuItemLink>
        )}
      </Styles.MenuListItem>
    );
  }

  return (
    <Styles.MenuListItem>
      <Styles.MenuItemButton disabled={disabled} {...rest}>
        {children}
      </Styles.MenuItemButton>
    </Styles.MenuListItem>
  );
};

export { MenuItemLink, MenuItemText, MenuSection, SubMenu } from './Menu.styles';
