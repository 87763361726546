import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum WindowType {
  Aluminum = 'Aluminum',
  Awning = 'Awning',
  BayWindow = 'BayWindow',
  BeveledGlass = 'BeveledGlass',
  BowWindow = 'BowWindow',
  Casement = 'Casement',
  CenterPivot = 'CenterPivot',
  CircleHead = 'CircleHead',
  Clerestory = 'Clerestory',
  Combination = 'Combination',
  CrankOut = 'CrankOut',
  Decorative = 'Decorative',
  Dormer = 'Dormer',
  DoubleHung = 'DoubleHung',
  DoublePane = 'DoublePane',
  EtchedGlass = 'EtchedGlass',
  FixedPane = 'FixedPane',
  French = 'French',
  Hopper = 'Hopper',
  HorizontalSliding = 'HorizontalSliding',
  Insulated = 'Insulated',
  Jalousie = 'Jalousie',
  LeadedGlass = 'LeadedGlass',
  LowE = 'LowE',
  Oriel = 'Oriel',
  Other = 'Other',
  Palladian = 'Palladian',
  Picture = 'Picture',
  SingleHung = 'SingleHung',
  Sliding = 'Sliding',
  StainedGlass = 'StainedGlass',
  Steel = 'Steel',
  ThermalPane = 'ThermalPane',
  Thermopane = 'Thermopane',
  Transom = 'Transom',
  Transverse = 'Transverse',
  Vinyl = 'Vinyl',
  Wood = 'Wood',
}

export type Window = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
    windowDescription: MISMOString;
    windowType: WindowType;
    windowTypeOtherDescription: MISMOString;
  }>;
