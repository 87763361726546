import { timingFunctions } from 'polished';
import styled from 'styled-components/macro';

export const Input = styled.input<{ $isInvalid?: boolean }>`
  width: 100%;
  height: 46px;
  padding: 8px 16px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.greyLight};
  border-width: 1px;
  border-radius: 4px;
  outline: 0;
  font-family: ${(props) => props.theme.typography.fontFamily.text};
  font-size: 14px;
  font-weight: 400;
  transition: color 666ms ${timingFunctions('easeOutExpo')},
    border-color 666ms ${timingFunctions('easeOutExpo')},
    box-shadow 666ms ${timingFunctions('easeOutExpo')};

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    outline: none;

    ${(props) =>
      !props.$isInvalid &&
      `
        border-color: ${props.theme.colors.greenMain};
        box-shadow: 0px 0px 0px 3px ${props.theme.colors.greenLight}
      `}
  }

  &:not(:disabled) {
    ${(props) =>
      props.$isInvalid &&
      `
      border-color: ${props.theme.colors.greenMain};
      border-width: 2px;
    `}
  }

  &::placeholder {
    font-family: ${(props) => props.theme.typography.fontFamily.text};
    font-size: 14px;
    color: ${(props) => props.theme.colors.statusBlack};
  }
`;
