import { Filter, FilterKey } from '@plus-platform/shared';
import isUndefined from 'lodash/isUndefined';
import words from 'lodash/words';

import { formatDelinquency } from '../utils/formatUtils';

export type Column = {
  title: string;
  key: FilterKey;
};

export const columns: Column[] = [
  { title: 'Loan ID', key: FilterKey.ID },
  { title: 'Status', key: FilterKey.IS_DELINQUENT },
  { title: 'State', key: FilterKey.STATE },
  { title: 'Purchase price', key: FilterKey.PURCHASE_PRICE },
  { title: 'Current Value', key: FilterKey.CURRENT_BPO },
  { title: 'Down payment', key: FilterKey.DOWN_PAYMENT_AMOUNT },
  { title: 'Original UPB', key: FilterKey.ORIGINAL_UPB },
  { title: 'Current UPB', key: FilterKey.CURRENT_UPB },
  { title: 'Current LTV', key: FilterKey.CURRENT_LTV },
  { title: 'Principal', key: FilterKey.PRINCIPAL },
  { title: 'Interest', key: FilterKey.INTEREST },
  { title: 'Taxes', key: FilterKey.TAXES },
  { title: 'Insurance', key: FilterKey.INSURANCE },
  { title: 'HOA', key: FilterKey.HOA_FEE },
  { title: 'MI', key: FilterKey.MORTGAGE_INSURANCE },
  { title: 'Originator', key: FilterKey.ORIGINATOR },
  { title: 'Loan type', key: FilterKey.LOAN_TYPE },
  { title: 'Interest rate', key: FilterKey.NOTE_RATE },
];

export const getAppliedFilters = (filters: Filter[]) => {
  return filters.filter((filter) => filter.isApplied && !filter.isDeleted);
};

const getFilterLabelValue = (filter: Filter) => {
  const { key, value } = filter;

  if (isUndefined(value)) {
    return;
  }

  if (key === FilterKey.IS_DELINQUENT) {
    return formatDelinquency(value === 'true');
  }

  return value;
};

export const getFilterLabel = (filter: Filter) => {
  const { key, name } = filter;

  if (name) {
    return name;
  }

  const column = columns.find((column) => column.key === key);
  const operator = words(filter.operator).join(' ').toLowerCase();
  const value = getFilterLabelValue(filter);

  return `${column?.title ?? key} ${operator} ${value}`;
};
