import React from 'react';

import type { PairProps } from './KeyValue.styles';
import * as Styles from './KeyValue.styles';

type KeyValueListProps = PairProps & {
  children: React.ReactNode;
  rowGap?: number;
};

export const KeyValueList = ({ children, rowGap = 0, ...rest }: KeyValueListProps) => {
  return (
    <Styles.KVListWrapper $rowGap={rowGap}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }
        return React.cloneElement(child, rest);
      })}
    </Styles.KVListWrapper>
  );
};

export const Pair = ({
  $type,
  children,
  ...rest
}: PairProps & {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const isList = $type === 'list';

  return (
    <Styles.Pair $type={$type} {...rest}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        if (isList) {
          return React.cloneElement(child, { $hasPadding: true });
        }

        return React.cloneElement(child, rest);
      })}
    </Styles.Pair>
  );
};

export type { PairProps } from './KeyValue.styles';
export { Key, TitleKey, Value, ValueWithAlert } from './KeyValue.styles';
