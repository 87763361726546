import React from 'react';

import { Bar, CustomInput, Label, Range, Tick, Wrapper } from './GraduatedRange.styles';

const MAX_CONTAINER_WIDTH = 640 - 2 * 16 - 2 * 12;
const THUMB_WIDTH = 16;

type GraduatedRangeProps = {
  id: string;
  startValue: number;
  value: number;
  endValue: number;
  valueToLabelMapping: Record<string, string>;
  onChange: (value: string) => void;
};

export const GraduatedRange = ({
  endValue,
  id,
  onChange,
  startValue,
  value,
  valueToLabelMapping,
}: GraduatedRangeProps) => {
  const [currentValue, setCurrentValue] = React.useState<string>(String(value));

  React.useEffect(() => {
    setCurrentValue(String(value));
  }, [value]);

  const totalItemsCount = endValue - startValue + 1;
  const ticks = [...Array(totalItemsCount).keys()].map((n) => ({
    value: n,
    label: valueToLabelMapping[n] || '',
  }));

  return (
    <Wrapper>
      <CustomInput
        value={currentValue}
        type="range"
        max={endValue}
        min={startValue}
        step="1"
        list={id}
        onChange={(event) => {
          setCurrentValue(event.target.value);
          onChange(event.target.value);
        }}
      />
      <Range id={id} $width={MAX_CONTAINER_WIDTH}>
        {ticks.map((tick, index) => {
          const itemWidth = MAX_CONTAINER_WIDTH / (totalItemsCount - 1);
          const thumbOffset = THUMB_WIDTH / 2 - (THUMB_WIDTH / totalItemsCount) * index;
          const offsetX = itemWidth * index + thumbOffset;
          const isSelected = Number(currentValue) > tick.value;
          const isCurrent = Number(currentValue) === tick.value;
          const shouldBeCentered = index !== 0 && index !== ticks.length - 1;
          const hasExtraElement = isCurrent && index !== ticks.length - 1;
          const alignSelf = index === ticks.length - 1 ? 'flex-end' : 'flex-start';

          return (
            <Tick
              key={`${tick.value}-${index}`}
              $isCurrent={isCurrent}
              $isSelected={isSelected}
              $shouldBeCentered={shouldBeCentered}
              $hasExtraElement={hasExtraElement}
              $offsetX={offsetX}
              $width={index === ticks.length - 1 ? 0 : itemWidth}
            >
              <Bar />
              <Label $alignSelf={alignSelf} value={tick.value}>
                {tick.label}
              </Label>
            </Tick>
          );
        })}
      </Range>
    </Wrapper>
  );
};
