import { PortfolioBreakdown } from '@plus-platform/shared';

import { formatNumber } from '../../utils/formatUtils';
import {
  Analytic,
  AnalyticContainer,
  AnalyticCount,
  AnalyticHeader,
  AnalyticTitle,
} from './Analytic';
import { BreakdownTileContent } from './BreakdownTile';
import { mapBreakdownForLoanCountChart } from './breakdownUtils';
import { Bar, BarValue } from './LoanTypeMetrics.styles';

type LoanTypesMetricsProps = {
  portfolioBreakdown?: PortfolioBreakdown;
};

export const LoanTypesMetrics = (props: LoanTypesMetricsProps) => {
  const { portfolioBreakdown } = props;

  const { data } = mapBreakdownForLoanCountChart(portfolioBreakdown);

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Loan types</AnalyticTitle>
          <AnalyticCount>{formatNumber(data.length)}</AnalyticCount>
        </AnalyticHeader>

        <BreakdownTileContent
          label="All"
          value={
            <BarValue>
              <Bar />
              {portfolioBreakdown?.totalLoansCount}
            </BarValue>
          }
        />

        {data.map((data) => (
          <BreakdownTileContent
            label={data.label}
            value={
              <BarValue>
                <Bar $width={data.width} $offset={data.offset} />
                {data.count}
              </BarValue>
            }
            key={data.label}
          />
        ))}
      </AnalyticContainer>
    </Analytic>
  );
};
