import {
  TradeLogType,
  TradeRequestActionLog,
  TradeRequestCounterLog,
  TradeRequestLog,
  TradeRequestQuoteLog,
  TradeRequestWithSummary,
} from '@plus-platform/shared';
import compact from 'lodash/compact';
import React from 'react';
import { InfiniteData } from 'react-query';

import {
  TradeRequestTransactionLogsResponse,
  useTradeRequestTransactionLogsQuery,
} from '../hooks/queries';
import { UserProfile } from '../users/types';
import { formatPercentage } from '../utils/formatUtils';
import { getTradeMessage, stripMessageTags, TradeMessageId } from './tradeMessagesUtil';
import { QuoteSummaryType } from './TradeRequestMessenger/TradeRequestQuotes/types';
import { formatNameWithInitial } from './tradeRequestsUtils';
import {
  ACTION_TYPE_TO_LOG_TYPE_MAP,
  getLogMessageIdFromAction,
  getLogMessageIdFromCounter,
  getLogMessageIdFromQuote,
} from './transactionLogUtils';
import { TradeTransactionLog } from './types';

export const getItemsFromInfiniteData = (
  data?: InfiniteData<TradeRequestTransactionLogsResponse | undefined>
) => {
  return compact(data?.pages).flatMap((page) => page.logs) ?? [];
};

const getTotalCountFromInfiniteData = (
  data?: InfiniteData<TradeRequestTransactionLogsResponse | undefined>
) => {
  return data?.pages[data?.pages.length - 1]?.totalCount ?? 0;
};

const getLogMessage = (
  log: TradeRequestLog,
  tradeRequest: TradeRequestWithSummary,
  isSeller: boolean
) => {
  const { type } = log;

  if (type === TradeLogType.DM) {
    return log.metadata.content;
  }

  let messageId;

  if (type === TradeLogType.ACTION) {
    messageId = getLogMessageIdFromAction(log, isSeller);
  }

  if (type === TradeLogType.QUOTE) {
    messageId = getLogMessageIdFromQuote(log, isSeller);
  }

  if (type === TradeLogType.COUNTER) {
    messageId = getLogMessageIdFromCounter(log, isSeller);
  }

  if (!messageId) {
    return;
  }

  return getLogMessageFromId(messageId, tradeRequest, log);
};

const getLogMessageFromId = (
  id: TradeMessageId,
  tradeRequest: TradeRequestWithSummary,
  log: TradeRequestLog
) => {
  const { fromCounterparty, toCounterparty } = log;

  const optionsMap: Partial<Record<TradeMessageId, Record<string, string | number | undefined>>> = {
    'Trade.Outbound.ExpressionOfInterest.Requested': {
      poolName: tradeRequest.pool.name,
    },
    'Trade.Inbound.ExpressionOfInterest.Requested': {
      poolName: tradeRequest.pool.name,
    },
    'Trade.Outbound.ExpressionOfInterest.Accepted': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.ExpressionOfInterest.Accepted': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
    },
    'Trade.Outbound.ExpressionOfInterest.DeclinedWithReason': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
      reason: (log as TradeRequestActionLog).metadata.reason,
    },
    'Trade.Inbound.ExpressionOfInterest.DeclinedWithReason': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
      reason: (log as TradeRequestActionLog).metadata.reason,
    },
    'Trade.Outbound.ExpressionOfInterest.DeclinedWithoutReason': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.ExpressionOfInterest.DeclinedWithoutReason': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
    },
    'Trade.Outbound.DataRequest.Requested': {
      poolName: tradeRequest.pool.name,
      ticketId: tradeRequest.id,
    },
    'Trade.Inbound.DataRequest.Requested': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
    },
    'Trade.Outbound.DataRequest.Accepted': {
      poolName: tradeRequest.pool.name,
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.DataRequest.Accepted': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
    },
    'Trade.Outbound.DataRequest.DeniedWithReason': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
      reason: (log as TradeRequestActionLog).metadata.reason,
    },
    'Trade.Inbound.DataRequest.DeniedWithReason': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      reason: (log as TradeRequestActionLog).metadata.reason,
    },
    'Trade.Outbound.DataRequest.DeniedWithoutReason': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.DataRequest.DeniedWithoutReason': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
    },
    'Trade.Outbound.Quote.PendingWithStipulations': {
      poolName: tradeRequest.pool.name,
      ticketId: tradeRequest.id,
      value: formatPercentage((log as TradeRequestQuoteLog).metadata.value),
    },
    'Trade.Outbound.Quote.PendingWithoutStipulations': {
      poolName: tradeRequest.pool.name,
      ticketId: tradeRequest.id,
      value: formatPercentage((log as TradeRequestQuoteLog).metadata.value),
    },
    'Trade.Inbound.Quote.Pending': {
      poolName: tradeRequest.pool.name,
      ticketId: tradeRequest.id,
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Outbound.Quote.Rejected': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.Quote.Rejected': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
    },
    'Trade.Outbound.Quote.Countered': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.Quote.Counter.Received': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      linkedQuoteId: (log as TradeRequestCounterLog).metadata.linkedQuoteId,
    },
    'Trade.Outbound.Quote.Counterback.Received': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      linkedQuoteId: (log as TradeRequestCounterLog).metadata.linkedQuoteId,
    },
    'Trade.Inbound.Quote.Counterbacked': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Outbound.Quote.Counterback.Accepted': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
    },
    'Trade.Inbound.Quote.Counterback.Accepted': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
      counterType: QuoteSummaryType.COUNTERBACK.toLowerCase(),
    },
    'Trade.Outbound.Quote.Counter.Accepted': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
    },
    'Trade.Inbound.Quote.Counter.Accepted': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
      counterType: QuoteSummaryType.COUNTER.toLowerCase(),
    },
    'Trade.Outbound.Quote.Counterback.Rejected': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
      counterType: QuoteSummaryType.COUNTERBACK.toLowerCase(),
    },
    'Trade.Inbound.Quote.Counterback.Rejected': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
      counterType: QuoteSummaryType.COUNTERBACK.toLowerCase(),
    },
    'Trade.Outbound.Quote.Counter.Rejected': {
      userName: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organizationName: fromCounterparty.organization.tradingName,
    },
    'Trade.Inbound.Quote.Counter.Rejected': {
      userName: formatNameWithInitial(toCounterparty.lastName, toCounterparty.firstName),
      organizationName: toCounterparty.organization.tradingName,
      counterType: QuoteSummaryType.COUNTER.toLowerCase(),
    },
  };

  if (optionsMap[id]) {
    return stripMessageTags(getTradeMessage(id, optionsMap[id]));
  }
};

const getLogCategory = (log: TradeRequestLog) => {
  const { type } = log;

  if (type === TradeLogType.DM) {
    return 'DM';
  }

  if (type === TradeLogType.ACTION) {
    const actionType = (log as TradeRequestActionLog).metadata.type;
    return ACTION_TYPE_TO_LOG_TYPE_MAP[actionType];
  }

  if ([TradeLogType.QUOTE, TradeLogType.COUNTER].includes(type)) {
    return 'Bids';
  }
};

const getLogDocuments = (log: TradeRequestLog) => {
  const { type } = log;

  if (type === TradeLogType.ACTION) {
    return log.metadata.stipulation?.stipulation;
  }

  if (type === TradeLogType.QUOTE) {
    return log.metadata.stipulation?.quoteStipulation;
  }

  if (type === TradeLogType.COUNTER) {
    return log.metadata.stipulation?.quoteStipulation;
  }
};

export const getAggregatedLog = (
  log: TradeRequestLog,
  tradeRequest?: TradeRequestWithSummary,
  userProfile?: UserProfile
) => {
  if (!tradeRequest || !userProfile) {
    return;
  }

  const isCurrentUserSeller = tradeRequest.user.id === userProfile.id;

  const category = getLogCategory(log);
  const message = getLogMessage(log, tradeRequest, isCurrentUserSeller);
  const stipulation = getLogDocuments(log);

  if (!message || !category) {
    return;
  }

  return {
    createdAt: new Date(log.createdAt),
    fromCounterparty: log.fromCounterparty,
    toCounterparty: log.toCounterparty,
    message,
    category,
    stipulation,
  } as TradeTransactionLog;
};

export const useTradeRequestTransactionLogs = (
  tradeRequest?: TradeRequestWithSummary,
  userProfile?: UserProfile,
  onSuccess?: (data: InfiniteData<TradeRequestTransactionLogsResponse | undefined>) => void
) => {
  const { data, ...query } = useTradeRequestTransactionLogsQuery(
    tradeRequest ? String(tradeRequest?.id) : undefined,
    Boolean(tradeRequest && userProfile),
    onSuccess
  );

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

  const canFetchMoreActions = hasNextPage && !isFetchingNextPage;

  const fetchMoreActions = React.useCallback(() => {
    if (canFetchMoreActions) {
      fetchNextPage();
    }
  }, [canFetchMoreActions, fetchNextPage]);

  const logData = React.useMemo(
    () => ({
      logs: compact(
        getItemsFromInfiniteData(data).map((log) =>
          getAggregatedLog(log, tradeRequest, userProfile)
        )
      ) as TradeTransactionLog[],
      totalCount: getTotalCountFromInfiniteData(data),
      allLogIds: data?.pages.flatMap((page) => page?.allIds ?? []) ?? [],
      canFetchMoreActions,
      fetchMoreActions,
    }),
    [data, canFetchMoreActions, fetchMoreActions]
  );

  return { ...logData, ...query };
};
