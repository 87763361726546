import { Dialog } from '@headlessui/react';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

export const StyledDialog = styled(Dialog)`
  background: black;
  position: relative;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
  }
`;

export const StyledDialogOverlay = styled(Dialog.Overlay)`
  ${({ theme }) => css`
    background: ${transparentize(0.75, theme.colors.white)};
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const StyledPanel = styled(Dialog.Panel)`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    position: relative;
    border-radius: 4px;
    width: 100%;
    max-width: 640px;
    overflow: hidden;
  `}
`;

export const StyledTitle = styled(Dialog.Title)<{ $bgColor?: string }>`
  ${({ $bgColor, theme }) => css`
    background-color: ${$bgColor ?? theme.colors.background01};
    color: ${theme.colors.white};
    padding: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      margin-right: 8px;
    }
  `}
`;

export const Content = styled.div`
  padding: 16px;
`;

export const ModalButton = styled.button<{ $style?: 'outlined' }>`
  ${({ $style, theme }) => css`
    opacity: 0.8;
    background: ${theme.colors.greenLight};
    border: 0.5px solid ${theme.colors.greenLight};
    color: ${theme.colors.white};
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    min-width: 160px;
    padding: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${$style === 'outlined' &&
    css`
      background: ${theme.colors.white};
      border: 0.5px solid ${theme.colors.greenLight};
      color: ${theme.colors.greenLight};
    `}
  `}
`;
