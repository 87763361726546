export enum SubmissionStep {
  UPLOAD = 'UPLOAD',
  INGEST = 'INGEST',
  VALIDATE = 'VALIDATE',
  CREATE = 'CREATE',
}

export const SUBMISSION_STEP_LABELS_MAPPING: Record<SubmissionStep, string> = {
  [SubmissionStep.UPLOAD]: 'Upload',
  [SubmissionStep.INGEST]: 'Ingest',
  [SubmissionStep.VALIDATE]: 'Validate',
  [SubmissionStep.CREATE]: 'Create',
};

export const getPercentageComplete = (startTime?: Date, endTime?: Date) => {
  if (!startTime || !endTime) {
    return 0;
  }
  const start = startTime.getTime();
  const end = endTime.getTime();
  const current = new Date().getTime();

  return (current - start) / (end - start);
};
