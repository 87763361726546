import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOString,
} from './types';

enum DefaultStatusType {
  Bankruptcy = 'Bankruptcy',
  DefaultImminent = 'DefaultImminent',
  Forbearance = 'Forbearance',
  Foreclosure = 'Foreclosure',
  InDefault = 'InDefault',
  Other = 'Other',
}

enum LoanImminentDefaultSourceType {
  InvestorModel = 'InvestorModel',
  Other = 'Other',
  ServicerEvaluation = 'ServicerEvaluation',
}

export type DelinquencySummary = MISMOEntity &
  Partial<{
    delinquentPaymentCount: MISMOCount;
    delinquentPaymentsOverPastTwelveMonthsCount: MISMOCount;
    loanDelinquencyHistoryPeriodMonthsCount: MISMOCount;
    loanReinstateTotalAmount: MISMOAmount;
    netLiquidationProceedsAmount: MISMOAmount;
    onTimePaymentCount: MISMOCount;
    paymentDelinquentDaysCount: MISMOCount;
    totalDelinquentInterestAmount: MISMOAmount;
    totalDelinquentInterestReportedDate: MISMODate;
    totalLiquidationExpenseAmount: MISMOAmount;
  }>;

export type Servicing = MISMOEntity &
  Partial<{
    activeServicingTransferIndicator: MISMOIndicator;
    defaultStatusType: DefaultStatusType;
    defaultStatusTypeOtherDescription: MISMOString;
    loanAcquisitionActualUPBAmount: MISMOAmount;
    loanActivityReportingTransactionIdentifier: MISMOIdentifier;
    loanImminentDefaultSourceType: LoanImminentDefaultSourceType;
    loanImminentDefaultSourceTypeOtherDescription: MISMOString;
    previouslyReportedInformationRevisionIndicator: MISMOIndicator;
    servicerWelcomeCallPerformedDate: MISMODate;
    SFDMSAutomatedDefaultProcessingIdentifier: MISMOIdentifier;
    titleReportLastReceivedDate: MISMODate;
    // advances: Advance[];
    // advanceSummary: AdvanceSummary;
    // creditBureauReporting: CreditBureauReporting;
    // debtCollections: DebtCollection[];
    // delinquencies: Delinquency[];
    delinquencySummary: DelinquencySummary;
    // disclosureOnServicer: DisclosureOnServicer;
    // monetaryEvents: MonetaryEvent[];
    // monetaryEventSummaries: MonetaryEventSummary[];
    // repurchases: Repurchase[];
    // servicerQualifiedWrittenRequestMailTo: ServicerQualifiedWrittenRequestMailTo;
    // servicingComments: ServicingComment[];
    // servicingREO: ServicingREO;
    // servicingStops: ServicingStop[];
  }>;
