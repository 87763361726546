import React from 'react';
import styled from 'styled-components/macro';

import photo from '../../assets/photos/photo-1.jpg';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { useLoanDetailsQuery } from '../../hooks/queries';
import {
  formatDate,
  formatDefaultValue,
  formatMonetary,
  formatPercentage,
} from '../../utils/formatUtils';
import { formatForeclosureMethodType } from '../../utils/loanUtils';
import { Description, DescriptionName, DescriptionValue, PropertyPhoto } from './shared/styles';

const Section = styled.section`
  padding: 20px 20px 0;
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.greenMain};
  padding-bottom: 10px;
`;

type LoanSummaryProps = {
  loanNumber: string;
};

export const LoanSummary = ({ loanNumber }: LoanSummaryProps) => {
  const { data: loan, isLoading } = useLoanDetailsQuery(loanNumber);

  if (isLoading) {
    <ActivityIndicator isActive isFullScreen />;
  }

  if (!loan) {
    return null;
  }

  const { financials, loanProperty } = loan;

  return (
    <React.Fragment>
      <PropertyPhoto src={photo} />
      <Section data-testid="LoanSummary_Financials">
        <SectionTitle>Financials</SectionTitle>
        <Description>
          <DescriptionName>Purchase Price</DescriptionName>
          <DescriptionValue>
            {formatMonetary(loanProperty.property?.purchasePrice)}
          </DescriptionValue>

          <DescriptionName>Current Value</DescriptionName>
          <DescriptionValue>{formatMonetary(loanProperty.property?.currentBpo)}</DescriptionValue>

          <DescriptionName>Current Value Date</DescriptionName>
          <DescriptionValue>
            {formatDate(
              loanProperty.property?.currentBpoDate
                ? new Date(loanProperty.property?.currentBpoDate)
                : undefined
            )}
          </DescriptionValue>

          <DescriptionName>Down Payment</DescriptionName>
          <DescriptionValue>
            {formatMonetary(loanProperty.property?.downPaymentAmount)}
          </DescriptionValue>

          <DescriptionName>Original UPB</DescriptionName>
          <DescriptionValue>{formatMonetary(financials.originalUPB)}</DescriptionValue>

          <DescriptionName>Current UPB</DescriptionName>
          <DescriptionValue>{formatMonetary(financials.currentUPB)}</DescriptionValue>

          <DescriptionName>Current LTV</DescriptionName>
          <DescriptionValue>{formatPercentage(financials.currentLTV)}</DescriptionValue>
        </Description>
      </Section>

      <Section data-testid="LoanSummary_Property">
        <SectionTitle>Property</SectionTitle>
        <Description>
          <DescriptionName>Foreclosure</DescriptionName>
          <DescriptionValue>
            {formatForeclosureMethodType(loanProperty.property?.foreclosureMethodType)}
          </DescriptionValue>

          <DescriptionName>Property Type</DescriptionName>
          <DescriptionValue>
            {formatDefaultValue(loanProperty.property?.propertyType)}
          </DescriptionValue>
        </Description>
      </Section>
    </React.Fragment>
  );
};
