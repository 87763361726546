import styled from 'styled-components/macro';

const TICKET_DRAWER_WIDTH = 346;

export const Layout = styled.div`
  display: grid;
  grid-template-columns: auto ${TICKET_DRAWER_WIDTH}px;
  max-width: 100%;
  height: 100%;
  column-gap: 8px;
`;

export const Content = styled.div<{ $isTicketDrawerOpen: boolean }>`
  grid-column: ${({ $isTicketDrawerOpen }) => ($isTicketDrawerOpen ? '1 / span 1' : '1 / -1')};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  row-gap: 8px;
  width: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
`;

export const Section = styled.section<{ $isTicketDrawerOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: ${({ $isTicketDrawerOpen }) => ($isTicketDrawerOpen ? '1 / span 1' : '1 / span 2')};
  margin-bottom: 8px;
`;

export const SidePanel = styled.section`
  grid-column: 2 / span 1;
  grid-row: 1 / end;
  display: flex;
  margin-bottom: 8px;
`;

export const SectionContent = styled.div`
  width: 100%;
`;

export const SectionFooter = styled.footer`
  padding-top: 8px;
`;

export const PoolList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Analytics = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  > * {
    width: auto;
  }
`;
