import { useMutation, useQueryClient } from 'react-query';

import { updateAnalyticsSettings } from '../../admin/utils';
import { QueryKeys } from './types';

export const useUpdateAnalyticsSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateAnalyticsSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ANALYTICS_SETTINGS]);
    },
  });
};
