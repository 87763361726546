import React from 'react';

export const CloseLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.07692 19L5 17.9231L10.9231 12L5 6.07692L6.07692 5L12 10.9231L17.9231 5L19 6.07692L13.0769 12L19 17.9231L17.9231 19L12 13.0769L6.07692 19Z"
        fill="currentColor"
      />
    </svg>
  );
};
