import { useMutation } from 'react-query';

import { login } from '../../utils/authUtils';
import { LoginMutation } from '.';
import { MutationKeys } from './types';

/**
 * Mutation hook to login using email and password
 */
export const useLoginMutation = () =>
  useMutation(MutationKeys.LOGIN, ({ email, password }: LoginMutation) => login(email, password));
