import { Address } from './address';
import { Contact } from './contact';
import { CreditComment } from './creditComment';
import { Name } from './name';
import { MISMOAmount, MISMOEntity } from './types';
import { Verification } from './verification';

export enum MonthlyRentCurrentRatingType {
  AsAgreed = 'AsAgreed',
  ChargeOff = 'ChargeOff',
  Collection = 'Collection',
  Late30Days = 'Late30Days',
  Late60Days = 'Late60Days',
  Late90Days = 'Late90Days',
  LateOver120Days = 'LateOver120Days',
  NoDataAvailable = 'NoDataAvailable',
  TooNew = 'TooNew',
}
export type Landlord = MISMOEntity &
  Partial<{
    monthlyRentAmount: MISMOAmount;
    monthlyRentCurrentRatingType: MonthlyRentCurrentRatingType;
    address: Address;
    contacts: Contact[];
    creditComments: CreditComment[];
    name: Name;
    verification: Verification;
  }>;
