import styled, { css } from 'styled-components/macro';

export const PageContentWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const Sidebar = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 140px;
    padding-right: 8px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: ${theme.colors.primary02};
  `}
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 148px);
`;

export const SectionWrapper = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Widget = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
`;
