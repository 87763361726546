export const TrafficIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.1145 4.96135C17.2683 4.88443 17.3453 4.76904 17.3453 4.6152V3.07674C17.3453 2.84597 17.1529 2.69212 16.9606 2.69212H13.8837V1.92289C13.8837 1.26904 13.3837 0.769043 12.7299 0.769043H7.30679C6.65294 0.769043 6.15294 1.26904 6.15294 1.92289V2.69212H3.07602C2.84525 2.69212 2.69141 2.84597 2.69141 3.07674V4.6152C2.69141 4.76904 2.76833 4.92289 2.92218 4.96135L6.19141 6.42289V8.07674H3.07602C2.88371 8.07674 2.69141 8.23058 2.69141 8.46135V9.99981C2.69141 10.1537 2.76833 10.3075 2.92218 10.346L6.19141 11.8075V13.4614H3.07602C2.88371 13.4614 2.69141 13.6152 2.69141 13.846V15.3844C2.69141 15.5383 2.76833 15.6921 2.92218 15.7306L6.19141 17.1921V18.0767C6.19141 18.7306 6.69141 19.2306 7.34525 19.2306H12.7299C13.3837 19.2306 13.8837 18.7306 13.8837 18.0767V17.1537L17.1145 15.7306C17.2683 15.6537 17.3453 15.5383 17.3453 15.3844V13.846C17.3453 13.6152 17.1529 13.4614 16.9606 13.4614H13.8837V11.769L17.1145 10.346C17.2683 10.269 17.3453 10.1537 17.3453 9.99981V8.46135C17.3453 8.23058 17.1529 8.07674 16.9606 8.07674H13.8837V6.38443L17.1145 4.96135ZM9.9991 17.6921C8.72987 17.6921 7.69141 16.6537 7.69141 15.3844C7.69141 14.1152 8.72987 13.0767 9.9991 13.0767C11.2683 13.0767 12.3068 14.1152 12.3068 15.3844C12.3068 16.6537 11.3068 17.6921 9.9991 17.6921ZM9.9991 12.3075C8.72987 12.3075 7.69141 11.269 7.69141 9.99981C7.69141 8.73058 8.72987 7.69212 9.9991 7.69212C11.2683 7.69212 12.3068 8.73058 12.3068 9.99981C12.3068 11.269 11.3068 12.3075 9.9991 12.3075ZM9.9991 6.92289C8.72987 6.92289 7.69141 5.88443 7.69141 4.6152C7.69141 3.34597 8.72987 2.3075 9.9991 2.3075C11.2683 2.3075 12.3068 3.34597 12.3068 4.6152C12.3068 5.88443 11.3068 6.92289 9.9991 6.92289Z"
        fill="currentColor"
      />
    </svg>
  );
};
