import { Filter } from '@plus-platform/shared';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../global/PrivateRoute';
import { FilterPopup } from './FilterPopup';

type TableFiltersChildrenProps = {
  filterPopupPath: string;
  isFiltersActive: boolean;
};

type TableFiltersProps = {
  children: (props: TableFiltersChildrenProps) => React.ReactNode;
  path: string;
  filters: Filter[];
  isLoading?: boolean;
  onFiltersUpdate: (filters: Filter[]) => unknown | Promise<unknown>;
};

export const TableFilters = ({
  children,
  filters,
  isLoading,
  onFiltersUpdate,
  path,
}: TableFiltersProps) => {
  const history = useHistory();
  const location = useLocation<{ isPanelOpen?: boolean }>();

  const FILTER_PATH = `${path}/filters`;
  const isPopupActive = Boolean(useRouteMatch(FILTER_PATH));
  const isPanelOpen = isPopupActive || Boolean(location?.state?.isPanelOpen);

  const closePopup = () => {
    history.push({ pathname: path, state: { isPanelOpen } });
  };

  return (
    <>
      <PrivateRoute
        exact
        path={FILTER_PATH}
        render={() => (
          <FilterPopup
            filters={filters}
            isLoading={isLoading}
            onCancel={() => closePopup()}
            onSave={async (filters: Filter[]) => {
              await onFiltersUpdate(filters);
              closePopup();
            }}
          />
        )}
      />
      {children({
        filterPopupPath: FILTER_PATH,
        isFiltersActive: isPanelOpen,
      })}
    </>
  );
};
