import { HeaderCell, HeaderRow } from '../../components/Table';
import { ORGANIZATION_MEMBERS_TABLE_COLUMN_COUNT } from './OrganizationMembersTable.styles';

export const OrganizationMembersTableHeaderRow = () => {
  return (
    <HeaderRow $columnCount={ORGANIZATION_MEMBERS_TABLE_COLUMN_COUNT}>
      <HeaderCell>Full name</HeaderCell>
      <HeaderCell>Email</HeaderCell>
      <HeaderCell>Status</HeaderCell>
      <HeaderCell>Role</HeaderCell>
      <HeaderCell>Date joined</HeaderCell>
      <HeaderCell>Phone number</HeaderCell>
    </HeaderRow>
  );
};
