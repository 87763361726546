export const EarthquakeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.06166 16.7114H0.135742V17.3742H6.06166V16.7114Z" fill="currentColor" />
      <path
        d="M3.75684 5.44403H5.73214V2.46143H3.75684V5.44403ZM4.41527 3.12423H5.07371V4.78123H4.41527V3.12423Z"
        fill="currentColor"
      />
      <path
        d="M7.0498 2.46143V5.44403H9.02511V2.46143H7.0498ZM8.36668 4.78123H7.70824V3.12423H8.36668V4.78123Z"
        fill="currentColor"
      />
      <path
        d="M3.75684 9.42059H5.73214V6.43799H3.75684V9.42059ZM4.41527 7.10079H5.07371V8.75779H4.41527V7.10079Z"
        fill="currentColor"
      />
      <path
        d="M7.0498 9.42059H9.02511V6.43799H7.0498V9.42059ZM7.70824 7.10079H8.36668V8.75779H7.70824V7.10079Z"
        fill="currentColor"
      />
      <path
        d="M17.502 8.96505L15.3867 9.39173L14.7571 8.75793H15.3785L16.3291 8.11998L17.7612 8.48038L19.9999 3.9692L12.8189 0.356934L10.6707 4.95511V1.13572H2.11105V12.0719H3.89294L4.74479 11.2144L5.32915 11.8027L7.37853 10.9162V10.941L5.92586 12.4033H4.9382L4.27977 13.0661H2.11105V15.3859H0.135742V16.0487H6.58429L7.24273 16.7115L6.25507 17.7057L7.24273 18.6999L6.48964 19.458L6.95466 19.9261L9.49375 17.3702H13.9629V16.7074H10.1481L9.48964 16.0446H15.1439L14.4855 16.7074L15.8024 18.033L14.9505 18.8946V20.3569H15.609V19.168L16.7324 18.0371L15.4156 16.7115L16.074 16.0487H19.8888V15.3859H14.4814L17.502 8.96505ZM13.1316 1.25171L19.1193 4.26746L17.4073 7.70988L16.2057 7.40747L15.181 8.09513H13.1686L15.1728 10.1125L16.3497 9.8764L13.7571 15.3859H10.6707V12.4406L10.8189 12.5152L12.1028 9.9344L10.6707 9.21775V8.30225L12.4649 9.20532L13.7489 6.62454L11.8435 5.66348L10.6707 8.01642V6.51269L13.1316 1.25171ZM10.6707 9.95511L11.218 10.2285L10.6707 11.3304V9.95511ZM11.4403 7.946L12.1316 6.54997L12.86 6.91452L12.1686 8.31054L11.4403 7.946ZM8.03696 11.2144V11.0777H8.36618V12.7347H7.37853V11.8772L8.03696 11.2144ZM7.90116 15.3859L9.21803 16.7115L7.70775 18.2318L7.18511 17.7057L8.17277 16.7115L6.8559 15.3859H2.76948V13.7289H4.55137L5.20981 13.0661H6.19746L6.72009 12.54V13.3975H9.02462V10.4149H8.03696V9.91369L5.4773 11.0156L4.74479 10.2782L3.62133 11.4091H2.76948V1.79852H10.0123V15.3859H7.90116Z"
        fill="currentColor"
      />
      <path
        d="M15.3905 3.30628L13.4851 2.34521L12.2012 4.92599L14.1065 5.88705L15.3905 3.30628ZM13.0859 4.63188L13.7773 3.23585L14.5057 3.60039L13.8143 4.99642L13.0859 4.63188Z"
        fill="currentColor"
      />
      <path
        d="M16.7452 7.21274L18.0291 4.63196L16.1238 3.6709L14.8398 6.25168L16.7452 7.21274ZM16.416 4.56154L17.1444 4.92608L16.453 6.3221L15.7246 5.95756L16.416 4.56154Z"
        fill="currentColor"
      />
      <path
        d="M11.877 12.884L13.7823 13.8451L15.0662 11.2643L13.1609 10.3032L11.877 12.884ZM14.1815 11.5584L13.4901 12.9544L12.7617 12.5899L13.4531 11.1939L14.1815 11.5584Z"
        fill="currentColor"
      />
      <path
        d="M17.9465 10.9077L16.9424 11.9185L19.0782 12.457L19.9588 10.6799L18.3909 9.10156L17.9465 10.9077ZM18.749 10.3982L19.1564 10.8083L18.7202 11.6823L18.2222 11.5581L18.535 11.2432L18.749 10.3982Z"
        fill="currentColor"
      />
      <path
        d="M17.2207 13.251L16.6318 13.5474L16.9613 14.2106L17.5502 13.9142L17.2207 13.251Z"
        fill="currentColor"
      />
      <path
        d="M19.1345 13.8263L18.6689 14.2949L19.3266 14.9569L19.7922 14.4882L19.1345 13.8263Z"
        fill="currentColor"
      />
      <path
        d="M19.2306 1.46735H19.889C19.889 0.920535 19.4446 0.473145 18.9014 0.473145V1.13594C19.0824 1.13594 19.2306 1.28508 19.2306 1.46735Z"
        fill="currentColor"
      />
      <path
        d="M0.658435 3.45568L0.230452 3.88236L0.699587 4.35461L1.58847 3.45568L0.93004 2.79288L1.58847 2.13008L0.93004 1.46728L1.35802 1.0406L0.888887 0.568359L0 1.46728L0.658435 2.13008L0 2.79288L0.658435 3.45568Z"
        fill="currentColor"
      />
      <path
        d="M1.21915 7.86096L0.561523 8.52295L1.0271 8.99162L1.68473 8.32962L1.21915 7.86096Z"
        fill="currentColor"
      />
      <path
        d="M0.698001 12.8321L0.232422 13.3008L0.890052 13.9628L1.35563 13.4941L0.698001 12.8321Z"
        fill="currentColor"
      />
      <path
        d="M17.5848 1.79854C18.128 1.79854 18.5724 2.24593 18.5724 2.79274H19.2309C19.2309 1.87725 18.4942 1.13574 17.5848 1.13574C17.2967 1.13574 17.0127 1.21031 16.7617 1.35944L17.0909 1.9311C17.2432 1.84411 17.4119 1.79854 17.5848 1.79854Z"
        fill="currentColor"
      />
      <path d="M19.8889 16.7114H16.5967V17.3742H19.8889V16.7114Z" fill="currentColor" />
    </svg>
  );
};
