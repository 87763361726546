import React from 'react';
import { useParams } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageSection } from '../../components/Page';
import { DocumentViewer } from './DocumentViewer';

type DocumentViewerPageParams = {
  documentId: string;
};

export const DocumentViewerPage = () => {
  const [selectedPageId, setSelectedPageId] = React.useState<number | undefined>(undefined);
  const { documentId } = useParams<DocumentViewerPageParams>();

  return (
    <Page>
      <PageHeader>
        <Heading>Document Detail</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/documents" title="Documents" />
          <BreadcrumbItem title="Document detail" />
        </Breadcrumb>
      </PageHeader>

      <PageContent>
        <PageSection>
          <DocumentViewer
            documentId={documentId}
            onPageClick={(pageId) => setSelectedPageId(pageId)}
            selectedPageId={selectedPageId}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};
