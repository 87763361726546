import React from 'react';

export const InsuranceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2127 19.9987L11.2185 12.9189L12.7794 12.9202L12.7736 20L11.2127 19.9987ZM4 11.9932C4.00091 10.8782 4.21546 9.83779 4.64364 8.87186C5.07182 7.90593 5.64856 7.0609 6.37386 6.33679C7.09916 5.61267 7.94048 5.04195 8.89781 4.62463C9.85514 4.20731 10.8913 3.9991 12.0062 4.00001C13.1211 4.00092 14.1616 4.21082 15.1275 4.62971C16.0935 5.0486 16.9385 5.6207 17.6626 6.346C18.3867 7.0713 18.9574 7.91726 19.3748 8.88388C19.7921 9.8505 20.0003 10.8913 19.9994 12.0062L4 11.9932ZM5.81307 10.4059L7.31824 10.4071C7.36497 10.0633 7.42332 9.72195 7.49328 9.38288C7.56324 9.04381 7.64481 8.71636 7.73798 8.40054C7.83115 8.08471 7.93824 7.78284 8.05926 7.49491C8.18028 7.20698 8.30593 6.933 8.43622 6.67295C7.78548 7.1184 7.22989 7.65915 6.76946 8.29522C6.30903 8.93129 5.99023 9.63483 5.81307 10.4059ZM8.907 10.4363L15.1228 10.4135C15.0305 9.70725 14.8777 9.05674 14.6645 8.46193C14.4513 7.86713 14.2031 7.35823 13.9201 6.93525C13.6371 6.51228 13.3307 6.18219 13.0011 5.945C12.6715 5.7078 12.3487 5.58908 12.0328 5.58882C11.7076 5.59785 11.38 5.72069 11.0499 5.95734C10.7199 6.194 10.4107 6.5259 10.1223 6.95306C9.83396 7.38022 9.58501 7.88871 9.37548 8.47852C9.16595 9.06834 9.00979 9.72092 8.907 10.4363ZM16.6837 10.4147L18.2167 10.4438C18.0223 9.65394 17.6977 8.93826 17.2429 8.2968C16.7882 7.65534 16.2311 7.116 15.5718 6.67877C15.711 6.94833 15.8408 7.22717 15.9614 7.51529C16.0819 7.80342 16.1908 8.10315 16.2882 8.41448C16.3855 8.72581 16.4665 9.04875 16.5313 9.38328C16.596 9.71782 16.6468 10.0616 16.6837 10.4147Z"
        fill="currentColor"
      />
    </svg>
  );
};
