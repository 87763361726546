import styled, { css } from 'styled-components/macro';

import { Bubble, RateTypeLink as RateTypeLinkComponent } from './LoanTypesByDetailPage.styles';

type RateTypeLinkProps = {
  $isBelowCurrentMarketRate: boolean;
};

export const RateTypeLink = styled(RateTypeLinkComponent)<RateTypeLinkProps>`
  ${Bubble} {
    background-color: ${({ theme }) => theme.colors.statusRed};
  }

  ${(props) =>
    props.$isBelowCurrentMarketRate &&
    css`
      ${Bubble} {
        background-color: ${({ theme }) => theme.colors.statusGreen};
      }
    `}
`;
