import {
  DocumentCategoryType,
  GetPortfolioLoansDocumentSummaryItem,
  GetPortfolioLoansDocumentSummaryItemDocument,
} from '@plus-platform/shared';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Popover, PopoverScrollable } from '../components/Popover';
import { TabItem, Tabs, TabsHeader } from '../components/Tabs';
import { LoanDocumentsLists } from '../loans/LoanDocumentsLists';

type DocumentTablePopoverProps = {
  onClose: () => void;
  loanWithDocumentSummary: GetPortfolioLoansDocumentSummaryItem;
};

export const DocumentsTablePopover = (props: DocumentTablePopoverProps) => {
  const {
    loanWithDocumentSummary: { closingDocuments, creditDocuments, disclosureDocuments, loanNumber },
    onClose,
  } = props;

  const [documentCategory, setDocumentCategory] = React.useState<DocumentCategoryType>(
    DocumentCategoryType.ORIGINATION_DOCUMENT
  );
  const history = useHistory();

  const handleDocumentClick = (document: GetPortfolioLoansDocumentSummaryItemDocument) => {
    history.push(`/documents/document/${document.processorId}`);
  };

  return (
    <Popover isInset onClose={onClose} title={loanNumber} width={950}>
      <TabsHeader>
        <Tabs>
          <TabItem
            $isActive={documentCategory === DocumentCategoryType.ORIGINATION_DOCUMENT}
            onClick={() => setDocumentCategory(DocumentCategoryType.ORIGINATION_DOCUMENT)}
          >
            Origination Documents
          </TabItem>
          <TabItem
            $isActive={documentCategory === DocumentCategoryType.SERVICER_DOCUMENT}
            onClick={() => setDocumentCategory(DocumentCategoryType.SERVICER_DOCUMENT)}
          >
            Servicer Documents
          </TabItem>
          <TabItem
            $isActive={documentCategory === DocumentCategoryType.TRANSACTION_DOCUMENT}
            onClick={() => setDocumentCategory(DocumentCategoryType.TRANSACTION_DOCUMENT)}
          >
            Transaction Documents
          </TabItem>
        </Tabs>
      </TabsHeader>
      <PopoverScrollable style={{ marginTop: 16 }} data-testid="LoanDocuments_DocumentsPopover">
        <LoanDocumentsLists
          closingDocuments={closingDocuments}
          creditDocuments={creditDocuments}
          disclosureDocuments={disclosureDocuments}
          documentCategory={documentCategory}
          onDocumentClick={handleDocumentClick}
          variant="popover"
        />
      </PopoverScrollable>
    </Popover>
  );
};
