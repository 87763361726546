import {
  MISMODate,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { PaymentOption } from './paymentOption';

enum PaymentOptionRecastTriggerType {
  Other = 'Other',
  Scheduled = 'Scheduled',
  TooManyMinimumPayments = 'TooManyMinimumPayments',
}

export type PaymentOptionSet = MISMOEntity &
  Partial<{
    paymentOptionPeriodActualEndDate: MISMODate;
    paymentOptionPeriodScheduledEndDate: MISMODate;
    paymentOptionPeriodStartDate: MISMODate;
    paymentOptionRecastTriggerType: PaymentOptionRecastTriggerType;
    paymentOptionRecastTriggerTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    paymentOptions: PaymentOption[];
  }>;
