import {
  Identifier,
  PortfolioHomeAnalytic,
  UpdatePortfolioHomeAnalyticBody,
} from '@plus-platform/shared';

import { makeApiUrl, patchRequestWithAuth } from '../../utils/apiUtils';

export const updatePortfolioHomeAnalytic = (
  id: Identifier,
  data: UpdatePortfolioHomeAnalyticBody
) => {
  const url = makeApiUrl(`portfolio/home/analytics/${id}`);

  return patchRequestWithAuth<PortfolioHomeAnalytic>(url, {
    body: JSON.stringify(data),
  });
};
