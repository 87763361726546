import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum PurchaseCreditSourceType {
  BorrowerPaidOutsideClosing = 'BorrowerPaidOutsideClosing',
  BuilderDeveloper = 'BuilderDeveloper',
  Employer = 'Employer',
  FederalAgency = 'FederalAgency',
  Lender = 'Lender',
  LocalAgency = 'LocalAgency',
  NonParentRelative = 'NonParentRelative',
  Other = 'Other',
  Parent = 'Parent',
  PropertySeller = 'PropertySeller',
  RealEstateAgent = 'RealEstateAgent',
  StateAgency = 'StateAgency',
  UnrelatedFriend = 'UnrelatedFriend',
}

export enum PurchaseCreditType {
  BuydownFund = 'BuydownFund',
  CommitmentOriginationFee = 'CommitmentOriginationFee',
  EarnestMoney = 'EarnestMoney',
  EmployerAssistedHousing = 'EmployerAssistedHousing',
  FederalAgencyFundingFeeRefund = 'FederalAgencyFundingFeeRefund',
  GiftOfEquity = 'GiftOfEquity',
  LeasePurchaseFund = 'LeasePurchaseFund',
  MIPremiumRefund = 'MIPremiumRefund',
  Other = 'Other',
  RelocationFunds = 'RelocationFunds',
  SweatEquity = 'SweatEquity',
  TradeEquity = 'TradeEquity',
  TradeEquityFromPropertySwap = 'TradeEquityFromPropertySwap',
}

export type PurchaseCredit = MISMOEntity &
  Partial<{
    purchaseCreditAmount: MISMOAmount;
    purchaseCreditSourceType: PurchaseCreditSourceType;
    purchaseCreditSourceTypeOtherDescription: MISMOString;
    purchaseCreditType: PurchaseCreditType;
    purchaseCreditTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
