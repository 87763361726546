import { PortfolioBreakdownRateType } from '@plus-platform/shared';
import { useHistory } from 'react-router';
import { VictoryScatter, VictoryThemeDefinition } from 'victory';

import { Breadcrumb, BreadcrumbItem } from '../../../components/Breadcrumb';
import { Heading } from '../../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../../components/Page';
import { Skeleton } from '../../../components/Skeleton';
import { usePortfolioLoanTypesByNotRateQuery } from '../../../hooks/queries';
import { plusChartTheme } from '../../../styles/chart';
import { breakdownRateTypeLabels, orderedBreakdownRateTypes } from '../../../utils/loanUtils';
import {
  ABOVE_MARKET_RATE_CHART_COLOR,
  BELOW_MARKET_RATE_CHART_COLOR,
  mapLoansCountByNoteRateForChart,
} from '../helpers';
import {
  LARGEST_BUBBLE_FONT_SIZE,
  LARGEST_BUBBLE_SIZE,
  SMALLEST_BUBBLE_FONT_SIZE,
  SMALLEST_BUBBLE_SIZE,
} from './helpers';
import { MainContent } from './LoanTypesByNoteRatePage.styles';
import { ContentWrapper } from './LoanTypesByPage.styles';
import { LoanTypesByPageChart, ScatterChartLabel, ScatterChartPoint } from './LoanTypesByPageChart';
import { LoanTypesChartHeader } from './LoanTypesChartHeader';
import { Summary } from './Summary';

const ALL_LABEL_AXIS_OFFSET = 2;

const theme: VictoryThemeDefinition = {
  dependentAxis: {
    style: {
      grid: {
        strokeOpacity: ({ index }) =>
          Number(index) % 2 || Number(index) <= ALL_LABEL_AXIS_OFFSET
            ? 0
            : plusChartTheme.dependentAxis.style.grid.strokeOpacity,
      },
    },
  },
};

export const LoanTypesByNoteRatePage = () => {
  const { data, isLoading } = usePortfolioLoanTypesByNotRateQuery();
  const history = useHistory();

  const flatDataForChart = mapLoansCountByNoteRateForChart(data?.countsByRateType);
  const largestLoanCount = Math.max(...flatDataForChart.map((dataItem) => dataItem.loansCount));

  const labels = Object.entries(breakdownRateTypeLabels).map(([rateType, label]) => {
    const loansCount =
      data?.countsByRateType[rateType as PortfolioBreakdownRateType]?.loansCount ?? 0;

    return `${label} (${loansCount})`;
  });

  const tickFormat = labels.flatMap((label) => {
    if (label.includes('All')) {
      return ['', '', label, ''];
    }

    return ['', label];
  });

  const chartData = flatDataForChart.map((dataItem) => {
    const { currentMarketRate, loansCount, noteRate, rateType } = dataItem;
    const loanCountRatio = largestLoanCount ? loansCount / largestLoanCount : 0;
    const yOffset =
      rateType === PortfolioBreakdownRateType.ALL
        ? ALL_LABEL_AXIS_OFFSET
        : ALL_LABEL_AXIS_OFFSET + 1;

    return {
      x: noteRate * 100,
      y: orderedBreakdownRateTypes.indexOf(rateType) * 2 + yOffset,
      size: Math.max(LARGEST_BUBBLE_SIZE * loanCountRatio, SMALLEST_BUBBLE_SIZE),
      loansCount,
      labelFontSize: Math.max(LARGEST_BUBBLE_FONT_SIZE * loanCountRatio, SMALLEST_BUBBLE_FONT_SIZE),
      rateType,
      noteRate,
      fill:
        !currentMarketRate || noteRate <= currentMarketRate
          ? BELOW_MARKET_RATE_CHART_COLOR
          : ABOVE_MARKET_RATE_CHART_COLOR,
    };
  });

  const goToDetails = (rateType: PortfolioBreakdownRateType, noteRate: number) => {
    history.push(`/home/breakdown/types/${rateType}/rates/${noteRate}`);
  };

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>Loans by type by note rate</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem title="Loans by type by note rate" />
            </Breadcrumb>
          </div>

          {data && (
            <Summary
              flagsCount={data.flagsCount}
              alertsCount={data.alertsCount}
              warningsCount={data.warningsCount}
              totalLoansCount={data.totalLoansCount}
            />
          )}
        </PageHeaderContent>
      </PageHeader>
      <PageContent>
        {isLoading && <Skeleton height={307} />}

        {!isLoading && (
          <ContentWrapper>
            <LoanTypesChartHeader />
            <MainContent>
              <LoanTypesByPageChart
                tickFormat={tickFormat}
                theme={theme}
                width={1200}
                padding={{
                  top: -1,
                  left: 140,
                  right: 0,
                  bottom: 32,
                }}
              >
                <VictoryScatter
                  name="scatter"
                  data={chartData}
                  labels={({ datum }) => datum.loansCount}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.fill,
                    },
                    labels: {
                      fontSize: ({ datum }) => datum.labelFontSize,
                    },
                  }}
                  labelComponent={<ScatterChartLabel />}
                  dataComponent={<ScatterChartPoint />}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onClick: (_, { datum }) => goToDetails(datum.rateType, datum.noteRate),
                      },
                    },
                    {
                      target: 'labels',
                      eventHandlers: {
                        onClick: (_, { datum }) => goToDetails(datum.rateType, datum.noteRate),
                      },
                    },
                  ]}
                />
              </LoanTypesByPageChart>
            </MainContent>
          </ContentWrapper>
        )}
      </PageContent>
    </Page>
  );
};
