import { ADMIN_GENERAL_DEFAULT_SETTINGS, GeneralBehaviorSettings } from '@plus-platform/shared';
import { Switch, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  BubbleChartIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  HelpIcon,
  PortfolioLineIcon,
} from '../components/icons';
import { FullWidthLoaderWrapper, Loader } from '../components/Loader';
import { PageSection } from '../components/Page';
import { PrivateRoute } from '../global/PrivateRoute';
import { useGetAnalyticsSettings, useUpdateAnalyticsSettingsMutation } from '../hooks/queries';
import { SpecificMenuItemButton } from './AdminMenuItemButton';
import { Header, SpecificMenu, SpecificMenuItem, Wrapper } from './FlagsAlertsWarningsPage.styles';
import { GeneralSettings } from './GeneralSettings';
import { QATilesPage } from './QATilesPage';
import {
  AnalyticsHeader,
  AnalyticsSubtitle,
  AnalyticsTitle,
  Description,
  HeaderLink,
  LoaderOpacityMask,
  Subsection,
} from './SettingsAnalyticsPage.styles';
import { AdminRouteType } from './types';

const SettingsAnalyticsHomePage = () => {
  const theme = useTheme();
  const history = useHistory();

  const { data: settingsData } = useGetAnalyticsSettings();

  const { isLoading: areSettingsUpdating, mutateAsync: updateSettings } =
    useUpdateAnalyticsSettingsMutation();

  const updateAnalyticsSettings = (newSettings: GeneralBehaviorSettings) => {
    updateSettings({
      generalBehaviorUpdateFrequency: newSettings,
    });
  };

  const generalSettings = settingsData?.generalBehaviorUpdateFrequency;

  return (
    <Wrapper>
      <AnalyticsHeader>
        <HeaderLink to={AdminRouteType.FlagsAlertsWarning}>
          <ChevronLeftIcon color={theme.colors.fillAdminBackLinkIcon} />
        </HeaderLink>
        <AnalyticsTitle>Analytics</AnalyticsTitle>
      </AnalyticsHeader>

      <PageSection>
        <Header>
          <AnalyticsSubtitle>General behaviour</AnalyticsSubtitle>
          <HelpIcon color={theme.colors.statusGreen} />
        </Header>
        <Description>
          Changing Frequency, Vigilance or Significance here will effect all Flags, Alerts and
          Warnings.{' '}
        </Description>

        <Subsection style={{ minHeight: '275px' }}>
          {areSettingsUpdating && (
            <FullWidthLoaderWrapper>
              <LoaderOpacityMask>
                <Loader color={theme.colors.fillSettingsSpinnerIcon} />
              </LoaderOpacityMask>
            </FullWidthLoaderWrapper>
          )}

          <GeneralSettings
            defaultSettings={ADMIN_GENERAL_DEFAULT_SETTINGS}
            settings={generalSettings}
            onSettingsChange={updateAnalyticsSettings}
          />
        </Subsection>
      </PageSection>

      <PageSection>
        <Header>
          <AnalyticsSubtitle>Specific</AnalyticsSubtitle>
        </Header>

        <SpecificMenu>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Q&A Tiles"
              actionComponent={() => <ChevronRightIcon />}
              icon={<PortfolioLineIcon />}
              style={{
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
              onClick={() => history.push(AdminRouteType.AnalyticsQATiles)}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Visualisations"
              actionComponent={() => <ChevronRightIcon />}
              icon={<BubbleChartIcon />}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Portfolio Tiles"
              actionComponent={() => <ChevronRightIcon />}
              icon={<PortfolioLineIcon />}
            />
          </SpecificMenuItem>
        </SpecificMenu>
      </PageSection>
    </Wrapper>
  );
};

export const SettingsAnalyticsPage = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        component={() => <SettingsAnalyticsHomePage />}
        path={AdminRouteType.FlagsAlertsWarningAnalytics}
      />
      <PrivateRoute component={() => <QATilesPage />} path={AdminRouteType.AnalyticsQATiles} />
    </Switch>
  );
};
