import { LoanDelinquencyStatus } from '@plus-platform/shared';
import { useTheme } from 'styled-components/macro';

import { Badge, BadgeWrapper } from './Badge';

export const DelinquencyBadge = (
  props: Pick<LoanDelinquencyStatus, 'delinquencyDays' | 'isDelinquent'>
) => {
  const theme = useTheme();
  const { delinquencyDays, isDelinquent } = props;

  if (!isDelinquent) {
    return (
      <BadgeWrapper>
        <Badge $color={theme.colors.accent01} $size={8} /> Current
      </BadgeWrapper>
    );
  }

  if (delinquencyDays <= 30) {
    return (
      <BadgeWrapper>
        <Badge $color={theme.colors.delinquency30Days} $size={8} />
        30d DQ
      </BadgeWrapper>
    );
  }

  if (delinquencyDays <= 60) {
    return (
      <BadgeWrapper>
        <Badge $color={theme.colors.delinquency60Days} $size={8} />
        60d DQ
      </BadgeWrapper>
    );
  }

  if (delinquencyDays > 60) {
    return (
      <BadgeWrapper>
        <Badge $color={theme.colors.delinquency90Days} $size={8} />
        90{delinquencyDays > 90 ? '+' : ''}d DQ
      </BadgeWrapper>
    );
  }

  return null;
};
