import { Address } from './address';
import { Landlord } from './landlord';
import {
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOSequenceNumber,
} from './types';

export enum BorrowerResidencyBasisType {
  LivingRentFree = 'LivingRentFree',
  Own = 'Own',
  Rent = 'Rent',
  Unknown = 'Unknown',
}

export enum BorrowerResidencyType {
  Current = 'Current',
  Prior = 'Prior',
}

export type Residence = MISMOEntity &
  Partial<{
    borrowerResidencyBasisType: BorrowerResidencyBasisType;
    borrowerResidencyDurationMonthsCount: MISMOCount;
    borrowerResidencyDurationYearsCount: MISMOCount;
    borrowerResidencyEndDate: MISMODate;
    borrowerResidencyReportedDate: MISMODate;
    borrowerResidencyStartDate: MISMODate;
    borrowerResidencyType: BorrowerResidencyType;
    sequenceNumber: MISMOSequenceNumber;
    address: Address;
    landlord: Landlord;
  }>;
