import { MISMOEntity, MISMOSequenceNumber, MISMOString } from '../types';

enum SecuritySystemType {
  InteractiveAlarmSystem = 'InteractiveAlarmSystem',
  LocalAlarmSystem = 'LocalAlarmSystem',
  MonitoredAlarmSystem = 'MonitoredAlarmSystem',
  Other = 'Other',
}

export type SecuritySystem = MISMOEntity &
  Partial<{
    securitySystemType: SecuritySystemType;
    securitySystemTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
