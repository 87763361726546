import { MISMO } from '.';

export interface EncompassAuthResponseType {
  access_token?: string;
  token_type?: string;
  expires_in?: number;
  error?: string;
  error_description?: string;
}

export enum EncompassConditionDocumentType {
  Purchase = 'Purchase Advice',
  Eligibility = 'Eligibility Letter',
  Lock = 'Lock Confirmation',
  Misc = 'Misc',
}

export enum EncompassOrderStatusType {
  Delivered = 'Delivered',
  Processed = 'Processed',
  Error = 'Error',
  Denied = 'Denied',
}

export interface EncompassUpdateTransactionRequestType {
  orders: {
    id?: string;
    orderDateTime?: string;
    orderStatus: EncompassOrderStatusType;
    message: string;
  }[];
  delivery?: {
    files: {
      id: string;
      name: string;
      mimeType: 'application/pdf';
      documentType: EncompassConditionDocumentType;
    }[];
  };
}

export const MESSAGE_FILE_NAME = 'parsedData.json';

export type EncompassMessage = Partial<{
  data: MISMO.Message;
  meta: {
    userEmail: string;
  };
}>;
