import {
  CalculationType,
  PortfolioAnalyticItemData,
  PortfolioAnalyticMetric,
  VisualisationType,
} from '@plus-platform/shared';
import React from 'react';
import { useTheme } from 'styled-components';

import {
  FlagsAlertsWarnings,
  FlagsAlertsWarningsProps,
} from '../../components/FlagsAlertsWarnings';
import {
  AddToPoolIcon,
  AnalyticsLineIcon,
  BarChartIcon,
  BubbleChartLineIcon,
  ChevronRightIcon,
  DeleteIcon,
  DuplicateIcon,
  HashtagLineIcon,
  HistoryIcon,
  MapLineIcon,
  PieChartFillIcon,
  PortfolioLineIcon,
  ShareIcon,
} from '../../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection, SubMenu } from '../../components/Menu';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { Title, Wrapper } from './Analytics.styles';
import { AnalyticsChart } from './AnalyticsCharts';
import { AnalyticsTable } from './AnalyticsTable';
import {
  AnalyticWidgetLink,
  Emphasis,
  Header,
  Menus,
  ToggleLeftIcon,
  ToggleRightIcon,
  ToggleWrapper,
} from './AnalyticWidget.styles';
import {
  formatDataForAnalyticWidget,
  getAllowedVisualizationTypes,
  getCalculationTypeLabel,
  portfolioAnalyticMetricLabels,
} from './utils';

export type AnalyticWidgetProps = FlagsAlertsWarningsProps & {
  id: string;
  metric: PortfolioAnalyticMetric;
  metricBy: PortfolioAnalyticMetric;
  metricCalculationType?: CalculationType;
  visualisationType: VisualisationType;
  data: PortfolioAnalyticItemData[];
  linkUrl?: string;
  onDelete?: () => void;
  onUpdate?: (visualisationType: VisualisationType) => void;
};

export const AnalyticWidget = ({
  alertsCount,
  data,
  flagsCount,
  id,
  linkUrl,
  metric,
  metricBy,
  metricCalculationType,
  onDelete,
  onUpdate,
  visualisationType,
  warningsCount,
}: AnalyticWidgetProps) => {
  const [isViewVisualisation, setIsViewVisualisation] = React.useState(true);

  const theme = useTheme();

  const metricLabel = portfolioAnalyticMetricLabels[metric];
  const metricByLabel = portfolioAnalyticMetricLabels[metricBy];

  const formattedData = formatDataForAnalyticWidget(metric, data);

  const isAnalyticGeographic = metricBy === PortfolioAnalyticMetric.STATE;
  const allowedVisualisationTypes = getAllowedVisualizationTypes({ metric, metricBy });

  const link = visualisationType === VisualisationType.BUBBLE_CHART && linkUrl ? linkUrl : '#';

  return (
    <Wrapper>
      <Header>
        <AnalyticWidgetLink $size="medium" to={link}>
          <Title>
            {metricCalculationType && (
              <React.Fragment>
                <Emphasis>{getCalculationTypeLabel(metricCalculationType)}</Emphasis>{' '}
              </React.Fragment>
            )}
            <Emphasis>{metricLabel}</Emphasis> <span>by</span> <Emphasis>{metricByLabel}</Emphasis>
          </Title>
        </AnalyticWidgetLink>

        <Menus>
          <ToggleWrapper>
            <ToggleSwitch
              style={{
                borderRadius: '4px',
                width: '44px',
                height: '24px',
                backgroundColor: theme.colors.white05,
              }}
              disabled={!isAnalyticGeographic}
              onChange={() => setIsViewVisualisation(!isViewVisualisation)}
            >
              <ToggleLeftIcon>
                <PieChartFillIcon width="16px" />
              </ToggleLeftIcon>
              <ToggleRightIcon>
                <HashtagLineIcon width="16px" />
              </ToggleRightIcon>
            </ToggleSwitch>
          </ToggleWrapper>

          <Menu title="Q&A tools">
            <MenuSection>
              <MenuItem disabled>
                <ShareIcon />
                <MenuItemText>Share</MenuItemText>
              </MenuItem>
            </MenuSection>
            <MenuSection>
              <MenuItem disabled>
                <PortfolioLineIcon />
                <MenuItemText>View in portfolio</MenuItemText>
              </MenuItem>
              <MenuItem disabled>
                <HistoryIcon />
                <MenuItemText>History</MenuItemText>
              </MenuItem>

              <MenuItem isSubmenu>
                <AnalyticsLineIcon />
                <MenuItemText>Visualisations</MenuItemText>
                <ChevronRightIcon />
                <SubMenu $direction="left">
                  <MenuSection>
                    <MenuItem
                      disabled={
                        visualisationType === VisualisationType.BUBBLE_CHART ||
                        !allowedVisualisationTypes.includes(VisualisationType.BUBBLE_CHART)
                      }
                      onClick={() => onUpdate?.(VisualisationType.BUBBLE_CHART)}
                    >
                      <BubbleChartLineIcon />
                      <MenuItemText>Bubble chart</MenuItemText>
                    </MenuItem>
                    <MenuItem
                      disabled={
                        visualisationType === VisualisationType.BAR_CHART ||
                        !allowedVisualisationTypes.includes(VisualisationType.BAR_CHART)
                      }
                      onClick={() => onUpdate?.(VisualisationType.BAR_CHART)}
                    >
                      <BarChartIcon />
                      <MenuItemText>Bar chart</MenuItemText>
                    </MenuItem>
                    <MenuItem
                      disabled={
                        visualisationType === VisualisationType.CHLOROPLETH ||
                        !allowedVisualisationTypes.includes(VisualisationType.CHLOROPLETH)
                      }
                      onClick={() => onUpdate?.(VisualisationType.CHLOROPLETH)}
                    >
                      <MapLineIcon />
                      <MenuItemText>Chloropleth</MenuItemText>
                    </MenuItem>
                  </MenuSection>
                </SubMenu>
              </MenuItem>
            </MenuSection>
            <MenuSection>
              <MenuItem disabled>
                <AddToPoolIcon />
                <MenuItemText>Flags, alerts & warnings</MenuItemText>
              </MenuItem>
            </MenuSection>
            <MenuSection>
              <MenuItem disabled>
                <DuplicateIcon />
                <MenuItemText>Duplicate</MenuItemText>
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <DeleteIcon />
                <MenuItemText>Delete</MenuItemText>
              </MenuItem>
            </MenuSection>
          </Menu>
        </Menus>
      </Header>

      {isViewVisualisation ? (
        <AnalyticsChart
          id={id}
          data={formattedData}
          visualisationType={visualisationType}
          metric={metric}
          metricBy={metricBy}
        />
      ) : (
        <AnalyticsTable id={id} data={formattedData} metric={metric} metricBy={metricBy} />
      )}

      <FlagsAlertsWarnings
        flagsCount={flagsCount}
        warningsCount={warningsCount}
        alertsCount={alertsCount}
      />
    </Wrapper>
  );
};
