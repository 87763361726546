import styled, { css, CSSProperties, DefaultTheme, ThemeProps } from 'styled-components/macro';

type ModalProps = {
  $right?: number;
  $left?: number;
  $top?: number;
  $bottom?: number;
  $width?: CSSProperties['width'];
  $height?: CSSProperties['height'];
  $maxWidth?: CSSProperties['maxWidth'];
  $minHeight?: CSSProperties['minHeight'];
  $minWidth?: CSSProperties['minWidth'];
  $variant?: 'light' | 'dark';
};

type baseStyles = ThemeProps<DefaultTheme>;

const modalDarkVariantStyles = ({ theme }: baseStyles) => css`
  background-color: ${theme.colors.primary02};
  border: 1px solid ${theme.colors.primary03};
  color: ${theme.colors.textPrimary01};
`;

const modalLightVariantStyles = ({ theme }: baseStyles) => css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.accent01};
  color: ${theme.colors.blue};
`;

export const Modal = styled.div<ModalProps>(
  ({
    $bottom,
    $height,
    $left,
    $maxWidth,
    $minHeight,
    $minWidth,
    $right,
    $top,
    $variant,
    $width,
    theme,
  }) => css`
    position: absolute;
    top: ${$top !== undefined ? `${$top}px` : 'auto'};
    bottom: ${$bottom !== undefined ? `${$bottom}px` : 'auto'};
    right: ${$right !== undefined ? `${$right}px` : 'auto'};
    left: ${$left !== undefined ? `${$left}px` : 'auto'};
    width: ${$width ?? 'auto'};
    height: ${$height ?? 'auto'};
    min-height: ${$minHeight ?? 'auto'};
    max-width: ${$maxWidth ?? 'none'};
    min-width: ${$minWidth ?? `230px`};
    margin: auto;
    z-index: 6;
    border-radius: 4px;
    box-shadow: ${theme.shadows.modal};

    ${$variant === 'dark' && modalDarkVariantStyles}
    ${$variant === 'light' && modalLightVariantStyles}
  `
);

export const Overlay = styled.div(
  ({ theme }) =>
    css`
      position: fixed;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${theme.colors.blue90};
      z-index: 5;
    `
);
