import React from 'react';

export const PoolLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0503 16.0851C14.2774 16.0851 16.1257 14.236 16.1257 12.0155C16.1257 9.79497 14.2697 7.9381 12.0503 7.9381C9.83084 7.9381 7.9826 9.78723 7.9826 12.0155C7.9826 14.2282 9.83857 16.0851 12.0503 16.0851ZM12.0503 14.7157C10.5887 14.7157 9.35911 13.4778 9.35911 12.0155C9.35911 10.5377 10.581 9.30754 12.0503 9.30754C13.5196 9.30754 14.7492 10.5455 14.7492 12.0155C14.7492 13.4855 13.5273 14.7157 12.0503 14.7157Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9961 20C16.3809 20 20 16.3791 20 12C20 7.62089 16.3731 4 11.9884 4C7.61141 4 4 7.62089 4 12C4 16.3791 7.61914 20 11.9961 20ZM11.9961 18.4062C8.44659 18.4062 5.60851 15.5513 5.60851 12C5.60851 8.44874 8.44659 5.60155 11.9884 5.60155C15.5379 5.60155 18.3915 8.44874 18.3992 12C18.407 15.5513 15.5457 18.4062 11.9961 18.4062Z"
        fill="currentColor"
      />
    </svg>
  );
};
