import {
  ActionStipulation,
  CounterpartyType,
  CounterpartyWithSummary,
  getLatestCounterpartyStatusFromActions,
  OutboundTradeRequestWithSummary,
  TradeCounterpartyStatus,
  TradeQuoteWithSummary,
  TradeRequestMessage,
} from '@plus-platform/shared';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { BodyTextLink } from '../../../components/BodyTextLink';
import { Card } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import { HeadingLink } from '../../../components/HeadingLink';
import {
  BidLineIcon,
  ChatLineIcon,
  DataRequestLineIcon,
  StipulationsLineIcon,
  ThumbsUpLineIcon,
} from '../../../components/icons';
import { PersonCropCircleBadgeXMarkIcon } from '../../../components/icons/PersonCropCircleBadgeXMark';
import { StarIcon } from '../../../components/icons/StarIcon';
import { RecipientList } from '../../../components/RecipientList';
import { TradeStatus } from '../../../components/TradeStatus';
import {
  Content,
  Layout,
  Sidebar,
  SidebarContent,
  SidebarHeading,
  SidebarItem,
} from '../../TradeRequestPageLayout';
import { formatNameWithInitial, isTradeStepComplete } from '../../tradeRequestsUtils';
import { OutboundTradeRequestSidebar, SummaryActivityList } from '../../TradeRequestTickets';
import { TradeRequestStep } from '../../types';
import { Stack } from '../Stack';
import * as Styles from './Dashboard.styles';

const STATUS_TO_TAB_MAPPING: Record<TradeCounterpartyStatus, string> = {
  [TradeCounterpartyStatus.PENDING]: 'expressions-of-interest',
  [TradeCounterpartyStatus.EXPRESSED_INTEREST]: 'expressions-of-interest',
  [TradeCounterpartyStatus.EXPRESSED_INTEREST_ACCEPTED]: 'expressions-of-interest',
  [TradeCounterpartyStatus.EXPRESSED_INTEREST_DECLINED]: 'expressions-of-interest',
  [TradeCounterpartyStatus.DATA_REQUESTED]: 'data-requests',
  [TradeCounterpartyStatus.DATA_REQUEST_ACCEPTED]: 'data-requests',
  [TradeCounterpartyStatus.DATA_REQUEST_DECLINED]: 'data-requests',
  [TradeCounterpartyStatus.SOLD]: 'quotes',
  [TradeCounterpartyStatus.SETTLED]: 'quotes',
};

const getTabFromStatus = (status: TradeCounterpartyStatus) => {
  return STATUS_TO_TAB_MAPPING[status];
};

type CounterpartyActionsProps = {
  status: TradeCounterpartyStatus;
  messages: TradeRequestMessage[];
};

const CounterpartyActions = ({ messages, status }: CounterpartyActionsProps) => {
  const theme = useTheme();

  const isExpressionOfInterestActive = isTradeStepComplete(
    status,
    TradeRequestStep.EXPRESSION_OF_INTEREST
  );
  const isDataRequestActive = isTradeStepComplete(status, TradeRequestStep.DATA_REQUEST);

  return (
    <Styles.RecipientSteps>
      <TradeStatus
        color={theme.colors.statusTurquoise}
        icon={<ThumbsUpLineIcon />}
        isComplete={isExpressionOfInterestActive}
        variant="light"
      />
      <TradeStatus
        count={messages.length}
        icon={<ChatLineIcon />}
        label="Messages"
        variant="light"
      />
      <TradeStatus
        color={theme.colors.statusOrange}
        icon={<DataRequestLineIcon />}
        isComplete={isDataRequestActive}
        label="Data request"
        variant="light"
      />
    </Styles.RecipientSteps>
  );
};

type CounterpartyActivityProps = {
  counterparty: CounterpartyWithSummary;
  counterpartyBids: TradeQuoteWithSummary[];
};

const CounterpartyActivity = ({ counterparty, counterpartyBids }: CounterpartyActivityProps) => {
  const isCounterpartyInactive = counterparty.actions.length === 0;
  const counterpartyStipulations = counterparty.actions.flatMap<ActionStipulation>(
    (action) => action?.actionStipulations || []
  );

  return (
    <React.Fragment>
      {isCounterpartyInactive ? (
        <Styles.Inactive>
          <PersonCropCircleBadgeXMarkIcon />
          <p>
            There hasn’t been any activity from
            <br />
            {formatNameWithInitial(counterparty.user.lastName, counterparty.user.firstName)} yet
          </p>
        </Styles.Inactive>
      ) : (
        <Styles.Stacks>
          {counterpartyStipulations.length > 0 ? (
            <Stack<ActionStipulation>
              icon={<StipulationsLineIcon />}
              items={counterpartyStipulations}
              title="Stipulations"
            />
          ) : (
            <Styles.Inactive>
              <BidLineIcon />
              <p>No current stipulations</p>
            </Styles.Inactive>
          )}
          <Styles.Divider />
          {counterpartyBids.length > 0 ? (
            <Stack<TradeQuoteWithSummary>
              icon={<BidLineIcon />}
              items={counterpartyBids}
              title="Bids"
            />
          ) : (
            <Styles.Inactive>
              <BidLineIcon />
              <p>No current bids</p>
            </Styles.Inactive>
          )}
        </Styles.Stacks>
      )}
    </React.Fragment>
  );
};

type CounterpartiesStatusesProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
  counterparties: CounterpartyWithSummary[];
};

const CounterpartiesStatuses = ({ counterparties, tradeRequest }: CounterpartiesStatusesProps) => {
  return (
    <React.Fragment>
      {counterparties.map((counterparty) => {
        const counterpartyMessages = tradeRequest.messages.filter((message) => {
          const { fromCounterpartyId, toCounterpartyId } = message;

          return fromCounterpartyId === counterparty.id || toCounterpartyId === counterparty.id;
        });

        const counterpartyBids = tradeRequest.bids.filter((bid) => {
          const { fromCounterpartyId } = bid;
          return fromCounterpartyId === counterparty.id;
        });

        const latestCounterpartyStatus = getLatestCounterpartyStatusFromActions(
          counterparty.actions
        );

        // TODO: Determine leading bid
        const isCounterpartyLeadingBid = counterparty.id && counterpartyBids.length > 0;
        const isCounterpartyInactive = counterparty.actions.length === 0;

        return (
          <Card key={counterparty.id} style={{ opacity: isCounterpartyInactive ? 0.5 : 1 }}>
            <Styles.GridItem>
              <Styles.RecipientHeader>
                <HeadingLink
                  $size="medium"
                  to={`/trade-requests/outbound/${tradeRequest.id}/${
                    counterparty.id
                  }/${getTabFromStatus(latestCounterpartyStatus)}`}
                >
                  {formatNameWithInitial(counterparty.user.lastName, counterparty.user.firstName)}
                  <Styles.RecipientDivider> • </Styles.RecipientDivider>
                  <Styles.RecipientOrganization>
                    {counterparty.user.organization.tradingName}
                  </Styles.RecipientOrganization>
                </HeadingLink>

                {isCounterpartyLeadingBid && (
                  <Styles.LeadingBid>
                    <StarIcon height={12} width={12} /> Leading Bid
                  </Styles.LeadingBid>
                )}

                <Styles.Steps>
                  <CounterpartyActions
                    status={latestCounterpartyStatus}
                    messages={counterpartyMessages}
                  />
                </Styles.Steps>
              </Styles.RecipientHeader>

              <CounterpartyActivity
                counterparty={counterparty}
                counterpartyBids={counterpartyBids}
              />
            </Styles.GridItem>
          </Card>
        );
      })}
    </React.Fragment>
  );
};

type HeaderProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const Header = ({ tradeRequest }: HeaderProps) => {
  return (
    <Styles.Header>
      <span>Trade in progress on pool</span>
      <HeadingLink $size="small" to={`/pool/${tradeRequest.pool.id}`}>
        {tradeRequest.pool.name}
      </HeadingLink>
    </Styles.Header>
  );
};

type DashboardProps = {
  tradeRequest?: OutboundTradeRequestWithSummary;
  isLoading: boolean;
};

export const Dashboard = ({ isLoading, tradeRequest }: DashboardProps) => {
  const buyerCounterparties = (tradeRequest?.counterparties || []).filter(
    (counterparty) => counterparty.type === CounterpartyType.BUYER
  );

  const history = useHistory();

  return (
    <Layout>
      <Sidebar>
        <SidebarContent>
          {tradeRequest && (
            <>
              <Header tradeRequest={tradeRequest} />
              <SidebarItem>
                <SidebarHeading>Recipients</SidebarHeading>
                <RecipientList counterparties={buyerCounterparties} />
              </SidebarItem>
              <SidebarItem>
                <SidebarHeading>Summary</SidebarHeading>
                <SummaryActivityList tradeRequest={tradeRequest} />
              </SidebarItem>
              <SidebarItem>
                <SidebarHeading>Details</SidebarHeading>
                <OutboundTradeRequestSidebar tradeRequest={tradeRequest} />
              </SidebarItem>
              <SidebarItem>
                <Styles.TransactionLog>
                  <Heading $size="small">Transaction log</Heading>
                  <BodyTextLink $size="small" to={`${history.location.pathname}/transaction-log`}>
                    View
                  </BodyTextLink>
                </Styles.TransactionLog>
              </SidebarItem>
            </>
          )}
        </SidebarContent>
      </Sidebar>
      <Content>
        <ActivityIndicator contain isActive={isLoading}>
          {tradeRequest && (
            <Styles.Grid>
              <CounterpartiesStatuses
                tradeRequest={tradeRequest}
                counterparties={buyerCounterparties}
              />
            </Styles.Grid>
          )}
        </ActivityIndicator>
      </Content>
    </Layout>
  );
};
