import { InboundTradeRequestWithSummary } from '@plus-platform/shared';

import { formatNameWithInitial } from '../../tradeRequestsUtils';
import { TradeRequestDirectMessaging } from './TradeRequestDirectMessaging';

type InboundTradeRequestDirectMessagingProps = {
  tradeRequest: InboundTradeRequestWithSummary;
  tradeCounterpartyId: string;
};

export const InboundTradeRequestDirectMessaging = (
  props: InboundTradeRequestDirectMessagingProps
) => {
  const { tradeCounterpartyId, tradeRequest } = props;

  const seller = {
    id: tradeRequest.user.id,
    userName: formatNameWithInitial(tradeRequest.user.lastName, tradeRequest.user.firstName),
    organizationName: tradeRequest.user.organization.tradingName,
  };

  return (
    <TradeRequestDirectMessaging
      recipient={seller}
      tradeCounterpartyId={tradeCounterpartyId}
      tradeRequest={tradeRequest}
    />
  );
};
