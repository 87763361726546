import styled, { css } from 'styled-components/macro';
import { Swiper } from 'swiper/react';

export const Section = styled.section`
  padding-bottom: 25px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.white05};
    padding-top: 12px;
  }
`;

export const SectionTitle = styled.h2`
  padding: 0 4px;
  ${({ theme }) => theme.typography.headings.headingMedium03}
`;

export const Analytics = styled.ul`
  display: flex;
  list-style-type: none;
  margin-top: 12px;
  gap: 12px;
`;

export const Analytic = styled.li`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.white05};
  border-radius: 4px;
`;

type SelectableAnalyticProps = {
  $isSelected: boolean;
};

export const SelectableAnalytic = styled(Analytic)<SelectableAnalyticProps>`
  cursor: pointer;

  ${(props) =>
    props.$isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.primary02};
    `}
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
`;

export const ToggleLabel = styled.span`
  ${({ theme }) => theme.typography.bodyText.bodyRegular03Tight}
`;

export const Preview = styled.img`
  vertical-align: middle;
  height: 176px;
  max-width: 100%;
  border-radius: 2px;
`;

export const NoPreview = styled.span`
  height: 176px;
  aspect-ratio: 1;
  background-color: ${({ theme }) => theme.colors.white05};
  border-radius: 2px;
`;
