import { Loan, LoanDetails } from '@plus-platform/shared';
import debounce from 'lodash/debounce';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { ButtonIcon } from '../../components/ButtonIcon';
import { DelinquencyStatus } from '../../components/DelinquencyStatus';
import { SearchFillIcon } from '../../components/icons';
import { DeltaIcon } from '../../components/icons/DeltaIcon';
import { Portal } from '../../components/Portal';
import { SearchBar } from '../../components/SearchBar';
import { useLoanSearchQuery } from '../../hooks/queries';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { isDarkTheme } from '../../styles/theme';
import { formatMonetary, formatPercentage } from '../../utils/formatUtils';
import * as Styles from './Search.styles';

export const Search = () => {
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const searchResultsRef = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isTouched, setIsTouched] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  useOnClickOutside(searchBarRef, (event: Event) => {
    if (
      !searchResultsRef.current ||
      searchResultsRef.current?.contains?.(event.target as HTMLElement)
    ) {
      return;
    }

    setIsActive(false);
  });

  useEffect(() => {
    if (Boolean(searchTerm.length) && !isActive) {
      setIsActive(true);
    }

    if (Boolean(searchTerm.length === 0) && isActive) {
      setIsActive(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (isTouched) {
      if (searchBarRef && searchBarRef?.current) {
        searchBarRef.current.focus();
      }
    }
  }, [isTouched]);

  const handleChange = useMemo(
    () => debounce((event) => setSearchTerm(event.target.value), 500),
    [setSearchTerm]
  );

  const handleOnFocus = () => {
    if (!isActive && Boolean(searchTerm.trim().length)) {
      setIsActive(true);
    }
  };

  const handleOnClear = () => {
    setSearchTerm('');
    setIsTouched(false);
    setIsActive(false);
  };

  const handleLoanSelect = (loanData: LoanData) => {
    history.push(`/loan/${loanData.loanNumber}`);
    handleOnClear();
  };

  return (
    <>
      {isTouched ? (
        <LoanSearch
          onLoanSelect={handleLoanSelect}
          searchTerm={searchTerm}
          isActive={isActive}
          ref={searchResultsRef}
        >
          <SearchBar
            ref={searchBarRef}
            onChange={handleChange}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            data-testid="SearchBar_Input"
          />
        </LoanSearch>
      ) : (
        <ButtonIcon
          data-testid="SearchBar_Activate_Button"
          variant={isDarkTheme(theme) ? 'dark' : 'light'}
          onClick={() => {
            setIsTouched(true);
          }}
        >
          <SearchFillIcon />
        </ButtonIcon>
      )}
    </>
  );
};

type LoanData = {
  id: Loan['id'];
  loanNumber: LoanDetails['loanNumber'];
  loanPropertyAddress?: string;
};

type LoanSearchProps = {
  onLoanSelect: (loanData: LoanData) => void;
  searchTerm: string;
  children: React.ReactNode;
  isActive: boolean;
};

export const LoanSearch = forwardRef<HTMLDivElement, LoanSearchProps>(
  ({ children, isActive, onLoanSelect, searchTerm }, ref) => {
    const [hoveredLoan, setHoveredLoan] = React.useState<LoanData | undefined>(undefined);
    const theme = useTheme();

    const { data: searchResults = [], isLoading, isSuccess } = useLoanSearchQuery(searchTerm);

    useEffect(() => {
      if (searchResults.length > 0) {
        const { id, loanDetails, loanProperty } = searchResults[0];
        const selectedLoan = {
          id,
          loanNumber: loanDetails.loanNumber,
          loanPropertyAddress: loanProperty.property.fullAddress,
        };
        setHoveredLoan(selectedLoan);
      } else {
        setHoveredLoan(undefined);
      }
    }, [isLoading, isSuccess, searchTerm]);

    const selectedResult = searchResults.find((result) => result.id === hoveredLoan?.id);

    const totalInvestorRemittanceAmount =
      selectedResult?.financials?.investorRemittance?.remittanceAmount || 0;

    return (
      <div>
        {children}
        {isActive && (
          <Portal>
            <Styles.Overlay>
              <Styles.SearchResultsWrapper ref={ref}>
                <ActivityIndicator contain isActive={isLoading}>
                  {searchResults.length === 0 ? (
                    <Styles.NoLoansFound>{`No search results matching '${searchTerm}'.`}</Styles.NoLoansFound>
                  ) : (
                    <>
                      <Styles.SearchResults>
                        <Styles.List>
                          {searchResults.map(({ id, loanDetails, loanProperty }) => (
                            <Styles.ListItem key={id}>
                              <Styles.ListItemButton
                                onClick={() => {
                                  onLoanSelect({
                                    id,
                                    loanNumber: loanDetails.loanNumber,
                                    loanPropertyAddress: loanProperty.property.fullAddress,
                                  });
                                }}
                                onMouseEnter={() => {
                                  setHoveredLoan({
                                    id,
                                    loanNumber: loanDetails.loanNumber,
                                  });
                                }}
                                data-testid="LoanSearch_Result"
                                $isSelected={hoveredLoan?.id === id}
                              >
                                {loanDetails.loanNumber}
                              </Styles.ListItemButton>
                            </Styles.ListItem>
                          ))}
                        </Styles.List>
                      </Styles.SearchResults>
                      <Styles.ResultDetail>
                        {selectedResult && !isLoading && (
                          <Styles.SelectLoanButton
                            onClick={() => {
                              onLoanSelect({
                                id: selectedResult.id,
                                loanNumber: selectedResult.loanDetails.loanNumber,
                                loanPropertyAddress:
                                  selectedResult.loanProperty.property.fullAddress,
                              });
                            }}
                          >
                            <Styles.ResultHeader>
                              <Styles.ResultTitle>
                                {selectedResult.loanDetails.loanNumber}
                              </Styles.ResultTitle>
                              <Styles.ResultStatus>
                                Status:
                                <DelinquencyStatus
                                  isDelinquent={selectedResult.delinquencyStatus.isDelinquent}
                                />
                              </Styles.ResultStatus>
                            </Styles.ResultHeader>
                            <Styles.ResultMain>
                              <Styles.ResultContent>
                                <Styles.ResultSubtitle>Financials</Styles.ResultSubtitle>
                                <Styles.Row>
                                  <Styles.Key>Purchase Price</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(
                                      selectedResult.loanProperty.property.purchasePrice
                                    )}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Current Value</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(
                                      selectedResult.loanProperty.property.currentBpo
                                    )}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Down Payment</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(
                                      selectedResult.loanProperty.property.downPaymentAmount
                                    )}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Original UPB</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(selectedResult.financials.originalUPB)}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Current UPB</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(selectedResult.financials.currentUPB)}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Current LTV</Styles.Key>
                                  <Styles.Value>
                                    {formatPercentage(selectedResult.financials.currentLTV)}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Total Monthly Payment</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(selectedResult.financials.totalMonthlyPayment)}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Investor Remittance</Styles.Key>
                                  <Styles.Value>
                                    {formatMonetary(totalInvestorRemittanceAmount)}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Servicing Fee Rate</Styles.Key>
                                  <Styles.Value>
                                    {formatPercentage(
                                      selectedResult.financials.investorRemittance
                                        .yearlyServicingFeeRate
                                    )}
                                  </Styles.Value>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Escrow Monthly Payment</Styles.Key>
                                  <Styles.ValueWithAlert>
                                    {formatMonetary(
                                      selectedResult.loanEscrow.escrowMonthlyPaymentAmount
                                    )}
                                    {!selectedResult.loanEscrow.isEscrowMonthlyPaymentCompliant && (
                                      <DeltaIcon
                                        color={theme.colors.redSecondary}
                                        height="12px"
                                        width="12px"
                                      />
                                    )}
                                  </Styles.ValueWithAlert>
                                </Styles.Row>
                                <Styles.Row>
                                  <Styles.Key>Address</Styles.Key>
                                  <Styles.Value>
                                    {selectedResult.loanProperty.property.fullAddress}
                                  </Styles.Value>
                                </Styles.Row>
                              </Styles.ResultContent>
                              <Styles.ResultImage
                                alt={`Photo of ${selectedResult.loanDetails.loanNumber} property`}
                                src={selectedResult.loanProperty.photos[0].path}
                              />
                            </Styles.ResultMain>
                          </Styles.SelectLoanButton>
                        )}
                      </Styles.ResultDetail>
                    </>
                  )}
                </ActivityIndicator>
              </Styles.SearchResultsWrapper>
            </Styles.Overlay>
          </Portal>
        )}
      </div>
    );
  }
);
