import { PortfolioHomeAnalyticItem, PortfolioHomeAnalyticType } from '@plus-platform/shared';
import { useParams } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { useHomeAnalyticGroupSummaryQuery } from '../../hooks/queries';
import { AnalyticGroupDetailWidget } from './AnalyticGroupDetailWidget';
import { getLabelForHomeAnalytic, mapHomeAnalyticItemToAnalyticItem } from './utils';

type HomeAnalyticGroupDetailParams = {
  analyticId: string;
  groupValue: string;
};

type HomeAnalyticGroupDetailPageProps = {
  analytic?: PortfolioHomeAnalyticItem;
  isLoading: boolean;
};

export const HomeAnalyticGroupDetailPage = (props: HomeAnalyticGroupDetailPageProps) => {
  const { analytic, isLoading } = props;
  const { analyticId, groupValue } = useParams<HomeAnalyticGroupDetailParams>();

  const title = analytic ? getLabelForHomeAnalytic(analytic) : '';

  const { data: loansSummaryData, isLoading: isSummaryLoading } = useHomeAnalyticGroupSummaryQuery(
    Number(analyticId),
    groupValue
  );

  if (analytic?.type === PortfolioHomeAnalyticType.ONE_METRIC) {
    return null;
  }

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>{title}</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem linkTo={`/home/analytics/${analyticId}`} title={title} />
              <BreadcrumbItem title="Detail" />
            </Breadcrumb>
          </div>
        </PageHeaderContent>
      </PageHeader>

      <PageContent>
        <AnalyticGroupDetailWidget
          analytic={mapHomeAnalyticItemToAnalyticItem(analytic)}
          loansSummaryData={loansSummaryData}
          isLoading={isLoading || isSummaryLoading}
          groupValue={groupValue}
        />
      </PageContent>
    </Page>
  );
};
