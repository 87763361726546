import styled from 'styled-components/macro';

export const RowWrapper = styled.div<{ height: number; offsetY: number }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 16px;
  width: 100%;
  height: ${(props) => props.height}px;
  transform: translateY(${(props) => props.offsetY}px);
`;
