import {
  OutboundTradeRequestWithSummary,
  TradeQuoteType,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';
import React from 'react';

import { MessageText, PlusMessage, UserMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { QuoteCounterSummary } from '../QuoteCounterSummary';
import { QuoteStack } from '../QuoteStack';
import { QuoteSummary } from '../QuoteSummary';
import { QuoteSummaryType } from '../types';

type OutboundQuoteCounteredProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: OutboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const OutboundQuoteCountered = (props: OutboundQuoteCounteredProps) => {
  const { quote, totalQuoteCount, tradeRequest } = props;
  const { fromCounterparty, toCounterparty } = quote;

  const lastCounter = quote;

  const allQuotes = [
    { ...lastCounter, stipulation: lastCounter.quoteStipulation },
    ...quote.linkedQuotes,
  ];
  const initialQuote = allQuotes.find((quote) => !quote.linkedQuoteId);
  const counters = allQuotes.filter((quote) => quote.linkedQuoteId);
  const sortedCounters = [...counters].reverse();

  const messageDate = quote.linkedQuoteId ? lastCounter.createdAt : quote.createdAt;

  const totalCounterbacksCount = [lastCounter, ...quote.linkedQuotes].filter(
    (counter) => counter.type === TradeQuoteType.BUYER_TO_SELLER && Boolean(counter.linkedQuoteId)
  ).length;

  return (
    <React.Fragment>
      {lastCounter.type === TradeQuoteType.SELLER_TO_BUYER && (
        <PlusMessage date={quote.createdAt}>
          <MessageText>
            <TradeMessage
              id="Trade.Outbound.Quote.Countered"
              values={{
                userName: formatNameWithInitial(
                  toCounterparty.user.lastName,
                  toCounterparty.user.firstName
                ),
                organizationName: toCounterparty.user.organization.tradingName,
              }}
            />
          </MessageText>
        </PlusMessage>
      )}
      <UserMessage
        date={messageDate}
        organizationName={fromCounterparty.user.organization.tradingName}
        lastName={fromCounterparty.user.lastName}
        firstName={fromCounterparty.user.firstName}
      >
        <QuoteStack>
          {initialQuote && (
            <QuoteSummary
              currentQuoteIndex={0}
              quote={initialQuote}
              totalQuoteCount={totalQuoteCount}
              tradeRequest={tradeRequest}
            />
          )}

          {sortedCounters.map((linkedQuote) => {
            const linkedQuoteType =
              linkedQuote.type === TradeQuoteType.BUYER_TO_SELLER
                ? QuoteSummaryType.COUNTERBACK
                : QuoteSummaryType.COUNTER;

            const linkedQuotesCounters = sortedCounters.filter(
              (quote) => quote.type === linkedQuote.type
            );
            const currentCounterIndex = linkedQuotesCounters.findIndex(
              (counter) => counter.id === linkedQuote.id
            );

            const totalQuoteCount =
              linkedQuoteType === QuoteSummaryType.COUNTERBACK
                ? totalCounterbacksCount
                : linkedQuotesCounters.length;

            return (
              <QuoteCounterSummary
                currentCounterIndex={currentCounterIndex}
                key={linkedQuote.id}
                reason={linkedQuote.reason}
                // TODO: Generate reference
                reference={String(linkedQuote.id)}
                sender={linkedQuote.fromCounterparty.user}
                senderOrganization={linkedQuote.fromCounterparty.user.organization}
                sentAt={linkedQuote.createdAt}
                stipulation={linkedQuote.stipulation}
                totalCounterCount={totalQuoteCount}
                type={linkedQuoteType}
                value={linkedQuote.value}
              />
            );
          })}
        </QuoteStack>
      </UserMessage>
    </React.Fragment>
  );
};
