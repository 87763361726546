import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

export const TAB_HEIGHT = 50;

export const Tabs = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const TabsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Tabs} li:not(:first-child) {
    margin-left: 16px;
  }
`;

type TabItemProps = {
  $isActive?: boolean;
  $isDisabled?: boolean;
};

export const TabItem = styled.li<TabItemProps>`
  ${({ $isActive, $isDisabled, theme }) => css`
    display: flex;
    align-self: center;
    align-items: center;
    color: ${transparentize(0.6, theme.colors.white)};
    cursor: pointer;
    font-family: ${theme.typography.fontFamily.display};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    height: ${TAB_HEIGHT}px;
    padding-top: 6px;
    transition: color 0.2s ease-in-out;

    & + & {
      margin-left: 12px;
    }

    svg {
      margin-top: 2px;
      color: ${transparentize(0.7, theme.colors.white)};
    }

    &:hover {
      color: ${transparentize(0.2, theme.colors.white)};
    }

    ${$isActive &&
    css`
      box-shadow: inset 0 -2px 0 0 ${theme.colors.purpleSecondary};
      color: ${transparentize(0.2, theme.colors.white)};
    `}

    ${$isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  `}
`;
