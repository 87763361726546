import {
  InboundTradeRequestWithSummary,
  TradeCounterpartyActionHistoryFilter,
  TradeCounterpartyActionType,
} from '@plus-platform/shared';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components/macro';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Button } from '../../../components/Button';
import { ButtonLoader } from '../../../components/ButtonLoader';
import { DocumentCard } from '../../../components/DocumentCard';
import { Heading } from '../../../components/Heading';
import { MessageActions, MessageText, PlusMessage } from '../../../components/Messages';
import { DateDivider } from '../../../components/Messages/DateDivider';
import {
  isMessageDate,
  isMessageInboundCTA,
  shouldDisplayDateDivider,
} from '../../../components/Messages/messengerUtils';
import {
  DateMessage,
  InboundActionMessage,
  InboundCTAMessage,
  InboundMessage,
  MessageType,
} from '../../../components/Messages/types';
import { UploadOverlay } from '../../../components/UploadOverlay';
import { useUserContext } from '../../../contexts/UserContext';
import { useTradeRequestExpressInterestMutation } from '../../../hooks/queries';
import { canCounterpartyExpressInterest } from '../../tradeRequestsUtils';
import {
  getActionsFromData,
  useTradeRequestInboundActionsHistory,
} from '../../useTradeRequestInboundActionsHistory';
import * as Styles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import { InboundExpressionOfInterestAccepted } from './Messages/InboundExpressionOfInterestAccepted';
import { InboundExpressionOfInterestDeclined } from './Messages/InboundExpressionOfInterestDeclined';
import { InboundExpressionOfInterestRequested } from './Messages/InboundExpressionOfInterestRequested';

const StipulationUploadWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  place-content: center;
  flex-direction: column;
`;

const StipulationUploadConfirmation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;
`;

type InboundTradeRequestExpressionsOfInterestProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundTradeRequestExpressionsOfInterest = (
  props: InboundTradeRequestExpressionsOfInterestProps
) => {
  const { tradeRequest } = props;
  const [stipulation, setStipulation] = React.useState<File | undefined>();
  const [canExpressInterest, setCanExpressInterest] = React.useState(false);
  const { isUserDataLoading, userProfile } = useUserContext();
  const { actions, fetchMoreActions, isLoading } = useTradeRequestInboundActionsHistory(
    String(tradeRequest.id),
    TradeCounterpartyActionHistoryFilter.EXPRESSED_INTEREST,
    (data) => {
      const actions = getActionsFromData(data);
      setCanExpressInterest(canCounterpartyExpressInterest(actions));
    }
  );

  const { isLoading: isExpressingInterest, mutate: expressInterestOnTradeRequest } =
    useTradeRequestExpressInterestMutation();

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    disabled: !canExpressInterest || isExpressingInterest,
    noClick: true,
    accept: 'application/pdf',
    multiple: false,
    onDropAccepted: ([stipulation]) => {
      setStipulation(stipulation);
    },
  });

  const handleExpressInterest = () => {
    if (stipulation) {
      expressInterestOnTradeRequest(
        {
          tradeRequestId: tradeRequest.id,
          stipulation,
        },
        {
          onSuccess: () => {
            setStipulation(undefined);
            setCanExpressInterest(false);
          },
        }
      );
    }
  };

  const handleRemoveStips = () => {
    if (!isExpressingInterest) {
      setStipulation(undefined);
    }
  };

  let messageList = React.useMemo(
    () =>
      actions.flatMap((action, index) => {
        const previousAction = actions[index - 1];

        let messages: InboundMessage[] = [];

        if (
          action.createdAt &&
          shouldDisplayDateDivider(action.createdAt, previousAction?.createdAt)
        ) {
          const message: DateMessage = {
            type: MessageType.DATE_DIVIDER,
            data: { date: new Date(action.createdAt) },
          };
          messages = [...messages, message];
        }

        if (
          action.type === TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST &&
          userProfile
        ) {
          const message: InboundActionMessage = {
            type: MessageType.ACTION,
            data: { action, tradeRequest, userProfile },
          };
          messages = [...messages, message];
        }

        if (
          [
            TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST,
            TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST,
          ].includes(action.type) &&
          userProfile
        ) {
          const message: InboundActionMessage = {
            type: MessageType.ACTION,
            data: { action, tradeRequest, userProfile },
          };
          messages = [...messages, message];
        }

        return messages;
      }),
    [actions, tradeRequest, userProfile]
  );

  if (canExpressInterest) {
    const message: InboundCTAMessage = {
      type: MessageType.CTA,
    };
    messageList = [...messageList, message];
  }

  return (
    <StipulationUploadWrapper {...getRootProps()}>
      <ActivityIndicator contain isActive={isLoading || isUserDataLoading}>
        <Styles.Header>
          <Heading $size="medium">Expressions of interest</Heading>
          <Styles.IntroText>
            This is the very beginning of Expressions of interest on trading pool{' '}
            <strong>{tradeRequest.pool.name}</strong> ticket ID <strong>{tradeRequest.id}</strong>.
          </Styles.IntroText>
        </Styles.Header>
        <MessengerList<InboundMessage> loadMore={fetchMoreActions} messages={messageList}>
          {({ measure, message }) => (
            <React.Fragment>
              {isMessageDate(message) && (
                <DateDivider
                  key={`${(message as DateMessage).data.date}`}
                  variant="outline"
                  {...(message as DateMessage).data}
                />
              )}
              {message.type === MessageType.ACTION && (
                <React.Fragment>
                  {message.data.action.type ===
                    TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST &&
                    message.data.userProfile && (
                      <InboundExpressionOfInterestRequested
                        onStipulationToggle={measure}
                        {...message.data}
                      />
                    )}
                  {message.data.action.type ===
                    TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST && (
                    <InboundExpressionOfInterestAccepted {...message.data} />
                  )}
                  {message.data.action.type ===
                    TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST && (
                    <InboundExpressionOfInterestDeclined {...message.data} />
                  )}
                </React.Fragment>
              )}
              {isMessageInboundCTA(message) && (
                // FIXME: Workaround for measure not firing to recalculate message height once stip has been selected
                <PlusMessage style={{ height: 180 }}>
                  <MessageText>
                    You're able to Express Interest in the pool{' '}
                    <strong>{tradeRequest.pool.name}</strong> ticket ID{' '}
                    <strong>{tradeRequest.id}</strong> immediately, but you'll need to send{' '}
                    <strong>Stipulations</strong>.
                  </MessageText>

                  {!stipulation && (
                    <MessageActions>
                      <Button $size="large" disabled={isExpressingInterest} onClick={open}>
                        Browse for stipulations
                      </Button>
                    </MessageActions>
                  )}

                  {stipulation && (
                    <StipulationUploadConfirmation>
                      <DocumentCard
                        documentName={stipulation.name}
                        onRemove={() => {
                          handleRemoveStips();
                          measure();
                        }}
                      />
                      <ButtonLoader
                        $size="large"
                        isLoading={isExpressingInterest}
                        onClick={handleExpressInterest}
                      >
                        Send stipulations
                      </ButtonLoader>
                    </StipulationUploadConfirmation>
                  )}
                  <input {...getInputProps()} />
                </PlusMessage>
              )}
            </React.Fragment>
          )}
        </MessengerList>
      </ActivityIndicator>
      <UploadOverlay isVisible={isDragActive} />
    </StipulationUploadWrapper>
  );
};
