import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { TradeRequestsCompleted } from './TradeRequestsCompleted';

export const TradeRequestsCompletedPage = () => {
  return (
    <Page>
      <PageHeader>
        <Heading>Completed trades</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/trade-requests" title="Trade activity" />
          <BreadcrumbItem title="Completed" />
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        <TradeRequestsCompleted />
      </PageContent>
    </Page>
  );
};
