import {
  AttributeType,
  GetPoolsItem,
  PermissionType,
  PortfolioSettings,
} from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { AddIcon } from '../components/icons';
import { InternalLink } from '../components/Link';
import { Page, PageContent, PageSectionTitle, PageSectionTitleWrapper } from '../components/Page';
import { Permissions, ReadPermissions } from '../components/Permissions';
import { useLayoutContext } from '../contexts/LayoutContext';
import { PrivateRoute } from '../global/PrivateRoute';
import {
  usePortfolioHomeAnalyticsQuery,
  usePortfolioSettingsQuery,
  usePortfolioSummaryQuery,
  useUpdatePortfolioSettingsMutation,
} from '../hooks/queries';
import { usePoolsQuery } from '../hooks/queries/usePoolsQuery';
import { CreateNewPoolButton } from '../pools/CreateNewPoolButton';
import { NoPoolsSection, NoPoolsText } from '../pools/PoolsPage';
import { PoolWidget } from '../pools/PoolWidget';
import { TradeRequestDrawer } from '../trades/TradeRequestDrawer/index';
import { HomeAnalyticTile } from './analytics/HomeAnalyticTile';
import { PortfolioBreakdown } from './Breakdown/PortfolioBreakdown';
import { DataPopup } from './DataPopup/DataPopup';
import {
  Analytics,
  CardContent,
  Content,
  Layout,
  PoolList,
  Section,
  SectionContent,
  SectionFooter,
  SidePanel,
} from './InvestorHomePage.styles';

export const InvestorHomePage = () => {
  const { data: portfolioSummary } = usePortfolioSummaryQuery();
  const { data: poolsData, isLoading: arePoolsLoading } = usePoolsQuery();
  const { data: settings } = usePortfolioSettingsQuery();
  const { mutateAsync: updatePortfolioSettings } = useUpdatePortfolioSettingsMutation();
  const { data: analytics = [] } = usePortfolioHomeAnalyticsQuery();
  const { ticketDrawer } = useLayoutContext();
  const match = useRouteMatch();
  const history = useHistory();

  const pages = compact(get(poolsData, 'pages', []));
  const pools: GetPoolsItem[] = flatMap(pages, 'data');
  const breakdownTypes = settings?.breakdown ?? [];

  const openEditDataPopup = () => {
    history.push(`${match.url}/data/edit`);
  };

  const closeEditDataPopup = () => {
    history.push(match.url);
  };

  const onUpdateData = async (settings: PortfolioSettings) => {
    await updatePortfolioSettings(settings);

    closeEditDataPopup();
  };

  return (
    <React.Fragment>
      <Page $withoutHeader>
        <PageContent>
          <Layout>
            <Content $isTicketDrawerOpen={ticketDrawer.isPanelOpen}>
              <Section $isTicketDrawerOpen={ticketDrawer.isPanelOpen}>
                <Card>
                  <CardContent>
                    <PortfolioBreakdown
                      breakdownTypes={breakdownTypes}
                      portfolioSummary={portfolioSummary}
                    />
                  </CardContent>
                </Card>

                {Boolean(analytics.length) && (
                  <Analytics>
                    {analytics.map((analytic) => (
                      <HomeAnalyticTile key={analytic.id} {...analytic} />
                    ))}
                  </Analytics>
                )}

                <SectionFooter>
                  <Button $size="large" onClick={openEditDataPopup} startIcon={<AddIcon />}>
                    Edit data
                  </Button>
                </SectionFooter>
              </Section>

              {!arePoolsLoading && (
                <Section
                  $isTicketDrawerOpen={ticketDrawer.isPanelOpen}
                  data-testid="InvestorHomePage_Pools"
                >
                  <PageSectionTitleWrapper>
                    <PageSectionTitle>
                      <InternalLink to="/pools">Pools</InternalLink>
                    </PageSectionTitle>

                    {!arePoolsLoading && pools.length === 0 && (
                      <Permissions
                        permissions={{
                          [PermissionType.PERMISSION_CREATE]: [AttributeType.ATTRIBUTE_POOL],
                        }}
                      >
                        <CreateNewPoolButton />
                      </Permissions>
                    )}
                  </PageSectionTitleWrapper>
                  <SectionContent>
                    {!arePoolsLoading && pools.length === 0 && (
                      <NoPoolsSection>
                        <NoPoolsText>No pools added</NoPoolsText>
                      </NoPoolsSection>
                    )}
                    {!arePoolsLoading && pools.length > 0 && (
                      <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_POOL]}>
                        <PoolList>
                          {pools.map(({ id, name, summary }) => (
                            <PoolWidget id={id} key={id} name={name} summary={summary} />
                          ))}
                        </PoolList>
                      </ReadPermissions>
                    )}
                  </SectionContent>
                </Section>
              )}
            </Content>
            {ticketDrawer.isPanelOpen && (
              <SidePanel>
                <TradeRequestDrawer onClose={ticketDrawer.onPanelClose} />
              </SidePanel>
            )}
          </Layout>
        </PageContent>
      </Page>

      {settings && (
        <PrivateRoute
          render={() => (
            <DataPopup onCancel={closeEditDataPopup} onUpdate={onUpdateData} settings={settings} />
          )}
          path={`${match.path}/data`}
        />
      )}
    </React.Fragment>
  );
};
