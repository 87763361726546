import React from 'react';

export const InquiryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        fill="currentColor"
        height="7"
        stroke="currentColor"
        strokeWidth="2"
        width="10"
        x="1"
        y="1"
      />
      <path d="M3 12V8H9L3 12Z" fill="currentColor" />
    </svg>
  );
};
