import { AttributeType, Loan, LoanDetails, LoanNoteDto } from '@plus-platform/shared';
import isEmpty from 'lodash/isEmpty';
import React, { RefObject } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { Card } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import { Loader } from '../../../components/Loader';
import { MessageText, UserMessage } from '../../../components/Messages';
import { DateDivider } from '../../../components/Messages/DateDivider';
import { shouldDisplayDateDivider } from '../../../components/Messages/messengerUtils';
import { CreatePermissions, ReadPermissions } from '../../../components/Permissions';
import { useGetLoanNotesQuery } from '../../../hooks/queries';
import { AddNoteButton } from '../../AddNoteButton';
import * as Styles from './LoanDetailNotes.styles';

type LoanNotesListProps = {
  loanNumber: LoanDetails['loanNumber'];
};

type LoanDetailNotesProps = {
  loan: Loan;
  ref: RefObject<HTMLDivElement>;
};

const LoanDetailNotesList = ({ loanNumber }: LoanNotesListProps) => {
  const {
    data: infinitePagedLoanNotes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetLoanNotesQuery(loanNumber);

  const scrollRef = useBottomScrollListener(async () => {
    if (hasNextPage) {
      await fetchNextPage({ cancelRefetch: false });
    }
  });

  const loanNotesItems = infinitePagedLoanNotes?.pages
    .filter((page) => Boolean(page?.items))
    .flatMap((page) => page?.items ?? []) as LoanNoteDto[];

  const loanNotesListIsEmpty = isEmpty(loanNotesItems);

  return (
    <Styles.Wrapper ref={scrollRef as RefObject<HTMLDivElement>}>
      {loanNotesListIsEmpty ? (
        <Styles.NoteText $size="small">No notes available</Styles.NoteText>
      ) : (
        <Styles.List>
          {loanNotesItems.map((loanNote, index) => {
            const previousLoanNote = loanNotesItems[index - 1];
            const createdAt = new Date(loanNote.createdAt);
            const previousNoteCreatedAt = new Date(previousLoanNote?.createdAt);
            return (
              <React.Fragment key={loanNote.id}>
                {loanNote.createdAt &&
                  shouldDisplayDateDivider(createdAt, previousNoteCreatedAt) && (
                    <Styles.DateDividerWrapper>
                      <DateDivider date={createdAt} />
                    </Styles.DateDividerWrapper>
                  )}
                <UserMessage
                  firstName={loanNote.createdBy.firstName}
                  lastName={loanNote.createdBy.lastName}
                  userAvatar={loanNote.createdBy.avatarUrl}
                  date={new Date(loanNote.createdAt)}
                >
                  <MessageText>
                    <Styles.NoteContentWrapper>{loanNote.content}</Styles.NoteContentWrapper>
                  </MessageText>
                </UserMessage>
              </React.Fragment>
            );
          })}
        </Styles.List>
      )}
      {isFetchingNextPage && <Styles.Loading>Loading more...</Styles.Loading>}
    </Styles.Wrapper>
  );
};

const LoanDetailNotes = React.forwardRef<HTMLDivElement, LoanDetailNotesProps>(({ loan }, ref) => (
  <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_NOTES]}>
    <Card id="notes" ref={ref} data-testid="LoanDetail_Notes">
      <Styles.ControlsWrapper>
        <Heading $size="medium">Notes</Heading>
        <CreatePermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_NOTES]}>
          <AddNoteButton loanNumber={loan.loanDetails.loanNumber} />
        </CreatePermissions>
      </Styles.ControlsWrapper>
      <React.Suspense fallback={<Loader />}>
        <LoanDetailNotesList loanNumber={loan.loanDetails.loanNumber} />
      </React.Suspense>
    </Card>
  </ReadPermissions>
));

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailNotes;
