import {
  DocumentCategoryType,
  GetPortfolioLoansDocumentSummaryItemDocument,
} from '@plus-platform/shared';
import { useTheme } from 'styled-components/macro';

import { Badge } from '../components/Badge';
import { Divider } from '../components/Divider';
import { PDFOutlineIcon } from '../components/icons';
import { WidgetSection } from '../components/Widget';
import { EMPTY_VALUE } from '../utils/formatUtils';
import * as Styles from './LoanDocumentsLists.styles';

type DocumentDetailsProps = {
  documentTitle: string;
  searchTermFrequency?: number;
  showStatus: boolean;
};

const DocumentDetails = ({
  documentTitle,
  searchTermFrequency,
  showStatus,
}: DocumentDetailsProps) => {
  const theme = useTheme();

  return (
    <Styles.DocumentDetailsWrapper data-testid="LoanDocuments_DocumentDetailsItem">
      {/* TODO: Add document statuses - Placeholder below for now */}
      {showStatus && <Badge $color={theme.colors.statusGreen} />}
      <Styles.IconWrapper>
        <PDFOutlineIcon color={theme.colors.white} width={16} />
      </Styles.IconWrapper>
      <Styles.DocumentContent>
        <Styles.DocumentHeading $size="small">{documentTitle}</Styles.DocumentHeading>
        <Styles.DocumentText>{EMPTY_VALUE}</Styles.DocumentText>
        {Number(searchTermFrequency) > 0 && (
          <Styles.DocumentText>
            {searchTermFrequency} result
            {searchTermFrequency === 1 ? '' : 's'} found
          </Styles.DocumentText>
        )}
      </Styles.DocumentContent>
    </Styles.DocumentDetailsWrapper>
  );
};

// Using the type from portfolio loans document summary for now, because the types share
// the same shape, but eventually we'll want to define our own type for this component.
// That shares the shape of both the loan documents returned and portfolio loan document summary.
export type LoanDocumentListsDocument = GetPortfolioLoansDocumentSummaryItemDocument;

type LoanDocumentsListsProps = {
  onDocumentClick?: (document: LoanDocumentListsDocument) => void;
  documentCategory: DocumentCategoryType;
  creditDocuments: LoanDocumentListsDocument[];
  disclosureDocuments: LoanDocumentListsDocument[];
  closingDocuments: LoanDocumentListsDocument[];
  variant?: 'popover';
};

export const LoanDocumentsLists = (props: LoanDocumentsListsProps) => {
  const {
    closingDocuments,
    creditDocuments,
    disclosureDocuments,
    documentCategory,
    onDocumentClick,
  } = props;
  const theme = useTheme();

  return (
    <Styles.LDWidgetContent>
      {documentCategory === DocumentCategoryType.ORIGINATION_DOCUMENT && (
        <>
          <WidgetSection>
            <Styles.ListHeading $size="small">
              Closing documents: {closingDocuments.length} ( {closingDocuments.length}{' '}
              <Styles.CountBadge $color={theme.colors.statusGreen} /> 0{' '}
              <Styles.CountBadge $color={theme.colors.statusRed} />)
            </Styles.ListHeading>

            {closingDocuments.map((document) => (
              <Styles.DocumentListItem key={document.id}>
                <DocumentDetails
                  documentTitle={document.documentTitle}
                  searchTermFrequency={document.searchTermFrequency ?? 0}
                  showStatus
                />
              </Styles.DocumentListItem>
            ))}
          </WidgetSection>

          <Divider $flexItem $orientation="vertical" />

          <WidgetSection>
            <Styles.ListHeading $size="small">
              Credit documents: {creditDocuments.length} ( {creditDocuments.length}{' '}
              <Styles.CountBadge $color={theme.colors.statusGreen} /> 0{' '}
              <Styles.CountBadge $color={theme.colors.statusRed} />)
            </Styles.ListHeading>

            <Styles.DocumentList>
              {creditDocuments.map((document) => (
                <Styles.DocumentListItem key={document.id}>
                  <Styles.DocumentButton
                    onClick={() => {
                      if (document?.processorId || document?.submissionId) {
                        onDocumentClick?.(document);
                      }
                    }}
                  >
                    <DocumentDetails
                      documentTitle={document.documentTitle}
                      searchTermFrequency={document.searchTermFrequency ?? 0}
                      showStatus
                    />
                  </Styles.DocumentButton>
                </Styles.DocumentListItem>
              ))}
            </Styles.DocumentList>
          </WidgetSection>

          <Divider $flexItem $orientation="vertical" />

          <WidgetSection>
            <Styles.ListHeading $size="small">
              Disclosure documents: {disclosureDocuments.length} ( {disclosureDocuments.length}{' '}
              <Styles.CountBadge $color={theme.colors.statusGreen} /> 0{' '}
              <Styles.CountBadge $color={theme.colors.statusRed} />)
            </Styles.ListHeading>

            {disclosureDocuments.map((document) => (
              <Styles.DocumentListItem key={document.id}>
                <DocumentDetails
                  documentTitle={document.documentTitle}
                  searchTermFrequency={document.searchTermFrequency ?? 0}
                  showStatus
                />
              </Styles.DocumentListItem>
            ))}
          </WidgetSection>
        </>
      )}
    </Styles.LDWidgetContent>
  );
};
