import { NavLinkProps, useLocation } from 'react-router-dom';

import {
  NotificationsFillIcon,
  NotificationsLineIcon,
  PortfolioFillIcon,
  PortfolioLineIcon,
  TradeFillIcon,
  TradeLineIcon,
  WarningIcon,
} from '../components/icons';
import { AppearanceFillIcon } from '../components/icons/AppearanceFillIcon';
import { AppearanceLineIcon } from '../components/icons/AppearanceLineIcon';
import { FilterFillIcon } from '../components/icons/FilterFillIcon';
import { FilterLineIcon } from '../components/icons/FilterLineIcon';
import { SettingsFillIcon } from '../components/icons/SettingsFillIcon';
import { SettingsLineIcon } from '../components/icons/SettingsLineIcon';
import { UsersIcon } from '../components/icons/UsersIcon';
import {
  IconWrapper,
  MenuItem,
  MenuItemLabel,
  MenuItemLink,
  NavigationMenu,
  Wrapper,
} from './SidePanel.styles';

type NavigationLinkProps = NavLinkProps & {
  width?: number;
  children: (props: { isActive: boolean }) => React.ReactNode;
};

const NavigationLink = ({ children, to }: NavigationLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname.includes(to as string);

  return (
    <MenuItemLink to={to} $isActive={isActive}>
      {children({ isActive })}
    </MenuItemLink>
  );
};

export const SidePanel = () => {
  return (
    <Wrapper>
      <NavigationMenu>
        <MenuItem>
          <NavigationLink to="#">
            {({ isActive }) => (
              <>
                <IconWrapper>{isActive ? <SettingsFillIcon /> : <SettingsLineIcon />}</IconWrapper>
                <MenuItemLabel $isActive={isActive}>General</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="#">
            {({ isActive }) => (
              <>
                <IconWrapper>
                  {isActive ? <AppearanceFillIcon /> : <AppearanceLineIcon />}
                </IconWrapper>
                <MenuItemLabel $isActive={isActive}>Appearance</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="#">
            {({ isActive }) => (
              <>
                <IconWrapper>{isActive ? <FilterFillIcon /> : <FilterLineIcon />}</IconWrapper>
                <MenuItemLabel $isActive={isActive}>Filters</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="#">
            {({ isActive }) => (
              <>
                <IconWrapper>{isActive ? <TradeFillIcon /> : <TradeLineIcon />}</IconWrapper>
                <MenuItemLabel $isActive={isActive}>Trading</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="#">
            {({ isActive }) => (
              <>
                <IconWrapper>
                  {isActive ? <NotificationsFillIcon /> : <NotificationsLineIcon />}
                </IconWrapper>
                <MenuItemLabel $isActive={isActive}>Notifications</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="/admin/flags-alerts-warnings">
            {({ isActive }) => (
              <>
                <IconWrapper>
                  <WarningIcon />
                </IconWrapper>
                <MenuItemLabel $isActive={isActive}>Flags, Alerts & Warnings</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="#">
            {({ isActive }) => (
              <>
                <IconWrapper>
                  {isActive ? <PortfolioFillIcon /> : <PortfolioLineIcon />}
                </IconWrapper>
                <MenuItemLabel $isActive={isActive}>Portfolio</MenuItemLabel>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="/admin/users">
            {({ isActive }) => (
              <>
                <IconWrapper>
                  <UsersIcon />
                </IconWrapper>
                <MenuItemLabel $isActive={isActive}>Users</MenuItemLabel>
              </>
            )}
          </NavigationLink>
        </MenuItem>
      </NavigationMenu>
    </Wrapper>
  );
};
