import styled from 'styled-components';

import { WidgetSection } from '../../../components/Widget';

export const AmortizationWidgetSection = styled(WidgetSection)`
  width: 25%;

  & + & {
    flex: 1;
    display: flex;
    width: auto;
  }
`;

export const AmortizationPadding = styled.div`
  padding: 12px;
`;

export const Chart = styled.div`
  max-width: 668px;
  padding: 12px;
  width: 70%;
`;

export const ChartDescription = styled.div``;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;
