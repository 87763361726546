import React from 'react';

export const BellIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" height="1em" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M4.2002 1.81274V1.71429C4.2002 0.767512 5.00608 0 6.0002 0C6.99431 0 7.8002 0.767512 7.8002 1.71429V1.81289C8.88546 2.09202 9.74278 2.93885 9.96349 4.03187L10.9955 9.14287H12V10.2857H8.4001C8.4001 11.2325 7.59421 12 6.6001 12H5.4001C4.40598 12 3.6001 11.2325 3.6001 10.2857H0V9.14287H1.0043L2.03633 4.03187C2.25707 2.93866 3.11466 2.09172 4.2002 1.81274ZM4.8001 10.2857H7.2001C7.2001 10.6013 6.93147 10.8572 6.6001 10.8572H5.4001C5.06873 10.8572 4.8001 10.6013 4.8001 10.2857Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
