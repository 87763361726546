import styled, { css } from 'styled-components/macro';

export const Status = styled.div<{
  $isActive?: boolean;
  $isInline?: boolean;
  $variant?: 'default' | 'light';
}>`
  ${({ $isActive, $isInline, $variant, theme }) => css`
    display: ${$isInline ? 'inline-flex' : 'flex'};
    align-items: center;
    justify-content: center;
    height: 24px;
    border-radius: 4px;
    background: ${$variant === 'light' ? theme.colors.primary04 : theme.colors.primary03};
    color: ${theme.colors.primary10};
    opacity: ${$isActive ? 1 : 0.2};
    white-space: nowrap;
  `}
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 4px;
`;

export const Label = styled.span(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium03Tight}
    color: ${theme.colors.textPrimary01};
  `
);
