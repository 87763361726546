import { Button } from '../../../../components/Button';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { useTradeRequestOutboundNavigation } from '../../../TradeRequestTickets/useTradeRequestNavigation';

export const OutboundCompleted = () => {
  const { goToCompletedTrades } = useTradeRequestOutboundNavigation();

  return (
    <PlusMessage>
      <MessageText>
        <TradeMessage id="Trade.Outbound.Completed" />
      </MessageText>
      <MessageActions>
        <Button $size="large" onClick={() => goToCompletedTrades()}>
          View completed trades
        </Button>
      </MessageActions>
    </PlusMessage>
  );
};
