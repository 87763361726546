import React from 'react';

export const PieChartFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2187 10.817V3.525C14.151 3.65086 15.8044 4.41782 17.1789 5.82589C18.5535 7.23395 19.3272 8.89767 19.5 10.817H12.2187ZM10.8049 19.525C8.74693 19.3519 7.01497 18.4945 5.60898 16.9527C4.20299 15.4109 3.5 13.6017 3.5 11.525C3.5 9.44831 4.20299 7.63906 5.60898 6.09727C7.01497 4.55548 8.74693 3.69806 10.8049 3.525V19.525ZM12.2187 19.525V12.233H19.5C19.3272 14.1523 18.5535 15.8161 17.1789 17.2241C15.8044 18.6322 14.151 19.3991 12.2187 19.525Z"
        fill="currentColor"
      />
    </svg>
  );
};
