import {
  AnalyticTwoMetricsConfig,
  CalculationType,
  PortfolioAnalyticItem,
  PortfolioAnalyticItemData,
  PortfolioAnalyticMetric,
  PortfolioHomeAnalyticItem,
  PortfolioHomeAnalyticTemplate,
  PortfolioHomeAnalyticType,
  VisualisationType,
} from '@plus-platform/shared';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import React from 'react';

import { formatCompactMonetary, formatPercentage } from '../../utils/formatUtils';

export const ALLOWED_METRIC_OPTIONS = [
  PortfolioAnalyticMetric.DELINQUENCY_RATE,
  PortfolioAnalyticMetric.UPB,
];

export const ALLOWED_METRIC_BY_OPTIONS = [
  PortfolioAnalyticMetric.FICO_SCORE,
  PortfolioAnalyticMetric.STATE,
];

type ValueFormatter = (value: number) => string;
type ValueFlattener = (data: PortfolioAnalyticItemData) => number;

const VALUE_FORMATTERS_MAPPING: Partial<Record<PortfolioAnalyticMetric, ValueFormatter>> = {
  [PortfolioAnalyticMetric.DELINQUENCY_RATE]: (value) => formatPercentage(value),
  [PortfolioAnalyticMetric.UPB]: (value) => formatCompactMonetary(value),
};

const VALUE_FLATTENERS_MAPPING: Partial<Record<PortfolioAnalyticMetric, ValueFlattener>> = {
  [PortfolioAnalyticMetric.DELINQUENCY_RATE]: (data) =>
    Number(data.value) / Number(data.loansCount),
  [PortfolioAnalyticMetric.UPB]: (data) => data.value,
};

export const portfolioAnalyticMetricLabels: Record<PortfolioAnalyticMetric, string> = {
  [PortfolioAnalyticMetric.DELINQUENCY_RATE]: 'Delinquency rate',
  [PortfolioAnalyticMetric.REPOSSESSIONS_COUNT]: 'Repossessions count',
  [PortfolioAnalyticMetric.DEFAULTS_COUNT]: 'Default count',
  [PortfolioAnalyticMetric.REFINANCE_COUNT]: 'Refinance count',
  [PortfolioAnalyticMetric.WAC]: 'Weighted average coupon',
  [PortfolioAnalyticMetric.WTD_AVG_LTV]: 'Weighted average LTV',
  [PortfolioAnalyticMetric.UPB]: 'UPB',
  [PortfolioAnalyticMetric.FICO_SCORE]: 'FICO score',
  [PortfolioAnalyticMetric.STATE]: 'State',
  [PortfolioAnalyticMetric.PURCHASE_PRICE]: 'Purchase price',
};

export const visualizationTypeLabels: Record<VisualisationType, string> = {
  [VisualisationType.BAR_CHART]: 'Bar chart',
  [VisualisationType.BUBBLE_CHART]: 'Bubble chart',
  [VisualisationType.CHLOROPLETH]: 'Chloropleth',
};

export const calculationTypeLabels: Record<CalculationType, string> = {
  [CalculationType.AVERAGE]: 'Average',
  [CalculationType.MEDIAN]: 'Median',
};

type ValuesData = {
  groupedByValue: string;
  value: number;
};

const WORST_CALCULATION_FN_BY_METRIC_MAPPING: Partial<
  Record<PortfolioAnalyticMetric, (data: ValuesData[]) => string | undefined>
> = {
  [PortfolioAnalyticMetric.DELINQUENCY_RATE]: (data) => maxBy(data, 'value')?.groupedByValue,
  [PortfolioAnalyticMetric.UPB]: (data) => minBy(data, 'value')?.groupedByValue,
};

export const getWorstByGroupedValue = (data: ValuesData[], metric?: PortfolioAnalyticMetric) => {
  if (!metric) {
    return undefined;
  }
  return WORST_CALCULATION_FN_BY_METRIC_MAPPING[metric]?.(data);
};

export type AnalyticsChartProps = {
  data: AnalyticsValue[];
  visualisationType: VisualisationType;
  metric: PortfolioAnalyticMetric;
  style?: React.CSSProperties;
};

export const getVisualizationTypeLabel = (type?: VisualisationType) => {
  if (!type) {
    return '';
  }

  return visualizationTypeLabels[type] || '';
};

export const getCalculationTypeLabel = (type?: CalculationType) => {
  if (!type) {
    return '';
  }

  return calculationTypeLabels[type] || '';
};

export const mapMetricsToOptions = (metrics: PortfolioAnalyticMetric[]) => {
  return metrics.map((metric) => ({
    value: metric,
    label: portfolioAnalyticMetricLabels[metric],
  }));
};

export type AnalyticsValue = PortfolioAnalyticItemData & {
  valueLabel: string;
  formattedValue: string;
};

export type LabelsFormatter = (data: any) => string;

export const convertValuesToHashmap = (data: AnalyticsValue[]) => {
  return data.reduce<{
    [key: string]: {
      value: AnalyticsValue['value'];
      formattedValue: AnalyticsValue['formattedValue'];
    };
  }>((acc, item) => {
    return {
      ...acc,
      [item.groupedByValue]: {
        value: item.value,
        formattedValue: item.formattedValue,
      },
    };
  }, {});
};

type GetAllowedVisualizationTypesProps = {
  metric?: string;
  metricBy?: string;
};

// TODO: update that that once we have full knowledge of all metrics combinations
export const getAllowedVisualizationTypes = ({
  metric,
  metricBy,
}: GetAllowedVisualizationTypesProps) => {
  if (!metric || !metricBy) {
    return [];
  }

  if (metricBy === PortfolioAnalyticMetric.STATE) {
    return [VisualisationType.CHLOROPLETH];
  }

  return [VisualisationType.BUBBLE_CHART, VisualisationType.BAR_CHART];
};

// TODO: update that that once we have full knowledge of all metrics combinations
export const getAllowedCalculationTypes = (metric: string) => {
  if (metric === PortfolioAnalyticMetric.UPB) {
    return [CalculationType.AVERAGE, CalculationType.MEDIAN];
  }

  return [];
};

export const formatValueForMetric = (value: number, metric?: PortfolioAnalyticMetric) => {
  if (!metric) {
    return '';
  }
  return VALUE_FORMATTERS_MAPPING?.[metric]?.(value) || '';
};

export const flattenValueForMetric = (
  data: PortfolioAnalyticItemData,
  metric?: PortfolioAnalyticMetric
) => {
  if (!metric) {
    return data.value;
  }
  return VALUE_FLATTENERS_MAPPING?.[metric]?.(data) || data.value;
};

export const getLabelFromAnalyticResult = (data: PortfolioAnalyticItem) => {
  return getLabelFromTwoMetricsConfig({
    metric: data.metric,
    metricBy: data.metricBy,
    metricCalculationType: data.metricCalculationType,
    visualisationType: data.visualisationType,
  });
};

export const getLabelFromTwoMetricsConfig = (config: AnalyticTwoMetricsConfig) => {
  const metricLabel = portfolioAnalyticMetricLabels[config.metric];
  const metricByLabel = portfolioAnalyticMetricLabels[config.metricBy];
  if (!metricLabel || !metricByLabel) {
    return '';
  }

  const metricCalculationType = getCalculationTypeLabel(config.metricCalculationType);

  return `${
    metricCalculationType ? `${metricCalculationType} ` : ''
  }${metricLabel} by ${metricByLabel}`;
};

export const getLabelForHomeAnalytic = (template: PortfolioHomeAnalyticTemplate) => {
  if (template.type === PortfolioHomeAnalyticType.ONE_METRIC) {
    return '-';
  }

  return getLabelFromTwoMetricsConfig(template.config);
};

export const formatDataForAnalyticWidget = (
  metric: PortfolioAnalyticMetric,
  data: PortfolioAnalyticItemData[]
) => {
  return data.reduce<AnalyticsValue[]>((acc, item) => {
    if (item?.value === undefined) {
      return acc;
    }

    const value = flattenValueForMetric(item, metric);
    const formattedValue = formatValueForMetric(value, metric);

    if (data.length === 0) {
      return acc;
    }

    return [
      ...acc,
      {
        groupedByValue: item.groupedByValue,
        loansCount: item.loansCount,
        value,
        formattedValue,
        valueLabel: item.groupedByValue,
      },
    ];
  }, []);
};

export const mapHomeAnalyticItemToAnalyticItem = (homeAnalyticItem?: PortfolioHomeAnalyticItem) => {
  if (!homeAnalyticItem || homeAnalyticItem.type === PortfolioHomeAnalyticType.ONE_METRIC) {
    return;
  }

  const { config, ...analyticItem } = homeAnalyticItem;

  return {
    ...analyticItem,
    metric: config.metric,
    metricBy: config.metricBy,
    metricCalculationType: config.metricCalculationType!,
    visualisationType: config.visualisationType,
  };
};
