import React from 'react';

export const BikeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.33333 17.6667C6.38889 17.6667 5.59722 17.3472 4.95833 16.7083C4.31944 16.0694 4 15.2778 4 14.3333C4 13.3889 4.325 12.5972 4.975 11.9583C5.625 11.3194 6.41111 11 7.33333 11C8.18889 11 8.90833 11.2556 9.49167 11.7667C10.075 12.2778 10.4444 12.9111 10.6 13.6667H11.0333L9.83333 10.3333H8.66667V9H12V10.3333H11.2667L11.5 11H14.7L13.7333 8.33333H12V7H13.7333C14.0222 7 14.2806 7.07778 14.5083 7.23333C14.7361 7.38889 14.9 7.6 15 7.86667L16.1333 10.9667H16.6667C17.5889 10.9667 18.375 11.2917 19.025 11.9417C19.675 12.5917 20 13.3778 20 14.3C20 15.2333 19.6778 16.0278 19.0333 16.6833C18.3889 17.3389 17.6 17.6667 16.6667 17.6667C15.8667 17.6667 15.1639 17.4167 14.5583 16.9167C13.9528 16.4167 13.5667 15.7778 13.4 15H10.6C10.4444 15.7667 10.0667 16.4028 9.46667 16.9083C8.86667 17.4139 8.15556 17.6667 7.33333 17.6667ZM7.33333 16.3333C7.78889 16.3333 8.18056 16.2083 8.50833 15.9583C8.83611 15.7083 9.06667 15.3889 9.2 15H7.33333V13.6667H9.2C9.06667 13.2667 8.83611 12.9444 8.50833 12.7C8.18056 12.4556 7.78889 12.3333 7.33333 12.3333C6.76667 12.3333 6.29167 12.525 5.90833 12.9083C5.525 13.2917 5.33333 13.7667 5.33333 14.3333C5.33333 14.8889 5.525 15.3611 5.90833 15.75C6.29167 16.1389 6.76667 16.3333 7.33333 16.3333ZM12.4667 13.6667H13.4C13.4556 13.4111 13.5306 13.1722 13.625 12.95C13.7194 12.7278 13.8444 12.5222 14 12.3333H11.9667L12.4667 13.6667ZM16.6667 16.3333C17.2333 16.3333 17.7083 16.1389 18.0917 15.75C18.475 15.3611 18.6667 14.8889 18.6667 14.3333C18.6667 13.7667 18.475 13.2917 18.0917 12.9083C17.7083 12.525 17.2333 12.3333 16.6667 12.3333H16.6L17.2667 14.1L16 14.5667L15.3667 12.8C15.1444 12.9889 14.9722 13.2111 14.85 13.4667C14.7278 13.7222 14.6667 14.0111 14.6667 14.3333C14.6667 14.8889 14.8583 15.3611 15.2417 15.75C15.625 16.1389 16.1 16.3333 16.6667 16.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};
