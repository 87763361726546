import { Identifier } from './global';

export enum JumboFamilyType {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
}

export type JumboCap = {
  id: Identifier;
  year: number;
  amount: number;
  familyType: JumboFamilyType;
  createdAt?: Date;
  updatedAt?: Date;
};
