import { AggregatedTradeRequestMessage, LoanChatMessage } from '.';
import { GetNotificationsCountForUserResponse } from './notifications';
import { TradeCounterpartyActionWithSummary } from './trades';

export enum MessageType {
  LoanChatMessage = 'LoanChatMessage',
  TradeRequestChatMessage = 'TradeRequestChatMessage',
  TradeRequestActionMessage = 'TradeRequestActionMessage',
  NotificationsCountMessage = 'NotificationsCountMessage',
}

export type TradeLoanChatMessage = {
  type: MessageType.LoanChatMessage;
  payload: LoanChatMessage;
};

export type TradeRequestChatMessage = {
  type: MessageType.TradeRequestChatMessage;
  payload: AggregatedTradeRequestMessage;
};

export type TradeRequestActionMessage = {
  type: MessageType.TradeRequestActionMessage;
  payload: TradeCounterpartyActionWithSummary;
};

export type NotificationsCountMessage = {
  type: MessageType.NotificationsCountMessage;
  payload: {
    params: Record<string, number | string[]>;
    count: GetNotificationsCountForUserResponse;
  };
};

export type Message =
  | TradeLoanChatMessage
  | TradeRequestChatMessage
  | TradeRequestActionMessage
  | NotificationsCountMessage;
