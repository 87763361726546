import React from 'react';

export const TableFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 5.25C5 5.11193 5.11193 5 5.25 5H18.75C18.8881 5 19 5.11193 19 5.25V10.75C19 10.8881 18.8881 11 18.75 11H5.25C5.11193 11 5 10.8881 5 10.75V5.25Z"
        fill="currentColor"
      />
      <path
        d="M5 12.25C5 12.1119 5.11193 12 5.25 12H18.75C18.8881 12 19 12.1119 19 12.25V14.75C19 14.8881 18.8881 15 18.75 15H5.25C5.11193 15 5 14.8881 5 14.75V12.25Z"
        fill="currentColor"
      />
      <path
        d="M5 16.25C5 16.1119 5.11193 16 5.25 16H18.75C18.8881 16 19 16.1119 19 16.25V18.75C19 18.8881 18.8881 19 18.75 19H5.25C5.11193 19 5 18.8881 5 18.75V16.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
