import { DelinquencyData, PortfolioSummary } from '@plus-platform/shared';
import React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import { CountBadge } from '../components/Badge';
import { Key, Row as Pair, Value } from '../components/DeprecatedKeyValue';
import { Bar, LargeBar, ProgressBar, SummarySection } from '../loans/DeprecatedDelinquencyWidget';
import {
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../utils/formatUtils';

const DelinquencyProgressBar = styled(ProgressBar)`
  margin-top: 16px;
  margin-bottom: 0;
`;

const DelinquencyKey = styled(Key)`
  width: 70%;
`;

const DelinquencyValue = styled(Value)`
  width: 30%;
`;

const Summary = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 6px;
`;

type DelinquencySummaryProps = {
  summary: DelinquencyData;
};

const DelinquencySummary = (props: DelinquencySummaryProps) => {
  const {
    summary: { judicialCount, nonJudicialCount, upb, weightedAverageCoupon, weightedAverageLTV },
  } = props;

  return (
    <React.Fragment>
      <Pair>
        <DelinquencyKey>UPB</DelinquencyKey>
        <DelinquencyValue>{formatMonetary(upb)}</DelinquencyValue>
      </Pair>
      <Pair>
        <DelinquencyKey>Coupon (WAC)</DelinquencyKey>
        <DelinquencyValue>{formatPercentage(weightedAverageCoupon)}</DelinquencyValue>
      </Pair>
      <Pair>
        <DelinquencyKey>Wtd Avg LTV</DelinquencyKey>
        <DelinquencyValue>{formatPercentage(weightedAverageLTV)}</DelinquencyValue>
      </Pair>
      <Pair>
        <DelinquencyKey>Judicial / Non-Judicial</DelinquencyKey>
        <DelinquencyValue>
          {formatDefaultValue(judicialCount)} / {formatDefaultValue(nonJudicialCount)}
        </DelinquencyValue>
      </Pair>
      <Pair>
        <DelinquencyKey>Total P&I arrearage</DelinquencyKey>
        {/* TODO: Get real value from API when available */}
        <DelinquencyValue>{formatMonetary(0)}</DelinquencyValue>
      </Pair>
    </React.Fragment>
  );
};

type PoolLoanSummaryProps = {
  summary: PortfolioSummary;
  variant?: 'pool' | 'loan';
};

export const PoolLoanSummary = (props: PoolLoanSummaryProps) => {
  const {
    summary: {
      loansCount,
      loansInDefault,
      loansUPBAmount,
      loansWeightedAverageCoupon,
      loansWeightedAverageLTV,
    },
    variant,
  } = props;
  const theme = useTheme();

  const $variant = variant ?? 'pool';

  return (
    <Summary>
      <SummarySection $variant={$variant}>
        <Pair>
          <Key color={theme.colors.white}>Overview</Key>
          <Value>
            <CountBadge $color={theme.colors.white}>{formatNumber(loansCount)}</CountBadge>
          </Value>
        </Pair>
        <Pair>
          <Key>UPB</Key>
          <Value>{formatMonetary(loansUPBAmount)}</Value>
        </Pair>
        <Pair>
          <Key>Coupon (WAC)</Key>

          <Value>{formatPercentage(loansWeightedAverageCoupon)}</Value>
        </Pair>
        <Pair>
          <Key>Wtd Avg LTV</Key>
          <Value>{formatPercentage(loansWeightedAverageLTV)}</Value>
        </Pair>
      </SummarySection>

      {loansInDefault.thirtyDays && (
        <SummarySection $variant={$variant}>
          <Pair>
            <DelinquencyKey color="white">Loans in 30-Days Default</DelinquencyKey>
            <DelinquencyValue color={theme.colors.statusYellow}>
              <CountBadge $color={theme.colors.statusYellow}>
                {formatNumber(loansInDefault.thirtyDays.loanCount)}
              </CountBadge>
            </DelinquencyValue>
          </Pair>
          <DelinquencySummary summary={loansInDefault.thirtyDays} />
          <DelinquencyProgressBar>
            <Bar $color={theme.colors.statusYellow} $variant={$variant} />
            <Bar $color={theme.colors.statusBlack} $variant={$variant} />
            <LargeBar $color={theme.colors.statusBlack} $variant={$variant} />
          </DelinquencyProgressBar>
        </SummarySection>
      )}

      {loansInDefault.sixtyDays && (
        <SummarySection $variant={$variant}>
          <Pair>
            <DelinquencyKey color="white">Loans in 60-Days Default</DelinquencyKey>
            <DelinquencyValue color={theme.colors.statusOrange}>
              <CountBadge $color={theme.colors.statusOrange}>
                {formatNumber(loansInDefault.sixtyDays.loanCount)}
              </CountBadge>
            </DelinquencyValue>
          </Pair>
          <DelinquencySummary summary={loansInDefault.sixtyDays} />
          <DelinquencyProgressBar>
            <Bar $color={theme.colors.statusBlack} $variant={$variant} />
            <Bar $color={theme.colors.statusOrange} $variant={$variant} />
            <LargeBar $color={theme.colors.statusBlack} $variant={$variant} />
          </DelinquencyProgressBar>
        </SummarySection>
      )}

      {loansInDefault.ninetyPlusDays && (
        <SummarySection $variant={$variant}>
          <Pair>
            <DelinquencyKey color="white">Loans in 90+ day Default</DelinquencyKey>
            <DelinquencyValue color={theme.colors.redSecondary}>
              <CountBadge $color={theme.colors.redSecondary}>
                {formatNumber(loansInDefault.ninetyPlusDays.loanCount)}
              </CountBadge>
            </DelinquencyValue>
          </Pair>
          <DelinquencySummary summary={loansInDefault.ninetyPlusDays} />
          <DelinquencyProgressBar>
            <Bar $color={theme.colors.statusBlack} $variant={$variant} />
            <Bar $color={theme.colors.statusBlack} $variant={$variant} />
            <LargeBar $color={theme.colors.redSecondary} $variant={$variant} />
          </DelinquencyProgressBar>
        </SummarySection>
      )}
    </Summary>
  );
};
