import React from 'react';

export const DashboardLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6667 10V4H20V10H12.6667ZM4 12.6667V4H11.3333V12.6667H4ZM12.6667 20V11.3333H20V20H12.6667ZM4 20V14H11.3333V20H4ZM5.33333 11.3333H10V5.33333H5.33333V11.3333ZM14 18.6667H18.6667V12.6667H14V18.6667ZM14 8.66667H18.6667V5.33333H14V8.66667ZM5.33333 18.6667H10V15.3333H5.33333V18.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};
