import { InsuranceType, Loan } from '@plus-platform/shared';
import React from 'react';

import { DelinquencyStatus } from '../components/DelinquencyStatus';
import { Divider } from '../components/Divider';
import { Key, Pair, Value } from '../components/KeyValue';
import { EMPTY_VALUE, formatMonetary, formatPercentage } from '../utils/formatUtils';
import * as Styles from './LoanBreadcrumbSummary.styles';

export const SUMMARY_MAP_PATH = 'map';

type LoanBreadcrumbSummaryProps = {
  loan?: Loan;
};

export const LoanBreadcrumbSummary = ({ loan }: LoanBreadcrumbSummaryProps) => {
  const floodInsurer = loan?.loanInsurers.find(
    (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_FLOOD
  );

  return (
    <Styles.SummaryWrapper>
      <Pair $align="left">
        <Key>Current UPB</Key>
        <Value>{formatMonetary(loan?.financials?.currentUPB)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left">
        <Key>Current LTV</Key>
        <Value>{formatPercentage(loan?.financials?.currentLTV)}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left">
        <Key>Flood zone</Key>
        {/* TODO: add flood zone on flood insurer structure */}
        <Value>{EMPTY_VALUE}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left">
        <Key>Flood insurer</Key>
        <Value>{floodInsurer || EMPTY_VALUE}</Value>
      </Pair>
      <Divider $orientation="vertical" $flexItem />
      <Pair $align="left">
        <Key>Status</Key>
        <Value>
          {loan ? (
            <DelinquencyStatus isDelinquent={loan.delinquencyStatus.isDelinquent} variant="body" />
          ) : (
            EMPTY_VALUE
          )}
        </Value>
      </Pair>
    </Styles.SummaryWrapper>
  );
};
