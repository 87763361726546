import React from 'react';

export const PoolFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.8C14.651 16.8 16.8 14.651 16.8 12C16.8 9.34903 14.651 7.2 12 7.2C9.34903 7.2 7.2 9.34903 7.2 12C7.2 14.651 9.34903 16.8 12 16.8ZM9.35911 12.0155C9.35911 13.4778 10.5887 14.7157 12.0503 14.7157C13.5273 14.7157 14.7492 13.4855 14.7492 12.0155C14.7492 10.5455 13.5196 9.30754 12.0503 9.30754C10.581 9.30754 9.35911 10.5377 9.35911 12.0155Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12C20 16.3791 16.3809 20 11.9961 20C7.61914 20 4 16.3791 4 12C4 7.62089 7.61141 4 11.9884 4C16.3731 4 20 7.62089 20 12ZM5.60851 12C5.60851 15.5513 8.44659 18.4062 11.9961 18.4062C15.5457 18.4062 18.407 15.5513 18.3992 12C18.3915 8.44874 15.5379 5.60155 11.9884 5.60155C8.44659 5.60155 5.60851 8.44874 5.60851 12Z"
        fill="currentColor"
      />
    </svg>
  );
};
