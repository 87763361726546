import { rem } from 'polished';
import { css } from 'styled-components';

export const fontFamily = {
  heading: 'Roboto',
  text: 'Yantramanav',
  display: 'Montserrat', // TODO: change to Yantramanav?
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const fontSize = {
  heading01: 18,
  heading02: 16,
  heading03: 14,
  heading04: 12,
  body01: 16,
  body02: 14,
  body03: 12,
  body04: 10,
};

export const lineHeight = {
  heading01: 24,
  heading02: 24,
  heading03: 24,
  heading03Tight: 16,
  heading04: 16,
  body01: 24,
  body02: 24,
  body02Tight: 16,
  body03: 24,
  body03Tight: 16,
  body04: 16,
};

export const headings = {
  headingRegular01: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.heading01)};
    line-height: ${rem(lineHeight.heading01)};
  `,
  headingRegular02: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.heading02)};
    line-height: ${rem(lineHeight.heading02)};
  `,
  headingRegular03: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.heading03)};
    line-height: ${rem(lineHeight.heading03)};
  `,
  headingRegular03Tight: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.heading03)};
    line-height: ${rem(lineHeight.heading03Tight)};
  `,
  headingRegular04: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.heading04)};
    line-height: ${rem(lineHeight.heading04)};
  `,
  headingMedium01: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.heading01)};
    line-height: ${rem(lineHeight.heading01)};
  `,
  headingMedium02: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.heading02)};
    line-height: ${rem(lineHeight.heading02)};
  `,
  headingMedium03: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.heading03)};
    line-height: ${rem(lineHeight.heading03)};
  `,
  headingMedium03Tight: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.heading03)};
    line-height: ${rem(lineHeight.heading03Tight)};
  `,
  headingMedium04: css`
    font-family: ${fontFamily.heading};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.heading04)};
    line-height: ${rem(lineHeight.heading04)};
  `,
};

export const bodyText = {
  bodyRegular01: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.body01)};
    line-height: ${rem(lineHeight.body01)};
  `,
  bodyRegular02: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.body02)};
    line-height: ${rem(lineHeight.body02)};
  `,
  bodyRegular02Tight: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.body02)};
    line-height: ${rem(lineHeight.body02Tight)};
  `,
  bodyRegular03: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.body03)};
    line-height: ${rem(lineHeight.body03)};
  `,
  bodyRegular03Tight: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.body03)};
    line-height: ${rem(lineHeight.body03Tight)};
  `,
  bodyRegular04: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.regular};
    font-size: ${rem(fontSize.body04)};
    line-height: ${rem(lineHeight.body04)};
  `,
  bodyMedium01: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.body01)};
    line-height: ${rem(lineHeight.body01)};
  `,
  bodyMedium02: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.body02)};
    line-height: ${rem(lineHeight.body02)};
  `,
  bodyMedium02Tight: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.body02)};
    line-height: ${rem(lineHeight.body02Tight)};
  `,
  bodyMedium03: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.body03)};
    line-height: ${rem(lineHeight.body03)};
  `,
  bodyMedium03Tight: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.body03)};
    line-height: ${rem(lineHeight.body03Tight)};
  `,
  bodyMedium04: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.medium};
    font-size: ${rem(fontSize.body04)};
    line-height: ${rem(lineHeight.body04)};
  `,
  bodyBold01: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.bold};
    font-size: ${rem(fontSize.body01)};
    line-height: ${rem(lineHeight.body01)};
  `,
  bodyBold02: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.bold};
    font-size: ${rem(fontSize.body02)};
    line-height: ${rem(lineHeight.body02)};
  `,
  bodyBold02Tight: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.bold};
    font-size: ${rem(fontSize.body02)};
    line-height: ${rem(lineHeight.body02Tight)};
  `,
  bodyBold03: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.bold};
    font-size: ${rem(fontSize.body03)};
    line-height: ${rem(lineHeight.body03)};
  `,
  bodyBold03Tight: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.bold};
    font-size: ${rem(fontSize.body03)};
    line-height: ${rem(lineHeight.body03Tight)};
  `,
  bodyBold04: css`
    font-family: ${fontFamily.text};
    font-weight: ${fontWeight.bold};
    font-size: ${rem(fontSize.body04)};
    line-height: ${rem(lineHeight.body04)};
  `,
};
