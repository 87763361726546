import {
  SubmissionAttributes,
  SubmissionErrorAttributes,
  SubmissionErrorOrigin,
} from '@plus-platform/shared';
import groupBy from 'lodash/groupBy';

import { PDFOutlineIcon, PlusIcon } from '../components/icons';
import { formatDefaultValue } from '../utils/formatUtils';

type DocumentFileTypeProperties = {
  shortName: string;
  icon: React.FC;
};

const DEFAULT_DOCUMENT_FILE_TYPE_PROPERTIES: DocumentFileTypeProperties = {
  shortName: 'File',
  icon: PlusIcon,
};

// TODO - expand this list - this is just core documents for now
const DOCUMENT_FILE_TYPE_MAPPING: Record<string, DocumentFileTypeProperties> = {
  'application/pdf': {
    shortName: 'PDF',
    icon: PDFOutlineIcon,
  },
  'application/msword': {
    shortName: 'Word',
    icon: PlusIcon,
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    shortName: 'Word OpenXML',
    icon: PlusIcon,
  },
  'application/vnd.ms-excel': {
    shortName: 'Excel',
    icon: PlusIcon,
  },
  'application/vnd.oasis.opendocument.text': {
    shortName: 'Open Document',
    icon: PlusIcon,
  },
  'application/rtf': {
    shortName: 'RTF',
    icon: PlusIcon,
  },
};

const DOCUMENT_TYPE_MAPPING: Record<string, string> = {
  '1004': '1004',
  '1005': '1005',
  '1003_2009': '1003 (2009)',
  '1003_2020': '1003 (2020)',
  W2: 'W2',
  A_1099_MISC: '1099 misc',
  CLOSING_DISCLOSURE: 'closing disclosure',
  LOAN_ESTIMATE: 'loan estimate',
};

const STATUS_MAPPING: Record<string, string> = {
  VERIFICATION_COMPLETE: 'Verification Completed',
  REJECTED: 'Rejected',
};

export const getDocumentTypeLabel = (type: string) => {
  return DOCUMENT_TYPE_MAPPING[type] ?? type;
};

export const getDocumentStatusLabel = (status: string) => {
  return STATUS_MAPPING[status] ?? status;
};

export enum GroupedOriginName {
  OCR = 'OCR',
  SMD = 'SMD',
  CUSTOM = 'PLUS',
}

export const getDocumentType = (documentType?: string): string => {
  const defaultDocumentType = formatDefaultValue(documentType);
  return DOCUMENT_TYPE_MAPPING[defaultDocumentType] ?? defaultDocumentType;
};

export type GroupedValidationErrorsType = {
  [GroupedOriginName.OCR]: SubmissionErrorAttributes[];
  [GroupedOriginName.SMD]: SubmissionErrorAttributes[];
  [GroupedOriginName.CUSTOM]: SubmissionErrorAttributes[];
};

export const groupValidationErrors = (
  validationErrors: SubmissionErrorAttributes[]
): GroupedValidationErrorsType => {
  return validationErrors.reduce<GroupedValidationErrorsType>(
    (acc, error) => {
      if (error.origin === SubmissionErrorOrigin.OCR) {
        acc[GroupedOriginName.OCR].push(error);
      } else if ([SubmissionErrorOrigin.MISMO, SubmissionErrorOrigin.ULDD].includes(error.origin)) {
        acc[GroupedOriginName.SMD].push(error);
      } else if (error.origin != SubmissionErrorOrigin.SUBMISSION) {
        acc[GroupedOriginName.CUSTOM].push(error);
      }
      return acc;
    },
    {
      [GroupedOriginName.OCR]: [],
      [GroupedOriginName.SMD]: [],
      [GroupedOriginName.CUSTOM]: [],
    }
  );
};

export const groupValidationByOcrolusUuid = (
  groupedValidation: GroupedValidationErrorsType
): {
  [GroupedOriginName.OCR]: { [key: string]: SubmissionErrorAttributes[] };
  [GroupedOriginName.SMD]: { [key: string]: SubmissionErrorAttributes[] };
  [GroupedOriginName.CUSTOM]: { [key: string]: SubmissionErrorAttributes[] };
} => {
  return {
    [GroupedOriginName.OCR]: groupBy(groupedValidation[GroupedOriginName.OCR], 'ocrolusUuid'),
    [GroupedOriginName.SMD]: groupBy(groupedValidation[GroupedOriginName.SMD], 'ocrolusUuid'),
    [GroupedOriginName.CUSTOM]: groupBy(groupedValidation[GroupedOriginName.CUSTOM], 'ocrolusUuid'),
  };
};

export const getErrorCount = (errorList?: SubmissionErrorAttributes[]) => errorList?.length ?? 0;

// NOTE Ocrolus adds the page numbers to the doc name, which we are removing here
export const formatDocumentName = (name: string) => name.replace(/\(.*\)/, '');

export const getDocumentFileTypeProperties = (
  documentFileType?: string
): DocumentFileTypeProperties =>
  documentFileType && Object.keys(DOCUMENT_FILE_TYPE_MAPPING).includes(documentFileType)
    ? DOCUMENT_FILE_TYPE_MAPPING[documentFileType]
    : DEFAULT_DOCUMENT_FILE_TYPE_PROPERTIES;

export const getFilesProcessedOrErrored = (submission?: SubmissionAttributes) => {
  if (!submission) {
    return 0;
  }
  return (submission.filesProcessed ?? 0) + (submission.filesErrored ?? 0);
};

export const getIsSubmissionComplete = (submission?: SubmissionAttributes) => {
  if (!submission) {
    return false;
  }
  return getFilesProcessedOrErrored(submission) === submission.filesReceived;
};
