import {
  DOCUMENT_CATEGORY_MAPPING,
  DocumentType,
  EncompassDocumentType,
  EscrowDisbursementReportDocumentType,
  Form1003DocumentType,
  FormLoanEstimateDocumentType,
  FormW8BENDocumentType,
  FormW9DocumentType,
  OptionalDocumentType,
  PoolSaleReportDocumentType,
  PropertyPreservationReportDocumentType,
  RequiredDocumentType,
} from '@plus-platform/shared';
import uniqueId from 'lodash/uniqueId';
import { ellipsis } from 'polished';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components/macro';

import { Button, DefaultButton } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { CheckCircleIcon, CloseLineIcon, IngestionLineIcon } from '../components/icons';
import { Popup, PopupContent, PopupFooter, PopupHeader } from '../components/Popup';
import { Select } from '../components/Select';
import { Stack } from '../components/Stack';
import { useAddLoanDocumentMutation } from '../hooks/queries/useAddLoanDocumentMutation';

const PopupComponent = styled(Popup)`
  width: 100%;
  max-width: 800px;
`;

const DropZone = styled.div`
  display: flex;
  height: 300px;
  border-radius: 4px;
`;

const NoDocuments = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Documents = styled.ul`
  display: grid;
  flex: 1;
  align-content: flex-start;
  overflow-y: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  margin: 0;
  padding: 10px;
  list-style: none;
`;

const Document = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
`;

const DocumentTitle = styled.h6`
  margin: 5px 0;
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: 12px;

  ${ellipsis()}
`;

const DocumentRemoveButton = styled(DefaultButton)`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    padding: 0;
    height: 18px;
    width: 18px;
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.white};
    border-radius: 50%;
  `}
`;

const DocumentTypeSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const UploadSuccessMessage = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-family: ${theme.typography.fontFamily.display};
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.greenLight};
    column-gap: 5px;
    margin: 13px 0;
  `}
`;

const DOCUMENT_TYPE_LABELS_MAP: Record<DocumentType, string> = {
  [Form1003DocumentType.FANNIE_MAE_FORM_1003_LEGACY]: 'Fannie Mae Legacy 1003 Form',
  [RequiredDocumentType.Form1003]: 'Fannie Mae 1003 Form',
  [RequiredDocumentType.Form1004]: 'Fannie Mae 1004 Form',
  [RequiredDocumentType.Form1005]: 'Fannie Mae 1005 Form',
  [RequiredDocumentType.Form1099]: 'Fannie Mae 1099 Form',
  [RequiredDocumentType.W2]: 'IRS Form W-2',
  [OptionalDocumentType.Form1008]: 'Fannie Mae 1008 Form',
  [EncompassDocumentType.ENCOMPASS_DOCUMENT_TYPE]: 'Encompass Document',
  // TODO: Add Forms 181 and note here
  [FormW8BENDocumentType.IRS_FORM_W8BEN]: 'IRS Form W-8BEN',
  [FormW9DocumentType.IRS_FORM_W9]: 'IRS Form W-9',
  [FormLoanEstimateDocumentType.CFPB_FORM_LOAN_ESTIMATE]: 'CFPB Loan Estimate Form',
  [PropertyPreservationReportDocumentType.STANDARD_PROPERTY_PRESERVATION_REPORT]:
    'Standard Property Preservation Report',
  [EscrowDisbursementReportDocumentType.STANDARD_ESCROW_DISBURSEMENT_REPORT]:
    'Standard Escrow Disbursement Report',
  [PoolSaleReportDocumentType.STANDARD_POOL_SALE_REPORT]: 'Standard Pool Sale Report',
};

const documentTypeOptions = Object.values(DOCUMENT_CATEGORY_MAPPING)
  .flat()
  .map((type) => ({
    label: DOCUMENT_TYPE_LABELS_MAP[type] ?? '',
    value: type,
  }));

const buildNewDocument = (file: File) => {
  return {
    file,
    id: uniqueId(),
    isUploaded: false,
  };
};

type AddedDocument = {
  id: string;
  file: File;
  type?: DocumentType;
  isUploaded: boolean;
};

type AddDocumentsPopupProps = {
  loanNumber: string;
  onClose: () => void;
};

export const AddDocumentsPopup = ({ loanNumber, onClose }: AddDocumentsPopupProps) => {
  const [documents, setDocuments] = React.useState<AddedDocument[]>([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const { mutateAsync: addLoanDocument } = useAddLoanDocumentMutation();

  const hasSelectedDocuments = Boolean(documents.length);
  const isValid = hasSelectedDocuments && documents.every((document) => document.type);

  const { getInputProps, getRootProps, open } = useDropzone({
    noClick: true,
    accept: 'application/pdf',
    disabled: isUploading,
    onDropAccepted: (files) => {
      const acceptedDocuments = files.map(buildNewDocument);

      setDocuments((documents) => [...documents, ...acceptedDocuments]);
    },
  });

  const markDocumentAsUploaded = (id: string) => {
    setDocuments((documents) =>
      documents.map((document) => {
        if (document.id === id) {
          return { ...document, isUploaded: true };
        }

        return document;
      })
    );
  };

  const uploadFiles = async () => {
    try {
      setIsUploading(true);

      for (const document of documents as Required<AddedDocument>[]) {
        await addLoanDocument({
          loanNumber,
          file: document.file,
          type: document.type,
        });

        markDocumentAsUploaded(document.id);
      }

      onClose();
    } finally {
      setIsUploading(false);
    }
  };

  const setDocumentType = (id: string, type: DocumentType) => {
    setDocuments((documents) =>
      documents.map((document) => {
        if (document.id === id) {
          return { ...document, type };
        }

        return document;
      })
    );
  };

  const removeDocument = (id: string) => {
    setDocuments((documents) => documents.filter((document) => document.id !== id));
  };

  return (
    <PopupComponent>
      <PopupHeader title="Add documents" />
      <PopupContent>
        <DropZone {...getRootProps()}>
          <input {...getInputProps()} />

          {hasSelectedDocuments && (
            <Documents>
              {documents.map((document, index) => {
                const target = `modal-select-target-${index}`;

                return (
                  <Document key={document.id}>
                    <IngestionLineIcon width={64} height={64} />
                    <DocumentTitle>{document.file.name}</DocumentTitle>

                    {document.isUploaded && (
                      <UploadSuccessMessage>
                        <CheckCircleIcon width={16} height={16} />
                        Successfully added
                      </UploadSuccessMessage>
                    )}

                    {!document.isUploaded && (
                      <DocumentTypeSelectWrapper id={target}>
                        <Select
                          disabled={isUploading}
                          onChange={(value) => {
                            setDocumentType(document.id, value as DocumentType);
                          }}
                          options={documentTypeOptions}
                          value={document.type}
                          menuPortalTarget={target}
                          styles={{
                            control: {
                              alignItems: 'center',
                            },
                            menuPortal: {
                              top: '36px',
                              left: 0,
                              right: 0,
                              width: '100%',
                            },
                          }}
                        />
                      </DocumentTypeSelectWrapper>
                    )}

                    {!isUploading && !document.isUploaded && (
                      <DocumentRemoveButton>
                        <CloseLineIcon
                          onClick={() => removeDocument(document.id)}
                          width={14}
                          height={14}
                        />
                      </DocumentRemoveButton>
                    )}
                  </Document>
                );
              })}
            </Documents>
          )}

          {!hasSelectedDocuments && (
            <NoDocuments>
              <p>Drag 'n' drop some files here, or click the button below to select files</p>
              <Button onClick={open}>Select documents</Button>
            </NoDocuments>
          )}
        </DropZone>
      </PopupContent>
      <PopupFooter>
        <Stack>
          <Button $color="tertiary" $variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <ButtonLoader disabled={!isValid} isLoading={isUploading} onClick={uploadFiles}>
            Add
          </ButtonLoader>
        </Stack>
      </PopupFooter>
    </PopupComponent>
  );
};
