import { Item, ItemCount, ItemTitle, VariantType, Wrapper } from './FlagsAlertsWarnings.styles';
import { AlertIcon, FlagIcon, WarningIcon } from './icons';

export type FlagsAlertsWarningsProps = {
  variant?: VariantType;
  flagsCount: number;
  alertsCount: number;
  warningsCount: number;
};

export type FlagType = 'flag' | 'alert' | 'warning';

export const FlagsAlertsWarnings = ({
  alertsCount,
  flagsCount,
  variant = 'verbose',
  warningsCount,
}: FlagsAlertsWarningsProps) => {
  return (
    <>
      {(Boolean(alertsCount) ||
        Boolean(flagsCount) ||
        Boolean(warningsCount) ||
        variant === 'verbose') && (
        <Wrapper $variant={variant}>
          {(Boolean(flagsCount) || variant === 'verbose') && (
            <Item $variant={variant} $flagType="flag" $noItems={!flagsCount}>
              <FlagIcon width="16px" />
              {variant === 'verbose' && <ItemTitle>Flags</ItemTitle>}
              <ItemCount>{flagsCount | 0}</ItemCount>
            </Item>
          )}
          {(Boolean(alertsCount) || variant === 'verbose') && (
            <Item $variant={variant} $flagType="alert" $noItems={!alertsCount}>
              <AlertIcon width="16px" />
              {variant === 'verbose' && <ItemTitle>Alerts</ItemTitle>}
              <ItemCount>{alertsCount | 0}</ItemCount>
            </Item>
          )}
          {(Boolean(warningsCount) || variant === 'verbose') && (
            <Item $variant={variant} $flagType="warning" $noItems={!warningsCount}>
              <WarningIcon width="16px" />
              {variant === 'verbose' && <ItemTitle>Warnings</ItemTitle>}
              <ItemCount>{warningsCount | 0}</ItemCount>
            </Item>
          )}
        </Wrapper>
      )}
    </>
  );
};
