import { ALL_LOANS_ATTRIBUTES, AttributeType, PermissionType } from '@plus-platform/shared';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import styled, { ThemeProvider } from 'styled-components/macro';

import { AdminDashboardPage } from '../admin/AdminDashboardPage';
import { AdminRouteType } from '../admin/types';
import { QueryErrorBoundary } from '../components/QueryErrorBoundary';
import { LayoutProvider } from '../contexts/LayoutContext';
import { MapProvider } from '../contexts/MapContext';
import { SocketProvider } from '../contexts/SocketContext';
import { UserProvider, useUserContext } from '../contexts/UserContext';
import { PrivateRoute } from '../global/PrivateRoute';
import { IngestionPage } from '../ingestion/IngestionPage';
import { LoanPage } from '../loans/LoanPage';
import { PoolDetailPage } from '../pools/PoolDetailPage';
import { PoolsPage } from '../pools/PoolsPage';
import { AnalyticsPage } from '../portfolio/analytics/AnalyticsPage';
import { HomeAnalyticPage } from '../portfolio/analytics/HomeAnalyticPage';
import { LoanTypesByDefaultDetailPage } from '../portfolio/Breakdown/LoanTypesByPage/LoanTypesByDefaultDetailPage';
import { LoanTypesByDefaultPage } from '../portfolio/Breakdown/LoanTypesByPage/LoanTypesByDefaultPage';
import { LoanTypesByNoteRateDetailPage } from '../portfolio/Breakdown/LoanTypesByPage/LoanTypesByNoteRateDetailPage';
import { LoanTypesByNoteRatePage } from '../portfolio/Breakdown/LoanTypesByPage/LoanTypesByNoteRatePage';
import { ImportPage } from '../portfolio/Import/ImportPage';
import { InvestorHomePage } from '../portfolio/InvestorHomePage';
import { OriginatorHomePage } from '../portfolio/OriginatorHomePage';
import { PortfolioPage } from '../portfolio/PortfolioPage';
import { GlobalStyles } from '../styles/globalStyles';
import { darkTheme, gridTheme } from '../styles/theme';
import { TradeRequestsPage } from '../trades/TradeRequestsPage';
import { ProfilePage } from '../users/ProfilePage';
import { isInvestor, isOriginator } from '../utils/userUtils';
import { CreateAccount } from './createAccount/CreateAccount';
import { Login } from './Login';
import { DarkPage } from './ThemedPage';

const AppWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100vh;

  /* Dirty hack so that the "main" content (e.g. the route)
  will always grow to fill the space. Really there should
  be a consistent "Page" component around each route */
  > *:last-child {
    flex-grow: 1;
    overflow: auto;
  }
`;

const NotFoundText = styled.h1`
  padding: 80px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 32px;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // This is disabled for now to overcome a problem with uploading files.
      // When browser opens OS file picker, the focus goes to it from the
      // browser window, and when user picks a file, the focus goes back to the
      // browser window, which causes react-query to re-fetch data on the screen.
      // Which triggers loading state, which then replaces component instances
      // with loader components, so the original handlers on the file input
      // never actually called.
      // Maybe in the future we could display loaders only on `isLoading` state
      // from react-query, and have a different UI for background fetches.
      refetchOnWindowFocus: false,
    },
  },
});

const NotFoundMessage = () => (
  <MainWrapper>
    <NotFoundText>Error 404 - Page Not Found</NotFoundText>
  </MainWrapper>
);

const Main = () => {
  const { isUserDataLoading, userProfile } = useUserContext();

  return (
    <React.Fragment>
      {/* TODO: Loading component here */}
      {!isUserDataLoading && (
        <Switch>
          {!userProfile && <Redirect from="/" to="/login" />}
          {userProfile && <Redirect exact from="/" to="home" />}

          {isInvestor(userProfile) && (
            <PrivateRoute path="/home">
              {({ match }) => (
                <DarkPage>
                  <Switch>
                    <PrivateRoute
                      component={HomeAnalyticPage}
                      path={`${match?.path}/analytics/:analyticId`}
                    />
                    <PrivateRoute
                      component={LoanTypesByNoteRatePage}
                      path={`${match?.path}/breakdown/types/rates`}
                    />
                    <PrivateRoute
                      component={LoanTypesByNoteRateDetailPage}
                      path={`${match?.path}/breakdown/types/:rateType/rates/:noteRate`}
                    />
                    <PrivateRoute
                      component={LoanTypesByDefaultPage}
                      path={`${match?.path}/breakdown/delinquent/types/rates`}
                    />
                    <PrivateRoute
                      component={LoanTypesByDefaultDetailPage}
                      path={`${match?.path}/breakdown/delinquent/:delinquencyType/types/:rateType/rates/:noteRate`}
                    />
                    <PrivateRoute component={InvestorHomePage} path={match?.path} />
                  </Switch>
                </DarkPage>
              )}
            </PrivateRoute>
          )}

          {isOriginator(userProfile) && (
            <PrivateRoute
              component={() => (
                <DarkPage>
                  <OriginatorHomePage />
                </DarkPage>
              )}
              path="/home"
            />
          )}

          <PrivateRoute
            component={() => (
              <DarkPage>
                <PortfolioPage />
              </DarkPage>
            )}
            path="/portfolio"
          />
          <PrivateRoute
            component={() => (
              <DarkPage>
                <LoanPage />
              </DarkPage>
            )}
            path="/loan/:loanNumber"
          />
          <PrivateRoute
            component={() => (
              <DarkPage>
                <PoolsPage />
              </DarkPage>
            )}
            path="/pools"
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_POOL],
            }}
          />
          <PrivateRoute
            component={() => (
              <DarkPage>
                <PoolDetailPage />
              </DarkPage>
            )}
            path="/pool/:poolId"
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_POOL],
            }}
          />
          <PrivateRoute
            component={() => (
              <DarkPage>
                <TradeRequestsPage />
              </DarkPage>
            )}
            path="/trade-requests"
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_TRADE_REQUEST],
            }}
          />
          <PrivateRoute
            component={() => {
              return (
                <DarkPage>
                  <IngestionPage />
                </DarkPage>
              );
            }}
            path="/documents"
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_DOCUMENT],
            }}
          />
          <PrivateRoute
            component={() => (
              <DarkPage>
                <AnalyticsPage />
              </DarkPage>
            )}
            path="/analytics"
          />

          <PrivateRoute
            component={() => (
              <DarkPage>
                <ProfilePage />
              </DarkPage>
            )}
            path="/profile"
          />
          <PrivateRoute
            component={AdminDashboardPage}
            path={AdminRouteType.Dashboard}
            requiredPermissions={{
              [PermissionType.PERMISSION_READ]: [AttributeType.ATTRIBUTE_ORGANIZATION],
            }}
          />

          <PrivateRoute
            component={() => (
              <DarkPage>
                <ImportPage />
              </DarkPage>
            )}
            path="/import"
            requiredPermissions={{
              [PermissionType.PERMISSION_CREATE]: ALL_LOANS_ATTRIBUTES,
            }}
          />
          <Route
            component={() => (
              <DarkPage>
                <NotFoundMessage />
              </DarkPage>
            )}
          />
        </Switch>
      )}
    </React.Fragment>
  );
};

export const App = () => (
  <AppWrapper>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={darkTheme}>
        <GlobalStyles />
        <GridThemeProvider gridTheme={gridTheme}>
          <Router>
            <MainWrapper>
              <QueryErrorBoundary message="There was an application problem.">
                <Switch>
                  <Route component={Login} path="/login" />
                  <Route component={CreateAccount} path="/create-account" />
                  <UserProvider>
                    <SocketProvider>
                      <MapProvider>
                        <LayoutProvider>
                          <QueryErrorBoundary message="Oops that request didn't work">
                            <Main />
                          </QueryErrorBoundary>
                        </LayoutProvider>
                      </MapProvider>
                    </SocketProvider>
                  </UserProvider>
                </Switch>
              </QueryErrorBoundary>
            </MainWrapper>
          </Router>
        </GridThemeProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </AppWrapper>
);
