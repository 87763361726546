import {
  InboundTradeCounterpartyActionWithSummary,
  InboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type InboundExpressionOfInterestDeclinedProps = {
  action: InboundTradeCounterpartyActionWithSummary;
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundExpressionOfInterestDeclined = ({
  action,
  tradeRequest,
}: InboundExpressionOfInterestDeclinedProps) => {
  const { createdAt, reason } = action;

  const userName = formatNameWithInitial(tradeRequest.user.lastName, tradeRequest.user.firstName);
  const organizationName = tradeRequest.user.organization.tradingName;

  return (
    <PlusMessage date={createdAt}>
      <MessageText>
        {reason ? (
          <TradeMessage
            id="Trade.Inbound.ExpressionOfInterest.DeclinedWithReason"
            values={{ userName, organizationName, reason }}
          />
        ) : (
          <TradeMessage
            id="Trade.Inbound.ExpressionOfInterest.DeclinedWithoutReason"
            values={{ userName, organizationName }}
          />
        )}
      </MessageText>
    </PlusMessage>
  );
};
