import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components/macro';

import { PlusIcon } from '../../components/icons/PlusIcon';
import { useResetDataMutation } from '../../hooks/queries/useResetDataQuery';
import {
  Content,
  ModalButton,
  StyledDialog,
  StyledDialogOverlay,
  StyledPanel,
  StyledTitle,
} from '../AdminModal/AdminModal';
import { ResetDataForm, SectionDescription, SectionTitle } from './ResetDataForm';

const SubmitError = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.redMain};
    font-size: 14px;
    line-height: 18px;
    padding: 0 0 16px;
  `}
`;

type InviteUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormData = {
  confirm: string;
};

export const ResetDataModal = ({ isOpen, onClose }: InviteUserModalProps) => {
  const theme = useTheme();
  const { error, isError, mutateAsync: resetData } = useResetDataMutation();
  const formMethods = useForm<FormData>();
  const { formState, reset } = formMethods;
  const { isSubmitSuccessful, isSubmitting } = formState;

  useEffect(() => {
    if (isError) {
      reset(undefined, { keepValues: true });
    }
  }, [isError]);

  const submitDataReset = async () => {
    await resetData();
  };

  const resetModal = () => {
    formMethods.reset();
    onClose();
  };

  return (
    <StyledDialog open={isOpen} static onClose={() => null}>
      <StyledDialogOverlay>
        <StyledPanel>
          <StyledTitle $bgColor={isSubmitSuccessful ? theme.colors.greenMain : undefined}>
            <PlusIcon color={theme.colors.white} height={20} width={23} />
            {!isError ? (
              <React.Fragment>Reset all data</React.Fragment>
            ) : (
              <React.Fragment>Error</React.Fragment>
            )}
          </StyledTitle>
          <Content>
            {isSubmitSuccessful ? (
              <>
                <SectionTitle>Data Reset Complete</SectionTitle>
                <SectionDescription>
                  All user entered data has been deleted and values returned to defaults.
                </SectionDescription>
                <ModalButton type="button" onClick={onClose}>
                  OK
                </ModalButton>
              </>
            ) : (
              <React.Fragment>
                {isError && (
                  <SubmitError>
                    <SectionTitle>Data could not be reset</SectionTitle>
                    <SectionDescription>
                      The data reset could not be completed. You can try again, but you should check
                      the application to verify the state of the data, and contact the engineering
                      team if further issues occur.
                    </SectionDescription>
                    <ModalButton type="button" onClick={onClose}>
                      OK
                    </ModalButton>
                  </SubmitError>
                )}

                {!isError && (
                  // TODO: Re-evalute once/if we update our TS version. Currently there is an issue with react-hook-form as TS 4.4.4
                  // https://github.com/react-hook-form/react-hook-form/issues/6679
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <FormProvider {...formMethods}>
                    <ResetDataForm
                      onSubmit={submitDataReset}
                      onClose={resetModal}
                      submitError={isError}
                      isBusy={isSubmitting}
                    />
                  </FormProvider>
                )}
              </React.Fragment>
            )}
          </Content>
        </StyledPanel>
      </StyledDialogOverlay>
    </StyledDialog>
  );
};
