import styled, { css } from 'styled-components/macro';

import { HeadingLink } from '../../components/HeadingLink';

export const Emphasis = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
`;

export const AnalyticWidgetLink = styled(HeadingLink)<{ $isActive?: boolean }>`
  ${({ $isActive, theme }) => css`
    display: block;

    ${$isActive &&
    `
      background-color: ${theme.colors.backgroundPageSection};
    `}

    &:hover {
      color: ${theme.colors.white};

      ${Emphasis} {
        color: ${theme.colors.anchorColorHover};
      }
    }
  `}
`;

const ToggleIcon = styled.span`
  position: absolute;
  top: 1px;
  pointer-events: none;
`;

export const ToggleLeftIcon = styled(ToggleIcon)`
  left: 5px;
`;

export const ToggleRightIcon = styled(ToggleIcon)`
  right: 3px;
`;

export const ToggleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: 8px;

    label {
      display: flex;
      align-items: center;
      position: relative;
    }

    input {
      &::before {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-color: ${theme.colors.white};
      }

      & + ${ToggleLeftIcon} {
        color: ${theme.colors.blue};
      }

      &:hover {
        &::before {
          background-color: ${theme.colors.white};
        }
      }

      &:checked {
        & + ${ToggleLeftIcon} {
          color: ${theme.colors.white};
        }

        & + ${ToggleLeftIcon} + ${ToggleRightIcon} {
          color: ${theme.colors.blue};
        }

        &:hover::before {
          background-color: ${theme.colors.white};
        }
      }
    }
  `}
`;

export const Menus = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
