import { PortfolioSummary } from '@plus-platform/shared';
import React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import { Skeleton } from '../components/Skeleton';
import { DelinquencyWidget } from './DeprecatedDelinquencyWidget';

export const DetailedWrapper = styled.div<{
  $hideLoansInDefault?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $hideLoansInDefault }) =>
    $hideLoansInDefault ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)'};
  column-gap: 6px;
`;

type LoanSummaryProps = {
  summary?: PortfolioSummary;
  showDetailSummary?: boolean;
};

type LoanDetailedSummaryProps = LoanSummaryProps & {
  sectionWidth?: string;
  style?: React.CSSProperties;
  variant?: 'loan' | 'pool';
  hideLoansInDefault?: boolean;
};

export const LoanDetailedSummary = ({
  hideLoansInDefault,
  style,
  summary,
  variant,
}: LoanDetailedSummaryProps) => {
  const theme = useTheme();

  if (!summary) {
    return (
      <Skeleton
        background={variant === 'pool' ? theme.colors.greenTreeLight : undefined}
        height={214}
      />
    );
  }
  return (
    <DetailedWrapper $hideLoansInDefault={hideLoansInDefault} style={style}>
      <DelinquencyWidget
        summary={summary}
        variant={variant}
        hideLoansInDefault={hideLoansInDefault}
      />
    </DetailedWrapper>
  );
};
