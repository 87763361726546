// TODO: Replace with MISMO's ForeclosureMethodType
export enum ForeclosureMethodType {
  Judicial = 'Judicial',
  NonJudicial = 'NonJudicial',
  Other = 'Other',
}

export type ForeclosureCreation = {
  isInForeclosure: boolean;
  foreclosureMethodType?: ForeclosureMethodType;
};
