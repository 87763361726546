import { MISMOAmount, MISMOEntity } from './types';

export enum integratedDisclosureSubsectionPaymentTimingType {
  AtClosing = 'AtClosing',
  BeforeClosing = 'BeforeClosing',
}

export enum IntegratedDisclosureSubsectionPaidByType {
  Buyer = 'Buyer',
  Seller = 'Seller',
  ThirdParty = 'ThirdParty',
}

export type IntegratedDisclosureSubsectionPayment = MISMOEntity &
  Partial<{
    integratedDisclosureSubsectionPaidByType: IntegratedDisclosureSubsectionPaidByType;
    integratedDisclosureSubsectionPaymentAmount: MISMOAmount;
    integratedDisclosureSubsectionPaymentTimingType: integratedDisclosureSubsectionPaymentTimingType;
  }>;
