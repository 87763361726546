import { TradeConfirmationSummary, TradeRequest } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestQuoteConfirmationSummaryQuery = {
  tradeRequestId: TradeRequest['id'];
  quoteId: string;
};

const getTradeRequestQuoteConfirmationSummary = (
  query: TradeRequestQuoteConfirmationSummaryQuery
) => {
  const { quoteId, tradeRequestId } = query;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/quotes/${quoteId}/confirmation-summary`);

  return getRequestWithAuth<TradeConfirmationSummary>(url);
};

export const useTradeRequestQuoteConfirmationSummary = (
  query: TradeRequestQuoteConfirmationSummaryQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_QUOTE_CONFIRMATION_SUMMARY, query], () =>
    getTradeRequestQuoteConfirmationSummary(query)
  );
