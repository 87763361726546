import React from 'react';

export const StarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.80943 0.598303C5.86859 0.412567 6.13141 0.412566 6.19057 0.598302L7.40227 4.40255C7.42848 4.48486 7.50457 4.54103 7.59094 4.54185L11.4264 4.57816C11.6158 4.57995 11.6968 4.8196 11.5474 4.93597L8.44801 7.34879C8.3829 7.39948 8.3556 7.48495 8.37928 7.564L9.53399 11.4185C9.58935 11.6033 9.37681 11.7516 9.22245 11.6359L6.11996 9.31039C6.04886 9.2571 5.95114 9.2571 5.88005 9.31039L2.77755 11.6359C2.62319 11.7516 2.41065 11.6033 2.46601 11.4185L3.62072 7.564C3.6444 7.48495 3.6171 7.39948 3.55199 7.34879L0.452639 4.93597C0.30316 4.8196 0.384177 4.57995 0.573604 4.57816L4.40906 4.54185C4.49543 4.54103 4.57152 4.48486 4.59773 4.40255L5.80943 0.598303Z"
        fill="currentColor"
      />
    </svg>
  );
};
