import { TradeCounterparty, TradeRequest } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

type AcceptTradeRequestExpressionOfInterestMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId: TradeCounterparty['id'];
};

const acceptTradeRequestExpressionOfInterest = (
  data: AcceptTradeRequestExpressionOfInterestMutation
) => {
  const { tradeCounterpartyId, tradeRequestId } = data;
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/counterparties/${tradeCounterpartyId}/accept-expressed-interest`
  );

  return postRequestWithAuth(url);
};

export const useAcceptTradeRequestExpressionOfInterestMutation = () => {
  return useMutation(acceptTradeRequestExpressionOfInterest);
};
