import {
  InboundTradeCounterpartyActionWithSummary,
  InboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type InboundDataRequestRequestedProps = {
  action: InboundTradeCounterpartyActionWithSummary;
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundDataRequestRequested = ({
  action,
  tradeRequest,
}: InboundDataRequestRequestedProps) => {
  return (
    <PlusMessage date={action.createdAt}>
      <MessageText>
        <TradeMessage
          id="Trade.Inbound.DataRequest.Requested"
          values={{
            userName: formatNameWithInitial(
              tradeRequest.user.lastName,
              tradeRequest.user.firstName
            ),
          }}
        />
      </MessageText>
      <MessageText>Requesting...</MessageText>
    </PlusMessage>
  );
};
