import { useQuery } from 'react-query';

import { getNearbyProperties } from '../../utils/propertiesUtils';
import { QueryKeys } from './types';

type GetNearbyPropertiesQueryProps = {
  loanNumber: string;
};

export const useGetNearbyPropertiesQuery = ({ loanNumber }: GetNearbyPropertiesQueryProps) =>
  useQuery([QueryKeys.NEARBY_PROPERTIES, loanNumber], async () => {
    return await getNearbyProperties(loanNumber);
  });
