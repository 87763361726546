import { Organization, User } from '@plus-platform/shared';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { formatDate, formatPercentage, formatTime } from '../../../utils/formatUtils';
import { formatNameWithInitial } from '../../tradeRequestsUtils';
import {
  QuoteCard,
  QuoteCardBar,
  QuoteCardBorder,
  QuoteCardContent,
  QuoteCardDisk,
  QuoteCardHeader,
  QuoteCardKey,
  QuoteCardKeyValues,
  QuoteCardRef,
  QuoteCardSubtitle,
  QuoteCardTitle,
  QuoteCardValue,
} from './Quote.styles';
import { QuoteSummaryType } from './types';

type QuoteRejectedSummaryProps = {
  type: QuoteSummaryType;
  sender: User;
  senderOrganization: Organization;
  sentAt?: Date;
  reference: string;
  value: number;
};

export const QuoteRejectedSummary = (props: QuoteRejectedSummaryProps) => {
  const { reference, sender, senderOrganization, sentAt, type, value, ...rest } = props;
  const theme = useTheme();

  return (
    <QuoteCard {...rest}>
      <QuoteCardBar $color={theme.colors.tradeQuoteCardBarRed} />
      <QuoteCardContent>
        <QuoteCardHeader>
          <QuoteCardTitle>Rejected</QuoteCardTitle>
          <QuoteCardSubtitle>
            Received from {formatNameWithInitial(sender.lastName, sender.firstName)} @{' '}
            {senderOrganization.tradingName}
            {sentAt && (
              <React.Fragment>
                {' '}
                on{' '}
                {formatDate(new Date(sentAt), {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  weekday: 'short',
                })}{' '}
                @{' '}
                {formatTime(new Date(sentAt), {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false,
                })}
              </React.Fragment>
            )}
          </QuoteCardSubtitle>
          <QuoteCardRef>Ref: {reference}</QuoteCardRef>
        </QuoteCardHeader>
        <QuoteCardBorder />
        <QuoteCardKeyValues>
          <QuoteCardKey>I am formally rejecting your {type.toLowerCase()} of</QuoteCardKey>
          <QuoteCardValue>{formatPercentage(value)}</QuoteCardValue>
          <QuoteCardKey>Reason or comment</QuoteCardKey>
          {/* TODO: Add reason for rejection */}
          <QuoteCardValue>N/A</QuoteCardValue>
        </QuoteCardKeyValues>
      </QuoteCardContent>
      <QuoteCardDisk $variant="rejection" />
    </QuoteCard>
  );
};
