import styled from 'styled-components/macro';

import { TabsHeader as TabsHeaderComponent } from '../../components/Tabs';

export const Container = styled.div`
  width: 100%;
`;

export const TabsHeader = styled(TabsHeaderComponent)`
  border-bottom: 1px solid ${(props) => props.theme.colors.blue03};
`;
