import styled, { css } from 'styled-components/macro';

import { HeadingLink } from './HeadingLink';

export const BreadcrumbWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary03};
  padding-bottom: 14px;
  padding-left: 2px;
`;

export const BreadcrumbItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2px;
`;

export const BreadcrumbItem = styled(({ ...rest }) => <HeadingLink {...rest} $size="small" />)(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary03};

    &:visited {
      color: ${theme.colors.textPrimary03};
    }

    &:hover,
    &:focus-visible,
    &:active {
      color: ${theme.colors.accent01};
    }
  `
);
