import {
  IntegratedDisclosureSectionType,
  IntegratedDisclosureSubsectionType,
} from './integratedDisclosure';
import {
  MISMOAmount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

enum CompensationPaidByType {
  Borrower = 'Borrower',
  Investor = 'Investor',
  Lender = 'Lender',
  Other = 'Other',
  Seller = 'Seller',
}

enum CompensationPaidToType {
  Borrower = 'Borrower',
  Broker = 'Broker',
  Lender = 'Lender',
  LoanOriginator = 'LoanOriginator',
  Other = 'Other',
}

enum CompensationType {
  Other = 'Other',
  Rebate = 'Rebate',
  ServiceReleasePremium = 'ServiceReleasePremium',
  YieldSpreadDifferential = 'YieldSpreadDifferential',
}

export enum GFEAggregationType {
  ChosenInterestRateCreditOrCharge = 'ChosenInterestRateCreditOrCharge',
  CombinedOurOriginationAndInterestRateCreditOrCharge = 'CombinedOurOriginationAndInterestRateCreditOrCharge',
  GovernmentRecordingCharges = 'GovernmentRecordingCharges',
  HomeownersInsurance = 'HomeownersInsurance',
  None = 'None',
  Other = 'Other',
  OurOriginationCharge = 'OurOriginationCharge',
  OwnersTitleInsurance = 'OwnersTitleInsurance',
  RequiredServicesLenderSelected = 'RequiredServicesLenderSelected',
  RequiredServicesYouCanShopFor = 'RequiredServicesYouCanShopFor',
  TitleServices = 'TitleServices',
  TransferTaxes = 'TransferTaxes',
}

export type Compensation = MISMOEntity &
  Partial<{
    compensationActualAmount: MISMOAmount;
    compensationEstimatedAmount: MISMOAmount;
    compensationPaidByType: CompensationPaidByType;
    compensationPaidByTypeOtherDescription: MISMOString;
    compensationPaidToType: CompensationPaidToType;
    compensationPaidToTypeOtherDescription: MISMOString;
    compensationPercent: MISMOPercent;
    compensationPlanIdentifier: MISMOIdentifier;
    compensationPlanMaximumAmount: MISMOAmount;
    compensationPlanMinimumAmount: MISMOAmount;
    compensationSpecifiedFixedAmount: MISMOAmount;
    compensationType: CompensationType;
    compensationTypeOtherDescription: MISMOString;
    GFEAggregationType: GFEAggregationType;
    GFEAggregationTypeOtherDescription: MISMOString;
    GFEDisclosedCompensationAmount: MISMOAmount;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    integratedDisclosureSubsectionType: IntegratedDisclosureSubsectionType;
    integratedDisclosureSubsectionTypeOtherDescription: MISMOString;
    paymentIncludedInAPRIndicator: MISMOIndicator;
    paymentIncludedInJurisdictionHighCostIndicator: MISMOIndicator;
    regulationZPointsAndFeesIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
  }>;
