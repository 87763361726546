import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum UnparsedLegalDescriptionType {
  Long = 'Long',
  Other = 'Other',
  Short = 'Short',
}

type UnparsedLegalDescription = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    unparsedLegalDescription: MISMOString;
    unparsedLegalDescriptionType: UnparsedLegalDescriptionType;
    unparsedLegalDescriptionTypeOtherDescription: MISMOString;
  }>;

type ParsedLegalDescription = MISMOEntity &
  Partial<{
    // plattedLands: PlattedLand[];
    // unplattedLands: UnplattedLand[];
  }>;

enum ParcelIdentificationType {
  AssessorUnformattedIdentifier = 'AssessorUnformattedIdentifier',
  Other = 'Other',
  ParcelIdentificationNumber = 'ParcelIdentificationNumber',
  TaxMapIdentifier = 'TaxMapIdentifier',
  TaxParcelIdentifier = 'TaxParcelIdentifier',
  TorrensCertificateIdentifier = 'TorrensCertificateIdentifier',
}

type ParcelIdentification = MISMOEntity &
  Partial<{
    parcelIdentificationType: ParcelIdentificationType;
    parcelIdentificationTypeOtherDescription: MISMOString;
    parcelIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type LegalDescription = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    parcelIdentifications: ParcelIdentification[];
    parsedLegalDescription: ParsedLegalDescription;
    unparsedLegalDescriptions: UnparsedLegalDescription[];
  }>;
