import { rgba } from 'polished';
import React, { ComponentProps, Fragment, ReactNode } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import { Loader } from '../Loader';

export type Props = ComponentProps<typeof Loader> & {
  isActive?: boolean;
  children?: ReactNode;
  contain?: boolean;
  isFullScreen?: boolean;
  hasMask?: boolean;
  showChildren?: boolean;
  variant?: 'light' | 'dark';
};

type StyledActivityIndicatorProps = Pick<Props, 'contain' | 'isFullScreen' | 'hasMask'>;

export const StyledActivityIndicator = styled.div<StyledActivityIndicatorProps>`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;

  ${({ contain }) =>
    contain &&
    css`
      position: absolute;
    `}

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      position: fixed;
    `}

  ${({ hasMask, theme }) =>
    hasMask &&
    css`
      background: ${rgba(theme.colors.greyBlue, 0.8)};
    `}
`;

export const ChildrenMask = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const ActivityIndicator = ({
  children,
  contain,
  hasMask,
  isActive,
  isFullScreen,
  showChildren,
  variant,
}: Props) => {
  const ChildrenContainer = showChildren && isActive ? ChildrenMask : Fragment;
  const theme = useTheme();

  return (
    <React.Fragment>
      {isActive && (
        <StyledActivityIndicator
          contain={contain || showChildren}
          hasMask={hasMask || showChildren}
          isFullScreen={isFullScreen}
          data-testid="ActivityIndicator"
        >
          <Loader color={variant === 'dark' ? theme.colors.statusBlack : theme.colors.white} />
        </StyledActivityIndicator>
      )}
      {(!isActive || showChildren) && <ChildrenContainer>{children}</ChildrenContainer>}
    </React.Fragment>
  );
};
