import { Identifier } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type LOSLoan = {
  id: Identifier;
};

const getLOSLoans = () => {
  const url = makeApiUrl('loans/los');

  return getRequestWithAuth<LOSLoan[]>(url);
};

export const useLOSLoansQuery = () => useQuery([QueryKeys.LOS_LOANS], getLOSLoans);
