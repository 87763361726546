import React from 'react';

export const useOnClickOutside = <RefType>(
  ref: React.MutableRefObject<RefType> | React.MutableRefObject<RefType>[],
  fn: (event: Event) => void
) => {
  React.useEffect(() => {
    const handleClick = (event: Event) => {
      const refs = Array.isArray(ref) ? ref : [ref];

      const doesContain = refs.some((ref) => {
        if (!ref.current) {
          return true;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (ref.current as any).contains(event.target);
      });

      if (doesContain) {
        return;
      }

      fn(event);
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, fn]);
};
