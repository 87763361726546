import { CounterpartyType, TradeQuoteStatus, TradeRequestTabType } from '@plus-platform/shared';

import { TradeRequestCategory } from '../../trades/types';
import { isSameDay } from '../../utils/dateUtils';
import {
  DateMessage,
  InboundMessage,
  MessageType,
  OutboundActionMessage,
  OutboundMessage,
  QuoteActionMessage,
  QuoteCTAMessageType,
  QuoteMessage,
} from './types';

export const makeMessengerUrl = (
  tradeId: string,
  category: TradeRequestCategory,
  counterpartyId: string,
  tabType?: TradeRequestTabType
) => {
  const root = `/trade-requests/${category}/${tradeId}/${counterpartyId}`;
  const optionalTab = tabType ? `/${tabType}` : '';

  return `${root}${optionalTab}`;
};

export const shouldDisplayDateDivider = (
  currentMessageCreatedDate: Date,
  previousMessageCreatedDate?: Date
) => {
  return (
    !previousMessageCreatedDate ||
    !isSameDay(new Date(previousMessageCreatedDate), new Date(currentMessageCreatedDate))
  );
};

export const isMessageDate = (message: OutboundMessage | InboundMessage | QuoteMessage) => {
  return (
    message.type === MessageType.DATE_DIVIDER && (message as DateMessage).data.date !== undefined
  );
};

export const isMessageInboundAction = (message: OutboundMessage | InboundMessage) => {
  return message.type === MessageType.ACTION && !isMessageOutbound(message);
};

export const isMessageInboundCTA = (message: OutboundMessage | InboundMessage) => {
  return message.type === MessageType.CTA && !isMessageOutbound(message);
};

const isMessageOutbound = (message: OutboundMessage | InboundMessage) => {
  return (
    (message as OutboundActionMessage).data?.action?.fromCounterparty?.type ===
    CounterpartyType.SELLER
  );
};

export const isMessageOutboundAction = (message: OutboundMessage | InboundMessage) => {
  return message.type === MessageType.ACTION && isMessageOutbound(message);
};

export const isMessageOutboundCTA = (message: OutboundMessage | InboundMessage) => {
  return message.type === MessageType.CTA && isMessageOutbound(message);
};

export const isMessageQuote = (message: QuoteMessage): message is QuoteActionMessage => {
  return message.type === MessageType.ACTION;
};

export const isMessageNewQuoteCTA = (message: QuoteMessage) => {
  return message.type === MessageType.CTA && message.data.kind === QuoteCTAMessageType.NEW_QUOTE;
};

export const isMessageAcceptRejectQuoteCTA = (message: QuoteMessage) => {
  return (
    message.type === MessageType.CTA &&
    message.data.kind === QuoteCTAMessageType.ACCEPT_REJECT &&
    message.data.quote.status === TradeQuoteStatus.PENDING
  );
};

export const isMessageCounterCTA = (message: QuoteMessage) => {
  return (
    message.type === MessageType.CTA &&
    message.data.kind === QuoteCTAMessageType.COUNTER &&
    message.data.quote.counters.length > 0
  );
};

export const isMessageAcceptedCounterCTA = (message: QuoteMessage) => {
  return (
    message.type === MessageType.CTA &&
    message.data.kind === QuoteCTAMessageType.COUNTER_ACCEPTED &&
    message.data.quote.status === TradeQuoteStatus.ACCEPTED
  );
};

export const isMessageCompletedCTA = (message: QuoteMessage) => {
  return (
    message.type === MessageType.CTA &&
    message.data.kind === QuoteCTAMessageType.COMPLETED &&
    message.data.quote.status === TradeQuoteStatus.ACCEPTED
  );
};
