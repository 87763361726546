import { UserInvitationTokenStatus } from '@plus-platform/shared';

import { CreateAccountMutation } from '../hooks/queries';
import { getRequest, makeApiUrl, postRequest, requestWithAuth } from './apiUtils';
import { STORAGE_EVENT } from './storageUtils';
import { getAccessToken, getAccessTokenKey, setAccessToken } from './tokenUtils';

export const LOGOUT_EVENT = 'logout';

export const login = async (email: string, password: string) => {
  const url = makeApiUrl('auth/login');

  try {
    const loginResult = await postRequest<{ token: string }>(url, {
      body: JSON.stringify({ email, password }),
      credentials: 'include',
    });

    if (loginResult?.token) {
      setAccessToken(loginResult.token);
    }

    return undefined;
  } catch (error) {
    throw new Error(
      `${error instanceof Error ? error.message : 'Something went wrong. Please try again'}`
    );
  }
};

export const createAccount = async (input: CreateAccountMutation) => {
  const url = makeApiUrl('auth/account');

  try {
    const createAccountResponse = await postRequest<{ token: string }>(url, {
      body: JSON.stringify(input),
      credentials: 'include',
    });

    if (createAccountResponse?.token) {
      setAccessToken(createAccountResponse.token);
    }

    return undefined;
  } catch (error) {
    throw new Error(
      `${error instanceof Error ? error.message : 'Something went wrong. Please try again'}`
    );
  }
};

export const logout = async () => {
  const handleLogout = () => {
    window.dispatchEvent(new CustomEvent(LOGOUT_EVENT));
  };

  if (getAccessToken()) {
    const url = makeApiUrl('auth/logout');
    await requestWithAuth(getRequest, url, handleLogout, {
      credentials: 'include',
    });

    handleLogout();
  }
};

export const isLogoutEvent = (event: { type?: string; key?: string }) =>
  event.type === LOGOUT_EVENT ||
  (event.type === STORAGE_EVENT && event.key === getAccessTokenKey());

export const validateInviteToken = async (token: string) => {
  const url = makeApiUrl(`invite/validate/${token}`);

  try {
    return getRequest<{ status: UserInvitationTokenStatus }>(url);
  } catch (error) {
    throw new Error(
      `${error instanceof Error ? error.message : 'Something went wrong. Please try again'}`
    );
  }
};
