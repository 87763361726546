import messagesJson from './tradeMessages.json';

export type TradeMessageId = keyof typeof messagesJson;

export const getTradeMessage = (
  id: TradeMessageId,
  values?: Record<string, string | number | undefined>
) => {
  const messages: Record<string, string> = messagesJson;
  const message = messages?.[id];

  if (!message) {
    throw Error(`Trade message id "${id}" is not valid`);
  }

  if (!values) {
    return message;
  }

  const interpolatedMessage = Object.entries(values).reduce(
    (acc, [key, value]) => acc.replaceAll(`{${key}}`, value as string),
    message
  );

  return interpolatedMessage;
};

export const stripMessageTags = (str: string) => {
  let message = str;

  const brTags = /<br \/>/g;
  message = message.replaceAll(brTags, ' ');

  const allTags = /<[^>]+>/g;
  message = message.replaceAll(allTags, '');

  return message;
};
