import { Address } from './address';
import {
  MISMODate,
  MISMODatetime,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ActualSignatureType {
  Digital = 'Digital',
  Image = 'Image',
  Other = 'Other',
  Text = 'Text',
  Wet = 'Wet',
}

enum ExecutionJudicialDistrictType {
  Borough = 'Borough',
  City = 'City',
  County = 'County',
  District = 'District',
  Other = 'Other',
  Parish = 'Parish',
  Town = 'Town',
}

export type Execution = MISMOEntity &
  Partial<{
    actualSignatureType: ActualSignatureType;
    actualSignatureTypeOtherDescription: MISMOString;
    executionDate: MISMODate;
    executionDatetime: MISMODatetime;
    executionJudicialDistrictName: MISMOString;
    executionJudicialDistrictType: ExecutionJudicialDistrictType;
    executionJudicialDistrictTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    address: Address;
  }>;
