import {
  OutboundTradeRequestWithSummary,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';
import React from 'react';

import { MessageText, PlusMessage, UserMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';
import { QuoteRejectedSummary } from '../QuoteRejectedSummary';
import { QuoteStack } from '../QuoteStack';
import { QuoteSummary } from '../QuoteSummary';
import { QuoteSummaryType } from '../types';

type OutboundQuoteRejectedProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: OutboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const OutboundQuoteRejected = (props: OutboundQuoteRejectedProps) => {
  const { currentQuoteIndex, quote, totalQuoteCount, tradeRequest } = props;
  const { fromCounterparty } = quote;

  return (
    <React.Fragment>
      <PlusMessage date={quote.createdAt}>
        <MessageText>
          <TradeMessage
            id="Trade.Outbound.Quote.Rejected"
            values={{
              userName: formatNameWithInitial(
                fromCounterparty.user.lastName,
                fromCounterparty.user.firstName
              ),
              organizationName: fromCounterparty.user.organization.tradingName,
            }}
          />
        </MessageText>
      </PlusMessage>
      <UserMessage
        date={quote.createdAt}
        organizationName={tradeRequest.user.organization.tradingName}
        lastName={tradeRequest.user.lastName}
        firstName={tradeRequest.user.firstName}
      >
        <QuoteStack>
          <QuoteSummary
            currentQuoteIndex={currentQuoteIndex}
            quote={quote}
            totalQuoteCount={totalQuoteCount}
            tradeRequest={tradeRequest}
          />
          <QuoteRejectedSummary
            reference={`${quote.id}`}
            sender={tradeRequest.user}
            senderOrganization={tradeRequest.user.organization}
            sentAt={quote.updatedAt}
            type={QuoteSummaryType.QUOTE}
            value={quote.value}
          />
        </QuoteStack>
      </UserMessage>
    </React.Fragment>
  );
};
