import { transparentize } from 'polished';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { ButtonLoader } from '../../components/ButtonLoader';
import { PageHeader, PageSectionTitle } from '../../components/Page';
import { Select } from '../../components/Select';
import { WidthWrapper } from '../../global/WidthWrapper';
import { useCustodiansQuery } from '../../hooks/queries';

const Wrapper = styled(WidthWrapper)`
  padding: 24px 16px;
`;

const Guide = styled.div(
  ({ theme }) => css`
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    font-family: ${theme.typography.fontFamily.text};
    color: ${transparentize(0.5, theme.colors.white)};
  `
);

const SelectWrapper = styled.div``;

export const AssignCustodianPage = () => {
  const [selectedCustodianId, setSelectedCustodianId] = useState<string | undefined>();
  const { data: custodians, isLoading } = useCustodiansQuery();
  const history = useHistory();

  const handleAssignCustodianClick = () => {
    // TODO: Use selectedCustodianId
    // TODO: Implement next step in flow
    history.push('/home');
  };

  return (
    <ActivityIndicator contain isActive={isLoading}>
      <Wrapper>
        <PageHeader>
          <PageSectionTitle>Assign a Custodian</PageSectionTitle>
          <Guide>Please select a custodian for the chosen loans</Guide>
          <ButtonLoader
            style={{ marginTop: '10px', marginBottom: '20px' }}
            disabled={!selectedCustodianId}
            isLoading={isLoading}
            onClick={handleAssignCustodianClick}
          >
            Assign Custodian
          </ButtonLoader>
        </PageHeader>

        <SelectWrapper>
          {custodians && (
            <Select
              onChange={(value) => {
                setSelectedCustodianId(String(value));
              }}
              options={custodians.map((custodian) => ({
                label: custodian.name,
                value: String(custodian.id),
              }))}
              value={selectedCustodianId}
              styles={{
                control: {
                  alignItems: 'center',
                },
              }}
            />
          )}
        </SelectWrapper>
      </Wrapper>
    </ActivityIndicator>
  );
};
