import React from 'react';

export const PortfolioLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.6 19C5.16 19 4.78333 18.8557 4.47 18.5671C4.15667 18.2785 4 17.9316 4 17.5263V9.42105C4 9.01579 4.15667 8.66886 4.47 8.38026C4.78333 8.09167 5.16 7.94737 5.6 7.94737H8.8V6.47368C8.8 6.06842 8.95667 5.72149 9.27 5.43289C9.58333 5.1443 9.96 5 10.4 5H13.6C14.04 5 14.4167 5.1443 14.73 5.43289C15.0433 5.72149 15.2 6.06842 15.2 6.47368V7.94737H18.4C18.84 7.94737 19.2167 8.09167 19.53 8.38026C19.8433 8.66886 20 9.01579 20 9.42105V17.5263C20 17.9316 19.8433 18.2785 19.53 18.5671C19.2167 18.8557 18.84 19 18.4 19H5.6ZM10.4 7.94737H13.6V6.47368H10.4V7.94737ZM18.4 14.5789H14.4V16.0526H9.6V14.5789H5.6V17.5263H18.4V14.5789ZM11.2 14.5789H12.8V13.1053H11.2V14.5789ZM5.6 13.1053H9.6V11.6316H14.4V13.1053H18.4V9.42105H5.6V13.1053Z"
        fill="currentColor"
      />
    </svg>
  );
};
