import React from 'react';

export const PersonCropCircleBadgeXMarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9992 19.9933C16.2706 19.9933 19.7888 16.4676 19.7888 12.2037C19.7888 7.93973 16.2556 4.41406 11.9916 4.41406C8.02902 4.41406 4.71429 7.45006 4.26981 11.2997C4.73689 11.1942 5.35463 11.1641 5.85184 11.2469C6.29632 8.2486 8.8577 5.97349 11.9916 5.97349C15.4495 5.97349 18.2369 8.74581 18.2369 12.2037C18.2369 13.8535 17.6041 15.3451 16.5569 16.4526C15.781 15.5937 14.1387 14.8103 11.9916 14.8103C11.3211 14.8103 10.6959 14.8856 10.1309 15.0212C10.2213 15.3828 10.2665 15.7595 10.2665 16.1437C10.2665 17.3717 9.79185 18.5092 9.02344 19.3906C9.94252 19.7748 10.952 19.9933 11.9992 19.9933ZM11.9916 13.5748C13.4531 13.5823 14.5907 12.3393 14.5907 10.7271C14.5907 9.20536 13.4456 7.9322 11.9916 7.9322C10.5452 7.9322 9.38504 9.20536 9.40011 10.7271C9.40765 12.3393 10.5301 13.5597 11.9916 13.5748ZM5.30943 20.031C7.41881 20.031 9.18917 18.2681 9.18917 16.1437C9.18917 14.0193 7.44141 12.264 5.30943 12.264C3.18499 12.264 1.42969 14.0193 1.42969 16.1437C1.42969 18.2757 3.18499 20.031 5.30943 20.031ZM4.26981 17.9593C4.05887 18.1777 3.71987 18.1476 3.51646 17.9442C3.31306 17.7408 3.28292 17.4018 3.5014 17.1908L4.56362 16.1286L3.57673 15.1342C3.38086 14.9308 3.37333 14.5993 3.57673 14.411C3.7726 14.2151 4.10407 14.2151 4.29994 14.411L5.29436 15.3979L6.35659 14.3357C6.57506 14.1172 6.899 14.1473 7.1024 14.3583C7.31334 14.5617 7.34347 14.8856 7.125 15.1041L6.06278 16.1663L7.04967 17.1532C7.24554 17.3566 7.25307 17.6881 7.04967 17.8839C6.85379 18.0798 6.52985 18.0798 6.32645 17.8839L5.33203 16.897L4.26981 17.9593Z"
        fill="currentColor"
      />
    </svg>
  );
};
