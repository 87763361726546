import {
  AggregatedTradeRequestMessage,
  TradeRequestMessageCreationMetadata,
} from '@plus-platform/shared';

export const isLastMessage = (
  messages: AggregatedTradeRequestMessage[],
  metadata: TradeRequestMessageCreationMetadata
) => {
  const lastMessage = messages[messages.length - 1];
  return (
    lastMessage?.metadata?.quote?.id &&
    lastMessage?.metadata?.quote?.id === metadata?.quote?.id &&
    lastMessage?.metadata?.quoteMessageType &&
    lastMessage?.metadata?.quoteMessageType === metadata?.quoteMessageType
  );
};
