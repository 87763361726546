import styled, { css } from 'styled-components/macro';

export const Header = styled.div(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}
    color: ${theme.colors.textPrimary02};

    span {
      display: block;
      padding: 0 4px;
    }
  `
);

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 12px;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 180px;
`;

export const RecipientHeader = styled.header(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 12px;
    border-bottom: 1px solid ${theme.colors.primary03};
  `
);

export const RecipientDivider = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary02};
    font-weight: ${theme.typography.fontWeight.regular};
    white-space: pre;
  `
);

export const RecipientOrganization = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary02};
    font-weight: ${theme.typography.fontWeight.regular};
  `
);

export const LeadingBid = styled.div`
  ${({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03}
    display: flex;
    align-items: center;
    column-gap: 4px;
    height: 24px;
    padding: 0 12px 0 8px;
    background: ${theme.colors.statusGreen};
    border-radius: 16px;
    color: ${theme.colors.statusWhite};
    white-space: nowrap;
  `}
`;

export const Steps = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const RecipientSteps = styled.div`
  width: min-content;
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 8px;
`;

export const Stacks = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 1px;
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    flex: 1;
    height: 100%;
    width: 1px;
    background: ${theme.colors.primary03};
  `}
`;

export const Inactive = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.primary07};
    text-align: center;

    > p {
      ${theme.typography.bodyText.bodyRegular02Tight}
      margin-top: 0;
      color: ${theme.colors.textPrimary03};
    }
  `
);

export const TransactionLog = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
`;
