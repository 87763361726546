import { useTheme } from 'styled-components';

import { InternalLink } from '../../components/Link';
import { PlusLogoDark, PlusLogoWhite } from '../../components/Logo';
import { REACT_APP_BUILD_VERSION } from '../../config';
import { isDarkTheme } from '../../styles/theme';
import { logout } from '../../utils/authUtils';
import * as Styles from './Header.styles';
import { MainMenu } from './MainMenu';
import { Navigation } from './Navigation';
import { NotificationsPanel } from './NotificationsPanel';
import { Search } from './Search';
import { UserProfileMenu } from './UserProfileMenu';

export const Header = () => {
  const theme = useTheme();

  const isAdmin = !isDarkTheme(theme);
  const HeaderComponent = isAdmin ? Styles.AdminHeader : Styles.Header;

  return (
    <HeaderComponent>
      <Styles.Grid>
        <InternalLink to="/home" title={REACT_APP_BUILD_VERSION} data-testid="MainNav_Logo">
          {isDarkTheme(theme) ? <PlusLogoWhite /> : <PlusLogoDark />}
        </InternalLink>
        <Navigation />
        <Styles.Subgrid>
          <Search />
          <NotificationsPanel />
          <MainMenu data-testid="GlobalActionsMenu" />
          <UserProfileMenu
            onLogoutClick={() => {
              logout();
            }}
          />
        </Styles.Subgrid>
      </Styles.Grid>
    </HeaderComponent>
  );
};
