import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

enum HUD1LineItemPaidByType {
  Buyer = 'Buyer',
  LenderPremium = 'LenderPremium',
  Seller = 'Seller',
}

type HUD1LineItem = MISMOEntity &
  Partial<{
    HUD1LineItemAdditionalTextDescription: MISMOString;
    HUD1LineItemAmount: MISMOAmount;
    HUD1LineItemDescription: MISMOString;
    HUD1LineItemFromDate: MISMODate;
    HUD1LineItemPaidByType: HUD1LineItemPaidByType;
    HUD1LineItemToDate: MISMODate;
    HUD1SpecifiedLineNumberValue: MISMOValue;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type HUD1 = MISMOEntity &
  Partial<{
    HUD1CashToOrFromBorrowerIndicator: MISMOIndicator;
    HUD1CashToOrFromSellerIndicator: MISMOIndicator;
    HUD1ConventionalInsuredIndicator: MISMOIndicator;
    HUD1FileNumberIdentifier: MISMOIdentifier;
    HUD1SettlementDate: MISMODate;
    HUD1LineItems: HUD1LineItem[];
  }>;
