import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import {
  StyledComponentInnerOtherProps,
  StyledComponentPropsWithRef,
} from 'styled-components/macro';

import * as Styles from './SearchBar.styles';

type SearchBarProps = StyledComponentPropsWithRef<typeof Styles.SearchInput> &
  StyledComponentInnerOtherProps<typeof Styles.SearchInput> & {
    onClear?: () => void;
  };

export const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      $isFullWidth,
      $lightBackground,
      autoComplete = 'off',
      onChange,
      onClear,
      placeholder = 'Search...',
      ...rest
    },
    forwardedRef
  ) => {
    const [hasText, setHasText] = useState(false);
    const innerRef = useRef<HTMLInputElement | null>(null);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasText(Boolean(event.target.value));
        onChange?.(event);
      },
      [onChange]
    );

    const clearInput = useCallback(() => {
      onClear?.();
      innerRef?.current && (innerRef.current.value = '');
      setHasText(false);
    }, []);

    useEffect(() => setHasText(Boolean(innerRef?.current?.value)), []);

    return (
      <Styles.SearchBar $isFullWidth={$isFullWidth}>
        <Styles.SearchInput
          ref={(ref) => {
            if (ref) {
              innerRef.current = ref;
            }
            if (typeof forwardedRef === 'function') {
              forwardedRef(ref);
            } else if (forwardedRef !== null) {
              forwardedRef.current = ref;
            }
          }}
          type="search"
          onChange={handleChange}
          autoComplete={autoComplete}
          placeholder={placeholder}
          $isDirty={hasText}
          $isFullWidth={$isFullWidth}
          $lightBackground={$lightBackground}
          {...rest}
        />
        <Styles.SearchIcon />
        {hasText && <Styles.CloseButton onClick={() => clearInput()} />}
      </Styles.SearchBar>
    );
  }
);
