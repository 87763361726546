import { Switch, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ChevronLeftIcon, ChevronRightIcon, HelpIcon } from '../components/icons';
import { PageSection } from '../components/Page';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { PrivateRoute } from '../global/PrivateRoute';
import { usePortfolioAnalyticsQuery } from '../hooks/queries';
import { getLabelFromAnalyticResult } from '../portfolio/analytics/utils';
import { MenuItemButton, SpecificMenuItemButton } from './AdminMenuItemButton';
import {
  GeneralMenu,
  Header,
  MenuItem,
  SpecificMenu,
  SpecificMenuItem,
  Wrapper,
} from './FlagsAlertsWarningsPage.styles';
import { QATilePage } from './QATilePage';
import {
  AnalyticsHeader,
  AnalyticsSubtitle,
  AnalyticsTitle,
  Description,
  HeaderLink,
} from './SettingsAnalyticsPage.styles';
import { AdminRouteType } from './types';

const QATilesHomePage = () => {
  const theme = useTheme();
  const history = useHistory();

  const { data } = usePortfolioAnalyticsQuery();

  const analytics = data?.analytics || [];

  return (
    <Wrapper>
      <AnalyticsHeader>
        <HeaderLink to={AdminRouteType.FlagsAlertsWarningAnalytics}>
          <ChevronLeftIcon color={theme.colors.fillAdminBackLinkIcon} />
        </HeaderLink>
        <AnalyticsTitle>Analytics</AnalyticsTitle>
      </AnalyticsHeader>

      <PageSection>
        <Header>
          <AnalyticsSubtitle>General</AnalyticsSubtitle>
          <HelpIcon color={theme.colors.statusGreen} />
        </Header>

        <GeneralMenu>
          <MenuItem>
            <MenuItemButton
              title="Configure all Q&A tiles the same"
              actionComponent={() => <ToggleSwitch disabled />}
              icon={<HelpIcon />}
            />
          </MenuItem>
        </GeneralMenu>
      </PageSection>

      <PageSection>
        <Header>
          <AnalyticsSubtitle>
            Configure all tiles individually ({analytics.length})
          </AnalyticsSubtitle>
        </Header>

        {analytics.length === 0 && <Description>No tiles configured</Description>}
        {analytics.length > 0 && (
          <SpecificMenu>
            {analytics.map((analytic) => {
              const title = getLabelFromAnalyticResult(analytic);

              return (
                <SpecificMenuItem key={analytic.id}>
                  <SpecificMenuItemButton
                    title={title}
                    actionComponent={() => <ChevronRightIcon />}
                    onClick={() =>
                      history.push(`${AdminRouteType.AnalyticsQATiles}/${analytic.id}`)
                    }
                  />
                </SpecificMenuItem>
              );
            })}
          </SpecificMenu>
        )}
      </PageSection>
    </Wrapper>
  );
};

export const QATilesPage = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        component={() => <QATilesHomePage />}
        path={AdminRouteType.AnalyticsQATiles}
      />
      <PrivateRoute
        exact
        component={() => <QATilePage />}
        path={`${AdminRouteType.AnalyticsQATiles}/:analyticId`}
      />
    </Switch>
  );
};
