import React from 'react';

export const MenuLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4993 4.46574V6.30552C10.8351 6.50032 10.3591 7.10636 10.3591 7.82062C10.3591 8.69722 11.0897 9.40066 11.9753 9.40066C12.8719 9.40066 13.5914 8.6864 13.5914 7.82062C13.5914 7.10636 13.1043 6.51114 12.4513 6.30552V4.46574C12.4513 4.20601 12.2409 4.00039 11.9753 4.00039C11.7096 3.98957 11.4993 4.20601 11.4993 4.46574ZM12.6394 7.82062C12.6394 8.17776 12.3406 8.46996 11.9753 8.46996C11.61 8.46996 11.3111 8.17776 11.3111 7.82062C11.3111 7.46349 11.61 7.17129 11.9753 7.17129C12.3406 7.16047 12.6394 7.45267 12.6394 7.82062Z"
        fill="currentColor"
      />
      <path
        d="M19.5337 11.4998H17.694C17.4992 10.8356 16.8931 10.3597 16.1789 10.3597C15.3023 10.3597 14.5988 11.0902 14.5988 11.9758C14.5988 12.8724 15.3131 13.5919 16.1789 13.5919C16.8931 13.5919 17.4883 13.1049 17.694 12.4518L19.5337 12.4518C19.7935 12.4518 19.9991 12.2415 19.9991 11.9758C20.0099 11.7101 19.7935 11.4998 19.5337 11.4998ZM16.1789 12.6399C15.8217 12.6399 15.5295 12.3411 15.5295 11.9758C15.5295 11.6105 15.8217 11.3116 16.1789 11.3116C16.536 11.3116 16.8282 11.6105 16.8282 11.9758C16.839 12.3411 16.5468 12.6399 16.1789 12.6399Z"
        fill="currentColor"
      />
      <path
        d="M13.5919 11.9758C13.5919 11.0792 12.8612 10.3597 11.9755 10.3597C11.0788 10.3597 10.3591 11.0902 10.3591 11.9758C10.3591 12.8724 11.0898 13.5919 11.9755 13.5919C12.8723 13.5919 13.5919 12.8724 13.5919 11.9758ZM11.9755 12.6399C11.6102 12.6399 11.3113 12.3411 11.3113 11.9758C11.3113 11.6105 11.6102 11.3116 11.9755 11.3116C12.3409 11.3116 12.6398 11.6105 12.6398 11.9758C12.6398 12.3411 12.3409 12.6399 11.9755 12.6399Z"
        fill="currentColor"
      />
      <path
        d="M17.8316 7.73611C17.8316 6.8395 17.1009 6.11999 16.2152 6.11999C15.3184 6.11999 14.5988 6.85057 14.5988 7.73611C14.5988 8.63273 15.3295 9.35224 16.2152 9.35224C17.1119 9.35224 17.8316 8.63273 17.8316 7.73611ZM16.2152 8.40027C15.8498 8.40027 15.5509 8.1014 15.5509 7.73611C15.5509 7.37083 15.8498 7.07195 16.2152 7.07195C16.5805 7.07195 16.8795 7.37083 16.8795 7.73611C16.8795 8.1014 16.5805 8.40027 16.2152 8.40027Z"
        fill="currentColor"
      />
      <path
        d="M9.35222 7.73611C9.35222 6.8395 8.62153 6.11999 7.73585 6.11999C6.83909 6.11999 6.11947 6.85057 6.11947 7.73611C6.11947 8.63273 6.85016 9.35224 7.73585 9.35224C8.6326 9.35224 9.35222 8.63273 9.35222 7.73611ZM7.73585 8.40027C7.3705 8.40027 7.07158 8.1014 7.07158 7.73611C7.07158 7.37083 7.3705 7.07195 7.73585 7.07195C8.10119 7.07195 8.40011 7.37083 8.40011 7.73611C8.40011 8.1014 8.10119 8.40027 7.73585 8.40027Z"
        fill="currentColor"
      />
      <path
        d="M9.35222 16.2155C9.35222 15.3188 8.62153 14.5993 7.73585 14.5993C6.83909 14.5993 6.11947 15.3299 6.11947 16.2155C6.11947 17.1121 6.85016 17.8316 7.73585 17.8316C8.6326 17.8316 9.35222 17.1121 9.35222 16.2155ZM7.73585 16.8796C7.3705 16.8796 7.07158 16.5807 7.07158 16.2155C7.07158 15.8502 7.3705 15.5513 7.73585 15.5513C8.10119 15.5513 8.40011 15.8502 8.40011 16.2155C8.40011 16.5807 8.10119 16.8796 7.73585 16.8796Z"
        fill="currentColor"
      />
      <path
        d="M17.8316 16.2155C17.8316 15.3188 17.1009 14.5993 16.2152 14.5993C15.3184 14.5993 14.5988 15.3299 14.5988 16.2155C14.5988 17.1121 15.3295 17.8316 16.2152 17.8316C17.1119 17.8316 17.8316 17.1121 17.8316 16.2155ZM16.2152 16.8796C15.8498 16.8796 15.5509 16.5807 15.5509 16.2155C15.5509 15.8502 15.8498 15.5513 16.2152 15.5513C16.5805 15.5513 16.8795 15.8502 16.8795 16.2155C16.8795 16.5807 16.5805 16.8796 16.2152 16.8796Z"
        fill="currentColor"
      />
      <path
        d="M4.46574 12.4515L6.30552 12.4515C6.50032 13.1157 7.10636 13.5917 7.82062 13.5917C8.69722 13.5917 9.40066 12.8611 9.40066 11.9756C9.40066 11.0789 8.6864 10.3594 7.82062 10.3594C7.10636 10.3594 6.51114 10.8465 6.30552 11.4996L4.46574 11.4996C4.20601 11.4996 4.00039 11.7099 4.00039 11.9756C3.98957 12.2412 4.20601 12.4515 4.46574 12.4515ZM7.82062 11.3114C8.17776 11.3114 8.46996 11.6103 8.46996 11.9756C8.46996 12.3409 8.17776 12.6397 7.82062 12.6397C7.46349 12.6397 7.17129 12.3409 7.17129 11.9756C7.16047 11.6103 7.45267 11.3114 7.82062 11.3114Z"
        fill="currentColor"
      />
      <path
        d="M12.3988 19.5343V17.6945C13.0629 17.4997 13.5389 16.8936 13.5389 16.1794C13.5389 15.3028 12.8083 14.5993 11.9228 14.5993C11.0262 14.5993 10.3067 15.3136 10.3067 16.1794C10.3067 16.8936 10.7937 17.4889 11.4468 17.6945L11.4468 19.5343C11.4468 19.794 11.6571 19.9996 11.9228 19.9996C12.1884 20.0104 12.3988 19.794 12.3988 19.5343ZM11.2586 16.1794C11.2586 15.8222 11.5575 15.53 11.9228 15.53C12.2881 15.53 12.5869 15.8222 12.5869 16.1794C12.5869 16.5365 12.2881 16.8287 11.9228 16.8287C11.5575 16.8395 11.2586 16.5473 11.2586 16.1794Z"
        fill="currentColor"
      />
    </svg>
  );
};
