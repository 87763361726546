import { NotificationDto } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const markNotificationAsReadRequest = (notificationId: NotificationDto['id']) => {
  const url = makeApiUrl(`user/notifications/${notificationId}/read`);
  return postRequestWithAuth(url);
};

export const useMarkNotificationAsReadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(markNotificationAsReadRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.USER_NOTIFICATIONS);
      queryClient.invalidateQueries(QueryKeys.USER_NOTIFICATIONS_COUNT);
    },
  });
};
