import styled, { css } from 'styled-components/macro';

type StackProps = {
  $gap?: number | string;
  $direction?: React.CSSProperties['flexDirection'];
  $justifyContent?: React.CSSProperties['justifyContent'];
  $alignItems?: React.CSSProperties['alignItems'];
};

export const Stack = styled.div<StackProps>`
  ${({ $alignItems, $direction, $gap, $justifyContent }) => css`
    display: flex;
    flex-direction: ${$direction ?? 'row'};
    gap: ${typeof $gap === 'string' ? $gap : `${$gap ?? 8}px`};
    align-items: ${$alignItems ?? 'initial'};
    justify-content: ${$justifyContent ?? 'initial'};
  `};
`;
