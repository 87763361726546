import { MISMOEntity, MISMOIndicator, MISMOString } from '../types';
import { OffSiteImprovement } from './offSiteImprovement';
import { SiteFeature } from './siteFeature';
import { SiteInfluence } from './siteInfluence';
import { SiteLocation } from './siteLocation';
import { SiteSizeLayout } from './siteSizeLayout';
import { SiteUtility } from './siteUtility';
import { SiteView } from './siteView';
import { SiteZoning } from './siteZoning';

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type Site = MISMOEntity &
  Partial<{
    characteristicsAffectMarketabilityDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    cornerLotIndicator: MISMOIndicator;
    siteAdverseConditionsIndicator: MISMOIndicator;
    siteHighestBestUseDescription: MISMOIndicator;
    siteHighestBestUseIndicator: MISMOIndicator;
    offSiteImprovements: OffSiteImprovement[];
    siteFeatures: SiteFeature[];
    siteInfluences: SiteInfluence[];
    siteLocations: SiteLocation[];
    siteSizeLayout: SiteSizeLayout;
    siteUtilities: SiteUtility[];
    siteViews: SiteView[];
    siteZoning: SiteZoning;
  }>;
