import { TradeRequest } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postFormDataRequestWithAuth } from '../../utils/apiUtils';

type ExpressInterestOnTradeRequestMutation = {
  tradeRequestId: TradeRequest['id'];
  stipulation: File;
};

const expressInterestOnTradeRequest = (data: ExpressInterestOnTradeRequestMutation) => {
  const { stipulation, tradeRequestId } = data;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/express-interest`);
  const body = new FormData();

  if (stipulation) {
    body.append('stipulation', stipulation);
  }

  return postFormDataRequestWithAuth(url, { body });
};

export const useTradeRequestExpressInterestMutation = () => {
  return useMutation(expressInterestOnTradeRequest);
};
