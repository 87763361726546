import { OrganizationType, UserPermissionHierarchy } from '@plus-platform/shared';

import { UserProfile } from '../users/types';
import { getRequestWithAuth, makeApiUrl } from './apiUtils';

export const getUserProfile = () => {
  const url = makeApiUrl('user/profile');
  return getRequestWithAuth<UserProfile>(url);
};

export const getUserPermissions = () => {
  const url = makeApiUrl('user/permissions');
  return getRequestWithAuth<UserPermissionHierarchy>(url);
};

export const isInvestor = (profile?: UserProfile) => {
  return profile?.organization?.type === OrganizationType.ORGANIZATION_INVESTOR;
};

export const isOriginator = (profile?: UserProfile) => {
  return profile?.organization?.type === OrganizationType.ORGANIZATION_ORIGINATOR;
};
