import {
  InboundTradeCounterpartyActionWithSummary,
  InboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type InboundDataRequestDeniedProps = {
  action: InboundTradeCounterpartyActionWithSummary;
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundDataRequestDenied = ({
  action,
  tradeRequest,
}: InboundDataRequestDeniedProps) => {
  const { createdAt, reason } = action;

  const userName = formatNameWithInitial(tradeRequest.user.lastName, tradeRequest.user.firstName);

  return (
    <PlusMessage date={createdAt}>
      <MessageText>
        {reason ? (
          <TradeMessage
            id="Trade.Inbound.DataRequest.DeniedWithReason"
            values={{ userName, reason }}
          />
        ) : (
          <TradeMessage id="Trade.Inbound.DataRequest.DeniedWithoutReason" values={{ userName }} />
        )}
      </MessageText>
    </PlusMessage>
  );
};
