import {
  MISMOAmount,
  MISMOEntity,
  MISMONumeric,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum AtticFeatureType {
  DropStair = 'DropStair',
  Finished = 'Finished',
  Floor = 'Floor',
  Heated = 'Heated',
  Other = 'Other',
  Scuttle = 'Scuttle',
  Stairs = 'Stairs',
}

export type AtticFeature = MISMOEntity &
  Partial<{
    atticFeatureDescription: MISMOString;
    atticFeatureType: AtticFeatureType;
    atticFeatureTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type Attic = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    squareFeetNumber: MISMONumeric;
    atticFeatures: AtticFeature[];
  }>;
