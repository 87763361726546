import { NavLinkProps } from 'react-router-dom';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { HeadingLink } from '../HeadingLink';
import {
  baseStyles,
  largeStyles,
  lightBackgroundStyles,
  mediumStyles,
  smallStyles,
} from './ButtonToggle.styles';

type styleProps = ThemeProps<DefaultTheme>;

export const activeStyles = ({ theme }: styleProps) => css`
  color: ${theme.colors.background01};
  background: ${theme.colors.white};
`;

export const hoverStyles = ({ theme }: styleProps) => css`
  color: ${theme.colors.white};
  background: ${theme.colors.white15};
`;

type LinkStyleProps = {
  $size?: 'small' | 'medium' | 'large';
  $lightBackground?: boolean;
};

export const Link = styled(HeadingLink)<LinkStyleProps & NavLinkProps>(
  ({ $lightBackground, $size = 'large', isActive }) => css`
    line-height: 1.5em;
    gap: 0.25em;

    ${baseStyles};

    ${$lightBackground && lightBackgroundStyles};

    ${$size === 'large' && largeStyles};
    ${$size === 'medium' && mediumStyles};
    ${$size === 'small' && smallStyles};

    &:hover,
    &:focus-visible {
      ${isActive ? activeStyles : hoverStyles};
    }

    ${isActive && activeStyles}

    &.active,
    &:active {
      ${activeStyles}
    }
  `
);
