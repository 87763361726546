import styled, { css } from 'styled-components/macro';

import { CloseCircleIcon, SearchFillIcon } from './icons';

export const SearchBar = styled.div<{ $isFullWidth?: boolean }>(
  ({ $isFullWidth, theme }) => css`
    position: relative;
    display: ${$isFullWidth ? 'block' : 'inline-block'};
    color: ${theme.colors.white};
  `
);

export const SearchIcon = styled(SearchFillIcon)(
  () => css`
    position: absolute;
    top: 4px;
    left: 8px;
  `
);

export const CloseButton = styled(CloseCircleIcon)(
  ({ theme }) => css`
    position: absolute;
    top: 4px;
    right: 4px;
    color: ${theme.colors.white50};
    cursor: pointer;
  `
);

type SearchInputProps = {
  $isFullWidth?: boolean;
  $isDirty?: boolean;
  $lightBackground?: boolean;
};

export const SearchInput = styled.input<SearchInputProps>(
  ({ $isDirty, $isFullWidth, $lightBackground, theme }) =>
    css`
      ${theme.typography.bodyText.bodyRegular02}

      width: ${$isFullWidth ? '100%' : '182px'};
      height: 32px;
      padding-left: 36px;
      padding-right: 36px;
      background: ${$lightBackground
        ? theme.colors.white05
        : theme.colors.backgroundHeaderSearchBar};
      border-width: 0;
      border-style: solid;
      border-radius: 16px;
      border-color: ${theme.colors.borderHeaderSearchBar};
      color: ${theme.colors.white};
      caret-color: ${theme.colors.accent01};
      outline: 0;
      transition: background 0.2s ease-out, box-shadow 0.2s ease-out;

      &:disabled {
        pointer-events: none;
      }

      &::placeholder {
        color: ${theme.colors.white50};
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      + ${SearchIcon} {
        transition: color 0.2s ease-out;
      }

      &:hover,
      &:focus-visible {
        box-shadow: inset 0 0 0 1px ${theme.colors.accent01};

        + ${SearchIcon} {
          color: ${theme.colors.accent01};
        }
      }

      ${$isDirty &&
      css`
        + ${SearchIcon} {
          color: ${theme.colors.white50};
        }
      `}
    `
);
