import React from 'react';
import { NavLinkProps } from 'react-router-dom';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { ExternalLink, NavLink } from './Link';

export type BodyTextLinkStyleProps = {
  $size?: 'large' | 'medium' | 'small' | 'xSmall';
};
const largeSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.bodyText.bodyRegular01}
  padding: 0.25em;
`;

const mediumSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.bodyText.bodyRegular02}
  padding: 0 0.25em;
`;

const smallSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.bodyText.bodyRegular03}
  padding: 0 0.25em;
`;

const xSmallSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.bodyText.bodyRegular04}
  padding: 0 0.25em;
`;

const TextLinkStyles = ({
  $size = 'medium',
  theme,
}: BodyTextLinkStyleProps & { theme: DefaultTheme }) => css`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  color: ${theme.colors.anchorColorHover};
  background-color: ${theme.colors.anchorBGColor};
  line-height: inherit;

  ${$size === 'large' && largeSizeStyles}
  ${$size === 'medium' && mediumSizeStyles}
    ${$size === 'small' && smallSizeStyles}
    ${$size === 'xSmall' && xSmallSizeStyles}

    &:visited {
    background-color: ${theme.colors.anchorBGColor};
  }

  &:hover {
    color: ${theme.colors.anchorColorHover};
    background-color: ${theme.colors.anchorBGColorHover};
  }

  &:focus-visible {
    color: ${theme.colors.anchorColorHover};
    background-color: ${theme.colors.anchorBGColorHover};
    outline: none;
  }

  &:active {
    color: ${theme.colors.anchorColorActive};
    background-color: ${theme.colors.anchorBGColorActive};
  }
`;

export const BodyTextLink = styled(NavLink)<BodyTextLinkStyleProps & NavLinkProps>(TextLinkStyles);

export const BodyTextExternalLink = styled(ExternalLink)<
  BodyTextLinkStyleProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(TextLinkStyles);
