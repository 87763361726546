import { CreatePoolResponse } from '@plus-platform/shared';
import React from 'react';

import { Button } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { Popup, PopupContent, PopupFooter, PopupHeader } from '../components/Popup';
import { Stack } from '../components/Stack';
import { useCreatePoolMutation } from '../hooks/queries';
import * as Styled from './CreatePoolPopup.styles';

type CreatePoolPopupProps = {
  onCancel: () => void;
  onSubmit: (pool?: CreatePoolResponse) => void;
};

export const CreatePoolPopup = ({ onCancel, onSubmit }: CreatePoolPopupProps) => {
  const [name, setName] = React.useState('');

  const { isLoading: isNewPoolCreating, mutateAsync: createPool } = useCreatePoolMutation();

  const isLoading = isNewPoolCreating;
  const isDisabled = !name.trim() || isLoading;

  return (
    <Popup
      style={{
        width: '500px',
      }}
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault();

          try {
            await createPool(
              {
                name,
              },
              {
                onSuccess: (data) => {
                  onSubmit(data);
                },
              }
            );
          } catch (error) {
            // TODO: display error message once error states are defined
          }
        }}
      >
        <PopupHeader title="Create new pool" />
        <PopupContent>
          <Styled.Input
            autoComplete="off"
            id="new-pool-name"
            name="new-pool-name"
            onChange={(event) => setName(event.target.value)}
            placeholder="Pool name"
            required
            type="text"
            value={name}
          />
        </PopupContent>
        <PopupFooter>
          <Stack>
            <Button
              $color="tertiary"
              $variant="outlined"
              disabled={isLoading}
              onClick={onCancel}
              type="button"
            >
              Cancel
            </Button>
            <ButtonLoader disabled={isDisabled} isLoading={isLoading} type="submit">
              Create
            </ButtonLoader>
          </Stack>
        </PopupFooter>
      </form>
    </Popup>
  );
};
