import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

export enum HousingExpenseTimingType {
  Present = 'Present',
  Proposed = 'Proposed',
}

export enum HousingExpenseType {
  Cable = 'Cable',
  Electricity = 'Electricity',
  EscrowShortage = 'EscrowShortage',
  FirstMortgagePITI = 'FirstMortgagePITI',
  FirstMortgagePrincipalAndInterest = 'FirstMortgagePrincipalAndInterest',
  FloodInsurance = 'FloodInsurance',
  GroundRent = 'GroundRent',
  Heating = 'Heating',
  HomeownersAssociationDuesAndCondominiumFees = 'HomeownersAssociationDuesAndCondominiumFees',
  HomeownersInsurance = 'HomeownersInsurance',
  LeaseholdPayments = 'LeaseholdPayments',
  MaintenanceAndMiscellaneous = 'MaintenanceAndMiscellaneous',
  MIPremium = 'MIPremium',
  Other = 'Other',
  OtherMortgageLoanPrincipalAndInterest = 'OtherMortgageLoanPrincipalAndInterest',
  OtherMortgageLoanPrincipalInterestTaxesAndInsurance = 'OtherMortgageLoanPrincipalInterestTaxesAndInsurance',
  RealEstateTax = 'RealEstateTax',
  Rent = 'Rent',
  SupplementalPropertyInsurance = 'SupplementalPropertyInsurance',
  Telephone = 'Telephone',
  Utilities = 'Utilities',
}

export type HousingExpense = MISMOEntity &
  Partial<{
    housingExpensePaymentAmount: MISMOAmount;
    housingExpenseTimingType: HousingExpenseTimingType;
    housingExpenseType: HousingExpenseType;
    housingExpenseTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type HousingExpenseSummary = MISMOEntity &
  Partial<{
    housingExpensePresentTotalMonthlyPaymentAmount: MISMOAmount;
    housingExpenseProposedTotalMonthlyPaymentAmount: MISMOAmount;
  }>;
