import { EventLogItem } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanAssetLedgerQuery = {
  tradeRequestId: string;
  loanId: string;
};

const getTradeRequestDataLoanAssetLedger = (query: TradeRequestDataLoanAssetLedgerQuery) => {
  const { loanId, tradeRequestId } = query;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/loans/${loanId}/asset-ledger`);

  return getRequestWithAuth<EventLogItem[]>(url);
};

export const useTradeRequestDataLoanAssetLedgerQuery = (
  query: TradeRequestDataLoanAssetLedgerQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_ASSET_LEDGER, query], () =>
    getTradeRequestDataLoanAssetLedger(query)
  );
