import React from 'react';

export const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2059 19L17 12L10.2059 5L9 6.2425L14.5881 12L9 17.7575L10.2059 19Z"
        fill="currentColor"
      />
    </svg>
  );
};
