import { formatDecimal, formatMonetary, formatPercentage } from '../../utils/formatUtils';
import { Analytic, AnalyticContainer, AnalyticHeader, AnalyticTitle } from './Analytic';
import { BreakdownTileContent } from './BreakdownTile';
import { ChangeValue } from './ValuationMetrics.styles';

type ValuationMetricsProps = {
  marketValue?: number | null;
  marketValueChange?: number | null;
  yieldCC?: number | null;
  yieldFC?: number | null;
  oas?: number | null;
};

export const ValuationMetrics = (props: ValuationMetricsProps) => {
  const { marketValue, marketValueChange, oas, yieldCC, yieldFC } = props;

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Valuation metrics</AnalyticTitle>
        </AnalyticHeader>

        <BreakdownTileContent label="Market value" value={formatMonetary(marketValue)} />
        <BreakdownTileContent
          label="Mkt Val % change"
          value={
            <ChangeValue $isPositive={Number(marketValueChange) > 0}>
              {formatPercentage(marketValueChange)}
            </ChangeValue>
          }
        />
        <BreakdownTileContent label="Yield (CC)" value={formatDecimal(yieldCC)} />
        <BreakdownTileContent label="Yield (FC)" value={formatDecimal(yieldFC)} />
        <BreakdownTileContent label="OAS" value={formatDecimal(oas)} />
      </AnalyticContainer>
    </Analytic>
  );
};
