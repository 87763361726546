import { Name } from './name';
import {
  MISMOCode,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum NotaryCertificateSignerIdentificationType {
  PersonallyKnown = 'PersonallyKnown',
  ProvidedIdentification = 'ProvidedIdentification',
}

type BirthInformation = {
  birthDate: MISMODate;
  cityTownVillageOfBirthName: MISMOString;
  countryOfBirthName: MISMOString;
  mothersMaidenName: MISMOString;
  stateProvinceOfBirthName: MISMOString;
};

enum IdentityDocumentIssuedByType {
  Country = 'Country',
  Other = 'Other',
  StateProvince = 'StateProvince',
}

enum IdentityDocumentType {
  BankStatement = 'BankStatement',
  DriversLicense = 'DriversLicense',
  EmployeeIdentification = 'EmployeeIdentification',
  MilitaryIdentification = 'MilitaryIdentification',
  NationalIdentification = 'NationalIdentification',
  Other = 'Other',
  Passport = 'Passport',
  PrivateIdentification = 'PrivateIdentification',
  StateIdentification = 'StateIdentification',
  TaxpayerIdentification = 'TaxpayerIdentification',
  Visa = 'Visa',
}

type IdentityDocumentation = {
  identityDocumentExpirationDate: MISMODate;
  identityDocumentIdentifier: MISMOIdentifier;
  identityDocumentIssuedByCountryName: MISMOString;
  identityDocumentIssuedByName: MISMOString;
  identityDocumentIssuedByStateCode: MISMOCode;
  identityDocumentIssuedByStateProvinceName: MISMOString;
  identityDocumentIssuedByType: IdentityDocumentIssuedByType;
  identityDocumentIssuedByTypeOtherDescription: MISMOString;
  identityDocumentIssuedDate: MISMODate;
  identityDocumentType: IdentityDocumentType;
  identityDocumentTypeOtherDescription: MISMOString;
  sequenceNumber: MISMOSequenceNumber;
};

type SharedSecret = {
  sequenceNumber: MISMOSequenceNumber;
  sharedSecretPromptText: MISMOString;
  sharedSecretResponseText: MISMOString;
};

type SpouseInformation = {
  name: Name;
};

export type IdentificationVerification = MISMOEntity &
  Partial<{
    countryOfCitizenshipName: MISMOString;
    notaryCertificateSignerIdentificationType: NotaryCertificateSignerIdentificationType;
    SSNAssociationsCount: MISMOCount;
    birthInformation: BirthInformation;
    identityDocumentations: IdentityDocumentation[];
    sharedSecrets: SharedSecret[];
    spouseInformation: SpouseInformation;
  }>;
