import { MISMODate, MISMOEntity, MISMOString } from './types';

export enum VerificationMethodType {
  Other = 'Other',
  Verbal = 'Verbal',
  Written = 'Written',
}

export enum VerificationStatusType {
  NotVerified = 'NotVerified',
  ToBeVerified = 'ToBeVerified',
  Verified = 'Verified',
}

export type Verification = MISMOEntity &
  Partial<{
    verificationByName: MISMOString;
    verificationCommentDescription: MISMOString;
    verificationDate: MISMODate;
    verificationMethodType: VerificationMethodType;
    verificationMethodTypeOtherDescription: MISMOString;
    verificationStatusType: VerificationStatusType;
  }>;
