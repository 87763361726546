import { AggregatedUser, Stipulation, TradeCounterparty } from '@plus-platform/shared';

export enum TradeRequestCategory {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum TradeRequestTab {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum TradeRequestStep {
  EXPRESSION_OF_INTEREST = 'EXPRESSION_OF_INTEREST',
  DIRECT_MESSAGE = 'DIRECT_MESSAGE',
  DATA_REQUEST = 'DATA_REQUEST',
  QUOTE = 'QUOTE',
}

export type TradeTransactionLog = {
  createdAt: Date;
  toCounterparty: {
    id: TradeCounterparty['id'];
    firstName: AggregatedUser['firstName'];
    lastName: AggregatedUser['lastName'];
    organization: {
      tradingName: AggregatedUser['organization']['tradingName'];
    };
  };
  fromCounterparty: {
    id: TradeCounterparty['id'];
    firstName: AggregatedUser['firstName'];
    lastName: AggregatedUser['lastName'];
    organization: {
      tradingName: AggregatedUser['organization']['tradingName'];
    };
  };
  category: 'Bids' | 'DM' | 'Expression of Interest' | 'Request for Data' | 'Settled';
  message: string | JSX.Element;
  stipulation?: Stipulation;
};
