import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { PopupContent, PopupFooter, PopupHeader } from '../components/Popup';
import { Stack } from '../components/Stack';
import { useAddLoanNoteMutation } from '../hooks/queries';
import * as Styles from './AddNotePopup.styles';

type AddNotePopupProps = {
  onClose: () => void;
  loanNumber: string;
};

type AddNoteFormValues = {
  content: string;
};

export const AddNotePopup = ({ loanNumber, onClose }: AddNotePopupProps) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<AddNoteFormValues>();
  const [submitError, setSubmitError] = React.useState<Error | null>(null);
  const { mutate } = useAddLoanNoteMutation();

  const createNote: SubmitHandler<AddNoteFormValues> = async (data) => {
    try {
      await mutate({ loanNumber, content: data.content });
    } catch (error) {
      setSubmitError(error as Error);
    }
    onClose();
  };

  const fieldError = errors.content;
  const error = fieldError || submitError;

  return (
    <Styles.PopupComponent>
      <PopupHeader title="Add note" />
      <PopupContent>
        <div>
          {Boolean(error) && (
            <Styles.ErrorText>{error?.message || 'Something went very wrong'}</Styles.ErrorText>
          )}
        </div>
        <Styles.Textarea
          rows={8}
          autoFocus
          placeholder="Type your note here..."
          {...register('content', {
            required: {
              value: true,
              message: 'You must enter a note',
            },
            maxLength: {
              value: 2000,
              message: 'A note cannot be longer than 2000 characters',
            },
          })}
        />
      </PopupContent>
      <PopupFooter>
        <Stack>
          <Button $color="tertiary" $variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <ButtonLoader isLoading={isSubmitting} onClick={handleSubmit(createNote)}>
            Add
          </ButtonLoader>
        </Stack>
      </PopupFooter>
    </Styles.PopupComponent>
  );
};
