import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../components/Button';
import { AddIcon } from '../components/icons/AddIcon';

export const CreateNewPoolButton = () => {
  const history = useHistory();

  return (
    <Button
      $size="medium"
      $variant="outlined"
      $color="secondary"
      onClick={() => history.push('/pools/create')}
      startIcon={<AddIcon />}
    >
      Create new pool
    </Button>
  );
};
