import styled, { css } from 'styled-components/macro';

export const Status = styled.span<{ $color: string; $variant: 'heading' | 'body' }>(
  ({ $color, $variant, theme }) => css`
    ${theme.typography.headings.headingMedium03}

    ${$variant === 'heading'
      ? theme.typography.headings.headingMedium03
      : theme.typography.bodyText.bodyMedium02Tight}

    color: ${$color};
  `
);
