import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { DefaultButton } from '../../components/Button';
import { SIDE_PANEL_WIDTH } from './LayerControls';

export const Wrapper = styled.div<{
  isSidebarVisible?: boolean;
}>`
  position: absolute;
  top: 8px;
  left: ${(props) => (props.isSidebarVisible ? `${SIDE_PANEL_WIDTH + 10}px` : '120px')};
  display: flex;
  align-items: flex-start;
`;

export const LayersOverlay = styled.div`
  display: none;
  padding-left: 10px;

  &:hover {
    display: flex;
  }
`;

export const LayersButton = styled(DefaultButton)`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
    background-color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.text};
    padding: 8px 24px;
    width: auto;
    height: auto;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: ${theme.shadows.mapLayersShadow};
    flex-shrink: 0;

    &:hover {
      & + ${LayersOverlay} {
        display: flex;
      }
    }
  `};
`;

export const LayersContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.colors.white};
    padding: 15px 5px 15px 5px;
    border-radius: 4px;
    box-shadow: ${theme.shadows.mapLayersShadow};
  `}
`;

export const layerToggleGrid = css`
  display: grid;
  grid-template-columns: repeat(6, 60px);
  column-gap: 4px;
  place-items: center;
`;

export const LayerToggles = styled.div`
  ${layerToggleGrid}
  row-gap: 8px;
`;

export const LayerCategories = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
`;

export const LayerCategory = styled.div`
  ${layerToggleGrid}
`;

export const LayerCategoryHeading = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 400;
    font-size: 10px;
    border-top: 1px solid ${transparentize(0.8, theme.colors.statusBlack)};
    color: ${transparentize(0.5, theme.colors.darkBlue)};
    text-transform: uppercase;
    padding: 8px 0;
    margin: 0 12px;

    &:not(&:first-of-type) {
      margin-top: 10px;
    }
  `}
`;

export const LayerToggleButtonIcon = styled.span<{ $hideBorder?: boolean }>`
  ${({ $hideBorder, theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border: ${$hideBorder ? 'none' : `0.25px solid ${transparentize(0.5, theme.colors.darkBlue)}`};
    border-radius: 2px;
    color: ${theme.colors.toggleIconGrey};

    ${$hideBorder &&
    css`
      svg {
        height: 20px;
        width: 20px;
      }
    `}
  `}
`;

export const layerToggleButtonHoverStyles = css`
  ${({ theme }) => css`
    color: ${theme.colors.greenLight};

    ${LayerToggleButtonIcon} {
      border-width: 1px;
      border-color: ${theme.colors.greenLight};
      color: ${theme.colors.statusBlack};
    }
  `}
`;

export type LayerToggleButtonProps = {
  isToggled?: boolean;
};

export const LayerToggleButton = styled(DefaultButton)<LayerToggleButtonProps>`
  ${({ isToggled, theme }) => css`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    padding: 0;
    height: auto;
    width: auto;
    font-family: ${theme.typography.fontFamily.text};
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.greenDark};
    white-space: nowrap;

    &:hover {
      ${layerToggleButtonHoverStyles}
    }

    ${isToggled && layerToggleButtonHoverStyles}
  `}
`;

export const LayerExpandToggle = styled.div`
  grid-column: -2;
`;
