import { LoanDelinquencyStatus } from '.';
import { Identifier } from './global';
import { Loan } from './loans';
import { Organization } from './organizations';
import { Pool, PoolWithSummary } from './pools';
import { AggregatedUser, User, UserIdentifier } from './users';

/**
 * Trade request
 */

export enum TradeRequestAttributeType {
  ATTRIBUTE_TRADE_REQUEST = 'ATTRIBUTE_TRADE_REQUEST',
}

export type TradeRequestDetails = {
  externalTradeId?: string;
  externalTradeName?: string;
  externalTradeOwner?: string;
  commercials?: string;
};

export type TradeRequestCreation = TradeRequestDetails & {
  userId: UserIdentifier;
  poolId: Identifier;
};

export type TradeRequest = TradeRequestCreation & {
  id: Identifier;
  createdAt: Date;
  updatedAt?: Date;
};

export type Stipulation = {
  id: Identifier;
  organizationId: Identifier;
  fileId: string;
  fileOriginalName: string;
  filePreviewPath: string;
  fileName: string;
};

export type TradeRequestStipulation = {
  id: Identifier;
  tradeRequestId: Identifier;
  stipulationId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedTradeRequestStipulation = Stipulation & {
  tradeRequestId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type CounterpartyStipulation = {
  id: Identifier;
  counterpartyId: Identifier;
  stipulationId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedCounterpartyStipulation = Stipulation & {
  counterpartyId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type QuoteStipulation = {
  id: Identifier;
  quoteId: Identifier;
  stipulationId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type ActionStipulation = {
  id: Identifier;
  actionId: Identifier;
  stipulationId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedActionStipulation = ActionStipulation & {
  stipulation: Stipulation;
};

export type AggregatedQuoteStipulation = {
  quoteId: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
  quoteStipulation?: Stipulation;
};

export type TradeRequestWithStipulations = TradeRequest & {
  stipulations: TradeRequestStipulation[];
};

export type AggregatedTradeRequest = TradeRequest & {
  counterparties: AggregatedTradeCounterparty[];
  pool: Pool;
  user: AggregatedUser;
};

export type AggregatedTradeRequestWithBidsAndMessages = AggregatedTradeRequest & {
  bids: (TradeRequestQuote & {
    quoteStipulations: AggregatedQuoteStipulation[];
  })[];
  messages: TradeRequestMessage[];
};

export type TradeRequestUpdate = Partial<TradeRequest> & Pick<TradeRequest, 'id' | 'updatedAt'>;

/**
 * Trade counterparty
 */

export enum TradeCounterpartyAttributeType {
  ATTRIBUTE_TRADE_COUNTERPARTY = 'ATTRIBUTE_TRADE_COUNTERPARTY',
}

export enum TradeCounterpartyStatus {
  PENDING = 'PENDING',
  EXPRESSED_INTEREST = 'EXPRESSED_INTEREST',
  EXPRESSED_INTEREST_ACCEPTED = 'EXPRESSED_INTEREST_ACCEPTED',
  EXPRESSED_INTEREST_DECLINED = 'EXPRESSED_INTEREST_DECLINED',
  DATA_REQUESTED = 'DATA_REQUESTED',
  DATA_REQUEST_ACCEPTED = 'DATA_REQUEST_ACCEPTED',
  DATA_REQUEST_DECLINED = 'DATA_REQUEST_DECLINED',
  SOLD = 'SOLD',
  SETTLED = 'SETTLED',
}

export type TradeCounterpartyCreation = {
  tradeRequestId: Identifier;
  userId: Identifier;
  type: CounterpartyType;
  createdAt?: Date;
  updatedAt?: Date;
};

export type TradeCounterparty = TradeCounterpartyCreation & {
  id: Identifier;
};

export type AggregatedTradeCounterparty = TradeCounterparty & {
  actions: AggregatedCounterpartyAction[];
  user: AggregatedUser;
};

/**
 * Trade counterparty actions
 */

export enum TradeCounterpartyActionType {
  REQUEST_EXPRESSION_OF_INTEREST = 'REQUEST_EXPRESSION_OF_INTEREST',
  ACCEPT_EXPRESSION_OF_INTEREST = 'ACCEPT_EXPRESSION_OF_INTEREST',
  DECLINE_EXPRESSION_OF_INTEREST = 'DECLINE_EXPRESSION_OF_INTEREST',
  REQUEST_DATA_REQUEST = 'REQUEST_DATA_REQUEST',
  ACCEPT_DATA_REQUEST = 'ACCEPT_DATA_REQUEST',
  DENY_DATA_REQUEST = 'DENY_DATA_REQUEST',
  // TODO: remove the below
  MARK_AS_SOLD = 'MARK_AS_SOLD',
  MARK_AS_SETTLED = 'MARK_AS_SETTLED',
}

export type CounterpartyAction = {
  id: Identifier;
  fromCounterpartyId: TradeCounterparty['id'];
  toCounterpartyId: TradeCounterparty['id'];
  type: TradeCounterpartyActionType;
  reason?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedCounterpartyAction = CounterpartyAction & {
  fromCounterparty: TradeCounterparty;
  toCounterparty: TradeCounterparty;
  actionStipulations: AggregatedActionStipulation[];
  // TODO: Review why stipulations are being added
  stipulations: AggregatedActionStipulation[];
  stipulation: AggregatedActionStipulation;
};

/**
 * Trade transaction logs
 */

export enum TradeLogType {
  ACTION = 'ACTION',
  COUNTER = 'COUNTER',
  DM = 'DM',
  QUOTE = 'QUOTE',
}

type TradeRequestLogCommon = {
  id: Identifier;
  tradeRequestId: TradeRequest['id'];
  toCounterparty: {
    id: TradeCounterparty['id'];
    firstName: AggregatedUser['firstName'];
    lastName: AggregatedUser['lastName'];
    organization: {
      tradingName: AggregatedUser['organization']['tradingName'];
    };
  };
  fromCounterparty: {
    id: TradeCounterparty['id'];
    firstName: AggregatedUser['firstName'];
    lastName: AggregatedUser['lastName'];
    organization: {
      tradingName: AggregatedUser['organization']['tradingName'];
    };
  };
  createdAt: Date;
};

export type TradeRequestActionLog = TradeRequestLogCommon & {
  type: TradeLogType.ACTION;
  metadata: {
    type: TradeCounterpartyActionType;
    stipulation?: AggregatedActionStipulation;
    reason?: string;
  };
};

export type TradeRequestMessageLog = TradeRequestLogCommon & {
  type: TradeLogType.DM;
  metadata: {
    content?: string;
  };
};

export type TradeRequestQuoteLog = TradeRequestLogCommon & {
  type: TradeLogType.QUOTE;
  metadata: {
    type: TradeQuoteType;
    value?: number;
    stipulation?: AggregatedQuoteStipulation;
    status: TradeQuoteStatus;
  };
};

export type TradeRequestCounterLog = TradeRequestLogCommon & {
  type: TradeLogType.COUNTER;
  metadata: {
    type: TradeQuoteType;
    value?: number;
    reason?: string;
    stipulation?: AggregatedQuoteStipulation;
    status: TradeQuoteStatus;
    linkedQuoteId: number;
  };
};

export type TradeRequestLog =
  | TradeRequestActionLog
  | TradeRequestMessageLog
  | TradeRequestQuoteLog
  | TradeRequestCounterLog;

/**
 * Trade quote
 * NOTE: First pass - reconsider when more requirements are known for quotes
 */

export enum TradeQuoteAttributeType {
  ATTRIBUTE_TRADE_QUOTE = 'ATTRIBUTE_TRADE_QUOTE',
}

export enum TradeQuoteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum TradeQuoteType {
  BUYER_TO_SELLER = 'BUYER_TO_SELLER',
  SELLER_TO_BUYER = 'SELLER_TO_BUYER',
}

export type TradeRequestQuoteCreation = {
  tradeRequestId: TradeRequest['id'];
  fromCounterpartyId: TradeCounterparty['id'];
  toCounterpartyId: TradeCounterparty['id'];
  linkedQuoteId?: Identifier;
  value: number;
  reason?: string;
};

export type TradeRequestQuote = TradeRequestQuoteCreation & {
  id: Identifier;
  status: TradeQuoteStatus;
  type: TradeQuoteType;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedTradeRequestQuote = TradeRequestQuote & {
  stipulation: AggregatedQuoteStipulation;
  fromCounterparty: AggregatedTradeCounterparty;
  toCounterparty: AggregatedTradeCounterparty;
};

export type TradeQuoteStatusUpdate = {
  tradeQuoteId: TradeRequestQuote['id'];
  updatedAt: Date;
};

export enum CounterpartyType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type QuoteCounter = TradeRequestQuote;

export type AggregatedQuoteCounter = QuoteCounter & {
  stipulation?: AggregatedQuoteStipulation;
};

export type TradeQuoteCounterWithSummary = Omit<QuoteCounter, 'stipulation'> & {
  stipulation?: AggregatedQuoteStipulation;
};

export type TradeQuoteCounterStatusUpdate = {
  tradeQuoteCounterId: QuoteCounter['id'];
  updatedAt: Date;
};

export type TradeStipulationFile = {
  id: Identifier;
  originalName: string;
  preview: string;
  name: string;
};

export type TradeStipulationCreation = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId?: TradeCounterparty['id'];
  file: TradeStipulationFile;
};

export type TradeStipulation = TradeStipulationCreation & {
  id: TradeStipulationFile['id'];
  createdAt: Date;
};

/**
 * Trade message
 */

export type TradeChatParticipant = {
  type: CounterpartyType;
  counterpartyId: TradeCounterparty['id'];
};

export enum TradeRequestMessageType {
  MESSAGE = 'MESSAGE',
  EXPRESSION_OF_INTEREST = 'EXPRESSION_OF_INTEREST',
  DATA_REQUEST = 'DATA_REQUEST',
  QUOTE = 'QUOTE',
}

export enum QuoteMessageType {
  QUOTE_CTA_NEW_QUOTE = 'QUOTE_CTA_NEW_QUOTE',
  QUOTE_CREATED_PENDING = 'QUOTE_CREATED_PENDING',
  QUOTE_CREATED_PENDING_CTA = 'QUOTE_CREATED_PENDING_CTA',
  QUOTE_ACCEPTED = 'QUOTE_ACCEPTED',
  QUOTE_ACCEPTED_CTA = 'QUOTE_ACCEPTED_CTA',
  QUOTE_REJECTED = 'QUOTE_REJECTED',
  QUOTE_REJECTED_CTA = 'QUOTE_REJECTED_CTA',
  QUOTE_COUNTERED = 'QUOTE_COUNTERED',
  QUOTE_COUNTERED_CTA = 'QUOTE_COUNTERED_CTA',
  QUOTE_COUNTER_ACCEPTED = 'QUOTE_COUNTER_ACCEPTED',
  QUOTE_COUNTER_ACCEPTED_CTA = 'QUOTE_COUNTER_ACCEPTED_CTA',
  QUOTE_COUNTER_REJECTED = 'QUOTE_COUNTER_REJECTED',
  QUOTE_COUNTER_REJECTED_CTA = 'QUOTE_COUNTER_REJECTED_CTA',
  QUOTE_COMPLETED = 'QUOTE_COMPLETED',
  QUOTE_COMPLETED_CTA = 'QUOTE_COMPLETED_CTA',
}

export type TradeRequestMessageCreationMetadataQuote = Omit<
  AggregatedTradeRequestQuote,
  'stipulation'
> & {
  quoteStipulation?: Omit<AggregatedQuoteStipulation, 'quoteStipulation'> & {
    quoteStipulation?: Stipulation;
  };
  linkedQuotes: AggregatedTradeRequestQuote[];
};

export type TradeRequestMessageCreationMetadata = {
  quote?: TradeRequestMessageCreationMetadataQuote;
  lastCounter?: AggregatedTradeRequestQuote;
  quoteMessageType: QuoteMessageType;
};

export type TradeRequestMessageCreation = {
  tradeRequestId: TradeRequest['id'];
  fromCounterpartyId: TradeCounterparty['id'];
  toCounterpartyId: TradeCounterparty['id'];
  type: TradeRequestMessageType;
  content: string;
  metadata?: TradeRequestMessageCreationMetadata;
};

export type TradeRequestMessage = TradeRequestMessageCreation & {
  id: Identifier;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AggregatedTradeRequestMessage = TradeRequestMessage & {
  fromCounterparty: AggregatedTradeCounterparty;
  toCounterparty: AggregatedTradeCounterparty;
};

export enum TradeMessageAttributeType {
  ATTRIBUTE_TRADE_MESSAGE = 'ATTRIBUTE_TRADE_MESSAGE',
}

export enum StipulationType {
  STIPULATION_ACTION = 'STIPULATION_ACTION',
  STIPULATION_QUOTE = 'STIPULATION_QUOTE',
}

/**
 * Types for the Trade Summary UI
 * NOTE: First pass - may change soon depending on UI requirement changes
 */

export type CounterpartyWithSummary = Pick<TradeCounterparty, 'id' | 'updatedAt' | 'type'> & {
  actions: AggregatedCounterpartyAction[];
  user: AggregatedUser;
};

type TradeRequestWithSummaryCommon = Pick<
  TradeRequest,
  'id' | 'createdAt' | 'updatedAt' | 'externalTradeId'
> & {
  user: AggregatedUser;
  pool: PoolWithSummary;
};

export type InboundTradeRequestWithSummary = TradeRequestWithSummaryCommon & {
  counterparties: CounterpartyWithSummary[];
};

export type OutboundTradeRequestWithSummary = TradeRequestWithSummaryCommon & {
  counterparties: CounterpartyWithSummary[];
  messages: TradeRequestMessage[];
  stipulations: TradeStipulation[];
  bids: TradeQuoteWithSummary[];
};

export type TradeRequestWithSummary =
  | InboundTradeRequestWithSummary
  | OutboundTradeRequestWithSummary;

export type TradeRequestsSummary = {
  inbound: InboundTradeRequestWithSummary[];
  outbound: OutboundTradeRequestWithSummary[];
  completed: TradeRequestWithSummary[];
};

export type TradeRequestDataLoan = {
  id: Loan['id'];
  delinquencyStatus: LoanDelinquencyStatus;
  state: string;
  originalUPB: number;
  currentUPB: number;
  currentNoteRate: number;
  productType: string;
  maturityDate: Date;
  originalLTV: number;
  currentLTV: number;
  originalDTI: number;
  originalCreditScore: number;
  currentCreditScore: number;
  originator: string;
  loanType: string;
};

export type TradeSeller = {
  user: User;
  organization: Organization;
};

export type TradeQuoteWithSummary = TradeRequestQuote & {
  fromCounterparty: CounterpartyWithSummary;
  toCounterparty: CounterpartyWithSummary;
  counters: TradeQuoteCounterWithSummary[];
  seller: TradeSeller;
  stipulation?: AggregatedQuoteStipulation;
};

export type TradeCounterpartyActionWithSummaryCommon = Omit<CounterpartyAction, 'stipulation'> & {
  stipulation?: AggregatedActionStipulation;
};

export type InboundTradeCounterpartyActionWithSummary = Omit<
  TradeCounterpartyActionWithSummaryCommon,
  'tradeCounterpartyId'
>;

export type OutboundTradeCounterpartyActionWithSummary = Omit<
  TradeCounterpartyActionWithSummaryCommon,
  'tradeCounterpartyId'
> & {
  fromCounterparty: CounterpartyWithSummary;
  toCounterparty: CounterpartyWithSummary;
};

export type TradeCounterpartyActionWithSummary =
  | InboundTradeCounterpartyActionWithSummary
  | OutboundTradeCounterpartyActionWithSummary;

export enum TradeCounterpartyActionHistoryFilter {
  EXPRESSED_INTEREST = 'express-interest',
  DATA_REQUEST = 'data-request',
}

export type TradeConfirmationSummary = {
  pool: PoolWithSummary;
  fromCounterparty: CounterpartyWithSummary;
  toCounterparty: CounterpartyWithSummary;
  buyerStipulations: AggregatedQuoteStipulation[];
  sellerStipulations: AggregatedQuoteStipulation[];
};

export type CompletedTradeRequestWithSummary = {
  id: TradeRequest['id'];
  pool: PoolWithSummary;
  purchaser: CounterpartyWithSummary;
  pricePercentage: number;
};

export enum TradeRequestTabType {
  'EXPRESSIONS_OF_INTEREST' = 'expressions-of-interest',
  'DATA_REQUESTS' = 'data-requests',
  'DATA_VIEW' = 'data-view',
  'QUOTES' = 'quotes',
  'MESSAGES' = 'messages',
}
