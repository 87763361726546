import { FundsSourceType } from '../funds';
import { OwnedProperty } from '../property/ownedProperty';
import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOURI,
  MISMOYear,
} from '../types';
import { Verification } from '../verification';
import { AssetHolder } from './assetHolder';

enum AssetAccountType {
  BorrowerManagedAccount = 'BorrowerManagedAccount',
  Other = 'Other',
  RelatedPartyManagedAccount = 'RelatedPartyManagedAccount',
}

export enum AssetType {
  Annuity = 'Annuity',
  Automobile = 'Automobile',
  Boat = 'Boat',
  Bond = 'Bond',
  BorrowerEstimatedTotalAssets = 'BorrowerEstimatedTotalAssets',
  BorrowerPrimaryHome = 'BorrowerPrimaryHome',
  BridgeLoanNotDeposited = 'BridgeLoanNotDeposited',
  CashOnHand = 'CashOnHand',
  CertificateOfDepositTimeDeposit = 'CertificateOfDepositTimeDeposit',
  CheckingAccount = 'CheckingAccount',
  EarnestMoneyCashDepositTowardPurchase = 'EarnestMoneyCashDepositTowardPurchase',
  EmployerAssistance = 'EmployerAssistance',
  GiftOfCash = 'GiftOfCash',
  GiftOfPropertyEquity = 'GiftOfPropertyEquity',
  GiftsTotal = 'GiftsTotal',
  Grant = 'Grant',
  IndividualDevelopmentAccount = 'IndividualDevelopmentAccount',
  LifeInsurance = 'LifeInsurance',
  MoneyMarketFund = 'MoneyMarketFund',
  MutualFund = 'MutualFund',
  NetWorthOfBusinessOwned = 'NetWorthOfBusinessOwned',
  Other = 'Other',
  PendingNetSaleProceedsFromRealEstateAssets = 'PendingNetSaleProceedsFromRealEstateAssets',
  ProceedsFromSaleOfNonRealEstateAsset = 'ProceedsFromSaleOfNonRealEstateAsset',
  ProceedsFromSecuredLoan = 'ProceedsFromSecuredLoan',
  ProceedsFromUnsecuredLoan = 'ProceedsFromUnsecuredLoan',
  RealEstateOwned = 'RealEstateOwned',
  RecreationalVehicle = 'RecreationalVehicle',
  RelocationMoney = 'RelocationMoney',
  RetirementFund = 'RetirementFund',
  SaleOtherAssets = 'SaleOtherAssets',
  SavingsAccount = 'SavingsAccount',
  SavingsBond = 'SavingsBond',
  SeverancePackage = 'SeverancePackage',
  Stock = 'Stock',
  StockOptions = 'StockOptions',
  TrustAccount = 'TrustAccount',
}

export type Asset = MISMOEntity &
  Partial<{
    assetAccountIdentifier: MISMOIdentifier;
    'assetAccountIdentifier@IdentifierOwnerURI': MISMOURI;
    assetAccountInNameOfDescription: MISMOString;
    assetAccountType: AssetAccountType;
    assetAccountTypeOtherDescription: MISMOString;
    assetCashOrMarketValueAmount: MISMOAmount;
    assetDescription: MISMOString;
    assetLiquidityIndicator: MISMOIndicator;
    assetNetValueAmount: MISMOAmount;
    assetType: AssetType;
    assetTypeOtherDescription: MISMOString;
    automobileMakeDescription: MISMOString;
    automobileModelYear: MISMOYear;
    fundsSourceType: FundsSourceType;
    fundsSourceTypeOtherDescription: MISMOString;
    includedInAssetAccountIndicator: MISMOIndicator;
    lifeInsuranceFaceValueAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
    stockBondMutualFundShareCount: MISMOCount;
    verifiedIndicator: MISMOIndicator;
    // assetDocumentations: AssetDocumentation[];
    assetHolder: AssetHolder;
    ownedProperty: OwnedProperty;
    verification: Verification;
  }>;

export type AssetSummary = MISMOEntity &
  Partial<{
    giftGrantTotalCashOrMarketValueAmount: MISMOAmount;
    liquidAssetTotalCashOrMarketValueAmount: MISMOAmount;
    nonLiquidAssetTotalCashOrMarketValueAmount: MISMOAmount;
  }>;
