import { ellipsis } from 'polished';
import styled, { css } from 'styled-components';

export type ImageTileStyleProps = {
  $height: number;
  $width?: number;
  $variant?: 'default' | 'fit-container';
  $hasCursorPointer?: boolean;
};

export const Wrapper = styled.div<ImageTileStyleProps>(
  ({ $hasCursorPointer, $height, $variant, $width }) => css`
    position: relative;
    flex-shrink: 0;
    border-radius: 4px;
    overflow: hidden;
    cursor: ${$hasCursorPointer ? 'pointer' : 'default'};

    width: ${$width}px;
    height: ${$height}px;

    ${$variant === 'fit-container' &&
    css`
      width: 100%;
      height: 100%;
    `}
  `
);

export const Image = styled.img<{ $hasLoaded: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: ${({ $hasLoaded }) => ($hasLoaded ? 1 : 0)};
  transition: opacity 0.2s ease-out;
`;

export const Label = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  border-radius: 4px;
  padding: 0 4px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white50};
  color: ${({ theme }) => theme.colors.background01};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  ${ellipsis()}
  max-width: calc(100% - 16px);
`;

export const Error = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    ${theme.typography.bodyText.bodyRegular02}
    color: ${theme.colors.white50};
    background-color: ${theme.colors.white05};
  `
);

export const Loader = styled.div<{ $hasLoaded: boolean }>(
  ({ $hasLoaded, theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: ${theme.colors.white05};
    align-items: center;
    justify-content: center;
    opacity: ${$hasLoaded ? 0 : 1};
    transition: opacity 0.2s ease-out;
  `
);
