import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMONumeric,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

export enum LoanAmortizationType {
  AdjustableRate = 'AdjustableRate',
  Fixed = 'Fixed',
  GEM = 'GEM',
  GPM = 'GPM',
  GraduatedPaymentARM = 'GraduatedPaymentARM',
  Other = 'Other',
  RateImprovementMortgage = 'RateImprovementMortgage',
  Step = 'Step',
}

export enum LoanAmortizationPeriodType {
  Biweekly = 'Biweekly',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Semimonthly = 'Semimonthly',
  Week = 'Week',
  Year = 'Year',
}

export type AmortizationRule = MISMOEntity &
  Partial<{
    amortizationType: LoanAmortizationType;
    amortizationTypeOtherDescription: MISMOString;
    loanAmortizationMaximumTermMonthsCount: MISMOCount;
    loanAmortizationPeriodCount: MISMOCount;
    loanAmortizationPeriodType: LoanAmortizationPeriodType;
  }>;

type AmortizationScheduleItem = MISMOEntity &
  Partial<{
    amortizationScheduleEndingBalanceAmount: MISMOAmount;
    amortizationScheduleInterestPortionAmount: MISMOAmount;
    amortizationScheduleInterestRatePercent: MISMOPercent;
    amortizationScheduleLoanToValuePercent: MISMOPercent;
    amortizationScheduleMIPaymentAmount: MISMOAmount;
    amortizationSchedulePaymentAmount: MISMOAmount;
    amortizationSchedulePaymentDueDate: MISMODate;
    amortizationSchedulePaymentNumber: MISMONumeric;
    amortizationSchedulePrincipalPortionAmount: MISMOAmount;
    amortizationScheduleUSDAAnnualFeePaymentAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type Amortization = MISMOEntity &
  Partial<{
    amortizationRule: AmortizationRule;
    amortizationScheduleItems: AmortizationScheduleItem[];
  }>;
