import {
  CounterpartyAction,
  NotificationType,
  TradeCounterpartyActionType,
  TradeCounterpartyStatus,
  TradeRequestTabType,
} from '../types';

const BUYER_COUNTERPARTY_ACTIONS_TYPES_ALLOWING_MESSAGING = [
  TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST,
  TradeCounterpartyActionType.REQUEST_DATA_REQUEST,
  TradeCounterpartyActionType.MARK_AS_SOLD,
  TradeCounterpartyActionType.MARK_AS_SETTLED,
];

export const canBuyerCounterpartyExchangeMessages = (
  actionTypes: TradeCounterpartyActionType[]
) => {
  if (actionTypes.length === 0) {
    return false;
  }

  return actionTypes.some((actionType) =>
    BUYER_COUNTERPARTY_ACTIONS_TYPES_ALLOWING_MESSAGING.includes(actionType)
  );
};

export const hasSellerCounterpartyExpressedInterest = (
  actionTypes: TradeCounterpartyActionType[]
) => {
  if (actionTypes.length === 0) {
    return false;
  }

  return actionTypes.some(
    (actionType) =>
      actionType === TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST
  );
};

export const isCounterpartySold = (
  actionTypes: TradeCounterpartyActionType[]
) => actionTypes.includes(TradeCounterpartyActionType.MARK_AS_SOLD);

export const isCounterpartySettled = (
  actionTypes: TradeCounterpartyActionType[]
) => actionTypes.includes(TradeCounterpartyActionType.MARK_AS_SETTLED);

const COUNTERPARTY_ACTIONS_TO_STATUSES_MAP: Record<
  TradeCounterpartyActionType,
  TradeCounterpartyStatus
> = {
  [TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST]:
    TradeCounterpartyStatus.EXPRESSED_INTEREST,
  [TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST]:
    TradeCounterpartyStatus.EXPRESSED_INTEREST_ACCEPTED,
  [TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST]:
    TradeCounterpartyStatus.EXPRESSED_INTEREST_DECLINED,
  [TradeCounterpartyActionType.REQUEST_DATA_REQUEST]:
    TradeCounterpartyStatus.DATA_REQUESTED,
  [TradeCounterpartyActionType.ACCEPT_DATA_REQUEST]:
    TradeCounterpartyStatus.DATA_REQUEST_ACCEPTED,
  [TradeCounterpartyActionType.DENY_DATA_REQUEST]:
    TradeCounterpartyStatus.DATA_REQUEST_DECLINED,
  [TradeCounterpartyActionType.MARK_AS_SOLD]: TradeCounterpartyStatus.SOLD,
  [TradeCounterpartyActionType.MARK_AS_SETTLED]:
    TradeCounterpartyStatus.SETTLED,
};

const EXPECTED_STATUSES_MAP: Record<
  TradeCounterpartyStatus,
  TradeCounterpartyStatus[]
> = {
  [TradeCounterpartyStatus.PENDING]: [
    TradeCounterpartyStatus.EXPRESSED_INTEREST,
  ],
  [TradeCounterpartyStatus.EXPRESSED_INTEREST]: [
    TradeCounterpartyStatus.EXPRESSED_INTEREST_ACCEPTED,
    TradeCounterpartyStatus.EXPRESSED_INTEREST_DECLINED,
  ],
  [TradeCounterpartyStatus.EXPRESSED_INTEREST_ACCEPTED]: [
    TradeCounterpartyStatus.DATA_REQUESTED,
  ],
  [TradeCounterpartyStatus.EXPRESSED_INTEREST_DECLINED]: [
    TradeCounterpartyStatus.EXPRESSED_INTEREST,
  ],
  [TradeCounterpartyStatus.DATA_REQUESTED]: [
    TradeCounterpartyStatus.DATA_REQUEST_ACCEPTED,
    TradeCounterpartyStatus.DATA_REQUEST_DECLINED,
  ],
  [TradeCounterpartyStatus.DATA_REQUEST_ACCEPTED]: [
    TradeCounterpartyStatus.SOLD,
  ],
  [TradeCounterpartyStatus.DATA_REQUEST_DECLINED]: [
    TradeCounterpartyStatus.DATA_REQUESTED,
  ],
  [TradeCounterpartyStatus.SOLD]: [TradeCounterpartyStatus.SETTLED],
  [TradeCounterpartyStatus.SETTLED]: [TradeCounterpartyStatus.SETTLED],
};

export const getLatestAction = (actions: CounterpartyAction[]) => {
  const sortedActions = [...actions].sort((actionA, actionB) => {
    const actionACreationDate = actionA.createdAt
      ? new Date(actionA.createdAt)
      : undefined;
    const actionBCreationDate = actionB.createdAt
      ? new Date(actionB.createdAt)
      : undefined;

    return (
      (actionACreationDate?.getTime() || 0) -
      (actionBCreationDate?.getTime() || 0)
    );
  });

  return sortedActions[sortedActions.length - 1];
};

export const getLatestCounterpartyStatusFromActions = (
  actions: CounterpartyAction[]
) => {
  if (actions.length === 0) {
    return TradeCounterpartyStatus.PENDING;
  }

  const latestAction = getLatestAction(actions);

  return COUNTERPARTY_ACTIONS_TO_STATUSES_MAP[latestAction.type];
};

export const getNextExpectedStatuses = (
  targetStatus: TradeCounterpartyStatus
) => {
  return EXPECTED_STATUSES_MAP[targetStatus];
};

export const getTradeTabForNotificationType = (
  notificationType: NotificationType
): TradeRequestTabType | undefined => {
  switch (notificationType) {
    case NotificationType.TRADE_SELLER_INVITED_COUNTERPARTY:
    case NotificationType.TRADE_SELLER_ACCEPTED_STIPULATIONS:
    case NotificationType.TRADE_SELLER_DECLINED_STIPULATIONS:
    case NotificationType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST:
      return TradeRequestTabType.EXPRESSIONS_OF_INTEREST;
    case NotificationType.TRADE_COUNTERPARTY_REQUESTED_DATA:
    case NotificationType.TRADE_SELLER_ACCEPTED_DATA_REQUEST:
    case NotificationType.TRADE_SELLER_DECLINED_DATA_REQUEST:
      return TradeRequestTabType.DATA_REQUESTS;
    case NotificationType.TRADE_SELLER_ACCEPTED_QUOTE:
    case NotificationType.TRADE_SELLER_REJECTED_QUOTE:
    case NotificationType.TRADE_SELLER_CREATED_QUOTE_COUNTER:
    case NotificationType.TRADE_SELLER_ACCEPTED_QUOTE_COUNTER:
    case NotificationType.TRADE_COUNTERPARTY_CREATED_QUOTE:
    case NotificationType.TRADE_COUNTERPARTY_CREATED_QUOTE_COUNTER:
    case NotificationType.TRADE_COUNTERPARTY_ACCEPTED_QUOTE_COUNTER:
      return TradeRequestTabType.QUOTES;
    case NotificationType.TRADE_SELLER_SENT_DIRECT_MESSAGE:
    case NotificationType.TRADE_COUNTERPARTY_SENT_DIRECT_MESSAGE:
      return TradeRequestTabType.MESSAGES;
    default:
      return undefined;
  }
};

export const getNotificationTypesForTradeTab = (
  tabType: TradeRequestTabType
): NotificationType[] => {
  switch (tabType) {
    case TradeRequestTabType.EXPRESSIONS_OF_INTEREST:
      return [
        NotificationType.TRADE_SELLER_INVITED_COUNTERPARTY,
        NotificationType.TRADE_SELLER_ACCEPTED_STIPULATIONS,
        NotificationType.TRADE_SELLER_DECLINED_STIPULATIONS,
        NotificationType.TRADE_COUNTERPARTY_EXPRESSED_INTEREST,
      ];
    case TradeRequestTabType.DATA_REQUESTS:
      return [
        NotificationType.TRADE_COUNTERPARTY_REQUESTED_DATA,
        NotificationType.TRADE_SELLER_ACCEPTED_DATA_REQUEST,
        NotificationType.TRADE_SELLER_DECLINED_DATA_REQUEST,
      ];
    case TradeRequestTabType.QUOTES:
      return [
        NotificationType.TRADE_SELLER_ACCEPTED_QUOTE,
        NotificationType.TRADE_SELLER_REJECTED_QUOTE,
        NotificationType.TRADE_SELLER_CREATED_QUOTE_COUNTER,
        NotificationType.TRADE_SELLER_ACCEPTED_QUOTE_COUNTER,
        NotificationType.TRADE_COUNTERPARTY_CREATED_QUOTE,
        NotificationType.TRADE_COUNTERPARTY_CREATED_QUOTE_COUNTER,
        NotificationType.TRADE_COUNTERPARTY_ACCEPTED_QUOTE_COUNTER,
      ];
    case TradeRequestTabType.MESSAGES:
      return [
        NotificationType.TRADE_SELLER_SENT_DIRECT_MESSAGE,
        NotificationType.TRADE_COUNTERPARTY_SENT_DIRECT_MESSAGE,
      ];
    default:
      return [];
  }
};
