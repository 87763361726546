import { Exemption } from './exemption';
import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOPercent,
  MISMOString,
  MISMOValue,
} from './types';

export enum AbilityToRepayMethodType {
  Exempt = 'Exempt',
  General = 'General',
  QualifiedMortgage = 'QualifiedMortgage',
}

export enum QualifiedMortgageTemporaryGSEType {
  FannieMae = 'FannieMae',
  FreddieMac = 'FreddieMac',
}

export enum PresumptionOfComplianceType {
  RebuttablePresumption = 'RebuttablePresumption',
  SafeHarbor = 'SafeHarbor',
}

export enum QualifiedMortgageType {
  BalloonPayment = 'BalloonPayment',
  NotApplicable = 'NotApplicable',
  NotAQualifiedMortgage = 'NotAQualifiedMortgage',
  Other = 'Other',
  SmallCreditor = 'SmallCreditor',
  SmallCreditorRural = 'SmallCreditorRural',
  Standard = 'Standard',
  Temporary = 'Temporary',
  TemporaryAgencyGSE = 'TemporaryAgencyGSE',
  TemporarySmallCreditorBalloon = 'TemporarySmallCreditorBalloon',
}

export type QualifiedMortgage = MISMOEntity &
  Partial<{
    abilityToRepayInterestRateDebtExpenseRatioPercent: MISMOPercent;
    abilityToRepayMethodType: AbilityToRepayMethodType;
    portfolioPeriodEffectiveMonthsCount: MISMOCount;
    presumptionOfComplianceType: PresumptionOfComplianceType;
    qualifiedMortgageHighestRateDebtExpenseRatioPercent: MISMOPercent;
    qualifiedMortgageMinimumRequiredFICOScoreValue: MISMOValue;
    qualifiedMortgageTemporaryGSEType: QualifiedMortgageTemporaryGSEType;
    qualifiedMortgageTotalPointsAndFeesThresholdAmount: MISMOAmount;
    qualifiedMortgageTotalPointsAndFeesThresholdPercent: MISMOPercent;
    qualifiedMortgageType: QualifiedMortgageType;
    qualifiedMortgageTypeOtherDescription: MISMOString;
    safeHarborThresholdAmount: MISMOAmount;
    exemptions: Exemption[];
  }>;
