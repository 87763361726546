import {
  GetPortfolioGeodataPropertiesItem,
  PROPERTY_MAP_PIN_TITLE_PREFIX,
} from '@plus-platform/shared';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import React from 'react';

import mapMarker from '../../assets/map-marker.svg';
import { PropertyInfoWindow } from './PropertyInfoWindow';

type PropertyMarkerProps = {
  property: GetPortfolioGeodataPropertiesItem;
  clusterer?: Clusterer;
  onClick?: () => void;
};

export const PropertyMarker = ({ clusterer, onClick, property }: PropertyMarkerProps) => {
  const { fullAddress, geolocationLatitude, geolocationLongitude, id: propertyId } = property;
  const [isSummaryVisible, setIsSummaryVisible] = React.useState(false);
  const markerRef = React.useRef<Marker | null>(null);

  const openSummary = () => {
    setIsSummaryVisible(true);
  };

  const closeSummary = () => {
    setIsSummaryVisible(false);
  };

  if (!geolocationLatitude || !geolocationLongitude) {
    return null;
  }

  const position = {
    lat: Number(geolocationLatitude),
    lng: Number(geolocationLongitude),
  };

  return (
    <React.Fragment>
      <Marker
        icon={mapMarker}
        onClick={onClick}
        onMouseOut={closeSummary}
        onMouseOver={openSummary}
        position={position}
        clusterer={clusterer}
        ref={markerRef}
        options={{
          title: `${PROPERTY_MAP_PIN_TITLE_PREFIX}${fullAddress}`,
        }}
      />
      {isSummaryVisible && markerRef.current?.marker && (
        <InfoWindow
          anchor={markerRef.current?.marker}
          onCloseClick={closeSummary}
          options={{ disableAutoPan: true }}
        >
          <PropertyInfoWindow propertyId={propertyId} />
        </InfoWindow>
      )}
    </React.Fragment>
  );
};
