import { ellipsis } from 'polished';
import styled, { css } from 'styled-components/macro';

import { MenuItemButton } from '../components/Menu.styles';

export const PopupTitleWrapper = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary03};
`;

export const MenuSectionWrapper = styled.div`
  width: 236px;
  border-right: 1px solid ${({ theme }) => theme.colors.primary03};
`;

export const AddButtonWrapper = styled.div`
  padding: 4px 8px;
`;

export const MenuContactsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 560px;
`;

export const SearchWrapper = styled.div`
  padding: 8px 12px;
`;

export const ContactsWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const ContactsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;

export const GroupSelector = styled(MenuItemButton)(
  ({ theme }) => css`
    &:disabled {
      opacity: 1;
      background-color: ${theme.colors.primary03};
    }
  `
);

export const ContactList = styled.ul`
  list-style: none;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
`;

export const ContactListItem = styled.li``;

export const ContactAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  min-width: 128px;
`;

export const ContactName = styled.span`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary01};
  ${({ theme }) => theme.typography.bodyText.bodyMedium02}

  ${ellipsis()}
`;

export const ContactOrganization = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  color: ${({ theme }) => theme.colors.textPrimary02};
  ${({ theme }) => theme.typography.bodyText.bodyRegular02}
`;

export const ContactLabel = styled.label<{ $isSelected?: boolean }>`
  ${({ $isSelected, theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 12px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${theme.colors.primary04};

    ${$isSelected &&
    css`
      background-color: ${theme.colors.primary04};
    `}

    &:hover {
      background-color: ${theme.colors.primary04};
      border-color: ${theme.colors.primary04};
    }
  `}
`;
