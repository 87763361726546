import React from 'react';

import * as Styles from './ButtonIcon.styles';

type ButtonIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'small' | 'medium' | 'large';
  variant?: 'light' | 'dark';
};

export const ButtonIcon = ({
  children,
  size = 'large',
  variant = 'light',
  ...rest
}: ButtonIconProps) => {
  return (
    <Styles.Button type="button" $size={size} $variant={variant} {...rest}>
      {children}
    </Styles.Button>
  );
};
