import { BreakdownType, PortfolioSummary } from '@plus-platform/shared';
import React from 'react';

import { ButtonIcon } from '../../components/ButtonIcon';
import { ChevronDownIcon, ChevronUpIcon } from '../../components/icons';
import { Skeleton } from '../../components/Skeleton';
import { usePortfolioAnalysisQuery } from '../../hooks/queries';
import { usePortfolioBreakdownQuery } from '../../hooks/queries/usePortfolioBreakdownQuery';
import { CreditMetrics } from './CreditMetrics';
import { DelinquencyByDaysMetrics } from './DelinquencyByDaysMetrics';
import { DelinquencyTotalMetrics } from './DelinquencyTotalMetrics';
import { DetailedDataMetrics } from './DetailedDataMetrics';
import { InterestRateSensitivityMetrics } from './InterestRateSensitivityMetrics';
import { LoanTypesByMetrics } from './LoanTypesByMetrics/LoanTypesByMetrics';
import { LoanTypesMetrics } from './LoanTypesMetrics';
import { Analytics, Container } from './PortfolioBreakdown.styles';
import { RiskMetrics } from './RiskMetrics';
import { SummaryMetrics } from './SummaryMetrics';
import { ValuationMetrics } from './ValuationMetrics';

type PortfolioBreakdownProps = {
  breakdownTypes: BreakdownType[];
  portfolioSummary?: PortfolioSummary;
};

export const PortfolioBreakdown = (props: PortfolioBreakdownProps) => {
  const { breakdownTypes, portfolioSummary } = props;
  const [isDetailedDataMetricsToggled, setIsDetailedDataMetricsToggled] = React.useState(false);

  // TODO: Consider creating a different endpoint, or replacing this one, with
  // implementation that returns metrics data only for a set of chosen metrics, instead of
  // returning all the data for all metrics. Potentially, move portfolio summary data
  // there as well.
  const { data: portfolioBreakdown } = usePortfolioBreakdownQuery();
  const { data: portfolioAnalysis, isLoading: isLoadingPortfolioAnalysis } =
    usePortfolioAnalysisQuery();

  const hasAnalyticTiles = Boolean(breakdownTypes.length);
  const shouldDisplayDetailedDataMetrics = isDetailedDataMetricsToggled || !hasAnalyticTiles;

  const isTypeEnabled = (type: BreakdownType) => {
    return breakdownTypes.includes(type);
  };

  const toggleDetailedDataMetrics = () => {
    setIsDetailedDataMetricsToggled((displayDetailedDataMetrics) => !displayDetailedDataMetrics);
  };

  if (!portfolioSummary || !portfolioBreakdown || isLoadingPortfolioAnalysis) {
    return <Skeleton height={244} />;
  }

  const { loansInDefault } = portfolioSummary;

  return (
    <Container data-testid="InvestorHomePage_AtAGlance">
      {!shouldDisplayDetailedDataMetrics && (
        <Analytics>
          {isTypeEnabled(BreakdownType.LOAN_COUNT) && (
            <SummaryMetrics
              loanCount={portfolioSummary.loansCount}
              upbAmount={portfolioSummary.loansUPBAmount}
              remittanceAmount={portfolioSummary.loansRemittanceAmount}
              weightedAverageCoupon={portfolioSummary.loansWeightedAverageCoupon}
              weightedAverageLTV={portfolioSummary.loansWeightedAverageLTV}
              judicialCount={portfolioSummary.judicialCount}
              nonJudicialCount={portfolioSummary.nonJudicialCount}
            />
          )}

          {isTypeEnabled(BreakdownType.LOANS_IN_DEFAULT) && (
            <DelinquencyTotalMetrics
              loanCount={loansInDefault.total?.loanCount}
              upbAmount={loansInDefault.total?.upb}
              pAndIArrearageAmount={loansInDefault.total?.outstandingRemittance}
              weightedAverageCoupon={loansInDefault.total?.weightedAverageCoupon}
              weightedAverageLTV={loansInDefault.total?.weightedAverageLTV}
              judicialCount={loansInDefault.total?.judicialCount}
              nonJudicialCount={loansInDefault.total?.nonJudicialCount}
            />
          )}

          {isTypeEnabled(BreakdownType.LOAN_TYPES) && (
            <LoanTypesMetrics portfolioBreakdown={portfolioBreakdown} />
          )}

          {isTypeEnabled(BreakdownType.LOAN_TYPES_BY_NOTE_RATE) && (
            <LoanTypesByMetrics portfolioBreakdown={portfolioBreakdown} />
          )}

          {isTypeEnabled(BreakdownType.DEFAULT_30_DAY) && (
            <DelinquencyByDaysMetrics
              title="30-day default"
              variant="30-day"
              loanCount={loansInDefault.thirtyDays?.loanCount}
              upbAmount={loansInDefault.thirtyDays?.upb}
              pAndIArrearageAmount={loansInDefault.thirtyDays?.outstandingRemittance}
              weightedAverageCoupon={loansInDefault.thirtyDays?.weightedAverageCoupon}
              weightedAverageLTV={loansInDefault.thirtyDays?.weightedAverageLTV}
              judicialCount={loansInDefault.thirtyDays?.judicialCount}
              nonJudicialCount={loansInDefault.thirtyDays?.nonJudicialCount}
              delinquency30DayCount={loansInDefault.thirtyDays?.loanCount}
              delinquency60DayCount={loansInDefault.sixtyDays?.loanCount}
              delinquency90DayCount={loansInDefault.ninetyDays?.loanCount}
            />
          )}

          {isTypeEnabled(BreakdownType.DEFAULT_60_DAY) && (
            <DelinquencyByDaysMetrics
              title="60-day default"
              variant="60-day"
              loanCount={loansInDefault.sixtyDays?.loanCount}
              upbAmount={loansInDefault.sixtyDays?.upb}
              pAndIArrearageAmount={loansInDefault.sixtyDays?.outstandingRemittance}
              weightedAverageCoupon={loansInDefault.sixtyDays?.weightedAverageCoupon}
              weightedAverageLTV={loansInDefault.sixtyDays?.weightedAverageLTV}
              judicialCount={loansInDefault.sixtyDays?.judicialCount}
              nonJudicialCount={loansInDefault.sixtyDays?.nonJudicialCount}
              delinquency30DayCount={loansInDefault.thirtyDays?.loanCount}
              delinquency60DayCount={loansInDefault.sixtyDays?.loanCount}
              delinquency90DayCount={loansInDefault.ninetyDays?.loanCount}
            />
          )}

          {isTypeEnabled(BreakdownType.DEFAULT_90_DAY) && (
            <DelinquencyByDaysMetrics
              title="90-day default"
              variant="90-day"
              loanCount={loansInDefault.ninetyDays?.loanCount}
              upbAmount={loansInDefault.ninetyDays?.upb}
              pAndIArrearageAmount={loansInDefault.ninetyDays?.outstandingRemittance}
              weightedAverageCoupon={loansInDefault.ninetyDays?.weightedAverageCoupon}
              weightedAverageLTV={loansInDefault.ninetyDays?.weightedAverageLTV}
              judicialCount={loansInDefault.ninetyDays?.judicialCount}
              nonJudicialCount={loansInDefault.ninetyDays?.nonJudicialCount}
              delinquency30DayCount={loansInDefault.thirtyDays?.loanCount}
              delinquency60DayCount={loansInDefault.sixtyDays?.loanCount}
              delinquency90DayCount={loansInDefault.ninetyDays?.loanCount}
            />
          )}

          {isTypeEnabled(BreakdownType.VALUATION_METRICS) && (
            <ValuationMetrics
              marketValue={portfolioAnalysis?.marketValue}
              marketValueChange={portfolioAnalysis?.marketChangePercentage}
              yieldCC={portfolioAnalysis?.yieldCc}
              yieldFC={portfolioAnalysis?.yieldFc}
              oas={portfolioAnalysis?.oas}
            />
          )}

          {isTypeEnabled(BreakdownType.RISK_METRICS) && (
            <RiskMetrics
              wal={portfolioAnalysis?.wal}
              walCC={portfolioAnalysis?.walCc}
              walFC={portfolioAnalysis?.walFc}
              cumulativeLoss={portfolioAnalysis?.cumLoss}
              expectedShortfall={portfolioAnalysis?.expShortfall}
            />
          )}

          {isTypeEnabled(BreakdownType.CREDIT_METRICS) && <CreditMetrics />}

          {isTypeEnabled(BreakdownType.INTEREST_RATE_SENSITIVITY) && (
            <InterestRateSensitivityMetrics portfolioBreakdown={portfolioBreakdown} />
          )}
        </Analytics>
      )}

      {shouldDisplayDetailedDataMetrics && (
        <DetailedDataMetrics portfolioBreakdown={portfolioBreakdown} />
      )}

      {hasAnalyticTiles && (
        <ButtonIcon onClick={toggleDetailedDataMetrics} data-testid="Breakdown_Button">
          {!isDetailedDataMetricsToggled && <ChevronDownIcon />}
          {isDetailedDataMetricsToggled && <ChevronUpIcon />}
        </ButtonIcon>
      )}
    </Container>
  );
};
