import React from 'react';

export const BubbleChartFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2222 20C13.4815 20 12.8519 19.7407 12.3333 19.2222C11.8148 18.7037 11.5556 18.0741 11.5556 17.3333C11.5556 16.5926 11.8148 15.963 12.3333 15.4444C12.8519 14.9259 13.4815 14.6667 14.2222 14.6667C14.963 14.6667 15.5926 14.9259 16.1111 15.4444C16.6296 15.963 16.8889 16.5926 16.8889 17.3333C16.8889 18.0741 16.6296 18.7037 16.1111 19.2222C15.5926 19.7407 14.963 20 14.2222 20ZM16 13.7778C14.637 13.7778 13.4815 13.3037 12.5333 12.3556C11.5852 11.4074 11.1111 10.2519 11.1111 8.88889C11.1111 7.52593 11.5852 6.37037 12.5333 5.42222C13.4815 4.47407 14.637 4 16 4C17.363 4 18.5185 4.47407 19.4667 5.42222C20.4148 6.37037 20.8889 7.52593 20.8889 8.88889C20.8889 10.2519 20.4148 11.4074 19.4667 12.3556C18.5185 13.3037 17.363 13.7778 16 13.7778ZM7.55556 17.3333C6.57778 17.3333 5.74074 16.9852 5.04444 16.2889C4.34815 15.5926 4 14.7556 4 13.7778C4 12.8 4.34815 11.963 5.04444 11.2667C5.74074 10.5704 6.57778 10.2222 7.55556 10.2222C8.53333 10.2222 9.37037 10.5704 10.0667 11.2667C10.763 11.963 11.1111 12.8 11.1111 13.7778C11.1111 14.7556 10.763 15.5926 10.0667 16.2889C9.37037 16.9852 8.53333 17.3333 7.55556 17.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};
