import { TradeRequestMessageType, TradeRequestWithSummary } from '@plus-platform/shared';
import React from 'react';

import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { SendIcon } from '../../../components/icons';
import { InputText } from '../../../components/InputText';
import { MessageText, UserMessage } from '../../../components/Messages';
import { DateDivider } from '../../../components/Messages/DateDivider';
import { shouldDisplayDateDivider } from '../../../components/Messages/messengerUtils';
import * as MessengerStyles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import * as Styles from './TradeRequestDirectMessaging.styles';
import { MessagingParticipant } from './types';
import { useMessages } from './useMessages';

type TradeRequestDirectMessagingProps = {
  tradeRequest: TradeRequestWithSummary;
  tradeCounterpartyId: string;
  recipient: MessagingParticipant;
};

export const TradeRequestDirectMessaging = (props: TradeRequestDirectMessagingProps) => {
  const { recipient, tradeCounterpartyId, tradeRequest } = props;
  const [message, setMessage] = React.useState('');

  const { isSending, loadMore, messages, send } = useMessages({
    tradeRequestId: tradeRequest.id,
    tradeCounterpartyId,
    messageType: TradeRequestMessageType.MESSAGE,
  });

  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    send(message);
    setMessage('');
  };

  return (
    <React.Fragment>
      <MessengerStyles.Header>
        <Heading $size="medium">{recipient.userName}</Heading>
      </MessengerStyles.Header>
      <MessengerList loadMore={loadMore} messages={messages}>
        {({ message }) => {
          const previousMessage = messages[messages.indexOf(message) - 1];

          const counterpartyOrganizationName =
            message.fromCounterparty.user.organization.tradingName;

          return (
            <React.Fragment>
              {message.createdAt &&
                shouldDisplayDateDivider(message.createdAt, previousMessage?.createdAt) && (
                  <DateDivider date={new Date(message.createdAt)} variant="outline" />
                )}

              <UserMessage
                date={message.createdAt ? new Date(message.createdAt) : undefined}
                organizationName={counterpartyOrganizationName}
                lastName={message.fromCounterparty.user.lastName}
                firstName={message.fromCounterparty.user.firstName}
                userAvatar={message.fromCounterparty.user.avatarUrl}
              >
                <MessageText>{message.content}</MessageText>
              </UserMessage>
            </React.Fragment>
          );
        }}
      </MessengerList>
      <Styles.Form onSubmit={handleSendMessage}>
        <InputText
          autoFocus
          disabled={isSending}
          onChange={(event) => setMessage((event.target as EventTarget & HTMLInputElement).value)}
          label={`Message ${recipient.userName}`}
          type="text"
          value={message}
          $width="100%"
          actionButton={
            <Button type="submit">
              <SendIcon />
            </Button>
          }
        />
      </Styles.Form>
    </React.Fragment>
  );
};
