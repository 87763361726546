import React from 'react';

export const AppearanceFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 20C10.9067 20 9.87333 19.79 8.9 19.37C7.92667 18.95 7.07667 18.3767 6.35 17.65C5.62333 16.9233 5.05 16.0733 4.63 15.1C4.21 14.1267 4 13.0933 4 12C4 10.88 4.21333 9.83333 4.64 8.86C5.06667 7.88667 5.65 7.04 6.39 6.32C7.13 5.6 7.99667 5.03333 8.99 4.62C9.98333 4.20667 11.0467 4 12.18 4C13.2333 4 14.2333 4.17667 15.18 4.53C16.1267 4.88333 16.9567 5.37333 17.67 6C18.3833 6.62667 18.95 7.37 19.37 8.23C19.79 9.09 20 10.0333 20 11.06C20 12.5 19.58 13.6367 18.74 14.47C17.9 15.3033 16.7867 15.72 15.4 15.72H13.9C13.66 15.72 13.4533 15.8133 13.28 16C13.1067 16.1867 13.02 16.3933 13.02 16.62C13.02 16.8867 13.1167 17.14 13.31 17.38C13.5033 17.62 13.6 17.9067 13.6 18.24C13.6 18.5867 13.4367 18.9667 13.11 19.38C12.7833 19.7933 12.4133 20 12 20ZM7.34 12.52C7.60667 12.52 7.84 12.42 8.04 12.22C8.24 12.02 8.34 11.7867 8.34 11.52C8.34 11.2533 8.24 11.02 8.04 10.82C7.84 10.62 7.60667 10.52 7.34 10.52C7.07333 10.52 6.84 10.62 6.64 10.82C6.44 11.02 6.34 11.2533 6.34 11.52C6.34 11.7867 6.44 12.02 6.64 12.22C6.84 12.42 7.07333 12.52 7.34 12.52ZM9.86 9.12C10.1267 9.12 10.36 9.02 10.56 8.82C10.76 8.62 10.86 8.38667 10.86 8.12C10.86 7.85333 10.76 7.62 10.56 7.42C10.36 7.22 10.1267 7.12 9.86 7.12C9.59333 7.12 9.36 7.22 9.16 7.42C8.96 7.62 8.86 7.85333 8.86 8.12C8.86 8.38667 8.96 8.62 9.16 8.82C9.36 9.02 9.59333 9.12 9.86 9.12ZM14.14 9.12C14.4067 9.12 14.64 9.02 14.84 8.82C15.04 8.62 15.14 8.38667 15.14 8.12C15.14 7.85333 15.04 7.62 14.84 7.42C14.64 7.22 14.4067 7.12 14.14 7.12C13.8733 7.12 13.64 7.22 13.44 7.42C13.24 7.62 13.14 7.85333 13.14 8.12C13.14 8.38667 13.24 8.62 13.44 8.82C13.64 9.02 13.8733 9.12 14.14 9.12ZM16.76 12.52C17.0267 12.52 17.26 12.42 17.46 12.22C17.66 12.02 17.76 11.7867 17.76 11.52C17.76 11.2533 17.66 11.02 17.46 10.82C17.26 10.62 17.0267 10.52 16.76 10.52C16.4933 10.52 16.26 10.62 16.06 10.82C15.86 11.02 15.76 11.2533 15.76 11.52C15.76 11.7867 15.86 12.02 16.06 12.22C16.26 12.42 16.4933 12.52 16.76 12.52Z"
        fill="currentColor"
      />
    </svg>
  );
};
