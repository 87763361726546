import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum RoomFeatureType {
  BathroomFloors = 'BathroomFloors',
  BathroomWainscot = 'BathroomWainscot',
  Doors = 'Doors',
  Other = 'Other',
  TrimAndFinish = 'TrimAndFinish',
}

export type RoomFeature = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    roomFeatureDescription: MISMOString;
    roomFeatureType: RoomFeatureType;
    roomFeatureTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
