import { InboundTradeRequestWithSummary } from '@plus-platform/shared';

import { Button } from '../../../../components/Button';
import { MessageActions, MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { useTradeRequestInboundNavigation } from '../../../TradeRequestTickets/useTradeRequestNavigation';

type InboundCompletedProps = {
  tradeRequest: InboundTradeRequestWithSummary;
};

export const InboundCompleted = (props: InboundCompletedProps) => {
  const { goToCompletedTrades } = useTradeRequestInboundNavigation();

  return (
    <PlusMessage>
      <MessageText>
        <TradeMessage
          id="Trade.Inbound.Completed"
          values={{
            poolName: props.tradeRequest.pool.name,
          }}
        />
      </MessageText>
      <MessageActions>
        <Button $size="large" onClick={() => goToCompletedTrades()}>
          View completed trades
        </Button>
      </MessageActions>
    </PlusMessage>
  );
};
