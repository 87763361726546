import { PortfolioHomeAnalyticGroupType, RecommendedAnalyticType } from '@plus-platform/shared';
import React from 'react';

import { Button } from '../../components/Button';
import { PopupContent, PopupFooter } from '../../components/Popup';
import { Skeleton } from '../../components/Skeleton';
import { Stack } from '../../components/Stack';
import { usePortfolioHomeAnalyticsPreviewQuery } from '../../hooks/queries';
import { HomeAnalyticTile } from '../analytics/HomeAnalyticTile';
import { Analytics, Tag, Tags, Title } from './BrilliantPreview.styles';
import { Subtitle } from './styles';

type BrilliantPreviewProps = {
  onAdd: () => void;
  onCancel: () => void;
};

export const BrilliantDataPreview = (props: BrilliantPreviewProps) => {
  const { onAdd, onCancel } = props;

  const { isLoading, data: analytics = [] } = usePortfolioHomeAnalyticsPreviewQuery(
    PortfolioHomeAnalyticGroupType.RECOMMENDED,
    RecommendedAnalyticType.BRILLIANT
  );

  return (
    <React.Fragment>
      <PopupContent>
        <Title>Brilliant</Title>
        <Subtitle>
          The Brilliant Capsule is perfectly balanced at the point between depth and brevity -
          useful, functional, sparse.
        </Subtitle>
        <Tags>
          <Tag>4 Data Tiles</Tag>
          <Tag>2 Q&A</Tag>
          <Tag>2 Core Metrics</Tag>
        </Tags>

        {isLoading && <Skeleton height={224} />}

        {!isLoading && (
          <Analytics>
            {analytics.map((analytic, index) => (
              <HomeAnalyticTile {...analytic} key={index} />
            ))}
          </Analytics>
        )}
      </PopupContent>

      <PopupFooter>
        <Stack>
          <Button $size="large" $color="secondary" $variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button $size="large" onClick={onAdd}>
            Add
          </Button>
        </Stack>
      </PopupFooter>
    </React.Fragment>
  );
};
