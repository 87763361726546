import {
  OutboundTradeRequestWithSummary,
  QuoteMessageType,
  TradeQuoteType,
  TradeRequestMessageType,
  TradeRequestQuote,
} from '@plus-platform/shared';
import React from 'react';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Heading } from '../../../components/Heading';
import { DateDivider } from '../../../components/Messages/DateDivider';
import { shouldDisplayDateDivider } from '../../../components/Messages/messengerUtils';
import { useTradeRequestOutboundQuotesQuery } from '../../../hooks/queries';
import { useTradeRequestOutboundNavigation } from '../../TradeRequestTickets/useTradeRequestNavigation';
import * as Styles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import { useTabMessages } from '../TradeRequestDirectMessaging/useMessages';
import { OutboundCompleted } from './Messages/OutboundCompleted';
import { OutboundQuoteAcceptRejectCounter } from './Messages/OutboundQuoteAcceptRejectCounter';
import { OutboundQuoteCounterAccepted } from './Messages/OutboundQuoteCounterAccepted';
import { OutboundQuoteCounterAcceptRejectCounter } from './Messages/OutboundQuoteCounterAcceptRejectCounter';
import { OutboundQuoteCountered } from './Messages/OutboundQuoteCountered';
import { OutboundQuoteCounterRejected } from './Messages/OutboundQuoteCounterRejected';
import { OutboundQuotePending } from './Messages/OutboundQuotePending';
import { OutboundQuoteRejected } from './Messages/OutboundQuoteRejected';
import { isLastMessage } from './quoteUtils';
import { TradeQuoteConfirmation } from './TradeQuoteConfirmation';

type OutboundTradeRequestQuotesProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundTradeRequestQuotes = (props: OutboundTradeRequestQuotesProps) => {
  const { tradeRequest } = props;
  const { data: quotes = [] } = useTradeRequestOutboundQuotesQuery(tradeRequest.id);
  const [selectedQuoteIdForConfirmation, setSelectedQuoteIdForConfirmation] = React.useState<
    TradeRequestQuote['id'] | undefined
  >();

  const { isLoading, messages } = useTabMessages({
    tradeRequestId: tradeRequest.id,
    messageType: TradeRequestMessageType.QUOTE,
  });

  const { goToCompletedTrades } = useTradeRequestOutboundNavigation();

  const clearSelectedQuote = () => {
    setSelectedQuoteIdForConfirmation(undefined);
  };

  const filteredQuotes = quotes.filter((quote) => !quote.linkedQuoteId);
  const totalQuoteCount = filteredQuotes.length;

  return (
    <React.Fragment>
      {selectedQuoteIdForConfirmation && (
        <TradeQuoteConfirmation
          onAccept={() => {
            clearSelectedQuote();
            goToCompletedTrades();
          }}
          onCancel={clearSelectedQuote}
          quoteId={selectedQuoteIdForConfirmation}
          tradeRequestId={tradeRequest.id}
        />
      )}

      {!selectedQuoteIdForConfirmation && (
        <ActivityIndicator contain isActive={isLoading}>
          <Styles.Header>
            <Heading $size="medium">Bids</Heading>
            <Styles.IntroText>
              This is the very beginning of Bids on trading pool{' '}
              <strong>{tradeRequest.pool.name}</strong> ticket ID <strong>{tradeRequest.id}</strong>
              .
            </Styles.IntroText>
          </Styles.Header>
          {/* TODO: Add pagination to quotes request and pass loadMore fn */}
          <MessengerList messages={messages}>
            {({ measure, message }) => {
              const currentQuoteIndex = message?.metadata?.quote
                ? quotes.findIndex(({ id }) => message?.metadata?.quote?.id === id)
                : -1;
              const previousMessage = messages[messages.length - 1];

              const previousMessageCreationDate = previousMessage?.metadata?.quote?.createdAt
                ? new Date(previousMessage.metadata.quote.createdAt)
                : undefined;
              const messageCreationDate = message?.metadata?.quote?.createdAt
                ? new Date(message.metadata.quote.createdAt)
                : undefined;
              const hasDateDivider = messageCreationDate
                ? shouldDisplayDateDivider(messageCreationDate, previousMessageCreationDate)
                : false;

              return (
                <React.Fragment>
                  {hasDateDivider && messageCreationDate && (
                    <DateDivider date={messageCreationDate} />
                  )}

                  {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_CREATED_PENDING &&
                    message?.metadata?.quote && (
                      <OutboundQuotePending
                        currentQuoteIndex={currentQuoteIndex}
                        quote={message.metadata.quote}
                        totalQuoteCount={totalQuoteCount}
                        tradeRequest={tradeRequest}
                      />
                    )}
                  {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_COUNTERED &&
                    message?.metadata?.quote && (
                      <OutboundQuoteCountered
                        currentQuoteIndex={currentQuoteIndex}
                        quote={message.metadata.quote}
                        totalQuoteCount={totalQuoteCount}
                        tradeRequest={tradeRequest}
                      />
                    )}
                  {message?.metadata?.quoteMessageType ===
                    QuoteMessageType.QUOTE_COUNTER_REJECTED &&
                    message?.metadata?.quote && (
                      <OutboundQuoteCounterRejected
                        currentQuoteIndex={currentQuoteIndex}
                        quote={message.metadata.quote}
                        totalQuoteCount={totalQuoteCount}
                        tradeRequest={tradeRequest}
                      />
                    )}

                  {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_REJECTED &&
                    message?.metadata?.quote && (
                      <OutboundQuoteRejected
                        currentQuoteIndex={currentQuoteIndex}
                        quote={message.metadata.quote}
                        totalQuoteCount={totalQuoteCount}
                        tradeRequest={tradeRequest}
                      />
                    )}

                  {message?.metadata?.quoteMessageType ===
                    QuoteMessageType.QUOTE_CREATED_PENDING_CTA &&
                    message?.metadata?.quote &&
                    isLastMessage(messages, message.metadata) && (
                      <OutboundQuoteAcceptRejectCounter
                        onCancelCounter={measure}
                        onCancelReject={measure}
                        onCounterInitial={measure}
                        onQuoteAccept={(id) => setSelectedQuoteIdForConfirmation(id)}
                        onRejectInitial={measure}
                        onRejectInitialConfirm={measure}
                        quote={message.metadata.quote}
                        tradeRequest={tradeRequest}
                      />
                    )}

                  {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_COUNTERED_CTA &&
                    message?.metadata?.quote &&
                    isLastMessage(messages, message.metadata) &&
                    message.metadata.quote.type === TradeQuoteType.BUYER_TO_SELLER && (
                      <OutboundQuoteCounterAcceptRejectCounter
                        onCounterAccept={(quoteId) => setSelectedQuoteIdForConfirmation(quoteId)}
                        onCounterBack={measure}
                        onCounterBackCancel={measure}
                        onCounterCounter={measure}
                        onCounterReject={measure}
                        onCounterRejectCancel={measure}
                        onCounterRejectConfirm={measure}
                        quote={message.metadata.quote}
                        tradeRequest={tradeRequest}
                      />
                    )}

                  {message?.metadata?.quoteMessageType ===
                    QuoteMessageType.QUOTE_COUNTER_ACCEPTED_CTA &&
                    message?.metadata?.quote &&
                    isLastMessage(messages, message.metadata) && (
                      <OutboundQuoteCounterAccepted
                        onProceed={(counterId) => setSelectedQuoteIdForConfirmation(counterId)}
                        quote={message.metadata.quote}
                        tradeRequest={tradeRequest}
                      />
                    )}

                  {message?.metadata?.quoteMessageType === QuoteMessageType.QUOTE_COMPLETED && (
                    <OutboundCompleted />
                  )}
                </React.Fragment>
              );
            }}
          </MessengerList>
        </ActivityIndicator>
      )}
    </React.Fragment>
  );
};
