import { AnalyticSettings, AnalyticsSettings } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl, postRequestWithAuth } from '../utils/apiUtils';

export const getAnalyticsSettings = async () => {
  const url = makeApiUrl(`admin/analytics/settings`);
  return getRequestWithAuth<AnalyticsSettings>(url);
};

export const updateAnalyticsSettings = async (settings: AnalyticsSettings) => {
  const url = makeApiUrl(`admin/analytics/settings`);
  return postRequestWithAuth<AnalyticsSettings>(url, {
    body: JSON.stringify(settings),
  });
};

export const getAnalyticSettings = async (analyticId: string) => {
  const url = makeApiUrl(`admin/analytic/${analyticId}/settings`);
  return getRequestWithAuth<AnalyticSettings>(url);
};

export type UpdateAnalyticSettingsProps = {
  analyticId: string;
  settings: AnalyticSettings;
};

export const updateAnalyticSettings = async ({
  analyticId,
  settings,
}: UpdateAnalyticSettingsProps) => {
  const url = makeApiUrl(`admin/analytic/${analyticId}/settings`);
  return postRequestWithAuth<string>(url, {
    body: JSON.stringify(settings),
  });
};
