import { Identifier } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const useHomeAnalyticGroupSummaryQuery = (analyticId: Identifier, groupValue: string) =>
  useQuery([QueryKeys.PORTFOLIO_HOME_ANALYTIC_GROUP_SUMMARY, analyticId, groupValue], () =>
    api.getSummaryByHomeAnalyticGroup(analyticId, groupValue)
  );
