import { Identifier } from '../global';
import { OrganizationIdentifier } from '../organizations';

export enum DocumentsAttributeType {
  ATTRIBUTE_DOCUMENT = 'ATTRIBUTE_DOCUMENT',
}

export enum DocumentCategoryType {
  ORIGINATION_DOCUMENT = 'ORIGINATION_DOCUMENT',
  SERVICER_DOCUMENT = 'SERVICER_DOCUMENT',
  TRANSACTION_DOCUMENT = 'TRANSACTION_DOCUMENT',
}

export enum DocumentSubCategoryType {
  CLOSING_DOCUMENT = 'CLOSING_DOCUMENT',
  CREDIT_DOCUMENT = 'CREDIT_DOCUMENT',
  DISCLOSURE_DOCUMENT = 'DISCLOSURE_DOCUMENT',
}

// TODO: Add other 1003 layouts, Forms 1004, 1005, 1099, 181, W2, and note here
export enum Form1003DocumentType {
  FANNIE_MAE_FORM_1003_LEGACY = 'FANNIE_MAE_FORM_1003_LEGACY',
}

export enum FormW8BENDocumentType {
  IRS_FORM_W8BEN = 'IRS_FORM_W8BEN',
}

export enum FormW9DocumentType {
  IRS_FORM_W9 = 'IRS_FORM_W9',
}

export enum FormLoanEstimateDocumentType {
  CFPB_FORM_LOAN_ESTIMATE = 'CFPB_FORM_LOAN_ESTIMATE',
}

export enum PropertyPreservationReportDocumentType {
  STANDARD_PROPERTY_PRESERVATION_REPORT = 'STANDARD_PROPERTY_PRESERVATION_REPORT',
}

export enum EscrowDisbursementReportDocumentType {
  STANDARD_ESCROW_DISBURSEMENT_REPORT = 'STANDARD_ESCROW_DISBURSEMENT_REPORT',
}

export enum PoolSaleReportDocumentType {
  STANDARD_POOL_SALE_REPORT = 'STANDARD_POOL_SALE_REPORT',
}

export enum EncompassDocumentType {
  ENCOMPASS_DOCUMENT_TYPE = 'ENCOMPASS_DOCUMENT_TYPE',
}

export enum RequiredDocumentType {
  // TODO: Implement layouts for those
  // Form181 = 'Form181',
  // Note = 'Note',
  Form1003 = 'Form1003',
  Form1004 = 'Form1004',
  Form1005 = 'Form1005',
  Form1099 = 'Form1099',
  W2 = 'W2',
}

export enum OptionalDocumentType {
  Form1008 = 'Form1008',
}

// TODO: Add Forms 181 and note here
export type OriginationDocument =
  | Form1003DocumentType
  | FormW8BENDocumentType
  | FormW9DocumentType
  | FormLoanEstimateDocumentType
  | RequiredDocumentType
  | OptionalDocumentType
  | EncompassDocumentType;

export type ServicerDocument =
  | PropertyPreservationReportDocumentType
  | EscrowDisbursementReportDocumentType;

export type TransactionDocument = PoolSaleReportDocumentType;

export type DocumentType = OriginationDocument | ServicerDocument | TransactionDocument;

export type DocumentCreation = {
  organizationId: OrganizationIdentifier;
  documentType: DocumentType;
  documentTitle: string;
  documentPath: string;
  documentUrl?: string;
  allText: string;
  submissionId?: string;
  processorId?: string;
  searchTermFrequency?: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export type DocumentMetadata = {
  documentId: string;
  documentType: DocumentType;
  documentTitle: string;
  documentPath: string;
  documentContent: unknown;
  documentUrl?: string;
  submissionId?: string;
  processorId?: string;
};

export type Document = Omit<DocumentMetadata, 'documentId'> & {
  loanId: Identifier;
  loanNumber: Identifier;
  documentId: Identifier;
};

export type DocumentIngestedCreation = DocumentMetadata & {
  allText: string;
};

export type DocumentIngested = DocumentIngestedCreation & {
  id: Identifier;
};

export type DocumentUploadedCreation = {
  documentId: Identifier;
  documentUrl: string;
};

export type DocumentUploaded = {
  id: Identifier;
};

export type DocumentValidationError = {
  type: string;
  message: string;
};
