import { TradeRequest, TradeRequestQuote } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type ExecuteTradeRequestQuoteMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeQuoteId: TradeRequestQuote['id'];
};

const executeTradeRequestQuote = (data: ExecuteTradeRequestQuoteMutation) => {
  const { tradeQuoteId, tradeRequestId } = data;
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/quotes/${tradeQuoteId}/execute`);

  return postRequestWithAuth(url);
};

export const useExecuteTradeRequestQuoteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(executeTradeRequestQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUESTS_SUMMARY);
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_INBOUND_QUOTES);
      queryClient.invalidateQueries(QueryKeys.TRADE_REQUEST_OUTBOUND_QUOTES);
      queryClient.invalidateQueries(QueryKeys.COMPLETED_TRADE_REQUESTS);
    },
  });
};
