import { PortfolioAnalyticItem, VisualisationType } from '@plus-platform/shared';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { FlagsAlertsWarnings } from '../../components/FlagsAlertsWarnings';
import * as Styles from './AnalyticDetailWidget.styles';
import { AnalyticsChart } from './AnalyticsCharts';
import { AnalyticWidgetLink } from './AnalyticWidget.styles';
import { formatDataForAnalyticWidget } from './utils';

type AnalyticWidgetProps = {
  hasLink?: boolean;
  analytic: PortfolioAnalyticItem;
};

export const AnalyticDetailWidget = ({ analytic, hasLink }: AnalyticWidgetProps) => {
  const analyticItems = analytic?.data || [];
  const match = useRouteMatch();

  return (
    <Styles.ContentWrapper>
      {analytic.visualisationType !== VisualisationType.BUBBLE_CHART ? (
        <p>Analytics not available for Chloropleths or Bar charts yet</p>
      ) : (
        <React.Fragment>
          {analyticItems.length === 0 && <p>No analytics available</p>}

          {analyticItems.length > 0 && analytic && (
            <Styles.WidgetsWrapper>
              {analyticItems.map((item, index) => {
                const id = `${item.groupedByValue}-${index}`;
                const data = formatDataForAnalyticWidget(analytic.metric, [
                  {
                    value: item.value,
                    groupedByValue: item.groupedByValue,
                    loansCount: item.loansCount,
                  },
                ]);
                const to = hasLink ? `${match.url}/group/${item.groupedByValue}` : '#';

                return (
                  <AnalyticWidgetLink key={id} $size="medium" to={to}>
                    <Styles.Widget>
                      <AnalyticsChart
                        id={id}
                        data={data}
                        visualisationType={analytic.visualisationType}
                        metric={analytic.metric}
                        metricBy={analytic.metricBy}
                        style={{ justifyContent: 'space-evenly' }}
                      />
                      <FlagsAlertsWarnings
                        // TODO:
                        flagsCount={0}
                        warningsCount={0}
                        alertsCount={0}
                      />
                    </Styles.Widget>
                  </AnalyticWidgetLink>
                );
              })}
            </Styles.WidgetsWrapper>
          )}
        </React.Fragment>
      )}
    </Styles.ContentWrapper>
  );
};
