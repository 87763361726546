import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ $isVisible: boolean }>(
  ({ $isVisible, theme }) => css`
    position: absolute;
    inset: 0;
    border-radius: 4px;
    z-index: 1;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.primary02};
    border: 2px dashed ${theme.colors.accent01};
    transition: opacity 0.2s ease-in-out;
    opacity: ${$isVisible ? 1 : 0};
  `
);
