import { Attic } from './attic';
import { Basement } from './basement';
import { Room } from './room';
import {
  MISMOCount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum RoomType {
  AllRooms = 'AllRooms',
  Bedroom = 'Bedroom',
  BonusRoom = 'BonusRoom',
  Den = 'Den',
  DiningRoom = 'DiningRoom',
  EnsuiteFullBath = 'EnsuiteFullBath',
  ExerciseRoom = 'ExerciseRoom',
  FamilyRoom = 'FamilyRoom',
  Foyer = 'Foyer',
  FullBathroom = 'FullBathroom',
  GameRoom = 'GameRoom',
  GreatRoom = 'GreatRoom',
  HalfBath = 'HalfBath',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  Library = 'Library',
  LivingRoom = 'LivingRoom',
  MasterBedroom = 'MasterBedroom',
  MediaRoom = 'MediaRoom',
  Office = 'Office',
  Other = 'Other',
  RecreationRoom = 'RecreationRoom',
  UtilityRoom = 'UtilityRoom',
  Workshop = 'Workshop',
}

type InteriorRoomSummary = MISMOEntity &
  Partial<{
    roomType: RoomType;
    roomTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    totalNumberOfRoomsAboveGradeCount: MISMOCount;
    totalNumberOfRoomsBelowGradeCount: MISMOCount;
    totalRoomCount: MISMOCount;
  }>;

export type Interior = MISMOEntity &
  Partial<{
    attic: Attic;
    basement: Basement;
    interiorRoomSummaries: InteriorRoomSummary[];
    rooms: Room[];
  }>;
