import React from 'react';

import { MessageAction } from '../../components/Messages/Message.styles';
import { useReverseVirtual } from '../../hooks/useReverseVirtual';
import * as Styles from './MessengerList.styles';

type ChildrenProps<T> = {
  message: T;
  measure: () => void;
  index: number;
};

type MessengerListProps<T> = {
  messages: T[];
  loadMore?(): void;
  children: (props: ChildrenProps<T>) => React.ReactNode;
};

export const MessengerList = <T,>(props: MessengerListProps<T>) => {
  const { children, loadMore, messages } = props;

  const messengerRef = React.useRef<HTMLDivElement | null>(null);

  const { measure, onContentScroll, totalSize, virtualItems } = useReverseVirtual({
    size: messages.length,
    parentRef: messengerRef,
    overscan: 20,
    onLoadMore: loadMore,
    scrollThreshold: 200,
  });

  return (
    <Styles.Messenger onScroll={onContentScroll} ref={messengerRef}>
      <Styles.MessengerContent style={{ height: `${totalSize}px` }}>
        {virtualItems.map((virtualRow, index) => {
          const message = messages[virtualRow.index];
          return message ? (
            <MessageAction
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <React.Fragment>{children({ message, measure, index })}</React.Fragment>
            </MessageAction>
          ) : null;
        })}
      </Styles.MessengerContent>
      <Styles.MessengerScrollAnchor />
    </Styles.Messenger>
  );
};
