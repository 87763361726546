import { Stipulation, StipulationType } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getBlobRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const getStipulationDocument = (
  stipulationType: StipulationType,
  stipulationId: Stipulation['id']
) => {
  const url = makeApiUrl(`stipulations/${stipulationType}/${stipulationId}/document`);

  return getBlobRequestWithAuth(url);
};

export const useStipulationDocumentQuery = (
  stipulationType: StipulationType,
  stipulationId?: Stipulation['id'],
  isEnabled?: boolean
) =>
  useQuery(
    [QueryKeys.TRADE_STIPULATION_DOCUMENT, stipulationId],
    () => (stipulationId ? getStipulationDocument(stipulationType, stipulationId) : undefined),
    { enabled: isEnabled }
  );
