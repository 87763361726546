import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { ButtonLink } from '../../components/ButtonLink';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { Skeleton } from '../../components/Skeleton';
import { usePortfolioAnalyticsQuery } from '../../hooks/queries';
import { formatNumber } from '../../utils/formatUtils';
import { AnalyticPage } from './AnalyticPage';
import {
  AnalyticsMenu,
  AnalyticsMenuItem,
  AnalyticsMetrics,
  AnalyticsWrapper,
  LoansCount,
  Metrics,
  PortfolioCount,
} from './Analytics.styles';
import { AnalyticsChoiceWidget } from './AnalyticsChoiceWidget';
import { AnalyticTile } from './AnalyticTile';

const AnalyticsQA = () => {
  const { data: analyticsData, isLoading: isAnalyticsLoading } = usePortfolioAnalyticsQuery();

  const analyticsResults = analyticsData?.analytics || [];
  const loansCount = analyticsData?.loansCount || 0;

  return (
    <React.Fragment>
      <Page>
        <PageHeader>
          <PageHeaderContent>
            <div>
              <Heading>Analytics</Heading>
              <Breadcrumb>
                <BreadcrumbItem linkTo="/" title="Home" />
                <BreadcrumbItem title="Analytics" />
              </Breadcrumb>
            </div>
            <AnalyticsMetrics>
              <Metrics>
                <PortfolioCount>
                  <span>Number of loans in portfolio</span>
                  <LoansCount>{formatNumber(loansCount)}</LoansCount>
                </PortfolioCount>
              </Metrics>
            </AnalyticsMetrics>
          </PageHeaderContent>
        </PageHeader>
        <PageContent>
          <AnalyticsMenu>
            <ButtonLink to="/analytics">
              <AnalyticsMenuItem>Q&A</AnalyticsMenuItem>
            </ButtonLink>
            <ButtonLink to="#">
              <AnalyticsMenuItem>Core</AnalyticsMenuItem>
            </ButtonLink>
            <ButtonLink to="#">
              <AnalyticsMenuItem>Tools</AnalyticsMenuItem>
            </ButtonLink>
            <ButtonLink to="#">
              <AnalyticsMenuItem>Advanced</AnalyticsMenuItem>
            </ButtonLink>
          </AnalyticsMenu>

          <AnalyticsWrapper>
            {isAnalyticsLoading && <Skeleton height={307} />}

            {!isAnalyticsLoading &&
              analyticsResults.map((analyticResult, index) => {
                return (
                  <AnalyticTile
                    id={String(analyticResult.id)}
                    metric={analyticResult.metric}
                    metricBy={analyticResult.metricBy}
                    metricCalculationType={analyticResult?.metricCalculationType}
                    visualisationType={analyticResult.visualisationType}
                    key={`${analyticResult.metric}-${analyticResult.metricBy}-${index}`}
                    data={analyticResult.data}
                    flagsCount={analyticResult.flagsCount}
                    warningsCount={analyticResult.warningsCount}
                    alertsCount={analyticResult.alertsCount}
                  />
                );
              })}
            <AnalyticsChoiceWidget analytics={analyticsResults} />
          </AnalyticsWrapper>
        </PageContent>
      </Page>
    </React.Fragment>
  );
};

export const AnalyticsPage = () => {
  return (
    <Switch>
      <Route component={AnalyticsQA} path="/analytics" exact />
      <Route component={AnalyticPage} path="/analytics/:analyticId" />
    </Switch>
  );
};
