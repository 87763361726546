import {
  MISMOCount,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum IndexAveragingType {
  AverageConsecutiveIndexValues = 'AverageConsecutiveIndexValues',
  AveragePeriodicIndexRates = 'AveragePeriodicIndexRates',
  AveragePreviousLoanIndexRates = 'AveragePreviousLoanIndexRates',
}

enum IndexCalculationMethodType {
  AverageTwoIndexRates = 'AverageTwoIndexRates',
  CurrentDaysIndexRate = 'CurrentDaysIndexRate',
  HighestOfTwoIndexRates = 'HighestOfTwoIndexRates',
  LowestOfTwoIndexRates = 'LowestOfTwoIndexRates',
  SingleIndexRate = 'SingleIndexRate',
}

enum IndexDesignationType {
  Alternate = 'Alternate',
  Primary = 'Primary',
}

enum IndexLookbackType {
  FirstMondayOfTheMonth = 'FirstMondayOfTheMonth',
  Other = 'Other',
  SecondTuesdayOfTheMonth = 'SecondTuesdayOfTheMonth',
  SpecificDayOfTheMonth = 'SpecificDayOfTheMonth',
  ThirdFridayOfTheMonth = 'ThirdFridayOfTheMonth',
}

enum IndexRoundingTimingType {
  RoundIndexAfterCheckingMinimumIndexMovement = 'RoundIndexAfterCheckingMinimumIndexMovement',
  RoundIndexBeforeCheckingMinimumIndexMovement = 'RoundIndexBeforeCheckingMinimumIndexMovement',
}

enum IndexRoundingType {
  Nearest = 'Nearest',
  NoRounding = 'NoRounding',
  Up = 'Up',
}

export enum IndexSourceType {
  FHLBEleventhDistrictMonthlyCostOfFundsIndex = 'FHLBEleventhDistrictMonthlyCostOfFundsIndex',
  'FHLBEleventhDistrictSemi-AnnualCostOfFundsIndex' = 'FHLBEleventhDistrictSemi-AnnualCostOfFundsIndex',
  LIBOROneMonthWSJ25thDayOfMonth = 'LIBOROneMonthWSJ25thDayOfMonth',
  LIBOROneMonthWSJ25thDayOfMonthOrNextBusinessDay = 'LIBOROneMonthWSJ25thDayOfMonthOrNextBusinessDay',
  LIBOROneMonthWSJDaily = 'LIBOROneMonthWSJDaily',
  LIBOROneMonthWSJFifteenthDayOfMonth = 'LIBOROneMonthWSJFifteenthDayOfMonth',
  LIBOROneMonthWSJFifteenthDayOfMonthOrNextBusinessDay = 'LIBOROneMonthWSJFifteenthDayOfMonthOrNextBusinessDay',
  LIBOROneMonthWSJFirstBusinessDayOfTheMonth = 'LIBOROneMonthWSJFirstBusinessDayOfTheMonth',
  LIBOROneMonthWSJFirstDayOfTheMonth = 'LIBOROneMonthWSJFirstDayOfTheMonth',
  LIBOROneYearWSJDaily = 'LIBOROneYearWSJDaily',
  LIBOROneYearWSJFirstBusinessDayOfTheMonth = 'LIBOROneYearWSJFirstBusinessDayOfTheMonth',
  LIBORSixMonthWSJ25thDayOfMonth = 'LIBORSixMonthWSJ25thDayOfMonth',
  LIBORSixMonthWSJ25thDayOfMonthOrNextBusinessDay = 'LIBORSixMonthWSJ25thDayOfMonthOrNextBusinessDay',
  LIBORSixMonthWSJFifteenthDayOfMonth = 'LIBORSixMonthWSJFifteenthDayOfMonth',
  LIBORSixMonthWSJFifteenthDayOfMonthOrNextBusinessDay = 'LIBORSixMonthWSJFifteenthDayOfMonthOrNextBusinessDay',
  LIBORSixMonthWSJLastBusinessDayOfMonth = 'LIBORSixMonthWSJLastBusinessDayOfMonth',
  LIBORThreeMonthWSJDaily = 'LIBORThreeMonthWSJDaily',
  LIBORThreeMonthWSJFirstBusinessDayOfTheMonth = 'LIBORThreeMonthWSJFirstBusinessDayOfTheMonth',
  MonthlyFiveYearTreasurySecuritiesConstantMaturityFRBH15 = 'MonthlyFiveYearTreasurySecuritiesConstantMaturityFRBH15',
  MonthlyOneYearTreasurySecuritiesConstantMaturityFRBH15 = 'MonthlyOneYearTreasurySecuritiesConstantMaturityFRBH15',
  MonthlySevenYearTreasurySecuritiesConstantMaturityFRBH15 = 'MonthlySevenYearTreasurySecuritiesConstantMaturityFRBH15',
  MonthlyTenYearTreasurySecuritiesConstantMaturityFRBH15 = 'MonthlyTenYearTreasurySecuritiesConstantMaturityFRBH15',
  MonthlyThreeYearTreasurySecuritiesConstantMaturityFRBH15 = 'MonthlyThreeYearTreasurySecuritiesConstantMaturityFRBH15',
  MonthlyTwoYearTreasurySecuritiesConstantMaturityFRBH15 = 'MonthlyTwoYearTreasurySecuritiesConstantMaturityFRBH15',
  NationalAverageContractMortgageRate = 'NationalAverageContractMortgageRate',
  NationalMonthlyMedianCostOfFundsIndexOTS = 'NationalMonthlyMedianCostOfFundsIndexOTS',
  OneMonthUSTBillWeeklyAuctionInvestmentYieldUST = 'OneMonthUSTBillWeeklyAuctionInvestmentYieldUST',
  OneYearCMTMovingTwelveMonthAverageMTA = 'OneYearCMTMovingTwelveMonthAverageMTA',
  Other = 'Other',
  PrimeRateWSJEffectiveDate = 'PrimeRateWSJEffectiveDate',
  PrimeRateWSJFirstBusinessDayOfTheMonth = 'PrimeRateWSJFirstBusinessDayOfTheMonth',
  PrimeRateWSJPublicationDate = 'PrimeRateWSJPublicationDate',
  SixMonthLIBOR_WSJDaily = 'SixMonthLIBOR_WSJDaily',
  SixMonthLIBOR_WSJFirstBusinessDayOfTheMonth = 'SixMonthLIBOR_WSJFirstBusinessDayOfTheMonth',
  SixMonthUSTBillMonthlyAuctionDiscountRateCalculated = 'SixMonthUSTBillMonthlyAuctionDiscountRateCalculated',
  SixMonthUSTBillMonthlyAuctionDiscountRateUST = 'SixMonthUSTBillMonthlyAuctionDiscountRateUST',
  SixMonthUSTBillMonthlyAuctionInvestmentYieldCalculated = 'SixMonthUSTBillMonthlyAuctionInvestmentYieldCalculated',
  SixMonthUSTBillWeeklyAuctionDiscountRateUST = 'SixMonthUSTBillWeeklyAuctionDiscountRateUST',
  SixMonthUSTBillWeeklyAuctionInvestmentYieldUST = 'SixMonthUSTBillWeeklyAuctionInvestmentYieldUST',
  ThreeMonthUSTBillMonthlyAuctionDiscountRateCalculated = 'ThreeMonthUSTBillMonthlyAuctionDiscountRateCalculated',
  ThreeMonthUSTBillWeeklyAuctionInvestmentYieldUST = 'ThreeMonthUSTBillWeeklyAuctionInvestmentYieldUST',
  WeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15 = 'WeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15',
  WeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15 = 'WeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15',
  WeeklySevenYearTreasurySecuritiesConstantMaturityFRBH15 = 'WeeklySevenYearTreasurySecuritiesConstantMaturityFRBH15',
  WeeklySixMonthCertificateOfDepositSecondaryMarketFRBH15 = 'WeeklySixMonthCertificateOfDepositSecondaryMarketFRBH15',
  WeeklyTenYearTreasurySecuritiesConstantMaturityFRBH15 = 'WeeklyTenYearTreasurySecuritiesConstantMaturityFRBH15',
  WeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15 = 'WeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15',
  WeeklyTwoYearTreasurySecuritiesConstantMaturityFRBH15 = 'WeeklyTwoYearTreasurySecuritiesConstantMaturityFRBH15',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
// 30DayAverageSOFR value taken from: https://www.freddiemac.com/sites/g/files/ynjofi111/files/docs/LIBOR_playbook_SFARM.pdf
export enum IndexSourceTypeOtherDescription {
  '30DayAverageSOFR' = '30DayAverageSOFR',
}

enum IndexType {
  BankPrimeLoan = 'BankPrimeLoan',
  CertificateOfDepositIndex = 'CertificateOfDepositIndex',
  ConstantMaturityTreasury = 'ConstantMaturityTreasury',
  CostOfSavingsIndex = 'CostOfSavingsIndex',
  EleventhDistrictCostOfFundsIndex = 'EleventhDistrictCostOfFundsIndex',
  LIBOR = 'LIBOR',
  Other = 'Other',
  TreasuryBill = 'TreasuryBill',
  TwelveMonthTreasuryAverage = 'TwelveMonthTreasuryAverage',
}

export type IndexRule = MISMOEntity &
  Partial<{
    indexAveragingIndicator: MISMOIndicator;
    indexAveragingType: IndexAveragingType;
    indexAveragingValueCount: MISMOCount;
    indexCalculationMethodType: IndexCalculationMethodType;
    indexDesignationType: IndexDesignationType;
    indexLeadMonthsCount: MISMOCount;
    indexLookbackType: IndexLookbackType;
    indexLookbackTypeOtherDescription: MISMOString;
    indexRoundingPercent: MISMOPercent;
    indexRoundingTimingType: IndexRoundingTimingType;
    indexRoundingType: IndexRoundingType;
    indexSourceType: IndexSourceType;
    indexSourceTypeOtherDescription: MISMOString;
    indexType: IndexType;
    indexTypeOtherDescription: MISMOString;
    interestAdjustmentIndexLeadDaysCount: MISMOCount;
    interestAndPaymentAdjustmentIndexLeadDaysCount: MISMOCount;
    minimumIndexMovementRatePercent: MISMOPercent;
    paymentsBetweenIndexValuesCount: MISMOCount;
    paymentsToFirstIndexValueCount: MISMOCount;
    sequenceNumber: MISMOSequenceNumber;
  }>;
