import { PortfolioLoanRateType } from '@plus-platform/shared';
import uniqBy from 'lodash/uniqBy';
import React, { useState } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useTheme } from 'styled-components';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { DelinquencyVariantProps } from '../../components/DelinquencyPanes/DelinquencyPanes';
import { Key, Pair, Value } from '../../components/KeyValue';
import { Skeleton } from '../../components/Skeleton';
import { usePortfolioBreakdownRatesQuery } from '../../hooks/queries';
import { plusChartTheme } from '../../styles/deprecatedChart';
import { EMPTY_VALUE } from '../../utils/formatUtils';
import * as Styles from './BreakdownTile.styles';
import {
  ChartData,
  createGraphData,
  DEFAULT_SLIDER_BOX_LENGTH,
  formatRates,
  FormattedRate,
  getPercentage,
} from './breakdownUtils';
import { RatesGraphSlider } from './GraphSlider';

export const BreakdownTileWrapper = (props: {
  title?: string;
  subtitle?: string | number;
  children: React.ReactNode;
}) => (
  <Styles.Wrapper>
    <Styles.Header $withoutBorder={!props.title && !props.subtitle}>
      <Styles.Heading>{props.title}</Styles.Heading>
      <Styles.Subheading>{props.subtitle}</Styles.Subheading>
    </Styles.Header>

    {props.children}
  </Styles.Wrapper>
);

export const BreakdownTileContent = (props: {
  label: string;
  value: string | number | React.ReactNode;
}) => (
  <Pair $align="right" $size="large">
    <Key>{props.label}</Key>
    <Value>{props.value}</Value>
  </Pair>
);

type LoanCountChartProps = DelinquencyVariantProps & {
  data: ChartData[];
};

export const LoanCountChart = ({ data, variant }: LoanCountChartProps) => {
  return (
    <Styles.Container $variant={variant}>
      {data.map(({ label, offset, width }) => (
        <Styles.Row key={`loancount-${label}`} $variant={variant}>
          <Styles.Label $variant={variant}>{label}</Styles.Label>
          <Styles.CountChart>
            <Styles.CountChartBase
              data-testid="Breakdown_Vertical_Bar_Count"
              $width={width}
              $offSet={offset}
            />
          </Styles.CountChart>
        </Styles.Row>
      ))}
    </Styles.Container>
  );
};

type LoanRateChartProps = {
  data: {
    label: string;
    marketRateGreaterOrEqualToNoteRateLoanCount: number;
    marketRateLessThanNoteRateLoanCount: number;
    loanCount: number;
  }[];
};

export const LoanRateChart = ({ data }: LoanRateChartProps) => {
  return (
    <Container fluid>
      {data.map(
        ({
          label,
          loanCount,
          marketRateGreaterOrEqualToNoteRateLoanCount,
          marketRateLessThanNoteRateLoanCount,
        }) => (
          <Row key={`loanrate-${label}`}>
            <Col col={6}>
              <Styles.Label>{label}</Styles.Label>
            </Col>
            <Col col={6}>
              <Styles.BarGraphWrapper data-testid="Breakdown_Bar_GraphWrapper">
                {!marketRateGreaterOrEqualToNoteRateLoanCount &&
                  !marketRateLessThanNoteRateLoanCount && <Styles.EmptyVerticalBarChart />}

                <Styles.VerticalBarChart
                  data-testid="Breakdown_Vertical_Bar_Comparison"
                  $isPositive={true}
                  $width={getPercentage(marketRateGreaterOrEqualToNoteRateLoanCount, loanCount)}
                />
                <Styles.VerticalBarChart
                  $isPositive={false}
                  $width={getPercentage(marketRateLessThanNoteRateLoanCount, loanCount)}
                />
              </Styles.BarGraphWrapper>
            </Col>
          </Row>
        )
      )}
    </Container>
  );
};

type RatesTableProps = {
  data: FormattedRate[];
};

export const RatesTable = ({ data }: RatesTableProps) => {
  const [selectedRatesIndex, setSelectedRatesIndex] = useState(0);
  const [graphIndexes, setGraphIndexes] = useState<[number, number]>([
    0,
    DEFAULT_SLIDER_BOX_LENGTH,
  ]);

  const theme = useTheme();

  const selectedRateData = data[selectedRatesIndex];

  return (
    <Styles.RatesTableWrapper>
      <Container fluid>
        <Row>
          <Col col={4} noGutter>
            <Styles.RatesHeader>
              <Styles.Heading>Market Rates</Styles.Heading>
            </Styles.RatesHeader>
            <Styles.RatesTable cellSpacing={0} width="100%">
              <thead>
                <tr>
                  <th></th>
                  <Styles.RatesTableHeader>Current rate %</Styles.RatesTableHeader>
                  <Styles.RatesTableHeader>Previous rate %</Styles.RatesTableHeader>
                  <Styles.RatesTableHeader>Change</Styles.RatesTableHeader>
                </tr>
              </thead>

              <tbody>
                {data.map((rate, index) => (
                  <Styles.RatesTableRow
                    key={rate.name}
                    $isActive={selectedRateData?.name === rate.name}
                    onClick={() => {
                      setSelectedRatesIndex(index);
                      setGraphIndexes([0, DEFAULT_SLIDER_BOX_LENGTH]);
                    }}
                  >
                    <Styles.RatesTableData $isLabel={true}>{rate.name}</Styles.RatesTableData>
                    <Styles.RatesTableData data-testid={`${rate.name}_Current_Rate_Value`}>
                      {rate?.currentRate ? rate.currentRate.toFixed(2) : EMPTY_VALUE}
                    </Styles.RatesTableData>
                    <Styles.RatesTableData data-testid={`${rate.name}_Previous_Rate_Value`}>
                      {rate?.previousRate ? rate.previousRate.toFixed(2) : EMPTY_VALUE}
                    </Styles.RatesTableData>
                    <Styles.RatesTableData $hasIcon={true}>
                      {rate.change !== 0 && (
                        <Styles.ChangeIcon
                          $isNegative={rate.change < 0}
                          color={
                            rate.change < 0 ? theme.colors.statusGreen : theme.colors.statusRed
                          }
                          height="10px"
                          width="10px"
                        />
                      )}

                      {rate.change}
                    </Styles.RatesTableData>
                  </Styles.RatesTableRow>
                ))}
              </tbody>
            </Styles.RatesTable>
          </Col>

          {selectedRateData && (
            <Col col={8} noGutter>
              <Styles.RatesGraphWrapper>
                <Styles.RateGraphHeading>
                  <Styles.Heading>{selectedRateData.name}</Styles.Heading>
                </Styles.RateGraphHeading>
                <RatesGraph
                  graphIndexes={graphIndexes}
                  setGraphIndexes={setGraphIndexes}
                  rateType={selectedRateData.rateType}
                />
              </Styles.RatesGraphWrapper>
            </Col>
          )}
        </Row>
      </Container>
    </Styles.RatesTableWrapper>
  );
};

type RatesGraphProps = {
  rateType: PortfolioLoanRateType;
  graphIndexes: [number, number];
  setGraphIndexes: React.Dispatch<React.SetStateAction<[number, number]>>;
};

const RatesGraph = ({ graphIndexes, rateType, setGraphIndexes }: RatesGraphProps) => {
  const theme = useTheme();

  const { data: breakdownRates, isLoading: areRatesLoading } =
    usePortfolioBreakdownRatesQuery(rateType);

  const graphData = createGraphData(formatRates(breakdownRates || []));

  const filteredGraphData = graphData
    .slice(graphIndexes[0], graphIndexes[1])
    .map((data) => ({ ...data, xValue: data.xValue - graphIndexes[0] / 4 }));
  const graphLabels = uniqBy(filteredGraphData, 'label').map((filteredData) => {
    if (!filteredData.label.toLocaleLowerCase().includes('jan')) {
      return filteredData.label.slice(0, 3);
    }

    return filteredData.label.split(' ').join('\n');
  });

  const axisStyle = {
    grid: { stroke: theme.colors.white10, fill: theme.colors.white10 },
    axis: { stroke: theme.colors.white10 },
    tickLabels: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily.heading,
      fill: theme.colors.white,
      padding: 2,
    },
  };

  const graphHeight = 450;

  if (areRatesLoading || !graphData.length || !filteredGraphData.length) {
    return <Skeleton height={332} />;
  }

  return (
    <Styles.RatesGraphContainer>
      <svg
        viewBox={'0 0' + ' ' + window.innerWidth + ' ' + graphHeight}
        preserveAspectRatio="none"
        width="100%"
      >
        <VictoryChart
          domainPadding={{ x: 15 }}
          standalone={false}
          width={window.innerWidth}
          height={graphHeight}
          theme={{
            ...plusChartTheme,
            axis: {
              ...plusChartTheme.axis,
              style: axisStyle,
            },
            dependentAxis: {
              ...plusChartTheme.dependentAxis,
              style: axisStyle,
            },
          }}
          padding={{ top: 15, bottom: 30, left: 40, right: 15 }}
          domain={{
            y: [
              Math.floor(Math.min(...filteredGraphData.map((data) => data.rate))) - 1,
              Math.ceil(Math.max(...filteredGraphData.map((data) => data.rate))),
            ],
          }}
        >
          <VictoryAxis
            tickValues={Array.from({ length: graphLabels.length }, (_, i) => i + 1)}
            tickFormat={graphLabels}
            standalone={false}
          />
          <VictoryAxis dependentAxis standalone={false} />
          <VictoryBar
            alignment="start"
            x="date"
            y="rate"
            style={{
              data: {
                fill: theme.colors.white,
                fillOpacity: '50%',
                stroke: 'none',
              },
              labels: { fontSize: '10px', fill: theme.colors.white },
              parent: { border: '1px solid #ccc' },
            }}
            data={filteredGraphData.map((data) => ({
              date: data.xValue,
              rate: data.rate,
            }))}
          />
        </VictoryChart>
      </svg>

      <RatesGraphSlider
        graphData={graphData}
        graphIndexes={graphIndexes}
        onChange={({ endIndex, startIndex }) => {
          setGraphIndexes([startIndex, endIndex]);
        }}
      />
    </Styles.RatesGraphContainer>
  );
};
