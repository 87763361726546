import React from 'react';

export const FilterLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1442 19C10.9017 19 10.6984 18.9161 10.5344 18.7484C10.3704 18.5807 10.2883 18.3729 10.2883 18.125V12.875L5.17473 6.20312C4.97504 5.95521 4.94651 5.69271 5.08915 5.41563C5.23179 5.13854 5.45288 5 5.75242 5H18.2476C18.5471 5 18.7682 5.13854 18.9109 5.41563C19.0535 5.69271 19.025 5.95521 18.8253 6.20312L13.7117 12.875V18.125C13.7117 18.3729 13.6296 18.5807 13.4656 18.7484C13.3016 18.9161 13.0983 19 12.8558 19H11.1442ZM12 12.9625L17.135 6.3125H6.865L12 12.9625Z"
        fill="currentColor"
      />
    </svg>
  );
};
