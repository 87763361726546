import { IntegratedDisclosureSectionType } from './integratedDisclosure';
import { FeePaidToType, PaidTo } from './paidTo';
import { SelectedServiceProvider } from './serviceProvider';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

enum PrepaidItemPerDiemCalculationMethodType {
  PrepaidItemPerDiemCalculationMethodType360 = '360',
  PrepaidItemPerDiemCalculationMethodType365 = '365',
  '365Or366' = '365Or366',
  Other = 'Other',
}

enum PrepaidItemPerDiemRoundingType {
  Down = 'Down',
  None = 'None',
  Other = 'Other',
  Up = 'Up',
}

export enum PrepaidItemType {
  BoroughPropertyTax = 'BoroughPropertyTax',
  CityPropertyTax = 'CityPropertyTax',
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  CountyPropertyTax = 'CountyPropertyTax',
  DistrictPropertyTax = 'DistrictPropertyTax',
  EarthquakeInsurancePremium = 'EarthquakeInsurancePremium',
  FloodInsurancePremium = 'FloodInsurancePremium',
  HailInsurancePremium = 'HailInsurancePremium',
  HazardInsurancePremium = 'HazardInsurancePremium',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeownersInsurancePremium = 'HomeownersInsurancePremium',
  MortgageInsurancePremium = 'MortgageInsurancePremium',
  Other = 'Other',
  PrepaidInterest = 'PrepaidInterest',
  StatePropertyTax = 'StatePropertyTax',
  TownPropertyTax = 'TownPropertyTax',
  USDARuralDevelopmentGuaranteeFee = 'USDARuralDevelopmentGuaranteeFee',
  VAFundingFee = 'VAFundingFee',
  VolcanoInsurancePremium = 'VolcanoInsurancePremium',
  WindAndStormInsurancePremium = 'WindAndStormInsurancePremium',
}

export enum PrepaidItemPaymentPaidByType {
  Broker = 'Broker',
  Buyer = 'Buyer',
  Correspondent = 'Correspondent',
  Lender = 'Lender',
  Other = 'Other',
  Seller = 'Seller',
  ThirdParty = 'ThirdParty',
}

export enum PrepaidItemPaymentTimingType {
  AtClosing = 'AtClosing',
  BeforeClosing = 'BeforeClosing',
  FirstPayment = 'FirstPayment',
  Other = 'Other',
}

export type PrepaidItemPayment = MISMOEntity &
  Partial<{
    paymentFinancedIndicator: MISMOIndicator;
    paymentIncludedInAPRIndicator: MISMOIndicator;
    paymentIncludedInJurisdictionHighCostIndicator: MISMOIndicator;
    prepaidFinanceChargeIndicator: MISMOIndicator;
    prepaidItemActualPaymentAmount: MISMOAmount;
    prepaidItemEstimatedPaymentAmount: MISMOAmount;
    prepaidItemPaymentPaidByType: PrepaidItemPaymentPaidByType;
    prepaidItemPaymentPaidByTypeOtherDescription: MISMOString;
    prepaidItemPaymentTimingType: PrepaidItemPaymentTimingType;
    prepaidItemPaymentTimingTypeOtherDescription: MISMOString;
    regulationZPointsAndFeesIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export type PrepaidItem = MISMOEntity &
  Partial<{
    borrowerChosenProviderIndicator: MISMOIndicator;
    feePaidToType: FeePaidToType;
    feePaidToTypeOtherDescription: MISMOString;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    prepaidItemActualTotalAmount: MISMOAmount;
    prepaidItemEstimatedTotalAmount: MISMOAmount;
    prepaidItemMonthlyAmount: MISMOAmount;
    prepaidItemMonthsPaidCount: MISMOCount;
    prepaidItemNumberOfDaysCount: MISMOCount;
    prepaidItemPaidFromDate: MISMODate;
    prepaidItemPaidThroughDate: MISMODate;
    prepaidItemPerDiemAmount: MISMOAmount;
    prepaidItemPerDiemCalculationMethodType: PrepaidItemPerDiemCalculationMethodType;
    prepaidItemPerDiemCalculationMethodTypeOtherDescription: MISMOString;
    prepaidItemPerDiemPrintDecimalCount: MISMOCount;
    prepaidItemPerDiemRoundingDecimalCount: MISMOCount;
    prepaidItemPerDiemRoundingType: PrepaidItemPerDiemRoundingType;
    prepaidItemPerDiemRoundingTypeOtherDescription: MISMOString;
    prepaidItemRefundableAmount: MISMOAmount;
    prepaidItemRefundableConditionsDescription: MISMOString;
    prepaidItemRefundableIndicator: MISMOIndicator;
    prepaidItemType: PrepaidItemType;
    'prepaidItemType@DisplayLabelText': MISMOString;
    prepaidItemTypeOtherDescription: MISMOString;
    regulationZPointsAndFeesIndicator: MISMOIndicator;
    requiredProviderOfServiceIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
    paidTo: PaidTo;
    prepaidItemPayments: PrepaidItemPayment[];
    selectedServiceProvider: SelectedServiceProvider;
  }>;
