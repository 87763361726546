import styled, { css } from 'styled-components/macro';

import { HeadingButton as _HeadingButton } from '../../components/HeadingButton';

export const Wrapper = styled.div`
  padding: 4px 8px 8px;
  height: 326px;
  overflow-y: auto;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  display: grid;
  row-gap: 8px;
`;

export const ListDivider = styled.hr(
  ({ theme }) => css`
    margin: 8px 0;
    height: 1px;
    border: 0;
    background: ${theme.colors.primary03};
  `
);

export const Loading = styled.p`
  text-align: center;
  margin: 24px 0 8px;
`;

export const Empty = styled.div(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 8px;
  `
);

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleText = styled.p(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium04}

    color: ${theme.colors.textPrimary01};
  `
);

export const HeadingLink = styled(_HeadingButton)``;
