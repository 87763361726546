import { GetPortfolioGeodataPropertiesResponse, Identifier } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioGeodataProperties = (filterIds: Identifier[] = []) => {
  const query = new URLSearchParams({});

  filterIds.forEach((filterId) => {
    query.append('filterIds', `${filterId}`);
  });

  const url = makeApiUrl(`portfolio/properties?${query}`);

  return getRequestWithAuth<GetPortfolioGeodataPropertiesResponse>(url);
};
