import { Identifier } from '.';

export enum InsuranceType {
  INSURANCE_MORTGAGE = 'INSURANCE_MORTGAGE',
  INSURANCE_FLOOD = 'INSURANCE_FLOOD',
  INSURANCE_HAZARD = 'INSURANCE_HAZARD',
}

export type InsurerCreation = {
  name: string;
  address: string;
  type: InsuranceType;
};

export type Insurer = InsurerCreation & {
  id: Identifier;
};
