import { DelinquencyType, PortfolioLoanRateType } from '@plus-platform/shared';
import { useInfiniteQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const usePortfolioDelinquentLoanTypesByNotRateLoansQuery = (
  delinquencyType: DelinquencyType,
  rateType: PortfolioLoanRateType,
  noteRate: number
) =>
  useInfiniteQuery(
    [
      QueryKeys.PORTFOLIO_DELINQUENT_LOAN_TYPES_BY_NOTE_RATE_LOANS,
      delinquencyType,
      rateType,
      noteRate,
    ],
    ({ pageParam = 0 }) => {
      return api.getPortfolioDelinquentLoanTypesByNoteRateLoans(
        delinquencyType,
        rateType,
        noteRate,
        pageParam
      );
    },
    {
      getNextPageParam: (lastPage) => lastPage?.pageNumber ?? 0,
    }
  );
