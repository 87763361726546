export const NFHLIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.8734 13.7502C18.4762 13.4018 17.9613 13.2175 17.4332 13.2345C16.905 13.2515 16.4031 13.4686 16.029 13.8418C15.756 14.1144 15.3859 14.2675 15 14.2675C14.6141 14.2675 14.244 14.1144 13.9709 13.8418C13.5805 13.4522 13.0515 13.2334 12.5 13.2334C11.9485 13.2334 11.4195 13.4522 11.029 13.8418C10.756 14.1144 10.3859 14.2675 9.99999 14.2675C9.61413 14.2675 9.24403 14.1144 8.97094 13.8418C8.58053 13.4522 8.05153 13.2334 7.49999 13.2334C6.94846 13.2334 6.41945 13.4522 6.02905 13.8418C5.75596 14.1144 5.38586 14.2675 4.99999 14.2675C4.61413 14.2675 4.24403 14.1144 3.97094 13.8418C3.59689 13.4686 3.09496 13.2515 2.56683 13.2345C2.0387 13.2175 1.52385 13.4018 1.12656 13.7502H0.625V14.3752H1.37939L1.47096 14.2837C1.74404 14.0111 2.11414 13.858 2.50001 13.858C2.88588 13.858 3.25598 14.0111 3.52906 14.2837C3.91947 14.6733 4.44848 14.892 5.00001 14.892C5.55154 14.892 6.08055 14.6733 6.47096 14.2837C6.74404 14.0111 7.11414 13.858 7.50001 13.858C7.88588 13.858 8.25598 14.0111 8.52906 14.2837C8.91947 14.6733 9.44848 14.892 10 14.892C10.5515 14.892 11.0805 14.6733 11.471 14.2837C11.744 14.0111 12.1141 13.858 12.5 13.858C12.8859 13.858 13.256 14.0111 13.5291 14.2837C13.9195 14.6732 14.4485 14.8919 15 14.8919C15.5515 14.8919 16.0805 14.6732 16.471 14.2837C16.744 14.0111 17.1141 13.858 17.5 13.858C17.8859 13.858 18.256 14.0111 18.5291 14.2837L18.6206 14.3752H19.375V13.7502H18.8734Z"
        fill="currentColor"
      />
      <path
        d="M14.7793 17.1721C14.6879 17.2709 14.5771 17.3497 14.4537 17.4036C14.3304 17.4574 14.1973 17.4853 14.0627 17.4853C13.9282 17.4853 13.7951 17.4574 13.6717 17.4036C13.5484 17.3497 13.4376 17.2709 13.3462 17.1721L12.9043 17.614C13.2223 17.9292 13.648 18.112 14.0955 18.1255C14.308 18.121 14.5174 18.0733 14.7109 17.9854C14.9044 17.8975 15.078 17.7711 15.2212 17.614C15.3128 17.5153 15.4238 17.4366 15.5472 17.3828C15.6707 17.329 15.8039 17.3013 15.9385 17.3014C16.0732 17.3016 16.2063 17.3295 16.3297 17.3835C16.453 17.4375 16.5639 17.5164 16.6553 17.6152L17.0962 17.1721C16.9483 17.0126 16.769 16.8854 16.5697 16.7984C16.3704 16.7114 16.1552 16.6665 15.9377 16.6665C15.7202 16.6665 15.5051 16.7114 15.3058 16.7984C15.1064 16.8854 14.9272 17.0126 14.7793 17.1721Z"
        fill="currentColor"
      />
      <path
        d="M9.7793 16.5468C9.68787 16.6456 9.57703 16.7243 9.45372 16.7782C9.33042 16.8321 9.19731 16.8599 9.06274 16.8599C8.92818 16.8599 8.79507 16.8321 8.67176 16.7782C8.54846 16.7243 8.43761 16.6456 8.34619 16.5468L7.9043 16.9887C8.22226 17.3039 8.64801 17.4867 9.09555 17.5002C9.30804 17.4957 9.51741 17.4481 9.71091 17.3601C9.90442 17.2722 10.078 17.1458 10.2212 16.9887C10.3128 16.89 10.4238 16.8113 10.5472 16.7576C10.6707 16.7038 10.8039 16.6761 10.9385 16.6762C11.0732 16.6763 11.2063 16.7042 11.3297 16.7582C11.453 16.8122 11.5639 16.8911 11.6553 16.9899L12.0962 16.5468C11.9483 16.3873 11.7691 16.26 11.5698 16.173C11.3704 16.0859 11.1552 16.041 10.9377 16.041C10.7202 16.041 10.505 16.0859 10.3057 16.173C10.1064 16.26 9.92715 16.3873 9.7793 16.5468Z"
        fill="currentColor"
      />
      <path
        d="M4.4668 17.1721C4.37539 17.2709 4.26456 17.3497 4.14125 17.4036C4.01794 17.4575 3.88482 17.4853 3.75024 17.4853C3.61567 17.4853 3.48255 17.4575 3.35924 17.4036C3.23593 17.3497 3.12509 17.2709 3.03369 17.1721L2.5918 17.614C2.90976 17.9292 3.33551 18.112 3.78305 18.1255C3.99554 18.121 4.20491 18.0733 4.39841 17.9854C4.59192 17.8975 4.76552 17.7711 4.90867 17.614C5.00027 17.5153 5.11127 17.4366 5.23471 17.3828C5.35815 17.329 5.49138 17.3013 5.62603 17.3014C5.76068 17.3015 5.89385 17.3295 6.0172 17.3835C6.14055 17.4375 6.25142 17.5164 6.34285 17.6152L6.78367 17.1721C6.63578 17.0126 6.45655 16.8854 6.25721 16.7984C6.05788 16.7114 5.84272 16.6665 5.62523 16.6665C5.40774 16.6665 5.19259 16.7114 4.99325 16.7984C4.79392 16.8854 4.61469 17.0126 4.4668 17.1721Z"
        fill="currentColor"
      />
      <path
        d="M7.67334 10.3706C7.69952 10.2928 7.71 10.2105 7.70419 10.1286C7.69838 10.0467 7.67638 9.96671 7.63947 9.89333L6.98151 8.57742C6.90735 8.42917 6.77734 8.31644 6.62008 8.26402C6.46282 8.2116 6.29117 8.22378 6.14289 8.29788L4.82697 8.95583C4.6787 9.02998 4.56595 9.15998 4.51352 9.31725C4.4611 9.47453 4.4733 9.64618 4.54743 9.79446L5.20539 11.1104C5.24203 11.1839 5.2928 11.2494 5.3548 11.3032C5.41681 11.3571 5.48882 11.3981 5.56672 11.4241C5.64461 11.4501 5.72687 11.4604 5.80877 11.4545C5.89067 11.4487 5.97061 11.4267 6.04401 11.3899L7.35993 10.732C7.43347 10.6954 7.49903 10.6447 7.55283 10.5827C7.60662 10.5207 7.64759 10.4486 7.67334 10.3706ZM5.76447 10.8308L5.10605 9.51521L6.42243 8.85695L7.08039 10.1729L5.76447 10.8308Z"
        fill="currentColor"
      />
      <path
        d="M10.0663 12.3931C10.1211 12.5034 10.1972 12.6018 10.2903 12.6825C10.3833 12.7632 10.4914 12.8247 10.6084 12.8634C10.7252 12.9026 10.8485 12.9183 10.9714 12.9096C11.0943 12.9008 11.2142 12.8678 11.3242 12.8124L12.081 12.434C12.3034 12.3228 12.4726 12.1278 12.5512 11.8919C12.6298 11.656 12.6115 11.3985 12.5003 11.1761L12.1219 10.4192C12.0669 10.3091 11.9907 10.2109 11.8977 10.1302C11.8046 10.0495 11.6966 9.98792 11.5798 9.94898C11.463 9.91004 11.3397 9.8945 11.2168 9.90324C11.094 9.91199 10.9741 9.94484 10.864 9.99993L10.1072 10.3784C9.88475 10.4896 9.71562 10.6846 9.63698 10.9205C9.55835 11.1564 9.57664 11.4139 9.68784 11.6363L10.0663 12.3931ZM10.2298 11.1181C10.2427 11.0791 10.2632 11.0431 10.2902 11.012C10.3171 10.981 10.3499 10.9557 10.3867 10.9374L11.1435 10.559C11.1869 10.5373 11.2347 10.5259 11.2831 10.5258C11.3168 10.5259 11.3502 10.5313 11.3822 10.5419C11.4212 10.5549 11.4572 10.5754 11.4882 10.6023C11.5192 10.6292 11.5446 10.662 11.5628 10.6988L11.9413 11.4556C11.9597 11.4923 11.9706 11.5323 11.9736 11.5733C11.9765 11.6142 11.9713 11.6553 11.9583 11.6943C11.9454 11.7332 11.9248 11.7692 11.8979 11.8002C11.871 11.8312 11.8382 11.8566 11.8015 11.8749L11.0447 12.2534C11.008 12.2718 10.968 12.2827 10.927 12.2857C10.8861 12.2886 10.845 12.2834 10.806 12.2704C10.7671 12.2575 10.7311 12.2369 10.7001 12.21C10.669 12.1831 10.6437 12.1503 10.6253 12.1136L10.2469 11.3567C10.2284 11.3201 10.2174 11.2801 10.2145 11.2391C10.2115 11.1982 10.2168 11.157 10.2298 11.1181Z"
        fill="currentColor"
      />
      <path
        d="M9.20258 9.89364C9.29944 9.89351 9.39493 9.87083 9.48151 9.82742L10.7974 9.16946C10.9457 9.09532 11.0585 8.96531 11.1109 8.80804C11.1633 8.65077 11.1511 8.47911 11.077 8.33083L10.419 7.01492C10.3448 6.86667 10.2148 6.75394 10.0576 6.70152C9.90031 6.6491 9.72867 6.66128 9.58039 6.73538L8.26447 7.39333C8.1162 7.46748 8.00345 7.59748 7.95102 7.75475C7.8986 7.91203 7.9108 8.08368 7.98493 8.23196L8.64289 9.54788C8.69497 9.65172 8.77489 9.73904 8.87372 9.8001C8.97255 9.86115 9.0864 9.89354 9.20257 9.89364H9.20258ZM8.54355 7.95272L9.85993 7.29446L10.5179 8.61038L9.20197 9.26833L8.54355 7.95272Z"
        fill="currentColor"
      />
      <path
        d="M1.28703 9.49424C1.35985 9.49424 1.43167 9.47731 1.49683 9.4448L2.04207 9.17219L3.68159 12.6333L4.24647 12.3659L2.60111 8.89273L10.798 4.79422L15.0338 13.2659L15.5929 12.9864L11.357 4.51465L11.8933 4.24644C11.982 4.20203 12.0543 4.13062 12.0999 4.04249C12.1454 3.95435 12.1618 3.85403 12.1467 3.75598C12.1316 3.65793 12.0858 3.5672 12.0158 3.49684C11.9459 3.42649 11.8554 3.38015 11.7575 3.36448L4.5373 2.20939C4.4389 2.1938 4.33809 2.20995 4.24947 2.25549C4.16086 2.30103 4.08905 2.3736 4.04444 2.46269L0.867869 8.81584C0.832129 8.8873 0.815249 8.96671 0.818831 9.04652C0.822412 9.12634 0.846337 9.20392 0.888333 9.27189C0.930329 9.33986 0.989003 9.39597 1.05878 9.43488C1.12856 9.4738 1.20713 9.49423 1.28703 9.49424ZM4.55226 2.84446L11.1791 3.90495L11.0776 3.9557L11.0775 3.95561L1.77439 8.60711L1.77441 8.60713L1.63645 8.67608L4.55226 2.84446Z"
        fill="currentColor"
      />
      <path
        d="M7.9852 11.9816L8.31419 12.6396L8.87327 12.36L8.54429 11.7021C8.46991 11.554 8.3399 11.4415 8.18272 11.3891C8.02554 11.3367 7.854 11.3487 7.70566 11.4225L6.42285 12.064L6.70239 12.6231L7.9852 11.9816Z"
        fill="currentColor"
      />
    </svg>
  );
};
