export enum ExtraDataEnum {
  EXTRA_DATA_MLS = 'EXTRA_DATA_MLS',
  EXTRA_DATA_DETAILS = 'EXTRA_DATA_DETAILS',
}

export enum MapLayerEnum {
  NFHL = 'NFHL',
  EARTHQUAKES = 'EARTHQUAKES',
  WEATHER = 'WEATHER',
  TRANSIT = 'TRANSIT',
  BICYCLING = 'BICYCLING',
  TRAFFIC = 'TRAFFIC',
  STORMS = 'STORMS',
  GREATSCHOOLS = 'GREATSCHOOLS',
  EV_CHARGING_STATIONS = 'EV_CHARGING_STATIONS',
  AIR_QUALITY = 'AIR_QUALITY',
}

export type LoanPageRouteParams = {
  loanNumber: string;
};
