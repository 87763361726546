import React from 'react';

export const LineGraphIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3C3.22386 3 3 3.22386 3 3.5V20.5C3 20.7761 3.22386 21 3.5 21H20.5C20.7761 21 21 20.7761 21 20.5C21 20.2239 20.7761 20 20.5 20H4V3.5C4 3.22386 3.77614 3 3.5 3Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5312 6.25C11.5258 6.25 10.5955 6.78197 10.0855 7.64845L9.68981 8.32083C9.39455 8.8225 9.20674 9.38004 9.13828 9.95812L8.46644 15.6315C8.24981 17.4608 6.85765 18.9307 5.04283 19.2463L4.89505 19.272C4.84064 19.2815 4.78886 19.245 4.7794 19.1906L4.728 18.8951C4.71853 18.8406 4.75497 18.7889 4.80938 18.7794L4.95716 18.7537C6.55419 18.476 7.77928 17.1824 7.96991 15.5727L8.64175 9.89932C8.71836 9.25247 8.92851 8.62858 9.2589 8.06722L9.65462 7.39485C10.2544 6.3757 11.3487 5.75 12.5312 5.75H12.7764C12.7791 5.75 12.7812 5.75217 12.7812 5.75485C12.7812 5.75739 12.7832 5.7595 12.7857 5.75969C13.8692 5.84236 14.8514 6.44925 15.4079 7.39485L15.7925 8.04832C16.1305 8.62271 16.3416 9.26288 16.4113 9.92571L16.9992 15.5105C17.1719 17.1513 18.4154 18.4767 20.0419 18.7535L20.1898 18.7787C20.2443 18.788 20.2809 18.8396 20.2716 18.8941L20.2213 19.1898C20.212 19.2443 20.1604 19.2809 20.1059 19.2716L19.958 19.2465C18.1106 18.932 16.6981 17.4265 16.5019 15.5628L15.9141 9.97805C15.8518 9.38628 15.6634 8.81474 15.3616 8.30193L14.977 7.64845C14.467 6.78197 13.5367 6.25 12.5312 6.25Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </svg>
  );
};
