import { AdminAttributeType } from './admin';
import { BorrowersAttributeType } from './borrowers';
import { CustodianAttributeType } from './custodians';
import { DocumentsAttributeType } from './documents';
import { IndexAttributeType } from './indexes';
import { LoansAttributeType } from './loans';
import { NotificationsAttributeType } from './notifications';
import { OrganizationAttributeType } from './organizations';
import { PoolAttributeType } from './pools';
import { PortfolioAttributeType } from './portfolio';
import { RolesAttributeType } from './roles';
import {
  TradeCounterpartyAttributeType,
  TradeMessageAttributeType,
  TradeQuoteAttributeType,
  TradeRequestAttributeType,
} from './trades';
import { UsersAttributeType } from './users';

export type Identifier = number;

export const AttributeType = {
  ...BorrowersAttributeType,
  ...CustodianAttributeType,
  ...DocumentsAttributeType,
  ...LoansAttributeType,
  ...PoolAttributeType,
  ...UsersAttributeType,
  ...TradeRequestAttributeType,
  ...TradeCounterpartyAttributeType,
  ...TradeQuoteAttributeType,
  ...TradeMessageAttributeType,
  ...OrganizationAttributeType,
  ...NotificationsAttributeType,
  ...RolesAttributeType,
  ...IndexAttributeType,
  ...PortfolioAttributeType,
  ...AdminAttributeType,
};

export type AttributeType =
  | BorrowersAttributeType
  | CustodianAttributeType
  | DocumentsAttributeType
  | LoansAttributeType
  | PoolAttributeType
  | UsersAttributeType
  | TradeRequestAttributeType
  | TradeCounterpartyAttributeType
  | TradeQuoteAttributeType
  | TradeMessageAttributeType
  | OrganizationAttributeType
  | NotificationsAttributeType
  | RolesAttributeType
  | IndexAttributeType
  | PortfolioAttributeType
  | AdminAttributeType;
