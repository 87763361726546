import React from 'react';

export const HomeFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector"
        d="M17.8182 19V11.7083L19.1273 12.7778L20 11.5333L17.8182 9.74444V7.33333H16.3636V8.55833L12 5L4 11.5333L4.87273 12.7583L6.18182 11.7083V19H11.2727V14.3333H12.7273V19H17.8182Z"
        fill="currentColor"
      />
    </svg>
  );
};
