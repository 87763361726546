import React from 'react';

export const BidLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2 16.4H12.8V15.6H13.6C13.8267 15.6 14.0167 15.5233 14.17 15.37C14.3233 15.2167 14.4 15.0267 14.4 14.8V12.4C14.4 12.1733 14.3233 11.9833 14.17 11.83C14.0167 11.6767 13.8267 11.6 13.6 11.6H11.2V10.8H14.4V9.2H12.8V8.4H11.2V9.2H10.4C10.1733 9.2 9.98333 9.27667 9.83 9.43C9.67667 9.58333 9.6 9.77333 9.6 10V12.4C9.6 12.6267 9.67667 12.8167 9.83 12.97C9.98333 13.1233 10.1733 13.2 10.4 13.2H12.8V14H9.6V15.6H11.2V16.4ZM5.6 18.8C5.16 18.8 4.78333 18.6433 4.47 18.33C4.15667 18.0167 4 17.64 4 17.2V7.6C4 7.16 4.15667 6.78333 4.47 6.47C4.78333 6.15667 5.16 6 5.6 6H18.4C18.84 6 19.2167 6.15667 19.53 6.47C19.8433 6.78333 20 7.16 20 7.6V17.2C20 17.64 19.8433 18.0167 19.53 18.33C19.2167 18.6433 18.84 18.8 18.4 18.8H5.6ZM5.6 17.2H18.4V7.6H5.6V17.2Z"
        fill="currentColor"
      />
    </svg>
  );
};
