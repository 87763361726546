import { PortfolioAnalyticItem } from '@plus-platform/shared';
import { useParams } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { Heading } from '../../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../../components/Page';
import { useGetSummaryByAnalyticGroupQuery } from '../../hooks/queries';
import { AnalyticGroupDetailWidget } from './AnalyticGroupDetailWidget';
import { getLabelFromAnalyticResult } from './utils';

type AnalyticGroupDetailParams = {
  analyticId: string;
  groupValue: string;
};

type AnalyticGroupDetailPageProps = {
  analytic?: PortfolioAnalyticItem;
  isAnalyticLoading: boolean;
};

export const AnalyticGroupDetailPage = ({
  analytic,
  isAnalyticLoading,
}: AnalyticGroupDetailPageProps) => {
  const { analyticId, groupValue } = useParams<AnalyticGroupDetailParams>();

  const { data: loansSummaryData, isLoading: isSummaryLoading } = useGetSummaryByAnalyticGroupQuery(
    { analyticId, groupValue }
  );

  const title = analytic ? getLabelFromAnalyticResult(analytic) : '';

  return (
    <Page>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>{title}</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem linkTo="/analytics" title="Analytics" />
              <BreadcrumbItem linkTo={`/analytics/${analyticId}`} title={title} />
              <BreadcrumbItem title="Detail" />
            </Breadcrumb>
          </div>
        </PageHeaderContent>
      </PageHeader>

      <PageContent>
        <AnalyticGroupDetailWidget
          analytic={analytic}
          loansSummaryData={loansSummaryData}
          isLoading={isAnalyticLoading || isSummaryLoading}
          groupValue={groupValue}
        />
      </PageContent>
    </Page>
  );
};
