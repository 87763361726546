import {
  MISMOCount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum DocumentationStateType {
  AsCollected = 'AsCollected',
  AsRequested = 'AsRequested',
  AsRequired = 'AsRequired',
  Other = 'Other',
}

enum EmploymentDocumentType {
  Other = 'Other',
  ThirdPartyEmploymentStatement = 'ThirdPartyEmploymentStatement',
  VerbalStatement = 'VerbalStatement',
  VerificationOfEmployment = 'VerificationOfEmployment',
}

enum EmploymentVerificationRangeType {}

export type EmploymentDocumentation = MISMOEntity &
  Partial<{
    documentationStateType: DocumentationStateType;
    documentationStateTypeOtherDescription: MISMOString;
    employmentDocumentType: EmploymentDocumentType;
    employmentDocumentTypeOtherDescription: MISMOString;
    employmentVerificationRangeCount: MISMOCount;
    employmentVerificationRangeType: EmploymentVerificationRangeType;
    employmentVerificationRangeTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
