import React from 'react';

export const ContactIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.125 19C16.6213 19 15.0884 18.637 13.5264 17.9111C11.9644 17.1852 10.5157 16.1546 9.18056 14.8194C7.84537 13.4843 6.81481 12.0356 6.08889 10.4736C5.36296 8.91157 5 7.3787 5 5.875C5 5.625 5.08333 5.41666 5.25 5.25C5.41666 5.08333 5.625 5 5.875 5H8.59722C8.7787 5 8.93426 5.06481 9.06389 5.19444C9.19352 5.32407 9.28426 5.48611 9.33611 5.68056L9.85973 8.12357C9.88658 8.30971 9.8838 8.47731 9.85139 8.62639C9.81898 8.77546 9.74944 8.90332 9.64277 9.00995L7.70278 10.9694C8.03981 11.5398 8.3963 12.0713 8.77222 12.5639C9.14815 13.0565 9.56296 13.5231 10.0167 13.9639C10.4963 14.4565 11.0019 14.9069 11.5333 15.3153C12.0648 15.7236 12.6222 16.0833 13.2056 16.3944L15.0528 14.4889C15.1824 14.3463 15.3325 14.2491 15.5029 14.1972C15.6734 14.1454 15.841 14.1324 16.0056 14.1583L18.3194 14.6639C18.5139 14.7157 18.6759 14.8197 18.8056 14.9759C18.9352 15.132 19 15.3067 19 15.5V18.125C19 18.375 18.9167 18.5833 18.75 18.75C18.5833 18.9167 18.375 19 18.125 19ZM7.11944 9.9L8.69444 8.30556L8.24722 6.16667H6.16667C6.19259 6.71111 6.28009 7.28472 6.42917 7.8875C6.57824 8.49028 6.80833 9.16111 7.11944 9.9ZM14.2944 16.9583C14.8259 17.2046 15.4028 17.4056 16.025 17.5611C16.6472 17.7167 17.25 17.8074 17.8333 17.8333V15.7528L15.8306 15.3444L14.2944 16.9583Z"
        fill="currentColor"
      />
    </svg>
  );
};
