import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { ModificationAspect } from './modificationAspect';

enum LoanModificationActionType {
  BorrowerDelinquencyWorkout = 'BorrowerDelinquencyWorkout',
  Capitalization = 'Capitalization',
  ChangeOfPaymentFrequency = 'ChangeOfPaymentFrequency',
  ConstructionToPermanentFinancing = 'ConstructionToPermanentFinancing',
  ForgivenInterest = 'ForgivenInterest',
  HAMP = 'HAMP',
  LenderPortfolioManagement = 'LenderPortfolioManagement',
  Other = 'Other',
  PrincipalForgiveness = 'PrincipalForgiveness',
  Restructure = 'Restructure',
  UnscheduledPaymentRecast = 'UnscheduledPaymentRecast',
}

export type Modification = MISMOEntity &
  Partial<{
    borrowerModificationClosingCostAmount: MISMOAmount;
    forbearanceExpenseReductionAmount: MISMOAmount;
    forbearanceInterestReductionAmount: MISMOAmount;
    forbearancePrincipalReductionAmount: MISMOAmount;
    interestBearingUPBAmount: MISMOAmount;
    interestForgivenessAmount: MISMOAmount;
    loanModificationActionType: LoanModificationActionType;
    loanModificationActionTypeOtherDescription: MISMOString;
    loanModificationEffectiveDate: MISMODate;
    modificationAdditionalEndorsementIndicator: MISMOIndicator;
    modificationAgreementAcceptanceCutoffDate: MISMODate;
    modificationAgreementReceivedIndicator: MISMOIndicator;
    modificationEstimatedRequestProcessingDaysCount: MISMOCount;
    modificationSignedDate: MISMODate;
    modificationSubmissionCutoffDate: MISMODate;
    modificationTrialPlanEffectiveDate: MISMODate;
    modificationTrialPlanEndDate: MISMODate;
    modificationTrialPlanReturnOfDocumentsCutoffDate: MISMODate;
    nonInterestBearingUPBAmount: MISMOAmount;
    preModificationInterestRatePercent: MISMOPercent;
    preModificationPrincipalAndInterestPaymentAmount: MISMOAmount;
    preModificationTotalPaymentAmount: MISMOAmount;
    preModificationUPBAmount: MISMOAmount;
    principalForgivenessAmount: MISMOAmount;
    principalReductionOfUnsecuredAmount: MISMOAmount;
    reimbursableServicerModificationCostAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
    servicerAttestsOnSubmissionIndicator: MISMOIndicator;
    servicerOfficerModificationSignatureDate: MISMODate;
    totalForgivenessAmount: MISMOAmount;
    totalStepCount: MISMOCount;
    modificationAspects: ModificationAspect[];
  }>;
