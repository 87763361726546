import styled from 'styled-components/macro';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white05};
`;

export const HeaderTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white70};

  ${({ theme }) => theme.typography.headings.headingRegular03}

  strong {
    color: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.typography.headings.headingMedium03}
  }
`;
