import { GetPoolLoansItem, Identifier } from '@plus-platform/shared';
import compact from 'lodash/compact';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import map from 'lodash/map';
import without from 'lodash/without';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { ButtonLoader } from '../components/ButtonLoader';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import { getMaxVisibleItems, TableWrapper } from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { useGetPoolLoansQuery, usePoolQuery, usePoolUpdateMutation } from '../hooks/queries';
import { useDoubleClick } from '../hooks/useDoubleClick';
import { LoanDetailPanel } from '../loans/LoanDetailPanel';
import { SelectablePortfolioTable } from '../portfolio/PortfolioTable';
import { useLoanFilters } from './useLoanFilters';

type PoolRemoveLoansTableProps = {
  poolId: string;
};

export const PoolRemoveLoansTable = ({ poolId }: PoolRemoveLoansTableProps) => {
  const [selectedLoanId, setSelectedLoanId] = React.useState<Identifier | undefined>();
  const [checkedLoanIds, setCheckedLoanIds] = React.useState<Identifier[]>([]);

  const history = useHistory();

  const { handleClick: handleRowClick, handleDoubleClick: handleRowDoubleClick } = useDoubleClick({
    onClick: (loanId: Identifier) => {
      setSelectedLoanId(selectedLoanId !== loanId ? loanId : undefined);
    },
    onDoubleClick: (loanNumber: string) => {
      history.push(`/loan/${loanNumber}`);
    },
  });

  const { appliedFilters, filters, updateFilters } = useLoanFilters();

  const { data: poolSummary, isLoading: isPoolSummaryLoading } = usePoolQuery(Number(poolId));

  const {
    data: poolLoans,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isPoolLoansLoading,
  } = useGetPoolLoansQuery({
    poolId: Number(poolId),
    filterIds: map(appliedFilters, 'id'),
  });

  const isLoading = isPoolSummaryLoading || isPoolLoansLoading;

  const pages = compact(get(poolLoans, 'pages', []));
  const loans: GetPoolLoansItem[] = flatMap(pages, 'data');
  const selectedLoan = find<GetPoolLoansItem>(loans, { id: selectedLoanId });

  const totalLoansCount = get(pages, '0.totalLoansCount', 0);
  const totalFilteredLoansCount = get(pages, '0.totalFilteredLoansCount', 0);

  const { isLoading: isUpdating, mutateAsync: updatePool } = usePoolUpdateMutation();

  const handleRemoveLoansFromPool = async () => {
    if (poolSummary) {
      await updatePool({
        poolId: Number(poolId),
        loanIds: without(poolSummary.loanIds, ...checkedLoanIds),
      });
      setCheckedLoanIds([]);
    }
  };

  return (
    <TableFilters filters={filters} onFiltersUpdate={updateFilters} path={`/pool/${poolId}`}>
      {({ filterPopupPath, isFiltersActive }) => (
        <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
          {({
            closeLeftPanel,
            closeRightPanel,
            containerHeight,
            isLeftPanelOpen,
            isRightPanelOpen,
            openLeftPanel,
            openRightPanel,
          }) => (
            <>
              {isLeftPanelOpen && (
                <TableFilterPanel
                  filters={filters}
                  filtersPopupPath={filterPopupPath}
                  onClose={closeLeftPanel}
                  onFiltersUpdate={updateFilters}
                />
              )}
              <Card $hasFlex>
                <TableToolbar>
                  <TableStats totalCount={totalLoansCount} visibleCount={loans.length} />
                  <Divider $orientation="vertical" $flexItem />
                  <TableFilterBar
                    filters={filters}
                    onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                    onFiltersUpdate={updateFilters}
                  />
                  {checkedLoanIds.length > 0 && (
                    <>
                      {appliedFilters.length === 0 && <Divider $orientation="vertical" $flexItem />}
                      <ButtonLoader
                        $color="primary"
                        $variant="outlined"
                        $size="xSmall"
                        isLoading={isUpdating}
                        onClick={handleRemoveLoansFromPool}
                      >
                        Remove {checkedLoanIds.length} loan
                        {checkedLoanIds.length > 1 && 's'} from pool
                      </ButtonLoader>
                    </>
                  )}
                </TableToolbar>
                <ActivityIndicator contain isActive={isLoading}>
                  <TableWrapper>
                    <SelectablePortfolioTable
                      checkedLoanIds={checkedLoanIds}
                      fetchNextPage={fetchNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      loans={loans}
                      maxVisibleItems={getMaxVisibleItems({
                        containerHeight,
                        hasHeaderGroups: true,
                      })}
                      onCheckboxClick={(checkedLoanIds) => setCheckedLoanIds(checkedLoanIds)}
                      onRowClick={(loanId: Identifier) => {
                        if (selectedLoanId === loanId) {
                          closeRightPanel();
                        } else {
                          openRightPanel();
                        }
                        handleRowClick(loanId);
                      }}
                      onRowDoubleClick={handleRowDoubleClick}
                      totalLoansCount={totalFilteredLoansCount}
                    />
                  </TableWrapper>
                </ActivityIndicator>
              </Card>
              {isRightPanelOpen && (
                <LoanDetailPanel
                  loan={selectedLoan}
                  onClose={() => {
                    setSelectedLoanId(undefined);
                    closeRightPanel();
                  }}
                />
              )}
            </>
          )}
        </TableLayout>
      )}
    </TableFilters>
  );
};
