import { Filter, FilterKey, FilterOperator } from '@plus-platform/shared';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import words from 'lodash/words';

import { Column } from '../../utils/portfolioFilterUtils';
import { FormFilter } from './types';

const commonOperators = [FilterOperator.IS, FilterOperator.IS_NOT];
const numericOperators = [FilterOperator.LESS_THAN, FilterOperator.GREATER_THAN];

export const isColumnWithNumericValue = (columnKey: FilterKey) => {
  return [
    FilterKey.PURCHASE_PRICE,
    FilterKey.CURRENT_BPO,
    FilterKey.DOWN_PAYMENT_AMOUNT,
    FilterKey.ORIGINAL_UPB,
    FilterKey.CURRENT_UPB,
    FilterKey.CURRENT_LTV,
    FilterKey.PRINCIPAL,
    FilterKey.INTEREST,
    FilterKey.TAXES,
    FilterKey.INSURANCE,
    FilterKey.HOA_FEE,
    FilterKey.MORTGAGE_INSURANCE,
    FilterKey.NOTE_RATE,
  ].includes(columnKey);
};

const getNormalizedFormFilterValue = (filter: FormFilter) => {
  const { key, value } = filter;

  if (isColumnWithNumericValue(key)) {
    return Number(value);
  }

  if (key === FilterKey.IS_DELINQUENT) {
    return value === 'true';
  }

  return value;
};

const mapOperatorsToOptions = (operators: FilterOperator[]) => {
  return operators.map((operator) => ({
    label: words(capitalize(operator)).join(' '),
    value: operator,
  }));
};

export const getOperatorOptionsForColumn = (columnKey: FilterKey) => {
  if (isColumnWithNumericValue(columnKey)) {
    return mapOperatorsToOptions([...commonOperators, ...numericOperators]);
  }

  return mapOperatorsToOptions(commonOperators);
};

export const mapFormFiltersToPortfolioFilters = (filters: FormFilter[]): Omit<Filter, 'id'>[] => {
  return filters.map((filter) => ({
    ...filter,
    operator: filter.operator as FilterOperator,
    value: getNormalizedFormFilterValue(filter),
  }));
};

export const mapPortfolioFiltersToFormFilters = (filter: Filter[]): FormFilter[] => {
  return filter.map((filter) => ({
    ...filter,
    name: filter.name ?? '',
    value: isNil(filter.value) ? '' : String(filter.value),
  }));
};

export const mapColumnsToSelectOptions = (columns: Column[]) => {
  return columns.map((column) => ({
    label: column.title,
    value: column.key,
  }));
};
