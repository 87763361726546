import { AttributeType, PermissionType } from '@plus-platform/shared';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../global/PrivateRoute';
import { DarkPage, LightPage } from '../global/ThemedPage';
import { AdminPage, AdminPageContent } from './AdminDashboardPage.styles';
import { AdminUsersPage } from './AdminUsersPage';
import { FlagsAlertsWarningsPage } from './FlagsAlertsWarningsPage';
import { SidePanel } from './SidePanel';
import { AdminRouteType } from './types';

export const AdminDashboardPage = () => {
  return (
    <>
      <Switch>
        <PrivateRoute
          component={() => (
            <DarkPage>
              <AdminUsersPage />
            </DarkPage>
          )}
          path={AdminRouteType.Users}
          requiredPermissions={{
            [PermissionType.PERMISSION_READ]: [
              AttributeType.ATTRIBUTE_ORGANIZATION_USER,
              AttributeType.ATTRIBUTE_ORGANIZATION_INVITE,
            ],
          }}
        />

        <LightPage>
          <AdminPage>
            <SidePanel />
            <AdminPageContent>
              <PrivateRoute
                component={() => <FlagsAlertsWarningsPage />}
                path={AdminRouteType.FlagsAlertsWarning}
              />
            </AdminPageContent>
          </AdminPage>
        </LightPage>
      </Switch>
    </>
  );
};
