import { Adjustment } from '../adjustment';
import { Amortization } from '../amortization';
import { InterestCalculation } from '../amortization/interestCalculation';
import { Buydown } from '../buydown';
import { Construction } from '../construction';
import { DocumentSpecificDataSet } from '../documents';
import { DownPayment } from '../downPayment';
import { Escrow } from '../escrow';
import { FeeInformation } from '../feeInformation';
import { Foreclosure } from '../foreclosure';
import { HELOC } from '../HELOC';
import { HighCostMortgage } from '../highCostMortgage';
import { HousingExpense, HousingExpenseSummary } from '../housingExpense';
import { InterestOnly } from '../interestOnly';
import { InvestorLoanInformation } from '../investor';
import { InvestorFeature } from '../investorFeature';
import { LateCharge } from '../lateCharge';
import { LTV } from '../ltv';
import { Maturity } from '../maturity';
import { MIData } from '../MIData';
import { Modification } from '../modification';
import { OriginationFund } from '../originationFund';
import { OriginationSystem } from '../originationSystem';
import { Payment } from '../payment';
import { PurchaseCredit } from '../purchaseCredit';
import { Qualification } from '../qualification';
import { QualifiedMortgage } from '../qualifiedMortgage';
import { Refinance } from '../refinance';
import { Servicing } from '../servicing';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMODatetime,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { Underwriting } from '../underwriting';
import { ClosingInformation } from './closingInformation';
import { GovernmentLoan } from './governmentLoan';
import { HMDALoan } from './HDMALoan';
import { LoanComment } from './loanComment';
import { LoanIdentifier } from './loanIdentifier';
import { LoanLevelCredit } from './loanLevelCredit';
import { LoanProduct } from './loanProduct';
import { LoanProgram } from './loanProgram';
import { TermsOfLoan } from './loanTerms';

enum CorrespondentAcquisitionType {
  Bulk = 'Bulk',
  Flow = 'Flow',
  Other = 'Other',
}

enum CreditorServicingOfLoanStatementType {
  CreditorIntendsToServiceLoan = 'CreditorIntendsToServiceLoan',
  CreditorIntendsToTransferServicingOfLoan = 'CreditorIntendsToTransferServicingOfLoan',
  CreditorMayAssignSellOrTransferServicingOfLoan = 'CreditorMayAssignSellOrTransferServicingOfLoan',
  Other = 'Other',
}

enum EscrowAbsenceReasonType {
  BorrowerDeclined = 'BorrowerDeclined',
  LenderDoesNotOffer = 'LenderDoesNotOffer',
  LenderDoesNotRequire = 'LenderDoesNotRequire',
  Other = 'Other',
}

enum EscrowAccountLenderRequirementType {
  EscrowsRequiredByLender = 'EscrowsRequiredByLender',
  EscrowsWaivedByLender = 'EscrowsWaivedByLender',
}

enum LendingLimitType {
  Calculated = 'Calculated',
  County = 'County',
  Investor = 'Investor',
  Other = 'Other',
}

enum LoanClosingStatusType {
  Closed = 'Closed',
  TableFunded = 'TableFunded',
}

enum LoanRepaymentType {
  ConstantPrincipal = 'ConstantPrincipal',
  Other = 'Other',
  PrincipalPaymentOption = 'PrincipalPaymentOption',
  ScheduledAmortization = 'ScheduledAmortization',
}

export enum LoanRoleType {
  HistoricalLoan = 'HistoricalLoan',
  RelatedLoan = 'RelatedLoan',
  SubjectLoan = 'SubjectLoan',
}

enum LoanSecuredByType {
  Other = 'Other',
  SubjectProperty = 'SubjectProperty',
}

enum RESPAConformingYearType {
  January2010 = 'January2010',
  October2015 = 'October2015',
  Other = 'Other',
  Pre2010 = 'Pre2010',
}

enum SCRAReliefStatusType {
  NeverReceived = 'NeverReceived',
  OneOrMoreBorrowersCurrentlyReceive = 'OneOrMoreBorrowersCurrentlyReceive',
  OneOrMoreBorrowersPreviouslyReceived = 'OneOrMoreBorrowersPreviouslyReceived',
  Other = 'Other',
}

enum SelectionType {
  Comparison = 'Comparison',
  Proposed = 'Proposed',
  Requested = 'Requested',
  Selected = 'Selected',
}

enum ServicingTransferStatusType {
  Released = 'Released',
  Retained = 'Retained',
}

export enum LoanStateType {
  AtAssumption = 'AtAssumption',
  AtBankruptcyFiling = 'AtBankruptcyFiling',
  AtClosing = 'AtClosing',
  AtConstruction = 'AtConstruction',
  AtConversion = 'AtConversion',
  AtEstimate = 'AtEstimate',
  AtModification = 'AtModification',
  AtRelief = 'AtRelief',
  AtReportingCycleBeginning = 'AtReportingCycleBeginning',
  AtRepurchase = 'AtRepurchase',
  AtReset = 'AtReset',
  AtSecurityIssuance = 'AtSecurityIssuance',
  AtTransfer = 'AtTransfer',
  AtTrial = 'AtTrial',
  AtUnderwriting = 'AtUnderwriting',
  Current = 'Current',
}

type LoanState = MISMOEntity &
  Partial<{
    loanStateDate: MISMODate;
    loanStateDatetime: MISMODatetime;
    loanStateType: LoanStateType;
  }>;

export type Loan = MISMOEntity &
  Partial<{
    acceleratedPaymentProgramIndicator: MISMOIndicator;
    ACHIndicator: MISMOIndicator;
    activeLitigationIndicator: MISMOIndicator;
    applicationReceivedDate: MISMODate;
    armsLengthIndicator: MISMOIndicator;
    assumabilityIndicator: MISMOIndicator;
    assumedIndicator: MISMOIndicator;
    balloonGuaranteedRefinancingIndicator: MISMOIndicator;
    balloonIndicator: MISMOIndicator;
    balloonPaymentAmount: MISMOAmount;
    balloonPaymentMaximumAmount: MISMOAmount;
    balloonPaymentMinimumAmount: MISMOAmount;
    balloonResetIndicator: MISMOIndicator;
    balloonResetOptionIndicator: MISMOIndicator;
    belowMarketSubordinateFinancingIndicator: MISMOIndicator;
    biweeklyToMonthlyOnDefaultIndicator: MISMOIndicator;
    borrowerCount: MISMOCount;
    buydownTemporarySubsidyFundingIndicator: MISMOIndicator;
    capitalizedFeesIndicator: MISMOIndicator;
    capitalizedLoanIndicator: MISMOIndicator;
    closingCostFinancedIndicator: MISMOIndicator;
    closingDisclosureWaitingPeriodWaivedIndicator: MISMOIndicator;
    collateralPledgedToName: MISMOString;
    concurrentOriginationIndicator: MISMOIndicator;
    concurrentOriginationLenderIndicator: MISMOIndicator;
    conformingIndicator: MISMOIndicator;
    constructionLoanIndicator: MISMOIndicator;
    conversionOfContractForDeedIndicator: MISMOIndicator;
    convertibleIndicator: MISMOIndicator;
    correspondentAcquisitionType: CorrespondentAcquisitionType;
    correspondentAcquisitionTypeOtherDescription: MISMOString;
    creditEnhancementIndicator: MISMOIndicator;
    creditorServicingOfLoanStatementType: CreditorServicingOfLoanStatementType;
    creditorServicingOfLoanStatementTypeOtherDescription: MISMOString;
    currentInterestRatePercent: MISMOPercent;
    deferredInterestBalanceAmount: MISMOAmount;
    delegatedUnderwritingIndicator: MISMOIndicator;
    demandFeatureIndicator: MISMOIndicator;
    eligibleForLenderPaidMIIndicator: MISMOIndicator;
    employeeLoanProgramIndicator: MISMOIndicator;
    energyRelatedImprovementsIndicator: MISMOIndicator;
    enoteCertifiedIndicator: MISMOIndicator;
    enoteIndicator: MISMOIndicator;
    escrowAbsenceReasonType: EscrowAbsenceReasonType;
    escrowAbsenceReasonTypeOtherDescription: MISMOString;
    escrowAccountLenderRequirementType: EscrowAccountLenderRequirementType;
    escrowAccountRequestedIndicator: MISMOIndicator;
    escrowIndicator: MISMOIndicator;
    escrowNotAllowedIndicator: MISMOIndicator;
    escrowsElectedByBorrowerIndicator: MISMOIndicator;
    HELOCIndicator: MISMOIndicator;
    higherPricedMortgageLoanIndicator: MISMOIndicator;
    homeBuyersHomeownershipEducationCertificateIndicator: MISMOIndicator;
    ineligibleLoanProductIndicator: MISMOIndicator;
    initialFixedPeriodEffectiveMonthsCount: MISMOCount;
    interestCreditedToBorrowerIndicator: MISMOIndicator;
    interestOnlyIndicator: MISMOIndicator;
    interestRateIncreaseIndicator: MISMOIndicator;
    jurisdictionHighCostLoanIndicator: MISMOIndicator;
    lenderPlacedHazardInsuranceIndicator: MISMOIndicator;
    lenderSelfInsuredIndicator: MISMOIndicator;
    lendingLimitAmount: MISMOAmount;
    lendingLimitType: LendingLimitType;
    lendingLimitTypeOtherDescription: MISMOString;
    lienHolderSameAsSubjectLoanIndicator: MISMOIndicator;
    loanAffordableIndicator: MISMOIndicator;
    loanAgeMonthsCount: MISMOCount;
    loanAmountIncreaseIndicator: MISMOIndicator;
    loanApprovalDate: MISMODate;
    loanApprovalExpirationDate: MISMODate;
    loanApprovalPeriodDaysCount: MISMOCount;
    loanAssetAddedIndicator: MISMOIndicator;
    loanClosingStatusType: LoanClosingStatusType;
    loanCommitmentDate: MISMODate;
    loanCommitmentExpirationDate: MISMODate;
    loanCommitmentPeriodDaysCount: MISMOCount;
    loanEstimateWaitingPeriodWaivedIndicator: MISMOIndicator;
    loanFundingDate: MISMODate;
    loanLevelCreditScoreIndicator: MISMOIndicator;
    loanRepaymentType: LoanRepaymentType;
    loanRepaymentTypeOtherDescription: MISMOString;
    loanRoleType: LoanRoleType;
    loanSaleFundingDate: MISMODate;
    loanSecuredByType: LoanSecuredByType;
    loanSecuredByTypeOtherDescription: MISMOString;
    loanSecuredIndicator: MISMOIndicator;
    loanSellerProvidedInvestmentRatingDescription: MISMOString;
    lostNoteAffidavitIndicator: MISMOIndicator;
    MICoverageExistsIndicator: MISMOIndicator;
    MIRequiredIndicator: MISMOIndicator;
    mortgageModificationIndicator: MISMOIndicator;
    negativeAmortizationIndicator: MISMOIndicator;
    openEndCreditIndicator: MISMOIndicator;
    originalLoanStandardEscrowProvisionsIndicator: MISMOIndicator;
    overdueInterestAmount: MISMOAmount;
    paymentIncreaseIndicator: MISMOIndicator;
    piggybackLoanIndicator: MISMOIndicator;
    poolIdentifier: MISMOString;
    poolIssueDate: MISMODate;
    poolPrefixIdentifier: MISMOIdentifier;
    prepaymentPenaltyIndicator: MISMOIndicator;
    prepaymentPenaltyWaivedIndicator: MISMOIndicator;
    prepaymentRestrictionIndicator: MISMOIndicator;
    propertiesFinancedByLenderCount: MISMOCount;
    propertyInspectionWaiverIndicator: MISMOIndicator;
    qualifiedMortgageIndicator: MISMOIndicator;
    recoverableCorporateAdvanceBalanceFromBorrowerAmount: MISMOAmount;
    recoverableCorporateAdvanceBalanceFromThirdPartyAmount: MISMOAmount;
    refundOfOverpaidInterestCalendarYearAmount: MISMOAmount;
    regulationZHighCostLoanIndicator: MISMOIndicator;
    relocationLoanIndicator: MISMOIndicator;
    remainingUnearnedInterestAmount: MISMOAmount;
    renovationLoanIndicator: MISMOIndicator;
    RESPAConformingYearType: RESPAConformingYearType;
    RESPAConformingYearTypeOtherDescription: MISMOString;
    reverseMortgageIndicator: MISMOIndicator;
    rightOfRescissionWaitingPeriodWaivedIndicator: MISMOIndicator;
    SCRAIndicator: MISMOIndicator;
    SCRAReliefForbearanceRequestedIndicator: MISMOIndicator;
    SCRAReliefStatusType: SCRAReliefStatusType;
    SCRAReliefStatusTypeOtherDescription: MISMOString;
    seasonalPaymentFeatureDescription: MISMOString;
    seasonalPaymentFeatureIndicator: MISMOIndicator;
    selectionType: SelectionType;
    sequenceNumber: MISMOSequenceNumber;
    servicingTransferProhibitedIndicator: MISMOIndicator;
    servicingTransferStatusType: ServicingTransferStatusType;
    sharedAppreciationIndicator: MISMOIndicator;
    sharedEquityIndicator: MISMOIndicator;
    signedAuthorizationToRequestTaxRecordsIndicator: MISMOIndicator;
    stepPaymentsFeatureDescription: MISMOString;
    subordinateFinancingIsNewIndicator: MISMOIndicator;
    taxRecordsObtainedIndicator: MISMOIndicator;
    timelyPaymentRateReductionIndicator: MISMOIndicator;
    totalMortgagedPropertiesCount: MISMOCount;
    totalSalesConcessionAmount: MISMOAmount;
    totalSeniorFinancingAmount: MISMOAmount;
    totalSubordinateFinancingAmount: MISMOAmount;
    TRIDRuleIndicator: MISMOIndicator;
    warehouseLenderIndicator: MISMOIndicator;
    workoutActiveIndicator: MISMOIndicator;

    // ACH: ACH;
    adjustment: Adjustment;
    // affordableLending: AffordableLending;
    amortization: Amortization;
    // assumability: Assumability;
    // billingAddress: BillingAddress;
    buydown: Buydown;
    // capitalization: Capitalization;
    // capitalizationSummary: CapitalizationSummary;
    // chargeOff: ChargeOff;
    closingInformation: ClosingInformation;
    // collections: Collection[];
    construction: Construction;
    // contractVariances: ContractVariance[];
    // creditEnhancements: CreditEnhancement[];
    // disclosures: Disclosure[];
    // documentations: Documentation[];
    documentSpecificDataSets: DocumentSpecificDataSet[];
    downPayments: DownPayment[];
    // draw: Draw;
    escrow: Escrow;
    feeInformation: FeeInformation;
    foreclosures: Foreclosure[];
    governmentLoan: GovernmentLoan;
    HELOC: HELOC;
    highCostMortgages: HighCostMortgage[];
    HMDALoan: HMDALoan;
    housingExpenses: HousingExpense[];
    housingExpenseSummary: HousingExpenseSummary;
    // insuranceClaims: InsuranceClaim[];
    interestCalculation: InterestCalculation;
    interestOnly: InterestOnly;
    investorFeatures: InvestorFeature[];
    investorLoanInformation: InvestorLoanInformation;
    lateCharge: LateCharge;
    loanComments: LoanComment[];
    loanIdentifiers: LoanIdentifier[];
    loanLevelCredit: LoanLevelCredit;
    loanProduct: LoanProduct;
    loanPrograms: LoanProgram[];
    loanState: LoanState;
    // loanStatuses: LoanStatus[];
    LTV: LTV;
    maturity: Maturity;
    // MERSRegistrations: MERSRegistration[];
    MIData: MIData;
    modifications: Modification[];
    // modificationSummary: ModificationSummary;
    // mortgageScores: MortgageScore[];
    // negativeAmortization: NegativeAmortization;
    // optionalProducts: OptionalProduct[];
    // optionalProductSummary: OptionalProductSummary;
    originationFunds: OriginationFund[];
    originationSystems: OriginationSystem[];
    payment: Payment;
    // prepaymentPenalty: PrepaymentPenalty;
    purchaseCredits: PurchaseCredit[];
    // purchaseCreditSummary: PurchaseCreditSummary;
    qualification: Qualification;
    qualifiedMortgage: QualifiedMortgage;
    refinance: Refinance;
    // rehabilitation: Rehabilitation;
    // reverseMortgage: ReverseMortgage;
    servicing: Servicing;
    // NOTE: Named TERMS_OF_MORTGAGE in ULDD
    termsOfLoan: TermsOfLoan;
    // treasuryNPV: TreasuryNPV;
    underwriting: Underwriting;
    // workouts: Workout[];
    // workoutSummary: WorkoutSummary;
  }>;
