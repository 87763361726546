export const CloseSquareFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8599 4.70453L4.70401 11.8604C2.96452 13.5999 2.97867 16.4142 4.70401 18.1396L11.8458 25.2813C13.5853 27.0208 16.3996 27.0067 18.1249 25.2813L25.2667 18.1396C27.0062 16.4001 26.992 13.5858 25.2667 11.8604L18.1249 4.71867C16.4137 2.97919 13.5853 2.97918 11.8599 4.70453ZM17.0077 15.5657L18.9027 17.4607C19.3128 17.8709 19.327 18.5638 18.9027 18.9598C18.7189 19.1436 18.4643 19.2285 18.2097 19.2285C17.9552 19.2285 17.6865 19.1295 17.4885 18.9315L15.5652 17.0082C15.254 16.6971 14.7449 16.6971 14.4338 17.0082L12.5105 18.9315C12.3125 19.1295 12.0438 19.2285 11.7892 19.2285C11.5347 19.2285 11.2942 19.1295 11.0963 18.9598C10.672 18.5638 10.6861 17.8709 11.0963 17.4607C11.5771 16.9799 12.4256 16.1314 12.9913 15.5657C13.3024 15.2546 13.3024 14.7454 12.9913 14.4343L11.068 12.511C10.6437 12.0867 10.672 11.3796 11.1387 10.9978C11.5488 10.6725 12.1569 10.7149 12.5388 11.0968L14.4338 12.9918C14.7449 13.3029 15.254 13.3029 15.5652 12.9918L17.4885 11.0685C17.9128 10.6442 18.6199 10.6725 19.0017 11.1392C19.327 11.5493 19.2845 12.1574 18.9027 12.5393L17.0077 14.4343C16.6965 14.7454 16.6965 15.2546 17.0077 15.5657Z"
        fill="currentColor"
      />
    </svg>
  );
};
