import { PoolsSummary } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { deletePool } from '../../pools/poolsUtils';
import { QueryKeys } from '.';

export const useLoanPoolDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((poolId: string) => deletePool(poolId), {
    onSuccess: (poolId) => {
      queryClient.removeQueries([QueryKeys.POOL, poolId]);

      queryClient.setQueryData<PoolsSummary | undefined>([QueryKeys.POOLS_SUMMARY], (data) => {
        if (data && poolId) {
          return {
            ...data,
            pools: data?.pools.filter((pool) => String(pool.id) !== poolId),
          };
        }

        return data;
      });
    },
  });
};
