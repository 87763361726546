export const MLSIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M15.5,7.4C15.5,7.4,15.5,7.4,15.5,7.4 M20,20H0V7.2L10,0l10,7.2V20z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
