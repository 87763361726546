import { MISMOAmount, MISMOEntity, MISMOString } from './types';

enum ArchitecturalDesignCategoryType {
  Bungalow = 'Bungalow',
  CapeCod = 'CapeCod',
  Colonial = 'Colonial',
  Contemporary = 'Contemporary',
  Cottage = 'Cottage',
  Craftsman = 'Craftsman',
  English = 'English',
  French = 'French',
  Georgian = 'Georgian',
  LowCountry = 'LowCountry',
  Mediterranean = 'Mediterranean',
  Modern = 'Modern',
  Other = 'Other',
  Plantation = 'Plantation',
  Ranch = 'Ranch',
  Shotgun = 'Shotgun',
  Spanish = 'Spanish',
  Traditional = 'Traditional',
  Tudor = 'Tudor',
  Victorian = 'Victorian',
}

enum ArchitecturalDesignConfigurationType {
  Duplex = 'Duplex',
  Fourplex = 'Fourplex',
  Other = 'Other',
  RowHouse = 'RowHouse',
  Single = 'Single',
  Triplex = 'Triplex',
}

export type ArchitecturalDesign = MISMOEntity &
  Partial<{
    architecturalDesignCategoryType: ArchitecturalDesignCategoryType;
    architecturalDesignCategoryTypeOtherDescription: MISMOString;
    architecturalDesignConfigurationType: ArchitecturalDesignConfigurationType;
    architecturalDesignConfigurationTypeOtherDescription: MISMOString;
    architecturalDesignDescription: MISMOString;
    componentAdjustmentAmount: MISMOAmount;
  }>;
