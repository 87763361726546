import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum LoanAmortizationPeriodType {
  Biweekly = 'Biweekly',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Semimonthly = 'Semimonthly',
  Week = 'Week',
  Year = 'Year',
}

enum PaymentOptionType {
  Amortization = 'Amortization',
  InterestOnly = 'InterestOnly',
  MinimumPayment = 'MinimumPayment',
  Other = 'Other',
}

export type PaymentOption = MISMOEntity &
  Partial<{
    loanAmortizationPeriodCount: MISMOCount;
    loanAmortizationPeriodType: LoanAmortizationPeriodType;
    paymentOptionPrincipalAndInterestPaymentAmount: MISMOAmount;
    paymentOptionType: PaymentOptionType;
    paymentOptionTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;
