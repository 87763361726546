import { PortfolioAnalyticOneMetricDataItem } from '@plus-platform/shared';
import { mix, rgba } from 'polished';
import { useTheme } from 'styled-components';
import { VictoryGroup } from 'victory-group';
import { VictoryScatter } from 'victory-scatter';

const LARGEST_BUBBLE_SIZE = 48;
const SMALLEST_BUBBLE_SIZE = 2;

type BubbleChartProps = {
  data: PortfolioAnalyticOneMetricDataItem[];
};

export const BubbleChart = (props: BubbleChartProps) => {
  const { data } = props;
  const { colors } = useTheme();

  const largestLoanCount = Math.max(...data.map((dataItem) => dataItem.loansCount));
  const largestGroupValue = Math.max(...data.map((dataItem) => dataItem.groupedByValue));

  const strokeColor = rgba(colors.primary01, 0.15);
  const smallestGroupValueColor = colors.statusTurquoise;
  const largestGroupValueColor = colors.white;

  const chartData = data.map((dataItem, index) => {
    const loanCountRatio = dataItem.loansCount / largestLoanCount;
    const groupValueRatio = 1 - dataItem.groupedByValue / largestGroupValue;

    return {
      y: index,
      // NOTE: This is a very simple way to distribute bubbles horizontally so that
      // they don't sit right on top of each other and spread out somewhat naturally
      // We are simply using vertical wave to define the path for the bubbles to follow.
      // This approach works OK for the current user portfolios that are made out of
      // test data, but potentially may need to be tweaked when real data is actually available
      x: Math.sin(index),

      size: Math.max(LARGEST_BUBBLE_SIZE * loanCountRatio, SMALLEST_BUBBLE_SIZE),
      fill: mix(groupValueRatio, smallestGroupValueColor, largestGroupValueColor),
    };
  });

  return (
    <VictoryGroup
      padding={{
        top: 50,
        bottom: 50,
        left: 120,
        right: 120,
      }}
    >
      <VictoryScatter
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
            stroke: strokeColor,
            strokeWidth: 1,
          },
        }}
        data={chartData}
        sortKey="size"
        sortOrder="descending"
      />
    </VictoryGroup>
  );
};
