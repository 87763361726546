import { Switch, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  AlertIcon,
  AnalyticsLineIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  FlagIcon,
  HelpIcon,
  IngestionLineIcon,
  PoolLineIcon,
  PortfolioLineIcon,
  TradeLineIcon,
  WarningIcon,
} from '../components/icons';
import { PageSection } from '../components/Page';
import { PrivateRoute } from '../global/PrivateRoute';
import { MenuItemButton, SpecificMenuItemButton } from './AdminMenuItemButton';
import {
  ActionColour,
  GeneralMenu,
  Header,
  MenuItem,
  SpecificMenu,
  SpecificMenuItem,
  Status,
  Subtitle,
  Title,
  Wrapper,
} from './FlagsAlertsWarningsPage.styles';
import { SettingsAnalyticsPage } from './SettingsAnalyticsPage';
import { AdminRouteType } from './types';

const FlagsAlertsWarningsHomePage = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Wrapper>
      <Title>Flags, Alerts & Warnings</Title>

      <PageSection>
        <Header>
          <Subtitle>General</Subtitle>
          <HelpIcon color={theme.colors.statusGreen} />
        </Header>
        <GeneralMenu>
          <MenuItem>
            <MenuItemButton
              title="Flag colour"
              actionComponent={(isOpened) => (
                <ActionColour>
                  <Status $color={theme.colors.statusBlue}>Blue</Status>
                  {isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </ActionColour>
              )}
              icon={<FlagIcon />}
            />
          </MenuItem>
          <MenuItem>
            <MenuItemButton
              title="Alert colour"
              actionComponent={(isOpened) => (
                <ActionColour>
                  <Status $color={theme.colors.statusOrange}>Amber</Status>
                  {isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </ActionColour>
              )}
              icon={<AlertIcon />}
            />
          </MenuItem>
          <MenuItem>
            <MenuItemButton
              title="Warning colour"
              actionComponent={(isOpened) => (
                <ActionColour>
                  <Status $color={theme.colors.statusRed}>Red</Status>
                  {isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </ActionColour>
              )}
              icon={<WarningIcon />}
            />
          </MenuItem>
        </GeneralMenu>
      </PageSection>

      <PageSection>
        <Header>
          <Subtitle>Specific</Subtitle>
          <HelpIcon color={theme.colors.statusGreen} />
        </Header>
        <SpecificMenu>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Portfolio"
              actionComponent={() => <ChevronRightIcon />}
              icon={<PortfolioLineIcon />}
              style={{
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Pools"
              actionComponent={() => <ChevronRightIcon />}
              icon={<PoolLineIcon />}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Analytics"
              actionComponent={() => <ChevronRightIcon />}
              icon={<AnalyticsLineIcon />}
              onClick={() => history.push(AdminRouteType.FlagsAlertsWarningAnalytics)}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Trading"
              actionComponent={() => <ChevronRightIcon />}
              icon={<TradeLineIcon />}
            />
          </SpecificMenuItem>
          <SpecificMenuItem>
            <SpecificMenuItemButton
              title="Ingestion"
              actionComponent={() => <ChevronRightIcon />}
              icon={<IngestionLineIcon />}
              style={{
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
              }}
            />
          </SpecificMenuItem>
        </SpecificMenu>
      </PageSection>
    </Wrapper>
  );
};

export const FlagsAlertsWarningsPage = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        component={() => <FlagsAlertsWarningsHomePage />}
        path={AdminRouteType.FlagsAlertsWarning}
      />
      <PrivateRoute
        component={() => <SettingsAnalyticsPage />}
        path={AdminRouteType.FlagsAlertsWarningAnalytics}
      />
    </Switch>
  );
};
