import {
  PortfolioBreakdown,
  PortfolioBreakdownRate,
  PortfolioLoanRateType,
} from '@plus-platform/shared';

import { formatLoanRateType, orderedLoanRateTypes } from '../../utils/loanUtils';
import { GraphData, RatesData } from './types';

export const DEFAULT_SLIDER_BOX_LENGTH = 72;
export const MIN_SLIDER_BOX_LENGTH = 35;

export type ChartData = {
  width: number;
  label: string;
  offset: number;
  count: number;
};

export type FormattedRate = {
  name: string;
  currentRate: number;
  previousRate: number;
  change: number;
  rateType: PortfolioLoanRateType;
};

export const getPercentage = (active: number, total: number) => (active / total) * 100;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatRates = (rates: PortfolioBreakdownRate[]): RatesData[] =>
  rates.map((rate) => {
    const date = new Date(rate.effectiveDate);
    const year = date.getFullYear();
    const month = date.getMonth();

    return {
      month,
      date: date.getDay(),
      year,
      label: `${months[month].slice(0, 3)} ${year}`,
      value: rate.rate,
    };
  });

export const createGraphData = (rates: RatesData[]) => {
  return rates.reduce<GraphData[]>((acc, data) => {
    const label = `${months[data.month].slice(0, 3)} ${data.year}`;

    const newPoint = {
      rate: Number(data.value),
      label,
      xValue: 1,
    };

    if (acc[acc.length - 4]?.label === label) {
      return acc;
    }

    const previousValue = acc[acc.length - 1];

    if (!previousValue) {
      return [...acc, newPoint];
    } else if (previousValue.label === label) {
      newPoint.xValue = Number((previousValue.xValue + 0.2).toFixed(1));
    } else {
      newPoint.xValue = Math.floor(previousValue.xValue + 1);
    }

    return [...acc, newPoint];
  }, []);
};

export const mapBreakdownForLoansCountByState = (portfolioBreakdown?: PortfolioBreakdown) => {
  return Object.entries(portfolioBreakdown?.loansCountByState || {}).reduce<{
    [key: string]: { value: number; formattedValue: string };
  }>((acc, [state, count]) => {
    return {
      ...acc,
      [state]: {
        value: count,
        formattedValue: String(count),
      },
    };
  }, {});
};

export const mapBreakdownForLoanCountChart = (portfolioBreakdown?: PortfolioBreakdown) => {
  return portfolioBreakdown?.loansCountsByMarketRates
    ? orderedLoanRateTypes.reduce<{
        totalWidth: number;
        data: ChartData[];
      }>(
        (acc, rateType) => {
          const loanCount =
            portfolioBreakdown.loanRatesCountsByMarketRates[rateType]?.loanCount ?? 0;
          const width = getPercentage(loanCount, portfolioBreakdown?.totalLoansCount || 0);

          return {
            totalWidth: acc.totalWidth + width,
            data: [
              ...acc.data,
              {
                label: formatLoanRateType(rateType as PortfolioLoanRateType),
                offset: acc.totalWidth,
                width,
                count: loanCount,
              },
            ],
          };
        },
        { totalWidth: 0, data: [] }
      )
    : { totalWidth: 0, data: [] };
};

export const mapBreakdownForLoanRateChart = (portfolioBreakdown?: PortfolioBreakdown) => {
  return portfolioBreakdown?.loanRatesCountsByMarketRates
    ? orderedLoanRateTypes.map((rateType) => {
        const data = portfolioBreakdown.loanRatesCountsByMarketRates[rateType];

        return {
          label: formatLoanRateType(rateType as PortfolioLoanRateType),
          ...data,
        };
      })
    : [];
};

export const formatMarketRates = (marketRates?: PortfolioBreakdown['marketRates']) => {
  if (!marketRates) {
    return [];
  }

  return Object.entries(marketRates).reduce<FormattedRate[]>((acc, [rateType, rateData]) => {
    const currentRateData = rateData[rateData.length - 1];
    const previousRateData = rateData[rateData.length - 2];

    if (!currentRateData || !previousRateData) {
      return acc;
    }

    const currentRate = currentRateData.noteRate;
    const previousRate = previousRateData.noteRate;

    return [
      ...acc,
      {
        name: formatLoanRateType(rateType as PortfolioLoanRateType),
        currentRate,
        previousRate,
        rateType: rateType as PortfolioLoanRateType,
        change: Number((currentRate - previousRate).toFixed(2)),
      },
    ];
  }, []);
};
