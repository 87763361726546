import {
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../../utils/formatUtils';
import {
  Analytic,
  AnalyticContainer,
  AnalyticCount,
  AnalyticHeader,
  AnalyticTitle,
} from './Analytic';
import { BreakdownTileContent } from './BreakdownTile';

type SummaryMetricsProps = {
  loanCount: number;
  upbAmount: number;
  remittanceAmount: number;
  weightedAverageCoupon: number;
  weightedAverageLTV: number;
  judicialCount: number;
  nonJudicialCount: number;
};

export const SummaryMetrics = (props: SummaryMetricsProps) => {
  const {
    judicialCount,
    loanCount,
    nonJudicialCount,
    remittanceAmount,
    upbAmount,
    weightedAverageCoupon,
    weightedAverageLTV,
  } = props;

  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Loan count</AnalyticTitle>
          <AnalyticCount>{formatNumber(loanCount)}</AnalyticCount>
        </AnalyticHeader>

        <BreakdownTileContent label="UPB" value={formatMonetary(upbAmount)} />
        <BreakdownTileContent label="Remittance" value={formatMonetary(remittanceAmount)} />
        <BreakdownTileContent
          label="Coupon (WAC)"
          value={formatPercentage(weightedAverageCoupon)}
        />
        <BreakdownTileContent label="Wtd Avg LTV" value={formatPercentage(weightedAverageLTV)} />
        <BreakdownTileContent label="Judicial" value={formatDefaultValue(judicialCount)} />
        <BreakdownTileContent label="Non-Judicial" value={formatDefaultValue(nonJudicialCount)} />
      </AnalyticContainer>
    </Analytic>
  );
};
