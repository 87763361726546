import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components/macro';

import { PlusIcon } from '../../components/icons/PlusIcon';
import {
  createUserInvitationMutation,
  CreateUserInvitationResponse,
} from '../../hooks/queries/useCreateUserInvitationMutation';
import {
  Content,
  StyledDialog,
  StyledDialogOverlay,
  StyledPanel,
  StyledTitle,
} from '../AdminModal/AdminModal';
import { InviteFormValues, InviteUserForm } from './InviteUserForm';
import { InviteUserSuccess } from './InviteUserSuccess';

const SubmitError = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.redMain};
    font-size: 14px;
    line-height: 18px;
    padding: 0 0 16px;
  `}
`;

type InviteUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const errorMessages: Record<string, string> = {
  'Email already exists':
    'Sorry, this email is already associated with another user account. Please try again with a different email address, or if you believe this is a mistake, please contact our support team for assistance.',
  'Email already invited':
    'Sorry, this email has already been invited to the system. Please ask the user to check their email inbox (and spam folder) for the invitation email, or try again with a different email address. If you believe this is a mistake, please contact our support team for assistance.',
  'Invalid role':
    'Sorry, an unexpected error has occurred. Please try again later or contact our support team for assistance.',
};

export const InviteUserModal = ({ isOpen, onClose }: InviteUserModalProps) => {
  const theme = useTheme();
  const [createdUserInvitation, setCreatedUserInvitation] = useState<
    CreateUserInvitationResponse | undefined
  >(undefined);
  const {
    error,
    isError,
    mutateAsync: createUser,
    reset: resetCreateUser,
  } = createUserInvitationMutation();
  const formMethods = useForm<InviteFormValues>();
  const { formState, reset } = formMethods;
  const { isSubmitSuccessful } = formState;
  const watchedEmail = formMethods.watch('email');

  useEffect(() => {
    if (isError) {
      reset(undefined, { keepValues: true });
    }
  }, [isError]);

  // Reset the mutation state if the user changes the email address
  // and an error is being displayed
  useEffect(() => {
    if (isError) {
      resetCreateUser();
    }
  }, [watchedEmail]);

  const createInvite = async (values: InviteFormValues) => {
    const newUser = await createUser(values);
    setCreatedUserInvitation(newUser);
  };

  const resetModal = () => {
    formMethods.reset();
    setCreatedUserInvitation(undefined);
    resetCreateUser();
    onClose();
  };

  return (
    <StyledDialog open={isOpen} onClose={resetModal}>
      <StyledDialogOverlay>
        <StyledPanel>
          <StyledTitle $bgColor={isSubmitSuccessful ? theme.colors.greenMain : undefined}>
            <PlusIcon color={theme.colors.white} height={20} width={23} />
            Create new user
          </StyledTitle>
          <Content>
            {isSubmitSuccessful ? (
              <InviteUserSuccess onClose={resetModal} invite={createdUserInvitation} />
            ) : (
              <React.Fragment>
                {isError && (
                  <SubmitError>
                    {error instanceof Error && errorMessages[error.message]
                      ? errorMessages[error.message]
                      : 'Sorry, an unexpected error has occurred. Please try again later or contact our support team for assistance.'}
                  </SubmitError>
                )}

                <InviteUserForm
                  onSubmit={createInvite}
                  onClose={resetModal}
                  submitError={isError}
                  {...formMethods}
                />
              </React.Fragment>
            )}
          </Content>
        </StyledPanel>
      </StyledDialogOverlay>
    </StyledDialog>
  );
};
