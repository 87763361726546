import { AttributeType, Loan } from '@plus-platform/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import {
  AddNoteIcon,
  AddSingleIcon,
  AddToPoolIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  FullRecordIcon,
  MapFillIcon,
  MapLineIcon,
  ShareIcon,
  TableFillIcon,
  TableLineIcon,
  ViewIcon,
} from '../components/icons';
import { Menu, MenuItem, MenuItemText, MenuSection, SubMenu } from '../components/Menu';
import { CreatePermissions } from '../components/Permissions';
import { LinkToggle } from '../components/Toggles/LinkToggle';
import { ToggleGroup } from '../components/Toggles/ToggleGroup';
import { AddDocumentsPopup } from './AddDocumentsPopup';
import { AddNotePopup } from './AddNotePopup';
import { AddToPoolPopup } from './AddToPoolPopup';
import { LoanBreadcrumbSummary } from './LoanBreadcrumbSummary';
import * as Styles from './LoanDetailBreadcrumb.styles';

enum Views {
  GRID = 'grid',
  MAP = 'map',
}

type LoanDetailBreadcrumbPropTypes = {
  loan?: Loan;
};

export const LoanDetailBreadcrumb = ({ loan }: LoanDetailBreadcrumbPropTypes) => {
  const location = useLocation();
  const [isAddToPoolPopupDisplayed, setIsAddToPoolPopupDisplayed] = React.useState(false);
  const [isAddDocumentsPopupDisplayed, setIsAddDocumentsPopupDisplayed] = React.useState(false);
  const [isAddNotePopupDisplayed, setIsAddNotePopupDisplayed] = React.useState(false);

  const loanNumber = loan?.loanDetails?.loanNumber;

  // TODO: Move to util that consumes location and Views
  const pathBase = `/loan/${loanNumber}/detail`;
  const gridPath = `${pathBase}/${Views.GRID}`;
  const mapPath = `${pathBase}/${Views.MAP}`;

  const isGridView = location.pathname === gridPath;
  const isMapView = location.pathname === mapPath;

  return (
    <React.Fragment>
      <Card>
        <Styles.SummaryControlsWrapper>
          <LoanBreadcrumbSummary loan={loan} />
          <Styles.ActionsWrapper>
            <ToggleGroup size="small">
              <LinkToggle to={Views.GRID}>
                {isGridView ? <TableFillIcon /> : <TableLineIcon />}
              </LinkToggle>
              <LinkToggle to={Views.MAP}>
                {isMapView ? <MapFillIcon /> : <MapLineIcon />}
              </LinkToggle>
            </ToggleGroup>
            <Divider $flexItem $orientation="vertical" />
            <Menu title="Loan tools">
              <MenuSection>
                <MenuItem onClick={() => setIsAddToPoolPopupDisplayed(true)}>
                  <AddToPoolIcon />
                  <MenuItemText>Add to pool</MenuItemText>
                </MenuItem>
                <MenuItem disabled>
                  <ShareIcon />
                  <MenuItemText>Share</MenuItemText>
                </MenuItem>
                <MenuItem disabled>
                  <FullRecordIcon />
                  <MenuItemText>Full record</MenuItemText>
                </MenuItem>
              </MenuSection>
              <MenuSection>
                <MenuItem onClick={() => setIsAddDocumentsPopupDisplayed(true)}>
                  <AddSingleIcon />
                  <MenuItemText>Add documents</MenuItemText>
                </MenuItem>
                <CreatePermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_NOTES]}>
                  <MenuItem onClick={() => setIsAddNotePopupDisplayed(true)}>
                    <AddNoteIcon />
                    <MenuItemText>Add note</MenuItemText>
                  </MenuItem>
                </CreatePermissions>
              </MenuSection>
              <MenuSection>
                <MenuItem isSubmenu>
                  <ViewIcon />
                  <MenuItemText>View</MenuItemText>
                  <ChevronRightIcon />
                  <SubMenu $direction="left">
                    <MenuSection>
                      <MenuItem disabled={!isMapView} to="grid">
                        <TableLineIcon />
                        <MenuItemText>As grid</MenuItemText>
                      </MenuItem>
                      <MenuItem disabled={isMapView} to="map">
                        <MapLineIcon />
                        <MenuItemText>As map</MenuItemText>
                      </MenuItem>
                    </MenuSection>
                  </SubMenu>
                </MenuItem>
                <MenuItem disabled>
                  <EditIcon />
                  <MenuItemText>Edit</MenuItemText>
                </MenuItem>
                <MenuItem disabled>
                  <DeleteIcon />
                  <MenuItemText>Delete</MenuItemText>
                </MenuItem>
              </MenuSection>
            </Menu>
          </Styles.ActionsWrapper>
        </Styles.SummaryControlsWrapper>
      </Card>
      {isAddToPoolPopupDisplayed && loan && loanNumber && (
        <AddToPoolPopup
          loanId={loan.id}
          loanNumber={loanNumber}
          onClose={() => setIsAddToPoolPopupDisplayed(false)}
        />
      )}
      {isAddDocumentsPopupDisplayed && loanNumber && (
        <AddDocumentsPopup
          loanNumber={loanNumber}
          onClose={() => setIsAddDocumentsPopupDisplayed(false)}
        />
      )}
      {isAddNotePopupDisplayed && loanNumber && (
        <AddNotePopup loanNumber={loanNumber} onClose={() => setIsAddNotePopupDisplayed(false)} />
      )}
    </React.Fragment>
  );
};
