import { NavLinkProps } from 'react-router-dom';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { NavLink } from './Link';

export type HeadingLinkStyleProps = {
  $size?: 'large' | 'medium' | 'small';
  $variant?: 'default' | 'light';
};

const largeSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium01}
  padding: 0.25em;
`;

const mediumSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium03}
  padding: 0 0.25em;
`;

const smallSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium04}
  padding: 0 0.25em;
`;

export const HeadingLink = styled(NavLink)<HeadingLinkStyleProps & NavLinkProps>(
  ({ $size = 'large', $variant = 'default', theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-self: start;
    text-decoration: none;
    border-radius: 4px;
    color: ${theme.colors.anchorColor};
    background-color: ${theme.colors.anchorBGColor};
    white-space: nowrap;

    ${$size === 'large' && largeSizeStyles}
    ${$size === 'medium' && mediumSizeStyles}
    ${$size === 'small' && smallSizeStyles}

    &:visited {
      color: ${theme.colors.anchorColor};
      background-color: ${theme.colors.anchorBGColor};
    }

    &:hover {
      color: ${theme.colors.anchorColorHover};
      background-color: ${$variant === 'light'
        ? theme.colors.anchorLightBGColorHover
        : theme.colors.anchorBGColorHover};

      > span {
        color: inherit;
        background-color: inherit;
      }
    }

    &:focus-visible {
      color: ${theme.colors.anchorColorHover};
      background-color: ${$variant === 'light'
        ? theme.colors.anchorLightBGColorHover
        : theme.colors.anchorBGColorHover};
      outline: none;
    }

    &:active {
      color: ${theme.colors.anchorColorActive};
      background-color: ${$variant === 'light'
        ? theme.colors.anchorLightBGColorActive
        : theme.colors.anchorBGColorActive};
    }
  `
);
