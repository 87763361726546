import {
  MISMOCount,
  MISMOEntity,
  MISMONumeric,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum SolarPanelArrayLocationType {
  Ground = 'Ground',
  Other = 'Other',
  Roof = 'Roof',
}

enum SolarPanelArrayOwnershipType {
  Leased = 'Leased',
  Owned = 'Owned',
}

export type SolarPanelArray = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    solarPanelArrayAgeYearsCount: MISMOCount;
    solarPanelArrayAzimuthDescription: MISMOString;
    solarPanelArrayEstimatedKilowattHoursOutputPerYearNumber: MISMONumeric;
    solarPanelArrayEstimatedOutputSourceDescription: MISMOString;
    solarPanelArrayLocationType: SolarPanelArrayLocationType;
    solarPanelArrayLocationTypeOtherDescription: MISMOString;
    solarPanelArrayManufacturerName: MISMOString;
    solarPanelArrayMaximumKilowattOutputNumber: MISMONumeric;
    solarPanelArrayOwnershipType: SolarPanelArrayOwnershipType;
    solarPanelArrayTiltSlopeDescription: MISMOString;
    solarPanelArrayWarrantyTermsDescription: MISMOString;
    // inverters: Inverter[];
  }>;
