import { Stipulation, StipulationType } from '@plus-platform/shared';

import { DocumentPreview } from '../../components/DocumentPreview';
import { useStipulationResources } from '../useStipulationResources';
import * as Styles from './StipulationPreview.styles';

type StipulationPreviewProps = {
  stipulationType: StipulationType;
  stipulationId: Stipulation['id'];
  stipulationFileOriginalName: string;
  onStipulationToggle?: () => void;
  documentType?: string;
};

export const StipulationPreview = ({
  documentType = 'PDF document',
  onStipulationToggle,
  stipulationFileOriginalName,
  stipulationId,
  stipulationType,
}: StipulationPreviewProps) => {
  const { openDocument, preview } = useStipulationResources(stipulationType, stipulationId);

  return (
    <Styles.Details open onToggle={() => onStipulationToggle?.()}>
      <Styles.Summary>{documentType}</Styles.Summary>
      <DocumentPreview
        onClick={openDocument}
        preview={preview}
        style={{ minWidth: 504, minHeight: 176 }}
        title={stipulationFileOriginalName}
        type={documentType}
      />
    </Styles.Details>
  );
};
