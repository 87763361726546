import { NavLink } from '../../../components/Link';
import { Tab, Tabs } from '../LoanTypesChartTabs';
import { Header, HeaderTitle } from './LoanTypesChartHeader.styles';

export const LoanTypesChartHeader = () => {
  return (
    <Header>
      <HeaderTitle>
        <strong>Loans</strong> by <strong>type</strong> by <strong>note rate & default</strong>
      </HeaderTitle>

      <Tabs>
        <Tab as={NavLink} to="/home/breakdown/types/rates">
          Note rate
        </Tab>
        <Tab as={NavLink} to="/home/breakdown/delinquent/types/rates">
          Default
        </Tab>
      </Tabs>
    </Header>
  );
};
