import {
  AggregatedQuoteStipulation,
  CounterpartyType,
  Stipulation,
  StipulationType,
  TradeConfirmationSummary,
} from '@plus-platform/shared';

import { Button } from '../../../components/Button';
import { Stack } from '../../../components/Stack';
import { Tag, TagLabel, Tags } from '../../../components/Tag';
import { PoolLoanSummary } from '../../PoolLoanSummary';
import { StipulationPreviewTag, StipulationPreviewTags } from '../../StipulationTag';
import { formatNameWithInitial } from '../../tradeRequestsUtils';
import * as Styles from './TradeConfirmation.styles';

type FilteredAggregatedQuoteStipulation = Omit<AggregatedQuoteStipulation, 'quoteStipulation'> & {
  quoteStipulation: Stipulation;
};

type TradeConfirmationProps = {
  summary: TradeConfirmationSummary;
  isEnabled: boolean;
  onAccept: () => void;
  onCancel: () => void;
};

export const TradeConfirmation = (props: TradeConfirmationProps) => {
  const { isEnabled, onAccept, onCancel, summary } = props;
  const { buyerStipulations, fromCounterparty, pool, sellerStipulations, toCounterparty } = summary;

  const buyerCounterparty =
    fromCounterparty.type === CounterpartyType.BUYER ? fromCounterparty : toCounterparty;

  const filteredSellerStipulations = sellerStipulations.filter((stipulation) =>
    Boolean(stipulation?.quoteStipulation)
  ) as FilteredAggregatedQuoteStipulation[];
  const filteredBuyerStipulations = buyerStipulations.filter((stipulation) =>
    Boolean(stipulation?.quoteStipulation)
  ) as FilteredAggregatedQuoteStipulation[];

  return (
    <Styles.Messenger>
      <Styles.TradeConfirmationTitle>Ready to confirm trade?</Styles.TradeConfirmationTitle>
      <Styles.Summary>
        <Styles.Content>
          <Styles.Title>
            You have indicated you're ready to SELL your pool "{pool.name}"
          </Styles.Title>
          <Styles.Description>
            The details of the trade, including the buyer and all relevant stipulations, are shown
            below.
          </Styles.Description>
          <Styles.Description>Final check before execution.</Styles.Description>
          <Styles.PoolWrapper>
            <PoolLoanSummary summary={pool.summary} variant="loan" />
          </Styles.PoolWrapper>

          <Styles.Title>Buyer (1)</Styles.Title>
          <Tags>
            <Tag variant="violet">
              <TagLabel>
                {formatNameWithInitial(
                  buyerCounterparty.user.lastName,
                  buyerCounterparty.user.firstName
                )}
              </TagLabel>
            </Tag>
          </Tags>
          <Styles.Stipulations>
            <div>
              <Styles.Title>Seller stipulations</Styles.Title>
              {filteredSellerStipulations.length > 0 ? (
                <StipulationPreviewTags>
                  {filteredSellerStipulations.map((stipulation) => (
                    <StipulationPreviewTag
                      key={stipulation.quoteStipulation.id}
                      stipulationFileOriginalName={stipulation.quoteStipulation.fileOriginalName}
                      stipulationId={stipulation.quoteStipulation.id}
                      stipulationType={StipulationType.STIPULATION_QUOTE}
                    />
                  ))}
                </StipulationPreviewTags>
              ) : (
                <Styles.NoStipulations>No stipulations</Styles.NoStipulations>
              )}
            </div>
            <div>
              <Styles.Title>Buyer stipulations</Styles.Title>
              {filteredBuyerStipulations.length > 0 ? (
                <StipulationPreviewTags>
                  {filteredBuyerStipulations.map((stipulation) => (
                    <StipulationPreviewTag
                      key={stipulation.quoteStipulation.id}
                      stipulationFileOriginalName={stipulation.quoteStipulation.fileOriginalName}
                      stipulationId={stipulation.quoteStipulation.id}
                      stipulationType={StipulationType.STIPULATION_QUOTE}
                    />
                  ))}
                </StipulationPreviewTags>
              ) : (
                <Styles.NoStipulations>No stipulations</Styles.NoStipulations>
              )}
            </div>
          </Styles.Stipulations>
        </Styles.Content>
        <Stack $justifyContent="end">
          <Button
            $color="danger"
            $size="large"
            $variant="outlined"
            disabled={!isEnabled}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button $size="large" disabled={!isEnabled} onClick={onAccept}>
            Execute
          </Button>
        </Stack>
      </Styles.Summary>
    </Styles.Messenger>
  );
};
