import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export type DeletePortfolioAnalyticsMutation = {
  id: string;
};

export const useDeletePortfolioAnalyticsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeletePortfolioAnalyticsMutation) => api.deletePortfolioAnalytics(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PORTFOLIO_ANALYTICS]);
      },
    }
  );
};
