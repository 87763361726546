import { Analytic, AnalyticContainer, AnalyticHeader, AnalyticTitle } from './Analytic';
import { BreakdownTileContent } from './BreakdownTile';

// TODO: Integrate with real data when available
export const CreditMetrics = () => {
  return (
    <Analytic>
      <AnalyticContainer>
        <AnalyticHeader>
          <AnalyticTitle>Credit metrics</AnalyticTitle>
        </AnalyticHeader>

        <BreakdownTileContent label="Average CDR" value="1.44" />
        <BreakdownTileContent label="Cumulative default" value="4.611" />
        <BreakdownTileContent label="Cumulative loss" value="7.498" />
        <BreakdownTileContent label="Market value" value="$200,000,000" />
        <BreakdownTileContent label="Expected shortfall" value="$10,000,00" />
      </AnalyticContainer>
    </Analytic>
  );
};
