import { useTheme } from 'styled-components/macro';

import { Heading } from './Heading';
import { UploadIcon } from './icons';
import * as Styles from './UploadOverlay.styles';

type UploadOverlayProps = {
  isVisible: boolean;
};

export const UploadOverlay = ({ isVisible }: UploadOverlayProps) => {
  const theme = useTheme();
  return (
    <Styles.Wrapper $isVisible={isVisible}>
      <UploadIcon color={theme.colors.accent01} height={64} width={64} />
      <Heading $size="large">Upload to Plus</Heading>
    </Styles.Wrapper>
  );
};
