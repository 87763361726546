import { UserPermissionHierarchy } from '@plus-platform/shared';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useUserQueries } from '../hooks/queries';
import { useInterval } from '../hooks/useInterval';
import { UserProfile } from '../users/types';
import { isLogoutEvent, LOGOUT_EVENT } from '../utils/authUtils';
import { STORAGE_EVENT } from '../utils/storageUtils';
import {
  getAccessToken,
  isTokenExpiringSoonOrExpired,
  refreshAccessToken,
  removeAccessToken,
} from '../utils/tokenUtils';

const TOKEN_VALIDATION_INTERVAL_IN_SECONDS = 60;

type UserState = {
  isUserDataLoading: boolean;
  userPermissions?: UserPermissionHierarchy;
  userProfile?: UserProfile;
};

type UserContextProps = {
  children?: React.ReactNode;
};

const initialUserState: UserState = {
  isUserDataLoading: false,
  userProfile: undefined,
  userPermissions: undefined,
};

const UserContext = React.createContext(initialUserState);

export const useUserContext = () => React.useContext(UserContext);

export const UserProvider = ({ children }: UserContextProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [
    { data: userProfile, isFetching: isFetchingUserProfile, isLoading: isLoadingUserProfile },
    {
      data: userPermissions,
      isFetching: isFetchingUserPermissions,
      isLoading: isLoadingUserPermissions,
    },
  ] = useUserQueries();

  const isUserProfileLoading = isFetchingUserProfile || isLoadingUserProfile;
  const isUserPermissionsLoading = isFetchingUserPermissions || isLoadingUserPermissions;

  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(
    isUserProfileLoading || isUserPermissionsLoading || true
  );

  useEffect(() => {
    setIsUserDataLoading(isUserProfileLoading || isUserPermissionsLoading);
  }, [setIsUserDataLoading, isUserProfileLoading, isUserPermissionsLoading]);

  useInterval(
    async () => {
      if (userProfile && isTokenExpiringSoonOrExpired(getAccessToken())) {
        await refreshAccessToken();
      }
    },
    userProfile ? TOKEN_VALIDATION_INTERVAL_IN_SECONDS * 1000 : null,
    true
  );

  React.useEffect(() => {
    const handleLogout = (event: Event) => {
      if (isLogoutEvent(event)) {
        removeAccessToken();

        history.push('/login');
        queryClient.clear();
      }
    };

    // Logout event for current window
    window.addEventListener(LOGOUT_EVENT, handleLogout);
    // Storage event for other windows/tabs
    window.addEventListener(STORAGE_EVENT, handleLogout);

    return () => {
      window.removeEventListener(LOGOUT_EVENT, handleLogout);
      window.removeEventListener(STORAGE_EVENT, handleLogout);
    };
  }, [history, queryClient]);

  return (
    <UserContext.Provider
      value={{
        isUserDataLoading,
        userPermissions,
        userProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
