import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  row-gap: 12px;
`;

export const Section = styled.div`
  display: grid;
  width: 100%;
  row-gap: 8px;
`;
