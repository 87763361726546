import { random } from './number';

const LOAN_NUMBER_PREFIX_LENGTH = 3;
const LOAN_NUMBER_SUFFIX_LENGTH = 4;

export const generateLoanNumber = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const prefix = [...Array(LOAN_NUMBER_PREFIX_LENGTH)]
    .map(() => alphabet.charAt(random(0, alphabet.length)))
    .join('');
  const suffix = [...Array(LOAN_NUMBER_SUFFIX_LENGTH)].map(() => random(0, 9)).join('');

  return `${prefix}-${suffix}`;
};
