import { LoanAmortizationData } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type UseLoanAmortizationQueryProps = {
  loanNumber: string;
};

const getLoanAmortization = (loanNumber: string) => {
  const url = makeApiUrl(`loans/${loanNumber}/amortization`);
  return getRequestWithAuth<LoanAmortizationData>(url);
};

/**
 * Query hook for fetching loan amortization details
 **/
export const useGetLoanAmortizationQuery = ({ loanNumber }: UseLoanAmortizationQueryProps) =>
  useQuery([QueryKeys.LOAN_AMORTIZATION, loanNumber], () => getLoanAmortization(loanNumber));
