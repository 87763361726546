import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const ItemLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding: 8px;
`;

export const ItemWrapper = styled.li(
  ({ theme }) => css`
    border-radius: 4px;
    background-color: ${theme.colors.primary03};

    &:hover {
      background-color: ${theme.colors.primary04};
    }

    &:active {
      background-color: ${theme.colors.primary04};
    }
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const Title = styled.p(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}

    color: ${theme.colors.textPrimary01};
    margin: 0;
  `
);

export const TimeAgo = styled.span(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}

    color: ${theme.colors.textPrimary02};
    flex-shrink: 0;
  `
);
