import styled, { css } from 'styled-components';

export const CheckboxGroupWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 12px 0 24px;
`;

export const CheckboxItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const CheckboxImage = styled.img`
  height: 60px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CheckboxLabel = styled.label<{
  $isSelected: boolean;
}>(
  ({ $isSelected, theme }) => css`
    ${theme.typography.headings.headingMedium04}
    color: ${theme.colors.fillHeading};

    font-weight: ${$isSelected
      ? theme.typography.fontWeight.medium
      : theme.typography.fontWeight.regular};
  `
);
