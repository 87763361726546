import React from 'react';

import * as Styles from './ButtonIconToggle.styles';

type ButtonIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  value?: string;
  isActive?: boolean;
  onChange?: (event: React.MouseEvent<HTMLElement>, v: string[]) => void;
  size?: 'small' | 'medium' | 'large';
  variant?: 'light' | 'dark';
};

export const ButtonIconToggle = ({
  children,
  isActive = false,
  onClick,
  size,
  variant = 'light',
  ...rest
}: ButtonIconProps) => {
  return (
    <Styles.Button
      type="button"
      onClick={onClick}
      $isActive={isActive}
      $size={size}
      $variant={variant}
      {...rest}
    >
      {children}
    </Styles.Button>
  );
};

ButtonIconToggle.displayName = 'ButtonIconToggle';
