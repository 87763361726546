import { Stipulation, StipulationType } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getBlobRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const getStipulationPreview = (
  stipulationType: StipulationType,
  stipulationId: Stipulation['id']
) => {
  const url = makeApiUrl(`stipulations/${stipulationType}/${stipulationId}/preview`);

  return getBlobRequestWithAuth(url);
};

export const useStipulationPreviewQuery = (
  stipulationType: StipulationType,
  stipulationId?: Stipulation['id'],
  enabled?: boolean | undefined
) =>
  useQuery(
    [QueryKeys.TRADE_STIPULATION_PREVIEW, stipulationId],
    () => (stipulationId ? getStipulationPreview(stipulationType, stipulationId) : undefined),
    { enabled }
  );
