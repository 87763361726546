import { ellipsis, transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 230px;
  min-height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    ${theme.typography.headings.headingMedium03}

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    padding: 12px;
    color: ${theme.colors.white};
  `}
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px 12px 12px;
`;

export const FiltersSection = styled.div`
  flex: 1;
`;

export const Filters = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 30px;
`;

export const Filter = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  min-height: 18px;
  justify-content: space-between;
  column-gap: 4px;
`;

export const FilterLabel = styled.span<{ $isApplied?: boolean }>(
  ({ $isApplied, theme }) => css`
    ${theme.typography.bodyText.bodyMedium02}

    color: ${$isApplied ? 'inherit' : transparentize(0.7, theme.colors.white)};

    ${ellipsis()};
  `
);
