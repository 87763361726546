import React from 'react';

export const ExportLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3437 15.6531V7.5375L8.71875 10.1625L7.77812 9.22187L12 5L16.2219 9.22187L15.2812 10.1625L12.6562 7.5375V15.6531H11.3437ZM6.3125 19C5.9625 19 5.65625 18.8687 5.39375 18.6062C5.13125 18.3437 5 18.0375 5 17.6875V14.5594H6.3125V17.6875H17.6875V14.5594H19V17.6875C19 18.0375 18.8687 18.3437 18.6062 18.6062C18.3437 18.8687 18.0375 19 17.6875 19H6.3125Z"
        fill="currentColor"
      />
    </svg>
  );
};
