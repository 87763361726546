import { TradeRequestLog } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { TradeRequestTransactionLogsResponse } from '.';

type ExportTradeRequestTransactionMutation = {
  logIds: TradeRequestLog['id'][];
};

export const exportTradeRequestTransactionLogs = ({
  logIds,
}: ExportTradeRequestTransactionMutation) => {
  const url = makeApiUrl(`trade-requests/logs/export`);

  return postRequestWithAuth<TradeRequestTransactionLogsResponse>(url, {
    body: JSON.stringify({ logIds }),
  });
};

export const useExportTradeRequestTransactionLogsMutation = (
  onSuccess?: (data: TradeRequestTransactionLogsResponse | undefined) => void
) => {
  return useMutation(exportTradeRequestTransactionLogs, {
    onSuccess,
  });
};
