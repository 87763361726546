import { ScopeOfWork } from '../../scopeOfWork';
import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOString,
} from '../../types';

enum PropertyValuationFormType {
  AppraisalInstituteGreenAddendum = 'AppraisalInstituteGreenAddendum',
  AppraisalUpdateAndOrCompletionReport = 'AppraisalUpdateAndOrCompletionReport',
  ComplianceInspectionReport = 'ComplianceInspectionReport',
  ConditionalCommitmentDirectEndorsementStatementOfAppraisedValue = 'ConditionalCommitmentDirectEndorsementStatementOfAppraisedValue',
  DefinitionsStatementOfLimitingConditionsAndAppraisersCertification = 'DefinitionsStatementOfLimitingConditionsAndAppraisersCertification',
  DesktopUnderwriterPropertyInspectionReport = 'DesktopUnderwriterPropertyInspectionReport',
  EmployeeRelocationCouncil2001 = 'EmployeeRelocationCouncil2001',
  ExteriorOnlyInspectionIndividualCondominiumUnitAppraisalReport = 'ExteriorOnlyInspectionIndividualCondominiumUnitAppraisalReport',
  ExteriorOnlyInspectionIndividualCooperativeInterestAppraisalReport = 'ExteriorOnlyInspectionIndividualCooperativeInterestAppraisalReport',
  ExteriorOnlyInspectionResidentialAppraisalReport = 'ExteriorOnlyInspectionResidentialAppraisalReport',
  ForYourProtectionGetAHomeInspection = 'ForYourProtectionGetAHomeInspection',
  GeneralAddendum = 'GeneralAddendum',
  HUD_VAAddendumToURLA = 'HUD_VAAddendumToURLA',
  ImportantNoticeToHomebuyers = 'ImportantNoticeToHomebuyers',
  IndividualCondominiumUnitAppraisalReport = 'IndividualCondominiumUnitAppraisalReport',
  IndividualCooperativeInterestAppraisalReport = 'IndividualCooperativeInterestAppraisalReport',
  LoanProspectorConditionAndMarketability = 'LoanProspectorConditionAndMarketability',
  ManufacturedHomeAppraisalReport = 'ManufacturedHomeAppraisalReport',
  ManufacturedHomeAppraisalReportVA = 'ManufacturedHomeAppraisalReportVA',
  MarketConditionsAddendumToTheAppraisalReport = 'MarketConditionsAddendumToTheAppraisalReport',
  MortgageesAssuranceOfCompletion = 'MortgageesAssuranceOfCompletion',
  NewConstructionSubterraneanTermiteSoilTreatmentRecord = 'NewConstructionSubterraneanTermiteSoilTreatmentRecord',
  OneUnitResidentialAppraisalFieldReviewReport = 'OneUnitResidentialAppraisalFieldReviewReport',
  Other = 'Other',
  RequestForDeterminationOfReasonableValue = 'RequestForDeterminationOfReasonableValue',
  SmallResidentialIncomePropertyAppraisalReport = 'SmallResidentialIncomePropertyAppraisalReport',
  SubterraneanTermiteSoilTreatmentBuildersGuarantee = 'SubterraneanTermiteSoilTreatmentBuildersGuarantee',
  TwoToFourUnitResidentialAppraisalFieldReviewReport = 'TwoToFourUnitResidentialAppraisalFieldReviewReport',
  UniformResidentialAppraisalReport = 'UniformResidentialAppraisalReport',
  WorldwideEmployeeRelocationCouncilSummaryAppraisalReport = 'WorldwideEmployeeRelocationCouncilSummaryAppraisalReport',
}

enum ValuationRequestActionType {
  Cancellation = 'Cancellation',
  Change = 'Change',
  Hold = 'Hold',
  Original = 'Original',
  Other = 'Other',
  PriceQuote = 'PriceQuote',
  Reissue = 'Reissue',
  Resume = 'Resume',
  StatusQuery = 'StatusQuery',
  Update = 'Update',
  Upgrade = 'Upgrade',
}

enum VendorRelationshipType {
  Independent = 'Independent',
  Other = 'Other',
  Staff = 'Staff',
}

export type ValuationRequest = MISMOEntity &
  Partial<{
    appraiserFileIdentifier: MISMOIdentifier;
    orderingSystemName: MISMOString;
    priorInterestIndicator: MISMOIndicator;
    propertyValuationFormIdentifier: MISMOIdentifier;
    propertyValuationFormType: PropertyValuationFormType;
    propertyValuationFormTypeOtherDescription: MISMOString;
    valuationRequestActionType: ValuationRequestActionType;
    valuationRequestActionTypeOtherDescription: MISMOString;
    valuationRequestCommentText: MISMOString;
    vendorOrderIdentifier: MISMOIdentifier;
    vendorRelationshipType: VendorRelationshipType;
    vendorRelationshipTypeOtherDescription: MISMOString;
    // assignmentParameters: AssignmentParameter[];
    scopeOfWork: ScopeOfWork;
  }>;
