import { MISMOEntity, MISMOSequenceNumber, MISMOString } from './types';

enum InsulationAreaType {
  Ceiling = 'Ceiling',
  Floor = 'Floor',
  Other = 'Other',
  Roof = 'Roof',
  Walls = 'Walls',
}

enum InsulationMaterialType {
  Cellulose = 'Cellulose',
  FiberglassBatt = 'FiberglassBatt',
  FiberglassBlownIn = 'FiberglassBlownIn',
  Foam = 'Foam',
  MineralWool = 'MineralWool',
  Other = 'Other',
}

enum InsulationPresenceType {
  Absent = 'Absent',
  Exists = 'Exists',
  Other = 'Other',
  Unknown = 'Unknown',
}

type InsulationArea = MISMOEntity &
  Partial<{
    insulationAreaType: InsulationAreaType;
    insulationAreaTypeOtherDescription: MISMOString;
    insulationCommentDescription: MISMOString;
    insulationMaterialType: InsulationMaterialType;
    insulationMaterialTypeOtherDescription: MISMOString;
    insulationPresenceType: InsulationPresenceType;
    insulationPresenceTypeOtherDescription: MISMOString;
    insulationRatingDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
  }>;

enum InsulationHERSType {
  Best = 'Best',
  Lowest = 'Lowest',
  Moderate = 'Moderate',
}

enum ThermalRatedItemsType {
  Ceilings = 'Ceilings',
  Floors = 'Floors',
  Walls = 'Walls',
}

export type Insulation = MISMOEntity &
  Partial<{
    insulationCommentDescription: MISMOString;
    insulationHERSType: InsulationHERSType;
    thermalRatedItemsType: ThermalRatedItemsType;
    insulationAreas: InsulationArea[];
  }>;
