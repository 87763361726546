import styled, { css } from 'styled-components/macro';

import { DelinquencyVariant } from './DelinquencyPanes';

const poolVariantStyles = css`
  background-color: ${({ theme }) => theme.colors.primary03};
  border-radius: 2px;
  padding: 8px;
  row-gap: 8px;
  grid-template-rows: [title] 32px [progress] 4px [summary-start] auto [summary-end];
`;

export const Pane = styled.div<{ $variant?: DelinquencyVariant }>(
  ({ $variant = 'loan' }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: [title] 48px [progress] 4px [summary-start] auto [summary-end];
    row-gap: 12px;
    ${$variant === 'pool' && poolVariantStyles}
  `
);

export const Summary = styled.div`
  grid-row: summary-start / summary-end;
`;

export const NoProgressSummary = styled.div`
  grid-row: progress / summary-end;
`;
