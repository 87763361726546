import { OutboundTradeRequestWithSummary } from '@plus-platform/shared';

import { useTradeRequestsOutboundSummaryQuery } from '../hooks/queries';
import { TradeRequestsView } from './TradeRequestsView';
import { OutboundTradeRequestLarge } from './TradeRequestTickets';

export const TradeRequestsOutbound = () => {
  const { data, isLoading } = useTradeRequestsOutboundSummaryQuery();
  const tradeRequests = data || [];

  return (
    <TradeRequestsView<OutboundTradeRequestWithSummary>
      isLoading={isLoading}
      tradeRequests={tradeRequests}
      noItemsMessage="No outbound trade activity"
      renderItem={(tradeRequest) => (
        <OutboundTradeRequestLarge
          key={tradeRequest.id}
          tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
          url={`/trade-requests/outbound/${tradeRequest.id}`}
        />
      )}
    />
  );
};
