import { PortfolioBreakdown } from '@plus-platform/shared';
import { VictoryScatter } from 'victory-scatter';

import {
  orderedBreakdownRateTypeLabels,
  orderedBreakdownRateTypes,
} from '../../../utils/loanUtils';
import { ABOVE_MARKET_RATE_CHART_COLOR, BELOW_MARKET_RATE_CHART_COLOR } from '../helpers';
import {
  LARGEST_BUBBLE_SIZE,
  mapBreakdownLoansCountByNoteRateForChart,
  SMALLEST_BUBBLE_SIZE,
} from './helpers';
import { LoanTypesByMetricsChart } from './LoanTypesByMetricsChart';

type LoanTypesByNoteRateProps = {
  loansCountByNoteRate?: PortfolioBreakdown['loansCountByNoteRate'];
};

export const LoanTypesByNoteRate = (props: LoanTypesByNoteRateProps) => {
  const { loansCountByNoteRate } = props;

  const data = mapBreakdownLoansCountByNoteRateForChart(loansCountByNoteRate);

  const largestLoanCount = Math.max(...data.map((dataItem) => dataItem.loansCount));

  const chartData = data.map((dataItem) => {
    const { currentMarketRate, loansCount, noteRate, rateType } = dataItem;
    const loanCountRatio = largestLoanCount ? loansCount / largestLoanCount : 0;

    return {
      x: noteRate * 100,
      y: orderedBreakdownRateTypes.indexOf(rateType) * 2 + 1,
      size: Math.max(LARGEST_BUBBLE_SIZE * loanCountRatio, SMALLEST_BUBBLE_SIZE),
      fill:
        !currentMarketRate || noteRate <= currentMarketRate
          ? BELOW_MARKET_RATE_CHART_COLOR
          : ABOVE_MARKET_RATE_CHART_COLOR,
    };
  });

  return (
    <LoanTypesByMetricsChart
      labels={orderedBreakdownRateTypeLabels}
      height={198}
      padding={{ top: 20 }}
    >
      <VictoryScatter
        data={chartData}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
          },
        }}
      />
    </LoanTypesByMetricsChart>
  );
};
