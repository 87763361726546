import { useMutation, useQueryClient } from 'react-query';

import { deleteRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

const revokeUserInvitation = (inviteId: string) => {
  const url = makeApiUrl(`users/invite/${inviteId}`);
  return deleteRequestWithAuth(url);
};

export const useRevokeUserInvitationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(revokeUserInvitation, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ORGANIZATION_MEMBERS]);
    },
  });
};
