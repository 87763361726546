import { REACT_APP_WS_RECONNECT } from '../config';
import { SocketStatusType } from '../contexts/SocketContext';

export const isSocketOpen = (status: SocketStatusType) => status === SocketStatusType.OPEN;

export const isSocketClosed = (status: SocketStatusType) => status === SocketStatusType.CLOSED;

export const isSocketConnecting = (status: SocketStatusType) =>
  status === SocketStatusType.CONNECTING;

export const shouldReconnectSocket = (status: SocketStatusType) =>
  REACT_APP_WS_RECONNECT && isSocketClosed(status);
