import { transparentize } from 'polished';
import styled from 'styled-components/macro';

import { Input as _Input } from '../components/Input';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  animation-name: ${(props) => props.theme.keyFrames.fade};
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const Section = styled.section`
  display: grid;
  justify-content: center;
  align-content: center;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-fill-mode: forwards;
  opacity: 0;
`;

export const LeftHalf = styled(Section)`
  animation-name: ${(props) => props.theme.keyFrames.fadeFromLeft};
  img {
    margin: 0 64px 72px;
  }
`;

export const RightHalf = styled(Section)`
  background: ${(props) => props.theme.colors.purpleSection};
  animation-name: ${(props) => props.theme.keyFrames.fadeFromRight};
`;

export const LoginForm = styled.form`
  width: 410px;
  margin: 64px;
`;

export const Title = styled.h1`
  display: block;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily.display};
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.greenLight};
`;

export const Subtitle = styled.h2`
  display: block;
  margin: 16px 44px 40px;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily.display};
  font-weight: 700;
  font-size: 22px;
  line-height: 145%;
  color: ${(props) => transparentize(0.2, props.theme.colors.white)};
`;

export const Inputs = styled.div`
  input:first-child {
    margin-bottom: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const Input = styled(_Input)`
  background: none;
  color: ${(props) => props.theme.colors.white};
  font-size: 18px;

  &::placeholder {
    color: ${(props) => transparentize(0.2, props.theme.colors.white)};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) => props.theme.colors.white};
    -webkit-box-shadow: 0 0 0 1000px ${(props) => props.theme.colors.purpleSection} inset;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 22px 0 36px;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.greenLight};
  div {
    border-bottom: 1px solid ${(props) => props.theme.colors.greenLight};
    padding-bottom: 4px;
    cursor: not-allowed;
  }
`;

export const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.redMain};
`;
