import {
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOSequenceNumber,
} from './types';

type MaturityOccurrence = MISMOEntity &
  Partial<{
    loanRemainingMaturityTermMonthsCount: MISMOCount;
    loanTermExtensionMonthsCount: MISMOCount;
    sequenceNumber: MISMOSequenceNumber;
  }>;

export enum LoanMaturityPeriodType {
  Biweekly = 'Biweekly',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Semimonthly = 'Semimonthly',
  Week = 'Week',
  Year = 'Year',
}

export type MaturityRule = MISMOEntity &
  Partial<{
    biweeklyComparableMonthlyMaturityDate: MISMODate;
    loanMaturityDate: MISMODate;
    loanMaturityPeriodCount: MISMOCount;
    loanMaturityPeriodType: LoanMaturityPeriodType;
    loanTermMaximumMonthsCount: MISMOCount;
    maximumMaturityTermExtensionCount: MISMOCount;
  }>;

export type Maturity = MISMOEntity &
  Partial<{
    maturityOccurrences: MaturityOccurrence[];
    maturityRule: MaturityRule;
  }>;
