import { IntegratedDisclosureSectionType } from '../integratedDisclosure';
import { MortgageType } from '../loan/loanTerms';
import { Payoff } from '../payOff';
import {
  MISMOAmount,
  MISMOCount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOURI,
} from '../types';
import { LiabilityHolder } from './liabilityHolder';

export enum LiabilityType {
  BorrowerEstimatedTotalMonthlyLiabilityPayment = 'BorrowerEstimatedTotalMonthlyLiabilityPayment',
  CollectionsJudgmentsAndLiens = 'CollectionsJudgmentsAndLiens',
  DeferredStudentLoan = 'DeferredStudentLoan',
  DelinquentTaxes = 'DelinquentTaxes',
  FirstPositionMortgageLien = 'FirstPositionMortgageLien',
  Garnishments = 'Garnishments',
  HELOC = 'HELOC',
  HomeownersAssociationLien = 'HomeownersAssociationLien',
  Installment = 'Installment',
  LeasePayment = 'LeasePayment',
  MonetaryJudgment = 'MonetaryJudgment',
  MortgageLoan = 'MortgageLoan',
  Open30DayChargeAccount = 'Open30DayChargeAccount',
  Open = 'Open',
  Other = 'Other',
  PersonalLoan = 'PersonalLoan',
  Revolving = 'Revolving',
  SecondPositionMortgageLien = 'SecondPositionMortgageLien',
  Taxes = 'Taxes',
  TaxLien = 'TaxLien',
  ThirdPositionMortgageLien = 'ThirdPositionMortgageLien',
  UnsecuredHomeImprovementLoanInstallment = 'UnsecuredHomeImprovementLoanInstallment',
  UnsecuredHomeImprovementLoanRevolving = 'UnsecuredHomeImprovementLoanRevolving',
}

export type Liability = MISMOEntity &
  Partial<{
    HELOCMaximumBalanceAmount: MISMOAmount;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    liabilityAccountIdentifier: MISMOIdentifier;
    'liabilityAccountIdentifier@IdentifierOwnerURI': MISMOURI;
    liabilityDescription: MISMOString;
    liabilityExclusionIndicator: MISMOIndicator;
    liabilityMonthlyPaymentAmount: MISMOAmount;
    liabilityPaymentIncludesTaxesInsuranceIndicator: MISMOIndicator;
    liabilityPayoffStatusIndicator: MISMOIndicator;
    liabilityPayoffWithCurrentAssetsIndicator: MISMOIndicator;
    liabilityRemainingTermMonthsCount: MISMOCount;
    liabilitySecuredBySubjectPropertyIndicator: MISMOIndicator;
    liabilityType: LiabilityType;
    'liabilityType@DisplayLabelText': MISMOString;
    liabilityTypeOtherDescription: MISMOString;
    liabilityUnpaidBalanceAmount: MISMOAmount;
    mortgageType: MortgageType;
    mortgageTypeOtherDescription: MISMOString;
    sequenceNumber: MISMOSequenceNumber;
    subjectLoanResubordinationIndicator: MISMOIndicator;
    liabilityHolder: LiabilityHolder;
    payoff: Payoff;
  }>;

export type LiabilitySummary = MISMOEntity &
  Partial<{
    totalNonSubjectPropertyDebtsToBePaidOffAmount: MISMOAmount;
    totalSubjectPropertyPayoffsAndPaymentsAmount: MISMOAmount;
    totalSubordinateLiensPayOffAmount: MISMOAmount;
  }>;
