export const calculateBPOPercent = (
  BPOAmount?: number,
  purchasePrice?: number
) => {
  if (!BPOAmount || !purchasePrice) {
    return undefined;
  }

  return (BPOAmount - purchasePrice) / purchasePrice;
};
