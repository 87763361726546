import { transparentize } from 'polished';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Input } from '../../components/Input';
import { Stack } from '../../components/Stack';
import { ModalButton } from '../AdminModal/AdminModal';

const FieldWrapper = styled.div`
  margin: 16px 0;
`;

const FormWrapper = styled.div`
  max-width: 370px;
  margin: 0 auto;
`;

const FieldLabel = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${transparentize(0.5, theme.colors.background01)};
    margin-bottom: 8px;
  `}
`;

const FieldError = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.redMain};
    font-size: 12px;
    line-height: 18px;
  `}
`;

const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0;
    border: 0.5px solid ${transparentize(0.85, theme.colors.background01)};
    margin: 16px 0;
  `}
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const SectionTitle = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${transparentize(0.3, theme.colors.redSecondary)};
    text-transform: uppercase;
  `}
`;

export const SectionDescription = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${transparentize(0.3, theme.colors.background01)};
  `}
`;

export const BusyMessage = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${transparentize(0.3, theme.colors.background01)};
  `}
`;

const FieldSet = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`;

type ResetDataFormValues = {
  confirm: string;
};

type ResetDataFormProps = {
  onSubmit: SubmitHandler<ResetDataFormValues>;
  onClose: () => void;
  submitError?: boolean;
  isBusy: boolean;
};

export const ResetDataForm = ({ isBusy, onClose, onSubmit, submitError }: ResetDataFormProps) => {
  const { formState, handleSubmit, register } = useFormContext<ResetDataFormValues>();
  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldSet>
        <ActivityIndicator variant="dark" isActive={isBusy}>
          <SectionTitle>Warning</SectionTitle>
          <SectionDescription>
            This will delete all pools, transactions and notifications and reset all data to it's
            starting value.
          </SectionDescription>
          <SectionDescription>
            Enter <em>"Reset Database"</em> to confirm you want to do this.
          </SectionDescription>
          <FormWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="confirm">Confirm</FieldLabel>
              <Input
                $isInvalid={submitError}
                placeholder="Reset Database"
                type="text"
                {...register('confirm', {
                  required: 'Please enter Reset Database to confirm you really want to do this',
                  validate: (value) =>
                    value === 'Reset Database' ||
                    'Please enter Reset Database to confirm you really want to do this',
                })}
              />
              <FieldError>{errors.confirm ? errors.confirm.message : '\u00A0'}</FieldError>
            </FieldWrapper>
          </FormWrapper>

          <Divider />

          <Buttons>
            <Stack>
              <ModalButton type="button" $style="outlined" onClick={onClose}>
                Cancel
              </ModalButton>
              <ModalButton type="submit">Reset</ModalButton>
            </Stack>
          </Buttons>
        </ActivityIndicator>
      </FieldSet>
      {isBusy && <BusyMessage>Please wait, data reset in progress...</BusyMessage>}
    </form>
  );
};
