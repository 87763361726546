import { NearbyProperties } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataNearbyPropertiesQuery = {
  tradeRequestId: string;
  loanId: string;
};

const getTradeRequestDataNearbyProperties = ({
  loanId,
  tradeRequestId,
}: TradeRequestDataNearbyPropertiesQuery) => {
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/data/loans/${loanId}/nearby-properties`);
  return getRequestWithAuth<NearbyProperties>(url);
};

export const useTradeRequestDataNearbyPropertiesQuery = (
  query: TradeRequestDataNearbyPropertiesQuery
) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_NEARBY_PROPERTIES, query], () =>
    getTradeRequestDataNearbyProperties(query)
  );
