import React from 'react';

import { formatNameWithInitial } from '../../trades/tradeRequestsUtils';
import { AvatarWithFallback } from '../AvatarWithFallback';
import { Message } from './Message';

type UserMessageProps = {
  firstName?: string;
  lastName: string;
  organizationName?: string;
  userAvatar?: string;
  date?: Date;
  children: React.ReactNode;
};

export const UserMessage = (props: UserMessageProps) => {
  const { children, date, firstName, lastName, organizationName, userAvatar } = props;
  const userName = formatNameWithInitial(lastName, firstName);

  return (
    <Message
      icon={
        <AvatarWithFallback src={userAvatar} firstName={firstName} lastName={lastName} size={24} />
      }
      userName={userName}
      organizationName={organizationName}
      date={date}
    >
      {children}
    </Message>
  );
};
