import jwtDecode, { JwtPayload } from 'jwt-decode';

import { makeApiUrl, postRequest } from './apiUtils';
import { LOGOUT_EVENT } from './authUtils';
import { getStorage, makeKey, removeStorage, setStorage } from './storageUtils';

const TOKEN_STORAGE_KEY = 'token';
const TOKEN_EXPIRY_BUFFER_IN_SECONDS = 120;

const isTokenValid = (token: string, isExpiryDateValid: (expiry: number) => boolean) => {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    const expiry = decoded?.exp;

    if (typeof expiry !== 'number') {
      return false;
    }

    if (!isExpiryDateValid(expiry)) {
      return false;
    }

    return true;
  } catch (err) {
    return false;
  }
};

export const isTokenExpired = (token: string) => {
  const isExpiryDateValid = (expiry: number) => expiry >= Date.now() / 1000;
  return !isTokenValid(token, isExpiryDateValid);
};

export const isTokenExpiringSoonOrExpired = (token: string): boolean => {
  const isExpiryDateValid = (expiry: number) =>
    expiry - TOKEN_EXPIRY_BUFFER_IN_SECONDS >= Date.now() / 1000;
  return !isTokenValid(token, isExpiryDateValid);
};

export const getAccessTokenKey = () => makeKey(TOKEN_STORAGE_KEY);

export const getAccessToken = () => getStorage(TOKEN_STORAGE_KEY);

export const removeAccessToken = () => removeStorage(TOKEN_STORAGE_KEY);

export const setAccessToken = (token: string) => setStorage(TOKEN_STORAGE_KEY, token);

export const refreshAccessToken = async () => {
  try {
    const refreshTokenResult = await postRequest<{ token: string }>(
      makeApiUrl('auth/refresh-token'),
      {
        credentials: 'include',
      }
    );

    if (refreshTokenResult?.token) {
      setStorage(TOKEN_STORAGE_KEY, refreshTokenResult.token);
    }

    return refreshTokenResult?.token;
  } catch (error) {
    window.dispatchEvent(new CustomEvent(LOGOUT_EVENT));

    // TODO: Do something with the error
    /* eslint-disable-next-line no-console */
    console.log(error);
  }
};
