import { Loan } from '@plus-platform/shared';

import { makeApiUrl, postDownloadRequestWithAuth } from '../../utils/apiUtils';

export const exportLoanPortfolio = async (loanNumbers: Loan['loanDetails']['loanNumber'][]) => {
  const url = makeApiUrl('portfolio/export');

  return postDownloadRequestWithAuth(url, {
    body: JSON.stringify({ loanNumbers }),
  });
};
