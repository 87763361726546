import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';
import { AdjustmentRuleType } from './types';

enum PerChangePrincipalAndInterestCalculationType {
  AddDollarAmountToPreviousPrincipalAndInterest = 'AddDollarAmountToPreviousPrincipalAndInterest',
  AddPercentBasedOnOriginalPrincipalAndInterest = 'AddPercentBasedOnOriginalPrincipalAndInterest',
  AddPercentBasedOnPreviousPrincipalAndInterest = 'AddPercentBasedOnPreviousPrincipalAndInterest',
  BasedOnInterestRateUsingAThrowawayRate = 'BasedOnInterestRateUsingAThrowawayRate',
  BasedOnNewInterestRate = 'BasedOnNewInterestRate',
  FHA245Formula = 'FHA245Formula',
  Other = 'Other',
}

export type PrincipalAndInterestPaymentPerChangeAdjustmentRule = MISMOEntity &
  Partial<{
    adjustmentRuleType: AdjustmentRuleType;
    noPrincipalAndInterestPaymentCapsFeatureDescription: MISMOString;
    paymentsBetweenPrincipalAndInterestPaymentChangesCount: MISMOCount;
    perChangeMaximumPrincipalAndInterestPaymentAmount: MISMOAmount;
    perChangeMaximumPrincipalAndInterestPaymentDecreaseAmount: MISMOAmount;
    perChangeMaximumPrincipalAndInterestPaymentDecreasePercent: MISMOPercent;
    perChangeMaximumPrincipalAndInterestPaymentIncreaseAmount: MISMOAmount;
    perChangeMaximumPrincipalAndInterestPaymentIncreasePercent: MISMOPercent;
    perChangeMinimumPrincipalAndInterestPaymentAmount: MISMOAmount;
    perChangePrincipalAndInterestCalculationType: PerChangePrincipalAndInterestCalculationType;
    perChangePrincipalAndInterestCalculationTypeOtherDescription: MISMOString;
    perChangePrincipalAndInterestPaymentAdjustmentAmount: MISMOAmount;
    perChangePrincipalAndInterestPaymentAdjustmentEffectiveDate: MISMODate;
    perChangePrincipalAndInterestPaymentAdjustmentEffectiveMonthsCount: MISMOCount;
    perChangePrincipalAndInterestPaymentAdjustmentFrequencyMonthsCount: MISMOCount;
    perChangePrincipalAndInterestPaymentAdjustmentPercent: MISMOPercent;
    perChangePrincipalAndInterestPaymentMinimumDecreaseRatePercent: MISMOPercent;
    perChangePrincipalAndInterestPaymentMinimumIncreaseRatePercent: MISMOPercent;
    sequenceNumber: MISMOSequenceNumber;
  }>;
