import { ALL_LOANS_ATTRIBUTES, PermissionType } from '@plus-platform/shared';
import styled from 'styled-components/macro';

import { ButtonLink } from '../components/ButtonLink';
import { PageSectionTitle } from '../components/Page';
import { Permissions } from '../components/Permissions';
import { WidthWrapper } from '../global/WidthWrapper';

const Wrapper = styled(WidthWrapper)`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const OriginatorHomePage = () => {
  return (
    <Wrapper>
      <Content>
        <PageSectionTitle>Originator Dashboard</PageSectionTitle>
        <Permissions
          permissions={{
            [PermissionType.PERMISSION_CREATE]: ALL_LOANS_ATTRIBUTES,
          }}
        >
          <ButtonLink to="/import">Import loans</ButtonLink>
        </Permissions>
      </Content>
    </Wrapper>
  );
};
