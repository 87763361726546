import { formatNumber } from '../utils/formatUtils';
import * as Styles from './TableStats.styles';

type MapTableStatsProps = {
  totalCount: number;
  label?: string;
};

type TableStatsProps = MapTableStatsProps & {
  visibleCount: number;
};

export const TableStats = ({ label = 'loans', totalCount, visibleCount }: TableStatsProps) => (
  <Styles.Heading>
    Showing {formatNumber(visibleCount)} of {formatNumber(totalCount)} {label}
  </Styles.Heading>
);

export const MapTableStats = ({ label = 'loans', totalCount }: MapTableStatsProps) => (
  <Styles.Heading>
    Showing {formatNumber(totalCount)} {label}
  </Styles.Heading>
);
