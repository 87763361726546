import { GetPoolsResponse } from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPools = async (offset = 0, limit: number | undefined = undefined) => {
  const query = new URLSearchParams({
    offset: `${offset}`,
    limit: limit ? `${limit}` : '',
  });

  const url = makeApiUrl(`pools?${query}`);
  return getRequestWithAuth<GetPoolsResponse>(url);
};
