import { TradeRequest, TradeRequestDataLoan } from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import { useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

const NUMBER_OF_ITEMS_PER_PAGE = 20;

export type TradeRequestDataResponse = {
  loans: (TradeRequestDataLoan & { maturityDate: string })[];
  totalCount: number;
};

export const useTradeRequestDataQuery = (tradeRequestId: TradeRequest['id']) => {
  return useInfiniteQuery(
    [QueryKeys.TRADE_REQUEST_DATA, tradeRequestId],
    ({ pageParam = 0 }) => {
      const query = new URLSearchParams({
        limit: String(NUMBER_OF_ITEMS_PER_PAGE),
        offset: String(pageParam),
      });
      const url = makeApiUrl(`trade-requests/${tradeRequestId}/data?${query}`);

      return getRequestWithAuth<TradeRequestDataResponse>(url);
    },
    {
      getNextPageParam(lastPage, pages) {
        return lastPage && lastPage.loans.length === NUMBER_OF_ITEMS_PER_PAGE
          ? sumBy(pages, 'loans.length')
          : undefined;
      },
    }
  );
};
