import { ContactPoint } from './contact';
import { Name } from './name';
import {
  MISMOCode,
  MISMOEntity,
  MISMOIdentifier,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

enum AddressFormatType {
  HighwayContract = 'HighwayContract',
  Individual = 'Individual',
  International = 'International',
  Military = 'Military',
  Other = 'Other',
  PostOfficeBox = 'PostOfficeBox',
  RuralRoute = 'RuralRoute',
}

export enum AddressType {
  AsShownOnTaxReturn = 'AsShownOnTaxReturn',
  CorporateHeadquarters = 'CorporateHeadquarters',
  Current = 'Current',
  LegalEntityFormation = 'LegalEntityFormation',
  Mailing = 'Mailing',
  Other = 'Other',
  Primary = 'Primary',
  Prior = 'Prior',
}

enum AddressUnitDesignatorType {
  Apartment = 'Apartment',
  Basement = 'Basement',
  Building = 'Building',
  Condo = 'Condo',
  Department = 'Department',
  Floor = 'Floor',
  Front = 'Front',
  Hangar = 'Hangar',
  Key = 'Key',
  Lobby = 'Lobby',
  Lot = 'Lot',
  Lower = 'Lower',
  Office = 'Office',
  Other = 'Other',
  Penthouse = 'Penthouse',
  Pier = 'Pier',
  Rear = 'Rear',
  Room = 'Room',
  Side = 'Side',
  Space = 'Space',
  Stop = 'Stop',
  Suite = 'Suite',
  Trailer = 'Trailer',
  Unit = 'Unit',
  Upper = 'Upper',
}

export type Address = MISMOEntity &
  Partial<{
    addressAdditionalLineText: MISMOString;
    addressFormatType: AddressFormatType;
    addressFormatTypeOtherDescription: MISMOString;
    addressLineText: MISMOString;
    addressType: AddressType;
    addressTypeOtherDescription: MISMOString;
    addressUnitDesignatorType: AddressUnitDesignatorType;
    addressUnitDesignatorTypeOtherDescription: MISMOString;
    addressUnitIdentifier: MISMOIdentifier;
    attentionToName: MISMOString;
    carrierRouteCode: MISMOCode;
    cityName: MISMOString;
    countryCode: MISMOCode;
    countryName: MISMOString;
    countyCode: MISMOCode;
    countyName: MISMOString;
    deliveryPointBarCodeCheckValue: MISMOValue;
    deliveryPointBarCodeValue: MISMOValue;
    highwayContractRouteIdentifier: MISMOIdentifier;
    mailStopCode: MISMOCode;
    plusFourZipCode: MISMOCode;
    postalCode: MISMOCode;
    postOfficeBoxIdentifier: MISMOIdentifier;
    ruralRouteBoxIdentifier: MISMOIdentifier;
    ruralRouteIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
    stateCode: MISMOCode;
    stateName: MISMOString;
    streetName: MISMOString;
    streetPostDirectionalText: MISMOString;
    streetPreDirectionalText: MISMOString;
    streetPrimaryNumberText: MISMOString;
    streetSuffixText: MISMOString;
  }>;

export type BillingAddress = MISMOEntity &
  Partial<{
    address: Address;
    contactPoints: ContactPoint[];
    name: Name;
  }>;
