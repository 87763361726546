import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { Button as ButtonIcon } from '../ButtonIcon.styles';

type styleProps = ThemeProps<DefaultTheme> & ButtonIconStyleProps;

type ButtonIconStyleProps = {
  $isActive: boolean;
  $size?: 'small' | 'medium' | 'large';
  $variant?: 'light' | 'dark';
};

export const activeStyles = ({ $variant, theme }: styleProps) => css`
  color: ${$variant === 'dark' ? theme.colors.accent01 : theme.colors.fillButtonIconToggleActive};
  background: ${$variant === 'dark'
    ? theme.colors.white30
    : theme.colors.backgroundButtonIconToggleActive};
`;

export const hoverStyles = ({ $variant, theme }: styleProps) => css`
  color: ${$variant === 'dark' ? theme.colors.accent01 : theme.colors.fillButtonIconToggleHover};
  background: ${theme.colors.backgroundButtonIconToggleHover};
`;

export const smallStyles = css`
  height: 22px;
  width: 24px;
`;

export const mediumStyles = css``;

export const largeStyles = css``;

export const Button = styled(ButtonIcon)<ButtonIconStyleProps>(
  ({ $isActive, $size = 'large' }) => css`
    ${$isActive && activeStyles};
    border-radius: 3px;

    &:hover,
    &:focus-visible {
      ${$isActive ? activeStyles : hoverStyles};
    }

    &:active {
      ${activeStyles}
    }

    ${$size === 'small' && smallStyles};
    ${$size === 'medium' && mediumStyles};
    ${$size === 'large' && largeStyles};
  `
);
