import styled, { css } from 'styled-components/macro';

import { Heading as _Heading } from './Heading.styles';

export const Heading = styled(_Heading)(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary01};

    span {
      padding: 0 4px;
    }
  `
);

export const SummaryCount = styled.div<{
  $highlightColor?: string;
  $isClickable: boolean;
  $size?: 'medium' | 'small';
}>(
  ({ $highlightColor, $isClickable, $size = 'medium', theme }) => css`
    ${$size === 'small'
      ? theme.typography.headings.headingMedium03Tight
      : theme.typography.headings.headingMedium01}

    width: min-content;
    padding: 0 4px;
    color: ${$highlightColor ?? 'inherit'};
    background: ${theme.colors.white05};
    border-radius: 2px;
    cursor: ${$isClickable ? 'pointer' : 'default'};
  `
);
