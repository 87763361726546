import {
  Identifier,
  PortfolioHomeAnalyticItemData,
  PortfolioHomeAnalyticTemplate,
  PortfolioHomeAnalyticType,
  VisualisationType,
} from '@plus-platform/shared';

import { FlagsAlertsWarningsProps } from '../../components/FlagsAlertsWarnings';
import {
  useDeletePortfolioHomeAnalyticMutation,
  useUpdatePortfolioHomeAnalyticMutation,
} from '../../hooks/queries';
import { AnalyticWidget } from './AnalyticWidget';
import { OneMetricAnalyticTile } from './OneMetricAnalyticTile/OneMetricAnalyticTile';

type HomeAnalyticTileProps = FlagsAlertsWarningsProps &
  PortfolioHomeAnalyticTemplate &
  PortfolioHomeAnalyticItemData & {
    id?: Identifier;
  };

export const HomeAnalyticTile = (props: HomeAnalyticTileProps) => {
  const { id, type, ...otherProps } = props;

  const { mutate: updatePortfolioHomeAnalytic } = useUpdatePortfolioHomeAnalyticMutation();
  const { mutate: deletePortfolioHomeAnalytic } = useDeletePortfolioHomeAnalyticMutation();

  const onUpdate = (visualisationType: VisualisationType) => {
    if (id) {
      updatePortfolioHomeAnalytic({
        id,
        data: { config: { visualisationType } },
      });
    }
  };

  const onDelete = () => {
    if (id) {
      deletePortfolioHomeAnalytic(id);
    }
  };

  if (type !== PortfolioHomeAnalyticType.TWO_METRICS) {
    return (
      <OneMetricAnalyticTile
        {...otherProps}
        data={props.data}
        metric={props.config.metric}
        visualisationType={props.config.visualisationType}
      />
    );
  }

  return (
    <AnalyticWidget
      {...otherProps}
      id={id ? String(id) : ''}
      data={props.data}
      metric={props.config.metric}
      metricBy={props.config.metricBy}
      metricCalculationType={props.config.metricCalculationType}
      visualisationType={props.config.visualisationType}
      linkUrl={id ? `/home/analytics/${id}` : undefined}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  );
};
