import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../../types';
import { AVM } from '../avm';

enum DefinitionOfValueType {
  AsIsValue = 'AsIsValue',
  AsRepaired = 'AsRepaired',
  ForecastValue = 'ForecastValue',
  MarketValue = 'MarketValue',
  Other = 'Other',
  QuickSaleValue = 'QuickSaleValue',
  RetrospectiveValue = 'RetrospectiveValue',
}

export enum PropertyInspectionType {
  ExteriorAndInterior = 'ExteriorAndInterior',
  ExteriorOnly = 'ExteriorOnly',
  None = 'None',
  Other = 'Other',
}

enum PropertyValuationConditionalConclusionType {
  AsIs = 'AsIs',
  Other = 'Other',
  SubjectToCompletionPerPlans = 'SubjectToCompletionPerPlans',
  SubjectToRepairsAndConditions = 'SubjectToRepairsAndConditions',
  SubjectToRepairsProvingUnnecessary = 'SubjectToRepairsProvingUnnecessary',
}

export enum PropertyValuationFormType {
  AppraisalInstituteGreenAddendum = 'AppraisalInstituteGreenAddendum',
  AppraisalUpdateAndOrCompletionReport = 'AppraisalUpdateAndOrCompletionReport',
  ComplianceInspectionReport = 'ComplianceInspectionReport',
  ConditionalCommitmentDirectEndorsementStatementOfAppraisedValue = 'ConditionalCommitmentDirectEndorsementStatementOfAppraisedValue',
  DefinitionsStatementOfLimitingConditionsAndAppraisersCertification = 'DefinitionsStatementOfLimitingConditionsAndAppraisersCertification',
  DesktopUnderwriterPropertyInspectionReport = 'DesktopUnderwriterPropertyInspectionReport',
  EmployeeRelocationCouncil2001 = 'EmployeeRelocationCouncil2001',
  ExteriorOnlyInspectionIndividualCondominiumUnitAppraisalReport = 'ExteriorOnlyInspectionIndividualCondominiumUnitAppraisalReport',
  ExteriorOnlyInspectionIndividualCooperativeInterestAppraisalReport = 'ExteriorOnlyInspectionIndividualCooperativeInterestAppraisalReport',
  ExteriorOnlyInspectionResidentialAppraisalReport = 'ExteriorOnlyInspectionResidentialAppraisalReport',
  ForYourProtectionGetAHomeInspection = 'ForYourProtectionGetAHomeInspection',
  GeneralAddendum = 'GeneralAddendum',
  HUD_VAAddendumToURLA = 'HUD_VAAddendumToURLA',
  ImportantNoticeToHomebuyers = 'ImportantNoticeToHomebuyers',
  IndividualCondominiumUnitAppraisalReport = 'IndividualCondominiumUnitAppraisalReport',
  IndividualCooperativeInterestAppraisalReport = 'IndividualCooperativeInterestAppraisalReport',
  LoanProspectorConditionAndMarketability = 'LoanProspectorConditionAndMarketability',
  ManufacturedHomeAppraisalReport = 'ManufacturedHomeAppraisalReport',
  ManufacturedHomeAppraisalReportVA = 'ManufacturedHomeAppraisalReportVA',
  MarketConditionsAddendumToTheAppraisalReport = 'MarketConditionsAddendumToTheAppraisalReport',
  MortgageesAssuranceOfCompletion = 'MortgageesAssuranceOfCompletion',
  NewConstructionSubterraneanTermiteSoilTreatmentRecord = 'NewConstructionSubterraneanTermiteSoilTreatmentRecord',
  OneUnitResidentialAppraisalFieldReviewReport = 'OneUnitResidentialAppraisalFieldReviewReport',
  Other = 'Other',
  RequestForDeterminationOfReasonableValue = 'RequestForDeterminationOfReasonableValue',
  SmallResidentialIncomePropertyAppraisalReport = 'SmallResidentialIncomePropertyAppraisalReport',
  SubterraneanTermiteSoilTreatmentBuildersGuarantee = 'SubterraneanTermiteSoilTreatmentBuildersGuarantee',
  TwoToFourUnitResidentialAppraisalFieldReviewReport = 'TwoToFourUnitResidentialAppraisalFieldReviewReport',
  UniformResidentialAppraisalReport = 'UniformResidentialAppraisalReport',
  WorldwideEmployeeRelocationCouncilSummaryAppraisalReport = 'WorldwideEmployeeRelocationCouncilSummaryAppraisalReport',
}

export enum PropertyValuationMethodType {
  AutomatedValuationModel = 'AutomatedValuationModel',
  BrokerPriceOpinion = 'BrokerPriceOpinion',
  DesktopAppraisal = 'DesktopAppraisal',
  DriveBy = 'DriveBy',
  EstimateManuallyRenderedFromAPublishedGuide = 'EstimateManuallyRenderedFromAPublishedGuide',
  FullAppraisal = 'FullAppraisal',
  None = 'None',
  Other = 'Other',
  PriorAppraisalUsed = 'PriorAppraisalUsed',
  TaxValuation = 'TaxValuation',
}

// NOTE: MISMO considers OtherDescriptions as a free form String field
// while ULDD has them as a special enum
export enum PropertyValuationMethodTypeOtherDescription {
  FieldReview = 'FieldReview',
}

enum PropertyValuationServiceType {
  LimitedSummaryReport = 'LimitedSummaryReport',
  NonUSPAPAutomatedValuationModel = 'NonUSPAPAutomatedValuationModel',
  NonUSPAPPriceOpinion = 'NonUSPAPPriceOpinion',
  Other = 'Other',
  RestrictedUseReport = 'RestrictedUseReport',
  SelfContainedReport = 'SelfContainedReport',
}

enum PropertyValuationStateType {
  Original = 'Original',
  Subsequent = 'Subsequent',
}

enum PublishedValuationSourceGuideType {
  NADAYellowBook = 'NADAYellowBook',
  Other = 'Other',
}

export type PropertyValuation = MISMOEntity &
  Partial<{
    appraisalIdentifier: MISMOIdentifier;
    definitionOfValueType: DefinitionOfValueType;
    definitionOfValueTypeOtherDescription: MISMOString;
    propertyAppraisedWithinPreviousYearDescription: MISMOString;
    propertyAppraisedWithinPreviousYearIndicator: MISMOIndicator;
    propertyInspectionRequestCommentDescription: MISMOString;
    propertyInspectionResultCommentDescription: MISMOString;
    propertyInspectionType: PropertyInspectionType;
    propertyInspectionTypeOtherDescription: MISMOString;
    propertyMostRecentValuationOrderDate: MISMODate;
    propertyReplacementValueAmount: MISMOAmount;
    propertyValuationAgeDaysCount: MISMOCount;
    propertyValuationAmount: MISMOAmount;
    propertyValuationCommentText: MISMOString;
    propertyValuationConditionalConclusionType: PropertyValuationConditionalConclusionType;
    propertyValuationConditionalConclusionTypeOtherDescription: MISMOString;
    propertyValuationEffectiveDate: MISMODate;
    propertyValuationFormType: PropertyValuationFormType;
    propertyValuationFormTypeOtherDescription: MISMOString;
    propertyValuationMethodType: PropertyValuationMethodType;
    propertyValuationMethodTypeOtherDescription: MISMOString;
    propertyValuationServiceType: PropertyValuationServiceType;
    propertyValuationServiceTypeOtherDescription: MISMOString;
    propertyValuationStateType: PropertyValuationStateType;
    publishedValuationSourceGuideType: PublishedValuationSourceGuideType;
    publishedValuationSourceGuideTypeOtherDescription: MISMOString;
    repairsTotalCostAmount: MISMOAmount;
    sequenceNumber: MISMOSequenceNumber;
    // appraisalAnalysisScores: AppraisalAnalysisScore[];
    avms: AVM[];
  }>;
