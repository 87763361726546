import React from 'react';

export const TableLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6V10H18V6H6ZM6 13V14H18V13H6ZM6 17V18H18V17H6ZM5 5.25C5 5.11193 5.11193 5 5.25 5H18.75C18.8881 5 19 5.11193 19 5.25V10.75C19 10.8881 18.8881 11 18.75 11H5.25C5.11193 11 5 10.8881 5 10.75V5.25ZM5 12.25C5 12.1119 5.11193 12 5.25 12H18.75C18.8881 12 19 12.1119 19 12.25V14.75C19 14.8881 18.8881 15 18.75 15H5.25C5.11193 15 5 14.8881 5 14.75V12.25ZM5.25 16C5.11193 16 5 16.1119 5 16.25V18.75C5 18.8881 5.11193 19 5.25 19H18.75C18.8881 19 19 18.8881 19 18.75V16.25C19 16.1119 18.8881 16 18.75 16H5.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
