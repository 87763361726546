import { GetPortfolioLoansItem } from '@plus-platform/shared';
import { FetchNextPageOptions } from 'react-query';

import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { TableStats } from '../../../components/TableStats';
import { TableToolbar } from '../../../components/TableToolbar';
import { PortfolioTable } from '../../PortfolioTable';
import { Container } from './LoanTypesByLoansTable.styles';

type LoanTypesByLoansTableProps = {
  loans: GetPortfolioLoansItem[];
  totalLoansCount: number;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>;
};

export const LoanTypesByLoansTable = (props: LoanTypesByLoansTableProps) => {
  const { fetchNextPage, isFetchingNextPage, loans, totalLoansCount } = props;

  return (
    <Container>
      <TableToolbar>
        <Heading $size="medium">By loan data</Heading>
        <Divider $orientation="vertical" $flexItem />
        <TableStats totalCount={totalLoansCount} visibleCount={loans.length} />
      </TableToolbar>
      <PortfolioTable
        loans={loans}
        totalLoansCount={totalLoansCount}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        maxVisibleItems={10}
        shouldRedirectToLoan
      />
    </Container>
  );
};
