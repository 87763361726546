import { SubmissionSummary } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const getSubmissionsSummaryQuery = async () => {
  const url = makeApiUrl('submissions/summary');
  return await getRequestWithAuth<Required<SubmissionSummary>>(url);
};

export const useGetSubmissionsSummaryQuery = () =>
  useQuery([QueryKeys.SUBMISSIONS_SUMMARY], async () => {
    return await getSubmissionsSummaryQuery();
  });
