export enum DelinquencyType {
  THIRTY_DAYS = 'thirtyDays',
  SIXTY_DAYS = 'sixtyDays',
  NINETY_DAYS = 'ninetyDays',
  NINETY_PLUS_DAYS = 'ninetyPlusDays',
}

export type DelinquencyData = {
  loanCount: number;
  upb: number;
  outstandingRemittance: number;
  weightedAverageLTV: number;
  weightedAverageCoupon: number;
  judicialCount: number;
  nonJudicialCount: number;
};

export type DelinquencySummary = Partial<Record<DelinquencyType | 'total', DelinquencyData>>;
