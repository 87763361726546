import { FilterKey, FilterOperator, PortfolioSummary } from '@plus-platform/shared';
import React, { HTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { useSinglePortfolioFilterMutation } from '../../hooks/queries';
import { usePortfolioBreakdownQuery } from '../../hooks/queries/usePortfolioBreakdownQuery';
import { LoanCountChart } from '../../portfolio/Breakdown/BreakdownTile';
import { mapBreakdownForLoanCountChart } from '../../portfolio/Breakdown/breakdownUtils';
import {
  formatDefaultValue,
  formatMonetary,
  formatNumber,
  formatPercentage,
} from '../../utils/formatUtils';
import { Divider } from '../Divider';
import { Key, KeyValueList, Pair, Value } from '../KeyValue';
import { PaneHeader } from '../PaneHeader';
import { ProgressBar } from '../ProgressBar';
import * as Styles from './DelinquencyPanes.styles';

export type DelinquencyVariant = 'loan' | 'pool';

export type DelinquencyVariantProps = {
  variant?: DelinquencyVariant;
};

type SummaryKeyFiguresProps = DelinquencyVariantProps & {
  summary?: PortfolioSummary;
};

export const SummaryKeyFigures = ({ summary, variant = 'loan' }: SummaryKeyFiguresProps) => {
  return (
    <Styles.Pane $variant={variant}>
      <PaneHeader
        size={variant === 'pool' ? 'small' : 'medium'}
        highlight={formatNumber(summary?.loansCount).toString()}
      >
        Loan count
      </PaneHeader>
      <Styles.NoProgressSummary>
        <DelinquencyFinancials
          remittanceAmount={summary?.loansRemittanceAmount}
          upbAmount={summary?.loansUPBAmount}
          weightedAverageCoupon={summary?.loansWeightedAverageCoupon}
          weightedAverageLTV={summary?.loansWeightedAverageLTV}
          judicialCount={summary?.judicialCount}
          nonJudicialCount={summary?.nonJudicialCount}
          variant={variant}
          fullHeight
        />
      </Styles.NoProgressSummary>
    </Styles.Pane>
  );
};

type DelinquencyFinancialsProps = DelinquencyVariantProps & {
  upbAmount?: number;
  remittanceAmount?: number;
  weightedAverageLTV?: number;
  weightedAverageCoupon?: number;
  judicialCount?: number;
  nonJudicialCount?: number;
  fullHeight?: boolean;
};

type SummaryWrapperProps = HTMLAttributes<HTMLDivElement> & {
  fullHeight?: boolean;
};

export const SummaryWrapper = ({ children, fullHeight }: SummaryWrapperProps) => (
  <>
    {fullHeight ? (
      <Styles.NoProgressSummary>{children}</Styles.NoProgressSummary>
    ) : (
      <Styles.Summary>{children}</Styles.Summary>
    )}
  </>
);

export const DelinquencyFinancials = ({
  fullHeight,
  judicialCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant = 'loan',
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyFinancialsProps) => {
  return (
    <SummaryWrapper fullHeight={fullHeight}>
      <KeyValueList
        $size={variant === 'pool' ? 'small' : 'large'}
        rowGap={variant === 'pool' ? 4 : 0}
      >
        <Pair>
          <Key $hasPadding={variant === 'pool'}>UPB</Key>
          <Value>{formatMonetary(upbAmount)}</Value>
        </Pair>
        <Pair>
          <Key $hasPadding={variant === 'pool'}>P+I Arrearage</Key>
          <Value>{formatMonetary(remittanceAmount)}</Value>
        </Pair>
        <Pair>
          <Key $hasPadding={variant === 'pool'}>WAC</Key>
          <Value>{formatPercentage(weightedAverageCoupon)}</Value>
        </Pair>
        <Pair>
          <Key $hasPadding={variant === 'pool'}>Wtd Avg LTV</Key>
          <Value>{formatPercentage(weightedAverageLTV)}</Value>
        </Pair>
        <Pair>
          <Key $hasPadding={variant === 'pool'}>Judicial</Key>
          <Value>{formatDefaultValue(judicialCount)}</Value>
        </Pair>
        <Pair>
          <Key $hasPadding={variant === 'pool'}>Non-Judicial</Key>
          <Value>{formatDefaultValue(nonJudicialCount)}</Value>
        </Pair>
      </KeyValueList>
    </SummaryWrapper>
  );
};

type DelinquencyPaneProps = DelinquencyFinancialsProps &
  DelinquencyVariantProps & {
    loanCount?: number;
    fullHeight?: boolean;
  };

const Delinquency30DaysPane = ({
  judicialCount,
  loanCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant = 'loan',
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyPaneProps) => {
  const theme = useTheme();
  return (
    <Styles.Pane $variant={variant}>
      <PaneHeader
        size={variant === 'pool' ? 'small' : 'medium'}
        highlight={formatNumber(loanCount).toString()}
        highlightColor={theme.colors.delinquency30Days}
      >
        30 day default
      </PaneHeader>
      <ProgressBar color1={theme.colors.delinquency30Days} />
      <DelinquencyFinancials
        judicialCount={judicialCount}
        nonJudicialCount={nonJudicialCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
        variant={variant}
      />
    </Styles.Pane>
  );
};

const Delinquency60DaysPane = ({
  judicialCount,
  loanCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant = 'loan',
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyPaneProps) => {
  const theme = useTheme();
  return (
    <Styles.Pane $variant={variant}>
      <PaneHeader
        size={variant === 'pool' ? 'small' : 'medium'}
        highlight={formatNumber(loanCount).toString()}
        highlightColor={theme.colors.delinquency60Days}
      >
        60 day default
      </PaneHeader>
      <ProgressBar color2={theme.colors.delinquency60Days} />
      <DelinquencyFinancials
        judicialCount={judicialCount}
        nonJudicialCount={nonJudicialCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
        variant={variant}
      />
    </Styles.Pane>
  );
};

const Delinquency90DaysPane = ({
  judicialCount,
  loanCount,
  nonJudicialCount,
  remittanceAmount,
  upbAmount,
  variant = 'loan',
  weightedAverageCoupon,
  weightedAverageLTV,
}: DelinquencyPaneProps) => {
  const theme = useTheme();
  return (
    <Styles.Pane $variant={variant}>
      <PaneHeader
        size={variant === 'pool' ? 'small' : 'medium'}
        highlight={formatNumber(loanCount).toString()}
        highlightColor={theme.colors.delinquency90Days}
      >
        90+ day default
      </PaneHeader>
      <ProgressBar color3={theme.colors.delinquency90Days} />
      <DelinquencyFinancials
        judicialCount={judicialCount}
        nonJudicialCount={nonJudicialCount}
        remittanceAmount={remittanceAmount}
        upbAmount={upbAmount}
        weightedAverageCoupon={weightedAverageCoupon}
        weightedAverageLTV={weightedAverageLTV}
        variant={variant}
      />
    </Styles.Pane>
  );
};

type PortfolioMakeupPaneProps = DelinquencyVariantProps & {
  summary: PortfolioSummary;
};

export const PortfolioMakeupPane = ({ summary, variant }: PortfolioMakeupPaneProps) => {
  // TODO: Replace with Pool breakdown query
  const { data: portfolioBreakdown } = usePortfolioBreakdownQuery();

  const loanCountChartData = mapBreakdownForLoanCountChart(portfolioBreakdown);

  return (
    <Styles.Pane $variant={variant}>
      <PaneHeader
        size={variant === 'pool' ? 'small' : 'medium'}
        highlight={formatNumber(summary.loansCount).toString()}
      >
        Loan count
      </PaneHeader>
      <Styles.NoProgressSummary>
        <LoanCountChart data={loanCountChartData.data} variant={variant} />
      </Styles.NoProgressSummary>
    </Styles.Pane>
  );
};

type DelinquencySummaryPaneProps = DelinquencyVariantProps & {
  summary: PortfolioSummary;
};

export const DelinquencySummaryPane = ({ summary, variant }: DelinquencySummaryPaneProps) => {
  const history = useHistory();
  const { mutateAsync: applySingleFilter } = useSinglePortfolioFilterMutation();
  return (
    <Styles.Pane $variant={variant}>
      <PaneHeader
        size={variant === 'pool' ? 'small' : 'medium'}
        highlight={formatNumber(summary.loansInDefault?.total?.loanCount).toString()}
        onClick={async () => {
          await applySingleFilter({
            key: FilterKey.IS_DELINQUENT,
            operator: FilterOperator.IS,
            value: 'true',
            name: 'Status is Delinquent',
          });
          history.push('/portfolio');
        }}
      >
        Loans in default
      </PaneHeader>
      <DelinquencyFinancials
        judicialCount={summary.loansInDefault?.total?.judicialCount}
        nonJudicialCount={summary.loansInDefault?.total?.nonJudicialCount}
        remittanceAmount={summary.loansInDefault?.total?.outstandingRemittance}
        upbAmount={summary.loansInDefault?.total?.upb}
        weightedAverageCoupon={summary.loansInDefault?.total?.weightedAverageCoupon}
        weightedAverageLTV={summary.loansInDefault?.total?.weightedAverageLTV}
        variant={variant}
        fullHeight
      />
    </Styles.Pane>
  );
};

type DelinquencyWidgetProps = DelinquencyVariantProps & {
  summary: PortfolioSummary;
  hideLoansInDefault?: boolean;
};

export const DelinquencyPanes = ({
  hideLoansInDefault,
  summary,
  variant = 'loan',
}: DelinquencyWidgetProps) => {
  return (
    <React.Fragment>
      <SummaryKeyFigures summary={summary} variant={variant} />
      {!hideLoansInDefault && (
        <>
          {variant === 'loan' && <Divider $orientation="vertical" $flexItem />}
          <DelinquencySummaryPane summary={summary} variant={variant} />
        </>
      )}
      {variant === 'pool' && <PortfolioMakeupPane summary={summary} variant={variant} />}
      {variant === 'loan' && <Divider $orientation="vertical" $flexItem />}
      <Delinquency30DaysPane
        judicialCount={summary.loansInDefault?.thirtyDays?.judicialCount}
        loanCount={summary.loansInDefault?.thirtyDays?.loanCount}
        nonJudicialCount={summary.loansInDefault?.thirtyDays?.nonJudicialCount}
        remittanceAmount={summary.loansInDefault?.thirtyDays?.outstandingRemittance}
        upbAmount={summary.loansInDefault?.thirtyDays?.upb}
        weightedAverageCoupon={summary.loansInDefault?.thirtyDays?.weightedAverageCoupon}
        weightedAverageLTV={summary.loansInDefault?.thirtyDays?.weightedAverageLTV}
        variant={variant}
      />
      {variant === 'loan' && <Divider $orientation="vertical" $flexItem />}
      <Delinquency60DaysPane
        judicialCount={summary.loansInDefault?.sixtyDays?.judicialCount}
        loanCount={summary.loansInDefault?.sixtyDays?.loanCount}
        nonJudicialCount={summary.loansInDefault?.sixtyDays?.nonJudicialCount}
        remittanceAmount={summary.loansInDefault?.sixtyDays?.outstandingRemittance}
        upbAmount={summary.loansInDefault?.sixtyDays?.upb}
        weightedAverageCoupon={summary.loansInDefault?.sixtyDays?.weightedAverageCoupon}
        weightedAverageLTV={summary.loansInDefault?.sixtyDays?.weightedAverageLTV}
        variant={variant}
      />
      {variant === 'loan' && <Divider $orientation="vertical" $flexItem />}
      <Delinquency90DaysPane
        judicialCount={summary.loansInDefault?.ninetyPlusDays?.judicialCount}
        loanCount={summary.loansInDefault?.ninetyPlusDays?.loanCount}
        nonJudicialCount={summary.loansInDefault?.ninetyPlusDays?.nonJudicialCount}
        remittanceAmount={summary.loansInDefault?.ninetyPlusDays?.outstandingRemittance}
        upbAmount={summary.loansInDefault?.ninetyPlusDays?.upb}
        weightedAverageCoupon={summary.loansInDefault?.ninetyPlusDays?.weightedAverageCoupon}
        weightedAverageLTV={summary.loansInDefault?.ninetyPlusDays?.weightedAverageLTV}
        variant={variant}
      />
    </React.Fragment>
  );
};
