import { AttributeType, InsuranceType, Loan } from '@plus-platform/shared';
import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components/macro';

import { Badge } from '../components/Badge';
import { BodyTextButton } from '../components/BodyTextButton';
import { BodyTextExternalLink } from '../components/BodyTextLink';
import type { CardVariant } from '../components/Card';
import {
  Chain,
  ChainLink,
  ChainLinkSubtext,
  ChainLinkText,
  ChainLinkTitle,
} from '../components/Chain';
import { HeadingLink } from '../components/HeadingLink';
import { AlertIcon } from '../components/icons';
import { Key, Pair, Value, ValueWithAlert } from '../components/KeyValue';
import { Loader } from '../components/Loader';
import { ReadPermissions } from '../components/Permissions';
import { Tbody } from '../components/Table';
import { WidgetContent } from '../components/Widget';
import {
  getClosingDocuments,
  getCreditDocuments,
  getDisclosureDocuments,
} from '../documents/documentUtils';
import { useGetLoanNotesQuery, useZipCodeDataQuery } from '../hooks/queries';
import { formatPoolSaleDate } from '../trades/tradeRequestsUtils';
import {
  DATE_WITH_TIME_OPTIONS,
  EMPTY_VALUE,
  formatBoolean,
  formatDate,
  formatDefaultValue,
  formatMonetary,
  formatPercentage,
} from '../utils/formatUtils';
import {
  formatAmortizationType,
  formatForeclosureMethodType,
  formatLienPriorityType,
  formatLoanPurposeType,
  formatLoanType,
  formatPropertyUsageType,
  formatRefinancePurposeType,
} from '../utils/loanUtils';
import { useLoanContext } from './context';
import { LoanAmortizationData, LoanNearbyPropertiesData, LoanPropertyScoresData } from './LoanData';
import * as Styles from './LoanSummaryWidgets.styles';
import { getLoanNotesMessageFromNotesCount } from './loansUtils';

const LoanAmortization = React.lazy(() => import('./LoanAmortizationSummary'));
const ScoresCharts = React.lazy(() => import('./ScoresCharts'));
const LoanPropertyPictures = React.lazy(() => import('./LoanPropertyPictures'));

const LOAN_SUMMARY_COLUMN_COUNT = 4;

type LoanWidgetProps = {
  loanNumber: string;
  loanSummary: Loan;
  variant?: CardVariant;
};

export const LoanFinancialsWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    const theme = useTheme();

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Financials">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail`}
          role="heading"
          data-testid="LoanWidget_Financials_Heading"
        >
          Financials
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Purchase Price</Key>
            <Value>{formatMonetary(loanSummary.loanProperty.property.purchasePrice)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Current Value</Key>
            <Value>{formatMonetary(loanSummary.loanProperty.property.currentBpo)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Current Value Date</Key>
            <Value>
              {formatDate(
                loanSummary.loanProperty.property.currentBpoDate
                  ? new Date(loanSummary.loanProperty.property.currentBpoDate)
                  : undefined
              )}
            </Value>
          </Pair>
          <Pair $size="large">
            <Key>Down Payment</Key>
            <Value>{formatMonetary(loanSummary.loanProperty.property.downPaymentAmount)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Original UPB</Key>
            <Value>{formatMonetary(loanSummary.financials.originalUPB)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Current UPB</Key>
            <Value>{formatMonetary(loanSummary.financials.currentUPB)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Current LTV</Key>
            <Value>{formatPercentage(loanSummary.financials.currentLTV)}</Value>
          </Pair>
          <Pair $size="large">
            <Styles.TitleKey>Total Monthly Payment</Styles.TitleKey>
            <Value>{formatMonetary(loanSummary.financials.totalMonthlyPayment)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Investor Remittance</Key>
            <Value>
              {formatMonetary(loanSummary.financials.investorRemittance.remittanceAmount)}
            </Value>
          </Pair>
          <Pair $size="large">
            <Key>Servicing Fee Rate</Key>
            <Value>
              {formatPercentage(loanSummary.financials.investorRemittance.yearlyServicingFeeRate)}
            </Value>
          </Pair>
          <Pair $size="large">
            <Key>Escrow Monthly Payment</Key>
            <ValueWithAlert>
              {formatMonetary(loanSummary.loanEscrow.escrowMonthlyPaymentAmount)}
              {!loanSummary.loanEscrow.isEscrowMonthlyPaymentCompliant && (
                <AlertIcon color={theme.colors.redSecondary} height="12px" width="12px" />
              )}
            </ValueWithAlert>
          </Pair>
          <Pair $size="large">
            <Key>Principal</Key>
            <Value>{formatMonetary(loanSummary.financials.principal)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Interest</Key>
            <Value>{formatMonetary(loanSummary.financials.interest)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Taxes</Key>
            <Value>{formatMonetary(loanSummary.financials.taxes)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Insurance</Key>
            <Value>{formatMonetary(loanSummary.financials.insurance)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>HOA Fee</Key>
            <Value>{formatMonetary(loanSummary.financials.hoaFee)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Mortgage Insurance</Key>
            <Value>{formatMonetary(loanSummary.financials.mortgageInsurance)}</Value>
          </Pair>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanPropertyWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Property">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#property`}
          role="heading"
          data-testid="LoanWidget_Property_Heading"
        >
          Property
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Foreclosure</Key>
            <Value>
              {formatForeclosureMethodType(
                loanSummary.loanProperty.property?.foreclosureMethodType
              )}
            </Value>
          </Pair>
          <Pair $size="large">
            <Key>Property Type</Key>
            <Value>{formatDefaultValue(loanSummary.loanProperty.property?.propertyType)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Number of Units</Key>
            <Value>{loanSummary.loanProperty.property.numberOfUnits}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Year Built</Key>
            <Value>{loanSummary.loanProperty.property.yearBuilt}</Value>
          </Pair>
          <Pair $size="large" $isMultiLine>
            <Key>Address</Key>
            <Value>{formatDefaultValue(loanSummary.loanProperty.property.fullAddress)}</Value>
          </Pair>
          <Pair $size="large">
            <React.Suspense fallback={<Loader />}>
              <LoanNearbyPropertiesData>
                <LoanPropertyPictures />
              </LoanNearbyPropertiesData>
            </React.Suspense>
          </Pair>
        </WidgetContent>
        <Styles.PropertyScores>
          <React.Suspense fallback={<Loader />}>
            <LoanPropertyScoresData>
              <ScoresCharts />
            </LoanPropertyScoresData>
          </React.Suspense>
        </Styles.PropertyScores>
      </Styles.LoanWidget>
    );
  }
);

export const LoanOriginationWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Origination">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#origination`}
          role="heading"
          data-testid="LoanWidget_Origination_Heading"
        >
          Origination
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Originating Entity</Key>
            <Value>{formatDefaultValue(loanSummary.loanDetails.originatingEntry)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Loan Type</Key>
            <Value>{formatLoanType(loanSummary.loanTerms?.loanType)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Interest Rate</Key>
            <Value>{formatPercentage(loanSummary.loanTerms?.noteRate)}</Value>
          </Pair>
          <ReadPermissions
            requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_AMORTIZATION_TYPE]}
          >
            <Pair $size="large">
              <Key>Amortization Type</Key>
              <Value>{formatAmortizationType(loanSummary.loanTerms?.amortizationType)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_IS_BALLOON]}>
            <Pair $size="large">
              <Key>Is Balloon</Key>
              <Value>{formatBoolean(loanSummary.loanTerms?.isBalloon)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions
            requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_LIEN_PRIORITY_TYPE]}
          >
            <Pair $size="large">
              <Key>Lien Priority Type</Key>
              <Value>{formatLienPriorityType(loanSummary.loanTerms?.lienPriorityType)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_TERMS_TERM_MONTHS]}>
            <Pair $size="large">
              <Key>Loan Term</Key>
              <Value>{formatDefaultValue(loanSummary.loanTerms?.termMonths)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_REASON_FOR_LOAN]}>
            <Pair $size="large">
              <Key>Reason for Loan</Key>
              <Value>{formatPropertyUsageType(loanSummary.loanDetails.reasonForLoan)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_PURPOSE_OF_LOAN]}>
            <Pair $size="large">
              <Key>Purpose of Loan</Key>
              <Value>{formatLoanPurposeType(loanSummary.loanDetails.purposeOfLoan)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions
            requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_REFINANCE_PURPOSE_TYPE]}
          >
            <Pair $size="large">
              <Key>Refinance Purpose Type</Key>
              <Value>
                {formatRefinancePurposeType(loanSummary.loanDetails?.refinancePurposeType)}
              </Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_ADDITIONAL_LIENS]}>
            <Pair $size="large">
              <Key>Additional Liens</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails.additionalLiens)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_AGENCY_CASE_NUMBER]}>
            <Pair $size="large">
              <Key>Agency Case Number</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails.agencyCaseNumber)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_LENDER_NAME]}>
            <Pair $size="large">
              <Key>Lender Name</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails?.lendersName)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_LENDER_ADDRESS]}>
            <Pair $size="large">
              <Key>Lender Address</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails?.lendersAddress)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_LENDER_CASE_NUMBER]}>
            <Pair $size="large">
              <Key>Lenders Case Number</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails?.lendersCaseNumber)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_QUALIFIED_MORTGAGE]}>
            <Pair $size="large">
              <Key>Qualified Mortgage</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails.qualifiedMortgage)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_QUALIFYING_RATE]}>
            <Pair $size="large">
              <Key>Qualifying Rate</Key>
              <Value>{formatPercentage(loanSummary.loanDetails?.qualifyingRatePercent)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_ORIGINAL_LTV]}>
            <Pair $size="large">
              <Key>Original LTV</Key>
              <Value>{formatPercentage(loanSummary.loanDetails?.originalLtv)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_COMBINED_LTV]}>
            <Pair $size="large">
              <Key>Combined LTV</Key>
              <Value>{formatPercentage(loanSummary.loanDetails?.combinedLtv)}</Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions
            requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_RESERVES_MONTHLY_PAYMENT_COUNT]}
          >
            <Pair $size="large">
              <Key>PITIA Reserves Months</Key>
              <Value>
                {formatDefaultValue(loanSummary.loanDetails?.borrowerReservesMonthlyPaymentCount)}
              </Value>
            </Pair>
          </ReadPermissions>
          <ReadPermissions requiredAttributes={[AttributeType.ATTRIBUTE_LOANS_ABILITY_TO_REPAY]}>
            <Pair $size="large">
              <Key>Ability to Repay</Key>
              <Value>{formatDefaultValue(loanSummary.loanDetails.abilityToRepay)}</Value>
            </Pair>
          </ReadPermissions>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanBorrowersWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Borrowers">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#borrower`}
          role="heading"
          data-testid="LoanWidget_Borrowers_Heading"
        >
          Borrower
        </HeadingLink>
        <WidgetContent direction="column">
          {loanSummary.loanBorrowers.map((loanBorrower, index) => (
            <React.Fragment key={`${loanBorrower.borrower.name}-${index}`}>
              <Pair $size="large">
                <Key>Borrower</Key>
                <Value>{loanBorrower.borrower.name}</Value>
              </Pair>
              <Pair $size="large">
                <Key>Last Credit Score</Key>
                <Value>
                  {formatDefaultValue(loanBorrower.borrower?.representativeCreditScore)}{' '}
                </Value>
              </Pair>
              <Pair $size="large">
                <Key>Credit Score Provider</Key>
                {/* TODO: add Credit Score Provider to borrower structure */}
                <Value>{EMPTY_VALUE} </Value>
              </Pair>
              <Pair $size="large">
                <Key>As of</Key>
                <Value>
                  {formatDefaultValue(
                    loanBorrower.borrower.lastFicoScoreDate
                      ? String(loanBorrower.borrower.lastFicoScoreDate)
                      : undefined
                  )}
                </Value>
              </Pair>
            </React.Fragment>
          ))}
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanAssetLedgerWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    const lastAssetLedgerAction =
      loanSummary.assetLedger.length > 0
        ? loanSummary.assetLedger[loanSummary.assetLedger.length - 1]
        : undefined;

    if (!lastAssetLedgerAction) {
      return null;
    }

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_AssetLedger">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#asset-ledger`}
          role="heading"
          data-testid="LoanWidget_AssetLedger_Heading"
        >
          Asset Ledger
        </HeadingLink>
        <WidgetContent direction="column">
          <Styles.Actions>
            <Styles.ActionTitle>Last Action</Styles.ActionTitle>

            <Styles.Action
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Styles.ActionCategory>
                {(lastAssetLedgerAction.event.category || '').toLowerCase()}
              </Styles.ActionCategory>
              <Styles.ActionText>
                {lastAssetLedgerAction.label} by {lastAssetLedgerAction.participant}
                <br />
                Topic: {lastAssetLedgerAction.topic}
              </Styles.ActionText>

              {lastAssetLedgerAction.event.createdAt && (
                <Styles.ActionText>
                  {formatDate(
                    new Date(lastAssetLedgerAction.event.createdAt),
                    DATE_WITH_TIME_OPTIONS
                  )}
                </Styles.ActionText>
              )}
            </Styles.Action>
          </Styles.Actions>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

type LoanServicerWidgetProps = LoanWidgetProps & {
  isServicerChatOpened?: boolean;
  onChatClick?: () => void;
};

export const LoanServicerWidget = forwardRef<HTMLDivElement, LoanServicerWidgetProps>(
  ({ isServicerChatOpened = false, loanNumber, loanSummary, onChatClick, variant }, ref) => {
    const { isAnonymised } = useLoanContext();

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Servicer">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#servicer`}
          role="heading"
          data-testid="LoanWidget_Servicer_Heading"
        >
          Servicer
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Name</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.name)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>SPOC Group</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.spocName)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Phone</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.companyPhoneNumber)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>{!isAnonymised && 'Servicer history'}</Key>
            <Value>
              {EMPTY_VALUE}
              {/*TODO: Add servicer history*/}
              {/*<BodyTextButton>View</BodyTextButton>*/}
            </Value>
          </Pair>
          <Pair $size="large">
            <Key>Servicer</Key>
            <Value>
              {onChatClick && (
                <BodyTextButton
                  disabled={isServicerChatOpened}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onChatClick();
                  }}
                >
                  Chat now
                </BodyTextButton>
              )}
            </Value>
          </Pair>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

type LoanAcquisitionWidgetProps = LoanWidgetProps & {
  formattedPoolSaleDate: string;
};

export const LoanAcquisitionWidget = forwardRef<HTMLDivElement, LoanAcquisitionWidgetProps>(
  ({ formattedPoolSaleDate, loanNumber, loanSummary, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_AcquisitionHistory">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#acquisition-history`}
          role="heading"
          data-testid="LoanWidget_AcquisitionHistory_Heading"
        >
          Acquisition History
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Pool Number</Key>
            <Value>{formatDefaultValue(loanSummary?.pool?.id)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Pool Type</Key>
            {/* TODO: Add type on Pool structure once defined and return it from backend */}
            <Value>{EMPTY_VALUE}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Deal Identifier</Key>
            <Value>{formatDefaultValue(loanSummary?.pool?.tradeRequest?.id)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Reporting</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.reporting)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Sale Date</Key>
            <Value>{formattedPoolSaleDate}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Status</Key>
            <Value>
              {loanSummary?.pool
                ? formatDefaultValue(loanSummary?.servicer?.servicer?.status)
                : EMPTY_VALUE}
            </Value>
          </Pair>
          <Pair $size="large">
            <Key>Loans</Key>
            <Value>{formatDefaultValue(loanSummary?.pool?.summary?.loansCount)}</Value>
          </Pair>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanInsuranceWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    const hazardInsurer = loanSummary.loanInsurers.find(
      (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_HAZARD
    );
    const mortgageInsurer = loanSummary.loanInsurers.find(
      (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_MORTGAGE
    );

    const floodInsurer = loanSummary.loanInsurers.find(
      (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_FLOOD
    );

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Insurance">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#insurance`}
          role="heading"
          data-testid="LoanWidget_Insurance_Heading"
        >
          Insurance
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Mortgage Insurer</Key>
            <Value>{formatDefaultValue(mortgageInsurer?.insurer?.name)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Hazard Insurer</Key>
            <Value>{formatDefaultValue(hazardInsurer?.insurer?.name)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Flood Zone</Key>
            {/* TODO: add flood zone on flood insurer structure */}
            <Value>{EMPTY_VALUE}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Flood Insurer</Key>
            <Value>{formatDefaultValue(floodInsurer?.insurer?.name)}</Value>
          </Pair>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanDocumentsWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    const theme = useTheme();

    const loanDocuments = loanSummary.loanDocuments.flatMap(
      (loanDocument) => loanDocument.document
    );
    const closingDocuments = getClosingDocuments(loanDocuments);
    const creditDocuments = getCreditDocuments(loanDocuments);
    const disclosureDocuments = getDisclosureDocuments(loanDocuments);

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_DocumentLibrary">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#document-library`}
          role="heading"
          data-testid="LoanWidget_DocumentLibrary_Heading"
        >
          Document Library
        </HeadingLink>
        <WidgetContent direction="column">
          <Styles.DocumentTable>
            <Styles.DocumentThead>
              <Styles.DocumentTableHeaderRow $columnCount={LOAN_SUMMARY_COLUMN_COUNT}>
                <Styles.DocumentHeaderCell />
                <Styles.DocumentHeaderCell>{EMPTY_VALUE}</Styles.DocumentHeaderCell>
                <Styles.DocumentHeaderCell>
                  <Badge $color={theme.colors.statusRed} />
                </Styles.DocumentHeaderCell>
                <Styles.DocumentHeaderCell>
                  <Badge $color={theme.colors.statusGreen} />
                </Styles.DocumentHeaderCell>
              </Styles.DocumentTableHeaderRow>
            </Styles.DocumentThead>
            <Tbody>
              {/* TODO: Update with real document data statuses */}
              <Styles.DocumentTableRow $columnCount={LOAN_SUMMARY_COLUMN_COUNT}>
                <Styles.DocumentCell>Closing Documents</Styles.DocumentCell>
                <Styles.DocumentCell>{closingDocuments.length}</Styles.DocumentCell>
                <Styles.DocumentCell>0</Styles.DocumentCell>
                <Styles.DocumentCell>{closingDocuments.length}</Styles.DocumentCell>
              </Styles.DocumentTableRow>
              {/* TODO: Update with real document data statuses */}
              <Styles.DocumentTableRow $columnCount={LOAN_SUMMARY_COLUMN_COUNT}>
                <Styles.DocumentCell>Credit Documents</Styles.DocumentCell>
                <Styles.DocumentCell>{creditDocuments.length}</Styles.DocumentCell>
                <Styles.DocumentCell>0</Styles.DocumentCell>
                <Styles.DocumentCell>{creditDocuments.length}</Styles.DocumentCell>
              </Styles.DocumentTableRow>
              {/* TODO: Update with real document data statuses */}
              <Styles.DocumentTableRow $columnCount={LOAN_SUMMARY_COLUMN_COUNT}>
                <Styles.DocumentCell>Disclosure Documents</Styles.DocumentCell>
                <Styles.DocumentCell>{disclosureDocuments.length}</Styles.DocumentCell>
                <Styles.DocumentCell>0</Styles.DocumentCell>
                <Styles.DocumentCell>{disclosureDocuments.length}</Styles.DocumentCell>
              </Styles.DocumentTableRow>
            </Tbody>
          </Styles.DocumentTable>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

type LoanAmortizationWidgetProps = {
  loanNumber: LoanWidgetProps['loanNumber'];
  variant: LoanWidgetProps['variant'];
};

export const LoanAmortizationWidget = forwardRef<HTMLDivElement, LoanAmortizationWidgetProps>(
  ({ loanNumber, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Amortization">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#amortization`}
          role="heading"
          data-testid="LoanWidget_Amortization_Heading"
        >
          Amortization &amp; Payment Status
        </HeadingLink>
        <WidgetContent direction="column">
          <React.Suspense fallback={<Loader />}>
            <LoanAmortizationData>
              <LoanAmortization />
            </LoanAmortizationData>
          </React.Suspense>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

type LoanNotesWidgetProps = {
  loanNumber: string;
  variant: LoanWidgetProps['variant'];
};

export const LoanNotesWidget = forwardRef<HTMLDivElement, LoanNotesWidgetProps>(
  ({ loanNumber, variant }, ref) => {
    const { data, isLoading } = useGetLoanNotesQuery(loanNumber);
    const totalCount = data?.pages[0]?.pagination.totalCount || 0;
    const message = getLoanNotesMessageFromNotesCount(totalCount);

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Notes">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#notes`}
          role="heading"
          data-testid="LoanWidget_Notes_Heading"
        >
          Notes
        </HeadingLink>
        <WidgetContent direction="column">
          {isLoading ? (
            <Loader />
          ) : (
            <Styles.Actions>
              <Styles.Action>{message}</Styles.Action>
            </Styles.Actions>
          )}
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanServicerBasicWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Servicer">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#servicer`}
          role="heading"
          data-testid="LoanWidget_Servicer_Heading"
        >
          Servicer
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Name</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.name)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>SPOC Group</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.spocName)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>Phone</Key>
            <Value>{formatDefaultValue(loanSummary?.servicer?.servicer?.companyPhoneNumber)}</Value>
          </Pair>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

export const LoanDemographicsWidget = forwardRef<HTMLDivElement, LoanWidgetProps>(
  ({ loanNumber, loanSummary, variant }, ref) => {
    const zipCode = loanSummary?.loanProperty.property.zipCode;

    const { data: zipCodeData } = useZipCodeDataQuery(zipCode);

    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Demographics">
        <Styles.CensusTitle>
          <HeadingLink
            $size="medium"
            to={`/loan/${loanNumber}/detail/grid#property`}
            role="heading"
            data-testid="LoanWidget_Demographics_Heading"
          >
            Census data
          </HeadingLink>
          for zip code {zipCode}
        </Styles.CensusTitle>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Median Household Income*</Key>
            <Value>{formatMonetary(zipCodeData?.medianIncome)}</Value>
          </Pair>
          <Pair $size="large">
            <Key>% Below Poverty Level</Key>
            <Value>{formatPercentage(zipCodeData?.povertyLevel)}</Value>
          </Pair>
        </WidgetContent>

        <Styles.CitationText>
          source: American Community Survey 2021 5-Year Estimates
        </Styles.CitationText>
        <Styles.CitationText>*in 2021 Inflation-Adjusted Dollars</Styles.CitationText>
      </Styles.LoanWidget>
    );
  }
);

type LoanCustodialWidgetProps = {
  variant: LoanWidgetProps['variant'];
  loanNumber: LoanWidgetProps['loanNumber'];
};

export const LoanCustodialWidget = forwardRef<HTMLDivElement, LoanCustodialWidgetProps>(
  ({ loanNumber, variant }, ref) => {
    return (
      <Styles.LoanWidget ref={ref} $variant={variant} data-testid="LoanWidget_Custodial">
        <HeadingLink
          $size="medium"
          to={`/loan/${loanNumber}/detail/grid#custodial`}
          role="heading"
          data-testid="LoanWidget_Custodial_Heading"
        >
          Custodial
        </HeadingLink>
        <WidgetContent direction="column">
          <Pair $size="large">
            <Key>Custodian name</Key>
            <Value>First National</Value>
          </Pair>
          <Pair $size="large" $isMultiLine>
            <Key>Address</Key>
            <Value>3001 Summer Street Dubuque IA 52000</Value>
          </Pair>
          <Pair $size="large">
            <Styles.TitleKey>Exception Report</Styles.TitleKey>
            <Value></Value>
          </Pair>
          <Pair $size="large">
            <Key>Status</Key>
            <Value>Good</Value>
          </Pair>
          <Pair $size="large">
            <Key>Document</Key>
            <Value>
              {/* Temporary - this should be a link to the doc */}
              <BodyTextExternalLink href="/blank.pdf" download>
                Exception-doc.pdf
              </BodyTextExternalLink>
            </Value>
          </Pair>
          <Pair $size="large">
            <Styles.TitleKey>Inventory Report</Styles.TitleKey>
            <Value></Value>
          </Pair>
          <Pair $size="large">
            <Key>Status</Key>
            <Value>Good</Value>
          </Pair>
          <Pair $size="large">
            <Key>Document</Key>
            <Value>
              {/* Temporary - this should be a link to the doc */}
              <BodyTextExternalLink href="/blank.pdf" download>
                Inventory-report.pdf
              </BodyTextExternalLink>
            </Value>
          </Pair>
          <Pair $size="large" style={{ marginTop: 24 }}>
            <Key style={{ color: 'white' }}>Endorsement Chain</Key>
            <Value></Value>
          </Pair>
          <Pair $size="large">
            <Key>Status</Key>
            <Value>Unbroken, Complete</Value>
          </Pair>
          <Chain>
            <ChainLink>
              <ChainLinkTitle>First Owner</ChainLinkTitle>
              <ChainLinkText>Original note</ChainLinkText>
              <ChainLinkSubtext>as of 3/12/2021</ChainLinkSubtext>
            </ChainLink>
            <ChainLink>
              <ChainLinkTitle>Second Owner</ChainLinkTitle>
              <ChainLinkSubtext>4/8/2021</ChainLinkSubtext>
            </ChainLink>
            <ChainLink>
              <ChainLinkTitle>Current Owner</ChainLinkTitle>
              <ChainLinkSubtext>6/10/2023</ChainLinkSubtext>
            </ChainLink>
          </Chain>
          <Pair $size="large" style={{ marginTop: 24 }}>
            <Key style={{ color: 'white' }}>Assignment Chain</Key>
            <Value></Value>
          </Pair>
          <Pair $size="large">
            <Key>Status</Key>
            <Value>Unbroken, Complete</Value>
          </Pair>
          <Chain>
            <ChainLink>
              <ChainLinkTitle>Original mortgage</ChainLinkTitle>
              <ChainLinkSubtext>as of 3/12/2021</ChainLinkSubtext>
            </ChainLink>
            <ChainLink>
              <ChainLinkTitle>Second mortgage</ChainLinkTitle>
              <ChainLinkSubtext>4/8/2021</ChainLinkSubtext>
            </ChainLink>
            <ChainLink>
              <ChainLinkTitle>Current mortgage</ChainLinkTitle>
              <ChainLinkSubtext>6/10/2023</ChainLinkSubtext>
            </ChainLink>
          </Chain>
        </WidgetContent>
      </Styles.LoanWidget>
    );
  }
);

type LoanSummaryWidgetsProps = {
  loanSummary: Loan;
  isServicerChatOpened?: boolean;
  onChatButtonClick?: () => void;
};

export const LoanSummaryWidgets = ({
  isServicerChatOpened,
  loanSummary,
  onChatButtonClick,
}: LoanSummaryWidgetsProps) => {
  const { isAnonymised } = useLoanContext();

  const formattedPoolSaleDate = formatPoolSaleDate(
    // TODO: Determine trade status based on counterparties
    // loanSummary?.pool?.tradeRequest?.status,
    undefined,
    loanSummary?.pool?.tradeRequest?.updatedAt
  );

  const loanNumber = loanSummary.loanDetails.loanNumber.toUpperCase();
  const variant = 'default';

  return (
    <Styles.Widgets>
      <Styles.WidgetCol>
        <LoanFinancialsWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
        <LoanPropertyWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
        <LoanDocumentsWidget loanSummary={loanSummary} loanNumber={loanNumber} variant={variant} />
      </Styles.WidgetCol>

      <Styles.WidgetCol>
        <LoanOriginationWidget
          loanNumber={loanNumber}
          loanSummary={loanSummary}
          variant={variant}
        />
        <LoanBorrowersWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
        <LoanAssetLedgerWidget
          loanNumber={loanNumber}
          loanSummary={loanSummary}
          variant={variant}
        />
      </Styles.WidgetCol>

      <Styles.WidgetCol>
        <LoanCustodialWidget loanNumber={loanNumber} variant={variant} />
        <LoanServicerWidget
          isServicerChatOpened={isServicerChatOpened}
          loanNumber={loanNumber}
          loanSummary={loanSummary}
          onChatClick={onChatButtonClick}
          variant={variant}
        />
        {!isAnonymised && (
          <LoanAcquisitionWidget
            formattedPoolSaleDate={formattedPoolSaleDate}
            loanNumber={loanNumber}
            loanSummary={loanSummary}
            variant={variant}
          />
        )}
      </Styles.WidgetCol>

      <Styles.WidgetCol>
        <LoanAmortizationWidget loanNumber={loanNumber} variant={variant} />
        {!isAnonymised && (
          <LoanInsuranceWidget
            loanNumber={loanNumber}
            loanSummary={loanSummary}
            variant={variant}
          />
        )}
        {!isAnonymised && <LoanNotesWidget loanNumber={loanNumber} variant={variant} />}
        <LoanDemographicsWidget
          loanNumber={loanNumber}
          loanSummary={loanSummary}
          variant={variant}
        />
      </Styles.WidgetCol>
    </Styles.Widgets>
  );
};

export const LoanMapSummaryWidgets = ({
  loanNumber,
  loanSummary,
  variant = 'light',
}: LoanWidgetProps) => {
  const { isAnonymised } = useLoanContext();
  const formattedPoolSaleDate = formatPoolSaleDate(
    // TODO: Determine trade status based on counterparties
    // loanSummary?.pool?.tradeRequest?.status,
    undefined,
    loanSummary?.pool?.tradeRequest?.updatedAt
  );

  return (
    <React.Fragment>
      <LoanFinancialsWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
      <LoanPropertyWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
      <LoanOriginationWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
      <LoanBorrowersWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
      <LoanAssetLedgerWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
      <LoanServicerBasicWidget
        loanNumber={loanNumber}
        loanSummary={loanSummary}
        variant={variant}
      />
      {!isAnonymised && (
        <LoanAcquisitionWidget
          formattedPoolSaleDate={formattedPoolSaleDate}
          loanNumber={loanNumber}
          loanSummary={loanSummary}
          variant={variant}
        />
      )}
      {!isAnonymised && (
        <LoanInsuranceWidget loanNumber={loanNumber} loanSummary={loanSummary} variant={variant} />
      )}
      <LoanDocumentsWidget loanSummary={loanSummary} loanNumber={loanNumber} variant={variant} />
      <LoanAmortizationWidget loanNumber={loanNumber} variant={variant} />
      {!isAnonymised && <LoanNotesWidget loanNumber={loanNumber} variant={variant} />}
    </React.Fragment>
  );
};
