import styled, { css } from 'styled-components/macro';

import { CloseLineIcon } from '../../components/icons';
import { MapWrapper } from '../../components/Map';
import { SIDE_PANEL_WIDTH } from '../../loans/layers/LayerControls';

export const Wrapper = styled.div<{ $height?: number }>`
  position: relative;
  height: ${({ $height }) => `${$height}px` ?? 'auto'};

  ${MapWrapper} {
    position: absolute;
    inset: 0;
  }

  .google-maps {
    height: 100%;
    width: 100%;
  }
`;

export const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${SIDE_PANEL_WIDTH}px;
`;

export const SidebarContent = styled.div`
  overflow: auto;
  height: 100%;
`;

export const CloseIcon = styled(CloseLineIcon)(
  ({ theme }) => css`
    position: absolute;
    top: 8px;
    right: 8px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    color: ${theme.colors.greyMain};
    background-color: ${theme.colors.white};
    border-radius: 50%;
  `
);
