import { EventActor, EventCategory, Loan } from '@plus-platform/shared';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { Card } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import {
  AlertIcon,
  BellIcon,
  CheckmarkIcon,
  InquiryIcon,
  UpdateIcon,
} from '../../../components/icons';
import { Loader } from '../../../components/Loader';
import { SearchBar } from '../../../components/SearchBar';
import {
  Cell,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
  TableWrapper,
  Tbody,
  Thead,
} from '../../../components/Table';
import { ButtonToggle } from '../../../components/Toggles/ButtonToggle';
import { ToggleGroup } from '../../../components/Toggles/ToggleGroup';
import { formatDate, formatDefaultValue } from '../../../utils/formatUtils';
import { LoanAssetLedgerData, useLoanAssetLedger } from '../../LoanData';
import * as LoanDetailStyles from '../LoanDetail.styles';
import * as Styles from './LoanDetailAssetLedger.styles';

const ASSET_LEDGER_COLUMN_COUNT = 7;

export enum AssetType {
  ALL = 'ALL',
  ORIGINATOR = 'ORIGINATOR',
  SERVICER = 'SERVICER',
  INVESTOR = 'INVESTOR',
}

type AssetLedgerItemIconProps = {
  eventCategory?: EventCategory;
};

export const AssetLedgerItemIcon = ({ eventCategory }: AssetLedgerItemIconProps) => {
  const theme = useTheme();

  if (eventCategory === EventCategory.NOTIFICATION) {
    return (
      <React.Fragment>
        <BellIcon color={theme.colors.statusYellow} height="10px" width="10px" />
        <span>Notification</span>
      </React.Fragment>
    );
  }

  if (eventCategory === EventCategory.ALERT) {
    return (
      <React.Fragment>
        <AlertIcon color={theme.colors.statusOrange} height="9px" width="9px" />
        <span>Alert</span>
      </React.Fragment>
    );
  }

  if (eventCategory === EventCategory.INQUIRY) {
    return (
      <React.Fragment>
        <InquiryIcon color={theme.colors.statusBlue} height="10px" width="10px" />
        <span>Inquiry</span>
      </React.Fragment>
    );
  }

  if (eventCategory === EventCategory.RESOLVED) {
    return (
      <React.Fragment>
        <CheckmarkIcon color={theme.colors.statusGreen} height="10px" width="10px" />
        <span>Resolved</span>
      </React.Fragment>
    );
  }

  if (eventCategory === EventCategory.UPDATE) {
    return (
      <React.Fragment>
        <UpdateIcon color={theme.colors.statusTurquoise} height="10px" width="10px" />
        <span>Update</span>
      </React.Fragment>
    );
  }

  return null;
};

type AssetLedgerTableProps = {
  filter: AssetType;
  searchQuery?: string;
};

const AssetLedgerTable = ({ filter, searchQuery }: AssetLedgerTableProps) => {
  const { data: assetLedger, isLoading } = useLoanAssetLedger();

  const assetLedgerItems = assetLedger || [];

  let filteredAssets =
    filter === AssetType.ALL
      ? assetLedgerItems
      : assetLedgerItems.filter(
          (asset) => asset.participant.toLowerCase() === filter.toLowerCase()
        );

  filteredAssets = filteredAssets.filter(
    (asset) => !searchQuery || asset.label.toLowerCase().includes(searchQuery)
  );

  return (
    <TableWrapper $maxVisibleItems={filteredAssets?.length}>
      <ActivityIndicator contain isActive={isLoading}>
        <Table>
          <Thead>
            <HeaderRow $columnCount={ASSET_LEDGER_COLUMN_COUNT}>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Date</HeaderCell>
              <HeaderCell>Participant</HeaderCell>
              <HeaderCell>Topic</HeaderCell>
              <HeaderCell>Issue #</HeaderCell>
              <HeaderCell>Issue</HeaderCell>
              <HeaderCell>Note</HeaderCell>
            </HeaderRow>
          </Thead>
          <Styles.TableBodyWrapper>
            <Tbody>
              {filteredAssets.map((action, index) => (
                <Row
                  $columnCount={ASSET_LEDGER_COLUMN_COUNT}
                  key={index}
                  data-testid="AssetLedger_Row"
                >
                  <Cell>
                    <Styles.IconWrapper>
                      <AssetLedgerItemIcon eventCategory={action.event.category} />
                    </Styles.IconWrapper>
                  </Cell>
                  <Cell>
                    {action.event.createdAt && formatDate(new Date(action.event.createdAt))}
                  </Cell>
                  <Cell>{action.participant}</Cell>
                  <Cell>{action.topic}</Cell>
                  <Cell>{action.issueId}</Cell>
                  <Cell>{action.label}</Cell>
                  <Cell>{formatDefaultValue(action.note)}</Cell>
                </Row>
              ))}
            </Tbody>
          </Styles.TableBodyWrapper>
        </Table>
      </ActivityIndicator>
    </TableWrapper>
  );
};

type LoanDetailAssetLedgerProps = {
  loan: Loan;
  assetType: AssetType;
  setAssetType: (v: AssetType) => void;
};

const LoanDetailAssetLedger = React.forwardRef<HTMLDivElement, LoanDetailAssetLedgerProps>(
  ({ assetType, loan, setAssetType }, ref) => {
    const [assetLedgerSearchQuery, setAssetLedgerSearchQuery] = React.useState<string>('');

    const handleAssetLedgerSearchChange = React.useMemo(
      () => debounce((event) => setAssetLedgerSearchQuery(event.target.value), 500),
      [setAssetLedgerSearchQuery]
    );

    return (
      <Card id="asset-ledger" ref={ref} data-testid="LoanDetail_AssetLedger">
        <Styles.TitleWrapper>
          <Heading $size="medium">Asset Ledger</Heading>
          <LoanDetailStyles.ControlsWrapper>
            <ToggleGroup
              size="large"
              variant="spaced"
              value={assetType as string}
              onChange={(e, v) => setAssetType(v as AssetType)}
            >
              <ButtonToggle value={AssetType.ALL}>All</ButtonToggle>

              <ButtonToggle
                value={AssetType.INVESTOR}
                disabled={
                  !loan.assetLedger.some((event) => event.event.actor === EventActor.INVESTOR)
                }
              >
                Investor
              </ButtonToggle>

              <ButtonToggle
                value={AssetType.SERVICER}
                disabled={
                  !loan.assetLedger.some((event) => event.event.actor === EventActor.SERVICER)
                }
              >
                Servicer
              </ButtonToggle>

              <ButtonToggle
                value={AssetType.ORIGINATOR}
                disabled={
                  !loan.assetLedger.some((event) => event.event.actor === EventActor.ORIGINATOR)
                }
              >
                Originator
              </ButtonToggle>
            </ToggleGroup>

            <LoanDetailStyles.SearchWrapper>
              <SearchBar
                $lightBackground
                placeholder="Search"
                onChange={handleAssetLedgerSearchChange}
              />
            </LoanDetailStyles.SearchWrapper>
          </LoanDetailStyles.ControlsWrapper>
        </Styles.TitleWrapper>
        <Styles.TableWrapper>
          <React.Suspense fallback={<Loader />}>
            <LoanAssetLedgerData>
              <AssetLedgerTable filter={assetType} searchQuery={assetLedgerSearchQuery} />
            </LoanAssetLedgerData>
          </React.Suspense>
        </Styles.TableWrapper>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailAssetLedger;
