import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: absolute;
  inset: 0;
`;

export const Positioner = styled.div<{ $top: number; $left: number }>`
  ${({ $left, $top }) => css`
    position: absolute;
    top: ${$top}px;
    left: ${$left}px;
  `}
`;
