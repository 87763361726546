import { TradeRequestTabType } from '@plus-platform/shared';
import { useHistory, useParams } from 'react-router-dom';

type ParamProps = {
  category: string;
  tradeId: string;
  counterpartyId?: string;
  tabType?: TradeRequestTabType;
};

export const useMessagingNavigation = () => {
  const { category, counterpartyId, tabType, tradeId } = useParams<ParamProps>();
  const history = useHistory();

  const isCounterpartyMessagingActive = (id: string) => {
    return tabType === TradeRequestTabType.MESSAGES && counterpartyId === id;
  };

  const goToMessagingWithCounterparty = (counterpartyId: string) => {
    history.push(`/trade-requests/${category}/${tradeId}/${counterpartyId}/messages`);
  };

  return {
    isCounterpartyMessagingActive,
    goToMessagingWithCounterparty,
  };
};
