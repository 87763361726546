import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOString,
} from '../types';

enum PropertyZoningCategoryType {
  Agricultural = 'Agricultural',
  Commercial = 'Commercial',
  Industrial = 'Industrial',
  NonResidentialGrandfatheredResidential = 'NonResidentialGrandfatheredResidential',
  NoZoning = 'NoZoning',
  Other = 'Other',
  Residential = 'Residential',
}

export enum SiteZoningComplianceType {
  Illegal = 'Illegal',
  Legal = 'Legal',
  LegalNonConforming = 'LegalNonConforming',
  NoZoning = 'NoZoning',
  Undetermined = 'Undetermined',
  Unknown = 'Unknown',
}

export type SiteZoning = MISMOEntity &
  Partial<{
    propertyZoningCategoryType: PropertyZoningCategoryType;
    propertyZoningCategoryTypeOtherDescription: MISMOString;
    siteZoningClassificationDescription: MISMOString;
    siteZoningClassificationIdentifier: MISMOIdentifier;
    siteZoningComplianceDescription: MISMOString;
    siteZoningComplianceType: SiteZoningComplianceType;
    zoningPermitRebuildToCurrentDensityIndicator: MISMOIndicator;
  }>;
