import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOPercent,
  MISMOString,
} from '../types';

enum PrincipalAndInterestCalculationPaymentPeriodType {
  '30DayPaymentPeriod' = '30DayPaymentPeriod',
  NumberOfDaysBetweenPayments = 'NumberOfDaysBetweenPayments',
  NumberOfDaysInCalendarMonth = 'NumberOfDaysInCalendarMonth',
  Other = 'Other',
}

enum PrincipalAndInterestPaymentDecreaseCapType {
  Mandatory = 'Mandatory',
  Optional = 'Optional',
}

enum PrincipalAndInterestPaymentFinalRecastType {
  None = 'None',
  Other = 'Other',
  RecastOnceOnFinalPaymentChangeDate = 'RecastOnceOnFinalPaymentChangeDate',
  RecastOnFinalPaymentChangeDateAndEveryMonthThereafter = 'RecastOnFinalPaymentChangeDateAndEveryMonthThereafter',
}

enum PrincipalAndInterestPaymentIncreaseCapType {
  Mandatory = 'Mandatory',
  Optional = 'Optional',
}

enum PrincipalBalanceCalculationMethodType {
  CurrentPrincipalBalance = 'CurrentPrincipalBalance',
  ProjectedPrincipalBalance = 'ProjectedPrincipalBalance',
}

export type PrincipalAndInterestPaymentLifetimeAdjustmentRule = MISMOEntity &
  Partial<{
    finalPrincipalAndInterestPaymentChangeDate: MISMODate;
    firstPrincipalAndInterestPaymentChangeDate: MISMODate;
    firstPrincipalAndInterestPaymentChangeMonthsCount: MISMOCount;
    GEMPayoffYearsCount: MISMOCount;
    GPMAndGEMPrincipalAndInterestPaymentChangesCount: MISMOCount;
    GPMMultiplierRatePercent: MISMOPercent;
    initialPaymentDiscountPercent: MISMOPercent;
    paymentAdjustmentLifetimeCapAmount: MISMOAmount;
    paymentAdjustmentLifetimeCapPercent: MISMOPercent;
    paymentsBetweenPrincipalAndInterestPaymentChangesCount: MISMOCount;
    principalAndInterestCalculationPaymentPeriodType: PrincipalAndInterestCalculationPaymentPeriodType;
    principalAndInterestCalculationPaymentPeriodTypeOtherDescription: MISMOString;
    principalAndInterestPaymentDecreaseCapType: PrincipalAndInterestPaymentDecreaseCapType;
    principalAndInterestPaymentFinalRecastType: PrincipalAndInterestPaymentFinalRecastType;
    principalAndInterestPaymentFinalRecastTypeOtherDescription: MISMOString;
    principalAndInterestPaymentIncreaseCapType: PrincipalAndInterestPaymentIncreaseCapType;
    principalAndInterestPaymentMaximumAmount: MISMOAmount;
    principalAndInterestPaymentMaximumAmountEarliestEffectiveMonthsCount: MISMOCount;
    principalAndInterestPaymentMaximumDecreaseRatePercent: MISMOPercent;
    principalAndInterestPaymentMaximumExtensionCount: MISMOCount;
    principalAndInterestPaymentMinimumAmount: MISMOAmount;
    principalBalanceCalculationMethodType: PrincipalBalanceCalculationMethodType;
    totalStepCount: MISMOCount;
  }>;
