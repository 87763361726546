import React from 'react';

export const DashboardFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.6667 4V10H20V4H12.6667Z" fill="currentColor" />
      <path d="M4 4V12.6667H11.3333V4H4Z" fill="currentColor" />
      <path d="M12.6667 11.3333V20H20V11.3333H12.6667Z" fill="currentColor" />
      <path d="M4 14V20H11.3333V14H4Z" fill="currentColor" />
    </svg>
  );
};
