import {
  InboundTradeRequestWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';
import { useState } from 'react';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { ButtonIcon } from '../../components/ButtonIcon';
import { Card } from '../../components/Card';
import { Heading } from '../../components/Heading';
import { CloseLineIcon, InboundIcon, OutboundIcon } from '../../components/icons';
import { ButtonToggle } from '../../components/Toggles/ButtonToggle';
import { ToggleGroup } from '../../components/Toggles/ToggleGroup';
import { useGetTradeRequestsSummaryQuery } from '../../hooks/queries';
import {
  InboundTradeRequestSmall,
  NoTrades,
  OutboundTradeRequestSmall,
} from '../TradeRequestTickets';
import { TradeRequestTab } from '../types';
import * as Styles from './TradeRequestDrawer.styles';

type TradeRequestDrawerProps = {
  onClose?(): void;
};

export const TradeRequestDrawer = ({ onClose }: TradeRequestDrawerProps) => {
  const [selectedTab, setSelectedTab] = useState<TradeRequestTab>(TradeRequestTab.INBOUND);
  const { data: tradeRequestData, isLoading } = useGetTradeRequestsSummaryQuery();

  const inboundTradeRequests = tradeRequestData?.inbound || [];
  const outboundTradeRequests = tradeRequestData?.outbound || [];

  const tradeRequests =
    selectedTab === TradeRequestTab.INBOUND ? inboundTradeRequests : outboundTradeRequests;

  return (
    <Card $hasFlex $hasPadding>
      <Styles.Header>
        <Heading $size="medium">Ticket drawer</Heading>
        <ButtonIcon onClick={onClose} size="medium" variant="dark">
          <CloseLineIcon />
        </ButtonIcon>
      </Styles.Header>
      <ToggleGroup<TradeRequestTab>
        size="medium"
        value={selectedTab}
        variant="spaced"
        shouldEnforceValue
        onChange={(_, value) => setSelectedTab(value as TradeRequestTab)}
      >
        <ButtonToggle value={TradeRequestTab.INBOUND}>
          Inbound {inboundTradeRequests.length > 0 ? `(${inboundTradeRequests.length})` : ''}
        </ButtonToggle>
        <ButtonToggle value={TradeRequestTab.OUTBOUND}>
          Outbound {outboundTradeRequests.length > 0 ? `(${outboundTradeRequests.length})` : ''}
        </ButtonToggle>
      </ToggleGroup>
      <Styles.TicketList>
        <ActivityIndicator contain isActive={isLoading}>
          {tradeRequests.length > 0 ? (
            <>
              {tradeRequests.map((tradeRequest) =>
                selectedTab === TradeRequestTab.INBOUND ? (
                  <InboundTradeRequestSmall
                    key={tradeRequest.id}
                    tradeRequest={tradeRequest as InboundTradeRequestWithSummary}
                    url={`/trade-requests/inbound/${tradeRequest.id}`}
                    variant="light"
                  />
                ) : (
                  <OutboundTradeRequestSmall
                    key={tradeRequest.id}
                    tradeRequest={tradeRequest as OutboundTradeRequestWithSummary}
                    url={`/trade-requests/outbound/${tradeRequest.id}`}
                    variant="light"
                  />
                )
              )}
            </>
          ) : (
            <NoTrades>
              {selectedTab === TradeRequestTab.INBOUND ? (
                <>
                  <InboundIcon /> No inbound trade activity
                </>
              ) : (
                <>
                  <OutboundIcon /> No outbound trade activity
                </>
              )}
            </NoTrades>
          )}
        </ActivityIndicator>
      </Styles.TicketList>
    </Card>
  );
};
