import React from 'react';

export const AddToPoolIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 11.5C19 15.6054 15.6071 19 11.4964 19C11.2625 19 11.0309 18.989 10.8021 18.9674C10.9311 18.5003 11 18.0082 11 17.5C11 17.4953 11 17.4905 11 17.4858C11.1636 17.499 11.3291 17.5058 11.4964 17.5058C14.8241 17.5058 17.5065 14.8293 17.4993 11.5C17.492 8.1707 14.8168 5.50145 11.4891 5.50145C8.16868 5.50145 5.50797 8.1707 5.50797 11.5C5.50797 11.6684 5.51479 11.8352 5.52815 12.0001C5.51877 12 5.50939 12 5.5 12C4.99193 12 4.49994 12.0689 4.03288 12.1978C4.01113 11.9679 4 11.7351 4 11.5C4 7.39458 7.38569 4 11.4891 4C15.5998 4 19 7.39458 19 11.5ZM10.4854 15.174C9.94749 14.0231 9.02274 13.0891 7.87844 12.5395C7.78426 12.2122 7.73369 11.8682 7.73369 11.5145C7.73369 9.42553 9.46641 7.69197 11.5471 7.69197C13.6278 7.69197 15.3678 9.43279 15.3678 11.5145C15.3678 13.5962 13.6351 15.3298 11.5471 15.3298C11.1802 15.3298 10.8237 15.2753 10.4854 15.174ZM9.02417 11.5145C9.02417 12.8854 10.1769 14.0459 11.5471 14.0459C12.9319 14.0459 14.0773 12.8926 14.0773 11.5145C14.0773 10.1364 12.9246 8.97582 11.5471 8.97582C10.1696 8.97582 9.02417 10.1291 9.02417 11.5145Z"
        fill="currentColor"
      />
      <path
        d="M5.19625 19.75H5.87125V17.8825H7.75V17.2075H5.87125V15.25H5.19625V17.2075H3.25V17.8825H5.19625V19.75ZM5.50299 22C4.88249 22 4.29936 21.8819 3.75362 21.6456C3.20787 21.4094 2.73062 21.0869 2.32187 20.6781C1.91312 20.2694 1.59062 19.7918 1.35438 19.2455C1.11812 18.6992 1 18.1155 1 17.4944C1 16.8732 1.11812 16.2895 1.35438 15.7432C1.59062 15.1969 1.91312 14.7212 2.32187 14.3162C2.73062 13.9112 3.20815 13.5906 3.75446 13.3544C4.30076 13.1181 4.88448 13 5.50562 13C6.12677 13 6.71049 13.1181 7.25679 13.3544C7.8031 13.5906 8.27875 13.9112 8.68375 14.3162C9.08875 14.7212 9.40937 15.1975 9.64562 15.745C9.88188 16.2925 10 16.8765 10 17.497C10 18.1175 9.88188 18.7006 9.64562 19.2464C9.40937 19.7921 9.08875 20.2687 8.68375 20.6761C8.27875 21.0834 7.8025 21.4059 7.255 21.6436C6.7075 21.8812 6.1235 22 5.50299 22Z"
        fill="currentColor"
      />
    </svg>
  );
};
