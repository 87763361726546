import React from 'react';

export const AlertIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9978 21C11.5159 21 11.1042 20.8284 10.7625 20.4853C10.4208 20.1421 10.25 19.7296 10.25 19.2478C10.25 18.7659 10.4216 18.3542 10.7647 18.0125C11.1079 17.6708 11.5204 17.5 12.0022 17.5C12.4841 17.5 12.8958 17.6716 13.2375 18.0147C13.5792 18.3579 13.75 18.7704 13.75 19.2522C13.75 19.7341 13.5784 20.1458 13.2353 20.4875C12.8921 20.8292 12.4796 21 11.9978 21ZM10.25 15V3H13.75V15H10.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
