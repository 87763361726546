import { GetPoolsResponse } from '@plus-platform/shared';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import * as api from '../../api/pools';
import { QueryKeys } from './types';

const DEFAULT_ITEMS_PER_PAGE = 30;

export const usePoolsQuery = (
  itemCount = DEFAULT_ITEMS_PER_PAGE
): UseInfiniteQueryResult<GetPoolsResponse> =>
  useInfiniteQuery(
    [QueryKeys.POOLS, itemCount],
    async ({ pageParam = 0 }) => {
      return api.getPools(pageParam, itemCount);
    },
    {
      getNextPageParam: (lastPage) => (lastPage !== undefined ? lastPage.pageNumber : 0),
    }
  );
