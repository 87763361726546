import { PaymentFrequencyType } from './payment';
import { MISMOAmount, MISMOEntity, MISMONumeric, MISMOString } from './types';

export enum ProjectedPaymentCalculationPeriodTermType {
  FinalPayment = 'FinalPayment',
  Monthly = 'Monthly',
  Other = 'Other',
  Yearly = 'Yearly',
}

export type ProjectedPayment = MISMOEntity &
  Partial<{
    paymentFrequencyType: PaymentFrequencyType;
    paymentFrequencyTypeOtherDescription: MISMOString;
    projectedPaymentCalculationPeriodEndNumber: MISMONumeric;
    projectedPaymentCalculationPeriodStartNumber: MISMONumeric;
    projectedPaymentCalculationPeriodTermType: ProjectedPaymentCalculationPeriodTermType;
    projectedPaymentCalculationPeriodTermTypeOtherDescription: MISMOString;
    projectedPaymentEstimatedEscrowPaymentAmount: MISMOAmount;
    projectedPaymentEstimatedTotalMaximumPaymentAmount: MISMOAmount;
    projectedPaymentEstimatedTotalMinimumPaymentAmount: MISMOAmount;
    projectedPaymentMIPaymentAmount: MISMOAmount;
    projectedPaymentPrincipalAndInterestMaximumPaymentAmount: MISMOAmount;
    projectedPaymentPrincipalAndInterestMinimumPaymentAmount: MISMOAmount;
  }>;
