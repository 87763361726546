import React from 'react';

export const CensusDataIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.55556 17.4444H17.4444V10.3083L13.6917 6.55556H6.55556V17.4444ZM6.55556 19C6.12778 19 5.76157 18.8477 5.45694 18.5431C5.15231 18.2384 5 17.8722 5 17.4444V6.55556C5 6.12778 5.15231 5.76157 5.45694 5.45694C5.76157 5.15231 6.12778 5 6.55556 5H14.3333L19 9.66667V17.4444C19 17.8722 18.8477 18.2384 18.5431 18.5431C18.2384 18.8477 17.8722 19 17.4444 19H6.55556ZM8.11111 15.8889H15.8889V14.3333H8.11111V15.8889ZM8.11111 12.7778H15.8889V11.2222H8.11111V12.7778ZM8.11111 9.66667H13.5556V8.11111H8.11111V9.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
