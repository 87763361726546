import React from 'react';

import * as Styles from './DocumentPreview.styles';
import { PDFIconBadge } from './PDFIconBadge';

type DocumentPreviewProps = {
  title: string;
  onClick?: React.MouseEventHandler;
  type?: string;
  preview?: string;
  style?: React.CSSProperties;
};

export const DocumentPreview = (props: DocumentPreviewProps) => {
  const { onClick, preview, style, title, type = 'PDF document' } = props;

  return (
    <Styles.Preview onClick={onClick} style={style}>
      <Styles.Header>
        <PDFIconBadge />
        <div>
          <Styles.FileName>{title}</Styles.FileName>
          <Styles.FileType>{type}</Styles.FileType>
        </div>
      </Styles.Header>
      {preview && <Styles.PreviewImage src={preview} />}
    </Styles.Preview>
  );
};
