import { TradeQuoteWithSummary, TradeRequest } from '@plus-platform/shared';
import sortBy from 'lodash/sortBy';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

const POLLING_DELAY = 10000;

const getTradeRequestOutboundQuotes = (tradeRequestId: TradeRequest['id']) => {
  const url = makeApiUrl(`trade-requests/${tradeRequestId}/outbound/quotes`);

  return getRequestWithAuth<TradeQuoteWithSummary[]>(url);
};

export const useTradeRequestOutboundQuotesQuery = (tradeRequestId: TradeRequest['id']) =>
  useQuery(
    [QueryKeys.TRADE_REQUEST_OUTBOUND_QUOTES, tradeRequestId],
    () => getTradeRequestOutboundQuotes(tradeRequestId),
    {
      select: (data) => {
        // TODO: Investigate sorting on server - remove once fixed
        return sortBy(data, 'createdAt');
      },
      refetchInterval: POLLING_DELAY,
    }
  );
