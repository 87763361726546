import styled from 'styled-components/macro';

export const Messenger = styled.div`
  flex: 1;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const MessengerContent = styled.div`
  position: relative;
`;

export const MessengerScrollAnchor = styled.div`
  overflow-anchor: auto;
  /* NB: Scroll anchor requires 1px of height */
  height: 1px;
`;
