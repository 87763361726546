import { PortfolioBreakdown } from '@plus-platform/shared';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { InternalLink } from '../../../components/Link';
import { AnalyticContainer, AnalyticTitle, WideAnalytic } from '../Analytic';
import { Tab, Tabs } from '../LoanTypesChartTabs';
import { LoanTypesByDefault } from './LoanTypesByDefault';
import { Header } from './LoanTypesByMetrics.styles';
import { LoanTypesByNoteRate } from './LoanTypesByNoteRate';

type TabType = 'note' | 'default';

type Tab = {
  title: string;
  type: TabType;
};

const tabs: Tab[] = [
  { title: 'Note rate', type: 'note' },
  { title: 'Default', type: 'default' },
];

type LoanTypesByMetricsProps = {
  portfolioBreakdown?: PortfolioBreakdown;
};

export const LoanTypesByMetrics = (props: LoanTypesByMetricsProps) => {
  const { url } = useRouteMatch();
  const { portfolioBreakdown } = props;
  const [selectedTab, setSelectedTab] = React.useState<TabType>('note');

  const linkToLargerView =
    selectedTab === 'note'
      ? `${url}/breakdown/types/rates`
      : `${url}/breakdown/delinquent/types/rates`;

  return (
    <WideAnalytic>
      <AnalyticContainer>
        <Header>
          <AnalyticTitle>
            <InternalLink to={linkToLargerView}>Loan types by</InternalLink>
          </AnalyticTitle>

          <Tabs>
            {tabs.map((tab) => (
              <Tab
                key={tab.type}
                $isActive={selectedTab === tab.type}
                onClick={() => setSelectedTab(tab.type)}
              >
                {tab.title}
              </Tab>
            ))}
          </Tabs>
        </Header>

        <div>
          {selectedTab === 'note' && (
            <LoanTypesByNoteRate loansCountByNoteRate={portfolioBreakdown?.loansCountByNoteRate} />
          )}
          {selectedTab === 'default' && (
            <LoanTypesByDefault
              defaultedLoansCountByNoteRate={portfolioBreakdown?.delinquentLoansCountByNoteRate}
            />
          )}
        </div>
      </AnalyticContainer>
    </WideAnalytic>
  );
};
