import React from 'react';

export const UploadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 17.6364C6.89697 17.6364 5.95455 17.2545 5.17273 16.4909C4.39091 15.7273 4 14.7939 4 13.6909C4 12.7455 4.28485 11.903 4.85455 11.1636C5.42424 10.4242 6.1697 9.95152 7.09091 9.74545C7.39394 8.6303 8 7.72727 8.90909 7.03636C9.81818 6.34545 10.8485 6 12 6C13.4182 6 14.6212 6.49394 15.6091 7.48182C16.597 8.4697 17.0909 9.67273 17.0909 11.0909C17.9273 11.1879 18.6212 11.5485 19.1727 12.1727C19.7242 12.797 20 13.5273 20 14.3636C20 15.2727 19.6818 16.0455 19.0455 16.6818C18.4091 17.3182 17.6364 17.6364 16.7273 17.6364H12.7273C12.3273 17.6364 11.9848 17.4939 11.7 17.2091C11.4152 16.9242 11.2727 16.5818 11.2727 16.1818V12.4364L10.1091 13.5636L9.09091 12.5455L12 9.63636L14.9091 12.5455L13.8909 13.5636L12.7273 12.4364V16.1818H16.7273C17.2364 16.1818 17.6667 16.0061 18.0182 15.6545C18.3697 15.303 18.5455 14.8727 18.5455 14.3636C18.5455 13.8545 18.3697 13.4242 18.0182 13.0727C17.6667 12.7212 17.2364 12.5455 16.7273 12.5455H15.6364V11.0909C15.6364 10.0848 15.2818 9.22727 14.5727 8.51818C13.8636 7.80909 13.0061 7.45455 12 7.45455C10.9939 7.45455 10.1364 7.80909 9.42727 8.51818C8.71818 9.22727 8.36364 10.0848 8.36364 11.0909H8C7.29697 11.0909 6.69697 11.3394 6.2 11.8364C5.70303 12.3333 5.45455 12.9333 5.45455 13.6364C5.45455 14.3394 5.70303 14.9394 6.2 15.4364C6.69697 15.9333 7.29697 16.1818 8 16.1818H9.81818V17.6364H8Z"
        fill="currentColor"
      />
    </svg>
  );
};
