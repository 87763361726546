import { GetPortfolioPropertyResponse, Identifier } from '@plus-platform/shared';
import { useQuery, UseQueryResult } from 'react-query';

import * as queries from '../../api/portfolio';
import { QueryKeys } from './types';

export const useGetPortfolioPropertyQuery = (
  propertyId: Identifier
): UseQueryResult<GetPortfolioPropertyResponse> =>
  useQuery([QueryKeys.PORTFOLIO_PROPERTY, propertyId], () =>
    queries.getPortfolioProperty(propertyId)
  );
