import React from 'react';

export const DashboardSquareLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 4.5H11V11H4.5V4.5ZM13 4.5H19.5V11H13V4.5ZM4.5 13H11V19.5H4.5V13ZM13 13H19.5V19.5H13V13Z"
        stroke="currentColor"
      />
    </svg>
  );
};
