export const StormIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8 13.769H8.2C8.1 13.769 8 13.869 8 13.969L7 17.569C7 17.669 7.1 17.769 7.2 17.769H7.7L7 20.569C7 20.769 7.2 20.869 7.3 20.769L10.9 17.169C11 17.069 10.9 16.869 10.8 16.869H9.5L11 14.169C11.1 13.969 11 13.769 10.8 13.769Z"
        fill="currentColor"
      />
      <path
        d="M4 12.769H17C18.7 12.769 20 11.469 20 9.76904C20 8.06904 18.7 6.76904 17 6.76904H16.9C16.6 5.66904 15.9 4.76904 15 4.26904C14.7 2.86904 13.5 1.76904 12 1.76904C11.3 1.76904 10.7 1.96904 10.2 2.36904C9.5 1.36904 8.3 0.769043 7 0.769043C4.8 0.769043 3 2.56904 3 4.76904V4.86904C1.3 5.36904 0 6.86904 0 8.76904C0 10.969 1.8 12.769 4 12.769Z"
        fill="currentColor"
      />
      <path
        d="M3 15.769C3.55228 15.769 4 15.3213 4 14.769C4 14.2168 3.55228 13.769 3 13.769C2.44772 13.769 2 14.2168 2 14.769C2 15.3213 2.44772 15.769 3 15.769Z"
        fill="currentColor"
      />
      <path
        d="M5 18.769C5.55228 18.769 6 18.3213 6 17.769C6 17.2168 5.55228 16.769 5 16.769C4.44772 16.769 4 17.2168 4 17.769C4 18.3213 4.44772 18.769 5 18.769Z"
        fill="currentColor"
      />
      <path
        d="M16 17.769C16.5523 17.769 17 17.3213 17 16.769C17 16.2168 16.5523 15.769 16 15.769C15.4477 15.769 15 16.2168 15 16.769C15 17.3213 15.4477 17.769 16 17.769Z"
        fill="currentColor"
      />
      <path
        d="M1 19.769C1.55228 19.769 2 19.3213 2 18.769C2 18.2168 1.55228 17.769 1 17.769C0.447715 17.769 0 18.2168 0 18.769C0 19.3213 0.447715 19.769 1 19.769Z"
        fill="currentColor"
      />
      <path
        d="M13 15.769C13.5523 15.769 14 15.3213 14 14.769C14 14.2168 13.5523 13.769 13 13.769C12.4477 13.769 12 14.2168 12 14.769C12 15.3213 12.4477 15.769 13 15.769Z"
        fill="currentColor"
      />
      <path
        d="M19 15.769C19.5523 15.769 20 15.3213 20 14.769C20 14.2168 19.5523 13.769 19 13.769C18.4477 13.769 18 14.2168 18 14.769C18 15.3213 18.4477 15.769 19 15.769Z"
        fill="currentColor"
      />
      <path
        d="M12 19.769C12.5523 19.769 13 19.3213 13 18.769C13 18.2168 12.5523 17.769 12 17.769C11.4477 17.769 11 18.2168 11 18.769C11 19.3213 11.4477 19.769 12 19.769Z"
        fill="currentColor"
      />
      <path
        d="M17 20.769C17.5523 20.769 18 20.3213 18 19.769C18 19.2168 17.5523 18.769 17 18.769C16.4477 18.769 16 19.2168 16 19.769C16 20.3213 16.4477 20.769 17 20.769Z"
        fill="currentColor"
      />
    </svg>
  );
};
