import styled, { css } from 'styled-components/macro';

export const CaptionWrapper = styled.div<{
  $align?: string;
  $flexDirection?: string;
  $justify?: string;
}>(
  ({ $align = 'center', $flexDirection = 'row', $justify = 'center' }) => css`
    display: flex;
    gap: 8px;
    align-items: ${$align};
    justify-content: ${$justify};
    flex-direction: ${$flexDirection};
  `
);

export const ChartCaption = styled.div`
  ${({ theme }) => theme.typography.headings.headingRegular03}
`;

export const CaptionTitle = styled.h4`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
`;

export const ChartBadge = styled.div<{ color: string }>`
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 6px 0 0;
  border-radius: 50%;
  background-color: ${({ color, theme }) => theme.colors[color]};
`;

export const CaptionText = styled.p(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular02Tight}
    color: ${theme.colors.white70};
    margin: 0 0 0 18px;
  `
);
