import React from 'react';

type UseDoubleClickProps<T, S> = {
  onClick?: (data: S) => void;
  onDoubleClick: (data: T) => void;
};

const DOUBLE_CLICK_TIMEOUT = 200;

export const useDoubleClick = <T, S>({ onClick, onDoubleClick }: UseDoubleClickProps<T, S>) => {
  const timer = React.useRef<ReturnType<typeof setTimeout> | null>();

  const cancelTimer = () => {
    if (timer) {
      clearTimeout(timer.current as unknown as number);
      timer.current = null;
    }
  };

  const handleClick = React.useCallback(
    (data: S) => {
      cancelTimer();
      timer.current = setTimeout(() => {
        timer.current = null;
        onClick?.(data);
      }, DOUBLE_CLICK_TIMEOUT);
    },
    [onClick]
  );

  const handleDoubleClick = React.useCallback(
    (data: T) => {
      cancelTimer();
      onDoubleClick(data);
    },
    [onDoubleClick]
  );

  return { handleClick, handleDoubleClick };
};
