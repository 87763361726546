import React from 'react';

export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.81711 17L4.9999 12.2341L5.92463 11.3192L9.81711 15.1702L18.0752 7.00002L18.9999 7.91492L9.81711 17Z"
        fill="currentColor"
      />
    </svg>
  );
};
