import React from 'react';

export const ColumnsLineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6V18H8V6H6ZM9 18.75C9 18.8881 8.88807 19 8.75 19H5.25C5.11193 19 5 18.8881 5 18.75V5.25C5 5.11193 5.11193 5 5.25 5H8.75C8.88807 5 9 5.11193 9 5.25V18.75ZM14 18.75V5.25C14 5.11193 13.8881 5 13.75 5H10.25C10.1119 5 10 5.11193 10 5.25V18.75C10 18.8881 10.1119 19 10.25 19H13.75C13.8881 19 14 18.8881 14 18.75ZM15 5.25V18.75C15 18.8881 15.1119 19 15.25 19H18.75C18.8881 19 19 18.8881 19 18.75V5.25C19 5.11193 18.8881 5 18.75 5H15.25C15.1119 5 15 5.11193 15 5.25ZM11 6V18H13V6H11ZM16 6V18H18V6H16Z"
        fill="currentColor"
      />
    </svg>
  );
};
