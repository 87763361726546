import { CompletedTradeRequestWithSummary } from '@plus-platform/shared';
import sumBy from 'lodash/sumBy';
import { useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

const NUMBER_OF_ITEMS_PER_PAGE = 20;

export type CompletedTradeRequestsResponse = {
  tradeRequests: CompletedTradeRequestWithSummary[];
  totalCount: number;
};

export const useCompletedTradeRequestsQuery = () => {
  return useInfiniteQuery(
    [QueryKeys.COMPLETED_TRADE_REQUESTS],
    ({ pageParam = 0 }) => {
      const query = new URLSearchParams({
        limit: String(NUMBER_OF_ITEMS_PER_PAGE),
        offset: String(pageParam),
      });
      const url = makeApiUrl(`trade-requests/completed?${query}`);

      return getRequestWithAuth<CompletedTradeRequestsResponse>(url);
    },
    {
      getNextPageParam(lastPage, pages) {
        return lastPage && lastPage.tradeRequests.length === NUMBER_OF_ITEMS_PER_PAGE
          ? sumBy(pages, 'tradeRequests.length')
          : undefined;
      },
    }
  );
};
