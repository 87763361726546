import { MISMOAmount, MISMOEntity, MISMOIndicator, MISMOString } from './types';

export enum IntegratedDisclosureCashToCloseItemPaymentType {
  FromBorrower = 'FromBorrower',
  ToBorrower = 'ToBorrower',
}

export enum IntegratedDisclosureCashToCloseItemType {
  AdjustmentsAndOtherCredits = 'AdjustmentsAndOtherCredits',
  CashToCloseTotal = 'CashToCloseTotal',
  ClosingCostsFinanced = 'ClosingCostsFinanced',
  ClosingCostsPaidBeforeClosing = 'ClosingCostsPaidBeforeClosing',
  Deposit = 'Deposit',
  DownPayment = 'DownPayment',
  FundsForBorrower = 'FundsForBorrower',
  FundsFromBorrower = 'FundsFromBorrower',
  LenderCredits = 'LenderCredits',
  LoanAmount = 'LoanAmount',
  LoanCostsAndOtherCostsTotal = 'LoanCostsAndOtherCostsTotal',
  SellerCredits = 'SellerCredits',
  TotalClosingCosts = 'TotalClosingCosts',
  TotalPayoffsAndPayments = 'TotalPayoffsAndPayments',
}

export type CashToCloseItem = MISMOEntity &
  Partial<{
    integratedDisclosureCashToCloseItemAmountChangedIndicator: MISMOIndicator;
    integratedDisclosureCashToCloseItemChangeDescription: MISMOString;
    integratedDisclosureCashToCloseItemEstimatedAmount: MISMOAmount;
    integratedDisclosureCashToCloseItemFinalAmount: MISMOAmount;
    integratedDisclosureCashToCloseItemPaymentType: IntegratedDisclosureCashToCloseItemPaymentType;
    integratedDisclosureCashToCloseItemType: IntegratedDisclosureCashToCloseItemType;
  }>;
