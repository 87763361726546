import { ellipsis } from 'polished';
import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary03};
  width: fit-content;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameText = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary01};
    display: inline-block;
    ${theme.typography.headings.headingMedium04}
    ${ellipsis('150px')}
  `
);

export const DocTypeText = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary02};
    ${theme.typography.bodyText.bodyRegular04}
  `
);

export const CloseIconWrapper = styled.div`
  align-self: flex-start;
`;
