import React from 'react';

export const AssetLedgerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.53125 13.4375V12.7656C7.25521 12.7031 7.01693 12.5833 6.81641 12.4063C6.61589 12.2292 6.46875 11.9792 6.375 11.6563L6.95313 11.4219C7.03125 11.6719 7.14714 11.862 7.30078 11.9922C7.45443 12.1224 7.65625 12.1875 7.90625 12.1875C8.11979 12.1875 8.30078 12.1393 8.44922 12.043C8.59766 11.9466 8.67188 11.7969 8.67188 11.5938C8.67188 11.4115 8.61458 11.2669 8.5 11.1602C8.38542 11.0534 8.11979 10.9323 7.70313 10.7969C7.25521 10.6563 6.94792 10.4883 6.78125 10.293C6.61458 10.0977 6.53125 9.85938 6.53125 9.57813C6.53125 9.23958 6.64063 8.97656 6.85938 8.78906C7.07813 8.60156 7.30208 8.49479 7.53125 8.46875V7.8125H8.15625V8.46875C8.41667 8.51042 8.63151 8.60547 8.80078 8.75391C8.97005 8.90234 9.09375 9.08333 9.17188 9.29688L8.59375 9.54688C8.53125 9.38021 8.44271 9.25521 8.32813 9.17188C8.21354 9.08854 8.05729 9.04688 7.85938 9.04688C7.63021 9.04688 7.45573 9.09766 7.33594 9.19922C7.21615 9.30078 7.15625 9.42708 7.15625 9.57813C7.15625 9.75 7.23438 9.88542 7.39063 9.98438C7.54688 10.0833 7.81771 10.1875 8.20313 10.2969C8.5625 10.401 8.83464 10.5664 9.01953 10.793C9.20443 11.0195 9.29688 11.2813 9.29688 11.5781C9.29688 11.9479 9.1875 12.2292 8.96875 12.4219C8.75 12.6146 8.47917 12.7344 8.15625 12.7813V13.4375H7.53125Z"
        fill="currentColor"
      />
      <path
        d="M15.9688 12.7656V13.4375H16.5938V12.7813C16.9167 12.7344 17.1875 12.6146 17.4063 12.4219C17.625 12.2292 17.7344 11.9479 17.7344 11.5781C17.7344 11.2813 17.6419 11.0195 17.457 10.793C17.2721 10.5664 17 10.401 16.6406 10.2969C16.2552 10.1875 15.9844 10.0833 15.8281 9.98438C15.6719 9.88542 15.5938 9.75 15.5938 9.57813C15.5938 9.42708 15.6536 9.30078 15.7734 9.19922C15.8932 9.09766 16.0677 9.04688 16.2969 9.04688C16.4948 9.04688 16.651 9.08854 16.7656 9.17188C16.8802 9.25521 16.9688 9.38021 17.0313 9.54688L17.6094 9.29688C17.5313 9.08333 17.4076 8.90234 17.2383 8.75391C17.069 8.60547 16.8542 8.51042 16.5938 8.46875V7.8125H15.9688V8.46875C15.7396 8.49479 15.5156 8.60156 15.2969 8.78906C15.0781 8.97656 14.9688 9.23958 14.9688 9.57813C14.9688 9.85938 15.0521 10.0977 15.2188 10.293C15.3854 10.4883 15.6927 10.6563 16.1406 10.7969C16.5573 10.9323 16.8229 11.0534 16.9375 11.1602C17.0521 11.2669 17.1094 11.4115 17.1094 11.5938C17.1094 11.7969 17.0352 11.9466 16.8867 12.043C16.7383 12.1393 16.5573 12.1875 16.3438 12.1875C16.0938 12.1875 15.8919 12.1224 15.7383 11.9922C15.5846 11.862 15.4688 11.6719 15.3906 11.4219L14.8125 11.6563C14.9063 11.9792 15.0534 12.2292 15.2539 12.4063C15.4544 12.5833 15.6927 12.7031 15.9688 12.7656Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.0909C11.3455 17.5727 10.6364 17.1705 9.87273 16.8841C9.10909 16.5977 8.31818 16.4545 7.5 16.4545C6.92727 16.4545 6.36477 16.5295 5.8125 16.6795C5.26023 16.8295 4.73182 17.0409 4.22727 17.3136C3.94091 17.4636 3.66477 17.4568 3.39886 17.2932C3.13295 17.1295 3 16.8909 3 16.5773V6.71818C3 6.56818 3.0375 6.425 3.1125 6.28864C3.1875 6.15227 3.3 6.05 3.45 5.98182C4.07727 5.65455 4.73182 5.40909 5.41364 5.24545C6.09545 5.08182 6.79091 5 7.5 5C8.29091 5 9.06477 5.10227 9.82159 5.30682C10.5784 5.51136 11.3045 5.81818 12 6.22727C12.6955 5.81818 13.4216 5.51136 14.1784 5.30682C14.9352 5.10227 15.7091 5 16.5 5C17.2091 5 17.9045 5.08182 18.5864 5.24545C19.2682 5.40909 19.9227 5.65455 20.55 5.98182C20.7 6.05 20.8125 6.15227 20.8875 6.28864C20.9625 6.425 21 6.56818 21 6.71818V16.5773C21 16.8909 20.867 17.1295 20.6011 17.2932C20.3352 17.4568 20.0591 17.4636 19.7727 17.3136C19.2682 17.0409 18.7398 16.8295 18.1875 16.6795C17.6352 16.5295 17.0727 16.4545 16.5 16.4545C15.6818 16.4545 14.8909 16.5977 14.1273 16.8841C13.3636 17.1705 12.6545 17.5727 12 18.0909ZM7.5 14.8182C8.14091 14.8182 8.76477 14.8898 9.37159 15.033C9.97841 15.1761 10.5818 15.3909 11.1818 15.6773V7.61818C10.6227 7.29091 10.0295 7.04545 9.40227 6.88182C8.775 6.71818 8.14091 6.63636 7.5 6.63636C7.00909 6.63636 6.52159 6.68409 6.0375 6.77955C5.55341 6.875 5.08636 7.01818 4.63636 7.20909V15.3091C5.11364 15.1455 5.5875 15.0227 6.05795 14.9409C6.52841 14.8591 7.00909 14.8182 7.5 14.8182ZM12.8182 15.6773C13.4182 15.3909 14.0216 15.1761 14.6284 15.033C15.2352 14.8898 15.8591 14.8182 16.5 14.8182C16.9909 14.8182 17.4716 14.8591 17.942 14.9409C18.4125 15.0227 18.8864 15.1455 19.3636 15.3091V7.20909C18.9136 7.01818 18.4466 6.875 17.9625 6.77955C17.4784 6.68409 16.9909 6.63636 16.5 6.63636C15.8591 6.63636 15.225 6.71818 14.5977 6.88182C13.9705 7.04545 13.3773 7.29091 12.8182 7.61818V15.6773Z"
        fill="currentColor"
      />
    </svg>
  );
};
