import {
  CounterpartyWithSummary,
  TradeCounterpartyActionType,
  TradeQuoteStatus,
  TradeQuoteType,
  TradeRequestActionLog,
  TradeRequestCounterLog,
  TradeRequestLog,
  TradeRequestQuoteLog,
  TradeRequestWithSummary,
  UserIdentifier,
} from '@plus-platform/shared';

import { downloadBlob } from '../utils/apiUtils';
import { EMPTY_VALUE, formatDate, formatTime } from '../utils/formatUtils';
import { TradeMessageId } from './tradeMessagesUtil';
import { formatNameWithInitial } from './tradeRequestsUtils';
import { TradeTransactionLog } from './types';

export const formatFromUserName = (
  counterparties: CounterpartyWithSummary[],
  fromCounterparty: TradeRequestLog['fromCounterparty'],
  currentUserId?: UserIdentifier
) => {
  const counterparty = counterparties.find(
    (counterparty) => counterparty.id === fromCounterparty.id
  );

  if (!counterparty) {
    return undefined;
  }

  return counterparty.user.id === currentUserId
    ? 'You'
    : formatNameWithInitial(fromCounterparty.lastName, fromCounterparty?.firstName);
};

export const ACTION_TYPE_TO_LOG_TYPE_MAP: Record<TradeCounterpartyActionType, string> = {
  [TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST]: 'Expression of Interest',
  [TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST]: 'Expression of Interest',
  [TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST]: 'Expression of Interest',
  [TradeCounterpartyActionType.REQUEST_DATA_REQUEST]: 'Request for Data',
  [TradeCounterpartyActionType.ACCEPT_DATA_REQUEST]: 'Request for Data',
  [TradeCounterpartyActionType.DENY_DATA_REQUEST]: 'Request for Data',
  [TradeCounterpartyActionType.MARK_AS_SOLD]: 'Bids',
  [TradeCounterpartyActionType.MARK_AS_SETTLED]: 'Settled',
};

export const getLogMessageIdFromAction = (
  log: TradeRequestActionLog,
  isSeller: boolean
): TradeMessageId | undefined => {
  const type = log.metadata.type;

  if (type === TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST) {
    return isSeller
      ? 'Trade.Outbound.ExpressionOfInterest.Requested'
      : 'Trade.Inbound.ExpressionOfInterest.Requested';
  }

  if (type === TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST) {
    return isSeller
      ? 'Trade.Outbound.ExpressionOfInterest.Accepted'
      : 'Trade.Inbound.ExpressionOfInterest.Accepted';
  }

  if (type === TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST) {
    return isSeller
      ? log.metadata.reason
        ? 'Trade.Outbound.ExpressionOfInterest.DeclinedWithReason'
        : 'Trade.Outbound.ExpressionOfInterest.DeclinedWithoutReason'
      : log.metadata.reason
      ? 'Trade.Inbound.ExpressionOfInterest.DeclinedWithReason'
      : 'Trade.Inbound.ExpressionOfInterest.DeclinedWithoutReason';
  }

  if (type === TradeCounterpartyActionType.REQUEST_DATA_REQUEST) {
    return isSeller
      ? 'Trade.Outbound.DataRequest.Requested'
      : 'Trade.Inbound.DataRequest.Requested';
  }

  if (type === TradeCounterpartyActionType.ACCEPT_DATA_REQUEST) {
    return isSeller ? 'Trade.Outbound.DataRequest.Accepted' : 'Trade.Inbound.DataRequest.Accepted';
  }

  if (type === TradeCounterpartyActionType.DENY_DATA_REQUEST) {
    return isSeller
      ? log.metadata.reason
        ? 'Trade.Outbound.DataRequest.DeniedWithReason'
        : 'Trade.Outbound.DataRequest.DeniedWithoutReason'
      : log.metadata.reason
      ? 'Trade.Inbound.DataRequest.DeniedWithReason'
      : 'Trade.Inbound.DataRequest.DeniedWithoutReason';
  }
};

export const getLogMessageIdFromQuote = (
  log: TradeRequestQuoteLog,
  isSeller: boolean
): TradeMessageId | undefined => {
  const { status, stipulation } = log.metadata;

  if (status === TradeQuoteStatus.PENDING) {
    return isSeller
      ? stipulation
        ? 'Trade.Outbound.Quote.PendingWithStipulations'
        : 'Trade.Outbound.Quote.PendingWithoutStipulations'
      : 'Trade.Inbound.Quote.Pending';
  }

  if (status === TradeQuoteStatus.REJECTED) {
    return isSeller ? 'Trade.Outbound.Quote.Rejected' : 'Trade.Inbound.Quote.Rejected';
  }
};

export const getLogMessageIdFromCounter = (
  log: TradeRequestCounterLog,
  isSeller: boolean
): TradeMessageId | undefined => {
  const { status, type } = log.metadata;

  if (status === TradeQuoteStatus.PENDING) {
    if (type === TradeQuoteType.SELLER_TO_BUYER) {
      return isSeller ? 'Trade.Outbound.Quote.Countered' : 'Trade.Inbound.Quote.Counter.Received';
    }

    if (type === TradeQuoteType.BUYER_TO_SELLER) {
      return isSeller
        ? 'Trade.Outbound.Quote.Counterback.Received'
        : 'Trade.Inbound.Quote.Counterbacked';
    }
  }

  if (status === TradeQuoteStatus.ACCEPTED) {
    if (type === TradeQuoteType.SELLER_TO_BUYER) {
      return isSeller
        ? 'Trade.Outbound.Quote.Counterback.Accepted'
        : 'Trade.Inbound.Quote.Counterback.Accepted';
    }

    if (type === TradeQuoteType.BUYER_TO_SELLER) {
      return isSeller
        ? 'Trade.Outbound.Quote.Counter.Accepted'
        : 'Trade.Inbound.Quote.Counter.Accepted';
    }
  }

  if (status === TradeQuoteStatus.REJECTED) {
    if (type === TradeQuoteType.SELLER_TO_BUYER) {
      return isSeller
        ? 'Trade.Outbound.Quote.Counterback.Rejected'
        : 'Trade.Inbound.Quote.Counterback.Rejected';
    }

    if (type === TradeQuoteType.BUYER_TO_SELLER) {
      return isSeller
        ? 'Trade.Outbound.Quote.Counter.Rejected'
        : 'Trade.Inbound.Quote.Counter.Rejected';
    }
  }
};

export const transformLogsToCSV = (logs: TradeTransactionLog[]) => {
  const header = 'Date,Time,From,Organization,Type,Message,Documents';
  const body = logs
    .map(({ category, createdAt, fromCounterparty, message, stipulation }) => ({
      date: formatDate(createdAt, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      time: formatTime(createdAt, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      }),
      from: formatNameWithInitial(fromCounterparty.lastName, fromCounterparty.firstName),
      organization: fromCounterparty.organization.tradingName,
      type: category,
      message,
      documents: stipulation?.fileName ?? EMPTY_VALUE,
    }))
    .map((log) =>
      Object.values(log)
        .map((value) => `"${value}"`)
        .join(',')
    )
    .join('\n');

  return `${header}\n${body}`;
};

export const downloadLogs = (
  logs: TradeTransactionLog[],
  poolName: TradeRequestWithSummary['pool']['name']
) => {
  const csv = transformLogsToCSV(logs);
  const downloadDateTime = `${formatDate(new Date(), {
    dateStyle: 'full',
  })} ${formatTime(new Date(), { timeStyle: 'long' })}`;
  const fileName = `${poolName} - Transaction logs - ${downloadDateTime}.csv`;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  downloadBlob(fileName, blob);
};
