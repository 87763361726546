import { CashToCloseItem } from './cashToCloseItem';
import { EstimatedPropertyCost } from './estimatedPropertyCost';
import { IntegratedDisclosureSectionSummary } from './integratedDisclosureSectionSummary';
import { ProjectedPayment } from './projectedPayment';
import {
  MISMOAmount,
  MISMODate,
  MISMODatetime,
  MISMOEntity,
  MISMOIndicator,
  MISMOString,
} from './types';

export enum IntegratedDisclosureSectionType {
  DueFromBorrowerAtClosing = 'DueFromBorrowerAtClosing',
  DueFromSellerAtClosing = 'DueFromSellerAtClosing',
  DueToSellerAtClosing = 'DueToSellerAtClosing',
  InitialEscrowPaymentAtClosing = 'InitialEscrowPaymentAtClosing',
  OriginationCharges = 'OriginationCharges',
  Other = 'Other',
  OtherCosts = 'OtherCosts',
  PaidAlreadyByOrOnBehalfOfBorrowerAtClosing = 'PaidAlreadyByOrOnBehalfOfBorrowerAtClosing',
  PayoffsAndPayments = 'PayoffsAndPayments',
  Prepaids = 'Prepaids',
  ServicesBorrowerDidNotShopFor = 'ServicesBorrowerDidNotShopFor',
  ServicesBorrowerDidShopFor = 'ServicesBorrowerDidShopFor',
  ServicesYouCannotShopFor = 'ServicesYouCannotShopFor',
  ServicesYouCanShopFor = 'ServicesYouCanShopFor',
  TaxesAndOtherGovernmentFees = 'TaxesAndOtherGovernmentFees',
  TotalClosingCosts = 'TotalClosingCosts',
  TotalLoanCosts = 'TotalLoanCosts',
  TotalOtherCosts = 'TotalOtherCosts',
}

export enum IntegratedDisclosureSubsectionType {
  Adjustments = 'Adjustments',
  AdjustmentsForItemsPaidBySellerInAdvance = 'AdjustmentsForItemsPaidBySellerInAdvance',
  AdjustmentsForItemsUnpaidBySeller = 'AdjustmentsForItemsUnpaidBySeller',
  ClosingCostsSubtotal = 'ClosingCostsSubtotal',
  LenderCredits = 'LenderCredits',
  LoanCostsSubtotal = 'LoanCostsSubtotal',
  Other = 'Other',
  OtherCostsSubtotal = 'OtherCostsSubtotal',
  OtherCredits = 'OtherCredits',
  TotalClosingCostsSellerOnly = 'TotalClosingCostsSellerOnly',
}

enum IntegratedDisclosureDocumentType {
  ClosingDisclosure = 'ClosingDisclosure',
  'ClosingDisclosure:AlternateForm' = 'ClosingDisclosure:AlternateForm',
  'ClosingDisclosure:BorrowerOnly' = 'ClosingDisclosure:BorrowerOnly',
  'ClosingDisclosure:ModelForm' = 'ClosingDisclosure:ModelForm',
  'ClosingDisclosure:SellerOnly' = 'ClosingDisclosure:SellerOnly',
  LoanEstimate = 'LoanEstimate',
  Other = 'Other',
}

export type IntegratedDisclosure = MISMOEntity &
  Partial<{
    firstYearTotalEscrowPaymentAmount: MISMOAmount;
    firstYearTotalEscrowPaymentDescription: MISMOString;
    firstYearTotalNonEscrowPaymentAmount: MISMOAmount;
    firstYearTotalNonEscrowPaymentDescription: MISMOString;
    fiveYearPrincipalReductionComparisonAmount: MISMOAmount;
    fiveYearTotalOfPaymentsComparisonAmount: MISMOAmount;
    includeSignatureLinesIndicator: MISMOIndicator;
    integratedDisclosureDocumentType: IntegratedDisclosureDocumentType;
    integratedDisclosureDocumentTypeOtherDescription: MISMOString;
    integratedDisclosureEstimatedClosingCostsExpirationDatetime: MISMODatetime;
    integratedDisclosureHomeEquityLoanIndicator: MISMOIndicator;
    integratedDisclosureInitialPrincipalAndInterestPaymentAmount: MISMOAmount;
    integratedDisclosureIssuedDate: MISMODate;
    integratedDisclosureLoanProductDescription: MISMOString;
    integratedDisclosureReceivedDate: MISMODate;
    totalOfPaymentsAndLoanCostsAmount: MISMOAmount;
    cashToCloseItems: CashToCloseItem[];
    estimatedPropertyCost: EstimatedPropertyCost;
    integratedDisclosureSectionSummaries: IntegratedDisclosureSectionSummary[];
    // otherLoanConsiderationsAndDisclosuresItems: OtherLoanConsiderationsAndDisclosuresItem[];
    projectedPayments: ProjectedPayment[];
  }>;
