import styled, { css } from 'styled-components/macro';

import { DefaultButton } from '../components/Button';

export const ViewActions = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButtons = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.purpleDark};
  border-radius: 4px;
  gap: 4px;
`;

export const ActionButton = styled(DefaultButton)<{ $isActive?: boolean }>(
  ({ $isActive, theme }) => css`
    height: 36px;
    width: 36px;
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      svg {
        color: ${theme.colors.white60};
      }
    }

    svg {
      width: 18px;
      height: 18px;
      color: ${theme.colors.white60};
      transition: color 0.2s ease-in-out;
    }

    ${$isActive &&
    css`
      border: 2px solid ${theme.colors.purpleSecondary};

      svg,
      &:hover svg {
        color: ${theme.colors.white60};
      }
    `}
  `
);
