import { CreatePoolBody, GetPoolResponse, GetPoolsResponse } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../api/pools';
import { QueryKeys } from '.';

const blankSummary = {
  loansRemittanceAmount: 0,
  loansUPBAmount: 0,
  loansCount: 0,
  loansWeightedAverageLTV: 0,
  loansWeightedAverageCoupon: 0,
  judicialCount: 0,
  nonJudicialCount: 0,
  loansInDefault: {
    thirtyDays: {
      loanCount: 0,
      upb: 0,
      outstandingRemittance: 0,
      weightedAverageLTV: 0,
      weightedAverageCoupon: 0,
      judicialCount: 0,
      nonJudicialCount: 0,
    },
    sixtyDays: {
      loanCount: 0,
      upb: 0,
      outstandingRemittance: 0,
      weightedAverageLTV: 0,
      weightedAverageCoupon: 0,
      judicialCount: 0,
      nonJudicialCount: 0,
    },
    ninetyDays: {
      loanCount: 0,
      upb: 0,
      outstandingRemittance: 0,
      weightedAverageLTV: 0,
      weightedAverageCoupon: 0,
      judicialCount: 0,
      nonJudicialCount: 0,
    },
    ninetyPlusDays: {
      loanCount: 0,
      upb: 0,
      outstandingRemittance: 0,
      weightedAverageLTV: 0,
      weightedAverageCoupon: 0,
      judicialCount: 0,
      nonJudicialCount: 0,
    },
    total: {
      loanCount: 0,
      upb: 0,
      outstandingRemittance: 0,
      weightedAverageLTV: 0,
      weightedAverageCoupon: 0,
      judicialCount: 0,
      nonJudicialCount: 0,
    },
  },
};

export const useCreatePoolMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: CreatePoolBody) => await api.createPool(data), {
    onSuccess: (pool) => {
      if (pool) {
        const poolWithSummary: GetPoolResponse = {
          ...pool,
          loanIds: [],
          summary: blankSummary,
        };

        queryClient.setQueryData<GetPoolResponse>(
          [QueryKeys.POOL, String(pool.id)],
          poolWithSummary
        );

        queryClient.setQueryData<GetPoolsResponse | undefined>([QueryKeys.POOLS], (data) => {
          if (data) {
            return {
              ...data,
              data: [poolWithSummary, ...data.data],
            };
          }

          return data;
        });

        queryClient.invalidateQueries(QueryKeys.POOLS_SUMMARY);
      }
    },
  });
};
