import { SubmissionAttributes } from '@plus-platform/shared';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../components/Button';
import { ButtonLoader } from '../components/ButtonLoader';
import { AlertIcon } from '../components/icons';
import { Key, Pair, Value } from '../components/KeyValue';
import { SubmitButtonsWrapper } from '../documents/DocumentStepModal.styles';
import { UploadedDocumentsList } from '../documents/LoanFromDocuments';
import { useInterval } from '../hooks/useInterval';
import { formatDate, formatNumber, formatTime } from '../utils/formatUtils';
import * as Styles from './SubmissionBreakdown.styles';
import {
  getDocumentType,
  getErrorCount,
  GroupedOriginName,
  GroupedValidationErrorsType,
  groupValidationByOcrolusUuid,
} from './utils';

type SubmissionBreakdownProps = {
  submission: SubmissionAttributes;
  groupedErrors: GroupedValidationErrorsType;
  isIngestionComplete: boolean;
  isCreatingLoan: boolean;
  onSetIsCreatingLoan: () => void;
};

// For mock processing message
const PROCESSING_STEP_TIME = 333;
const PROCESSING_INCREMENTS = 10;

export const SubmissionBreakdown = (props: SubmissionBreakdownProps) => {
  const [processingStep, setProcessingStep] = useState<number>(1);
  const [processingIncrement, setProcessingIncrement] = useState<number>(0);
  const { groupedErrors, isCreatingLoan, isIngestionComplete, onSetIsCreatingLoan, submission } =
    props;
  const { filesReceived, validationErrors } = submission;
  const groupedByDocument = groupValidationByOcrolusUuid(groupedErrors);
  const history = useHistory();

  useInterval(
    async () => {
      if (isCreatingLoan) {
        if (processingIncrement < PROCESSING_INCREMENTS) {
          setProcessingIncrement((processingIncrement) => processingIncrement + 1);
        }
        if (processingIncrement === PROCESSING_INCREMENTS && processingStep < 4) {
          setProcessingIncrement(1);
          setProcessingStep((processingStep) => processingStep + 1);
        }
      }
    },
    PROCESSING_STEP_TIME,
    false
  );

  const stepProgress = processingIncrement / PROCESSING_INCREMENTS;

  const delayRedirect = (to: string) => setTimeout(() => history.push(to), 2000);

  useEffect(() => {
    if (processingStep === 4) {
      delayRedirect('/portfolio');
    }
  }, [processingStep]);

  return (
    <Styles.BreakdownWrapper>
      <Styles.KVWrapper>
        <Pair $direction="vertical">
          <Key style={{ textAlign: 'left' }}>Update</Key>
          <Value>
            {isCreatingLoan
              ? 'Creating loan'
              : isIngestionComplete
              ? 'Validation errors found'
              : 'Ingesting documents'}
          </Value>
        </Pair>
        {isCreatingLoan ? (
          <>
            <Pair $direction="vertical">
              <Key style={{ textAlign: 'left' }}>Finalizing validations...</Key>
              <Value>
                <Styles.ProgressWrapper>
                  <Styles.Progress
                    $isComplete={processingStep > 1}
                    $progress={processingStep > 1 ? 1 : stepProgress}
                  />
                </Styles.ProgressWrapper>
              </Value>
            </Pair>
            <Pair $direction="vertical">
              <Key style={{ textAlign: 'left' }}>Preparing transaction log...</Key>
              <Value>
                <Styles.ProgressWrapper>
                  <Styles.Progress
                    $isComplete={processingStep > 2}
                    $progress={processingStep < 2 ? 0 : processingStep > 2 ? 1 : stepProgress}
                  />
                </Styles.ProgressWrapper>
              </Value>
            </Pair>
            <Pair $direction="vertical">
              <Key style={{ textAlign: 'left' }}>Creating Plus loan...</Key>
              <Value>
                <Styles.ProgressWrapper>
                  <Styles.Progress
                    $isComplete={processingStep > 3}
                    $progress={processingStep < 3 ? 0 : processingStep > 3 ? 1 : stepProgress}
                  />
                </Styles.ProgressWrapper>
              </Value>
            </Pair>
          </>
        ) : (
          <>
            <Pair $direction="vertical">
              <Key>Date initiated</Key>
              <Value>{formatDate(new Date(submission.createdAt))}</Value>
            </Pair>
            <Pair $direction="vertical">
              <Key>Time initiated</Key>
              <Value>{formatTime(new Date(submission.createdAt), { timeStyle: 'short' })}</Value>
            </Pair>
            <Pair $direction="vertical">
              <Key>Files submitted</Key>
              <Value>{formatNumber(submission.filesReceived)}</Value>
            </Pair>
            <Pair $direction="vertical">
              <Key>Files ingested</Key>
              <Value>{formatNumber(submission.filesProcessed)}</Value>
            </Pair>
          </>
        )}
      </Styles.KVWrapper>

      {isIngestionComplete ? (
        <Styles.DocumentListWrapper>
          {(submission.documents || []).map((document) => {
            const hasErrors = validationErrors?.some(
              (error) => error.ocrolusUuid === document.ocrolusUuid
            );
            return (
              <Styles.DocumentContainer key={document.id} $hasErrors={hasErrors}>
                <Styles.DocumentTypeHeader>
                  {getDocumentType(document.documentType)}
                  {hasErrors && (
                    <Styles.IconWrapper>
                      <AlertIcon height="10px" />
                    </Styles.IconWrapper>
                  )}
                </Styles.DocumentTypeHeader>
                <Styles.ErrorType>
                  <div>OCR</div>
                  <Styles.ErrorCount>
                    {getErrorCount(groupedByDocument[GroupedOriginName.OCR][document.ocrolusUuid])}
                  </Styles.ErrorCount>
                </Styles.ErrorType>

                <Styles.ErrorType>
                  <div>MISMO 3.4/ULDD/UCD</div>
                  <Styles.ErrorCount>
                    {getErrorCount(groupedByDocument[GroupedOriginName.SMD][document.ocrolusUuid])}
                  </Styles.ErrorCount>
                </Styles.ErrorType>

                <Styles.ErrorType>
                  <div>Plus</div>
                  <Styles.ErrorCount>
                    {getErrorCount(
                      groupedByDocument[GroupedOriginName.CUSTOM][document.ocrolusUuid]
                    )}
                  </Styles.ErrorCount>
                </Styles.ErrorType>
              </Styles.DocumentContainer>
            );
          })}
          <SubmitButtonsWrapper>
            <Button
              $color="tertiary"
              $variant="outlined"
              onClick={() => history.push('/documents')}
            >
              Cancel
            </Button>
            <ButtonLoader
              disabled={!isIngestionComplete}
              isLoading={isCreatingLoan}
              onClick={onSetIsCreatingLoan}
            >
              Continue despite defects
            </ButtonLoader>
          </SubmitButtonsWrapper>
        </Styles.DocumentListWrapper>
      ) : (
        <Styles.DocumentListWrapper>
          <UploadedDocumentsList
            documents={new Array(filesReceived).fill({
              name: 'File',
            })}
            numProcessed={submission.filesProcessed}
          />
        </Styles.DocumentListWrapper>
      )}
    </Styles.BreakdownWrapper>
  );
};
