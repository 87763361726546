import React from 'react';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';

export type HeadingStyleProps = {
  $size?: 'large' | 'medium' | 'small';
  $variant?: 'default' | 'hasBackground';
};

const largeSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium01}
  padding: 0.25em;
`;

const mediumSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium03}
  padding: 0 0.25em;
`;

const smallSizeStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  ${theme.typography.headings.headingMedium04}
  padding: 0 0.25em;
`;

export const Heading = styled.h1<HeadingStyleProps & React.HTMLAttributes<HTMLHeadingElement>>(
  ({ $size = 'large', $variant, theme }) => css`
    display: inline-block;
    text-decoration: none;
    border-radius: 0.25em;
    color: ${theme.colors.fillHeading};
    background-color: ${$variant === 'hasBackground' ? theme.colors.white05 : 'transparent'};

    ${$size === 'large' && largeSizeStyles}
    ${$size === 'medium' && mediumSizeStyles}
    ${$size === 'small' && smallSizeStyles}
  `
);
