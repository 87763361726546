import React from 'react';
import { useVirtual } from 'react-virtual';

const DEFAULT_SCROLL_THRESHOLD_FOR_MORE_ITEMS = 50;

type Options = Parameters<typeof useVirtual>[0] & {
  parentRef: React.RefObject<HTMLElement>;
  onLoadMore?(): void | Promise<void>;
  scrollThreshold?: number;
};

// TODO: Look for off-the-shelf virtual scrolling solution that supports reversed
// virtual lists with dynamic height rows, and infinite scrolling
export const useReverseVirtual = (options: Options) => {
  const virtual = useVirtual(options);
  const { scrollToIndex, scrollToOffset, totalSize } = virtual;
  const { onLoadMore, parentRef, scrollThreshold, size } = options;

  const [isListInitialised, setIsListInitialised] = React.useState(false);

  const bottomOffsetRef = React.useRef(0);

  React.useEffect(() => {
    if (!isListInitialised) {
      scrollToIndex(size - 1, { align: 'end' });
      setIsListInitialised(true);
    }
  }, [scrollToIndex, size, isListInitialised]);

  React.useLayoutEffect(() => {
    scrollToOffset(totalSize - bottomOffsetRef.current);
  }, [scrollToOffset, totalSize]);

  const onContentScroll: React.UIEventHandler = (event) => {
    if (!parentRef.current) {
      return;
    }
    const { scrollTop } = parentRef.current;
    bottomOffsetRef.current = totalSize - scrollTop;
    if (scrollTop < (scrollThreshold ?? DEFAULT_SCROLL_THRESHOLD_FOR_MORE_ITEMS)) {
      onLoadMore?.();
    }
  };

  return { ...virtual, onContentScroll };
};
