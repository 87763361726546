import { CounterpartyWithSummary } from '@plus-platform/shared';

import { AvatarWithFallback } from './AvatarWithFallback';
import * as Styles from './RecipientList.styles';

type RecipientListProps = {
  counterparties: CounterpartyWithSummary[];
  size?: number;
};

export const RecipientList = ({ counterparties, size = 24 }: RecipientListProps) => {
  return (
    <Styles.Recipients>
      {counterparties?.map(({ user: { avatarUrl, firstName, lastName } }, index) => (
        <Styles.Initials key={index} $zIndex={counterparties.length - index}>
          <AvatarWithFallback
            size={size}
            firstName={firstName}
            lastName={lastName}
            src={avatarUrl}
            variant="outline"
          />
        </Styles.Initials>
      ))}
    </Styles.Recipients>
  );
};
