import styled, { css } from 'styled-components/macro';

import { activeMenuItemStyles, MenuItemButton, MenuItemText } from '../../components/Menu.styles';

export const MessengerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Header = styled.header(
  ({ theme }) => css`
    padding: 12px;
    border-bottom: 1px solid ${theme.colors.primary03};
  `
);

export const SidebarHeader = styled.header`
  margin-bottom: 12px;
`;

export const IntroText = styled.p(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyRegular03Tight}
    margin: 0 4px;
    color: ${theme.colors.textPrimary03};

    strong {
      font-weight: ${theme.typography.fontWeight.medium};
    }
  `
);

export const Tabs = styled.ul`
  list-style: none;
  margin: 0 0 0 -12px;
`;

export const Tab = styled.li``;

export const TabButton = styled(MenuItemButton)<{ $isActive: boolean }>(
  ({ $isActive }) => css`
    ${$isActive &&
    // TODO: Fix this - should be primary02. We need an $elevation/$variant prop on MenuItemButton to handle this
    activeMenuItemStyles}
  `
);

export const TabButtonText = styled(MenuItemText)``;
