import React from 'react';

export const OriginationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1111 20V12.9111C10.163 12.9111 9.25185 12.7296 8.37778 12.3667C7.5037 12.0037 6.73333 11.4889 6.06667 10.8222C5.4 10.1556 4.88889 9.38519 4.53333 8.51111C4.17778 7.63704 4 6.72593 4 5.77778V4H5.77778C6.71111 4 7.61481 4.18148 8.48889 4.54444C9.36296 4.90741 10.1333 5.42222 10.8 6.08889C11.2593 6.54815 11.6407 7.05185 11.9444 7.6C12.2481 8.14815 12.4815 8.73333 12.6444 9.35556C12.7185 9.25185 12.8 9.15185 12.8889 9.05556C12.9778 8.95926 13.0741 8.85926 13.1778 8.75556C13.8444 8.08889 14.6148 7.57407 15.4889 7.21111C16.363 6.84815 17.2741 6.66667 18.2222 6.66667H20V8.44444C20 9.39259 19.8185 10.3037 19.4556 11.1778C19.0926 12.0519 18.5778 12.8222 17.9111 13.4889C17.2444 14.1556 16.4778 14.6667 15.6111 15.0222C14.7444 15.3778 13.837 15.5556 12.8889 15.5556V20H11.1111ZM11.1111 11.1111C11.1111 10.4 10.9741 9.72222 10.7 9.07778C10.4259 8.43333 10.037 7.85926 9.53333 7.35556C9.02963 6.85185 8.45556 6.46296 7.81111 6.18889C7.16667 5.91481 6.48889 5.77778 5.77778 5.77778C5.77778 6.48889 5.91111 7.17037 6.17778 7.82222C6.44444 8.47407 6.82963 9.05185 7.33333 9.55556C7.83704 10.0593 8.41481 10.4444 9.06667 10.7111C9.71852 10.9778 10.4 11.1111 11.1111 11.1111ZM12.8889 13.7778C13.6 13.7778 14.2778 13.6444 14.9222 13.3778C15.5667 13.1111 16.1407 12.7259 16.6444 12.2222C17.1481 11.7185 17.537 11.1407 17.8111 10.4889C18.0852 9.83704 18.2222 9.15556 18.2222 8.44444C17.5111 8.44444 16.8296 8.58148 16.1778 8.85556C15.5259 9.12963 14.9481 9.51852 14.4444 10.0222C13.9407 10.5259 13.5556 11.1 13.2889 11.7444C13.0222 12.3889 12.8889 13.0667 12.8889 13.7778Z"
        fill="currentColor"
      />
    </svg>
  );
};
