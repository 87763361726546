import { TradeRequest, TradeRequestMessage } from '@plus-platform/shared';
import { useMutation } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';

type SendTradeRequestMessageMutation = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId: string;
  content: string;
};

const sendTradeRequestMessage = (data: SendTradeRequestMessageMutation) => {
  const { content, tradeCounterpartyId, tradeRequestId } = data;
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/counterparties/${tradeCounterpartyId}/messages`
  );
  const body = { content };

  return postRequestWithAuth<TradeRequestMessage>(url, {
    body: JSON.stringify(body),
  });
};

export const useSendTradeRequestMessageMutation = () => {
  return useMutation(sendTradeRequestMessage);
};
