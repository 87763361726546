import {
  InboundTradeCounterpartyActionWithSummary,
  InboundTradeRequestWithSummary,
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
  TradeQuoteWithSummary,
  TradeRequestTabType,
} from '@plus-platform/shared';

import { TradeRequestCategory } from '../../trades/types';
import { UserProfile } from '../../users/types';

export type MessengerParamProps = {
  tabType?: TradeRequestTabType;
  category: TradeRequestCategory;
  tradeId: string;
  counterpartyId?: string;
};

export enum MessageType {
  DATE_DIVIDER = 'DATE_DIVIDER',
  ACTION = 'ACTION',
  CTA = 'CTA',
}

export type DateMessage = {
  type: MessageType.DATE_DIVIDER;
  data: {
    date: Date;
  };
};

export type OutboundActionMessage = {
  type: MessageType.ACTION;
  data: {
    action: OutboundTradeCounterpartyActionWithSummary;
    tradeRequest: OutboundTradeRequestWithSummary;
  };
};

export type OutboundCTAMessage = {
  type: MessageType.CTA;
  data: {
    action: OutboundTradeCounterpartyActionWithSummary;
    tradeRequest: OutboundTradeRequestWithSummary;
  };
};

export type InboundActionMessage = {
  type: MessageType.ACTION;
  data: {
    action: InboundTradeCounterpartyActionWithSummary;
    tradeRequest: InboundTradeRequestWithSummary;
    userProfile: UserProfile;
  };
};

export type InboundCTAMessage = {
  type: MessageType.CTA;
  data?: {
    action?: InboundTradeCounterpartyActionWithSummary;
    tradeRequest?: InboundTradeRequestWithSummary;
  };
};

export type InboundMessage = DateMessage | InboundActionMessage | InboundCTAMessage;

export type OutboundMessage = DateMessage | OutboundActionMessage | OutboundCTAMessage;

export type QuoteActionMessage = {
  type: MessageType.ACTION;
  data: {
    quote: TradeQuoteWithSummary;
  };
};

export type QuoteCTAMessage =
  | NewQuoteCTAMessage
  | CounterCTAMessage
  | AcceptRejectQuoteCTAMessage
  | CounterAcceptedCTAMessage
  | TradeCompletedCTAMessage;

export enum QuoteCTAMessageType {
  NEW_QUOTE = 'NEW_QUOTE',
  ACCEPT_REJECT = 'ACCEPT_REJECT',
  COUNTER = 'COUNTER',
  COUNTER_ACCEPTED = 'COUNTER_ACCEPTED',
  COMPLETED = 'COMPLETED',
}

export type NewQuoteCTAMessage = {
  type: MessageType.CTA;
  data: {
    kind: QuoteCTAMessageType.NEW_QUOTE;
  };
};

export type AcceptRejectQuoteCTAMessage = {
  type: MessageType.CTA;
  data: {
    kind: QuoteCTAMessageType.ACCEPT_REJECT;
    quote: TradeQuoteWithSummary;
  };
};

export type CounterCTAMessage = {
  type: MessageType.CTA;
  data: {
    kind: QuoteCTAMessageType.COUNTER;
    quote: TradeQuoteWithSummary;
  };
};

export type CounterAcceptedCTAMessage = {
  type: MessageType.CTA;
  data: {
    kind: QuoteCTAMessageType.COUNTER_ACCEPTED;
    quote: TradeQuoteWithSummary;
  };
};

export type TradeCompletedCTAMessage = {
  type: MessageType.CTA;
  data: {
    kind: QuoteCTAMessageType.COMPLETED;
    quote: TradeQuoteWithSummary;
  };
};

export type QuoteMessage = DateMessage | QuoteActionMessage | QuoteCTAMessage;
