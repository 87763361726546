import { Filter, HoldingTankDocument, SubmissionErrorOrigin } from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import React from 'react';
import { VirtualItem } from 'react-virtual';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Card } from '../components/Card';
import { Divider } from '../components/Divider';
import {
  Cell,
  ErrorRow,
  HeaderCell,
  HeaderRow,
  LoadingRow,
  Row,
  Table,
  Tbody,
  Thead,
} from '../components/Table';
import { TableFilterBar } from '../components/TableFilterBar';
import { TableFilterPanel } from '../components/TableFilterPanel';
import { TableFilters } from '../components/TableFilters';
import { TableLayout } from '../components/TableLayout';
import { TableStats } from '../components/TableStats';
import { TableToolbar } from '../components/TableToolbar';
import { VirtualizedTable } from '../components/VirtualizedTable';
import { useGetHoldingTankDocumentsQuery } from '../hooks/queries/useGetHoldingTankDocumentsQuery';
import { formatDate, formatDefaultValue } from '../utils/formatUtils';
import { RowWrapper } from './HoldingTankTable.styles';
import { getDocumentTypeLabel } from './utils';

const TABLE_COLUMN_COUNT = 6;

type RenderRowProps = {
  isLoaderRow: boolean;
  totalCount: number;
  virtualRow: VirtualItem;
  documentData: HoldingTankDocument;
};

const renderRow = ({ documentData, isLoaderRow, totalCount, virtualRow }: RenderRowProps) => {
  const documents = documentData?.documents || [];
  const OCRIssuesCount = documents.filter(
    (document) => document.origin === SubmissionErrorOrigin.OCR
  ).length;
  const SMDIssuesCount = documents.filter(
    (document) =>
      document.origin === SubmissionErrorOrigin.MISMO ||
      document.origin === SubmissionErrorOrigin.ULDD
  ).length;
  const plusIssuesCount = documents.filter(
    (document) => document.origin === SubmissionErrorOrigin.CUSTOM
  ).length;

  return (
    <RowWrapper
      height={virtualRow.size}
      offsetY={virtualRow.start}
      data-testid="HoldingTankTable_Row"
    >
      {isLoaderRow && (
        <LoadingRow>
          {virtualRow.index < totalCount ? 'Loading more...' : 'Nothing more to load'}
        </LoadingRow>
      )}

      {!isLoaderRow && !documentData && <ErrorRow>Error loading row</ErrorRow>}

      {!isLoaderRow && documentData && (
        <Row $columnCount={TABLE_COLUMN_COUNT}>
          <Cell>{formatDefaultValue(documentData.submissionNumber)}</Cell>
          <Cell>{formatDate(new Date(documentData.submissionDate))}</Cell>
          <Cell>{formatDefaultValue(getDocumentTypeLabel(documentData.fileType))}</Cell>
          <Cell>{formatDefaultValue(OCRIssuesCount)}</Cell>
          <Cell>{formatDefaultValue(SMDIssuesCount)}</Cell>
          <Cell>{formatDefaultValue(plusIssuesCount)}</Cell>
        </Row>
      )}
    </RowWrapper>
  );
};

type HoldingTankTableProps = {
  maxVisibleItems: number;
};

export const HoldingTankTable = ({ maxVisibleItems }: HoldingTankTableProps) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  // TODO: implement filtering once we know by which to filter
  const filters: Filter[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const updateFilters = () => {};

  const {
    data: holdingTankData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetHoldingTankDocumentsQuery(
    filters.map((filter) => filter.id),
    maxVisibleItems
  );

  const pages = compact(holdingTankData?.pages || []);
  const documents = flatMap(pages, 'data') as HoldingTankDocument[];
  const totalDocumentsCount = get(pages, '0.totalDocumentsCount', 0);
  const totalFilteredDocumentsCount = get(pages, '0.totalFilteredDocumentsCount', 0);

  return (
    <>
      {totalFilteredDocumentsCount === 0 ? (
        <div>Holding tank is empty</div>
      ) : (
        <TableFilters filters={filters} onFiltersUpdate={updateFilters} path="/documents">
          {({ filterPopupPath, isFiltersActive }) => (
            <TableLayout value={{ defaults: { isLeftPanelOpen: isFiltersActive } }}>
              {({ closeLeftPanel, isLeftPanelOpen, openLeftPanel }) => (
                <>
                  {isLeftPanelOpen && (
                    <TableFilterPanel
                      filters={filters}
                      filtersPopupPath={filterPopupPath}
                      onClose={closeLeftPanel}
                      onFiltersUpdate={updateFilters}
                    />
                  )}
                  <Card $hasFlex>
                    <TableToolbar>
                      <TableStats
                        label="documents"
                        totalCount={totalDocumentsCount}
                        visibleCount={documents.length}
                      />
                      <Divider $orientation="vertical" $flexItem />
                      <TableFilterBar
                        isDisabled={true}
                        filters={filters}
                        onFilterSettingsClick={isLeftPanelOpen ? closeLeftPanel : openLeftPanel}
                        onFiltersUpdate={updateFilters}
                      />
                    </TableToolbar>
                    <ActivityIndicator contain isActive={isLoading}>
                      <Table>
                        <Thead>
                          <HeaderRow $columnCount={TABLE_COLUMN_COUNT}>
                            <HeaderCell>Submission ID</HeaderCell>
                            <HeaderCell>Date of submission</HeaderCell>
                            <HeaderCell>Type of file</HeaderCell>
                            <HeaderCell>OCR issues</HeaderCell>
                            <HeaderCell>MISMO 3.4/ULDD/UCD issues</HeaderCell>
                            <HeaderCell>Plus issues</HeaderCell>
                          </HeaderRow>
                        </Thead>
                        <Tbody>
                          <VirtualizedTable<HoldingTankDocument>
                            fetchNextPage={fetchNextPage}
                            isFetchingNextPage={isFetchingNextPage}
                            items={documents}
                            maxVisibleItems={maxVisibleItems}
                            parentRef={parentRef}
                            renderRow={(isLoaderRow, virtualRow, documentData) => {
                              if (!documentData) {
                                return null;
                              }

                              return renderRow({
                                isLoaderRow,
                                totalCount: totalFilteredDocumentsCount,
                                virtualRow,
                                documentData,
                              });
                            }}
                            totalItemsCount={totalFilteredDocumentsCount}
                          />
                        </Tbody>
                      </Table>
                    </ActivityIndicator>
                  </Card>
                </>
              )}
            </TableLayout>
          )}
        </TableFilters>
      )}
    </>
  );
};
