import { Loan } from '@plus-platform/shared';
import React from 'react';
import styled from 'styled-components/macro';

import { Loader } from '../components/Loader';
import { MapWrapper } from '../components/Map';
import { LoanNearbyPropertiesData } from './LoanData';
import { ExtraDataEnum } from './types';

const NearbyPropertiesMap = React.lazy(() => import('./NearbyPropertiesMap'));

const ContentWrapper = styled.div`
  position: relative;
  flex: 1;

  .google-maps {
    height: 100%;
    width: 100%;
  }
`;

type LoanSummaryMapProps = {
  extraDataToDisplay?: ExtraDataEnum;
  loanSummary: Loan;
  mapHeight?: string;
  onSidebarClose: () => void;
  onDetailsButtonClick: (data?: ExtraDataEnum) => void;
  onMLSButtonClick: (data?: ExtraDataEnum) => void;
};

export const LoanSummaryMap = ({
  extraDataToDisplay,
  loanSummary,
  onDetailsButtonClick,
  onMLSButtonClick,
  onSidebarClose,
}: LoanSummaryMapProps) => {
  return (
    <MapWrapper>
      <ContentWrapper>
        <React.Suspense fallback={<Loader />}>
          <LoanNearbyPropertiesData>
            <NearbyPropertiesMap
              loanSummary={loanSummary}
              extraDataToDisplay={extraDataToDisplay}
              onDetailsButtonClick={onDetailsButtonClick}
              onMLSButtonClick={onMLSButtonClick}
              onSidebarClose={onSidebarClose}
            />
          </LoanNearbyPropertiesData>
        </React.Suspense>
      </ContentWrapper>
    </MapWrapper>
  );
};
