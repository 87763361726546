import { white10, white70 } from './darkTheme/colors';

const sansSerif =
  "font-family: 'Yantramanav', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'Arial', sans-serif";
const fontSize = 6;
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  fill: white70,
  stroke: white70,
  strokeWidth: 0.1,
};

export const plusChartTheme = {
  area: {
    style: {
      data: { stroke: white10 },
    },
  },
  axis: {
    style: {
      axis: {
        stroke: white10,
      },
      axisLabel: {
        ...baseLabelStyles,
        padding: 25,
      },
      grid: {
        stroke: white10,
      },
      tick: {
        stroke: white10,
      },
      tickLabels: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
  },
  dependentAxis: {
    style: {
      axis: {
        stroke: white10,
        fill: white10,
      },
      axisLabel: {
        ...baseLabelStyles,
        padding: 25,
      },
      grid: {
        stroke: white10,
        strokeOpacity: 0.6,
        pointerEvents: 'painted',
      },
      tick: {
        stroke: white10,
        fill: white10,
      },
      tickLabels: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
  },
  brush: {
    style: {
      stroke: white10,
      fillOpacity: 0.1,
    },
  },
};
