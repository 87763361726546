import { Loan } from '@plus-platform/shared';

import { CheckCircleIcon } from '../../components/icons';
import * as Styles from './SubmissionHeader.styles';
import { SUBMISSION_STEP_LABELS_MAPPING, SubmissionStep } from './submissionUtils';

type Props = {
  activeStep: SubmissionStep;
  submissionNumber?: string;
  completePercent?: number;
  loanId?: Loan['id'];
};

export const SubmissionHeader = ({ activeStep, completePercent, submissionNumber }: Props) => {
  return (
    <Styles.HeaderWrapper>
      {submissionNumber ? `Submission ${submissionNumber}` : 'Create new loan'}
      <Styles.Steps>
        {Object.entries(SUBMISSION_STEP_LABELS_MAPPING).map(([submissionStep, label], index) => {
          const isActive = activeStep === (submissionStep as SubmissionStep);
          const shouldAnimate = activeStep === SubmissionStep.INGEST && isActive;
          const isComplete =
            Object.keys(SUBMISSION_STEP_LABELS_MAPPING).indexOf(activeStep) > index;
          const stepLabel =
            submissionStep === SubmissionStep.CREATE && submissionNumber !== undefined
              ? 'Create'
              : label;

          return (
            <Styles.StepWrapper key={submissionStep} $shouldAnimate={shouldAnimate}>
              <Styles.StepProgress
                $shouldAnimate={shouldAnimate}
                $completePercent={completePercent}
              >
                <Styles.Step
                  $shouldAnimate={shouldAnimate}
                  $isActive={isActive}
                  $isComplete={isComplete}
                >
                  {isComplete ? <CheckCircleIcon /> : <Styles.Circle>{index + 1}</Styles.Circle>}
                  <Styles.StepDetails>{stepLabel}</Styles.StepDetails>
                </Styles.Step>
              </Styles.StepProgress>
            </Styles.StepWrapper>
          );
        })}
      </Styles.Steps>
    </Styles.HeaderWrapper>
  );
};
