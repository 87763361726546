import { DocumentMetadata } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from '.';

type TradeRequestDataLoanDocumentsQuery = {
  tradeRequestId: string;
  loanId: string;
  searchQuery?: string;
};

const getTradeRequestDataLoanDocuments = ({
  loanId,
  searchQuery,
  tradeRequestId,
}: TradeRequestDataLoanDocumentsQuery) => {
  const query = new URLSearchParams({
    search: searchQuery ?? '',
  });
  const url = makeApiUrl(
    `trade-requests/${tradeRequestId}/data/loans/${loanId}/documents${
      searchQuery ? `?${query}` : ''
    }`
  );
  return getRequestWithAuth<DocumentMetadata[]>(url);
};

export const useTradeRequestDataLoanDocumentsQuery = (query: TradeRequestDataLoanDocumentsQuery) =>
  useQuery([QueryKeys.TRADE_REQUEST_DATA_LOAN_DOCUMENTS, query], () =>
    getTradeRequestDataLoanDocuments(query)
  );
