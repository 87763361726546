import React from 'react';

export const FinancialsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.28 17.6H12.68V16.6C13.3467 16.48 13.92 16.22 14.4 15.82C14.88 15.42 15.12 14.8267 15.12 14.04C15.12 13.48 14.96 12.9667 14.64 12.5C14.32 12.0333 13.68 11.6267 12.72 11.28C11.92 11.0133 11.3667 10.78 11.06 10.58C10.7533 10.38 10.6 10.1067 10.6 9.76C10.6 9.41333 10.7233 9.14 10.97 8.94C11.2167 8.74 11.5733 8.64 12.04 8.64C12.4667 8.64 12.8 8.74333 13.04 8.95C13.28 9.15667 13.4533 9.41333 13.56 9.72L14.84 9.2C14.6933 8.73333 14.4233 8.32667 14.03 7.98C13.6367 7.63333 13.2 7.44 12.72 7.4V6.4H11.32V7.4C10.6533 7.54667 10.1333 7.84 9.76 8.28C9.38667 8.72 9.2 9.21333 9.2 9.76C9.2 10.3867 9.38333 10.8933 9.75 11.28C10.1167 11.6667 10.6933 12 11.48 12.28C12.32 12.5867 12.9033 12.86 13.23 13.1C13.5567 13.34 13.72 13.6533 13.72 14.04C13.72 14.48 13.5633 14.8033 13.25 15.01C12.9367 15.2167 12.56 15.32 12.12 15.32C11.68 15.32 11.29 15.1833 10.95 14.91C10.61 14.6367 10.36 14.2267 10.2 13.68L8.88 14.2C9.06667 14.84 9.35667 15.3567 9.75 15.75C10.1433 16.1433 10.6533 16.4133 11.28 16.56V17.6ZM12 20C10.8933 20 9.85333 19.79 8.88 19.37C7.90667 18.95 7.06 18.38 6.34 17.66C5.62 16.94 5.05 16.0933 4.63 15.12C4.21 14.1467 4 13.1067 4 12C4 10.8933 4.21 9.85333 4.63 8.88C5.05 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.05 8.88 4.63C9.85333 4.21 10.8933 4 12 4C13.1067 4 14.1467 4.21 15.12 4.63C16.0933 5.05 16.94 5.62 17.66 6.34C18.38 7.06 18.95 7.90667 19.37 8.88C19.79 9.85333 20 10.8933 20 12C20 13.1067 19.79 14.1467 19.37 15.12C18.95 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.95 15.12 19.37C14.1467 19.79 13.1067 20 12 20ZM12 18.4C13.7867 18.4 15.3 17.78 16.54 16.54C17.78 15.3 18.4 13.7867 18.4 12C18.4 10.2133 17.78 8.7 16.54 7.46C15.3 6.22 13.7867 5.6 12 5.6C10.2133 5.6 8.7 6.22 7.46 7.46C6.22 8.7 5.6 10.2133 5.6 12C5.6 13.7867 6.22 15.3 7.46 16.54C8.7 17.78 10.2133 18.4 12 18.4Z"
        fill="currentColor"
      />
    </svg>
  );
};
