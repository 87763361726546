import { LoanDetails } from '@plus-platform/shared';
import { useMutation, useQueryClient } from 'react-query';

import { makeApiUrl, postRequestWithAuth } from '../../utils/apiUtils';
import { QueryKeys } from './types';

type AddLoanNoteProps = {
  loanNumber: LoanDetails['loanNumber'];
  content: string;
};

const addLoanNote = ({ content, loanNumber }: AddLoanNoteProps) => {
  const url = makeApiUrl(`loans/${loanNumber}/notes`);

  return postRequestWithAuth(url, {
    body: JSON.stringify({ content }),
  });
};

export const useAddLoanNoteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addLoanNote, {
    onSuccess: (_, { loanNumber }) => {
      queryClient.invalidateQueries([QueryKeys.LOAN_NOTES, loanNumber]);
    },
  });
};
