import { MISMOEntity, MISMOString } from './types';

enum PropertyInspectionType {
  ExteriorAndInterior = 'ExteriorAndInterior',
  ExteriorOnly = 'ExteriorOnly',
  None = 'None',
  Other = 'Other',
}

enum PropertyValuationServiceType {
  LimitedSummaryReport = 'LimitedSummaryReport',
  NonUSPAPAutomatedValuationModel = 'NonUSPAPAutomatedValuationModel',
  NonUSPAPPriceOpinion = 'NonUSPAPPriceOpinion',
  Other = 'Other',
  RestrictedUseReport = 'RestrictedUseReport',
  SelfContainedReport = 'SelfContainedReport',
}

export enum ValuationAssignmentType {
  EmployeeRelocation = 'EmployeeRelocation',
  Foreclosure = 'Foreclosure',
  JuniorLien = 'JuniorLien',
  LossMitigation = 'LossMitigation',
  Other = 'Other',
  PortfolioEvaluation = 'PortfolioEvaluation',
  Purchase = 'Purchase',
  QualityControl = 'QualityControl',
  Refinance = 'Refinance',
  REO = 'REO',
  Review = 'Review',
  ShortSale = 'ShortSale',
  Validation = 'Validation',
}

export type ScopeOfWork = MISMOEntity &
  Partial<{
    propertyInspectionRequestCommentDescription: MISMOString;
    propertyInspectionResultCommentDescription: MISMOString;
    propertyInspectionType: PropertyInspectionType;
    propertyInspectionTypeOtherDescription: MISMOString;
    propertyValuationServiceType: PropertyValuationServiceType;
    propertyValuationServiceTypeOtherDescription: MISMOString;
    USPAPReportDescription: MISMOString;
    valuationAssignmentType: ValuationAssignmentType;
    valuationAssignmentTypeOtherDescription: MISMOString;
    valuationScopeOfWorkDescription: MISMOString;
    //   encumbrances: Encumbrance[];
    //   realPropertyInterests: RealPropertyInterest[];
    //   valuationIntendedUsers: ValuationIntendedUser[];
    //   valuationIntendedUses: ValuationIntendedUse[];
    //   valueDefinitions: ValueDefinition[];
  }>;
