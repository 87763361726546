import React from 'react';

export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.30969 18.6903H6.27012L15.94 9.02046L14.9795 8.06002L5.30969 17.7299V18.6903ZM18.7121 8.08185L15.9181 5.28785L16.8349 4.37107C17.0823 4.12369 17.3879 4 17.7517 4C18.1155 4 18.4211 4.12369 18.6685 4.37107L19.6289 5.33151C19.8763 5.5789 20 5.88449 20 6.24829C20 6.61209 19.8763 6.91769 19.6289 7.16507L18.7121 8.08185ZM17.7954 8.99863L6.794 20H4V17.206L15.0014 6.20463L17.7954 8.99863ZM15.4598 8.54024L14.9795 8.06002L15.94 9.02046L15.4598 8.54024Z"
        fill="currentColor"
      />
    </svg>
  );
};
