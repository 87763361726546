import { MISMOEntity, MISMOString } from './types';

export enum ProjectedPaymentEscrowedType {
  Escrowed = 'Escrowed',
  NotEscrowed = 'NotEscrowed',
  SomeEscrowed = 'SomeEscrowed',
}

export enum ProjectedPaymentEstimatedTaxesInsuranceAssessmentComponentType {
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  GroundRent = 'GroundRent',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeownersInsurance = 'HomeownersInsurance',
  LeaseholdPayment = 'LeaseholdPayment',
  Other = 'Other',
  PropertyTaxes = 'PropertyTaxes',
}

export type EstimatedPropertyCostComponent = MISMOEntity &
  Partial<{
    projectedPaymentEscrowedType: ProjectedPaymentEscrowedType;
    projectedPaymentEstimatedTaxesInsuranceAssessmentComponentType: ProjectedPaymentEstimatedTaxesInsuranceAssessmentComponentType;
    projectedPaymentEstimatedTaxesInsuranceAssessmentComponentTypeOtherDescription: MISMOString;
  }>;
