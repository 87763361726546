import { PortfolioBreakdown } from '@plus-platform/shared';
import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useTheme } from 'styled-components/macro';

import { LoanMap } from '../../components/LoanMap';
import { Skeleton } from '../../components/Skeleton';
import { TabItem, Tabs } from '../../components/Tabs';
import { BreakdownTileWrapper, RatesTable } from './BreakdownTile';
import { formatMarketRates, mapBreakdownForLoansCountByState } from './breakdownUtils';
import * as Styles from './DetailedDataMetrics.styles';

enum TabNames {
  MARKET_RATE = 'MARKET_RATE',
  LOAN_STATES = 'LOAN_STATES',
}

type DetailedDataMetricsProps = {
  portfolioBreakdown?: PortfolioBreakdown;
};

export const DetailedDataMetrics = (props: DetailedDataMetricsProps) => {
  const { portfolioBreakdown } = props;
  const [activeTab, setActiveTab] = React.useState<TabNames>(TabNames.MARKET_RATE);
  const theme = useTheme();

  const loansCountByState = mapBreakdownForLoansCountByState(portfolioBreakdown);
  const marketRatesData = formatMarketRates(portfolioBreakdown?.marketRates);

  return (
    <Styles.Container>
      <BreakdownTileWrapper>
        <Container fluid>
          <Row>
            <Col col={12}>
              <Styles.TabsHeader>
                <Tabs>
                  <TabItem
                    $isActive={activeTab === TabNames.MARKET_RATE}
                    onClick={() => setActiveTab(TabNames.MARKET_RATE)}
                  >
                    Market rates
                  </TabItem>
                  <TabItem
                    $isActive={activeTab === TabNames.LOAN_STATES}
                    onClick={() => setActiveTab(TabNames.LOAN_STATES)}
                    data-testid="Breakdown_States_Tab"
                  >
                    Loans by state
                  </TabItem>
                </Tabs>
              </Styles.TabsHeader>
            </Col>
            {activeTab === TabNames.MARKET_RATE && (
              <>
                {marketRatesData.length === 0 ? (
                  <Skeleton height={200} />
                ) : (
                  <Col col={12}>
                    <RatesTable data={marketRatesData} />
                  </Col>
                )}
              </>
            )}

            {activeTab === TabNames.LOAN_STATES && (
              <>
                {!loansCountByState ? (
                  <Skeleton height={200} />
                ) : (
                  <Col col={12}>
                    <LoanMap
                      loans={loansCountByState}
                      getMapDefaultColor={(threshold) =>
                        threshold > 0 ? theme.colors.white20 : theme.colors.white50
                      }
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </BreakdownTileWrapper>
    </Styles.Container>
  );
};
