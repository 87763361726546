import { useQuery } from 'react-query';

import * as api from '../../api';
import { QueryKeys } from './types';

export const usePortfolioLoanTypesByNotRateDetailsQuery = (
  ...args: Parameters<typeof api.getPortfolioLoanTypesByNoteRateDetails>
) =>
  useQuery([QueryKeys.PORTFOLIO_LOAN_TYPES_BY_NOTE_RATE_DETAILS, ...args], () =>
    api.getPortfolioLoanTypesByNoteRateDetails(...args)
  );
