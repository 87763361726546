import { DocumentCategoryType, Loan } from '@plus-platform/shared';
import React, { useState } from 'react';

import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Card } from '../../components/Card';
import { useZipCodeDataQuery } from '../../hooks/queries';
import { useFixedBroadbandQuery } from '../../hooks/queries/useFixedBroadbandQuery';
import { useLoanWidgetsRefs } from '../../hooks/useLoanWidgetsRefs';
import { useLoanContext } from '../context';
import { LoanAmortizationData } from '../LoanData';
import { LoanSummaryMap } from '../LoanSummaryMap';
import { ExtraDataEnum } from '../types';
import * as Styles from './LoanDetail.styles';
import LoanDetailAcquisitionHistory from './sections/LoanDetailAcquisitionHistory';
import LoanDetailAmortization from './sections/LoanDetailAmortization';
import LoanDetailAssetLedger, { AssetType } from './sections/LoanDetailAssetLedger';
import LoanDetailBorrowers from './sections/LoanDetailBorrowers';
import LoanDetailCustodial from './sections/LoanDetailCustodial';
import LoanDetailDocuments from './sections/LoanDetailDocuments';
import LoanDetailsFinancials from './sections/LoanDetailFinancials';
import LoanDetailInsurance from './sections/LoanDetailInsurance';
import LoanDetailNotes from './sections/LoanDetailNotes';
import LoanDetailOrigination from './sections/LoanDetailOrigination';
import LoanDetailProperty from './sections/LoanDetailProperty';
import LoanDetailServicer from './sections/LoanDetailServicer';

type LoanDetailWidgetsProps = {
  refs: Record<string, React.MutableRefObject<HTMLDivElement | null>>;
  isChatActive?: boolean;
  loan: Loan;
  onChatClick?: () => void;
};

const LoanDetailWidgets = ({
  isChatActive = false,
  loan,
  onChatClick,
  refs,
}: LoanDetailWidgetsProps) => {
  const [documentCategory, setDocumentCategory] = React.useState<DocumentCategoryType>(
    DocumentCategoryType.ORIGINATION_DOCUMENT
  );
  const [isSearchBarDisplayed, setIsSearchBarDisplayed] = useState<boolean>(true);
  const [documentSearchQuery, setDocumentSearchQuery] = React.useState<string>('');
  const [assetType, setAssetType] = React.useState(AssetType.ALL);
  const { isAnonymised } = useLoanContext();

  // TODO: Anonymise request data
  const { data: fixedBroadbandData, isLoading: isFixedBroadbandLoading } = useFixedBroadbandQuery(
    loan.loanProperty.property.geolocationLatitude,
    loan.loanProperty.property.geolocationLongitude
  );

  const { data: zipCodeData } = useZipCodeDataQuery(loan.loanProperty.property.zipCode);

  return (
    <Styles.LoanDetailWrapper>
      <LoanAmortizationData>
        <LoanDetailsFinancials loan={loan} ref={refs.financials} />
        <LoanDetailProperty
          loan={loan}
          fixedBroadbandData={fixedBroadbandData}
          isFixedBroadbandLoading={isFixedBroadbandLoading}
          zipCodeData={zipCodeData}
          ref={refs.property}
        />
        <LoanDetailOrigination
          loan={loan}
          setDocumentCategory={setDocumentCategory}
          refs={refs}
          ref={refs.origination}
        />
        <LoanDetailCustodial loan={loan} ref={refs.custodial} />

        <LoanDetailBorrowers loan={loan} ref={refs.borrower} />
        <LoanDetailServicer
          loan={loan}
          setDocumentCategory={setDocumentCategory}
          isAnonymised={isAnonymised}
          isChatActive={isChatActive}
          onChatClick={onChatClick}
          ref={refs.servicer}
          refs={refs}
        />
        {!isAnonymised && (
          <>
            <LoanDetailInsurance ref={refs.insurance} loan={loan} />
            <LoanDetailAcquisitionHistory loan={loan} ref={refs['acquisition-history']} />
          </>
        )}
        <LoanDetailDocuments
          ref={refs['document-library']}
          documentSearchQuery={documentSearchQuery}
          setDocumentSearchQuery={setDocumentSearchQuery}
          documentCategory={documentCategory}
          setDocumentCategory={setDocumentCategory}
          isSearchBarDisplayed={isSearchBarDisplayed}
          setIsSearchBarDisplayed={setIsSearchBarDisplayed}
        />
        <LoanDetailAssetLedger
          ref={refs['asset-ledger']}
          loan={loan}
          assetType={assetType}
          setAssetType={setAssetType}
        />
        <LoanDetailAmortization ref={refs.amortization} />
        {!isAnonymised && <LoanDetailNotes loan={loan} ref={refs.notes} />}
      </LoanAmortizationData>
    </Styles.LoanDetailWrapper>
  );
};

type LoanDetailProps = {
  isLoanLoading: boolean;
  isChatActive?: boolean;
  onChatClick?: () => void;
  loan?: Loan;
};

export const LoanDetail = ({ isChatActive, isLoanLoading, loan, onChatClick }: LoanDetailProps) => {
  const refs = useLoanWidgetsRefs(loan);

  return (
    <ActivityIndicator isActive={isLoanLoading}>
      {loan && (
        <LoanDetailWidgets
          isChatActive={isChatActive}
          loan={loan}
          onChatClick={onChatClick}
          refs={refs}
        />
      )}
    </ActivityIndicator>
  );
};

type LoanDetailMapProps = LoanDetailProps & {
  extraDataToDisplay?: ExtraDataEnum;
  onSidebarClose: () => void;
  onDetailsButtonClick: (data?: ExtraDataEnum) => void;
  onMLSButtonClick: (data?: ExtraDataEnum) => void;
};

export const LoanDetailMap = ({
  extraDataToDisplay,
  isChatActive,
  isLoanLoading,
  loan,
  onChatClick,
  onDetailsButtonClick,
  onMLSButtonClick,
  onSidebarClose,
}: LoanDetailMapProps) => {
  const refs = useLoanWidgetsRefs(loan);

  return (
    <ActivityIndicator isActive={isLoanLoading}>
      {loan && (
        <React.Fragment>
          <Styles.MapWrapper style={{ display: 'flex', flexDirection: 'column', minHeight: 500 }}>
            <Card $hasFlex id="map" ref={refs.map}>
              <LoanSummaryMap
                extraDataToDisplay={extraDataToDisplay}
                loanSummary={loan}
                onDetailsButtonClick={onDetailsButtonClick}
                onMLSButtonClick={onMLSButtonClick}
                onSidebarClose={onSidebarClose}
              />
            </Card>
          </Styles.MapWrapper>
          <LoanDetailWidgets
            isChatActive={isChatActive}
            loan={loan}
            onChatClick={onChatClick}
            refs={refs}
          />
        </React.Fragment>
      )}
    </ActivityIndicator>
  );
};
