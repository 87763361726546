import { ThemeProvider } from 'styled-components';

import { darkTheme, lightTheme } from '../styles/theme';
import { Header } from './Header/Header';

type PageProps = {
  children: React.ReactNode;
};

export const LightPage = ({ children }: PageProps) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Header />
      {children}
    </ThemeProvider>
  );
};

export const DarkPage = ({ children }: PageProps) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Header />
      {children}
    </ThemeProvider>
  );
};
