import { ALL_LOANS_ATTRIBUTES, PermissionType } from '@plus-platform/shared';

import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader } from '../components/Page';
import { LoanFromDocuments } from '../documents/LoanFromDocuments';
import { PrivateRoute } from '../global/PrivateRoute';
import { usePortfolioSummaryQuery } from '../hooks/queries';
import { LoanPortfolioSummary } from '../loans/LoanSummary';
import { Portfolio } from './Portfolio';

export const PortfolioPage = () => {
  const { data: portfolioSummary, isLoading: isPortfolioLoading } = usePortfolioSummaryQuery();

  return (
    <Page>
      <PrivateRoute
        component={LoanFromDocuments}
        path={`/portfolio/create-single-loan`}
        requiredPermissions={{
          [PermissionType.PERMISSION_CREATE]: ALL_LOANS_ATTRIBUTES,
        }}
      />
      <PageHeader>
        <Heading>Portfolio</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem title="Portfolio" />
        </Breadcrumb>
        <LoanPortfolioSummary summary={portfolioSummary} />
      </PageHeader>
      <PageContent>
        <Portfolio isPortfolioLoading={isPortfolioLoading} />
      </PageContent>
    </Page>
  );
};
