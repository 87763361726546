import React from 'react';
import styled, { css } from 'styled-components/macro';

import { CloseCircleIcon } from './icons';

type LabelProps = React.HTMLAttributes<HTMLSpanElement> & {
  $isSmall: boolean;
  $variant?: 'default' | 'withAction';
};

export type InputWrapperProps = React.HTMLAttributes<HTMLLabelElement> & {
  $width?: string;
  $isInvalid?: boolean;
  $variant?: 'default' | 'withAction';
};

const dirtyLabelCss = css`
  transform: scale(0.75) translateY(-0.4em);
  transform-origin: top left;
`;

const withActionsLabelCss = css`
  transform: translateY(0.4em);
`;

export const Label = styled.span<LabelProps>(
  ({ $isSmall, $variant, theme }) => css`
    ${theme.typography.bodyText.bodyRegular02}

    color: ${theme.colors.inputLabelText};
    display: inline-block;
    background: transparent;
    position: absolute;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    margin-bottom: 0.5em;
    margin-left: 0.2em;

    ${$variant === 'withAction' && withActionsLabelCss}
    ${$isSmall && dirtyLabelCss}
  `
);

export const Input = styled.input<React.InputHTMLAttributes<HTMLInputElement>>(
  ({ theme }) => css`
    ${theme.typography.bodyText.bodyMedium02Tight}

    color: inherit;
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    caret-color: inherit;
    line-height: 1.4em;

    /* Hide numbers on input type=number */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: textfield;
    }

    &::selection {
      background: ${theme.colors.accent01};
      color: ${theme.colors.textSecondary01};
    }
  `
);

const withActionVariantStyles = css`
  padding: 10px 12px 8px 12px;
  height: 48px;
`;

export const InputWrapper = styled.label<InputWrapperProps>(
  ({ $isInvalid, $variant, $width, theme }) => css`
    position: relative;
    color: ${theme.colors.inputText};
    display: inline-flex;
    background: ${theme.colors.inputBackground};
    border: 1px solid transparent;
    border-radius: 0.25em;
    padding: 0 0.5em 0 0.3em;
    height: 2.5em;
    align-items: flex-end;
    min-width: 250px;
    width: ${$width || 'auto'};
    transition: background 0.2s ease-out, border 0.2s ease-out;
    caret-color: ${$isInvalid ? theme.colors.inputCaretInvalid : theme.colors.inputCaret};

    ${$variant === 'withAction' && withActionVariantStyles}

    &:hover {
      border-bottom-color: ${$isInvalid
        ? theme.colors.inputBorderInvalidHover
        : theme.colors.inputBorderHover};
      background: ${theme.colors.inputBackgroundHover};
    }

    &:active {
      border-color: ${$isInvalid
        ? theme.colors.inputBorderInvalidActive
        : theme.colors.inputBorderActive};
      background: ${theme.colors.inputBackgroundActive};
    }

    &:focus-within {
      border-color: ${$isInvalid
        ? theme.colors.inputBorderInvalidActive
        : theme.colors.inputBorderActive};

      ${Label} {
        ${dirtyLabelCss};
      }
    }
  `
);

export const CloseButton = styled(CloseCircleIcon)(
  ({ theme }) => css`
    align-self: center;
    color: ${theme.colors.primary07};
  `
);

export const ActionButtonWrapper = styled.div(
  ({ theme }) => css`
    align-self: center;
    color: ${theme.colors.primary07};
  `
);
