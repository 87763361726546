import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
  StipulationType,
} from '@plus-platform/shared';

import { MessageText, UserMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { StipulationPreview } from '../../StipulationPreview';

type OutboundExpressionOfInterestRequestedProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
  onStipulationToggle?: () => void;
};

export const OutboundExpressionOfInterestRequested = (
  props: OutboundExpressionOfInterestRequestedProps
) => {
  const { action, onStipulationToggle, tradeRequest } = props;
  const { createdAt, fromCounterparty, stipulation } = action;

  return (
    <UserMessage
      date={createdAt}
      organizationName={fromCounterparty.user.organization.tradingName}
      firstName={fromCounterparty.user.firstName}
      lastName={fromCounterparty.user.lastName}
      userAvatar={fromCounterparty.user.avatarUrl}
    >
      <MessageText>
        <TradeMessage
          id="Trade.Outbound.ExpressionOfInterest.Requested"
          values={{ poolName: tradeRequest.pool.name }}
        />
      </MessageText>
      {stipulation?.stipulation && (
        <StipulationPreview
          stipulationFileOriginalName={stipulation.stipulation.fileOriginalName}
          stipulationId={stipulation.stipulation.id}
          stipulationType={StipulationType.STIPULATION_ACTION}
          onStipulationToggle={onStipulationToggle}
        />
      )}
    </UserMessage>
  );
};
