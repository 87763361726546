import { LoanAmortizationData } from '@plus-platform/shared';
import React from 'react';

import { Key, Pair, Value } from '../../components/KeyValue';
import { formatDate, formatDefaultValue } from '../../utils/formatUtils';

type AmortizationInformationProps = Pick<
  LoanAmortizationData,
  | 'refinanceProjection'
  | 'nextPaymentNumber'
  | 'termMonths'
  | 'numbersOfPaymentsBehind'
  | 'asOfDate'
>;

export const AmortizationInformation = ({
  asOfDate,
  nextPaymentNumber,
  numbersOfPaymentsBehind,
  refinanceProjection,
  termMonths,
}: AmortizationInformationProps) => (
  <React.Fragment>
    <Pair $size="large">
      <Key>Refinance Projection</Key>
      <Value>{formatDefaultValue(refinanceProjection)}</Value>
    </Pair>
    <Pair $size="large">
      <Key>Number of Months</Key>
      <Value>
        {nextPaymentNumber} of {termMonths}
      </Value>
    </Pair>
    <Pair $size="large">
      <Key>Payments Behind</Key>
      <Value>{formatDefaultValue(numbersOfPaymentsBehind)}</Value>
    </Pair>
    <Pair $size="large">
      <Key>As of</Key>
      <Value>{formatDate(new Date(asOfDate))}</Value>
    </Pair>
  </React.Fragment>
);
