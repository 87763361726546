import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
} from '@plus-platform/shared';

import { MessageText, PlusMessage } from '../../../../components/Messages';
import { TradeMessage } from '../../../TradeMessage';
import { formatNameWithInitial } from '../../../tradeRequestsUtils';

type OutboundExpressionOfInterestDeclinedProps = {
  action: OutboundTradeCounterpartyActionWithSummary;
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundExpressionOfInterestDeclined = ({
  action,
}: OutboundExpressionOfInterestDeclinedProps) => {
  const { createdAt, reason, toCounterparty } = action;

  const userName = formatNameWithInitial(
    toCounterparty.user.lastName,
    toCounterparty.user.firstName
  );
  const organizationName = toCounterparty.user.organization.tradingName;

  return (
    <PlusMessage date={createdAt}>
      <MessageText>
        {reason ? (
          <TradeMessage
            id="Trade.Outbound.ExpressionOfInterest.DeclinedWithReason"
            values={{ userName, organizationName, reason }}
          />
        ) : (
          <TradeMessage
            id="Trade.Outbound.ExpressionOfInterest.DeclinedWithoutReason"
            values={{ userName, organizationName }}
          />
        )}
      </MessageText>
    </PlusMessage>
  );
};
