import { DelinquencySummary, DelinquencyType } from './delinquency';
import { Identifier } from './global';
import { ADCOAnalysisStatus } from './loans';

export enum PortfolioAttributeType {
  ATTRIBUTE_PORTFOLIO_ANALYTICS = 'ATTRIBUTE_PORTFOLIO_ANALYTICS',
  ATTRIBUTE_PORTFOLIO_ANALYSES = 'ATTRIBUTE_PORTFOLIO_ANALYSES',
}

export type PortfolioSummary = {
  loansRemittanceAmount: number;
  loansUPBAmount: number;
  loansCount: number;
  loansInDefault: DelinquencySummary;
  loansWeightedAverageLTV: number;
  loansWeightedAverageCoupon: number;
  judicialCount: number;
  nonJudicialCount: number;
};

export enum PortfolioAnalysisStatus {
  QUEUED = 'queued',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export type PortfolioAnalysisCreation = {
  organizationId: Identifier;
  adcoAnalysisId: number;
  cashFlowType: string;
  cprCc?: number;
  marketValue?: number;
  currentBalance?: number;
  price?: number;
  wal?: number;
  walCc?: number;
  walFc?: number;
  yieldCc?: number;
  yieldFc?: number;
  vega?: number;
  ltvSens?: number;
  oas?: number;
  oasDur?: number;
  cumLoss?: number;
  expShortfall?: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PortfolioAnalysis = PortfolioAnalysisCreation & {
  id: Identifier;
};

export enum CalculationType {
  AVERAGE = 'AVERAGE',
  MEDIAN = 'MEDIAN',
}

export enum VisualisationType {
  BUBBLE_CHART = 'BUBBLE_CHART',
  BAR_CHART = 'BAR_CHART',
  CHLOROPLETH = 'CHLOROPLETH',
}

export enum PortfolioAnalyticMetric {
  DELINQUENCY_RATE = 'DELINQUENCY_RATE',
  REPOSSESSIONS_COUNT = 'REPOSSESSIONS_COUNT',
  DEFAULTS_COUNT = 'DEFAULTS_COUNT',
  REFINANCE_COUNT = 'REFINANCE_COUNT',
  WAC = 'WAC',
  WTD_AVG_LTV = 'WTD_AVG_LTV',
  UPB = 'UPB',
  FICO_SCORE = 'FICO_SCORE',
  STATE = 'STATE',
  PURCHASE_PRICE = 'PURCHASE_PRICE',
}

export type PortfolioAnalyticsCreation = {
  organizationId: Identifier;
  metric: PortfolioAnalyticMetric;
  metricBy: PortfolioAnalyticMetric;
  visualisationType: VisualisationType;
  metricCalculationType?: CalculationType;
};

export type PortfolioAnalytics = PortfolioAnalyticsCreation & {
  id: Identifier;
};

export type PortfolioAnalyticsGroupedValue = {
  rangeFrom: number;
  rangeTo: number;
  metricValue: number;
  metricByValue: number;
};

export type PortfolioAnalyticsResult = {
  id: Identifier;
  metric: PortfolioAnalyticMetric;
  metricBy: PortfolioAnalyticMetric;
  visualisationType: VisualisationType;
  metricCalculationType?: CalculationType;
  groupedValues: PortfolioAnalyticsGroupedValue[];
  flagsCount: number;
  alertsCount: number;
  warningsCount: number;
};

export type PortfolioAnalyticsResults = {
  loansCount: number;
  results: PortfolioAnalyticsResult[];
  flagsCount: number;
  alertsCount: number;
  warningsCount: number;
};

export type PortfolioMetrics = {
  cashFlowType: string;
  cprCc?: number;
  currentBalance?: number;
  marketValue?: number;
  price?: number;
  wal?: number;
  walCc?: number;
  walFc?: number;
  yieldCc?: number;
  yieldFc?: number;
  vega?: number;
  ltvSens?: number;
  oas?: number;
  oasDur?: number;
  cumLoss?: number;
  expShortfall?: number;
};

export type PortfolioStatus = ADCOAnalysisStatus;

export enum PortfolioLoanRateType {
  FIXEDRATE30YR = 'fixedRate30YR',
  FIXEDRATE15YR = 'fixedRate15YR',
  FIXEDFHA30YR = 'fixedFHA30YR',
  FIXEDVA30YR = 'fixedVA30YR',
  JUMBO30YR = 'jumbo30YR',
  ARM5YR = 'arm5YR',
}

export enum PortfolioBreakdownRateType {
  ALL = 'all',
  FIXEDRATE30YR = 'fixedRate30YR',
  FIXEDRATE15YR = 'fixedRate15YR',
  FIXEDFHA30YR = 'fixedFHA30YR',
  FIXEDVA30YR = 'fixedVA30YR',
  JUMBO30YR = 'jumbo30YR',
  ARM5YR = 'arm5YR',
}

export enum PortfolioBreakdownType {
  ALL = 'all',
}

export type PortfolioBreakdownDelinquencyType = PortfolioBreakdownType | DelinquencyType;

export type PortfolioLoanRates = {
  noteRate: number;
  date: Date;
};

export type PortfolioBreakdown = {
  totalLoansCount: number;
  marketRates: Record<string, PortfolioLoanRates[]>;
  loansCountsByMarketRates: Record<
    string,
    {
      rateType: string;
      loanCount: number;
    }
  >;
  loanRatesCountsByMarketRates: Record<
    string,
    {
      rateType: string;
      marketRateGreaterOrEqualToNoteRateLoanCount: number;
      marketRateLessThanNoteRateLoanCount: number;
      loanCount: number;
    }
  >;
  loansCountByState: { [key: string]: number };
  loansCountByNoteRate: Record<
    string,
    {
      currentMarketRate: number;
      countsByNoteRate: Record<string, number>;
    }
  >;
  delinquentLoansCountByNoteRate: Partial<
    Record<PortfolioBreakdownDelinquencyType, Record<string, Record<string, number>>>
  >;
};

export type PortfolioBreakdownRate = {
  id: Identifier;
  effectiveDate: Date;
  rate: number;
};

export type PortfolioAnalyticData = {
  value: string;
  grouped_by_value: string;
  loans_count: string;
};

export type PortfolioAnalytic = {
  id: Identifier;
  metric: PortfolioAnalyticMetric;
  metricBy: PortfolioAnalyticMetric;
  metricCalculationType: CalculationType;
  visualisationType: VisualisationType;
};

export enum PortfolioHomeAnalyticGroupType {
  RECOMMENDED = 'RECOMMENDED',
}

export enum RecommendedAnalyticType {
  BRILLIANT = 'BRILLIANT',
  CARTOPHILE = 'CARTOPHILE',
  AWESOME = 'AWESOME',
}

export type PortfolioHomeAnalyticPresetType = RecommendedAnalyticType;

export enum PortfolioHomeAnalyticType {
  ONE_METRIC = 'ONE_METRIC',
  TWO_METRICS = 'TWO_METRICS',
}

export type AnalyticOneMetricConfig = {
  metric: PortfolioAnalyticMetric;
  visualisationType: VisualisationType;
};

export type AnalyticTwoMetricsConfig = {
  metric: PortfolioAnalyticMetric;
  metricBy: PortfolioAnalyticMetric;
  visualisationType: VisualisationType;
  metricCalculationType?: CalculationType;
};

export type PortfolioHomeAnalyticTemplate =
  | {
      type: PortfolioHomeAnalyticType.ONE_METRIC;
      config: AnalyticOneMetricConfig;
    }
  | {
      type: PortfolioHomeAnalyticType.TWO_METRICS;
      config: AnalyticTwoMetricsConfig;
    };

export type PortfolioHomeAnalytic = {
  id: Identifier;
  organizationId: Identifier;
  analyticGroupType: PortfolioHomeAnalyticGroupType;
  analyticPresetType: PortfolioHomeAnalyticPresetType;
  copiedAnalyticId?: Identifier;
} & PortfolioHomeAnalyticTemplate;

export type PortfolioAnalyticsSettings = {
  [PortfolioHomeAnalyticGroupType.RECOMMENDED]: RecommendedAnalyticType[];
};

export enum BreakdownType {
  LOAN_COUNT = 'LOAN_COUNT',
  LOANS_IN_DEFAULT = 'LOANS_IN_DEFAULT',
  LOAN_TYPES = 'LOAN_TYPES',
  LOAN_TYPES_BY_NOTE_RATE = 'LOAN_TYPES_BY_NOTE_RATE',
  DEFAULT_30_DAY = 'DEFAULT_30_DAY',
  DEFAULT_60_DAY = 'DEFAULT_60_DAY',
  DEFAULT_90_DAY = 'DEFAULT_90_DAY',
  VALUATION_METRICS = 'VALUATION_METRICS',
  RISK_METRICS = 'RISK_METRICS',
  CREDIT_METRICS = 'CREDIT_METRICS',
  INTEREST_RATE_SENSITIVITY = 'INTEREST_RATE_SENSITIVITY',
}

export type PortfolioSettings = {
  breakdown: BreakdownType[];
  analytics: PortfolioAnalyticsSettings;
};
