import React from 'react';

type Size = {
  width?: number;
  height?: number;
};

export const useWindowSize = (onResize: () => void) => {
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      onResize();
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [onResize]);

  return windowSize;
};
