import React from 'react';

import * as Styles from './PaneHeader.styles';

type PaneHeaderProps = {
  children: React.ReactNode;
  highlight?: string | number;
  highlightColor?: string;
  onClick?: () => void;
  size?: 'medium' | 'small';
};

export const PaneHeader = ({
  children,
  highlight,
  highlightColor,
  onClick,
  size = 'medium',
}: PaneHeaderProps) => (
  <Styles.Heading $size={size} as="h3">
    <span>{children}</span>{' '}
    {highlight && (
      <Styles.SummaryCount
        $highlightColor={highlightColor}
        onClick={onClick}
        $isClickable={Boolean(onClick)}
        $size={size}
      >
        {highlight}
      </Styles.SummaryCount>
    )}
  </Styles.Heading>
);
