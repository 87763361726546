import {
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIndicator,
  MISMOString,
} from '../types';

enum TitleProcessInsuranceType {
  Insurance = 'Insurance',
  LimitedInsurance = 'LimitedInsurance',
  NonInsurance = 'NonInsurance',
}

enum TitleProcessType {
  DeedReport = 'DeedReport',
  Other = 'Other',
  TitleSearch = 'TitleSearch',
}

export type PropertyTitle = MISMOEntity &
  Partial<{
    clearAndMarketablePropertyTitleIndicator: MISMOIndicator;
    propertyTitleTransferInspectionRequiredDescription: MISMOString;
    propertyTitleTransferInspectionRequiredIndicator: MISMOIndicator;
    sellerPropertyAcquisitionDate: MISMODate;
    titleHolderCount: MISMOCount;
    titlePreliminaryReportDate: MISMODate;
    titleProcessInsuranceType: TitleProcessInsuranceType;
    titleProcessType: TitleProcessType;
    titleProcessTypeOtherDescription: MISMOString;
    titleReportItemsDescription: MISMOString;
    titleReportRequiredEndorsementsDescription: MISMOString;
    titleTransferredPastSixMonthsIndicator: MISMOIndicator;
  }>;
