import {
  OutboundTradeCounterpartyActionWithSummary,
  OutboundTradeRequestWithSummary,
  TradeCounterpartyActionHistoryFilter,
  TradeCounterpartyActionType,
} from '@plus-platform/shared';
import React from 'react';

import { ActivityIndicator } from '../../../components/ActivityIndicator';
import { BodyText } from '../../../components/BodyText';
import { Heading } from '../../../components/Heading';
import { DateDivider } from '../../../components/Messages/DateDivider';
import {
  isMessageDate,
  shouldDisplayDateDivider,
} from '../../../components/Messages/messengerUtils';
import {
  DateMessage,
  MessageType,
  OutboundActionMessage,
  OutboundCTAMessage,
  OutboundMessage,
} from '../../../components/Messages/types';
import { useTradeRequestOutboundActionsHistory } from '../../useTradeRequestOutboundActionsHistory';
import * as Styles from '../Messenger.styles';
import { MessengerList } from '../MessengerList';
import { OutboundExpressionOfInterestAcceptDecline } from './Messages/OutboundExpressionOfInterestAcceptDecline';
import { OutboundExpressionOfInterestAccepted } from './Messages/OutboundExpressionOfInterestAccepted';
import { OutboundExpressionOfInterestDeclined } from './Messages/OutboundExpressionOfInterestDeclined';
import { OutboundExpressionOfInterestRequested } from './Messages/OutboundExpressionOfInterestRequested';

const isPendingRequest = (actions: OutboundTradeCounterpartyActionWithSummary[], index: number) => {
  const isSettled = actions
    .slice(index)
    .find(
      (action) =>
        [
          TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST,
          TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST,
        ].includes(action.type) &&
        action.fromCounterparty?.id === actions[index]?.fromCounterparty?.id
    );

  return !isSettled;
};

type OutboundTradeRequestExpressionsOfInterestProps = {
  tradeRequest: OutboundTradeRequestWithSummary;
};

export const OutboundTradeRequestExpressionsOfInterest = (
  props: OutboundTradeRequestExpressionsOfInterestProps
) => {
  const { tradeRequest } = props;

  const { actions, fetchMoreActions, isLoading } = useTradeRequestOutboundActionsHistory(
    String(tradeRequest.id),
    TradeCounterpartyActionHistoryFilter.EXPRESSED_INTEREST
  );

  const messageList = React.useMemo(() => {
    return actions.flatMap((action, index) => {
      const previousAction = actions[index - 1];

      let messages: OutboundMessage[] = [];

      if (
        action.createdAt &&
        shouldDisplayDateDivider(action.createdAt, previousAction?.createdAt)
      ) {
        const message: DateMessage = {
          type: MessageType.DATE_DIVIDER,
          data: { date: new Date(action.createdAt) },
        };
        messages = [...messages, message];
      }

      if (
        [
          TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST,
          TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST,
          TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST,
        ].includes(action.type)
      ) {
        const message: OutboundActionMessage = {
          type: MessageType.ACTION,
          data: { action, tradeRequest },
        };
        messages = [...messages, message];
      }

      if (
        action.type === TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST &&
        isPendingRequest(actions, index)
      ) {
        const message: OutboundCTAMessage = {
          type: MessageType.CTA,
          data: { action, tradeRequest },
        };
        messages = [...messages, message];
      }

      return messages;
    });
  }, [actions, tradeRequest]);

  return (
    <ActivityIndicator contain isActive={isLoading}>
      <Styles.Header>
        <Heading $size="medium">Expressions of interest</Heading>
        <Styles.IntroText>
          This is the very beginning of Expressions of interest on trading pool{' '}
          <strong>{tradeRequest.pool.name}</strong> ticket ID <strong>{tradeRequest.id}</strong>.
        </Styles.IntroText>
      </Styles.Header>
      <MessengerList<OutboundMessage> loadMore={fetchMoreActions} messages={messageList}>
        {({ index, measure, message }) => (
          <React.Fragment>
            {isMessageDate(message) && (
              <DateDivider
                key={`${(message as DateMessage).data.date}`}
                variant="outline"
                {...(message as DateMessage).data}
              />
            )}
            {message.type === MessageType.CTA && index === messageList.length - 1 && (
              <React.Fragment>
                {message.data.action.type ===
                  TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST && (
                  <OutboundExpressionOfInterestAcceptDecline
                    onAccept={measure}
                    onDecline={measure}
                    {...message.data}
                  />
                )}
              </React.Fragment>
            )}
            {message.type === MessageType.ACTION && (
              <React.Fragment>
                {message.data.action.type ===
                  TradeCounterpartyActionType.REQUEST_EXPRESSION_OF_INTEREST && (
                  <OutboundExpressionOfInterestRequested
                    onStipulationToggle={measure}
                    {...message.data}
                  />
                )}
                {message.data.action.type ===
                  TradeCounterpartyActionType.ACCEPT_EXPRESSION_OF_INTEREST && (
                  <OutboundExpressionOfInterestAccepted {...message.data} />
                )}
                {message.data.action.type ===
                  TradeCounterpartyActionType.DECLINE_EXPRESSION_OF_INTEREST && (
                  <OutboundExpressionOfInterestDeclined {...message.data} />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </MessengerList>
    </ActivityIndicator>
  );
};
