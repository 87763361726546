import styled, { css, DefaultTheme, ThemeProps } from 'styled-components/macro';

import { InternalLink } from './Link';

export const MenuBase = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.primary02};
    border: 1px solid ${theme.colors.primary03};
    box-shadow: ${theme.shadows.modal};
  `
);

type menuItemStyles = ThemeProps<DefaultTheme>;

const disabledStyles = () => css`
  cursor: default;
  pointer-events: none;
  opacity: 0.2;
`;

export const activeMenuItemStyles = ({ theme }: menuItemStyles) => css`
  background: ${theme.colors.primary03};
`;

export const hoverMenuItemStyles = ({ theme }: menuItemStyles) => css`
  background: ${theme.colors.primary03};
`;

const menuItemBase = ({ theme }: menuItemStyles) => css`
  ${theme.typography.bodyText.bodyRegular02}

  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 8px;
  border: 0;
  outline: 0;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  color: ${theme.colors.textPrimary01};
  width: 100%;
  padding: 8px 8px 8px 12px;

  &:hover {
    ${hoverMenuItemStyles}
  }

  &:active {
    ${activeMenuItemStyles}
  }
`;

export const Menu = styled.div`
  display: inline-block;
`;

export const MenuHeader = styled.header<{ $direction?: 'left' | 'right' }>(
  ({ $direction = 'left', theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    width: 100%;
    padding: 8px 8px 8px 12px;

    ${$direction === 'right' &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}

    svg {
      color: ${theme.colors.accent01};
    }
  `
);

export const MenuTitle = styled.h1(
  ({ theme }) => css`
    ${theme.typography.headings.headingMedium03};
    color: ${theme.colors.textPrimary01};
  `
);

export const MenuSection = styled.ul(
  ({ theme }) => css`
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.primary03};
    }
  `
);

type SubMenuProps = {
  $direction?: 'left' | 'right';
};

export const SubMenu = styled(MenuBase)<SubMenuProps>(
  ({ $direction = 'right' }) => css`
    display: none;
    position: absolute;
    top: 0;
    min-width: 150px;
    border-radius: 4px;

    ${$direction === 'right' &&
    css`
      left: 100%;
    `}

    ${$direction === 'left' &&
    css`
      right: 100%;
    `}
  `
);

export const MenuItemButton = styled.button`
  ${menuItemBase}

  &:disabled {
    ${disabledStyles};
  }
`;

export const MenuListItem = styled.li`
  list-style: none;
`;

export const MenuItemDiv = styled.div<{ $isDisabled?: boolean; $hasSubmenu?: boolean }>(
  ({ $hasSubmenu, $isDisabled }) => css`
    ${menuItemBase}

    ${$hasSubmenu &&
    css`
      cursor: default;

      &:hover {
        > ${SubMenu} {
          display: block;
        }
      }
    `}

    ${$isDisabled &&
    css`
      ${disabledStyles};
    `}
  `
);

export const MenuItemLink = styled(InternalLink)`
  ${menuItemBase}
`;

export const MenuItemText = styled.span`
  text-align: left;
`;
