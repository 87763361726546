import { transparentize } from 'polished';
import styled, { css } from 'styled-components/macro';

import { NudeButton } from '../components/Button';

export const FormContainer = styled.form``;

export const DocumentsListWrapper = styled.ol`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  gap: 12px;
`;

export const DocumentContainer = styled.li<{ $isMuted?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.textSecondary01};
  ${(props) => props.theme.typography.headings.headingMedium04};
  position: relative;
  overflow: hidden;
  border-radius: 2px;

  ${(props) =>
    props.$isMuted &&
    css`
      opacity: 0.5;
    `}
`;

export const FileTypeImage = styled.div`
  width: 32px;
  height: 32px;
  background: ${(props) => transparentize(0.9, props.theme.colors.statusRed)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  svg {
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.colors.statusRed};
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const UploadDescription = styled.div`
  ${(props) => props.theme.typography.headings.headingMedium03};
  color: ${(props) => props.theme.colors.textPrimary01};
`;

export const UploadDescriptionText = styled.div`
  ${(props) => props.theme.typography.bodyText.bodyRegular03Tight};
  color: ${(props) => props.theme.colors.textPrimary02};
`;

export const MultipleUploadContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 0;
  gap: 12px;
`;

export const MultipleFileUploadButton = styled(NudeButton)<{ $canSubmit?: boolean }>`
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.accent01};

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 7px 13px;

  ${(props) => props.theme.typography.headings.headingMedium04};

  svg {
    display: block;
    margin: 7px 0 13px;
  }
  ${(props) =>
    props.$canSubmit
      ? css`
          color: ${(props) => props.theme.colors.accent01};
          background: ${(props) => props.theme.colors.primary02};
        `
      : css`
          color: ${(props) => props.theme.colors.primary02};
          background: ${(props) => props.theme.colors.accent01};
        `}
`;

export const Container = styled.div`
  border-radius: 0 0 6px 6px;
  padding: 20px;
  background: ${(props) => props.theme.colors.purpleDark};
`;

export const FooterWrapperSection = styled.div`
  background: ${(props) => props.theme.colors.purpleDark};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${(props) => transparentize(0.9, props.theme.colors.white)};
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProcessingWrapper = styled.div`
  padding: 6px 14px 8px;
`;

export const ProcessingMessage = styled.div<{ $isComplete?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;

  ${(props) =>
    props.$isComplete &&
    css`
      color: ${props.theme.colors.textPrimary03};
      ${props.theme.typography.bodyText.bodyRegular04};

      span {
        color: ${props.theme.colors.statusGreen};
        ${props.theme.typography.bodyText.bodyRegular03Tight};
      }
    `}
`;

export const ProcessingStep = styled.div<{ $isActive?: boolean; $isComplete?: boolean }>`
  padding: 8px 0;
  display: flex;
  align-items: center;
  ${(props) => props.theme.typography.bodyText.bodyRegular03};
  color: ${(props) => props.theme.colors.textPrimary01};

  ${(props) =>
    !props.$isActive &&
    !props.$isComplete &&
    css`
      color: ${props.theme.colors.textPrimary03};
      ${ProcessingMessage} {
        padding-left: 16px;
      }
    `}
  ${(props) =>
    props.$isComplete &&
    css`
      svg {
        height: 16px;
        width: 16px;
        background: ${props.theme.colors.statusGreen};
        border-radius: 50%;
        padding: 3px;
      }
    `}
`;

export const RequiredMessage = styled.div`
  ${(props) => props.theme.typography.headings.headingMedium03};
  color: ${(props) => props.theme.colors.textPrimary01};
  padding: 16px;
  margin-right: 16px;
  background: ${(props) => props.theme.colors.white05};
  border: 1px solid ${(props) => props.theme.colors.white10};
  border-radius: 4px;
`;

export const RequiredMessageText = styled.div`
  ${(props) => props.theme.typography.bodyText.bodyRegular03Tight};
  color: ${(props) => props.theme.colors.textPrimary02};
  margin-bottom: 8px;
`;

export const FileTypeShortName = styled.div`
  ${(props) => props.theme.typography.bodyText.bodyRegular02};
  display: inline-block;
  background: ${(props) => props.theme.colors.textPrimary01};
  color: ${(props) => props.theme.colors.textSecondary01};
  border: 1px solid ${(props) => props.theme.colors.primary09};
  border-radius: 2px;
  padding: 8px 0;
  width: 52px;
  height: 72px;
  text-align: center;
  line-height: 16px;
`;
