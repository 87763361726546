import { FixedBroadbandData, Loan, ZipCodeData } from '@plus-platform/shared';
import React, { useMemo } from 'react';

import { Card } from '../../../components/Card';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Key, Pair, TitleKey, Value } from '../../../components/KeyValue';
import { FullWidthLoaderWrapper, Loader } from '../../../components/Loader';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import {
  EMPTY_VALUE,
  formatBoolean,
  formatDefaultValue,
  formatMonetary,
  formatPercentage,
  formatSurface,
} from '../../../utils/formatUtils';
import {
  formatForeclosureMethodType,
  formatListOfEnumValues,
  formatNeighborhoodBuiltUpRangeType,
  formatNeighborhoodDemandSupplyType,
  formatNeighborhoodGrowthPaceType,
  formatNeighborhoodLocationType,
  formatNeighborhoodMarketingMonthsDurationType,
  formatNeighborhoodTrendType,
  formatPropertyAttachmentType,
  formatPropertyEstateType,
  formatPropertyOccupancyType,
  formatPropertyUsageType,
} from '../../../utils/loanUtils';
import { LoanNearbyPropertiesData, LoanPropertyScoresData } from '../../LoanData';
import { ZIP_CODE_DEMOGRAPHIC_KEY_TO_LABEL } from '../../loansUtils';
import ScoresCharts from '../../ScoresCharts';
import { LoanDetailAdditionalSection, LoanDetailSectionLoaderWrapper } from '../LoanDetail.styles';
import LoanDetailPhotoLibrary from './LoanDetailPhotoLibrary';
import * as Styles from './LoanDetailProperty.styles';

type LoanPropertyWidgetProps = {
  loan: Loan;
  fixedBroadbandData?: FixedBroadbandData;
  isFixedBroadbandLoading: boolean;
  zipCodeData?: ZipCodeData | null;
};

export const LoanDetailProperty = React.forwardRef<HTMLDivElement, LoanPropertyWidgetProps>(
  ({ fixedBroadbandData, isFixedBroadbandLoading, loan, zipCodeData }, ref) => {
    const zipCodeDemographics = useMemo(
      () => Object.entries(zipCodeData?.demographics || {}).sort((a, b) => b[1] - a[1]),
      [zipCodeData]
    );

    return (
      <Card $hasPadding id="property" ref={ref} data-testid="LoanDetail_Property">
        <Heading $size="medium">Property</Heading>
        <div>
          <WidgetSectionWrapper>
            <WidgetSection>
              <LoanDetailAdditionalSection>
                <TitleKey>General</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Foreclosure</Key>
                  <Value>
                    {formatForeclosureMethodType(loan.loanProperty.property.foreclosureMethodType)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Usage</Key>
                  <Value>{formatPropertyUsageType(loan.loanDetails.reasonForLoan)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Occupancy Type</Key>
                  <Value>
                    {formatPropertyOccupancyType(loan.loanProperty.property.propertyOccupancyType)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Property Type</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.propertyType)}</Value>
                </Pair>

                <Pair $size="large" $align="right">
                  <Key>Property Rights Appraised</Key>
                  <Value>
                    {formatPropertyEstateType(loan.loanProperty.property.propertyEstateType)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Year Built</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.yearBuilt)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>County</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.county)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Street Address</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.addressLine1)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>City State</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.city)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Zip Code</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.zipCode)}</Value>
                </Pair>
              </LoanDetailAdditionalSection>

              <LoanDetailAdditionalSection>
                <TitleKey>Foundation</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Foundation Type</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.foundationType)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Sump Pump</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasSumpPump)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Basement Area Sqf</Key>
                  <Value>{formatSurface(loan.loanProperty.property.basementArea)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Basement Finished %</Key>
                  <Value>
                    {formatPercentage(loan.loanProperty.property.basementFinishedSpace)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Outside Entry/Exit</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.isBasementOutsideEntry)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Evidence of Infestation</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasInfestation)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Evidence of Damp</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasDamp)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Evidence of Settlement</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasSettlement)}</Value>
                </Pair>
              </LoanDetailAdditionalSection>
            </WidgetSection>

            <Divider $flexItem $orientation="vertical" />

            <WidgetSection>
              <LoanDetailAdditionalSection>
                <TitleKey>Units Characteristics</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Unit(s)</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.numberOfUnits)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Type of Unit</Key>
                  <Value>
                    {formatPropertyAttachmentType(loan.loanProperty.property.attachmentType)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Square Footage</Key>
                  <Value>{formatSurface(loan.loanProperty.property.totalLivingArea)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Number of Stories</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.numberOfStories)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Bedrooms</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.numberOfBedrooms)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Bathrooms</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.numberOfBathrooms)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Attic</Key>
                  <Value>{formatListOfEnumValues(loan.loanProperty.property.atticFeatures)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Heating</Key>
                  <Value>{formatListOfEnumValues(loan.loanProperty.property.heatingTypes)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Cooling</Key>
                  <Value>{formatListOfEnumValues(loan.loanProperty.property.coolingTypes)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Amenities</Key>
                  <Value>{formatListOfEnumValues(loan.loanProperty.property.amenities)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Floors</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.floorType)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Walls</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.wallType)}</Value>
                </Pair>
              </LoanDetailAdditionalSection>

              <LoanDetailAdditionalSection>
                <TitleKey>Interior condition</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Floors</Key>
                  <Value>
                    {formatDefaultValue(loan.loanProperty.property.interiorFloorsDescription)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Walls</Key>
                  <Value>
                    {formatDefaultValue(loan.loanProperty.property.interiorWallsDescription)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Trim Finish</Key>
                  <Value>
                    {formatDefaultValue(loan.loanProperty.property.trimFinishDescription)}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Bath Floor</Key>
                  <Value>
                    {formatDefaultValue(loan.loanProperty.property.bathFloorDescription)}
                  </Value>
                </Pair>
              </LoanDetailAdditionalSection>

              <LoanDetailAdditionalSection>
                <TitleKey>Exterior & Condition</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Foundation Walls</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.foundationWallType)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Exterior Walls</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.exteriorWallType)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Roof Surface</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.roofSurfaceType)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Gutters & Downspouts</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasGuttersAndDownspouts)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Driveway</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasDriveway)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Space for Cars</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.spaceForCars)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Driveway Surface</Key>
                  <Value>{formatDefaultValue(loan.loanProperty.property.drivewaySurface)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Garage</Key>
                  <Value>{formatBoolean(loan.loanProperty.property.hasGarage)}</Value>
                </Pair>
              </LoanDetailAdditionalSection>
            </WidgetSection>

            <Divider $flexItem $orientation="vertical" />
            <WidgetSection>
              <Styles.TransportWrapper>
                <TitleKey>Transport</TitleKey>
                <React.Suspense
                  fallback={
                    <FullWidthLoaderWrapper>
                      <Loader />
                    </FullWidthLoaderWrapper>
                  }
                >
                  <LoanPropertyScoresData>
                    <ScoresCharts />
                  </LoanPropertyScoresData>
                </React.Suspense>
              </Styles.TransportWrapper>

              <LoanDetailAdditionalSection>
                <TitleKey>Schools</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Great School Rating</Key>
                  <Value>{EMPTY_VALUE}</Value>
                </Pair>
              </LoanDetailAdditionalSection>

              <LoanDetailAdditionalSection>
                <TitleKey>Broadband availability</TitleKey>
                {isFixedBroadbandLoading ? (
                  <LoanDetailSectionLoaderWrapper>
                    <FullWidthLoaderWrapper>
                      <Loader />
                    </FullWidthLoaderWrapper>
                  </LoanDetailSectionLoaderWrapper>
                ) : (
                  <React.Fragment>
                    <Pair $size="large" $align="right">
                      <Key>Max Download Speed</Key>
                      <Value>
                        {formatDefaultValue(
                          fixedBroadbandData?.maxDownloadSpeed &&
                            `${fixedBroadbandData.maxDownloadSpeed} Mb/s`
                        )}
                      </Value>
                    </Pair>
                    <Pair $size="large" $align="right">
                      <Key>Min Download Speed</Key>
                      <Value>
                        {formatDefaultValue(
                          fixedBroadbandData?.minDownloadSpeed &&
                            `${fixedBroadbandData.minDownloadSpeed} Mb/s`
                        )}
                      </Value>
                    </Pair>
                    <Pair $size="large" $align="right">
                      <Key>Max Upload Speed</Key>
                      <Value>
                        {formatDefaultValue(
                          fixedBroadbandData?.maxUploadSpeed &&
                            `${fixedBroadbandData.maxUploadSpeed} Mb/s`
                        )}
                      </Value>
                    </Pair>
                    <Pair $size="large" $align="right">
                      <Key>Min Upload Speed</Key>
                      <Value>
                        {formatDefaultValue(
                          fixedBroadbandData?.minUploadSpeed &&
                            `${fixedBroadbandData.minUploadSpeed} Mb/s`
                        )}
                      </Value>
                    </Pair>
                    <Pair $size="large" $align="right">
                      <Key>No. Providers</Key>
                      <Value>{formatDefaultValue(fixedBroadbandData?.providers?.length)}</Value>
                    </Pair>
                  </React.Fragment>
                )}
              </LoanDetailAdditionalSection>

              <LoanDetailAdditionalSection>
                <TitleKey>Census data</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>For Zip code {loan.loanProperty.property.zipCode}</Key>
                  <Value> </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Median Household Income</Key>
                  <Value>{formatMonetary(zipCodeData?.medianIncome)}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Percent Below Poverty Level</Key>
                  <Value>{formatPercentage(zipCodeData?.povertyLevel)}</Value>
                </Pair>
              </LoanDetailAdditionalSection>

              <LoanDetailAdditionalSection>
                <TitleKey>Demographic breakdown</TitleKey>
                {zipCodeDemographics.map(([key, value]) => (
                  <Pair key={key} $size="large" $align="right">
                    <Key>{ZIP_CODE_DEMOGRAPHIC_KEY_TO_LABEL[key]}</Key>
                    <Value>{formatPercentage(value)}</Value>
                  </Pair>
                ))}
              </LoanDetailAdditionalSection>
            </WidgetSection>
            <Divider $flexItem $orientation="vertical" />
            <WidgetSection>
              <Styles.AdditionalSection>
                <TitleKey>Neighborhood</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Location</Key>
                  <Value>
                    {formatNeighborhoodLocationType(
                      loan.loanProperty?.property?.neighborhoodLocation
                    )}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Built-up</Key>
                  <Value>
                    {formatNeighborhoodBuiltUpRangeType(
                      loan.loanProperty?.property?.neighborhoodBuiltUp
                    )}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Growth</Key>
                  <Value>
                    {formatNeighborhoodGrowthPaceType(
                      loan.loanProperty?.property?.neighborhoodGrowth
                    )}
                  </Value>
                </Pair>
              </Styles.AdditionalSection>

              <Styles.AdditionalSection>
                <TitleKey>Housing Trends</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Property Values</Key>
                  <Value>
                    {formatNeighborhoodTrendType(
                      loan.loanProperty?.property?.neighborhoodTrendType
                    )}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Demand/Supply</Key>
                  <Value>
                    {formatNeighborhoodDemandSupplyType(
                      loan.loanProperty?.property?.neighborhoodDemandSupplyType
                    )}
                  </Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Marketing Time</Key>
                  <Value>
                    {formatNeighborhoodMarketingMonthsDurationType(
                      loan.loanProperty?.property?.neighborhoodMarketingMonthsDurationType
                    )}
                  </Value>
                </Pair>
              </Styles.AdditionalSection>

              <Styles.AdditionalSection>
                <TitleKey>Environmental & Energy</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Climate Zone</Key>
                  <Value>{EMPTY_VALUE}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Ancillary Energy Sources</Key>
                  <Value>{EMPTY_VALUE}</Value>
                </Pair>
                <Pair $size="large" $align="right">
                  <Key>Energy Performance Scores</Key>
                  <Value>{EMPTY_VALUE}</Value>
                </Pair>
              </Styles.AdditionalSection>

              <Styles.AdditionalSection>
                <TitleKey>Water-saving</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Low-flush toilet</Key>
                  <Value>{EMPTY_VALUE}</Value>
                </Pair>
              </Styles.AdditionalSection>

              <Styles.AdditionalSection>
                <TitleKey>Energy Certification</TitleKey>
                <Pair $size="large" $align="right">
                  <Key>Energy Star</Key>
                  <Value>{EMPTY_VALUE}</Value>
                </Pair>
              </Styles.AdditionalSection>
            </WidgetSection>
          </WidgetSectionWrapper>

          <Styles.PhotoWidgetWrapper>
            <React.Suspense fallback={<Loader />}>
              <LoanNearbyPropertiesData>
                <LoanDetailPhotoLibrary />
              </LoanNearbyPropertiesData>
            </React.Suspense>
          </Styles.PhotoWidgetWrapper>
        </div>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailProperty;
