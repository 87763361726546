import React from 'react';

export const ChevronDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 9.20594L12 16L19 9.20594L17.7575 8L12 13.5881L6.2425 8L5 9.20594Z"
        fill="currentColor"
      />
    </svg>
  );
};
