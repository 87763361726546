import { PropertyMedia } from '@plus-platform/shared';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { ButtonIcon } from './ButtonIcon';
import { Heading } from './Heading';
import { ChevronLeftIcon, ChevronRightIcon } from './icons';
import * as Styles from './ImageLightbox.styles';
import { ImageRibbon, ImageRibbonScrollWrapper } from './ImageLightbox.styles';
import { ImageTile } from './ImageTile';

function elementIsOutOfViewLeft(target: HTMLElement, scrollRef: RefObject<HTMLElement>): boolean {
  return target.offsetLeft < (scrollRef?.current?.scrollLeft || 0);
}

function elementIsOutOfViewRight(
  current: HTMLDivElement,
  target: HTMLElement,
  scrollRef: RefObject<HTMLElement>
): boolean {
  const parentRight = (scrollRef?.current?.scrollLeft || 0) + current.getBoundingClientRect().width;
  return target.offsetLeft > parentRight;
}

function scrollToElementLeft(element: HTMLElement) {
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  });
}

function scrollToElementRight(element: HTMLElement) {
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'end',
  });
}

function scrollIfOutOfView(
  ref: RefObject<HTMLDivElement>,
  index: number,
  scrollRef: RefObject<HTMLElement>
) {
  if (ref.current) {
    if (elementIsOutOfViewLeft(ref.current.children[index] as HTMLElement, scrollRef)) {
      scrollToElementLeft(ref.current.children[index] as HTMLElement);
    }
    if (
      elementIsOutOfViewRight(ref.current, ref.current.children[index] as HTMLElement, scrollRef)
    ) {
      scrollToElementRight(ref.current.children[index] as HTMLElement);
    }
  }
}

function handleClickToFirstAndLast(
  ribbonRef: RefObject<HTMLDivElement> | null,
  selectedIndex: number,
  images: PropertyMedia[]
) {
  if (selectedIndex === 0 && ribbonRef?.current) {
    scrollToElementLeft(ribbonRef.current.children[selectedIndex] as HTMLElement);
  }

  if (selectedIndex === images.length - 1 && ribbonRef?.current) {
    scrollToElementRight(ribbonRef.current.children[selectedIndex] as HTMLElement);
  }
}

type PhotoGalleryProps = {
  images: PropertyMedia[];
  setImageIndex: number;
};

export const ImageLightbox = ({ images, setImageIndex }: PhotoGalleryProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const buttonsDisabled = useMemo(() => !images || images.length === 0, []);
  const scrollRef = useRef<HTMLDivElement>(null);
  const ribbonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedIndex(setImageIndex || 0);
  }, [images, setImageIndex]);

  useEffect(() => {
    const previousIndex = selectedIndex - 1 < 0 ? images.length - 1 : selectedIndex - 1;
    const nextIndex = selectedIndex + 1 === images.length ? 0 : selectedIndex + 1;
    [images[previousIndex], images[nextIndex]].forEach((picture) => {
      const img = new Image();
      img.src = picture.url;
    });
  }, [selectedIndex]);

  useEffect(() => {
    handleClickToFirstAndLast(ribbonRef, selectedIndex, images);
    scrollIfOutOfView(ribbonRef, selectedIndex, scrollRef);
  }, [selectedIndex]);

  function previousIndex() {
    setSelectedIndex((selectedIndex - 1 + images.length) % images.length);
  }

  function nextIndex() {
    setSelectedIndex((selectedIndex + 1) % images.length);
  }

  return (
    <Styles.LightboxWrapper>
      <Styles.ButtonImageWrapper>
        <ButtonIcon onClick={previousIndex} disabled={buttonsDisabled}>
          <ChevronLeftIcon />
        </ButtonIcon>
        {images && (
          <Styles.ImageAspectRatioWrapper>
            <Styles.ImageAspectRatioInner>
              <ImageTile
                src={images[selectedIndex].url}
                alt={images[selectedIndex].description}
                variant="fit-container"
                children={images[selectedIndex].description}
              />
            </Styles.ImageAspectRatioInner>
          </Styles.ImageAspectRatioWrapper>
        )}
        <ButtonIcon onClick={nextIndex} disabled={buttonsDisabled}>
          <ChevronRightIcon />
        </ButtonIcon>
      </Styles.ButtonImageWrapper>
      <Styles.HeadingWrapper>
        <Heading as="h3" $size={'medium'} $variant="hasBackground">
          {selectedIndex + 1} of {images.length}
        </Heading>
      </Styles.HeadingWrapper>
      <ImageRibbonScrollWrapper ref={scrollRef}>
        <ImageRibbon ref={ribbonRef}>
          {images.map(({ description, thumbnailUrl }, index) => (
            <Styles.TileSelector
              $isSelected={index === selectedIndex}
              onClick={() => setSelectedIndex(index)}
              key={index}
            >
              <ImageTile
                src={thumbnailUrl}
                alt={description}
                children={description}
                width={164}
                height={92}
              />
            </Styles.TileSelector>
          ))}
        </ImageRibbon>
      </ImageRibbonScrollWrapper>
    </Styles.LightboxWrapper>
  );
};
