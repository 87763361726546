import { IdentificationVerification } from './identificationVerification';
import { Name } from './name';
import {
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

export enum ContactPointRoleType {
  Home = 'Home',
  Mobile = 'Mobile',
  Other = 'Other',
  Work = 'Work',
}

type ContactPointEmail = Partial<{
  contactPointEmailValue: MISMOValue;
}>;

type ContactPointSocialMedia = Partial<{
  socialMediaIdentifier: MISMOIdentifier;
}>;

export type ContactPointTelephone = MISMOEntity &
  Partial<{
    contactPointFaxExtensionValue: MISMOString;
    contactPointFaxValue: MISMOString;
    contactPointTelephoneExtensionValue: MISMOString;
    contactPointTelephoneValue: MISMOString;
  }>;

type OtherContactPoint = Partial<{
  contactPointOtherValue: MISMOValue;
  contactPointOtherValueDescription: MISMOString;
}>;

export type ContactPoint = MISMOEntity &
  Partial<{
    contactPointPreferenceIndicator: MISMOIndicator;
    contactPointRoleType: ContactPointRoleType;
    contactPointRoleTypeOtherDescription: MISMOString;
    priorContactPointIndicator: MISMOIndicator;
    requestNoContactIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
  }> &
  (
    | ContactPointEmail
    | ContactPointSocialMedia
    | ContactPointTelephone
    | OtherContactPoint
  );

enum SignatoryRoleType {
  Authorized = 'Authorized',
  NotAuthorized = 'NotAuthorized',
  Required = 'Required',
}

export type Contact = MISMOEntity &
  Partial<{
    attorneyInFactIndicator: MISMOIndicator;
    contactPartyIdentifier: MISMOIdentifier;
    sequenceNumber: MISMOSequenceNumber;
    signatoryRoleType: SignatoryRoleType;
    contactPoints: ContactPoint[];
    identificationVerification: IdentificationVerification;
    name: Name;
  }>;
