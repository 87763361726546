import {
  MISMOAmount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from '../types';
import { URLATotal, URLATotalHousingExpense } from './URLATotal';

enum ApplicationTakenMethodType {
  Email = 'Email',
  FaceToFace = 'FaceToFace',
  Fax = 'Fax',
  Internet = 'Internet',
  Mail = 'Mail',
  Telephone = 'Telephone',
}

export enum RefinanceImprovementsType {
  Made = 'Made',
  ToBeMade = 'ToBeMade',
  Unknown = 'Unknown',
}

enum URLAStatusType {
  Final = 'Final',
  Interim = 'Interim',
}

export type URLA = MISMOEntity &
  Partial<{
    additionalBorrowerAssetsConsideredIndicator: MISMOIndicator;
    additionalBorrowerAssetsNotConsideredIndicator: MISMOIndicator;
    alterationsImprovementsAndRepairsAmount: MISMOAmount;
    applicationSignedByLoanOriginatorDate: MISMODate;
    applicationTakenMethodType: ApplicationTakenMethodType;
    ARMTypeDescription: MISMOString;
    borrowerPaidDiscountPointsTotalAmount: MISMOAmount;
    borrowerRequestedInterestRatePercent: MISMOPercent;
    borrowerRequestedLoanAmount: MISMOAmount;
    estimatedClosingCostsAmount: MISMOAmount;
    lenderRegistrationIdentifier: MISMOIdentifier;
    MIAndFundingFeeFinancedAmount: MISMOAmount;
    MIAndFundingFeeTotalAmount: MISMOAmount;
    prepaidItemsEstimatedAmount: MISMOAmount;
    purchasePriceAmount: MISMOAmount;
    purchasePriceNetAmount: MISMOAmount;
    refinanceImprovementCostsAmount: MISMOAmount;
    refinanceImprovementsType: RefinanceImprovementsType;
    refinanceIncludingDebtsToBePaidOffAmount: MISMOAmount;
    refinanceProposedImprovementsDescription: MISMOString;
    refundableApplicationFeeIndicator: MISMOIndicator;
    requiredDepositIndicator: MISMOIndicator;
    sellerPaidClosingCostsAmount: MISMOAmount;
    URLAStatusDate: MISMODate;
    URLAStatusType: URLAStatusType;
    URLATotal: URLATotal;
    URLATotalHousingExpenses: URLATotalHousingExpense[];
  }>;
