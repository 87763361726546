import { Loan, LoanChatMessage, LoanDetails, Message, MessageType } from '@plus-platform/shared';
import React from 'react';
import { InfiniteData, useQueryClient } from 'react-query';

import { useSocketContext } from '../contexts/SocketContext';
import { QueryKeys } from '../hooks/queries';
import {
  DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE,
  useLoanChatMessagesQuery,
} from '../hooks/queries/useLoanChatMessagesQuery';
import { useSendLoanChatMessageMutation } from '../hooks/queries/useSendLoanChatMessageMutation';

const getMessagesFromData = (data?: InfiniteData<LoanChatMessage[]>) => {
  const pages = data?.pages ?? [];

  return [...pages].reverse().flatMap((messages) => [...messages].reverse());
};

type Props = {
  loanId?: Loan['id'];
  loanNumber: LoanDetails['loanNumber'];
};

export const useLoanChatMessages = (props: Props) => {
  const { loanId, loanNumber } = props;
  const queryClient = useQueryClient();
  const { subscribe, unsubscribe } = useSocketContext();
  const { mutate: send } = useSendLoanChatMessageMutation(loanNumber);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useLoanChatMessagesQuery(loanNumber, DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE);

  const messages = React.useMemo(() => getMessagesFromData(data), [data]);
  const canFetchMoreMessages = hasNextPage && !isFetchingNextPage;

  const loadMore = () => {
    if (canFetchMoreMessages) {
      fetchNextPage();
    }
  };

  React.useEffect(() => {
    const handleMessage = (message: Message) => {
      if (message.type !== MessageType.LoanChatMessage || message.payload.loanId !== loanId) {
        return;
      }

      queryClient.setQueryData<InfiniteData<LoanChatMessage[]> | undefined>(
        [QueryKeys.LOAN_CHAT_MESSAGES, loanNumber, DEFAULT_NUMBER_OF_MESSAGES_PER_PAGE],
        (data) => {
          if (!data) {
            return data;
          }

          const [firstPage, ...restPages] = data.pages;
          const pages = [[message.payload, ...firstPage], ...restPages];

          return { ...data, pages };
        }
      );
    };

    subscribe(handleMessage);

    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, queryClient, loanNumber, loanId]);

  return { messages, loadMore, isLoading, send };
};
