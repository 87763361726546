import styled, { css } from 'styled-components/macro';

import type { ButtonStyleProps } from './Button.styles';
import { Button as StandardButton } from './Button.styles';
import { BUTTON_SIDE_PADDING_BY_SIZE_MAP } from './Button.styles.utils';

type ButtonLoaderStyleProps = ButtonStyleProps & {
  $isLoading?: boolean;
};

export const Button = styled(StandardButton)<ButtonLoaderStyleProps>(
  ({ $isLoading }) => css`
    position: relative;
    pointer-events: ${$isLoading ? 'none' : 'auto'};

    &:disabled {
      opacity: 0.2;
    }
  `
);

export const Loader = styled.div<{
  $position: 'start' | 'center' | 'end';
  $size: 'xSmall' | 'small' | 'medium' | 'large';
}>(
  ({ $position, $size }) => css`
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${$position === 'center' &&
    css`
      left: 50%;
      transform: translate(-50%);
    `}

    ${$position === 'start' &&
    css`
      left: ${BUTTON_SIDE_PADDING_BY_SIZE_MAP[$size]}px;
    `}

    ${$position === 'end' &&
    css`
      right: ${BUTTON_SIDE_PADDING_BY_SIZE_MAP[$size]}px;
    `}
  `
);

export const Wrapper = styled.span<{ $isHidden?: boolean }>`
  ${({ $isHidden }) =>
    $isHidden &&
    css`
      opacity: 0;
    `}
`;
