import React from 'react';

export const DocumentScanIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 6.57955V3H7.57955V4.22727H5.22727V6.57955H4ZM19.1364 6.57955V4.22727H16.7841V3H20.3636V6.57955H19.1364ZM4 21V17.4205H5.22727V19.7727H7.57955V21H4ZM16.7841 21V19.7727H19.1364V17.4205H20.3636V21H16.7841ZM7.68182 17.3182H16.6818V6.68182H7.68182V17.3182ZM7.68182 18.5455C7.35455 18.5455 7.06818 18.4227 6.82273 18.1773C6.57727 17.9318 6.45455 17.6455 6.45455 17.3182V6.68182C6.45455 6.35455 6.57727 6.06818 6.82273 5.82273C7.06818 5.57727 7.35455 5.45455 7.68182 5.45455H16.6818C17.0091 5.45455 17.2955 5.57727 17.5409 5.82273C17.7864 6.06818 17.9091 6.35455 17.9091 6.68182V17.3182C17.9091 17.6455 17.7864 17.9318 17.5409 18.1773C17.2955 18.4227 17.0091 18.5455 16.6818 18.5455H7.68182ZM9.93182 10.1591H14.4318V8.93182H9.93182V10.1591ZM9.93182 12.6136H14.4318V11.3864H9.93182V12.6136ZM9.93182 15.0682H14.4318V13.8409H9.93182V15.0682Z"
        fill="currentColor"
      />
    </svg>
  );
};
