import {
  AggregatedTradeRequestMessage,
  CounterpartyType,
  Message,
  MessageType,
  TradeRequest,
  TradeRequestMessage,
  TradeRequestMessageType,
} from '@plus-platform/shared';
import compact from 'lodash/compact';
import React from 'react';
import { InfiniteData, useQueryClient } from 'react-query';

import { useSocketContext } from '../../../contexts/SocketContext';
import {
  QueryKeys,
  useSendTradeRequestMessageMutation,
  useTradeRequestCounterpartyMessagesQuery,
  useTradeRequestMessagesQuery,
} from '../../../hooks/queries';

type Props = {
  tradeRequestId: TradeRequest['id'];
  tradeCounterpartyId: string;
  messageType: TradeRequestMessageType;
};

const getMessagesFromData = (data?: InfiniteData<AggregatedTradeRequestMessage[] | undefined>) => {
  return compact(data?.pages).reverse().flat();
};

const isMessageForCurrentChat = (
  message: AggregatedTradeRequestMessage,
  tradeCounterpartyId: string
) => {
  const { fromCounterparty, toCounterparty } = message;

  const isCounterpartyMatching =
    String(toCounterparty.id) === tradeCounterpartyId ||
    String(fromCounterparty.id) === tradeCounterpartyId;
  const areCounterpartiesBuyerAndSeller =
    (fromCounterparty.type === CounterpartyType.BUYER &&
      toCounterparty.type === CounterpartyType.SELLER) ||
    (fromCounterparty.type === CounterpartyType.SELLER &&
      toCounterparty.type === CounterpartyType.BUYER);

  return isCounterpartyMatching && areCounterpartiesBuyerAndSeller;
};

export const useMessages = (props: Props) => {
  const { messageType, tradeCounterpartyId, tradeRequestId } = props;
  const queryClient = useQueryClient();
  const { subscribe, unsubscribe } = useSocketContext();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useTradeRequestCounterpartyMessagesQuery({
      tradeRequestId,
      tradeCounterpartyId,
      messageType,
    });
  const { isLoading: isSending, mutate: sendMessage } = useSendTradeRequestMessageMutation();

  React.useEffect(() => {
    const handleMessage = (message: Message) => {
      if (
        message.type !== MessageType.TradeRequestChatMessage ||
        !isMessageForCurrentChat(message.payload, tradeCounterpartyId)
      ) {
        return;
      }

      queryClient.setQueryData<InfiniteData<TradeRequestMessage[]> | undefined>(
        [
          QueryKeys.TRADE_REQUEST_COUNTERPARTY_MESSAGES,
          { tradeRequestId, tradeCounterpartyId, messageType },
        ],
        (data) => {
          if (!data) {
            return data;
          }

          const [firstPage, ...restPages] = data.pages;
          const pages = [[...firstPage, message.payload], ...restPages];

          return { ...data, pages };
        }
      );
    };

    subscribe(handleMessage);

    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, queryClient, tradeCounterpartyId, tradeRequestId]);

  const messages = React.useMemo(() => getMessagesFromData(data), [data]);
  const canFetchMoreMessages = hasNextPage && !isFetchingNextPage;

  const loadMore = () => {
    if (canFetchMoreMessages) {
      fetchNextPage();
    }
  };

  const send = (content: string) => {
    return sendMessage({ tradeRequestId, tradeCounterpartyId, content });
  };

  return { messages, loadMore, isLoading, send, isSending };
};

export const useTabMessages = (props: Omit<Props, 'tradeCounterpartyId'>) => {
  const { messageType, tradeRequestId } = props;
  const queryClient = useQueryClient();
  const { subscribe, unsubscribe } = useSocketContext();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useTradeRequestMessagesQuery({
      tradeRequestId,
      messageType,
    });

  React.useEffect(() => {
    const handleMessage = (message: Message) => {
      if (
        message.type !== MessageType.TradeRequestChatMessage ||
        message.payload.type !== messageType
      ) {
        return;
      }

      queryClient.setQueryData<InfiniteData<TradeRequestMessage[]> | undefined>(
        [QueryKeys.TRADE_REQUEST_MESSAGES, { tradeRequestId, messageType }],
        (data) => {
          if (!data) {
            return data;
          }

          const [firstPage, ...restPages] = data.pages;
          const pages = [[...firstPage, message.payload], ...restPages];

          return { ...data, pages };
        }
      );
    };

    subscribe(handleMessage);

    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, queryClient, tradeRequestId]);

  const messages = React.useMemo(() => getMessagesFromData(data), [data]);
  const canFetchMoreMessages = hasNextPage && !isFetchingNextPage;

  const loadMore = () => {
    if (canFetchMoreMessages) {
      fetchNextPage();
    }
  };

  return { messages, loadMore, isLoading };
};
