export const EVChargingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6342 14.1198C11.2268 13.7214 10.7314 13.5186 10.1626 13.5186C9.57743 13.5186 9.07343 13.7214 8.66363 14.1198C8.25643 14.5222 8.04883 15.0216 8.04883 15.6052C8.04883 16.1748 8.25603 16.6704 8.66363 17.0778C9.07303 17.4868 9.57623 17.6946 10.1626 17.6946C10.7316 17.6946 11.228 17.4868 11.6346 17.0784C12.0428 16.6708 12.2496 16.1754 12.2496 15.6052C12.249 15.0208 12.0428 14.5204 11.6342 14.1198ZM10.7818 16.2276C10.6106 16.3992 10.4038 16.4832 10.1624 16.4832C9.92083 16.4832 9.71443 16.3988 9.54203 16.2276C9.37003 16.0544 9.28463 15.8484 9.28463 15.6052C9.28463 15.3658 9.37043 15.1596 9.54203 14.9878C9.71443 14.815 9.92103 14.7268 10.1624 14.7268C10.4038 14.7268 10.6104 14.8148 10.7818 14.9878C10.9538 15.1598 11.0402 15.3658 11.0402 15.6052C11.0398 15.8484 10.9538 16.0544 10.7818 16.2276Z"
        fill="currentColor"
      />
      <path
        d="M0.128906 2.06299V17.6946H3.06171V2.06299H0.128906ZM1.60771 4.71159C1.89011 4.71159 2.11911 4.94059 2.11911 5.22259C2.11911 5.50519 1.89011 5.73419 1.60771 5.73419C1.32531 5.73419 1.09631 5.50519 1.09631 5.22259C1.09631 4.94039 1.32531 4.71159 1.60771 4.71159ZM1.60891 7.36319C1.32651 7.36319 1.09751 7.13419 1.09751 6.85199C1.09751 6.56959 1.32651 6.34059 1.60891 6.34059C1.89131 6.34059 2.12031 6.56959 2.12031 6.85199C2.12031 7.13419 1.89131 7.36319 1.60891 7.36319Z"
        fill="currentColor"
      />
      <path
        d="M18.4777 7.21635H19.8243V6.06795H14.4861C14.1405 6.06795 13.6791 6.19695 13.4595 6.56255L11.5903 10.0282H10.0315V6.92895C10.0315 6.26975 9.49489 5.73315 8.83569 5.73315H7.83609C7.65949 4.64315 6.63989 3.80615 5.40389 3.80615V4.86835H3.82989C3.62709 4.86835 3.46289 5.03275 3.46289 5.23535C3.46289 5.43815 3.62729 5.60255 3.82989 5.60255H5.40389V6.52815H3.82989C3.62709 6.52815 3.46289 6.69255 3.46289 6.89515C3.46289 7.09795 3.62729 7.26235 3.82989 7.26235H5.40389V8.32975C6.63989 8.32975 7.65949 7.49255 7.83609 6.40275H8.83569C9.12589 6.40275 9.36189 6.63875 9.36189 6.92875V10.0282H7.96789C7.13929 10.0282 6.46749 10.6458 6.46749 11.4084V11.629H6.47089C6.47089 11.8488 6.47089 12.284 6.47089 12.284V13.2596H5.84869V14.4756C5.84869 14.9046 6.06549 15.1216 6.49589 15.1216H7.09089C7.14269 14.3976 7.47549 13.781 8.08669 13.2712C8.69969 12.764 9.34589 12.548 10.1053 12.548C11.0485 12.548 11.9625 12.948 12.5279 13.6846C13.1467 14.4914 13.0493 15.4584 13.0493 15.4584L19.8243 15.4608V9.92615L18.0557 9.91735L18.0551 7.63895C18.0551 7.40455 18.2435 7.21635 18.4777 7.21635ZM12.9895 9.92155L14.1831 7.46915C14.2989 7.28455 14.4487 7.22875 14.6549 7.22875H16.5793C16.9079 7.22875 17.0711 7.39255 17.0711 7.72155L17.0733 9.92135H12.9895V9.92155Z"
        fill="currentColor"
      />
    </svg>
  );
};
