import { transparentize } from 'polished';
import { Controller, SubmitHandler, useFormContext, UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import isEmail from 'validator/lib/isEmail';

import { Input } from '../../components/Input';
import { SegmentedControl } from '../../components/SegmentedControl';
import { Stack } from '../../components/Stack';
import { useGetRolesQuery } from '../../hooks/queries';
import { ModalButton } from '../AdminModal/AdminModal';

const FieldWrapper = styled.div`
  margin: 16px 0;
`;

const FormWrapper = styled.div`
  max-width: 370px;
  margin: 0 auto;
`;

const FieldLabel = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${transparentize(0.5, theme.colors.background01)};
    margin-bottom: 8px;
  `}
`;

const FieldError = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.redMain};
    font-size: 12px;
    line-height: 18px;
  `}
`;

const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0;
    border: 0.5px solid ${transparentize(0.85, theme.colors.background01)};
    margin: 16px 0;
  `}
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SectionTitle = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.text};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${transparentize(0.3, theme.colors.background01)};
    text-transform: uppercase;
  `}
`;

export type InviteFormValues = {
  email: string;
  roleId: string;
};

type InviteUserFormProps = UseFormReturn<InviteFormValues> & {
  onSubmit: SubmitHandler<InviteFormValues>;
  onClose: () => void;
  submitError?: boolean;
};

export const InviteUserForm = ({
  onClose,
  onSubmit,
  submitError,
  ...formMethods
}: InviteUserFormProps) => {
  const { data: roles } = useGetRolesQuery();
  const { control, formState, handleSubmit, register } = formMethods;
  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionTitle>Required details of new user</SectionTitle>

      <FormWrapper>
        <FieldWrapper>
          <FieldLabel htmlFor="roleId">Type</FieldLabel>
          {roles && (
            <Controller
              name="roleId"
              control={control}
              rules={{ required: 'Please select a role' }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <SegmentedControl
                    id={name}
                    onChange={onChange}
                    options={roles.map((role) => ({
                      label: role.name,
                      value: String(role.id),
                    }))}
                    value={value}
                  />
                );
              }}
            />
          )}
          <FieldError>{errors.roleId ? errors.roleId.message : '\u00A0'}</FieldError>
        </FieldWrapper>

        <FieldWrapper>
          <FieldLabel htmlFor="email">Email</FieldLabel>
          <Input
            $isInvalid={submitError}
            placeholder="Email Address"
            type="email"
            {...register('email', {
              required: 'Please enter a valid email address',
              validate: (value) =>
                isEmail(value) || 'Please enter a valid email address with the correct format',
            })}
          />
          <FieldError>{errors.email ? errors.email.message : '\u00A0'}</FieldError>
        </FieldWrapper>
      </FormWrapper>

      <Divider />

      <Buttons>
        <Stack>
          <ModalButton type="button" $style="outlined" onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton type="submit">Create new user</ModalButton>
        </Stack>
      </Buttons>
    </form>
  );
};
