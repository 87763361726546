import styled from 'styled-components/macro';

import monogramPath from '../assets/logo.svg';
import plusLogoDarkPath from '../assets/plus-dark.svg';
import plusLogoWhitePath from '../assets/plus-white.svg';

export const Monogram = styled(({ width, ...rest }) => (
  <img alt="Plus logo" src={monogramPath} {...rest} />
))`
  width: ${(props) => props.width || '32px'};
  height: auto;
`;

export const PlusLogoDark = styled(({ width, ...rest }) => (
  <img alt="Plus text" src={plusLogoDarkPath} {...rest} />
))``;

export const PlusLogoWhite = styled(({ width, ...rest }) => (
  <img alt="Plus text" src={plusLogoWhitePath} {...rest} />
))``;
