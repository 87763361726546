import isEmpty from 'lodash/isEmpty';
import React, { RefObject, useEffect } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { useGetNotificationsQuery } from '../../hooks/queries';
import { usePrevious } from '../../hooks/usePrevious';
import { getNotificationItemsFromInfinitePagedList } from '../../utils/notificationsUtils';
import { NotificationsPanelItem } from './NotificationsPanelItem';
import * as Styles from './NotificationsPanelList.styles';

interface NotificationsPanelListProps {
  unseenNotificationsCount?: number;
  onMarkAllAsRead(): void;
}

export const NotificationsPanelList = ({
  onMarkAllAsRead,
  unseenNotificationsCount,
}: NotificationsPanelListProps) => {
  const {
    data: infinitePagedNotifications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useGetNotificationsQuery();
  const prevHasUnseenNotificationsCount = usePrevious<number | undefined>(unseenNotificationsCount);

  useEffect(() => {
    if (
      unseenNotificationsCount &&
      // if the unseen notifications count has changed
      prevHasUnseenNotificationsCount !== unseenNotificationsCount &&
      // and there are unseen notifications
      unseenNotificationsCount !== 0
    ) {
      refetch();
    }
  }, [unseenNotificationsCount]);

  const scrollRef = useBottomScrollListener(async () => {
    if (hasNextPage) {
      await fetchNextPage({ cancelRefetch: false });
    }
  });

  const { readNotifications, unreadNotifications } = getNotificationItemsFromInfinitePagedList(
    infinitePagedNotifications
  );

  const notificationListIsEmpty = isEmpty(readNotifications) && isEmpty(unreadNotifications);
  const hasBothReadAndUnreadNotifications = readNotifications.length && unreadNotifications.length;
  const hasUnseenNotifications = Boolean(unseenNotificationsCount && unseenNotificationsCount > 0);

  if (notificationListIsEmpty) {
    return <Styles.Empty>You currently have no notifications.</Styles.Empty>;
  }

  return (
    <Styles.Wrapper ref={scrollRef as RefObject<HTMLDivElement>}>
      {unreadNotifications.length > 0 && (
        <>
          <Styles.ListHeader>
            <Styles.TitleText>Latest</Styles.TitleText>
            {hasUnseenNotifications && (
              <Styles.HeadingLink $size="small" onClick={onMarkAllAsRead}>
                Mark all as read
              </Styles.HeadingLink>
            )}
          </Styles.ListHeader>
          <Styles.List>
            {unreadNotifications.map((notification) => (
              <NotificationsPanelItem key={notification.id} notification={notification} />
            ))}
          </Styles.List>
        </>
      )}
      {hasBothReadAndUnreadNotifications ? <Styles.ListDivider /> : null}
      {readNotifications.length > 0 && (
        <>
          <Styles.TitleText>History</Styles.TitleText>
          <Styles.List>
            {readNotifications.map((notification) => (
              <NotificationsPanelItem key={notification.id} notification={notification} />
            ))}
          </Styles.List>
        </>
      )}
      {isFetchingNextPage && <Styles.Loading>Loading more...</Styles.Loading>}
    </Styles.Wrapper>
  );
};
