import {
  TradeRequestMessageCreationMetadataQuote,
  TradeRequestWithSummary,
} from '@plus-platform/shared';
import { StyledComponentInnerOtherProps, useTheme } from 'styled-components/macro';

import { formatDate, formatPercentage, formatTime } from '../../../utils/formatUtils';
import { TradeMessage } from '../../TradeMessage';
import { formatNameWithInitial } from '../../tradeRequestsUtils';
import {
  QuoteCard,
  QuoteCardBar,
  QuoteCardBorder,
  QuoteCardContent,
  QuoteCardDetails,
  QuoteCardDisk,
  QuoteCardHeader,
  QuoteCardPriceDetails,
  QuoteCardRef,
  QuoteCardSubtitle,
  QuoteCardTitle,
  QuoteSummaryPrice,
} from './Quote.styles';

type QuoteSummaryProps = {
  quote: Pick<
    TradeRequestMessageCreationMetadataQuote,
    'id' | 'value' | 'createdAt' | 'quoteStipulation' | 'fromCounterparty'
  >;
  tradeRequest: TradeRequestWithSummary;
  currentQuoteIndex: number;
  totalQuoteCount: number;
} & StyledComponentInnerOtherProps<typeof QuoteCard>;

export const QuoteSummary = (props: QuoteSummaryProps) => {
  const { currentQuoteIndex, quote, totalQuoteCount, tradeRequest, ...rest } = props;
  const { fromCounterparty } = quote;

  const theme = useTheme();

  return (
    <QuoteCard {...rest}>
      <QuoteCardBar $color={theme.colors.tradeQuoteCardBarViolet} />
      <QuoteCardContent>
        <QuoteCardHeader>
          <QuoteCardTitle>{`Quote ${currentQuoteIndex + 1} of ${totalQuoteCount}`}</QuoteCardTitle>
          <QuoteCardSubtitle>
            Received from{' '}
            {formatNameWithInitial(fromCounterparty.user.lastName, fromCounterparty.user.firstName)}{' '}
            @ {fromCounterparty.user.organization.tradingName} on{' '}
            {formatDate(quote.createdAt ? new Date(quote.createdAt) : undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              weekday: 'short',
            })}{' '}
            @{' '}
            {formatTime(quote.createdAt ? new Date(quote.createdAt) : undefined, {
              hour: 'numeric',
              minute: 'numeric',
              hour12: false,
            })}
          </QuoteCardSubtitle>
          <QuoteCardRef>Ref: {quote.id}</QuoteCardRef>
        </QuoteCardHeader>
        <QuoteCardBorder />
        <QuoteCardDetails>
          {quote?.quoteStipulation?.quoteStipulation ? (
            <TradeMessage
              id="Trade.Outbound.Quote.PendingWithStipulations"
              values={{
                poolName: tradeRequest.pool.name,
                ticketId: tradeRequest.id,
                value: formatPercentage(quote.value),
              }}
            />
          ) : (
            <TradeMessage
              id="Trade.Outbound.Quote.PendingWithoutStipulations"
              values={{
                poolName: tradeRequest.pool.name,
                ticketId: tradeRequest.id,
                value: formatPercentage(quote.value),
              }}
            />
          )}
        </QuoteCardDetails>
        <QuoteCardPriceDetails>
          <span>Quoted percentage:</span>
          <QuoteSummaryPrice>{formatPercentage(quote.value)}</QuoteSummaryPrice>
        </QuoteCardPriceDetails>
      </QuoteCardContent>
      {props.$isStack && <QuoteCardDisk $variant="quote" />}
    </QuoteCard>
  );
};
