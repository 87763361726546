import { LoanDetails } from '@plus-platform/shared';
import { useQuery } from 'react-query';

import { getPropertyValuationHistory } from '../../utils/propertiesUtils';
import { QueryKeys } from './types';

type GetPropertyValuationHistoryQueryProps = {
  loanNumber: LoanDetails['loanNumber'];
};

export const useGetPropertyValuationHistoryQuery = ({
  loanNumber,
}: GetPropertyValuationHistoryQueryProps) =>
  useQuery([QueryKeys.PROPERTY_VALUATION_HISTORY, loanNumber], async () => {
    return getPropertyValuationHistory(loanNumber);
  });
