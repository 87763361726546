import { AttributeType, GetPoolsItem, PermissionType } from '@plus-platform/shared';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import { Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Card } from '../components/Card';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader, PageHeaderContent } from '../components/Page';
import { Permissions } from '../components/Permissions';
import { PrivateRoute } from '../global/PrivateRoute';
import { usePoolsQuery } from '../hooks/queries/usePoolsQuery';
import { usePoolsSummaryQuery } from '../hooks/queries/usePoolsSummaryQuery';
import { PoolsSummary } from '../loans/LoanSummary';
import { CreateNewPoolButton } from './CreateNewPoolButton';
import { CreatePoolPopup } from './CreatePoolPopup';
import { PoolWidget } from './PoolWidget';

const PoolsList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const NoPoolsSection = styled.div`
  position: relative;
  height: 50px;
  padding: 16px;
  background: ${(props) => props.theme.colors.greenTree};
  border-radius: 4px;
`;

export const NoPoolsText = styled.p`
  margin: 0;
  font-family: ${(props) => props.theme.typography.fontFamily.display};
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
`;

export const PoolsPage = () => {
  const history = useHistory();
  const { data: poolsData, isLoading: isPoolsLoading } = usePoolsQuery();
  const { data: poolsSummary, isLoading: isSummaryLoading } = usePoolsSummaryQuery();

  const pages = compact(get(poolsData, 'pages', []));
  const pools: GetPoolsItem[] = flatMap(pages, 'data');

  const hasPoolsSummary = !isSummaryLoading && poolsSummary;
  const hasLoansInPools = hasPoolsSummary && poolsSummary?.totalPoolsCount > 0;
  const noPoolsAdded = !isPoolsLoading && pools.length === 0;
  const hasPools = !isPoolsLoading && pools.length > 0;

  return (
    <Page>
      <Switch>
        <PrivateRoute
          exact
          path="/pools/create"
          render={() => (
            <CreatePoolPopup
              onCancel={() => history.push('/pools')}
              onSubmit={() => history.push('/pools')}
            />
          )}
          requiredPermissions={{
            [PermissionType.PERMISSION_CREATE]: [AttributeType.ATTRIBUTE_POOL],
          }}
        />
      </Switch>
      <PageHeader>
        <PageHeaderContent>
          <div>
            <Heading>Pools</Heading>
            <Breadcrumb>
              <BreadcrumbItem linkTo="/" title="Home" />
              <BreadcrumbItem title="Pools" />
            </Breadcrumb>
          </div>
          {pools.length === 0 && !isPoolsLoading && (
            <Permissions
              permissions={{
                [PermissionType.PERMISSION_CREATE]: [AttributeType.ATTRIBUTE_POOL],
              }}
            >
              <CreateNewPoolButton />
            </Permissions>
          )}
        </PageHeaderContent>
        <Card>
          {hasLoansInPools && (
            <PoolsSummary
              delinquencySummary={poolsSummary?.totalLoansInDefault}
              hasPoolInformation
              loansCount={poolsSummary?.totalLoansCount}
              poolsCount={poolsSummary?.totalPoolsCount}
              remittanceAmount={poolsSummary?.totalRemittanceAmount}
              upbAmount={poolsSummary?.totalUPBAmount}
              weightedAverageCoupon={poolsSummary?.totalLoansWeightedAverageCoupon}
              weightedAverageLTV={poolsSummary?.totalLoansWeightedAverageLTV}
            />
          )}
          {noPoolsAdded && (
            <NoPoolsSection>
              <ActivityIndicator contain isActive={isPoolsLoading}>
                <NoPoolsText>No pools added</NoPoolsText>
              </ActivityIndicator>
            </NoPoolsSection>
          )}
        </Card>
      </PageHeader>
      {hasPools && (
        <PageContent>
          <PoolsList>
            {pools.map(({ id, name, summary }) => (
              <PoolWidget id={id} key={id} name={name} summary={summary} />
            ))}
          </PoolsList>
        </PageContent>
      )}
    </Page>
  );
};
