import { InsuranceType, Loan } from '@plus-platform/shared';
import React, { useMemo } from 'react';

import { Card } from '../../../components/Card';
import { Divider } from '../../../components/Divider';
import { Heading } from '../../../components/Heading';
import { Key, Pair, TitleKey, Value } from '../../../components/KeyValue';
import { WidgetSection, WidgetSectionWrapper } from '../../../components/Widget';
import { EMPTY_VALUE, formatDefaultValue } from '../../../utils/formatUtils';
import * as Styles from '../LoanDetail.styles';

type LoanDetailInsuranceProps = {
  loan: Loan;
};

const LoanDetailInsurance = React.forwardRef<HTMLDivElement, LoanDetailInsuranceProps>(
  ({ loan }, ref) => {
    const { floodInsurer, hazardInsurer, mortgageInsurer } = useMemo(() => {
      const hazardInsurer = loan?.loanInsurers.find(
        (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_HAZARD
      );
      const mortgageInsurer = loan?.loanInsurers.find(
        (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_MORTGAGE
      );
      const floodInsurer = loan?.loanInsurers.find(
        (loanInsurer) => loanInsurer.insurer.type === InsuranceType.INSURANCE_FLOOD
      );

      return {
        hazardInsurer,
        mortgageInsurer,
        floodInsurer,
      };
    }, [loan]);

    return (
      <Card $hasPadding id="insurance" ref={ref} data-testid="LoanDetail_Insurance">
        <Heading $size="medium">Insurance</Heading>
        <WidgetSectionWrapper>
          <WidgetSection>
            <Heading $size="small">Mortgage Insurance</Heading>
            <Styles.AdditionalSection>
              <TitleKey>Company Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Company Name</Key>
                <Value>{formatDefaultValue(mortgageInsurer?.insurer?.name)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Identifier</Key>
                <Value>{formatDefaultValue(mortgageInsurer?.insurer?.id)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Address</Key>
                <Value>{formatDefaultValue(mortgageInsurer?.insurer?.address)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Certificate Coverage</Key>
                {/* TODO: add Certificate Coverage to mortgage insurer structure */}
                <Value>{EMPTY_VALUE}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Coverage %</Key>
                {/* TODO: add Coverage % to mortgage insurer structure */}
                <Value>{EMPTY_VALUE}</Value>
              </Pair>
            </Styles.AdditionalSection>
          </WidgetSection>

          <Divider $flexItem $orientation="vertical" />

          <WidgetSection>
            <Heading $size="small">Hazard Insurance</Heading>

            <Styles.AdditionalSection>
              <TitleKey>Company Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Company Name</Key>
                <Value>{formatDefaultValue(hazardInsurer?.insurer?.name)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Identifier</Key>
                <Value>{formatDefaultValue(hazardInsurer?.insurer?.id)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Address</Key>
                <Value>{formatDefaultValue(hazardInsurer?.insurer?.address)}</Value>
              </Pair>
            </Styles.AdditionalSection>
          </WidgetSection>

          <Divider $flexItem $orientation="vertical" />

          <WidgetSection>
            <Heading $size="small">Flood Insurance</Heading>

            <Styles.AdditionalSection>
              <TitleKey>Company Details</TitleKey>
              <Pair $size="large" $align="right">
                <Key>Company Name</Key>
                <Value>{formatDefaultValue(floodInsurer?.insurer?.name)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Identifier</Key>
                <Value>{formatDefaultValue(floodInsurer?.insurer?.id)}</Value>
              </Pair>
              <Pair $size="large" $align="right">
                <Key>Company Address</Key>
                <Value>{formatDefaultValue(floodInsurer?.insurer?.address)}</Value>
              </Pair>
            </Styles.AdditionalSection>
          </WidgetSection>
        </WidgetSectionWrapper>
      </Card>
    );
  }
);

// React.lazy only currently supports default exports
/* eslint-disable-next-line import/no-default-export */
export default LoanDetailInsurance;
