import {
  MISMOAmount,
  MISMOEntity,
  MISMOSequenceNumber,
  MISMOString,
} from './types';

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum ExteriorFeatureType {
  Doors = 'Doors',
  GuttersAndDownspouts = 'GuttersAndDownspouts',
  Other = 'Other',
  Skirting = 'Skirting',
}

export type ExteriorFeature = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    exteriorFeatureDescription: MISMOString;
    exteriorFeatureType: ExteriorFeatureType;
    exteriorFeatureTypeOtherDescription: MISMOString;
    materialDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
  }>;
