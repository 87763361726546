import {
  OutboundTradeRequestWithSummary,
  TradeQuoteType,
  TradeRequestMessageCreationMetadataQuote,
} from '@plus-platform/shared';
import React from 'react';

import { UserMessage } from '../../../../components/Messages';
import { QuoteCounterSummary } from '../QuoteCounterSummary';
import { QuoteRejectedSummary } from '../QuoteRejectedSummary';
import { QuoteStack } from '../QuoteStack';
import { QuoteSummary } from '../QuoteSummary';
import { QuoteSummaryType } from '../types';
import { OutboundQuoteCounterbackRejected } from './OutboundQuoteCounterbackRejected';

type OutboundQuoteCounterRejectedProps = {
  quote: TradeRequestMessageCreationMetadataQuote;
  tradeRequest: OutboundTradeRequestWithSummary;
  totalQuoteCount: number;
  currentQuoteIndex: number;
};

export const OutboundQuoteCounterRejected = (props: OutboundQuoteCounterRejectedProps) => {
  const { currentQuoteIndex, quote, totalQuoteCount, tradeRequest } = props;
  const { fromCounterparty } = quote;

  const lastCounter = quote;

  const rejectionType =
    lastCounter.type === TradeQuoteType.SELLER_TO_BUYER
      ? QuoteSummaryType.COUNTER
      : QuoteSummaryType.COUNTERBACK;

  return (
    <React.Fragment>
      {lastCounter.type === TradeQuoteType.BUYER_TO_SELLER && (
        <OutboundQuoteCounterbackRejected
          date={quote.createdAt}
          fromCounterparty={fromCounterparty}
        />
      )}
      <UserMessage
        date={quote.createdAt}
        organizationName={fromCounterparty.user.organization.tradingName}
        lastName={fromCounterparty.user.lastName}
        firstName={fromCounterparty.user.firstName}
      >
        <QuoteStack>
          <QuoteSummary
            currentQuoteIndex={currentQuoteIndex}
            quote={quote}
            totalQuoteCount={totalQuoteCount}
            tradeRequest={tradeRequest}
          />
          {quote.linkedQuotes.map((linkedQuote, linkedQuoteIndex) => {
            const counterType =
              linkedQuote.type === TradeQuoteType.SELLER_TO_BUYER
                ? QuoteSummaryType.COUNTER
                : QuoteSummaryType.COUNTERBACK;

            const totalCounterCount = quote.linkedQuotes.length;

            return (
              <QuoteCounterSummary
                currentCounterIndex={linkedQuoteIndex}
                key={linkedQuote.id}
                // TODO: Generate reference
                reason={linkedQuote.reason}
                reference={String(linkedQuote.id)}
                sender={
                  linkedQuote.type === TradeQuoteType.SELLER_TO_BUYER
                    ? tradeRequest.user
                    : fromCounterparty.user
                }
                senderOrganization={
                  linkedQuote.type === TradeQuoteType.SELLER_TO_BUYER
                    ? tradeRequest.user.organization
                    : fromCounterparty.user.organization
                }
                sentAt={linkedQuote.createdAt}
                stipulation={linkedQuote.stipulation}
                totalCounterCount={totalCounterCount}
                type={counterType}
                value={linkedQuote.value}
              />
            );
          })}
          <QuoteRejectedSummary
            // TODO: Recursively generate list of counter/counterback ids
            // Consider viability of displaying long list
            reference={`${lastCounter.id}/${lastCounter.linkedQuoteId}`}
            sender={fromCounterparty.user}
            senderOrganization={fromCounterparty.user.organization}
            sentAt={lastCounter.updatedAt}
            type={rejectionType}
            value={lastCounter.value}
          />
        </QuoteStack>
      </UserMessage>
    </React.Fragment>
  );
};
