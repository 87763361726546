import styled from 'styled-components/macro';

export const Analytic = styled.li`
  display: flex;
  flex-basis: 20%;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white05};

  // NOTE: Because we are using flex-wrap layout on the parent, to arrange analytic items
  // in a set of rows without a predefined number of columns, we can't,
  // at least at the moment, target first element of each row or all elements
  // of the last row with a query selector alone. To avoid writing any custom JS
  // logic, this is one of the CSS-only solutions, where the parent is set
  // to overflow: hidden, and children elements are shifted by one pixel to the left,
  // so that left borders on first elements in each row are hidden, and then shifted
  // by one pixel down, so that bottom borders of the elements in the last row are hidden.
  margin-left: -1px;
  margin-bottom: -1px;
`;

export const WideAnalytic = styled(Analytic)`
  flex-basis: 40%;
`;

export const AnalyticContainer = styled.div`
  flex: 1;
  padding: 12px;
  border-left: 1px solid ${({ theme }) => theme.colors.white05};
`;

export const AnalyticHeader = styled.header`
  padding-bottom: 12px;
`;

export const AnalyticTitle = styled.h3`
  padding: 0 4px;
  ${({ theme }) => theme.typography.headings.headingMedium03}
`;

export const AnalyticCount = styled.span`
  padding: 0 4px;
  background-color: ${({ theme }) => theme.colors.white05};
  border-radius: 2px;
  ${({ theme }) => theme.typography.headings.headingMedium02}
  font-size: 20px;
`;
