import { transparentize } from 'polished';
import styled from 'styled-components/macro';

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => transparentize(0.25, props.theme.colors.background01)};
  z-index: 3;
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
`;

export const FooterWrapperSection = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Container = styled.div<{ $isInline?: boolean }>`
  border-radius: 0 0 4px 4px;
  padding: 16px;
  background: ${(props) => props.theme.colors.primary02};

  ${(props) => !props.$isInline && `border: 1px solid ${props.theme.colors.primary03};`}
`;

export const SubmitButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 24px;
  gap: 8px;
`;
