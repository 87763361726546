import { GeneralBehaviorSettings } from '@plus-platform/shared';
import React from 'react';

import { GraduatedRange } from '../components/GraduatedRange';
import { GeneralMenu, MenuItem } from './FlagsAlertsWarningsPage.styles';
import { Subheading } from './SettingsAnalyticsPage.styles';

export type DefaultGeneralBehaviorSettings = {
  general: number;
  vigilance: number;
  significance: number;
};

type GeneralSettingsProps = {
  defaultSettings: DefaultGeneralBehaviorSettings;
  settings?: GeneralBehaviorSettings;
  onSettingsChange: (settings: GeneralBehaviorSettings) => void;
};

export const GeneralSettings = ({
  defaultSettings,
  onSettingsChange,
  settings,
}: GeneralSettingsProps) => {
  const [generalSettings, setGeneralSettings] = React.useState<GeneralBehaviorSettings | undefined>(
    settings
  );

  React.useEffect(() => {
    setGeneralSettings(settings);
  }, [settings]);

  const updateSettings = (newSettings: GeneralBehaviorSettings) => {
    setGeneralSettings(newSettings);
    onSettingsChange(newSettings);
  };

  return (
    <>
      <GeneralMenu style={{ marginTop: 0 }}>
        <MenuItem>
          <Subheading>General behaviour</Subheading>
          <GraduatedRange
            id="analytics-general-behavior"
            value={generalSettings?.general ?? defaultSettings.general}
            startValue={0}
            endValue={8}
            valueToLabelMapping={{
              0: 'Low',
              4: 'Once per day',
              8: 'High',
            }}
            onChange={(value) =>
              updateSettings({
                ...generalSettings,
                general: Number(value),
              })
            }
          />
        </MenuItem>
      </GeneralMenu>

      <GeneralMenu>
        <MenuItem>
          <Subheading>Update vigilance</Subheading>
          <GraduatedRange
            id="analytics-vigilance"
            value={generalSettings?.vigilance ?? defaultSettings.vigilance}
            startValue={0}
            endValue={8}
            valueToLabelMapping={{
              0: 'Trusting',
              4: 'Once per day',
              8: 'Suspicious',
            }}
            onChange={(value) =>
              updateSettings({
                ...generalSettings,
                vigilance: Number(value),
              })
            }
          />
        </MenuItem>
      </GeneralMenu>

      <GeneralMenu>
        <MenuItem>
          <Subheading>Update significance</Subheading>
          <GraduatedRange
            id="analytics-significance"
            value={generalSettings?.significance ?? defaultSettings.significance}
            startValue={0}
            endValue={8}
            valueToLabelMapping={{
              0: 'Low',
              4: 'Normal',
              8: 'High',
            }}
            onChange={(value) =>
              updateSettings({
                ...generalSettings,
                significance: Number(value),
              })
            }
          />
        </MenuItem>
      </GeneralMenu>
    </>
  );
};
