import { MortgageType } from '../loan/loanTerms';
import {
  MISMOAmount,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOString,
} from '../types';
import { ComparableAdjustment } from './comparableAdjustment';
import { Research } from './research';

type ComparableSummary = MISMOEntity &
  Partial<{
    salesComparisonCurrentSalesAgreementAnalysisCommentDescription: MISMOString;
    salesComparisonTotalBedroomValueAmount: MISMOAmount;
    salesComparisonTotalGrossBuildingAreaValueAmount: MISMOAmount;
    salesComparisonTotalRoomValueAmount: MISMOAmount;
    salesComparisonTotalUnitValueAmount: MISMOAmount;
  }>;

enum ComparableType {
  ActiveListing = 'ActiveListing',
  ClosedSale = 'ClosedSale',
  CompetitiveOffering = 'CompetitiveOffering',
  ExpiredListing = 'ExpiredListing',
  Other = 'Other',
}

enum RentControlStatusType {
  Likely = 'Likely',
  No = 'No',
  Yes = 'Yes',
}

export type Comparable = MISMOEntity &
  Partial<{
    adjustedSalesPriceAmount: MISMOAmount;
    adjustedSalesPricePerBedroomAmount: MISMOAmount;
    adjustedSalesPricePerRoomAmount: MISMOAmount;
    adjustedSalesPricePerUnitAmount: MISMOAmount;
    closedSaleCommentDescription: MISMOString;
    comparableIdentifier: MISMOIdentifier;
    comparableListingCommentDescription: MISMOString;
    comparablePropertyCommentDescription: MISMOString;
    comparableSaleFurnishedIndicator: MISMOIndicator;
    comparableType: ComparableType;
    comparableTypeOtherDescription: MISMOString;
    competitiveListingsCommentDescription: MISMOString;
    contractDateUnknownIndicator: MISMOIndicator;
    grossMonthlyRentMultiplierPercent: MISMOPercent;
    MLSNumberIdentifier: MISMOIdentifier;
    monthlyRentAmount: MISMOAmount;
    mortgageType: MortgageType;
    mortgageTypeOtherDescription: MISMOString;
    noFinancingTransactionIndicator: MISMOIndicator;
    pricePerSquareFootAmount: MISMOAmount;
    proximityToSubjectDescription: MISMOString;
    rentControlStatusType: RentControlStatusType;
    salePriceTotalAdjustmentAmount: MISMOAmount;
    salePriceTotalAdjustmentNetPercent: MISMOPercent;
    salesPricePerBedroomAmount: MISMOAmount;
    salesPricePerGrossBuildingAreaAmount: MISMOAmount;
    salesPricePerGrossLivingAreaAmount: MISMOAmount;
    salesPricePerOwnershipShareAmount: MISMOAmount;
    salesPricePerRoomAmount: MISMOAmount;
    salesPricePerUnitAmount: MISMOAmount;
    salesPriceToListPriceRatePercent: MISMOPercent;
    salesPriceTotalAdjustmentGrossPercent: MISMOPercent;
    comparableAdjustments: ComparableAdjustment[];
    comparableSummary: ComparableSummary;
    research: Research;
  }>;
