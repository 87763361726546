import { MISMOAmount, MISMOEntity, MISMOString } from './types';

export enum FeeSummaryTotalFeesPaidByType {
  Broker = 'Broker',
  Buyer = 'Buyer',
  Investor = 'Investor',
  Lender = 'Lender',
  Other = 'Other',
  Seller = 'Seller',
}

export type FeeSummaryTotalFeesPaidBy = MISMOEntity &
  Partial<{
    feeSummaryTotalFeesPaidByAmount: MISMOAmount;
    feeSummaryTotalFeesPaidByType: FeeSummaryTotalFeesPaidByType;
    feeSummaryTotalFeesPaidByTypeOtherDescription: MISMOString;
  }>;
