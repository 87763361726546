import styled from 'styled-components/macro';

import { Button } from '../components/Button';
import { Popup } from '../components/Popup';

export const AddToPoolPopupComponent = styled(Popup)`
  width: 500px;
`;

export const SuccessPopupComponent = styled(Popup)`
  width: 500px;
`;

export const NewPoolButton = styled(Button)`
  margin-right: auto;
`;

export const SelectWrapper = styled.div`
  position: relative;
`;
