import {
  DelinquencyType,
  GetPortfolioDelinquentLoanTypesByNoteRateLoansResponse,
  PortfolioLoanRateType,
} from '@plus-platform/shared';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';

export const getPortfolioDelinquentLoanTypesByNoteRateLoans = async (
  delinquencyType: DelinquencyType,
  rateType: PortfolioLoanRateType,
  noteRate: number,
  offset = 0
) => {
  const params = new URLSearchParams({ offset: String(offset) });
  const url = makeApiUrl(
    `/portfolio/delinquent/${delinquencyType}/types/${rateType}/rates/${noteRate}/loans`,
    params
  );

  return getRequestWithAuth<GetPortfolioDelinquentLoanTypesByNoteRateLoansResponse>(url);
};
