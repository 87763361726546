import { GetNotificationsForUserResponse } from '@plus-platform/shared';
import isEmpty from 'lodash/isEmpty';
import { useInfiniteQuery } from 'react-query';

import { getRequestWithAuth, makeApiUrl } from '../../utils/apiUtils';
import { QueryKeys } from './types';

export const DEFAULT_NUMBER_OF_NOTIFICATIONS_PER_PAGE = 10;

const getNotifications = async (limit: number, offset: number) => {
  const query = new URLSearchParams({
    limit: String(limit),
    offset: String(offset),
  });
  const url = makeApiUrl('user/notifications', query);
  return getRequestWithAuth<GetNotificationsForUserResponse>(url);
};

export const useGetNotificationsQuery = () => {
  return useInfiniteQuery(
    [QueryKeys.USER_NOTIFICATIONS],
    async ({ pageParam = 0 }) => {
      return getNotifications(DEFAULT_NUMBER_OF_NOTIFICATIONS_PER_PAGE, pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage === undefined || isEmpty(lastPage.items)) {
          return undefined;
        }
        return lastPage?.pagination.offset + lastPage?.pagination.limit;
      },
      keepPreviousData: true,
    }
  );
};
