import React, { createContext, useContext } from 'react';

type LoanContextValue = {
  loanNumber: string;
} & (
  | {
      isAnonymised: true;
      loanId: string;
      tradeRequestId: string;
    }
  | {
      isAnonymised: false;
    }
);

export const LoanContext = createContext<LoanContextValue>({
  loanNumber: '',
  isAnonymised: false,
});

type LoanProviderProps = {
  value: Pick<LoanContextValue, 'loanNumber'>;
  children: React.ReactNode;
};

export const LoanProvider = (props: LoanProviderProps) => {
  const { children, value } = props;

  return (
    <LoanContext.Provider value={{ ...value, isAnonymised: false }}>
      {children}
    </LoanContext.Provider>
  );
};

type AnonymisedLoanProviderProps = {
  value: Pick<LoanContextValue, 'loanNumber'> & {
    loanId: string;
    tradeRequestId: string;
  };
  children: React.ReactNode;
};

export const AnonymisedLoanProvider = (props: AnonymisedLoanProviderProps) => {
  const { children, value } = props;

  return (
    <LoanContext.Provider value={{ ...value, isAnonymised: true }}>{children}</LoanContext.Provider>
  );
};

export const useLoanContext = () => useContext(LoanContext);
