import { PropertyTitle } from './property/propertyTitle';
import { MISMOEntity, MISMOSequenceNumber, MISMOString } from './types';
import { Valuation } from './valuation';
import { VerificationOfIncome } from './verificationOfIncome';

enum ServiceType {
  AutomatedUnderwritingSystem = 'AutomatedUnderwritingSystem',
  Claim = 'Claim',
  Credit = 'Credit',
  DataChange = 'DataChange',
  DocumentManagement = 'DocumentManagement',
  DocumentPreparation = 'DocumentPreparation',
  Flood = 'Flood',
  Fraud = 'Fraud',
  Inspection = 'Inspection',
  LoanDelivery = 'LoanDelivery',
  MERS = 'MERS',
  MI = 'MI',
  Other = 'Other',
  PRIA = 'PRIA',
  Tax = 'Tax',
  Title = 'Title',
  Valuation = 'Valuation',
  VerificationOfIncome = 'VerificationOfIncome',
}

export type Service = MISMOEntity &
  Partial<{
    sequenceNumber: MISMOSequenceNumber;
    serviceType: ServiceType;
    serviceTypeOtherDescription: MISMOString;
    // additionalDataItems: AdditionalDataItem[];
    // errors: Error[];
    // reasons: Reason[];
    // relationships: Relationship[];
    // reportingInformation: ReportingInformation;
    // servicePayments: ServicePayment[];
    // serviceProduct: ServiceProduct;
    // serviceProductFulfillment: ServiceProductFulfillment;
    // statuses: Status[];
  }> &
  (
    | // | AutomatedUnderwritingSystem
    // | Claim
    // | Closing
    // | Credit
    // | DataChange
    // | DocumentManagement
    // | DocumentPreparation
    // | Flood
    // | Fraud
    // | InspectionService
    // | LoanDelivery
    // | MERSService
    // | MI
    // | PRIA
    // | PropertyInformationService
    // | Tax
    PropertyTitle
    | Valuation
    | VerificationOfIncome
  );
