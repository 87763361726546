import { SVGAttributes, useEffect, useState } from 'react';

import { EMPTY_VALUE } from '../utils/formatUtils';
import * as Styles from './RadioDial.styles';

export type RadioDialProps = SVGAttributes<SVGSVGElement> & {
  value?: number;
  svgTitle?: string;
};
export const RadioDial = ({ svgTitle = '', value, ...rest }: RadioDialProps) => {
  const [$value, set$Value] = useState(0);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      set$Value(value || 0);
    }, 1);
    return () => clearTimeout(timeOut);
  }, [value]);

  return (
    <Styles.SvgContainer {...rest}>
      <title>{svgTitle}</title>
      <Styles.CircleBackground />
      <Styles.Circle $value={$value} $colorValue={value || 0} />
      <Styles.Text>{value != undefined ? Math.round(value) : EMPTY_VALUE}</Styles.Text>
    </Styles.SvgContainer>
  );
};
