import { IntegratedDisclosureSectionType } from './integratedDisclosure';
import { FeePaidToType, PaidTo } from './paidTo';
import { SelectedServiceProvider } from './serviceProvider';
import {
  MISMOAmount,
  MISMOCount,
  MISMODate,
  MISMOEntity,
  MISMOIdentifier,
  MISMOIndicator,
  MISMOPercent,
  MISMOSequenceNumber,
  MISMOString,
  MISMOValue,
} from './types';

enum EscrowItemCategoryType {
  AssociationDues = 'AssociationDues',
  Holdback = 'Holdback',
  HomeownersInsurance = 'HomeownersInsurance',
  MI = 'MI',
  Other = 'Other',
  PropertyTaxes = 'PropertyTaxes',
}

export enum EscrowItemType {
  AssessmentTax = 'AssessmentTax',
  BoroughPropertyTax = 'BoroughPropertyTax',
  CityBondTax = 'CityBondTax',
  CityPropertyTax = 'CityPropertyTax',
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  ConstructionCompletionFunds = 'ConstructionCompletionFunds',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  CountyBondTax = 'CountyBondTax',
  CountyPropertyTax = 'CountyPropertyTax',
  DistrictPropertyTax = 'DistrictPropertyTax',
  EarthquakeInsurance = 'EarthquakeInsurance',
  EnergyEfficientImprovementFunds = 'EnergyEfficientImprovementFunds',
  FloodInsurance = 'FloodInsurance',
  HailInsurancePremium = 'HailInsurancePremium',
  HazardInsurance = 'HazardInsurance',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeownersInsurance = 'HomeownersInsurance',
  MortgageInsurance = 'MortgageInsurance',
  Other = 'Other',
  ParishTax = 'ParishTax',
  PestInsurance = 'PestInsurance',
  PropertyTax = 'PropertyTax',
  RehabilitationFunds = 'RehabilitationFunds',
  SchoolPropertyTax = 'SchoolPropertyTax',
  StatePropertyTax = 'StatePropertyTax',
  TownPropertyTax = 'TownPropertyTax',
  TownshipPropertyTax = 'TownshipPropertyTax',
  VillagePropertyTax = 'VillagePropertyTax',
  VolcanoInsurance = 'VolcanoInsurance',
  WindstormInsurance = 'WindstormInsurance',
}

enum EscrowMonthlyPaymentRoundingType {
  Down = 'Down',
  None = 'None',
  Up = 'Up',
}

enum EscrowPaidByType {
  Buyer = 'Buyer',
  LenderPremium = 'LenderPremium',
  Seller = 'Seller',
  ThirdParty = 'ThirdParty',
}

enum EscrowPaymentFrequencyType {
  Annual = 'Annual',
  Monthly = 'Monthly',
  Other = 'Other',
  Quarterly = 'Quarterly',
  SemiAnnual = 'SemiAnnual',
  Unequal = 'Unequal',
}

enum EscrowPremiumPaidByType {
  Buyer = 'Buyer',
  Lender = 'Lender',
  Seller = 'Seller',
}

enum EscrowPremiumPaymentType {
  CollectAtClosing = 'CollectAtClosing',
  PaidOutsideOfClosing = 'PaidOutsideOfClosing',
  Waived = 'Waived',
}

enum EscrowPremiumRatePercentBasisType {
  BorrowerRequestedLoanAmount = 'BorrowerRequestedLoanAmount',
  NoteAmount = 'NoteAmount',
  Other = 'Other',
  PropertyValuationAmount = 'PropertyValuationAmount',
  PurchasePriceAmount = 'PurchasePriceAmount',
  TotalLoanAmount = 'TotalLoanAmount',
}

export type EscrowItem = MISMOEntity &
  Partial<{
    borrowerChosenProviderIndicator: MISMOIndicator;
    escrowAnnualPaymentAmount: MISMOAmount;
    escrowCollectedNumberOfMonthsCount: MISMOCount;
    escrowInsurancePolicyIdentifier: MISMOIdentifier;
    escrowItemActualTotalAmount: MISMOAmount;
    escrowItemCategoryType: EscrowItemCategoryType;
    escrowItemCategoryTypeOtherDescription: MISMOString;
    escrowItemEstimatedTotalAmount: MISMOAmount;
    escrowItemLastDisbursementDate: MISMODate;
    escrowItemNextDisbursementDueDate: MISMODate;
    escrowItemNextProjectedDisbursementAmount: MISMOAmount;
    escrowItemTotalReserveCollectedAtClosingAmount: MISMOAmount;
    escrowItemType: EscrowItemType;
    'escrowItemType@DisplayLabelText': MISMOString;
    escrowItemTypeOtherDescription: MISMOString;
    escrowMonthlyPaymentAmount: MISMOAmount;
    escrowMonthlyPaymentRoundingType: EscrowMonthlyPaymentRoundingType;
    escrowPaidByType: EscrowPaidByType;
    escrowPaymentFrequencyType: EscrowPaymentFrequencyType;
    escrowPaymentFrequencyTypeOtherDescription: MISMOString;
    escrowPremiumAmount: MISMOAmount;
    escrowPremiumDurationMonthsCount: MISMOCount;
    escrowPremiumPaidByType: EscrowPremiumPaidByType;
    escrowPremiumPaymentType: EscrowPremiumPaymentType;
    escrowPremiumRatePercent: MISMOPercent;
    escrowPremiumRatePercentBasisType: EscrowPremiumRatePercentBasisType;
    escrowPremiumRatePercentBasisTypeOtherDescription: MISMOString;
    escrowSpecifiedHUD1LineNumberValue: MISMOValue;
    escrowTotalDisbursementAmount: MISMOAmount;
    feePaidToType: FeePaidToType;
    feePaidToTypeOtherDescription: MISMOString;
    GFEDisclosedEscrowPremiumAmount: MISMOAmount;
    integratedDisclosureLineNumberValue: MISMOValue;
    integratedDisclosureSectionType: IntegratedDisclosureSectionType;
    integratedDisclosureSectionTypeOtherDescription: MISMOString;
    regulationZPointsAndFeesIndicator: MISMOIndicator;
    requiredProviderOfServiceIndicator: MISMOIndicator;
    sequenceNumber: MISMOSequenceNumber;
    // escrowItemDisbursements: EscrowItemDisbursement[];
    // escrowItemPayments: EscrowItemPayment[];
    paidTo: PaidTo;
    selectedServiceProvider: SelectedServiceProvider;
  }>;

export type Escrow = MISMOEntity &
  Partial<{
    escrowAccountInitialBalanceAmount: MISMOAmount;
    escrowAccountMinimumBalanceAmount: MISMOAmount;
    escrowAccountRolloverAmount: MISMOAmount;
    escrowAccountShortageAmount: MISMOAmount;
    escrowAccountShortageRepaymentPeriodYearsCount: MISMOCount;
    escrowAggregateAccountingAdjustmentAmount: MISMOAmount;
    escrowBalanceAmount: MISMOAmount;
    escrowCushionNumberOfMonthsCount: MISMOCount;
    GFEDisclosedInitialEscrowBalanceAmount: MISMOAmount;
    initialEscrowDepositIncludesAllInsuranceIndicator: MISMOIndicator;
    initialEscrowDepositIncludesAllPropertyTaxesIndicator: MISMOIndicator;
    initialEscrowDepositIncludesOtherDescription: MISMOString;
    initialEscrowProjectionStatementDate: MISMODate;
    interestOnEscrowAccruedAmount: MISMOAmount;
    interestOnEscrowAccruedThroughDate: MISMODate;
    interestOnEscrowBackupWithholdingIndicator: MISMOIndicator;
    interestOnEscrowIndicator: MISMOIndicator;
    interestOnEscrowYearToDatePostedAmount: MISMOAmount;
    interestOnRestrictedEscrowIndicator: MISMOIndicator;
    lastEscrowAnalysisDate: MISMODate;
    lastInterestOnEscrowPostedDate: MISMODate;
    MIEscrowIncludedInAggregateIndicator: MISMOIndicator;
    restrictedEscrowBalanceAmount: MISMOAmount;
    // escrowDisclosures: EscrowDisclosure[];
    escrowItems: EscrowItem[];
    // holdbackItems: HoldbackItem[];
  }>;
