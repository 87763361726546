import styled, { css } from 'styled-components/macro';

export const ProgressWrapper = styled.div`
  ${({ theme }) => css`
    progress {
      border-radius: 5px;
      width: 240px;
      height: 10px;
      margin-top: 5px;
      border: none;
      border-radius: 10px;

      &::-webkit-progress-bar {
        background-color: ${theme.colors.tradeChatButtonPurple};
        border-radius: 10px;
      }

      /* Chome - Value is the progress bar */
      &::-webkit-progress-value {
        background-color: ${theme.colors.tradeActivityYellow};
        border-radius: 10px;
      }

      /* Firefox - Bar is the progress bar */
      background-color: ${theme.colors.tradeChatButtonPurple};

      &::-moz-progress-bar {
        background-color: ${theme.colors.tradeActivityYellow};
        border-radius: 10px;
      }
    }
  `}
`;
