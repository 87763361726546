import { useQuery } from 'react-query';

import { searchLoans } from '../../utils/searchUtils';
import { QueryKeys } from './types';

/**
 * Query hook for searching loans
 */
export const useLoanSearchQuery = (searchTerm: string) =>
  useQuery([QueryKeys.LOAN_SEARCH, searchTerm], () => searchLoans(searchTerm), {
    enabled: Boolean(searchTerm),
  });
