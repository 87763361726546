import {
  MISMOAmount,
  MISMOEntity,
  MISMOIndicator,
  MISMOSequenceNumber,
  MISMOString,
} from '../types';

enum ComponentClassificationType {
  PersonalProperty = 'PersonalProperty',
  RealProperty = 'RealProperty',
}

enum ConditionRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

enum HeatingFuelType {
  Coal = 'Coal',
  Electric = 'Electric',
  Gas = 'Gas',
  Other = 'Other',
  Solar = 'Solar',
  Wood = 'Wood',
}

export enum HeatingSystemType {
  ForcedWarmAir = 'ForcedWarmAir',
  HotWaterBaseboard = 'HotWaterBaseboard',
  Other = 'Other',
  Radiant = 'Radiant',
}

enum QualityRatingType {
  Average = 'Average',
  Excellent = 'Excellent',
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
}

export type HeatingSystem = MISMOEntity &
  Partial<{
    componentAdjustmentAmount: MISMOAmount;
    componentClassificationType: ComponentClassificationType;
    conditionRatingDescription: MISMOString;
    conditionRatingType: ConditionRatingType;
    heatingFuelType: HeatingFuelType;
    heatingFuelTypeOtherDescription: MISMOString;
    heatingSystemDescription: MISMOString;
    heatingSystemPrimaryIndicator: MISMOIndicator;
    heatingSystemType: HeatingSystemType;
    heatingSystemTypeOtherDescription: MISMOString;
    qualityRatingDescription: MISMOString;
    qualityRatingType: QualityRatingType;
    sequenceNumber: MISMOSequenceNumber;
    systemPermanentIndicator: MISMOIndicator;
  }>;
