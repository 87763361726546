import { MISMOAmount, MISMOCount, MISMOEntity, MISMOIndicator } from '../types';
import { Property } from '.';

export enum OwnedPropertyDispositionStatusType {
  PendingSale = 'PendingSale',
  Retain = 'Retain',
  Sold = 'Sold',
}

export type OwnedProperty = MISMOEntity &
  Partial<{
    ownedPropertyDispositionStatusType: OwnedPropertyDispositionStatusType;
    ownedPropertyLienInstallmentAmount: MISMOAmount;
    ownedPropertyLienUPBAmount: MISMOAmount;
    ownedPropertyMaintenanceExpenseAmount: MISMOAmount;
    ownedPropertyOwnedUnitCount: MISMOCount;
    ownedPropertyRentalIncomeGrossAmount: MISMOAmount;
    ownedPropertyRentalIncomeNetAmount: MISMOAmount;
    ownedPropertySubjectIndicator: MISMOIndicator;
    property: Property;
  }>;
