import { GetPoolLoansResponse, Identifier } from '@plus-platform/shared';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import * as api from '../../api/pools';
import { QueryKeys } from './types';

const DEFAULT_ITEMS_PER_PAGE = 10;

type UseGetPoolLoansQueryProps = {
  poolId: Identifier;
  filterIds: number[];
  itemCount?: number;
  onSuccess?: (data: InfiniteData<GetPoolLoansResponse>) => void;
};

export const useGetPoolLoansQuery = ({
  filterIds,
  itemCount = DEFAULT_ITEMS_PER_PAGE,
  onSuccess,
  poolId,
}: UseGetPoolLoansQueryProps): UseInfiniteQueryResult<GetPoolLoansResponse> =>
  useInfiniteQuery(
    [QueryKeys.POOL_LOANS, itemCount, filterIds],
    async ({ pageParam = 0 }) => {
      return api.getPoolLoans(poolId, filterIds, pageParam, itemCount);
    },
    {
      enabled: Boolean(poolId),
      getNextPageParam: (lastPage) => (lastPage !== undefined ? lastPage.pageNumber : 0),
      onSuccess: onSuccess ?? undefined,
    }
  );
