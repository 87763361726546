import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb';
import { Heading } from '../components/Heading';
import { Page, PageContent, PageHeader, PageSection } from '../components/Page';
import { REACT_APP_ALLOW_DATA_RESET } from '../config';
import { DataReset } from './DataReset';
import { OrganizationMembersTable } from './OrganizationMembersTable/OrganizationMembersTable';

export const AdminUsersPage = () => {
  return (
    <Page>
      <PageHeader>
        <Heading>Users</Heading>
        <Breadcrumb>
          <BreadcrumbItem linkTo="/" title="Home" />
          <BreadcrumbItem linkTo="/admin" title="Admin" />
          <BreadcrumbItem title="Users" />
        </Breadcrumb>
      </PageHeader>
      <PageContent>
        <PageSection>
          <OrganizationMembersTable />
        </PageSection>

        {REACT_APP_ALLOW_DATA_RESET && (
          <PageSection>
            <DataReset />
          </PageSection>
        )}
      </PageContent>
    </Page>
  );
};
