import React from 'react';

export const NotificationsPausedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 16.6V15.4H7.6V9.32C7.6 8.2 7.93 7.19667 8.59 6.31C9.25 5.42333 10.12 4.86667 11.2 4.64V4.2C11.2 3.86667 11.3167 3.58333 11.55 3.35C11.7833 3.11667 12.0667 3 12.4 3C12.7333 3 13.0167 3.11667 13.25 3.35C13.4833 3.58333 13.6 3.86667 13.6 4.2V4.64C14.68 4.86667 15.55 5.42333 16.21 6.31C16.87 7.19667 17.2 8.2 17.2 9.32V15.4H18.8V16.6H6ZM12.4 19C11.96 19 11.5833 18.8433 11.27 18.53C10.9567 18.2167 10.8 17.84 10.8 17.4H14C14 17.84 13.8433 18.2167 13.53 18.53C13.2167 18.8433 12.84 19 12.4 19ZM8.8 15.4H16V9.32C16 8.32 15.65 7.47 14.95 6.77C14.25 6.07 13.4 5.72 12.4 5.72C11.4 5.72 10.55 6.07 9.85 6.77C9.15 7.47 8.8 8.32 8.8 9.32V15.4Z"
        fill="currentColor"
      />
      <path d="M13 13.8V9H14.4V13.8H13ZM10.4 13.8V9H11.8V13.8H10.4Z" fill="currentColor" />
    </svg>
  );
};
